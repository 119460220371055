import {makeStyles} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import {createStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Card, {CardProps} from 'components/atoms/Card'
import {Colors} from 'components/common/useBaseStyle'

import React, {ReactNodeArray} from 'react'

const headerStyles = () =>
  createStyles({
    headerRoot: {
      padding: '15px 14px 0 39px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    headerHasChildren: {
      marginBottom: 14,
    },
    headerTitle: {
      fontSize: '1.3125rem',
      fontWeight: 500,
      marginRight: 13,
      color: '#676a6c',
    },
    headerDesc: {
      color: '#555555',
      fontSize: 14,
    },
    headerButtons: {
      marginLeft: 'auto',
    },
  })

const styles = () =>
  createStyles({
    root: {
      borderColor: Colors.border,
      border: '1px solid',
      borderRadius: 5,
      marginBottom: 0,
    },
    divider: {
      height: 1,
      marginBottom: 23,
    },
    body: {},
    ...headerStyles(),
  })
const useStyles = makeStyles(styles)

interface CardWithTileProps extends CardProps {
  title: string
  desc?: string
  buttons?: ReactNodeArray
}

const CardWithPoint = ({
  title,
  desc,
  buttons = [],
  children,
  className,
  ...props
}: CardWithTileProps) => {
  const classes = useStyles()

  const headerRootClasses = classNames({
    [classes.headerRoot]: true,
    [classes.headerHasChildren]: !!children,
  })

  return (
    <Card className={`${classes.root} ${className}`} {...props} elevation={0}>
      <div className={headerRootClasses}>
        <div className={classes.headerTitle}>{title}</div>
        {desc && <div className={classes.headerDesc}>{desc}</div>}
        {buttons.length > 0 && (
          <div className={classes.headerButtons}>{buttons}</div>
        )}
      </div>
      {children && <Divider className={classes.divider} />}
      {children && <div className={classes.body}>{children}</div>}
    </Card>
  )
}

export default CardWithPoint
