import React from 'react'
import PeriodSearch from 'components/molcules/PeriodSearch'
import {dateToPeriodString, getDefaultPeriodDate} from 'helpers/dateHelper'
import InstitutionSearch from 'pages/CustomerManagement/InvoiceList/InstitutionSearch'
import useTableToolbarStyles from 'components/Table/useTableToolbarStyles'

interface TableSearchProps {
  isRoot: boolean
  query: any
  onSearch: any
  search?: CustomerSearchKind
  children?: any
}

function TableCustomerSearch(props: TableSearchProps) {
  const {isRoot, query, onSearch, search, children} = props
  const classes = useTableToolbarStyles()
  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())

  // Search By Date period
  const handlePeriod = (period: PeriodDate) => {
    const formatedPeriod = dateToPeriodString(period)
    if (
      formatedPeriod.startDate === query.period.startDate &&
      formatedPeriod.endDate === query.period.endDate
    )
      return
    onSearch({
      ...query,
      period: dateToPeriodString(period),
    })
  }

  return (
    <div className={classes[isRoot ? 'root' : 'container']}>
      <PeriodSearch period={selection} onSearch={handlePeriod} />
      <InstitutionSearch />
      {children}
    </div>
  )
}

export default TableCustomerSearch
