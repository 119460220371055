import styled from 'styled-components'
import Colors from 'theme/Colors'
import Size from 'theme/Size'
import Weight from 'theme/Weight'

export const PrimaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.NormalButton.width,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SecondaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonWidth: Size.NormalButton.width,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const DisabledButtonStyles = {
  buttonColor: Colors.common.secondary,
  buttonBackgroundColor: Colors.common.disabled,
  buttonBackgroundHoverColor: Colors.common.disabled,
  buttonWidth: Size.NormalButton.width,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const DestructiveButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.error.error_01,
  buttonBackgroundHoverColor: Colors.common.error.error_01,
  buttonWidth: Size.NormalButton.width,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SmallButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.SmallButton.width,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const DisabledSmallButtonStyles = {
  buttonColor: Colors.common.secondary,
  buttonBackgroundColor: Colors.common.disabled,
  buttonBackgroundHoverColor: Colors.common.disabled,
  buttonWidth: Size.SmallButton.width,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SmallSecondaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonWidth: Size.SmallButton.width,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const MediumButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.MediumButton.width,
  buttonHeight: Size.MediumButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const MediumSecondaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonWidth: Size.MediumButton.width,
  buttonHeight: Size.MediumButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const LargeButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.LargeButton.width,
  buttonHeight: Size.LargeButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const LargeSecondaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonWidth: Size.LargeButton.width,
  buttonHeight: Size.LargeButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SmallActionSecondaryButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SmallActionButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const PrimaryNormalDialogButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.NormalDialogButton.width,
  buttonHeight: Size.NormalDialogButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const BlackBigSmallButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.blacks.b_03,
  buttonBackgroundHoverColor: Colors.common.blacks.b_02,
  buttonWidth: Size.BigSmallButton.width,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.small,
  buttonFontWeight: Weight.extra_bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const PrimaryFixableNormalDialogButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.primary,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonHeight: Size.NormalDialogButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const SecondaryFixableNormalDialogButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonHeight: Size.NormalDialogButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.smallButton.padding,
}

export const CommonWhiteBorderButtonStyles = {
  buttonColor: Colors.common.grays.g_13,
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: Colors.common.opacity_hover,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.semi_bolder,
  buttonPadding: Size.padding.smallButton.padding,
  buttonBorder: `1px solid ${Colors.common.grays.g_12}`,
}

export const CommonBlackSmallButonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.secondary_hover,
  buttonBackgroundHoverColor: Colors.common.secondary_button_hover,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.regular,
  buttonPadding: Size.padding.smallButton.padding,
}

export const CommonNavySmallButonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.bg.sidebar.menu,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonHeight: Size.SmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.regular,
  buttonPadding: Size.padding.smallButton.padding,
}

interface ButtonLayoutStyles {
  marginTop?: number
}

export const StyledbuttonDisplay = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  gap: 20px;
`

export const StyledButtonLayout = styled.div<ButtonLayoutStyles>`
  margin-top: ${(props) => props.marginTop ?? Size.margin.MyPage.button}px;
  text-align: center;

  & > button {
    margin-right: ${Size.margin.MyPage.button_right}px;
  }
`
export const StyledButtonRightLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 10px;

  & > button {
    flex-basis: 84px;
    padding-left: 10px;
    padding-right: 10px;
  }
`
