import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles, Theme} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {findIdApi, FindIdRequest} from 'api/authApi'
import {makeRsaKeys, rsaDecryption, rsaEncryption} from 'api/securityApi'
import Card from 'components/atoms/Card'
import {styles} from 'components/Dialog/findDialogStyle'
import OrganizationInput from 'components/Dialog/FindIdDialog/form/OrganizationInput'
import {
  closeFindIdDialog,
  selectFindIdDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import {useAppDispatch, useAppSelector} from 'hooks'
import React from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  ...styles(theme),
  result: {
    width: '100%',
    backgroundColor: '#f5f5f5',
    height: 34,
    fontSize: 11,
    color: '#868e96',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .Label': {userSelect: 'none'},
    '& .Email': {paddingLeft: 8},
  },
}))
const FindIdDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const open = useAppSelector(selectFindIdDialogOpen)
  const dispatch = useAppDispatch()

  const findIdData = useForm()
  const {onOpen: openFailModal} = useFailureModal()

  const [email, setEmail] = React.useState('')
  const ONCLOSE_REASON = {
    CLICK_AWAY: 'backdropClick',
  }
  const FORM_FIELDS = {
    firstName: 'firstName',
    lastName: 'lastName',
    tel: 'tel',
    orgName: 'orgName',
  }
  const handleClose = (event?: any, reason?: any) => {
    if (reason && reason === ONCLOSE_REASON.CLICK_AWAY) {
      return
    }
    findIdData.resetField(FORM_FIELDS.firstName)
    findIdData.resetField(FORM_FIELDS.lastName)
    findIdData.resetField(FORM_FIELDS.tel)
    findIdData.resetField(FORM_FIELDS.orgName)
    dispatch(closeFindIdDialog())
    setEmail('')
  }

  const keySet = makeRsaKeys()
  const rsaPublic = keySet.PublicKey
  const rsaPrivate = keySet.PrivateKey

  const handleFindId = async ({
    orgName,
    firstName,
    lastName,
    tel,
    encKey,
  }: FindIdRequest) => {
    try {
      const response = await findIdApi({
        orgName,
        firstName,
        lastName,
        tel,
        encKey,
      })
      if (response) {
        const emailDecrypt = rsaDecryption(rsaPrivate, response.email)
        setEmail(emailDecrypt)
      }
    } catch (e) {
      openFailModal(t(e.getErrorMessage()))
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <Card className={classes.root}>
        <div className='Header'>
          <h3 className='Title'>{t('IFindId')}</h3>
          <IconButton
            type='submit'
            aria-label='search'
            onClick={handleClose}
            className='CloseButton'
          >
            <CloseIcon className='CloseIcon' />
          </IconButton>
        </div>

        <FormProvider {...findIdData}>
          <form
            onSubmit={findIdData.handleSubmit((data) => {
              handleFindId({
                orgName: data.orgName ? data.orgName : undefined,
                firstName: rsaEncryption(data.firstName),
                lastName: rsaEncryption(data.lastName),
                // tel: data.tel,
                tel: rsaEncryption(data.tel),
                encKey: rsaPublic,
              })
            })}
          >
            <div className='Contents'>
              <OrganizationInput />

              {email && (
                <div className={classes.result}>
                  <span className='Label'>id:</span>
                  <span className='Email'>{email}</span>
                </div>
              )}
            </div>
            <div style={{display: 'flex', gap: 10, margin: '0 20px'}}>
              <Button
                className='CancelButton'
                variant='contained'
                disableElevation
                fullWidth
                onClick={handleClose}
              >
                {t('ICancel')}
              </Button>
              <Button
                size='medium'
                variant='contained'
                color='primary'
                disableElevation
                fullWidth
                className='ConfirmButton'
                type='submit'
              >
                {t('IFindId')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Dialog>
  )
}

export default FindIdDialog
