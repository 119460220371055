import CardWithTitle from 'components/atoms/CardWithTitle'
import {SvgIcon} from 'components/common/useSvgIcons'
import SubTitle from 'components/Text/SubTitle'
import {
  PrimaryBtn,
  FlexContainer,
  BoxContainer,
} from 'components/V2Dialog/TherapyDialog/Style'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import usePreset from 'features/org/usePreset'
import useFailureModal from 'features/modal/useFailureModal'
import useConfirmModal from 'features/modal/useConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import {compareTwoArray, getValidValue} from 'helpers/analysisHelper'
import {
  NEW_PRESET_SETTING as DEFAULT_PRESET_SETTING,
  DATA_EEG_LABEL_PRESET,
  DATA_HRV_LABEL_PRESET,
  PresetSetting,
  PRESET_KEY,
  PresetAction,
  ZSCORE_VALID,
} from './constant'
import useStyle from './Style'
import RenderTablePreset from './components/tabContent'
import TabHeader from './components/tabHeader'

function PresetPage() {
  const {t} = useTranslation()
  const {onOpen: onOpenConfirmModal} = useConfirmModal()
  const {onOpen: onOpenFailureModal} = useFailureModal()
  const {onOpen: onOpenSuccessModal} = useSuccessModal()
  const {
    uid,
    loading,
    presets: presetsApi,
    onFetch,
    onCreate,
    onUpdate,
    onRemove,
  } = usePreset()
  const classes = useStyle()
  const [presets, setPresets] = useState<PresetSetting[]>([])
  const [selectedSet, setSelectedSet] = useState<PresetSetting>(
    DEFAULT_PRESET_SETTING,
  )
  const [indexActive, setIndexActive] = useState<number>(0)
  const [deletedPresets, setDeletedPresets] = useState<PresetSetting[]>([])

  /**
   * Parse response 'requestTypeList' to Key - Value match with UI
   * @param raw requestTypeList
   * @returns
   */
  const parseResponseToPreset = (raw: string[]) => {
    const isEegIndv3D = raw.includes('eegIndv3D')
    const isNorm3D = raw.includes('eegNorm3D')
    const isEegSummaryAdv = raw.includes(PRESET_KEY.eegSummaryAdv)
    const isEegSummaryBasic = raw.includes(PRESET_KEY.eegSummary)
    const isHrvSummaryAdv = raw.includes(PRESET_KEY.hrvSummaryAdv)
    const isHrvSummaryBasic = raw.includes(PRESET_KEY.hrvSummary)
    const handleUnmatchedKey = []
    if (isEegIndv3D) {
      handleUnmatchedKey.push(PRESET_KEY.eegIndv, PRESET_KEY.eegIndv3dOn)
    }
    if (raw.includes(PRESET_KEY.eegNorm)) {
      handleUnmatchedKey.push(
        isNorm3D ? PRESET_KEY.eegNorm3dOn : PRESET_KEY.eegNorm3dOff,
      )
    }
    if (isEegSummaryAdv || isEegSummaryBasic) {
      handleUnmatchedKey.push(PRESET_KEY.eegSummary)
      handleUnmatchedKey.push(
        isEegSummaryAdv ? PRESET_KEY.eegSummaryAdv : PRESET_KEY.eegSummaryBas,
      )
    }
    if (isHrvSummaryBasic || isHrvSummaryAdv) {
      handleUnmatchedKey.push(PRESET_KEY.hrvSummary)
      handleUnmatchedKey.push(
        isHrvSummaryAdv ? PRESET_KEY.hrvSummaryAdv : PRESET_KEY.hrvSummaryBas,
      )
    }

    // set status for Individual Analysis
    const EEG = [
      PRESET_KEY.eegIndvAuto,
      PRESET_KEY.eegNorm,
      PRESET_KEY.eegMci,
      PRESET_KEY.eegSummary,
    ]
    const HRV = [PRESET_KEY.hrvSummary]
    if (
      EEG.find((item) => raw.includes(item)) &&
      !handleUnmatchedKey.includes(PRESET_KEY.eegIndv3dOn)
    ) {
      handleUnmatchedKey.push(PRESET_KEY.eegIndv, PRESET_KEY.eegIndv3dOff)
    }
    if (HRV.find((item) => raw.includes(item))) {
      handleUnmatchedKey.push(PRESET_KEY.hrvIndv)
    }

    const matchedKey = raw.filter(
      (k) =>
        k !== 'eegIndv3D' &&
        k !== 'eegNorm3D' &&
        k !== PRESET_KEY.eegSummaryAdv &&
        k !== PRESET_KEY.hrvSummaryAdv,
    )
    const finalKey = [...matchedKey, ...handleUnmatchedKey].filter(
      (key) => key !== PRESET_KEY.eegIndvAuto,
    )
    return finalKey
  }

  const handleSelectPreset = (id: number, index: number) => {
    const dataSelect = presets.find((item) => item.id === id)
    if (dataSelect) {
      const dataSet = JSON.parse(JSON.stringify(dataSelect))
      if (dataSet.zscore)
        dataSet.requestTypeList.push(dataSet.zscore.toString())
      setSelectedSet(dataSet)
    }
    setIndexActive(index)
  }

  const handleCreatePreset = () => {
    const NEW_PRESET_SETTING = {
      // Detect Create new or Update
      label: `preset_${Date.now()}`,
      id: Date.now() * -1,
      requestTypeList: [],
      zscore: null,
      action: PresetAction.create,
    }
    setPresets((prevState) => [...prevState, NEW_PRESET_SETTING])
    setIndexActive(presets.length)
    setSelectedSet(NEW_PRESET_SETTING)
  }

  const callAPIPreset = async (
    data: string[],
    id: number,
  ): Promise<boolean | void> => {
    const zscore = +(data.find((key) => ZSCORE_VALID.includes(key)) || 0)
    const parseData = (key: string) => (data.includes(key) ? 'Y' : 'N')
    const payload = {
      eegIndvAuto: parseData(PRESET_KEY.eegIndv),
      eegIndv3D: parseData(PRESET_KEY.eegIndv3dOn),
      eegIndvIca: 'N',
      eegMci: parseData(PRESET_KEY.eegMci),
      eegNorm: parseData(PRESET_KEY.eegNorm),
      eegNorm3D: parseData(PRESET_KEY.eegNorm3dOn),
      eegSummary:
        parseData(PRESET_KEY.eegSummaryAdv) === 'Y'
          ? 'N'
          : parseData(PRESET_KEY.eegSummary),
      eegSummaryAdv: parseData(PRESET_KEY.eegSummaryAdv),
      hrvIndv: data.includes(PRESET_KEY.hrvSummary)
        ? 'Y'
        : parseData(PRESET_KEY.hrvIndv),
      hrvSummary:
        parseData(PRESET_KEY.hrvSummaryAdv) === 'Y'
          ? 'N'
          : parseData(PRESET_KEY.hrvSummary),
      hrvSummaryAdv: parseData(PRESET_KEY.hrvSummaryAdv),
      zscore: zscore > 0 ? zscore : null,
      label: selectedSet.label,
      uid,
    }
    let submitResult: any = null
    if (id >= 0) {
      submitResult = await onUpdate({...payload, id})
    } else {
      submitResult = await onCreate(payload)
    }
    if (submitResult?.error) {
      onOpenFailureModal(t('IProcessFail'))
    }
  }

  async function* createPromisesGenerator(presetsCreate: PresetSetting[]) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of presetsCreate) {
      yield callAPIPreset(item.requestTypeList, item.id)
    }
  }

  const handleSubmitAll = async () => {
    // Delete Preset first
    const deletePromises = deletedPresets.map(async (preset) =>
      onRemove({id: preset.id, uid}),
    )
    if (deletePromises?.length && deletePromises.length > 0) {
      await Promise.all(deletePromises)
      setDeletedPresets([])
    }
    const presetsUpdate = presets.filter(
      (item) => item.id >= 0 && item.action === PresetAction.update,
    )
    const presetsCreate = presets.filter((item) => item.id < 0)
    const updatePromises = presetsUpdate.map((item) =>
      callAPIPreset(item.requestTypeList, item.id),
    )
    const createPromises = []
    const createGenerator = createPromisesGenerator(presetsCreate)
    // eslint-disable-next-line no-restricted-syntax
    for await (const promise of createGenerator) {
      createPromises.push(promise)
    }
    if (![...updatePromises, ...createPromises, ...deletePromises].length)
      return
    await Promise.all([...updatePromises, ...createPromises])
    onOpenSuccessModal(t('IPresetSuccess'))
    onFetch()
  }

  /**
   * Rollback to intilized value
   * @param presetId selected Preset ID
   * @returns
   */
  const handleCancel = async () => {
    const confirmResult = await onOpenConfirmModal({
      message: t('IPresetCancelConfirm'),
    })
    if (!confirmResult?.payload) return
    onFetch()
    setDeletedPresets([])
  }

  const handleDeletePreset = async (id: number) => {
    // Check if  can delete preset
    if (presets.length <= 1) {
      onOpenFailureModal(t('IPresetCannotDelete'))
      return
    }
    const newPresets = [...presets.filter((preset) => preset.id !== id)]
    const deletedPreset = presets.find((preset) => preset.id === id)
    // Delete from server => Store deleted values
    if (id > 0 && deletedPreset) {
      setDeletedPresets((prevState) => [...prevState, deletedPreset])
    }
    setPresets(newPresets)
    setSelectedSet(newPresets[0])
    setIndexActive(0)
  }

  const isUpdateSelectedSet = (requestTypeList: string[]) => {
    const requestTypeListOrigin = getValidValue([
      ...parseResponseToPreset(presetsApi[indexActive]?.requestTypeList).filter(
        (item) => !ZSCORE_VALID.includes(item),
      ),
      presetsApi[indexActive]?.zscore?.toString(),
    ])

    return !compareTwoArray(requestTypeListOrigin, requestTypeList)
  }

  const updateSelectedSet = (
    newSelected: string[],
    removeSelect: string[],
    selectedKey: string,
  ) => {
    let selectedUpdate = [...selectedSet?.requestTypeList, ...newSelected]
    selectedUpdate = selectedUpdate.filter(
      (item: string) => !removeSelect.includes(item),
    )
    const set = new Set(selectedUpdate)
    selectedUpdate = Array.from(set)

    // Handle Logic auto select Individual Analysis
    const EEG = [PRESET_KEY.eegNorm, PRESET_KEY.eegMci, PRESET_KEY.eegSummary]
    const HRV = [PRESET_KEY.hrvSummary]
    if (
      newSelected.includes(selectedKey) &&
      EEG.includes(selectedKey) &&
      !selectedUpdate.includes(PRESET_KEY.eegIndv)
    ) {
      selectedUpdate.push(PRESET_KEY.eegIndv, PRESET_KEY.eegIndv3dOff)
    }
    if (
      newSelected.includes(selectedKey) &&
      HRV.includes(selectedKey) &&
      !selectedUpdate.includes(PRESET_KEY.hrvIndv)
    ) {
      selectedUpdate.push(PRESET_KEY.hrvIndv)
    }

    const newSelectedSet = {
      ...selectedSet,
      requestTypeList: selectedUpdate,
    }
    if (selectedSet.action !== PresetAction.create) {
      newSelectedSet.action = isUpdateSelectedSet(getValidValue(selectedUpdate))
        ? PresetAction.update
        : PresetAction.default
    }

    setSelectedSet(newSelectedSet)
    const newPresets = presets.map((preset) => {
      if (preset.id !== selectedSet?.id) return preset
      return newSelectedSet
    })
    setPresets(newPresets)
  }

  const disabledCancel = useMemo(() => {
    if (deletedPresets.length > 0) return false
    if (presets.length === 1 && presets[0].action === PresetAction.create)
      return true
    return !presets.find(
      (item) =>
        item.action === PresetAction.update ||
        item.action === PresetAction.create,
    )
  }, [presets, deletedPresets])

  useEffect(() => {
    if (
      (!presetsApi || presetsApi.length === 0) &&
      (!selectedSet || selectedSet?.id < 0)
    ) {
      setPresets([DEFAULT_PRESET_SETTING])
      setSelectedSet(DEFAULT_PRESET_SETTING)
      setIndexActive(0)
    } else {
      const remotePreset = presetsApi.map((preset) => ({
        ...preset,
        requestTypeList: [
          ...parseResponseToPreset(preset?.requestTypeList).filter(
            (item) => !ZSCORE_VALID.includes(item),
          ),
          preset?.zscore?.toString(),
        ],
      }))

      setPresets(remotePreset)
      const dataSet = JSON.parse(JSON.stringify(remotePreset))
      setSelectedSet(dataSet[indexActive])
    }
  }, [presetsApi])

  useEffect(() => {
    onFetch()
  }, [])

  return (
    <>
      <CardWithTitle>
        <SubTitle
          style={{
            padding: '15px 0',
          }}
        >
          {t('IPresetManagement')}
        </SubTitle>
        {/* Preset Management */}
        <div>
          {/* Preset Tabs */}
          {presets?.length ? (
            <FlexContainer alignItem='center' gap='5px'>
              {presets?.map((set, index) => {
                return (
                  <TabHeader
                    handleDeletePreset={handleDeletePreset}
                    handleSelectPreset={handleSelectPreset}
                    presets={presets}
                    selectedSet={selectedSet}
                    set={set}
                    index={index}
                  />
                )
              })}
              <FlexContainer cursor='pointer' padding='0px' alignItem='center'>
                {/* create set container */}
                {presets.length < 5 && (
                  <PrimaryBtn
                    onClick={handleCreatePreset}
                    bgcolor='#3EBCBD'
                    radius='4px 4px 0px 0px'
                    disabled={presets.length > 4}
                    width='80px'
                    height='41px'
                  >
                    <SvgIcon name='addPreset' iconColor='' />
                  </PrimaryBtn>
                )}
              </FlexContainer>
            </FlexContainer>
          ) : (
            ''
          )}
          <BoxContainer
            minHeight='300px'
            bgColor='#FAFAFA'
            padding='30px 40px'
            shadow='none'
            style={{border: '1px solid #ebebeb'}}
          >
            {presets &&
              presets?.length > 0 &&
              presets?.map((set) => {
                return (
                  <div
                    style={{
                      display: set?.id === selectedSet?.id ? 'flex' : 'none',
                    }}
                    key={set.id}
                    className={classes.reportPage}
                  >
                    {/* EEG Analysis */}
                    <div className={classes.reportContainer}>
                      <h1 className={classes.reportTitle}>{t('IPresetEeg')}</h1>
                      {DATA_EEG_LABEL_PRESET.map((item, index) => (
                        <div
                          className={classes.rowContainer}
                          key={`DATA_EEG_LABEL_PRESET_${index}`}
                        >
                          <RenderTablePreset
                            data={item}
                            keySelected={selectedSet?.requestTypeList.filter(
                              (selected: string) =>
                                item
                                  .map((value) => value.key)
                                  .includes(selected),
                            )}
                            allSelected={selectedSet?.requestTypeList}
                            updateSelectedSet={updateSelectedSet}
                          />
                        </div>
                      ))}
                    </div>
                    <div className={classes.verticalDivider} />
                    {/* HRV Analysis */}
                    <div className={classes.reportContainer}>
                      <h1 className={classes.reportTitle}>{t('IPresetHrv')}</h1>
                      {DATA_HRV_LABEL_PRESET.map((item, index) => (
                        <div
                          className={classes.rowContainer}
                          key={`DATA_HRV_LABEL_PRESET_${index}`}
                        >
                          <RenderTablePreset
                            data={item}
                            keySelected={selectedSet?.requestTypeList.filter(
                              (selected: string) =>
                                item
                                  .map((value) => value.key)
                                  .includes(selected),
                            )}
                            allSelected={selectedSet?.requestTypeList}
                            updateSelectedSet={updateSelectedSet}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })}
          </BoxContainer>
          {/* Preset Tab Footer */}
          <div>
            <div
              style={{
                display: 'flex',
                gap: '28px',
                padding: 30,
                justifyContent: 'center',
              }}
            >
              <PrimaryBtn
                disabled={disabledCancel}
                onClick={handleCancel}
                bgcolor={disabledCancel ? '#5A62689e' : '#5a6268'}
                style={{
                  cursor: disabledCancel ? 'not-allowed' : 'pointer',
                }}
                color='#fff'
                padding='10px 90px'
                radius='4px'
              >
                {t('ICancel')}
              </PrimaryBtn>
              <PrimaryBtn
                onClick={() => !loading && handleSubmitAll()}
                bgcolor={loading ? '#55a5a5' : '#3EBCBD'}
                color='#fff'
                padding='10px 90px'
                radius='4px'
              >
                {t('ISave')}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </CardWithTitle>
    </>
  )
}

export default PresetPage
