import {Typography} from '@material-ui/core'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

export default function UserNameInput() {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const schema = yup.object().shape({
    code: yup.string().required(t('IRequired')),
  })

  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IRegisterOrgBossName')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.inputWrap}>
          <input
            placeholder={t('IFirstName')}
            className={classes.input}
            {...createOrgInfo.register('chiefFirstName', {
              validate: (code) =>
                schema.validate({code}).catch((e) => e.errors[0]),
            })}
          />
          {errors.chiefFirstName && (
            <span className={classes.errorText}>
              {errors.chiefFirstName && errors.chiefFirstName.message}
            </span>
          )}
        </div>
        <Typography className={classes.padding} />
        <div className={classes.inputWrap}>
          <input
            placeholder={t('ILastName')}
            className={classes.input}
            {...createOrgInfo.register('chiefLastName', {
              validate: (code) =>
                schema.validate({code}).catch((e) => e.errors[0]),
            })}
          />
          {errors.chiefLastName && (
            <span className={classes.errorText}>
              {errors.chiefLastName && errors.chiefLastName.message}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
