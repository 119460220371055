import {TableRow, TableCell} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import useTableStyles from 'components/Table/useTableStyles'
import React from 'react'

interface Props {
  loading: boolean
  emptyRows: number
}

export default function TableLoading({loading, emptyRows}: Props) {
  const classes = useTableStyles()

  if (!loading) return null

  return (
    <TableRow style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}>
      <TableCell colSpan={12}>
        <div className={classes.loading}>
          <CircularProgress size={50} thickness={5} />
        </div>
      </TableCell>
    </TableRow>
  )
}
