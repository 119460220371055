import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {resetPaymentListRequest} from 'features/payment/paymentSlice'
import {
  fetchMyPackageRequest,
  resetPointListRequest,
} from 'features/point/pointSlice'
import {resetSelectedOidRequest} from 'features/sales/salesAllOrgSlice'
import {useAppDispatch} from 'hooks'
import MyPaymentHistory from 'pages/MyPages/MyPaymentHistory'
import MyPointHistory from 'pages/MyPages/MyPointHistory'
import React, {useEffect} from 'react'
import MyPromotionHistory from '../MyPromotionHistory'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    indexInputContainer: {
      marginTop: 6,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
      marginTop: 3,
    },
    analBoxWrap: {
      marginTop: 10,
    },
    analBox: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 80px 15px 80px',
      border: '1px solid rgb(53 73 99 / 20%)',
      position: 'relative',
      borderRadius: '4px',
    },
    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      width: '100%',
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
)

// side메뉴 병원관리-결제관리 페이지임 원래는 마이페이지 하위메뉴에 있다가 옮긴것이라 이 폴더에 있는것
const MyPurchasePage = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const handlePackageList = () => {
    dispatch(fetchMyPackageRequest())
  }

  useEffect(() => {
    return () => {
      dispatch(resetPointListRequest())
      dispatch(resetPaymentListRequest())
      dispatch(resetSelectedOidRequest())
    }
  }, [])
  useEffect(() => {
    handlePackageList()
  }, [])

  return (
    <div>
      <Typography className={classes.padding} />
      <MyPointHistory />
      <Typography className={classes.padding} />
      <MyPromotionHistory />
      <Typography className={classes.padding} />
      <MyPaymentHistory />
      <Typography className={classes.padding} />
    </div>
  )
}

export default MyPurchasePage
