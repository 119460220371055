import React, {useEffect} from 'react'
import styled from 'styled-components'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  PackageTable,
  PackageTableHeader,
  TableOverflowWrap,
} from 'components/Table/CommonTableStyle'
import {useTranslation} from 'react-i18next'
import ProductThumbnail from 'components/V2ProductThumbnail/ProductThumbnail'
import {
  PackageThumbnailStyles,
  BundleThumbnailStyles,
} from 'components/V2ProductThumbnail/Styles'
import useViewModel from './MyPackageTableViewModel'
import {StyledRemainsText, StyledTotalityText} from '../../Style'
import ProductsInfos from '../../ProductsInfos'
import IncludedProducts from '../../IncludedProducts'

const ButtonPrimaryLine = styled.button`
  padding: 0 14px;
  height: 30px;
  color: #64b9bc;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #64b9bc;
  cursor: pointer;
  transition: background-color 300ms linear, color 300ms linear;

  &:hover {
    background-color: #64b9bc;
    color: white;
  }
`
const ButtonWarningLine = styled(ButtonPrimaryLine)`
  color: #ee5454;
  border: 1px solid #ee5454;

  &:hover {
    background-color: #ee5454;
    color: white;
  }
`

const MyPackageTable = () => {
  const {t} = useTranslation()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {
    myProducts,
    isLoaded,
    getMyProducts,
    toggleAutoBilling,
    isPauseForPackage,
  } = useViewModel()

  // CYM: 알림 팝업을 띄우기 위한 자동 결제 토글 버튼 핸들 임시 함수
  // eslint-disable-next-line
  const handleAutoPaymentToggle = () => {
    // 자동 결제 가짜 데이터
    const autoPaymentTempData = 'N'

    // 자동 결제가 정지 상태인가?
    if (autoPaymentTempData === 'N') {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeRestartAutoPayment'),
      }).then((result) => {
        if (result.payload) {
          // result.payload가 true면 confirm
          // 버튼 텍스트 정지 -> 재시작으로 변경 필요
          console.log(result.payload)
        }
      })
    }

    // 자동결제가 재시작인가?
    else {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeStopAutoPayment'),
      }).then((result) => {
        if (result.payload) {
          // result.payload가 true면 confirm
          // 버튼 텍스트 재시작 -> 정지로 변경 필요
          console.log(result.payload)
        }
      })
    }
  }

  useEffect(() => {
    // getProducts()
    getMyProducts()
  }, [])

  useEffect(() => {
    console.log('myProducts: ,', myProducts)
  }, [myProducts])

  return (
    <>
      <TableOverflowWrap>
        <PackageTable>
          <colgroup>
            <col style={{minWidth: 340, width: '20%'}} />
            <col style={{minWidth: 420, width: '20%'}} />
            <col style={{minWidth: 120}} />
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 120}} />
            <col style={{minWidth: 110}} />
          </colgroup>
          <PackageTableHeader>
            <tr>
              {/* <th>
                <p>{t('ITypeInPymtMngmt')}</p>
              </th> */}
              <th>
                <p>{t('IProductName')}</p>
              </th>
              <th>
                <p>{t('IIncludedProducts')}</p>
              </th>
              <th>
                <p>{t('IRemainingBillingCycle')}</p>
              </th>
              <th>
                <p>{t('IRemainingAmount')}</p>
              </th>
              <th>
                <p>{t('IExpirationAt')}</p>
              </th>
              <th>
                <p>{t('IAutoPayment')}</p>
              </th>
            </tr>
          </PackageTableHeader>
          {isLoaded && (
            <tbody>
              {myProducts.packages.map((item, idx) => (
                <tr key={idx}>
                  <td>
                    <ProductThumbnail
                      type='Package'
                      productName={item.name}
                      theme={PackageThumbnailStyles}
                    />
                  </td>
                  <td style={{verticalAlign: 'top'}}>
                    {/* TODO: hasPdf는 가짜 데이터로 추후 api 변경 후 변경 필요 item.guide ? 같은게 있을 때만 호버 및 이벤트 추가 */}
                    <ProductsInfos
                      description={item.description ? item.description : '-'}
                      hasPdf={false}
                    >
                      <IncludedProducts analyses={item.analyses} />
                    </ProductsInfos>
                  </td>
                  <td>
                    {/* TODO: 만료일 바뀌고 다시 작업 필요 */}
                    <StyledRemainsText>
                      {`${item.getRemainDate()}/`}
                    </StyledRemainsText>
                    <StyledTotalityText>
                      {item.getGivenDate()}
                    </StyledTotalityText>
                  </td>
                  <td>
                    <StyledRemainsText>
                      {`${item.getRemainAmount()}/`}
                    </StyledRemainsText>
                    <StyledTotalityText>
                      {item.getGivenAmount()}
                    </StyledTotalityText>
                  </td>
                  <td>{item.expiresAt}</td>
                  <td>
                    {isPauseForPackage(idx) ? (
                      <ButtonPrimaryLine
                        type='button'
                        onClick={() => toggleAutoBilling(idx)}
                      >
                        {t('IRestartAutoPayment')}
                      </ButtonPrimaryLine>
                    ) : (
                      <ButtonWarningLine
                        type='button'
                        onClick={() => toggleAutoBilling(idx)}
                      >
                        {t('IStopAutoPayment')}
                      </ButtonWarningLine>
                    )}
                  </td>
                </tr>
              ))}
              {myProducts.bundles.map((item, idx) => (
                <tr key={idx}>
                  <td>
                    <ProductThumbnail
                      type='Bundle'
                      productName={item.name}
                      theme={BundleThumbnailStyles}
                    />
                  </td>
                  <td style={{verticalAlign: 'top'}}>
                    {/* TODO: hasPdf는 가짜 데이터로 추후 api 변경 후 변경 필요 item.guide ? 같은게 있을 때만 호버 및 이벤트 추가 */}
                    <ProductsInfos
                      description={item.description ? item.description : '-'}
                      hasPdf={false}
                    >
                      <IncludedProducts analyses={item.analyses} />
                    </ProductsInfos>
                  </td>
                  <td>-</td>
                  <td>
                    <StyledRemainsText>
                      {`${item.getRemainAmount()}/`}
                    </StyledRemainsText>
                    <StyledTotalityText>
                      {item.getGivenAmount()}
                    </StyledTotalityText>
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
            </tbody>
          )}
        </PackageTable>

        {isLoaded &&
          myProducts.bundles.length < 1 &&
          myProducts.packages.length < 1 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              {t('INoData')}
            </div>
          )}
      </TableOverflowWrap>
    </>
  )
}
export default MyPackageTable
