import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

export const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    height: 50,
    borderRadius: 8,
    '& $notchedOutline': {
      border: '3px solid #e9e9e9',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      border: '3px solid #e9e9e9',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        border: '3px solid #e9e9e9',
      },
    },
    '&$focused $notchedOutline': {
      border: '3px solid #e9e9e9',
    },
    '&$error $notchedOutline': {
      border: '3px solid #e9e9e9',
    },
    '&$disabled $notchedOutline': {
      border: '3px solid #e9e9e9',
      backgroundColor: 'red',
    },
  },
  focused: {},
  notchedOutline: {},
}))

export const useBoldInputStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'column',
    width: '100%',
    '& input:focus': {outline: `none`, borderColor: `${Colors.primary}`},
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    flex: 1,
    height: 50,
    minHeight: 50,
    border: '3px solid #e9e9e9',
    borderRadius: 7,
    padding: 10,
    color: '#676a6c',
    outline: 'none',
    '&:focus': {outline: `none`, borderColor: `${Colors.primary}`},
    '&:invalid': {outline: `none`, borderColor: `${Colors.red}`},
    '& input:focus': {outline: `none`, borderColor: `${Colors.primary}`},
  },
  errorInput: {
    flex: 1,
    height: 50,
    border: '3px solid #e9e9e9',
    borderRadius: 7,
    padding: 10,
    color: '#676a6c',
    outline: 'none',
    '&:focus': {outline: `none`, borderColor: `${Colors.red}`},
  },
  errorText: {
    fontSize: 11,
    marginTop: 2,
    color: '#d03c35',
  },
  padding: {
    padding: theme.spacing(1),
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: '#4b4b4b',
  },
  labelWrapContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  require: {
    color: '#d03c35',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 8,
    marginLeft: 3,
  },
  subSmallText: {
    color: '#4b4b4b',
    fontSize: 10,
    marginLeft: 7,
  },
  checkbox: {
    '& label': {
      border: '1px solid #666a6c',
      width: 14,
      height: 14,
      boxSizing: 'border-box',
      borderRadius: 3,
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& input[type="checkbox"]': {
      display: 'none',
    },
    '& input[type="checkbox"]:checked + label': {
      borderColor: '#0cc7c7',
      background: '#0cc7c7',
    },
    '& label:disabled': {
      border: '1px solid #666a6c',
      width: 14,
      height: 14,
      boxSizing: 'border-box',
      borderRadius: 3,
      background: 'white',
      display: 'flex',
      margin: 'auto',
    },

    '& .arrowIcon': {
      width: 13,
      height: 13,
      marginBottom: 3,
      color: 'white',
      border: 1,
    },
  },
}))
