import * as React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {ErrorText} from '../../style'

export default function OrganizationNameInput() {
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    formState: {errors},
    register,
    watch,
  } = useFormContext()

  const isPersonal = watch('isPersonal')

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IInstituteName')}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
        <div style={{flex: 1}}>
          <input
            placeholder={t('IRegisterHospitalName')}
            className={classes.input}
            style={{width: '100%'}}
            {...register('name')}
            disabled={isPersonal}
          />
        </div>
        <div style={{flex: 1}}>
          <div
            className={classes.checkbox}
            style={{display: 'flex', gap: 4, alignItems: 'center'}}
          >
            <input
              {...register('isPersonal')}
              type='checkbox'
              id='noInstitute'
            />
            {/* eslint-disable-next-line */}
            <label className='label' htmlFor='noInstitute'>
              <CheckIcon className='arrowIcon' />
            </label>
            <div>{t('IIndividualUser')}</div>
          </div>
        </div>
      </div>
      {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
    </div>
  )
}
