import Card from 'components/atoms/Card'
import Dialog from '@material-ui/core/Dialog'
import {
  closeSalseInspectionAddDialog,
  selectSalesInspectionAddDialogOpen,
} from 'features/modal/modalSlice'
import {EmergencyNotifyRequest} from 'api/salesNoticeApi'
import useFailureModal from 'features/modal/useFailureModal'
import {UpdateEmergencyNotice} from 'features/sales/salesNoticeSlice'
import Button from '@material-ui/core/Button'
import {
  dateToDashString,
  fromIsoDateOrStringToLocalDate,
  localStringToUtcString,
  makeDateAndTimeFormat,
  startDateCompareEndDate,
  toUtcDate,
} from 'helpers/dateHelper'
import CloseIcon from '@material-ui/icons/Close'
import CalenderSinglePicker from 'components/Calender/CalenderSinglePicker'
import {
  InputTitle,
  NoticeContentWrapper,
  NoticeInputContentText,
  NoticeInputTitleText,
  NoticeTimeInputText,
  NoticeTitle,
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioButtonText,
  TitleLine,
} from 'pages/SalesPages/SalesAppNoticePage/StyledPostDialog'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import {useTranslation} from 'react-i18next'
import i18n from 'i18n'

export default function SalesNoticeInspectionAddDialog() {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const [startTimeSelect, setStartTimeSelect] = React.useState<string>(
    t('IAppNoticeTimeNow'),
  )
  const [endTimeSelect, setEndTimeSelect] = React.useState<string>(
    t('IAppNoticeTimeAutoNone'),
  )
  const beginDate = new Date()
  const [beginDateString, setBeginDateString] = React.useState<string>(
    dateToDashString(beginDate),
  )
  const endDate = new Date()
  const [endDateString, setEndDateString] = React.useState<string>(
    dateToDashString(endDate),
  )
  const [titleKr, setTitleKr] = React.useState<string>()
  const [contentKr, setContentKr] = React.useState<string>()
  const [titleEn, setTitelEn] = React.useState<string>()
  const [contentEn, setContentEn] = React.useState<string>()
  const [beginHour, setBeginHour] = React.useState<string>('')
  const [beginMin, setBeginMin] = React.useState<string>('')

  const [onBeginDate, setOnBeginDate] = React.useState<Date>()
  const [beginDatePick, setBeginDatePick] = React.useState<boolean>(false)

  const [endHour, setEndHour] = React.useState<string>('')
  const [endMin, setEndMin] = React.useState<string>('')
  const [submitCheck, setSubmitCheck] = React.useState<boolean>(true)

  const date = new Date()

  const handleClose = () => {
    dispatch(closeSalseInspectionAddDialog())
  }
  const checkSelectManualTime = (value: string) => {
    return value === t('IAppNoticeTimeManualEntry')
  }

  const handleDuplicate = (hour: string, min: string): string => {
    return `${hour}:${min}`
  }

  const hadleStartSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStartTimeSelect(event.target.value)
  }

  const handleBeginDateChanged = (date?: Date) => {
    if (date) {
      setBeginDatePick(true)
      setOnBeginDate(date)
      setBeginDateString(dateToDashString(date))
    }
  }

  const handleEndDateChanged = (date?: Date) => {
    if (date) setEndDateString(dateToDashString(date))
  }

  const handleEndSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEndTimeSelect(event.target.value)
  }

  const salesInspectionDialogOpen = useAppSelector(
    selectSalesInspectionAddDialogOpen,
  )
  const selectTimeNow = (value: string): boolean => {
    return value === t('IAppNoticeTimeNow')
  }

  const selectTimeEnd = (value: string): boolean => {
    return value === t('IAppNoticeTimeAutoNone')
  }

  const handleTimeNumberSet = (time: string): string => {
    const timeString = time.substring(0, 1)

    if (Number(time) < 10 && timeString !== '0') {
      return `0${time}`
    }
    return time
  }
  const onSubmit = () => {
    if (Number(beginHour) > 23 || Number(endHour) > 23) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }
    if (Number(beginMin) > 59 || Number(endMin) > 59) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }

    const contentData: EmergencyNotifyRequest = {
      isNotProceed: true,
      begin: null,
      end: null,
      contents: JSON.stringify([
        {
          languageCode: 'EN',
          title: titleEn,
          content: contentEn,
        },
        {
          languageCode: 'KO',
          title: titleKr,
          content: contentKr,
        },
      ]),
    }

    if (!checkSelectManualTime(startTimeSelect)) {
      const timeNow = toUtcDate(new Date())

      contentData.begin = makeDateAndTimeFormat(timeNow)
    }

    if (beginHour && beginMin && beginDate) {
      const beginString = `${beginDateString} ${handleDuplicate(
        handleTimeNumberSet(beginHour),
        handleTimeNumberSet(beginMin),
      )}`

      contentData.begin = localStringToUtcString(beginString)
    }
    if (endHour && endMin && endDate) {
      const endString = `${endDateString} ${handleDuplicate(
        handleTimeNumberSet(endHour),
        handleTimeNumberSet(endMin),
      )}`

      contentData.end = localStringToUtcString(endString)
    }

    if (
      (checkSelectManualTime(startTimeSelect) && !beginHour) ||
      (checkSelectManualTime(startTimeSelect) && !beginMin)
    ) {
      const beginString = `${beginDateString} ${handleDuplicate('00', '00')}`

      contentData.begin = localStringToUtcString(beginString)
    }
    if (
      (checkSelectManualTime(endTimeSelect) && !endHour) ||
      (checkSelectManualTime(endTimeSelect) && !endMin)
    ) {
      const endString = `${endDateString} ${handleDuplicate('23', '59')}`

      contentData.end = localStringToUtcString(endString)
    }

    if (contentData.begin && contentData.end) {
      if (
        !startDateCompareEndDate(
          fromIsoDateOrStringToLocalDate(contentData.begin),
          fromIsoDateOrStringToLocalDate(contentData.end),
        )
      ) {
        return onFailureModalOpen(t('IStartTimeEndTimeComparisonMessage'))
      }
    }

    dispatch(UpdateEmergencyNotice(contentData))
    return handleClose()
  }

  useEffect(() => {
    if (titleKr && contentKr && titleEn && contentEn) {
      setSubmitCheck(false)
    }
    if (
      titleKr === undefined ||
      contentKr === undefined ||
      titleEn === undefined ||
      contentEn === undefined
    ) {
      setSubmitCheck(true)
    }
  }, [titleKr, contentKr, titleEn, contentEn])

  useEffect(() => {
    setStartTimeSelect(t('IAppNoticeTimeNow'))
    setEndTimeSelect(t('IAppNoticeTimeAutoNone'))
    setBeginDatePick(false)
    setOnBeginDate(undefined)
    setTitleKr(undefined)
    setContentKr(undefined)
    setTitelEn(undefined)
    setContentEn(undefined)
  }, [salesInspectionDialogOpen])

  return (
    <div>
      <Dialog
        open={salesInspectionDialogOpen}
        fullWidth
        maxWidth='md'
        style={{
          width: `${Spacing.notice.noticeDialogWidth}px`,
          margin: `${Spacing.notice.noticeTextMargin} auto`,
        }}
      >
        <Card>
          <div
            style={{
              marginBottom: `${Spacing.notice.noticeDivWidthMarginBottom}px`,
            }}
          >
            <NoticeTitle>{t('IAppInspectionTitle')}</NoticeTitle>
            <CloseIcon
              style={{
                position: 'absolute',
                right: `${Spacing.notice.noticeCloseIconRight}px`,
                top: `${Spacing.notice.noticeCloseIconTop}px`,
                width: `${Spacing.notice.noticeCloseIconWidth}px`,
                height: `${Spacing.notice.noticeCloseIconWidth}px`,
                color: `${Colors.notice.noticeCancleIconColor}`,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <TitleLine />
            <NoticeContentWrapper>
              <div
                style={{
                  margin: `${Spacing.notice.noticeTextMargin} auto`,
                  width: `${Spacing.notice.noticeContentWidth}px`,
                }}
              >
                <InputTitle>{t('IAppInspectionTime')}</InputTitle>
                <div
                  style={{padding: `${Spacing.notice.noticeContentPadding}px`}}
                />
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeStart')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeNow')}
                      checked={startTimeSelect === `${t('IAppNoticeTimeNow')}`}
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>{t('IAppNoticeTimeNow')}</RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        startTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeEnd')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeAutoNone')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeAutoNone')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeAutoNone')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                <InputTitle>{t('IAppInspectionTitleKrEn')}</InputTitle>
                <NoticeInputTitleText
                  onChange={(e) => {
                    setTitleKr(e.target.value)
                  }}
                  placeholder={t('IAppInspectionTitleRequired')}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/g,
                  //     '',
                  //   )
                  // }}
                />
                <InputTitle>{t('IAppNoticeContentKrEn')}</InputTitle>
                <NoticeInputContentText
                  onChange={(e) => {
                    setContentKr(e.target.value)
                  }}
                  placeholder={t('IContentsRequired')}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/g,
                  //     '',
                  //   )
                  // }}
                />
              </div>
              <div
                style={{marginTop: `${Spacing.notice.noticeDivMarginTop}px`}}
              >
                <div>
                  <div style={{display: 'flex'}}>
                    <CalenderSinglePicker
                      selectedBeginDate={false}
                      isOpen={!selectTimeNow(startTimeSelect)}
                      onChangeValue={handleBeginDateChanged}
                      dateValue={date}
                    />
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => setBeginHour(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => setBeginMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                  <div style={{display: 'flex'}}>
                    <CalenderSinglePicker
                      onBeginDate={onBeginDate}
                      selectedBeginDate={beginDatePick}
                      isOpen={!selectTimeEnd(endTimeSelect)}
                      onChangeValue={handleEndDateChanged}
                      dateValue={date}
                    />
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndHour(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                </div>
                {/* 영문입력 텍스트 */}
                {i18n.language !== 'ko' && (
                  <div
                    style={{
                      marginTop: `${Spacing.notice.noticeInputTitleTextCustomMarginTop}px`,
                    }}
                  >
                    <NoticeInputTitleText
                      style={{
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      value={titleEn}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      value={contentEn}
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
                {i18n.language === 'ko' && (
                  <div>
                    <NoticeInputTitleText
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      style={{
                        marginTop: `${Spacing.notice.noticeInputEnTitleCustomMarginTop}px`,
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
              </div>
            </NoticeContentWrapper>
          </div>
          <div
            style={{
              textAlign: 'center',
              backgroundColor: `${Colors.cancelButtonLight}`,
              padding: `${Spacing.notice.noticeButtonBackgroundPaddingTop}px ${Spacing.notice.noticeButtonBackgroundPaddingLeft}`,
            }}
          >
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
              style={{
                backgroundColor: 'white',
                color: `${Colors.notice.noticeRadioButtonTextColor}`,
                marginRight: `${Spacing.notice.noticeButtonMarginRight}px`,
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('ICancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              disabled={submitCheck}
              onClick={onSubmit}
              style={{
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('IOk')}
            </Button>
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
