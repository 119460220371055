import React from 'react'
import CardInfoAIBrainScan from 'components/atoms/CardInfoAIBrainScan'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

const headerStyles = () =>
  createStyles({
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px 30px',
      marginTop: '50px',
    },
  })
const useStyles = makeStyles(headerStyles)
interface RenderPage2Props {
  dataHealth: UsageHistory[]
}

const AnalysisHealth = ({dataHealth}: RenderPage2Props) => {
  const classes = useStyles()
  if (dataHealth.length) {
    return (
      <div className={classes.card}>
        {dataHealth.map((item: UsageHistory) => (
          <CardInfoAIBrainScan data={item} key={item.type} />
        ))}
      </div>
    )
  }
  return null
}
export default AnalysisHealth
