import {createStyles} from '@material-ui/core/styles'
import CheckedImage from 'assets/img/check-38x76.png'
import {Colors} from 'components/common/useBaseStyle'

const loginPageStyle = () =>
  createStyles({
    root: {
      backgroundColor: '#f4f4f7',
      transition: 'all 0.4s',
      padding: '0 15px',
      // @ts-ignore
      position: 'relative !important',
      margin: '0 auto',
      flex: 1,
      '& .Container': {
        width: 'auto',
        minWidth: 300,
        maxWidth: 400,
        position: 'relative',
        padding: 0,
        margin: '0 auto',
        textAlign: 'center',
        zIndex: 0,
        '@media (max-width: 768px)': {
          minWidth: 'auto',
          position: 'unset',
          transform: 'none',
          margin: '80px auto',
        },
        '& .Logo': {
          fontSize: 170,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          margin: 0,
          // marginBottom: 20,
          textAlign: 'center',
          '& img': {
            width: '50%',
            verticalAlign: 'middle',
            border: 0,
          },
        },
        '& .Header': {
          marginBottom: 30,
          '& .Title': {
            fontSize: 46,
            fontWeight: 'bold',
            color: '#8b8c8f',
            margin: 0,
            marginBottom: 20,
            '& .Upper': {
              verticalAlign: 'top',
              fontSize: 23,
            },
          },
          '& .Desc': {
            color: Colors.black,
            fontSize: 13,
            lineHeight: 1.36,
            textAlign: 'center',
          },
        },

        '& .Form': {
          marginBottom: 30,
          '& .FormText': {
            fontSize: 12,
            color: '#999',
            marginTop: 10,
          },
          '& .FormControl': {
            marginBottom: 15,
            '& input[type=text], & input[type=password]': {
              display: 'block',
              boxSizing: 'border-box',
              width: '100%',
              height: 40,
              lineHeight: 1.42857143,
              padding: '10px 15px 10px 15px',
              color: Colors.text.content,
              fontSize: 12,
              fontWeight: 400,
              outline: 'none',
              backgroundColor: Colors.white,
              backgroundImage: 'none',
              border: '1px solid #e5e6e7',
              borderRadius: 1,
              transition:
                'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
              boxShadow: 'none',
              '&:focus': {borderColor: Colors.primary},
            },
            '& .FormError': {
              color: Colors.error,
              textAlign: 'left',
              fontSize: 11,
              paddingTop: 3,
              paddingLeft: 2,
            },
          },
          '& button[type=submit]': {
            outline: 'none',
            cursor: 'pointer',
            background: '#2f4050',
            height: 50,
            color: Colors.white,
            fontSize: 15,
            borderRadius: 3,
            display: 'block',
            width: '100%',
            marginBottom: 10,
          },
          '& .Checkbox': {
            color: Colors.text.title,
            padding: 4,
            textAlign: 'left',
            zIndex: 9,
            marginBottom: 20,
            '& label': {
              display: 'block',
              whiteSpace: 'normal',
              position: 'relative',
              pointerEvents: 'auto',
              margin: 0,
              maxWidth: '100%',
              fontWeight: 'bold',
              '& > *': {
                display: 'inline-block',
                verticalAlign: 'text-top',
                fontWeight: 400,
              },
            },
            '& input[type=checkbox]': {
              zIndex: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0,
              width: 0,
              height: 0,
              pointerEvents: 'none',
              margin: '4px 0 0',
              lineHeight: 'normal',
              boxSizing: 'border-box',
              padding: 0,
              outline: 'none',
              '&:checked + .CheckMark': {
                backgroundPosition: '0 -16px',
              },
            },
            '& .CheckMark': {
              position: 'relative',
              left: 0,
              top: 0,
              width: 16,
              height: 16,
              boxSizing: 'border-box',
              background: `url(${CheckedImage}) 0 0 no-repeat`,
              backgroundSize: '16px 32px',
            },
            '& .CheckLabel': {
              margin: '0 0 0 8px',
              userSelect: 'none',
              fontSize: 13,
            },
          },
          '& .Buttons': {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            '& .Button': {
              fontSize: 12,
              fontWeight: 600,
              borderRadius: 3,
              display: 'inline-block',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              verticalAlign: 'middle',
              touchAction: 'manipulation',
              cursor: 'pointer',
              userSelect: 'none',
              backgroundImage: 'none',
              border: '1px solid transparent',
              '&.Large': {
                padding: '10px 16px',
                // fontSize: 18,
                lineHeight: 1.333333,
                // borderRadius: 6,
              },
              '&.Primary': {
                color: Colors.white,
                backgroundColor: Colors.primary,
                borderColor: Colors.primary,
              },
              '&.White': {
                color: Colors.black,
                fontWeight: 400,
                backgroundColor: Colors.white,
                borderColor: Colors.border,
              },
            },
            '& .FindButtons': {
              width: '100%',
              display: 'flex',
              gap: 10,
              '& .Button': {
                flex: 1,
              },
            },
          },
        },
        '& .Copyright': {
          color: Colors.nineX3,
          fontSize: 12,
          lineHeight: 1.36,
          textAlign: 'center',
        },
      },
      '& .Footer': {
        overflow: 'hidden',
        padding: '15px 20px',
        fontSize: 12,
        color: Colors.text.title,
        background: 'none repeat scroll 0 0 white',
        borderTop: '1px solid #e7eaec',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        '@media (max-width: 768px)': {
          position: 'unset',
          margin: '0 -15px',
        },
        '& .Left': {
          float: 'left',
          '& ul': {
            listStyle: 'none',
            margin: 0,
            padding: 0,
            '& li': {
              color: Colors.text.title,
              display: 'inline-block',
              marginRight: 10,
              textAlign: 'center',
              verticalAlign: 'middle',
              '& .MuiSvgIcon-root': {
                fontSize: 17,
                verticalAlign: 'bottom',
                paddingRight: 5,
              },
              '&.Copyright': {
                display: 'block',
                margin: '5px 0 0',
                textAlign: 'left',
              },
            },
          },
        },
        '& .Right': {
          position: 'absolute',
          right: 10,
          top: 15,
          '@media (max-width: 1199px)': {
            position: 'unset',
            margin: '0 0 10px',
            '& a:first-child': {
              padding: '0 10px 0 0',
            },
          },
          '& a': {
            backgroundColor: 'transparent',
            color: Colors.text.title,
            position: 'relative',
            padding: '0 10px',
            cursor: 'pointer',
            userSelect: 'none',
            zIndex: 3,
          },
          '& a:first-child::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: 0,
            width: 1,
            height: '100%',
            backgroundColor: '#dbdbdb',
          },
        },
      },
    },
  })

export default loginPageStyle
