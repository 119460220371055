import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import {ActivityItem, ActivitySearchCondition} from 'api/patientApi'
import openInNewTab from 'helpers/openInNewTab'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {
  getEegIndividualReportPath,
  getEegMciReportPath,
  getEegNormReportPath,
  getEegSummaryReportPath,
  getHrvIndividualReportPath,
  getHrvSummaryReportPath,
} from 'constants/RouteConstant'
import usePatientActivity from 'features/patient/usePatientActivity'
import {statusToI18nString} from 'helpers/analysisHelper'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import PatientActivitySearch from 'pages/Home/PatientActivitySearch'
import PatientActivitySearchType from 'pages/Home/PatientActivitySearchType'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {navigate} from 'AppHistory'

interface OptionSelectedProps {
  onSearchValue: (value?: string) => void
}

function OptionSelect({onSearchValue}: OptionSelectedProps) {
  const {t} = useTranslation()
  const {query} = usePatientActivity()

  const [optionOpen, setOptionOpen] = React.useState(false)

  const handleDetailOption = () => {
    setOptionOpen(false)
  }
  const handleOptionClick = () => {
    setOptionOpen((prev) => !prev)
  }
  const handleOptionChange = (value: string) => {
    onSearchValue(value)
  }

  return (
    <ClickAwayListener onClickAway={handleDetailOption}>
      <div>
        <ActionButton
          startIcon={<AddIcon />}
          onClick={() => handleOptionClick()}
        >
          {t('IDetailCondition')}
        </ActionButton>
        {optionOpen ? (
          <PatientActivitySearchType
            searched={query.type}
            onClosed={() => handleOptionClick()}
            onChanged={(searched) => handleOptionChange(searched)}
          />
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

interface TableProps {
  uuid: string
}

function TableToolbar({uuid}: TableProps) {
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const {query, onSearch} = usePatientActivity()

  const handleGoToTreatment = () => navigate('/org/treatments')

  const [parentSearchOption, setSearchOption] = React.useState<string>()
  const handleOptionChange = (value?: string) => {
    setSearchOption(value)
  }
  const handleSearch = (search: ActivitySearchCondition) => {
    if (parentSearchOption) {
      onSearch({
        ...query,
        uuid,
        type: parentSearchOption,
      })
    } else if (search.searchName && !search.searchValue) {
      onSearch({
        ...query,
        search: {searchName: 'FILE_NAME', searchValue: ''},
        uuid,
        type: 'ALL',
      })
    } else {
      onSearch({...query, search, uuid})
    }
  }

  return (
    <div>
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={classes.container}>
            <PatientActivitySearch
              onSearch={handleSearch}
              optionButtons={[
                <OptionSelect onSearchValue={handleOptionChange} />,
              ]}
            />
          </div>
          <div className={classes.containerOptional}>
            {/* hidden button */}
            {/* <ActionButton onClick={handleGoToTreatment}>
              {t('ITreatmentManagement')}
            </ActionButton> */}
          </div>
        </div>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: ActivityItem
  firstName: string
  lastName: string
}

function RenderTableRow({item, firstName, lastName}: RenderTableRowProps) {
  const {t} = useTranslation()

  const clientNameData = useMemo(() => {
    return encodeURIComponent(
      JSON.stringify({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      }),
    )
  }, [firstName, lastName])

  const clientName = useMemo(() => {
    return t('IGetFullName', {firstName, lastName})
  }, [firstName, lastName])

  const handleClick = () => {
    if (item.process !== 'SUCCESS') {
      return
    }

    if (item.analysisType === 'EEG') {
      openInNewTab(
        getEegIndividualReportPath(item.analysisId, undefined, clientNameData),
      )
      return
    }

    if (item.analysisType === 'EEG_STANDARD_SUMMARY') {
      openInNewTab(getEegSummaryReportPath(item.analysisId, clientName))
      return
    }

    if (item.analysisType === 'EEG_NORM') {
      openInNewTab(
        getEegNormReportPath(item.analysisId, undefined, clientNameData),
      )
    }

    if (item.analysisType === 'HRV') {
      openInNewTab(getHrvIndividualReportPath(item.analysisId, clientName))
      return
    }

    if (item.analysisType === 'HRV_STANDARD_SUMMARY') {
      openInNewTab(getHrvSummaryReportPath(item.analysisId, clientName))
    }

    if (item.analysisType === 'MCI') {
      openInNewTab(getEegMciReportPath(item.analysisId, clientName))
    }
  }

  return (
    <TableRow hover>
      <TableCell align='center'>{item.analysisType}</TableCell>
      <TableCell align='center'>{item.treatmentNumber}</TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(item.treatmentDate)}
      </TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.process))}
      </TableCell>
      <TableCell align='center'>
        <TableButton
          onClick={handleClick}
          disabled={item.process !== 'SUCCESS'}
        >
          {t('IReport')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

interface PageTableProps {
  patient: Patient
}

function PatientActivityTable({patient}: PageTableProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  const {
    loading,
    query,
    paging,
    pagingInfo,
    activities: items,
    onSearch,
  } = usePatientActivity()

  const setPageIndex = (page: number) =>
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })

  useEffect(() => {
    onSearch({
      ...query,
      uuid: patient.uuid,
      paging: {page: 0, size: 10},
    })
  }, [patient])

  const emptyRows = items === null ? 10 : 10 - items.length

  return (
    <TableContainer>
      <TableToolbar uuid={patient.uuid} />
      <Table className={classes.table} size='small'>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              {t('IAnalysisType')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMainCareNo_no')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IEtc')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((item) => (
              <RenderTableRow
                key={item.id}
                item={item}
                firstName={patient.firstName}
                lastName={patient.lastName}
              />
            ))}
          {items?.length === 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <div className={classes.empty}>{t('ISearchResultEmpty')}</div>
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={(event) => {
          onSearch({
            ...query,
            paging: {
              page: 0,
              size: parseInt(event.target.value as string, 10),
            },
          })
        }}
        isItemCountPerPageEnabled={false}
      />
    </TableContainer>
  )
}

export default PatientActivityTable
