import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  fetchIndividualEEGResult,
  FetchIndividualEEGResultRequest,
  TypeScreenGraph,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import ReportItem, {
  RenderingItem,
} from 'pages/Analysis/EEGIndividual/Report/ReportItem'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
} from 'constants/AnalysisConstant'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'

interface ReportProps {
  readonly id: number
  readonly version: string
  readonly clientName?: string | null
  readonly screen: TypeScreenGraph
}

/**
 * ICA IcaReport
 *
 * - progress가 SUCCESS -> 결과지
 * - progress가 ICA -> Ica 페이지
 * - progress가 TSR -> Timeseries-Rejection 페이지
 */
function ReportPage({id, version, clientName, screen}: ReportProps) {
  const {t, i18n} = useTranslation()

  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Individual
  const locale = i18n.language

  const params: FetchIndividualEEGResultRequest = {
    individualRequestIds: [id],
    codeTypeDivision,
    codeType,
    codeVersion: version ?? '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchIndividualEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [componentList, setComponentList] = useState<number[]>([])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {codeList, resultDetailList, resultInfo, componentList} = result
      const sortedCodeList = codeList.sort((code) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map((code) => {
        const items = resultDetailList.filter(
          (item) => item.analysisType === code.codeId,
        )
        return [code, items]
      })
      setRenderingItems(renderingItems)
      setResultInfo(resultInfo)
      setComponentList(componentList)
    }
  }, [asyncResult.result, locale])

  useEffect(() => {
    asyncResult.execute(params)
  }, [locale])

  return (
    <CardWithTitle title={t('IEEGIndividualReportVersion', {version})}>
      {/* height - header - footer - CardHeader & border - margin */}
      <div
        style={{
          minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {asyncResult.error && (
          <div>
            {asyncResult.error.message}
            <IconButton
              onClick={() => asyncResult.execute(params)}
              size='small'
            >
              <ReplayIcon />
            </IconButton>
          </div>
        )}
        <div style={{width: '100%'}}>
          {resultInfo !== undefined && (
            <ReportInfo {...resultInfo} clientName={clientName} eeg />
          )}
          {renderingItems !== undefined &&
            renderingItems.map((item, index) => (
              <ReportItem
                key={index}
                renderingItem={item}
                requestId={id}
                codeType={codeType}
                codeTypeDivision={codeTypeDivision}
                componentList={componentList}
                screen={screen}
              />
            ))}
        </div>
      </div>
    </CardWithTitle>
  )
}

export default ReportPage
