import {yupResolver} from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {QnaEditRequest} from 'api/qnaApi'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useStyles from 'components/Dialog/User/Style'
import useAuth from 'features/auth/useAuth'
import {
  closeQnaEditDialog,
  selectQnaEditDialogOpen,
} from 'features/modal/modalSlice'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  addQnaAnswerAction,
  fetchQnaDetailInfoAction,
  fetchQnaListAction,
  selectQna,
  updateQnaAction,
} from 'features/qna/qnaSlice'
import {useAppDispatch} from 'hooks'
import SelectQnaType from 'pages/CommunityPages/QuestionPage/QuestionEditDialog/SelectQnaType'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import * as yup from 'yup'

interface Props {
  id: number
}

export default function QuestionEditDialog(prop: Props) {
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {t} = useTranslation()
  const {id} = prop
  const [editOpen, setEditOpen] = React.useState(false)
  const [answerOpen, setAnswerOpen] = React.useState(false)
  const [answer, setAnswer] = React.useState('')
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const qnaEdit = useSelector(selectQnaEditDialogOpen)
  const qnaData = useSelector(selectQna)
  const inputClasses = useBoldInputStyle()
  const {user: currentUser} = useAuth()

  function checkAuthority(Authority: string): boolean {
    if (
      Authority === 'ROLE_COMPANY_SALES' ||
      Authority === 'ROLE_COMPANY_CS' ||
      Authority === 'ROLE_COMPANY_ADMIN'
    ) {
      return true
    }
    return false
  }

  const AddSchema = yup.object().shape({
    title: yup.string().min(1),
    content: yup.string().min(1).max(300, t('IValidationQnaContent')),
  })

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    setError,
  } = useForm<QnaEditRequest>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })

  const onSubmit = (data: QnaEditRequest) => {
    data.id = id
    // data.uid = currentUser?.uid ?? ''
    // submit object에서 undefined인 값 삭제
    if (answer === '') {
      setError('content', {type: 'custom', message: t('IContentsRequired')})
    }

    if (answer !== '' && data) {
      const submitOrgInfo = Object.fromEntries(
        Object.entries(data).filter(([v]) => v != null),
      )
      dispatch(updateQnaAction(submitOrgInfo))
      dispatch(addQnaAnswerAction({id, content: answer}))
      dispatch(
        fetchQnaListAction({
          paging: {
            page: 0,
            size: 10,
          },
        }),
      )
      dispatch(closeQnaEditDialog())
      reset()
    }
    if (!data) closeQnaEditDialog()
  }

  const handleClose = () => {
    dispatch(closeQnaEditDialog())
  }

  useEffect(() => {
    dispatch(fetchQnaDetailInfoAction(id))
  }, [id])

  useEffect(() => {
    if (checkAuthority(currentUser?.authority ?? '')) {
      setEditOpen(true)
      setAnswerOpen(false)
    } else {
      setEditOpen(false)
      setAnswerOpen(true)
    }
  }, [])

  useEffect(() => {
    if (checkAuthority(currentUser?.authority ?? '')) {
      setEditOpen(true)
      setAnswerOpen(false)
    } else {
      setEditOpen(false)
      setAnswerOpen(true)
    }
  }, [])

  useEffect(() => {
    setAnswer(qnaData.qnaData?.answer ?? '')
    setValue('title', qnaData.qnaData?.title)
    setValue('qnaType', qnaData.qnaData?.qnaType)
  }, [qnaData])

  return (
    <div>
      <Dialog open={qnaEdit} maxWidth='md' onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={classes.cardWrap}>
            <div className={classes.root}>
              {/* 상단 타이틀 컨테이너  */}
              <div className={classes.longTitleContainer}>
                <div className={classes.containerTitle}>
                  <div className={classes.closeButtonWrap}>
                    <IconButton
                      color='secondary'
                      aria-label='favorite'
                      className={classes.closeButton}
                      onClick={() => {
                        onNoticeDialogOpen({
                          title: '',
                          message: t('ICancleConfirm'),
                        }).then((result) => {
                          if (result.payload) {
                            handleClose()
                          }
                        })
                      }}
                    >
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                  {!checkAuthority(currentUser?.authority ?? '') && (
                    <Typography variant='h6' className={classes.title}>
                      {t('IInquireUpdate')}
                    </Typography>
                  )}
                  {checkAuthority(currentUser?.authority ?? '') && (
                    <Typography variant='h6' className={classes.title}>
                      {t('IInquireAnswer')}
                    </Typography>
                  )}
                </div>
              </div>
              <Divider className={classes.titleDivider} />
              <Typography className={classes.padding} />
              <Grid item xs={12} className={classes.scroll}>
                <CardContent className={classes.scroll}>
                  <div className={classes.contentContainer}>
                    <div>
                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IInquireType')}
                          </p>
                        </div>
                        <div style={{width: 750}}>
                          <Controller
                            name='qnaType'
                            control={control}
                            render={({field}) => (
                              <SelectQnaType readOnly={editOpen} {...field} />
                            )}
                          />
                        </div>
                      </div>

                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IInquireTitle')}
                          </p>
                        </div>
                        <div>
                          <Controller
                            name='title'
                            control={control}
                            rules={{required: false}}
                            render={({field}) => (
                              <input
                                id='title'
                                style={{width: '100%'}}
                                // defaultValue='112'
                                // placeholder={t('IFirstName')}
                                defaultValue={qnaData.qnaData?.title ?? ''}
                                // variant='outlined'
                                // fullWidth
                                // i18n.t('ITitleEmpty')
                                disabled={editOpen}
                                className={inputClasses.input}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IInquireContents')}
                          </p>
                        </div>
                        <div>
                          <Controller
                            name='content'
                            control={control}
                            rules={{required: false}}
                            render={({field}) => (
                              <textarea
                                style={{
                                  width: '100%',
                                  height: 250,
                                  resize: 'none',
                                }}
                                rows={10}
                                defaultValue={qnaData.qnaData?.content ?? ''}
                                disabled={editOpen}
                                className={inputClasses.input}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IQnaAnswerContents')}
                          </p>
                        </div>
                        <div style={{width: 750}}>
                          {qnaData.qnaData?.confirmedYn === 'Y' && (
                            <Controller
                              name='content'
                              control={control}
                              rules={{required: false}}
                              render={() => (
                                <textarea
                                  style={{
                                    width: '100%',
                                    height: 250,
                                    resize: 'none',
                                  }}
                                  defaultValue={qnaData.qnaData?.answer}
                                  onChange={({target: {value}}) =>
                                    setAnswer(value)
                                  }
                                  placeholder={t('IAnswerRequired')}
                                  rows={10}
                                  disabled={answerOpen}
                                  className={inputClasses.input}
                                />
                              )}
                            />
                          )}
                          {qnaData.qnaData?.confirmedYn === 'N' && (
                            <Controller
                              name='content'
                              control={control}
                              rules={{required: false}}
                              render={() => (
                                <textarea
                                  style={{
                                    width: '100%',
                                    height: 250,
                                    resize: 'none',
                                  }}
                                  onChange={({target: {value}}) =>
                                    setAnswer(value)
                                  }
                                  placeholder={t('IAnswerRequired')}
                                  rows={10}
                                  disabled={answerOpen}
                                  className={inputClasses.input}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <span className={classes.errorText}>
                        {errors.content && t(errors.content.message ?? '')}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Grid>
              <div className={classes.buttonContainerBgColor}>
                {!checkAuthority(currentUser?.authority ?? '') && (
                  <div className={classes.buttonContainer}>
                    <Button variant='contained' disableElevation type='submit'>
                      {t('IOk')}
                    </Button>
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Typography className={classes.padding} />
                    )}
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Button
                        variant='contained'
                        color='secondary'
                        disableElevation
                        onClick={handleClose}
                        className={classes.okButtonColor}
                        // type='submit'
                      >
                        {t('ICancel')}
                      </Button>
                    )}
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Typography className={classes.padding} />
                    )}
                  </div>
                )}
                {checkAuthority(currentUser?.authority ?? '') && (
                  <div className={classes.buttonContainer}>
                    <Button
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        onNoticeDialogOpen({
                          title: '',
                          message: t('ICancleConfirm'),
                        }).then((result) => {
                          if (result.payload) {
                            dispatch(closeQnaEditDialog())
                          }
                        })
                      }}
                    >
                      {t('ICancel')}
                    </Button>
                    <Typography className={classes.padding} />
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      type='submit'
                      className={classes.okButtonColor}
                    >
                      {t('IOk')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </form>
      </Dialog>
    </div>
  )
}
