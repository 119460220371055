import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import RegisterStyle from 'pages/CustomerManagement/MemberRegistration/Style'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  makeStyles,
} from '@material-ui/core'
import {getHealthProductsApi} from 'api/healthCareApi'
import {DATA_BODY_TABLE, DataTable} from 'pages/CustomerManagement/constants'

export interface SelectedListType {
  displayName: string
  productId: number
}

interface TableHospitalInformationProps {
  products: SelectedListType[]
  onCheckboxChange: (details: SelectedListType[]) => void
}

const useStyles = makeStyles(RegisterStyle)

const TableHospitalInformation = ({
  products,
  onCheckboxChange,
}: TableHospitalInformationProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const [selectedList, setSelectedList] = useState<SelectedListType[]>([])
  const [dataTable, setDataTable] = useState<DataTable[]>(DATA_BODY_TABLE)

  const onInitData = async () => {
    try {
      const responseProduct = await getHealthProductsApi()
      if (responseProduct.success && responseProduct.list) {
        const newDataTable: DataTable[] = JSON.parse(JSON.stringify(dataTable))
        newDataTable.forEach((item) => {
          const dataOrigin = responseProduct.list.filter(
            (product) => product.type === item.checkbox.type,
          )

          item.displayName = dataOrigin[0]?.displayName || ''
          item.checkbox.productId = dataOrigin[0]?.id
          item.checkbox.displayName = dataOrigin[0]?.displayName

          if (item.checkboxHrv) {
            const dataHrv = responseProduct.list.filter(
              (product) => product.type === item.checkboxHrv?.type,
            )
            item.checkboxHrv.productId = dataHrv[0]?.id
            item.checkboxHrv.displayName = dataHrv[0]?.displayName
          }
        })

        setDataTable(newDataTable)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeTypes = (productId: number | null, displayName: string) => {
    if (productId === null) return
    const itemChecked = selectedList.find(
      (selected) => selected.productId === productId,
    )
    if (itemChecked) {
      const newSelectedList = selectedList.filter(
        (item) => item.productId !== itemChecked.productId,
      )
      setSelectedList(newSelectedList)
      onCheckboxChange(newSelectedList)
    } else {
      const newSelectedList = [...selectedList, {productId, displayName}]
      setSelectedList(newSelectedList)
      onCheckboxChange(newSelectedList)
    }
  }

  const idsChecked = useMemo(
    () => selectedList.map((item) => item.productId),
    [selectedList],
  )

  useEffect(() => {
    onInitData()
  }, [])

  useEffect(() => {
    setSelectedList(products)
  }, [products])

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.inspectionTable}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.centerText} rowSpan={2}>
              {t('IExaminationProduct')}
            </TableCell>
            <TableCell className={classes.centerText} rowSpan={2}>
              {t('IDetailedIndicators')}
            </TableCell>
            <TableCell className={classes.centerText} colSpan={2}>
              {t('ISelectProduct')}
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.centerText}>{t('IQEEG')}</TableCell>
            <TableCell className={classes.centerText}>
              {t('IHRVStandard')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.map((product) => (
            <TableRow>
              <TableCell className={classes.centerText}>
                <div className={classes.titleProduct}>
                  {product.displayName}
                </div>
              </TableCell>
              <TableCell className={classes.cellDetail}>
                {product.details.map((item) => (
                  <div className={classes.detailContent}>{item}</div>
                ))}
              </TableCell>
              <TableCell
                className={classes.centerText}
                colSpan={product.checkbox.type === 'HrvOnly' ? 2 : 1}
              >
                <Checkbox
                  checked={idsChecked.includes(product.checkbox.productId || 0)}
                  onChange={() => {
                    onChangeTypes(
                      product.checkbox.productId,
                      product.checkbox.displayName,
                    )
                  }}
                />
              </TableCell>
              {product.checkbox.type !== 'HrvOnly' ? (
                <TableCell className={classes.centerText}>
                  <Checkbox
                    checked={idsChecked.includes(
                      product.checkboxHrv?.productId || 0,
                    )}
                    onChange={() => {
                      onChangeTypes(
                        product.checkboxHrv?.productId || null,
                        product.checkboxHrv?.displayName || '',
                      )
                    }}
                  />
                </TableCell>
              ) : (
                ''
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableHospitalInformation
