import Papa, {ParseConfig, ParseResult} from 'papaparse'

export interface CsvConfig<T>
  extends Omit<ParseConfig<T>, 'complete' | 'error'> {}

export const fetchCsv = <T>(url: string, config?: CsvConfig<T>) =>
  new Promise<ParseResult<T>>((resolve, reject) => {
    // @ts-ignore
    Papa.parse<T>(url, {
      complete(data) {
        return resolve(data)
      },
      error(error: Error) {
        return reject(error)
      },
      dynamicTyping: true,
      ...config,
    })
  })
