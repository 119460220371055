import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

const RegisterStyle = (theme: Theme) =>
  createStyles({
    topRoot: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
    },
    container: {
      minWidth: 1425,
      color: '#676A6C',
      zIndex: 10,
      position: 'relative',
      gap: 30,
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        flex: 1,
        width: '100%',
      },
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      '@media (max-width: 1770px)': {
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    root: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      width: 'calc(100% - 200px)',
    },
    padding: {
      padding: theme.spacing(3),
    },

    InvoiceSettingContainer: {
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& $notchedOutline': {
        borderColor: '#e0e0e0',
      },
      '&:hover $notchedOutline': {
        borderColor: '#e0e0e0',
      },
    },
    focused: {},
    notchedOutline: {},
    selectContainer: {
      flex: 1,
      width: '100%',
      height: 45,
    },
    industryContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      gap: '16px',
    },
    industryWrap: {
      width: '100%',
    },
    submitButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      '& button': {
        width: '100%',
        height: 55,
        fontSize: 18,
      },
    },
    cancelButton: {
      color: '#868e96',
      width: 50,
      maxWidth: 50,
    },
    closeModalButton: {
      color: '#fff',
      width: 50,
      maxWidth: 50,
    },
    bottomToolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      '& p': {
        color: '#4b4b4b',
        fontSize: 14,
      },
    },
    textButton: {
      fontSize: 14,
      textDecoration: 'underline',
      marginLeft: 10,
      color: '#3cbbbd',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    checkbox: {
      '& input[type="checkbox" i]': {
        cursor: 'pointer',
        border: '1px solid red',
      },
    },
    errorText: {
      fontSize: 11,
      marginTop: 2,
      color: '#d03c35',
    },
    formRegister: {
      width: '100%',
    },
    standardPlan: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #e4e7eb',
      width: 'max-content',
    },
    standardPlanTitle: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      display: 'flex',
      alignItems: 'center',
      padding: '0 20px',
    },
    standardPlanTitleV2: {
      width: 360,
      minWidth: 360,
      maxWidth: 360,
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
    },
    generalOnly: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #e4e7eb',
      borderBottom: '1px solid #e4e7eb',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    generalOnlyTitle: {
      minHeight: 45,
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
      fontWeight: 500,
      whiteSpace: 'pre-wrap',
    },
    generalOnlyContent: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #e4e7eb',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    generalOnlyContentTitle: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
    },
    contentForm: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #e4e7eb',
      width: '100%',
    },
    contentFormV2: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    contentElement: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #e4e7eb',
      width: '100%',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    contentElementTitle: {
      width: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    elementRows: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    contentElementRow: {
      display: 'flex',
      minHeight: 45,
      borderBottom: '1px solid #e4e7eb',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    contentElementCell: {
      display: 'flex',
      minWidth: 400,
      whiteSpace: 'nowrap',
    },
    elementCell: {
      display: 'flex',
      borderLeft: '1px solid #e4e7eb',
      width: '100%',
    },
    cellItem: {
      display: 'flex',
      alignItems: 'center',
      margin: 8,
    },
    cellItemTitle: {
      minWidth: 'min-content',
    },
    input: {
      border: '1px solid #ced4da',
      borderRadius: 3,
      color: '#495057',
      flex: 1,
      height: 28,
      margin: '2px 4px',
      maxWidth: 60,
      minWidth: 60,
      outline: 'none',
      padding: '3px 5px',
      'input::placeholder': {
        color: '#555',
        margin: 5,
      },
    },
    break: {
      flexBasis: '100%',
      height: 0,
    },
    invoiceOther: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #e4e7eb',
      borderTop: 'none',
      borderBottom: 'none',
      minWidth: 1424,
      maxWidth: 1424,
    },
    invoiceOtherItem: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #e4e7eb',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    invoiceOtherDescription: {
      display: 'flex',
      minHeight: 45,
      borderBottom: '1px solid #e4e7eb',
      fontWeight: 500,
    },
    invoiceOtherBlank: {
      display: 'flex',
      width: 180,
      borderRight: '1px solid #e4e7eb',
    },
    invoiceOtherTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    invoiceOtherSpan: {whiteSpace: 'pre-wrap'},
    descriptionLabel: {display: 'flex', alignItems: 'center', margin: '0 8px'},
    invoiceOtherForm: {
      display: 'flex',
      minHeight: 45,
    },
    formElement: {
      display: 'flex',
      alignItems: 'center',
      width: 180,
      borderRight: '1px solid #e4e7eb',
    },
    invoiceOtherInput: {display: 'flex', alignItems: 'center', margin: '0 8px'},
    searchSelect: {
      width: 130,
      height: 40,
    },
    invoiceAction: {
      width: '100%',
      marginBottom: 10,
      display: 'flex',
      gap: 20,
      alignItems: 'center',
    },
    cardWrap: {
      overflow: 'auto',
    },
    longTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'space-between',
      color: 'primary',
      background: 'white',
      paddingBottom: 10,
    },
    containerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      marginLeft: 30,
      marginTop: 20,
      marginRight: 20,
    },
    closeButtonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignContent: 'start',
      '& .MuiTypography-h6': {
        margin: 0,
        lineHeight: 0,
        fontWeight: 700,
      },
    },
    closeButton: {
      backgroundColor: '#f4f4f4',
      width: 20,
      height: 20,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: '#c5c5c5',
    },
    titleDialog: {
      fontSize: 22,
      color: '#4b4b4b',
      fontWeight: 500,
    },
    titleDivider: {
      backgroundColor: '#f1f1f1',
    },
    buttonContainerBgColor: {
      backgroundColor: '#f4f4f4',
      display: 'flex',
      justifyItems: 'center',
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      padding: 10,
      width: 520,
      justifyContent: 'flex-end',
      '& button': {
        minWidth: 100,
        padding: '8px 0',
        flex: 1,
        margin: '0 10px',
        fontWeight: 600,
      },
      '& button:nth-child(1)': {
        marginLeft: 20,
      },
      '& button:nth-child(3)': {
        marginRight: 20,
      },
      '& .MuiButton-containedSecondary': {
        color: 'white',
      },
    },
    okButtonColor: {
      width: 120,
      maxWidth: 120,
    },
    containerNewInvoice: {
      display: 'flex',
      justifyContent: 'center',
      padding: 40,
      gap: 20,
      flexDirection: 'column',
      alignItems: 'center',
    },
    inputVersion: {
      border: '1px solid #ced4da',
      borderRadius: 3,
      color: '#495057',
      flex: 1,
      height: 36,
      margin: '2px 4px',
      maxWidth: 200,
      minWidth: 200,
      outline: 'none',
      padding: '3px 5px',
      'input::placeholder': {
        color: '#555',
        margin: 5,
      },
    },
    contentNewInvoice: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 20,
      width: '100%',
      justifyContent: 'center',
    },
    titleOldestVersion: {
      width: '100%',
      paddingLeft: 90,
    },
  })

export default RegisterStyle
