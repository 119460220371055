import {ConfirmType} from 'features/analysis/analysisSlice'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const getHeaderI18nString = (type?: ConfirmType): string => {
  switch (type) {
    case 'eeg_norm':
      return 'INoticeTitle'
    case 'eeg_mci':
      return 'INoticeTitle'
    case 'eeg_summary':
      return 'INoticeTitle'
    case 'eeg_summary_premium':
      return 'INoticeTitle'
    default:
      return 'INoticeTitle'
  }
}

interface DialogHeaderProps {
  type?: ConfirmType
}

function DialogHeader({type}: DialogHeaderProps) {
  const {t} = useTranslation()
  const i18nString = getHeaderI18nString(type)

  useEffect(() => {}, [i18nString])

  return <h3 className='header'>{t('IPreAlert')}</h3>
}

export default DialogHeader
