import {selectTime, setTime} from 'features/analysis/reloadImgSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useHrvSummary() {
  const dispatch = useAppDispatch()
  const time = useAppSelector(selectTime)
  const reloadImg = () => dispatch(setTime())

  return {
    time,
    reloadImg,
  }
}
