import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

const RegisterStyle = (theme: Theme) =>
  createStyles({
    topRoot: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
    },
    container: {
      minWidth: 1425,
      color: '#676A6C',
      zIndex: 10,
      position: 'relative',
      gap: 30,
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        flex: 1,
        width: '100%',
      },
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      '@media (max-width: 1770px)': {
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    root: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      width: 'calc(100% - 200px)',
    },
    padding: {
      padding: theme.spacing(3),
    },

    InvoiceSettingContainer: {
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& $notchedOutline': {
        borderColor: '#e0e0e0',
      },
      '&:hover $notchedOutline': {
        borderColor: '#e0e0e0',
      },
    },
    focused: {},
    notchedOutline: {},
    selectContainer: {
      flex: 1,
      width: '100%',
      height: 45,
    },
    industryContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      gap: '16px',
    },
    industryWrap: {
      width: '100%',
    },
    submitButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      '& button': {
        width: '100%',
        height: 55,
        fontSize: 18,
      },
    },
    cancelButton: {
      color: '#868e96',
    },
    bottomToolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      '& p': {
        color: '#4b4b4b',
        fontSize: 14,
      },
    },
    textButton: {
      fontSize: 14,
      textDecoration: 'underline',
      marginLeft: 10,
      color: '#3cbbbd',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    checkbox: {
      '& input[type="checkbox" i]': {
        cursor: 'pointer',
        border: '1px solid red',
      },
    },
    errorText: {
      fontSize: 11,
      marginTop: 2,
      color: '#d03c35',
    },
    invoiceView: {
      width: '100%',
    },
    invoiceAction: {
      width: '100%',
      marginBottom: 10,
      display: 'flex',
      gap: 20,
      alignItems: 'center',
    },
    searchSelect: {
      width: 130,
      height: 40,
    },
    invoiceViewInfo: {
      width: '100%',
      maxWidth: 1280,
    },
    titleInvoice: {
      fontSize: 64,
      fontWeight: 900,
      margin: '40px 0',
      textAlign: 'center',
      letterSpacing: '24px !important',
    },
    titleMonthInvoice: {
      fontSize: 64,
      fontWeight: 900,
    },
    defaultBilling: {},
    defaultBillingTitle: {fontWeight: 700},
    defaultBillingTable: {
      marginTop: 10,
    },
    header: {
      fontWeight: 700,
      border: '1px solid rgba(224, 224, 224, 1)',
      textAlign: 'center',
      height: 43,
      padding: 8,
    },
    headerMax: {
      fontWeight: 700,
      border: '1px solid rgba(224, 224, 224, 1)',
      textAlign: 'center',
      height: 56,
      padding: 8,
    },
    cell: {
      border: '1px solid rgba(224, 224, 224, 1)',
      textAlign: 'center',
      height: 43,
      padding: 8,
    },
    invoiceCommonInfo: {
      display: 'flex',
      gap: 30,
    },
    textBold: {
      fontWeight: 700,
    },
    invoiceDetailInfo: {
      marginTop: 30,
    },
    verticalText: {
      writingMode: 'vertical-lr',
      whiteSpace: 'break-spaces',
      minHeight: 'max-content',
    },
    inputDescription: {
      minHeight: 150,
      width: '100%',
      border: '1px solid',
    },
    inputTotal: {
      minHeight: 50,
      width: '100%',
      textAlign: 'center',
      border: '1px solid',
    },

    backGroundHead: {
      background: '#f4f4f7',
      fontWeight: 700,
    },
    noColumn: {
      width: 100,
    },
  })

export default RegisterStyle
