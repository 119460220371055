import {RootState} from 'store'
import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'

import {
  MultiTherapyPrescriptionListApi,
  MultiTherapyPrescriptionDeleteApi,
  MultiTherapyPrescriptionUpdateApi,
  MultiTherapyPrescriptionRegApi,
  MultiTherapyPrescriptionListResp,
} from 'api/therapyApi'

/**
 *  Order of Protocol in Prescription from response is unordered => Re order it
 */
function reOrderProtocol(prescriptions: MultiTherapyPrescriptionListResp[]) {
  const sorted = prescriptions.map((prescription) => {
    const sortedProtocols = prescription.dataList.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    return {
      ...prescription,
      dataList: sortedProtocols,
    }
  })
  return sorted
}

export const fetchElectrodesByGroup = createAsyncThunk(
  'api/get/customer/pbm-preset/electrode',
  async (obj: any, {rejectWithValue}) => {
    try {
      const {uid, customerId, groupId, list, indexToBeUpdated = 0} = obj

      console.log(`current : ${JSON.stringify(obj)}`)

      if (list.length === 0 && !groupId) {
        return {list, indexToBeUpdated}
      }

      let newList = []
      // const result = await MultiTherapyPrescriptionOneApi({
      //   uid,
      //   uuid: customerId,
      //   groupId,
      // })

      // console.log(`current api res : ${JSON.stringify(result)}`)

      const sortedElectrodeListResult = list[indexToBeUpdated].dataList
        ? list[indexToBeUpdated].dataList?.sort(
            (a: any, b: any) => a.seq - b.seq,
          )
        : []

      newList = [...list]

      newList[indexToBeUpdated] = {
        ...list[indexToBeUpdated],
        // dataList: resultWithFrequencyAndDuration,
      }

      return {list: newList, indexToBeUpdated}
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchPBMGroupList = createAsyncThunk(
  'api/get/customer/pbm-preset',
  async (
    {uid, customerId, indexToBeUpdated}: any,
    {dispatch, rejectWithValue, getState},
  ) => {
    try {
      console.log('HELLO')

      const result = await MultiTherapyPrescriptionListApi({
        uuid: customerId,
        uid,
      })

      const currentState: any = getState()
      const groupAddedLocally = currentState?.nirCare?.Groups.filter(
        (grp: GroupType) => {
          return grp.status === 'ADD'
        },
      )
      const deletedArrayList = currentState.nirCare.deletedGroupArray
      // Order of Protocol in Prescription from response is unordered
      if (result.list) {
        result.list = reOrderProtocol(result.list)
      }
      const resultList = result.list
        ? result.list.concat(groupAddedLocally)
        : []

      const filteredFinalArr = resultList.filter((group) => {
        return !deletedArrayList.some(
          (removeGrp: GroupType) => removeGrp.label === group.label,
        )
      })

      if (result.list && result.list?.length > 0) {
        dispatch(
          fetchElectrodesByGroup({
            uid,
            customerId,
            groupId: result.list[indexToBeUpdated].id,
            list: resultList,
            indexToBeUpdated,
            status: 'UPDATE',
          }),
        )
      } else if (result.list && result.list?.length === 0) {
        dispatch(
          fetchElectrodesByGroup({
            uid,
            customerId,
            groupId: null,
            list: resultList,
            indexToBeUpdated: 0,
          }),
        )
      }
      return resultList
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)
export const addNewGroupThunk = createAsyncThunk(
  'api/post/customer/group/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await MultiTherapyPrescriptionRegApi({
        uid: data.uid,
        uuid: data.uuid,
        label: data.label,
        dataList: data.dataList,
      })

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const updateGroupListThunk = createAsyncThunk(
  'api/post/customer/put/group/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await MultiTherapyPrescriptionRegApi({
        uid: data.uid,
        uuid: data.uuid,
        label: data.label,
        dataList: data.dataList,
      })

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const deleteGroupThunk = createAsyncThunk(
  'api/delete/customer/group/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await MultiTherapyPrescriptionDeleteApi({
        ...data,
      })

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)
// export const deleteSetThunk = createAsyncThunk(
//   'api/delete/customer/set/pbm-preset',
//   async (data: any, {dispatch, rejectWithValue}) => {
//     try {
//       const result = await deleteTherapySet({
//         ...data,
//       })
//
//       return result
//     } catch (err) {
//       return rejectWithValue(err.message)
//     }
//   },
// )

export const changeNameOfAGroupThunk = createAsyncThunk(
  'api/post/customer/put/group/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      console.log(`update name action : ${JSON.stringify(data)}`)
      const result = await MultiTherapyPrescriptionUpdateApi(data.groupId, {
        uid: data.uid,
        uuid: data.uuid,
        dataList: data.protocolList,
        label: data.label,
      })
      console.log(`update name action result : ${JSON.stringify(result)}`)

      dispatch(
        fetchPBMGroupList({
          uid: data.uid,
          customerId: data.customerId,
          indexToBeUpdated: data.indexToBeUpdated,
        }),
      )

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const addNewSetToAGroupThunk = createAsyncThunk(
  'api/post/customer/add/set/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await MultiTherapyPrescriptionUpdateApi(data.groupId, {
        ...data,
      })

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const updateSetThunk = createAsyncThunk(
  'api/post/customer/update/set/pbm-preset',
  async (data: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await MultiTherapyPrescriptionUpdateApi(data.groupId, {
        ...data,
      })

      return result
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

// export const addNewGroups = createAsyncThunk(
//   'api/post/customer/groups/pbm-preset',
//   async (data: any, {dispatch, rejectWithValue}) => {
//     try {
//       const {customerId, ...rest} = data
//       dispatch(addNewGroup(customerId, ...rest))
//       return result.list
//     } catch (err) {
//       return rejectWithValue(err.message)
//     }
//   },
// )

export interface NirCareLedState {
  loading: boolean
  error: any
  Groups: GroupType[]
  selectedGroup: GroupType
  selectedSet: Set2
  deletedGroupArray: any[]
  deletedSetArray: any[]
  isAnyChange: boolean
  canPrescribe: boolean
}
export interface ElectrodeType {
  c3: 'Y' | 'N'
  c4: 'Y' | 'N'
  cz: 'Y' | 'N'
  f3: 'Y' | 'N'
  f4: 'Y' | 'N'
  f7: 'Y' | 'N'
  f8: 'Y' | 'N'
  fp1: 'Y' | 'N'
  fp2: 'Y' | 'N'
  fz: 'Y' | 'N'
  o1: 'Y' | 'N'
  o2: 'Y' | 'N'
  p3: 'Y' | 'N'
  p4: 'Y' | 'N'
  pz: 'Y' | 'N'
  t3: 'Y' | 'N'
  t4: 'Y' | 'N'
  t5: 'Y' | 'N'
  t6: 'Y' | 'N'
  seq?: string
  frequency?: number
  settingTime?: number
  therapyMode?: 'THERAPY' | 'PRESCRIPTION'
  label?: string
}

// export interface Set {
//   set?: string
//   name?: string
//   setID?: string
//   status?: string
//   frequency?: number
//   duration?: number
//   settingTime?: number
//   electrode: ElectrodeType
//   pbmType?: 'THERAPY'
//   presetDataId?: string
// }

export interface Set2 extends ElectrodeType {
  status?: string
  label?: string
}

export interface GroupType {
  // presetList?: Set2[]
  protocolList: Set2[]
  label?: string
  id?: string
  uid?: string
  uuid?: string
  status?: string
}

const initialSet: Set2 = {
  status: 'ADD',
  c3: 'N',
  c4: 'N',
  cz: 'N',
  f3: 'N',
  f4: 'N',
  f7: 'N',
  f8: 'N',
  fp1: 'N',
  fp2: 'N',
  fz: 'N',
  o1: 'N',
  o2: 'N',
  p3: 'N',
  p4: 'N',
  pz: 'N',
  t3: 'N',
  t4: 'N',
  t5: 'N',
  t6: 'N',
  frequency: 10,
  settingTime: 300,
  therapyMode: 'PRESCRIPTION',
  label: 'Set 1',
}
/*
const initialSet: Set = {
  name: 'Set 1',
  pbmType: 'THERAPY',
  frequency: 10,
  duration: 300,
  status: 'ADD',
  electrode: {
    c3: false,
    c4: false,
    cz: false,
    f3: false,
    f4: false,
    f7: false,
    f8: false,
    fp1: false,
    fp2: false,
    fz: false,
    o1: false,
    o2: false,
    p3: false,
    p4: false,
    pz: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
  },
}
*/

const getSetByName = (nameOfSet: string) => {
  return {...initialSet, name: nameOfSet}
}

const initialState: NirCareLedState = {
  loading: false,
  error: null,
  Groups: [
    {
      protocolList: [initialSet],
      // presetList: [initialSet],
      // status: 'ADD',
      // label: 'Group 1',
    },
  ],
  selectedGroup: {} as GroupType,
  // {
  //   set: [initialSet],
  //   presetList: [initialSet],
  //   status: 'ADD',
  //   name: 'Group 1',
  // },
  selectedSet: {} as Set2,
  deletedGroupArray: [],
  deletedSetArray: [],
  isAnyChange: false,
  canPrescribe: true,
}

const slice = createSlice({
  name: 'nirCareLedSlice',
  initialState,
  reducers: {
    addGroup(state, action) {
      state.Groups = [...state.Groups, action.payload]
      state.selectedGroup = action.payload
      state.selectedSet = action.payload.protocolList
      state.isAnyChange = true
    },
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload
    },
    reInitializeState(state, action) {
      state.selectedGroup = {} as GroupType
      state.selectedSet = {} as Set2
      state.Groups = []
    },
    setSelectedSet(state, action) {
      state.selectedSet = action.payload
    },
    setCanPrescribe(state, action) {
      state.canPrescribe = action.payload
    },
    addToDeleteSetArray(state, action) {
      state.deletedSetArray = [...state.deletedSetArray, action.payload]
    },
    removeAGroup(state, action) {
      const {group} = action.payload

      const filteredArray = current(state).Groups.filter(function (obj) {
        return obj.label !== group.label
      })

      if (group.status !== 'ADD') {
        state.deletedGroupArray = [...state.deletedGroupArray, group]
      }
      state.Groups = filteredArray
      state.selectedGroup =
        state.Groups.length > 0 ? {...state.Groups[0]} : ({} as GroupType)

      state.selectedSet =
        state.Groups.length > 0
          ? // @ts-ignore
            {...state.Groups[0].protocolList[0]}
          : ({} as Set2)
      state.isAnyChange = true
    },
    removeASet(state, action) {
      const {group: selectedGroup, set: selectedSet} = action.payload
      let tempGroup

      const filteredSetArray = selectedGroup.protocolList.filter(
        (localSet: Set2) => {
          return localSet.label !== state.selectedSet.label
        },
      )
      const updatedGroupArray = state.Groups.map((group) => {
        if (group.label === selectedGroup.label) {
          tempGroup = {
            ...group,
            protocolList: filteredSetArray,
            // presetList: filteredSetArray,
          }
          return tempGroup
        }
        return {...group}
      })

      state.Groups = updatedGroupArray
      // eslint-disable-next-line prefer-destructuring
      state.selectedSet = filteredSetArray[0]
      if (tempGroup) {
        state.selectedGroup = tempGroup
      }
      state.isAnyChange = true
    },
    changeNameOfAAddedGroup(state, action) {
      const {newName} = action.payload

      state.Groups = state.Groups.map((group) => {
        if (group.label === state.selectedGroup.label) {
          return {...group, label: newName}
        }
        return {...group}
      })

      state.selectedGroup = {...state.selectedGroup, label: newName}
    },
    addSetToAGroup(state, action) {
      const {localGroup, setToAdd} = action.payload

      // to update the Group Array
      console.log(`addSetToAGroup : ${JSON.stringify(setToAdd)}`)
      const updatedArray = state.Groups?.map((group) =>
        group.label === localGroup.label
          ? {
              ...group,
              // @ts-ignore
              protocolList: [...group.protocolList, setToAdd],
              // @ts-ignore
              // presetList: [...group.dataList, setToAdd],
              status: group.status === 'ADD' ? 'ADD' : 'UPDATE',
            }
          : {...group},
      )

      state.Groups = updatedArray
      // to update the selected group
      if (
        state.selectedGroup.protocolList &&
        state.selectedGroup.label === localGroup.label
      ) {
        const updatedSetInSelectedGroup = {
          ...state.selectedGroup,
          protocolList: [...state.selectedGroup.protocolList, setToAdd],
          // presetList: [...state.selectedGroup.dataList, setToAdd],
          status: state.selectedGroup.status === 'ADD' ? 'ADD' : 'UPDATE',
        }

        state.selectedGroup = updatedSetInSelectedGroup
      }
      state.isAnyChange = true
    },
    updateElectrode(state, action) {
      let tempSet
      let localSelectedData
      let tempGroup
      const {electrodeName, electrodeValue} = action.payload
      console.log(`elec ${JSON.stringify(action.payload)}`)

      const updatedArray = state.Groups?.map((group) => {
        if (group.label === state.selectedGroup.label) {
          tempSet = current(group).protocolList?.map((set) => {
            if (set.label === state.selectedSet?.label) {
              localSelectedData = {
                ...set,
                [electrodeName]: electrodeValue,
                status: set.status === 'ADD' ? 'ADD' : 'UPDATE',
              }
              return localSelectedData
            }
            return {...set}
          })
          tempGroup = {
            ...group,
            protocolList: tempSet,
            // presetList: tempSet,
            status: group.status === 'ADD' ? 'ADD' : 'UPDATE',
          }
          // this.setSelectedGroup = tempGroup
          return tempGroup
        }
        return current(group)
      })
      if (updatedArray) {
        state.Groups = updatedArray
        state.selectedGroup = tempGroup as any
      }
      if (localSelectedData) {
        state.selectedSet = localSelectedData
      }
      state.isAnyChange = true
    },
    selectDeselectAllElectrode(state, action) {
      let tempSet
      let localSelectedData
      let tempGroup
      const {electrodeValue} = action.payload

      const updatedArray = state.Groups?.map((group) => {
        if (group.label === state.selectedGroup.label) {
          tempSet = current(group).protocolList?.map((set) => {
            if (set.label === state.selectedSet?.label) {
              const tmptmp = Object.keys(set)
                .filter((key) => {
                  return (
                    key !== 'frequency' &&
                    key !== 'label' &&
                    key !== 'name' &&
                    key !== 'settingTime' &&
                    key !== 'seq' &&
                    key !== 'therapyMode' &&
                    key !== 'status'
                  )
                })
                .map((key) => [key, electrodeValue])

              localSelectedData = {
                ...set,
                ...Object.fromEntries(tmptmp),
              }
              return localSelectedData
            }
            return {...set}
          })
          tempGroup = {...group, protocolList: tempSet}
          return tempGroup
        }
        return current(group)
      })
      if (updatedArray) {
        state.Groups = updatedArray as any
        state.selectedGroup = tempGroup as any
      }
      if (localSelectedData) {
        state.selectedSet = localSelectedData
      }
      state.isAnyChange = true
    },
    updateFrequencyOfASet(state, action) {
      let tempSet
      let localSelectedData
      let tempGroup
      const {frequency} = action.payload
      const updatedArray = state.Groups?.map((group) => {
        if (group.label === state.selectedGroup.label) {
          tempSet = current(group).protocolList?.map((set) => {
            if (set.label === state.selectedSet?.label) {
              localSelectedData = {
                ...set,
                frequency,
                status: set.status === 'ADD' ? 'ADD' : 'UPDATE',
              }
              return localSelectedData
            }
            return {...set}
          })
          tempGroup = {
            ...group,
            protocolList: tempSet,
            // presetList: tempSet,
            status: group.status === 'ADD' ? 'ADD' : 'UPDATE',
          }
          state.selectedGroup = {...tempGroup}
          return tempGroup
        }
        return current(group)
      })
      if (updatedArray) {
        state.Groups = updatedArray
        state.selectedGroup = tempGroup as any
      }
      if (localSelectedData) {
        state.selectedSet = localSelectedData
      }
      state.isAnyChange = true
    },
    updateDurationOfASet(state, action) {
      let tempSet
      let localSelectedData
      let tempGroup
      const {settingTime} = action.payload
      const updatedArray = state.Groups?.map((group) => {
        if (group.label === state.selectedGroup.label) {
          tempSet = current(group).protocolList?.map((set) => {
            if (set.label === state.selectedSet?.label) {
              localSelectedData = {
                ...set,
                settingTime,
                status: set.status === 'ADD' ? 'ADD' : 'UPDATE',
              }
              return localSelectedData
            }
            return {...set}
          })
          tempGroup = {
            ...group,
            protocolList: tempSet,
            // presetList: tempSet,
            status: group.status === 'ADD' ? 'ADD' : 'UPDATE',
          }
          state.selectedGroup = {...tempGroup}
          return tempGroup
        }
        return current(group)
      })
      if (updatedArray) {
        state.Groups = updatedArray
        state.selectedGroup = tempGroup as any
      }
      if (localSelectedData) {
        state.selectedSet = localSelectedData
      }
      state.isAnyChange = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchElectrodesByGroup.pending, (state, action) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchElectrodesByGroup.fulfilled, (state, action) => {
      state.loading = false
      const {list, indexToBeUpdated} = action.payload

      console.log(
        'fetchElectrodesByGroup.fulfilled reducer {}',
        JSON.stringify(list),
      )

      const updatedList = list?.map((group: any) => {
        const {dataList, label} = group
        const updatedSet = dataList?.map((obj: any) => ({
          ...obj,
          label: obj.label,
        }))
        group.dataList = null

        return {
          ...group,
          protocolList: updatedSet,
          // presetList: updatedSet,
          label,
          status: 'UPDATE',
        }
      })
      // const {t} = useTranslation()
      //
      // const findGroupIndex = (): number => {
      //   const index = state.Groups.findIndex(
      //     (group) => group.label === state.selectedGroup.label,
      //   )
      //   return index
      // }

      const groupName = `grp_${Date.now().toString(36)}`
      const setName = `set_${Date.now().toString(36)}`
      // const groupName = `${t('IPrescription')} ${findGroupIndex() + 1}`
      // const setName = `${t('IProtocol')} ${
      //   state.selectedGroup.protocolList.length + 1
      // }`
      if (updatedList.length === 0) {
        state.Groups = [
          {
            protocolList: [getSetByName(setName)],
            // presetList: [getSetByName(setName)],
            status: 'ADD',
            label: groupName,
          },
        ]
        state.selectedGroup = {
          protocolList: [getSetByName(setName)],
          status: 'ADD',
          label: groupName,
        }
        state.selectedSet = getSetByName(setName)
      } else if (updatedList.length > 0) {
        state.Groups = updatedList ?? []
        state.selectedGroup =
          updatedList.length > 0
            ? updatedList[indexToBeUpdated]
            : {
                protocolList: [getSetByName(setName)],
                status: 'ADD',
                name: groupName,
              }
        state.selectedSet =
          updatedList.length > 0 &&
          updatedList[indexToBeUpdated].protocolList.length > 0
            ? updatedList[indexToBeUpdated].protocolList[0]
            : getSetByName(setName)
      }
    })
    builder.addCase(fetchElectrodesByGroup.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const {
  addGroup,
  setSelectedGroup,
  addSetToAGroup,
  setSelectedSet,
  updateElectrode,
  updateFrequencyOfASet,
  updateDurationOfASet,
  selectDeselectAllElectrode,
  changeNameOfAAddedGroup,
  removeAGroup,
  removeASet,
  addToDeleteSetArray,
  reInitializeState,
  setCanPrescribe,
} = slice.actions

export const selectLoading = (state: RootState) => state.nirCare.loading
export const selectError = (state: RootState) => state.nirCare.error
export const selectGroups = (state: RootState) => state.nirCare.Groups
export const toBeDeletedGroups = (state: RootState) =>
  state.nirCare.deletedGroupArray
export const toBeDeletedSets = (state: RootState) =>
  state.nirCare.deletedSetArray
export const selectSelectedGroup = (state: RootState) =>
  state.nirCare.selectedGroup
export const selectSelectedSet = (state: RootState) => state.nirCare.selectedSet
export const isAnyChange = (state: RootState) => state.nirCare.isAnyChange
export const canPrescribe = (state: RootState) => state.nirCare.canPrescribe
