import {
  deleteAllPackagePriceApi,
  discountAllPackageApi,
  paidMonthListApi,
  Price,
} from 'api/paymentApi'
import TableButton from 'components/atoms/Buttons/TableButton'
import SalesOrganizationEditDialog from 'components/Dialog/Sales/Organization/Edit'
import SalesOrganizationReadDialog from 'components/Dialog/Sales/Organization/Read'
import Pagination from 'components/molcules/Pagination'
import SelectOrder, {SelectContent} from 'components/Select/SelectOrder'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import SubTitle from 'components/Text/SubTitle'
import {openSalesOrgReadDialog} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrganization from 'features/org/useOrganization'
import {
  fetchOrganizationPackageAction,
  searchSalesInstituteAction,
  selectSalesInstitutes,
} from 'features/sales/salesSlice'
import {dateToDashString} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import RegisteredCard from '../RegisteredCard'
import UsageHistory from '../UsageHistory'
import OrganizationPackageTable from './OrganizationPackageTable'

const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
  }
`
const DiscountButton = styled.button<{backgroundColor: string}>`
  border-collapse: collapse;
  border-radius: 5px;
  background: ${(props) => props.backgroundColor};
  color: #fff;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 15px 10px;
  font-size: 14px;
`

const TextInput = styled.input`
  border-radius: 3px;
  border: 1px solid #b7b9bc;
  height: 40px;
`

const HiddenDiv = styled.div<{hidden: boolean}>`
  display: ${(props) => (props.hidden ? 'flex' : 'none')};
  flex-direction: column;
`

export interface PackageList {
  id: number
  appearanceName: string
  availableNumberOfTimes: number
  expiresOn: number
  isDefault: boolean
  price: Price
}

export interface SelectPackage {
  packageId: string
  isSelected: boolean
}

export interface DiscountSectionProps {
  title: string
  oid: string
}

const DiscountSection = (props: DiscountSectionProps) => {
  const {title, oid} = props

  return (
    <div style={{width: '100%'}}>
      <SubTitle>{title}</SubTitle>
      <div style={{display: 'flex', gap: 20}}>
        <OrganizationPackageTable oid={oid} />
      </div>
    </div>
  )
}

const SearchInstituteTable = () => {
  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IOrgNameShort'},
    {id: '2', label: 'IEmail'},
    {id: '3', label: 'IPhone'},
    {id: '4', label: 'IDetailInfo'},
  ]

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const items = useSelector(selectSalesInstitutes)

  const [isClickedCell, setIsClickedCell] = React.useState(false)
  const [selectedOid, setSelectedOid] = React.useState('')
  const [selectedOidName, setSelectedOidName] = React.useState('')

  const [discountPercent, setDiscountPercent] = React.useState(0)

  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const handleDetail = () => dispatch(openSalesOrgReadDialog())
  const makeCurrentPage = () => {
    if (items?.page !== 0 && items?.page) return items?.page - 1
    return 0
  }
  const totalPages = (totalCount: number, size: number) =>
    Math.ceil(totalCount / size)
  const fetchInstitutes = (page: number) =>
    dispatch(searchSalesInstituteAction({page, size: 5}))
  const deleteAllPackage = async () => {
    try {
      const result = await deleteAllPackagePriceApi({packageIds: null})
      if (!result) onSuccessModalOpen(t('IProcessSuccess'))
    } catch (error) {
      onFailureModalOpen(t('IProcessFail'))
    }
  }
  const discountAllPackages = async (oid: string, percent: number) => {
    if (percent >= 0 && percent <= 100) {
      try {
        const result = await discountAllPackageApi({orgOid: oid, rate: percent})
        if (!result) onSuccessModalOpen(t('IProcessSuccess'))
      } catch (error) {
        onFailureModalOpen(t('IProcessFail'))
      }
    } else {
      onFailureModalOpen(t('IPercentValid'))
    }
  }

  const fetchEnablePackage = async (oid: string) => {
    try {
      await dispatch(fetchOrganizationPackageAction(oid))
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
  const {organization} = useOrganization()
  const [paidMonths, setPaidMonths] = React.useState<SelectContent[]>([])
  const getPaidMonthList = async () => {
    try {
      const result = await paidMonthListApi(organization?.oid ?? '')
      if (result && result.length > 1) {
        result.forEach((item) => {
          setPaidMonths([...paidMonths, {label: item, value: item}])
        })
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  useEffect(() => {
    fetchInstitutes(1)
    getPaidMonthList()
  }, [])

  return (
    <div style={{width: '100%'}}>
      <PackageTable>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={item.label}
            length={HeaderCells.length}
            index={index}
          />
        ))}
        {items?.items.map((item) => (
          <RoundTableWrap
            onClick={() => {
              setSelectedOidName(item.name)
              setIsClickedCell(true)
              fetchEnablePackage(item.oid)
              setSelectedOid(item.oid)
            }}
          >
            <td>{item.name}</td>
            <td>{item.chiefEmail}</td>
            <td>{item.tel}</td>
            <td>
              <TableButton
                onClick={() => {
                  setSelectedOid(item.oid)
                  handleDetail()
                }}
              >
                {t('IRead')}
              </TableButton>
            </td>
          </RoundTableWrap>
        ))}
      </PackageTable>
      <Pagination
        totalPageCount={totalPages(items?.total ?? 0, 5)}
        currentPageIndex={makeCurrentPage()}
        itemCountPerPage={items?.size ?? 0}
        setCurrentPageIndex={(e) => {
          fetchInstitutes(e + 1)
        }}
        loading={false}
        onItemCountPerPageChanged={() => {}}
        displayIndexCount={2}
        isItemCountPerPageEnabled={false}
        isFirstAndLastButtonEnabled
      />
      <br />
      <br />
      <HiddenDiv hidden={isClickedCell}>
        <DiscountSection title={selectedOidName as string} oid={selectedOid} />
        <div style={{display: 'flex', gap: 10}}>
          <DiscountButton backgroundColor='#3ebbbd'>
            {t('IApplyDiscount')}
          </DiscountButton>
          <DiscountButton backgroundColor='#5a6268' onClick={deleteAllPackage}>
            {t('IDiscountReset')}
          </DiscountButton>
        </div>
        <br />
        <p>
          {t('IBatchDiscount')}({t('IBatchDiscountDes')})
        </p>
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextInput
            onChange={(e) => {
              setDiscountPercent(Number(e.target.value))
            }}
            value={discountPercent}
            type='number'
            pattern='[0-9.]+'
          />
          <p style={{margin: 0}}>%</p>
          <DiscountButton
            backgroundColor='#3ebbbd'
            onClick={() => {
              discountAllPackages(selectedOid, discountPercent)
            }}
          >
            {t('IApplyDiscount')}
          </DiscountButton>
        </div>
        <br />
        <br />
        {/* TODO: 사용내역 더미데이터 주시면 그거에 맞춰서 작업  */}
        <div style={{display: 'flex', alignItems: 'baseline', gap: 10}}>
          <SubTitle>{t('IUsageHistory')}</SubTitle>
          {paidMonths.length > 0 && (
            <>
              <p style={{margin: 5}}>~ {dateToDashString(new Date())}</p>
              <SelectOrder
                selectContent={paidMonths}
                setSelectName={() => {}}
              />
            </>
          )}
        </div>
        <UsageHistory />
        <br />
        <SubTitle>{t('IRegisteredCard')}</SubTitle>
        <RegisteredCard />
      </HiddenDiv>
      {selectedOid && <SalesOrganizationEditDialog oid={selectedOid} />}
      {selectedOid && <SalesOrganizationReadDialog oid={selectedOid} />}
    </div>
  )
}
export default SearchInstituteTable
