import React from 'react'
import {useTranslation} from 'react-i18next'
import {Text} from 'components/V2Dialog/TherapyDialog/Style'
import {SvgIcon} from 'components/common/useSvgIcons'
import {PresetSetting} from '../constant'
import useStyle from '../Style'

interface RenderTableHeaderProps {
  selectedSet: PresetSetting
  set: PresetSetting
  presets: PresetSetting[]
  handleSelectPreset: any
  handleDeletePreset: any
  index: number
}

function TabHeader(props: RenderTableHeaderProps) {
  const {t} = useTranslation()
  const classes = useStyle()
  const {
    selectedSet,
    presets,
    set,
    handleSelectPreset,
    handleDeletePreset,
    index,
  } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classes.tabWrapper}
      style={{
        backgroundColor: selectedSet?.id === set?.id ? '#5A6268' : '#E4E4E6',
        cursor: selectedSet?.id === set?.id ? 'default' : 'pointer',
        height: '41px',
      }}
      onClick={() => handleSelectPreset(set.id, index)}
    >
      <Text
        size='12px'
        fontWeight={selectedSet?.id === set?.id ? '700' : '500'}
        radius='inherit'
        padding='10px 20px'
        margin='0px 5px 0px 0px'
        color={selectedSet?.id === set?.id ? '#fff' : '#8A8A8A'}
        bgcolor='inherit'
        cursor='pointer'
      >
        {`${t('IPreset')} ${index + 1}`}
      </Text>
      {/* Cannot remove first set */}
      {presets.length > 1 && (
        <Text
          style={{
            minWidth: '25px',
            borderRadius: '0px 4px 0px 0px',
          }}
          size='12px'
          fontWeight={selectedSet?.id === set?.id ? '700' : '600'}
          padding='10px 20px 10px 5px'
          margin='0px 0px 0px -8px'
          color={selectedSet?.id === set?.id ? '#fff' : '#8A8A8A'}
          cursor='pointer'
          bgcolor='inherit'
          onClick={(event) => {
            event.stopPropagation()
            handleDeletePreset(set.id)
          }}
        >
          <SvgIcon
            name='closePreset'
            iconColor={selectedSet?.id === set?.id ? '#fff' : '#8A8A8A'}
          />
        </Text>
      )}
    </div>
  )
}

export default TabHeader
