import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import CardWithTitle from 'components/atoms/CardWithTitle'
import {useReadDialog} from 'features/patient/usePatientDialog'
import useWhiteListReadDialog from 'features/patient/usePatientWhiteListDialog'
import PatientTable from 'pages/OrgPage/PatientsPage/PatientTable'
import PatientInfo from 'pages/OrgPage/PatientsPage/PatientInfo'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'
import {getFeatures} from 'api/storageApi'

const PatientPage = () => {
  const {t} = useTranslation()
  const {onOpen} = useReadDialog()
  const {onOpen: onOpenWhiteList} = useWhiteListReadDialog()
  const [selectedPatient, setSelectedPatient] = useState('')
  const [isWhiteList, setIsWhiteList] = React.useState<boolean>(false)

  const handlePatientClick = (uuid: string) => {
    if (isWhiteList) onOpenWhiteList(uuid)
    else onOpen(uuid)
  }
  useEffect(() => {
    const IS_WHITE_LIST = TYPE_WHITE_LIST.every((item) =>
      JSON.parse(getFeatures() ?? '[]').includes(item),
    )
    setIsWhiteList(IS_WHITE_LIST)
  }, [])
  const handlePatientSelect = (uuid: string) => setSelectedPatient(uuid)

  return (
    <>
      <CardWithTitle title={t('ICustomerHome')}>
        {selectedPatient && selectedPatient !== '' && (
          <>
            <PatientInfo uuid={selectedPatient} isWhiteList={isWhiteList} />
            <div style={{padding: '15px'}} />
          </>
        )}
        <PatientTable
          onPatientClick={handlePatientClick}
          onPatientSelect={handlePatientSelect}
          isWhiteList={isWhiteList}
        />
      </CardWithTitle>
    </>
  )
}

export default PatientPage
