import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  MultiTherapyPrescriptionListApi,
  selectTherapyApi,
  selectTherapyApiV2,
  selectTherapyLatestApiV2,
  TherapySelectRequest,
} from 'api/therapyApi'
import {RootState} from 'store'
import {MultiTherapyHistory} from '../../../@types'

export const fetchTherapySelect = createAsyncThunk(
  'api/therapy/select',
  async (payload: TherapySelectRequest, {rejectWithValue}) => {
    try {
      const response = await selectTherapyApi(payload)
      const response2 = await selectTherapyApiV2(payload)
      // const response3 = await selectTherapyLatestApiV2(payload)
      // const response4 = await MultiTherapyPrescriptionListApi(payload)

      console.log(`HIHI : ${JSON.stringify(response2.data)}`)
      // console.log(`HIHI2 : ${JSON.stringify(response3.data)}`)
      // console.log(`HIHI3 : ${JSON.stringify(response4.list)}`)
      return response2.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface TherapySelectState {
  loading: boolean
  uid?: string | null
  uuid?: string | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  period?: PeriodString
  therapySelect: MultiTherapyHistory[]
  error: any
}
export const initialState: TherapySelectState = {
  loading: false,
  therapySelect: [],
  error: null,
  pageIndex: 0,
  totalPages: 1,
  totalElements: 0,
  itemPerPage: 5,
  uid: '',
  uuid: '',
}
export const therapySelectSlice = createSlice({
  name: 'therapySelectSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTherapySelect.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchTherapySelect.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber},
        content: therapySelect,
        size,
      } = action.payload
      state.therapySelect = therapySelect
      state.loading = false
      state.error = null
      state.totalPages = totalPages
      state.totalElements = totalElements
      state.pageIndex = pageNumber
      state.itemPerPage = size
    })
    builder.addCase(fetchTherapySelect.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectError = (state: RootState) => state.therapySelect.error
export const selectList = (state: RootState) =>
  state.therapySelect.therapySelect
export const selectLoading = (state: RootState) => state.therapySelect.loading
export const selectQuery = (state: RootState) => ({
  period: state.therapySelect.period,
  paging: {
    page: state.therapySelect.pageIndex,
    size: state.therapySelect.itemPerPage,
  },
  uid: state.therapySelect.uid,
  uuid: state.therapySelect.uuid,
})
export const selectPeriod = (state: RootState) => state.therapySelect.period
export const selectPaging = (state: RootState) => ({
  page: state.therapySelect.pageIndex,
  size: state.therapySelect.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.therapySelect.totalPages,
  totalElements: state.therapySelect.totalElements,
})

export default therapySelectSlice.reducer
