import {Typography} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import {addDays} from 'date-fns'
import {enUS, ko} from 'date-fns/locale'
import {formatDate, oneYearAgoFromNow} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {Calendar} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useTranslation} from 'react-i18next'

interface ParentProps {
  onChangeValue: (date?: Date) => void
  dateValue: Date
  maxDate?: Date
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  parentDate: {
    position: 'relative',
    width: '100%',
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    padding: 9,
    border: '1px solid #e0e0e0',
    backgroundColor: 'white',
    borderRadius: 4,
    '& h6': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
    textAlign: 'center',
  },
  divider: {
    marginRight: 10,
    marginLeft: 10,
  },
  dropdown: {
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 500,
    boxShadow: '15px 10px 30px rgba(0, 0, 0, 0.1)',
  },
  dateTextWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  padding: {
    padding: theme.spacing(0.5),
  },
}))

/**
 * 날짜선택이 가능한 캘린더
 *
 * @param onChangeValue setValue 함수
 * @param dateValue date value 값
 */

export default function CalenderDatePicker({
  onChangeValue,
  dateValue,
  maxDate,
}: ParentProps) {
  const {i18n} = useTranslation()
  const classes = useStyles()
  const [selection, setSelection] = React.useState<Date>(dateValue)
  const [open, setOpen] = React.useState(false)
  const [date, setDate] = React.useState<Date>()
  const [currentLocale, setCurrentLocale] = React.useState<string>()

  const onRangeChange = (ranges: any) => {
    if (ranges != null) {
      setDate(ranges)
      setSelection(ranges)
      setOpen(false)
    }
  }
  const handleClickAway = () => {
    setOpen(false)
  }
  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const getLocale = () => {
    if (currentLocale === 'ko') {
      return ko
    }
    return enUS
  }

  useEffect(() => {
    setCurrentLocale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    onChangeValue(date)
  }, [date])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.parentDate}>
        <Grid className={classes.dateWrap} onClick={handleClick}>
          <DateRangeIcon className={classes.icon} />
          <Divider
            orientation='vertical'
            flexItem
            className={classes.divider}
          />
          <div className={classes.dateTextWrap}>
            <Typography variant='h6'>{formatDate(selection)}</Typography>
          </div>
        </Grid>
        {open ? (
          <div className={classes.dropdown}>
            <Calendar
              onChange={onRangeChange}
              locale={getLocale()}
              date={date ?? new Date()}
              minDate={oneYearAgoFromNow()}
              maxDate={addDays(maxDate ?? new Date(), 0)}
            />
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}
