import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import {hasResponseFileDownload} from 'api'
import CardWithTitle from 'components/atoms/CardWithTitle'
import {addDays} from 'date-fns'
import {enUS, ko} from 'date-fns/locale'
import useFailureModal from 'features/modal/useFailureModal'
import {
  dateToPeriodString,
  getDateForFile,
  oneYearAgoFromNow,
} from 'helpers/dateHelper'
import React from 'react'
import {DateRange} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: 10,
    border: 0,
    '& .rdrInRange': {
      backgroundColor: 'white',
      borderTop: '1px solid #0cc7c7',
      borderBottom: '1px solid #0cc7c7',
    },
    '& .rdrStartEdge, ': {
      backgroundColor: 'white',
      borderLeft: '1px solid #0cc7c7',
      borderTop: '1px solid #0cc7c7',
      borderBottom: '1px solid #0cc7c7',
    },
    '& .rdrEndEdge, ': {
      backgroundColor: 'white',
      borderRight: '1px solid #0cc7c7',
      borderTop: '1px solid #0cc7c7',
      borderBottom: '1px solid #0cc7c7',
    },
    '& .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge':
      {
        backgroundColor: 'white',
        borderRight: '1px solid #0cc7c7',
      },
    '& .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge':
      {
        backgroundColor: 'white',
        borderLeft: '1px solid #0cc7c7',
      },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
      {
        color: '#0cc7c7',
      },
  },
}))

export default function SalesUsageInquiryPage() {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const init = [
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
    },
  ]
  const [state, setState] = React.useState(init)

  const handleDownload = async () => {
    try {
      const response = await hasResponseFileDownload({
        path: '/api/manager/v1/org/usage/download/excel',
        params: dateToPeriodString({
          startDate: state[0].startDate,
          endDate: state[0].endDate,
        }),

        fileName: getDateForFile(),
      })
      if (!response) {
        onFailureModalOpen(t('IProcessFail'))
        return
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }

  return (
    <div>
      <CardWithTitle>
        <div
          className={classes.root}
          style={{
            width: 700,
            backgroundColor: 'rgb(239, 242, 247)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 15,
            border: 0,
          }}
        >
          <DateRange
            onChange={(item) =>
              setState([
                {
                  startDate: item?.range1.startDate ?? new Date(),
                  endDate: item?.range1.endDate ?? new Date(),
                },
              ])
            }
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction='horizontal'
            rangeColors={['#0cc7c7']}
            showPreview={false}
            showMonthAndYearPickers={false}
            color='#0cc7c7'
            locale={i18n.language === 'ko' ? ko : enUS}
            editableDateInputs
            retainEndDateOnFirstSelection={false}
            minDate={oneYearAgoFromNow()}
            maxDate={addDays(new Date(), 0)}
          />
          <div
            style={{
              backgroundColor: 'rgb(239, 242, 247)',
              padding: '10px 0',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{fontSize: 12, color: '#849095'}}>
              {i18n.language === 'ko' &&
                `* 기간을 선택 하신 후 '사용량 조회 엑셀 다운 받기' 버튼을
              눌러주세요.`}
              {i18n.language !== 'ko' &&
                `After selecting the period, click the 'Download Usage Inquiry
                Excel' button Please press`}
            </p>
            <Button
              startIcon={<GetAppIcon />}
              type='submit'
              style={{
                backgroundColor: '#0cc7c7',
                padding: '8px 25px',
                borderRadius: 5,
                fontSize: 13,
                fontWeight: 'bold',
                color: 'white',
              }}
              onClick={() => handleDownload()}
            >
              {i18n.language === 'ko' && `사용량 조회 엑셀 다운 받기`}
              {i18n.language !== 'ko' && `Download the usage inquiry excel`}
            </Button>
          </div>
        </div>
      </CardWithTitle>
    </div>
  )
}
