import {createStyles, makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import {styles} from 'components/Dialog/findFormStyle'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

export const useStyles = makeStyles(() =>
  createStyles({
    ...styles(),
    root: {
      display: 'flex',
      gap: 8,
    },
  }),
)

interface FindPasswordInputProps {
  isAuthCodeSend: boolean
}

export default function FindPasswordInput({
  isAuthCodeSend,
}: FindPasswordInputProps) {
  const findPasswordData = useFormContext()
  const classes = useStyles()
  const {t} = useTranslation()
  const schema = yup.object().shape({
    email: yup
      .string()
      // .required(`${t('IEmailRequired')}`)
      .email(`${t('IEmailInvalid')}`),
  })

  const {
    formState: {errors},
  } = useFormContext()

  // const handleCheckEmailData = async (emailValue: string) => {
  //   const response = await checkEmailDuplicateApi({email: emailValue})
  //   if (response.success) {
  //     console.log('성공')
  //   } else {
  //     console.log('실패')
  //   }
  // }

  const emailClassNames = classNames({
    Right: true,
    InputText: true,
    Error: errors.email !== undefined,
  })

  return (
    <label htmlFor='input_email' className={classes.inputWithLabel}>
      <div className='Container'>
        <span className='Left Label'>{t('IEmailId')}</span>
        <input
          id='input_email'
          className={emailClassNames}
          placeholder={t('IEmail')}
          {...findPasswordData.register('email', {
            validate: (email) =>
              schema.validate({email}).catch((e) => e.errors[0]),
          })}
          disabled={isAuthCodeSend}
        />
        <Button
          size='medium'
          variant='contained'
          color='primary'
          disableElevation
          className='ConfirmButton'
          type='submit'
          // disabled={isAuthCodeSend}
        >
          {t('IAuthCodeSend')}
        </Button>
      </div>
      <div className='Container'>
        <div className='Left' />
        <span className='Right ErrorText'>{errors.email?.message}</span>
      </div>
    </label>
  )
}
