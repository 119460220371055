/**
 * 로직 오류 혹은 밸리데이션 오류
 */

// eslint-disable-next-line max-classes-per-file
import {navigator} from 'AppHistory'
import RouteConstant from 'constants/RouteConstant'
import {RouteErrorConstant} from 'error/AppError'

export interface ErrorStatus {
  status: number
}

export interface CommonErrorStatus {
  status: number
  statusCode: number
}

export interface ValidationErrorDetail {
  loc: string[]
  msg: string
  type: string
}

//
export class AppError extends Error {}

export class NotTokenError extends AppError {
  path: string

  constructor(path: string) {
    super()
    this.path = path
  }

  moveToLogin() {
    window.location.replace(this.path)
  }
}

export class AuthError extends AppError {
  constructor(message: string) {
    super()
    this.message = message
    new NotTokenError(`${RouteConstant.LOGIN.path}`).moveToLogin()
  }
}

export class NotFoundError extends AppError {
  constructor(errMessage: string) {
    super(errMessage)
    this.message = errMessage
    this.name = 'NotFoundError'
    this.setNotFoundMessage(errMessage)
  }

  setNotFoundMessage(errMessage: string) {
    if (errMessage === RouteErrorConstant.NOT_FOUND_USER) {
      this.message = 'IUserEmpty'
    }
  }
}

export class AuthRefreshError extends AppError {}

export class NetworkError extends AppError {}

export class ConflictError extends AppError {}

export class ValidationError extends AppError {
  message: string

  name: string

  constructor(errorMessage: string) {
    super(errorMessage)
    this.message = errorMessage
    this.name = 'ValidationError'
    this.setValidationMessage(errorMessage)
  }

  setValidationMessage(errorMessage: string) {
    if (errorMessage === RouteErrorConstant.FIELD_REQUIRED) {
      this.message = 'ICheckedRequired'
    }
  }
}

export class ForbiddenError extends AppError {
  constructor(message?: string) {
    super(message)
    this.setForbiddenPages()
  }

  setForbiddenPages() {
    navigator()
    const text = window.document.getElementById('errorText')
    if (text) {
      text.innerHTML = `<p>${this.message}</p>`
    }
  }
}

export class ServerError extends AppError {
  message: string

  name: string

  constructor(errorMessage: string) {
    super(errorMessage)
    this.message = errorMessage
    this.name = 'ServerError'
    this.setErrorMessage(errorMessage)
  }

  setErrorMessage(errorMessage: string) {
    if (errorMessage === RouteErrorConstant.SERVER_ERROR) {
      this.message = 'IInvalidRequest'
    }
  }
}

export class ApiError extends AppError {
  err?: any

  detail: any

  status?: number

  statusCode?: number

  name: string

  success: boolean

  constructor(err: any) {
    super(err)
    this.err = err
    this.detail = err.data
    this.status = err.status
    this.success = false
    this.message = err.message
    this.name = 'ApiError'
    this.setStatusError()
  }

  // Branch error class according to http status
  setStatusError() {
    const errorDetail = this.detail
    if (this.isSameStatus(401)) throw new AuthError(errorDetail.msg)
    if (this.isSameStatus(404)) throw new NotFoundError(errorDetail.msg)
    if (this.isSameStatus(403)) throw new ForbiddenError(errorDetail)
    if (this.isSameStatus(409)) throw new ConflictError(errorDetail)
    if (this.isSameStatus(422))
      throw new ValidationError(errorDetail.detail[0].msg)
    if (this.isSameStatus(500)) throw new ServerError(errorDetail.detail)
  }

  getErrorMessage(): string {
    if (this.err) {
      return this.err.message
    }
    return ''
  }

  isSameStatus(status: number) {
    return status === this.status
  }
}
