import request from 'api/index'

export interface NoticeListRequest extends PagingRequest {}

export interface NoticeDetailInfoRequest {
  id: number
}

export interface NoticeEditInfoRequest {
  id: number
  title: string
  content: string
}

export interface NoticeCreateInfoRequest {
  title: string
  content: string
}

export const fetchNoticeListApi = ({paging}: NoticeListRequest) =>
  request<PageResponse<NoticePageItem>>({
    method: 'get',
    path: '/api/notice/v1/list',
    params: {...paging},
  })

export const fetchNoticeDetailInfoApi = (query: NoticeDetailInfoRequest) =>
  request<ModelResponse<NoticeDetailInfo>>({
    method: 'get',
    path: '/api/notice/v1/detail',
    params: query,
  })

export const deleteNoticeApi = (data: NoticeDetailInfoRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/notice/v1/delete',
    data,
  })

export const updateNoticeApi = (data: NoticeEditInfoRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/notice/v1/update',
    data,
  })

export const createNoticeApi = (data: NoticeCreateInfoRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/notice/v1/create',
    data,
  })

export const countHitsNoticeApi = (data: NoticeDetailInfoRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/notice/v1/count-update',
    data,
  })
