import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from 'components/Text/ReportSubtitle'

const getNormativeI18n = (score: number) => {
  if (score > 84) return 'INormativeHigher'
  if (score >= 16) return 'INormativeIn'
  return 'INormativeLower'
}

export default function EEGSummaryOpinion(props: {
  score: number
  index: number
  contourImage?: string
}) {
  const {score, contourImage, index} = props
  const {t} = useTranslation()
  const classes = useStyles()
  const normative = getNormativeI18n(score)
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number={`${index}`}>
        {t('IEEGSummaryReportBrainAgingOpinionTitle')}
      </ReportSubtitle>
      <div className={classes.p_brainOpinion}>
        <div className='Detail'>
          <div className='Opinion' style={{fontSize: 17, fontWeight: 'bold'}}>
            {t('IEEGSummaryReportBrainAgingResult2', {
              score,
              normative: t(normative),
            })}
          </div>

          <div className='Reference'>
            <div style={{fontSize: 16}}>
              {t('IEEGSummaryReportBrainAgingOpinionAbout')}
            </div>
          </div>
        </div>
        <div className='Contour'>
          <img src={contourImage} alt='' className='Image' />
        </div>
      </div>
    </div>
  )
}
