import React from 'react'
import * as yup from 'yup'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {TFunction, useTranslation} from 'react-i18next'
import CardWithTitle from 'components/atoms/CardWithTitle'
import useFailureModal from 'features/modal/useFailureModal'
import Container, {FormContainer, LeftContainer, RightContainer} from './style'
import UserForm from './Content/UserForm'
import Policy from './Content/Policy'

const schema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string(),
    isPersonal: yup.boolean(),
    chiefFirstName: yup.string().required(t('IRequired')),
    chiefLastName: yup.string().required(t('IRequired')),
    email: yup.string().email(t('IEmailInvalid')).required(t('IRequired')),
    password: yup
      .string()
      .required(t('IRequired'))
      .min(8, t('IPasswordLength'))
      .max(20, t('IPasswordLength'))
      .matches(
        /^.*(?=.{1,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-zA-z]){1}).*$/,
        t('IPasswordInvalid'),
      ),
    confirmPassword: yup
      .string()
      .required(t('IRequired'))
      .oneOf([yup.ref('password'), null], t('IPasswordSamePlease')),
    countryId: yup.string().required(t('IRequired')),
    tel: yup.string().required(t('IRequired')),
    industry: yup.string().required(t('IRequired')),
    industryDetail: yup.string().required(t('IRequired')),
    businessNo: yup.string(),
    isAgreePrivacy: yup.boolean().oneOf([true], t('IRequired')),
    isAgreeTerms: yup.boolean().oneOf([true], t('IRequired')),
  })

const RegisterPageV2 = () => {
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const userInfoForm = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      name: '',
      isPersonal: false,
      chiefFirstName: '',
      chiefLastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      countryId: '',
      tel: '',
      industry: '',
      industryDetail: '',
      businessNo: '',
      isAgreePrivacy: false,
      isAgreeTerms: false,
      checkDuplicated: false,
    },
  })
  return (
    <Container>
      <FormProvider {...userInfoForm}>
        <FormContainer
          // TODO: Should handle submit account
          onSubmit={userInfoForm.handleSubmit((data) => {
            if (!data.checkDuplicated) {
              onFailureModalOpen(t('IRegisterEmailDuplicateNotChecked'))
              return
            }
            console.log(data)
          })}
        >
          <LeftContainer>
            <Policy />
          </LeftContainer>
          <RightContainer>
            <CardWithTitle title={t('IUserInfo')}>
              <UserForm />
            </CardWithTitle>
          </RightContainer>
        </FormContainer>
      </FormProvider>
    </Container>
  )
}

export default RegisterPageV2
