export interface BaseError {
  type: string
  message?: string
  code?: string
  status?: number
  error?: Error
}

export interface NetworkError extends BaseError {
  type: 'network_error'
}
export interface NotFoundError extends BaseError {
  type: 'not_found_error'
}
export interface ForbiddenError extends BaseError {
  type: 'forbidden_error'
}
export interface ApiError extends BaseError {
  type: 'api_error'
}
export interface AccessTokenExpiredError extends BaseError {
  type: 'access_token_error'
}
export interface RefreshTokenExpiredError extends BaseError {
  type: 'refresh_token_error'
}
export interface ServerError extends BaseError {
  type: 'server_error'
}
export type ServiceError =
  | NetworkError
  | NotFoundError
  | ForbiddenError
  | ApiError
  | AccessTokenExpiredError
  | RefreshTokenExpiredError
  | ServerError
export type WithoutType = Omit<ServiceError, 'type'>

export function getServiceError<T extends BaseError>(params: {
  [Key in keyof T]: T[Key]
}) {
  return {...params}
}

export const getNetworkError = (param?: WithoutType) =>
  getServiceError<NetworkError>({type: 'network_error', ...param})
export const getNotFoundError = (param?: WithoutType) =>
  getServiceError<NotFoundError>({type: 'not_found_error', ...param})
export const getForbiddenError = (param?: WithoutType) =>
  getServiceError<ForbiddenError>({type: 'forbidden_error', ...param})
export const getApiError = (param?: WithoutType) =>
  getServiceError<ApiError>({type: 'api_error', ...param})
export const getAccessTokenExpiredError = (param?: WithoutType) =>
  getServiceError<AccessTokenExpiredError>({
    type: 'access_token_error',
    ...param,
  })
export const getRefreshTokenExpiredError = (param?: WithoutType) =>
  getServiceError<RefreshTokenExpiredError>({
    type: 'refresh_token_error',
    ...param,
  })
export const getServerError = (param?: WithoutType) =>
  getServiceError<ServerError>({type: 'server_error', ...param})

export const isServiceError = (error: unknown): error is ServiceError => {
  if (typeof error === 'object' && error !== null && 'type' in error) {
    return true
  }
  return false
}

export const RouteErrorConstant = {
  FIELD_REQUIRED: 'field required',
  INVALID_FILE_NAME: '유효한 파일 이름을 입력해주세요',
  NOT_FOUND_CONTENT: '요청하신 내용을 찾을수 없습니다',
  NOT_FOUND_USER: '제공된 정보와 일치하는 사용자를 찾을 수 없습니다',
  NETWORK_ERROR_MESSAGE: '네트워크 오류가 발생했습니다.',
  SERVER_ERROR: 'list index out of range',
  CREDENTIAL_EXPIRED: '인증정보가 만료되었습니다.',
}
