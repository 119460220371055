import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
  BaseFeesType,
  PlanType,
  ProductTemplateType,
} from 'features/invoice/invoiceSlice'

import Cell, {
  CellItemType,
} from 'pages/CustomerManagement/InvoiceSetting/Form/Cell'

import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'

const useStyles = makeStyles(InvoiceSettingStyle)

interface RowsItemType {
  title: string
  rowsContent: CellItemType[][][]
}

interface ElementPropsType {
  data: RowsItemType[]
  type?: boolean
  parentId: PlanType
  packageType: PlanType
  baseFees: BaseFeesType | null
  templates: ProductTemplateType[] | null
  changeProductTemplateAction: (payload: ProductTemplateType[]) => void
  changeBaseFeesAction: (payload: BaseFeesType) => void
  ratePlan?: boolean
}

function Element({
  data,
  type = true,
  parentId,
  packageType,
  baseFees,
  templates,
  changeProductTemplateAction,
  changeBaseFeesAction,
  ratePlan,
}: ElementPropsType) {
  const classes = useStyles()

  return (
    <div className={type ? classes.contentForm : classes.contentFormV2}>
      {data.map((elm, num) => (
        <div className={classes.contentElement} key={`${num}_element`}>
          <div className={classes.contentElementTitle}>{elm.title}</div>
          <div className={classes.elementRows}>
            {elm.rowsContent.map((row, index) => (
              <div className={classes.contentElementRow} key={`${index}_row`}>
                {row.map((cell, i) => (
                  <div className={classes.contentElementCell} key={`${i}_elm`}>
                    <Cell
                      data={cell}
                      parentId={parentId}
                      packageType={packageType}
                      baseFees={baseFees}
                      templates={templates}
                      changeProductTemplateAction={changeProductTemplateAction}
                      changeBaseFeesAction={changeBaseFeesAction}
                      ratePlan={ratePlan}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Element
