import {IndividualOrNormativeEEGCodeType} from 'api/analysisApi'
import {Channel, CodeType, PowerType} from 'constants/AnalysisConstant'
import {isNotDefined} from 'helpers/commonHelper'
import {Match, match} from 'helpers/matchHelper'

const RouteConstant = {
  HOME: {path: '/', title: 'IDashboard'},
  LOGIN: {path: '/auth/login', title: 'ILogin'},
  VERIFICATION: {path: '/auth/verification', title: 'IAuthenticationCode'},
  LOGOUT: {path: '/auth/login', title: 'ILogout'},
  REGISTER: {path: '/auth/register', title: 'IRegister'},
  REGISTER_SALE: {path: '/auth/register-sale', title: 'IRegister'},
  SELECT_ACCOUNT: {path: '/auth/selectAccount', title: 'IChangeAccount'},
  ERROR_NOT_FOUND: {path: '/error/404', title: 'IErrorNotFound'},
  ERROR_FORBIDDEN: {path: '/error/403', title: 'IErrorForbidden'},
  ERROR_INTERNAL: {path: '/error/500', title: 'IErrorInternal'},
  MY: {path: '/my', title: 'IMyPage'},
  MY_INFO: {path: '/my/info', title: 'IMyInfo'},
  MY_USAGE_HISTORY: {path: '/my/usage-history', title: 'IMyUsageHistory'},
  MY_PURCHASE: {path: '/my/purchase', title: 'IMyPurchase'},
  MY_CALIBRATION: {path: '/my/calibration', title: 'IMyCalibration'},
  ORG: {path: '/org', title: 'IInstituteManagement'},
  ORG_INFO: {path: '/org/info', title: 'IOrgInfo_short'},
  ORG_USERS: {path: '/org/users', title: 'IOrgIUsers'},
  ORG_DEPARTMENTS: {path: '/org/departments', title: 'IOrgIDepartments'},
  ORG_PRESET: {path: '/org/preset', title: 'IPresetManagement'},
  ORG_PATIENTS: {path: '/org/patients', title: 'IOrgCustomers'},
  ORG_INSTITUTIONAL: {
    path: '/org/institutional-usage-history',
    title: 'IInstitutionalUsageHistory',
  },
  ORG_ACCOUNT: {
    path: '/org/account-usage-history',
    title: 'IAcountUsageHistory',
  },
  ORG_TREATMENTS: {
    path: '/org/treatments',
    title: 'IOrgTreatments',
  },
  ORG_TREATMENTS_PATIENT: {
    path: '/org/treatments/:id',
    title: 'IOrgTreatments',
  },
  ORG_TREATMENTS_SUMMARY: {
    path: '/org/treatments/summary/:id',
    title: 'ISummaryReport',
  },
  ORG_THERAPY_REPORT: {
    path: '/org/treatments/therapy/:id',
    title: 'ITherapyReport',
  },
  ORG_PURCHASE: {path: '/org/purchase', title: 'IPurchaseManagement'},
  ORG_PURCHASE_SELECT: {
    path: '/org/purchase/select',
    title: 'IProductsSelectTitle',
  },
  ORG_PURCHASE_CHECK: {
    path: '/org/purchase/check',
    title: 'IProductsCheckTitle',
  },
  ORG_HOSPITAL_INFO: {
    path: '/org/hospital-information',
    title: 'IHospitalInformation',
  },
  ANALYSIS: {path: '/analysis', title: 'IAnalysis'},
  ANALYSIS_EDF: {
    path: '/analysis/edf',
    title: 'IEdf',
  },
  ANALYSIS_HEALTH_CENTER: {
    path: '/analysis/health-center',
    title: 'IHealthCenter',
  },
  ANALYSIS_HEALTH_CENTER_REPORT: {
    path: '/analysis/health-center/report/:id',
    title: 'IHealthCenter',
  },
  ANALYSIS_HEALTH_CENTER_MAJOR_INDICATOR: {
    path: '/analysis/health-center/major-indicators',
    title: 'IHealthCenter',
  },
  ANALYSIS_HEALTH_CENTER_HEART_RATE_ANALYSIS: {
    path: '/analysis/health-center/heart-rate-analysis',
    title: '',
  },
  ANALYSIS_EEG_19: {path: '/analysis/eeg_19', title: 'IEEG'},
  ANALYSIS_EEG_19_NONE: {
    path: '/analysis/eeg_19/individual/:id',
    title: 'ERROR',
  },
  ANALYSIS_EEG_19_I: {
    path: '/analysis/eeg_19/individual',
    title: 'IAnalysisEEG',
  },
  ANALYSIS_EEG_19_I_R: {
    path: '/analysis/eeg_19/individual/report/:id',
    title: 'IAnalysisIndividual',
  },

  ANALYSIS_EEG_19_I_I: {
    path: '/analysis/eeg_19/individual/ica/:id',
    title: 'ICA',
  },
  ANALYSIS_EEG_19_I_T: {
    path: '/analysis/eeg_19/individual/tsr/:id',
    title: 'Time-series Rejection',
  },
  ANALYSIS_EEG_19_S: {
    path: '/analysis/eeg_19/summary',
    title: 'IAnalysisEEGSummary',
  },
  ANALYSIS_EEG_19_S_R: {
    path: '/analysis/eeg_19/summary/:id',
    title: 'IAnalysisEEGSummary',
  },
  ANALYSIS_EEG_19_N: {
    path: '/analysis/eeg_19/norm',
    title: 'IAnalysisNorm',
  },
  ANALYSIS_EEG_19_N_R: {
    path: '/analysis/eeg_19/norm/report/:id',
    title: '  ANALYSIS_EEG_19_N_R: {',
  },
  ANALYSIS_EEG_19_G: {
    path: '/analysis/eeg_19/group_compare',
    title: 'IAnalysisGC',
  },
  ANALYSIS_EEG_19_M: {
    path: '/analysis/eeg_19/mci',
    title: 'IAnalysisMCI',
  },
  ANALYSIS_EEG_19_M_R: {
    path: '/analysis/eeg_19/mci/:id',
    title: 'IAnalysisMCI',
  },
  ANALYSIS_HRV: {path: '/analysis/hrv', title: 'IAnalysisHRV'},
  ANALYSIS_HRV_I: {
    path: '/analysis/hrv/individual',
    title: 'IAnalysisHRVIndividual',
  },
  ANALYSIS_HRV_I_R: {
    path: '/analysis/hrv/individual/:id',
    title: 'IAnalysisHRVIndividual',
  },
  ANALYSIS_HRV_N: {path: '/analysis/hrv/norm', title: 'IAnalysisHRVNorm'},
  ANALYSIS_HRV_S: {path: '/analysis/hrv/summary', title: 'IAnalysisHRVSummary'},
  ANALYSIS_HRV_S_R: {
    path: '/analysis/hrv/summary/:id',
    title: 'IAnalysisHRVSummary',
  },
  ANALYSIS_ALL_COMPONENT_IMAGES: {
    path: '/popup/eeg/all_images',
    title: '',
  },
  ANALYSIS_ALL_TIME_SERIES: {
    path: '/popup/eeg/all_time_series',
    title: '',
  },
  ANALYSIS_RAW_DATA_IMAGES: {
    path: '/popup/eeg/raw_data_image',
    title: '',
  },
  ANALYSIS_POWER_SPECTRUM_CHART: {
    path: '/popup/eeg/power_spectrum',
    title: '',
  },
  THREE_D_REPORT: {
    path: '/popup/eeg/3d_report/:type/:id',
    title: '',
  },
  STORE: {path: '/store', title: 'IStore'},
  PRODUCTS: {path: '/store/products', title: 'IProduct'},
  COMMUNITY: {path: '/community', title: 'ICommunity'},
  COMMUNITY_BOARD: {path: '/community/board', title: 'IBoard'},
  COMMUNITY_NOTICE: {path: '/community/notice', title: 'INotice'},
  COMMUNITY_NOTICE_READ: {path: '/community/notice/:id', title: 'INotice'},
  COMMUNITY_NOTICE_EDIT: {path: '/community/notice/edit/:id', title: 'INotice'},
  COMMUNITY_NOTICE_WRITE: {path: '/community/notice/write/', title: 'INotice'},
  COMMUNITY_QUESTION: {path: '/community/question', title: 'IInquiry'},
  SALES: {path: '/sales', title: 'ISalesManagement'},
  SALES_ACCOUNTS: {path: '/sales/accounts', title: 'ISearchAccountAndUser'},
  SALES_PATIENTS: {
    path: '/sales/patients',
    title: 'ISearchCustomerAndTreatment',
  },
  SALES_PURCHASE: {path: '/sales/purchase', title: 'IPurchaseManagement'},
  SALES_STATISTICS: {path: '/sales/statistics', title: 'IStatistics'},
  SALES_USAGE_INQUIRY: {path: '/sales/usage', title: 'IUsageInquiry'},
  SALES_PAGE_MANAGEMENT: {path: '/sales/pages', title: 'IPageManagement'},
  SALES_APP_NOTICE: {path: '/sales/app/notice', title: 'IAppNotice'},
  // CYM : 테라피 관리
  THERAPY_MANAGEMENT: {path: '/therapy', title: 'ITherapyManagement'},
  THERAPY_MANAGEMENT_L: {
    path: '/therapy/ledtherapy',
    title: 'ILedTherapy',
  },
  PACKAGE_PAYMENT: {
    path: '/package/payment',
    title: 'IPay',
  },
  DISPLAY_DESCRIPTION: {
    path: '/display',
    title: 'IDisplayDescription',
  },
  ISYNC_BRAIN_C: {
    path: '/display/brainc',
    title: 'iSyncBrain-C',
  },
  ISYNCBRAIN_MCI: {
    path: '/display/brainmci',
    title: 'iSyncBrain-MCI Classifier',
  },
  ISYNCBRAIN_HEART: {
    path: '/display/heart',
    title: 'iSyncHeart',
  },
  MANAGER_MEMBER_REGISTER: {
    path: '/manager/member-register',
    title: 'IRegister',
  },
  CUSTOMER: {path: '/customer', title: 'ICustomerCare'},
  INVOICES_LIST: {
    path: '/sales/invoices',
    title: 'IRegistration',
  },
  CUSTOMER_INQUIRY: {path: '/sales/inquiry', title: 'ICustomerInquiry'},
  INVOICE_SETTING: {
    path: '/sales/invoice-setting',
    title: 'IRegistration',
  },
  INVOICE_VIEW: {
    path: '/sales/invoice-view',
    title: 'IRegistration',
  },
  MEMBER_REGISTRATION: {
    path: '/sales/member-register',
    title: 'IMemberRegistration',
  },
  MEMBER_EDIT: {
    path: '/sales/member-edit',
    title: 'IMemberRegistration1',
  },
} as const
export default RouteConstant
export const getHomePath = () => '/'
export const getRegisterPath = () => '/auth/register'
export const getRegisterSalePath = () => '/auth/register-sale'
export const getTreatmentPatientPath = (uuid: string) =>
  `/org/treatments/${uuid}`
export const getTreatmentSummaryPath = (
  id: string,
  requestId: string,
  hrvRequestId: string,
) =>
  `/org/treatments/summary/${id}?requestId=${requestId}&hrvRequestId=${hrvRequestId}`
export const getTreatmentTherapyPath = (id: string) =>
  `/org/treatments/therapy/${id}`
export const getEegIndividualReportPath = (
  id: number,
  version?: string,
  clientName?: string,
) =>
  `/analysis/eeg_19/individual/report/${id}?clientName=${clientName}&version=${
    version ?? '3.0'
  }`
export const getEegIndividualIcaPath = (id: number, version?: string) =>
  `/analysis/eeg_19/individual/ica/${id}?version=${version ?? '3.0'}`
export const getEegIndividualTsrPath = (id: number, version?: string) =>
  `/analysis/eeg_19/individual/tsr/${id}?version=${version ?? '3.0'}`
export const getEegSummaryReportPath = (id: number, clientName?: string) =>
  `/analysis/eeg_19/summary/${id}?clientName=${clientName}`
export const getEegNormReportPath = (
  id: number,
  version?: string,
  clientName?: string,
) =>
  `/analysis/eeg_19/norm/report/${id}?clientName=${clientName}&version=${
    version ?? '3.0.1'
  }`
export const getEegMciReportPath = (id: number, clientName?: string) =>
  `/analysis/eeg_19/mci/${id}?clientName=${clientName}`
export const getHrvIndividualReportPath = (id: number, clientName?: string) =>
  `/analysis/hrv/individual/${id}?clientName=${clientName}`
export const getAllComponentImagesUrl = (id: number, type: CodeType) =>
  `${RouteConstant.ANALYSIS_ALL_COMPONENT_IMAGES.path}?id=${id}&type=${type}`
export const getAllTimeSeriesUrl = (id: number, type: CodeType) =>
  `${RouteConstant.ANALYSIS_ALL_TIME_SERIES.path}?id=${id}&type=${type}`
export const getRawDataUrl = (id: number, type: CodeType) =>
  `${RouteConstant.ANALYSIS_RAW_DATA_IMAGES.path}?id=${id}&type=${type}`
export const getPowerSpectrumChartUrl = (
  id: number,
  type: IndividualOrNormativeEEGCodeType,
  powerType: PowerType,
  channel: Channel,
) =>
  `${RouteConstant.ANALYSIS_POWER_SPECTRUM_CHART.path}?id=${id}&type=${type}&power_type=${powerType}&channel=${channel}`
export const getHrvSummaryReportPath = (id: number, clientName?: string) =>
  `/analysis/hrv/summary/${id}?clientName=${clientName}`
export const getOrgPurchasePath = () => `/org/purchase/`
export const getStorePath = () => `/store`
export const getNoticeListPath = () => `/community/notice/`
export const getNoticeDetailPath = (id: number) => `/community/notice/${id}`
export const getNoticeEditPath = (id: number) => `/community/notice/edit/${id}`
export const getNoticeWritePath = () => `/community/notice/write/`
export const getQnaListPath = () => `/community/question/`
export const getThreeDimensionReportPath = (id: number, type: CodeType) =>
  `/popup/eeg/3d_report/${type}/${id}`

export interface ThreeDPopupReportInfo {
  id: number
  type: CodeType
}

export const getThreeDimensionReportInfo = (
  url: string,
): Match<ThreeDPopupReportInfo> => {
  const matched = match('/popup/eeg/3d_report/:type/:id', url)
  const {matches, params} = matched

  if (matches === false) return {matches, params: null}
  if (isNotDefined(params)) return {matches, params: null}

  return {
    matches,
    params: {
      id: parseInt(params.id, 10),
      type: params.type as CodeType,
    },
  }
}

export const getPaymentsPath = () => `/package/payment`

/**
 * path parameter 를 포함하는 라우트 아이템은 별도로 여기에 추가해주어야 한다.
 * 그래야 상단 타이틀에 페이지 이름이 나온다.
 *
 * - /user/:id -> /user/1
 * - /treatments/:id -> /treatments/1
 */
export const routesHasPathParameter: RouteItem[] = [
  RouteConstant.ORG_TREATMENTS_PATIENT,
  RouteConstant.ORG_TREATMENTS_SUMMARY,
  RouteConstant.COMMUNITY_NOTICE_READ,
  RouteConstant.COMMUNITY_NOTICE_EDIT,
  RouteConstant.ANALYSIS_EEG_19_I_R,
  RouteConstant.ANALYSIS_EEG_19_I_I,
  RouteConstant.ANALYSIS_EEG_19_I_T,
  RouteConstant.ANALYSIS_EEG_19_S_R,
  RouteConstant.ANALYSIS_EEG_19_N_R,
  RouteConstant.ANALYSIS_HRV_I_R,
  RouteConstant.ANALYSIS_HRV_S_R,
  RouteConstant.ORG_DEPARTMENTS,
  RouteConstant.ORG_PRESET,
]
