import {createSlice} from '@reduxjs/toolkit'
import {RootState} from 'store'

interface ReloadImgState {
  time: number | undefined
}

const initialState: ReloadImgState = {
  time: undefined,
}

const slice = createSlice({
  name: 'reloadImgSlice',
  initialState,
  reducers: {
    setTime: (state) => {
      state.time = new Date()?.getTime()
    },
  },
})

export default slice.reducer

export const {setTime} = slice.actions

export const selectTime = (state: RootState) => state.reloadImg.time
