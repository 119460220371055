import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Table, TableBody, TableHead, TableRow} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'
import useInvoiceView from 'features/invoice/useInvoiceView'
import InvoiceViewStyle from 'pages/CustomerManagement/InvoiceView/Style'
import {USAGE_STATUS_HEADER} from 'pages/CustomerManagement/InvoiceView/constant'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'

const useStyles = makeStyles(InvoiceViewStyle)

function UsageStatus() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {usageDetail} = useInvoiceView()

  const dataUsageStatus = useMemo(() => {
    if (usageDetail) {
      return usageDetail.map((item, index) => {
        return [
          index + 1,
          item.type,
          makeDateAndTimeFormat(new Date(item.analysisRequestTime)),
          makeDateAndTimeFormat(new Date(item.analysisCompleteTime)),
          item.patientChartNo,
          t('IGetFullName', {
            firstName: item.patientFirstname ?? '',
            lastName: item.patientLastname ?? '',
          }),
          item.patientAge,
          item.remarks,
        ]
      })
    }
    return []
  }, [usageDetail])

  return (
    <div className={classes.defaultBilling} style={{marginTop: 5}}>
      <div className={classes.defaultBillingTitle}>
        {t('IBasicBillingItems')}
      </div>
      <div className={classes.defaultBillingTable}>
        <Table>
          <TableHead>
            <TableRow>
              {USAGE_STATUS_HEADER.map((item, colId) => (
                <TableCell
                  key={`usage_${colId}`}
                  className={`${classes.header} ${classes.backGroundHead}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataUsageStatus.map((data, index) => (
              <TableRow key={`usage_body${index}`}>
                {data.map((cell, collId) => (
                  <TableCell
                    key={`cell_usage_body${collId}`}
                    className={classes.cell}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default UsageStatus
