import i18n from 'i18n'
import {ProductType} from 'api/healthCareApi'

export interface DataTable {
  id: number
  displayName: string
  details: string[]
  checkbox: {type: ProductType; productId: number | null; displayName: string}
  checkboxHrv?: {
    type: ProductType
    productId: number | null
    displayName: string
  } | null
}

const DATA_BODY_TABLE: DataTable[] = [
  {
    id: 1,
    displayName: 'AI Brain Scan Basic',
    details: [i18n.t('I5MajorIndicator'), i18n.t('IBrainFunctionBasic')],
    checkbox: {type: 'Basic', productId: null, displayName: ''},
    checkboxHrv: {type: 'BasicHrv', productId: null, displayName: ''},
  },
  {
    id: 2,
    displayName: 'AI Brain Scan Standard',
    details: [
      i18n.t('I5MajorIndicator'),
      i18n.t('IBrainFunctionBasic'),
      i18n.t('IBrainFunctionAdvance'),
    ],
    checkbox: {type: 'Standard', productId: null, displayName: ''},
    checkboxHrv: {type: 'StandardHrv', productId: null, displayName: ''},
  },
  {
    id: 3,
    displayName: 'AI Brain Scan Premium',
    details: [
      i18n.t('I5MajorIndicator'),
      i18n.t('IBrainFunctionBasic'),
      i18n.t('IBrainFunctionAdvance'),
      i18n.t('IBrainConnectivity'),
    ],
    checkbox: {type: 'Premium', productId: null, displayName: ''},
    checkboxHrv: {type: 'PremiumHrv', productId: null, displayName: ''},
  },

  {
    id: 4,
    displayName: 'AI Brain Scan Premium Plus',
    details: [
      i18n.t('I5MajorIndicator'),
      i18n.t('IBrainFunctionBasic'),
      i18n.t('IBrainFunctionAdvance'),
      i18n.t('IBrainConnectivity'),
      i18n.t('IAIDementiaPrediction'),
    ],
    checkbox: {type: 'PremiumPlus', productId: null, displayName: ''},
    checkboxHrv: {type: 'PremiumPlusHrv', productId: null, displayName: ''},
  },
  {
    id: 5,
    displayName: 'HRV 기본',
    details: [i18n.t('IHeartRate')],
    checkbox: {type: 'HrvOnly', productId: null, displayName: ''},
  },
]

export {DATA_BODY_TABLE}
