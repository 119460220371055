import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  deletePatientApi,
  PatientSearchRequest,
  searchPatientApi,
} from 'api/patientApi'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import {confirmAction} from 'features/modal/confirmSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const fetchPatientSearch = createAsyncThunk(
  'api/patient/search',
  async (payload: PatientSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchPatientApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const patientDelete = createAsyncThunk(
  'patient/delete/multiple',
  async (uuids: string[], {dispatch}) => {
    if (uuids.length <= 0) {
      dispatch(openFailureModalAction('IError'))
      return {status: 'rejected', uuids: [], failed: []}
    }

    const confirmed = await dispatch(
      confirmAction({message: i18n.t('IPatientDeleteSelect')}),
    ).unwrap()
    if (!confirmed) return {status: 'canceled', uuids: [], failed: []}

    const promises = uuids.map((uuid) => deletePatientApi({uuid}))
    const results = await Promise.allSettled(promises)

    type DeleteResult = [string, boolean]
    const mapped = results.reduce<DeleteResult[]>((accr, curr, index) => {
      if (curr.status === 'fulfilled') {
        return [...accr, [uuids[index], true]]
      }

      return [...accr, [uuids[index], false]]
    }, [])

    const failed = mapped
      .filter(([, success]) => success === false)
      .map(([uuid]) => uuid)

    if (failed.length <= 0) {
      dispatch(openSuccessModalAction(i18n.t('ISuccess')))
    } else if (failed.length < uuids.length) {
      // TODO: 여러 항목을 삭제하다 일부 실패했을 때 오류 보여주는게 구리다. 테이블은 어떨까?
      dispatch(openFailureModalAction(i18n.t('IDeleteFailure_plural')))
    } else if (failed.length >= uuids.length) {
      dispatch(openFailureModalAction(i18n.t('IPatientDelete')))
    }
    return {status: 'fulfilled', uuids, failed}
  },
)

export interface PatientSearchState {
  loading: boolean
  searchName: PatientSearchKind | null
  searchValue: string | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  period?: PeriodString
  patients: Patient[]
  error: any
}

export const initialState: PatientSearchState = {
  loading: false,
  patients: [],
  error: null,
  searchName: null,
  searchValue: null,
  pageIndex: 0,
  totalPages: 1,
  totalElements: 0,
  itemPerPage: 10,
}

export const patientSearchSlice = createSlice({
  name: 'patientSearchSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatientSearch.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchPatientSearch.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber},
        content: patients,
        size,
      } = action.payload
      state.loading = false
      state.error = null
      state.patients = patients
      state.totalPages = totalPages
      state.totalElements = totalElements
      state.pageIndex = pageNumber
      state.itemPerPage = size
    })
    builder.addCase(fetchPatientSearch.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectError = (state: RootState) => state.patientSearch.error
export const selectList = (state: RootState) => state.patientSearch.patients
export const selectLoading = (state: RootState) => state.patientSearch.loading
export const selectQuery = (state: RootState): PatientSearchRequest => ({
  search: {
    searchName: state.patientSearch.searchName ?? null,
    searchValue: state.patientSearch.searchValue ?? null,
  },
  period: state.patientSearch.period,
  paging: {
    page: state.patientSearch.pageIndex,
    size: state.patientSearch.itemPerPage,
  },
})
export const selectPeriod = (state: RootState) => state.patientSearch.period
export const selectPaging = (state: RootState) => ({
  page: state.patientSearch.pageIndex,
  size: state.patientSearch.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.patientSearch.totalPages,
  totalElements: state.patientSearch.totalElements,
})

export default patientSearchSlice.reducer
