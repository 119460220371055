import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {DialogContent, IconButton, WithStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import useAuth from 'features/auth/useAuth'
import * as storage from 'api/storageApi'
import useWhiteListReadDialog, {
  useWhiteListUpdateDialog,
} from 'features/patient/usePatientWhiteListDialog'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {ClientName} from 'components/atoms/ClientName'
import {GenderValue, toGenderString} from 'constants/CommonConstant'
import {isDefined} from 'helpers/commonHelper'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      '& > .MuiDialog-container > .MuiPaper-root': {
        [theme.breakpoints.up('xs')]: {
          minWidth: 354,
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: 589,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 800,
        },
      },
    },
    containerTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flex: 1,
    },
    title: {
      padding: 0,
      color: '#4b4b4b',
      margin: 0,
      marginLeft: 50,
      paddingBottom: 15,
      fontSize: 24,
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    onlineContactButton: {
      height: 34,
      fontSize: 14,
      borderRadius: 7,
      marginLeft: 26,
    },
    closeButtonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
      marginRight: 10,
    },
    closeButton: {
      backgroundColor: '#f4f4f4',
      width: 20,
      height: 20,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: '#c5c5c5',
    },
    cardWrap: {
      maxWidth: 900,
    },
    cardContent: {
      margin: '0 20px',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '18px 80px',
      flexWrap: 'nowrap',

      '& .subTitle': {
        fontSize: '1.0rem',
        fontWeight: 600,
        textAlign: 'left',
        margin: '5px 0',
        minWidth: 50,
        maxWidth: 200,
        flexBasis: 200,
        flexWrap: 'wrap',
        marginTop: 15,
        color: '#4b4b4b',
      },

      '& .subContent': {
        fontSize: '1.0rem',
        textAlign: 'left',
        margin: '5px 0',
        flexWrap: 'wrap',
        marginTop: 15,
        flexGrow: 1,
        marginLeft: 10,
        fontWeight: 400,
        color: '#4b4b4b',
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '18px 0',
      flexWrap: 'wrap',
    },
    subTitle: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 50,
      maxWidth: 200,
      flexBasis: 200,
      flexWrap: 'wrap',
      marginTop: 15,
      color: '#757575',
      marginBottom: 15,
    },
    subContent: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      flexWrap: 'wrap',
      marginTop: 15,
      flexGrow: 1,
      marginLeft: 10,
      marginBottom: 15,
    },
    line: {
      background: '#eeeeee',
      marginLeft: 50,
      marginRight: 50,
    },
    buttonContainerBgColor: {
      backgroundColor: '#f4f4f4',
      display: 'flex',
      justifyItems: 'center',
      justifyContent: 'center',
    },
    padding: {
      padding: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      padding: 20,
      width: 400,

      '& button': {
        minWidth: 100,
        padding: '8px 0',
        flex: 1,
        margin: '0 10px',
      },
      '& button:nth-child(1)': {
        marginLeft: 20,
      },
      '& button:nth-child(3)': {
        marginRight: 20,
      },
      '& .MuiButton-containedSecondary': {
        color: 'white',
      },
    },
    okButton: {
      backgroundColor: 'white',
      border: 0,
      color: '#4b4b4b',
    },
    formatCheckUpItemLabels: {
      margin: '18px 80px',
      display: 'flex',
      gap: '80px',
      color: '#4b4b4b',
      fontSize: '1rem',
    },
  })
const useStyles = makeStyles(styles)

interface PatientSubInfoProps extends WithStyles<typeof styles> {
  label: string
  content?: string | undefined
  divider?: boolean
}

const PatientSubInfo = ({
  label,
  content,
  divider = false,
  classes,
}: PatientSubInfoProps) => {
  return (
    <>
      <div className={classes.infoContainer}>
        <Typography className='subTitle'>{label ?? ''}</Typography>
        <Typography className='subContent'>{content ?? ''}</Typography>
      </div>
      {divider && <Divider className={classes.line} />}
    </>
  )
}

interface PatientSubNameInfoProps extends WithStyles<typeof styles> {
  label: string
  firstName: string
  lastName: string
  divider?: boolean
}

const PatientSubNameInfo = ({
  label,
  firstName,
  lastName,
  divider = false,
  classes,
}: PatientSubNameInfoProps) => {
  return (
    <>
      <div className={classes.infoContainer}>
        <Typography className='subTitle'>{label ?? ''}</Typography>
        <Typography className='subContent'>
          <ClientName firstName={firstName} lastName={lastName} />
        </Typography>
      </div>
      {divider && <Divider className={classes.line} />}
    </>
  )
}

const PatientReadWhiteListDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()

  const {
    open,
    entity: patient,
    uuid,
    onClose: handleClose,
    listProducts: list,
    onFetch,
    onGetProducts,
  } = useWhiteListReadDialog()
  const {user} = useAuth()
  const organizationType = storage.getOrganizationType()

  const {onOpen: handleOpenWhiteListUpdateDialog} = useWhiteListUpdateDialog()

  const handleEditClick = () => {
    if (isDefined(uuid)) {
      handleClose()
      handleOpenWhiteListUpdateDialog(uuid)
    }
  }

  useEffect(() => {
    if (open && isDefined(uuid)) {
      onFetch(uuid)
    }
    if (open && user?.orgId) {
      onGetProducts(user?.orgId?.toString())
    }
  }, [open, uuid, user?.orgId])

  const nameProduct = useMemo(() => {
    return (
      list.find((item) => item.id === patient?.healthProductId)?.displayName ||
      ''
    )
  }, [list, patient?.healthProductId])

  return (
    <Dialog open={open} onClose={handleClose} className={classes.container}>
      <div className={classes.containerTitle}>
        <div className={classes.closeButtonWrap}>
          <IconButton
            color='secondary'
            aria-label='favorite'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.titleWrap}>
          <DialogTitle className={classes.title}>
            {t('ICustomerRead')}
          </DialogTitle>
          <Button
            variant='outlined'
            disabled
            className={classes.onlineContactButton}
            startIcon={<HeadsetMicIcon />}
          >
            {t('IOnlineContact')}
          </Button>
        </div>
      </div>

      <Divider className={classes.line} />
      <DialogContent>
        <PatientSubInfo
          label={t('IChartNo')}
          content={patient?.chartNo}
          classes={classes}
        />
        <PatientSubInfo
          label={t('IRegistrationNumber')}
          content={patient?.latestVisitNo}
          classes={classes}
        />
        <PatientSubNameInfo
          label={t('IName')}
          firstName={patient?.firstName ?? ''}
          lastName={patient?.lastName ?? ''}
          classes={classes}
        />
        <PatientSubInfo
          label={t('IBirthday')}
          content={patient?.birth}
          classes={classes}
        />
        <PatientSubInfo
          label={t('IGender')}
          content={t(toGenderString(patient?.gender as GenderValue))}
          classes={classes}
        />
        <PatientSubNameInfo
          label={t('ICareDoctor')}
          firstName={patient?.primaryFirstName ?? ''}
          lastName={patient?.primaryLastName ?? ''}
          classes={classes}
        />
        {organizationType === 'HEALTH' && (
          <PatientSubInfo
            label={t('IHcAnalysisTypes')}
            content={nameProduct}
            classes={classes}
          />
        )}

        <Typography className={classes.padding} />
      </DialogContent>
      <div className={classes.buttonContainerBgColor}>
        <div className={classes.buttonContainer}>
          <ActionButton
            onClick={handleClose}
            color='default'
            className={classes.okButton}
          >
            {t('IOk')}
          </ActionButton>

          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            onClick={handleEditClick}
          >
            {t('IEdit')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default PatientReadWhiteListDialog
