import {
  AnalysisResultItem,
  EEGResult,
  fetchIndividualHRVResult,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import {Colors} from 'components/common/useBaseStyle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import ReportSubtitle from 'components/Text/ReportSubtitle'
import React, {Fragment, useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {
  ReportCustomPadding,
  ReportFetcherWrap,
  ReportTranslationSelectWrap,
  ReportWrap,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import useQuery from 'hooks/useQuery'
import useLanguageReport from 'hooks/useLanguageReport'

const getAnalysisTitle = (analysisType: string) => {
  if (analysisType === '41') return 'RR Time Series Data'
  if (analysisType === '43') return 'Time-Domain Results'
  if (analysisType === '44') return 'Frequency-Domain Results'
  if (analysisType === '45') return 'Nonlinear Results'
  return 'Unknown'
}

const StyledReport = styled.div`
  & .report {
    text-align: center;
    & .item {
      text-align: left;
      overflow: auto;
      &.flex {
        display: flex;
      }

      & .title {
        color: ${Colors.primary};
      }
    }
    & .item.flex .graph {
      flex: 1;
    }
  }
`

const RRTimeSeriesReport = ({index, analysisType, items}: ReportItemProps) => (
  <StyledReport>
    <ReportSubtitle number={String(index + 1)}>
      {getAnalysisTitle(analysisType)}
    </ReportSubtitle>
    <div className='report'>
      <div className='item'>
        <h3 className='title'>* RR Time Series</h3>
        <img className='graph' src={items[0].imgPath} alt='' />
      </div>
      <div className='item'>
        <h3 className='title'>* Selected Detrended RR Series</h3>
        <img className='graph' src={items[1].imgPath} alt='' />
      </div>
    </div>
  </StyledReport>
)

const BaseHrvReport = ({index, analysisType, items}: ReportItemProps) => (
  <StyledReport>
    <ReportSubtitle number={String(index + 1)}>
      {getAnalysisTitle(analysisType)}
    </ReportSubtitle>
    <div className='report'>
      <div className='item flex'>
        {items.map((item) => (
          <img className='graph' src={item.imgPath} alt='' />
        ))}
      </div>
    </div>
  </StyledReport>
)

interface ReportItemProps {
  index: number
  analysisType: string
  items: AnalysisResultItem[]
}

function ReportItem(props: ReportItemProps) {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.analysisType === '41') return <RRTimeSeriesReport {...props} />
  return <BaseHrvReport {...props} />
}

function HRVIndividualReport() {
  const {t, i18n} = useTranslation()
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()
  const [report, setReport] = useState<EEGResult>()
  const [renderingItems, setRenderingItems] =
    useState<Record<string, AnalysisResultItem[]>>()
  const clientName = useQuery().get('clientName')
  const asyncResult = useAsync(
    fetchIndividualHRVResult,
    [
      {
        individualRequestIds: [Number.parseInt(id, 10)],
        codeTypeDivision: 'HRV',
        codeType: 'I',
        codeVersion: '3.0',
        // imgType: 'WEB',
        locale: i18n.language as LanguageCode,
      },
    ],
    {
      executeOnMount: true,
      executeOnUpdate: false,
    },
  )

  useEffect(() => {
    if (asyncResult.result !== undefined) {
      const [response] = asyncResult.result.list
      if (response !== undefined) setReport(response)

      const sorted = response.resultDetailList
        .sort((a, b) => {
          const parsedA = Number.parseInt(a.analysisType, 10)
          const parsedB = Number.parseInt(b.analysisType, 10)

          if (parsedA > parsedB) return 1
          if (parsedA < parsedB) return -1

          if (parsedA === parsedB) {
            if (a.psdScaleOrder > b.psdScaleOrder) return 1
            if (a.psdScaleOrder < b.psdScaleOrder) return -1
          }
          return 0
        })
        .reduce((accr, curr) => {
          // @ts-ignore
          accr[curr.analysisType] = accr[curr.analysisType] ?? []
          // @ts-ignore
          accr[curr.analysisType] = [...accr[curr.analysisType], curr]
          // @ts-ignore
          return accr
        }, {})

      setRenderingItems(sorted)
    }
  }, [asyncResult.result])

  useEffect(() => {
    asyncResult.execute({
      individualRequestIds: [Number.parseInt(id, 10)],
      codeTypeDivision: 'HRV',
      codeType: 'I',
      codeVersion: '3.0',
      // imgType: 'WEB',
      locale: i18n.language as LanguageCode,
    })
  }, [i18n.language])

  useLanguageReport()

  return (
    <ReportWrap style={{width: '100%'}}>
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <ReportCustomPadding />
        <CardWithTitle
          title={String(t('IHRVIndividualTitle'))}
          style={{width: '100%'}}
        >
          {asyncResult.loading && <div>{t('ILoading')}</div>}
          {!asyncResult.loading && asyncResult.error !== undefined && (
            <div>{asyncResult.error.message}</div>
          )}
          {!asyncResult.loading && report !== undefined && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 40,
                marginBottom: 50,
              }}
            >
              <ReportInfo
                {...report.resultInfo}
                clientName={clientName}
                isShowEyeStatus={false}
              />
              {renderingItems !== undefined && (
                <Fragment>
                  {Object.entries(renderingItems).map((entry, index) => {
                    const [key, value] = entry
                    return (
                      <ReportItem
                        analysisType={key}
                        index={index}
                        items={value}
                      />
                    )
                  })}
                </Fragment>
              )}
            </div>
          )}
          {!asyncResult.loading &&
            asyncResult.error === undefined &&
            report === undefined && (
              <div style={{minHeight: 400}}>출력할 결과지가 없습니다.</div>
            )}
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default HRVIndividualReport
