import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    horizontalImage: {
      width: '100%',
      height: 'auto',
    },
    content: {
      '& table': {
        borderSpacing: 0,
        '& thead tr th': {
          boxSizing: 'border-box',
          textAlign: 'center',
          backgroundColor: '#eff0f5',
          padding: '10px 3px',
          fontSize: '12px',
          color: '#333',
          minWidth: '50px',
          '&:first-child': {borderTopLeftRadius: 5},
          '&:last-child': {borderTopRightRadius: 5},
        },
        '& tbody tr td': {
          boxSizing: 'border-box',
          '& a': {
            color: '#f8ac59',
            cursor: 'pointer',
          },
          padding: '10px',
          fontSize: '12px',
          color: '#000',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          background: '#fff',
          minWidth: '55px',
          border: '1px solid #e4e7eb',
          borderWidth: '0 1px 1px 1px',
          '&:first-child': {borderRightWidth: 0},
        },
        '& tbody tr:last-child td:first-child': {
          borderBottomLeftRadius: 5,
        },
        '& tbody tr:last-child td:last-child': {
          borderBottomRightRadius: 5,
        },
      },
    },
    contentError: {
      padding: '.5rem',
      fontSize: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eff0f5',
      color: '#333',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
