import GetAppIcon from '@material-ui/icons/GetApp'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CustomCheckbox from 'components/atoms/CheckBox'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import Pagination from 'components/molcules/Pagination'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import SubTitle from 'components/Text/SubTitle'
import useSalesUsage from 'features/sales/useSalesUsage'
import {getDefaultPeriodString} from 'helpers/dateHelper'
import pagingHelper from 'helpers/pagingHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {utils, writeFile} from 'xlsx'

const PackageTableContainer = styled.div`
  overflow: auto;
`
const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 43px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 10px;
    min-width: 80px;
    max-width: 100px;
  }
`

const PackageToolbar = styled.div`
  display: flex;
  justify-content: space-between;
`
const FilterWrap = styled.div`
  display: flex;
  gap: 20px;
`

const HeaderCells: HeadCell[] = [
  {id: '1', label: 'IPeriod'},
  {id: '2', label: 'IOrgNameShort'},
  {id: '3', label: 'IEmail'},
  {id: '4', label: 'IOrgProperty'},
  {id: '5', label: 'IJoin'},
  {id: '6', label: 'ICountry'},
  {id: '7', label: 'IWaveCount'},
  {id: '8', label: 'IEegIndvCount'},
  {id: '9', label: 'IIndv3DRoi'},
  {id: '9', label: 'IEegNormCount'},
  {id: '9', label: 'INorm3DRoiCount'},
  {id: '10', label: 'IMciCount'},
  {id: '11', label: 'IEegSummary'},
  {id: '12', label: 'IEegAdvancedSummary'},
  {id: '13', label: 'IHrvIndv'},
  {id: '14', label: 'IHrvIndvSummary'},
  {id: '12', label: 'IHrvAdvancedSummary'},
  {id: '15', label: 'IEdf'},
  {id: '16', label: 'ILed'},
]

const PeriodUsageTable = () => {
  const {t} = useTranslation()
  const hearder = [
    [
      `${t('IPeriod')}`,
      `${t('IOrgNameShort')}`,
      `${t('IEmail')}`,
      `${t('IOrgProperty')}`,
      `${t('IJoin')}`,
      `${t('ICountry')}`,
      `${t('IWaveCount')}`,
      `${t('IEegIndvCount')}`,
      `${t('IIndv3DRoi')}`,
      `${t('IEegNormCount')}`,
      `${t('INorm3DRoiCount')}`,
      `${t('IMciCount')}`,
      `${t('IEegSummary')}`,
      `${t('IEegAdvancedSummary')}`,
      `${t('IHrvIndv')}`,
      `${t('IHrvIndvSummary')}`,
      `${t('IHrvAdvancedSummary')}`,
      `${t('IEdf')}`,
      `${t('ILed')}`,
    ],
  ]
  const {periodUsageList: usageData, onSearch} = useSalesUsage()
  const items = usageData?.items ?? []

  const [selection, setSelection] = React.useState<PeriodString>(
    getDefaultPeriodString(),
  )
  const [removeMou, setRemoveMou] = React.useState(false)
  const [removeDemo, setRemoveDemo] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(0)

  const handleValue = (value: PeriodDate) => {
    const selectPeriod: PeriodString = {
      startDate: String(value.startDate),
      endDate: String(value.endDate),
    }
    setSelection(selectPeriod)
  }
  const handlePagination = (value: number) => {
    setCurrentPage(value)
    onSearch({
      beginDate: selection.startDate,
      endDate: selection.endDate,
      ignoreFree: false,
      forDownload: false,
      page: value + 1,
      size: 5,
    })
  }
  const handleCheckRemoveMou = () => setRemoveMou(!removeMou)
  const handleCheckRemoveDemo = () => setRemoveDemo(!removeDemo)
  const handleExcelExport = () => {
    const wb = utils.book_new()
    const ws = utils.json_to_sheet(items)
    utils.sheet_add_aoa(ws, hearder)
    utils.book_append_sheet(wb, ws, 'Sheet1')
    writeFile(wb, 'ecle.xlsx')
  }

  useEffect(() => {
    if (removeMou) {
      onSearch({
        beginDate: selection.startDate,
        endDate: selection.endDate,
        ignoreFree: true,
        forDownload: false,
        page: 1,
        size: 5,
      })
    }
  }, [removeMou])
  // TODO: 데모 계정 삭제
  useEffect(() => {
    if (removeMou) {
      onSearch({
        beginDate: selection.startDate,
        endDate: selection.endDate,
        ignoreFree: true,
        forDownload: false,
        page: 1,
        size: 5,
      })
    }
  }, [removeDemo])
  useEffect(() => {
    onSearch({
      beginDate: selection.startDate,
      endDate: selection.endDate,
      ignoreFree: false,
      forDownload: false,
      page: 1,
      size: 5,
    })
  }, [selection])

  return (
    <PackageTableContainer>
      <SubTitle>{t('IPeriodUsageTitle')}</SubTitle>
      <PackageToolbar>
        <RangeDatePicker
          onChangeValue={handleValue}
          dateValue={{
            startDate: new Date(selection.startDate),
            endDate: new Date(selection.endDate),
          }}
        />
        <FilterWrap>
          <CustomCheckbox
            id='period'
            checked={removeMou}
            onChange={handleCheckRemoveMou}
            label={t('IRemoveFree')}
          />
          <CustomCheckbox
            id='period'
            checked={removeDemo}
            onChange={handleCheckRemoveDemo}
            label={t('IRemoveDemo')}
          />
          <ActionButton
            startIcon={<GetAppIcon />}
            color='secondary'
            onClick={handleExcelExport}
          >
            {t('IExcelDownload')}
          </ActionButton>
        </FilterWrap>
      </PackageToolbar>
      <br />
      <PackageTable>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={item.label}
            length={HeaderCells.length}
            index={index}
          />
        ))}
        {items?.map((item, index) => (
          <RoundTableWrap key={index}>
            <td>{item.period}</td>
            <td>{item.organizationName}</td>
            <td>{item.email}</td>
            <td>{item.organizationProperty}</td>
            <td>{item.joinStatus}</td>
            <td>{item.country}</td>
            <td>{item.waveCount ?? 0}</td>
            <td>{item.eegIndividualCount ?? 0}</td>
            <td>{item.individual3dRoiCount ?? 0}</td>
            <td>{item.eegNormCount ?? 0}</td>
            <td>{item.eegNormSummaryCount ?? 0}</td>
            <td>{item.Norm3dRoiCount ?? 0}</td>
            <td>{item.mciCount ?? 0}</td>
            <td>{item.eegSummaryCount ?? 0}</td>
            <td>{item.eegAdvancedSummaryCount ?? 0}</td>
            <td>{item.hrvIndividualCount ?? 0}</td>
            <td>{item.hrvAdvancedSummaryCount ?? 0}</td>
            <td>{item.edfCount ?? 0}</td>
            <td>{item.ledCount ?? 0}</td>
          </RoundTableWrap>
        ))}
      </PackageTable>
      {items?.length !== 0 && (
        <Pagination
          totalPageCount={pagingHelper(usageData?.total ?? 1, 5)}
          currentPageIndex={currentPage}
          itemCountPerPage={5}
          setCurrentPageIndex={handlePagination}
          onItemCountPerPageChanged={() => {}}
          isItemCountPerPageEnabled={false}
          loading={false}
        />
      )}
      {items?.length === 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 300,
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          {t('INoData')}
        </div>
      )}
    </PackageTableContainer>
  )
}
export default PeriodUsageTable
