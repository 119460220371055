import {getPackageFullName} from 'helpers/paymentHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 36px;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
`
const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
`
const ContentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin: 0;
`
const TotalWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const BackgroundWrap = styled.div`
  width: 100%;
  background-color: #f7f7f8;
  border-radius: 4px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`
const Divider = styled.span`
  border: 0;
  height: 1px;
  background: #e2e2e2;
`

interface Products {
  productCode: string
  amount: number
  priceOfOnetime: number
}
interface Unpaid {
  yearMonth: string
  unpaidAmount: number
}

interface UsageHistories {
  begin: string
  end: string
  products: Products[]
  unpaid: Unpaid[]
}

const UsageHistory = () => {
  const {t} = useTranslation()

  const [usageHistories, setUsageHistories] = React.useState<UsageHistories>()

  useEffect(() => {
    setUsageHistories({
      begin: '',
      end: '',
      products: [
        {productCode: 'ㅁㄴ', amount: 100, priceOfOnetime: 1231},
        {productCode: 'ㅁㄴㅇㄹ', amount: 100, priceOfOnetime: 1231},
      ],
      unpaid: [{yearMonth: '2020', unpaidAmount: 10000}],
    })
  }, [])

  return (
    <Root>
      <BackgroundWrap>
        <ContentContainer>
          {!usageHistories && (
            <TitleContainer>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 700,
                  borderBottom: '1px solid #e0e0e0',
                }}
              >
                {t('INoData')}
              </div>
            </TitleContainer>
          )}
          {usageHistories &&
            usageHistories?.products.map((item) => (
              <TitleContainer>
                <TitleText style={{width: 120}}>
                  {getPackageFullName(item.productCode)} :
                </TitleText>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <TitleText>
                        {item.amount}회 / {item.priceOfOnetime}
                      </TitleText>
                    </div>
                    <span> </span>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ContentText>{t('IAmountUsed')} </ContentText>
                      {/* TODO: 총 사용금액 금액 필요  */}
                      <ContentText style={{textAlign: 'end', width: 150}}>
                        {item.priceOfOnetime} {t('IMonetaryUnit')}
                      </ContentText>
                    </span>
                  </div>
                </div>
              </TitleContainer>
            ))}
        </ContentContainer>
        {usageHistories?.unpaid &&
          usageHistories?.unpaid.map((item) => (
            <>
              <Divider />
              <TotalWrap>
                <TitleContainer>
                  <TitleText style={{width: 200}}>
                    {t('IUnPaid')} : {item.yearMonth}
                  </TitleText>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span> </span>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <ContentText>{t('IAmountUsed')} </ContentText>
                        <TitleText style={{textAlign: 'end', width: 150}}>
                          {item.unpaidAmount} {t('IMonetaryUnit')}
                        </TitleText>
                      </span>
                    </div>
                  </div>
                </TitleContainer>
              </TotalWrap>
            </>
          ))}
        {usageHistories?.unpaid && (
          <>
            <Divider />
            <TotalWrap>
              {/* TODO: 총 결제 금액 붙이기  */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <span> </span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ContentText>{t('IAllTotalPrice')} </ContentText>
                    <TitleText style={{textAlign: 'end', width: 150}}>
                      10000 {t('IMonetaryUnit')}
                    </TitleText>
                  </span>
                </div>
              </div>
            </TotalWrap>
          </>
        )}
      </BackgroundWrap>
    </Root>
  )
}
export default UsageHistory
