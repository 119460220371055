import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {EDFSearchRequest, SearchedEDF, searchEdfApi} from 'api/analysisApi'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchEdf = createAsyncThunk(
  'api/edf/search',
  async (payload: EDFSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchEdfApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface EDFState {
  loading: boolean
  error: any
  items: SearchedEDF[]
  period: PeriodString
  search: EdfSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: EDFState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(), // TODO: 시간 관련 나래와 협의
  search: {searchName: 'CHART', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'edfSearchSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchEdf.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search} = action.meta.arg
      state.search = search
      state.period = period
    })
    builder.addCase(searchEdf.fulfilled, (state, action) => {
      state.loading = false
      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalElements = totalElements
      state.pagingInfo.totalPages = totalPages
    })
    builder.addCase(searchEdf.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) => state.edf.loading
export const selectError = (state: RootState) => state.edf.error
export const selectItems = (state: RootState) => state.edf.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.edf.period)
export const selectSearch = (state: RootState) => state.edf.search
export const selectPaging = (state: RootState) => state.edf.paging
export const selectPagingInfo = (state: RootState) => state.edf.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.edf.period,
  search: state.edf.search,
  paging: state.edf.paging,
})
