import React, {useEffect} from 'react'
import styled from 'styled-components'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SubTitle from 'components/Text/SubTitle'
import {openPackagePaymentDialog} from 'features/modal/modalSlice'
import useOrganization from 'features/org/useOrganization'
import {useAppDispatch} from 'hooks'
import {useTranslation} from 'react-i18next'
import useOrgPurchase from 'features/org/useOrgPurchase'
import MyPackageTable from './Tables/MyPackageTable'
import PaymentHistoryTable from './Tables/PaymentHistoryTable'
import UsageHistoryTable from './Tables/UsageHistoryTable'
import Payment from './Payment'
import useUsageHistoryAndUnpaidVouchersViewModel from '../ViewModels/UsageHistoryAndUnpaidVouchersViewModel'
import {StyledButtonGray, StyledButtonPrimary} from '../Style'
import useViewModel from '../ViewModels/PaymentManageViewModel'
import {OrderSteps} from '../../Interfaces/Enums'
import OrderResponseModel from '../Models/OrderResponseModel'

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;

  & > * {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
`
const ContentHeaderBetween = styled(ContentHeader)`
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 15px;
`
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
  }

  & + div {
    margin-top: 50px;
  }
`
const ContentContainer = styled.div`
  width: 100%;
`

interface PaymentManagementProps {
  setOrder: React.Dispatch<React.SetStateAction<OrderResponseModel | undefined>>
  orderUnabled: boolean
}

function PaymentManagementPage({
  setOrder,
  orderUnabled,
}: PaymentManagementProps) {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {onChange: setOrderStep} = useOrgPurchase()
  const {organization} = useOrganization()
  const {getMyProducts} = useViewModel()

  const {
    usageHistoryAndUnpaidVouchersList,
    makeUsageHistoryProcessedList,
    unpaidVouchersList,
    makeUsageHistoryBeginEndMonthDate,
    isUnpaidVouchersListLoading,
    getInvoicedYearMonth,
  } = useUsageHistoryAndUnpaidVouchersViewModel()

  // 납부 버튼 disabled state
  const [isPaymentDisabled, setIsPaymentDisabled] =
    React.useState<boolean>(false)

  // const handlePackageChange = () => dispatch(openPackageSelectDialog())
  const handlePayment = () => dispatch(openPackagePaymentDialog())
  const handleProductsSelect = () => {
    setOrderStep(OrderSteps.ProductsSelect)
  }

  useEffect(() => {
    getMyProducts()
    getInvoicedYearMonth()
  }, [])

  // UsageHistory props and UsageHistory begin end date month
  useEffect(() => {
    makeUsageHistoryProcessedList()
    makeUsageHistoryBeginEndMonthDate()
  }, [usageHistoryAndUnpaidVouchersList])

  // TODO: 납부 버튼 핸들러 - 미납금 데이터 수정 후 작업 필요
  // if (unPaidOrder != null) {
  //   handlePayment()
  // }

  useEffect(() => {
    if (orderUnabled) {
      setOrder(undefined)
    }
  }, [orderUnabled])

  // 납부 버튼 disabled 설정
  useEffect(() => {
    if (
      !unpaidVouchersList ||
      (unpaidVouchersList && unpaidVouchersList.length < 1)
    ) {
      setIsPaymentDisabled(true)
    } else {
      setIsPaymentDisabled(false)
    }
  }, [unpaidVouchersList])

  return (
    <CardWithTitle>
      <ContentContainer>
        <ContentWrap>
          <ContentHeaderBetween>
            <SubTitle>{t('IMyProducts')}</SubTitle>
            {organization && (
              <StyledButtonPrimary onClick={handleProductsSelect}>
                {t('IBuy')}
              </StyledButtonPrimary>
            )}
          </ContentHeaderBetween>
          <MyPackageTable />
        </ContentWrap>
        <ContentWrap>
          <ContentHeaderBetween>
            <SubTitle>{t('IUnPaid')}</SubTitle>
            <StyledButtonGray
              onClick={handlePayment}
              disabled={isPaymentDisabled}
            >
              {t('IPayment')}
            </StyledButtonGray>
          </ContentHeaderBetween>
          <Payment
            isUnpaidVouchersListLoading={isUnpaidVouchersListLoading}
            unpaidVouchersList={unpaidVouchersList}
            usageHistoryAndUnpaidVouchersList={
              usageHistoryAndUnpaidVouchersList
            }
          />
        </ContentWrap>
        <ContentWrap>
          <ContentHeader>
            <SubTitle>{t('IPaymentHistory')}</SubTitle>
          </ContentHeader>
          <PaymentHistoryTable />
        </ContentWrap>
        <ContentWrap>
          <ContentHeader>
            <SubTitle>{t('IUsageHistory')}</SubTitle>
          </ContentHeader>
          <UsageHistoryTable />
        </ContentWrap>
      </ContentContainer>
    </CardWithTitle>
  )
}

export default PaymentManagementPage
