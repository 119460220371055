import {setLanguage} from 'api/storageApi'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import resources from './locales'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',
      caches: ['localstorage'],
    },
    lng: 'ko',
    fallbackLng: 'ko',
    debug: false,
  })
  .then(() =>
    i18n.on('languageChanged', (lng) => {
      const isAnalysisPage = window.location.href.includes('/analysis/')
      if (!isAnalysisPage) {
        setLanguage(lng as LanguageCode)
      }
    }),
  )

export default i18n
