import styled from 'styled-components'
import Colors from 'theme/Colors'

export const StyledHeaderTitle = styled.div`
  text-align: start;
  position: relative;
  bottom: 10px;
`

export enum ModalTypes {
  state = 'state',
}

export interface DimmedWrapStyleProps {
  dimPaddingTopTablet: number
  dimPaddingTopMobile: number
  dimPaddingRightTablet: number
  dimPaddingRightMobile: number
  dimPaddingBottomTablet: number
  dimPaddingBottomMobile: number
  dimPaddingLeftTablet: number
  dimPaddingLeftMobile: number
}

export interface ModalHeaderWrapStyleProps {
  paddingHeaderTopTablet: number
  paddingHeaderTopMobile: number
  paddingHeaderBottomTablet: number
  paddingHeaderBottomMobile: number
  brHeaderBottom?: string
  textAlign?: string
}

export interface ModalFooterWrapStyleProps {
  paddingFooterTopTablet: number
  paddingFooterTopMobile: number
  paddingFooterBottomTablet: number
  paddingFooterBottomMobile: number
  bgFooter: string
}

export interface ModalWrapStyleProps {
  fixMaxWidth: number
  borderColor: string
  bgcolor?: string
  fixMaxHeight?: string
}

export interface ModalCommonStyleProps {
  paddingRightTablet: number
  paddingRightMobile: number
  paddingLeftTablet: number
  paddingLeftMobile: number
  paddingTopTablet?: number
  paddingTopMobile?: number
  headerBorderWidth?: string
  headerBorderStyle?: string
}

export interface CloseButtonWrapStyleProps {
  closeIconColor: string
  closeIconWidth: number
  closeIconHeight: number
}

export interface ModalAllStyleProps
  extends ModalCommonStyleProps,
    ModalHeaderWrapStyleProps,
    ModalFooterWrapStyleProps,
    ModalWrapStyleProps,
    DimmedWrapStyleProps,
    CloseButtonWrapStyleProps {
  titleFontSize?: number
  modalZindex?: number
  titleTextColor?: string
}

export const LargeWidthModalStyles = {
  paddingHeaderTopTablet: 64,
  paddingHeaderBottomTablet: 48,
  paddingFooterTopTablet: 48,
  paddingFooterBottomTablet: 64,
  paddingRightTablet: 60,
  paddingLeftTablet: 60,

  paddingHeaderTopMobile: 30,
  paddingHeaderBottomMobile: 20,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingRightMobile: 36,
  paddingLeftMobile: 36,

  closeIconColor: Colors.common.grays.g_02,
  closeIconWidth: 24,
  closeIconHeight: 24,
  closeIconWidthSmMobile: 18,
  closeIconHeightSmMobile: 18,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 1100,
  borderColor: Colors.border.card,
}

export const ProductInfoModal = {
  paddingHeaderTopTablet: 15,
  paddingHeaderBottomTablet: 15,
  paddingFooterTopTablet: 15,
  paddingFooterBottomTablet: 15,
  paddingRightTablet: 0,
  paddingLeftTablet: 0,

  paddingHeaderTopMobile: 10,
  paddingHeaderBottomMobile: 10,
  paddingFooterTopMobile: 10,
  paddingFooterBottomMobile: 10,
  paddingRightMobile: 0,
  paddingLeftMobile: 0,

  closeIconColor: Colors.common.grays.g_01,
  closeIconWidth: 10,
  closeIconHeight: 10,
  closeIconWidthSmMobile: 10,
  closeIconHeightSmMobile: 10,

  dimPaddingTopTablet: 10,
  dimPaddingRightTablet: 10,
  dimPaddingBottomTablet: 10,
  dimPaddingLeftTablet: 10,

  dimPaddingTopMobile: 10,
  dimPaddingRightMobile: 10,
  dimPaddingBottomMobile: 10,
  dimPaddingLeftMobile: 10,

  fixMaxWidth: 700,
  borderColor: Colors.border.card,
  bgcolor: '#fff',
}

export const LedCareInfoModal = {
  margin: 0,
  paddingHeaderTopTablet: 15,
  paddingHeaderBottomTablet: 15,
  paddingFooterTopTablet: 15,
  paddingFooterBottomTablet: 15,
  paddingRightTablet: 0,
  paddingLeftTablet: 0,

  paddingHeaderTopMobile: 10,
  paddingHeaderBottomMobile: 10,
  paddingFooterTopMobile: 10,
  paddingFooterBottomMobile: 10,
  paddingRightMobile: 0,
  paddingLeftMobile: 0,

  closeIconColor: Colors.common.grays.g_01,
  closeIconWidth: 10,
  closeIconHeight: 10,
  closeIconWidthSmMobile: 10,
  closeIconHeightSmMobile: 10,

  dimPaddingTopTablet: 10,
  dimPaddingRightTablet: 10,
  dimPaddingBottomTablet: 10,
  dimPaddingLeftTablet: 10,

  dimPaddingTopMobile: 10,
  dimPaddingRightMobile: 10,
  dimPaddingBottomMobile: 10,
  dimPaddingLeftMobile: 10,

  fixMaxWidth: 896,
  fixMaxHeight: '600px',
  borderColor: Colors.border.card,
  titleFontSize: 20,
  titleTextColor: '#333',
  textAlign: 'left',

  '& > h4': {
    marginBlock: 0,
  },
  bgFooter: '#f4f4f4',
  headerBorderWidth: '0px 0px 1px 0px !important',
  headerBorderStyle: 'solid #F1F1F1',
}

export const SmallWidthModalStyles = {
  paddingHeaderTopTablet: 64,
  paddingHeaderBottomTablet: 48,
  paddingFooterTopTablet: 48,
  paddingFooterBottomTablet: 64,
  paddingRightTablet: 80,
  paddingLeftTablet: 80,

  paddingHeaderTopMobile: 30,
  paddingHeaderBottomMobile: 20,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingRightMobile: 30,
  paddingLeftMobile: 30,

  closeIconColor: Colors.common.grays.g_02,
  closeIconWidth: 24,
  closeIconHeight: 24,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 700,
  borderColor: Colors.border.card,
}

export const VerySmallWidthModalStyles = {
  paddingHeaderTopTablet: 15,
  paddingHeaderBottomTablet: 12,
  paddingFooterTopTablet: 20,
  paddingFooterBottomTablet: 20,

  paddingRightTablet: 26,
  paddingLeftTablet: 26,

  paddingHeaderTopMobile: 5,
  paddingHeaderBottomMobile: 5,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingRightMobile: 10,
  paddingLeftMobile: 10,

  closeIconColor: '#B3B0B0',
  closeIconWidth: 15,
  closeIconHeight: 15,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 412,

  titleFontSize: 15,
  titleTextColor: '#333333',

  // Background Color
  bgFooter: '#F4F4F4',

  // Border
  brHeaderBottom: `1px solid #F1F1F1`,
  borderColor: Colors.border.card,
}

export const StateModalStyles = {
  paddingHeaderTopTablet: 50,
  paddingHeaderBottomTablet: 30,
  paddingFooterTopTablet: 30,
  paddingFooterBottomTablet: 50,
  paddingTopTablet: 50,
  paddingRightTablet: 30,
  paddingLeftTablet: 30,

  paddingHeaderTopMobile: 30,
  paddingHeaderBottomMobile: 20,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingTopMobile: 30,
  paddingRightMobile: 30,
  paddingLeftMobile: 30,

  closeIconColor: Colors.common.grays.g_02,
  closeIconWidth: 0,
  closeIconHeight: 0,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 400,
  borderColor: Colors.border.card,
}

export const VerySmallModalStyles = {
  paddingHeaderTopTablet: 50,
  paddingHeaderBottomTablet: 30,
  paddingFooterTopTablet: 30,
  paddingFooterBottomTablet: 50,
  paddingTopTablet: 50,
  paddingRightTablet: 30,
  paddingLeftTablet: 30,

  paddingHeaderTopMobile: 30,
  paddingHeaderBottomMobile: 20,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingTopMobile: 30,
  paddingRightMobile: 30,
  paddingLeftMobile: 30,

  closeIconColor: Colors.common.grays.g_02,
  closeIconWidth: 0,
  closeIconHeight: 0,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 400,
  borderColor: Colors.border.card,

  titleFontSize: 20,
  textWeight: 300,
}

export const SmallModalStyles = {
  paddingHeaderTopTablet: 50,
  paddingHeaderBottomTablet: 30,
  paddingFooterTopTablet: 30,
  paddingFooterBottomTablet: 50,
  paddingTopTablet: 50,
  paddingRightTablet: 30,
  paddingLeftTablet: 30,

  paddingHeaderTopMobile: 30,
  paddingHeaderBottomMobile: 20,
  paddingFooterTopMobile: 20,
  paddingFooterBottomMobile: 30,
  paddingTopMobile: 30,
  paddingRightMobile: 30,
  paddingLeftMobile: 30,

  closeIconColor: Colors.common.grays.g_02,
  closeIconWidth: 0,
  closeIconHeight: 0,

  dimPaddingTopTablet: 60,
  dimPaddingRightTablet: 40,
  dimPaddingBottomTablet: 60,
  dimPaddingLeftTablet: 40,

  dimPaddingTopMobile: 30,
  dimPaddingRightMobile: 15,
  dimPaddingBottomMobile: 30,
  dimPaddingLeftMobile: 15,

  fixMaxWidth: 470,
  borderColor: Colors.border.card,

  titleFontSize: 20,
  textWeight: 300,
}
