import CardWithTitle from 'components/atoms/CardWithTitle'
import React from 'react'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import PeriodUsageTable from './Tables/PeriodUsageTable'
import PersonalUsageTable from './Tables/PersonalUsageTable'
import SalesOldUsageInquiryPage from './Tables/OldUsageInquiry'
import {isProd} from '../../../helpers/envHelper'

export default function SalesUsageInquiryPage() {
  return (
    <>
      {isProd() && <SalesOldUsageInquiryPage />}
      {!isProd() && (
        <div>
          <CardWithTitle>
            <PeriodUsageTable />
          </CardWithTitle>
          <br />
          <CardWithTitle>
            <PersonalUsageTable />
          </CardWithTitle>
        </div>
      )}
    </>
  )
}
