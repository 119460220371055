import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {useAppDispatch} from 'hooks'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {fetchSalesIndexSearchAction} from 'features/sales/salesSlice'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useEegIndividualSearch from 'features/analysis/useEegIndividualSearch'

interface SelectCompanySearchProps {
  onSearch: (value?: string) => void
}

interface Organizations {
  name: string
  oid: string
}

export default function CompanySearch({onSearch}: SelectCompanySearchProps) {
  const classes = useToolbarStyles()
  const dispatch = useAppDispatch()
  const outlinedInputClasses = useOutlinedInputStyles()

  const {t} = useTranslation()

  const [allOrganizations, setAllOrganizations] = useState<Organizations[]>()
  const [organizationOid, setOrganizationOid] = useState('')

  const handleChange = (value: string) => {
    setOrganizationOid(value)
    onSearch(value)
  }

  const {query, onSearch: eegSearch} = useEegIndividualSearch()
  const getOrganizationList = async () => {
    const result = await dispatch(
      fetchSalesIndexSearchAction({index: null}),
    ).unwrap()
    setAllOrganizations(result)
  }

  useEffect(() => {
    getOrganizationList()
  }, [])

  return (
    <div>
      <FormControl variant='outlined'>
        <div style={{display: 'flex', gap: 3}}>
          <Select
            displayEmpty
            // defaultValue='전체선택'
            className={classes.searchSelect}
            onChange={(event) => {
              handleChange(event.target.value as string)
            }}
            input={
              <OutlinedInput
                name='age'
                id='outlined-age-simple'
                classes={outlinedInputClasses}
              />
            }
          >
            <MenuItem selected>전체선택</MenuItem>
            {allOrganizations?.map((item) => (
              <MenuItem value={item.oid} key={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <button
            type='submit'
            onClick={() => {
              eegSearch({...query, oid: organizationOid})
            }}
            className={classes.searchWrap}
            style={{
              backgroundColor: 'white',
              color: '#666a6c',
              padding: '0 15px',
            }}
          >
            {t('ISearch')}
          </button>
        </div>
      </FormControl>
    </div>
  )
}
