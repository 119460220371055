import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Roles} from 'Authority'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SearchBar from 'components/Forms/SearchBar'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import {ADMIN_INFO_DIALOG_TYPE} from 'constants/DialogConstant'
import {openSalesUserDetailInfoEditDialog} from 'features/modal/modalSlice'
import {
  searchSalesOrgUserAction,
  selectSales,
  selectSalesUsers,
} from 'features/sales/salesSlice'
import {getDefaultPeriodString} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 15,
      padding: 30,
    },
    tableHeader: {
      backgroundColor: '#e9ecef',
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
    button: {
      height: 55,
      minWidth: 110,
      maxWidth: 110,
      '& .span': {
        textTransform: 'none',
      },
    },
    tableWrap: {
      backgroundColor: '#fff',
      // border: '1px solid #D6D6D8',
      // borderRadius: 5,
      marginTop: 10,
      marginBottom: 30,
      overflow: 'auto',
    },
    hidden: {
      visibility: 'hidden',
    },
    titleWrap: {
      minWidth: 300,
    },
    dateWrap: {
      minWidth: 120,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
        cursor: 'pointer',
      },
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      marginRight: 5,
    },
    disableText: {
      color: '#a6a6a6',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#fff',
      border: '1px solid #BBBBBE',
      borderRadius: 4,
      padding: '0 10px',
      maxWidth: 300,
    },
    padding: {
      padding: theme.spacing(1),
    },
  }),
)

function makeChipLabel(data: number) {
  const chipLabel = `Total ${data}`
  return chipLabel
}

function makeAuthorityName(authority: string): string {
  if (
    authority === Roles.ROLE_ORG_ADMIN ||
    authority === Roles.ROLE_SHAREWARE_ADMIN ||
    authority === Roles.ROLE_COMPANY_ADMIN
  ) {
    return 'Admin'
  }
  if (
    authority === Roles.ROLE_ORG_RESEARCHER ||
    authority === Roles.ROLE_SHAREWARE_RESEARCHER ||
    authority === Roles.ROLE_COMPANY_RESEARCHER
  ) {
    return 'Researcher'
  }
  if (
    authority === Roles.ROLE_ORG_USER ||
    authority === Roles.ROLE_SHAREWARE_USER ||
    authority === Roles.ROLE_COMPANY_USER
  ) {
    return 'Researcher'
  }
  return '-'
}

export default function SalesRegisteredUserTable() {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const outlinedInputClasses = useOutlinedInputStyles()
  const searchedDetail = useSelector(selectSales)
  const searchedUsers = useSelector(selectSalesUsers) as SearchedUser[]

  const [chipLabel, setChipLabel] = React.useState('0')
  const [searchValue, setSearchValue] = React.useState('')

  const filterList = [
    t('IName'),
    t('IPhone'),
    t('IUserDepartment'),
    t('IEmail'),
  ]
  const filterListMap = filterList.map((value) => (
    <MenuItem value={value}>{value}</MenuItem>
  ))

  const makeSelectedFilter = (string: string): UserSearchKind | null => {
    if (string === t('IName')) return 'NAME'
    if (string === t('IPhone')) return 'TEL'
    if (string === t('IUserDepartment')) return 'DEPARTMENT'
    if (string === t('IEmail')) return 'EMAIL'
    return null
  }
  const [filterValue, setFilterValue] = React.useState('')
  const filterOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    setFilterValue(event.target.value as string)
  }

  useEffect(() => {
    setChipLabel(makeChipLabel(searchedDetail.searchedDetailData?.total ?? 0))
  }, [searchedDetail.searchedDetailData?.total])

  const handleSearch = () => {
    dispatch(
      searchSalesOrgUserAction({
        uid: searchedDetail.searchedDetailData?.userInfoList[0].uid,
        paging: {size: 10, page: 0},
        search: {
          searchName: makeSelectedFilter(filterValue),
          searchValue,
        },
        period: getDefaultPeriodString(),
      }),
    )
  }

  const handleValue = (value: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    return setSearchValue('')
  }, [])

  return (
    <div>
      <CardWithTitle title={t('IRegisteredUsers')}>
        <div className={classes.toolbar}>
          <Chip size='small' label={chipLabel} />
          {searchedDetail.searchedDetailData !== null && (
            <div className={classes.searchBar}>
              <FormControl variant='outlined' className={classes.selectBox}>
                <Select
                  value={filterValue}
                  onChange={filterOnchange}
                  displayEmpty
                  color='primary'
                  className={classes.selectBox}
                  input={
                    <OutlinedInput
                      name='age'
                      id='outlined-age-simple'
                      classes={outlinedInputClasses}
                    />
                  }
                >
                  <MenuItem disabled value=''>
                    <div className={classes.disableText}>
                      {t('ISelectFilter')}
                    </div>
                  </MenuItem>
                  {filterListMap}
                </Select>
              </FormControl>
              <SearchBar
                onChangeValue={handleValue}
                onSearchEvent={handleSearch}
                disabledValue={false}
              />
            </div>
          )}
        </div>
        <Grid xs={12} className={classes.tableWrap}>
          <Table size='medium'>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('IOrgName')}</Typography>
                </TableCell>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('IName')}</Typography>
                </TableCell>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('IEmail')}</Typography>
                </TableCell>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('IUserDepartment')}</Typography>
                </TableCell>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('ITel')}</Typography>
                </TableCell>
                <TableCell className={classes.dateWrap}>
                  <Typography align='center'>{t('IAuthority')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedUsers != null &&
                searchedUsers.map((item) => (
                  <TableRow
                    // key={index}
                    hover
                    classes={{hover: classes.hover}}
                    onClick={() => {
                      dispatch(
                        openSalesUserDetailInfoEditDialog({
                          dialogType: ADMIN_INFO_DIALOG_TYPE,
                          uid: item.uid ?? '',
                        }),
                      )
                    }}
                  >
                    <TableCell align='center'>{item.orgName ?? '-'}</TableCell>
                    <TableCell align='center'>{item.name ?? '-'}</TableCell>
                    <TableCell align='center'>{item.email ?? '-'}</TableCell>
                    <TableCell align='center'>
                      {item.department ?? '-'}
                    </TableCell>
                    <TableCell align='center'>{item.tel ?? '-'}</TableCell>
                    <TableCell align='center'>
                      {makeAuthorityName(item.authority ?? '-')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </CardWithTitle>
    </div>
  )
}
