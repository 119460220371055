import textLeft from 'assets/img/text_left.png'
import textRight from 'assets/img/text_right.png'
import therapyBrain from 'assets/img/therapy_brain.png'
import NormalButton from 'components/V2Buttons/NormalButton/NormalButton'
import {
  PrimaryButtonStyles,
  SecondaryButtonStyles,
} from 'components/V2Buttons/NormalButton/Styles'
import OutlineButton from 'components/V2Buttons/OutlineButton/OutlineButton'
import {
  SmallLedOffButtonStyles,
  SmallLedOnButtonStyles,
} from 'components/V2Buttons/OutlineButton/Styles'
import CommonDialog from 'components/V2Dialog/Templates/CommonDialog/CommonDialog'
import {
  StyledBrainContainer,
  StyledBrainImgContainer,
  StyledBrainImgInwrap,
  StyledButtonGroupAlignRight,
  StyledExplanation,
  StyledTextEditInfoContainer,
  StyledTextImgWrapLeft,
  StyledTextImgWrapRight,
  StyledTextInfoGroup,
  StyledTherapyPointCheckbox,
  StyledTherapyPointWrap,
} from 'components/V2Dialog/TherapyDialog/Style'
import {StyledButtonGroup} from 'components/V2Modal/Modal/Modal'
import {SmallWidthModalStyles} from 'components/V2Modal/Modal/Styles'
import Select, {ValueSelectItems} from 'components/V2Select/Select/Select'
import {NormalSelectTopShortStyles} from 'components/V2Select/Select/Styles'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useTherapy from 'features/therapy/useTherapy'
import {
  useCreateDialog,
  useUpdateDialog,
} from 'features/therapy/useTherapyDialog'
import {isDefined} from 'helpers/commonHelper'
import React, {useEffect} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import useTherapyDialogViewModels from '../TherapyDialogViewModels'
import {MultiTherapySettings} from '../../../../../@types'

interface TherapyId {
  therapyId: string
  pbmId: string
}

/**
 * TODO: 프리셋 삭제하는 기능은 없으므로 추가구현 해야함
 */

const TherapyEditDialog = ({therapyId, pbmId}: TherapyId) => {
  const channels = [
    'c3',
    'c4',
    'cz',
    'f3',
    'f4',
    'f7',
    'f8',
    'fp1',
    'fp2',
    'fz',
    'o1',
    'o2',
    'p3',
    'p4',
    'pz',
    't3',
    't4',
    't5',
    't6',
  ]

  const {t} = useTranslation()

  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const {onOpen: onTherapyPrescriptionDialogOpen} = useCreateDialog()

  const {open, onClose: handleClose} = useUpdateDialog()

  const {
    setCustomerId,
    therapyPresetLists,
    setFrequency,
    setDuration,
    onAddTherapyPreset: addTherapyPreset,
    onUpdateTherapyPreset: updateTherapyPreset,
    electrodeData,
  } = useTherapy()

  const {
    frequencyValues,
    settingTimeValues,
    frequencyThumb,
    setFrequencyThumb,
    settingTimeThumb,
    setSettingTimeThumb,
    handleSubmit,
    setValue,
  } = useTherapyDialogViewModels()

  const [initPresetState, setInitPresetState] = React.useState(new Map())

  const addPreset = (key: string, value: boolean) => {
    setInitPresetState((prev) => new Map([...prev, [key, value]]))
  }
  const updatePreset = (key: string, value: boolean) => {
    setInitPresetState((prev) => new Map(prev).set(key, value))
  }

  const handleAllTherapyCheckButton = (checked: boolean) => {
    channels.forEach((element) => {
      addPreset(element, checked)
    })
  }

  const handlePrescriptionClick = () => {
    if (isDefined(therapyId)) {
      handleClose()
      onTherapyPrescriptionDialogOpen(therapyId)
    }
  }

  const isChecked = (ele: MultiTherapySettings) =>
    JSON.stringify(ele).indexOf('true') === -1

  const isEdited = () =>
    therapyPresetLists &&
    therapyPresetLists.list &&
    therapyPresetLists.list.length > 0

  const onSubmit = async () => {
    // ElectrodeSettings 형태로 만드는 함수
    const electrode = channels.reduce(
      (obj, item) => ({...obj, [item]: initPresetState.get(item)}),
      {} as MultiTherapySettings,
    )
    if (isChecked(electrode)) onFailureModalOpen(t('ISelectCare'))
    // preset 수정
    if (!isChecked(electrode) && isEdited() && electrodeData) {
      const {groupId, presetDataId} = electrodeData
      updateTherapyPreset.mutate({electrode, groupId, dataId: presetDataId})
    }
    // preset 등록
    if (!isChecked(electrode) && !isEdited()) {
      addTherapyPreset.mutate(electrode)
    }
  }
  const onSubmitHandler: SubmitHandler<any> = () => onSubmit()

  useEffect(() => {
    if (open && isDefined(therapyId)) {
      setCustomerId(therapyId)
    }
    if (!open) {
      channels.forEach((element) => {
        addPreset(element, false)
      })
    }
  }, [open, therapyId])

  useEffect(() => {
    if (addTherapyPreset.isSuccess) {
      handleClose()
    }
  }, [addTherapyPreset.status])

  useEffect(() => {
    if (updateTherapyPreset.isSuccess) {
      handleClose()
    }
  }, [updateTherapyPreset.status])

  useEffect(() => {
    if (
      therapyPresetLists &&
      therapyPresetLists.list &&
      therapyPresetLists.list.length > 0 &&
      electrodeData?.electrode
    ) {
      channels.forEach((element) => {
        const keyName = element
        addPreset(element, electrodeData?.electrode[keyName])
      })
    }
  }, [therapyPresetLists])

  useEffect(() => {
    channels.forEach((element) => {
      addPreset(element, false)
    })
    setDuration(Number(settingTimeThumb.value))
    setFrequency(Number(frequencyThumb.value))
  }, [])

  const initBody = (
    <>
      {channels.map((element) => {
        return (
          <StyledTherapyPointCheckbox
            key={element}
            type='checkbox'
            name={element}
            value='N'
            className={element}
            onChange={(e) => {
              updatePreset(element, e.target.checked)
            }}
            checked={initPresetState.get(element)}
          />
        )
      })}
    </>
  )

  const body = (
    <form onSubmit={handleSubmit(onSubmitHandler)} id='therapyEdit'>
      <StyledBrainContainer>
        <StyledButtonGroupAlignRight>
          <OutlineButton
            theme={SmallLedOnButtonStyles}
            title={t('ISelectAllLed')}
            event={() => handleAllTherapyCheckButton(true)}
            disabled={false}
          />
          <OutlineButton
            theme={SmallLedOffButtonStyles}
            title={t('IDeselectAllLed')}
            event={() => handleAllTherapyCheckButton(false)}
            disabled={false}
          />
        </StyledButtonGroupAlignRight>
        <StyledBrainImgContainer>
          <StyledBrainImgInwrap>
            <img src={therapyBrain} alt='therapy_brain' />
            <StyledTherapyPointWrap>{initBody}</StyledTherapyPointWrap>
            <StyledTextImgWrapLeft>
              <img src={textLeft} alt='text_left' />
            </StyledTextImgWrapLeft>
            <StyledTextImgWrapRight>
              <img src={textRight} alt='text_right' />
            </StyledTextImgWrapRight>
          </StyledBrainImgInwrap>
        </StyledBrainImgContainer>
        <StyledTextEditInfoContainer>
          <StyledTextInfoGroup>
            <Select
              thumb={frequencyThumb.label}
              values={frequencyValues}
              handler={(items, index) => {
                const newData: ValueSelectItems = items[
                  index
                ] as ValueSelectItems
                setFrequencyThumb(newData)
                setValue('frequency', newData.value)
                setFrequency(Number(newData.value))
              }}
              theme={NormalSelectTopShortStyles}
            />
            <Select
              thumb={settingTimeThumb.label}
              values={settingTimeValues}
              handler={(items, index) => {
                const newData: ValueSelectItems = items[
                  index
                ] as ValueSelectItems
                setSettingTimeThumb(newData)
                setValue('settingTime', newData.value)
                setDuration(Number(newData.value))
              }}
              theme={NormalSelectTopShortStyles}
            />
          </StyledTextInfoGroup>
          <StyledExplanation> {t('ISelectCare')}</StyledExplanation>
        </StyledTextEditInfoContainer>
      </StyledBrainContainer>
    </form>
  )

  const footer = (
    <StyledButtonGroup>
      <NormalButton
        theme={SecondaryButtonStyles}
        title={t('ICancel')}
        event={() => handleClose()}
        disabled={false}
      />
      <NormalButton
        theme={PrimaryButtonStyles}
        title={t('IPrescription')}
        event={handlePrescriptionClick}
        submitForm='therapyEdit'
        disabled={addTherapyPreset.isLoading}
      />
    </StyledButtonGroup>
  )

  return (
    <>
      <CommonDialog
        open={open}
        modalTitle={t('ITherapyReservationTitle')}
        handleClose={handleClose}
        theme={SmallWidthModalStyles}
        body={body}
        footer={footer}
        style={{
          opacity: !open ? 0 : 1,
          userSelect: !open ? 'none' : 'auto',
        }}
      />
    </>
  )
}

export default TherapyEditDialog
