import {Colors} from 'components/common/useBaseStyle'
import ReportDescription from 'components/Report/ReportDescription'
import React, {ReactNode} from 'react'
import styled from 'styled-components'

const StyledReportHeader = styled.div`
  border-bottom: 2px solid #2f404f;
  
  & .Header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  & .Title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: ${Colors.darkblue};
  }

  & .Number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.darkblue};
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }

  & .Buttons {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }
}
`

interface ReportHeaderProps {
  index: number
  title: string
  description?: string
  buttons?: ReactNode
  hideDescription?: boolean
  hideButtons?: boolean
}

function ReportHeader({
  index,
  title,
  description = '',
  buttons,
  hideDescription = false,
  hideButtons = false,
}: ReportHeaderProps) {
  return (
    <StyledReportHeader>
      <div className='Header'>
        <h3 className='Title'>
          <span className='Number'>{index}</span> {title}
        </h3>
        {!hideButtons && buttons && <div className='Buttons'>{buttons}</div>}
      </div>
      {!hideDescription && description?.trim().length !== 0 && (
        <ReportDescription htmlText={description} />
      )}
    </StyledReportHeader>
  )
}

export default ReportHeader
