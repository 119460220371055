import {Channel, getPair, isPairable} from 'constants/AnalysisConstant'
import BarGraph from 'pages/Popup/PowerSpectrum/graph/BarGraph'
import LineGraph from 'pages/Popup/PowerSpectrum/graph/LineGraph'
import React, {useMemo} from 'react'

export const identity: <T>(d: T) => T = (d) => d

export const channelAccessor = (c: Channel) => (d: PowerSpectrumData) => d[c]

export type PowerSpectrumData = {
  [channel in Channel]: number
} & {
  hz: number
}

interface PowerSpectrumGraphProps {
  readonly data: PowerSpectrumData[]
  readonly channel: Channel
}

export default function Graph(props: PowerSpectrumGraphProps) {
  const {channel} = props

  const pairable = useMemo(() => isPairable(channel) ?? false, [channel])
  const pairableChannel: Channel = useMemo(
    () => getPair(channel) ?? channel,
    [channel, pairable],
  )

  const width = 1400
  const height = 700

  const BAR_GRAPH_HEIGHT = 250

  return (
    <div style={{flex: 1, minHeight: height}}>
      <LineGraph
        {...props}
        pairable={pairable}
        pairableChannel={pairableChannel}
        width={width}
        height={pairable ? height - BAR_GRAPH_HEIGHT : height}
      />
      {pairable && (
        <BarGraph
          {...props}
          width={width}
          height={BAR_GRAPH_HEIGHT}
          pairableChannel={pairableChannel}
        />
      )}
    </div>
  )
}
