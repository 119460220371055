import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  deleteSalesEmergencyApi,
  editSalesEmergencyApi,
  EmergencyNotifyEditRequest,
  EmergencyNotifyItems,
  EmergencyNotifyListRequest,
  EmergencyNotifyRequest,
  getSalesEmergencyApi,
  updateSalesEmergencyApi,
} from 'api/salesNoticeApi'
import pagingHelper from 'helpers/pagingHelper'
import {RootState} from 'store'

export const fetchEmergencyNotice = createAsyncThunk(
  'emergencyNoticeSlice/list',
  async (payload: EmergencyNotifyListRequest, {rejectWithValue}) => {
    try {
      const response = await getSalesEmergencyApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const UpdateEmergencyNotice = createAsyncThunk(
  'emergencyNoticeSlice/update',
  async (payload: EmergencyNotifyRequest, {dispatch, rejectWithValue}) => {
    try {
      const response = await updateSalesEmergencyApi(payload)
      await dispatch(
        fetchEmergencyNotice({
          page: 1,
          size: 100,
        }),
      ).unwrap()
      return response
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const deleteSalesEmergencyNoticeAction = createAsyncThunk(
  'emergencyNoticeSlice/delete',
  async (payload: EmergencyDeleteAction, {dispatch}) => {
    await deleteSalesEmergencyApi(payload)
    return dispatch(
      fetchEmergencyNotice({
        page: 1,
        size: 100,
      }),
    ).unwrap()
  },
)

export const editSalesEmergencyNoticeAction = createAsyncThunk(
  'emergencyNoticeSlice/Edit',
  async (payload: EmergencyNotifyEditRequest, {rejectWithValue}) => {
    try {
      const response = await editSalesEmergencyApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface EmergencyDeleteAction {
  notifies: number[]
}

export interface EmergencyEditAction {
  notifyId: number
}

export interface EmergencyNoticeState {
  loading: boolean
  error: any
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  noticeData: EmergencyNotifyItems[]
}

// 언어코드 일때 EN영어, 언어코드 KO일때 한국어

export const initialState: EmergencyNoticeState = {
  loading: false,
  error: null,
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 0,
  noticeData: [
    {
      id: 0,
      order: 0,
      isNotProceed: false,
      authorName: '',
      begin: '',
      end: '',
      contents: [
        {
          languageCode: 'EN',
          title: '',
          content: '',
          attachments: null,
        },
        {
          languageCode: 'KO',
          title: '',
          content: '',
          attachments: null,
        },
      ],
    },
  ],
}

export const emergencyNoticeSlice = createSlice({
  name: 'emergencyNoticeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmergencyNotice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchEmergencyNotice.fulfilled, (state, action) => {
      state.noticeData = action.payload.items
      state.loading = false
      state.error = null
      state.pageIndex = action.payload.page
      state.totalPages = pagingHelper(action.payload.total, action.payload.size)
      state.totalElements = action.payload.total
      state.itemPerPage = action.payload.size
    })
    builder.addCase(fetchEmergencyNotice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.salesEmergencyNotice.totalPages,
  pageIndex: state.salesEmergencyNotice.pageIndex,
  itemPerPage: state.salesEmergencyNotice.itemPerPage,
})

export const selectEmergencyPageIndex = (state: RootState) =>
  state.salesEmergencyNotice.pageIndex

export const selectEmergencyNoticeData = (state: RootState) =>
  state.salesEmergencyNotice.noticeData

export default emergencyNoticeSlice.reducer
