import React from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  Frontal_LH: number
  Temporal_LH: number
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number}) => {
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>{score}점</div>
      <Label score={score} />
    </div>
  )
}

function TablePrintP1({
  Frontal_LH,
  Temporal_LH,
  agingRoiScore,
}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const headTable = [
    t('IParietalLobe'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable5 = [
    {
      data: [
        {
          header: [
            {
              key: 'P1.',
              content: 'Postcentral',
              note: t('IPostCentralLobeP1'),
            },
          ],
          image: getHealthReportReportImage('brainP1.png'),
        },
        [t('IProcessesSomatosensoryP1')],
        [t('IDifficultiesP1'), t('IInsensitivityP1')],
        [getNumber(agingRoiScore?.Postcentral_LH)],
        [getNumber(agingRoiScore?.Postcentral_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P2.',
              content: 'Superior Parietal',
              note: t('ISuperiorParietalLobeP2'),
            },
          ],
          image: getHealthReportReportImage('brainP2.png'),
        },
        [t('IAnImportantRoleP2'), t('ILeftHemisphereP2')],
        [t('IDifficultyP2'), t('ILeftSideFunctionP2')],
        [getNumber(agingRoiScore?.Superior_Parietal_LH)],
        [getNumber(agingRoiScore?.Superior_Parietal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P3.',
              content: 'Inferior Parietal',
              note: t('IInferiorParietalLobeP3'),
            },
          ],
          image: getHealthReportReportImage('brainP3.png'),
        },
        [
          t('IPartThatReceivesP3'),
          t('ILeftHemisphereP3'),
          t('IRightHemisphereP3'),
        ],
        [t('IDifficultP3'), t('IRightSidedDysfunctionP3')],
        [getNumber(agingRoiScore?.Inferior_Parietal_LH)],
        [getNumber(agingRoiScore?.Inferior_Parietal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P4.',
              content: 'Supramarginal',
              note: t('IAssociationP4'),
            },
          ],
          image: getHealthReportReportImage('brainP4.png'),
        },
        [
          t('IRoleOfInterpretingP4'),
          t('ILeftHemisphereP4'),
          t('IRightHemisphereP4'),
        ],
        [t('IDecreasedP4'), t('ILeftSidedDysfunctionP4')],
        [getNumber(agingRoiScore?.Supramarginal_LH)],
        [getNumber(agingRoiScore?.Supramarginal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P5.',
              content: 'Precuneus',
              note: t('IPreLeafletP5'),
            },
          ],
          image: getHealthReportReportImage('brainP5.png'),
        },
        [
          t('IRelatedToSelfImageP5'),
          t('ILeftHemisphereP5'),
          t('IRightHemisphereP5'),
        ],
        [
          t('IDifficultyP5'),
          t('IDifficultToIntegrateP5'),
          t('IDifficultyInSpatiotemporalP5'),
        ],
        [getNumber(agingRoiScore?.Precuneus_LH)],
        [getNumber(agingRoiScore?.Precuneus_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'P6.',
              content: 'Posterior Cingulate',
              note: t('IPosteriorCinCortexP6'),
            },
          ],
          image: getHealthReportReportImage('brainP6.png'),
        },
        [
          t('IMetaCognitionP6'),
          t('IRegionAlsoInvolvedP6'),
          t('ILeftHemisphereP6'),
          t('IRightHemisphereP6'),
        ],
        [t('ILeftSidedFunctionP6')],
        [getNumber(agingRoiScore?.Posterior_Cingulate_LH)],
        [getNumber(agingRoiScore?.Posterior_Cingulate_RH)],
      ],
      background: 'White',
    },
  ]

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <WrapElement number={3} text={t('IParietalLobe3DDetailed')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IParietalLobe2')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IResponsible')}
                    </p>
                    &nbsp;
                    <p>{t('IMainArea2')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IResponsibleForTheIntegration')}</p>
                    <p>{t('ISymptomsLikeThese')}</p>
                    <p>{t('IHealthLevelOfEachDetailed')}</p>
                    <p>{t('ILowerNumber')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>좌</div>
                        <Score score={getNumber(Frontal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>우</div>
                        <Score score={getNumber(Temporal_LH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage5.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable5} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintP1
