import {SearchedIndividualNorm} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import PageTable from 'pages/Analysis/EEGNorm/PageTable'
import React, {useState} from 'react'

function EEGNorm() {
  const [selectedOld, setSelectedOld] = useState<SearchedIndividualNorm[]>([])

  const updateSelectedOld = (
    selected: SearchedIndividualNorm[],
    listAll: SearchedIndividualNorm[],
  ) => {
    const removeItems = listAll.filter(
      (itemA) => !selected.some((itemB) => itemA.id === itemB.id),
    )

    let selectedOldNew = selectedOld
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selected.length; i++) {
      if (!selectedOld?.find((item) => item.id === selected[i].id)) {
        selectedOldNew.push(selected[i])
      }
    }

    selectedOldNew = selectedOldNew.filter(
      (itemA) => !removeItems.some((itemB) => itemA.id === itemB.id),
    )

    setSelectedOld(selectedOldNew)
  }
  return (
    <>
      <CardWithTitle>
        <PageTable
          selectedOld={JSON.parse(JSON.stringify(selectedOld))}
          setSelectedOld={updateSelectedOld}
        />
      </CardWithTitle>
      <NoticeConfirmDialog />
    </>
  )
}

export default EEGNorm
