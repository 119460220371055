import styled, {css} from 'styled-components'
import React, {useEffect} from 'react'
import {handleClickOutside} from 'helpers/uiHelper'
import {CommonSvgIcon} from 'components/common/useSvgIcons'
import {useTranslation} from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import i18n from 'i18n'
import Colors from '../../../theme/Colors'
import useSelectCountryViewModels, {
  CountryType,
} from '../SelectCountry/SelectCountryViewModels'

interface SelectContainerStyleProps extends SelectStyleProps {
  isOpened: boolean
  isInvalid?: boolean
  FormRef?: React.ForwardedRef<unknown>
}

export interface SelectStyleProps {
  openDirection: 'top' | 'bottom'
  paddingLeft: number
  paddingRight: number
  boxHeight: number
  textColor: string
  textSize: number
  textWeight: number
  bgColor: string
  bgHoverColor: string
  borderColor: string
  borderRadius: number
  textHoverColor?: string
  fixWidth?: number // StyledSelectContainer width
  fixWidthPercent?: string // StyledSelectContainer width percent (ex: 100% 줘야할 때)
  fixMaxWidth?: number // StyledSelectContainer max-width - when select box have to be max width fix
  fixMinWidth?: number // StyledSelectContainer min-width - when select box have to be min width fix
  fixMaxHeight?: number
  arrowIconColor?: string
  arrowIconWidth?: number
  arrowIconHeight?: number
  marginLeft?: number
  marginRight?: number
  zIndexOptions?: number
  thumbColor?: string
}

export interface Spreadable {
  spread(): [string] | string[]
}

export interface SpreadItem {
  appearaceValue(): string
}

export interface ValueSelectItems {
  value: number | string
  label: string
}

export interface SelectProps {
  thumb: string
  spreadable?: Spreadable // 데이터를 spread() 형태로 넣어주면 spreadable로 전달
  options?: string[] // 데이터가 spread() 형태가 아니고 단순히 string[]이라면 options로 전달
  values?: ValueSelectItems[] // value와 label이 다를때 {value: '', label:''} 형태로 전달
  handler: (lists: string[] | ValueSelectItems[], index: number) => void
  theme: SelectStyleProps
  isInvalid?: boolean
  isOpenControlParent?: boolean
  setIsOpenControlParent?: React.Dispatch<React.SetStateAction<boolean>>
  placeHolder?: string
  countryImage?: string
  countryFlagList?: any[]
  updateFlagList?: (items: any[]) => void
  thumbType?: CountryType | string
  allCountries?: any[]
}

interface SelectComponentProps {
  thumb: string
  placeHolder?: string
  theme: SelectStyleProps
  selectItems?: string[]
  itemList: string[]
  valuesItems?: ValueSelectItems[]
  isOpen: boolean
  isInvalid?: boolean
  selectRef: React.RefObject<HTMLDivElement>
  toggling: () => void
  updateSelectItems: (items: string[]) => void
  updateFlagList: (items: any[]) => void
  handleSelectItem: (
    lists: string[] | ValueSelectItems[],
    index: number,
  ) => void
  FormRef?: React.ForwardedRef<unknown>
  countryImage?: string
  countryFlagList?: any[]
  thumbType: CountryType | string
  allCountries?: any[]
}

const StyledArrowIcon = styled.span`
  height: 100%;
`
const StyledSearchWrap = styled.div<{borderColor: string}>`
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.borderColor};
  padding: 4px 0px;
  margin: 4px 4px;
`

const StyledStateIconWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  // svg {
  //   width: 10px;
  //   height: 10px;
  // }
`

const StyledDirectionTopMixin = css<SelectContainerStyleProps>`
  display: flex;
  flex-direction: column-reverse;
  border-bottom: 0;
  bottom: ${(props) => `calc(100% + ${props.boxHeight}px)`};
  border-radius: 4px 4px 0 0;

  opacity: ${({isOpened}) => (isOpened ? 1 : 0)};
  visibility: ${({isOpened}) => (isOpened ? 'visible' : 'hidden')};
  transform: ${({isOpened}) =>
    isOpened ? 'translate3d(0, 0, 0)' : 'translate3d(0, 15px, 0)'};
  transition: opacity 200ms ease-out, visibility 200ms ease-out,
    transform 200ms ease-out;
`
const StyledDirectionBottomMixin = css<SelectContainerStyleProps>`
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  border-top: 0px;
  top: 100%;

  opacity: ${({isOpened}) => (isOpened ? 1 : 0)};
  visibility: ${({isOpened}) => (isOpened ? 'visible' : 'hidden')};
  transform: ${({isOpened}) =>
    isOpened ? 'translate3d(0, 0, 0)' : 'translate3d(0, -15px, 0)'};
  transition: opacity 200ms ease-out, visibility 200ms ease-out,
    transform 200ms ease-out;
`
const StyledSelectHeaderTextDirectionTopMixin = css<SelectContainerStyleProps>`
  border-radius: ${(props) => {
    let borderRadius = ''
    if (props.isOpened && props.borderRadius) {
      borderRadius = `0 0 ${props.borderRadius}px ${props.borderRadius}px`
    } else if (props.borderRadius) {
      borderRadius = `${props.borderRadius}px`
    } else if (props.isOpened) {
      borderRadius = '0 0 2px 2px'
    } else {
      borderRadius = '2px'
    }
    return borderRadius
  }};
`
const StyledSelectHeaderTextDirectionBottomMixin = css<SelectContainerStyleProps>`
  border-radius: ${(props) => {
    let borderRadius = ''
    if (props.isOpened && props.borderRadius) {
      borderRadius = `${props.borderRadius}px ${props.borderRadius}px 0 0`
    } else if (props.borderRadius) {
      borderRadius = `${props.borderRadius}px`
    } else if (props.isOpened) {
      borderRadius = '2px 2px 0 0'
    } else {
      borderRadius = '2px'
    }
    return borderRadius
  }};
`

const StyledSelectHeader = styled.div``

const StyledSelectHeaderText = styled.span`
  width: 120px;
`

const StyledTextAutoFullWidth = styled.span``

const StyledSelectListContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledSelectList = styled.ul`
  margin: 0;
  padding: 0;
`
const StyledSelectListItem = styled.li`
  display: flex;
`

const StyledSelectContainer = styled.div<SelectContainerStyleProps>`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) =>
    props.fixMaxWidth ? `${props.fixMaxWidth}px` : null};
  min-width: ${(props) =>
    props.fixMinWidth ? `${props.fixMinWidth}px` : null};
  width: ${(props) => {
    let width = ''
    if (props.fixWidth) width = `${props.fixWidth}px`
    else if (props.fixWidthPercent) width = `${props.fixWidthPercent}`
    return width
  }};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.textSize}px;
  font-weight: ${(props) => props.textWeight};
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;

  ${StyledSelectHeader} {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: ${(props) => props.bgColor};
    border: ${(props) =>
      props.isInvalid
        ? `1px solid ${Colors.state.error}`
        : `1px solid ${props.borderColor}`};
    border-radius: ${(props) => props.borderRadius}px;
    padding-right: ${(props) =>
      props.arrowIconWidth
        ? `calc(${props.arrowIconWidth}px + (${props.paddingRight}px  * 2))`
        : `calc(10px + (${props.paddingRight}px * 2))`};
    padding-left: ${(props) => props.paddingLeft}px;
    width: 100%;
    height: ${(props) => props.boxHeight}px;

    ${(props) =>
      props.openDirection === 'top'
        ? StyledSelectHeaderTextDirectionTopMixin
        : StyledSelectHeaderTextDirectionBottomMixin};
  }

  ${StyledSelectHeaderText} {
    overflow: hidden;

    span {
      display: block;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      line-height: ${(props) => props.boxHeight - 2}px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${(props) => (props.placeholder ? props.thumbColor : null)};
    }

    ${StyledTextAutoFullWidth} {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;

      & > span {
        height: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }

  ${StyledArrowIcon} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: ${(props) => props.paddingRight}px;

    svg {
      width: ${(props) => props.arrowIconWidth ?? 10}px;
      height: ${(props) => props.arrowIconHeight ?? 10}px;

      * {
        fill: ${(props) => props.isInvalid && Colors.state.error};
        stroke: ${(props) => props.isInvalid && Colors.state.error};
      }
    }
  }

  ${StyledSelectList} {
    display: flex !important;
    width: 100%;
    max-height: ${(props) => props.fixMaxHeight ?? 400}px;
    background-color: ${(props) => props.bgColor};
    border: ${(props) =>
      props.isInvalid
        ? `1px solid ${Colors.state.error}`
        : `1px solid ${props.borderColor}`};
    position: absolute;
    list-style: none;
    overflow: hidden;
    overflow-y: auto;
    z-index: ${(props) => props.zIndexOptions ?? 999};
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 8px;
    }

    ${(props) =>
      props.openDirection === 'top'
        ? StyledDirectionTopMixin
        : StyledDirectionBottomMixin}
  }

  ${StyledSelectListItem} {
    flex: 0 0 auto;
    padding-right: ${(props) =>
      props.arrowIconWidth
        ? `calc(${props.arrowIconWidth}px + (${props.paddingRight}px  * 2))`
        : `calc(10px + (${props.paddingRight}px * 2))`};
    padding-left: ${(props) => props.paddingLeft}px;
    height: ${(props) => props.boxHeight}px;
    line-height: ${(props) => props.boxHeight - 1}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: background-color 300ms ease-in-out;

    &:hover {
      color: ${(props) => props.textHoverColor ?? null};
      background-color: ${(props) => props.bgHoverColor};
    }
  }
`

export function SelectComponent(props: SelectComponentProps) {
  const {
    thumb,
    theme,
    placeHolder,
    selectItems,
    itemList,
    isOpen,
    isInvalid,
    selectRef,
    toggling,
    handleSelectItem,
    valuesItems,
    FormRef,
    updateSelectItems,
    countryImage,
    countryFlagList,
    updateFlagList,
    thumbType,
    allCountries = [],
  } = props

  const {t} = useTranslation()
  const divRef = React.useRef(null)
  const [searchStr, setSearchStr] = React.useState('')

  const getFilteredList = (val: string) => {
    let arr = itemList ?? []
    let flagArr = allCountries ?? []
    if (itemList) {
      arr = itemList.filter(function checkCountry(item, index) {
        return item.toLowerCase().includes(val.toLowerCase())
      })
      flagArr = allCountries.filter(function checkItem(item, ind) {
        if (i18n.language === 'ko') {
          return item.nameKo.toLowerCase().includes(val.toLowerCase())
        }
        return item.nameEn.toLowerCase().includes(val.toLowerCase())
      })
    }
    if (updateFlagList) updateFlagList(flagArr)
    updateSelectItems(arr)
    return arr
  }

  const handleKeyPress = (event: any, item?: string) => {
    let str = item ?? searchStr
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    // Check if the pressed key is an alphanumeric character
    const isAlphanumeric = /^[a-zA-Z0-9]$/.test(keyValue)

    if (!isAlphanumeric && event.key !== 'Backspace' && event.key !== 'Enter') {
      return
    }

    if (event.key === 'Backspace' && str.length > 0) {
      str = str.slice(0, -1)
      getFilteredList(str)
      setSearchStr(str)
    } else if (event.key === 'Backspace' && str.length === 0) {
      str = ''
      getFilteredList(str)
      setSearchStr(str)
    } else if (event.key !== 'Backspace' && event.key !== 'Enter') {
      str = searchStr + event.key
      getFilteredList(str)
      setSearchStr(str)
    }
  }
  const OnChangeHandler = (event: any) => {
    getFilteredList(event.target.value)
    setSearchStr(event.target.value)
  }

  const selectItemsList = (
    <StyledSelectList>
      {countryFlagList && (
        <StyledSearchWrap borderColor={theme.borderColor}>
          <input
            type='text'
            id='searchCountryValue'
            value={searchStr}
            style={{paddingLeft: '5px', maxWidth: '90%'}}
            // onKeyDown={handleKeyPress}
            onChange={OnChangeHandler}
            placeholder={t('ISearch')}
          />
          <StyledStateIconWrap
            onClick={() => {
              setSearchStr('')
              updateSelectItems(itemList)
              if (updateFlagList) updateFlagList(allCountries)
            }}
          >
            <CancelIcon
              fontSize='small'
              color={theme.arrowIconColor ?? Colors.icon.fill.select_arrow}
            />
          </StyledStateIconWrap>
        </StyledSearchWrap>
      )}
      {selectItems &&
        selectItems.map((item, idx) => {
          return (
            <StyledSelectListItem
              key={idx}
              value={item}
              onClick={() => {
                handleSelectItem(
                  selectItems,
                  itemList.findIndex((elem) => elem === item),
                )
                updateSelectItems(itemList)
                if (updateFlagList) updateFlagList(allCountries)
                setSearchStr('')
              }}
            >
              {countryFlagList &&
              countryFlagList.length > 0 &&
              countryFlagList[idx] ? (
                <img
                  src={countryFlagList[idx].flagImgUrl}
                  alt='countryFlagList'
                  style={{
                    transform: 'scale(0.60)',
                    // transformOrigin: 'top left', // Adjust as needed
                  }}
                />
              ) : null}
              {item}
            </StyledSelectListItem>
          )
        })}
    </StyledSelectList>
  )

  const valuesItemsList = (
    <StyledSelectList>
      {valuesItems &&
        valuesItems.map((item, idx) => (
          <StyledSelectListItem
            key={idx}
            value={item.value}
            onClick={() => {
              handleSelectItem(valuesItems, idx)
            }}
          >
            {countryFlagList &&
            countryFlagList.length > 0 &&
            countryFlagList[idx] ? (
              <img
                src={countryFlagList[idx]}
                alt='countryFlagList'
                style={{
                  transform: 'scale(0.60)',
                  // transformOrigin: 'top left', // Adjust as needed
                }}
              />
            ) : null}
            {t(item.label)}
          </StyledSelectListItem>
        ))}
    </StyledSelectList>
  )

  const selectListType = valuesItems ? valuesItemsList : selectItemsList

  return (
    <StyledSelectContainer
      isOpened={isOpen}
      isInvalid={isInvalid}
      openDirection={theme.openDirection}
      paddingLeft={theme.paddingLeft}
      paddingRight={theme.paddingRight}
      boxHeight={theme.boxHeight}
      textSize={theme.textSize}
      textColor={theme.textColor}
      textWeight={theme.textWeight}
      bgColor={theme.bgColor}
      bgHoverColor={theme.bgHoverColor}
      borderColor={theme.borderColor}
      borderRadius={theme.borderRadius}
      textHoverColor={theme.textHoverColor}
      fixWidth={theme.fixWidth}
      fixWidthPercent={theme.fixWidthPercent}
      fixMaxWidth={theme.fixMaxWidth}
      fixMinWidth={theme.fixMinWidth}
      fixMaxHeight={theme.fixMaxHeight}
      arrowIconColor={theme.arrowIconColor}
      arrowIconWidth={theme.arrowIconWidth}
      arrowIconHeight={theme.arrowIconHeight}
      ref={selectRef}
      FormRef={FormRef}
      marginLeft={theme.marginLeft}
      marginRight={theme.marginRight}
      zIndexOptions={theme.zIndexOptions}
      thumbColor={theme.thumbColor}
      placeholder={placeHolder}
    >
      <StyledSelectHeader
        ref={divRef}
        tabIndex={0}
        // onKeyDown={handleKeyPress}
        onClick={toggling}
      >
        {/* 선택된 옵션 보여줌 */}
        <StyledSelectHeaderText>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {countryImage && countryImage !== '' ? (
              <img
                src={countryImage}
                alt='countryImage'
                style={{height: 'fit-content', marginRight: '5px'}}
              />
            ) : null}
            <span>{placeHolder ?? thumb}</span>
          </div>
          <StyledTextAutoFullWidth>
            {valuesItems &&
              valuesItems.map((item, idx) => (
                <span key={idx}>{item.label}</span>
              ))}
            {selectItems &&
              selectItems.map((item, idx) => <span key={idx}>{item}</span>)}
          </StyledTextAutoFullWidth>
        </StyledSelectHeaderText>
        <StyledArrowIcon>
          <CommonSvgIcon
            name='allowBottom'
            iconColor={theme.arrowIconColor ?? Colors.icon.fill.select_arrow}
          />
        </StyledArrowIcon>
      </StyledSelectHeader>
      <StyledSelectListContainer>{selectListType}</StyledSelectListContainer>
    </StyledSelectContainer>
  )
}

/**
 *
 * @param
 * - thumb: select box가 닫혀있을 때 노출되는 문자열입니다. (items[0] or 선택해주세요.)
 * - placeHolder: select box가 닫혀있을때 노출되는 place hold 문자열. thumb와는 다르게 thumbColor색상 설정이 가능.
 * - spreadable: spread() => [string] or string[] 형태의 셀렉트 아이템입니다. (기관관리 - 결제관리 등에서 사용 됨)
 * - options: string[] 형태의 셀렉트 아이템입니다.
 * - handler: 부모가 전달하는 이벤트를 실행합니다. (thumb change 등)
 * - theme: 적용시킬 셀렉트 스타일입니다. (Styles.ts 파일에서 필요한 테마 만들기)
 * - value: { value: string, label: string} 형태의 아이템 필요할 때
 * - isInvalid?: required 등의 체크 필요할 때 테두리 색상을 error color로 변경
 *
 * 부모에서 select open 제어하고 싶을 때
 * - isOpenControlParent?: boolean state that select open with label click
 * - setIsOpenControlParent?: boolean setState that select open with label click
 *
 * useForm register 사용시 ref 에러로 forwardRef로 변경 (실제 FormRef가 사용되진 않음, setError, setValue 등 직접 해주기)
 */
const Select = React.forwardRef((props: SelectProps, FormRef) => {
  const {
    thumb,
    spreadable,
    placeHolder,
    options,
    handler,
    theme,
    values,
    isInvalid,
    isOpenControlParent,
    setIsOpenControlParent,
    countryImage,
    countryFlagList,
    updateFlagList,
    thumbType,
    allCountries = [],
  } = props

  const [selectItems, setSelectItems] = React.useState<string[]>([''])
  const [itemList, setItemList] = React.useState<string[]>([''])

  // open control
  const isControlParent =
    isOpenControlParent !== undefined && setIsOpenControlParent !== undefined
  const [selectIsOpen, setSelectIsOpen] = React.useState<boolean>(false)

  // isOpen은 isControlParent가 true면 프롭으로, 아니면 selectIsOpen으로 제어
  const [isOpen, setIsOpen] = isControlParent
    ? [isOpenControlParent, setIsOpenControlParent]
    : [selectIsOpen, setSelectIsOpen]

  const selectRef = React.useRef<HTMLDivElement>(null)

  const toggling = () => {
    setIsOpen((prev) => !prev)
  }

  const handleSelectItem = (
    lists: string[] | ValueSelectItems[],
    index: number,
  ) => {
    handler(lists, index)

    setIsOpen(false)
  }

  // 중첩 방지 useCallback
  const handleClickOutsideCallback = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      handleClickOutside(e, selectRef, setIsOpen)
    },
    [selectRef, isOpen],
  )

  useEffect(() => {
    if (spreadable && !options) {
      setSelectItems(spreadable.spread())
      setItemList(spreadable.spread())
    } else if (options) {
      setSelectItems(options)
      setItemList(options)
    }
  }, [spreadable, options])

  // 셀렉트 오픈 후 외부 영역 선택 확인
  useEffect(() => {
    // 셀렉트 박스가 열려 있을 때만 실행, 부모 컴포넌트에서 제어하면 실행 X 해당 컴포넌트에서 실행해야 함
    if (isOpen && !isOpenControlParent) {
      window.addEventListener('mousedown', handleClickOutsideCallback)
    }

    // 중첩 실행 방지
    return () =>
      window.removeEventListener('mousedown', handleClickOutsideCallback)
  }, [isOpen])

  return (
    <SelectComponent
      thumb={thumb}
      placeHolder={placeHolder}
      theme={theme}
      selectItems={selectItems}
      updateSelectItems={setSelectItems}
      itemList={itemList}
      isOpen={isOpen}
      isInvalid={isInvalid}
      selectRef={selectRef}
      toggling={toggling}
      handleSelectItem={handleSelectItem}
      valuesItems={values}
      FormRef={FormRef}
      countryImage={countryImage}
      countryFlagList={countryFlagList}
      updateFlagList={updateFlagList}
      thumbType={thumbType}
    />
  )
})

export default Select
