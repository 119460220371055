import React from 'react'
import {useTranslation} from 'react-i18next'

interface RoundTableHeaderProps {
  label: string
  index: number
  length: number
}

const RoundTableHeader = ({label, index, length}: RoundTableHeaderProps) => {
  const {t} = useTranslation()
  const isFirstIndex = (index: number): boolean => {
    if (index === 0) {
      return true
    }
    return false
  }
  const isLastIndex = (index: number): boolean => {
    if (index === length - 1) {
      return true
    }
    return false
  }

  return (
    <>
      {isFirstIndex(index) && (
        <th
          style={{
            padding: '5px',
            borderTopLeftRadius: '5px',
            backgroundColor: '#e9ecef',
          }}
        >
          <p>{t(label)}</p>
        </th>
      )}
      {!isFirstIndex(index) && !isLastIndex(index) && (
        <th style={{padding: '5px', backgroundColor: '#e9ecef'}}>
          <p>{t(label)}</p>
        </th>
      )}
      {isLastIndex(index) && (
        <th
          style={{
            padding: '5px',
            borderTopRightRadius: '5px',
            backgroundColor: '#e9ecef',
          }}
        >
          <p>{t(label)}</p>
        </th>
      )}
    </>
  )
}

export default RoundTableHeader
