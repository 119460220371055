import {Button, Typography} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'
import DateRangeIcon from '@material-ui/icons/DateRange'
import {addDays} from 'date-fns'
import {enUS, ko} from 'date-fns/locale'
import {formatDate, oneYearAgo, oneYearAgoFromNow} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {Calendar} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useTranslation} from 'react-i18next'
import {Colors, Spacing} from 'components/common/useBaseStyle'

interface ParentProps {
  onBeginDate?: Date
  selectedBeginDate: boolean
  onChangeValue: (date?: Date) => void
  dateValue: Date
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen: boolean
}

const ParentDate = styled.div`
  position: relative;
  display: flex;
  width: ${Spacing.calender.calenderWidth}px;
  height: ${Spacing.calender.calenderHeight}px;
  flex-direction: column;
  margin-bottom: ${Spacing.calender.calenderParentDateMargin}px;
  border: ${Spacing.calender.calenderDateWrapBorder}px solid
    ${Colors.notice.noticeTimeInputTextBorderColor};
`
const DateWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: ${Spacing.calender.calenderHeight}px;
  background-color: white;
  & h6 {
    font-size: ${Spacing.calender.calenderFontSize}px;
    color: ${Colors.text.input};
  }
`
const SubmitButton = styled.div`
  width: ${Spacing.calender.calenderWrapperWidth}px;
  display: flex;
  justify-content: flex-end;
  gap: ${Spacing.calender.calenderSubmitButtonPadding}px;
  padding: ${Spacing.calender.calenderSubmitButtonPadding}px;
  background: ${Colors.calenderSubmitBackground};
`

/**
 * 날짜선택이 가능한 캘린더
 *
 * @param onChangeValue setValue 함수
 * @param dateValue date value 값
 */

export default function CalenderSinglePicker({
  onChangeValue,
  onBeginDate,
  dateValue,
  selectedBeginDate,
  isOpen,
}: ParentProps) {
  const {i18n} = useTranslation()
  const [selection, setSelection] = React.useState<Date>(dateValue)
  const [open, setOpen] = React.useState(false)
  const [date, setDate] = React.useState<Date>()
  const [currentLocale, setCurrentLocale] = React.useState<string>()
  // eslint-disable-next-line
  const [selectRanges, setSelectRanges] = React.useState<any>()

  const onRangeChange = (ranges: any) => {
    if (ranges != null) {
      setDate(ranges)
      setSelection(ranges)
    }
  }

  const handleDataSubmit = () => {
    if (selectRanges) onChangeValue(selectRanges)
    if (!selectRanges) onChangeValue(selection)
  }

  const handleClickAway = () => {
    setOpen(false)
  }
  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const getLocale = () => {
    if (currentLocale === 'ko') {
      return ko
    }
    return enUS
  }

  const handleClose = () => setOpen(false)

  const handleDataChange = () => {
    setOpen(false)
    handleDataSubmit()
  }

  useEffect(() => {
    setCurrentLocale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    onChangeValue(date)
  }, [date])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ParentDate>
        <DateWrap
          onClick={handleClick}
          style={{
            background:
              isOpen === true
                ? 'white'
                : `${Colors.notice.noticeInputTextDisable}`,
          }}
        >
          <DateRangeIcon
            style={{
              marginLeft: Spacing.calender.calenderIconMarginLeft,
              color: Colors.calenderIcon,
              fontSize: Spacing.calender.calenderFontSize,
              textAlign: 'center',
            }}
          />
          <Divider
            orientation='vertical'
            flexItem
            style={{
              marginRight: Spacing.calender.calenderDividerMargin,
              marginLeft: Spacing.calender.calenderDividerMargin,
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
            }}
          >
            <Typography
              variant='h6'
              style={{marginLeft: Spacing.calender.calenderTypoMarginLeft}}
            >
              {formatDate(selection)}
            </Typography>
          </div>
        </DateWrap>
        {isOpen && open ? (
          <div
            style={{
              position: 'absolute',
              right: Spacing.calender.calenderOpenPosition,
              left: Spacing.calender.calenderOpenPosition,
              zIndex: Spacing.calender.calenderOpenZindex,
              boxShadow: Colors.calenderBoxShadow,
            }}
          >
            {/* 시작시간 선택하는 캘린더 */}
            {!selectedBeginDate && !onBeginDate && (
              <Calendar
                onChange={onRangeChange}
                locale={getLocale()}
                date={dateValue}
                minDate={oneYearAgoFromNow()}
                // maxDate={addDays(new Date(), 365)}
              />
            )}
            {/* 끝나는시간 선택하는 캘린더 */}
            {!selectedBeginDate && onBeginDate && (
              <Calendar
                onChange={onRangeChange}
                locale={getLocale()}
                date={dateValue}
                minDate={oneYearAgo(onBeginDate)}
                // maxDate={addDays(new Date(), 365)}
              />
            )}
            {selectedBeginDate && onBeginDate && (
              <Calendar
                onChange={onRangeChange}
                locale={getLocale()}
                date={dateValue}
                minDate={addDays(onBeginDate, 0)}
                // maxDate={addDays(new Date(), 760)}
              />
            )}
            <SubmitButton>
              <Button
                variant='contained'
                color='default'
                size='small'
                disableElevation
                onClick={() => handleClose()}
                className='cancelButton'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                disableElevation
                size='small'
                color='primary'
                onClick={() => handleDataChange()}
              >
                Apply
              </Button>
            </SubmitButton>
          </div>
        ) : null}
      </ParentDate>
    </ClickAwayListener>
  )
}
