import {
  ProductTemplateType,
  ProductUsesType,
} from 'features/invoice/invoiceSlice'

export const checkCounts = (data: ProductUsesType[]): boolean => {
  return data.reduce((isValid, current, index, array) => {
    if (!isValid) return false
    if (index === 0) return true

    const previous = array[index - 1]
    if (
      current.minCount !== null &&
      current.maxCount !== null &&
      current.minCount > current.maxCount
    ) {
      return false
    }
    return (
      previous.maxCount === null || current.minCount === previous.maxCount + 1
    )
  }, true)
}

export const checkContinuous = (templates: ProductTemplateType[]) => {
  let isContinuous = true
  if (!templates.length) return isContinuous
  for (let index = 0; index < templates.length; index += 1) {
    const element = templates[index]
    if (element.usages.length < 3) break
    if (!checkCounts(element.usages)) {
      isContinuous = false
      break
    }
  }
  return isContinuous
}
