import {faCodepen} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormControlLabel} from '@material-ui/core'
import {
  ThreeDReport,
  ThreeDZScoreRange,
  ThreeDZScoreResponse,
} from 'api/analysisApi'
import {IOSSwitch} from 'components/atoms/TranslationSelect'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React, {useMemo} from 'react'
import styled, {css} from 'styled-components'

const StyledZScoreDataList = styled.ul`
  list-style: none;
  margin: 20px 0;
  border: solid #fff;
  border-width: 1px 0;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px 10px;

  & li {
    margin: 0;
    padding: 5px 0;

    & .title {
      margin: 0;
      padding: 0;
      font-size: 12px;
      color: #fff;
    }

    & .text {
      margin: 0;
      padding: 0;
      font-size: 12px;
      color: #ccc;
    }
  }
`

interface ZScoreDataListProps {
  neural3dZscoreDataList: ThreeDZScoreResponse[]
  bandType: number
}

const ZScoreDataList = ({
  neural3dZscoreDataList,
  bandType,
}: ZScoreDataListProps) => {
  const zscoreDataList = useMemo(
    () => neural3dZscoreDataList.filter((data) => data.bandType === bandType),
    [neural3dZscoreDataList, bandType],
  )
  if (zscoreDataList.length === 0) return null

  return (
    <StyledZScoreDataList>
      {zscoreDataList.map((data) => (
        <li>
          <p className='title'>z-score: {data.zscoreValue}</p>
          <p className='text'>{data.labelName}</p>
        </li>
      ))}
    </StyledZScoreDataList>
  )
}

interface ZScoreButtonProps {
  zscore: ThreeDZScoreRange
  current: ThreeDZScoreRange
}

const StyledZScoreButton = styled.button<ZScoreButtonProps>`
  flex: 1;
  color: #fff;
  background-color: #2db1ae;
  font-size: 12px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 6px 12px;

  ${(props) =>
    props.current === props.zscore &&
    css`
      outline: 0;
      box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
      background-color: #0c8686;
    `}
`

const StyledZScoreButtons = styled.div`
  margin-bottom: 10px;

  & .title {
    color: #fff;
    font-size: 13px;
    margin: 0 0 5px;
  }

  & .buttons {
    display: flex;
    gap: 5px;
  }
`

interface ZScoreButtonsProps {
  loading: boolean
  zscore: ThreeDZScoreRange
  zscoreStrList: ThreeDZScoreRange[]
  onZScoreChange: (zscore: ThreeDZScoreRange) => void
}

const ZScoreButtons = ({
  loading,
  zscore,
  zscoreStrList,
  onZScoreChange,
}: ZScoreButtonsProps) => {
  const zscoreLabels = useMemo(
    () => zscoreStrList.filter((item) => item !== null),
    [zscoreStrList],
  )

  if (zscoreLabels.length === 0) return null

  return (
    <StyledZScoreButtons>
      <h4 className='title'>z-score</h4>
      <div className='buttons'>
        {zscoreLabels.map((label) => (
          <StyledZScoreButton
            zscore={label}
            current={zscore}
            key={label}
            onClick={() => onZScoreChange(label)}
            disabled={loading}
          >
            {'>'} ± {(parseInt(label ?? '0', 10) / 10).toFixed(1)}
          </StyledZScoreButton>
        ))}
      </div>
    </StyledZScoreButtons>
  )
}

interface StyledBandButtonProps {
  buttonBandType: number
  bandType: number
}

const StyledBandButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, minmax(20px, auto));
  gap: 5px;
`

const StyledBandButton = styled.button<StyledBandButtonProps>`
  font-size: 12px;
  padding: 6px 12px;
  vertical-align: center;
  text-align: center;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #b6b7bc;
  border-radius: 3px;

  ${(props) =>
    props.buttonBandType === props.bandType &&
    css`
      background: #fff;
      color: #000;
    `}
`

interface SettingsButtonGridProps {
  bandType: number
  neural3dDataList: ThreeDReport[]
  onBandChanged: (bandType: number) => void
}

function SettingsButtonGrid({
  neural3dDataList,
  bandType,
  onBandChanged,
}: SettingsButtonGridProps) {
  return (
    <StyledBandButtonGrid>
      {neural3dDataList.map((data) => (
        <StyledBandButton
          key={data.bandType}
          buttonBandType={data.bandType}
          bandType={bandType}
          onClick={() => onBandChanged(data.bandType)}
        >
          {data.bandName}
        </StyledBandButton>
      ))}
    </StyledBandButtonGrid>
  )
}

interface StyledSettingsProps {
  theme: ThreeDTheme
  open: boolean
}

const StyledSettings = styled.div<StyledSettingsProps>`
  position: fixed;
  right: ${(props) => (props.open ? 0 : -350)}px;
  top: 100px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: -3px 2px 12px -1px rgb(0 0 0 / 15%);
  width: 350px;
  //height: 350px;
  transition-duration: 0.4s;
  border: 1px solid #fff;
  padding: 15px;

  & button.toggle {
    cursor: pointer;
    border: none;
    position: absolute;
    left: -40px;
    top: 10px;
    background-color: #3ebcbd;
    font-size: 20px;
    color: #fff;
    border-radius: 20px 0 0 20px;
    width: 40px;
    height: 40px;
    text-align: center;
  }

  & .info {
    padding: 5px;
    & .top {
      display: flex;
      color: #fff;
      margin-bottom: 0;
      align-items: center;

      & .title {
        display: block;
        font-size: 16px;
        font-weight: bold;
      }

      & .switch {
        margin-left: auto;
        display: flex;
        align-items: center;
        & .label {
          display: block;
          font-size: 12px;
          &:first-child {
            margin-right: 8px;
          }
          &:last-child {
            margin-left: -18px;
          }
        }
      }
    }
  }
`

interface SettingsProps {
  theme: ThreeDTheme
  loading: boolean
  open: boolean
  onOpen: () => void
  onThemeChanged: () => void
  bandType: number
  onBandChanged: (bandType: number) => void
  neural3dDataList: ThreeDReport[]
  neural3dZscoreDataList: ThreeDZScoreResponse[]
  zscore: ThreeDZScoreRange
  zscoreStrList: ThreeDZScoreRange[]
  onZScoreChange: (zscore: ThreeDZScoreRange) => void
}

export default function Settings({
  theme,
  loading,
  onThemeChanged,
  open,
  onOpen,
  bandType,
  neural3dDataList,
  neural3dZscoreDataList,
  onBandChanged,
  zscore,
  zscoreStrList,
  onZScoreChange,
}: SettingsProps) {
  return (
    <StyledSettings theme={theme} open={open}>
      <button className='toggle' type='button' onClick={onOpen}>
        <FontAwesomeIcon icon={faCodepen} />
      </button>
      <div className='info'>
        <div className='top'>
          <span className='title'>3D Viewer</span>

          <div className='switch'>
            <span className='label'>White</span>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={theme === 'Dark'}
                  onChange={onThemeChanged}
                />
              }
              label=''
            />
            <span className='label'>Dark</span>
          </div>
        </div>
        <ZScoreButtons
          loading={loading}
          zscore={zscore}
          zscoreStrList={zscoreStrList}
          onZScoreChange={onZScoreChange}
        />
        <SettingsButtonGrid
          neural3dDataList={neural3dDataList}
          bandType={bandType}
          onBandChanged={onBandChanged}
        />
        <ZScoreDataList
          neural3dZscoreDataList={neural3dZscoreDataList}
          bandType={bandType}
        />
      </div>
    </StyledSettings>
  )
}
