import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import {
  containerFluid,
  drawerMiniWidth,
  drawerWidth,
  transition,
} from 'assets/tss/material-dashboard-pro-react'
import {Colors} from 'components/common/useBaseStyle'

const appStyle = (theme: Theme) =>
  createStyles({
    wrapper: {
      top: '0',
      height: '100vh',
      '&:after': {
        display: 'table',
        clear: 'both',
        content: '" "',
      },
      '@global': {
        '*::-webkit-scrollbar': {
          width: '1em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#DFDFE1',
        },
      },
    },
    mainPanel: {
      '&:before': {
        width: '100%',
        height: 240,
        content: '" "',
        backgroundImage: `linear-gradient( ${Colors.primary} 240px, #f4f4f7 20px)`,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
      },
      display: 'relative',
      transitionProperty: 'top, bottom, width',
      transitionDuration: '.2s, .2s, .35s',
      transitionTimingFunction: 'linear, linear, ease',
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      float: 'right',
      ...transition,
      height: '100vh',
      maxHeight: '100%',
      width: '100%',
    },
    content: {
      marginTop: 34,
      padding: '0px 40px',
      flex: 1,
      minHeight: 'calc(100vh - 200px)',
    },
    container: {...containerFluid},
    map: {
      marginTop: '70px',
    },
    mainPanelSidebarMini: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerMiniWidth}px)`,
      },
    },
    mainPanelWithPerfectScrollbar: {
      overflow: 'auto',
    },
  })

export default appStyle
