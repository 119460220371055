import {Typography} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import SalesIndividualSearch from 'pages/SalesPages/SalesIndividualSearch/index'
import SalesRegisteredUserTable from 'pages/SalesPages/SalesRegisteredUserTable/index'
import SalesSearchedInfo from 'pages/SalesPages/SalesSearchedInfo/index'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
  },
  gridContainer: {
    marginBottom: 30,
    // margin: 10,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  contentTitleWrap: {
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
    '& > .MuiFormLabel-root.Mui-disabled': {
      color: 'red', // (default alpha is 0.38)
    },
  },
  inputRoot: {
    '&$disabled': {
      color: 'red',
    },
  },

  titleSize: {
    fontSize: '1.0rem',
  },
  titleCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& button': {
      minWidth: 200,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  rootText: {
    '&$disabled': {
      color: 'white',
    },
  },
  disabled: {},
  background: {
    color: 'primary',
    '& span': {
      background: '#E0E0E0',
    },
  },

  textField: {
    '& input:disabled': {
      color: '#000',
    },
    '& textarea:disabled': {
      color: '#000',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  direction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  table: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      textAlign: 'center',
      width: 40,
      height: 40,
      margin: 'auto',
    },
  },
  tableInner: {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableMargin: {
    marginTop: 10,
  },
  hospital: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
  },
  hospitalName: {
    fontWeight: 'bolder',
  },
}))

export default function SalesAccountPage() {
  const classes = useStyles()

  return (
    <div>
      <SalesIndividualSearch />
      <Typography className={classes.padding} />
      <SalesSearchedInfo />
      <Typography className={classes.padding} />
      <SalesRegisteredUserTable />
    </div>
  )
}
