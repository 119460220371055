import styled from 'styled-components'
import {StyledFieldSet} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledFieldSet'

// eslint-disable-next-line import/prefer-default-export
export const StyledIcaDialog = styled.div`
  color: #666;
  font-size: 13px;
  background-color: #f9f9fb;

  & .header {
    font-size: 16px;
    padding: 0 20px;
  }

  & .content {
    padding: 10px 0;
    margin-bottom: 20px;
    padding: 20px;
  }

  & .input-area {
    padding: 0 10px;

    ${StyledFieldSet} {
      background-color: #f9f9f9;
    }
    padding: 20px;
  }

  & .footer {
    display: flex;
    gap: 10px;
    background-color: #fff;
    padding: 20px;

    & button {
      flex: 1;
    }
  }
`
