import {ThreeDAnalysisType, ThreeDEEGType} from 'api/analysisApi'
import {getPublicFile} from 'helpers/envHelper'
import {PowerMap} from 'pages/Popup/ThreeDReport/PowerTab'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React from 'react'
import styled from 'styled-components'

const getImage = getPublicFile('images/eeg_report')

const getFooterImage = (rightValue: string) => {
  if (rightValue === '1.96') return getImage('colorbar_v2_1.png')
  if (rightValue === '2.58') return getImage('colorbar_v2_2.png')
  if (rightValue === '3.0') return getImage('colorbar_v2_3.png')
  return getImage('colorbar_v2_3.png')
}

interface PowerFooterTitleProps {
  gb: ThreeDEEGType
  analysisType: ThreeDAnalysisType
}

const PowerFooterTitle = ({gb, analysisType}: PowerFooterTitleProps) => (
  <h2 className='title'>
    {gb === 'R' && PowerMap[analysisType]}
    {gb !== 'R' && 'Z-SCORE'}
  </h2>
)

interface FooterRangeProps {
  gb: ThreeDEEGType
  leftValue: string
  centerValue: string
  rightValue: string
}

function PowerFooterRange({
  gb,
  leftValue,
  centerValue,
  rightValue,
}: FooterRangeProps) {
  if (gb !== 'N') {
    return (
      <div className='img-box'>
        <ul>
          <li className='first'>{leftValue}</li>
          <li className='middle'>{centerValue}</li>
          <li className='last'>{rightValue}</li>
        </ul>
      </div>
    )
  }

  const first = Number.parseFloat(leftValue)
  const middle = Number.parseFloat(centerValue)
  const last = Number.parseFloat(rightValue)

  const firstStr = first.toFixed(2)
  const second = ((first / 3) * 2).toFixed(2)
  const third = ((first / 3) * 1).toFixed(2)
  const centerStr = middle.toFixed(2)
  const fourth = ((last / 3) * 1).toFixed(2)
  const fifth = ((last / 3) * 2).toFixed(2)
  const lastStr = last.toFixed(2)

  return (
    <div className='img-box'>
      <ul>
        <li className='first'>{firstStr}</li>
        <li className='second zbar'>{second}</li>
        <li className='third zbar'>{third}</li>
        <li className='middle'>{centerStr}</li>
        <li className='fourth zbar'>{fourth}</li>
        <li className='fifth zbar'>{fifth}</li>
        <li className='last'>{lastStr}</li>
      </ul>
    </div>
  )
}

interface StyledPowerFooterProps {
  theme: ThreeDTheme
  rightValue: string
}

const StyledPowerFooter = styled.div<StyledPowerFooterProps>`
  margin: 0;
  padding: 0;

  & .title {
    color: ${(props) => (props.theme === 'Dark' ? '#fff' : '#000')};
    font-size: 16px;
    margin: 0 0 10px;
  }

  & .img-box {
    background-image: url(${(props) => getFooterImage(props.rightValue)});
    position: relative;
    background-repeat: no-repeat;
    width: 60%;
    height: 20px;
    margin: 0 auto;
    background-size: 101% 100%;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => (props.theme === 'Dark' ? '#fff' : '#000')};
    text-align: center;

    & ul {
      position: absolute;
      left: 0;
      top: 20px;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      & li {
        color: ${(props) => (props.theme === 'Dark' ? '#fff' : '#000')};
        display: inline-block;
        transform: translateX(-50%);
        &.zbar::before {
          content: '';
          display: block;
          width: 1px;
          height: 7px;
          position: absolute;
          left: 50%;
          top: -10px;
          background: #000;
        }
        &.first {
          float: left;
        }
        &.second {
          position: absolute;
          left: 16.6%;
        }
        &.third {
          position: absolute;
          left: 33.2%;
        }
        &.middle {
          &::before {
            content: '';
            display: block;
            width: 2px;
            height: 10px;
            position: absolute;
            left: 50%;
            top: -12px;
            margin: 0 0 0 -1px;
            background: #000;
          }
          position: relative;
          transform: inherit;
        }
        &.fourth {
          position: absolute;
          left: 66.4%;
        }
        &.fifth {
          position: absolute;
          left: 83%;
        }
        &.last {
          float: right;
          transform: translateX(50%);
        }
      }
    }
  }
`

interface PowerFooterProps {
  theme: ThreeDTheme
  analysisType: ThreeDAnalysisType
  leftValue: string
  centerValue: string
  rightValue: string
  gb: ThreeDEEGType
}

export default function PowerFooter({
  theme,
  analysisType,
  leftValue,
  centerValue,
  rightValue,
  gb,
}: PowerFooterProps) {
  return (
    <StyledPowerFooter
      rightValue={rightValue}
      theme={theme}
      className={rightValue}
    >
      <PowerFooterTitle gb={gb} analysisType={analysisType} />
      <PowerFooterRange
        gb={gb}
        leftValue={leftValue}
        centerValue={centerValue}
        rightValue={rightValue}
      />
    </StyledPowerFooter>
  )
}
