import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  EEGSummaryResponseWithClient,
  fetchIndividualSummaryReportApi,
  FetchIndividualSummaryReportRequest,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import CustomerInfo from 'components/molcules/N1SummaryReport/CustomerInfo'
import NoneProfitSummary from 'components/molcules/N1SummaryReport/EEG/EEGSummary'
import NoneChildProfitSummary from 'components/molcules/N1SummaryReport/EEGChild/EEGSummary'
import ProfitSummary from 'components/molcules/ProfitSummaryReport/EEG/EEGSummary'
import ProfitChildSummary from 'components/molcules/ProfitSummaryReport/EEGChild/EEGSummary'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import {
  ReportCustomPadding,
  ReportFetcherWrap,
  ReportTranslationSelectWrap,
  ReportWrap,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import PdfDownload from 'components/PdfDownload'
import CustomerInfoChild from 'components/molcules/N1SummaryReport/CustomerInfoChild'
import useLanguageReport from 'hooks/useLanguageReport'

function Report() {
  const {t, i18n} = useTranslation()
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()
  const [report, setReport] = useState<EEGSummaryResponseWithClient>()

  const asyncResult = useAsync(
    fetchIndividualSummaryReportApi,
    [
      {
        summaryIds: [Number.parseInt(id, 10)],
        summaryLang: i18n.language as LanguageCode,
      },
    ],
    {
      executeOnMount: true,
      executeOnUpdate: false,
    },
  )

  useEffect(() => {
    if (asyncResult.currentParams !== null) {
      const {summaryIds} = asyncResult.currentParams[0]
      const newParams: [FetchIndividualSummaryReportRequest] = [
        {summaryIds, summaryLang: i18n.language as LanguageCode},
      ]
      asyncResult.execute(...newParams)
    }
  }, [i18n.language])

  useEffect(() => {
    try {
      if (asyncResult.result) {
        console.log(asyncResult.result)
        const [response] = asyncResult.result.list
        if (response !== undefined) setReport(response)
      }
    } catch (e) {
      console.log(e)
    }
  }, [asyncResult.result])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  useLanguageReport()

  console.log(`date : ${JSON.stringify(report)}`)
  console.log(`treatment date : ${report?.treatmentAt}`)
  console.log(`analysis date : ${report?.analysisTime}`)

  return (
    <div id='print-modified'>
      <div id='download-pdf'>
        <PdfDownload pathPDF={report?.finalReportPath} />
      </div>
      <ReportWrap style={{width: '100%'}}>
        <ReportTranslationSelectWrap id='translation-select'>
          <TranslationSelect />
        </ReportTranslationSelectWrap>
        <ReportFetcherWrap style={{width: '100%'}}>
          <ReportCustomPadding />
          <CardWithTitle
            title={String(
              t(
                report?.client?.treatmentAge ?? 0 >= 20
                  ? 'IEEGSummaryReport'
                  : 'IEEGChildSummaryReport',
              ),
            )}
            style={{width: '100%', padding: 20}}
          >
            {asyncResult.loading && <div>{t('ILoading')}</div>}
            {asyncResult.error && (
              <div
                style={{
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div>
                  {asyncResult.error.message}
                  <IconButton onClick={() => {}} size='small'>
                    <ReplayIcon />
                  </IconButton>
                </div>
              </div>
            )}
            {!asyncResult.loading &&
              report !== undefined &&
              report.reportType === 'N1' &&
              report.client.treatmentAge >= 20 && (
                <NoneProfitSummary
                  {...report}
                  CustomerCard={
                    <CustomerInfo
                      requestId={report.requestId}
                      eoRequestId={report.eoRequestId}
                      analysisNo={report.id}
                      analysisTime={report.treatmentAt}
                      {...report.client}
                    />
                  }
                />
              )}
            {!asyncResult.loading &&
              report !== undefined &&
              report.reportType !== 'N1' &&
              report.client.treatmentAge >= 20 && (
                <ProfitSummary
                  {...report}
                  CustomerCard={
                    <CustomerInfo
                      requestId={report.requestId}
                      eoRequestId={report.eoRequestId}
                      analysisNo={report.id}
                      analysisTime={report.treatmentAt}
                      {...report.client}
                    />
                  }
                />
              )}
            {!asyncResult.loading &&
              report !== undefined &&
              report.reportType === 'N1' &&
              report.client.treatmentAge < 20 && (
                <NoneChildProfitSummary
                  {...report}
                  client={report.client}
                  CustomerCard={
                    <CustomerInfoChild
                      requestId={report.requestId}
                      eoRequestId={report.eoRequestId}
                      analysisNo={report.id}
                      analysisTime={report.treatmentAt}
                      {...report.client}
                    />
                  }
                />
              )}
            {!asyncResult.loading &&
              report !== undefined &&
              report.reportType !== 'N1' &&
              report.client.treatmentAge < 20 && (
                <ProfitChildSummary
                  {...report}
                  client={report.client}
                  CustomerCard={
                    <CustomerInfoChild
                      requestId={report.requestId}
                      eoRequestId={report.eoRequestId}
                      analysisNo={report.id}
                      analysisTime={report.treatmentAt}
                      {...report.client}
                    />
                  }
                />
              )}
            {!asyncResult.loading &&
              asyncResult.error === undefined &&
              report === undefined && (
                <div style={{minHeight: 400}}>출력할 결과지가 없습니다.</div>
              )}
          </CardWithTitle>
          <ReportCustomPadding />
        </ReportFetcherWrap>
        <ReportCustomPadding />
      </ReportWrap>
    </div>
  )
}

export default Report
