import Colors from 'theme/Colors'
import Size from 'theme/Size'
import Weight from 'theme/Weight'
import {SelectStyleProps} from './IconSelect'

export const NormalIconSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
  iconColor: '#DDDDDD',
  iconWidth: 12,
  iconHeight: 12,
}

export const NormalIconSelectTopStyles: SelectStyleProps = {
  ...NormalIconSelectStyles,
  openDirection: 'top',
}

export const HeaderTranslationIconSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: '#f2f2f2',
  borderColor: Colors.common.white,
  borderRadius: 3,
  paddingLeft: 8,
  paddingRight: 8,
  openDirection: 'bottom',
  iconWidth: 20,
  iconHeight: 20,
}

// for 3.0 payment management design
export const PaymentManagementStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.large,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: '#E0E0E0',
  borderRadius: 4,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
  iconColor: '#DDDDDD',
  iconWidth: 12,
  iconHeight: 12,
}

export const AuthTranslationIconSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: '#efefef',
  bgHoverColor: '#dbdbdb',
  borderColor: '#cdcdcd',
  borderRadius: 3,
  paddingLeft: 8,
  paddingRight: 8,
  openDirection: 'bottom',
  iconWidth: 20,
  iconHeight: 20,
}

export const AnalysisHeaderTranslationIconSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.white,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: Colors.common.primary,
  bgHoverColor: '#f2f2f2',
  borderColor: '#eaeced',
  borderRadius: 3,
  paddingLeft: 8,
  paddingRight: 8,
  openDirection: 'bottom',
  iconWidth: 20,
  iconHeight: 20,
  arrowIconColor: Colors.common.white,
}
