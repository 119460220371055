import {
  fetchTherapySelect,
  selectError,
  selectList,
  selectLoading,
  selectQuery,
  selectPaging,
  selectPagingInfo,
} from 'features/therapy/therapySelectSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import {TherapySelectRequest} from 'api/therapyApi'

export default function useTherapySelect() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const therapySelect = useAppSelector(selectList)
  const query = useAppSelector(selectQuery)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)

  // 고객 클릭시 하단 테이블에 노출되는 테라피 치료 내역으로 함
  const onSelect = ({paging, uid, uuid}: TherapySelectRequest) =>
    dispatch(fetchTherapySelect({paging, uid, uuid}))

  return {
    loading,
    error,
    therapySelect,
    query,
    paging,
    pagingInfo,
    onSelect,
  }
}
