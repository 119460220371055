import {OutlinedInput} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
// eslint-disable-next-line no-restricted-imports
import grey from '@material-ui/core/colors/grey'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select, {SelectProps} from '@material-ui/core/Select'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import {fetchQnaTypeListApi} from 'api/qnaApi'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import useAuth from 'features/auth/useAuth'
import {selectQna} from 'features/qna/qnaSlice'
import useAsyncList from 'hooks/useAsyncList'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const styles = () =>
  createStyles({
    root: {
      flex: 1,
    },
    noSelect: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '18.5px 14px',
      minHeight: '1.1876rem',
      boxSizing: 'border-box',
      border: '1px solid',
      borderRadius: 4,
      borderColor: grey[400],
      fontSize: '1rem',
    },
    iconButton: {
      marginLeft: 'auto',
    },
    typeWidth: {
      width: '100%',
    },
  })
const selectStyles = makeStyles(styles)

const SelectLoading = () => {
  const classes = selectStyles()
  return (
    <div className={classes.noSelect}>
      <span>로딩중</span>
      <CircularProgress
        color='secondary'
        size='1rem'
        className={classes.iconButton}
      />
    </div>
  )
}

interface ErrorSelectProps {
  error: any
  fetch?: VoidPromiseFunc
}

const SelectError = ({error, fetch}: ErrorSelectProps) => {
  const classes = selectStyles()
  return (
    <div className={classes.noSelect}>
      <span>{error}</span>
      {fetch && (
        <IconButton onClick={fetch} className={classes.iconButton} size='small'>
          <RefreshIcon />
        </IconButton>
      )}
      {!fetch && <CloseIcon className={classes.iconButton} />}
    </div>
  )
}

export default function SelectQnaType(prop: SelectProps) {
  const {t} = useTranslation()
  const {...selectProps} = prop
  const classes = selectStyles()
  const {user: currentUser} = useAuth()
  const qnaData = useSelector(selectQna)
  const qnaType = ''
  const outlinedInputClasses = useOutlinedInputStyles()

  const [state, fetch] = useAsyncList<string>(
    () => fetchQnaTypeListApi(),
    [currentUser],
  )

  const {data, loading, error} = state

  if (loading) return <SelectLoading />
  if (error) return <SelectError error={error} fetch={fetch} />

  return (
    <div>
      <Grid item xs={12} sm={12}>
        <Select
          defaultValue={qnaType}
          displayEmpty
          variant='outlined'
          className={classes.typeWidth}
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
          {...selectProps}
        >
          <MenuItem disabled value=''>
            <Typography>
              {qnaData.qnaData?.qnaType ?? t('IInquireTypeRequired')}
            </Typography>
          </MenuItem>
          {data?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </div>
  )
}
