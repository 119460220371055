import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'

const ErrorPageStyle = () =>
  createStyles({
    root: {
      backgroundColor: '#F4F4F7',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    statusCodeText: {
      fontSize: 100,
      color: '#676a6c',
      margin: 0,
      fontWeight: 500,
    },
    titleText: {
      fontWeight: 800,
      fontSize: 18,
      color: '#676a6c',
      textAlign: 'center',
      margin: 0,
    },
    subText: {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
      fontSize: 14,
      color: '#333',
      textAlign: 'center',
      '& p': {
        color: '#333',
        textAlign: 'center',
      },
    },
  })

interface ForbiddenPageProps {
  message: string
}

const ForbiddenPage = ({message}: ForbiddenPageProps) => {
  const useStyles = makeStyles(ErrorPageStyle)
  const classes = useStyles()

  return (
    <div>
      <div className={classes.root}>
        <h3 className={classes.statusCodeText}>403</h3>
        <p className={classes.titleText}>Forbidden</p>
        <div className={classes.subText} id='errorText'>
          {message}
        </div>
      </div>
    </div>
  )
}

export default ForbiddenPage
