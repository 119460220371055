import Card from 'components/atoms/Card'
import DisplayCard from 'components/DisplayCard'
import React from 'react'
import {useTranslation} from 'react-i18next'
import packageJson from '../../../package.json'

function DescBrainMCIPage() {
  const {t} = useTranslation()
  const contents = [
    {title: t('IProductName'), content: 'iSyncBrain-MCI Classifier'},
    {title: t('IItemName'), content: t('IEEGAnalysisSoftware')},
    {title: t('IModelName'), content: 'ISB-M-001'},
    {title: t('IManuFacturer'), content: t('IMedisyncInc')},
    {title: t('IAddress'), content: t('IAppCompanyAddress')},
    {title: t('ILicenseNumber'), content: t('ILicenseCodeNumber')},
    {title: t('ICertificationNumber'), content: t('ICertificationBrainMci')},
    {title: t('ISerialNumber'), content: packageJson.version},
    {title: t('IYearOfManufacture'), content: t('IAugustEight')},
    {title: t('IPackagingUnit'), content: t('INotApplicable')},
    {title: t('IPurposeOfUse'), content: t('IMciPurposeUse')},
    {title: 'UDI', content: '(01)08800108500318'},
  ]
  return (
    <Card>
      <DisplayCard contents={contents} />
    </Card>
  )
}

export default DescBrainMCIPage
