import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import useStyles from 'components/Select/selectFormStyle'
import React from 'react'

interface SelectErrorProps {
  error: any
  fetch?: VoidPromiseFunc
}

export default function SelectError({error, fetch}: SelectErrorProps) {
  const classes = useStyles()
  return (
    <div className={classes.noSelect}>
      <span>{error}</span>
      {fetch && (
        <IconButton onClick={fetch} className={classes.iconButton} size='small'>
          <RefreshIcon />
        </IconButton>
      )}
      {!fetch && <CloseIcon className={classes.iconButton} />}
    </div>
  )
}
