import AnxietyLevel1Icon from 'assets/img/ic_ppg_anxiety_01.png'
import AnxietyLevel2Icon from 'assets/img/ic_ppg_anxiety_02.png'
import AnxietyLevel3Icon from 'assets/img/ic_ppg_anxiety_03.png'
import DepressionLevel1Icon from 'assets/img/ic_ppg_depression_01.png'
import DepressionLevel2Icon from 'assets/img/ic_ppg_depression_02.png'
import DepressionLevel3Icon from 'assets/img/ic_ppg_depression_03.png'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Typography} from '@material-ui/core'
import SummaryTable from 'components/molcules/ProfitSummaryReport/SummaryTable'
import ReportSubtitle from 'components/Text/ReportSubtitle'

interface ItemCell {
  id: string
  label: string
}

const DepressionIconList = [
  DepressionLevel1Icon,
  DepressionLevel2Icon,
  DepressionLevel3Icon,
]

const AnxietyIconList = [
  AnxietyLevel1Icon,
  AnxietyLevel2Icon,
  AnxietyLevel3Icon,
]

const depressItems: ItemCell[] = [
  {id: 'IDepressLevel_1', label: 'IDepressLevelDescription_1'},
  {id: 'IDepressLevel_2', label: 'IDepressLevelDescription_2'},
  {id: 'IDepressLevel_3', label: 'IDepressLevelDescription_3'},
]

const anxietyItem: ItemCell[] = [
  {id: 'IAnxietyLevel_1', label: 'IAnxietyLevelDescription_1'},
  {id: 'IAnxietyLevel_2', label: 'IAnxietyLevelDescription_2'},
  {id: 'IAnxietyLevel_3', label: 'IAnxietyLevelDescription_3'},
]

export default function HRVSummarySentimental(props: {
  anxiety: number
  depression: number
  customerFirstName: string
  customerLastName: string
}) {
  const {anxiety, depression, customerFirstName, customerLastName} = props
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const koreanCustomerName = `${customerLastName} ${customerFirstName}`
  const englishCustomerName = `${customerFirstName} ${customerLastName}`

  const getDescription = (number: number, type: string): string => {
    if (type === 'anxiety') {
      return `IAnxietyLevelDescription_${number}`
    }
    if (type === 'depression') {
      return `IDepressLevelDescription_${number}`
    }
    return ''
  }

  return (
    <div className={classes.hrvSentimental}>
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <ReportSubtitle number='5'>
        {t('IHRVSummaryReportAISentimentTitle')}
      </ReportSubtitle>
      <br />

      <div className='Status'>
        <div className='StatusIcon'>
          <img
            className='StatusIcon-image'
            src={DepressionIconList[depression - 1]}
            alt='Depression status icon'
          />
          <div className='StatusIcon-label Depress'>
            {t('IDepressLevel', {context: depression})}
          </div>
        </div>
        <div className='Status-text'>
          <div className='Title'>
            {i18n.language === 'ko'
              ? t('IDepressTitle', {
                  name: koreanCustomerName,
                  depress: t('IDepressLevel', {context: depression}),
                })
              : t('IDepressTitle', {
                  name: englishCustomerName,
                  depress: t('IDepressLevel', {context: depression}),
                })}
          </div>
          <div className='Level'>
            {t(getDescription(depression, 'depression'))}
          </div>
          <div className='Reference'>{t('IDepressDescription')}</div>
        </div>
        <div className='StatusIcon'>
          <img
            className='StatusIcon-image'
            src={AnxietyIconList[anxiety - 1]}
            alt='Anxiety status icon'
          />
          <div className='StatusIcon-label Anxiety'>
            {t('IAnxietyLevel', {context: anxiety})}
          </div>
        </div>

        <div className='Status-text'>
          <div className='Title'>
            {i18n.language === 'ko'
              ? t('IAnxietyTitle', {
                  name: koreanCustomerName,
                  anxiety: t('IAnxietyLevel', {context: anxiety}),
                })
              : t('IAnxietyTitle', {
                  name: englishCustomerName,
                  anxiety: t('IAnxietyLevel', {context: anxiety}),
                })}
          </div>
          <div className='Level'>{t(getDescription(anxiety, 'anxiety'))}</div>
          <div className='Reference'>{t('IAnxietyDescription')}</div>
        </div>
      </div>
      <Typography className={classes.padding} />
      <div className='TableTitle'> {t('IDepressSteps')}</div>
      <SummaryTable
        items={depressItems}
        headerTitle={t('IStageClassification')}
      />
      <Typography className={classes.padding} />
      <div className='TableTitle'> {t('IAnxietySteps')}</div>
      <SummaryTable
        items={anxietyItem}
        headerTitle={t('IStageClassification')}
      />
    </div>
  )
}
