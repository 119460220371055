import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {useForm, FormProvider} from 'react-hook-form'
import {makeStyles} from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {Button, Card, Dialog, Divider, IconButton} from '@material-ui/core'

import useInvoice from 'features/invoice/useInvoice'

import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import StandardPlan from 'pages/CustomerManagement/InvoiceSetting/Form/StandardPlan'
import MonthlyFee from 'pages/CustomerManagement/InvoiceSetting/Form/MonthlyFee'
import RatePlan from 'pages/CustomerManagement/InvoiceSetting/Form/RatePlan'
import InvoiceOtherSetting from 'pages/CustomerManagement/InvoiceSetting/Form/InvoiceOtherSetting'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'
import useFailureModal from 'features/modal/useFailureModal'

const useStyles = makeStyles(InvoiceSettingStyle)

function InvoiceSetting() {
  const {t} = useTranslation()
  const classes = useStyles()
  const [press, setPress] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const [newLabel, setNewLabel] = useState<string>('')
  const location = useLocation()
  const {
    loading,
    listInvoice,
    idInvoice,
    dataInvoices,
    fetchInvoices,
    changeInvoiceVersionAction,
    updateDataVersion,
    handleNewInvoice,
  } = useInvoice()
  const createBilling = useForm()
  const {
    reset,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{newVersion: string}>()

  const queryParams = new URLSearchParams(location.search)
  const orgId = queryParams.get('orgId')
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const handleSaveInvoice = () => {
    setPress(press + 1)
  }
  const handleChangeInvoice = (id: number | undefined) => {
    if (typeof id === 'number') changeInvoiceVersionAction(id)
  }

  const handleCreateNewVersion = async () => {
    await handleNewInvoice(newLabel)
    setOpen(false)
    setNewLabel('')
    reset({newVersion: ''})
  }
  const isDisabledSave = useMemo(() => {
    const versionActive = dataInvoices.find((item) => item.id === idInvoice)
    return versionActive?.canEditable === false
  }, [dataInvoices, idInvoice])

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 16) {
      onFailureModalOpen(t('IErrorLongerThan16Characters'))
      return
    }
    setNewLabel(e.target.value)
  }

  const resetInputNewVersion = () => {
    setOpen(false)
    setNewLabel('')
    reset({newVersion: ''})
  }

  useEffect(() => {
    const fetchData = async () => {
      if (Number(orgId)) {
        await fetchInvoices(Number(orgId))
      }
    }
    fetchData()
  }, [orgId])

  useEffect(() => {
    if (idInvoice) updateDataVersion()
  }, [idInvoice])

  useEffect(() => {
    if (!listInvoice.length && !loading) {
      setOpen(true)
    }
  }, [orgId, listInvoice, loading])

  return (
    <>
      <div className={classes.topRoot}>
        <div className={classes.container}>
          <CardWithTitle
            title={t('IBillingDetails')}
            className={classes.formRegister}
          >
            <div className={classes.invoiceAction}>
              <FormControl variant='outlined'>
                <Select
                  value={idInvoice}
                  displayEmpty
                  onChange={(event) =>
                    handleChangeInvoice(event?.target?.value as number)
                  }
                  className={classes.searchSelect}
                  input={<OutlinedInput />}
                >
                  {listInvoice?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ActionButton
                variant='contained'
                disableElevation
                disabled={isDisabledSave}
                color='primary'
                onClick={handleSaveInvoice}
              >
                {t('ISaveBilling')}
              </ActionButton>
              <ActionButton
                variant='contained'
                disableElevation
                color='primary'
                onClick={() => setOpen(true)}
              >
                {t('IAddedBillingVersion')}
              </ActionButton>
            </div>
            <div className={classes.InvoiceSettingContainer}>
              <StandardPlan press={press} orgId={Number(orgId)} />
              <MonthlyFee press={press} orgId={Number(orgId)} />
              <RatePlan press={press} orgId={Number(orgId)} />
              <InvoiceOtherSetting press={press} orgId={Number(orgId)} />
            </div>
          </CardWithTitle>
        </div>
      </div>
      <Dialog open={open} fullWidth maxWidth='sm'>
        <Card className={classes.cardWrap}>
          <div className={classes.longTitleContainer}>
            <div className={classes.containerTitle}>
              <Typography variant='h6' className={classes.titleDialog}>
                {t('IAddedBillingVersion')}
              </Typography>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={resetInputNewVersion}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            </div>
          </div>
          <Divider className={classes.titleDivider} />
          <div className={classes.containerNewInvoice}>
            <div className={classes.titleOldestVersion}>
              {listInvoice[listInvoice.length - 1] ? (
                <div>
                  {t('IFinalVersion')} :{' '}
                  {listInvoice[listInvoice.length - 1]?.label ?? 'Empty'}
                </div>
              ) : (
                t('IAddBilling')
              )}
            </div>
            <FormProvider {...createBilling}>
              <form onSubmit={handleSubmit(handleCreateNewVersion)}>
                <div className={classes.contentNewInvoice}>
                  <div>
                    <input
                      id='new_invoice'
                      className={classes.inputVersion}
                      value={newLabel}
                      {...register('newVersion', {
                        required: `${t('IRequired')}`,
                      })}
                      onChange={(e) => changeInput(e)}
                    />
                    {!newLabel && errors.newVersion && (
                      <p className={classes.errorText}>
                        {errors.newVersion.message}
                      </p>
                    )}
                  </div>
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    className={classes.okButtonColor}
                    type='submit'
                  >
                    {t('IAddedBillingVersion')}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className={classes.buttonContainerBgColor}>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                className={classes.closeModalButton}
                type='submit'
                onClick={resetInputNewVersion}
              >
                {t('IOk')}
              </Button>
            </div>
          </div>
        </Card>
      </Dialog>
      <NoticeConfirmDialog />
    </>
  )
}

export default InvoiceSetting
