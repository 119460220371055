import CardWithTitle from 'components/atoms/CardWithTitle'
import QuestionTable from 'pages/CommunityPages/QuestionPage/QuestionTable/index'
import React from 'react'

export default function NoticeListPage() {
  return (
    <CardWithTitle>
      <QuestionTable />
    </CardWithTitle>
  )
}
