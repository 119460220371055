import {AnalysisProduct, PurchasedPackage} from 'api/paymentApi'
import {TFunction} from 'react-i18next'

export default class PurchasedPackageModel implements PurchasedPackage {
  t: TFunction

  analyses: [AnalysisProduct]

  analysisGroup: [string]

  name: string

  packageId: string

  bundleId: string

  givenAmount: number | null

  remainAmount: number | null

  subscriptionPeriod: number

  billingCycle: number

  expiresAt: Date

  constructor(raw: PurchasedPackage, t: TFunction) {
    this.t = t

    this.analyses = raw.analyses
    this.analysisGroup = raw.analysisGroup
    this.name = raw.name
    this.packageId = raw.packageId
    this.bundleId = raw.bundleId
    this.givenAmount = raw.givenAmount
    this.remainAmount = raw.remainAmount
    this.subscriptionPeriod = raw.subscriptionPeriod
    this.billingCycle = raw.billingCycle
    this.expiresAt = raw.expiresAt
  }

  getRemainAmount = (): number | string => {
    if (this.remainAmount == null) {
      return this.t('IUnlimited')
    }
    return this.remainAmount
  }

  getGivenAmount(): number | string {
    if (this.givenAmount == null) {
      return this.t('IUnlimited')
    }
    return this.givenAmount
  }

  getGivenDate = (): string => {
    // const sum = this.subscriptionPeriod * this.billingCycle
    const sum = this.billingCycle
    return this.t(sum === 1 ? 'INMonth' : 'INMonths', {number: sum})
  }

  getRemainDate = (): string => {
    const now = new Date()
    const expires = new Date(this.expiresAt)
    const diffYear = expires.getUTCFullYear() - now.getUTCFullYear()
    const diffMonth = expires.getUTCMonth() - now.getUTCMonth()

    // TODO: 만료일이 1회 결제에 대한 만료일로 변경될 경우 this.subscriptionPeriod 빼기
    const sum = (diffYear * 12 + diffMonth) / this.subscriptionPeriod

    return this.t(sum === 1 ? 'INMonth' : 'INMonths', {number: sum})
  }
}
