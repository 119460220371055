import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  ActivitySearchCondition,
  KeyOfActivitySearchCondition,
} from 'api/patientApi'
import CalenderDatePicker from 'components/Calender/CalenderPicker'
import SearchBar from 'components/Forms/SearchBar'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {formatDate} from 'helpers/dateHelper'
import React, {ReactNodeArray, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

const SEARCH_SELECT_ITEMS: SelectItem<KeyOfActivitySearchCondition>[] = [
  {label: 'IMainCareNo', value: 'NUMBER'},
  {label: 'IFileName', value: 'FILE_NAME'},
]

interface PatientActivitySearchProps {
  onSearch: (params: ActivitySearchCondition) => void
  optionButtons?: ReactNodeArray
}

const PatientActivitySearch = ({
  onSearch,
  optionButtons = [],
}: PatientActivitySearchProps) => {
  const classes = useToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()

  const {t} = useTranslation()
  const currentDate = new Date()

  const [searchCondition, setSearchCondition] =
    useState<KeyOfActivitySearchCondition>('FILE_NAME')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [date, setDate] = useState(currentDate)

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as KeyOfActivitySearchCondition)

  const handleDateChanged = (date?: Date | null) => {
    if (date) setDate(date)
  }

  const getSearch = (): ActivitySearchCondition => {
    if (searchCondition === 'NUMBER') {
      return {
        searchName: searchCondition,
        searchValue: Number.parseInt(searchKeyword, 10),
      }
    }
    return {
      searchName: searchCondition,
      searchValue: searchKeyword,
    }
  }

  const handleSearch = () => {
    const newSearch = getSearch()
    onSearch(newSearch)
  }

  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  useEffect(() => {
    if (searchCondition === 'DATE') {
      onSearch({
        searchName: searchCondition,
        searchValue: formatDate(date ?? new Date()),
      })
    }
  }, [date])

  return (
    <div className={classes.container}>
      <FormControl variant='outlined' className={classes.searchSelect}>
        <Select
          value={searchCondition}
          onChange={handleSearchChanged}
          input={
            <OutlinedInput
              id='outlined-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          {SEARCH_SELECT_ITEMS.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {optionButtons.length > 0 && <div>{optionButtons}</div>}
      {searchCondition !== 'DATE' && (
        <SearchBar
          onChangeValue={handleValue}
          onSearchEvent={handleSearch}
          disabledValue={false}
        />
      )}

      {searchCondition === 'DATE' && (
        <div>
          <CalenderDatePicker
            onChangeValue={handleDateChanged}
            dateValue={date}
          />
        </div>
      )}
    </div>
  )
}
export default PatientActivitySearch
