export const ReportAnalysisTypeMap = {
  RawData: '1',
  ICAComponents: '12',
  OccipitalAlphaPeak: '13',
  SourceROIPower: '14',
  BandPower: '2',
  PowerSpectrum: '3',
  PowerRatio: '5',
  ThreeDView: '16',
} as const
export type ReportAnalysisType = ValueOf<typeof ReportAnalysisTypeMap>

export const ReportIcaAnalysisTypeMap = {
  RawData: '1001',
  ComponentDataTimeSeries: '1002',
  ComponentData: '1003',
  CleanedData: '1004',
} as const
export type ReportIcaAnalysisType = ValueOf<typeof ReportIcaAnalysisTypeMap>

export const CodeTypeMap = {
  Individual: 'I',
  Normative: 'N',
  GroupCompare: 'G',
  RawData: 'R',
} as const
export type CodeType = ValueOf<typeof CodeTypeMap>

export type AnalysisDivision = 'EEG' | 'HRV'

export const ReportTypeMap = {
  None: 'NONE',
  N1: 'N1',
  P1: 'P1',
  P2: 'P2',
  C1: 'C1',
} as const
export type ReportType = ValueOf<typeof ReportTypeMap>

export type EegReportType = 'NONE' | 'N1' | 'P1' | 'P2'
export type HrvReportType = 'NONE' | 'N1' | 'P1'
export type MciReportType = 'NONE' | 'C1'

export const AnalysisFeature = {
  None: 'NONE',
  EegIndividual: 'EEG_INDIVIDUAL',
  EegAdditional: 'EEG_ADDITIONAL',
  EegNorm: 'EEG_NORM',
  EegNormAdditional: 'EEG_NORM_ADDITIONAL',
  EegSummary: 'EEG_SUMMARY',
  EegMci: 'EEG_MCI',
  HrvIndividual: 'HRV_INDIVIDUAL',
  HrvSummary: 'HRV_SUMMARY',
  EdfOnly: 'EDF_ONLY',
}
export type AnalysisFeatureValue = ValueOf<typeof AnalysisFeature>

export const PairableChannels = [
  'Fp1',
  'Fp2',
  'F7',
  'F8',
  'F3',
  'F4',
  'T3',
  'T4',
  'C3',
  'C4',
  'T5',
  'T6',
  'P3',
  'P4',
  'O1',
  'O2',
] as const
export type PairableChannel = (typeof PairableChannels)[number]

export const UnPairableChannels = ['Fz', 'Cz', 'Pz'] as const

export type UnPairableChannel = (typeof UnPairableChannels)[number]

export const Channels = [...PairableChannels, ...UnPairableChannels] as const

export type Channel = (typeof Channels)[number]

export const PowerTypes = ['abs_uv', 'abs_db', 'rel'] as const

export type PowerType = (typeof PowerTypes)[number]
export const isValidPowerSpectrumSubType = (subType: number) =>
  subType >= 1 && subType <= 3
export const getPowerSpectrumSubType = (powerType: PowerType) => {
  switch (powerType) {
    case 'abs_uv':
      return 1
    case 'abs_db':
      return 2
    case 'rel':
      return 3
    default:
      return -1
  }
}

export const isPairable = (channel: string): channel is PairableChannel =>
  PairableChannels.includes(channel as PairableChannel)

export const isUnpairable = (channel: string): channel is UnPairableChannel =>
  UnPairableChannels.includes(channel as UnPairableChannel)

export type ChannelPair = [PairableChannel, PairableChannel]
export const ChannelPairs: ChannelPair[] = [
  ['Fp1', 'Fp2'],
  ['F7', 'F8'],
  ['F3', 'F4'],
  ['T3', 'T4'],
  ['C3', 'C4'],
  ['T5', 'T6'],
  ['P3', 'P4'],
  ['O1', 'O2'],
]

export const getPair = (channel: string): Channel | null => {
  if (!isPairable(channel)) return null

  const pair = ChannelPairs.find((item) => item.includes(channel))
  if (!pair) return null
  const [pair1, pair2] = pair
  return pair1 === channel ? pair2 : pair1
}

export enum AnalysisReportType {
  EEGIndividual = 'EEGIndividual',
  EEGMCI = 'EEGMCI',
  EEGNorm = 'EEGNorm',
  EEGSummary = 'EEGSummary',
  HRVIndividual = 'HRVIndividual',
  HRVSummary = 'HRVSummary',
}

export enum HealthProductType {
  Basic = 'Basic',
  BasicHrv = 'BasicHrv',
  Premium = 'Premium',
  Standard = 'Standard',
  HrvOnly = 'HrvOnly',
  PremiumPlus = 'PremiumPlus',
  StandardHrv = 'StandardHrv',
  PremiumPlusHrv = 'PremiumPlusHrv',
  PremiumHrv = 'PremiumHrv',
}

export const LIST_HEALTH_PRODUCT_TAB = [
  {type: HealthProductType.Basic, tabs: [0, 1]},
  {type: HealthProductType.BasicHrv, tabs: [0, 1, 5]},
  {type: HealthProductType.Premium, tabs: [0, 1, 2, 3]},
  {type: HealthProductType.PremiumHrv, tabs: [0, 1, 2, 3, 5]},
  {type: HealthProductType.PremiumPlus, tabs: [0, 1, 2, 3, 4]},
  {type: HealthProductType.PremiumPlusHrv, tabs: [0, 1, 2, 3, 4, 5]},
  {type: HealthProductType.Standard, tabs: [0, 1, 2]},
  {type: HealthProductType.StandardHrv, tabs: [0, 1, 2, 5]},
  {type: HealthProductType.HrvOnly, tabs: [5]},
]
