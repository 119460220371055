import CircularProgress from '@material-ui/core/CircularProgress'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
// 내 패키지 변경시 리다이렉트 페이지
function RedirectSuccessPage() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const paymentKey = params.get('paymentKey') ?? null
  const orderId = params.get('orderId') ?? null
  const amount = params.get('amount') ?? null

  useEffect(() => {
    if (paymentKey != null && orderId != null && amount != null) {
      window.opener.successCallback({
        paymentKey,
        orderId,
        amount,
      })
    }
    window.close()
  }, [])

  return (
    <ContentWrap>
      <CircularProgress size='3rem' color='primary' />
    </ContentWrap>
  )
}

export default RedirectSuccessPage
