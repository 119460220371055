import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    horizontalImage: {
      width: '100%',
      height: 'auto',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
