import React from 'react'
import styled from 'styled-components'
import {getPackageFullName} from 'helpers/paymentHelper'
import {AnalysisProduct} from 'api/paymentApi'
import {useTranslation} from 'react-i18next'

interface IncludedProductsProps {
  analyses: [AnalysisProduct]
}

export const StyledIncludedProductWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 10px;
`

export const StyledIncludedProductBadge = styled.span`
  padding: 8px 16px;
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  background-color: #e9ecef;
  border-radius: 30px;
`

function IncludedProducts({analyses}: IncludedProductsProps) {
  const {t} = useTranslation()

  return (
    <StyledIncludedProductWrap>
      {analyses.map((product, idx) => {
        const productText = getPackageFullName(product.code)
        return (
          <StyledIncludedProductBadge key={idx}>
            {t(productText)}
          </StyledIncludedProductBadge>
        )
      })}
    </StyledIncludedProductWrap>
  )
}

export default IncludedProducts
