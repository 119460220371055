import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Pagination from 'components/molcules/Pagination'
import useTableStyles from 'components/Table/useTableStyles'
import {getNoticeDetailPath, getNoticeWritePath} from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import useNotice from 'features/notice/useNotice'
import {getQueryFromUrl, isDefined, isNotDefined} from 'helpers/commonHelper'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 15,
      padding: '10px 20px',
    },
    tableHeader: {
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },

    tableContainer: {
      backgroundColor: '#fff',
      borderRadius: 5,
      border: '1px solid #D6D6D8',
      marginTop: 10,
      // marginBottom: 30,
      overflow: 'auto',
      width: '100%',
    },
    tableWrap: {
      borderRadius: 5,
    },
    hidden: {
      visibility: 'hidden',
    },
    titleWrap: {
      minWidth: 300,
    },
    dateWrap: {
      minWidth: 120,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
        cursor: 'pointer',
      },
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 14,
      marginTop: 10,
    },
    pagination: {
      marginBottom: 30,
      marginTop: 10,
    },
  }),
)

export default function NoticeTable() {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useStyles()
  const tableClasses = useTableStyles()
  const {user: currentUser} = useAuth()
  const {noticePage, paging, pagingInfo, loading, onPaging} = useNotice()

  const setPageIndex = (page: number) =>
    onPaging({
      paging: {page, size: 10},
    })

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initParams = getQueryFromUrl(location.search)
    onPaging({
      paging: {page: 0, size: 10},
      ...initParams,
    })
  }, [])

  const handleCreate = () => navigate(getNoticeWritePath())

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        {(currentUser?.authority === 'ROLE_COMPANY_SALES' ||
          currentUser?.authority === 'ROLE_COMPANY_CS') && (
          <ActionButton
            startIcon={<CreateIcon />}
            variant='contained'
            size='medium'
            color='primary'
            disableElevation
            onClick={handleCreate}
          >
            {t('ICreate')}
          </ActionButton>
        )}
      </div>
      <Grid xs={12}>
        <div>
          <Table className={tableClasses.table} size='small'>
            <TableHead>
              <TableRow className={tableClasses.tableHeader}>
                <TableCell align='center'>
                  <Typography className={classes.hidden}>d</Typography>
                </TableCell>
                <TableCell align='center'>{t('INotice')}</TableCell>
                <TableCell align='center'>{t('ICreateDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDefined(noticePage) &&
                noticePage.map((notice: any) => (
                  <TableRow
                    key={notice.title}
                    hover
                    classes={{hover: classes.hover}}
                    onClick={(event) => {
                      event.stopPropagation()
                      navigate(getNoticeDetailPath(notice.id))
                    }}
                  >
                    <TableCell>
                      <Typography align='center' className={classes.hidden}>
                        {notice.id}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>{notice.title}</TableCell>
                    <TableCell align='center'>
                      {isoStringToDateAndTimeString(notice.dateTime)}
                    </TableCell>
                  </TableRow>
                ))}
              {(isNotDefined(noticePage) || noticePage?.length === 0) && (
                <Typography align='center' style={{color: '#333333'}}>
                  {t('INoticeEmpty')}
                </Typography>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={classes.pagination}>
          <Pagination
            totalPageCount={pagingInfo.totalPages}
            currentPageIndex={paging.page}
            itemCountPerPage={paging.size}
            setCurrentPageIndex={setPageIndex}
            loading={loading}
            onItemCountPerPageChanged={(event) =>
              onPaging({
                paging: {
                  page: 0,
                  size: parseInt(event.target.value as string, 10),
                },
              })
            }
          />
        </div>
      </Grid>
    </div>
  )
}
