import {Checkbox} from '@material-ui/core'
import {
  AllPackages,
  fetchPackagePriceListApi,
  Price,
  updateRegisterPackageListApi,
} from 'api/paymentApi'
import TableButton from 'components/atoms/Buttons/TableButton'
import PaymentInfoEditDialog from 'components/Dialog/PaymentInfoEditDialog'
import CheckBoxRoundTableHeader from 'components/Table/CheckBoxRoundTableHeader'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import {openPaymentInfoEditDialog} from 'features/modal/modalSlice'
import {
  fetchOrganizationPackageAction,
  selectOrganizationPackages,
} from 'features/sales/salesSlice'
import {dateToDashString, makeExpires} from 'helpers/dateHelper'
import {makePackagesName, removeDot} from 'helpers/paymentHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import styled from 'styled-components'

const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 40px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
  }
`

const TableDiv = styled.div`
  width: '50%';
`
export interface PackageTableProps {
  oid: string
}
export interface PackageList {
  id: number
  appearanceName: string
  availableNumberOfTimes: number
  expiresOn: number
  isDefault: boolean
  price: Price
}

const OrganizationPackageTable = ({oid}: PackageTableProps) => {
  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IProductName'},
    {id: '2', label: 'IIncludedProduct'},
    {id: '3', label: 'IPrice'},
    {id: '4', label: 'IDiscountExpirationAt'},
    {id: '5', label: 'IEdit'},
  ]
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openDialog = () => dispatch(openPaymentInfoEditDialog())
  const organizationPackages = useSelector(selectOrganizationPackages)

  const [itemList, setItemList] = React.useState<AllPackages[]>()
  const [packageId, setPackageId] = React.useState<number>()
  const [packageType, setPackageType] = React.useState<string>()

  const updatePackage = async (packageId: number[]) => {
    try {
      const result = await updateRegisterPackageListApi({
        orgOid: oid,
        packageIds: packageId,
      })
      if (result) {
        await dispatch(fetchOrganizationPackageAction(oid))
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const updateMethod = () => {
    const array: number[] = []
    if (itemList) {
      itemList.forEach((element) => {
        if (element.isJoined) {
          array.push(element.id)
        }
      })
    }
    updatePackage(array)
  }
  const changeChecked = (
    changeId: number,
    index: number,
    item: AllPackages,
    isClicked: boolean,
  ) => {
    if (itemList) {
      const newState = [...itemList]
      newState[index] = {
        ...item,
        isJoined: isClicked,
      }
      setItemList(newState)
    }
  }
  const fetchPackage = async () => {
    try {
      if (itemList) {
        const data = {
          packageId: itemList[0].id ?? 1,
          orgUid: oid as string,
        }
        await fetchPackagePriceListApi(data)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  useEffect(() => {
    updateMethod()
    if (itemList && itemList.length > 0) {
      setPackageType(itemList[0].packageType ?? undefined)
    }
  }, [itemList])
  useEffect(() => {
    if (organizationPackages) {
      setItemList(organizationPackages)
    }
  }, [organizationPackages])
  useEffect(() => {
    fetchPackage()
  }, [])

  return (
    <TableDiv>
      <PackageTable>
        <th style={{borderTopLeftRadius: '5px', backgroundColor: '#e9ecef'}}>
          <span> </span>
        </th>
        {HeaderCells.map((item, index) => (
          <CheckBoxRoundTableHeader
            label={t(item.label)}
            length={HeaderCells.length}
            index={index}
          />
        ))}
        {/* TODO: 단품 가입 가능 패키지 리스트 api나 단품을 추가하고 수정할수있는 api가 나오면 작업 예정  */}
        {itemList &&
          itemList.map((item, index) => (
            <RoundTableWrap key={index}>
              <td>
                <Checkbox
                  checked={item.isJoined}
                  onClick={() => {
                    changeChecked(item.id, index, item, !item.isJoined)
                  }}
                />
              </td>
              <td>{item.appearanceName}</td>
              <td>{makePackagesName(item.products ?? [])}</td>
              <td style={{width: 100}}>
                {removeDot(
                  item.packageType === 'PRE'
                    ? item.price.undefinedPrice
                    : item.price.defaultPrice,
                )}{' '}
                {t('IMonetaryUnit')}
              </td>
              {item.expiresOn && (
                <td>{dateToDashString(makeExpires(item.expiresOn))}</td>
              )}
              {!item.expiresOn && <td>-</td>}
              <td>
                <TableButton
                  onClick={() => {
                    openDialog()
                    setPackageId(item.id)
                  }}
                >
                  {t('IEdit')}
                </TableButton>
              </td>
            </RoundTableWrap>
          ))}
      </PackageTable>
      <PaymentInfoEditDialog
        packageId={packageId ?? 0}
        organizationOid={oid}
        packageType={packageType}
      />
      <br />
    </TableDiv>
  )
}
export default OrganizationPackageTable
