import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Spacing} from 'components/common/useBaseStyle'
import useHealthCenterHeartRateAnalysisStyles from 'pages/HealthCenter/Report/HealthCenterHeartRateAnalysis/Style'
import {Typography} from '@material-ui/core'
import {HrvAutoNervousSystemBalance} from 'api/healthCareApi'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'

export const PageWrap = styled.div`
  background: white;
  width: 1280px;
  padding: 30px 60px;
`
export const PageContent = styled.div`
  width: 1160px;
`

export const PageCard = styled.div`
  width: 100%;
`
export const HeaderCard = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`
export const HeaderCardNumber = styled.div`
  background-color: #2e4d6f;
  color: white;
  background-color: #2e4d6f;
  width: 42px;
  height: 42px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px;
`

export const HeaderCardTitle = styled.div`
  font-size: 20px;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`

export const HeaderCardTitleGreen = styled.div`
  font-size: 18px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: #00a29c;
  color: white;
  padding: 10px 24px 12px;
  font-weight: 500;
  size: 18px;
  line-height: 18px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const Line = styled.div`
  height: 1px;
  background-color: #2e4d6f;
  width: 1150px;
`

export const LineGreen = styled.div`
  height: 1px;
  background-color: #00a29c;
  width: 1145px;
`
export const LineGreenHaft = styled.div`
  height: 1px;
  background-color: #00a29c;
  width: 545px;
`

export const EmptyBox = styled.div`
  width: 10px;
`

export const ReportCustomPadding = styled.div`
  padding: ${Spacing.report.reportCustomPadding}px;
`

export const FlexBox = styled.div`
  display: flex;
`

export interface HeartRateAnalysisProps {
  hrvTableImage: string
  hrvCircularImage: string
  hrvAutoNervousSystemBalance: HrvAutoNervousSystemBalance | null
  hrvContourImage: string
  hrvHistogramImage: string
  hrvCircularLevel: number
  hrvContourLevel: number
  hrvHistogramLevel: number
}

function HealthCenterHeartRateAnalysisPage({
  hrvTableImage,
  hrvCircularImage,
  hrvAutoNervousSystemBalance,
  hrvContourImage,
  hrvHistogramImage,
  hrvCircularLevel,
  hrvContourLevel,
  hrvHistogramLevel,
}: HeartRateAnalysisProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterHeartRateAnalysisStyles()
  const CIRCULAR = {good: 1, normal: 3, bad: 2}
  const HISTOGRAM_LEVER = {good: 1, normal: 2, bad: 3}
  const APPEARS = {
    great: 3,
    good: 4,
    average: 7,
    fast: 6, // CONFIRM
    slow: 2, // CONFIRM
    overload: 1,
    fatigue: 8,
    weakness: 5,
  }
  const getHeaderCircular = (value: number) => {
    if (value === CIRCULAR.normal)
      return t('IAutonomicBalanceNormal', {
        parasympathetic: hrvAutoNervousSystemBalance?.parasympathetic ?? 0,
        sympathetic: hrvAutoNervousSystemBalance?.sympathetic ?? 0,
      })
    if (value === CIRCULAR.bad)
      return t('IAutonomicBalanceBad', {
        parasympathetic: hrvAutoNervousSystemBalance?.parasympathetic ?? 0,
        sympathetic: hrvAutoNervousSystemBalance?.sympathetic ?? 0,
      })
    return t('IAutonomicBalance', {
      parasympathetic: hrvAutoNervousSystemBalance?.parasympathetic ?? 0,
      sympathetic: hrvAutoNervousSystemBalance?.sympathetic ?? 0,
    })
  }

  const getContentCircular = (value: number) => {
    if (value === CIRCULAR.good) return t('ISymptomsSuchGood')
    if (value === CIRCULAR.normal) return t('ISymptomsSuchNormal')
    return t('ISymptomsSuchBad')
  }

  const getHeaderAppears = (value: number) => {
    if (value === APPEARS.great) return t('IHealthAppearsGood') // 3
    if (value === APPEARS.good) return t('IHealthAppearsNormal') // 4
    if (value === APPEARS.average) return t('IHealthAppearsBad') // 7
    if (value === APPEARS.fast) return t('IHeaderCautionFast') // 6 -
    if (value === APPEARS.slow) return t('IHeaderCautionSlow') // 2 -
    if (value === APPEARS.overload) return t('IHealthAppearsHeaderOverload') // 1
    if (value === APPEARS.fatigue) return t('IHealthAppearsHeaderFatigue') // 8
    return t('IHealthAppearsHeaderWeakness') // 5
  }

  const getContentAppears = (value: number) => {
    if (value === APPEARS.great)
      return [
        t('IHealthAppearsGoodChildContentFirst'),
        t('IHealthAppearsGoodChildContentSecond'),
      ]
    if (value === APPEARS.good)
      return [t('IHealthAppearsNormalChildContentSecond')]
    if (value === APPEARS.average)
      return [t('IHealthAppearsBadChildContentSecond')]
    if (value === APPEARS.fast) return [t('IContentCautionFast')]
    if (value === APPEARS.slow) return [t('IContentCautionSlow')]
    if (value === APPEARS.overload) return [t('IHealthAppearsContentOverload')]
    if (value === APPEARS.fatigue) return [t('IHealthAppearsContentFatigue')]
    return [t('IHealthAppearsContentWeakness')]
  }

  const getHeaderHistogramLevel = (value: number) => {
    if (value === HISTOGRAM_LEVER.good) return t('IRateVariabilityHeaderGood')
    if (value === HISTOGRAM_LEVER.normal)
      return t('IRateVariabilityHeaderNormal')
    return t('IRateVariabilityHeaderBad')
  }

  const getContentHistogramLevel = (value: number) => {
    if (value === HISTOGRAM_LEVER.good) return t('IRateVariabilityContentGood')
    if (value === HISTOGRAM_LEVER.normal)
      return t('IRateVariabilityContentNormal')
    return t('IRateVariabilityContentBad')
  }

  return (
    <div className={classes.heartRate}>
      <PageWrap>
        <PageContent>
          <WrapElement number={1} text={t('IComprehensive')}>
            <FlexBox>
              <CardContent>
                <div className={classes.paddingContent}>
                  <p className={classes.fontWeightContent}>
                    {t('IComprehensiveHeart')}
                  </p>
                  <p className={classes.variabilityLabel}>{t('IEatAndRest')}</p>
                </div>
                <div className={classes.contentCard}>
                  <div className={classes.tablePercent}>
                    <img src={hrvTableImage} alt='Table' />
                  </div>
                  <div className={classes.circularChart}>
                    <div className={classes.flexCircular}>
                      <div className={classes.changePosition}>
                        <div className={classes.textCircularTop}>
                          {t('IAutonomic')}
                        </div>
                        <div>
                          <img
                            className={classes.imgCircular}
                            src={hrvCircularImage}
                            alt='Circular'
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.textEndCircular}>
                      <div className={classes.textLeftCircular}>
                        <div>
                          <div className={classes.textLeftGreen}>
                            {t('IParasympatheticNervousSystem')}
                          </div>
                          <div className={classes.textLeftGreen}>
                            {t('IPredominant')}
                          </div>
                          <div className={classes.textLeftDec}>
                            {t('IDepressionLethargy')}
                          </div>
                        </div>
                      </div>
                      <div className={classes.textRightCircular}>
                        <div style={{width: '150px'}}>
                          <div className={classes.textRightGreen}>
                            {t('ISympatheticNer')}
                          </div>
                          <div className={classes.textRightGreen}>
                            {t('IPredominant')}
                          </div>
                          <div className={classes.textRightDec}>
                            {t('INervousAnxiousPart1')}
                          </div>
                          <div className={classes.textRightDec}>
                            {t('INervousAnxiousPart2')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Typography className={classes.padding} />
                <Typography className={classes.padding} />
                <div className={classes.paddingContent}>
                  <HeaderCard>
                    <HeaderCardTitleGreen>
                      {t('IAnalysisHealth')}
                    </HeaderCardTitleGreen>
                  </HeaderCard>
                  <FlexBox>
                    <LineGreen> </LineGreen>
                  </FlexBox>
                  <div className={classes.contentAnalysis}>
                    <p className={classes.fontWeightContent}>
                      {getHeaderCircular(hrvCircularLevel)}
                    </p>
                    <p className={classes.fontSize18}>
                      {getContentCircular(hrvCircularLevel)}
                    </p>
                  </div>
                </div>
              </CardContent>
            </FlexBox>
          </WrapElement>
          <WrapElement number={2} text={t('IDimensional')}>
            <div className={classes.contentCard}>
              <div className={classes.tablePercentMargin}>
                <img src={hrvContourImage} alt='Contour' />
              </div>
              <div className={classes.pieChart}>
                <div>
                  <div>
                    <p className={classes.variabilityLabel}>
                      {t('IHeartRateVariability')}
                    </p>
                  </div>
                  <Typography className={classes.padding} />
                  <Typography className={classes.padding} />
                  <div>
                    <HeaderCard>
                      <HeaderCardTitleGreen>
                        {t('IAnalysisHealth')}
                      </HeaderCardTitleGreen>
                    </HeaderCard>
                    <FlexBox>
                      <LineGreenHaft> </LineGreenHaft>
                    </FlexBox>
                    <div className={classes.contentAnalysis}>
                      <p
                        className={classes.fontWeightContentHeaderAppears}
                        style={{
                          fontSize: '19px !important',
                          letterSpacing: '0.28px !important',
                        }}
                      >
                        {getHeaderAppears(hrvContourLevel)}
                      </p>
                      {getContentAppears(hrvContourLevel).map((item) => (
                        <p className={classes.variabilityLabel} key={item}>
                          {item}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WrapElement>
          <WrapElement number={3} text={t('IVariability')}>
            <div className={classes.contentCard}>
              <div className={classes.tablePercentMargin}>
                <img src={hrvHistogramImage} alt='Histogram' />
              </div>
              <div className={classes.pieChart}>
                <div>
                  <div>
                    <p className={classes.fontWeightContent}>
                      {t('IHistogramHeader')}
                    </p>
                    <p className={classes.variabilityLabel}>
                      {t('IHistogram')}
                    </p>
                  </div>
                  <Typography className={classes.padding} />
                  <Typography className={classes.padding} />
                  <div>
                    <HeaderCard>
                      <HeaderCardTitleGreen>
                        {t('IAnalysisHealth')}
                      </HeaderCardTitleGreen>
                    </HeaderCard>
                    <FlexBox>
                      <LineGreenHaft> </LineGreenHaft>
                    </FlexBox>
                    <div className={classes.contentAnalysis}>
                      <p className={classes.fontWeightContent}>
                        {getHeaderHistogramLevel(hrvHistogramLevel)}
                      </p>
                      <p className={classes.variabilityLabel}>
                        {getContentHistogramLevel(hrvHistogramLevel)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WrapElement>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default HealthCenterHeartRateAnalysisPage
