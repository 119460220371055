/* eslint-disable */
import React, {useEffect, useRef} from 'react'

export default function useInterval(
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  delay: number | undefined,
) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const savedCallback = useRef<Function>()

  useEffect(() => {
    savedCallback.current = callback
  })

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      if (savedCallback.current !== undefined) {
        savedCallback.current()
      }
    }

    if (delay !== undefined) {
      const id = setInterval(tick, delay)
      return () => {
        console.log('timer를 초기화 합니다:', id)
        clearInterval(id)
      }
    }
  }, [delay])
}
