import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Card, {CardProps} from 'components/atoms/Card'
import {Colors} from 'components/common/useBaseStyle'
import SubTitle from 'components/Text/SubTitle'
import TherapyCustomerSearchTable from 'pages/TherapyPages/LedTherapyPage/TherapyCustomerSearchTable'
import TherapyCustomerSelectTable from 'pages/TherapyPages/LedTherapyPage/TherapyCustomerSelectTable'
import React from 'react'
import {useTranslation} from 'react-i18next'
import TherapyRecentReadDialog from 'components/V2Dialog/TherapyDialog/RecentRead'
import TherapyEditDialog from '../../../components/V2Dialog/TherapyDialog/Edit'
import TherapyReadDialog from '../../../components/V2Dialog/TherapyDialog/Read'
import TherapyPrescriptionDialog from '../../../components/V2Dialog/TherapyDialog/Prescription'

// CYM : CardWithTitle Style 따로 수정
const headerStyles = () =>
  createStyles({
    headerRoot: {
      padding: '30px 48px 0',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    headerHasChildren: {},
    headerTitle: {
      fontSize: '1.3125rem',
      fontWeight: 500,
      marginRight: 13,
      color: '#676a6c',
    },
    headerDesc: {
      color: '#555555',
      fontSize: 14,
    },
    titleText: {
      marginRight: 10,
      color: '#676a6c',
    },
    titleNameText: {
      color: '#333333',
    },
  })

const styles = () =>
  createStyles({
    root: {
      borderColor: Colors.border,
      border: '1px solid',
      borderRadius: 5,
      marginBottom: 0,
      paddingBottom: 30,
    },
    divider: {
      height: 1,
      marginBottom: 23,
    },
    body: {
      padding: '6px 48px 0',
    },
    ...headerStyles(),
  })
const useStyles = makeStyles(styles)

interface CardWithTileProps extends CardProps {
  title?: string
  titleName?: string
  desc?: string
}
const CardWithTitle = ({
  title,
  titleName,
  desc,
  children,
  className,
  ...props
}: CardWithTileProps) => {
  const classes = useStyles()

  const headerRootClasses = classNames({
    [classes.headerRoot]: true,
    [classes.headerHasChildren]: !!children,
  })

  return (
    <Card className={`${classes.root} ${className}`} {...props} elevation={0}>
      <div className={headerRootClasses}>
        {title && (
          <SubTitle
            style={{
              overflow: 'auto',
            }}
          >
            <span className={classes.titleText}>{title}</span>
            {titleName !== '' && (
              <span className={classes.titleNameText}>{titleName}</span>
            )}
          </SubTitle>
        )}
      </div>
      {children && <div className={classes.body}>{children}</div>}
    </Card>
  )
}

const TherapyPage = () => {
  const {t} = useTranslation()
  const [therapyId, setTherapyId] = React.useState<string>('')
  const [pbmId, setPbmId] = React.useState<string>('')
  const [isTherapySelected, setIsTherapySelected] =
    React.useState<boolean>(false)
  const [customerName, setCustomerName] = React.useState<string>('')

  return (
    <>
      {/* CYM : Therapy Dialog ADD */}
      <TherapyReadDialog therapyId={therapyId} onFetchPbmId={setPbmId} />
      <TherapyEditDialog therapyId={therapyId} pbmId={pbmId} />
      <TherapyPrescriptionDialog therapyId={therapyId} />
      <TherapyRecentReadDialog />
      <CardWithTitle title={t('ICustomerSearch')}>
        <TherapyCustomerSearchTable
          therapyId={therapyId}
          setTherapyId={setTherapyId}
          setIsTherapySelected={setIsTherapySelected}
          setCustomerName={setCustomerName}
        />
      </CardWithTitle>
      <CardWithTitle
        title={`${t('ITherapyManagementHistory')}`}
        titleName={
          customerName !== '' ? `${customerName} ${t('IActivityTitle1')}` : ''
        }
        style={{marginTop: 25}}
      >
        <TherapyCustomerSelectTable
          therapyId={therapyId}
          isTherapySelected={isTherapySelected}
        />
      </CardWithTitle>
    </>
  )
}

export default TherapyPage
