import {isDev} from 'helpers/envHelper'
import {useEffect, useState} from 'react'
import ReactGa from 'react-ga'

const RouterChangeTracker = () => {
  // const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!isDev()) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
      }
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGa.pageview(window.location.pathname + window.location.search)
    }
  }, [initialized, window.location])
}

export default RouterChangeTracker
