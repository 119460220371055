import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles, Theme} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {updatePasswordApi} from 'api/authApi'
import {navigate} from 'AppHistory'
import Card from 'components/atoms/Card'
import {styles} from 'components/Dialog/findDialogStyle'
import {getHomePath} from 'constants/RouteConstant'
import {useSilentLogin} from 'features/auth/useAuth'
import {
  closeFirstLoginDialog,
  selectFirstLoginDialog,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {isNotDefined} from 'helpers/commonHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import React from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import PasswordInput from './form/PasswordInput'

const useStyles = makeStyles((theme: Theme) => ({
  ...styles(theme),
  result: {
    width: '100%',
    backgroundColor: '#f5f5f5',
    height: 34,
    fontSize: 11,
    color: '#868e96',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .Label': {userSelect: 'none'},
    '& .Email': {paddingLeft: 8},
  },
}))

const ONCLOSE_REASON = {
  CLICK_AWAY: 'backdropClick',
}
const FORM_FIELDS = {
  CURRENT: 'currentPwd',
  NEW: 'newPwd',
  ERROR: 'errors',
}

interface UpdatePassword {
  currentPwd: string
  newPwd: string
}

const FirstLoginDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {open, uid} = useAppSelector(selectFirstLoginDialog)
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const dispatch = useAppDispatch()
  const {onSilentLogin} = useSilentLogin()

  const firstLoginPassword = useForm()

  const handleClose = (event?: any, reason?: any) => {
    // Disable Click Away Can Close Modal
    if (reason && reason === ONCLOSE_REASON.CLICK_AWAY) {
      return
    }
    // Clear Data
    firstLoginPassword.resetField(FORM_FIELDS.CURRENT)
    firstLoginPassword.resetField(FORM_FIELDS.NEW)
    dispatch(closeFirstLoginDialog())
  }

  const handlePasswordRegister = async ({
    currentPwd,
    newPwd,
  }: UpdatePassword) => {
    if (isNotDefined(uid)) {
      return
    }

    try {
      await updatePasswordApi({
        currentPwd,
        newPwd,
        pwdType: 'REGISTER',
        uid,
      })
      await onSuccessModalOpen(t('IProcessSuccess'))
      await onSilentLogin()
      handleClose()
      navigate(getHomePath())
      //
    } catch (err) {
      onFailureModalOpen(err.message)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <Card className={classes.root}>
        <div className='Header'>
          <h3 className='Title'>{t('IFirstLoginPassword')}</h3>
          <IconButton
            type='submit'
            aria-label='search'
            onClick={handleClose}
            className='CloseButton'
          >
            <CloseIcon className='CloseIcon' />
          </IconButton>
        </div>

        <FormProvider {...firstLoginPassword}>
          <form
            onSubmit={firstLoginPassword.handleSubmit((data) => {
              handlePasswordRegister({
                currentPwd: data.currentPwd,
                newPwd: data.newPwd,
              })
            })}
          >
            <div className='Contents'>
              <PasswordInput />

              <Button
                size='medium'
                variant='contained'
                color='primary'
                disableElevation
                fullWidth
                className='ConfirmButton'
                type='submit'
              >
                {t('IFirstLoginPassword')}
              </Button>
            </div>
          </form>
        </FormProvider>

        <Button
          className='CancelButton'
          variant='contained'
          disableElevation
          onClick={handleClose}
        >
          {t('ICancel')}
        </Button>
      </Card>
    </Dialog>
  )
}

export default FirstLoginDialog
