import {OutlinedInput, TableCell, Typography} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {TreatmentTypeSearch} from 'api/treatmentApi'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import TableEmpty from 'components/Table/TableEmpty'
import TableLoading from 'components/Table/TableLoading'
import useStyles from 'components/Table/useTableStyles'
import useTableToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {getTreatmentPatientPath} from 'constants/RouteConstant'
import {useCreateDialog} from 'features/patient/usePatientDialog'
import useTreatmentSearch from 'features/treatment/useTreatmentSearch'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  convertParamsTreatmentListV3,
  convertTypeSearch,
} from 'helpers/treatmentHelper'
import {useLocation} from 'react-router-dom'
import {getQueryFromUrl} from 'helpers/commonHelper'

const SEARCH_SELECT_ITEMS: SelectItem<TreatmentTypeSearch>[] = [
  {label: 'IChartNo', value: 'CHART_NO'},
  {label: 'ICustomerName', value: 'NAME'},
  // {label: 'ITreatmentDate', value: 'TREATMENT_DATE'},
  {label: 'IOperator', value: 'CARE_DOC'},
]

interface HeadCell {
  id: keyof Treatment | 'description' | 'treatment'
  label: string
  type?: 'string' | 'dateTime'
}

const headCells: HeadCell[] = [
  {id: 'chartNo', label: 'IChartNo_no'},
  {id: 'name', label: 'ICustomerName'},
  {id: 'careDate', label: 'ITreatmentDate', type: 'dateTime'},
  {id: 'operator', label: 'IOperator'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => {
          const className =
            headCell.type === 'dateTime'
              ? classes.tableDateTime
              : classes.tableString
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='none'
              className={className}
            >
              {t(headCell.label)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  selected?: string // patient uuid
}

const EnhancedTableToolbar = ({selected}: EnhancedTableToolbarProps) => {
  const {t} = useTranslation()
  const classes = useTableToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const location = useLocation()

  const {loading, periodInfo, onSearchNew} = useTreatmentSearch()
  const {onOpen} = useCreateDialog()

  const [searchCondition, setSearchCondition] =
    React.useState<KeyOfTreatmentSearchKind>('NAME')
  const [searchKeyword, setSearchKeyword] = React.useState('')

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as KeyOfTreatmentSearchKind)

  const handleKeywordSearch = () =>
    onSearchNew({
      ...periodInfo,
      pageNumber: 0,
      pageSize: 5,
      type: 'ALL',
      ...convertParamsTreatmentListV3(searchKeyword, searchCondition),
    })

  const handleAddClick = () => onOpen()

  const handlePatientManagementClick = () => {
    // TODO: 회사 계정일 경우 환자관리 대처
    // if (isCompanyAuthority(subAccount.authority)) {
    //   dispatch(navigate(RouteConstant.SALES_PATIENTS.path))
    // }
    //
    // if (isOrgAuthority(subAccount.authority)) {
    //   dispatch(navigate(RouteConstant.ORG_PATIENTS.path))
    // }
    if (selected !== undefined) {
      navigate(getTreatmentPatientPath(selected))
    }
  }

  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    const searchData = convertTypeSearch(searchParams)
    if (searchData?.searchName) {
      setSearchCondition(searchData?.searchName)
    }
  }, [location.search])

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.searchContainer}>
          <FormControl variant='outlined'>
            <Select
              value={searchCondition}
              onChange={handleSearchChanged}
              className={classes.searchSelect}
              input={
                <OutlinedInput
                  name='age'
                  id='outlined-age-simple'
                  classes={outlinedInputClasses}
                />
              }
            >
              {SEARCH_SELECT_ITEMS.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <SearchBar
            onChangeValue={handleValue}
            onSearchEvent={handleKeywordSearch}
            disabledValue={!searchCondition || loading}
          />
        </div>
        <div className={`${classes.container} ${classes.containerOptional}`}>
          <ActionButton color='primary' onClick={handleAddClick}>
            {t('ICustomerCreate')}
          </ActionButton>
          {/* Hide function */}
          {/* <ActionButton
            onClick={handlePatientManagementClick}
            disabled={selected === undefined}
          >
            {t('ICustomerHome')}
          </ActionButton> */}
        </div>
      </div>
    </div>
  )
}

interface TreatmentTableMiniProps {
  onItemClick: (uuid: string) => void
}

export default function TreatmentTableMini(props: TreatmentTableMiniProps) {
  const classes = useStyles()
  const {onItemClick} = props
  const {t} = useTranslation()
  const {
    allItems,
    loading,
    error,
    query,
    periodInfo,
    paging,
    pagingInfo,
    onSearchNew,
    searchInfo,
  } = useTreatmentSearch()

  const [selected, setSelected] = React.useState<number>()
  const [selectedUuid, setSelectedUuid] = React.useState<string>()
  const rowsPerPage = 5

  const setPageIndex = (pageNumber: number) => {
    onSearchNew({
      ...searchInfo,
      ...periodInfo,
      pageNumber,
      pageSize: query.paging.size,
      type: 'ALL',
    })
  }

  const handleClick = (careId: number, uuid: string) => {
    setSelected(careId)
    onItemClick(uuid)
    setSelectedUuid(uuid)
  }

  const emptyRows = rowsPerPage - allItems.length

  useEffect(() => {
    console.log('allitems', allItems)
  }, [allItems])

  return (
    <TableContainer className={classes.container}>
      <EnhancedTableToolbar selected={selectedUuid} />
      <Table
        stickyHeader
        className={`${classes.table} ${classes.tableMini}`}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='small'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {allItems.map((row, index) => {
            const isItemSelected = selected === row.careId

            return (
              <TableRow
                hover
                onClick={() => handleClick(row.careId, row.uuid)}
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
              >
                <TableCell align='center'>{row.chartNo}</TableCell>
                <TableCell align='center'>
                  {t('IGetFullName', {
                    firstName: row.firstName ?? '',
                    lastName: row.lastName ?? '',
                  }).trim()}
                </TableCell>

                <TableCell align='center' className={classes.tableDateTime}>
                  {isoStringToDateAndTimeString(row.careDate)}
                </TableCell>
                <TableCell align='center'>
                  {t('IGetFullName', {
                    firstName: row.operatorFirstName ?? '',
                    lastName: row.operatorLastName ?? '',
                  }).trim()}
                </TableCell>
              </TableRow>
            )
          })}

          <TableLoading loading={loading} emptyRows={emptyRows} />
          <TableEmpty
            emptyRows={emptyRows}
            loading={loading}
            error={error}
            isEmpty={allItems.length === 0}
          />
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={paging.page}
        itemCountPerPage={paging.size}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        isItemCountPerPageEnabled={false}
        onItemCountPerPageChanged={(event) =>
          onSearchNew({
            ...searchInfo,
            ...periodInfo,
            pageNumber: 0,
            pageSize: parseInt(event.target.value as string, rowsPerPage),
            type: 'ALL',
          })
        }
      />
    </TableContainer>
  )
}
