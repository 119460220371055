import UsageHistoryTableItemsModel from './UsageHistoryTableItemsModel'

export default class UsageHistoryTableModel {
  constructor(raw: UsageHistoryTableModel) {
    this.items = raw.items.map((item) => new UsageHistoryTableItemsModel(item))

    this.size = raw.size

    this.page = raw.page

    this.total = raw.total
  }

  items: UsageHistoryTableItemsModel[]

  size: number

  page: number

  total: number
}
