import {FormControlLabel} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import {createStyles, makeStyles, styled, Theme} from '@material-ui/core/styles'
import {ActivityTypeCondition, ActivityTypeConditions} from 'api/patientApi'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  margin: 2,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 3,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#3cbbbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 17,
    height: 16,

    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#3cbbbd',
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: '.6rem',
      borderRadius: '5px',
    },
    error: {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      paddingBottom: '.3rem',
    },
    dropDown: {
      position: 'absolute',
      transform: 'translate(0%, 3%)',
      zIndex: 10,
      backgroundColor: 'white',
      width: 583,
      border: 'solid 1px #b5b6ba',

      '& > .contentsWrap': {
        backgroundColor: '#5a6268',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& > .closeButton': {
          color: 'white',
          width: 20,
          height: 20,
          marginRight: 5,
        },
        '& > .title': {
          color: 'white',
          padding: '5px 10px',
          fontSize: 13,
          fontWeight: 500,
          margin: 0,
        },
      },
    },
    checkBoxContainer: {
      padding: '5px 20px',
      '& > label > span > span > svg ': {
        width: 20,
        height: 20,
        borderRadius: 3,
        margin: 0,
        color: ' #dbdbdb',
        backgroundColor: '#fff',
      },
      '& > label > input ': {
        width: 34,
        height: 34,
      },
    },
  }),
)

interface PatientActivitySearchTypeProps {
  onChanged: (search: string) => void
  onClosed: () => void
  searched: string
}

type AllCheckboxChanged = (
  e: ChangeEvent<HTMLInputElement>,
  checked: boolean,
) => void

const PatientActivitySearchType = ({
  onChanged,
  onClosed,
  searched,
}: PatientActivitySearchTypeProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const [error, setError] = useState<string>()
  const [activityTypes, setActivityTypes] = useState<ActivityTypeCondition[]>(
    [],
  )

  useEffect(() => {
    // searched prop이 비어 있으므로 전부 false 상태로 설정
    if (!searched)
      setActivityTypes(
        ActivityTypeConditions.map((item) => ({...item, checked: false})),
      )

    // searched prop이 all 이므로 전부 true 상태로 설정
    if (searched === 'ALL')
      setActivityTypes(
        ActivityTypeConditions.map((item) => ({...item, checked: true})),
      )

    // searched prop이 일부를 만족하므로 부분 업데이트를 실시
    const split = searched.split(',')
    const reduced = ActivityTypeConditions.reduce<ActivityTypeCondition[]>(
      (accr, curr) => {
        const isSelected = split.find((item) => item === curr.value)
        if (isSelected === undefined) {
          accr.push({...curr, checked: false})
        } else {
          accr.push({...curr, checked: true})
        }
        return accr
      },
      [],
    )
    setActivityTypes(reduced)
  }, [searched])

  const handleAllChanged: AllCheckboxChanged = (e, checked) => {
    setActivityTypes(activityTypes.map((item) => ({...item, checked})))

    if (!checked) {
      setError(t('ISelectRequired'))
      return
    }

    if (checked) {
      setError(undefined)
      onChanged('ALL')
    }
  }

  const handleChanged = (checked: boolean, item: ActivityTypeCondition) => {
    const index = activityTypes.findIndex(
      (activityType) => activityType.value === item.value,
    )
    if (index === -1) return

    const curr = {...activityTypes[index], checked}
    const prev = activityTypes.slice(0, index)
    const next = activityTypes.slice(index + 1)

    const newActivityTypes = [...prev, curr, ...next]
    setActivityTypes(newActivityTypes)

    const isNeverChecked = newActivityTypes.every((item) => !item.checked)
    if (isNeverChecked) {
      setError(t('ISelectRequired'))
      return
    }

    // 이제 선택된 항목이 존재하므로 기존에 설정된 오류가 있으면 해제한다
    if (error !== undefined) {
      setError(undefined)
    }

    const isEveryChecked = newActivityTypes.every((item) => item.checked)
    if (isEveryChecked) {
      onChanged('ALL')
      return
    }

    onChanged(
      newActivityTypes
        .filter((item) => item.checked)
        .map((item) => item.value)
        .join(','),
    )
  }

  return (
    <div style={{position: 'relative'}}>
      <div className={classes.dropDown}>
        <div className='contentsWrap'>
          <p className='title'>Type</p>

          <CloseIcon className='closeButton' onClick={() => onClosed()} />
        </div>
        <div className={classes.checkBoxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                name='ALL'
                checked={activityTypes.every((item) => item.checked)}
                onChange={handleAllChanged}
                checkedIcon={<BpCheckedIcon />}
                inputProps={{'aria-label': 'Checkbox demo'}}
              />
            }
            label='All'
          />

          {activityTypes.map((item, index) => (
            <FormControlLabel
              key={item.value}
              control={
                <Checkbox
                  name={item.value}
                  checked={activityTypes[index].checked}
                  onChange={(e, checked) => handleChanged(checked, item)}
                  checkedIcon={<BpCheckedIcon />}
                  inputProps={{'aria-label': 'Checkbox demo'}}
                />
              }
              label={item.label}
            />
          ))}
          {error !== undefined && <p>{error}</p>}
        </div>
      </div>
    </div>
  )
}

export default PatientActivitySearchType
