import React from 'react'
import useGaugeStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/style'

interface Props {
  contentTitlePosition: {
    id: number
    text: string
  }[]
  index: number
}
function TitlePosition({contentTitlePosition, index}: Props) {
  const classes = useGaugeStyles()
  const checkPosition = (text: string) => {
    const isIndexThree = index === 3
    const positions = isIndexThree
      ? ['17%', '50%', '85%']
      : ['30px', '80px', '70px']

    const positionIndex = contentTitlePosition.findIndex(
      (item: any) => item.text === text,
    )

    if (positionIndex !== -1) {
      return isIndexThree
        ? {left: positions[positionIndex]}
        : {marginTop: positions[positionIndex]}
    }

    return isIndexThree ? {left: '85%'} : {marginTop: '70px'}
  }
  return (
    <div>
      {contentTitlePosition.map((item: any) => (
        <div
          className={index === 3 ? classes.marker2 : classes.marker}
          style={checkPosition(item.text)}
        >
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  )
}
export default TitlePosition
