import React from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

function TablePrintT6({agingRoiScore}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const headTable = [
    t('ITemporalLobe'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable4 = [
    {
      data: [
        {
          header: [
            {
              key: 'T6.',
              content: 'Banks of Superior Temporal Sulcus',
              note: t('ISuperiorT6'),
            },
          ],
          image: getHealthReportReportImage('brainT6.png'),
        },
        [t('IAreaT6'), t('ISensitiveT6')],
        [t('IDifficultyT6'), t('ILanguageT6'), t('IPossibleT6')],
        [getNumber(agingRoiScore?.Banks_of_Superior_Temporal_Sulcus_LH)],
        [getNumber(agingRoiScore?.Banks_of_Superior_Temporal_Sulcus_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T7.',
              content: 'Fusiform',
              note: t('INoteT7'),
            },
          ],
          image: getHealthReportReportImage('brainT7.png'),
        },
        [t('IRecognitionT7'), t('ILeftHemisphereT7'), t('IRightHemisphereT7')],
        [t('IDistinguishT7'), t('IUnderstandT7')],
        [getNumber(agingRoiScore?.Fusiform_LH)],
        [getNumber(agingRoiScore?.Fusiform_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T8.',
              content: 'Entorhinal',
              note: t('ICortexT8'),
            },
          ],
          image: getHealthReportReportImage('brainT8.png'),
        },
        [
          t('IEpisodicT8'),
          t('ICognitiveT8'),
          t('ILeftHemisphereT8'),
          t('IRightHemisphereT8'),
        ],
        [t('IEpisodicMemoryT8'), t('IRememberT8'), t('IVisualT8')],
        [getNumber(agingRoiScore?.Entorhinal_LH)],
        [getNumber(agingRoiScore?.Entorhinal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T9.',
              content: 'Parahippocampal',
              note: t('IHippocampusT9'),
            },
          ],
          image: getHealthReportReportImage('brainT9.png'),
        },
        [t('IEpisodicT9'), t('ILeftHemisphereT9'), t('IRightHemisphereT9')],
        [t('IRecallingT9'), t('IRememberT9'), t('ISpatialT9')],
        [getNumber(agingRoiScore?.Parahippocampal_LH)],
        [getNumber(agingRoiScore?.Parahippocampal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T10.',
              content: 'Insula',
              note: t('IInsulaT10'),
            },
          ],
          image: getHealthReportReportImage('brainT10.png'),
        },
        [t('IInvolvedT10'), t('IRegionT10'), t('IRecognizingT10')],
        [
          t('IDifficultyT10'),
          t('IPossibleT10'),
          t('ISlightestT10'),
          t('IFeelingPainT10'),
        ],
        [getNumber(agingRoiScore?.Insula_LH)],
        [getNumber(agingRoiScore?.Insula_RH)],
      ],
      background: 'White',
    },
  ]

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable4} />
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintT6
