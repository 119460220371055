import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import {deleteSalesEmergencyNoticeAction} from 'features/sales/salesNoticeSlice'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import React, {useEffect} from 'react'

import {EmergencyNotifyItems} from 'api/salesNoticeApi'

interface TableColor {
  hasIndexNumber?: number
  onClickedNumber?: number
  onClickedCancle?: boolean
}

const PackageTable = styled.table`
  width: ${Spacing.table.tablewidth}%;
  border-collapse: collapse;
  border-radius: ${Spacing.table.tableBorderRadiusTop}%
    ${Spacing.table.tableBorderRadiusRight}%;
  & th {
    color: ${Colors.text.content};
    height: ${Spacing.table.tableHeaderHeight}px;
    border-bottom: ${Spacing.table.tableBorderBottom}px solid ${Colors.border};
    background-color: ${Colors.tableHeaderBackgroundColor};
    font-size: ${Spacing.table.tableHeaderFontSize}px;
    font-weight: ${Spacing.table.tableHeaderFontWeight};
  }
  & td {
    border-bottom: ${Spacing.table.tableBorderBottom}px solid ${Colors.border};
    height: ${Spacing.table.tableBodyHeight}px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
  }
`

const PackageTableTr = styled.tr<TableColor>`
  background-color: ${(props) =>
    props.onClickedCancle && props.onClickedNumber === props.hasIndexNumber
      ? '#f4f4f7'
      : '#fff'};
`

const PackageTableTd = styled.td`
  width: 50px;

  &:first-child {
    width: 10px;
  }
`
const DeleteButton = styled.button`
  width: ${Spacing.notice.noticeDeleteButtonWidth}px;
  height: ${Spacing.notice.noticeDeleteButtonHeight}px;
  color: ${Colors.notice.noticeDeleteButtonColor};
  font-size: ${Spacing.notice.noticeDeleteButtonFontSize}px;
  font-weight: bold;
  border: none;
  border-radius: ${Spacing.notice.noticeDeleteButtonBorderRadius}px;
  border: solid 1px ${Colors.pagination.border};
  background-color: ${Colors.deleteButtonBackgroundColor};
`
// clickIndex : edit dialog에 선택한 인덱스 값 전달
// isProceed : 서버 데이터상 앱 공지 데이터는 isNotProceed :false, 앱 점검 데이터는 isNotProceed: true로 저장됨
// isSelected : 클릭한 인덱스가 없을때 false
interface TableNoticeProps {
  items: EmergencyNotifyItems[]
  clickIndex: (index: number) => void
  isProceed: boolean
  isSelected: (value: boolean) => void
}

export default function TableNotice(props: TableNoticeProps) {
  const {t, i18n} = useTranslation()
  const {clickIndex, isProceed, isSelected, items} = props
  const dispatch = useAppDispatch()
  // const notice = useAppSelector(selectEmergencyNoticeData)
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const [clickColumn, setClickColumn] = React.useState<number>()
  const [reverseItems, setReverseItems] =
    React.useState<EmergencyNotifyItems[]>()
  const titleLength = 10
  // const [titleLength, setTitleLength] = React.useState<number>(10)
  const [clickColor, setClickColor] = React.useState<boolean>(false)
  const lengthDot = '...'
  const noticeTableHead = [
    'No',
    'IUrgentNoticeStartTime',
    'IUrgentNoticeAutoEndTime',
    'ITitle',
    'IContents',
    'ICreateUser',
    'IDelete',
  ]
  const inspectionTableHead = [
    'No',
    'IUrgentInspectionStartTime',
    'IUrgentNoticeAutoEndTime',
    'ITitle',
    'IContents',
    'ICreateUser',
    'IDelete',
  ]

  const PackageTableTh = styled.th``

  const handleDelete = (index: number) => {
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('IDeleteConfirmMessage'),
    }).then((result) => {
      if (result.payload)
        dispatch(deleteSalesEmergencyNoticeAction({notifies: [index]}))
    })
  }

  useEffect(() => {
    if (clickIndex !== undefined) {
      isSelected(clickColor)
    }
  }, [clickColor])

  useEffect(() => {
    setReverseItems([...items].reverse())
  }, [items])

  return (
    <>
      <NoticeConfirmDialog />
      <PackageTable>
        <PackageTableTr>
          {!isProceed &&
            noticeTableHead.map((value) => {
              return <PackageTableTh>{t(value)}</PackageTableTh>
            })}
          {isProceed &&
            inspectionTableHead.map((value) => {
              return <PackageTableTh>{t(value)}</PackageTableTh>
            })}
        </PackageTableTr>
        {(i18n.language === 'ko' || i18n.language === 'ja') &&
          reverseItems &&
          reverseItems.map((value, index) => {
            return (
              <PackageTableTr
                // styled-component에 전달하는 부분
                onClickedNumber={clickColumn}
                hasIndexNumber={index}
                // 두번 클릭시 선택 취소
                onClickedCancle={clickColor}
                key={index}
                onClick={() => {
                  // 데이터 내의 id값 전달
                  clickIndex(value.id)
                  // 테이블 내에서의 td의 인덱스 번호 가져옴
                  setClickColumn(index)
                  setClickColor(!clickColor)
                }}
              >
                <PackageTableTd>{value.id}</PackageTableTd>
                <PackageTableTd>
                  {value.begin !== null
                    ? isoStringToDateAndTimeString(String(value.begin))
                    : ''}
                </PackageTableTd>
                <PackageTableTd>
                  {value.end !== null
                    ? isoStringToDateAndTimeString(String(value.end))
                    : ''}
                </PackageTableTd>
                <PackageTableTd>
                  {titleLength < value.contents[1].title.length
                    ? `${value.contents[1].title.slice(
                        0,
                        titleLength,
                      )}${lengthDot}`
                    : value.contents[1].title}
                </PackageTableTd>
                <PackageTableTd>
                  {titleLength < value.contents[1].content.length
                    ? `${value.contents[1].content.slice(
                        0,
                        titleLength,
                      )}${lengthDot}`
                    : value.contents[1].content}
                </PackageTableTd>
                <PackageTableTd>{value.authorName}</PackageTableTd>
                <PackageTableTd>
                  <DeleteButton onClick={() => handleDelete(value.id)}>
                    {t('IDelete')}
                  </DeleteButton>
                </PackageTableTd>
              </PackageTableTr>
            )
          })}

        {i18n.language !== 'ko' &&
          i18n.language !== 'ja' &&
          reverseItems &&
          reverseItems.map((value, index) => {
            return (
              <PackageTableTr
                onClickedNumber={clickColumn}
                hasIndexNumber={index}
                onClickedCancle={clickColor}
                key={index}
                onClick={() => {
                  clickIndex(value.id)
                  setClickColumn(index)
                  setClickColor(!clickColor)
                }}
              >
                <PackageTableTd>{value.id}</PackageTableTd>
                <PackageTableTd>
                  {value.begin !== null
                    ? isoStringToDateAndTimeString(String(value.begin))
                    : ''}
                </PackageTableTd>
                <PackageTableTd>
                  {value.end !== null
                    ? isoStringToDateAndTimeString(String(value.end))
                    : ''}
                </PackageTableTd>
                <PackageTableTd>
                  {titleLength < value.contents[0].title.length
                    ? `${value.contents[0].title.slice(
                        0,
                        titleLength,
                      )}${lengthDot}`
                    : value.contents[0].title}
                </PackageTableTd>
                <PackageTableTd>
                  {titleLength < value.contents[0].content.length
                    ? `${value.contents[0].content.slice(
                        0,
                        titleLength,
                      )}${lengthDot}`
                    : value.contents[0].content}
                </PackageTableTd>
                <PackageTableTd>{value.authorName}</PackageTableTd>
                <PackageTableTd>
                  <DeleteButton
                    style={{
                      paddingLeft: `${Spacing.marginCustom}px`,
                      paddingRight: `${Spacing.marginCustom}px`,
                    }}
                    onClick={() => handleDelete(value.id)}
                  >
                    {t('IDelete')}
                  </DeleteButton>
                </PackageTableTd>
              </PackageTableTr>
            )
          })}
      </PackageTable>
    </>
  )
}
