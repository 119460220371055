import request from 'api/index'

export const fetchCountryList = () =>
  request<ModelsResponse<Country>>({
    method: 'get',
    path: '/api/common/v2/country',
  })

export interface FetchTermsRequest {
  termsLang: string
  type: 'PRIVACY' | 'SERVICE'
}

export const fetchTerms = (params: FetchTermsRequest) =>
  request<ModelResponse<string>>({
    method: 'get',
    path: '/api/common/v1/terms',
    params,
  })
