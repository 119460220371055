import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getFullNamePerson} from 'helpers/userHelper'

export interface ClientNameProps {
  firstName?: string | null
  lastName?: string | null
  gender?: Gender | null
  birth?: string | null
}

export const ClassName = 'ClientName_Root'

/**
 * 테이블에 고객 이름을 표시하는 컴퍼넌트.
 * 스타일을 적용하고 싶을 경우 ClassName을 이용해서 스타일링 한다.
 */
export function ClientName({
  firstName,
  lastName,
  birth,
  gender,
}: ClientNameProps) {
  const {i18n} = useTranslation()

  const lang = i18n.language
  const fullName = useMemo(
    () => getFullNamePerson(lastName ?? '', firstName ?? '', lang).trim(),
    [lang, firstName, lastName],
  )
  // const age = useMemo(() => getAgeToday(birth), [t, birth])
  const ellipsisName = (name: string): string => {
    let nameText = ''
    if (name.length > 20) {
      nameText = `${name.substring(0, 19)}...`
      return nameText
    }
    return name
  }
  const name = `${ellipsisName(fullName)}`

  return <span className={ClassName}>{name}</span>
}
