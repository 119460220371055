import React, {useState, useEffect} from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {FormControlLabel} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

enum SearchKeys {
  download = 'download',
  notDownload = 'notDownload',
}
interface SelectAnalysisSearchProps {
  onSearch: (params: any) => void
}
const SEARCH_SELECT_ITEMS: SelectItem<any>[] = [
  {label: 'INotDownload', value: SearchKeys.notDownload},
  {label: 'IDownload', value: SearchKeys.download},
]

const DownloadSearch = ({onSearch}: SelectAnalysisSearchProps) => {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const [changeKey, setChangeKey] = useState<string>('')
  const initQuery = getQueryFromUrl(location.search)

  const handleSearchChanged = (value: string) => {
    let checkedItem = ''
    if (changeKey === value) {
      checkedItem = ''
    } else {
      checkedItem = value
    }
    setChangeKey(checkedItem)
    const paramSearch = {
      ...initQuery,
      downloaded: '',
    }
    if (checkedItem === SearchKeys.notDownload) {
      paramSearch.downloaded = false
    }
    if (checkedItem === SearchKeys.download) {
      paramSearch.downloaded = true
    }
    onSearch(paramSearch)
  }

  /** Set default value for search condition if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (searchParams) {
      if (searchParams.downloaded === false) {
        setChangeKey(SearchKeys.notDownload)
      } else if (searchParams.downloaded === true) {
        setChangeKey(SearchKeys.download)
      } else {
        setChangeKey('')
      }
    }
  }, [])
  return (
    <div className={classes.container}>
      {SEARCH_SELECT_ITEMS.map((item) => (
        <FormControlLabel
          control={<Checkbox checked={changeKey === item.value} />}
          value={item.value}
          key={item.value}
          label={t(item.label)}
          onChange={() => handleSearchChanged(item.value)}
        />
      ))}
    </div>
  )
}

export default DownloadSearch
