import React, {MouseEventHandler, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  executeNormativeAdditionalAnalysisApi,
  SearchedIndividualNorm,
} from 'api/analysisApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import TableReportSearch from 'components/Table/TableReportSearch'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import RouteConstant from 'constants/RouteConstant'
import useEegNormSearch from 'features/analysis/useEegNormSearch'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import {getEyeStatus, statusToI18nString} from 'helpers/analysisHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import openInNewTab from 'helpers/openInNewTab'
import {getQueryFromUrl} from 'helpers/commonHelper'

interface TableToolBarProps {
  selectedItems: SearchedIndividualNorm[]
}

const SEARCH_SELECT_ITEMS: SelectItem<KeyOfAnalysisSearchKind>[] = [
  {label: 'IEEGNormDbIndex', value: 'INDEX'},
  {label: 'IPatientName', value: 'NAME'},
  {label: 'IFileName', value: 'FILE_NAME'},
]

function TableToolbar({selectedItems}: TableToolBarProps) {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const {query, search, onSearch} = useEegNormSearch()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const additionalAnalysisRequest = async () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    try {
      const response = await executeNormativeAdditionalAnalysisApi({
        normRequestIds: selectedItems.map((item) => item.id),
        codeType: 'N',
        codeTypeDivision: 'EEG',
        codeVersion: '3.0',
      })
      if (response.success) {
        onSuccessModalOpen(t('IProcessSuccess'))
      } else {
        onFailureModalOpen(response.message)
      }
    } catch (err) {
      onFailureModalOpen(err.message)
    }
  }

  const handleAdditionalAnalysisClick = async () => {
    /// Question: 1. 추가분석이 진행중인 녀석은 어떻게 해야하는지
    /// Question: 1-1. 리스트에서 필터링 하고 서버로 요청
    /// Question: 1-2. 경고창을 띄워 선택을 해제하도록 유도
    /// Question: 2. 추가분석 결과가 오류로 완료된 녀석은 다시 분석요청이 가능해야 하는지?
    /// Question: 3. 추가분석이 완료된 녀석이 포함되어 있으면 어떻게 해야하는지?
    /// Question: 3-1. 리스트에서 필터링 하고 서버로 요청
    /// Question: 3-2. 경고창을 띄워 선택을 해제하도록 유도
    const listOfId = selectedItems.map((item) => item.id)
    if (listOfId.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) additionalAnalysisRequest()
    })
  }

  /** Set default value for search query if it is stored in url */
  const selection = getDefaultPeriodDate()

  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      period: dateToPeriodString(selection),
      paging: {page: 0, size: 10},
      ...initQuery,
    })
  }, [])

  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.EEGNorm}
        isRoot={false}
        query={query}
        search={search}
        onSearch={onSearch}
      />
      <div className={classes.containerOptional}>
        <ActionButton onClick={handleAdditionalAnalysisClick}>
          {t('IView3D')}
        </ActionButton>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedIndividualNorm
  onClick: (item: SearchedIndividualNorm) => void
  isSelected: (item: SearchedIndividualNorm) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()
  const handleResultClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    const clientName = {
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
    }
    openInNewTab(
      `${RouteConstant.ANALYSIS_EEG_19_N.path}/report/${
        item.id
      }?clientName=${encodeURIComponent(JSON.stringify(clientName))}&version=${
        item.normdbVersion
      }`,
    )
  }

  const labelId = `enhanced-table-checkbox-${item.id}`

  const checked = isSelected(item)

  const handleClick = () => onClick(item)

  return (
    <TableRow hover onClick={handleClick}>
      <TableCell padding='checkbox'>
        <Checkbox checked={checked} inputProps={{'aria-labelledby': labelId}} />
      </TableCell>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>{item.individualId}</TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center'>{getEyeStatus(item.eceo)}</TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(String(item.measureDate))}
      </TableCell>
      <TableCell align='center'>{item.normdbVersion || '-'}</TableCell>
      <TableCell align='center'>{item.zscore}</TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.progress))}
      </TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.additionalAnalysis))}
      </TableCell>
      <TableCell align='center'>
        <TableButton
          onClick={handleResultClick}
          disabled={item.progress !== 'SUCCESS'}
        >
          {t('IRead')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

interface PageTableProps {
  selectedOld: SearchedIndividualNorm[]
  setSelectedOld: (
    data: SearchedIndividualNorm[],
    items: SearchedIndividualNorm[],
  ) => void
}

function PageTable(props: PageTableProps) {
  const {selectedOld, setSelectedOld} = props

  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} =
    useEegNormSearch()
  const [selected, setSelected] = useState<SearchedIndividualNorm[]>([])
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const emptyRows = items === null ? 10 : 10 - items.length
  const selectedId = useMemo(() => selected.map((item) => item.id), [selected])

  const setPageIndex = (page: number) => {
    setSelectedOld(selected, items)
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })
  }

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter((item) => item.progress === 'SUCCESS'))
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedIndividualNorm) => {
    if (item.progress !== 'SUCCESS') {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }

    const selectedIndex = selectedId.indexOf(item.id)
    let newSelected: SearchedIndividualNorm[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedIndividualNorm) => {
    return selectedId.indexOf(item.id) !== -1
  }

  const successItems = items.filter((item) => item.progress === 'SUCCESS')

  useEffect(() => {
    const itemIds = items.map((item) => item.id)
    const selectedOldNew = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedOld.length; i++) {
      if (itemIds.indexOf(selectedOld[i].id) !== -1) {
        selectedOldNew.push(selectedOld[i])
      }
    }
    setSelected(selectedOldNew)
  }, [selectedOld, items])

  return (
    <TableContainer>
      <TableToolbar selectedItems={selected} />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < successItems.length
                }
                checked={
                  successItems.length > 0 &&
                  selected.length === successItems.length
                }
                onChange={handleSelectAllClick}
                inputProps={{'aria-label': 'select all desserts'}}
              />
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IEEGNormDbIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IIndividualIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IECEO')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IVersion')}
            </TableCell>
            <TableCell align='center' padding='none'>
              z-score
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IView3D')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisReport')}
            </TableCell>
            {/* TODO: 놈분석 3D 요청하는 버튼 추가 */}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow
              key={item.id}
              item={item}
              onClick={handleRowClick}
              isSelected={isRowSelected}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChange}
      />
    </TableContainer>
  )
}

export default PageTable
