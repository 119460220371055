import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import React, {ReactNode, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getLanguage} from 'api/storageApi'
import EEGChildSummaryFrontLobeDevelopment from './EEGSummaryFrontLobeDevelopment'
import EEGChildSummaryInformationProcessingSpeed from './EEGSummaryInformationProcessSpeed'
import EEGSummaryAlphaBandDevelopment from './EEGSummaryAlphaBandDevelopment'
import EEGSummaryBrainBalanceDevelopment from './EEGSummaryBrainBalanceDevelopment'
import EEGSummaryBrainAgeDevelopment from './EEGSummaryBrainAgeDevelopment'
import {Customer, Lobe} from '../../../../api/analysisApi'
import EEGSummaryBrain3D from '../EEG/EEGSummaryBrain3d'
import EEGSummaryOpinion from '../EEG/EEGSummaryOpnion'
import EEGSummaryLobe from '../../ProfitSummaryReport/EEG/EEGSummaryLobe'

interface EEGChildSummaryProps {
  CustomerCard?: ReactNode
  frontalLobeDevelopmentGraph_image?: string
  frontalLobeDevelopmentColorbarImage?: string
  informationProcessingSpeedGraphImage?: string
  frontalLobeDevelopment: {
    sensor_tbr_cz: number
    percentile?: number
    // normal_range_min: number
    // normal_range_max: number
  }
  informationProcessingSpeed: {
    occipital_alpha_peak_freq: number
    percentile: number
    // normal_range_min: number
    // normal_range_max: number
  }
  alphaBandDevelopment: {
    ec_alpha_proportion: number
    eo_alpha_proportion: number
    ec_alpha_proportion_normal: number
    eo_alpha_proportion_normal: number

    ec_alpha_proportion_percentile: number
    eo_alpha_proportion_percentile: number
  }
  alphaBandDevelopmentBarGraphImage: string
  alphaBandDevelopmentLineGraphImage: string
  brainBalance: {
    LH: number
    RH: number
  }
  brainBalance3dImage: string
  brainBalanceImage: string
  brainAge: {
    avg: number
    by_lobe: Lobe
    subject_age: number
  }
  lobe: Lobe
  brainAgeGraphImage: string
  contourImage?: string
  threeDimensionImage: string
  total: string
  client: Customer
}

export default function EEGSummary(props: EEGChildSummaryProps) {
  const {
    frontalLobeDevelopment,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    frontalLobeDevelopmentGraph_image,
    frontalLobeDevelopmentColorbarImage,
    informationProcessingSpeedGraphImage,
    informationProcessingSpeed,
    alphaBandDevelopment,
    alphaBandDevelopmentBarGraphImage,
    alphaBandDevelopmentLineGraphImage,
    brainBalance,
    brainBalance3dImage,
    brainBalanceImage,
    brainAgeGraphImage,
    brainAge,
    threeDimensionImage,
    contourImage,
    lobe,
    total,
    CustomerCard,
    client,
  } = props
  const classes = useStyles()
  const {t} = useTranslation()

  const score = Number.parseInt(total, 10) ?? 0
  const {treatmentAge} = client
  const [isShowSummaryChild, setIsShowSummaryChild] = useState<boolean>(false)
  const [isEmptyReport, setIsEmptyReport] = useState<boolean>(false)
  const lang = getLanguage()

  useEffect(() => {
    if (lang && lang === 'ko') setIsShowSummaryChild(true)
    else setIsShowSummaryChild(true)
  }, [lang])

  useEffect(() => {
    const isEmpty =
      !isShowSummaryChild && !lobe && !threeDimensionImage && !contourImage
    setIsEmptyReport(isEmpty)
  }, [isShowSummaryChild])

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      {isShowSummaryChild && (
        <>
          {frontalLobeDevelopment && (
            <EEGChildSummaryFrontLobeDevelopment
              frontalLobeDevelopmentGraphImage={
                frontalLobeDevelopmentGraph_image
              }
              frontalLobeDevelopmentColorbarImage={
                frontalLobeDevelopmentColorbarImage
              }
              score={frontalLobeDevelopment.sensor_tbr_cz ?? 0}
              percentile={frontalLobeDevelopment.percentile ?? 0}
              // minRange={frontalLobeDevelopment.normal_range_min ?? 0}
              // maxRange={frontalLobeDevelopment.normal_range_max ?? 0}
            />
          )}
          {informationProcessingSpeed && (
            <EEGChildSummaryInformationProcessingSpeed
              informationProcessingSpeedGraphImage={
                informationProcessingSpeedGraphImage
              }
              score={informationProcessingSpeed.occipital_alpha_peak_freq ?? 0}
              percentile={informationProcessingSpeed.percentile ?? 0}
              // minRange={informationProcessingSpeed.normal_range_min ?? 0}
              // maxRange={informationProcessingSpeed.normal_range_max ?? 0}
            />
          )}
          {alphaBandDevelopment && (
            <EEGSummaryAlphaBandDevelopment
              alphaBandDevelopmentBarGraphImage={
                alphaBandDevelopmentBarGraphImage
              }
              alphaBandDevelopmentLineGraphImage={
                alphaBandDevelopmentLineGraphImage
              }
              ec_alpha_proportion={
                alphaBandDevelopment.ec_alpha_proportion ?? 0
              }
              ec_alpha_proportion_normal={
                alphaBandDevelopment.ec_alpha_proportion_normal ?? 0
              }
              eo_alpha_proportion_percentile={
                alphaBandDevelopment.eo_alpha_proportion_percentile
              }
              ec_alpha_proportion_percentile={
                alphaBandDevelopment.ec_alpha_proportion_percentile
              }
              eo_alpha_proportion={
                alphaBandDevelopment.eo_alpha_proportion ?? 0
              }
              eo_alpha_proportion_normal={
                alphaBandDevelopment.eo_alpha_proportion_normal ?? 0
              }
            />
          )}
          {brainBalance && (
            <EEGSummaryBrainBalanceDevelopment
              brainBalance3dImage={brainBalance3dImage}
              brainBalanceImage={brainBalanceImage}
              LH={brainBalance.LH ?? 0}
              RH={brainBalance.RH ?? 0}
            />
          )}
          {brainAge && (
            <EEGSummaryBrainAgeDevelopment
              brainAgeGraphImage={brainAgeGraphImage}
              avg={brainAge.avg ?? 0}
              age={brainAge.subject_age ?? 0}
              lobe={brainAge.by_lobe}
            />
          )}
        </>
      )}
      {lobe !== null && lobe !== undefined && (
        <EEGSummaryLobe
          index={brainBalance == null || !isShowSummaryChild ? 1 : 6}
          lobe={lobe}
        />
      )}

      {threeDimensionImage && (
        <EEGSummaryBrain3D
          index={brainBalance == null || !isShowSummaryChild ? 2 : 7}
          threeDimensionImage={threeDimensionImage}
        />
      )}
      {contourImage && (
        <EEGSummaryOpinion
          index={brainBalance == null || !isShowSummaryChild ? 3 : 8}
          score={score ?? 0}
          contourImage={contourImage}
        />
      )}
      {isEmptyReport && (
        <p style={{textAlign: 'center', color: 'red'}}>
          {t('IResultNotProvided')}
        </p>
      )}
      <div className='Warning'>{t('IEEGSummaryReportWarning')}</div>
    </div>
  )
}
