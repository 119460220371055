import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import SelectBoldCountry from 'components/Dialog/BoldSelect/SelectBoldCountry'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

const SelectedCountry = () => {
  const {
    formState: {errors},
    register,
  } = useFormContext()
  const inputClasses = useBoldInputStyle()
  const {t} = useTranslation()

  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <p className={inputClasses.inputLabel}>{t('ICountry')}</p>
          <p className={inputClasses.require}>*</p>
        </div>

        <SelectBoldCountry style={{width: '100%'}} {...register('countryId')} />
        {errors.countryId && (
          <span className={inputClasses.errorText}>
            {errors.countryId.message}
          </span>
        )}
      </div>
    </div>
  )
}

export default SelectedCountry
