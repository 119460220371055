import {OrgDetailInfoEditRequest} from 'api/organizationApi'
import {
  fetchOrgReadAction,
  fetchOrgUpdateAction,
  selectData,
  selectError,
  selectLoading,
  selectOrgHistory,
  readHistoryOrg,
  selectAccountUsageHistory,
  readHistoryAccountUsage,
  readAccountById,
} from 'features/org/orgSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useOrganization() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const organization = useAppSelector(selectData)
  const error = useAppSelector(selectError)
  const orgHistory = useAppSelector(selectOrgHistory)
  const accountUsageHistory = useAppSelector(selectAccountUsageHistory)

  const onFetch = () => dispatch(fetchOrgReadAction())
  const onFetchOrgHistory = (payload: UserUsageHistoryRequest) =>
    dispatch(readHistoryOrg(payload)).unwrap()

  const onFetchUsageHistoryByAccount = (payload: UserUsageHistoryRequest) =>
    dispatch(readHistoryAccountUsage(payload)).unwrap()

  const onFetchAccountById = (payload: AccountByIdRequest) =>
    dispatch(readAccountById(payload)).unwrap()

  const onUpdate = (payload: OrgDetailInfoEditRequest) =>
    dispatch(fetchOrgUpdateAction(payload))

  return {
    loading,
    organization,
    error,
    orgHistory,
    accountUsageHistory,
    onFetch,
    onUpdate,
    onFetchOrgHistory,
    onFetchUsageHistoryByAccount,
    onFetchAccountById,
  }
}
