import {PaymentHistoryResponse} from 'api/paymentApi'
import PaymentHistoryTableItemsModel from './PaymentHistoryTableItemsModel'

export default class PaymentHistoryTableModel
  implements PaymentHistoryResponse
{
  constructor(raw: PaymentHistoryTableModel) {
    this.items = raw.items.map((item) => {
      return new PaymentHistoryTableItemsModel(item)
    })
    this.total = raw.total
    this.page = raw.page
    this.size = raw.size
  }

  items: PaymentHistoryTableItemsModel[]

  total: number

  page: number

  size: number
}
