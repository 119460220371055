import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import SearchBar from 'components/Forms/SearchBar'

import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {getQueryFromUrl} from 'helpers/commonHelper'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

const SEARCH_SELECT_ITEMS: SelectItem<KeyOfAnalysisSearchKind>[] = [
  {label: 'IIndividualIndex', value: 'INDEX'},
  {label: 'IPatientName', value: 'NAME'},
  {label: 'IFileName', value: 'FILE_NAME'},
]

interface SelectAnalysisSearchProps {
  search: AnalysisSearchKind
  onSearch: (params: AnalysisSearchKind) => void
}

export default function HrvAnalysisSearch({
  search,
  onSearch,
}: SelectAnalysisSearchProps) {
  const location = useLocation()
  const classes = useToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()

  const {t} = useTranslation()

  const [searchCondition, setSearchCondition] =
    useState<KeyOfAnalysisSearchKind>('NAME')
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as KeyOfAnalysisSearchKind)

  const getSearch = (): AnalysisSearchKind => {
    if (searchCondition === 'INDEX') {
      return {
        searchName: searchCondition,
        searchValue: Number.parseInt(searchKeyword, 10),
      }
    }
    if (searchCondition === 'ECEO') {
      return {
        searchName: searchCondition,
        searchValue: searchKeyword as ECEOSearch['searchValue'],
      }
    }
    return {
      searchName: searchCondition,
      searchValue: searchKeyword,
    }
  }

  const handleSearch = () => {
    const newSearch = getSearch()
    onSearch(newSearch)
  }

  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  /** Set default value for search condition if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (searchParams?.search && searchParams.search.searchName) {
      setSearchCondition(searchParams.search.searchName)
    }
  }, [])

  return (
    <div className={classes.searchContainer}>
      <FormControl variant='outlined'>
        <Select
          value={searchCondition}
          onChange={handleSearchChanged}
          className={classes.searchSelect}
          input={
            <OutlinedInput
              name='age'
              id='outlined-age-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          {SEARCH_SELECT_ITEMS.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar
        onChangeValue={handleValue}
        onSearchEvent={handleSearch}
        disabledValue={false}
      />
    </div>
  )
}
