import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  choiceHealthCareApi,
  downloadStatusHealthCareApi,
  HdAnalysisTypes,
  HealthCareChoiceRequest,
  HealthCareDownloadRequest,
  HealthCareSearchRequest,
  SearchedHealthCare,
  searchHealthCareAnalysisApi,
} from 'api/healthCareApi'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchHealthCare = createAsyncThunk(
  'api/healthCare/search',
  async (payload: HealthCareSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchHealthCareAnalysisApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const choiceHealthCare = createAsyncThunk(
  'api/healthCare/choice',
  async (payload: HealthCareChoiceRequest, {rejectWithValue}) => {
    try {
      const response = await choiceHealthCareApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const downloadHealthCare = createAsyncThunk(
  'api/healthCare/download',
  async (payload: HealthCareDownloadRequest, {rejectWithValue}) => {
    try {
      const response = await downloadStatusHealthCareApi(payload)
      return response.list
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

interface HdAnalysisTypesStore {
  id: number
  list: HdAnalysisTypes[]
}

export interface HealthCareState {
  /**
   * State for GET /api/healthcare/v1/analysis
   */
  loading: boolean
  error: any
  /**
   * State for PUT /api/healthcare/v1/analysis/{id}/choice
   */
  loadingChoice: boolean
  loadingDownload: boolean
  errorChoice: any
  errorDownload: any
  items: SearchedHealthCare[]
  startDate: string
  endDate: string
  hcId: string
  patientName: string
  page: number
  size: number
  lang: string
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
  hdAnalysisTypes: HdAnalysisTypesStore[] | null
  birth: string
  patientVisitNo: string
  patientChartNo: string
  downloaded: boolean | string
}

const initialState: HealthCareState = {
  loading: false,
  loadingChoice: false,
  loadingDownload: false,
  error: null,
  errorChoice: null,
  errorDownload: null,
  items: [],
  startDate: getDefaultPeriodString().startDate, // TODO: 시간 관련 나래와 협의
  endDate: getDefaultPeriodString().endDate, // TODO: 시간 관련 나래와 협의
  hcId: '',
  patientName: '',
  page: 0,
  size: 10,
  lang: 'ko',
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
  hdAnalysisTypes: null,
  birth: '',
  patientVisitNo: '',
  patientChartNo: '',
  downloaded: '',
}

const slice = createSlice({
  name: 'healthCareSearchSlice',
  initialState,
  reducers: {
    setHdAnalysisTypes(state, action: PayloadAction<HdAnalysisTypesStore[]>) {
      state.hdAnalysisTypes = action.payload
    },
  },
  extraReducers: (builder) => {
    // GET /api/healthcare/v1/analysis
    builder.addCase(searchHealthCare.pending, (state, action) => {
      state.loading = true
      state.error = null

      // TODO: Implement Reducers
      const {
        startDate,
        endDate,
        hcId,
        patientName,
        page,
        size,
        birth,
        patientVisitNo,
        patientChartNo,
        downloaded,
      } = action.meta.arg
      state.startDate = startDate || getDefaultPeriodString().startDate
      state.endDate = endDate || getDefaultPeriodString().endDate
      state.patientName = patientName || ''
      state.page = page || 0
      state.size = size || 10
      state.birth = birth || ''
      state.patientVisitNo = patientVisitNo || ''
      state.patientChartNo = patientChartNo || ''
      state.downloaded = typeof downloaded !== 'undefined' ? downloaded : ''
    })
    builder.addCase(searchHealthCare.fulfilled, (state, action) => {
      state.loading = false
      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.page = page
      state.size = size
      state.pagingInfo.totalElements = totalElements
      state.pagingInfo.totalPages = totalPages
    })
    builder.addCase(searchHealthCare.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    // PUT /api/healthcare/v1/analysis/{id}/choice
    builder.addCase(choiceHealthCare.pending, (state) => {
      state.loadingChoice = true
      state.errorChoice = null
    })
    builder.addCase(choiceHealthCare.fulfilled, (state) => {
      state.loadingChoice = false
    })
    builder.addCase(choiceHealthCare.rejected, (state, action) => {
      state.loadingChoice = false
      state.errorChoice = action.payload
    })

    // PUT /api/healthcare/v1/analysis/any/download-status
    builder.addCase(downloadHealthCare.pending, (state) => {
      state.loadingDownload = true
      state.errorDownload = null
    })
    builder.addCase(downloadHealthCare.fulfilled, (state) => {
      state.loadingDownload = false
      state.errorDownload = null
    })
    builder.addCase(downloadHealthCare.rejected, (state, action) => {
      state.loadingDownload = false
      state.errorDownload = action.payload
    })
  },
})

export default slice.reducer

export const {setHdAnalysisTypes} = slice.actions

export const selectLoading = (state: RootState) => state.healthCare.loading
export const selectError = (state: RootState) => state.healthCare.error
export const selectLoadingChoice = (state: RootState) =>
  state.healthCare.loadingChoice
export const selectErrorChoice = (state: RootState) =>
  state.healthCare.errorChoice
export const selectLoadingDownload = (state: RootState) =>
  state.healthCare.loadingDownload
export const selectErrorDownload = (state: RootState) =>
  state.healthCare.errorDownload
export const selectItems = (state: RootState) => state.healthCare.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate({
    startDate: state.healthCare.startDate,
    endDate: state.healthCare.endDate,
  })
export const selectSearch = (state: RootState) => ({
  patientName: state.healthCare.patientName,
})
export const selectPaging = (state: RootState) => ({
  page: state.healthCare.page,
  size: state.healthCare.size,
})
export const selectPagingInfo = (state: RootState) =>
  state.healthCare.pagingInfo
export const selectQuery = (state: RootState) => ({
  startDate: state.healthCare.startDate,
  endDate: state.healthCare.endDate,
  patientName: state.healthCare.patientName,
  hcId: state.healthCare.hcId,
  size: state.healthCare.size,
  page: state.healthCare.page,
  lang: state.healthCare.lang,
  birth: state.healthCare.birth,
  patientVisitNo: state.healthCare.patientVisitNo,
  patientChartNo: state.healthCare.patientChartNo,
  downloaded: state.healthCare.downloaded,
})

export const selectHdAnalysisTypes = (state: RootState) =>
  state.healthCare.hdAnalysisTypes
