import {TableCellProps} from '@material-ui/core'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import {Colors} from 'components/common/useBaseStyle'
import TableCell from 'components/Table/TableCell'
import React from 'react'
import styled from 'styled-components'

const StyledMainReportButton = styled.button<{isMainReport: boolean}>`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  & .MuiSvgIcon-root {
    color: ${(props) =>
      props.isMainReport ? Colors.primary : Colors.secondary};
  }
  &:hover {
    & .MuiSvgIcon-root {
      color: ${Colors.primary};
    }
  }
`

interface TableCellReportProps {
  reportId: number
  isMainReport: boolean
  onClick: () => void
}

const TableCellReport = ({
  isMainReport,
  onClick,
  ...props
}: TableCellProps & TableCellReportProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (isMainReport) {
      return
    }

    onClick()
  }

  return (
    <TableCell {...props}>
      <StyledMainReportButton onClick={handleClick} isMainReport={isMainReport}>
        {isMainReport && <Star />}
        {!isMainReport && <StarBorder />}
      </StyledMainReportButton>
    </TableCell>
  )
}

export default TableCellReport
