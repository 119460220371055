import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  changePasswordApi,
  checkEmailDuplicateApi,
  checkVerifyApi,
  findPasswordApi,
  FindPasswordRequest,
} from 'api/authApi'
import {rsaEncryption} from 'api/securityApi'
import Card from 'components/atoms/Card'
import ChangePasswordInput from 'components/Dialog/FindPasswordDialog/form/ChangePasswordInput'
import CheckVerifyCodeInput from 'components/Dialog/FindPasswordDialog/form/CheckVerifyCodeInput'
import FindPasswordInput from 'components/Dialog/FindPasswordDialog/form/FindPasswordInput'
import {
  closeFindPasswordDialog,
  selectFindPasswordDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {styles} from '../findDialogStyle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...styles(theme),
    inputWithButton: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      gap: 10,
      '& button': {
        minWidth: 120,
        width: 120,
        alignSelf: 'flex-start',
      },
    },
    changePasswordButton: {marginTop: 10},
  }),
)

const FindPasswordDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const open = useAppSelector(selectFindPasswordDialogOpen)
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const dispatch = useAppDispatch()
  const {i18n} = useTranslation()

  // const findPasswordData = useForm()

  // const {reset: emailReset} = findPasswordData

  const findPasswordData = useForm()
  const {reset: emailReset} = findPasswordData
  const checkVerifyCode = useForm()
  const {reset: authCodeReset} = checkVerifyCode
  const changePasswordData = useForm()
  const {reset: passwordReset} = changePasswordData

  const [userEmail, setUserEmail] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [responseUuid, setResponseUuid] = useState('')
  const currentLocale = i18n.language === 'ko' ? 'KO' : 'EN'

  const [isNoIdData, setNoIdCheckData] = useState<boolean>(false)
  const [codeNotSame, setCodeNotSame] = useState<boolean>(false)
  const isAuthCodeSend = useMemo<boolean>(() => userEmail !== '', [userEmail])
  const isAuthCodeConfirmed = useMemo<boolean>(
    () => verifyCode !== '',
    [verifyCode],
  )

  const handleClose = (event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    dispatch(closeFindPasswordDialog())

    setUserEmail('')
    setVerifyCode('')

    emailReset()
    authCodeReset()
    setNoIdCheckData(false)
    setCodeNotSame(false)
    passwordReset()
  }

  const handleFindPassword = async ({
    email,
    requestType,
    languageCode,
  }: FindPasswordRequest) => {
    try {
      if (!email || email.length === 0) {
        onFailureModalOpen(t('IEmailRequired'))
        return
      }
      await checkEmailDuplicateApi(email)
      setNoIdCheckData(true)
    } catch (e) {
      const response = await findPasswordApi({
        email,
        requestType,
        languageCode,
      })
      if (response) {
        // Reset Expiration Timer
        if (userEmail) {
          setUserEmail('')
        }
        await onSuccessModalOpen(t('IAuthCodeSendSuccess'))
        setUserEmail(email)
        setResponseUuid(response.uuid)
      }
    }
  }

  const handleCheckVerityCode = async (inputCode: string) => {
    try {
      const response = await checkVerifyApi({
        uuid: responseUuid,
        code: inputCode,
      })
      if (response) {
        setVerifyCode(inputCode)
        await onSuccessModalOpen(t('IAuthCodeConfirmSuccess'))
      }
    } catch (e) {
      setCodeNotSame(true)
      // onFailureModalOpen(t('ICorrectCodeRequired'))
    }
  }

  const handleChangePassword = async (password: string) => {
    if (!userEmail) {
      onFailureModalOpen(t('IAuthedEmailRequired'))
      return
    }
    if (!isAuthCodeConfirmed) {
      onFailureModalOpen(t('IAuthedCodeRequired'))
      return
    }
    try {
      const response = await changePasswordApi({
        email: rsaEncryption(userEmail),
        authenticateUuid: responseUuid,
        newPassword: password,
      })
      if (!response) {
        await onSuccessModalOpen(t('IPasswordChangeSuccess'))
        handleClose()
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }

  useEffect(() => {
    setCodeNotSame(false)
  }, [])
  return (
    <Dialog open={open} className={classes.dialog} onClose={handleClose}>
      <Card className={classes.root}>
        <div className='Header'>
          <h3 className='Title'>{t('IPasswordFind')}</h3>
          <IconButton
            type='submit'
            aria-label='search'
            onClick={handleClose}
            className='CloseButton'
          >
            <CloseIcon className='CloseIcon' />
          </IconButton>
        </div>

        <div className='Contents'>
          <FormProvider {...findPasswordData}>
            <form
              onSubmit={findPasswordData.handleSubmit((data) => {
                handleFindPassword({
                  email: data.email,
                  requestType: 'R',
                  languageCode: currentLocale,
                })
              })}
            >
              <div className={classes.inputWithButton}>
                <FindPasswordInput isAuthCodeSend={isAuthCodeSend} />
              </div>
            </form>
          </FormProvider>

          <FormProvider {...checkVerifyCode}>
            <form
              onSubmit={checkVerifyCode.handleSubmit((data) => {
                handleCheckVerityCode(data.code)
              })}
            >
              <div className={classes.inputWithButton}>
                <CheckVerifyCodeInput
                  isAuthCodeSend={isAuthCodeSend}
                  isAuthCodeConfirmed={isAuthCodeConfirmed}
                  isIdCheckData={isNoIdData}
                  codeNotSame={codeNotSame}
                />
              </div>
            </form>
          </FormProvider>

          <FormProvider {...changePasswordData}>
            <form
              onSubmit={changePasswordData.handleSubmit((data) => {
                handleChangePassword(data.password)
              })}
            >
              <ChangePasswordInput isAuthConfirmed={isAuthCodeConfirmed} />
              <div style={{display: 'flex', gap: 10, marginTop: 20}}>
                <Button
                  size='medium'
                  variant='contained'
                  disableElevation
                  fullWidth
                  className={`${classes.changePasswordButton} CancelButton`}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  {t('ICancel')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  fullWidth
                  type='submit'
                  className={`${classes.changePasswordButton} ConfirmButton`}
                >
                  {t('IConfirm')}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Card>
    </Dialog>
  )
}

export default FindPasswordDialog
