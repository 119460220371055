import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from 'hooks'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/CustomerManagement/Style'
import useStylesTable from 'components/Table/useTableStyles'
import Pagination from 'components/molcules/Pagination'
import {
  closeCustomerManagementDialog,
  selectCustomerManagerOpen,
} from 'features/modal/modalSlice'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import {OrgType, setOrgSelected} from 'features/invoice/invoiceInquirySlice'
import useCustomerOrg from 'features/invoice/useInvoiceInquiry'

const OrgCustomerDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const classesTable = useStylesTable()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectCustomerManagerOpen)
  const {loading, query, paging, pagingInfo, items, search, onSearch} =
    useCustomerOrg()

  const handleClose = () => {
    dispatch(closeCustomerManagementDialog())
  }
  const handleCloseItem = async (item: OrgType) => {
    dispatch(setOrgSelected(item))
    handleClose()
  }

  const setPageIndex = (pageNumber: number) => {
    onSearch({
      search: {orgName: search.orgName},
      paging: {
        pageNumber,
        pageSize: paging.size,
      },
    })
  }

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const pageSize = parseInt(event.target.value as string, 10)

    onSearch({
      search: {orgName: search.orgName},
      paging: {
        pageNumber: 0,
        pageSize,
      },
    })
  }

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={handleClose}>
      <Card className={classes.cardWrap}>
        <div className={classes.root}>
          <div
            className={classes.longTitleContainer}
            style={{backgroundColor: '#f9f9fb'}}
          >
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography className={classes.title}>
                {t('ISelectInstitution')}
              </Typography>
            </div>
          </div>
          <div className={classes.scroll} style={{backgroundColor: '#f9f9fb'}}>
            <div className={classes.cardContent}>
              <Typography className={classesTable.padding} />
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow className={classesTable.tableHeader}>
                      <TableCell
                        align='left'
                        className={classesTable.cellValue}
                      >
                        {t('IInstituteTitleName')}
                      </TableCell>
                      <TableCell
                        align='left'
                        className={classesTable.cellValue}
                      >
                        {t('ICeoName')}
                      </TableCell>
                      <TableCell
                        align='left'
                        className={classesTable.cellValue}
                      >
                        {t('IEmail')}
                      </TableCell>
                      <TableCell
                        align='left'
                        className={classesTable.cellValue}
                      >
                        {t('IConfirm')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item) => (
                      <TableRow>
                        <TableCell
                          align='left'
                          className={classesTable.cellValue}
                        >
                          {item.orgName}
                        </TableCell>
                        <TableCell className={classesTable.cellValue}>
                          {t('IGetFullName', {
                            firstName: item.firstName,
                            lastName: item.lastName,
                          })}
                        </TableCell>
                        <TableCell
                          align='left'
                          className={classesTable.cellValue}
                        >
                          {item.email}
                        </TableCell>
                        <TableCell
                          className={classesTable.cellValue}
                          align='center'
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            disableElevation
                            onClick={() => handleCloseItem(item)}
                          >
                            {t('ISelectCustomer')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  totalPageCount={pagingInfo.totalPages}
                  currentPageIndex={query.paging.page ?? 0}
                  itemCountPerPage={query.paging.size ?? 10}
                  setCurrentPageIndex={setPageIndex}
                  loading={loading}
                  onItemCountPerPageChanged={onSizeChange}
                />
              </TableContainer>
              <Typography className={classes.padding} />
              <Typography className={classes.padding} />
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <div className={classes.buttonContainerClose}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleClose}
              >
                {t('IClose')}
              </Button>
              <Typography className={classes.smallPadding} />
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default OrgCustomerDialog
