import TranslationSelect from 'components/atoms/TranslationSelect'
import useQuery from 'hooks/useQuery'
import ReportFetcher from 'pages/Analysis/EEGIndividual/Report/ReportFetcher'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {number, object, SchemaOf, string} from 'yup'
import styled from 'styled-components'
import {Spacing} from 'components/common/useBaseStyle'

interface PathParams {
  id: number
  version: string
}

const QueryItemScheme: SchemaOf<PathParams> = object({
  id: number()
    .typeError('IInvalidRequest')
    .required('IInvalidRequest')
    .min(1, 'IInvalidRequest'),
  version: string().required('IInvalidRequest'),
})

export const ReportWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReportTranslationSelectWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: ${Spacing.report.reportTranslationMargin}px;
`
export const ReportFetcherWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReportCustomPadding = styled.div`
  padding: ${Spacing.report.reportCustomPadding}px;
`

function ReportPage() {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [pathParams, setPathParams] = useState<PathParams>()

  const {id} = useParams<{
    id?: string | undefined
  }>()
  const version = useQuery().get('version')

  useEffect(() => {
    setLoading(true)
    QueryItemScheme.validate({
      id,
      version,
    })
      .then((value) => setPathParams(value))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false))
  }, [id, version])

  if (loading) return <div>Loading...</div>
  if (!loading && error !== undefined) return <div>{t(error)}</div>
  if (!loading && pathParams !== undefined)
    return (
      <ReportWrap>
        <ReportTranslationSelectWrap>
          <TranslationSelect />
        </ReportTranslationSelectWrap>
        <ReportFetcherWrap>
          <ReportCustomPadding />
          <ReportFetcher id={pathParams.id} version={pathParams.version} />
          <ReportCustomPadding />
        </ReportFetcherWrap>
        <ReportCustomPadding />
      </ReportWrap>
    )
  return null
}

export default ReportPage
