import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import EDFDownload from 'pages/Analysis/EDFDownload'
import EEGGroupCompare from 'pages/Analysis/EEGGroupCompare'
import EEGIndividual from 'pages/Analysis/EEGIndividual'
import EEGMCI from 'pages/Analysis/EEGMCI'
import EEGMCIReport from 'pages/Analysis/EEGMCI/Report'
import EEGNorm from 'pages/Analysis/EEGNorm'
import EEGNormReport from 'pages/Analysis/EEGNorm/Report'
import EEGSummary from 'pages/Analysis/EEGSummary'
import EEGSummaryReport from 'pages/Analysis/EEGSummary/Report'
import HealthCenter from 'pages/HealthCenter'
import HRVIndividual from 'pages/Analysis/HRVIndividual'
import HRVIndividualReport from 'pages/Analysis/HRVIndividual/Report'
import HRVNorm from 'pages/Analysis/HRVNorm'
import HRVSummary from 'pages/Analysis/HRVSummary'
import HRVSummaryReport from 'pages/Analysis/HRVSummary/Report'
import React from 'react'
import {Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

/**
 * 분석 담당 라우트
 */
const AnalysisRoute = () => {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EDF.path}
          exact
          component={EDFDownload}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_I.path}
          exact
          component={EEGIndividual}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_N_R.path}
          exact
          component={EEGNormReport}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_N.path}
          exact
          component={EEGNorm}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_S.path}
          exact
          component={EEGSummary}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_S_R.path}
          exact
          component={EEGSummaryReport}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_M.path}
          exact
          component={EEGMCI}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_M_R.path}
          exact
          component={EEGMCIReport}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_EEG_19_G.path}
          exact
          component={EEGGroupCompare}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HRV_I.path}
          exact
          component={HRVIndividual}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HRV_I_R.path}
          exact
          component={HRVIndividualReport}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HRV_N.path}
          exact
          component={HRVNorm}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HRV_S.path}
          exact
          component={HRVSummary}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HRV_S_R.path}
          exact
          component={HRVSummaryReport}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ANALYSIS_HEALTH_CENTER.path}
          exact
          component={HealthCenter}
        />
      </Switch>
    </CommonLayout>
  )
}

export default AnalysisRoute
