import {createStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

export const TextStyles = createStyles({
  root: {
    fontFamily:
      'Noto Sans KR, -apple-system, BlinkMacSystemFont, Malgun Gothic, 맑은 고딕, helvetica, Apple SD Gothic Neo, sans-serif !important',
  },
  title: {
    fontSize: '1.563rem',
    color: Colors.text.title,
  },
  subTitle: {
    fontSize: '1.125rem',
    color: Colors.text.title,
  },
  th: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: Colors.text.content,
  },
  content: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: Colors.text.content,
  },
})

const useTextStyles = makeStyles(TextStyles)

export default useTextStyles
