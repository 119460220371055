import {isDev, isProd, isStage} from 'helpers/envHelper'
import BusinessIcon from '@material-ui/icons/Business'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ForumIcon from '@material-ui/icons/Forum'
import InfoIcon from '@material-ui/icons/Info'
import CenterIcon from '@material-ui/icons/FilterCenterFocus'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import StoreIcon from '@material-ui/icons/Store'
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined'
import PlayForWorkIcon from '@material-ui/icons/PlayForWork'
import Bookmark from '@material-ui/icons/Bookmark'
import {
  isAdmin,
  isCompanyAuthority,
  isImedisyncCS,
  isCSOrSalesAuthority,
  isOrgAndCompanyUser,
  isOrganizationHealth,
  isOrganizationWhiteList,
  Role,
  Roles,
} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import {isDefined} from 'helpers/commonHelper'
import {Menu} from '../api/salesApi'

const eegTypes = {
  EEG: 'EEG',
  EEG_I: 'EEG_I',
  EEG_S: 'EEG_S',
  EEG_N: 'EEG_N',
  EEG_M: 'EEG_M',
}
const hrvTypes = {
  HRV_I: 'HRV_I',
  HRV_S: 'HRV_S',
}
const therapyTypes = {
  THERAPY: 'THERAPY',
  THERAPY_L: 'THERAPY_L',
}

const isUsed = (value: string): boolean => value === 'Y'
const isEeg = (type: string): boolean => type.includes('EEG')
const isHrv = (type: string): boolean => type.includes('HRV')
const isTherapy = (type: string): boolean => type.includes('THERAPY')

const getRouteConstant = (name: string): any => {
  switch (name) {
    case eegTypes.EEG_I:
      return {...RouteConstant.ANALYSIS_EEG_19_I, icon: undefined}
    case eegTypes.EEG_S:
      return {...RouteConstant.ANALYSIS_EEG_19_S, icon: undefined}
    case eegTypes.EEG_N:
      return {...RouteConstant.ANALYSIS_EEG_19_N, icon: undefined}
    case eegTypes.EEG_M:
      return {...RouteConstant.ANALYSIS_EEG_19_M, icon: undefined}
    case hrvTypes.HRV_I:
      return {...RouteConstant.ANALYSIS_HRV_I, icon: undefined}
    case hrvTypes.HRV_S:
      return {...RouteConstant.ANALYSIS_HRV_S, icon: undefined}
    case therapyTypes.THERAPY_L:
      return {...RouteConstant.THERAPY_MANAGEMENT_L, icon: undefined}
    default:
      return ''
  }
}
const createChildren = (name: string, menu: any[]) => {
  if (getRouteConstant(name) !== '') menu.push(getRouteConstant(name))
}
const createEegChildren = (menuList: Menu[]): any[] => {
  const eegChildren: any[] = []
  menuList.forEach((element) => {
    if (isUsed(element.useYn) && isEeg(element.name)) {
      createChildren(element.name, eegChildren)
    }
  })
  return eegChildren
}
const createHrvChildren = (menuList: Menu[]): any[] => {
  const hrvChildren: any[] = []
  menuList.forEach((element) => {
    if (isUsed(element.useYn) && isHrv(element.name)) {
      createChildren(element.name, hrvChildren)
    }
  })
  return hrvChildren
}
// CYM : 테라피 관리
const createTherapyChildren = (menuList: Menu[]): any[] => {
  const therapyChildren: any[] = []
  menuList.forEach((element) => {
    if (isUsed(element.useYn) && isTherapy(element.name)) {
      createChildren(element.name, therapyChildren)
    }
  })
  return therapyChildren
}

const DASHBOARD: SideMenu = {...RouteConstant.HOME, icon: DashboardIcon}
const EDF: SideMenu = {...RouteConstant.ANALYSIS_EDF, icon: DownloadIcon}
const HEALTH_CENTER: SideMenu = {
  ...RouteConstant.ANALYSIS_HEALTH_CENTER,
  icon: CenterIcon,
}
const MY_MENU: SideMenu = {
  state: 'myPageCollapse',
  title: RouteConstant.MY.title,
  collapse: true,
  icon: InfoIcon,
  children: [
    {...RouteConstant.MY_INFO, icon: undefined},
    {...RouteConstant.MY_USAGE_HISTORY, icon: undefined},
    {...RouteConstant.LOGOUT, icon: undefined},
  ],
}

const COMMUNITY_MENU: SideMenu = {
  state: 'communityCollapse',
  title: RouteConstant.COMMUNITY.title,
  collapse: true,
  icon: ForumIcon,
  children: [
    {...RouteConstant.COMMUNITY_NOTICE, icon: undefined},
    {...RouteConstant.COMMUNITY_QUESTION, icon: undefined},
  ],
}
const ORG_MENU_USER: SideMenu = {
  state: 'orgCollapse',
  title: RouteConstant.ORG.title,
  collapse: true,
  icon: BusinessIcon,
  children: [
    // Client Request Hide Component
    // {...RouteConstant.ORG_PURCHASE, icon: undefined},
    {...RouteConstant.ORG_DEPARTMENTS, icon: undefined},
    {...RouteConstant.ORG_PATIENTS, icon: undefined},
    {...RouteConstant.ORG_PRESET, icon: undefined},
    {...RouteConstant.ORG_INSTITUTIONAL, icon: undefined},
    {...RouteConstant.ORG_ACCOUNT, icon: undefined},
    // Client Request Hide Component
    // {...RouteConstant.ORG_TREATMENTS, icon: undefined},
  ],
}
const ORG_MENU: SideMenu = {
  state: 'orgCollapse',
  title: RouteConstant.ORG.title,
  collapse: true,
  icon: BusinessIcon,
  children: [
    {...RouteConstant.ORG_INFO, icon: undefined},
    {...RouteConstant.ORG_INSTITUTIONAL, icon: undefined},
    {...RouteConstant.ORG_ACCOUNT, icon: undefined},
    {...RouteConstant.ORG_USERS, icon: undefined},
    // Client Request Hide Component
    // {...RouteConstant.ORG_PURCHASE, icon: undefined},
    {...RouteConstant.ORG_DEPARTMENTS, icon: undefined},
    {...RouteConstant.ORG_PATIENTS, icon: undefined},
    {...RouteConstant.ORG_PRESET, icon: undefined},
    // Client Request Hide Component
    // {...RouteConstant.ORG_TREATMENTS, icon: undefined},
  ],
}
const ORG_MENU_CS: SideMenu = {
  state: 'orgCollapse',
  title: RouteConstant.ORG.title,
  collapse: true,
  icon: BusinessIcon,
  children: [
    // Client Request Hide Component
    // {...RouteConstant.ORG_INFO, icon: undefined},
    // {...RouteConstant.ORG_USERS, icon: undefined},
    {...RouteConstant.ORG_DEPARTMENTS, icon: undefined},
    {...RouteConstant.ORG_PATIENTS, icon: undefined},
    // Client Request Hide Component
    // {...RouteConstant.ORG_TREATMENTS, icon: undefined},
  ],
}
const STORE_MENU: SideMenu = {
  ...RouteConstant.STORE,
  icon: StoreIcon,
}
const EEG_ANALYSIS_MENU: SideMenu = {
  state: 'analysis19Collapse',
  title: RouteConstant.ANALYSIS_EEG_19.title,
  collapse: true,
  icon: TimelineOutlinedIcon,
  children: [
    {...RouteConstant.ANALYSIS_EEG_19_I, icon: undefined},
    {...RouteConstant.ANALYSIS_EEG_19_S, icon: undefined},
    {...RouteConstant.ANALYSIS_EEG_19_N, icon: undefined},
    {...RouteConstant.ANALYSIS_EEG_19_M, icon: undefined},
  ],
}
const HRV_ANALYSIS_MENU: SideMenu = {
  state: 'analysisHrvCollapse',
  title: RouteConstant.ANALYSIS_HRV.title,
  icon: FavoriteIcon,
  collapse: true,
  children: [
    {...RouteConstant.ANALYSIS_HRV_I, icon: undefined},
    {...RouteConstant.ANALYSIS_HRV_S, icon: undefined},
  ],
}
// CYM : 테라피 관리
const THERAPY_MANAGEMENT_MENU: SideMenu = {
  state: 'therapyManagementCollapse',
  title: 'ITherapyManagement',
  icon: PlayForWorkIcon,
  collapse: true,
  children: [{...RouteConstant.THERAPY_MANAGEMENT_L, icon: undefined}],
}

const DISPLAY_DESCRIPTION: SideMenu = {
  state: 'displayDescriptionCollapse',
  title: RouteConstant.DISPLAY_DESCRIPTION.title,
  icon: Bookmark,
  collapse: true,
  children: [
    {...RouteConstant.ISYNC_BRAIN_C, icon: undefined},
    {...RouteConstant.ISYNCBRAIN_MCI, icon: undefined},
    {...RouteConstant.ISYNCBRAIN_HEART, icon: undefined},
  ],
}

const SALES_MENU: SideMenu = {
  state: 'salesCollapse',
  title: RouteConstant.SALES.title,
  collapse: true,
  icon: MonetizationOnIcon,
  children: [
    {...RouteConstant.SALES_ACCOUNTS, icon: undefined},
    {...RouteConstant.SALES_PURCHASE, icon: undefined},
    {...RouteConstant.SALES_STATISTICS, icon: undefined},
    {...RouteConstant.SALES_USAGE_INQUIRY, icon: undefined},
    {...RouteConstant.SALES_PAGE_MANAGEMENT, icon: undefined},
    {...RouteConstant.SALES_APP_NOTICE, icon: undefined},
    {...RouteConstant.INVOICES_LIST, icon: undefined},
    {...RouteConstant.MEMBER_REGISTRATION, icon: undefined},
    {...RouteConstant.CUSTOMER_INQUIRY, icon: undefined},
  ],
}

// const MANAGEMENT_MENU: SideMenu = {
//   ...RouteConstant.MANAGER_MEMBER_REGISTER,
//   icon: InfoIcon,
// }

const commonMenu: SideMenu[] = [
  DASHBOARD,
  STORE_MENU,
  COMMUNITY_MENU,
  DISPLAY_DESCRIPTION,
]

// const userMenu: SideMenu[] = [
//   DASHBOARD,
//   EDF,
//   EEG_ANALYSIS_MENU,
//   HRV_ANALYSIS_MENU,
//   THERAPY_MANAGEMENT_MENU,
//   MY_MENU,
//   ORG_MENU_USER,
//   COMMUNITY_MENU,
//   STORE_MENU,
// ]
//
// const adminMenu: SideMenu[] = [
//   DASHBOARD,
//   EDF,
//   EEG_ANALYSIS_MENU,
//   HRV_ANALYSIS_MENU,
//   THERAPY_MANAGEMENT_MENU,
//   MY_MENU,
//   ORG_MENU,
//   COMMUNITY_MENU,
//   STORE_MENU,
// ]

// const isEdfAvailable = (edfYn: YN | undefined | null): edfYn is 'Y' =>
//   isDefined(edfYn) && edfYn === 'Y'
//
// const excludeEdf = (side: SideMenu) => side !== EDF

const buildCommonMenu = (): SideMenu[] => {
  return commonMenu
}

// const buildCompanyMenu = (authority: Role, edfYn: YN | undefined | null) => {
//   const edfAvailable = isEdfAvailable(edfYn)
//
//   if (isCSOrSalesAuthority(authority)) {
//     const selectedIndex = userMenu.indexOf(ORG_MENU_USER)
//     const menus = [
//       ...userMenu.slice(0, selectedIndex + 1),
//       SALES_MENU,
//       ...userMenu.slice(selectedIndex + 1),
//     ]
//     return edfAvailable ? menus : menus.filter(excludeEdf)
//   }
//
//   if (isAdmin(authority)) {
//     return edfAvailable ? adminMenu : adminMenu.filter(excludeEdf)
//   }
//
//   return edfAvailable ? userMenu : userMenu.filter(excludeEdf)
// }
//
// const buildOrgMenu = (
//   authority: Role,
//   edfYn: YN | undefined | null,
//   menuList: Menu[] | undefined | null,
// ) => {
//   const edfAvailable = isEdfAvailable(edfYn)
//
//   if (isAdmin(authority)) {
//     return edfAvailable ? adminMenu : adminMenu.filter(excludeEdf)
//   }
//
//   return edfAvailable ? userMenu : userMenu.filter(excludeEdf)
// }

// eslint-disable-next-line import/prefer-default-export
export const buildMenu = (
  authority: Role | undefined,
  edfYn: YN | undefined | null,
  menuList: Menu[] | undefined | null,
  email: string | undefined,
  organizationType?: string | undefined | null,
) => {
  if (!isDefined(authority) || authority === Roles.ROLE_UNKNOWN) {
    return buildCommonMenu()
  }
  const buildSideMenu: SideMenu[] = [DASHBOARD]

  if (!menuList) {
    return buildSideMenu
  }

  const CUSTOM_EEG_ANALYSIS_MENU: SideMenu = {
    state: 'analysis19Collapse',
    title: RouteConstant.ANALYSIS_EEG_19.title,
    collapse: true,
    icon: TimelineOutlinedIcon,
    children: createEegChildren(menuList),
  }

  const CUSTOM_HRV_ANALYSIS_MENU: SideMenu = {
    state: 'analysisHrvCollapse',
    title: RouteConstant.ANALYSIS_HRV.title,
    icon: FavoriteIcon,
    collapse: true,
    children: createHrvChildren(menuList),
  }

  const CUSTOM_THERAPY_MANAGEMENT_MENU: SideMenu = {
    state: 'therapyManagementCollapse',
    title: RouteConstant.THERAPY_MANAGEMENT.title,
    icon: PlayForWorkIcon,
    collapse: true,
    children: createTherapyChildren(menuList),
  }

  // 실 서버 환경에서 메뉴 추가
  if (isProd()) {
    menuList?.forEach((item) => {
      if (!isOrganizationWhiteList(organizationType)) {
        if (item.useYn === 'Y' && item.name === 'EDF') {
          return buildSideMenu.push(EDF)
        }
      }
      if (item.useYn === 'Y' && item.name === 'EEG') {
        return buildSideMenu.push(EEG_ANALYSIS_MENU)
      }
      if (item.useYn === 'Y' && item.name === 'HRV') {
        return buildSideMenu.push(HRV_ANALYSIS_MENU)
      }
      if (item.useYn === 'Y' && item.name === 'THERAPY') {
        return buildSideMenu.push(THERAPY_MANAGEMENT_MENU)
      }
      return buildSideMenu
    })
  }

  // 개발, 스테이징 환경에서 메뉴 추가
  if (isDev() || isStage()) {
    menuList?.forEach((item) => {
      if (!isOrganizationWhiteList(organizationType)) {
        if (item.useYn === 'Y' && item.name === 'EDF') {
          return buildSideMenu.push(EDF)
        }
      }
      if (item.useYn === 'Y' && item.name === 'EEG') {
        return buildSideMenu.push(CUSTOM_EEG_ANALYSIS_MENU)
      }
      if (item.useYn === 'Y' && item.name === 'HRV') {
        return buildSideMenu.push(CUSTOM_HRV_ANALYSIS_MENU)
      }
      if (item.useYn === 'Y' && item.name === 'THERAPY') {
        return buildSideMenu.push(CUSTOM_THERAPY_MANAGEMENT_MENU)
      }

      return buildSideMenu
    })
  }

  if (isOrganizationHealth(organizationType)) buildSideMenu.push(HEALTH_CENTER)

  buildSideMenu.push(MY_MENU)
  if (isOrgAndCompanyUser(authority)) {
    if (isOrganizationHealth(organizationType)) {
      ORG_MENU_USER.children = ORG_MENU_USER?.children?.filter(
        (menu) => menu.path !== RouteConstant.ORG_PRESET.path,
      )
    }
    if (!isAdmin(authority)) {
      ORG_MENU_USER.children = ORG_MENU_USER?.children?.filter(
        (menu) =>
          menu.path !== RouteConstant.ORG_INSTITUTIONAL.path &&
          menu.path !== RouteConstant.ORG_ACCOUNT.path,
      )
    }

    buildSideMenu.push(ORG_MENU_USER)
  } else {
    if (isOrganizationHealth(organizationType)) {
      ORG_MENU.children = ORG_MENU?.children?.filter(
        (menu) => menu.path !== RouteConstant.ORG_PRESET.path,
      )
    }
    if (!isAdmin(authority)) {
      ORG_MENU.children = ORG_MENU?.children?.filter(
        (menu) =>
          menu.path !== RouteConstant.ORG_INSTITUTIONAL.path &&
          menu.path !== RouteConstant.ORG_ACCOUNT.path,
      )
    }

    buildSideMenu.push(ORG_MENU)
  }

  // if (!isAdmin(authority) && !isCompanyAuthority(authority)) {
  //   buildSideMenu.push(ORG_MENU_USER)
  // }
  // if (isAdmin(authority) && !isCompanyAuthority(authority)) {
  //   buildSideMenu.push(ORG_MENU)
  // }
  // if (isCompanyAuthority(authority)) {
  //   buildSideMenu.push(ORG_MENU_CS)
  // }

  if (isCompanyAuthority(authority) && isCSOrSalesAuthority(authority)) {
    buildSideMenu.push(SALES_MENU)
  }
  buildSideMenu.push(COMMUNITY_MENU)

  buildSideMenu.push(DISPLAY_DESCRIPTION)
  // 엑심페이 심사용
  if (isDev()) buildSideMenu.push(STORE_MENU)

  // if (isImedisyncCS(authority, email ?? '')) buildSideMenu.push(MANAGEMENT_MENU)

  return buildSideMenu
}
