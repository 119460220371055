import {createStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {Colors} from 'components/common/useBaseStyle'
import Text, {TextProps} from 'components/Text'
import React, {ReactNodeArray} from 'react'

const useSubTitleStyles = makeStyles(
  createStyles({
    root: {
      position: 'relative',
      marginBottom: 21,
    },
    notation: {
      display: 'inline-block',
      width: 6,
      height: '100%',
      minHeight: '1.125rem',
      marginRight: 9,
      backgroundColor: Colors.primary,
      paddingTop: 5,
      marginTop: 5,
    },
    buttonsWrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      position: 'absolute',
      top: 0,
      right: 0,
      gap: 6,
    },
    h3Reset: {
      display: 'flex',
      alignContent: 'center',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
  }),
)

export type SubTitleBaseProps = {
  className?: string
  buttons?: ReactNodeArray
}

type SubtitleProps = Omit<TextProps<'h3'>, 'typography' | 'as'> &
  SubTitleBaseProps

export default function SubTitle({
  className,
  children,
  buttons = [],
  ...props
}: SubtitleProps) {
  const classes = useSubTitleStyles()

  const classNames = classnames({
    [classes.root]: true,
    // @ts-ignore
    [className]: className !== undefined,
  })

  return (
    <div className={classNames}>
      <Text
        typography='subTitle'
        as='h3'
        {...props}
        className={classes.h3Reset}
      >
        <span className={classes.notation} />
        {children}
      </Text>
      {buttons.length > 0 && (
        <div className={classes.buttonsWrapper}>{buttons}</div>
      )}
    </div>
  )
}
