import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate'
import Card from 'components/atoms/Card'
import {
  closePointPayment,
  selectPointPaymentDialogOpen,
} from 'features/modal/modalSlice'
import {consumePointRequest, savePointRequest} from 'features/point/pointSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // @ts-ignore
      zIndex: '1350 !important',
      Width: 600,
    },
    contentContainer: {
      Width: 600,
      justifyContent: 'center',
      background: '#eef2f6',
    },
    confirmContainer: {
      background: '#eef2f6',
      display: 'flex',
      alignItems: 'center',
      margin: '40px 20px',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'column',
      flex: 1,
      width: '300px',
      '& h5': {
        fontWeight: 'bolder',
      },
      '& h6': {
        fontSize: '1.0rem',
        fontWeight: 'normal',
        textAlign: 'left',
        margin: '5px 0',
        flexWrap: 'wrap',
        flex: 1,
        color: '#717376',
      },
      '& TextField': {
        flex: 1,
      },
    },
    alertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
      background: 'white',
      '& button': {
        flex: 1,
      },
    },
    padding: {
      padding: theme.spacing(1),
    },
    okButtonColor: {
      color: 'white',
    },
    longTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'space-between',
      color: 'primary',
      background: 'white',
      '& button': {
        margin: 10,
      },
    },
    hidden: {
      visibility: 'hidden',
    },
    iconTitleWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 15,
    },
    iconColor: {
      color: '#636364',
      fontSize: 20,
      marginRight: 8,
    },
    scroll: {
      flexDirection: 'column',
      flexGrow: 1,
      maxHeight: '550px',
      minHeight: '100px',
      flexWrap: 'wrap',
      overflow: 'auto',
      backgroundColor: '#fff',
      margin: 30,
      padding: 20,
    },
    subTitle: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 50,
      maxWidth: 200,
      flexBasis: 200,
      flexWrap: 'wrap',
      marginTop: 5,
      color: '#757575',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    textField: {
      flexGrow: 1,
      maxWidth: 200,
    },
  }),
)

const PointPaymentDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectPointPaymentDialogOpen)
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(closePointPayment())
  }
  const [pointValue, setPointValue] = React.useState('')

  const handleOk = () => {
    if (pointValue.includes('-')) {
      dispatch(consumePointRequest())
    } else {
      dispatch(savePointRequest())
    }
  }
  return (
    <Dialog open={open} disableEscapeKeyDown className={classes.root}>
      <Card className={classes.contentContainer}>
        <div className={classes.longTitleContainer}>
          <IconButton
            type='submit'
            aria-label='search'
            className={classes.hidden}
          />
          <div className={classes.iconTitleWrap}>
            <ControlPointDuplicateIcon className={classes.iconColor} />
            <Typography variant='h6'>{t('IPointPlusAndMinus')}</Typography>
          </div>
          <IconButton aria-label='search' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <div className={classes.scroll}>
            <div className={classes.inputContainer}>
              <Typography className={classes.subTitle}>
                {t('IQuantity')}
              </Typography>
              <TextField
                variant='outlined'
                className={classes.textField}
                placeholder={t('IQuantity')}
                onChange={(e) => {
                  setPointValue(e.currentTarget.value)
                }}
              />
            </div>
            <Typography className={classes.padding} />
            <div className={classes.inputContainer}>
              <Typography className={classes.subTitle}>메모</Typography>
              <TextField
                variant='outlined'
                className={classes.textField}
                placeholder={t('IPointMemoLength')}
                onChange={() => {}}
              />
            </div>
          </div>

          <div className={classes.alertButtonContainer}>
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
            >
              {t('ICancel')}
            </Button>
            <Typography className={classes.padding} />
            <Button
              variant='contained'
              color='secondary'
              disableElevation
              onClick={handleOk}
              className={classes.okButtonColor}
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default PointPaymentDialog
