/**
 * 페이지 큰 타이틀과 카드 서브 타이틀 공용으로 사용할 컴포넌트입니다.
 * font-size 등이 heading_02 면 as='h2' 태그로 사용합니다.
 */

import React from 'react'
import styled from 'styled-components'

export interface TextTitleStyleProps {
  textColor: string
  textSize: number
  textWeight: number
  lineHeight: number
  textMarginLeft?: number
}

export interface TextTitleProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  title: string
  theme: TextTitleStyleProps
}

const StyledTextTitle = styled.h2<TextTitleStyleProps>`
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.textSize}px;
  font-weight: ${(props) => props.textWeight};
  line-height: ${(props) => props.lineHeight}px;
  position: relative;
`
/**
 *
 * @param
 * - as: 'h2' || 'h3' || 'h4' || 'h5'
 */
export default function TextTitle({as, title, theme}: TextTitleProps) {
  return (
    <StyledTextTitle
      as={as}
      textColor={theme.textColor}
      textSize={theme.textSize}
      textWeight={theme.textWeight}
      lineHeight={theme.lineHeight}
      textMarginLeft={theme.textMarginLeft}
    >
      {title}
    </StyledTextTitle>
  )
}
