import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState, ThunkExtraArguments} from 'store'

interface State {
  successModalOpen: boolean
  successModalMessage?: string
  failureModalOpen: boolean
  failureModalMessage?: string
  failureModalTitle?: string
}

export interface FailureModalProps {
  message?: string
  title?: string
}

const initialState: State = {
  successModalOpen: false,
  failureModalOpen: false,
}

const slice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    openSuccessModal(state, action: PayloadAction<string>) {
      state.successModalOpen = true
      state.successModalMessage = action.payload
    },
    closeSuccessModal(state) {
      state.successModalOpen = false
      state.successModalMessage = undefined
    },
    openFailureModal(state, action: PayloadAction<string>) {
      state.failureModalOpen = true
      state.failureModalMessage = action.payload
    },
    openFailureCustomModal(state, action: PayloadAction<FailureModalProps>) {
      state.failureModalOpen = true
      if (action.payload) {
        state.failureModalTitle = action.payload.title
        state.failureModalMessage = action.payload.message
      }
    },
    closeFailureModal(state) {
      state.failureModalOpen = false
      state.failureModalMessage = undefined
    },
  },
})

export default slice.reducer

export const {
  openSuccessModal,
  closeSuccessModal,
  openFailureModal,
  openFailureCustomModal,
  closeFailureModal,
} = slice.actions

export const openSuccessModalAction = createAsyncThunk<
  void,
  string,
  {extra: ThunkExtraArguments}
>('asyncOpenSuccessModal', async (message: string, {dispatch, extra}) => {
  const {store} = extra
  dispatch(openSuccessModal(message))

  return new Promise<void>((resolve) => {
    // eslint-disable-next-line consistent-return
    const unsubscribe = store.subscribe(() => {
      const state: RootState = store.getState()
      if (state.alert.successModalOpen === false) {
        unsubscribe()
        return resolve()
      }
    })
  })
})

export const openFailureModalAction = createAsyncThunk<
  void,
  string,
  {extra: ThunkExtraArguments}
>('asyncOpenFailureModal', async (message: string, {dispatch, extra}) => {
  const {store} = extra
  dispatch(openFailureModal(message))

  return new Promise<void>((resolve) => {
    // eslint-disable-next-line consistent-return
    const unsubscribe = store.subscribe(() => {
      const state: RootState = store.getState()
      if (state.alert.failureModalOpen === false) {
        unsubscribe()
        return resolve()
      }
    })
  })
})

export const openFailureCustomModalAction = createAsyncThunk<
  void,
  FailureModalProps,
  {extra: ThunkExtraArguments}
>(
  'asyncOpenFailureCustomModal',
  async ({title, message}, {dispatch, extra}) => {
    const {store} = extra
    dispatch(openFailureCustomModal({title, message}))

    return new Promise<void>((resolve) => {
      // eslint-disable-next-line consistent-return
      const unsubscribe = store.subscribe(() => {
        const state: RootState = store.getState()
        if (state.alert.failureModalOpen === false) {
          unsubscribe()
          return resolve()
        }
      })
    })
  },
)

export const selectSuccessModal = (state: RootState) => ({
  open: state.alert.successModalOpen,
  message: state.alert.successModalMessage,
})

export const selectFailureModal = (state: RootState) => ({
  open: state.alert.failureModalOpen,
  message: state.alert.failureModalMessage,
})

export const selectFailureCustomModal = (state: RootState) => ({
  customOpen: state.alert.failureModalOpen,
  customTitle: state.alert.failureModalTitle,
  customMessage: state.alert.failureModalMessage,
})
