import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import {OrgUserSearchRequest, searchUserApi} from 'api/userApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import UserDeletePasswordConfirm from 'components/Dialog/UserDeletePasswordConfirm'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {
  openUserAddDialog,
  openUserDeletePasswordConfirm,
  openUserReadDialog,
} from 'features/modal/modalSlice'
import useConfirm from 'features/modal/useConfirm'
import useFailureModal from 'features/modal/useFailureModal'
import {useAppDispatch} from 'hooks'
import useUserList from 'hooks/useUserList'
import React, {MouseEventHandler, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useQuery} from 'react-query'
import styled from 'styled-components'
import {useHistory, useLocation} from 'react-router-dom'
import {getQueryFromUrl} from 'helpers/commonHelper'

interface EnhancedTableToolbarProps {
  selected: number[]
  query: OrgUserSearchRequest
}

/**
 * TODO: 삭제 버튼 클릭시 비밀번호 확인 다이얼로그를 띄우는데, 해당 다이얼로그에 사용자 삭제 로직이 들어있다.
 *       만약, 비밀번호 확인 다이얼로그가 여러 페이지에서 씌인다면 문제가 발생할 수 있으므로,
 *       비밀번호 확인 다이얼로그는 비밀번호 확인 결과만 리턴하도록 변경되어야 한다.
 */
const EnhancedTableToolbar = ({selected, query}: EnhancedTableToolbarProps) => {
  const {t} = useTranslation()
  const classes = useToolbarStyles()

  const dispatch = useAppDispatch()
  const {data} = useUserList(query)
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [deleteList, setDeleteList] = React.useState<string[]>([])

  const handleUserAddClick = () => dispatch(openUserAddDialog())

  const openPassword = () => dispatch(openUserDeletePasswordConfirm())

  const deleteOrgUser = async () => openPassword()

  const {onOpen: onConfirmOpen} = useConfirm()

  const handleDeleteUser = async () => {
    const result = await onConfirmOpen({message: t('IOrgUserDeleteSelect')})

    if (result.payload) {
      if (deleteList && deleteList?.length > 0) {
        deleteOrgUser()
      } else {
        onFailureModalOpen(t('IPatientSelect'))
      }
    }
  }

  useEffect(() => {
    const users = data?.data.content ?? []
    const deleteUsers = users
      .filter((user) => selected.includes(user.id))
      .map((user) => user.uid)
    setDeleteList([...deleteUsers])
  }, [selected])

  return (
    <div className={classes.root}>
      <div className={`${classes.actionContainer}`}>
        <ActionButton startIcon={<AddIcon />} onClick={handleUserAddClick}>
          {t('IAdd')}
        </ActionButton>

        <ActionButton
          onClick={handleDeleteUser}
          startIcon={<DeleteIcon />}
          disabled={!(deleteList.length > 0)}
        >
          {t('IDelete')}
        </ActionButton>
      </div>
      <UserDeletePasswordConfirm selected={deleteList} />
    </div>
  )
}

type HeadCellKey = keyof SearchedUser | 'detail'

interface HeadCell {
  id: HeadCellKey
  label: string
}

const headCells: HeadCell[] = [
  {id: 'id', label: 'IIndex'},
  {id: 'name', label: 'IFirstName'},
  {id: 'email', label: 'IEmail'},
  {id: 'deptName', label: 'IDepartment'},
  {id: 'detail', label: 'IDetailInfo'},
]

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

function EnhancedTableHead({
  classes,
  numSelected,
  onSelectAllClick,
  rowCount,
}: EnhancedTableHeadProps) {
  const {t} = useTranslation()
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {headCells.map((cell) => (
          <TableCell key={cell.id} align='center' padding='none'>
            {t(cell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const PagingLeftAlign = styled.div`
  display: flex;
  width: 100%;
`

function UserTable() {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const [items, setItems] = React.useState<SearchedUser[]>([])
  const [paging, setPaging] = React.useState<Paging>({
    page: 0,
    size: 0,
  })
  const [query, setQuery] = React.useState<OrgUserSearchRequest>({
    paging: {
      page: 0,
      size: 10,
    },
    search: {
      searchName: null,
      searchValue: '',
    },
    period: {
      startDate: '',
      endDate: '',
    },
  })
  const [selected, setSelected] = React.useState<number[]>([])
  const {isLoading, data} = useQuery(
    ['fetchUsersList', query],
    () => {
      try {
        const params = new URLSearchParams(
          Object.entries(query).map(([key, value]) => [
            key,
            typeof value === 'object' ? JSON.stringify(value) : value,
          ]),
        )
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        })
      } catch (error) {
        console.error('Update URLSearchParams failed', error)
      }
      return searchUserApi(query)
    },
    {
      onSuccess: (data) => {
        setPaging({
          page: data?.data.pageable.pageNumber ?? 0,
          size: data?.data.pageable.pageSize ?? 10,
        })
        setItems(data.data.content)
        setSelected([])
      },
    },
  )

  // Init search value  from url if exist
  useEffect(() => {
    const initValue = getQueryFromUrl(location.search)
    setQuery({...query, ...initValue})
  }, [])

  const rowsPerPage = 10

  const setPageIndex = (page: number) => {
    setQuery({...query, paging: {page, size: 10}})
  }
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = items?.map((item) => item.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleRowClick = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id)
    if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1))
    if (selectedIndex === selected.length - 1)
      newSelected = newSelected.concat(selected.slice(0, -1))
    if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    setSelected(newSelected)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1
  const emptyRows = rowsPerPage - items?.length

  return (
    <TableContainer className={classes.container}>
      <EnhancedTableToolbar selected={selected} query={query} />
      <Table className={classes.table} size='small'>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={items?.length ?? 0}
        />
        <TableBody>
          {items?.map((item, index) => {
            const isItemSelected = isSelected(item.id)
            const labelId = `enhanced-table-checkbox-${index}`
            const handleClick = () => handleRowClick(item.id)
            const handleUserClick: MouseEventHandler<HTMLButtonElement> = (
              event,
            ) => {
              event.stopPropagation()
              dispatch(
                openUserReadDialog({
                  uid: item.uid,
                  dialogType: 'USER_INFO',
                }),
              )
            }

            return (
              <TableRow
                hover
                onClick={handleClick}
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={item.id}
                selected={isItemSelected}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </TableCell>
                <TableCell align='center'>{item.id}</TableCell>
                <TableCell align='center'>
                  <ClientName
                    firstName={item?.firstName ?? '-'}
                    lastName={item?.lastName ?? '-'}
                  />
                </TableCell>
                <TableCell align='center'>{item.email}</TableCell>
                <TableCell align='center'>{item.deptName}</TableCell>
                <TableCell align='center'>
                  <TableButton onClick={handleUserClick}>
                    {t('IDetail')}
                  </TableButton>
                </TableCell>
              </TableRow>
            )
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <PagingLeftAlign>
        <span style={{width: '100%'}} />
        <Pagination
          totalPageCount={data?.data.totalPages ?? 0}
          currentPageIndex={data?.data.pageable.pageNumber ?? 0}
          itemCountPerPage={paging.size}
          setCurrentPageIndex={setPageIndex}
          isItemCountPerPageEnabled={false}
          loading={isLoading}
          onItemCountPerPageChanged={() => {}}
        />
      </PagingLeftAlign>
    </TableContainer>
  )
}

export default UserTable
