import basic from 'assets/img/usage_history/basic.png'
import standard from 'assets/img/usage_history/standard.png'
import premium from 'assets/img/usage_history/premium.png'
import premiumPlus from 'assets/img/usage_history/premiumplus.png'
import hrv from 'assets/img/usage_history/hrv.png'
import basicHrv from 'assets/img/usage_history/basic_hrv.png'
import standardHrv from 'assets/img/usage_history/standard_hrv.png'
import premiumHrv from 'assets/img/usage_history/premium_hrv.png'
import premiumPlusHrv from 'assets/img/usage_history/premiumplus_hrv.png'

export const MINUTE = 60
export const DEFAULT_DATE_SELECT = ''
export const MIN_DATE = '2024/10/01'
export const DAY_LEFT = 15
export const MILLISECONDS_OF_DAY = 1000 * 60 * 60 * 24

export const LIST_EEG: CommonUsageHistory[] = [
  {title: 'NORM (M)', key: 'eegNorm'},
  {title: 'NORM 3D (M)', key: 'eegNorm3d'},
  {title: '3D (M)', key: 'eeg3D'},
  {title: 'Summary (M)', key: 'eegSummary'},
  {title: 'Summary Advance (M)', key: 'eegSummaryAdv'},
  {title: 'MCI (M)', key: 'eegMci'},
]

export const LIST_HRV: CommonUsageHistory[] = [
  {title: 'Summary (M)', key: 'hrvSummary'},
  {title: 'Summary Advance (M)', key: 'hrvSummaryAdv'},
]

export const LIST_HEALTH: CommonUsageHistory[] = [
  {title: 'Basic', key: 'healthBasic', img: basic},
  {title: 'Basic + HRV', key: 'healthBasicHrv', img: basicHrv},
  {title: 'Standard', key: 'healthStandard', img: standard},
  {
    title: 'Standard + HRV',
    key: 'healthStandardHrv',
    img: standardHrv,
  },

  {title: 'Premium', key: 'healthPremium', img: premium},
  {
    title: 'Premium + HRV',
    key: 'healthPremiumHrv',
    img: premiumHrv,
  },

  {
    title: 'Premium Plus',
    key: 'healthPremiumPlus',
    img: premiumPlus,
  },

  {
    title: 'Premium Plus + HRV',
    key: 'healthPremiumPlusHrv',
    img: premiumPlusHrv,
  },
  {title: 'HRV', key: 'healthHrvOnly', img: hrv},
]
export const DATA_DEFAULT: UsageHistory[] = [
  {
    type: 'healthBasic',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthHrvOnly',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eegMci',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eegNorm3d',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eegSummaryAdv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthPremium',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthStandardHrv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'pbm',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthPremiumPlus',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'hrvSummaryAdv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eegSummary',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthBasicHrv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthStandard',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eeg',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'hrvSummary',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthPremiumPlusHrv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eegNorm',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'eeg3D',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'healthPremiumHrv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
  {
    type: 'hrv',
    periodBegin: null,
    periodEnd: null,
    totalCount: 0,
    successCount: 0,
    failureCount: 0,
    partialSuccessCount: 0,
  },
]
