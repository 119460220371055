import {DialogContent} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {fetchZScoreList, ZScore, ZScoreListItem} from 'api/analysisApi'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import useEegIndividualNormDialog from 'features/analysis/useEegIndividualNormDialog'
import useFailureModal from 'features/modal/useFailureModal'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'

type ZScoreAvailableMap = Record<ZScore, boolean>

const DefaultZScoreAvailableMap: ZScoreAvailableMap = {
  1.96: true,
  2.58: true,
  3: true,
}

const TestZScoreAvailableMap: ZScoreAvailableMap = {
  1.96: false,
  2.58: false,
  3: false,
}
const styles = () =>
  createStyles({
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 15,
    },
    inputLabel: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 2px',
      minWidth: 120,
      maxWidth: 100,
    },
    buttonsContainer: {
      padding: 20,
      backgroundColor: '#f4f4f4',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 10,
      '& .MuiFormLabel-root': {
        marginBottom: 10,
      },
      '& .MuiFormControlLabel-root': {
        minWidth: 250,
      },
      '& button': {
        minWidth: 136,
      },
    },
    radioWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& label > span': {
        marginRight: 70,
        color: '#555555',
        fontSize: 13,
      },
      '& label > span > span > div > .MuiSvgIcon-fontSizeSmall': {
        fontSize: 17,
      },
    },
    radioText: {
      color: '#555555',
      fontSize: 13,
    },
    line: {
      color: '#fafafa',
      backgroundColor: '#fafafa',
      margin: '5px 0',
    },
  })
const useStyles = makeStyles(styles)

function NormRequestDialog() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {open, selected, onConfirm, onClose} = useEegIndividualNormDialog()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const params = {individualRequestIds: selected}

  const [zScoreAvailableMap, setZScoreAvailableMap] =
    useState<ZScoreAvailableMap>(DefaultZScoreAvailableMap)

  const [zScore, setZScore] = useState('1.96')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZScore((event.target as HTMLInputElement).value)
  }

  const isCompleted = () =>
    !zScoreAvailableMap[1.96] &&
    !zScoreAvailableMap[2.58] &&
    !zScoreAvailableMap[3]

  const asyncResult = useAsync(fetchZScoreList, [params], {
    executeOnMount: false,
    executeOnUpdate: false,
  })

  const handleConfirm = () => {
    if (selected.length === 0) {
      return
    }

    if (zScore === '') {
      return
    }

    onConfirm({
      individualRequestIds: selected,
      zscore: Number.parseFloat(zScore) as ZScore,
    })
    setZScoreAvailableMap(DefaultZScoreAvailableMap)
    onClose()
  }

  const handleClose = () => {
    setZScoreAvailableMap(DefaultZScoreAvailableMap)
    onClose()
  }

  const arrayReduce = (list?: ZScoreListItem[]): ZScoreAvailableMap => {
    if (list) {
      setZScoreAvailableMap(TestZScoreAvailableMap)
      const availableMap: ZScoreAvailableMap = {
        1.96: true,
        2.58: true,
        3: true,
      }

      const availableZScore: number[] = [1.96, 2.58, 3]
      const listZScore: number[] = list.map((item) => item.zscore)
      list.forEach((element) => {
        if (element.zscore === 1.96) availableMap[1.96] = false
        if (element.zscore === 2.58) availableMap[2.58] = false
        if (element.zscore === 3) availableMap[3] = false
      })
      setZScoreAvailableMap(availableMap)

      const uniqueElements = [
        ...availableZScore.filter((item) => !listZScore.includes(item)),
        ...listZScore.filter((item) => !availableZScore.includes(item)),
      ]
      const minNumber = Math.min(...uniqueElements)
      if (minNumber) {
        setZScore(String(minNumber))
      } else {
        setZScore('')
      }

      return TestZScoreAvailableMap
    }

    return DefaultZScoreAvailableMap
  }

  useEffect(() => {
    if (!open) {
      setZScoreAvailableMap(DefaultZScoreAvailableMap)
      return
    }
    if (selected.length === 0) {
      onFailureModalOpen(t('ISelectRequired')).then(() => {
        handleClose()
      })
      return
    }

    setZScoreAvailableMap(DefaultZScoreAvailableMap)
    setZScore('1.96')

    asyncResult.execute(params).then((result) => {
      if (result.list.length === 0) {
        setZScoreAvailableMap(DefaultZScoreAvailableMap)
      }

      if (result.list.length !== 0) {
        arrayReduce(result.list)
      }
    })
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{color: '#333333'}}>
        {t('IZscoreAnalysisTitle')}
      </DialogTitle>
      <Divider />
      <DialogContent style={{margin: 10}}>
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {!asyncResult.loading && asyncResult.error !== undefined && (
          <div>{asyncResult.error.message}</div>
        )}
        {!asyncResult.loading && asyncResult.result !== undefined && (
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='gender'
              name='zScore'
              value={zScore}
              onChange={handleChange}
            >
              <div className={classes.radioWrap}>
                <FormControlLabel
                  value='1.96'
                  control={<Radio color='primary' size='small' />}
                  label='1.96 '
                  disabled={!zScoreAvailableMap[1.96]}
                />
                {!zScoreAvailableMap[1.96] && (
                  <span className={classes.radioText}>
                    {t('IHasCompletionHistory')}
                  </span>
                )}
                {zScoreAvailableMap[1.96] && (
                  <span className={classes.radioText}>
                    {t('IAnalysisAvailable')}
                  </span>
                )}
              </div>
              <Divider className={classes.line} />
              <div className={classes.radioWrap}>
                <FormControlLabel
                  value='2.58'
                  control={<Radio color='primary' size='small' />}
                  label='2.58'
                  disabled={!zScoreAvailableMap[2.58]}
                />
                {!zScoreAvailableMap[2.58] && (
                  <span className={classes.radioText}>
                    {t('IHasCompletionHistory')}
                  </span>
                )}
                {zScoreAvailableMap[2.58] && (
                  <span className={classes.radioText}>
                    {t('IAnalysisAvailable')}
                  </span>
                )}
              </div>
              <Divider className={classes.line} />
              <div className={classes.radioWrap}>
                <FormControlLabel
                  value='3'
                  control={<Radio color='primary' size='small' />}
                  label='3'
                  disabled={!zScoreAvailableMap[3]}
                />
                {!zScoreAvailableMap[3] && (
                  <span className={classes.radioText}>
                    {t('IHasCompletionHistory')}
                  </span>
                )}
                {zScoreAvailableMap[3] && (
                  <span className={classes.radioText}>
                    {t('IAnalysisAvailable')}
                  </span>
                )}
              </div>
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>
      <div className={classes.buttonsContainer}>
        <CancelButton onClick={handleClose} />
        <ConfirmButton onClick={handleConfirm} disabled={isCompleted()} />
      </div>
    </Dialog>
  )
}

export default NormRequestDialog
