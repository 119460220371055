import React, {useEffect} from 'react'
import {
  PurchasePackage,
  PurchaseBundle,
  PurchasedProducts,
  UnPaidVoucherContainer,
} from 'api/paymentApi'
import PackagePaymentDialog from 'components/Dialog/PackagePaymentDialog'
import {useLocation} from 'react-router-dom'
import useOrgPurchase from 'features/org/useOrgPurchase'
import ProductsCheckPage from './ProductsCheckPage'
import ProductsSelectPage from './ProductsSelectPage'
import PaymentManagementPage from './PaymentManagementPage'
import useViewModel from './ViewModels/PaymentManageViewModel'
import UnPaidOrderModel from './Models/UnPaidOrderModel'
import {OrderSteps} from '../Interfaces/Enums'
import OrderResponseModel from './Models/OrderResponseModel'

function PaymentManagementPages() {
  const location = useLocation()
  const {orderStep, onChange: setOrderStep} = useOrgPurchase()

  const [selectedPackages, setSelectedPackages] = React.useState<
    PurchasePackage[]
  >([])
  const [selectedBundles, setSelectedBundles] = React.useState<
    PurchaseBundle[]
  >([])
  const [purchased, setPurchased] = React.useState<PurchasedProducts>()
  const [overdue, setOverdue] = React.useState<UnPaidVoucherContainer>()
  const [order, setOrder] = React.useState<OrderResponseModel>()

  const [orderUnabled] = React.useState<boolean>(false)

  const {unPaidOrder, getUnpaidOrder, setInitialUnpaidOrder} = useViewModel()

  const successCalback = (
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
    purchased: PurchasedProducts,
    overdue: UnPaidVoucherContainer,
  ) => {
    setSelectedBundles(bundles)
    setSelectedPackages(packages)
    setPurchased(purchased)
    setOverdue(overdue)
  }

  useEffect(() => {
    if (!unPaidOrder) {
      getUnpaidOrder()
    }
  }, [order, unPaidOrder])

  useEffect(() => {
    setOrderStep(OrderSteps.Management)
  }, [])

  useEffect(() => {
    console.log('selectedPackages=======>', selectedPackages)
  }, [selectedPackages])

  // 같은 url 재접속 / 새로고침 등이 발생하는 경우 management로 가기
  useEffect(() => {
    if (orderStep !== OrderSteps.Management) {
      setOrderStep(OrderSteps.Management)
    }
  }, [location.key])

  return (
    <>
      {orderStep === OrderSteps.Management && (
        <PaymentManagementPage
          setOrder={setOrder}
          orderUnabled={orderUnabled}
        />
      )}
      {orderStep === OrderSteps.ProductsSelect && (
        <ProductsSelectPage
          successCalback={successCalback}
          setSelectedPackages={setSelectedPackages}
          setSelectedBundles={setSelectedBundles}
        />
      )}
      {orderStep === OrderSteps.ProductsCheck && (
        <ProductsCheckPage
          handler={(order) => {
            // TODO to lhy 이후 처리 해주세요
            if (order) {
              setOrder(order)
              setInitialUnpaidOrder()
            }
          }}
          packages={selectedPackages}
          bundles={selectedBundles}
          purchased={purchased}
          overdue={overdue}
        />
      )}
      {order && <PackagePaymentDialog order={order as UnPaidOrderModel} />}
      {unPaidOrder && <PackagePaymentDialog order={unPaidOrder} />}
    </>
  )
}

export default PaymentManagementPages
