import NewWindow from 'components/NewWindow'
import {WindowFeatures} from 'components/NewWindow/ReactNewWindowClone'
import {
  getThreeDimensionReportInfo,
  getThreeDimensionReportPath,
  ThreeDPopupReportInfo,
} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import {isDefined} from 'helpers/commonHelper'
import React, {createContext, ReactNode, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

const Features: WindowFeatures = {
  width: 800,
  height: 800,
}

interface ThreeDPopupContextType {
  urls: string[]
  handleOpen: ({id, type}: ThreeDPopupReportInfo) => void
}

export const ThreeDPopupContext = createContext<ThreeDPopupContextType>({
  urls: [],
  handleOpen() {},
})

const getTitleFromUrl = (url: string) => {
  const {params} = getThreeDimensionReportInfo(url)
  if (isDefined(params)) {
    const {type, id} = params
    if (type === 'N') {
      return `Normative 3D Report ${id}`
    }

    return `3D Report ${id}`
  }

  return '3D Report'
}

const getNameFromUrl = (url: string) => {
  const {params} = getThreeDimensionReportInfo(url)
  if (isDefined(params)) {
    const {type, id} = params
    if (type === 'N') {
      return `norm_3d_report_${id}`
    }

    return `3d_report_${id}`
  }

  return '3d_report'
}

export const ThreeDPopupProvider = ({children}: {children: ReactNode}) => {
  const {t} = useTranslation()
  const {onOpen: handleOpenFailureModal} = useFailureModal()

  const [urls, setUrls] = useState<string[]>([])

  const windowRefs = useRef({})

  function handleOpen({id, type}: ThreeDPopupReportInfo) {
    const threeDUrl = getThreeDimensionReportPath(id, type)

    if (urls.includes(threeDUrl)) {
      // @ts-ignore
      windowRefs.current[threeDUrl].focus()
    } else {
      setUrls([threeDUrl, ...urls])
    }
  }

  return (
    <ThreeDPopupContext.Provider
      value={{
        urls,
        handleOpen,
      }}
    >
      {children}
      {urls.map((url) => {
        const title = getTitleFromUrl(url)
        const name = getNameFromUrl(url)

        const handleUnload = () => {
          setUrls((urls) => urls.filter((stateUrl) => stateUrl !== url))
        }

        const handleBlock = () => {
          setUrls((urls) => urls.filter((stateUrl) => stateUrl !== url))
          handleOpenFailureModal(t('IAllowPopupPlease'))
        }

        // 확실하지 않지만 title이나 name 둘중에 한 프롭이 같을 경우 기존에 있던 팝업이 닫혀버리는데,
        // 확실한 사항은 html 스펙을 참조해야 한다.
        return (
          <NewWindow
            key={url}
            title={title}
            name={name}
            url={url}
            features={Features}
            onUnload={handleUnload}
            onBlock={handleBlock}
            // @ts-ignore
            // eslint-disable-next-line no-return-assign
            ref={(el) => (windowRefs.current[url] = el)}
          />
        )
      })}
    </ThreeDPopupContext.Provider>
  )
}
