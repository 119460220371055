import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import Home from 'pages/Home'
import OrgRoute from 'pages/OrgPage'
import RedirectPage from 'pages/OrgPage/PaymentManagementPages/Redirect'
import AllComponentImages from 'pages/Popup/AllComponentImagesPopup'
import AllComponentTimeSeriesPopup from 'pages/Popup/AllComponentTimeSeriesPopup'
import PowerSpectrum from 'pages/Popup/PowerSpectrum'
import ThreeDReportPage from 'pages/Popup/ThreeDReport'
import SalesRoute from 'pages/SalesPages'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import EEGMCIReport from 'pages/Analysis/EEGMCI/Report'
import EEGNormReport from 'pages/Analysis/EEGNorm/Report'
import EEGSummaryReport from 'pages/Analysis/EEGSummary/Report'
import HRVIndividualReport from 'pages/Analysis/HRVIndividual/Report'
import HRVSummaryReport from 'pages/Analysis/HRVSummary/Report'
import Summary from 'pages/OrgPage/TreatmentPage/TreatmentSummary'
import AnalysisRoute from 'routes/AnalysisRoute'
import AuthPages from 'routes/AuthRoute'
import CommunityRoute from 'routes/CommunityRoute'
import ErrorPages from 'routes/ErrorRoute'
import MyRoute from 'routes/MyRoute'
import RouteIf from 'routes/RouteIf'
import StoreRoute from 'routes/StoreRoute'
import TherapyRoute from 'routes/TherapyRoute'
import EEGReportPage from 'pages/Analysis/EEGIndividual/EEGreportPage'
import NotFoundPage from 'pages/ErrorPages/NotFoundPage'
import HealthCenterReport from 'pages/HealthCenter/Report'
import EximbayRoute from './EximbayRoute'
import TossRoute from './TossRoute'
import RedirectSuccessPage from '../pages/OrgPage/PaymentManagementPages/RedirectSuccess'
import DisplayRoute from './DisplayRoute'
import ManagementRoute from './ManagerRoute'

const Routes = () => {
  return (
    <Switch>
      <Route path={RouteConstant.ORG_TREATMENTS_SUMMARY.path} exact>
        <Summary />
      </Route>
      <Route path='/org'>
        <OrgRoute />
      </Route>
      <Route path='/auth'>
        <AuthPages />
      </Route>
      <Route path='/community'>
        <CommunityRoute />
      </Route>
      <Route path='/sales'>
        <SalesRoute />
      </Route>
      <Route path={RouteConstant.ANALYSIS_ALL_TIME_SERIES.path}>
        <AllComponentTimeSeriesPopup />
      </Route>
      <Route path={RouteConstant.ANALYSIS_ALL_COMPONENT_IMAGES.path}>
        <AllComponentImages />
      </Route>
      <Route path={RouteConstant.ANALYSIS_POWER_SPECTRUM_CHART.path} exact>
        <PowerSpectrum />
      </Route>
      <Route path={RouteConstant.PACKAGE_PAYMENT.path} exact>
        <TossRoute />
      </Route>
      <RouteIf
        path={RouteConstant.THREE_D_REPORT.path}
        exact
        requiredRoles={AllUserRoles}
      >
        <ThreeDReportPage />
      </RouteIf>
      <Route path={RouteConstant.ANALYSIS_EEG_19_I_R.path} exact>
        <EEGReportPage />
      </Route>
      <Route path={RouteConstant.ANALYSIS_EEG_19_I_I.path} exact>
        <NotFoundPage />
      </Route>
      <Route path={RouteConstant.ANALYSIS_EEG_19_I_T.path} exact>
        <NotFoundPage />
      </Route>
      <Route path={RouteConstant.ANALYSIS_EEG_19_N_R.path} exact>
        <EEGNormReport />
      </Route>
      <Route path={RouteConstant.ANALYSIS_EEG_19_S_R.path} exact>
        <EEGSummaryReport />
      </Route>
      <Route path={RouteConstant.ANALYSIS_EEG_19_M_R.path} exact>
        <EEGMCIReport />
      </Route>
      <Route path={RouteConstant.ANALYSIS_HRV_I_R.path} exact>
        <HRVIndividualReport />
      </Route>
      <Route path={RouteConstant.ANALYSIS_HRV_S_R.path} exact>
        <HRVSummaryReport />
      </Route>
      <Route path={RouteConstant.ANALYSIS_HEALTH_CENTER_REPORT.path} exact>
        <HealthCenterReport />
      </Route>
      <Route path='/analysis'>
        <AnalysisRoute />
      </Route>
      <Route path='/therapy'>
        <TherapyRoute />
      </Route>
      <Route path='/error'>
        <ErrorPages />
      </Route>
      <Route path='/my'>
        <MyRoute />
      </Route>
      <Route path='/store'>
        <StoreRoute />
      </Route>
      <RouteIf
        path='/'
        exact
        component={(props: any) => <Home {...props} />}
        requiredRoles={AllUserRoles}
      />
      <Route path='/eximbay'>
        <EximbayRoute />
      </Route>
      <RouteIf
        path='/auth/register/test'
        exact
        component={RedirectPage}
        requiredRoles={AllUserRoles}
      />
      <Route path='/display'>
        <DisplayRoute />
      </Route>
      <RouteIf
        requiredRoles={AllUserRoles}
        path='/package/payment/redirect/success'
        exact
        component={RedirectSuccessPage}
      />
      <Route path='/manager'>
        <ManagementRoute />
      </Route>
      <RouteIf path='/' exact component={Home} requiredRoles={AllUserRoles} />
      <Route path='*'>
        <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
      </Route>
    </Switch>
  )
}

export default Routes
