/**
 * 병원 정보 관련
 */
export const ORG_INFO_DIALOG_TYPE = 'ORG_INFO'
export const ORG_ADMIN_INFO_DIALOG_TYPE = 'ORG_ADMIN_INFO'

// user property
export const NONE_PROPERTY_TYPE = 'NONE'
export const MOU_PROPERTY_TYPE = 'MOU'
export const COMPANY_PROPERTY_TYPE = 'COMPANY'
export const A_CHARGE_PROPERTY_TYPE = 'A_CHARGE'
export const NO_CHARGE_PROPERTY_TYPE = 'NO_CHARGE'
export const DATA_SHARE_TYPE = 'DATA_SHARE'
export const NON_PROFIT_ORGANIZATION_TYPE = 'NON_PROFIT_ORGANIZATION'

/**
 * 사용자 정보관련
 */
export const MY_INFO_DIALOG_TYPE = 'MY_INFO'
export const USER_INFO_DIALOG_TYPE = 'USER_INFO'
export const ADMIN_INFO_DIALOG_TYPE = 'ADMIN_INFO'

// Custom Confirm 다이얼로그 관련
export const CONFIRM_OK = 'ok'
export const CONFIRM_CANCEL = 'cancel'
export const CONFIRM_CLOSE = 'close'
