import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {NoticeListRequest} from 'api/noticeApi'
import {
  addQnaAnswerApi,
  createQnaApi,
  deleteQnaApi,
  fetchDetailQnaApi,
  fetchQnaListApi,
  fetchQnaTypeListApi,
  QnaAnswerAddRequest,
  QnaCreateRequest,
  QnaEditRequest,
  updateQnaApi,
} from 'api/qnaApi'
import {RootState} from 'store'

export interface QnaListDeleteAction {
  list: number[]
  uid: string
}

export const fetchQnaListAction = createAsyncThunk(
  'api/qna/list',
  async (payload: NoticeListRequest) => {
    const response = await fetchQnaListApi({
      ...payload,
    })

    return response
  },
)

export const fetchQnaDetailInfoAction = createAsyncThunk(
  'api/qna/detail',
  async (payload: number) => {
    const response = await fetchDetailQnaApi(payload)
    return response
  },
)

export const fetchQnaTypeAction = createAsyncThunk('api/qna/type', async () => {
  const response = await fetchQnaTypeListApi()
  return response
})

export const updateQnaAction = createAsyncThunk(
  'api/qna/update',
  async (payload: QnaEditRequest) => {
    const response = await updateQnaApi({
      ...payload,
    })
    return response
  },
)

export const deleteQnaAction = createAsyncThunk(
  'api/qna/delete',
  async (payload: number) => {
    const response = await deleteQnaApi(payload)
    return response.message
  },
)

// TODO: 보강 필요. 실제로 멀티 Delete 안되고 있음
export const deleteQnaFlowAction = createAsyncThunk(
  'qna/delete',
  async (payload: QnaListDeleteAction, {dispatch}) => {
    payload.list.map((id) => {
      dispatch(deleteQnaAction(id)).then((result) => {
        if (result.payload) return true
        if (!result.payload) return false
        return true
      })
      return false
    })
  },
)

export const createQnaAction = createAsyncThunk(
  'api/qna/create',
  async (payload: QnaCreateRequest) => {
    const response = await createQnaApi({
      ...payload,
    })
    return response
  },
)

export const addQnaAnswerAction = createAsyncThunk(
  'api/qna/addAnswer',
  async (payload: QnaAnswerAddRequest) => {
    const response = await addQnaAnswerApi({
      ...payload,
    })
    return response
  },
)

export interface QnaState {
  loading: boolean
  error: any
  qnaList?: QnaContent[] | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  unpaged: boolean
  qnaData?: QnaDetailInfo | null
}

export const initialState: QnaState = {
  loading: false,
  error: null,
  qnaList: [],
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 0,
  qnaData: null,
  unpaged: true,
}

export const qnaSlice = createSlice({
  name: 'qnaSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQnaListAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchQnaListAction.fulfilled, (state, action) => {
      state.loading = false
      state.qnaList = action.payload.data.content
      state.totalPages = action.payload.data.totalPages
      state.totalElements = action.payload.data.totalElements
      state.pageIndex = action.payload.data.pageable.pageNumber
      state.itemPerPage = action.payload.data.pageable.pageSize
      state.unpaged = action.payload.data.pageable.unpaged
    })
    builder.addCase(fetchQnaListAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchQnaDetailInfoAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchQnaDetailInfoAction.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.qnaData = action.payload.data
    })
    builder.addCase(fetchQnaDetailInfoAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default qnaSlice.reducer

export const selectQna = (state: RootState) => state.qna
export const selectLoading = (state: RootState) => state.qna.loading
export const selectError = (state: RootState) => state.qna.error
export const selectItems = (state: RootState) => state.qna.qnaList
export const selectItem = (state: RootState) => state.qna.qnaData
export const selectPaging = (state: RootState) => ({
  page: state.qna.pageIndex,
  size: state.qna.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalElements: state.qna.totalElements,
  totalPages: state.qna.totalPages,
})
