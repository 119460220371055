import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import FilterListIcon from '@material-ui/icons/FilterList'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import PieChartIcon from '@material-ui/icons/PieChart'
import StorageIcon from '@material-ui/icons/Storage'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import React from 'react'
import {useTranslation} from 'react-i18next'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import PackageConstant from 'constants/PackageConstants'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    containerTitle: {
      padding: 10,
      fontWeight: 600,
      color: '#35495D',
      marginLeft: 20,
    },
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      flex: 1,
    },

    searchButton: {
      height: 56,
      marginTop: 15,
    },

    indexInputContainer: {
      marginTop: 6,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    button: {
      height: 55,
      minWidth: 110,
      maxWidth: 110,
    },
    tableWrap: {
      backgroundColor: '#fff',
      border: '1px solid #D6D6D8',
      borderRadius: 5,
      marginTop: 10,
      marginBottom: 30,
      overflow: 'Hidden',
      // margin: 15,
    },
    chipsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      marginLeft: 15,
      marginRight: 15,
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    align: {
      justify: 'center',
    },
    dateSearch: {
      marginLeft: 10,
    },
    dateWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
    },

    descriptionBox: {
      width: 106,
      height: 106,
      display: 'flex',
      flexDirection: 'column',
      padding: 7,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      border: 'solid 3px #f3f3f2',
      backgroundColor: 'white',
      '&:hover': {
        border: 'solid 3px #82d5d6',
      },
    },
    iconContainer: {
      backgroundColor: '#e8f9f9',
      width: 37,
      height: 37,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrap: {
      color: '#3ebbbd',
    },
    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      fontSize: 11,
      textAlign: 'center',
      fontWeight: 600,
      color: '#333333',
    },
    pointCont: {
      display: 'flex',
      fontWeight: 600,
      color: '#3EBBBD',
      textAlign: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'column',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
    },
  }),
)

interface PackageNameProps {
  packageName: string
}

export default function PointUsableRange({packageName}: PackageNameProps) {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <Grid style={{display: 'flex', flexDirection: 'row', gap: 10}}>
      {packageName === PackageConstant.EEG_INDIVIDUAL && (
        <Grid>
          <Tooltip title={String(t('IAnalysisIndividual_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <DeviceHubIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IAnalysisIndividual')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      {packageName === PackageConstant.EEG_NORM && (
        <Grid>
          <Tooltip title={String(t('IAnalysisNorm_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <TrendingUpIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IAnalysisNorm')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      {packageName === PackageConstant.EEG_SUMMARY && (
        <Grid>
          <Tooltip title={String(t('IAnalysisEEGSummary_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <FilterListIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('ISummaryBasic')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      {packageName === PackageConstant.EEG_ADDITIONAL && (
        <Grid style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <Tooltip title={String(t('IROIPower_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <InsertChartIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IROIPower')}
              </Typography>
            </Grid>
          </Tooltip>
          <Tooltip title={String(t('IView3D_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <StorageIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IView3D')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      {packageName === PackageConstant.EEG_MCI && (
        <Grid>
          <Tooltip title={String(t('IAnalysisMCI_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <DynamicFeedIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IAnalysisMCI')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}

      {packageName === PackageConstant.HRV_INDIVIDUAL && (
        <Grid>
          <Tooltip title={String(t('IAnalysisHRVIndividual_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <PieChartIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('IAnalysisHRVIndividual')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      {packageName === PackageConstant.HRV_SUMMARY && (
        <Grid>
          <Tooltip title={String(t('IAnalysisHRVSummary_content'))} arrow>
            <Grid className={classes.descriptionBox}>
              <div className={classes.iconContainer}>
                <AllInboxIcon className={classes.iconWrap} />
              </div>
              <Typography className={classes.smallPadding} />
              <Typography className={classes.pointTitle}>
                {t('ISummaryBasic')}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}
