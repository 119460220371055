import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import DatePicker from 'react-datepicker'
import {useHistory, useLocation} from 'react-router-dom'
import {parse} from 'date-fns'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import RouteConstant from 'constants/RouteConstant'
import {formatDateInvoice} from 'helpers/dateHelper'
import useOrgUser from 'features/org/useOrgUser'
import useFailureModal from 'features/modal/useFailureModal'
import CardWithTitle from 'components/atoms/CardWithTitle'
import AnalysisArea from 'components/molcules/UsageHistory/AnalysisArea'
import AnalysisHealth from 'components/molcules/UsageHistory/AnalysisHealth'
import AppLoading from 'components/atoms/Loading'
import {
  LIST_EEG,
  LIST_HRV,
  LIST_HEALTH,
  DATA_DEFAULT,
  DEFAULT_DATE_SELECT,
  MIN_DATE,
} from 'constants/HistoryConstant'
import AnalysisDate from 'components/molcules/UsageHistory/AnalysisDate'

const headerStyles = () =>
  createStyles({
    card: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    picker: {width: 150},
  })
const useStyles = makeStyles(headerStyles)

const MyUsageHistory = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  const [dataEEG, setDataEEG] = useState<AnalysisHistoryArea>({})
  const [dataHRV, setDataHRV] = useState<AnalysisHistoryArea>({})
  const [dataPBM, setDataPBM] = useState<AnalysisHistoryArea>({})
  const [dataHealth, setDataHealth] = useState<UsageHistory[]>([])
  const {userHistory, onFetchUserHistory, loading} = useOrgUser()
  const location = useLocation()
  const {onOpen: openFailModal} = useFailureModal()

  const queryParams = new URLSearchParams(location.search)
  const usageMonth = queryParams.get('usageMonth') ?? DEFAULT_DATE_SELECT
  const parsedDate = usageMonth
    ? parse(usageMonth, 'dd/MM/yyyy', new Date())
    : new Date()
  const [date, setDate] = useState(parsedDate)
  const history = useHistory()

  const getUserHistory = async () => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const output = `${year}${month}`

    const payload = {
      periodBegin: output,
      periodEnd: output,
    }
    try {
      await onFetchUserHistory(payload)
    } catch (e) {
      openFailModal(e)
    }
  }

  const onChangeDate = async (dateInput: Date | null) => {
    if (!dateInput) return
    setDate(dateInput)
    history.replace(
      `${RouteConstant.MY_USAGE_HISTORY.path}?usageMonth=${formatDateInvoice(
        dateInput,
      )}`,
    )
  }

  const fillData = (
    dataUsage: UsageHistory[],
    dataFill: CommonUsageHistory[],
  ) => {
    const usagesMap = new Map(dataUsage.map((j: UsageHistory) => [j.type, j]))
    return dataFill.reduce((acc: UsageHistory[], i: CommonUsageHistory) => {
      const matchingUsage = usagesMap.get(i.key)
      if (matchingUsage) {
        acc.push({...i, ...matchingUsage})
      }
      return acc
    }, [])
  }

  const getItemTotal = (dataUsage: UsageHistory[], key: string) => {
    return dataUsage.find((item: UsageHistory) => item.type === key)
  }

  useEffect(() => {
    getUserHistory()
  }, [date])

  useEffect(() => {
    const listDataEEG = fillData(userHistory?.usages ?? DATA_DEFAULT, LIST_EEG)
    const totalEEG = getItemTotal(userHistory?.usages ?? DATA_DEFAULT, 'eeg')
    setDataEEG({itemTotal: totalEEG, listData: listDataEEG})

    const listDataHRV = fillData(userHistory?.usages ?? DATA_DEFAULT, LIST_HRV)
    const totalHRV = getItemTotal(userHistory?.usages ?? DATA_DEFAULT, 'hrv')
    setDataHRV({itemTotal: totalHRV, listData: listDataHRV})

    const totalPBM = getItemTotal(userHistory?.usages ?? DATA_DEFAULT, 'pbm')
    const listDataPBM: [] = []
    setDataPBM({itemTotal: totalPBM, listData: listDataPBM})

    const listDataHealth = fillData(
      userHistory?.usages ?? DATA_DEFAULT,
      LIST_HEALTH,
    )

    setDataHealth([...listDataHealth])
  }, [userHistory])

  return (
    <>
      <AppLoading loading={loading} />
      <CardWithTitle title={t('IMyUsageHistory')}>
        <div className={classes.card}>
          <div className={classes.picker}>
            <DatePicker
              selected={date}
              onChange={(date) => onChangeDate(date)}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              maxDate={new Date()}
              minDate={new Date(MIN_DATE)}
            />
          </div>
          <div>
            <AnalysisDate />
          </div>
        </div>
        {!loading && (
          <Fragment>
            {(dataEEG.itemTotal || dataHRV.itemTotal || dataPBM.itemTotal) && (
              <AnalysisArea
                dataEEG={dataEEG}
                dataHRV={dataHRV}
                dataPBM={dataPBM}
              />
            )}
            {dataHealth.length > 0 && (
              <AnalysisHealth dataHealth={dataHealth} />
            )}
          </Fragment>
        )}
      </CardWithTitle>
    </>
  )
}

export default MyUsageHistory
