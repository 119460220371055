import {TFunction} from 'react-i18next'
import {PackageBillingCycle} from 'api/paymentApi'
import {
  SelectItemsWithIcon,
  SpreadableWithIcon,
  SpreadItemWithIcon,
} from 'components/V2Select/IconSelect/IconSelect'
import PackageSubscriptionModel from './PackageSubscriptionModel'

type SpreadType = 'PERIOD' | 'CYCLE' | 'AMOUNT'
export type {SpreadType}

/**
 * billingCycle: 결제 1회의 유효한 기간(결제 주기)을 의미함 (한글로 기간으로 번역한다.)
 * subscriptions.period: 결제 반복을 의미한다(결제를 billingCycle개월 마다 subscriptions.period회 반복)
 * ex billingCycle: 12  / subscriptions.period: 2 일 때 12개월에 1번씩 결제하고 총 2회 결제한다.(총 24개월)
 */
export default class PackageBillingCycleModel
  implements SpreadableWithIcon, SpreadItemWithIcon
{
  t: TFunction

  billingCycle: number

  subscriptions: [PackageSubscriptionModel]

  isSelected = false

  constructor(raw: PackageBillingCycle, t: TFunction) {
    this.t = t

    this.billingCycle = raw.billingCycle
    this.subscriptions = raw.subscriptions.map((item) => {
      return new PackageSubscriptionModel(item, t)
    }) as [PackageSubscriptionModel]
  }

  spread = (): SelectItemsWithIcon[] => {
    return this.subscriptions.map((item) => {
      return item.appearanceValueWithIcon()
    }) as SelectItemsWithIcon[]
  }

  // billingCycle - 기간
  appearanceValueWithIcon = () => {
    return {
      iconName: 'calendarStroke',
      optionText: this.t(this.billingCycle === 1 ? 'INMonth' : 'INMonths', {
        number: this.billingCycle,
      }),
    }
  }
}
