import React from 'react'
import styled from 'styled-components'
import Colors from 'theme/Colors'
import Media from 'theme/Media'
import Size from 'theme/Size'
import Weight from 'theme/Weight'
import ModalBody from './ModalBody/ModalBody'
import ModalFooter from './ModalFooter/ModalFooter'
import ModalHeader from './ModalHeader/ModalHeader'
import ModalHeaderOneRow from './ModalHeader/ModalHeaderOneRow'
import {
  DimmedWrapStyleProps,
  ModalAllStyleProps,
  ModalTypes,
  ModalWrapStyleProps,
} from './Styles'

interface OpenCloseProps {
  open: boolean
  onClose: () => any
}

interface ModalProps {
  open: boolean
  body: JSX.Element
  footer?: JSX.Element
  theme: ModalAllStyleProps
  handleClose: () => any
  modalTitle?: string
  modalZIndex?: number
  modalType?: ModalTypes
  style?: React.CSSProperties
  headerType?: string
}

const StyledModalWrap = styled.div<ModalWrapStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.fixMaxWidth}px;
  max-height: 100%;
  background-color: ${(props) => props.bgcolor || '#fff'};
  border: ${(props) => props.borderColor};
  box-shadow: 7px 8px 39px rgba(48, 68, 86, 0.1);
`

const StyledDimmedWrap = styled.div<
  DimmedWrapStyleProps & OpenCloseProps & {modalZIndex?: number}
>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(11, 11, 11, 0.5);
  padding-top: ${(props) => props.dimPaddingTopMobile}px;
  padding-right: ${(props) => props.dimPaddingRightMobile}px;
  padding-bottom: ${(props) => props.dimPaddingBottomMobile}px;
  padding-left: ${(props) => props.dimPaddingLeftMobile}px;

  ${(props) => Media.min_601`
    padding-top: ${props.dimPaddingTopTablet}px;
    padding-right: ${props.dimPaddingRightTablet}px;
    padding-bottom: ${props.dimPaddingBottomTablet}px;
    padding-left: ${props.dimPaddingLeftTablet}px;
  `}

  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 200ms ease-out;

  z-index: ${(props) => props.modalZIndex ?? 1300};
`

// for state modal
export const StyledBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
`
export const StyledStateIconWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 60px;
    height: 60px;
  }
`
export const StyledTitleText = styled.span`
  color: ${Colors.common.blacks.b_01};
  font-size: ${Size.font.heading_03}px;
  font-weight: ${Weight.extra_bolder};
  white-space: pre-line;
  text-align: center;
`
export const StyledMessageText = styled.span`
  color: ${Colors.common.blacks.b_01};
  font-size: ${Size.font.x_large}px;
  font-weight: ${Weight.semi_bolder};
  white-space: pre-line;
  text-align: center;
`
export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`

function Modal({
  open,
  modalTitle,
  handleClose,
  body,
  footer,
  theme,
  modalZIndex,
  modalType,
  style,
  headerType,
}: ModalProps) {
  return (
    <StyledDimmedWrap
      dimPaddingTopTablet={theme.dimPaddingTopTablet}
      dimPaddingRightTablet={theme.dimPaddingRightTablet}
      dimPaddingBottomTablet={theme.dimPaddingBottomTablet}
      dimPaddingLeftTablet={theme.dimPaddingLeftTablet}
      dimPaddingTopMobile={theme.dimPaddingTopMobile}
      dimPaddingRightMobile={theme.dimPaddingRightMobile}
      dimPaddingBottomMobile={theme.dimPaddingBottomMobile}
      dimPaddingLeftMobile={theme.dimPaddingLeftMobile}
      modalZIndex={modalZIndex}
      open={open}
      onClose={handleClose}
      style={style}
    >
      <StyledModalWrap
        fixMaxWidth={theme.fixMaxWidth}
        borderColor={theme.borderColor}
        bgcolor={theme.bgcolor}
        // fixMaxHeight={theme.fixMaxHeight}
      >
        {modalType !== ModalTypes.state &&
          (headerType === 'ONE_ROW' ? (
            <ModalHeaderOneRow
              title={modalTitle ?? ''}
              handleClose={handleClose}
              theme={theme}
            />
          ) : (
            <ModalHeader
              title={modalTitle ?? ''}
              handleClose={handleClose}
              theme={theme}
            />
          ))}
        <ModalBody theme={theme} modalType={modalType}>
          {body}
        </ModalBody>
        <ModalFooter theme={theme}>{footer}</ModalFooter>
      </StyledModalWrap>
    </StyledDimmedWrap>
  )
}

export default Modal
