import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '@material-ui/core'
import OrganizationNameInput from '../Form/OrganizationNameInput'
import UserNameInput from '../Form/UserNameInput'
import EmailInput from '../Form/EmailInput'
import PasswordInput from '../Form/PasswordInput'
import SelectedCountry from '../Form/SelectedCountry'
import TelephoneNumberInput from '../Form/TelephoneNumberInput'
import SelectedIndustry from '../Form/SelectedIndustry'
import BusinessNumberInput from '../Form/BusinessNumberInput'
import NursingInstitutionInput from '../Form/NursingInstitutionInput'
import {ButtonContainer, UserFormContainer} from '../../style'

const UserForm = () => {
  const {t} = useTranslation()

  return (
    <UserFormContainer>
      <OrganizationNameInput />
      <UserNameInput />
      <EmailInput
        // TODO implement API
        onDuplicateChecked={() => console.log('onDuplicateChecked')}
        requestType='J'
        onSetUuid={(data) => console.log(data)}
      />
      <PasswordInput />
      <SelectedCountry />
      <TelephoneNumberInput />
      <SelectedIndustry />
      <BusinessNumberInput />
      <NursingInstitutionInput />
      <ButtonContainer>
        <Button
          variant='contained'
          color='default'
          disableElevation
          onClick={() => {}}
        >
          {t('ICancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          type='submit'
        >
          {t('IRegister')}
        </Button>
      </ButtonContainer>
    </UserFormContainer>
  )
}

export default UserForm
