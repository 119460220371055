import {Divider} from '@material-ui/core'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import ReportSubtitle from 'components/Text/ReportSubtitle'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface MciProps {
  MciImagePath: string
  probability: number
  clientFirstName: string
  clientLastName: string
}

export default function MCIReportPage({
  MciImagePath,
  probability,
  clientFirstName,
  clientLastName,
}: MciProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const {i18n} = useTranslation()
  const clientFullName = (firstName: string, lastName: string): string => {
    const name = t('IGetFullName', {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    }).trim()
    return name
  }

  const getOpinion = (percent: number) => {
    if (percent < 50) return 'IMciAnalysisOpinionTitleLevel1'
    if (percent > 50 && percent < 60) return 'IMciAnalysisOpinionTitleLevel2'
    if (percent > 60 && percent < 70) return 'IMciAnalysisOpinionTitleLevel3'
    if (percent > 70 && percent < 85) return 'IMciAnalysisOpinionTitleLevel4'
    if (percent > 85) return 'IMciAnalysisOpinionTitleLevel5'
    return 'IMciAnalysisOpinionTitleLevel1'
  }

  const getOpinionContent = (percent: number) => {
    if (percent < 50) return 'IMciAnalysisOpinionDescriptionLevel1'
    if (percent > 50 && percent < 60)
      return 'IMciAnalysisOpinionDescriptionLevel2'
    if (percent > 60 && percent < 70)
      return 'IMciAnalysisOpinionDescriptionLevel3'
    if (percent > 70 && percent < 85)
      return 'IMciAnalysisOpinionDescriptionLevel4'
    if (percent > 85) return 'IMciAnalysisOpinionDescriptionLevel5'
    return 'IMciAnalysisOpinionDescriptionLevel1'
  }

  return (
    <div className={classes.mci}>
      <br />
      <ReportSubtitle className='ReportTitle' number='1'>
        {t('ISyncBrainMAnalysis')}
      </ReportSubtitle>
      <p className='titleDescription' style={{color: '#4D5D6B'}}>
        {t('IMciDescription')}
      </p>
      <img
        src={MciImagePath}
        alt='mci report'
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      <p className='contentTitle' style={{color: '#4D5D6B'}}>
        {t(getOpinion(probability), {
          percent: probability,
          name: clientFullName(clientFirstName, clientLastName),
        })}
      </p>

      <p className='contentDescription' style={{color: '#4D5D6B'}}>
        {t(getOpinionContent(probability))}
      </p>

      <ReportSubtitle
        className='ReportTitle'
        style={{
          padding: '10px 5px 10px 10px',
        }}
        number='2'
      >
        {t('ISyncBrainMGuide')}
      </ReportSubtitle>
      <Divider style={{marginRight: 10, marginLeft: 10}} />
      <div className='guideTable'>
        <div className='guideCell'>
          <div className='round' style={{backgroundColor: '#15B785'}}>
            <div style={{margin: '0 0 0 8px'}}>stage</div>
            <div style={{fontSize: 30, fontWeight: 'bold'}}>1</div>
          </div>
          <div className='guideRoundTitle'>
            <div className='guideContentPercent' style={{color: '#15B785'}}>
              ~ 50%
            </div>
            <div
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 12,
                fontSize: 17,
              }}
            >
              {t('ISyncBrainMGuideStage1')}
            </div>
          </div>

          <div className='guideContent'>
            {i18n.language !== 'en' && (
              <div className='guideContentBold'>
                {t('ISyncBrainMGuideStage1Description')}
              </div>
            )}
            <div>
              {t('ISyncBrainMGuideStage1DescriptionText', {
                name: clientFullName(clientFirstName, clientLastName),
              })}
            </div>
          </div>
          <div>
            {i18n.language === 'en' && (
              <div
                style={{fontSize: 12, fontWeight: 600, padding: '0 0 10px 0 '}}
              >
                Physical exam needed in
              </div>
            )}
            <div className='guideButton' style={{backgroundColor: '#15B785'}}>
              <p>{t('ISyncBrainMGuideStage1InspectionTime')}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider style={{marginRight: 10, marginLeft: 10}} />
      <div className='guideTable'>
        <div className='guideCell'>
          <div className='round' style={{backgroundColor: '#85ce74'}}>
            <div style={{margin: '0 0 0 8px'}}>stage</div>
            <div style={{fontSize: 30, fontWeight: 'bold'}}>2</div>
          </div>
          <div className='guideRoundTitle'>
            <div className='guideContentPercent' style={{color: '#85ce74'}}>
              50~60%
            </div>
            <div
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 12,
                fontSize: 17,
              }}
            >
              {t('ISyncBrainMGuideStage2')}
            </div>
          </div>
          <div className='guideContent'>
            <div className='guideContent'>
              {t('ISyncBrainMGuideStage2Description')}
            </div>
          </div>
          <div>
            {i18n.language === 'en' && (
              <div
                style={{fontSize: 12, fontWeight: 600, padding: '0 0 10px 0 '}}
              >
                Physical exam needed in
              </div>
            )}
            <div className='guideButton' style={{backgroundColor: '#85ce74'}}>
              <p>{t('ISyncBrainMGuideStage2InspectionTime')}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider style={{marginRight: 10, marginLeft: 10}} />
      <div className='guideTable'>
        <div className='guideCell'>
          <div className='round' style={{backgroundColor: '#ffd752'}}>
            <div style={{margin: '0 0 0 8px'}}>stage</div>
            <div style={{fontSize: 30, fontWeight: 'bold'}}>3</div>
          </div>
          <div className='guideRoundTitle'>
            <div className='guideContentPercent' style={{color: '#ffd752'}}>
              60~70%
            </div>
            <div
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 12,
                fontSize: 17,
              }}
            >
              {t('ISyncBrainMGuideStage3')}
            </div>
          </div>
          <div className='guideContent'>
            <div className='guideContent'>
              {t('ISyncBrainMGuideStage3Description')}
            </div>
          </div>
          <div>
            {i18n.language === 'en' && (
              <div
                style={{fontSize: 12, fontWeight: 600, padding: '0 0 10px 0 '}}
              >
                Physical exam needed in
              </div>
            )}
            <div className='guideButton' style={{backgroundColor: '#ffd752'}}>
              <p>{t('ISyncBrainMGuideStage3InspectionTime')}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider style={{marginRight: 10, marginLeft: 10}} />
      <div className='guideTable'>
        <div className='guideCell'>
          <div className='round' style={{backgroundColor: '#fd7a41'}}>
            <div style={{margin: '0 0 0 8px'}}>stage</div>
            <div style={{fontSize: 30, fontWeight: 'bold'}}>4</div>
          </div>
          <div className='guideRoundTitle'>
            <div className='guideContentPercent' style={{color: '#fd7a41'}}>
              70~85%
            </div>
            <div
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 10,
                fontSize: 17,
              }}
            >
              {t('ISyncBrainMGuideStage4')}
            </div>
          </div>
          <div className='guideContent'>
            <div className='guideContent'>
              {t('ISyncBrainMGuideStage4Description')}
            </div>
          </div>
          <div>
            {i18n.language === 'en' && (
              <div
                style={{fontSize: 12, fontWeight: 600, padding: '0 0 10px 0 '}}
              >
                Physical exam needed in
              </div>
            )}
            <div className='guideButton' style={{backgroundColor: '#fd7a41'}}>
              <p>{t('ISyncBrainMGuideStage4InspectionTime')}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider style={{marginRight: 10, marginLeft: 10}} />
      <div className='guideTable'>
        <div className='guideCell'>
          <div className='round' style={{backgroundColor: '#f24740'}}>
            <div style={{margin: '0 0 0 8px'}}>stage</div>
            <div style={{fontSize: 30, fontWeight: 'bold'}}>5</div>
          </div>
          <div className='guideRoundTitle'>
            <div className='guideContentPercent' style={{color: '#f24740'}}>
              85%~
            </div>
            <div
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 12,
                fontSize: 17,
              }}
            >
              {t('ISyncBrainMGuideStage5')}
            </div>
          </div>
          <div className='guideContent'>
            <div className='guideContent'>
              {t('ISyncBrainMGuideStage5Description')}
            </div>
          </div>
          <div>
            {i18n.language === 'en' && (
              <div
                style={{fontSize: 12, fontWeight: 600, padding: '0 0 10px 0 '}}
              >
                Physical exam needed in
              </div>
            )}
            <div className='guideButton' style={{backgroundColor: '#f24740'}}>
              <p>{t('ISyncBrainMGuideStage5InspectionTime')}</p>
            </div>
          </div>
        </div>
      </div>
      <Divider style={{marginRight: 10, marginLeft: 10}} />
    </div>
  )
}
