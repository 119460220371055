import React from 'react'
import {Route, Switch} from 'react-router-dom'
import EximbayResponses from 'pages/EximbayPage/EximbayResponses'

const EximbayRoute = () => (
  <Switch>
    <Route path='/eximbay/response' component={EximbayResponses} />
  </Switch>
)

export default EximbayRoute
