import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {getLanguage} from 'api/storageApi'
import {styles} from 'components/Dialog/findFormStyle'

const useStyles = makeStyles(() => ({
  ...styles(),
}))

export default function OrganizationInput() {
  const {
    formState: {errors},
  } = useFormContext()

  const findIdData = useFormContext()

  const classes = useStyles()
  const {t} = useTranslation()
  const currentLanguage = getLanguage()

  const orgNameClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.orgName !== undefined,
  })

  const orgTelClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.tel !== undefined,
  })
  const orgUserNameClasses = classNames({
    Right: true,
    nameInput: true,
    Error: errors.tel !== undefined,
  })

  const firstNameComponent = (
    <input
      id='input_orgTel'
      className={orgUserNameClasses}
      placeholder={t('IFirstName')}
      {...findIdData.register('firstName', {required: 'IRequired'})}
      defaultValue=''
    />
  )

  const lastNameComponent = (
    <input
      id='input_orgTel'
      className={orgUserNameClasses}
      placeholder={t('ILastName')}
      {...findIdData.register('lastName', {
        required: 'IRequired',
      })}
      defaultValue=''
    />
  )
  return (
    <div className={classes.root}>
      <label htmlFor='input_orgName' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IFindIdOrgName')}</span>
          <input
            id='input_orgName'
            className={orgNameClasses}
            placeholder={t('IFindIdOrgName')}
            {...findIdData.register('orgName')}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
        </div>
      </label>

      <label htmlFor='input_orgTel' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IName')}</span>
          {currentLanguage === 'ko' ? (
            <>
              {lastNameComponent}
              {firstNameComponent}
            </>
          ) : (
            <>
              {firstNameComponent}
              {lastNameComponent}
            </>
          )}
        </div>
        <div className='Container'>
          <div className='Left' />
          {/* <span className='Right ErrorText'>{t(`${errors.tel?.message}`)}</span> */}
        </div>
      </label>
      <label htmlFor='input_orgTel' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('ITel')}</span>
          <input
            id='input_orgTel'
            className={orgTelClasses}
            placeholder={t('ITel')}
            {...findIdData.register('tel', {
              required: 'ITelRequired',
            })}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
        </div>
      </label>
    </div>
  )
}
