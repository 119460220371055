import InvoicedYearMonthItemsModel from './InvoicedYearMonthItemsModel'

export default class InvoicedYearMonthModel {
  constructor(raw: InvoicedYearMonthModel) {
    this.items = raw.items.map((item) => {
      return new InvoicedYearMonthItemsModel(item)
    })
    this.total = raw.total
    this.size = raw.size
    this.page = raw.page
    this.selectedIndex = 0
  }

  items: InvoicedYearMonthItemsModel[]

  total: number

  page: number

  size: number

  selectedIndex: number

  appearanceValue = (): string => {
    return this.items[this.selectedIndex].appearanceValue()
  }

  spread = (): [string] => {
    return this.items.map((item) => {
      return item.appearanceValue()
    }) as [string]
  }
}
