import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import {whiteColor} from 'assets/tss/material-dashboard-pro-react'

const pagesStyle = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: 'auto',
      minHeight: '100vh',
      position: 'relative',
      top: '0',
    },
    fullPage: {
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '120px 0',
      },
      position: 'relative',
      minHeight: '100vh',
      display: 'flex !important',
      margin: '0',
      border: '0',
      color: whiteColor,
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'min-content',
      },
      backgroundColor: '#f4f4f7',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      height: '100%',
      // [theme.breakpoints.down('sm')]: {
      //   minHeight: '700px!important',
      // },
      '& footer': {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        border: 'none !important',
      },
      '&:before': {
        // backgroundColor: `rgba(${hexToRgb(blackColor)}, 0.65)`,
      },
      '&:before,&:after': {
        // display: 'block',
        // content: '""',
        // position: 'absolute',
        // width: '100%',
        // height: '100%',
        // top: '0',
        // left: '0',
        // zIndex: '2',
      },
    },
  })

export default pagesStyle
