import {yupResolver} from '@hookform/resolvers/yup'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {navigate} from 'AppHistory'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import useAuth from 'features/auth/useAuth'
import {
  closeDepartmentEditDialog,
  selectDepartment,
  selectDepartmentEditDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useOrgDepartment from 'features/org/useOrgDepartment'
import {isNotDefined} from 'helpers/commonHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

const styles = () =>
  createStyles({
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 15,
    },
    inputLabel: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 2px',
      minWidth: 120,
      maxWidth: 100,
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
      background: 'white',
      '& button': {
        flex: 1,
      },
      gap: '.5rem',
    },
  })
const useStyles = makeStyles(styles)

interface FormData {
  title: string
}

function EditDialog() {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const {user} = useAuth()
  const {onUpdate} = useOrgDepartment()
  const department = useAppSelector(selectDepartment)
  const isOpen = useAppSelector(selectDepartmentEditDialogOpen)
  const {onOpen} = useFailureModal()

  const closeDialog = () => dispatch(closeDepartmentEditDialog())

  const EditSchema = yup.object().shape({
    title: yup
      .string()
      .min(2, t('IBetween2to50_2_max_50'))
      .max(50, t('IBetween2to50_2_max_50')),
  })

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
  } = useForm<FormData>({
    // @ts-ignore
    resolver: yupResolver(EditSchema),
  })

  const onSubmit = (data: FormData) => {
    if (isNotDefined(user)) {
      navigate('/auth/selectAccount')
      return
    }

    if (isNotDefined(department)) {
      onOpen(t('IErrorItemNotFound'))
      return
    }

    onUpdate({
      id: department.id,
      uid: user.uid,
      ...data,
    }).then(() => reset())
  }

  useEffect(() => {
    if (isOpen) {
      setValue('title', department?.name ?? '')
    } else {
      reset()
    }
  }, [isOpen, department])

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('IDepartmentUpdate')}</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.inputWrapper}>
            <Typography color='error'>*</Typography>
            <Typography className={classes.inputLabel}>
              {t('IDepartmentName')}
            </Typography>
            <Controller
              name='title'
              control={control}
              render={({field}) => (
                <TextField
                  id='title'
                  label={t('IDepartmentName')}
                  fullWidth
                  variant='outlined'
                  required
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </div>
        </DialogContent>
        <div className={classes.buttonsContainer}>
          <ConfirmButton type='submit' onClick={closeDialog} />
          <CancelButton onClick={closeDialog} />
        </div>
      </form>
    </Dialog>
  )
}

export default EditDialog
