import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchOrgDepartmentApi} from 'api/organizationApi'
import {
  createDepartmentApi,
  deleteDepartmentMultiApi,
  OrgDepartmentCreateRequest,
  OrgDepartmentUpdateRequest,
  updateDepartmentApi,
} from 'api/orgApi'
import {RootState} from 'store'

export const fetchDepartmentListAction = createAsyncThunk(
  'api/org/department/list',
  async () => {
    const response = await fetchOrgDepartmentApi()
    return response.list
  },
)

export const fetchDepartmentCreateAction = createAsyncThunk(
  'api/org/department/create',
  async (payload: OrgDepartmentCreateRequest) => {
    const response = await createDepartmentApi(payload)
    return response.data
  },
)

export const fetchDepartmentUpdateAction = createAsyncThunk(
  'api/org/department/update',
  async (payload: OrgDepartmentUpdateRequest) => {
    const response = await updateDepartmentApi(payload)
    return response.data
  },
)

export const fetchDepartmentDeleteAction = createAsyncThunk(
  'api/org/department/delete',
  async (payload: number[], {dispatch}) => {
    const result = await deleteDepartmentMultiApi(payload)
    dispatch(fetchDepartmentListAction()).unwrap()
    return result
  },
)

interface OrgDepartmentState {
  departments: Department[]
  loading: boolean
  error: unknown
}

const initialState: OrgDepartmentState = {
  departments: [],
  loading: false,
  error: undefined,
}

export const orgDepartmentSlice = createSlice({
  name: 'org_department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartmentListAction.pending, (state) => {
      state.loading = false
      state.error = undefined
      state.departments = []
    })
    builder.addCase(fetchDepartmentListAction.fulfilled, (state, action) => {
      state.loading = false
      state.departments = action.payload
    })
    builder.addCase(fetchDepartmentListAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchDepartmentCreateAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchDepartmentCreateAction.fulfilled, (state, action) => {
      state.loading = false
      state.departments = [action.payload, ...state.departments]
    })
    builder.addCase(fetchDepartmentCreateAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchDepartmentUpdateAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchDepartmentUpdateAction.fulfilled, (state, action) => {
      state.loading = false
      state.departments = state.departments.map((d) => {
        if (d.id !== action.payload.id) {
          return d
        }
        return {...action.payload}
      })
    })
    builder.addCase(fetchDepartmentUpdateAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchDepartmentDeleteAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchDepartmentDeleteAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(fetchDepartmentDeleteAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default orgDepartmentSlice.reducer

export const selectOrgDepartmentList = (state: RootState) =>
  state.orgDepartment.departments
export const selectOrgDepartmentLoading = (state: RootState) =>
  state.orgDepartment.loading
export const selectOrgDepartmentError = (state: RootState) =>
  state.orgDepartment.loading
