import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import SummaryTable from 'components/molcules/ProfitSummaryReport/SummaryTable'
import React from 'react'

import GasPhaseChart1 from 'assets/img/img_gas_phase_01.png'
import GasPhaseChart2 from 'assets/img/img_gas_phase_02.png'
import GasPhaseChart3 from 'assets/img/img_gas_phase_03.png'
import GasPhaseChart4 from 'assets/img/img_gas_phase_04.png'
import GasPhaseChart5 from 'assets/img/img_gas_phase_05.png'
import GasPhaseChartEn1 from 'assets/img/img_gas_phase_en_01.png'
import GasPhaseChartEn2 from 'assets/img/img_gas_phase_en_02.png'
import GasPhaseChartEn3 from 'assets/img/img_gas_phase_en_03.png'
import GasPhaseChartEn4 from 'assets/img/img_gas_phase_en_04.png'
import GasPhaseChartEn5 from 'assets/img/img_gas_phase_en_05.png'

import {Typography} from '@material-ui/core'
import ReportSubtitle from 'components/Text/ReportSubtitle'

interface HeadCell {
  id: string
  label: string
}

const GasPhaseChartList = [
  GasPhaseChart1,
  GasPhaseChart2,
  GasPhaseChart3,
  GasPhaseChart4,
  GasPhaseChart5,
]
const GasPhaseChartEnList = [
  GasPhaseChartEn1,
  GasPhaseChartEn2,
  GasPhaseChartEn3,
  GasPhaseChartEn4,
  GasPhaseChartEn5,
]

const getGasPhasePosition = (phase: number) => {
  if (phase < 1 || phase > 5) return '-100%'
  if (phase === 1) return '11.862%'
  if (phase === 2) return '31.367%'
  if (phase === 3) return '50.903%'
  if (phase === 4) return '70.435%'
  if (phase === 5) return '90.103%'
  return '-100%'
}

const hrvStressItem: HeadCell[] = [
  {id: 'IGasPhaseLevel1', label: 'IGasPhaseLevel1Description'},
  {id: 'IGasPhaseLevel2', label: 'IGasPhaseLevel2Description'},
  {id: 'IGasPhaseLevel3', label: 'IGasPhaseLevel3Description'},
  {id: 'IGasPhaseLevel4', label: 'IGasPhaseLevel4Description'},
  {id: 'IGasPhaseLevel5', label: 'IGasPhaseLevel5Description'},
]

export default function HRVSummaryStress(props: {phase: number}) {
  const {phase} = props
  const classes = useStyles()
  const {t} = useTranslation()
  const {i18n} = useTranslation()

  return (
    <div className={classes.hrvStress}>
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <ReportSubtitle number='4'>
        {t('IHRVSummaryReportAIStressTitle')}
      </ReportSubtitle>

      <div className='Wrapper'>
        {i18n.language === 'ko' && (
          <img
            className='PhaseChart'
            src={GasPhaseChartList[phase - 1]}
            alt='Gas Phase chart'
          />
        )}
        {i18n.language !== 'ko' && (
          <img
            className='PhaseChart'
            src={GasPhaseChartEnList[phase - 1]}
            alt='Gas Phase chart'
          />
        )}

        <span
          className='PhaseChart-Label'
          style={{left: getGasPhasePosition(phase)}}
        >
          {t('ICurrentStatus')}
        </span>
      </div>
      <Typography className={classes.padding} />
      <div className={classes.description}>
        {t('IHRVSummaryReportAIStressDescription')}
      </div>
      <Typography className={classes.padding} />
      <SummaryTable
        items={hrvStressItem}
        headerTitle={t('IHRVSummaryStressTitle')}
      />
    </div>
  )
}
