import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AllPackages, fetchSalesEnabledPackageApi} from 'api/paymentApi'
import {
  fetchInstituteListApi,
  FetchListResponse,
  fetchPeriodUsageListApi,
  FetchPeriodUsageListRequest,
  FetchPersonalListRequest,
  FetchPersonalListResponse,
  fetchPersonalUsageListApi,
  fetchSalesDetailSearchApi,
  fetchSalesIndexSearchApi,
  InstituteListRequest,
  SalesDetailSearchRequest,
  SalesIndexSearchRequest,
  SalesUserUpdateRequest,
  updateOrgUserDetailInfoApi,
  updateSalesOrganizationApi,
} from 'api/salesApi'
import {
  OrgUserSearchRequest,
  OrgUserUpdateRequest,
  searchUserApi,
  updateUserDetailInfoApi,
} from 'api/userApi'
import {RootState} from 'store'
import {OrgDetailInfoEditRequest} from '../../api/organizationApi'

interface InstituteList {
  items: Institutes[]
  page: number
  size: number
  total: number
}

interface Institutes {
  oid: string
  name: string
  chiefFullName: string
  tel: string
  createdAt: string
  updatedAt: string
  property: string
  countyName: string
  chiefEmail: string
}

export interface SalesState {
  loading: boolean
  searchIndexList: SalesIndexSearchedData[] | null
  searchedData: SalesIndexSearchedData
  searchedDetailData: SalesDetailSearch | null
  searchUserList: SearchedUser[] | null
  searchInstitutes: InstituteList | null
  organizationPackages: AllPackages[] | null
  periodUsageList: FetchListResponse | null
  personalUsageList: FetchPersonalListResponse | null
  error: any
}

export const initialState: SalesState = {
  loading: false,
  error: null,
  searchIndexList: [],
  searchedData: {
    name: '',
    oid: '',
  },
  searchedDetailData: null,
  searchUserList: [],
  searchInstitutes: null,
  organizationPackages: [],
  periodUsageList: null,
  personalUsageList: null,
}

export const fetchSalesIndexSearchAction = createAsyncThunk(
  'api/manager/org/list',
  async (payload: SalesIndexSearchRequest) => {
    const response = await fetchSalesIndexSearchApi(payload)
    return response.list
  },
)

export const fetchSalesDetailAction = createAsyncThunk(
  'api/manager/org/detail',
  async (payload: SalesDetailSearchRequest) => {
    const response = await fetchSalesDetailSearchApi(payload)
    return response
  },
)

export const updateSalesOrganizationAction = createAsyncThunk(
  'api/manager/org/update',
  async (payload: OrgDetailInfoEditRequest) => {
    const response = await updateSalesOrganizationApi(payload)
    return response
  },
)

export const updateSalesUserAction = createAsyncThunk(
  'api/manager/org/update/update',
  async (payload: SalesUserUpdateRequest) => {
    const response = await updateOrgUserDetailInfoApi(payload)
    return response
  },
)

export const searchSalesOrgUserAction = createAsyncThunk(
  'api/org/user/list',
  async (payload: OrgUserSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchUserApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateSalesOrganizationUserAction = createAsyncThunk(
  'api/manager/org/user/update',
  async (payload: OrgUserUpdateRequest, {rejectWithValue}) => {
    try {
      const response = await updateUserDetailInfoApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const searchSalesInstituteAction = createAsyncThunk(
  'api/institute/list',
  async (payload: InstituteListRequest, {rejectWithValue}) => {
    try {
      const response = await fetchInstituteListApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchOrganizationPackageAction = createAsyncThunk(
  'api/organization/package',
  async (payload: string, {rejectWithValue}) => {
    try {
      const response = await fetchSalesEnabledPackageApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchUsageAction = createAsyncThunk(
  'api/sales/useage/period/list',
  async (payload: FetchPeriodUsageListRequest, {rejectWithValue}) => {
    try {
      const response = await fetchPeriodUsageListApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const fetchPersonalUsageAction = createAsyncThunk(
  'api/sales/useage/personal/list',
  async (payload: FetchPersonalListRequest, {rejectWithValue}) => {
    try {
      const response = await fetchPersonalUsageListApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    searchSalesRegisteredUserRequest(state: SalesState) {
      state.loading = true
      state.error = null
    },
    searchSalesRegisteredUserSuccess(
      state: SalesState,
      action: PayloadAction<ModelResponse<SalesDetailSearch>>,
    ) {
      state.loading = false
      state.error = null
      if (state.searchedDetailData?.total != null) {
        state.searchedDetailData.total = action.payload.data.total
      }
      if (state.searchedDetailData?.userInfoList != null) {
        state.searchedDetailData.userInfoList = action.payload.data.userInfoList
      }
    },
    searchSalesRegisteredUserError(
      state: SalesState,
      action: PayloadAction<any>,
    ) {
      state.loading = false
      state.error = action.payload
    },
    updateSalesOrganizationRequest(state: SalesState) {
      state.loading = true
      state.error = null
    },
    updateSalesUserRequest(state: SalesState) {
      state.loading = true
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesIndexSearchAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchSalesIndexSearchAction.fulfilled, (state, action) => {
      state.loading = false
      state.searchIndexList = action.payload
    })
    builder.addCase(fetchSalesIndexSearchAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchSalesDetailAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchSalesDetailAction.fulfilled, (state, action) => {
      state.loading = false
      state.searchedDetailData = action.payload?.data ?? null
    })
    builder.addCase(fetchSalesDetailAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(searchSalesOrgUserAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(searchSalesOrgUserAction.fulfilled, (state, action) => {
      state.loading = false
      state.searchUserList = action.payload.content
    })
    builder.addCase(searchSalesOrgUserAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(searchSalesInstituteAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(searchSalesInstituteAction.fulfilled, (state, action) => {
      state.loading = false
      state.searchInstitutes = action.payload
    })
    builder.addCase(searchSalesInstituteAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchOrganizationPackageAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(
      fetchOrganizationPackageAction.fulfilled,
      (state, action) => {
        state.loading = false
        state.organizationPackages = action.payload
      },
    )
    builder.addCase(
      fetchOrganizationPackageAction.rejected,
      (state, action) => {
        state.loading = false
        state.error = action.payload
      },
    )
    builder.addCase(fetchUsageAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchUsageAction.fulfilled, (state, action) => {
      state.loading = false
      state.periodUsageList = action.payload
    })
    builder.addCase(fetchUsageAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchPersonalUsageAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPersonalUsageAction.fulfilled, (state, action) => {
      state.loading = false
      state.personalUsageList = action.payload
    })
    builder.addCase(fetchPersonalUsageAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(updateSalesOrganizationAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(updateSalesOrganizationAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updateSalesOrganizationAction.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(updateSalesOrganizationUserAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(updateSalesOrganizationUserAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(
      updateSalesOrganizationUserAction.rejected,
      (state, action) => {
        state.loading = false
        state.error = action.payload
      },
    )
  },
})

export const {
  searchSalesRegisteredUserRequest,
  searchSalesRegisteredUserSuccess,
  searchSalesRegisteredUserError,
  updateSalesOrganizationRequest,
  updateSalesUserRequest,
} = salesSlice.actions

export const selectSales = (state: RootState): any => state.sales
export const selectSalesDetailInfo = (state: RootState) =>
  state.sales.searchedDetailData?.orgDetail
export const selectSalesOidUserListInfo = (state: RootState) =>
  state.sales.searchedDetailData?.userInfoList
export const selectSalesUsers = (state: RootState) => state.sales.searchUserList
export const selectSalesInstitutes = (state: RootState) =>
  state.sales.searchInstitutes
export const selectOrganizationPackages = (state: RootState) =>
  state.sales.organizationPackages
export const selectPeriodUsageList = (state: RootState) =>
  state.sales.periodUsageList
export const selectPersonalUsageList = (state: RootState) =>
  state.sales.personalUsageList

export default salesSlice.reducer
