import {Button, Typography} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import {addDays} from 'date-fns'
import {enUS, ko} from 'date-fns/locale'
import {formatDate} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {DateRange} from 'react-date-range'
import {useTranslation} from 'react-i18next'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useLocation} from 'react-router-dom'

interface ParentProps {
  onChangeValue: (range: PeriodDate) => void
  onOpen: boolean
  dateValue: PeriodDate
  dateOpen: (clickAwayOpen: boolean) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  parentDate: {
    // position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    left: '100px',
    marginBottom: 10,
  },
  dateContainer: {
    width: '100%',
    display: 'flex',
    gap: 5,
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    padding: 9,
    border: '1px solid #e0e0e0',
    backgroundColor: 'white',
    borderRadius: 4,
    width: '100%',
    '& h6': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
    textAlign: 'center',
  },
  divider: {
    marginRight: 10,
    marginLeft: 10,
  },
  searchButton: {
    background: 'white',
    width: 60,
    color: '#666a6c',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
  },
  dateSearchButton: {
    border: '1px solid #0cc7c7',
    borderRadius: 4,
    padding: '0 15px',
    background: 'white',
    color: '#0cc7c7',
  },
  dropdownWrapper: {},
  dropdown: {
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 500,
    width: 664,
    background: 'white',
    boxShadow: '15px 10px 30px rgba(0, 0, 0, 0.1)',

    '& .submitButton': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10,
      padding: 10,
      background: '#EFF2F7',
      '& .cancelButton': {
        background: 'white',
      },
    },
  },
  dateTextWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  padding: {
    padding: theme.spacing(0.5),
  },
}))

/**
 * 날짜범위선택이 가능한 캘린더
 *
 * @param onChangeValue setValue 함수
 * @param dateValue date value 값
 */

export default function MainRangeDatePicker({
  onChangeValue,
  onOpen,
  dateValue,
  dateOpen,
}: ParentProps) {
  const classes = useStyles()
  const {i18n} = useTranslation()
  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate: dateValue.startDate,
    endDate: dateValue.endDate,
  })

  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const [selectRanges, setSelectRanges] = React.useState<any>()

  const onRangeChange = (ranges: any) => {
    if (ranges != null) {
      setSelection(ranges.range1)
      setSelectRanges(ranges.range1)
    }
  }
  const handleClickAway = () => {
    // setOpen(false)
  }
  const handleClick = () => setOpen((prev) => !prev)
  const handleClose = () => setOpen(false)
  const handleDataSubmit = () => {
    if (selectRanges) onChangeValue(selectRanges)
    if (!selectRanges) onChangeValue(selection)
  }
  const handleDataChange = () => {
    setOpen(false)
    handleDataSubmit()
  }

  useEffect(() => {
    if (onOpen) {
      setOpen(onOpen)
    }
  }, [onOpen])

  useEffect(() => {
    dateOpen(open)
  }, [open])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.parentDate}>
        <div className={classes.dateContainer}>
          <Grid className={classes.dateWrap} onClick={handleClick}>
            <DateRangeIcon className={classes.icon} />
            <Divider
              orientation='vertical'
              flexItem
              className={classes.divider}
            />
            <div className={classes.dateTextWrap}>
              <Typography variant='h6'>
                {formatDate(selection.startDate)} ~{' '}
                {formatDate(selection.endDate)}
              </Typography>
            </div>
          </Grid>
        </div>

        {open ? (
          <div className={classes.dropdown}>
            <DateRange
              onChange={onRangeChange}
              months={2}
              // minDate={addDays(new Date(), -363)}
              maxDate={addDays(new Date(), 0)}
              direction='horizontal'
              scroll={{enabled: false}}
              ranges={[
                {
                  startDate: selection?.startDate,
                  endDate: selection?.endDate,
                },
              ]}
              // showMonthAndYearPickers={false}
              rangeColors={['#0cc7c7']}
              color='#0cc7c7'
              locale={i18n.language === 'ko' ? ko : enUS}
              editableDateInputs
            />
            <Divider />
            <div className='submitButton'>
              <Button
                variant='contained'
                color='default'
                size='small'
                disableElevation
                onClick={() => handleClose()}
                className='cancelButton'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                disableElevation
                size='small'
                color='primary'
                onClick={() => handleDataChange()}
              >
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}
