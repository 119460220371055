import Colors from 'theme/Colors'
import Size from 'theme/Size'
import Weight from 'theme/Weight'
import {SelectStyleProps} from './Select'

export const NormalSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
}
// 페이지 안에 셀렉트박스가 여러개 있을경우, 후순위에 오는 셀렉트박스는 상위 셀렉트박스와 겹치치않게 z-index값을 조절해주어야한다.
export const NormalSecondSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
  zIndexOptions: 600,
}

export const InCommonModalSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
  zIndexOptions: 9999,
}

export const FullWidthSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  fixWidthPercent: Size.full,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
}

export const FullWidthDisabledSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_02,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  fixWidthPercent: Size.full,
  bgColor: Colors.common.disabled,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
}

export const BirthDateSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  fixWidthPercent: Size.medium,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  marginLeft: Size.padding.input.selectBirth.margin,
  marginRight: Size.padding.input.selectBirth.margin,
  openDirection: 'bottom',
  thumbColor: Colors.common.grays.g_02,
}

export const PlaceHolderSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  fixWidthPercent: Size.full,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  marginLeft: Size.padding.input.selectBirth.margin,
  marginRight: Size.padding.input.selectBirth.margin,
  openDirection: 'bottom',
  thumbColor: Colors.common.grays.g_02,
}

export const NormalSelectTopStyles: SelectStyleProps = {
  ...NormalSelectStyles,
  openDirection: 'top',
}

export const HeaderTranslationSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: Colors.common.grays.g_05,
  bgHoverColor: Colors.common.grays.g_03,
  borderColor: Colors.common.grays.g_03,
  borderRadius: 3,
  paddingLeft: 14,
  paddingRight: 14,
  openDirection: 'bottom',
}

export const SidebarTranslationSelectMobileStyles: SelectStyleProps = {
  boxHeight: Size.height.input.big,
  textColor: Colors.common.white,
  textSize: Size.font.x_large,
  textWeight: Weight.semi_bolder,
  bgColor: Colors.bg.sidebar.menu,
  bgHoverColor: Colors.bg.sidebar.menu_hover,
  borderColor: Colors.common.light_blue,
  borderRadius: 4,
  paddingLeft: 14,
  paddingRight: 14,
  openDirection: 'top',
  fixWidthPercent: '100%',
}

export const SidebarTranslationSelectSmMobileStyles: SelectStyleProps = {
  ...SidebarTranslationSelectMobileStyles,
  boxHeight: Size.height.input.normal,
  textSize: Size.font.large,
}

export const FooterRelatedSitesSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: Colors.common.grays.g_05,
  bgHoverColor: Colors.common.grays.g_03,
  borderColor: Colors.common.grays.g_03,
  borderRadius: 3,
  paddingLeft: 14,
  paddingRight: 14,
  openDirection: 'top',
  fixMaxWidth: 120,
}

// for 3.0 payment management design
export const PaymentManagementStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.large,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: '#E0E0E0',
  borderRadius: 4,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
}

export const VerySmallSelectStyles: SelectStyleProps = {
  boxHeight: Size.selectBox.verySmall.height,
  fixWidth: Size.selectBox.verySmall.width,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.verySmall,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: '#E0E0E0',
  borderRadius: 4,
  paddingLeft: Size.padding.input.verySmall.left,
  paddingRight: Size.padding.input.verySmall.right,
  openDirection: 'bottom',
}

export const AuthSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: '#efefef',
  bgHoverColor: '#f2f2f2',
  borderColor: '#cdcdcd',
  borderRadius: 3,
  paddingLeft: 8,
  paddingRight: 8,
  openDirection: 'bottom',
}

export const AuthSelectTopStyles: SelectStyleProps = {
  boxHeight: Size.height.input.small,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.semi_bolder,
  bgColor: '#efefef',
  bgHoverColor: '#dbdbdb',
  borderColor: '#cdcdcd',
  borderRadius: 3,
  paddingLeft: 8,
  paddingRight: 8,
  openDirection: 'top',
}

export const NormalSelectTopShortStyles: SelectStyleProps = {
  ...NormalSelectTopStyles,
  fixMaxHeight: 250,
}

export const PhoneNumberSelectStyles: SelectStyleProps = {
  boxHeight: Size.height.input.normal,
  textColor: Colors.common.grays.g_01,
  textSize: Size.font.regular,
  textWeight: Weight.regular,
  bgColor: Colors.common.white,
  bgHoverColor: Colors.common.grays.g_04,
  borderColor: Colors.common.grays.g_03,
  borderRadius: Size.borderRadius.input,
  paddingLeft: Size.padding.input.normal.left,
  paddingRight: Size.padding.input.normal.right,
  openDirection: 'bottom',
  fixMinWidth: 200,
  fixWidth: 200,
}
