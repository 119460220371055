import {yupResolver} from '@hookform/resolvers/yup'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ReorderIcon from '@material-ui/icons/Reorder'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SubTitle from 'components/Text/SubTitle'
import {getNoticeListPath} from 'constants/RouteConstant'
import useNotice from 'features/notice/useNotice'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import * as yup from 'yup'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
  },
  gridContainer: {
    margin: 25,
    display: 'flex',
    flexDirection: 'column',
    // margin: 10,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  contentTitleWrap: {
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
    '& > .MuiFormLabel-root.Mui-disabled': {
      color: '#333333', // (default alpha is 0.38)
    },
  },
  inputRoot: {
    '&$disabled': {
      color: '#333333',
    },
  },

  titleSize: {
    fontSize: '1.0rem',
  },
  titleCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 43,
    '& button': {
      minWidth: 148,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  rootText: {
    '&$disabled': {
      color: 'white',
    },
  },
  disabled: {},
  background: {
    color: 'primary',
    '& span': {
      background: '#E0E0E0',
    },
  },

  textField: {
    marginTop: 10,
    '& .MuiInputBase-input': {
      borderColor: 'red',
      fontSize: 14,
      fontWeight: 400,
      color: '#333333',
    },
    '& textarea:disabled': {
      color: '#000',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '0',
    },
  },

  specialOutline: {
    borderColor: '#e2e4e7',
    borderWidth: 1,
  },

  hidden: {
    visibility: 'hidden',
  },

  titleTextField: {
    width: '100%',
    minWidth: 120,
    height: 47,
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
  },

  buttonWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))

interface Params {
  id: string
}

// 서버로 보낼때 사용하는 type
type NoticeEditInputType = {
  uid: string
  id: number
  title: string
  content: string
}

export default function NoticeEditPage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {id} = useParams<Params>()
  const {notice, onRead, onUpdate} = useNotice()

  const AddSchema = yup.object().shape({
    title: yup.string().min(1),
    content: yup.string().min(1),
  })

  const {handleSubmit, control, reset} = useForm<NoticeEditInputType>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })

  const onSubmit = handleSubmit((data: NoticeEditInputType) =>
    onUpdate({
      id: Number(id),
      title: data.title,
      content: data.content,
    }).then(() => {
      reset()
    }),
  )

  useEffect(() => {
    onRead({
      id: parseInt(id, 10),
    })
  }, [])

  return (
    <CardWithTitle title={t('INotice')}>
      <Grid container className={classes.gridContainer}>
        <SubTitle>{t('INoticeUpdate')} </SubTitle>
        <Grid item xs={12} sm={1} className={classes.titleCenter}>
          <Typography variant='h6'>{t('ITitle')} </Typography>
        </Grid>
        <Grid item xs={12} sm={11} className={classes.contentContainer}>
          <Controller
            name='title'
            control={control}
            rules={{required: false}}
            render={({field}) => (
              <TextField
                className={classes.textField}
                variant='outlined'
                disabled={false}
                fullWidth
                defaultValue={notice?.title ?? t('INoticeEmpty')}
                InputProps={{
                  classes: {notchedOutline: classes.specialOutline},
                }}
                {...field}
              />
            )}
          />
        </Grid>

        <Typography className={classes.padding} />
        <Grid item xs={12} sm={1}>
          <Typography variant='h6' className={classes.titleSize}>
            {t('IContents')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} className={classes.contentContainer}>
          <Controller
            name='content'
            control={control}
            rules={{required: false}}
            render={({field}) => (
              <TextField
                className={classes.textField}
                variant='outlined'
                disabled={false}
                fullWidth
                multiline
                InputProps={{
                  classes: {notchedOutline: classes.specialOutline},
                }}
                defaultValue={notice?.content ?? t('IContentsEmpty')}
                rows={13}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={11} className={classes.buttonContainer}>
          <ActionButton
            startIcon={<ReorderIcon />}
            color='secondary'
            onClick={(event) => {
              event.stopPropagation()
              navigate(getNoticeListPath())
            }}
          >
            {t('IReadList')}
          </ActionButton>
          <Typography className={classes.padding} />
          <div className={classes.buttonWrap}>
            <ActionButton
              variant='contained'
              disableElevation
              onClick={(event: any) => {
                event.stopPropagation()
                navigate(getNoticeListPath())
              }}
            >
              {t('ICancel')}
            </ActionButton>
            <Typography className={classes.padding} />
            <ActionButton
              variant='contained'
              color='primary'
              disableElevation
              onClick={onSubmit}
            >
              {t('IEdit')}
            </ActionButton>
          </div>
        </Grid>
      </Grid>
    </CardWithTitle>
  )
}
