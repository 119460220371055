import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

export default function TelephoneNumberInput() {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const schema = yup.object().shape({
    code: yup.string().required(t('IRequired')),
  })
  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IPhone')} </p>
        <p className={classes.require}>*</p>
      </div>
      <input
        placeholder={t('IRequired')}
        className={classes.input}
        {...createOrgInfo.register('tel', {
          validate: (code) => schema.validate({code}).catch((e) => e.errors[0]),
          pattern: {
            value:
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            message: t('ITelInvalid'),
          },
        })}
      />
      {errors.tel && (
        <span className={classes.errorText}>{errors.tel.message}</span>
      )}
    </div>
  )
}
