import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

const RegisterStyle = (theme: Theme) =>
  createStyles({
    topRoot: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      color: '#676A6C',
      zIndex: 10,
      position: 'relative',
      gap: 30,
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        flex: 1,
        width: '100%',
        minWidth: 'calc(100vh - 200px)',
      },
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      '@media (max-width: 1770px)': {
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    root: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      width: 'calc(100% - 200px)',
      // maxWidth: ,
    },
    padding: {
      padding: theme.spacing(3),
    },
    industryPadding: {
      padding: theme.spacing(1),
    },
    agreeSectionContainer: {
      flex: 1,
      maxWidth: '50%',
      minWidth: 675,
      width: '100%',
      gap: 20,
      '@media (max-width: 1770px)': {
        maxWidth: 980,
      },
    },
    termsContainer: {
      border: 'none',
      borderRadius: 5,
      overflow: 'auto',
      width: '100%',
      height: 500,
      '& .body': {
        margin: 0,
        border: 'none',
      },
    },
    termsWrap: {
      marginBottom: 14,
    },
    termsTitle: {
      fontSize: 16,
      color: '#4b4b4b',
      fontWeight: 500,
    },
    serviceCheckboxWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& input[id="check"]': {
        cursor: 'pointer',
        border: '1px solid red',
        color: 'red',
      },
    },

    inputContainer: {
      margin: 10,
    },
    selectContainer: {
      flex: 1,
      width: '100%',
      height: 45,
      '& .MuiOutlinedInput-root': {
        border: '3px solid red',
      },
      '& $notchedOutline': {
        border: '3px solid red',
      },
      '&:hover $notchedOutline': {
        borderColor: '#e9e9e9',
      },
    },
    industryContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      gap: '16px',
    },
    industryWrap: {
      width: '100%',
    },
    submitButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      '& button': {
        width: '100%',
        height: 55,
        fontSize: 18,
      },
    },
    cancelButton: {
      color: '#868e96',
    },
    bottomToolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      '& p': {
        color: '#4b4b4b',
        fontSize: 14,
      },
    },
    textButton: {
      fontSize: 14,
      textDecoration: 'underline',
      marginLeft: 10,
      color: '#3cbbbd',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    checkbox: {
      '& input[type="checkbox" i]': {
        cursor: 'pointer',
        border: '1px solid red',
      },
    },
    errorText: {
      fontSize: 11,
      marginTop: 2,
      color: '#d03c35',
    },
    formRegister: {
      maxWidth: '50%',
      width: '100%',
      '@media (max-width: 1770px)': {
        maxWidth: 980,
      },
    },
  })

export default RegisterStyle
