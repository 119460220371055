import {AnalysisProduct} from 'api/paymentApi'
import {TFunction} from 'react-i18next'
import {MyBundle} from '../../Interfaces/MyProducts'

export default class MyBundleModel implements MyBundle {
  t: TFunction

  analyses: [AnalysisProduct]

  analysisGroup: [string]

  description: string

  name: string

  remainAmount: number

  givenAmount: number

  constructor(raw: MyBundle, t: TFunction) {
    this.t = t

    this.analyses = raw.analyses
    this.analysisGroup = raw.analysisGroup
    this.description = raw.description
    this.name = raw.name
    this.remainAmount = raw.remainAmount
    this.givenAmount = raw.givenAmount
  }

  getRemainAmount = (): number | string => {
    if (this.remainAmount == null) {
      return this.t('IUnlimited')
    }
    return this.remainAmount
  }

  getGivenAmount(): number | string {
    if (this.givenAmount == null) {
      return this.t('IUnlimited')
    }
    return this.givenAmount
  }
}
