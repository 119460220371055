import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import SubTitle from 'components/Text/SubTitle'
import {
  fetchSingleProductsAction,
  selectSingleProductsList,
} from 'features/paymentProducts/paymentProductsSlice'
import {removeDot} from 'helpers/paymentHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import styled from 'styled-components'

const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 43px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 10px;
  }
`
const SingleItemsTable = () => {
  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IDefault'},
    {id: '2', label: 'IProductName'},
    {id: '3', label: 'IDomesticPrice'},
    {id: '4', label: 'IForeignPrice'},
  ]
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const items = useSelector(selectSingleProductsList)

  useEffect(() => {
    dispatch(fetchSingleProductsAction())
  }, [])

  return (
    <div style={{width: '100%'}}>
      <SubTitle>{t('ISingleTableTitle')}</SubTitle>
      <PackageTable>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={t(item.label)}
            length={HeaderCells.length}
            index={index}
          />
        ))}
        {items?.map((item, index) => (
          <RoundTableWrap key={index}>
            <td> </td>
            <td>{item.price.name}</td>
            <td>
              {removeDot(item.price.defaultPrice)} {t('IMonetaryUnit')}
            </td>
            <td>{removeDot(item.price.undefinedPrice)} $</td>
          </RoundTableWrap>
        ))}
      </PackageTable>
    </div>
  )
}
export default SingleItemsTable
