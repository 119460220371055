import React from 'react'

interface RoundTableHeaderProps {
  label: string
  index: number
  length: number
}

const CheckBoxRoundTableHeader = ({
  label,
  index,
  length,
}: RoundTableHeaderProps) => {
  const isLastIndex = (index: number): boolean => {
    if (index === length - 1) return true
    return false
  }

  return (
    <>
      {!isLastIndex(index) && (
        <th style={{backgroundColor: '#e9ecef'}}>
          <p>{label}</p>
        </th>
      )}
      {isLastIndex(index) && (
        <th style={{borderTopRightRadius: '5px', backgroundColor: '#e9ecef'}}>
          <p>{label}</p>
        </th>
      )}
    </>
  )
}

export default CheckBoxRoundTableHeader
