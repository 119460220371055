import {SpreadItem} from 'components/Select/SpreadableSelectBox'

export default class InvoicedYearMonthItemsModel implements SpreadItem {
  constructor(raw: InvoicedYearMonthItemsModel) {
    this.yearMonth = raw.yearMonth
  }

  yearMonth: string

  appearanceValue = () => {
    return this.yearMonth
  }
}
