import {makeStyles, TableCell} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import {createStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import usePagination, {useWriteDialog} from 'features/comment/useComment'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {confirmAction} from 'features/modal/confirmSlice'
import {dateToDateAndTimeString} from 'helpers/dateHelper'
import WriteCommentDialog from 'pages/OrgPage/TreatmentPage/SearchOne/WriteCommentDialog'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch} from 'hooks'

const styles = () =>
  createStyles({
    toolbar: {
      padding: '0',
      paddingLeft: 15,
      paddingRight: 5,
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    table: {
      marginBottom: 10,
      borderRadius: 0,
    },
    tableRow: {
      backgroundColor: 'white',
    },
    checkboxCell: {
      width: 42,
      padding: 0,
      paddingRight: 5,
    },
    doctorCell: {
      width: 140,
      padding: '5px 0',
    },
    commentCell: {
      padding: '10px 5px',
      paddingRight: 15,
    },
    commentTextArea: {
      width: '100%',
      resize: 'none',
      overflowY: 'scroll',
    },
    headerInfoButton: {
      marginRight: 10,
      border: '1px solid #ced4da',
      borderRadius: 0,
      backgroundColor: 'white',
      color: '#868e96',
      fontSize: 11,
    },
    commentButton: {
      border: '1px solid #ced4da',
      borderRadius: 0,
      backgroundColor: 'white',
      color: '#868e96',
      fontSize: 11,
    },
    commentCreateButton: {
      border: '1px solid #0cc7c7',
      borderRadius: 0,
      backgroundColor: '#0cc7c7',
      color: 'white',
      fontSize: 11,
      '&:hover,&:focus': {
        backgroundColor: '#0cc7c7',
      },
    },
  })

const useStyles = makeStyles(styles)

interface UserCardProps {
  uuid: string
}

const UserComments = (props: UserCardProps) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {uuid} = props
  const classes = useStyles()

  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const size = 3
  const {comments, loading, query, paging, onFetchPage, onDelete} =
    usePagination()
  const setPageIndex = (page: number) =>
    onFetchPage({...query, paging: {page, size}})

  const [selected, setSelected] = React.useState<number[]>([])
  const {onOpen} = useWriteDialog()

  const handleAddButtonClick = () => onOpen(uuid)
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }
  const handleDelete = async () => {
    if (selected.length > 0) {
      const confirmed = await dispatch(
        confirmAction({message: t('IPatientDeleteComment')}),
      ).unwrap()
      if (confirmed)
        onDelete({commentIds: selected, uuid}).then((result) => {
          const response = result.payload as CommonResponse
          if (response.success) {
            onSuccessModalOpen(t('IProcessSuccess'))
            onFetchPage({uuid, paging: {page: 0, size}})
            setSelected([])
          }
          if (!response.success) {
            onFailureModalOpen(t('IProcessFail'))
          }
        })
    } else if (!selected.length) {
      onFailureModalOpen(t('ICommentSelectComment'))
    }
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  const emptyRows = size - comments.length

  useEffect(() => {
    onFetchPage({uuid, paging: {page: 0, size}})
  }, [uuid])

  useEffect(() => {
    setSelected([])
  }, [paging.pageIndex])

  return (
    <>
      <TableContainer>
        <div
          style={{
            textAlign: 'right',
            marginTop: 13,
            display: 'flex',
            gap: 5,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant='outlined'
            className={classes.commentCreateButton}
            onClick={handleAddButtonClick}
          >
            {t('ICommentCreate')}
          </Button>
          <Button
            variant='outlined'
            className={classes.commentButton}
            onClick={handleDelete}
          >
            {t('ICommentDelete')}
          </Button>
        </div>
        <Toolbar
          style={{
            margin: 0,
            padding: 0,
            minHeight: 42,
            backgroundColor: '#e9ecef',
            textAlign: 'center',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            marginTop: 5,
          }}
        >
          <span style={{flex: 1, fontWeight: 600, color: '#333333'}}>
            {t('IComment')}
          </span>
        </Toolbar>
        <Table size='small' className={classes.table}>
          <TableBody>
            {comments.map((row) => {
              const isItemSelected = isSelected(row.id)
              const labelId = `table-checkbox-${row.id}`

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  className={classes.tableRow}
                >
                  <TableCell className={classes.checkboxCell}>
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{'aria-labelledby': labelId}}
                    />
                  </TableCell>
                  <TableCell className={classes.doctorCell}>
                    <div>
                      {dateToDateAndTimeString(new Date(row.createdAt))}
                    </div>
                    <div>
                      <ClientName
                        firstName={row?.firstName ?? '-'}
                        lastName={row?.lastName ?? '-'}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.commentCell}>
                    <textarea
                      className={classes.commentTextArea}
                      defaultValue={row.comment}
                      disabled
                      rows={2}
                      maxLength={150}
                      // eslint-disable-next-line react/no-unknown-property
                      unselectable='on'
                    />
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 50 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={paging.totalPages}
          currentPageIndex={paging.pageIndex}
          itemCountPerPage={paging.itemPerPage}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={(event) =>
            onFetchPage({
              ...query,
              paging: {
                page: 0,
                size: parseInt(event.target.value as string, 10),
              },
            })
          }
          displayIndexCount={3}
          isItemCountPerPageEnabled={false}
          isFirstAndLastButtonEnabled
        />
      </TableContainer>
      <WriteCommentDialog />
    </>
  )
}

export default UserComments
