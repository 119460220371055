import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ReorderIcon from '@material-ui/icons/Reorder'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SubTitle from 'components/Text/SubTitle'
import {getNoticeEditPath, getNoticeListPath} from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import useNotice from 'features/notice/useNotice'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
  },
  gridContainer: {
    // marginBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '& > h6': {
      fontSize: '1.0rem',
    },
  },
  contentTitleWrap: {
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    '& > h6': {
      fontSize: '1.0rem',
    },
  },
  inputRoot: {
    '&$disabled': {
      color: 'red',
    },
  },

  titleSize: {
    fontSize: '1.0rem',
    whiteSpace: 'pre-wrap',
  },
  titleCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
    '& > h6': {
      fontSize: '1.0rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 43,
  },
  buttonWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  padding: {
    padding: theme.spacing(1),
  },
  rootText: {
    '&$disabled': {
      color: 'white',
    },
  },
  disabled: {},
  background: {
    color: 'primary',
    '& span': {
      background: '#E0E0E0',
    },
  },
  dateTextField: {
    width: 270,
    height: 47,
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
    justifyContent: 'space-between',
  },
  contentTextField: {
    width: '100%',
    height: 292,
    overflow: 'scroll',
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
    justifyContent: 'space-between',
  },
  dateIcon: {
    color: '#5c6168',
  },
  textField: {
    width: '100%',
    height: 47,
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
  },
  titleTextField: {
    width: '100%',
    minWidth: 120,
    height: 47,
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
  },
  container: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
  backButton: {
    width: 148,
  },
  contentWrap: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    width: 800,
  },
}))

interface Params {
  id: string
}

const OnlyReadToolbar = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer}>
      <ActionButton
        startIcon={<ReorderIcon />}
        color='secondary'
        onClick={(event) => {
          event.stopPropagation()
          navigate(getNoticeListPath())
        }}
      >
        {t('IReadList')}
      </ActionButton>

      <Button variant='contained' className={classes.hidden}>
        {t('IOk')}
      </Button>
    </div>
  )
}

const ReadToolbar = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {id} = useParams<Params>()
  const {onDelete} = useNotice()

  const handleListClick = () => navigate(getNoticeListPath())

  const handleEditClick = () => navigate(getNoticeEditPath(parseInt(id, 10)))

  const handleDeleteClick = () =>
    onDelete({
      id: parseInt(id, 10),
    })

  return (
    <div className={classes.buttonContainer}>
      <ActionButton
        startIcon={<ReorderIcon />}
        color='secondary'
        onClick={handleListClick}
      >
        {t('IReadList')}
      </ActionButton>
      <div className={classes.buttonWrap}>
        <Typography className={classes.padding} />
        <ActionButton
          color='primary'
          className={classes.backButton}
          onClick={handleEditClick}
        >
          {t('IEdit')}
        </ActionButton>

        <Typography className={classes.padding} />
        <ActionButton
          color='default'
          className={classes.backButton}
          onClick={handleDeleteClick}
        >
          {t('IDelete')}
        </ActionButton>
      </div>
    </div>
  )
}

export default function NoticeReadPage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {id} = useParams<Params>()
  const {user: currentUser} = useAuth()
  const {notice, loading, onRead} = useNotice()

  function checkAuthority(Authority: string): boolean {
    if (Authority === 'ROLE_COMPANY_SALES' || Authority === 'ROLE_COMPANY_CS') {
      return true
    }
    return false
  }

  useEffect(() => {
    onRead({
      id: parseInt(id, 10),
    })
  }, [id])

  return (
    <CardWithTitle title={t('INotice')}>
      <Grid className={classes.gridContainer}>
        <SubTitle>{t('INoticeRead')} </SubTitle>
        <Grid item xs={12} className={classes.contentWrap}>
          <Grid item xs={12} sm={6} className={classes.container}>
            <Grid className={classes.titleCenter}>
              <Typography variant='h6'>{t('ITitle')} </Typography>
            </Grid>
            <Grid className={classes.contentContainer}>
              {!loading && (
                <div className={classes.titleTextField}>
                  {notice?.title ?? t('INoticeEmpty')}
                </div>
              )}
            </Grid>
          </Grid>
          <Typography className={classes.padding} />
          <Grid item xs={12} sm={4} className={classes.container}>
            <Grid className={classes.titleCenter}>
              <Typography variant='h6'>{t('ICreateDate')} </Typography>
            </Grid>
            <Grid className={classes.contentContainer}>
              <div className={classes.dateTextField}>
                {isoStringToDateAndTimeString(
                  String(notice?.dateTime ?? new Date()),
                )}
                <EventNoteIcon className={classes.dateIcon} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <div>
          <Grid item xs={12} sm={1}>
            <Typography variant='h6' className={classes.titleSize}>
              {t('IContents')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={11}
            className={classes.contentContainer}
            style={{whiteSpace: 'pre-wrap'}}
          >
            {!loading && (
              <div className={classes.contentTextField}>
                {notice?.content ?? t('IContentsEmpty')}
              </div>
            )}
          </Grid>
        </div>

        <Grid item xs={12} sm={11}>
          {checkAuthority(currentUser?.authority ?? '') && <ReadToolbar />}
          {!checkAuthority(currentUser?.authority ?? '') && <OnlyReadToolbar />}
        </Grid>
      </Grid>
    </CardWithTitle>
  )
}
