import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  checkEmailDuplicateApi,
  loginApi,
  logoutApi,
  verifyTwoFactorApi,
} from 'api/authApi'
import {fetchProductPackageListApi} from 'api/pointApi'
import * as storage from 'api/storageApi'
import {fetchMeApi} from 'api/userApi'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'
import {RouteErrorConstant} from 'error/AppError'
import {AuthError} from 'exceptions'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import {openFirstLoginDialog} from 'features/modal/modalSlice'
import {fetchOrgReadAction} from 'features/org/orgSlice'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import i18n from 'i18n'
import {RootState} from 'store'

const fetchLoginAction = createAsyncThunk(
  'api/auth/login',
  async (payload: LoginPayload) => {
    const response = await loginApi(payload)
    return response.data
  },
)
const fetchVerifyTwoFactorAction = createAsyncThunk(
  'api/auth/verify',
  async (payload: VerifyPayload) => {
    const response = await verifyTwoFactorApi(payload)
    return response.data
  },
)

const fetchLogoutAction = createAsyncThunk(
  'api/auth/logout',
  async (uid: string) => {
    await logoutApi({
      uid,
      deviceId: storage.getOrInitializeDeviceId(),
      deviceType: 'WEB',
    })
  },
)

export const fetchMeAction = createAsyncThunk('api/auth/me', async () => {
  const response = await fetchMeApi()
  return response.data
})

export const fetchMyPackageAction = createAsyncThunk(
  'api/auth/my_package',
  async (payload: string) => {
    const response = await fetchProductPackageListApi({oid: payload})
    return response.data
  },
)

export const loginAction = createAsyncThunk(
  'auth/login',
  async (payload: LoginPayload, {dispatch}) => {
    try {
      const {
        firstLogin,
        accessToken,
        refreshToken,
        organizationType,
        features,
        twoFactorType,
        twoFactorId,
        analysisStartAt,
        analysisEndAt,
      } = await dispatch(fetchLoginAction(payload)).unwrap()

      // 토큰값 설정
      if (twoFactorType === 'Email') {
        storage.setTwoFactorType(
          JSON.stringify({
            type: twoFactorType,
            id: twoFactorId,
            mail: payload.email,
          }),
        )
        return undefined
      }
      const analysisDate = {
        startDate: analysisStartAt,
        endDate: analysisEndAt,
      }
      storage.setAnalysisDate(JSON.stringify(analysisDate))
      storage.setAccessToken(accessToken)
      storage.setRefreshToken(refreshToken)
      storage.setOrganizationType(organizationType)
      storage.setFeatures(JSON.stringify(features))
      const localToken = storage.getAccessToken()
      if (localToken) {
        const me = await dispatch(fetchMeAction()).unwrap()

        if (firstLogin) {
          dispatch(openFirstLoginDialog(me.uid))
          return undefined
        }

        storage.setUserUid(me.uid)
        storage.setAdminUid(me.uid)

        const org = await dispatch(fetchOrgReadAction()).unwrap()
        await dispatch(fetchMyPackageAction(org.oid ?? ''))

        return me
      }
      throw new Error('not token')
    } catch (err) {
      storage.removeAccessToken()
      storage.removeRefreshToken()
      storage.removeUserUid()
      storage.removeAdminUid()
      storage.removeOrganizationType()
      throw err
    }
  },
)

export const verifyTwoFactorAction = createAsyncThunk(
  'auth/verify',
  async (payload: VerifyPayload, {dispatch}) => {
    try {
      const {
        firstLogin,
        accessToken,
        refreshToken,
        organizationType,
        features,
        analysisStartAt,
        analysisEndAt,
      } = await dispatch(fetchVerifyTwoFactorAction(payload)).unwrap()
      // 토큰값 설정
      const analysisDate = {
        startDate: analysisStartAt,
        endDate: analysisEndAt,
      }
      storage.setAnalysisDate(JSON.stringify(analysisDate))
      storage.setAccessToken(accessToken)
      storage.setRefreshToken(refreshToken)
      storage.setOrganizationType(organizationType)
      storage.setFeatures(JSON.stringify(features))
      const localToken = storage.getAccessToken()
      if (localToken) {
        const me = await dispatch(fetchMeAction()).unwrap()

        if (firstLogin) {
          dispatch(openFirstLoginDialog(me.uid))
          return undefined
        }

        storage.setUserUid(me.uid)
        storage.setAdminUid(me.uid)

        const org = await dispatch(fetchOrgReadAction()).unwrap()
        await dispatch(fetchMyPackageAction(org.oid ?? ''))

        return me
      }
      throw new Error('not token')
    } catch (err) {
      storage.removeAccessToken()
      storage.removeRefreshToken()
      storage.removeUserUid()
      storage.removeAdminUid()
      storage.removeOrganizationType()
      throw err
    }
  },
)

export const silentLoginAction = createAsyncThunk(
  'auth/login/silent',
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const token = storage.getAccessToken()
      if (isNotDefined(token)) {
        return rejectWithValue('토큰이 없습니다.')
      }

      const me = await dispatch(fetchMeAction()).unwrap()
      storage.setUserUid(me.uid)
      storage.setAdminUid(me.uid)

      const org = await dispatch(fetchOrgReadAction()).unwrap()
      await dispatch(fetchMyPackageAction(org.oid ?? ''))

      return me
    } catch (err) {
      storage.removeAccessToken()
      storage.removeRefreshToken()
      storage.removeUserUid()
      storage.removeAdminUid()
      storage.removeOrganizationType()

      return rejectWithValue(err.message)
    }
  },
)

export const logoutAction = createAsyncThunk(
  'auth/logout',
  async (_, {dispatch, getState}) => {
    const uid = (getState() as RootState).auth.user?.uid
    if (isDefined(uid)) {
      await dispatch(fetchLogoutAction(uid))
    }
    storage.removeAnalysisDate()
    storage.removeInputTimeout()
    storage.removeAccessToken()
    storage.removeRefreshToken()
    storage.removeAdminUid()
    storage.removeUserUid()
    storage.removeOrganizationType()
    throw new AuthError(RouteErrorConstant.CREDENTIAL_EXPIRED)
  },
)

export const checkEmailDuplicateAction = createAsyncThunk(
  'auth/email/isDuplicate',
  async (email: string, {dispatch, rejectWithValue}) => {
    try {
      await checkEmailDuplicateApi(email)
      dispatch(openSuccessModalAction(i18n.t('IEmailIsAvailable')))
      return true
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)
export interface LoginPayload {
  email: string
  password: string
  deviceId: string
  deviceType: 'WEB'
  token: string
  lang: string
}

export interface VerifyPayload {
  email: string
  authCode: number
  twoFactorId: number
}

export interface AuthState {
  user: User | undefined
  subUser: UserDetailInfo | undefined
  loading: boolean
  loading_silent: boolean
  error: unknown
}

const initialState: AuthState = {
  loading: false,
  loading_silent: true,
  user: undefined,
  subUser: undefined,
  error: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(silentLoginAction.pending, (state) => {
        state.loading_silent = true
        state.error = undefined
      })
      .addCase(silentLoginAction.fulfilled, (state, action) => {
        state.loading_silent = false
        state.user = action.payload
      })
      .addCase(silentLoginAction.rejected, (state, action) => {
        state.loading_silent = false
        state.error = action.error.message
      })
      .addCase(loginAction.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(verifyTwoFactorAction.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(verifyTwoFactorAction.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(verifyTwoFactorAction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(logoutAction.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.loading = false
        state.user = undefined
        state.error = undefined
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export default authSlice.reducer

export const selectData = (state: RootState) => state.auth.user
export const selectLoading = (state: RootState) => state.auth.loading
export const selectSilentLoading = (state: RootState) =>
  state.auth.loading_silent
export const selectError = (state: RootState) => state.auth.error
export const selectCurrentUser = (state: RootState) => state.auth.user
