import {Colors} from 'components/common/useBaseStyle'
import {darken} from 'polished'
import styled, {css} from 'styled-components'

export interface PrimaryButtonProps {
  disabled?: boolean
  fullWidth?: boolean
  flex?: boolean
}

export const PrimaryButton = styled.button.attrs<PrimaryButtonProps>(
  ({type = 'button', ...rest}) => ({type, ...rest}),
)<PrimaryButtonProps>`
  position: relative;
  user-select: none;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${({fullWidth = false}) => (fullWidth ? '100%' : 'auto')};
  flex: ${({flex = false}) => (flex ? '1' : 'none')};
  color: ${Colors.white};
  background-color: ${Colors.primary};
  border: 1px solid ${Colors.primary};
  border-radius: 3px;
  padding: 7px 9px;

  & .Icon {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    background-color: ${darken(0.1, Colors.primary)};
    border-color: ${darken(0.1, Colors.primary)};
  }

  ${({disabled = false}) =>
    disabled
      ? css`
          pointer-events: none;
          cursor: default;
          color: rgba(0, 0, 0, 0.26);
          box-shadow: none;
          border-color: rgba(0, 0, 0, 0.26);
          background-color: rgba(0, 0, 0, 0.12);
        `
      : null}
`
