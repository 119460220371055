import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import useTreatmentSearch from 'features/treatment/useTreatmentSearch'
import {getDefaultPeriodDate, localDateToUtcString} from 'helpers/dateHelper'
import PatientInfo from 'pages/OrgPage/TreatmentPage/SearchOne/PatientInfo'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

const styles = () =>
  createStyles({
    root: {},
    item: {
      marginBottom: 15,
    },
  })

const useStyles = makeStyles(styles)

interface Params {
  id: string
}

const TreatmentPatientPage = () => {
  const {id} = useParams<Params>()
  const classes = useStyles()

  const {onSearchNew} = useTreatmentSearch()
  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())

  useEffect(() => {
    onSearchNew({
      pageNumber: 0,
      pageSize: 10,
      type: 'ALL',
      patientUuid: id,
      // searchName: 'NAME',
      // searchValue: '',
      startDate: localDateToUtcString(selection.startDate),
      endDate: localDateToUtcString(selection.endDate),
    })
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} className={classes.item}>
        <PatientInfo uuid={id} />
      </Grid>
    </Grid>
  )
}

export default TreatmentPatientPage
