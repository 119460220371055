import {
  Action,
  configureStore,
  getDefaultMiddleware,
  Store,
  ThunkAction,
} from '@reduxjs/toolkit'
import rootReducer from 'features/index'
import {isProd} from 'helpers/envHelper'
import {useDispatch} from 'react-redux'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export interface ThunkExtraArguments {
  store: Store
}

function createStore() {
  const thunkArguments = {} as ThunkExtraArguments
  const customizedMiddleware = getDefaultMiddleware({
    thunk: {extraArgument: thunkArguments},
  })

  const store = configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware,
    devTools: !isProd(),
  })

  thunkArguments.store = store
  return store
}

export const store = createStore()
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const useAppDispatch = () => useDispatch<AppDispatch>()
