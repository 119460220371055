import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import OverActive from 'pages/HealthCenter/Report/Elements/OverActive.svg'
import BalancedIcon from 'pages/HealthCenter/Report/Elements/BalancedIcon.svg'
import UnderactiveIcon from 'pages/HealthCenter/Report/Elements/UnderactiveIcon.svg'
import {AgingLobeScore} from 'api/healthCareApi'

const useStyles = makeStyles(HealthCenterReportStyle)

const scoreToString = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score
  if (Math.floor(parsedScore) < 30) {
    return 'IBrainAgingScore_underactive'
  }
  if (Math.floor(parsedScore) > 70) {
    return 'IBrainAgingScore_overactive'
  }

  return 'IBrainAgingScore_balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (Math.floor(parsedScore) < 30) {
    return UnderactiveIcon
  }
  if (Math.floor(parsedScore) > 70) {
    return OverActive
  }

  return BalancedIcon
}

const scoreToClass = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (Math.floor(parsedScore) < 30) {
    return 'Underactive'
  }
  if (Math.floor(parsedScore) > 70) {
    return 'OverActive'
  }

  return 'Balanced'
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const string = scoreToString(score)
  const className = scoreToClass(Number(score))
  return (
    <div className={`${classes.Label} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='sign-icon' />
    </div>
  )
}

const Score = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.scoreContainer}>
      <div className='Score'>
        {score}
        {t('IScore')}
      </div>
      <Label score={score} />
    </div>
  )
}

const RelatedSymptoms = ({arr}: {arr: string[]}) => {
  const classes = useStyles()

  return (
    <div className={classes.symptoms}>
      <p>{arr[0]}</p>
      <p>
        <span>좌 : </span>
        {arr[1]}
      </p>
      <p>
        <span>우 : </span>
        {arr[2]}
      </p>
    </div>
  )
}

const TableFiguresByPart = ({
  agingLobeScore,
}: {
  agingLobeScore: AgingLobeScore
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }
  const DATA = {
    head: [
      t('IBrainAgingScoreTable_area'),
      t('IBrainAgingScoreTable_feature'),
      t('IBrainAgingScoreTable_left'),
      t('IBrainAgingScoreTable_right'),
    ],
    body: [
      [
        t('IFrontalLobe'),
        [
          '판단, 의사결정, 집중, 문제해결, 감정 조절, 성격 형성, 사회적 상호작용',
          '언어 표현, 논리적 분석 및 순차적 사고능력 등',
          '창의적, 직관적 사고. 사회적 맥락에 맞는 감정 반응 및 표현 조절 등',
        ],
        getNumber(agingLobeScore?.Frontal_LH),
        getNumber(agingLobeScore?.Frontal_RH),
      ],
      [
        t('ITemporalLobe'),
        [
          '청각 기능, 기억의 형성 및 저장',
          '단어, 이름, 사실(fact)과 같은 언어 관련 기억 및 이해 등',
          '얼굴, 장소 등 비언어적 기억. 음정, 억양, 뉘앙스, 표정 등에 대한 이해 등',
        ],
        getNumber(agingLobeScore?.Temporal_LH),
        getNumber(agingLobeScore?.Temporal_RH),
      ],
      [
        t('IParietalLobe'),
        [
          '통증 및 감각 지각, 감각 통합. 학습 및 주의력, 감정처리 등',
          '주로 수학 및 논리적 연산. 언어의 이해 등',
          '공간지각능력, 추상적 개념(은유), 맥락의 이해 등',
        ],
        getNumber(agingLobeScore?.Parietal_LH),
        getNumber(agingLobeScore?.Parietal_RH),
      ],
      [
        t('IOccipitalLobe'),
        [
          '물체의 색상, 위치, 모양 등 시각 정보 처리',
          '문자, 언어 관련 시각 정보 처리 등',
          '공간, 패턴, 얼굴 등 비언어적 시각 정보 처리 등',
        ],
        getNumber(agingLobeScore?.Occipital_LH),
        getNumber(agingLobeScore?.Occipital_RH),
      ],
    ],
  }
  return (
    <div className={classes.tableFiguresByPart}>
      <table>
        <thead>
          <tr>
            {DATA.head.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DATA.body.map((item, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {item.map((cell, cellIndex) => {
                  if (Array.isArray(cell)) {
                    return (
                      <td className={classes.secondCol}>
                        <RelatedSymptoms key={cellIndex} arr={cell} />
                      </td>
                    )
                  }
                  if (Number(cell)) {
                    return (
                      <td className={classes.scoreCol}>
                        <Score score={cell} />
                      </td>
                    )
                  }
                  return (
                    <td className={classes.firstCol} key={cellIndex}>
                      <p>{cell}</p>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export interface BrainFunctionBasicProps {
  agingThreeDimensionImage: string
  agingContourImage: string
  agingLobeScore: AgingLobeScore
}

const BrainFunctionBasic = ({
  agingThreeDimensionImage,
  agingContourImage,
  agingLobeScore,
}: BrainFunctionBasicProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.brainBasic}>
      <WrapElement number={1} text={t('I3DBrainMapping')}>
        <div className={classes.groupImage}>
          <img
            className={classes.image}
            alt='pic1'
            src={agingThreeDimensionImage}
          />
          <img className={classes.image} alt='pic2' src={agingContourImage} />
        </div>
        <div className={classes.groupText}>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription')}
          </p>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription2')}
          </p>
        </div>
      </WrapElement>

      <WrapElement number={2} text={t('IFiguresByPart')}>
        <TableFiguresByPart agingLobeScore={agingLobeScore} />
        <div className={classes.groupText}>
          <p className={classes.textDescription}>
            <span>*</span>
            {t('I3DBrainMappingDescription3')}
          </p>
        </div>
        <div className={classes.brainBasicDescription}>
          <div>
            <Label score={9} />
            <p>{t('I3DBrainMappingExplain1')}</p>
            <ul>
              <li>{t('I3DBrainMappingExplainDetail')}</li>
              <li>{t('I3DBrainMappingExplainDetail2')}</li>
            </ul>
          </div>
          <div>
            <Label score={99} />
            <p>{t('I3DBrainMappingExplain2')}</p>
            <ul>
              <li>{t('I3DBrainMappingExplainDetail3')}</li>
              <li>{t('I3DBrainMappingExplainDetail4')}</li>
            </ul>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default BrainFunctionBasic
