import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {makeStyles, Typography} from '@material-ui/core'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import RegisterStyle from '../Style'

export default function SelectedIndustry() {
  const createMemberInfo = useFormContext()
  const useStyles = makeStyles(RegisterStyle)
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const {t} = useTranslation()

  const [industry, setIndustry] = React.useState<Industry>({
    id: 0,
    title: '',
    parent: 0,
    depth: 1,
  })
  const [detailIndustry, setDetailIndustry] = React.useState<Industry>({
    id: 0,
    title: '',
    parent: 0,
    depth: 1,
  })

  const handleDivisionChanged = (data: Industry) => {
    if (data.id) {
      setIndustry(data)
    }
    createMemberInfo.setValue('industry', data)
    createMemberInfo.setValue('industryId', data.id)
  }

  const handlePositionChanged = (data: Industry) => {
    if (data.id) {
      setDetailIndustry(data)
    }
    createMemberInfo.setValue('industryDetail', data)
    createMemberInfo.setValue('detailIndustryId', data.id)
  }

  const {
    formState: {errors},
  } = useFormContext()

  const isNotSelectedIndustry = () => {
    if (errors.industry || errors.industryDetail) {
      return true
    }
    return false
  }

  React.useEffect(() => {
    if (createMemberInfo.getValues('industry')) {
      setIndustry(createMemberInfo.getValues('industry'))
      setDetailIndustry(createMemberInfo.getValues('detailIndustry'))
    }
  }, [createMemberInfo.getValues('industry')])

  return (
    <>
      <div className={classes.industryContainer}>
        <div className={classes.industryWrap}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <p className={inputClasses.inputLabel}>{t('IIndustry')}</p>
            <p className={inputClasses.require}>*</p>
          </div>
          <SelectBoldIndustryWrapper
            parent={0}
            depth={1}
            selectedValue={t('ISelectRequired')}
            defaultValue={createMemberInfo.getValues('industryId') ?? 0}
            onChangeData={handleDivisionChanged}
            {...createMemberInfo.register('industryId', {
              required: `${t('IRequired')}`,
            })}
          />
        </div>
        <Typography className={classes.industryPadding} />
        <div className={classes.industryWrap}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <p className={inputClasses.inputLabel}>{t('IIndustryDetail')}</p>
            <p className={inputClasses.require}>*</p>
          </div>

          <SelectBoldIndustryWrapper
            parent={industry.id ?? 0}
            depth={2}
            defaultValue={detailIndustry.id ?? 0}
            selectedValue={t('ISelectRequired')}
            onChangeData={handlePositionChanged}
            {...createMemberInfo.register('detailIndustryId', {
              required: `${t('IRequired')}`,
            })}
          />
        </div>
      </div>
      {isNotSelectedIndustry() && (
        <div className={classes.errorText}>{t('IRequired')}</div>
      )}
    </>
  )
}
