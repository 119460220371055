import {useEffect, useState} from 'react'
import {getHealthProductsApi} from 'api/healthCareApi'
import useAuth from 'features/auth/useAuth'

function useProductList() {
  const [idOver20Product, setIdOver20Product] = useState<number[]>([])
  const {user: currentUser} = useAuth()

  const getDataProductList = async () => {
    try {
      const res = await getHealthProductsApi()
      if (res.list) {
        const over20Product =
          res.list
            .filter(
              (item) =>
                item.type === 'PremiumPlus' || item.type === 'PremiumPlusHrv',
            )
            .map((product) => product.id) || []

        setIdOver20Product((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(over20Product)) {
            return over20Product
          }
          return prev
        })
      }
    } catch (error) {
      console.error('Failed to fetch product list', error)
    }
  }

  useEffect(() => {
    if (currentUser) {
      getDataProductList()
    }
  }, [currentUser])

  return {
    idOver20Product,
    getDataProductList,
  }
}

export default useProductList
