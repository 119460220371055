import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {Colors} from 'components/common/useBaseStyle'
import {styles} from 'components/Dialog/findFormStyle'
import * as React from 'react'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'
import ClockIcon from '@material-ui/icons/AccessTime'

export const useStyles = makeStyles(() =>
  createStyles({
    ...styles(),
    Timer: {
      justifySelf: 'center',
      color: Colors.primary,
      fontSize: 10,
      '& .Label': {fontSize: 10, marginRight: 6},
      '& .Icon': {
        width: 13,
        height: 13,
        position: 'relative',
        top: 2,
        marginRight: 2,
      },
    },
    Authorized: {color: Colors.text.content, fontSize: 10, paddingLeft: 2},
    Expired: {color: Colors.error, fontSize: 10, paddingLeft: 2},
    NotChecked: {color: Colors.error, fontSize: 10, paddingLeft: 2},
    Retried: {color: Colors.error, fontSize: 10, paddingLeft: 2},
  }),
)

const schema = yup.object().shape({
  code: yup.string().required('IRequired'),
})

const zeroPad = (s: number) => String(s).padStart(2, '0')

const msToMinuteString = (ms: number) => {
  if (ms <= 0) return '00:00'

  const millis = ms % 1000

  const totalSeconds = (ms - millis) / 1000
  const seconds = totalSeconds % 60

  const totalMinutes = (totalSeconds - seconds) / 60
  const minutes = totalMinutes % 60

  const hours = (totalMinutes - minutes) / 60

  if (hours > 0) {
    return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
  }

  return `${zeroPad(minutes)}:${zeroPad(seconds)}`
}

const EXPIRE_MS = 3 * 60 * 1000

type AuthStatus =
  | 'READY'
  | 'CHECK'
  | 'PROCESS'
  | 'EXPIRED'
  | 'SUCCESS'
  | 'RETRY'

interface CheckVerifyCodeInputProps {
  isAuthCodeSend: boolean
  isAuthCodeConfirmed: boolean
  isIdCheckData?: boolean
  codeNotSame?: boolean
  setIsExpired?: any
  code?: string
}

function AuthCodeTimerText({
  isAuthCodeSend = false,
  isAuthCodeConfirmed = false,
  isIdCheckData = false,
  codeNotSame = false,
  setIsExpired,
  code,
}: CheckVerifyCodeInputProps) {
  const classes = useStyles()
  const {t} = useTranslation()

  const [status, setStatus] = useState<AuthStatus>('READY')
  const [remainTime, setRemainTime] = useState<number>(EXPIRE_MS)
  const remainTimeString = useMemo(
    () => msToMinuteString(remainTime),
    [remainTime],
  )

  const timerRef = useRef<number | null>()
  const expireDateRef = useRef<Date | null>()

  const stopTimer = () => {
    expireDateRef.current = null

    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = null

    setRemainTime(0)
  }

  const startTimer = () => {
    stopTimer()

    expireDateRef.current = new Date(Date.now() + EXPIRE_MS)
    // @ts-ignore
    timerRef.current = setInterval(() => {
      if (!expireDateRef.current) {
        return
      }

      const now = Date.now()
      const ms = expireDateRef.current.getTime() - now
      setRemainTime(ms)

      if (ms <= 0) {
        setStatus('EXPIRED')
      }
    }, 1000)
    setRemainTime(EXPIRE_MS)
  }

  useEffect(() => {
    if (isAuthCodeSend && !isAuthCodeConfirmed) {
      setStatus('PROCESS')
    } else if (isAuthCodeConfirmed) {
      setStatus('SUCCESS')
    } else if (isIdCheckData) {
      setStatus('CHECK')
    } else if (!isIdCheckData) {
      setStatus('READY')
    }
  }, [isAuthCodeSend, isAuthCodeConfirmed, isIdCheckData])

  useEffect(() => {
    console.log({status})
    if (status === 'PROCESS') {
      setIsExpired(false)
      startTimer()
    }
    if (status === 'SUCCESS') {
      stopTimer()
    }
    if (status === 'EXPIRED' && setIsExpired) {
      setIsExpired(true)
    }
  }, [status])

  if (status === 'READY') return null
  if (status === 'CHECK')
    return (
      <span className={classes.NotChecked}>{t('IEmailCheckRequired')}</span>
    )
  if (status === 'SUCCESS') {
    return (
      <span className={classes.Authorized}>{t('IAuthCodeConfirmSuccess')}</span>
    )
  }
  if (status === 'EXPIRED')
    return <span className={classes.Expired}>{t('IAuthCodeExpired')}</span>
  if (status === 'PROCESS')
    return (
      //
      <div>
        {isAuthCodeSend &&
          !isAuthCodeConfirmed &&
          codeNotSame &&
          code &&
          code.length > 0 && (
            <p className={classes.Retried}>{t('ICorrectCodeRequired')}</p>
          )}
        <p className={classes.Timer}>
          <span className='Label'>{t('IAuthCodeLimit')}</span>
          <ClockIcon className='Icon' />
          {remainTimeString}
        </p>
      </div>
    )
  return null
}

export default function CheckVerifyCodeInput({
  isAuthCodeSend,
  isAuthCodeConfirmed,
  isIdCheckData,
  codeNotSame,
}: CheckVerifyCodeInputProps) {
  const {
    formState: {errors},
  } = useFormContext()

  const [isExpired, setIsExpired] = useState<boolean>(false)
  const checkVerifyCode = useFormContext()
  const code = checkVerifyCode.watch('code')
  const classes = useStyles()
  const {t} = useTranslation()

  const authCodeClassNames = classNames({
    Right: true,
    InputText: true,
    // Error: errors.code !== undefined,
  })

  return (
    <label htmlFor='input_authCode' className={classes.inputWithLabel}>
      <div className='Container'>
        <span className='Left Label'>{t('IAuthCode')}</span>
        <input
          placeholder={t('IAuthCode')}
          className={authCodeClassNames}
          {...checkVerifyCode.register('code', {
            validate: (code) =>
              schema.validate({code}).catch((e) => e.errors[0]),
          })}
          disabled={isAuthCodeConfirmed || isExpired}
        />
        <Button
          size='medium'
          variant='contained'
          color='primary'
          disableElevation
          className='ConfirmButton'
          type='submit'
          disabled={isAuthCodeConfirmed || isExpired}
        >
          {t('IAuthCodeConfirm')}
        </Button>
      </div>
      <div className='Container'>
        <div className='Left' />
        {errors.code?.message && (
          <span className='Right ErrorText'>
            {t(`${errors.code?.message}`)}
          </span>
        )}
      </div>
      <div className='Container'>
        <div className='Left' />
        <span className='Right Timer'>
          <AuthCodeTimerText
            isAuthCodeSend={isAuthCodeSend}
            isAuthCodeConfirmed={isAuthCodeConfirmed}
            isIdCheckData={isIdCheckData}
            codeNotSame={codeNotSame}
            code={code}
            setIsExpired={(value: boolean) => setIsExpired(value)}
          />
        </span>
      </div>
    </label>
  )
}
