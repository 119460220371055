import React from 'react'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import RegisterMember from 'pages/BusinessManagement/MemberRegistration'

import {Redirect, Route, Switch} from 'react-router-dom'

/**
 * 인증 관련 라우트.
 */
const ManagementPage = () => (
  <CommonLayout>
    <Switch>
      {/* <Route
        path={RouteConstant.MANAGER_MEMBER_REGISTER.path}
        component={RegisterMember}
      /> */}
      <Route path='*'>
        <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
      </Route>
    </Switch>
  </CommonLayout>
)

export default ManagementPage
