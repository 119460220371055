import {AnalysisProduct, CanBePurchaseBundle} from 'api/paymentApi'
import PriceSetModel from './PriceSetModel'

export default class CanBePurchaseBundleModel implements CanBePurchaseBundle {
  name: string

  id: string

  priceSet: PriceSetModel

  analyses: [AnalysisProduct]

  analysisGroup: [string]

  description: string

  descLink: string | null

  constructor(raw: CanBePurchaseBundle) {
    this.name = raw.name
    this.id = raw.id
    this.priceSet = new PriceSetModel(raw.priceSet)
    this.analyses = raw.analyses
    this.analysisGroup = raw.analysisGroup
    this.description = raw.description
    this.descLink = raw.descLink
  }
}
