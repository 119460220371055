import {createStyles, withStyles} from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import {Colors} from 'components/common/useBaseStyle'

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: Colors.tableHoverBackground,
      color: Colors.text.content,
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    body: {
      fontSize: '0.90rem',
      fontWeight: 'normal',
      color: Colors.text.content,
    },
  }),
)(TableCell)

export default StyledTableCell
