import request from 'api/index'

export interface FetchPromotionListRequest {
  oid?: string | null
  page: number
  size: number
  searchName?: string | null
  searchValue?: string | null
  startDate?: string | null
  endDate?: string | null
}

//  none(""),
//     use_point("use point"),             // 사용: 사용자가 검사시 사용 포인트 - 사용
//     give_point("give point"),           // 후불 결제 고객을 위해 포인트 지급시 - 충전
//     restore_point("restore point"),     // 조정: 검사 실패시 복구 포인트 - 조정
//     deduct_point("deduct point"),       // 포인트 강제 차감시 - 사용
//     free_point("free point"),           // 무료 포인트 지급시 - 충전
//     buy_point("buy point"),             // 충전: 결제, 아이메디신 관리자가 계좌 이체 받아서 넣어주는 포인트 - 충전
//     cancel_point("cancel point");       // 환불: 결제 환불 포인트 - 환불

/**
 *
 * searchName 에서 서버로 보낼수 있는 type
 *   - PRODUCT : 패키지 이름
 *   - TYPE : 결제타입(use_point(사용)|give_point(충전)|restore_point(조정)|deduct_point(사용)|free_point(충전)|buy_point(충전))
 * @param
 * @returns
 */

export const fetchPromotionListApi = (query: FetchPromotionListRequest) =>
  request<PageResponse<PromotionHistory>>({
    method: 'get',
    path: '/api/point/v1/promotion/list',
    params: query,
  })
