import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import {ResultSendEmail, DetailInfo} from 'api/healthCareApi'
import TableCell from 'components/Table/TableCell'
import confirmMailStyle from 'components/Dialog/ConfirmEmail/style'

interface HeaderTable {
  id: number
  label: string
  content: string
}

const useLoadingStyle = makeStyles(confirmMailStyle)
const ConfirmEmail = ({
  number,
  resultSendEmail,
}: {
  number: number
  resultSendEmail: ResultSendEmail
}) => {
  const {t} = useTranslation()
  const styles = useLoadingStyle()
  const [showModal, setShowModal] = useState(false)
  const [listInfo, setListInfo] = useState<DetailInfo[]>([])
  const [total, setTotal] = useState<number>(0)
  const [fail, setFail] = useState<number>(0)
  const [completed, setCompleted] = useState<number>(0)
  const dividers = true
  const handleCleanClose = () => {
    setShowModal(false)
  }

  const HEADER_TABLE: HeaderTable[] = [
    {
      id: 0,
      label: 'IExamNumber',
      content: 'hcId',
    },
    {
      id: 1,
      label: 'ICustomerName',
      content: 'customerName',
    },
    {
      id: 2,
      label: 'IEmail',
      content: 'email',
    },
  ]

  const checkDataDisplay = (data: DetailInfo, info: string) => {
    if (info === 'customerName') {
      return t('IGetFullName', {
        firstName: data.patientFirstName,
        lastName: data.patientLastName,
      })
    }

    if (info === 'email') {
      return data.email ? data.email : t('INotRegistered')
    }

    return data.hcId
  }
  useEffect(() => {
    if (number > 0) {
      setShowModal(true)
    }
  }, [number])
  useEffect(() => {
    setTotal(resultSendEmail.total)
    setFail(resultSendEmail.fail)
    setCompleted(resultSendEmail.completed)
    if (resultSendEmail.detailInfo.length) {
      setListInfo([...resultSendEmail.detailInfo])
    } else {
      setListInfo([])
    }
  }, [resultSendEmail])
  if (!showModal) return null

  return (
    <Dialog
      open={showModal}
      onClose={handleCleanClose}
      className={styles.container}
    >
      <DialogContent dividers={dividers}>
        <Typography className={styles.title}>
          {t('IMailTransmissionResult')}
        </Typography>
        <div className={styles.info}>
          <span className={styles.content}>
            {t('ITotalSendEmail', {number: total})}
          </span>
          <span className={styles.content}>
            {t('ICompletedSendEmail', {number: completed})}
          </span>
          <span className={styles.hr} />
          <span className={styles.content}>
            {t('IFailSendEmail', {number: fail})}
          </span>
          <span className={styles.instruct}>{t('IInstructReSendEmail')}</span>
        </div>

        <TableContainer>
          <Table className={styles.table} size='small'>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                {HEADER_TABLE.map((item) => (
                  <TableCell align='center' padding='none' key={item.id}>
                    {t(item.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listInfo.map((data: DetailInfo) => (
                <TableRow key={data.hcId}>
                  {HEADER_TABLE.map((info: HeaderTable) => (
                    <TableCell align='center' padding='none' key={info.id}>
                      {checkDataDisplay(data, info.content)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.btnOk}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            className={styles.okButtonColor}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {t('IOk')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmEmail
