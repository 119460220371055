import fetcher, {FileDownloadRequest} from './fetcher'
import newFetcher from './newFetcher'

export const normalRequest = async <Model extends CommonResponse>(
  request: ApiRequest,
): Promise<Model> => fetcher.request(request)

const withRefreshRequest = async <Model extends CommonResponse>(
  request: ApiRequest,
): Promise<Model> => fetcher.authedRequest(request)

export default withRefreshRequest

export const fileRequest = async <Model extends CommonResponse>(
  request: ApiRequest,
): Promise<Model> => fetcher.fileRequest(request)

export const fileDownload = async (request: FileDownloadRequest) =>
  fetcher.fileDownload(request)

export const hasResponseFileDownload = async (
  request: FileDownloadRequest,
): Promise<boolean> => fetcher.hasResponseFileDownload(request)

export const fileDownloadData = async (request: FileDownloadRequest) =>
  fetcher.fileDownloadDataResponse(request)

export const newRequest = async <T>(request: ApiRequest): Promise<T> =>
  newFetcher.newRequest(request)

export const newNormalRequest = async <T>(request: ApiRequest): Promise<T> =>
  newFetcher.newAuthedRequest(request)

export const newFileRequest = async <Model extends CommonResponse>(
  request: ApiRequest,
): Promise<Model> => newFetcher.newFileRequest(request)
