import {createAction, createSlice} from '@reduxjs/toolkit'
import {OrderSteps} from 'pages/OrgPage/Interfaces/Enums'
import {RootState} from 'store'

export const changeOrderStep = createAction<number>('orderStep/change')

const initialOrgStep = OrderSteps.Management
export const orgPurchaseSlice = createSlice({
  name: 'orderStep',
  initialState: {orderStep: initialOrgStep},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeOrderStep, (state, action) => {
      state.orderStep = action.payload
    })
  },
})

export const selectOrderStep = (state: RootState) => state.orgPurchase.orderStep
export default orgPurchaseSlice.reducer
