import {
  confirm,
  confirmNoticeAction,
  OpenDialogPayload,
  reject,
  selectNoticeConfirm,
} from 'features/modal/noticeConfirmSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useNoticeConfirm() {
  const dispatch = useAppDispatch()

  const onOpen = async (payload: OpenDialogPayload) => {
    return dispatch(confirmNoticeAction(payload))
  }

  const onConfirm = () => dispatch(confirm())

  const onReject = () => dispatch(reject())

  const {title, message, isOpened, isConfirmed, isDeclined} =
    useAppSelector(selectNoticeConfirm)

  return {
    open: isOpened,
    isConfirmed,
    isDeclined,
    title,
    message,
    onOpen,
    onConfirm,
    onReject,
  }
}
