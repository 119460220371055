import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function AddressInput() {
  const createMemberInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IAddress')} </p>
        <p className={classes.require}>*</p>
      </div>
      <input
        placeholder={t('IRegisterIAddress')}
        className={classes.input}
        autoComplete='off'
        {...createMemberInfo.register('customerPo', {
          required: `${t('IRequired')}`,
        })}
      />
      {errors.customerPo && (
        <span className={classes.errorText}>{errors.customerPo.message}</span>
      )}
    </div>
  )
}
