import {TFunction} from 'react-i18next'
import {AvailableProducts} from 'api/paymentApi'
import CanBePurchasePackageModel from './CanBePurchasePackageModel'
import CanBePurchaseBundleModel from './CanBePurchaseBundleModel'

export default class AvailableProductModel implements AvailableProducts {
  packages: [CanBePurchasePackageModel]

  bundles: [CanBePurchaseBundleModel]

  constructor(raw: AvailableProducts, t: TFunction) {
    this.packages = raw.packages.map((item) => {
      return new CanBePurchasePackageModel(item, t)
    }) as [CanBePurchasePackageModel]

    this.bundles = raw.bundles.map((item) => {
      return new CanBePurchaseBundleModel(item)
    }) as [CanBePurchaseBundleModel]
  }
}
