export interface Options {
  from: number
  till: number
  type?: string
}
interface TypeMap {
  text: string
  arraybuffer: string
}

export default class FileResource {
  file: File | null

  name: string

  isLocal: boolean

  fileLeader: FileReader

  typeMap: TypeMap

  url?: string

  constructor(file: File, url?: string) {
    this.file = file ?? null
    this.name = this.file.name
    this.isLocal = true
    this.fileLeader = new FileReader()
    this.typeMap = {
      text: 'readAsText',
      arraybuffer: 'readAsArrayBuffer',
    }
    this.url = url ?? ''
  }

  // edf file 읽어오는 클래스
  fileRead(options: Options): Promise<any | undefined> {
    if (!this.file) throw new Error('no file')
    const type = options.type ? this.typeMap.arraybuffer : this.typeMap.text
    const from = options.from || 0
    const till = options.till || this.file.size
    const blob = this.file.slice(from, till)

    const promise = new Promise((resolve) => {
      this.fileLeader.onload = (event) => {
        resolve(event.target?.result)
      }
    })
    // @ts-ignore
    this.fileLeader[type](blob)

    return promise
  }
}
