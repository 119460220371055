import {TFunction} from 'react-i18next'
import {MyProducts} from '../../Interfaces/MyProducts'
import MyBundleModel from './MyBundleModel'
import MyPackageModel from './MyPackageModel'

export default class MyProductsModel implements MyProducts {
  bundles: MyBundleModel[]

  packages: MyPackageModel[]

  constructor(raw: MyProducts, t: TFunction) {
    this.bundles = raw.bundles.map((item) => new MyBundleModel(item, t))
    this.packages = raw.packages.map((item) => new MyPackageModel(item, t))
  }
}
// export interface  {
//   analyses: [string]
//   analysisGroup: [string]
//   name: string
//   bundleId: string
//   remainAmount: number | null
// }

// export interface  {
//   analyses: [string]
//   analysisGroup: [string]
//   expiresAt: Date
//   givenAmount: number | null
//   name: string
//   packageId: string
//   remainAmount: number | null
//   subscriptionPeriod: number
// }

// export interface  {
// }
