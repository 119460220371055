import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {IndustryV2} from 'api/authApi'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function SelectedIndustry() {
  const inputClasses = useBoldInputStyle()
  const {t} = useTranslation()

  const [industry, setIndustry] = React.useState<IndustryV2>({
    id: 0,
    title: '',
  })

  const handleDivisionChanged = (industry: Industry) => {
    if (industry.id) {
      setIndustry({id: industry.id, title: industry.title})
    }
  }

  const {
    formState: {errors},
    register,
  } = useFormContext()

  return (
    <>
      <div className={inputClasses.inputContainer}>
        <div style={{flex: 1}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <p className={inputClasses.inputLabel}>{t('IIndustry')}</p>
            <p className={inputClasses.require}>*</p>
          </div>
          <SelectBoldIndustryWrapper
            parent={0}
            depth={1}
            selectedValue={t('ISelectRequired')}
            onChangeData={handleDivisionChanged}
            {...register('industry')}
          />
          {errors.industry && (
            <span className={inputClasses.errorText}>
              {errors.industry.message}
            </span>
          )}
        </div>
        <div style={{flex: 1}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <p className={inputClasses.inputLabel}>{t('IIndustryDetail')}</p>
            <p className={inputClasses.require}>*</p>
          </div>

          <SelectBoldIndustryWrapper
            onChangeData={() => {}}
            parent={industry.id ?? 0}
            depth={2}
            selectedValue={t('ISelectRequired')}
            {...register('industryDetail')}
          />
          {errors.industryDetail && (
            <span className={inputClasses.errorText}>
              {errors.industryDetail.message}
            </span>
          )}
        </div>
      </div>
    </>
  )
}
