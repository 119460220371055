import {yupResolver} from '@hookform/resolvers/yup'
import {DialogActions, IconButton} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {navigate} from 'AppHistory'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import usePinDialogStyles from 'components/Dialog/PinDialogStyle'
import useAuth from 'features/auth/useAuth'
import {
  closeDepartmentAddDialog,
  selectDepartmentAddDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrgDepartment from 'features/org/useOrgDepartment'
import {isNotDefined} from 'helpers/commonHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

interface FormData {
  title: string
}

function AddDialog() {
  const {t} = useTranslation()
  const classes = usePinDialogStyles()
  const dispatch = useAppDispatch()

  const {user} = useAuth()
  const {onCreate} = useOrgDepartment()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessAlertOpen} = useSuccessModal()

  const isOpen = useAppSelector(selectDepartmentAddDialogOpen)
  const closeDialog = () => dispatch(closeDepartmentAddDialog())

  const AddSchema = yup.object().shape({
    title: yup
      .string()
      .required(t('IContentsRequired'))
      .min(2, t('IBetween2to50'))
      .max(50, t('IBetween2to50')),
  })

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    clearErrors,
  } = useForm<FormData>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })

  const onSubmit = async (data: FormData) => {
    if (isNotDefined(user)) {
      navigate('/auth/login')
      return
    }

    try {
      await onCreate({...data, uid: user.uid})
      reset()
      onSuccessAlertOpen(t('IProcessSuccess'))
      closeDialog()
    } catch (err) {
      onFailureModalOpen(err.message)
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      clearErrors()
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{margin: 0}}>
          <div className={classes.longTitleContainer}>
            <div className={classes.iconTitleWrap}>
              <Typography variant='h6'>{t('IDepartmentCreate')}</Typography>
            </div>
            <IconButton aria-label='search' onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <Divider />
        <DialogContent>
          <div
            style={{
              display: 'flex',
              width: 300,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
              marginBottom: 10,
            }}
          >
            <div
              style={{display: 'flex', marginTop: '14px', alignItems: 'center'}}
            >
              <Typography color='error'>*</Typography>
              <Typography>{t('IDepartmentName')}</Typography>
            </div>

            <Controller
              name='title'
              control={control}
              render={({field}) => (
                <TextField
                  id='title'
                  variant='outlined'
                  size='small'
                  // required
                  error={!!errors.title}
                  placeholder={t('IDepartmentName')}
                  helperText={errors.title?.message}
                  className={classes.pinNumberTextField}
                  {...field}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actionButtonWrap}>
          <ConfirmButton fullWidth type='submit' />
          <CancelButton fullWidth onClick={closeDialog} />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddDialog
