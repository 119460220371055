import {CareList} from 'features/treatment/useTreatmentDialog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const StyledConfirmTable = styled.table`
  width: 100%;
  border: 1px solid #e6e7eb;
  border-collapse: collapse;

  & thead {
    background-color: #eff0f5;
    border: 1px solid #e6e7eb;
  }

  & th,
  & td {
    text-align: center;
    padding: 10px;
    border: 1px solid #e6e7eb;
  }

  & th:last-child {
    min-width: 200px;
  }
`

interface MultiIcaResultTableProps {
  items: CareList[]
}

function MultiEmailSendResultTable({items}: MultiIcaResultTableProps) {
  const {t} = useTranslation()
  return (
    <StyledConfirmTable>
      <thead>
        <tr>
          <th>{t('IAnalysisNumber')}</th>
          <th>{t('IProgress')}</th>
          <th>{t('IDescription')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.careId}>
            <td>{item.careId}</td>
            <th>
              {item.errorMsg === '' ? t('IProcessSuccess') : t('IProcessFail')}
            </th>
            {item.errorMsg !== '' && <td>{t('ISendDataIsEmpty')}</td>}
          </tr>
        ))}
      </tbody>
    </StyledConfirmTable>
  )
}

export default MultiEmailSendResultTable
