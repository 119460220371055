import {CareSummaryReport} from 'api/analysisApi'
import {Roles} from 'Authority'
import MCIReportPage from 'components/molcules/MciReport/MCIReportPage'
import CustomerInfo from 'components/molcules/N1SummaryReport/CustomerInfo'
import NoneProfitEEGSummary from 'components/molcules/N1SummaryReport/EEG/EEGSummary'
import NoneChildProfitSummary from 'components/molcules/N1SummaryReport/EEGChild/EEGSummary'
import NoneProfitHRVSummary from 'components/molcules/N1SummaryReport/HRV/HRVSummary'
import ProfitEEGSummary from 'components/molcules/ProfitSummaryReport/EEG/EEGSummary'
import ProfitChildSummary from 'components/molcules/ProfitSummaryReport/EEGChild/EEGSummary'
import ProfitHRVSummary from 'components/molcules/ProfitSummaryReport/HRV/HRVSummary'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import useAuth from 'features/auth/useAuth'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import Settings from 'pages/OrgPage/TreatmentPage/TreatmentSummary/Settings'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

function EmptyResult() {
  const {t} = useTranslation()

  return <div>{t('IReportEmpty')}</div>
}

interface ReportProps extends CareSummaryReport {}

function Report({
  ec,
  eo,
  hrv,
  mci,
  customer,
  requestId,
  eoRequestId,
}: ReportProps) {
  const [tabIndex, setTabIndex] = useState(0)
  const {user: currentUser} = useAuth()

  const handleTabChanged = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setTabIndex(newValue)
  }

  const isDownloadable =
    isDefined(ec) || isDefined(eo) || isDefined(hrv) || isDefined(mci)
  const userAuthority = currentUser?.authority ?? ''
  const isNotMaster = userAuthority !== Roles.ROLE_COMPANY_MASTER

  // analysisNo,
  //     ecAnalysisNo,
  //     eoAnalysisNo,

  const fnEeEo = function (ec, eo) {
    let res = ec
    if (ec != null && eo != null) {
      if (ec.finalAnalysisYn === 'Y') {
        res = ec
      } else if (eo.finalAnalysisYn === 'Y') {
        res = eo
      }
    } else if (ec !== null && ec.finalAnalysisYn === 'Y') {
      res = ec
    } else if (eo !== null && eo.finalAnalysisYn === 'Y') {
      res = eo
    }

    return res
  }

  return (
    <div>
      <Tabs value={tabIndex} onChange={handleTabChanged}>
        <Tab label='EEG' />
        <Tab label='HRV' />
        <Tab label='MCI' />
      </Tabs>
      <TabPanel index={0} value={tabIndex}>
        {isNotDefined(ec) && isNotDefined(eo) && <EmptyResult />}
        {isDefined(ec) &&
          isDefined(eo) &&
          fnEeEo(ec, eo).reportType === 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <NoneProfitEEGSummary
              {...fnEeEo(ec, eo)}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <NoneChildProfitSummary
              client={customer}
              {...fnEeEo(ec, eo)}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}
        {isDefined(ec) &&
          isDefined(eo) &&
          fnEeEo(ec, eo).reportType !== 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <ProfitEEGSummary
              {...fnEeEo(ec, eo)}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <ProfitChildSummary
              client={customer}
              {...fnEeEo(ec, eo)}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}

        {isDefined(ec) &&
          isNotDefined(eo) &&
          ec.reportType === 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <NoneProfitEEGSummary
              {...ec}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <NoneChildProfitSummary
              client={customer}
              {...ec}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}
        {isDefined(ec) &&
          isNotDefined(eo) &&
          ec.reportType !== 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <ProfitEEGSummary
              {...ec}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <ProfitChildSummary
              client={customer}
              {...ec}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}
        {isNotDefined(ec) &&
          isDefined(eo) &&
          eo.reportType === 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <NoneProfitEEGSummary
              {...eo}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <NoneChildProfitSummary
              client={customer}
              {...eo}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}
        {isNotDefined(ec) &&
          isDefined(eo) &&
          eo.reportType !== 'N1' &&
          (customer.treatmentAge >= 20 ? (
            <ProfitEEGSummary
              {...eo}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ) : (
            <ProfitChildSummary
              client={customer}
              {...eo}
              CustomerCard={
                <CustomerInfo
                  requestId={requestId}
                  eoRequestId={eoRequestId}
                  analysisNo={fnEeEo(ec, eo).id}
                  ecAnalysisNo={fnEeEo(ec, eo).id}
                  eoAnalysisNo={fnEeEo(ec, eo).id}
                  analysisTime={fnEeEo(ec, eo).treatmentAt}
                  {...customer}
                />
              }
            />
          ))}
      </TabPanel>
      <TabPanel index={1} value={tabIndex}>
        {isNotDefined(hrv) && <EmptyResult />}
        {isDefined(hrv) && hrv.reportType === 'N1' && (
          <NoneProfitHRVSummary
            {...hrv}
            customerName={customer.clientName}
            CustomerCard={
              <CustomerInfo
                analysisNo={hrv.id}
                analysisTime={hrv.treatmentAt}
                {...customer}
              />
            }
            customerFirstName={customer.clientFirstName ?? ''}
            customerLastName={customer.clientLastName ?? ''}
          />
        )}
        {isDefined(hrv) && hrv.reportType !== 'N1' && (
          <ProfitHRVSummary
            {...hrv}
            customerName={customer.clientName}
            CustomerCard={
              <CustomerInfo
                analysisNo={hrv.id}
                analysisTime={hrv.treatmentAt}
                {...customer}
              />
            }
            clientFirstName={customer.clientFirstName ?? ''}
            clientLastName={customer.clientLastName ?? ''}
          />
        )}
      </TabPanel>
      <TabPanel index={2} value={tabIndex}>
        {isNotDefined(mci) && <EmptyResult />}
        {isDefined(mci) && (
          <div style={{paddingBottom: 100}}>
            <CustomerInfo
              analysisNo={mci.id}
              analysisTime={mci.treatmentAt ?? ''}
              {...customer}
            />
            <MCIReportPage
              MciImagePath={mci.imagePath}
              probability={mci.probability}
              clientName={customer.clientName}
              clientFirstName={customer.clientFirstName ?? ''}
              clientLastName={customer.clientLastName ?? ''}
            />
          </div>
        )}
      </TabPanel>
      {isDownloadable && isNotMaster && (
        <Settings
          ecFilePath={ec?.finalReportPath}
          eoFilePath={eo?.finalReportPath}
          hrvFilePath={hrv?.finalReportPath}
          mciFilePath={mci?.finalReportPath}
        />
      )}
    </div>
  )
}

export default Report
