// eslint-disable-next-line no-restricted-imports
import FormControl from '@material-ui/core/FormControl'
import Select, {SelectProps} from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {fetchOrgDepartmentApi} from 'api/organizationApi'
import SelectError from 'components/Select/SelectError'
import useStyles from 'components/Select/selectFormStyle'
import SelectLoading from 'components/Select/SelectLoading'
import useAsyncList from 'hooks/useAsyncList'
import React, {forwardRef} from 'react'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import {useTranslation} from 'react-i18next'
import {OutlinedInput} from '@material-ui/core'

interface SelectAnalysisVersionProps extends SelectProps {
  selectedValue: string | null
  onChangeData: (data: string) => void
}

const SelectBoldDepartment = forwardRef(
  (props: SelectAnalysisVersionProps, ref) => {
    const {type, selectedValue, onChangeData, onChange, ...selectProps} = props
    const outlinedInputClasses = useOutlinedInputStyles()
    const {t} = useTranslation()
    const classes = useStyles()
    const [state, fetch] = useAsyncList<Department>(
      () => fetchOrgDepartmentApi(),
      [type],
    )
    const {data, loading, error} = state

    if (loading) return <SelectLoading />
    if (error) return <SelectError error={error} fetch={fetch} />

    return (
      <FormControl classes={{root: classes.root}}>
        <Select
          autoWidth
          variant='outlined'
          displayEmpty
          classes={{select: classes.root}}
          onChange={(e) => {
            const departmentId = e.target.value as number
            const department = data.find((item) => item.id === departmentId)
            if (onChangeData) onChangeData(department?.name ?? '')
            if (onChange) onChange(e, null)
          }}
          {...selectProps}
          ref={ref}
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          {selectedValue && <MenuItem disabled>{selectedValue}</MenuItem>}
          {!selectedValue && (
            <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
          )}

          {data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  },
)

export default SelectBoldDepartment
