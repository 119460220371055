import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import useStyles from 'components/Select/selectFormStyle'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

interface SelectErrorProps {
  error: any
  fetch?: VoidPromiseFunc
}

export default function SelectBoldError({error, fetch}: SelectErrorProps) {
  const classes = useStyles()
  const {t} = useTranslation()
  useEffect(() => {
    console.log({error})
  }, [error])
  return (
    <div className={classes.boldNoSelect}>
      <span>{t('ISelectRequired')}</span>
      {fetch && (
        <IconButton onClick={fetch} className={classes.iconButton} size='small'>
          <RefreshIcon />
        </IconButton>
      )}
      {!fetch && <CloseIcon className={classes.iconButton} />}
    </div>
  )
}
