import React, {useRef, useEffect, ReactElement, useState} from 'react'

interface PrintComponentProps {
  components: ReactElement[]
}

const PrintComponent: React.FC<PrintComponentProps> = ({components}) => {
  const printRef = useRef<HTMLDivElement>(null)
  const [displayPrint, setDisplayPrint] = useState(false)
  const print = () => {
    if (printRef.current) {
      window.print()
      setDisplayPrint(false)
    }
  }

  useEffect(() => {
    setDisplayPrint(false)
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'p') {
        e.preventDefault()
        setDisplayPrint(true)
        setTimeout(() => {
          print()
        }, 1500)
      }
    }
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <div>
      {displayPrint && (
        <div id='print-content' ref={printRef}>
          {components.map((Component, index) => (
            <div key={index} className='print-page'>
              {Component}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PrintComponent
