import {OutlinedInput, Select} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import {SelectProps} from '@material-ui/core/Select'
import {fetchCountryList} from 'api/commonApi'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

interface SelectCountryProps extends SelectProps {
  className?: string
}

const SelectBoldCountryNumber = (props: SelectCountryProps) => {
  const {t} = useTranslation()
  const {className, ...selectProps} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  //   const [countryList, setCountryList] = useState<SelectItem<String>[]>([])
  const [countryNumberList, setNumberCountryList] = useState<Country[]>([])
  const outlinedInputClasses = useOutlinedInputStyles()

  const handleFetchCountryList = async () => {
    const response = await fetchCountryList()
    if (response.success) {
      setNumberCountryList([...response.list])
      setLoading(false)
    } else {
      setLoading(true)
      setError('error')
    }
  }

  useEffect(() => {
    setLoading(true)
    handleFetchCountryList()
  }, [])

  useEffect(() => {}, [])

  if (loading) {
    return <div>{t('common.loading')}</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Select
      variant='outlined'
      displayEmpty
      input={
        <OutlinedInput
          name='country'
          id='outlined-country-simple'
          classes={outlinedInputClasses}
        />
      }
      className={className}
      {...selectProps}
    >
      <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
      {countryNumberList.map((item) => (
        <MenuItem key={item.code} value={item.code}>
          {item.name} +{item.number}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectBoldCountryNumber
