import React, {useEffect, useState} from 'react'
import {
  AnalysisResultItem,
  IcaAnalysisResultIndicator,
  TypeScreenGraph,
  TypeGraph,
} from 'api/analysisApi'
import {WaveGraphPropertySourceImpl} from 'lib/GraphSource'
import ReportHeader from 'components/Report/ReportHeader'
import LeftChannel from 'assets/img/LeftChannel.jpeg'
import RawDataEdfGraphV1 from 'components/molcules/Report/RawData/RawDataEdfGraphV1'
import {HorizontalImageGraphV1} from 'components/molcules/Report/RawData/HorizontalImageGraphV1'
import styled from 'styled-components'

interface RawDataProps {
  indicator: IcaAnalysisResultIndicator
  items: AnalysisResultItem[]
  yAxisImage?: string
}

export const GraphContainer = styled.div`
  margin: 0;
  overflow-x: scroll;
  min-height: 608px; // ch h 32 * 19
`
export interface WaveGraphRawProps {
  url?: string
  usingCache?: boolean
}

export interface WaveGraphProps {
  raw: WaveGraphRawProps
  image?: string
  yAxisImage?: string
  propertyGuide: WaveGraphPropertySourceImpl
  widthPercent?: number
  screen: TypeScreenGraph
  typeGraph: TypeGraph
}
export const WaveGraph = ({
  raw,
  image,
  propertyGuide,
  widthPercent,
  screen,
  typeGraph,
}: WaveGraphProps) => {
  const [containerWidthForGraph, setContainerWidthForGraph] = useState(0)

  useEffect(() => {
    const w = widthPercent ?? 85
    const handleResizeEdfContainer = () => {
      const containerWidth = window.innerWidth * (w / 100)
      setContainerWidthForGraph(containerWidth)
    }

    // Add an event listener for window resize
    window.addEventListener('resize', handleResizeEdfContainer)

    // Call the handleResize function initially to set the initial width
    handleResizeEdfContainer()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResizeEdfContainer)
    }
  }, [])

  const {url: rawUrl, usingCache} = raw

  return (
    <GraphContainer>
      {rawUrl !== undefined ? (
        <RawDataEdfGraphV1
          edfUrl={rawUrl}
          width={containerWidthForGraph}
          xAxisImg={propertyGuide.xAxisImage}
          usingCache={usingCache}
          propertyGuide={propertyGuide}
          screen={screen}
          typeGraph={typeGraph}
        />
      ) : (
        <HorizontalImageGraphV1
          width={containerWidthForGraph}
          xAxisImg={image ?? ''}
          yAxisImg={propertyGuide.yAxisImage}
        />
      )}
    </GraphContainer>
  )
}

function IcaRawData({
  indicator,
  items,
  yAxisImage = LeftChannel,
}: RawDataProps) {
  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
    </div>
  )
}

export default IcaRawData
