// eslint-disable-next-line no-restricted-imports
import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select, {SelectProps} from '@material-ui/core/Select'
import {fetchCommonIndustryListApi} from 'api/orgApi'
import useStyles from 'components/Select/selectFormStyle'
import SelectLoading from 'components/Select/SelectLoading'
import useAsyncList from 'hooks/useAsyncList'
import React, {forwardRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import SelectBoldError from './SelectBoldError'

interface SelectIndustryProps extends SelectProps {
  parent: number
  depth: number
  selectedValue: string | null
  onChangeData: (data: Industry) => void
  className?: string
  defaultValue?: number
}

const SelectIndustry = forwardRef((props: SelectIndustryProps, ref) => {
  const {
    parent,
    depth,
    selectedValue,
    onChange,
    onChangeData,
    className,
    defaultValue,
    ...selectProps
  } = props
  const {t} = useTranslation()
  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const [state, fetch] = useAsyncList<Industry>(
    () =>
      fetchCommonIndustryListApi({
        depth,
        parent,
      }),
    [parent, depth, defaultValue],
  )
  const {data, loading, error} = state

  if (loading) return <SelectLoading />
  if (error) return <SelectBoldError error={error} fetch={fetch} />

  return (
    <FormControl style={{flex: 1, width: '100%'}}>
      <Select
        fullWidth
        variant='outlined'
        displayEmpty
        className={classes.selectBox}
        defaultValue={defaultValue ?? 0}
        style={{width: '100%'}}
        input={
          <OutlinedInput
            name='country'
            id='outlined-country-simple'
            classes={outlinedInputClasses}
          />
        }
        onChange={(e) => {
          const departmentId = e.target.value as number
          const department = data.find((item) => item.id === departmentId)
          if (onChangeData)
            onChangeData(department ?? {id: 0, parent: 0, depth: 0, title: ''})
          if (onChange) onChange(e, null)
        }}
        {...selectProps}
        ref={ref}
      >
        {selectedValue && <MenuItem disabled>{selectedValue}</MenuItem>}
        {!selectedValue && <MenuItem disabled>{t('ISelectRequired')}</MenuItem>}
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

interface SelectIndustryWrapperProps
  extends Optional<SelectIndustryProps, 'parent'> {
  requiredErrorMessage?: string
}

const SelectBoldIndustryWrapper = forwardRef(
  (props: SelectIndustryWrapperProps, ref) => {
    const {parent, depth, requiredErrorMessage, className, ...selectProps} =
      props
    return (
      <SelectIndustry
        parent={parent ?? 0}
        depth={depth}
        {...selectProps}
        ref={ref}
      />
    )
  },
)

export default SelectBoldIndustryWrapper
