import {useTranslation} from 'react-i18next'
import {SearchOrgRequest, SearchedCustomerOrg} from 'api/invoiceApis'
import {useAppDispatch, useAppSelector} from 'hooks'
import {openOrgCustomerManagementDialog} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import {
  searchCustomerOrg,
  selectError,
  selectLoading,
  selectQuery,
  selectSearch,
  selectPaging,
  selectListOrg,
  selectPagingInfo,
  selectOrgSelected,
  setOrgSelected,
  OrgType,
  setListOrg,
} from './invoiceInquirySlice'

export default function useCustomerOrg() {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectListOrg)
  const paging = useAppSelector(selectPaging)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const orgSelected = useAppSelector(selectOrgSelected)
  const initOrgSelected: OrgType = {
    orgId: null,
    lastName: '',
    firstName: '',
    name: '',
    email: '',
    countryId: null,
    phoneNumber: '',
    industryId: null,
    detailIndustryId: null,
    address: '',
    businessNumber: '',
    institutionNumber: '',
    createdAt: '',
    orgType: '',
    orgName: '',
    healthProductInfoList: [],
  }
  const onSearch = async (payload: SearchOrgRequest) => {
    const res = await dispatch(searchCustomerOrg(payload))
    const payloadInfo = res.payload as {
      content: SearchedCustomerOrg[]
      pageable: Pageable
      first: boolean
      last: boolean
      totalPages: number
      totalElements: number
      size: number
    }
    if (!payloadInfo.content.length) {
      onFailureModalOpen(t('ISearchORGEmpty'))
    }
  }

  const changeSelectedOrg = (listOrg: OrgType[]) => {
    if (listOrg.length > 1) dispatch(openOrgCustomerManagementDialog())
    else if (listOrg.length === 1) dispatch(setOrgSelected(listOrg[0]))
    else dispatch(setOrgSelected(initOrgSelected))
  }

  const resetListOrg = () => {
    dispatch(setListOrg([]))
    dispatch(setOrgSelected(initOrgSelected))
  }

  return {
    loading,
    error,
    items,
    search,
    paging,
    query,
    pagingInfo,
    orgSelected,
    onSearch,
    changeSelectedOrg,
    resetListOrg,
  }
}
