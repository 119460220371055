import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  getPresetApi,
  postPresetApi,
  putPresetApi,
  deletePresetApi,
  PresetGetRequest,
  PostPresetData,
  PutPresetData,
  DeletePresetData,
  PresetListData,
} from 'api/orgApi'
import {RootState} from 'store'

export const fetchPresetListAction = createAsyncThunk(
  'api/org/preset/list',
  async (payload: PresetGetRequest) => {
    const response = await getPresetApi(payload)
    return response.list
  },
)

export const fetchPresetCreateAction = createAsyncThunk(
  'api/org/preset/create',
  async (payload: PostPresetData) => {
    const response = await postPresetApi(payload)
    return response.data
  },
)

export const fetchPresetUpdateAction = createAsyncThunk(
  'api/org/preset/update',
  async (payload: PutPresetData) => {
    const response = await putPresetApi(payload)
    return response.data
  },
)

export const fetchPresetDeleteAction = createAsyncThunk(
  'api/org/preset/delete',
  async (payload: DeletePresetData) => {
    const result = await deletePresetApi(payload)
    return result
  },
)

interface OrgPresetsState {
  presets: PresetListData[]
  loading: boolean
  error: unknown
}

const initialState: OrgPresetsState = {
  presets: [],
  loading: false,
  error: undefined,
}

export const orgPresetsSlice = createSlice({
  name: 'org_department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // For get presets
    builder.addCase(fetchPresetListAction.pending, (state) => {
      state.loading = false
      state.error = undefined
      state.presets = []
    })
    builder.addCase(fetchPresetListAction.fulfilled, (state, action) => {
      state.loading = false
      state.presets = action.payload
    })
    builder.addCase(fetchPresetListAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    // For post preset
    builder.addCase(fetchPresetCreateAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPresetCreateAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(fetchPresetCreateAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    // For update preset
    builder.addCase(fetchPresetUpdateAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPresetUpdateAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(fetchPresetUpdateAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    // For delete preset
    builder.addCase(fetchPresetDeleteAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPresetDeleteAction.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(fetchPresetDeleteAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default orgPresetsSlice.reducer

export const selectOrgPresetsList = (state: RootState) =>
  state.orgPresets.presets
export const selectOrgPresetsLoading = (state: RootState) =>
  state.orgPresets.loading
export const selectOrgPresetsError = (state: RootState) =>
  state.orgPresets.loading
