import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import CardWithTitle from 'components/atoms/CardWithTitle'
import useAuth from 'features/auth/useAuth'
import {
  fetchSalesDetailAction,
  fetchSalesIndexSearchAction,
  searchSalesOrgUserAction,
  selectSales,
} from 'features/sales/salesSlice'
import {useAppDispatch} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
  },
  gridContainer: {
    marginBottom: 30,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > h6': {
      fontSize: '1.0rem',
    },
  },
  contentTitleWrap: {
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    '& > h6': {
      fontSize: '1.0rem',
    },
    '& > .MuiFormLabel-root.Mui-disabled': {
      color: 'red',
    },
  },
  inputRoot: {
    '&$disabled': {
      color: 'red',
    },
  },

  titleSize: {
    fontSize: '1.0rem',
  },
  titleCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
    '& > h6': {
      fontSize: '1.0rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& button': {
      minWidth: 200,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  rootText: {
    '&$disabled': {
      color: 'white',
    },
  },
  disabled: {},
  background: {
    color: 'primary',
    '& span': {
      background: '#E0E0E0',
    },
  },

  textField: {
    '& input:disabled': {
      color: '#000',
    },
    '& textarea:disabled': {
      color: '#000',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  direction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  table: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& div': {
      textAlign: 'center',
      border: '1px solid #e7eaec',
      width: 40,
      height: 40,
      margin: 'auto',
    },
  },
  tableInner: {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '&:hover': {
      backgroundColor: '#F8F8F8 !important',
      cursor: 'pointer',
    },
  },
  tableMargin: {
    marginTop: 10,
  },
  hospital: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  hospitalName: {
    fontWeight: 'bolder',
    color: '#555555',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}))
export default function SalesIndividualSearch() {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const {user: currentUser} = useAuth()
  const searchData = useSelector(selectSales)

  const krRows = [
    'ㄱ',
    'ㄴ',
    'ㄷ',
    'ㄹ',
    'ㅁ',
    'ㅂ',
    'ㅅ',
    'ㅇ',
    'ㅈ',
    'ㅊ',
    'ㅋ',
    'ㅍ',
    'ㅌ',
    'ㅎ',
  ]

  const enRows = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]

  const handleIndividualSearch = (row: string) => {
    dispatch(
      fetchSalesIndexSearchAction({
        index: row,
      }),
    )
  }

  const getUserDetailList = async (uid: string) => {
    await dispatch(
      searchSalesOrgUserAction({
        uid,
        search: {searchName: null, searchValue: ''},
        paging: {size: 10, page: 0},
      }),
    )
  }

  const handleDetailSearch = async (oid: string, uid: string) => {
    const detailAction = await dispatch(
      fetchSalesDetailAction({
        uid,
        oid,
      }),
    ).unwrap()
    if (detailAction?.success) {
      getUserDetailList(detailAction.data.userInfoList[0].uid)
    }
  }

  return (
    <CardWithTitle title={t('IIndividualSearch')}>
      <Grid>
        <Grid xs={12}>
          <div className={classes.direction}>
            {krRows.map((row) => (
              <Grid
                key={row}
                className={classes.table}
                onClick={() => {
                  handleIndividualSearch(row)
                }}
              >
                <div className={classes.tableInner} key={row}>
                  <Typography variant='subtitle1' key={row}>
                    {row}
                  </Typography>
                </div>
              </Grid>
            ))}
          </div>
        </Grid>
        <Typography className={classes.padding} />
        <Grid xs={12}>
          <div className={classes.direction}>
            {enRows.map((row) => (
              <Grid
                key={row}
                className={classes.table}
                onClick={() => {
                  handleIndividualSearch(row)
                }}
              >
                <div className={classes.tableInner} key={row}>
                  <Typography variant='subtitle1' key={row}>
                    {row}
                  </Typography>
                </div>
              </Grid>
            ))}
          </div>
        </Grid>

        <Typography className={classes.padding} />
        <Grid item xs={12} className={classes.tableMargin}>
          {searchData.searchIndexList != null && (
            <div className={classes.hospital}>
              {searchData.searchIndexList.map((data: any) => (
                <Typography
                  key={data.id}
                  variant='subtitle1'
                  className={classes.hospitalName}
                  onClick={() => {
                    handleDetailSearch(data.oid, currentUser?.uid ?? '')
                  }}
                >
                  {data.name}
                </Typography>
              ))}
            </div>
          )}
        </Grid>
        <Grid item xs={12} className={classes.tableMargin}>
          <Typography className={classes.padding} />
        </Grid>
      </Grid>
    </CardWithTitle>
  )
}
