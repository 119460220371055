import React from 'react'
import closeIcon from 'assets/img/close-icon.png'
import TextTitle from 'components/V2Text/TextTitle'
import styled from 'styled-components'
import {
  CustomTitleModalPageTitleStyles,
  ModalPageTitleStyles,
} from 'components/V2Text/Styles'
import Media from 'theme/Media'
import {
  CloseButtonWrapStyleProps,
  ModalAllStyleProps,
  ModalHeaderWrapStyleProps,
  ModalCommonStyleProps,
} from '../Styles'

const StyledModalHeaderWrap = styled.div<
  ModalHeaderWrapStyleProps & ModalCommonStyleProps
>`
  display: flex;
  justify-content: ${(props) => props.textAlign || 'space-between'};
  padding-top: ${(props) => props.paddingHeaderTopMobile}px;
  padding-bottom: ${(props) => props.paddingHeaderBottomMobile}px;
  padding-left: ${(props) => props.paddingLeftMobile}px;
  padding-right: ${(props) => props.paddingRightMobile}px;
  position: relative;
  border-bottom: ${(props) => props.brHeaderBottom};
  margin-bottom: 5px;

  ${(props) => Media.min_601`
    padding-top: ${props.paddingHeaderTopTablet}px;
    padding-right: ${props.paddingRightTablet}px;
    padding-bottom: ${props.paddingHeaderBottomTablet}px;
    padding-left: ${props.paddingLeftTablet}px;
  `}

  h2,
    h3, h4, h5 {
    text-align: center;
    margin-block: 0;
  }

  .h5 {
    font-size: 15px;
  }
`

const StyledCloseButtonWrap = styled.button<CloseButtonWrapStyleProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: ${(props) => props.closeIconWidth}px;
    height: ${(props) => props.closeIconHeight}px;
  }
`

export interface ModalHeaderProps {
  title: string
  handleClose: () => any
  theme: ModalAllStyleProps
}

/**
 * Table Header with title and button close in one row
 */
function ModalHeaderOneRow({title, handleClose, theme}: ModalHeaderProps) {
  // const {breakpoints} = useBreakpoints()

  const styleThemeFilter = () => {
    if (theme.titleFontSize) {
      return CustomTitleModalPageTitleStyles(
        theme.titleFontSize,
        theme?.titleTextColor,
      )
    }
    // if (breakpoints === Breakpoints.smMobile) {
    //   return ModalPageTitleMobileStyles
    // }
    return ModalPageTitleStyles
  }

  return (
    <StyledModalHeaderWrap
      {...theme}
      paddingHeaderTopTablet={theme.paddingHeaderTopTablet}
      paddingHeaderTopMobile={theme.paddingHeaderTopMobile}
      paddingHeaderBottomTablet={theme.paddingHeaderBottomTablet}
      paddingHeaderBottomMobile={theme.paddingHeaderBottomMobile}
      paddingRightTablet={theme.paddingRightTablet}
      paddingRightMobile={theme.paddingRightMobile}
      paddingLeftTablet={theme.paddingLeftTablet}
      paddingLeftMobile={theme.paddingLeftMobile}
      textAlign={theme.textAlign}
      brHeaderBottom={theme.brHeaderBottom}
    >
      <TextTitle
        as='h5'
        title={title}
        theme={{...styleThemeFilter(), lineHeight: 22}}
      />
      <StyledCloseButtonWrap
        onClick={handleClose}
        closeIconWidth={theme.closeIconWidth}
        closeIconHeight={theme.closeIconHeight}
        closeIconColor={theme.closeIconColor}
      >
        <img src={closeIcon} alt='close-icon' />
      </StyledCloseButtonWrap>
    </StyledModalHeaderWrap>
  )
}

export default ModalHeaderOneRow
