import {makeStyles, Theme} from '@material-ui/core/styles'

const useTransparentGradientStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    background: '#eef2f6',
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  cardWrap: {
    minWidth: 275,
    overflow: 'scroll',
  },
  cardContent: {
    margin: '0 20px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subTitle: {
    fontSize: '1.0rem',
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    minWidth: 50,
    maxWidth: 200,
    flexBasis: 200,
    flexWrap: 'wrap',
    marginTop: 5,
    color: '#757575',
  },
  subContent: {
    fontSize: '1.0rem',
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    flexWrap: 'wrap',
    marginTop: 5,
    flexGrow: 1,
    marginLeft: 10,
  },
  line: {
    background: '#eeeeee',
  },
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& button': {
      minWidth: 100,
      height: 50,
      marginLeft: 10,
      padding: '0px 70px',
      marginTop: 20,
    },
  },
  checkButton: {
    height: 55,
    marginLeft: 10,
    padding: 10,
  },
  requiredMark: {
    color: 'red',
    marginRight: 5,
  },
  requiredMarkHidden: {
    visibility: 'hidden',
    marginLeft: 5,
  },
  textField: {
    flexGrow: 1,
  },
  leftMargin: {
    marginLeft: 10,
    flexGrow: 1,
  },
  selectBox: {
    flexGrow: 1,
    '& .MuiInputBase-root.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  disableText: {
    color: '#a6a6a6',
  },
  iconButton: {
    padding: 10,
  },
  titleHidden: {
    visibility: 'hidden',
    minWidth: 205,
    marginLeft: 5,
  },
  longTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0',
    justifyContent: 'space-between',
    color: 'primary',
    background: 'white',
    '& button': {
      margin: 10,
    },
  },
  okButtonColor: {
    color: 'white',
  },
  longContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '40px 20px',
    flexWrap: 'wrap',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    '& h5': {
      fontWeight: 'bolder',
    },
    '& h6': {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      flexWrap: 'wrap',
      flex: 1,
      color: '#717376',
    },
    '& TextField': {
      flex: 1,
    },
  },
  scroll: {
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '550px',
    minHeight: '100px',
    flexWrap: 'wrap',
    overflow: 'auto',
    backgroundColor: '#fff',
    margin: 30,
    padding: 20,
  },
  hidden: {
    visibility: 'hidden',
  },
  alertButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: 'white',
    '& button': {
      flex: 1,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& MuiOutlinedInput-input': {
      maxWidth: '100%',
    },
  },
  radioButtonTextColor: {
    color: 'black',
  },
  iconTitleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 15,
  },
  iconColor: {
    color: '#636364',
    fontSize: 20,
    marginRight: 8,
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    // marginTop: 10,
    margin: 20,
    '& button': {
      minWidth: 120,
      width: 120,
      height: 44,
    },
    '& > .MuiTextField-root': {
      width: '100%',
      background: 'white',
    },
  },
  transparentGradient: {
    position: 'relative',

    '&:after': {
      width: 60,
      height: 'calc(100% - 42px - 52px - 15px - 10px - 10px)',
      background: 'linear-gradient(90deg, transparent 20%, white)',
      position: 'absolute',
      top: 42,
      right: 0,
      PointerEvent: 'none',
    },

    '@media (max-width: 1354px)': {
      '&:after': {
        content: '" "',
      },
    },
  },
}))

export default useTransparentGradientStyles
