import React from 'react'
import {useTranslation} from 'react-i18next'
import useHealthCenterStyles from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Style'
import down from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/down.svg'

interface Props {
  headerName: string
  backgroundColor: string
  textRound: {
    value: number
    frequency?: string
    status: string
  }
  percentage: number
  index: number
  top: string
  middle: string
  bottom: string
  showHz?: boolean
}

function PercentChangeBarCard({
  headerName,
  backgroundColor,
  textRound,
  percentage,
  index,
  top,
  middle,
  bottom,
  showHz = true,
}: Props) {
  const {t} = useTranslation()
  const classes = useHealthCenterStyles()
  const getNumber = (value: string | number) => {
    if (!Number.isNaN(Number(value))) return value
    return 0
  }
  return (
    <div className={classes.percentBar}>
      <div style={{paddingBottom: 25}}>
        <div className={classes.percentBarHeaderName}>
          <p>{headerName}</p>
        </div>
        <div
          className={classes.arrowIconShort}
          // Offset of the arrow icon's size
          style={{marginLeft: `${percentage - 1}%`}}
        >
          {index === 4 && (
            <img className={classes.downImg} src={down} alt='icon' />
          )}
        </div>

        <div className={classes.colorBarNormal}>
          {index === 4 && (
            <>
              <div
                className={
                  !percentage || percentage <= 70
                    ? classes.marker2
                    : classes.marker1
                }
                style={{left: '30%'}}
              />
              <div
                className={
                  !percentage || percentage <= 70
                    ? classes.marker2
                    : classes.marker1
                }
                style={{left: '70%'}}
              />
            </>
          )}
          {(index === 1 || index === 2 || index === 3 || index === 5) && (
            <>
              <div className={classes.marker1} style={{left: '30%'}} />
              <div className={classes.marker1} style={{left: '70%'}} />
            </>
          )}
          {index !== 4 && (
            <div
              className={classes.colorBarFillNormal}
              style={{
                width: `${Number(percentage) ?? 0}%`,
                backgroundColor: `${backgroundColor}`,
              }}
            />
          )}
        </div>
        <div className={classes.contentBarPerNotificationV2}>
          <div className={classes.contentBarPerNotificationV2Child}>
            <div>{top}</div>
            <div>{middle}</div>
            <div>{bottom}</div>
          </div>
        </div>
      </div>
      <div
        className={classes.round}
        style={{
          backgroundColor: `${backgroundColor}`,
        }}
      >
        <div className={classes.textRound}>
          <p>
            {getNumber(textRound.value)}
            {t('IPoints')}
          </p>
          {textRound.frequency && textRound.frequency !== '0' && showHz && (
            <p className={`${classes.frequencyText} ${classes.textRoundItem2}`}>
              {textRound.frequency}
            </p>
          )}
          <p className={classes.textRoundItem3}>{textRound.status}</p>
        </div>
      </div>
    </div>
  )
}

export default PercentChangeBarCard
