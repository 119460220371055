import React, {useEffect, useState} from 'react'
import {Radio} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

import useInvoice from 'features/invoice/useInvoice'
import {
  BaseFeesType,
  PlanType,
  ProductTemplateType,
} from 'features/invoice/invoiceSlice'

import Element from 'pages/CustomerManagement/InvoiceSetting/Form/Element'
import {RATE_PLAN} from 'pages/CustomerManagement/InvoiceSetting/constant'
import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'

const useStyles = makeStyles(InvoiceSettingStyle)

function RatePlan({press, orgId}: {press: number; orgId: number}) {
  const classes = useStyles()
  const {
    packageType,
    baseFees,
    templates,
    changeProductTemplateAction,
    changeBaseFeesAction,
    changeInvoiceAction,
    onHandleInvoice,
  } = useInvoice()

  const [dataTem, setDataTem] = useState<ProductTemplateType[]>([])
  const [dataFees, setDataFees] = useState<BaseFeesType>([])
  const ratePlan = true

  useEffect(() => {
    setDataTem(templates || [])
  }, [templates])

  useEffect(() => {
    setDataFees(baseFees || [])
  }, [baseFees])

  useEffect(() => {
    if (press && packageType === RATE_PLAN.key) {
      changeProductTemplateAction(dataTem)
      changeBaseFeesAction(dataFees)
      onHandleInvoice(orgId, dataFees, dataTem)
    }
  }, [press])

  return (
    <div className={classes.standardPlan} style={{marginTop: 5}}>
      <label
        htmlFor='ratePlan'
        className={classes.standardPlanTitleV2}
        style={{width: 290}}
      >
        <Radio
          id={RATE_PLAN.key}
          name={RATE_PLAN.key}
          checked={packageType === RATE_PLAN.key}
          onChange={() => {
            changeInvoiceAction(RATE_PLAN.key as PlanType)
            setDataTem([])
            setDataFees([])
          }}
        />
        {RATE_PLAN.label}
      </label>
      <div>
        {RATE_PLAN.contentLevelOne.map((elm, index) => (
          <div className={classes.generalOnly} key={`${index}_rate-plan`}>
            <div className={classes.generalOnlyContent}>
              <Element
                data={elm.data.contentLevelTwo}
                type={false}
                parentId={RATE_PLAN.key as PlanType}
                packageType={packageType}
                baseFees={dataFees}
                templates={packageType === RATE_PLAN.key ? dataTem : []}
                changeProductTemplateAction={setDataTem}
                changeBaseFeesAction={setDataFees}
                ratePlan={ratePlan}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RatePlan
