import {EEGSummaryResponse} from 'api/analysisApi'
import EEGSummaryBrain3D from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryBrain3d'
import EEGSummaryLobe from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryLobe'
import EEGSummaryOpinion from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryOpnion'
import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import RoiComponent from 'components/molcules/Report/RoiComponent'
import React, {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

interface EEGSummaryProps extends EEGSummaryResponse {
  contourImage?: string
  threeDimensionImage: string
  total: string
  CustomerCard?: ReactNode
  reportType: ReportType
}

export default function EEGSummary(props: EEGSummaryProps) {
  const {
    contourImage,
    threeDimensionImage,
    total,
    lobe,
    roi,
    CustomerCard,
    reportType,
  } = props
  const classes = useStyles()
  const {t} = useTranslation()
  const score = Number(total) ?? 0

  const handleTop = () => {
    const scroll = document.getElementById('root')

    scroll?.scrollTo(0, 0)
  }

  useEffect(() => {
    handleTop()
  }, [])

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      <EEGSummaryBrain3D index={1} threeDimensionImage={threeDimensionImage} />
      {lobe !== null && lobe !== undefined && (
        <EEGSummaryLobe index={2} lobe={lobe} />
      )}
      <EEGSummaryOpinion index={3} score={score} contourImage={contourImage} />
      {lobe != null &&
        lobe !== undefined &&
        roi != null &&
        roi !== undefined &&
        reportType === 'P2' && (
          <>
            <RoiComponent roi={roi} lobe={lobe} />
          </>
        )}
      <p className='Warning'>{t('IEEGSummaryReportWarning')}</p>
    </div>
  )
}
