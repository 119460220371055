import {createBrowserHistory, Location} from 'history'

export type AppHistoryState = {
  from: Location
  msg?: string
}

export const history = createBrowserHistory()

const AppHistory = createBrowserHistory<AppHistoryState>()

export const navigate = (path: string) => {
  AppHistory.push(path, AppHistory.location.state)
}

export const navigator = () => {
  AppHistory.push('/error/403', AppHistory.location.state)
}

export const replace = (path: string) => {
  AppHistory.replace(path, AppHistory.location.state)
}

export const goBack = () => AppHistory.goBack()

export default AppHistory
