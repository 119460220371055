import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {useAppSelector} from 'hooks'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AsyncButton from 'components/atoms/Buttons/AsyncButton'

import {selectOrgSelected} from 'features/invoice/invoiceInquirySlice'
import {
  ProductTemplateType,
  ProductUsesType,
} from 'features/invoice/invoiceSlice'
import {fetchCountryList} from 'api/commonApi'
import {
  InvoiceGetResponse,
  fetchListVersionInvoice,
  fetchTemplateInvoice,
  getDetailOrgRegister,
  getInvoiceApi,
  getInvoiceFollowMonthAPI,
} from 'api/invoiceApis'
import {fetchCommonIndustryListApi} from 'api/orgApi'
import useCustomerOrg from 'features/invoice/useInvoiceInquiry'

import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useStyles from 'components/Table/useTableStyles'
import {
  formatDateGetApi,
  formatDateInvoice,
  fromIsoDateOrStringToLocalDate,
  toParsedDate,
} from 'helpers/dateHelper'
import {zeroPad} from 'helpers/commonHelper'
import RouteConstant from 'constants/RouteConstant'

function TableToolbar() {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useToolbarStyles()
  const [searchKeyword, setSearchKeyword] = useState('')
  const isFirstRender = useRef(true)
  const {orgSelected, items, onSearch, changeSelectedOrg} = useCustomerOrg()
  const handleSearch = () => {
    onSearch({
      search: {orgName: searchKeyword},
      paging: {
        pageNumber: 0,
        pageSize: 10,
      },
    })
  }

  const handleToEditInfo = () => {
    history.push(`${RouteConstant.MEMBER_EDIT.path}?orgId=${orgSelected.orgId}`)
  }

  const handleToInvoiceDetail = async () => {
    try {
      const resInvoiceDate = await getInvoiceFollowMonthAPI(
        Number(orgSelected.orgId),
        formatDateGetApi(new Date()),
      )
      if (resInvoiceDate.data.id) {
        history.push(
          `${RouteConstant.INVOICE_VIEW.path}?invoiceId=${
            resInvoiceDate.data.id
          }&usageMonth=${formatDateInvoice(new Date())}`,
        )
      } else
        history.push(
          `${RouteConstant.INVOICE_VIEW.path}?orgId=${orgSelected.orgId}`,
        )
    } catch (error) {
      history.push(
        `${RouteConstant.INVOICE_VIEW.path}?orgId=${orgSelected.orgId}`,
      )
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (items) {
      changeSelectedOrg(items)
    }
  }, [items])

  return (
    <div className={classes.root}>
      <div className={classes.searchWrap}>
        <input
          placeholder={t('ISearch')}
          className={classes.searchInput}
          value={searchKeyword}
          onChange={(event) => {
            const value = event?.currentTarget?.value
            setSearchKeyword(value)
          }}
        />
        <span className={classes.searchDivider} />
        <button
          type='submit'
          onClick={handleSearch}
          color='primary'
          className={classes.searchButton}
        >
          {t('ISearch')}
        </button>
      </div>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          disabled={typeof orgSelected.orgId !== 'number'}
          className={classes.buttonActive}
          onClick={handleToEditInfo}
        >
          {t('IEditMemberInformation')}
        </Button>
        <AsyncButton
          variant='contained'
          color='primary'
          disableElevation
          disabled={typeof orgSelected.orgId !== 'number'}
          className={classes.buttonActive}
          onClick={handleToInvoiceDetail}
        >
          {t('ICustomerInvoice')}
        </AsyncButton>
      </div>
    </div>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const orgIdQuery = queryParams.get('orgId')
  const classesToolbar = useToolbarStyles()
  const orgSelected = useAppSelector(selectOrgSelected)
  const {changeSelectedOrg, resetListOrg} = useCustomerOrg()
  const [country, setCountry] = useState<any>()
  const [productTemplateList, setProductTemplateList] =
    useState<ProductTemplateType[]>()
  const [productInfo, setProductInfo] = useState<InvoiceGetResponse>()
  const [industry, setIndustry] = useState('')
  const formatDate = (date: string) => {
    if (!date) return ''
    const localDate = fromIsoDateOrStringToLocalDate(date)
    const {years, months, days} = toParsedDate(localDate)
    return `${years}-${zeroPad(months)}-${zeroPad(days)}`
  }

  const handleFetchCountryList = async () => {
    const response = await fetchCountryList()
    if (response.success) {
      const countrySelected = response.list.find(
        (item) => item.id === orgSelected.countryId,
      )
      setCountry(countrySelected?.name)
    }
  }

  const handleProductTemplateList = async (pid: number) => {
    if (typeof pid !== 'number') return
    try {
      const responseListInvoice = await fetchListVersionInvoice(pid)
      const maxInvoice = responseListInvoice.list.reduce((max, current) => {
        return current.id > max.id ? current : max
      }, responseListInvoice.list[0])
      const productInfoRes = await getInvoiceApi({
        pid: maxInvoice.id,
      })
      if (productInfoRes.data) {
        setProductInfo(productInfoRes.data)
      } else {
        setProductInfo(undefined)
      }
      const responseTemplate = await fetchTemplateInvoice(maxInvoice.id)
      if (responseTemplate.success && responseTemplate.list)
        setProductTemplateList(responseTemplate.list)
    } catch (error) {
      setProductInfo(undefined)
      setProductTemplateList(undefined)
    }
  }

  const getTextOrgType = (type: string) => {
    if (type === 'NORMAL') return t('IHospital')
    if (type === 'HEALTH') return t('IExaminationCenter')
    return ''
  }

  const dataOrg = [
    [
      t('IInstituteTitleName'),
      orgSelected.orgName,
      t('ICeoName'),
      t('IGetFullName', {
        firstName: orgSelected.firstName,
        lastName: orgSelected.lastName,
      }),
    ],
    [t('IEmail'), orgSelected.email, t('ICustomerNation'), country],
    [t('IPhone'), orgSelected.phoneNumber, t('IIndustryDetail'), industry],
    [
      t('IAddress'),
      orgSelected.address,
      t('IBusinessNo'),
      orgSelected.businessNumber,
    ],
    [
      t('IOrgNursingInstitutionNumber'),
      orgSelected.institutionNumber,
      t('IMembershipDate'),
      formatDate(orgSelected.createdAt),
    ],
    [
      t('IListProductUse'),
      getTextOrgType(orgSelected.orgType),
      t('ICheckupItems'),
      orgSelected.orgType === 'HEALTH'
        ? orgSelected.healthProductInfoList
            .map((item) => item.displayName)
            .join(',')
        : '',
    ],
  ]

  const getDataCell = (key: string, keyHrv?: string) => {
    if (productTemplateList) {
      const value = productTemplateList.find((item) => {
        return item.productType === key
      })
      if (keyHrv && value) {
        const valueHrv = productTemplateList.find((item) => {
          return item.productType === keyHrv
        })
        const valueMapHrv = [
          ...(value?.usages as []),
          ...(valueHrv?.usages as []),
        ]
        return valueMapHrv
      }

      return value?.usages
    }
    return ''
  }

  const renderDataCell = (value: ProductUsesType[] | string | undefined) => {
    if (!value || typeof value === 'string') {
      return value
    }

    return (
      <div>
        {value.map((element: ProductUsesType, index: number) => {
          let countString = ''
          if (
            element.minCount === 1 &&
            !element.maxCount &&
            value.length === 1
          ) {
            countString = element.price?.toString() ?? ''
          } else if (element.minCount && element.maxCount) {
            countString = `${t('IBelow', {
              value: element.maxCount,
              price: element.price,
            })}`
          } else if (element.minCount && value.length > 1) {
            countString = `${t('IAbnormality', {
              value: element.minCount,
              price: element.price,
            })}`
          }

          return <p key={index}>{countString}</p>
        })}
      </div>
    )
  }

  const getBaseFeesMonth = () => {
    if (!productInfo) return ''
    const {pkgInfo} = productInfo
    return `${pkgInfo.baseFees[0].name}${'\u00A0'}${
      pkgInfo.baseFees[0].price
    }${'\u00A0'}${'\u00A0'}${'\u00A0'}${pkgInfo.baseFees[1].name}${'\u00A0'}${
      pkgInfo.baseFees[1].price
    }`
  }

  const getBaseFees = useMemo(() => {
    if (!productInfo) return []
    const {pkgInfo} = productInfo
    if (!pkgInfo?.baseFees?.length) return []
    if (pkgInfo.baseFees.length === 1) {
      return [`${pkgInfo.baseFees[0].name}`, `${pkgInfo.baseFees[0].price}`]
    }
    if (pkgInfo.baseFees.length === 2) {
      return [t('IMonthlyFeeOnly'), getBaseFeesMonth()]
    }
    return []
  }, [productInfo])

  const getDiscount = () => {
    if (!productInfo) return []
    const {pkgInfo} = productInfo
    if (!pkgInfo?.discount) return []
    return [[t('IRevenueSharing'), `${pkgInfo.discount}%`]]
  }

  const getEegReport = () => {
    if (!productInfo) return []
    const {pkgInfo} = productInfo
    if (!pkgInfo?.eegSummarySurcharge) return []
    return [[t('IReportApplicable'), `${pkgInfo.eegSummarySurcharge}%`]]
  }

  const defaultDataTable = [
    [
      t('IEEGText'),
      t('IPresetIndividual'),
      getDataCell('eegIndv'),
      t('IPreset3D'),
      getDataCell('eegIndv3d'),
    ],
    [
      t('IPresetNorm'),
      getDataCell('eegNorm'),
      t('IPreset3D'),
      getDataCell('eegNorm3d'),
    ],
    [
      t('IPresetSummaryBasic'),
      getDataCell('eegSummary'),
      t('IPresetSummaryAdvanced'),
      getDataCell('eegSummaryAdv'),
    ],
    [t('IPresetMci'), getDataCell('eegMci'), '', ''],
    [
      t('IHRVText'),
      t('IPresetIndividual'),
      getDataCell('hrvIndv'),
      '',
      getDataCell(''),
    ],
    [
      t('IPresetSummaryBasic'),
      getDataCell('hrvSummary'),
      t('IPresetSummaryAdvanced'),
      getDataCell('hrvSummaryAdv'),
    ],
  ]

  const dataTemplates = [
    [t('IRatePlan'), productInfo?.label || ''],
    ...(productTemplateList?.length || !getBaseFees.length
      ? defaultDataTable
      : []),
    ...(getBaseFees.length ? [getBaseFees] : []),
    ...getEegReport(),
    ...getDiscount(),
  ]

  const getColSpan = (row: number, col: number) => {
    if (getBaseFees.length && !productTemplateList?.length) {
      return {colSpan: 1, rowSpan: 1}
    }
    if (row === 0 && col === 1) return {colSpan: 4, rowSpan: 1}
    if (row === 1 && col === 0) return {colSpan: 1, rowSpan: 4}
    if (row === 5 && col === 0) return {colSpan: 1, rowSpan: 2}
    if (row >= 7 && col === 1) return {colSpan: 4, rowSpan: 1}
    return {colSpan: 1, rowSpan: 1}
  }

  const getColSpanOrg = () => {
    return {colSpan: 1, rowSpan: 1}
  }

  const handleGoToInvoiceSetting = () => {
    history.push(
      `${RouteConstant.INVOICE_SETTING.path}?orgId=${orgSelected.orgId}`,
    )
  }

  const getIndustryInfo = async () => {
    let nameIndustry = ''
    if (orgSelected.orgId && orgSelected.industryId) {
      const orgDetail = await getDetailOrgRegister(orgSelected.orgId)
      const resIndustry = await fetchCommonIndustryListApi({
        depth: 1,
        parent: 0,
      })
      nameIndustry =
        resIndustry.list.find((item) => item.id === orgSelected.industryId)
          ?.title || ''
      const resIndustryDetail = await fetchCommonIndustryListApi({
        depth: 2,
        parent: orgSelected.industryId,
      })

      const detailIndustryTitle = resIndustryDetail.list.find(
        (item) => item.id === orgDetail.data.detailIndustryId,
      )?.title
      if (detailIndustryTitle)
        nameIndustry = `${nameIndustry} - ${detailIndustryTitle}`

      setIndustry(nameIndustry)
    }
  }

  useEffect(() => {
    if (orgSelected.orgId) {
      handleFetchCountryList()
      handleProductTemplateList(orgSelected.orgId)
      getIndustryInfo()
    } else {
      setIndustry('')
      setCountry('')
    }
  }, [orgSelected])

  useEffect(() => {
    const getOrgInit = async () => {
      if (orgIdQuery) {
        const res = await getDetailOrgRegister(parseInt(orgIdQuery, 10))
        if (res.success) {
          changeSelectedOrg([res.data])
        }
      } else {
        changeSelectedOrg([])
      }
    }
    getOrgInit()
  }, [orgIdQuery])

  useEffect(() => {
    return () => {
      setIndustry('')
      setCountry('')
      resetListOrg()
    }
  }, [])

  return (
    <div>
      <TableContainer>
        <TableToolbar />
        <div>
          <Table>
            {dataOrg.map((item) => (
              <TableRow>
                {item.map((item) => (
                  <TableCell {...getColSpanOrg()} className={classes.widthCol}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </div>
        <Typography className={classes.padding} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>{t('IMembershipBillingSystem')}</div>

          <Button
            variant='contained'
            color='primary'
            disableElevation
            disabled={typeof orgSelected.orgId !== 'number'}
            className={classesToolbar.buttonActive}
            onClick={handleGoToInvoiceSetting}
          >
            {t('IRegisterBillingMethod')}
          </Button>
        </div>

        <Table>
          <TableBody>
            {dataTemplates.map((item, index) => (
              <TableRow>
                {item.map((item, i) => (
                  <TableCell
                    {...getColSpan(index, i)}
                    className={classes.colorBrowRow}
                  >
                    {renderDataCell(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PageTable
