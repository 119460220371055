import CardWithTitle from 'components/atoms/CardWithTitle'
import React from 'react'
import {useTranslation} from 'react-i18next'

function TreatmentTherapy() {
  const {t} = useTranslation()

  return (
    <CardWithTitle title={String(t('ISummaryTherapy'))}>
      <div>{t('INotYetImplemented')}</div>
    </CardWithTitle>
  )
}

export default TreatmentTherapy
