import {
  CanBePurchaseProducts,
  PurchasedProducts,
  UnPaidVoucherContainer,
} from 'api/paymentApi'
import {TFunction} from 'react-i18next'
import AvailableProductModel from './AvailableProductModel'
import PurchasedProductsModel from './PurchasedProductsModel'

export default class CanBePurchaseProductsModel
  implements CanBePurchaseProducts
{
  available: AvailableProductModel

  purchased: PurchasedProducts

  overdue: UnPaidVoucherContainer

  description: string

  descLink: string | null

  constructor(raw: CanBePurchaseProducts, t: TFunction) {
    this.available = new AvailableProductModel(raw.available, t)
    this.purchased = new PurchasedProductsModel(raw.purchased, t)
    this.overdue = raw.overdue
    this.description = raw.description
    this.descLink = raw.descLink
  }
}
