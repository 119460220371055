import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import i18n from 'i18n'

export default function UserNameInput() {
  const createMemberInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    formState: {errors},
  } = useFormContext()
  const lang = i18n.language
  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IRegisterOrgBossName')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div className={classes.inputContainer}>
        {lang === 'en' && (
          <>
            <div className={classes.inputWrap}>
              <input
                placeholder={t('IFirstName')}
                autoComplete='off'
                className={classes.input}
                style={{maxHeight: 50}}
                {...createMemberInfo.register('lastUserName', {
                  required: `${t('IRequired')}`,
                })}
              />
              {errors.lastUserName && (
                <span className={classes.errorText}>
                  {errors.lastUserName && errors.lastUserName.message}
                </span>
              )}
            </div>
            <div className={classes.inputWrap}>
              <input
                placeholder={t('ILastName')}
                autoComplete='off'
                className={classes.input}
                style={{maxHeight: 50}}
                type='text'
                {...createMemberInfo.register('customerFistN', {
                  required: `${t('IRequired')}`,
                })}
              />
              {errors.customerFistN && (
                <span className={classes.errorText}>
                  {errors.customerFistN && errors.customerFistN.message}
                </span>
              )}
            </div>
          </>
        )}
        {lang !== 'en' && (
          <>
            <div className={classes.inputWrap}>
              <input
                placeholder={t('ILastName')}
                autoComplete='off'
                className={classes.input}
                style={{maxHeight: 50}}
                type='text'
                {...createMemberInfo.register('customerFistN', {
                  required: `${t('IRequired')}`,
                })}
              />
              {errors.customerFistN && (
                <span className={classes.errorText}>
                  {errors.customerFistN && errors.customerFistN.message}
                </span>
              )}
            </div>
            <div className={classes.inputWrap}>
              <input
                placeholder={t('IFirstName')}
                autoComplete='off'
                className={classes.input}
                style={{maxHeight: 50}}
                {...createMemberInfo.register('lastUserName', {
                  required: `${t('IRequired')}`,
                })}
              />
              {errors.lastUserName && (
                <span className={classes.errorText}>
                  {errors.lastUserName && errors.lastUserName.message}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
