import styled from 'styled-components'

export const RoundTableWrap = styled.tr`
  width: 100%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 40px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 7px;
  
`
export const RoundTableHeader = styled.tr`
  height: 42px;
  background-color: #e9ecef;
  & p {
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }
`
