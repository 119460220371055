import {Typography} from '@material-ui/core'
import {checkEmailDuplicateApi, emailAuthenticationApi} from 'api/authApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {EmailAuthenticationType} from 'constants/CommonConstant'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {toLocaleUppercase} from 'helpers/userHelper'
import i18n from 'i18n'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

interface EmailProps {
  onDuplicateChecked: () => void
  requestType: EmailAuthenticationType
  onSetUuid: (uuid?: string) => void
  isVerifiedCode: boolean
}

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export default function EmailInput({
  onDuplicateChecked: onSubmit,
  requestType,
  onSetUuid: setUuid,
  isVerifiedCode,
}: EmailProps) {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const [isValid, setIsValid] = React.useState(false)
  const [wrongEmail, setWrongEmail] = React.useState(false)

  const {
    formState: {errors},
  } = useFormContext()

  async function checkEmailDuplication(email: string) {
    try {
      const response = await checkEmailDuplicateApi(email)
      if (response.success) {
        onSubmit()
        setIsValid(false)
        return response.success
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
    return false
  }

  async function checkEmail(email: string) {
    if (validateEmail(email)) {
      setWrongEmail(false)
      try {
        const response = await emailAuthenticationApi({
          email,
          requestType,
          languageCode: toLocaleUppercase(i18n.language),
        })
        if (response) {
          await onSuccessModalOpen(t('IAuthCodeSendSuccess'))
          setUuid(response.uuid)
          onSubmit()
          setIsValid(false)
        } else {
          setIsValid(true)
        }
      } catch (e) {
        onFailureModalOpen(e.message)
      }
    } else {
      setWrongEmail(true)
      setIsValid(false)
    }
  }

  const handleEmailDuplicate = () => {
    const result = checkEmailDuplication(createOrgInfo.getValues('email'))
    result.then((result) => {
      if (result) checkEmail(createOrgInfo.getValues('email'))
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IEmail')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <input
          id='email'
          placeholder={t('IEmailRequired')}
          className={classes.input}
          {...createOrgInfo.register('email', {
            required: `${t('IRequired')}`,
          })}
          disabled={isVerifiedCode}
        />
        <Typography className={classes.padding} />
        <ActionButton
          color='primary'
          onClick={handleEmailDuplicate}
          style={{height: 50}}
          disabled={isVerifiedCode}
        >
          {t('IAuthNumber')}
        </ActionButton>
      </div>
      <span className={classes.errorText}>
        {errors.email && t(`${errors.email.message}`)}
      </span>
      {isValid && (
        <span className={classes.errorText}>{t('IEmailIsExists')}</span>
      )}
      {wrongEmail && (
        <span className={classes.errorText}>{t('IEmailInvalid')}</span>
      )}
    </div>
  )
}
