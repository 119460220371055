import request from 'api/index'

export interface QnaListRequest extends PagingRequest {}

export interface QnaCreateRequest {
  qnaType?: string
  title?: string
  content?: string
}

export interface QnaEditRequest extends QnaCreateRequest {
  id?: number
}

export interface QnaAnswerAddRequest {
  id?: number
  content?: string
}

export const fetchQnaListApi = ({paging}: QnaListRequest) =>
  request<ModelResponse<Qna>>({
    method: 'get',
    path: '/api/qna/v1/list',
    params: {...paging},
  })

export const fetchDetailQnaApi = (id: number) =>
  request<ModelResponse<QnaDetailInfo>>({
    method: 'get',
    path: '/api/qna/v1/detail',
    params: {
      id,
    },
  })

export const fetchQnaTypeListApi = () =>
  request<ModelsResponse<string>>({
    method: 'get',
    path: '/api/qna/v1/type',
  })

export const updateQnaApi = (data: QnaEditRequest) =>
  request<ModelResponse<QnaContent>>({
    method: 'post',
    path: '/api/qna/v1/update',
    data,
  })

export const deleteQnaApi = (id: number) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/qna/v1/delete',
    data: {id},
  })
export const createQnaApi = (data: QnaCreateRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/qna/v1/create',
    data,
  })

export const addQnaAnswerApi = (data: QnaAnswerAddRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/qna/v1/answer',
    data,
  })
