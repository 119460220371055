import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import {fileDownload} from 'api'
import {
  fetchPaymentFailedReasonApi,
  fetchPaymentStatusApi,
} from 'api/paymentApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import ReceiptDialog from 'components/Dialog/ReceiptDialog'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import GridPaginationPadding from 'components/Grid/GridPaginationPadding'
import useTableStyles from 'components/Table/useTableStyles'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import {openReceiptDialog} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useOrganization from 'features/org/useOrganization'
import {
  cancelPaymentAction,
  fetchPaymentAction,
  fetchPaymentListRequest,
  selectPayment,
  selectPaymentQuery,
} from 'features/payment/paymentSlice'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import {
  dateToPeriodString,
  getDefaultPeriodString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchButton: {
      // marginTop: 15,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
    },
    disableText: {
      color: '#a6a6a6',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },

    pointTitle: {
      fontSize: '12px',
    },
    pointCont: {
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    toolbarWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    pointSearchContainer: {
      marginLeft: 5,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
    dateSearchButton: {
      border: '1px solid #0cc7c7',
      borderRadius: 4,
      padding: '0 15px',
      background: 'white',
      color: '#0cc7c7',
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}

const headCells: HeadCell[] = [
  {id: 'orderNumber', label: 'IOrderNo'},
  {id: 'purchaseDate', label: 'IOrderDate'},
  {id: 'productName', label: 'IProductName'},
  {id: 'name', label: 'IOrderOrgName'},
  {id: 'type', label: 'IPurchaseType'},
  {id: 'amount', label: 'IPurchaseAmount'},
  {id: 'receipt', label: 'IReceipt'},
  {id: 'cancelButton', label: 'IPurchaseCancel'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function PaymentHistoryTable() {
  const classes = useTableStyles()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const paymentList = useSelector(selectPayment)
  const paymentQuery = useSelector(selectPaymentQuery)
  const selectedOid = useSelector(selectAllOrgOid)
  const {user: currentUser} = useAuth()
  const {organization: orgInfo} = useOrganization()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const emptyRows =
    paymentList.paymentList === null ? 5 : 5 - paymentList.paymentList.length

  const [paymentId, setPaymentId] = React.useState(0)

  const setPageIndex = (page: number) => {
    dispatch(
      fetchPaymentAction({
        ...paymentQuery,
        paging: {
          page,
          size: paymentList.itemPerPage ?? 10,
        },
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? null,
      }),
    )
  }

  const handleReadClick = (paymnetId: number) => {
    dispatch(openReceiptDialog())
    setPaymentId(paymnetId)
  }

  function cancelledPayment(id: number) {
    dispatch(
      cancelPaymentAction({
        id,
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? null,
      }),
    )
  }

  async function fetchPaymentFailedReason(id: number) {
    const response = await fetchPaymentFailedReasonApi({
      id,
      uid: currentUser?.uid ?? '',
    })
    if (response.data === '') {
      onFailureModalOpen(t('IPurchaseCancelFailure'))
    } else {
      onFailureModalOpen(response.data)
    }
  }

  async function fetchPaymentStatus(id: number) {
    try {
      const response = await fetchPaymentStatusApi({
        id,
        status: 'cancelled',
      })
      if (response.data === null) {
        cancelledPayment(id)
      } else {
        fetchPaymentFailedReason(id)
      }
    } catch (e) {
      onFailureModalOpen(t('IPurchaseCancelFailure'))
    }
  }

  const handleCancelClick = (id: number) => {
    fetchPaymentStatus(id)
  }

  useEffect(() => {
    dispatch(
      fetchPaymentAction({
        paging: {
          page: 0,
          size: 5,
        },
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? null,
      }),
    )
  }, [])

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='small'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {paymentList.paymentList != null &&
            paymentList.paymentList.map((id: any) => {
              return (
                <TableRow
                  hover
                  role='checkbox'
                  classes={{hover: classes.hover}}
                  key={id.id}
                >
                  <TableCell align='center'>{id.id ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {isoStringToDateAndTimeString(id.dateTime ?? '-')}
                  </TableCell>
                  <TableCell align='center'>{id.product ?? '-'}</TableCell>
                  <TableCell align='center'>{id.name ?? '-'}</TableCell>
                  <TableCell align='center'>{id.paymentType ?? '-'}</TableCell>
                  <TableCell align='center'>{id.amount ?? '-'}</TableCell>
                  <TableCell align='center'>
                    <TableButton onClick={() => handleReadClick(id.id)}>
                      {t('IReceipt')}
                    </TableButton>
                  </TableCell>
                  <TableCell align='center'>
                    {id.paymentType !== 'Cancel' && (
                      <TableButton onClick={() => handleCancelClick(id.id)}>
                        {t('IPurchaseCancel')}
                      </TableButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <GridPaginationPadding />
      <Pagination
        totalPageCount={paymentList.totalPages}
        currentPageIndex={paymentList.pageIndex}
        itemCountPerPage={paymentList.itemPerPage}
        setCurrentPageIndex={setPageIndex}
        loading={paymentList.loading}
        onItemCountPerPageChanged={(event: any) => {
          dispatch(
            fetchPaymentListRequest({
              ...paymentQuery,
              paging: {
                page: 0,
                size: parseInt(event.target.value as string, 10),
              },
              uid: currentUser?.uid ?? '',
              oid: selectedOid ?? null,
            }),
          )
        }}
        isItemCountPerPageEnabled={false}
      />
      <ReceiptDialog paymentId={paymentId} />
    </TableContainer>
  )
}

type PaymentSearchedType =
  | 'ORDER_NO'
  | 'USED_DATE'
  | 'PRODUCT'
  | 'NAME'
  | 'ORG'
  | 'TYPE'

export default function MyPaymentHistory() {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const selectedOid = useSelector(selectAllOrgOid)
  const paymentList = useSelector(selectPayment)
  const {user: currentUser} = useAuth()
  const {organization: orgInfo} = useOrganization()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const {t} = useTranslation()
  const [searchNameValue, setSearchNameValue] = React.useState('')
  const [searchedValue, setSearchedValue] = React.useState('')
  const searchedList = [
    'IOrderNo',
    'IOrderDate',
    'IProductName',
    'IName',
    'IPurchaseType',
  ]

  const searchedListMap = searchedList.map((value, index) => (
    <MenuItem value={index} key={index}>
      {t(value)}
    </MenuItem>
  ))

  const [searchedSelectedType, setSearchedSelectedType] =
    React.useState<PaymentSearchedType>()

  // const defaultStateDate = new Date().setDate(-30)
  const [selection, setSelection] = React.useState<PeriodString>(
    getDefaultPeriodString(),
  )

  const handleOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchNameValue(event.target.value as string)
    switch (event.target.value) {
      case 0:
        setSearchedSelectedType('ORDER_NO')
        break
      case 1:
        setSearchedSelectedType('USED_DATE')
        break
      case 2:
        setSearchedSelectedType('PRODUCT')
        break
      case 3:
        setSearchedSelectedType('NAME')
        break
      case 4:
        setSearchedSelectedType('ORG')
        break
      case 5:
        setSearchedSelectedType('TYPE')
        break
      default:
        setSearchedSelectedType('PRODUCT')
    }
  }

  const handleValue = (value: string) => {
    setSearchedValue(value)
  }

  const handleDateValue = (value: any) => {
    setSelection(dateToPeriodString(value as PeriodDate))
  }

  const handleKeywordSearch = () =>
    dispatch(
      fetchPaymentListRequest({
        paging: {
          page: 0,
          size: 5,
        },
        uid: currentUser?.uid ?? '',
        search: {
          searchName: searchedSelectedType ?? '',
          searchValue: searchedValue ?? '',
        },

        oid: orgInfo?.oid ?? null,
      }),
    )
  const handlePeriod = (period: PeriodString) => {
    dispatch(
      fetchPaymentListRequest({
        paging: {
          page: 0,
          size: 5,
        },
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? null,
        period,
      }),
    )
  }

  const excelDownload = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (paymentList.paymentList?.length !== 0) {
      event.stopPropagation()
      await fileDownload({
        path: '/api/payment/v1/download/excel',
        params: {
          oid: selectedOid ?? null,
        },
        fileName: 'payment.xlsx',
      })
    }
    if (paymentList.paymentList?.length === 0) {
      onFailureModalOpen(t('IPaymentEmpty'))
    }
  }

  useEffect(() => {
    handlePeriod(selection)
  }, [selection])

  return (
    <CardWithTitle title={t('IPaymentHistory')}>
      <Grid className={classes.pointContainer}>
        <Grid item xs={12}>
          <Grid container className={classes.toolbarContainer}>
            <Grid className={classes.toolbarWrap}>
              <div style={{display: 'flex', gap: 5}}>
                <div className={classes.dataContainer}>
                  <RangeDatePicker
                    onChangeValue={handleDateValue}
                    dateValue={{
                      startDate: new Date(selection.startDate),
                      endDate: new Date(selection.endDate),
                    }}
                  />
                </div>

                <div className={classes.pointSearchContainer}>
                  <FormControl variant='outlined' className={classes.selectBox}>
                    <Select
                      value={searchNameValue}
                      onChange={handleOnchange}
                      displayEmpty
                      color='primary'
                      input={
                        <OutlinedInput
                          name='age'
                          id='outlined-age-simple'
                          classes={outlinedInputClasses}
                        />
                      }
                    >
                      <MenuItem disabled value=''>
                        <Typography className={classes.disableText}>
                          {t('ISelect')}
                        </Typography>
                      </MenuItem>
                      {searchedListMap}
                    </Select>
                  </FormControl>
                  <SearchBar
                    onChangeValue={handleValue}
                    onSearchEvent={handleKeywordSearch}
                    disabledValue={false}
                  />
                </div>
              </div>

              <ActionButton
                variant='contained'
                color='secondary'
                startIcon={<GetAppIcon />}
                disableElevation
                onClick={(event) => {
                  excelDownload(event)
                }}
              >
                {t('IExcelDownload')}
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <PaymentHistoryTable />
      </Grid>
    </CardWithTitle>
  )
}
