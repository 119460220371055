import React, {useEffect, useMemo, useState} from 'react'
import {useAsync} from 'react-async-hook'
import useInterval from 'hooks/useInterval'
import {useTranslation} from 'react-i18next'
import appHistory from 'AppHistory'
import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  fetchIndividualEEGResult,
  FetchIndividualEEGResultRequest,
  fetchIndividualEegStatus,
  TypeScreenGraph,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import {Settings} from 'components/Settings'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ReportIcaAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import {getEegIndividualReportPath} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import {addPushReceiveHandler} from 'helpers/pushHelper'
import {
  IcaProcess,
  RenderingItem,
} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import ReportLoading from 'pages/Analysis/EEGIndividual/ReAnalysis/ReportLoading'
import {TsrReportItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/TsrReport/TsrReportItem'
import {TsrSettings} from 'pages/Analysis/EEGIndividual/ReAnalysis/TsrReport/TsrSettings'

interface ReportProps {
  readonly id: number
  readonly clientName: string
  readonly screen: TypeScreenGraph
}

function TsrReportFetcher({id, clientName, screen}: ReportProps) {
  const {t, i18n} = useTranslation()

  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Individual
  const locale = i18n.language

  const params: FetchIndividualEEGResultRequest = {
    individualRequestIds: [id],
    codeTypeDivision,
    codeType,
    codeVersion: '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchIndividualEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [analysisTime, setAnalysisTime] = useState<string>('0')

  const [open, setOpen] = useState(false)

  const clientNameHandler = useMemo(() => {
    const userNameFromURL = JSON.parse(decodeURIComponent(clientName))
    return t('IGetFullName', {
      firstName: userNameFromURL?.firstName ?? '',
      lastName: userNameFromURL?.lastName ?? '',
      interpolation: {escapeValue: false},
    })
  }, [clientName])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {resultDetailList, resultInfo} = result

      const rawData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.CleanedData,
          codeName: 'Cleaned data(Common average reference)',
          codePrintYn: 'Y',
          codeSort: 1,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0)
          .filter((i) => i.psdScaleOrder === 3),
      ]
      setRenderingItems([rawData])
      setResultInfo(resultInfo)
      setAnalysisTime(
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .find((i) => i.psdScale !== '0')?.psdScale ?? '0',
      )
    }
  }, [asyncResult.result])

  const [icaProcessing, setIcaProcessing] =
    useState<IcaProcess>('ica_process_tsr')

  useEffect(() => {
    // const fetchStatus = async () => {
    //   try {
    //     const {data: status} = await fetchIndividualEegStatus(id)
    //     switch (status) {
    //       case 'SUCCESS':
    //         setIcaProcessing('ica_process_success')
    //         break
    //       case 'TIME_REJECTION':
    //         setIcaProcessing('ica_process_tsr')
    //         break
    //       case 'PROCESSING':
    //         setIcaProcessing('ica_process_loading')
    //         break
    //       case 'ERROR':
    //         setIcaProcessing('ica_process_failure')
    //         break
    //       default:
    //         break
    //     }
    //   } catch (err) {
    //     setIcaProcessing('ica_process_failure')
    //   }
    // }

    // fetchStatus()
    addPushReceiveHandler((event: any) => {
      if (event.detail.data.status === 'ICA') {
        setIcaProcessing('ica_process_idle')
      }
      if (event.detail.data.status === 'PROCESSING') {
        setIcaProcessing('ica_process_loading')
      }
      if (event.detail.data.status === 'TIME_REJECTION') {
        setIcaProcessing('ica_process_loading')
      }
      if (event.detail.data.status === 'ERROR') {
        setIcaProcessing('ica_process_failure')
      }
    })

    // fetchStatus()
  }, [id])

  useInterval(
    () => {
      if (icaProcessing === 'ica_process_loading') {
        fetchIndividualEegStatus(id)
          .then((res) => {
            const {data: status} = res
            switch (status) {
              case 'SUCCESS':
                setIcaProcessing('ica_process_success')
                break
              case 'ERROR':
                setIcaProcessing('ica_process_failure')
                break
              default:
                break
            }
          })
          .catch(() => setIcaProcessing('ica_process_failure'))
      }
    },
    icaProcessing === 'ica_process_tsr' ||
      icaProcessing === 'ica_process_loading'
      ? 3000
      : undefined,
  )

  useEffect(() => {
    if (icaProcessing === 'ica_process_success') {
      appHistory.push(
        getEegIndividualReportPath(id, undefined, clientName || ''),
      )
      window.location.reload()
      return
    }

    if (icaProcessing === 'ica_process_failure') {
      onFailureModalOpen(t('IRetry'))
    }
  }, [icaProcessing])

  return (
    <CardWithTitle title='ICA' style={{minWidth: '100%'}}>
      {/* height - header - footer - CardHeader & border - margin */}
      <div
        style={{
          minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {asyncResult.error && (
          <div>
            {asyncResult.error.message}
            <IconButton
              onClick={() => asyncResult.execute(params)}
              size='small'
            >
              <ReplayIcon />
            </IconButton>
          </div>
        )}
        <div style={{width: '100%'}}>
          {resultInfo !== undefined && (
            <ReportInfo {...resultInfo} clientName={clientNameHandler} />
          )}
          {renderingItems !== undefined &&
            renderingItems.map((item, index) => (
              <TsrReportItem
                key={`${index}tsr`}
                renderingItem={item}
                screen={screen}
              />
            ))}
        </div>
      </div>
      {icaProcessing === 'ica_process_loading' && <ReportLoading />}
      <Settings open={open} onOpen={() => setOpen((o) => !o)}>
        <TsrSettings
          requestId={id}
          onProcess={() => setIcaProcessing('ica_process_loading')}
          analysisTime={Number.parseFloat(analysisTime)}
        />
      </Settings>
    </CardWithTitle>
  )
}

export default TsrReportFetcher
