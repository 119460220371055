/* eslint-disable react/no-unknown-property */
import {OrbitControls} from '@react-three/drei'
import {Canvas, useLoader} from '@react-three/fiber'
import {DoubleSide, Mesh, MeshPhongMaterial, WebGLRenderer} from 'three'
import {ColladaLoader} from 'three/examples/jsm/loaders/ColladaLoader'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React, {Suspense, useEffect} from 'react'

interface ModelProps {
  path: string
  theme: ThreeDTheme
}

const Model = ({path, theme}: ModelProps) => {
  const collada = useLoader(ColladaLoader, path)
  const opacity = theme === 'Dark' ? 0.3 : 0.1

  useEffect(() => {
    collada.scene.traverse((child) => {
      // node1 이면 그룹으로 되어 있음. Connectivity 렌더링 하므로 opacity 조절이 필요
      if (child instanceof Mesh && child.name === 'node1') {
        child.material = new MeshPhongMaterial({
          vertexColors: true,
          transparent: true,
          opacity,
        })
        child.material.side = DoubleSide
      }
      if (child instanceof Mesh && child.name !== 'node1') {
        child.material = new MeshPhongMaterial({
          vertexColors: true,
          transparent: false,
          opacity: 1,
        })
        child.material.side = DoubleSide
      }
    })
  }, [collada, opacity])

  return (
    <group dispose={null}>
      <primitive object={collada.scene} dispose={null} />
    </group>
  )
}

interface ThreeDRendererProps {
  path: string
  theme: ThreeDTheme
}

export default function Renderer({path, theme}: ThreeDRendererProps) {
  const color = theme === 'Dark' ? 'black' : 'white'
  return (
    <Canvas
      // @ts-ignore
      width={900}
      height={800}
      style={{
        position: 'absolute',
        /* width: 900px; */
        /* height: 800px; */
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      camera={{
        fov: 45,
        near: 0.1,
        far: 2000,
        position: [8, 10, 8],
      }}
      resize={{debounce: 50}}
      // @ts-ignore
      gl={(canvas) =>
        new WebGLRenderer({
          canvas,
          alpha: true,
          antialias: true,
        })
      }
    >
      <color attach='background' args={[color]} />
      <Suspense fallback={null}>
        <Model path={path} theme={theme} />
      </Suspense>
      <OrbitControls zoomSpeed={1} />
      <ambientLight args={[0xcccccc]} intensity={0.3} />
      <directionalLight color={0xffffff} position={[1, 1, 0]} intensity={0.4} />
      <directionalLight
        color={0xffffff}
        position={[-1, -1, 0]}
        intensity={0.4}
      />
      <directionalLight
        color={0xffffff}
        position={[1, -1, 0]}
        intensity={0.4}
      />
      <directionalLight
        color={0xffffff}
        position={[-1, 1, 0]}
        intensity={0.4}
      />
    </Canvas>
  )
}
