import {fetchPatientRead, selectEntity} from 'features/patient/patientSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function usePatient() {
  const dispatch = useAppDispatch()

  const onFetch = (uuid: string) => dispatch(fetchPatientRead(uuid))

  const {loading, error, entity} = useAppSelector(selectEntity)

  return {
    loading,
    error,
    entity,
    onFetch,
  }
}
