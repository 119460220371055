import request, {fileDownload} from 'api/index'
import {dateToFileFormat} from 'helpers/dateHelper'

export interface HealthCareSearchRequest {
  page: number
  size: number
  lang: string
  endDate?: string
  startDate?: string
  hcId?: number | string
  patientName?: string
  birth?: string
  patientVisitNo?: string
  patientChartNo?: string
  downloaded?: boolean | string
}

export interface DetailInfo {
  hcId?: number
  patientFirstName?: string
  patientLastName?: string
  email?: string
}
export interface ResultSendEmail {
  total: number
  fail: number
  completed: number
  detailInfo: DetailInfo[]
}
export interface SearchedHealthCare {
  choice: string
  createdAt: string
  doctorFirstName: string
  doctorLastName: string
  ecId: number
  ecProgress: number
  ecStatus: AnalysisStatus
  ecStatusMsg: string
  ecStatusMsgEn: string
  eoId: number
  eoProgress: number
  eoStatus: string
  eoStatusMsg: string
  eoStatusMsgEn: string
  hcAnalysisTypes: number[]
  hcId: number
  hcProgress: number
  hcStatus: string
  hcStatusMsg: string
  hcStatusMsgEn: string
  layoutType: string
  measureId: number
  patientBirth: string
  patientFirstName: string
  patientLastName: string
  ppgId: number
  ppgProgress: number
  ppgStatus: string
  ppgStatusMsg: string
  ppgStatusMsgEn: string
  reportDownloaded: string
  reportUrl: string
  summaryType: string
  treatmentAt: string
  uid: string
  uuid: string
  patientEmail?: string
  reportSentEmail?: string
}

export const searchHealthCareAnalysisApi = (
  params: HealthCareSearchRequest,
) => {
  return request<PageResponse<SearchedHealthCare>>({
    method: 'get',
    path: '/api/healthcare/v1/analysis',
    params,
  })
}

export interface HealthCareChoiceRequest {
  id: number
  uid: string
}

export const choiceHealthCareApi = (data: HealthCareChoiceRequest) => {
  return request<ModelResponse<number>>({
    method: 'put',
    path: `/api/healthcare/v1/analysis/${data.id}/choice`,
    data,
  })
}

export interface HealthCareReanalysisRequest {
  eoId?: number
  ecId?: number
}

export const reanalysisHealthCareApi = (data: HealthCareReanalysisRequest) => {
  return request<ModelResponse<number>>({
    method: 'post',
    path: `/api/healthcare/v1/reanalysis`,
    data,
  })
}

export interface HealthCareDownloadRequest {
  id: string
  uid: string
}

export interface HealthCareDownloadResponse {
  id: string
  filePath: string
}

export interface HealthCareSendEmailResponse {
  noEmailIds: number[]
  notFoundIds: number[]
  successIds: number[]
}

export const downloadStatusHealthCareApi = (
  params: HealthCareDownloadRequest,
) => {
  return request<ModelsResponse<HealthCareDownloadResponse>>({
    method: 'get',
    path: `/api/healthcare/v1/analysis/any/report-file`,
    params,
  })
}

export const sendEmailHealthCareApi = (params: HealthCareDownloadRequest) => {
  return request<ModelResponse<HealthCareSendEmailResponse>>({
    method: 'get',
    path: `/api/healthcare/v1/analysis/any/report-email`,
    params,
  })
}

export const downloadExcelHealthCareApi = (
  payload: HealthCareDownloadRequest,
) => {
  return fileDownload({
    path: `/api/healthcare/v1/analysis/any/excel-file`,
    params: payload,
    fileName: `${dateToFileFormat(new Date())}.xlsx`,
  })
}

export interface Frequency {
  score: number
  value: number
}

export interface FiveFeaturesScore {
  sadness: number
  tension: number
  stress: number
  dominant_frequency: Frequency
  inattention: Frequency
}

export interface HrvAutoNervousSystemBalance {
  parasympathetic: number
  sympathetic: number
}

export interface AgingLobeScore {
  Frontal_LH: number | string
  Frontal_RH: number | string
  Occipital_LH: number | string
  Occipital_RH: number | string
  Parietal_LH: number | string
  Parietal_RH: number | string
  Temporal_LH: number | string
  Temporal_RH: number | string
}

export interface HealthReport {
  agingContourImage: string
  agingLobeScore: AgingLobeScore
  agingRoiScore: Record<string, any>
  agingThreeDimensionImage: string
  agingTotalScore: number
  analysisType: number[]
  brainConnectivityBetweenLobesImage: string
  brainConnectivityBetweenLobesScore: {
    vertical: {
      left: number
      right: number
    }
    horizontal: number
  } | null
  brainConnectivityByLobeImage: string
  brainConnectivityByLobeScore: {
    frontal: {
      left: number
      right: number
    }
    pariocci: {
      left: number
      right: number
    }
  } | null
  brainConnectivityTotalImage: string
  brainConnectivityTotalScore: number
  choice: string
  createdAt: string
  ecId: number
  eoId: number
  ecMeasuredTime: number
  eoMeasuredTime: number
  hrvMeasuredTime: number
  fiveFeaturesRadarImage: string
  fiveFeaturesScore: FiveFeaturesScore
  hcId: number
  hrvAutoNervousSystemBalance: HrvAutoNervousSystemBalance | null
  hrvCircularImage: string
  hrvContourImage: string
  hrvHistogramImage: string
  hrvId: number | null
  hrvTableImage: string
  layoutType: string
  mciProbability: number
  mciResultImage: string
  measureId: number
  patientBirth: string
  patientFirstName: string
  patientGender: string
  patientHand: string
  patientLastName: string
  summaryType: string
  treatmentAt: string
  hrvCircularLevel: number
  hrvContourLevel: number
  hrvHistogramLevel: number
  finalReportPath: string | null
}

export const getDataHealthReportApi = (id: number) => {
  return request<ModelResponse<HealthReport>>({
    method: 'get',
    path: `/api/healthcare/v1/analysis/${id}/report`,
    params: {
      id,
    },
  })
}

export interface HdAnalysisTypes {
  displayName: string
  id: number
  productId: number
}

export const getHdAnalysisTypesApi = (id: string) => {
  return request<ModelsResponse<HdAnalysisTypes>>({
    method: 'get',
    path: `/api/org/v1/healthcare-product`,
    params: {
      id,
    },
  })
}

export type ProductType =
  | 'Basic'
  | 'BasicHrv'
  | 'HrvOnly'
  | 'Premium'
  | 'PremiumPlus'
  | 'Standard'
  | 'PremiumHrv'
  | 'PremiumPlusHrv'
  | 'StandardHrv'

export interface HealthProduct {
  displayName: string
  id: number
  required: string[]
  type: ProductType
}

export const getHealthProductsApi = () => {
  return request<ModelsResponse<HealthProduct>>({
    method: 'get',
    path: `/api/manager/v1/health/product/list`,
  })
}
