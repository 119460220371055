import React from 'react'
import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import LedTherapy from 'pages/TherapyPages/LedTherapyPage'

import {Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

/**
 * 테라피 관리 라우트
 */
const TherapyRoute = () => {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.THERAPY_MANAGEMENT_L.path}
          exact
          component={LedTherapy}
        />
      </Switch>
    </CommonLayout>
  )
}

export default TherapyRoute
