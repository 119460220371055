import RouteConstant from 'constants/RouteConstant'
import AuthLayout from 'layouts/AuthLayout'
import LoginPage from 'pages/AuthPages/LoginPageOldStyle'
import VerificationPage from 'pages/AuthPages/VerificationLogin'
import RegisterPage from 'pages/AuthPages/RegisterPage'
import RegisterPageV2 from 'pages/AuthPages/RegisterPageV2'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

/**
 * 인증 관련 라우트.
 */
const AuthPages = () => (
  <AuthLayout>
    <Switch>
      <Route path={RouteConstant.LOGIN.path} component={LoginPage} />
      <Route
        path={RouteConstant.VERIFICATION.path}
        component={VerificationPage}
      />
      <Route path={RouteConstant.REGISTER.path} component={RegisterPage} />
      <Route
        path={RouteConstant.REGISTER_SALE.path}
        component={RegisterPageV2}
      />
      <Route path='*'>
        <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
      </Route>
    </Switch>
  </AuthLayout>
)

export default AuthPages
