import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import * as storage from 'api/storageApi'
import {DAY_LEFT, MILLISECONDS_OF_DAY} from 'constants/HistoryConstant'

const AnalysisDate = () => {
  const analysisDate = JSON.parse(
    storage.getAnalysisDate() ?? '{"startDate":null,"endDate":null}',
  )
  const {t, i18n} = useTranslation()
  const formatDate = (dateString: Date, type: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString(type, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const getStartDate = () => {
    if (i18n.language === 'ko') {
      return formatDate(analysisDate.startDate, 'ko-KR')
    }
    return formatDate(analysisDate.startDate, 'en-US')
  }

  const getEndDate = () => {
    if (i18n.language === 'ko') {
      return formatDate(analysisDate.endDate, 'ko-KR')
    }
    return formatDate(analysisDate.endDate, 'en-US')
  }

  const getDistanceDate = () => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const futureDate = new Date(analysisDate.endDate)
    futureDate.setHours(0, 0, 0, 0)
    const diffInMilliseconds = futureDate.getTime() - now.getTime()
    return Math.ceil(diffInMilliseconds / MILLISECONDS_OF_DAY)
  }

  const getExpiredText = () => {
    if (getDistanceDate() <= DAY_LEFT && getDistanceDate() > 0) {
      return t('IExpiry', {day: getDistanceDate()})
    }
    if (getDistanceDate() === 0) {
      return t('IExpiresToday')
    }
    if (getDistanceDate() < 0) {
      return t('IHasExpired')
    }
    return ''
  }

  const renderDate = useMemo(() => {
    if (!analysisDate.startDate && !analysisDate.endDate) {
      return {
        text: t('IUnlimited'),
        color: '',
      }
    }

    if (!analysisDate.endDate) {
      return {
        text: t('IUnlimited'),
        color: '',
      }
    }

    if (!analysisDate.startDate) {
      return {
        text: `- ~ ${getEndDate()} ${getExpiredText()}`,
        color: getDistanceDate() <= DAY_LEFT ? '#cc271b' : '',
      }
    }

    return {
      text: `${getStartDate()} ~ ${getEndDate()} ${getExpiredText()}`,
      color: getDistanceDate() <= DAY_LEFT ? '#cc271b' : '',
    }
  }, [analysisDate])

  return (
    <b>
      {t('IExpirationDate')}
      <span style={{color: renderDate.color}}>{renderDate.text}</span>
    </b>
  )
}

export default AnalysisDate
