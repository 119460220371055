import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from '../../../Text/ReportSubtitle'
import {Colors} from '../../../common/useBaseStyle'
import {
  CommentTag,
  SectionTitle,
  CommentDivider,
  CommentContainer,
  CommentDiscussion,
} from './Style'

export default function EEGSummaryAlphaBandDevelopment(props: {
  alphaBandDevelopmentBarGraphImage: string | undefined
  alphaBandDevelopmentLineGraphImage: string | undefined
  ec_alpha_proportion: number
  eo_alpha_proportion: number
  ec_alpha_proportion_normal: number
  eo_alpha_proportion_normal: number
  ec_alpha_proportion_percentile: number
  eo_alpha_proportion_percentile: number
}) {
  const {
    alphaBandDevelopmentBarGraphImage,
    alphaBandDevelopmentLineGraphImage,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ec_alpha_proportion,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    eo_alpha_proportion,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ec_alpha_proportion_normal,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    eo_alpha_proportion_normal,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ec_alpha_proportion_percentile,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    eo_alpha_proportion_percentile,
  } = props
  const {t} = useTranslation()
  const classes = useStyles()

  let discussion = ''
  // let discussionLevel = ''
  let discussionTmp = ''
  // if (
  //   ec_alpha_proportion_normal > ec_alpha_proportion &&
  //   eo_alpha_proportion_normal > eo_alpha_proportion
  // ) {
  //   discussion = 'IEEGSummaryReportDiscussionEcEo'
  //   discussionLevel = 'IEEGSummaryReportDiscussionLevelHigh'
  //   discussionTmp = 'IEEGSummaryReportDiscussionDescEcEo'
  // } else if (ec_alpha_proportion_normal > ec_alpha_proportion) {
  //   discussion = 'IEEGSummaryReportDiscussionEc'
  //   discussionLevel = 'IEEGSummaryReportDiscussionLevelHigh'
  //   discussionTmp = 'IEEGSummaryReportDiscussionDescEc'
  // } else if (eo_alpha_proportion_normal > eo_alpha_proportion) {
  //   discussion = 'IEEGSummaryReportDiscussionEo'
  //   discussionLevel = 'IEEGSummaryReportDiscussionLevelHigh'
  //   discussionTmp = 'IEEGSummaryReportDiscussionDescEo'
  // } else {
  //   discussion = 'IEEGSummaryReportDiscussionNormal'
  //   discussionLevel = 'IEEGSummaryReportDiscussionLevelNormal'
  //   discussionTmp = 'IEEGSummaryReportDiscussionDescNormal'
  // }

  if (
    ec_alpha_proportion_percentile != null &&
    eo_alpha_proportion_percentile != null
  ) {
    if (
      ec_alpha_proportion_percentile < 16 &&
      eo_alpha_proportion_percentile < 16
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_1'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_1'
    } else if (
      ec_alpha_proportion_percentile < 16 &&
      eo_alpha_proportion_percentile > 16 &&
      eo_alpha_proportion_percentile <= 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_2'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_2'
    } else if (
      ec_alpha_proportion_percentile < 16 &&
      eo_alpha_proportion_percentile > 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_3'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_3'
    } else if (
      ec_alpha_proportion_percentile > 16 &&
      ec_alpha_proportion_percentile <= 84 &&
      eo_alpha_proportion_percentile < 16
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_4'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_4'
    } else if (
      ec_alpha_proportion_percentile > 16 &&
      ec_alpha_proportion_percentile <= 84 &&
      eo_alpha_proportion_percentile > 16 &&
      eo_alpha_proportion_percentile <= 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_5'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_5'
    } else if (
      ec_alpha_proportion_percentile > 16 &&
      ec_alpha_proportion_percentile <= 84 &&
      eo_alpha_proportion_percentile > 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_6'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_6'
    } else if (
      ec_alpha_proportion_percentile > 84 &&
      eo_alpha_proportion_percentile < 16
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_7'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_7'
    } else if (
      ec_alpha_proportion_percentile > 84 &&
      eo_alpha_proportion_percentile > 16 &&
      eo_alpha_proportion_percentile <= 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_8'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_8'
    } else if (
      ec_alpha_proportion_percentile > 84 &&
      eo_alpha_proportion_percentile > 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_9'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_9'
    }
  } else if (
    ec_alpha_proportion_percentile != null &&
    eo_alpha_proportion_percentile == null
  ) {
    if (ec_alpha_proportion_percentile < 16) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EC_1'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EC_1'
    } else if (
      ec_alpha_proportion_percentile > 16 &&
      ec_alpha_proportion_percentile <= 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EC_4'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EC_4'
    } else if (ec_alpha_proportion_percentile > 84) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EC_7'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EC_7'
    }
  } else if (
    ec_alpha_proportion_percentile == null &&
    eo_alpha_proportion_percentile != null
  ) {
    if (eo_alpha_proportion_percentile < 16) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EO_1'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EO_1'
    } else if (
      eo_alpha_proportion_percentile > 16 &&
      eo_alpha_proportion_percentile <= 84
    ) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EO_2'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EO_2'
    } else if (eo_alpha_proportion_percentile > 84) {
      discussion = 'IEEGSummaryReportAlphaDiscussion_EO_3'
      discussionTmp = 'IEEGSummaryReportAlphaDiscussionDesc_EO_3'
    }
  }

  return (
    <div className={classes.eeg} style={{marginTop: 45}}>
      <ReportSubtitle number='3'>
        {t('IEEGSummaryReportChildAlphaBandTitle')}
      </ReportSubtitle>
      <div className={classes.childValueGraphFull}>
        <div className='Child-info'>
          <SectionTitle className='Title'>
            {t('IEEGSummaryReportChildAlphaBandAbout')}
          </SectionTitle>
          <div className='Desc' style={{color: '#333333'}}>
            {t('IEEGSummaryReportChildAlphaBandDescription')}
          </div>
        </div>

        <div className='Child-Graph'>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={alphaBandDevelopmentLineGraphImage}
            alt='brain wave analysis 3d image'
          />
        </div>
        <div className='Child-Graph'>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={alphaBandDevelopmentBarGraphImage}
            alt='brain wave analysis 3d image'
          />
        </div>

        <div>
          <CommentTag>{t('IEEGSummaryReportChildResult')}</CommentTag>
          <CommentDivider />

          <CommentContainer>
            <CommentDiscussion>{t(discussion)}</CommentDiscussion>
            {t(discussionTmp)?.length > 0 && <div>{t(discussionTmp)}</div>}
          </CommentContainer>
        </div>
        <div className='Child-info-alert' style={{marginBottom: '45px'}}>
          {t('IEEGSummaryReportChildECEOAlert')}
        </div>
      </div>
    </div>
  )
}
