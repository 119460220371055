import {makeStyles} from '@material-ui/core'
// eslint-disable-next-line no-restricted-imports
import grey from '@material-ui/core/colors/grey'
import {createStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {
      flex: 1,
    },
    noSelect: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '8px 14px',
      minHeight: '1.1876rem',
      boxSizing: 'border-box',
      border: '3px solid #e9e9e9',
      borderRadius: 4,
      borderColor: grey[400],
      fontSize: '1rem',
    },
    empty: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '18.5px 14px',
      minHeight: '1.1876rem',
      boxSizing: 'border-box',
      border: '1px solid',
      borderRadius: 4,
      borderColor: grey[400],
      fontSize: '1rem',
    },
    iconButton: {
      marginLeft: 'auto',
    },
    boldNoSelect: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '5px',
      minHeight: '1.1876rem',
      boxSizing: 'border-box',
      border: '3px solid #e9e9e9',
      borderRadius: 4,
      fontSize: 14,
      height: 40,
    },
    selectBox: {
      height: 45,
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
