import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from 'components/atoms/Card'
import {styles} from 'components/Dialog/SuccessDialog'
import useConfirm from 'features/modal/useConfirm'
import React from 'react'
import {useTranslation} from 'react-i18next'

// 일반 다이얼로그는 z-index가 1300
// alert 다이얼로그는 z-index가 1400
// confirm 다이얼로그는 z-index를 1350
// 다른 다이얼로그보다 위에 표현되도록 하고 alert 다이얼로그보다 아래에 표현되도록 한다.

const useStyles = makeStyles(styles)

const ConfirmDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {open, message, onConfirm, onReject} = useConfirm()

  return (
    <Dialog open={open} className={classes.root} disableEscapeKeyDown>
      <Card className={classes.alertRoot}>
        <div>
          <div className={classes.alertContainer}>
            <Typography variant='h6' style={{whiteSpace: 'pre-line'}}>
              {message}
            </Typography>
          </div>

          <div
            className={classes.alertButtonContainer}
            style={{display: 'flex', flexWrap: 'nowrap', gap: 10}}
          >
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={onReject}
              style={{color: '#555555'}}
            >
              {t('INo')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={onConfirm}
            >
              {t('IYes')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default ConfirmDialog
