import useStyles from 'components/Select/selectFormStyle'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function SelectEmpty() {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.empty}>
      <span>{t('ISelectRequired')}</span>
    </div>
  )
}
