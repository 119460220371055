import request from 'api/index'
import type {
  AnalysisDivision,
  CodeType,
  ReportAnalysisType,
} from 'constants/AnalysisConstant'
import {ReportIcaAnalysisType} from 'constants/AnalysisConstant'

/// Note: 분석 관련 api 호출에 대하여
/// - 결과지 fetch api 중 기존 api와 호환을 위한 api들은 result를 요청한다
///   -> eeg individual, norm, mci
/// - 결과지 fetch api 중 신규 api는 report를 요청한다
///   -> summary

const normalizeSearch = (
  search: AnalysisSearchKind | undefined,
): NormalizedSearch | undefined => {
  if (!search || !search.searchValue) return undefined
  if (search.searchName === 'FILE_NAME') return search
  if (search.searchName === 'NAME') return search
  if (search.searchName === 'ECEO') return search
  if (search.searchName === 'INDEX') {
    const {searchName, searchValue} = search
    return {searchName, searchValue: searchValue.toString()}
  }
  if (search.searchName === 'INDIVIDUAL_INDEX') {
    const {searchName, searchValue} = search
    return {searchName, searchValue: searchValue.toString()}
  }
  return undefined
}

interface AnalysisSearchRequest extends PagingRequest, PeriodRequest {
  search: AnalysisSearchKind
}

export interface IndividualEEGSearchRequest extends AnalysisSearchRequest {
  oid?: string | null
}

export interface SearchedIndividualEEG {
  clientName: string
  eceo: 'EC' | 'EO'
  fileName: string
  id: number
  measureDate: string // ISO date string
  measureId: number | null
  normdbVersion: string
  progress: AnalysisStatus
  report: boolean
  additionalAnalysis: AnalysisStatus
  normStatus: '-' | 'o'
  summaryStatus: '-' | 'o'
  mciStatus: '-' | 'o'
  firstName: string
  lastName: string
  birth: string
  gender: Gender
  reAnalysisCount: string
  reAnalysisId: string
  createdAt: string
}

export const searchIndividualEEGApi = ({
  paging,
  period,
  search,
  oid,
}: IndividualEEGSearchRequest) => {
  const newSearch = normalizeSearch(search)
  console.log({search})
  return request<PageResponse<SearchedIndividualEEG>>({
    method: 'get',
    path: '/api/analysis/v1/individual/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
      oid,
    },
  })
}
export type TypeScreenGraph = 'norm' | 'individual'
export type TypeGraph = 'raw' | 'component' | 'cleaned'
export interface AnalysisResultItem {
  id: number
  requestId?: number
  normativeId?: number
  analysisType: ReportAnalysisType
  analysisName: string
  bandType: number
  bandTypeName?: string
  cleanedDataName?: string
  cleanedDataPath: string
  subType: number
  componentType: number
  ecEo: '0' | '1'
  imgPath: string
  imgType?: string
  icaCheckYn: YN
  icaCheckAutoYn: YN
  icaCheckUpdateDate?: string | null
  rawDataPath: string
  psdScaleOrder: number
  psdScale: string
  psdPath?: string | null
  titleDescription: string | null
  pdfOrder?: string
  asVersion: string
  createdAt: string // ISO Date
  rawDataNamePath: string
  rejectedTime?: any
}

export interface AnalysisResultInfo {
  id: number
  age: number
  treatmentAge?: number // ADDED
  birth: string
  calibrationId: number
  chartNo: string
  clientId: number
  diseaseCode: string
  diseaseId?: number | null
  diseaseType?: string | null
  ecEo?: string | null
  gender?: string | null
  hand?: string | null
  createdAt: string // ISO string
  measureDate: string // yyyy-mm-dd
  postYn: YN
  patientId: number
}

export interface AnalysisResultIndicator {
  codeId: ReportAnalysisType
  codeName: string
  codePrintYn: YN
  codeSort: number
  titleDescription: string
}

export interface IcaAnalysisResultIndicator
  extends Omit<AnalysisResultIndicator, 'codeId'> {
  codeId: ReportIcaAnalysisType
}

// resultInfo.treatmentAge (측정 당시 나이) 필드 추가
// age 현재나이, teatmentAge: 측정당시 나이
export interface EEGResult {
  codeList: AnalysisResultIndicator[]
  componentList: number[]
  resultDetailList: AnalysisResultItem[]
  resultInfo: AnalysisResultInfo
}

export interface FetchIndividualEEGResultRequest {
  individualRequestIds: number[]
  codeTypeDivision: AnalysisDivision
  codeType: CodeType
  codeVersion: string
  locale: string
  imgType?: 'WEB' | 'PDF'
  analysisType?: ReportAnalysisType
}

export const fetchIndividualEEGResult = async (
  data: FetchIndividualEEGResultRequest,
) => {
  return request<ModelsResponse<EEGResult>>({
    method: 'post',
    path: '/api/analysis/v2/individual/result',
    data,
  })
}

// normRequestIds
export interface FetchNormativeEEGResultRequest
  extends Omit<FetchIndividualEEGResultRequest, 'individualRequestIds'> {
  normRequestIds: number[]
  locale: string
}

export const fetchNormativeEEGResult = async (
  data: FetchNormativeEEGResultRequest,
) => {
  return request<ModelsResponse<EEGResult>>({
    method: 'post',
    path: '/api/analysis/v2/individual/normative/result',
    data,
  })
}

export interface FetchNormativeContentRequest {
  normRequestId: number
  analysisType: ReportAnalysisType
}

export interface NormativeContent {
  dTable: string
}

export const fetchNormativeEEGContent = async (data: {
  normRequestId: number
  analysisType: ReportAnalysisType
}) => {
  return request<ModelResponse<NormativeContent>>({
    method: 'post',
    path: '/api/analysis/v1/individual/normative/content',
    data,
  })
}

export interface Reference {
  citation: string
  referenceId: string
  referenceNo: string
}

export const fetchReferenceApi = async (referenceId: string) => {
  return request<ModelsResponse<Reference>>({
    method: 'post',
    path: '/api/analysis/v1/individual/reference',
    data: {
      referenceId,
    },
  })
}

export interface FetchZScoreListRequest {
  individualRequestIds: number[]
}

export type ZScore = 1.96 | 2.58 | 3

export interface ZScoreListItem {
  requestId: number
  zscore: ZScore
}

/**
 * zScore 별로 Norm 분석 진행된 개별 분석의 ID 목록을 가져옵니다.
 * 이미 분석된 Norm 분석이 있을 때 필터링 하기 위해 사용합니다.
 */
export const fetchZScoreList = (data: FetchZScoreListRequest) => {
  return request<ModelsResponse<ZScoreListItem>>({
    method: 'post',
    path: '/api/analysis/v1/individual/normative/z-score',
    data,
  })
}

export interface IndividualNormSearchRequest extends AnalysisSearchRequest {}

export interface SearchedIndividualNorm {
  clientName: string
  eceo: 'EC' | 'EO'
  fileName: string
  id: number
  individualId: number
  measureDate: Date
  normdbVersion: string
  report: boolean
  progress: AnalysisStatus
  additionalAnalysis: AnalysisStatus
  threeDProgress: string // TODO: progress 상태값 확인
  zscore: number
  firstName: string
  lastName: string
  birth: string
  gender: Gender
}

export const searchIndividualNormApi = ({
  paging,
  period,
  search,
}: IndividualNormSearchRequest) => {
  const newSearch = normalizeSearch(search)
  return request<PageResponse<SearchedIndividualNorm>>({
    method: 'get',
    path: '/api/analysis/v1/individual/normative/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
    },
  })
}

interface SummarySearchRequest extends AnalysisSearchRequest {
  summaryType?: 'EEG' | 'HRV'
}

interface SearchedSummary {
  id: number
  eceo: 'EC' | 'EO'
  clientName: string
  fileName: string
  measureDate: Date
  normdbVersion: string
  progress: AnalysisStatus
  firstName: string
  lastName: string
  birth: string
  gender: Gender
  reportType: ReportType
  name: string
  additionalAnalysis: AnalysisStatus
}

// const searchSummaryApi = ({
//   paging,
//   period,
//   search,
//   summaryType = 'EEG',
// }: SummarySearchRequest) => {
//   const newSearch = normalizeSearch(search)
//   return request<PageResponse<SearchedIndividualSummary>>({
//     method: 'get',
//     path: '/api/analysis/v2/individual/summary/list',
//     params: {
//       ...paging,
//       ...newSearch,
//       ...period,
//       summaryType,
//     },
//   })
// }

// ADDED API
const searchSummaryApi = ({
  paging,
  period,
  search,
  summaryType = 'EEG',
}: SummarySearchRequest) => {
  const newSearch = normalizeSearch(search)
  return request<PageResponse<SearchedIndividualSummary>>({
    method: 'get',
    path: '/api/analysis/v3/individual/summary/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
      summaryType,
    },
  })
}

export interface IndividualSummarySearchRequest extends AnalysisSearchRequest {}

export interface SearchedIndividualSummary extends SearchedSummary {
  // individualId: number
  // reAnalysisId: number
  // fileName: string
  chartNo: string
  finalAnalysisYn?: null | 'Y' | 'N'
  report: boolean
  reportType: ReportType
  additionalAnalysis: AnalysisStatus

  ecIndividualId: number
  ecFileName: string
  ecReAnalysisId: '-' | number

  eoIndividualId: number
  eoFileName: string
  eoReAnalysisId: number

  summaryType: 'Y' | 'S' // 소아청소년(Y), 일반(S)
  finalReportPath?: null | string
}

export const searchIndividualSummaryApi = ({
  paging,
  period,
  search,
}: IndividualSummarySearchRequest) =>
  searchSummaryApi({paging, period, search, summaryType: 'EEG'})

export interface FetchIndividualSummaryReportRequest {
  summaryIds: number[]
  summaryLang: LanguageCode
}

export interface Lobe {
  Occipital_RH: string
  Parietal_RH: string
  Temporal_RH: string
  Occipital_LH: string
  Parietal_LH: string
  Frontal_RH: string
  Temporal_LH: string
  Frontal_LH: string
}

export interface Roi {
  Rostral_Middle_Frontal_LH: string
  Paracentral_RH: string
  Superior_Parietal_RH: string
  Inferior_Parietal_RH: string
  Insula_LH: string
  Posterior_Cingulate_LH: string
  Superior_Temporal_LH: string
  Temporal_Pole_LH: string
  Inferior_Temporal_LH: string
  Superior_Frontal_LH: string
  Supramarginal_LH: string
  Pars_Triangularis_RH: string
  Banks_of_Superior_Temporal_Sulcus_RH: string
  Lateral_Orbitofrontal_RH: string
  Postcentral_RH: string
  Precentral_LH: string
  Parahippocampal_RH: string
  Lingual_LH: string
  Caudal_Middle_Frontal_RH: string
  Pars_Opercularis_RH: string
  Cuneus_LH: string
  Rostral_Anterior_Cingulate_RH: string
  Transverse_Temporal_RH: string
  Entorhinal_LH: string
  Frontal_Pole_LH: string
  Precuneus_LH: string
  Middle_Temporal_RH: string
  Caudal_Anterior_Cingulate_LH: string
  Pericalcarine_LH: string
  Lateral_Occipital_RH: string
  Fusiform_RH: string
  Pars_Orbitalis_LH: string
  Lateral_Orbitofrontal_LH: string
  Paracentral_LH: string
  Medial_Orbitofrontal_LH: string
  Inferior_Temporal_RH: string
  Superior_Temporal_RH: string
  Superior_Parietal_LH: string
  Banks_of_Superior_Temporal_Sulcus_LH: string
  Inferior_Parietal_LH: string
  Temporal_Pole_RH: string
  Insula_RH: string
  Posterior_Cingulate_RH: string
  Superior_Frontal_RH: string
  Precentral_RH: string
  Rostral_Middle_Frontal_RH: string
  Parahippocampal_LH: string
  Pars_Opercularis_LH: string
  Pars_Triangularis_LH: string
  Postcentral_LH: string
  Fusiform_LH: string
  Supramarginal_RH: string
  Precuneus_RH: string
  Transverse_Temporal_LH: string
  Caudal_Middle_Frontal_LH: string
  Rostral_Anterior_Cingulate_LH: string
  Pericalcarine_RH: string
  Caudal_Anterior_Cingulate_RH: string
  Cuneus_RH: string
  Middle_Temporal_LH: string
  Entorhinal_RH: string
  Pars_Orbitalis_RH: string
  Lingual_RH: string
  Frontal_Pole_RH: string
  Lateral_Occipital_LH: string
  Medial_Orbitofrontal_RH: string
}

export interface EEGSummaryResponse {
  alphaBandDevelopment: {
    ec_alpha_proportion: number
    eo_alpha_proportion: number
    ec_alpha_proportion_normal: number
    eo_alpha_proportion_normal: number

    ec_alpha_proportion_percentile: number
    eo_alpha_proportion_percentile: number
  }
  alphaBandDevelopmentBarGraphImage: string
  alphaBandDevelopmentLineGraphImage: string
  analysisTime: string // date time
  treatmentAt?: string // date time

  barchartFrontalImage: string
  barchartOccipitalImage: string
  barchartParietalImage: string
  barchartTemporalImage: string

  brainAge: {
    avg: number
    by_lobe: Lobe
    subject_age: number
  }
  brainAgeGraphImage: string
  brainBalance: {
    LH: number
    RH: number
  }
  brainBalance3dImage: string
  brainBalanceImage: string

  contourImage?: string
  finalReportPath: string

  frontalLobeDevelopment: {
    sensor_tbr_cz: number
    percentile?: number
    // normal_range_min: number
    // normal_range_max: number
  }
  frontalLobeDevelopmentColorbarImage: string
  frontalLobeDevelopmentGraph_image: string

  id: number

  informationProcessingSpeed: {
    occipital_alpha_peak_freq: number
    percentile: number
    // normal_range_min: number
    // normal_range_max: number
  }
  informationProcessingSpeedColorbarImage: string
  informationProcessingSpeedGraphImage: string

  lobe: Lobe
  reportType: ReportType
  roi: Roi
  threeDimensionImage: string
  total: string

  requestId: string
  eoRequestId: string
  finalAnalysisYn: string
}

export interface EEGSummaryResponseWithClient extends EEGSummaryResponse {
  client: Customer
}

export const fetchIndividualSummaryReportApi = async (
  data: FetchIndividualSummaryReportRequest,
) => {
  return request<ModelsResponse<EEGSummaryResponseWithClient>>({
    method: 'post',
    path: '/api/analysis/v2/individual/summary/report',
    data,
  })
}

export interface FetchMCIReportRequest {
  requestIds: number[]
  locale: string
}

export interface MCIReport {
  id: number
  patientId: number
  requestId: number
  age: number
  birth: string
  calibrationId: number
  chartNo: string
  clientId: number
  createdAt: string
  diseaseCode: string
  diseaseId: number
  diseaseType: string
  ecEo: string
  gender: string
  hand: string
  imgPath: string
  measureDate: string
  probability: number
  clientName: string
  clientFirstName?: string
  clientLastName?: string
}

export const fetchMciReportApi = (data: FetchMCIReportRequest) => {
  return request<ModelsResponse<MCIReport>>({
    method: 'post',
    path: '/api/analysis/v2/individual/mci/result',
    data,
  })
}

export interface IndividualMCISearchRequest extends AnalysisSearchRequest {}

export interface SearchedIndividualMCI {
  clientName: string
  eceo: 'EC' | 'EO'
  fileName: string
  id: number
  individualId: number
  measureDate: Date
  // normdbVersion: string
  progress: AnalysisStatus
  report: boolean
  // threeDProgress: string // TODO: progress 상태값 확인
  // zscore: number
  firstName: string
  lastName: string
  birth: string
  gender: Gender
  reAnalysisId: string | '-'
  finalAnalysisYn?: 'Y' | 'N'
  name: string
}

export const searchIndividualMCIApi = ({
  paging,
  period,
  search,
}: IndividualMCISearchRequest) => {
  const newSearch = normalizeSearch(search)
  return request<PageResponse<SearchedIndividualMCI>>({
    method: 'get',
    path: '/api/analysis/v2/individual/mci/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
    },
  })
}

export interface HRVSearchRequest extends AnalysisSearchRequest {}

export interface SearchedHRV {
  clientName: string
  eceo: 'EC' | 'EO'
  fileName: string
  id: number
  individualId: number
  measureDate: Date
  normdbVersion: string
  progress: AnalysisStatus
  report: boolean
  firstName: string
  lastName: string
  birth: string
  gender: Gender
  createdAt: Date
}

export const searchHrvApi = ({paging, period, search}: HRVSearchRequest) => {
  const newSearch = normalizeSearch(search)
  return request<PageResponse<SearchedHRV>>({
    method: 'get',
    path: '/api/analysis/v1/hrv/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
    },
  })
}

export const fetchIndividualHRVResult = async (
  data: FetchIndividualEEGResultRequest,
) => {
  return request<ModelsResponse<EEGResult>>({
    method: 'post',
    path: '/api/analysis/v2/hrv/result',
    data,
  })
}

export interface HRVNormSearchRequest extends AnalysisSearchRequest {}

export interface SearchedHRVNorm {
  clientName: string
  eceo: 'EC' | 'EO'
  fileName: string
  id: number
  individualId: number
  measureDate: Date
  normdbVersion: string
  progress: AnalysisStatus
  report: boolean
  firstName: string
  lastName: string
  birth: string
  gender: Gender
  createdAt: Date
}

export const searchHrvNormApi = ({
  paging,
  period,
  search,
}: HRVNormSearchRequest) => {
  const newSearch = normalizeSearch(search)
  return request<PageResponse<SearchedHRVNorm>>({
    method: 'get',
    path: '/api/analysis/v1/hrv/normative/list',
    params: {
      ...paging,
      ...newSearch,
      ...period,
    },
  })
}

export interface HRVSummarySearchRequest extends AnalysisSearchRequest {}

export interface SearchedHRVSummary extends SearchedSummary {
  finalReportPath?: string
}

export const searchHRVSummaryApi = ({
  paging,
  period,
  search,
}: HRVSummarySearchRequest) =>
  searchSummaryApi({paging, period, search, summaryType: 'HRV'})

export const isAnalysisSearchName = (
  searchName?: KeyOfAnalysisSearchKind | null | string,
): searchName is KeyOfAnalysisSearchKind => {
  if (searchName === null || searchName === undefined || searchName === '')
    return false

  if (searchName.toUpperCase() === 'NAME') return true
  if (searchName.toUpperCase() === 'INDEX') return true
  if (searchName.toUpperCase() === 'FILE_NAME') return true
  if (searchName.toUpperCase() === 'MEASURE_DATE') return true
  if (searchName.toUpperCase() === 'ECEO') return true
  return false
}

export interface HRVSummaryResponse {
  anxiety: number
  balanceOpinion: string
  circularImage: string
  contour: number
  contourImage: string
  depression: number
  finalReportPath: string
  histogram: number
  histogramImage: string
  id: number
  phase: number
  reportType: ReportType
  tableImage: string
  treatmentAt?: string
}

export interface HRVSummaryResponseWithClient extends HRVSummaryResponse {
  client: Customer
}

export const fetchHRVSummaryReportApi = (
  data: FetchIndividualSummaryReportRequest,
) =>
  request<ModelsResponse<HRVSummaryResponseWithClient>>({
    method: 'post',
    path: '/api/analysis/v2/hrv/summary/report',
    data,
  })

export interface FetchCareSummaryReportRequest {
  careId: string
  uid: string
  requestId: number
  summaryLang: LanguageCode
  type?: 'ALL' | 'EC' | 'EO' | 'HRV'
}

export interface Customer {
  clientAge: number
  clientBirth: string
  clientGender: string
  clientHand: string
  clientName: string
  eceo: 'EC' | 'EO' | 'NULL' | null
  diseaseCode: string
  clientFirstName?: string
  clientLastName?: string
  treatmentAge: number // ADD V2
  clientEmail?: string // ADD V2
}

export interface MCISummaryResponse {
  id: number
  imagePath: string
  probability: number
  reportType: string
  finalReportPath: string
  treatmentAt?: string
}

export interface CareSummaryReport {
  customer: Customer
  ec?: EEGSummaryResponse // TODO: 소아청소년 필드 추가 필요
  eo?: EEGSummaryResponse // TODO: 소아청소년 필드 추가 필요
  hrv?: HRVSummaryResponse
  mci?: MCISummaryResponse
  requestId?: string
  eoRequestId?: string
}

// export const fetchCareSummaryReportApi = (
//   params: FetchCareSummaryReportRequest,
// ) =>
//   request<ModelResponse<CareSummaryReport>>({
//     method: 'post',
//     path: '/api/analysis/v1/wave/summary-report',
//     params: {...params, type: params.type ?? 'ALL'},
//   })

export interface ExecuteAnalysisRequest {
  individualRequestIds: number[]
}

export interface ExecuteNormAnalysisRequest extends ExecuteAnalysisRequest {
  zscore: ZScore
}

export const executeEegNormAnalysisApi = (data: ExecuteNormAnalysisRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/normative/request',
    data,
  })

export const executeEegMciAnalysisApi = async (
  data: ExecuteAnalysisRequest,
): Promise<Failable<void, string>> => {
  try {
    await request<CommonResponse>({
      method: 'post',
      path: '/api/analysis/v1/individual/mci/request',
      data,
    })
    return {isError: false}
  } catch (err) {
    return {isError: true, error: err.message}
  }
}

export type EEGSummaryType = 'EEG_STANDARD_SUMMARY' | 'EEG_PREMIUM_SUMMARY'
export type HRVSummaryType = 'HRV_STANDARD_SUMMARY' | 'HRV_PREMIUM_SUMMARY'

export const executeNormalEegSummaryApi = (ids: number[]) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/summary/request',
    data: {
      individualRequestIds: ids,
      type: 'EEG_STANDARD',
    },
  })

export const executePremiumEegSummaryApi = (ids: number[]) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/summary/request',
    data: {
      individualRequestIds: ids,
      type: 'EEG_PREMIUM',
    },
  })

interface ExecuteHrvSummaryAnalysisRequest extends ExecuteAnalysisRequest {
  type: HRVSummaryType
}

export const executeHrvSummaryAnalysisApi = async (
  data: ExecuteHrvSummaryAnalysisRequest,
): Promise<Failable<void, string>> => {
  try {
    await request<CommonResponse>({
      method: 'post',
      path: '/api/analysis/v1/hrv/summary/request',
      data,
    })
    return {isError: false}
  } catch (err) {
    return {isError: true, error: err.message}
  }
}

export interface ExecuteWaveAnalysisRequest extends ExecuteAnalysisRequest {
  uid: string
  uuid: string
}

export interface EDFSearchRequest extends PagingRequest, PeriodRequest {
  search: EdfSearchKind
}

type EdfType = 'ANALYSIS' | 'EDF'

export interface SearchedEDF {
  careId: number
  patientFirstName: string
  patientLastName: string
  doctorFirstName: string
  doctorLastName: string
  careDate: string
  status: string
  uuid: string
  chartNo: string
  birth: string
  message?: any
  type: EdfType
}

export const searchEdfApi = ({paging, period, search}: EDFSearchRequest) =>
  request<PageResponse<SearchedEDF>>({
    method: 'get',
    path: '/api/edf/v1/file/list',
    params: {
      ...paging,
      ...search,
      ...period,
    },
  })

export interface EdfRecord {
  careId: number
  ecEdf: string[] | null
  eoEdf: string[] | null
  ppgEdf: string | null
}

interface EdfDownloadTarget {
  careId: number
  type: EdfType
}

interface EdfDownloadRequest {
  careIds: EdfDownloadTarget[]
}

export const downloadEdfApi = (data: EdfDownloadRequest) =>
  request<ModelsResponse<EdfRecord>>({
    method: 'post',
    path: '/api/edf/v1/file/download',
    data,
  })

export type IndividualOrNormativeEEGCodeType = 'I' | 'N'
export type ThreeDEEGType = 'R' | 'N'

export const toThreeDEEGType = (
  type: IndividualOrNormativeEEGCodeType,
): ThreeDEEGType => {
  if (type === 'I') return 'R'
  return 'N'
}

export type ThreeDAnalysisType = 'P' | 'R' | 'C'
export type ThreeDSideType = 'L' | 'R' | 'B'
export type ThreeDZScoreRange = '15' | '20' | '25' | null

interface FetchThreeDPowerRequest {
  requestId: number
  gb: ThreeDEEGType
}

export interface ThreeDPowerResponse {
  analysisType: ThreeDAnalysisType
  sideType: ThreeDSideType
}

export const fetch3DPowerApi = (data: FetchThreeDPowerRequest) =>
  request<ModelsResponse<ThreeDPowerResponse>>({
    method: 'post',
    path: '/api/analysis/v1/individual/3d/neural-use-power',
    data,
  })

interface FetchThreeDZScoreRequest {
  requestId: number
  gb: ThreeDEEGType
  analysisType: ThreeDAnalysisType
  sideType: ThreeDSideType
  zscore: ThreeDZScoreRange
}

export interface ThreeDZScoreResponse {
  bandType: number
  labelName: string
  zscoreValue: number
}

export const fetch3DZScoreApi = (data: FetchThreeDZScoreRequest) =>
  request<ModelsResponse<ThreeDZScoreResponse>>({
    method: 'post',
    path: '/api/analysis/v1/individual/3d/neural-zscore',
    data,
  })

interface FetchThreeDReportRequest {
  requestIds: number[]
  gb: ThreeDEEGType
  analysisType: ThreeDAnalysisType
  sideType: ThreeDSideType
  bandType: number
}

export interface ThreeDReport {
  gb: ThreeDEEGType
  analysisType: ThreeDAnalysisType
  sideType: ThreeDSideType
  bandName: string
  bandType: number
  filePath: string
  zscore: string
  zscoreLeftValue: string
  zscoreMiddleValue: string
  zscoreRightValue: string
}

export interface FetchThreeDReportResponse {
  neural3dDataList: ThreeDReport[]
  neural3dZscoreDataList?: ThreeDZScoreResponse[]
  zScoreStrList: ThreeDZScoreRange[]
}

export const fetch3DReportApi = (data: FetchThreeDReportRequest) =>
  request<ModelsResponse<FetchThreeDReportResponse>>({
    method: 'post',
    path: '/api/analysis/v1/individual/3d/result',
    data: {
      ...data,
      firstLoad: 'S',
    },
  })

interface ExecuteIndividualAdditionalAnalysisRequest {
  individualRequestIds: number[]
  codeType: IndividualOrNormativeEEGCodeType
  codeTypeDivision: 'EEG' | 'HRV'
  codeVersion: string
}

export const executeIndividualAdditionalAnalysisApi = (
  data: ExecuteIndividualAdditionalAnalysisRequest,
) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/additional-request',
    data,
  })

interface ExecuteNormativeAdditionalAnalysisRequest {
  normRequestIds: number[]
  codeType: IndividualOrNormativeEEGCodeType
  codeTypeDivision: 'EEG' | 'HRV'
  codeVersion: string
}

export const executeNormativeAdditionalAnalysisApi = (
  data: ExecuteNormativeAdditionalAnalysisRequest,
) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/normative/additional-request',
    data,
  })

interface FetchIsIcaAvailableReq {
  requestIds: number[]
}

export interface AnalysisAvailable {
  totalPoint: number
  detailDto: AnalysisAvailableItem[]
}

interface AnalysisAvailableItem {
  requestIds: number[]
  requestId?: number
  pairRequestIds?: number[] // 소아 청소년인 경우
  status: 'SUCCESS' | 'FAIL' | 'PAIR' // 소아 청소년인경우, 페이ㅓ데이터가 존재하지만 선택하지 않았을경우 상태 'PAIR' 추가
  point: string
  description: string
  young: boolean
}

export const fetchIsIcaAvailableApi = (params: FetchIsIcaAvailableReq) =>
  request<ModelResponse<AnalysisAvailable>>({
    method: 'post',
    path: '/api/analysis/v1/individual/ica/check',
    params,
  })

interface RequestIcaReq {
  requestIds: number[]
  reSelectYn: YN
  reAnalysisYn: YN
  sraYn?: YN
}

export interface IcaRequestRes {
  requestId: number
  reAnalysisId: number
  resultCode: 'SUCCESS' | 'FAIL'
  resultMsg: string
}

export const requestIcaApi = (params: RequestIcaReq) =>
  request<ModelsResponse<IcaRequestRes>>({
    method: 'post',
    path: '/api/analysis/v1/individual/ica/request',
    params: {
      ...params,
      agreeYn: 'Y',
      codeType: 'I',
      codeTypeDivision: 'EEG',
    },
  })

type IcaPreview = 'P'
type IcaPostProcessing = 'C'

interface UpdateIcaReq {
  requestId: number
  componentType: number[]
  icaType: IcaPreview | IcaPostProcessing
}

export const updateIcaApi = (params: UpdateIcaReq) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/ica/update',
    params,
  })

interface FinalizeIcaReq {
  requestId: number
  timeArray: number[]
}

export const finalizeIcaApi = (params: FinalizeIcaReq) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v1/individual/ica/final',
    params: {
      ...params,
      timeArray: params.timeArray.length > 0 ? params.timeArray : '',
    },
  })

export const fetchIndividualEegStatus = (requestId: number) =>
  request<ModelResponse<AnalysisStatus>>({
    method: 'get',
    path: '/api/analysis/v1/individual/status',
    params: {
      requestId,
    },
  })

//-------------------------------------------------------------------
// checker api
//-------------------------------------------------------------------
export type AnalysisCheckApi = (
  ids: number[],
) => Promise<ModelResponse<AnalysisAvailable>>

export const checkEegNormApi: AnalysisCheckApi = (ids) =>
  request({
    method: 'post',
    path: '/api/analysis/v1/individual/normative/check',
    params: {
      requestIds: ids.join(','),
    },
  })

export const checkEegMciApi: AnalysisCheckApi = (ids) =>
  request({
    method: 'post',
    path: '/api/analysis/v1/individual/mci/check',
    params: {
      requestIds: ids.join(','),
    },
  })

// export const checkEegSummaryNormalApi: AnalysisCheckApi = (ids: number[]) =>
//   request({
//     method: 'post',
//     path: '/api/analysis/v1/individual/summary/check',
//     params: {requestIds: ids.join(','), type: 'EEG_STANDARD'},
//   })

// export const checkEegSummaryPremiumApi: AnalysisCheckApi = (ids: number[]) =>
//   request({
//     method: 'post',
//     path: '/api/analysis/v2/individual/summary/check',
//     params: {requestIds: ids.join(','), type: 'EEG_PREMIUM'},
//   })

//-------------------------------------------------------------------
// re-analysis finalize api
//-------------------------------------------------------------------
export type ReAnalysisFinalizeApi = (id: number) => Promise<CommonResponse>

export const finalizeEegMciApi: ReAnalysisFinalizeApi = (id) =>
  request({
    method: 'post',
    path: '/api/analysis/v1/individual/mci/final-analysis',
    data: {
      requestId: id,
    },
  })
export const finalizeEegSummary: ReAnalysisFinalizeApi = (id) =>
  request({
    method: 'post',
    path: '/api/analysis/v1/individual/summary/final-analysis',
    data: {
      requestId: id,
    },
  })

export interface BitKey {
  bitKey: string
  key: string
}
// edf 암호화 키
export const fetchEdfEncryptApi = (key: string) =>
  request<ModelResponse<BitKey>>({
    method: 'get',
    path: `/api/edf/v1/Encryption`,
    params: {id: key},
  })

/* #################### ADDED API ###################### */

export const executeWaveAnalysisApi = (data: ExecuteWaveAnalysisRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/analysis/v3/wave/request',
    data,
  })

export const checkEegSummaryNormalApi: AnalysisCheckApi = (ids: number[]) =>
  request({
    method: 'post',
    path: '/api/analysis/v2/individual/summary/check',
    params: {requestIds: ids.join(','), type: 'EEG_STANDARD'},
  })

export const checkEegSummaryPremiumApi: AnalysisCheckApi = (ids: number[]) =>
  request({
    method: 'post',
    path: '/api/analysis/v2/individual/summary/check',
    params: {requestIds: ids.join(','), type: 'EEG_PREMIUM'},
  })

/* #################### MODIFIED API ###################### */

export const fetchCareSummaryReportApi = (
  params: FetchCareSummaryReportRequest,
) =>
  request<ModelResponse<CareSummaryReport>>({
    method: 'post',
    path: '/api/analysis/v2/wave/summary-report',
    params: {...params, type: params.type ?? 'ALL'},
  })
