import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import useSuccessModal from 'features/modal/useSuccessModal'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const styles = () =>
  createStyles({
    root: {
      // @ts-ignore
      zIndex: '1400 !important',
    },
    alertRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    alertContainer: {
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      padding: '20px 38px',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'column',
      flex: 1,
      width: 400,
      gap: 7,
      margin: '13px 0 0 0',
      '& h2': {
        fontWeight: 'bolder',
        color: '#5a6268',
        size: 16,
        margin: 5,
      },
      '& h5': {
        fontWeight: 'bolder',
        color: '#5a6268',
        size: 16,
      },
      '& h6': {
        fontSize: 15,
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '5px 0',
        flexWrap: 'wrap',
        flex: 1,
        color: '#717376',
      },
      '& TextField': {
        flex: 1,
      },
    },
    alertIcon: {
      fontSize: 48,
      color: '#2db1ae',
      marginTop: 20,
    },
    alertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '13px 20px',
      justifyContent: 'center',
      flexWrap: 'wrap',
      background: '#f4f4f4',

      '& button': {
        width: '65%',
        color: 'white',
      },
    },
    failIcon: {
      fontSize: 48,
      color: '#EB5E5C',
    },
  })

const useStyles = makeStyles(styles)

const SuccessDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  const {open, message, onClose} = useSuccessModal()

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <div>
        <div className={classes.alertContainer}>
          <CheckCircleIcon className={classes.alertIcon} />
          <Typography variant='h5'>{t('IProcessSuccess')}</Typography>
          <Typography variant='h6' style={{whiteSpace: 'pre-line'}}>
            {message}
          </Typography>
        </div>
        <div className={classes.alertButtonContainer}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={onClose}
          >
            {t('IOk')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default SuccessDialog
