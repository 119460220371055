import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {styles} from 'components/Dialog/findFormStyle'

const useStyles = makeStyles(() => ({
  ...styles(),
}))

export default function PasswordInput() {
  const {
    formState: {errors},
  } = useFormContext()

  const firstLoginPassword = useFormContext()

  const classes = useStyles()
  const {t} = useTranslation()

  const passwordClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.currentPwd !== undefined,
  })

  const newPasswordClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.newPwd !== undefined,
  })

  return (
    <div className={classes.root}>
      <label htmlFor='input_orgName' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IPasswordPrevious')}</span>
          <input
            id='input_orgName'
            type='password'
            className={passwordClasses}
            placeholder={t('IPasswordRequired')}
            {...firstLoginPassword.register('currentPwd', {
              required: 'IPasswordRequired',
              minLength: {
                value: 4,
                message: 'IPasswordLength',
              },
              maxLength: {
                value: 20,
                message: 'IPasswordLength',
              },
            })}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
          {errors.currentPwd?.message && (
            <span className='Right ErrorText'>
              {t(`${errors.currentPwd?.message}`)}
            </span>
          )}
        </div>
      </label>

      <label htmlFor='input_orgTel' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IPasswordNext')}</span>
          <input
            id='input_orgTel'
            type='password'
            className={newPasswordClasses}
            placeholder={t('IPasswordRequired')}
            {...firstLoginPassword.register('newPwd', {
              required: 'IPasswordRequired',
              minLength: {
                value: 8,
                message: 'IPasswordLength',
              },
              maxLength: {
                value: 20,
                message: 'IPasswordLength',
              },
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/,
                message: 'IPasswordInvalid',
              },
            })}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
          {errors.newPwd?.message && (
            <span className='Right ErrorText'>
              {t(`${errors.newPwd?.message}`)}
            </span>
          )}
        </div>
      </label>
    </div>
  )
}
