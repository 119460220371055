/* eslint-disable no-lonely-if */

import {useAppDispatch, useAppSelector} from 'hooks'

import {
  addGroup,
  setSelectedGroup,
  GroupType,
  selectGroups,
  selectSelectedGroup,
  addSetToAGroup,
  selectSelectedSet,
  setSelectedSet,
  updateElectrode,
  updateFrequencyOfASet,
  updateDurationOfASet,
  selectDeselectAllElectrode,
  fetchPBMGroupList,
  fetchElectrodesByGroup,
  addNewGroupThunk,
  updateGroupListThunk,
  addNewSetToAGroupThunk,
  updateSetThunk,
  changeNameOfAGroupThunk,
  changeNameOfAAddedGroup,
  removeAGroup,
  toBeDeletedGroups,
  deleteGroupThunk,
  removeASet,
  addToDeleteSetArray,
  toBeDeletedSets,
  reInitializeState,
  isAnyChange,
  Set2,
} from 'features/analysis/nirCareLedSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {useTranslation} from 'react-i18next'
import {ValueSelectItems} from 'components/V2Select/Select/Select'
import useConfirm from 'features/modal/useConfirm'
import useAuth from '../../../../features/auth/useAuth'

export default function PrescriptionViewModel() {
  const dispatch = useAppDispatch()
  const Groups: GroupType[] = useAppSelector(selectGroups)
  const selectedGroup: GroupType = useAppSelector(selectSelectedGroup)
  const deletedGroups: GroupType[] = useAppSelector(toBeDeletedGroups)
  const isAnyChangeInPrescription: boolean = useAppSelector(isAnyChange)
  const deletedSets: any[] = useAppSelector(toBeDeletedSets)
  const selectedSet: any = useAppSelector(selectSelectedSet)
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {t} = useTranslation()
  const {user: currentUser} = useAuth()
  const {onOpen: onFailureOpen} = useFailureModal()
  const {onOpen: onConfirmOpen} = useConfirm()
  const newEmptySetStructure = (setName: string): Set2 => {
    return {
      label: setName,
      status: 'ADD',

      c3: 'N',
      c4: 'N',
      cz: 'N',
      f3: 'N',
      f4: 'N',
      f7: 'N',
      f8: 'N',
      fp1: 'N',
      fp2: 'N',
      fz: 'N',
      o1: 'N',
      o2: 'N',
      p3: 'N',
      p4: 'N',
      pz: 'N',
      t3: 'N',
      t4: 'N',
      t5: 'N',
      t6: 'N',
      frequency: 10,
      settingTime: 300,
      therapyMode: 'PRESCRIPTION',
    }
  }

  const getElectrodeStatus = () => {
    let tempElectrode: any = {}
    Groups.forEach((groupIterator) => {
      if (groupIterator.label === selectedGroup.label) {
        // @ts-ignore
        groupIterator?.protocolList.forEach((setIterator) => {
          if (setIterator.label === selectedSet.label) {
            tempElectrode = setIterator
          }
        })
      }
    })
    return tempElectrode
  }

  const updateElectrodeStatus = (
    electrodeName: string,
    electrodeValue: 'Y' | 'N',
  ) => {
    dispatch(updateElectrode({electrodeName, electrodeValue}))
  }
  const updateFrequency = (newData: ValueSelectItems) => {
    dispatch(updateFrequencyOfASet({frequency: newData.value}))
  }
  const updateDuration = (newData: ValueSelectItems) => {
    dispatch(updateDurationOfASet({settingTime: newData.value}))
  }

  const selectAndDeselectAllElectrode = (selectAll: boolean) => {
    dispatch(
      selectDeselectAllElectrode({electrodeValue: selectAll ? 'Y' : 'N'}),
    )
  }

  const newEmptyGroupStructure = (groupName: string): GroupType => {
    return {
      protocolList: [newEmptySetStructure('Set1')],
      // presetList: [newEmptySetStructure('Set1')],
      status: 'ADD',
      label: groupName,
    }
  }

  const setSelectGroup = (
    group: GroupType,
    customerId: string,
    indexToBeUpdated: number,
  ) => {
    if (setSelectedGroup) {
      if (
        // @ts-ignore
        !group.protocolList[0].settingTime &&
        // @ts-ignore
        !group.protocolList[0].frequency
        // !group.
      ) {
        dispatch(
          fetchElectrodesByGroup({
            uid: group.uid,
            customerId,
            groupId: group.id,
            list: Groups,
            indexToBeUpdated,
          }),
        )
      }

      console.log(`setSelectGroup - selected group ${JSON.stringify(group)}`)

      // eslint-disable-next-line no-debugger
      // debugger
      // @ts-ignore
      dispatch(setSelectedGroup(group))
    }
    // @ts-ignore
    dispatch(setSelectedSet(group.protocolList[0]))
  }

  const addNewGroup = (name: string) => {
    if (name.trim() === '') {
      return
    }
    console.log('addNewGroup')
    if (Groups.some((group) => group.label === name)) {
      onFailureModalOpen(t('IDuplicate'))
    } else {
      const tempEmptyGroup = newEmptyGroupStructure(name)
      dispatch(addGroup({...tempEmptyGroup, status: 'ADD'}))

      setSelectGroup(tempEmptyGroup, '', 0)
    }
  }

  const deleteGroup = async () => {
    if (Groups.length === 1) {
      await onFailureOpen(t('IPrescriptionDeleteLastSelect'))
      return
    }

    const result = await onConfirmOpen({
      message: t('IPrescriptionDeleteSelect'),
    })

    if (Object.keys(selectedGroup).length !== 0 && result.payload)
      dispatch(removeAGroup({group: selectedGroup}))
  }

  const deleteSet = (customerId: string) => {
    // @ts-ignore
    if (selectedGroup.protocolList.length > 1) {
      if (selectedGroup.id) {
        // if (selectedSet.presetDataId) {
        //   dispatch(
        //     addToDeleteSetArray({
        //       customerId,
        //       groupId: selectedGroup.id,
        //       dataId: selectedSet.presetDataId,
        //     }),
        //   )
        //   dispatch(removeASet({set: selectedSet, group: selectedGroup}))
        // } else {
        dispatch(removeASet({set: selectedSet, group: selectedGroup}))
        // }
      } else {
        dispatch(removeASet({set: selectedSet, group: selectedGroup}))
      }
    } else {
      onFailureModalOpen(t('ICannotDeleteASingleSet'))
    }
  }

  const setSelectSet = (set: Set2) => {
    if (selectedSet) {
      dispatch(setSelectedSet(set))
    }
  }

  const addNewSetToAGroup = (setName: string, group: GroupType) => {
    if (setName.trim() === '') {
      return
    }
    // @ts-ignore
    if (selectedGroup.protocolList.length >= 5) {
      onFailureModalOpen(t('ICannotAddSet'))
      return
    }
    // @ts-ignore
    if (selectedGroup.protocolList.some((set) => set.name === setName)) {
      onFailureModalOpen(t('IDuplicate'))
    } else {
      const tempSet = newEmptySetStructure(setName)
      console.log(`ADD PROTOCOL : ${JSON.stringify(tempSet)}`)
      dispatch(
        addSetToAGroup({
          localGroup: {...group, status: 'UPDATE'},
          setToAdd: {...tempSet, status: 'ADD'},
        }),
      )
      setSelectSet(tempSet)
    }
  }

  const fetchGroupList = (customerId: string) => {
    dispatch(
      fetchPBMGroupList({
        uid: currentUser?.uid,
        customerId,
        indexToBeUpdated: 0,
      }),
    )
  }

  const fnDupl = (newName: string) => {
    return Groups.some((group) => group.label === newName)
  }

  const postPrescription = async (
    uid: string,
    customerId: string,
    handleClose: any,
  ) => {
    if (!isAnyChangeInPrescription) {
      onFailureModalOpen(t('INoChangesInPrescription'))
      return
    }
    const filteredAddGroup = Groups.filter((group) => {
      return group && group.status === 'ADD'
    })

    const filteredUpdateGroup = Groups.filter((group) => {
      return group && group.status === 'UPDATE'
    })

    const addGroupPromises = filteredAddGroup.map(async (group, index) => {
      const count = filteredUpdateGroup.length + (index + 1)
      let newName = `${t('IPrescription')} ${count}`
      let i = 1
      while (fnDupl(newName)) {
        newName = `${t('IPrescription')} ${count}[${i}]`
        i += 1
      }

      const groupPayload = {
        // @ts-ignore
        dataList: group.protocolList.map((pt, idx) => {
          pt = {
            ...pt,
            seq: `${idx}`,
            label: `${t('IProtocol')} ${idx + 1}`,
          }
          return pt
        }),
        uuid: customerId,
        uid,
        label: group.label?.startsWith('grp_') ? newName : group.label,
      }
      console.log(`group payload : ${groupPayload}`)

      await dispatch(addNewGroupThunk(groupPayload))
    })

    const updateGroupPromises = filteredUpdateGroup.map(
      async (group, index) => {
        // const groupPayload = {
        //   // @ts-ignore
        //   dataList: group.protocolList,
        //   uuid: customerId,
        //   uid,
        //   label: group.label,
        //   groupId: group.id,
        // }

        const groupPayload = {
          // @ts-ignore
          dataList: group.protocolList.map((pt, idx) => {
            pt = {
              ...pt,
              seq: `${idx}`,
              label: `${t('IProtocol')} ${idx + 1}`,
            }
            return pt
          }),
          uuid: customerId,
          uid,
          label: group.label?.startsWith('grp_')
            ? `${t('IPrescription')} ${index + 1}`
            : group.label,
          groupId: group.id,
        }

        console.log(`group payload : ${groupPayload}`)

        await dispatch(updateSetThunk(groupPayload))
      },
    )

    const deletedGroupPromises = deletedGroups?.map(async (group) => {
      await dispatch(
        deleteGroupThunk({
          uid,
          uuid: customerId,
          groupId: group.id as unknown as number,
        }),
      )
    })

    // const deletedSetPromises = deletedSets?.map(async (sets) => {
    //   await dispatch(deleteSetThunk(sets))
    // })

    await Promise.all([
      ...addGroupPromises,
      ...updateGroupPromises,
      ...deletedGroupPromises,
      // ...deletedSetPromises,
    ])
    handleClose()
    dispatch(reInitializeState({}))
    onSuccessModalOpen(t('IprescriptionUpdated'))
  }

  const changeNameOfGroup = (name: string, customerId: string) => {
    if (name.trim() === '') {
      onFailureModalOpen(t('INameEmpty'))
      return
    }
    if (selectedGroup.label === name) {
      return
    }
    if (Groups.some((localGroup) => localGroup.label === name)) {
      onFailureModalOpen(t('IDuplicate'))
    } else {
      if (selectedGroup.status === 'ADD') {
        dispatch(changeNameOfAAddedGroup({newName: name}))
      } else {
        const currentIndexOfGroup = Groups.findIndex(
          (tempgroup) => tempgroup.label === selectedGroup.label,
        )

        if (currentIndexOfGroup !== -1)
          dispatch(
            changeNameOfAGroupThunk({
              ...selectedGroup,
              label: name,
              customerId,
              groupId: selectedGroup.id,
              indexToBeUpdated: currentIndexOfGroup,
            }),
          )
      }
    }
  }

  return {
    addNewGroup,
    newEmptyGroupStructure,
    selectedGroup,
    selectedSet,
    setSelectGroup,
    addNewSetToAGroup,
    setSelectSet,
    getElectrodeStatus,
    updateElectrodeStatus,
    updateFrequency,
    updateDuration,
    selectAndDeselectAllElectrode,
    fetchGroupList,
    postPrescription,
    changeNameOfGroup,
    deleteGroup,
    deleteSet,
  }
}
