import AnxietyLevel1Icon from 'assets/img/ic_ppg_anxiety_01.png'
import AnxietyLevel2Icon from 'assets/img/ic_ppg_anxiety_02.png'
import AnxietyLevel3Icon from 'assets/img/ic_ppg_anxiety_03.png'
import DepressionLevel1Icon from 'assets/img/ic_ppg_depression_01.png'
import DepressionLevel2Icon from 'assets/img/ic_ppg_depression_02.png'
import DepressionLevel3Icon from 'assets/img/ic_ppg_depression_03.png'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import SubTitle from 'components/Text/SubTitle'
import React from 'react'
import {useTranslation} from 'react-i18next'

const DepressionIconList = [
  DepressionLevel1Icon,
  DepressionLevel2Icon,
  DepressionLevel3Icon,
]

const AnxietyIconList = [
  AnxietyLevel1Icon,
  AnxietyLevel2Icon,
  AnxietyLevel3Icon,
]

export default function HRVSummarySentimental(props: {
  anxiety: number
  depression: number
  customerFirstName: string
  customerLastName: string
}) {
  const {anxiety, depression, customerFirstName, customerLastName} = props
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const koreanCustomerName = `${customerLastName} ${customerFirstName}`
  const englishCustomerName = `${customerFirstName} ${customerLastName}`

  return (
    <div className={classes.hrvSentimental}>
      <SubTitle className='ReportTitle'>
        3. {t('IHRVSummaryReportAISentimentTitle')}
      </SubTitle>
      <div className='Status'>
        <div className='StatusIcon'>
          <img
            className='StatusIcon-image'
            src={DepressionIconList[depression - 1]}
            alt='Depression status icon'
          />
          <div className='StatusIcon-label Depress'>
            {t('IDepressLevel', {context: depression})}
          </div>
        </div>
        <div className='Status-text'>
          <div className='Title'>
            {(i18n.language === 'ko' || i18n.language === 'ja') &&
              t('IAnxietyTitle', {
                name: koreanCustomerName,
                anxiety: t('IAnxietyLevel', {context: anxiety}),
              })}
            {i18n.language !== 'ko' &&
              i18n.language !== 'ja' &&
              t('IAnxietyTitle', {
                name: englishCustomerName,
                anxiety: t('IAnxietyLevel', {context: anxiety}),
              })}
          </div>
          <div className='Level'>
            {t('IDepressLevelDescription', {context: depression})}
          </div>
          <div className='Reference'>{t('IDepressDescription')}</div>
        </div>
        <div className='StatusIcon'>
          <img
            className='StatusIcon-image'
            src={AnxietyIconList[anxiety - 1]}
            alt='Anxiety status icon'
          />
          <div className='StatusIcon-label Anxiety'>
            {t('IAnxietyLevel', {context: anxiety})}
          </div>
        </div>

        <div className='Status-text'>
          <div className='Title'>
            {(i18n.language === 'ko' || i18n.language === 'ja') &&
              t('IDepressTitle', {
                name: koreanCustomerName,
                depress: t('IDepressLevel', {context: depression}),
              })}
            {i18n.language !== 'ko' &&
              i18n.language !== 'ja' &&
              t('IDepressTitle', {
                name: englishCustomerName,
                depress: t('IDepressLevel', {context: depression}),
              })}
          </div>
          <div className='Level'>
            {t('IAnxietyLevelDescription', {context: anxiety})}
          </div>
          <div className='Reference'>{t('IAnxietyDescription')}</div>
        </div>
      </div>
    </div>
  )
}
