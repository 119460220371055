import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function NursingInstitutionInput() {
  const {register} = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>
          {t('IOrgNursingInstitutionNumber')}
        </p>
      </div>
      <input
        placeholder={t('IRegisterNursingInstitutionNumber')}
        className={classes.input}
        {...register('nursingNo')}
      />
    </div>
  )
}
