import {createStyles, Theme} from '@material-ui/core'
import {Colors} from 'components/common/useBaseStyle'

// eslint-disable-next-line import/prefer-default-export
export const styles = (theme: Theme) =>
  createStyles({
    /** Material 다이얼로그에 적용해서 크기 제한 */
    dialog: {
      '& > .MuiDialog-container > .MuiPaper-root': {
        [theme.breakpoints.up('xs')]: {
          minWidth: 354,
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: 408,
        },
      },
    },
    /** 다이얼로그 안쪽 Card에 적용 */
    root: {
      textAlign: 'center',
      paddingBottom: 20,

      '& .Header': {
        display: 'flex',
        borderBottom: `1px solid ${Colors.border}`,
        paddingLeft: 18,
        alignItems: 'center',
        '& .Title': {
          fontSize: 15,
          fontWeight: '500',
        },
        '& .CloseButton': {
          marginLeft: 'auto',
          padding: 6,
          alignSelf: 'flex-start',
          marginTop: 2,
          marginRight: 2,
        },
        '& .CloseIcon': {
          width: 16,
          height: 16,
          color: '#c5c5c5',
        },
      },
      '& .Contents': {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 20,
      },

      '& .ConfirmButton': {
        backgroundColor: Colors.confirmButton,
        fontWeight: '500',
        color: '#fff',
        height: 33,
        fontSize: 10,
      },
      '& .CancelButton': {
        backgroundColor: Colors.cancelButton,
        border: `1px solid ${Colors.cancelButtonBorder}`,
        fontWeight: '500',
        color: '#333',
        height: 33,
        fontSize: 10,
      },
      '& .ConfirmButton:disabled, & .CancelButton:disabled': {
        opacity: 0.65,
      },

      '& .Actions': {
        display: 'flex',
        justifyContent: 'center',
        gap: 10,
        backgroundColor: '#f4f4f4',
        padding: '20px 0',

        '& > button': {
          width: 126,
          height: 33,
          boxSizing: 'border-box',
          borderRadius: 4,
          fontSize: 10,
        },
      },

      '& > .ConfirmButton, & > .CancelButton': {
        width: 'calc(100% - 40px)',
      },
      '& > .CancelButton': {
        backgroundColor: Colors.cancelButtonLight,
        border: 'none',
      },
    },
  })
