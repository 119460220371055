import {OrgUserCreateRequest, OrgUserUpdateRequest} from 'api/userApi'
import {
  createUser,
  readUser,
  selectData,
  selectError,
  selectLoading,
  selectUserHistory,
  updateUser,
  readHistoryUser,
} from 'features/org/orgUserSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useOrgUser() {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectLoading)
  const user = useAppSelector(selectData)
  const error = useAppSelector(selectError)
  const userHistory = useAppSelector(selectUserHistory)

  const onFetch = (payload: string) => dispatch(readUser(payload))
  const onFetchUserHistory = (payload: UserUsageHistoryRequest) =>
    dispatch(readHistoryUser(payload)).unwrap()

  const onCreate = (payload: OrgUserCreateRequest) =>
    dispatch(createUser(payload))

  const onUpdate = (payload: OrgUserUpdateRequest) =>
    dispatch(updateUser(payload))

  return {
    loading,
    user,
    error,
    userHistory,
    onFetch,
    onCreate,
    onUpdate,
    onFetchUserHistory,
  }
}
