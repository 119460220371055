import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import {
  closeOrgDetailInfoDialog,
  openOrgDetailInfoEditDialog,
  selectReadOrgDetailDialogOpen,
} from 'features/modal/modalSlice'
import useOrganization from 'features/org/useOrganization'
import {isObjectEmpty} from 'helpers/commonHelper'
import {useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

// export const ORG_INFO_DIALOG_TYPE = 'ORG_INFO'
// export const ORG_ADMIN_INFO_DIALOG_TYPE = 'ORG_ADMIN_INFO'

export default function OrganizationReadDialog() {
  // const {uid} = props
  const classes = useStyles()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const {organization, onFetch: onOrganizationFetch} = useOrganization()

  const handleDialogClose = () => {
    dispatch(closeOrgDetailInfoDialog())
  }
  const handleEditDialogOpen = () => {
    dispatch(closeOrgDetailInfoDialog())
    dispatch(openOrgDetailInfoEditDialog())
  }

  // 다이얼로그 상태값
  const orgDetailInfoDialogOpen = useAppSelector(selectReadOrgDetailDialogOpen)

  useEffect(() => {
    if (orgDetailInfoDialogOpen) {
      onOrganizationFetch()
    }
  }, [orgDetailInfoDialogOpen])

  return (
    <Dialog open={orgDetailInfoDialogOpen} fullWidth maxWidth='md'>
      <Card className={classes.cardWrap}>
        <div className={classes.root}>
          {/* 상단 타이틀 컨테이너  */}
          <div
            className={classes.longTitleContainer}
            style={{backgroundColor: '#f9f9fb'}}
          >
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleDialogClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography variant='h6' className={classes.title}>
                {t('IInstituteName')}
              </Typography>
            </div>
          </div>

          <div className={classes.scroll} style={{backgroundColor: '#f9f9fb'}}>
            <div className={classes.cardContent}>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ICeoName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {t('IGetFullName', {
                      firstName: organization?.firstName ?? '',
                      lastName: organization?.lastName ?? '',
                    }).trim()}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IInstituteTitleName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.orgName ?? '-'}
                  </Typography>
                </div>

                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IEmail')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.email ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div
                  className={classes.contentContainer}
                  style={{flexWrap: 'nowrap'}}
                >
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IPassword')}
                  </Typography>
                  <Typography
                    style={{flexWrap: 'nowrap', textAlign: 'left'}}
                    className={classes.subTitle}
                  >
                    • {t('IPasswordDesc')}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ICountry')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {!isObjectEmpty(organization?.country) && (
                      <Typography className={classes.subTitle}>
                        {organization?.country.name}
                      </Typography>
                    )}
                    {isObjectEmpty(organization?.country) && (
                      <Typography className={classes.subTitle}>-</Typography>
                    )}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ITel')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.tel ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IIndustryDetail')}
                  </Typography>
                  <Typography>
                    {!isObjectEmpty(organization?.industry) && (
                      <Typography className={classes.subTitle}>
                        {organization?.industry.title ?? '-'}
                      </Typography>
                    )}
                    {isObjectEmpty(organization?.industry) && (
                      <Typography className={classes.subTitle}>-</Typography>
                    )}
                  </Typography>
                </div>

                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IAddress')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.address ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IBusinessNo')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.businessNo ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IOrgNursingInstitutionNumber')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.institutionNo ?? '-'}
                  </Typography>
                </div>

                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ICreateAt')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {organization?.createdAt ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
              </div>
              <Typography className={classes.padding} />
            </div>
            <Typography className={classes.padding} />
          </div>
          {/* 하단 버튼 컨테이너  */}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='default'
                disableElevation
                onClick={handleDialogClose}
                className={classes.defaultButton}
              >
                {t('IOk')}
              </Button>
              <Typography className={classes.smallPadding} />
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleEditDialogOpen}
                className={classes.okButtonColor}
              >
                {t('IEdit')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}
