import React from 'react'
import styled from 'styled-components'

const StyledReportDescription = styled.div`
  background: #dee6eb;
  padding: 15px;
  margin-bottom: 15px;
  & a {
    cursor: pointer;
    color: rgb(248,172,89);
  },
`

interface DescriptionProps {
  htmlText: string
}

const ReportDescription = ({htmlText}: DescriptionProps) => (
  <StyledReportDescription
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{__html: htmlText}}
  />
)

export default ReportDescription
