import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  fetchPointInfoApi,
  fetchPointListApi,
  fetchPointPackageInfoApi,
  PointInfoRequest,
  PointListRequest,
  PointPackageInfoRequest,
} from 'api/pointApi'
import {RootState} from 'store'

interface PointState {
  loading: boolean
  error: any
  pointList: PointContent[] | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  unpaged: boolean
  searchValue?: string | null
  searchName?: string | null
  startDate?: string | null
  endDate?: string | null
  pointInfo?: PointInfo | null
  pointPackageInfo?: PointPackageInfo | null
  myPackageInfo?: Package[] | null
  period?: PeriodString | null
  selectedOid: string | null
}

export const initialState: PointState = {
  loading: false,
  error: null,
  pointList: [],
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 0,
  unpaged: true,
  searchValue: null,
  searchName: null,
  startDate: null,
  endDate: null,
  pointInfo: null,
  pointPackageInfo: null,
  myPackageInfo: null,
  period: null,
  selectedOid: null,
}

export const fetchUsedPointListAction = createAsyncThunk(
  'pointSlice/fetchUsedPointListAction',
  async (payload: PointListRequest) => {
    const response = await fetchPointListApi(payload)
    return response
  },
)

export const fetchPointInfoAction = createAsyncThunk(
  'pointSlice/fetchPointInfo',
  async (payload: PointInfoRequest) => {
    const response = await fetchPointInfoApi(payload)
    return response
  },
)

export const fetchPointPackageInfo = createAsyncThunk(
  'pointSlice/fetchPointPackage',
  async (payload: PointPackageInfoRequest) => {
    const response = await fetchPointPackageInfoApi(payload)
    return response
  },
)

export const pointSlice = createSlice({
  name: 'pointSlice',
  initialState,
  reducers: {
    selectedOidRequest(state: PointState, action: PayloadAction<string>) {
      state.selectedOid = action.payload
    },
    fetchPointListRequest(
      state: PointState,
      action: PayloadAction<PointListRequest>,
    ) {
      state.loading = true
      state.error = null
      if (action.payload.searchName) {
        state.searchName = action.payload.searchName
      } else {
        state.searchName = null
      }
      if (action.payload.searchValue) {
        state.searchValue = action.payload.searchValue
      } else {
        state.searchValue = null
      }

      if (action.payload.period) {
        state.startDate = action.payload.period.startDate
      } else {
        state.startDate = null
      }
      if (action.payload.period) {
        state.endDate = action.payload.period.endDate
      } else {
        state.endDate = null
      }
    },
    fetchPointListSuccess(state: PointState, action: PayloadAction<Point>) {
      state.loading = false
      state.error = null
      state.pointList = action.payload.content
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.pageIndex = action.payload.pageable.pageNumber
      state.itemPerPage = action.payload.pageable.pageSize
      state.unpaged = action.payload.pageable.unpaged
    },
    fetchPointListError(state: PointState, action: PayloadAction<any>) {
      state.loading = false
      state.error = action.payload
    },
    savePointRequest(state: PointState) {
      state.loading = true
      state.error = null
    },
    consumePointRequest(state: PointState) {
      state.loading = true
      state.error = null
    },

    fetchPointPackageInfoRequest(state: PointState) {
      state.loading = false
      state.error = null
    },
    fetchPointPackageInfoSuccess(
      state: PointState,
      action: PayloadAction<PointPackageInfo>,
    ) {
      state.loading = false
      state.error = null
      state.pointPackageInfo = action.payload
    },
    fetchPointPackageInfoError(state: PointState, action: PayloadAction<any>) {
      state.loading = false
      state.error = action.payload
    },
    downloadPointListRequest(state: PointState) {
      state.loading = false
      state.error = null
    },
    resetPointListRequest(state: PointState) {
      state.loading = false
      state.pointList = initialState.pointList
      state.pointInfo = initialState.pointInfo
      state.searchName = null
      state.searchValue = null
      state.startDate = null
      state.endDate = null
    },
    fetchMyPackageRequest(state: PointState) {
      state.loading = true
      state.error = null
    },
    fetchMyPackageSuccess(state: PointState, action: PayloadAction<Package[]>) {
      state.loading = false
      state.error = null
      state.myPackageInfo = action.payload
    },
    fetchMyPackageError(state: PointState, action: PayloadAction<any>) {
      state.loading = false
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsedPointListAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchUsedPointListAction.fulfilled, (state, action) => {
      state.loading = false
      state.pointList = action.payload.data.content
      state.totalPages = action.payload.data.totalPages
      state.pageIndex = action.payload.data.pageable.pageNumber
    })
    builder.addCase(fetchUsedPointListAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchPointInfoAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPointInfoAction.fulfilled, (state, action) => {
      state.loading = false
      state.pointInfo = action.payload.data
    })
    builder.addCase(fetchPointInfoAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  fetchPointListRequest,
  fetchPointListSuccess,
  fetchPointListError,
  savePointRequest,
  consumePointRequest,
  fetchPointPackageInfoRequest,
  fetchPointPackageInfoSuccess,
  fetchPointPackageInfoError,
  downloadPointListRequest,
  resetPointListRequest,
  fetchMyPackageRequest,
  fetchMyPackageSuccess,
  fetchMyPackageError,
  selectedOidRequest,
} = pointSlice.actions

export const selectPoint = (state: RootState) => state.point
export const selectPointQuery = (state: RootState): PointListRequest => ({
  searchName: state.point.searchName ?? null,
  searchValue: state.point.searchValue ?? null,
  period: state.point.period ?? null,
  page: state.point.pageIndex ?? 0,
  size: state.point.itemPerPage,
})
export const selectPointPackageList = (state: RootState): PointPackageInfo => ({
  eegPackage: state.point.pointPackageInfo?.eegPackage ?? null,
  hrvPackage: state.point.pointPackageInfo?.eegPackage ?? null,
  eegPackageList: state.point.pointPackageInfo?.eegPackageList ?? null,
  hrvPackageList: state.point.pointPackageInfo?.hrvPackageList ?? null,
})
export const selectPointInfo = (state: RootState) => state.point.pointInfo
export const selectMyPackage = (state: RootState) => state.point.myPackageInfo
export const selectedOid = (state: RootState) => state.point.selectedOid

export default pointSlice.reducer
