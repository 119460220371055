import React, {ErrorInfo, ReactNode} from 'react'

function DefaultErrorScreen({
  error,
  errorInfo,
}: {
  error: Error | null
  errorInfo: ErrorInfo
}) {
  return (
    <div style={{padding: 20}}>
      <h2>Something went wrong.</h2>
      <details style={{whiteSpace: 'pre-wrap'}}>
        {error && error.toString()}
        <br />
        {errorInfo.componentStack}
      </details>
    </div>
  )
}

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  public render() {
    const {children} = this.props
    const {error, errorInfo} = this.state

    if (errorInfo != null) {
      return <DefaultErrorScreen error={error} errorInfo={errorInfo} />
    }
    return children
  }
}
