import {
  PrimaryButton,
  PrimaryButtonProps,
} from 'components/CustomButtons/PrimaryButton'
import {isDefined} from 'helpers/commonHelper'
import React, {ReactNode} from 'react'
import styled, {keyframes} from 'styled-components'

const ButtonText = styled.p<{loading?: boolean}>`
  margin: 0;
  font-weight: bold;
  transition: opacity 200ms;
  transition-delay: ${({loading = false}) => (loading ? '0ms' : '200ms')};
  width: 100%;
  opacity: ${({loading}) => (loading ? 0 : 1)};
`

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div<{loading?: boolean}>`
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  opacity: ${({loading}) => (loading ? 1 : 0)};
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: ${({loading}) => (loading ? '200ms' : '0ms')};
`

export interface PrimaryLoadableButtonProps extends PrimaryButtonProps {
  loading?: boolean
  className?: string
  onClick?: () => void
  children?: ReactNode
}

export const PrimaryLoadableButton = React.forwardRef<
  HTMLButtonElement,
  PrimaryLoadableButtonProps
>((props: PrimaryLoadableButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const {loading = false, children, onClick, ...rest} = props
  const handleClick = () => {
    // if (loading) return

    if (isDefined(onClick) && typeof onClick === 'function') {
      onClick()
    }
  }
  return (
    <PrimaryButton {...rest} ref={ref} type='button' onClick={handleClick}>
      <LoadingSpinner loading={loading} />
      <ButtonText loading={loading}>{children}</ButtonText>
    </PrimaryButton>
  )
})

const StyledPrimaryLoadableButton = styled(PrimaryLoadableButton)
export default StyledPrimaryLoadableButton
