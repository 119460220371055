import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

interface CardInfoUsageHistoryProps {
  data: UsageHistory
}

const headerStyles = () =>
  createStyles({
    img: {
      height: '100px',
      marginRight: '15px',
      width: '100px',
      objectFit: 'contain',
    },
    divider: {
      backgroundColor: '#c0c0c0',
      height: '1px',
      margin: '10px 0 10px 0',
      width: 210,
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 35px',
      border: '2px solid #d8d8d8',
      fontWeight: 700,
      borderRadius: '5px',
      width: '31.5%',
      '@media (max-width: 1750px)': {
        width: '48%',
      },
      '@media (max-width: 1300px)': {
        width: '100%',
      },
    },
    totalCount: {
      fontSize: '22px',
      margin: '5px 0',
      color: '#676a6c',
      textAlign: 'center',
    },
    title: {
      fontSize: '16px',
      color: '#19beb9',
      fontWeight: 700,
      textAlign: 'center',
    },
    titleDefault: {
      fontSize: '22px',
      color: '#19beb9',
      fontWeight: 700,
      textAlign: 'center',
    },
    success: {
      color: '#4472C4',
    },
    fail: {
      color: '#FF0000',
    },
    mx5: {
      margin: '0 5px',
    },
    center: {
      textAlign: 'center',
    },
    cardRight: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
const useStyles = makeStyles(headerStyles)
const CardInfoAIBrainScan = ({data}: CardInfoUsageHistoryProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.card}>
      <div>
        <img src={data.img} alt='img' className={classes.img} />
      </div>
      <div className={classes.cardRight}>
        <div>
          <div className={classes.titleDefault}>{t('IAIBrainScan')}</div>
          <div className={classes.title}>{data.title}</div>
          <div className={classes.totalCount}>{data.totalCount}</div>
          <div className={classes.divider} />
          <div className={classes.center}>
            <Tooltip title={t('ISuccess')} arrow>
              <span className={classes.success}>S</span>
            </Tooltip>
            <span className={classes.mx5}>{data.successCount}</span>
            <span>|</span>
            <Tooltip title={t('IPartialSuccess')} arrow>
              <span className={classes.mx5}>PS</span>
            </Tooltip>
            <span>{data.partialSuccessCount}</span>
            <span className={classes.mx5}>|</span>
            <Tooltip title={t('IFailed')} arrow>
              <span className={classes.fail}>F</span>
            </Tooltip>
            <span className={classes.mx5}>{data.failureCount}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CardInfoAIBrainScan
