import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultIndicator,
  AnalysisResultItem,
  FetchNormativeContentRequest,
  fetchNormativeEEGContent,
} from 'api/analysisApi'
import useStyles from 'components/molcules/Report/PowerRatio.style'
import ReportHeader from 'components/Report/ReportHeader'
import {
  AnalysisDivision,
  CodeType,
  ReportAnalysisType,
} from 'constants/AnalysisConstant'
import {isEEGNorm} from 'helpers/analysisHelper'
import React, {useEffect} from 'react'
import {useAsync} from 'react-async-hook'

interface PowerRatioProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
  requestId: number
  codeType: CodeType
  codeTypeDivision: AnalysisDivision
  codeId: ReportAnalysisType
}

function PowerRatio({
  indicator,
  items,
  requestId,
  codeType,
  codeTypeDivision,
  codeId,
}: PowerRatioProps) {
  const classes = useStyles()

  const params: FetchNormativeContentRequest = {
    normRequestId: requestId,
    analysisType: codeId,
  }

  const asyncResult = useAsync(fetchNormativeEEGContent, [params], {
    executeOnMount: false,
    executeOnUpdate: false,
  })

  useEffect(() => {
    if (isEEGNorm(codeTypeDivision, codeType)) {
      asyncResult.execute(params)
    }
  }, [codeTypeDivision, codeType])

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />

      {items.map((item) => (
        <img
          key={item.imgPath}
          src={item.imgPath}
          className={classes.horizontalImage}
          alt='OccipitalAlphaPeak'
        />
      ))}
      {asyncResult.error !== undefined && (
        <div className={classes.contentError}>
          {asyncResult.error.message}
          <IconButton onClick={() => asyncResult.execute(params)} size='small'>
            <ReplayIcon />
          </IconButton>
        </div>
      )}
      {asyncResult.result !== undefined && (
        <div
          className={classes.content}
          // @ts-ignore
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{__html: asyncResult.result?.data.dTable}}
        />
      )}
    </div>
  )
}

export default PowerRatio
