import {createStyles, Theme} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

const confirmMailStyle = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
    },
    container: {
      '& > .MuiDialog-container > .MuiPaper-root': {
        [theme.breakpoints.up('md')]: {
          minWidth: 400,
        },
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 387,
      },
    },
    okButtonColor: {
      color: 'white',
      width: '150px',
    },
    table: {
      position: 'relative',
      minWidth: 350,
      color: 'red',
      '& td': {
        color: Colors.text.content,
        fontSize: '0.8125rem',
        lineHeight: 2,
        paddingTop: 6,
        paddingBottom: 6,
        height: 43,
      },
    },
    tableHeader: {
      height: 42,
      backgroundColor: Colors.tableHeaderBackground,
      borderRadius: 10,
      '& th': {
        color: Colors.text.content,
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        fontWeight: 'bold',
      },
    },
    title: {
      fontSize: 22,
      color: '#4b4b4b',
      fontWeight: 500,
    },
    content: {
      fontSize: '15px',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '35px',
      marginBottom: '20px',
    },
    hr: {
      height: '1px',
      background: '#000',
      margin: '20px 0 20px 0',
    },
    instruct: {
      padding: '0 15px',
      marginTop: '5px',
      fontSize: '12px',
    },
    btnOk: {
      display: 'flex',

      justifyContent: 'center',
      marginTop: '20px',
    },
  })

export default confirmMailStyle
