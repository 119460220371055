import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import SearchAll from 'pages/OrgPage/TreatmentPage/SearchAll'
import SearchOne from 'pages/OrgPage/TreatmentPage/SearchOne'
import Therapy from 'pages/OrgPage/TreatmentPage/TreatmentTherapy'
import React from 'react'
import {Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

const Treatment = () => {
  return (
    <Switch>
      {/* <RouteIf
        requiredRoles={AllUserRoles}
        path={RouteConstant.ORG_TREATMENTS.path}
        exact
        component={SearchAll}
      /> */}
      <RouteIf
        requiredRoles={AllUserRoles}
        path={RouteConstant.ORG_TREATMENTS_PATIENT.path}
        exact
        component={SearchOne}
      />
      {/* <RouteIf
        requiredRoles={AllUserRoles}
        path={RouteConstant.ORG_TREATMENTS_SUMMARY.path}
        exact
        component={Summary}
      /> */}
      <RouteIf
        requiredRoles={AllUserRoles}
        path={RouteConstant.ORG_THERAPY_REPORT.path}
        exact
        component={Therapy}
      />
    </Switch>
  )
}

export default Treatment
