import * as storage from 'api/storageApi'
import request, {newNormalRequest, newRequest} from 'api/index'
import {EmailAuthenticationType} from 'constants/CommonConstant'
import {normalRequest} from './index'

export interface FBTokenRequest {
  deviceId: string
  deviceType: 'WEB'
  token: string
}

export interface LoginRequest extends FBTokenRequest {
  email: string
  password: string
  lang: string
}
export interface VerifyPayload {
  email: string
  authCode: number
  twoFactorId: number
}
const lang = storage.getLanguage()

export const loginApi = (data: LoginRequest) =>
  normalRequest<ModelResponse<AuthResponse>>({
    method: 'post',
    path: `/api/org/v4/auth/login?lang=${data.lang}`,
    data: {
      ...data,
      lang,
    },
    header: {},
  })

export const verifyTwoFactorApi = (data: VerifyPayload) =>
  normalRequest<ModelResponse<AuthResponse>>({
    method: 'post',
    path: `/api/org/v4/auth/verify-2factor`,
    data: {
      ...data,
    },
    header: {},
  })

export interface LogoutRequest {
  deviceId: string
  deviceType: 'WEB'
  uid: string
}

export const logoutApi = (data: LogoutRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/user/v1/auth/logout',
    data: {
      ...data,
      lang,
    },
    header: {},
  })

export const checkEmailDuplicateApi = (email: string) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/v1/auth/checkEmailDuplicates',
    data: {
      email,
    },
  })

export interface PinStatusRequest {
  uid: string
}

interface PinStatusResponse {
  message: string
  status: string
}

export const pinStatusApi = async (
  data: PinStatusRequest,
): Promise<boolean> => {
  const response = await request<ModelResponse<PinStatusResponse>>({
    method: 'get',
    path: '/api/org/user/v1/pin/status',
    params: data,
  })
  if (response.success === false) throw new Error(response.message)
  if (response.data.status === 'REGISTERED') return true
  if (response.data.status === 'EMPTY') return false

  return false
}

export interface PinRequest {
  uid: string
  pin: string
}

export const pinConfirmApi = (data: PinRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/pin/confirm',
    data: {
      ...data,
    },
  })

export interface PinLoginRequest extends PinRequest, FBTokenRequest {}

export const pinLoginApi = (data: PinLoginRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/pin/login',
    data: {
      ...data,
    },
  })

export interface PinRegisterRequest extends PinRequest, FBTokenRequest {}

export const pinRegisterApi = (data: PinRegisterRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/pin/register',
    data: {
      ...data,
    },
  })

export interface PinResetRequest {
  uid: string
}

export const pinResetApi = (data: PinResetRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/pin/reset',
    data: {
      ...data,
    },
  })

export interface PinLogoutRequest extends FBTokenRequest {
  uid: string
}

export const pinLogoutApi = (data: PinLogoutRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/user/v1/pin/logout',
    data: {
      ...data,
    },
  })

export interface FindPasswordRequest {
  email: string
  requestType: string
  languageCode: string
}

export const findPasswordApi = (data: FindPasswordRequest) =>
  newNormalRequest<any>({
    method: 'post',
    path: '/authenticate/v1.0/request-email-auth/',
    data: {
      ...data,
    },
  })

export interface CheckVerifyRequest {
  uuid: string
  code: string
}
export interface CheckVerifyResponse {
  code: string
}

export const checkVerifyApi = ({uuid, code}: CheckVerifyRequest) =>
  newNormalRequest<any>({
    method: 'patch',
    path: `/authenticate/v1.0/confirm-email-auth/${uuid}/`,
    data: {
      code,
    },
  })

export interface ChangePasswordRequest {
  email: string
  authenticateUuid: string
  newPassword: string
}

export const changePasswordApi = (data: ChangePasswordRequest) =>
  newNormalRequest<any>({
    method: 'patch',
    path: '/user/v1.0/forgot/password',
    data: {
      ...data,
    },
  })

export interface FindIdRequest {
  orgName?: string
  firstName: string
  lastName: string
  tel?: string
  encKey: string
}

export interface FindIdResponse {
  email: string
}
export const findIdApi = (data: FindIdRequest) =>
  newNormalRequest<FindIdResponse>({
    method: 'post',
    path: '/user/v1.0/forgot/id',
    data,
  })

export interface CreateOrganization {
  privacyYn: string
  policyYn: string
  orgName: string
  firstUserName: string
  lastUserName: string
  email: string
  password: string
  countryId: number
  tel: string
  area: string
  address: string
  businessNo: string
  institutionNo: string
  doctorCount: number
  joinRoute: string
  interestArea: string
  industry: Industry
  detailIndustry: Industry
}

export interface IndustryV2 {
  id: number
  title: string
}

export interface CreateOrganizationV2 {
  name: string
  isPersonal: boolean
  chiefFirstName: string
  chiefLastName: string
  email: string
  password: string
  countryId: number
  address: string
  tel: string
  industries: IndustryV2[]
  businessNo: string
  nursingNo: string
  isAgreePrivacy: boolean | null
  isAgreeTerms: boolean | null
  orgType?: 'HEALTH' | 'NORMAL'
  therapyYn?: 'Y' | 'N'
  edfYn?: 'Y' | 'N'
  packageExpireDate?: string
  packageStartDate?: string
}

export const createOrganizationApi = (data: CreateOrganization) =>
  normalRequest<ModelsResponse<string>>({
    method: 'post',
    path: '/api/org/v1/create',
    data,
  })

export const createOrganizationV2Api = (data: CreateOrganizationV2) =>
  newNormalRequest<any>({
    method: 'post',
    path: '/organization/v1.0/join/',
    data,
  })

export interface UpdatePassword {
  uid: string
  currentPwd?: string | null
  newPwd: string
  pwdType: string
}

export const updatePasswordApi = (data: UpdatePassword) =>
  normalRequest<CommonResponse>({
    method: 'post',
    path: '/api/user/v1/auth/password/update',
    data: {
      ...data,
    },
  })

export interface EmailAuthenticationRequest {
  email: string
  requestType: EmailAuthenticationType
  languageCode: string
}

export interface EmailAuthenticationResponse {
  uuid: string
  expiresOn: string
}

export const emailAuthenticationApi = (data: EmailAuthenticationRequest) =>
  newNormalRequest<EmailAuthenticationResponse>({
    method: 'post',
    path: '/authenticate/v1.0/request-email-auth/',
    data: {
      ...data,
    },
  })

export interface EmailAuthenticationCheckRequest {
  uuid: string
  code: string
}
export const emailAuthenticationCheckApi = ({
  uuid,
  code,
}: EmailAuthenticationCheckRequest) =>
  newRequest<any>({
    method: 'patch',
    path: `/authenticate/v1.0/confirm-email-auth/${uuid}/`,
    data: {
      code,
    },
  })

export interface EmailDuplicateCheckRequest {
  email: string
}

export const emailDuplicateApiV2 = (params: EmailDuplicateCheckRequest) =>
  newRequest<any>({
    method: 'get',
    path: '/user/v1.0/check_email_is_available',
    params: {
      ...params,
    },
  })
