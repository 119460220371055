import {MenuItem, OutlinedInput, Select} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import {sendEmailApi} from 'api/treatmentApi'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {
  getTreatmentPatientPath,
  getTreatmentSummaryPath,
  getTreatmentTherapyPath,
} from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import {useEmailSendResultDialog} from 'features/treatment/useTreatmentDialog'
import useTreatmentSearch from 'features/treatment/useTreatmentSearch'
import {statusToI18nString} from 'helpers/analysisHelper'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {isProd} from 'helpers/envHelper'
import openInNewTab from 'helpers/openInNewTab'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import EmailSendResultDialog from '../EmailSendResultDialog'

type TreatmentSelect = 'NAME' | 'OPERATOR'
type HeadCellKey = keyof Treatment | 'result' | 'treatment'

interface HeadCell {
  id: HeadCellKey
  label: string
}

const SEARCH_SELECT_ITEMS: SelectItem<TreatmentSelect>[] = [
  {label: 'ICustomerName', value: 'NAME'},

  {label: 'IOperator', value: 'OPERATOR'},
]

// TODO: i18n
const headCells: HeadCell[] = [
  {id: 'careId', label: 'IMainCareNo_no'},
  {id: 'name', label: 'IPatientName'},
  {id: 'familyDoctor', label: 'IManager'},
  {id: 'operator', label: 'IOperator'},
  {id: 'careDate', label: 'IMeasureDate'},
  {id: 'type', label: 'IType'},
  {id: 'status', label: 'IProgress'},
  {id: 'result', label: 'IReport'},
  {id: 'treatment', label: 'ITreatmentRecords'},
]

const oneHeadCells: HeadCell[] = [
  {id: 'careId', label: 'IMainCareNo_no'},
  {id: 'name', label: 'IPatientName'},
  {id: 'familyDoctor', label: 'IManager'},
  {id: 'operator', label: 'IOperator'},
  {id: 'careDate', label: 'IMeasureDate'},
  {id: 'type', label: 'IType'},
  {id: 'status', label: 'IProgress'},
  {id: 'result', label: 'IReport'},
]

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  rejectedColumns?: HeadCellKey[]
  uuid?: string | null
}

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  numSelected,
  rowCount,
  rejectedColumns = [],
  uuid,
}: EnhancedTableHeadProps) {
  const filtered: HeadCell[] = uuid
    ? oneHeadCells
    : headCells.filter(
        (cell) => !rejectedColumns?.some((col) => col === cell.id),
      )
  const {t} = useTranslation()
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        <TableCell align='center' padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {filtered.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  selectedItems: number[]
  enableKeywordSearch?: boolean
  uuid?: string | null
}

interface CareList {
  careId: number
  country: string
  eegSummary: string
  errorMsg: string
  hrvSummary: string
  mciSummary: string
  patientEmail: string
}

const EnhancedTableToolbar = ({
  numSelected,
  enableKeywordSearch = true,
  selectedItems,
  uuid,
}: EnhancedTableToolbarProps) => {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useToolbarStyles()

  const {query, onSearchNew} = useTreatmentSearch()
  const {user: currentUser} = useAuth()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onEmailSendResultOpen} = useEmailSendResultDialog()
  const outlinedInputClasses = useOutlinedInputStyles()

  const [searchCondition, setSearchCondition] =
    React.useState<KeyOfTreatmentSearchKind>('NAME')
  const [keyword, setKeyword] = React.useState('')
  const [selection, setSelection] = React.useState<PeriodString>(
    dateToPeriodString(getDefaultPeriodDate()),
  )
  const handleSearch = () => {
    const searchVal = {
      careDoctorName: '',
      // chartNumber: number,
      // medicalNum: '',
      patientName: '',
      primaryDoctorName: '',
    }
    if (searchCondition === 'NAME') {
      searchVal.patientName = keyword
    } else if (searchCondition === 'OPERATOR') {
      searchVal.primaryDoctorName = keyword
    }

    onSearchNew({
      uid: currentUser?.uid ?? '',
      patientUuid: uuid,
      pageNumber: query.paging.page,
      pageSize: 10,
      type: 'ALL',
      searchValue: keyword,
      startDate: selection.startDate,
      endDate: selection.endDate,
      ...searchVal,
    })
  }

  const handlePeriodSearch = (period: PeriodString) => {
    const searchVal = {
      careDoctorName: '',
      // chartNumber: number,
      // medicalNum: '',
      patientName: '',
      primaryDoctorName: '',
    }
    if (searchCondition === 'NAME') {
      searchVal.patientName = keyword
    } else if (searchCondition === 'OPERATOR') {
      searchVal.primaryDoctorName = keyword
    }

    if (uuid) {
      onSearchNew({
        uid: currentUser?.uid ?? '',
        patientUuid: uuid,
        pageNumber: query.paging.page,
        pageSize: 10,
        type: 'ALL',
        searchValue: '',
        startDate: period.startDate,
        endDate: period.endDate,
        ...searchVal,
      })
      return
    }
    onSearchNew({
      uid: currentUser?.uid ?? '',
      pageNumber: query.paging.page,
      pageSize: 10,
      type: 'ALL',
      searchValue: '',
      startDate: period.startDate,
      endDate: period.endDate,
      ...searchVal,
    })
    // if (uuid) {
    //   onSearch({
    //     ...query,
    //     period,
    //     uuid,
    //   })
    //   return
    // }
    // onSearch({
    //   ...query,
    //   period,
    // })
  }
  const handleDateValue = (value: any) => {
    setSelection(dateToPeriodString(value as PeriodDate))
    handlePeriodSearch(dateToPeriodString(value as PeriodDate))
  }

  const handleSearchValue = (value: any) => {
    setKeyword(value)
  }

  const [careList, setCareList] = React.useState<CareList[]>([])

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as KeyOfTreatmentSearchKind)

  const handleSendEmail = async () => {
    if (numSelected === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }

    const response = await sendEmailApi({
      uid: currentUser?.uid ?? '',
      careList: selectedItems,
    })
    if (!response.success) {
      onFailureModalOpen('IProcessFail')
      return
    }
    if (response.list.length === 0) {
      onFailureModalOpen(t('ISendDataIsEmpty'))
      return
    }
    if (response.list.length > 0) {
      // let itemsList = []
      setCareList(response.list)
      onEmailSendResultOpen()
    }
  }

  // Default call api if query is stored in URL
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    if (initQuery.startDate && initQuery.endDate) {
      setSelection({
        startDate: initQuery.startDate,
        endDate: initQuery.endDate,
      })
    }
    if (initQuery.searchName) {
      setSearchCondition(initQuery.searchName)
    }
    onSearchNew({
      page: 0,
      size: 10,
      type: 'ALL',
      uuid,
      searchName: searchCondition,
      searchValue: keyword,
      startDate: selection.startDate,
      endDate: selection.endDate,
      ...initQuery,
    })
  }, [])

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className={classes.root}>
        <div className={classes.container}>
          <RangeDatePicker
            onChangeValue={handleDateValue}
            dateValue={{
              startDate: new Date(selection.startDate),
              endDate: new Date(selection.endDate),
            }}
          />
          <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
            {!isProd() && (
              <Select
                value={searchCondition}
                onChange={handleSearchChanged}
                className={classes.searchSelect}
                input={
                  <OutlinedInput
                    name='age'
                    id='outlined-age-simple'
                    classes={outlinedInputClasses}
                  />
                }
              >
                {SEARCH_SELECT_ITEMS.map((item) => (
                  <MenuItem value={item.value}>{t(item.label)}</MenuItem>
                ))}
              </Select>
            )}
            {enableKeywordSearch && (
              <SearchBar
                onChangeValue={handleSearchValue}
                onSearchEvent={handleSearch}
                disabledValue={false}
              />
            )}
          </div>
        </div>

        <div className={`${classes.container} ${classes.containerOptional}`}>
          {/* <ActionButton
            startIcon={<GetAppIcon />}
            className={classes.downloadButton}
          >
            {t('IExcelDownload')}
          </ActionButton> */}
          <ActionButton
            startIcon={<SmsOutlinedIcon />}
            className={classes.smsButton}
            disabled={numSelected === 0}
            onClick={() => {
              handleSendEmail()
            }}
          >
            {t('ISendEmail')}
          </ActionButton>
        </div>
      </div>
      <EmailSendResultDialog items={careList} />
    </div>
  )
}

interface RenderRowProps {
  isSelected: (careId: number) => boolean
  index: number
  data: Treatment
  onClick: (event: React.MouseEvent<unknown>, chartNo: number) => void
  rejectedColumns: HeadCellKey[]
  uuid?: string
}

function typeChange(type: string): string {
  if (type === 'EEG' || type === 'HRV' || type === 'PPG') {
    return 'IMeasurementType'
  }
  return 'INirLedCareType'
}

const RenderRow = ({
  isSelected,
  index,
  data,
  onClick,
  rejectedColumns,
  uuid,
}: RenderRowProps) => {
  const {t} = useTranslation()
  const isItemSelected = isSelected(data.careId)
  const labelId = `enhanced-table-checkbox-${index}`
  const {loading} = useTreatmentSearch()

  // 다른 필터링 해야할 항목이 추가되면... 아래처럼 추가.
  const isTreatmentEnabled = !rejectedColumns.some(
    (rejected) => rejected === 'treatment',
  )

  const typeString = useMemo(() => t(typeChange(data.type)), [t, data])
  const statusString = useMemo(
    () => t(statusToI18nString(data.status)),
    [t, data],
  )

  const checkDataStatus = (data: Treatment): boolean => {
    if (data.status !== 'SUCCESS') return false
    if (data.type === 'PBM') return false
    return true
  }

  if (loading) return <div> </div>

  return (
    <TableRow
      hover
      onClick={(event) => {
        if (checkDataStatus(data)) onClick(event, data.careId)
      }}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      {checkDataStatus(data) && (
        <TableCell align='center' padding='checkbox'>
          <Checkbox
            checked={isItemSelected}
            inputProps={{'aria-labelledby': labelId}}
          />
        </TableCell>
      )}
      {!checkDataStatus(data) && (
        <TableCell align='center' padding='checkbox'>
          <Checkbox disabled disableRipple />
        </TableCell>
      )}
      <TableCell align='center'>{data.careId}</TableCell>
      <TableCell align='center'>
        <ClientName firstName={data.firstName} lastName={data.lastName} />
      </TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={data?.familyDoctorFirstName ?? '-'}
          lastName={data?.familyDoctorLastName ?? '-'}
        />
      </TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={data?.operatorFirstName ?? 'Unknown'}
          lastName={data?.operatorLastName ?? ''}
        />
      </TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(data.careDate)}
      </TableCell>
      <TableCell align='center'>{typeString}</TableCell>
      <TableCell align='center'>{statusString}</TableCell>
      <TableCell align='center'>
        <TableButton
          disabled={data.status !== 'SUCCESS'}
          onClick={() => {
            console.log(`requestId : ${JSON.stringify(data)}`)

            if (data.type === 'PBM') {
              openInNewTab(getTreatmentTherapyPath(String(data.careId)))
            } else {
              openInNewTab(
                getTreatmentSummaryPath(
                  String(data.careId),
                  String(data.requestId),
                  String(data.hrvRequestId),
                ),
              )
            }
          }}
        >
          {t('IRead')}
        </TableButton>
      </TableCell>

      {isTreatmentEnabled && !uuid && (
        <TableCell align='center'>
          <TableButton
            onClick={(event) => {
              event.stopPropagation()
              navigate(getTreatmentPatientPath(data.uuid))
            }}
          >
            {t('IRead')}
          </TableButton>
        </TableCell>
      )}
    </TableRow>
  )
}

interface TreatmentTableProps {
  enableKeywordSearch?: boolean
  rejectedColumns?: HeadCellKey[]
  uuid?: string | null
}

export default function TreatmentTable({
  enableKeywordSearch = true,
  rejectedColumns = [],
  uuid,
}: TreatmentTableProps) {
  const classes = useStyles()

  const {
    allItems,
    loading,
    query,
    periodInfo,
    searchInfo,
    paging,
    pagingInfo,
    onSearchNew,
  } = useTreatmentSearch()
  const treatmentList = allItems
  const {user} = useAuth()

  const setPageIndex = (page: number) =>
    onSearchNew({
      ...periodInfo,
      ...searchInfo,
      type: 'ALL',
      uid: user?.uid ?? '',
      patientUuid: uuid,
      pageNumber: page,
      pageSize: query.paging.size,
    })

  const [selected, setSelected] = React.useState<number[]>([])
  const rowsPerPage = 10

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = treatmentList.map((n) => n.careId)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, chartNo: number) => {
    const selectedIndex = selected.indexOf(chartNo)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, chartNo)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (careId: number) => selected.indexOf(careId) !== -1
  const emptyRows = rowsPerPage - treatmentList.length

  return (
    <TableContainer className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        enableKeywordSearch={enableKeywordSearch}
        selectedItems={selected}
        uuid={uuid ?? null}
      />

      {!loading && (
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={treatmentList.length}
            rejectedColumns={rejectedColumns}
            uuid={uuid ?? null}
          />

          <TableBody>
            {treatmentList.map((row, index) => (
              <RenderRow
                isSelected={isSelected}
                index={index}
                data={row}
                onClick={handleClick}
                rejectedColumns={rejectedColumns}
                uuid={uuid ?? ''}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}

      <Pagination
        currentPageIndex={paging.page}
        itemCountPerPage={paging.size === 10 ? 10 : paging.size}
        totalPageCount={pagingInfo.totalPages}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={
          (event) =>
            onSearchNew({
              uid: user?.uid ?? '',
              patientUuid: uuid,
              pageNumber: 0,
              pageSize: parseInt(event.target.value as string, 10),
              type: 'ALL',
              ...searchInfo,
              ...periodInfo,
            })
          // onSearch({
          //   ...query,
          //   period: dateToPeriodString({
          //     startDate: new Date(query.period.startDate),
          //     endDate: new Date(query.period.endDate),
          //   }),
          //   uuid,
          //   paging: {
          //     page: 0,
          //     size: parseInt(event.target.value as string, 10),
          //   },
          // })
        }
      />
    </TableContainer>
  )
}
