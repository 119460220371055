import * as storage from 'api/storageApi'
import {navigate} from 'AppHistory'
import axios, {AxiosInstance, Method} from 'axios'
import RouteConstant from 'constants/RouteConstant'
import {ApiError, AuthError, NetworkError} from 'exceptions'
import {getNewApiHost, isProd} from 'helpers/envHelper'
import qs from 'qs'
import {refreshMethod} from './fetcher'

const isPatchMethod = (method: Method | undefined) =>
  method === 'patch' || method === 'PATCH'

const isPutMethod = (method: Method | undefined) =>
  method === 'put' || method === 'PUT'

const isPostMethod = (method: Method | undefined) =>
  method === 'post' || method === 'POST'

const isUpdateMethod = (method: Method | undefined) =>
  isPostMethod(method) || isPutMethod(method) || isPatchMethod(method)

const getApiHeader = (header: Header = {}) => {
  const newHeader = {...header}
  const accessToken = storage.getAccessToken()
  if (accessToken !== null) {
    newHeader.Authorization = `Bearer ${accessToken}`
  }
  return newHeader
}

const getBaseData = (data: Record<string, any>) => {
  const lang = storage.getLanguage()

  return {...data, lang}
}

export type AuthorizedRequest = ApiRequest & {ignoreData?: boolean}

export type FileDownloadRequest = ApiRequest & {fileName?: string}

class NewAxiosWrapper {
  axios: AxiosInstance

  constructor() {
    this.axios = axios.create({
      baseURL: getNewApiHost(),
      timeout: isProd() ? 10000 : 0,
      maxRedirects: 0,
    })
  }

  async newRequest<T>({
    method = 'get',
    path,
    data,
    header = null,
    params,
    responseType = 'json',
  }: ApiRequest): Promise<T> {
    const requestHeader = getApiHeader(header)
    try {
      const response = await this.axios.request<T>({
        method,
        url: path,
        headers: requestHeader,
        data,
        params,
        paramsSerializer: (params) => qs.stringify(params),
        responseType,
      })
      const {data: result} = response
      return result
    } catch (e) {
      if (e.response) {
        throw new ApiError(e.response)
      } else if (e.request) {
        throw new NetworkError(e.request)
      } else {
        throw e
      }
    }
  }

  async newAuthedRequest<T>({
    params: requestParams,
    data: requestData,
    method,
    ignoreData,
    ...rest
  }: AuthorizedRequest): Promise<T> {
    const params = {
      ...getBaseData(requestParams),
      ...requestParams,
    }

    // eslint-disable-next-line no-nested-ternary
    const data = ignoreData
      ? requestData
      : isUpdateMethod(method)
      ? {
          ...getBaseData(requestData),
          ...requestData,
        }
      : requestData

    try {
      return await this.newRequest<any>({
        method,
        params,
        data,
        ...rest,
      })
    } catch (err) {
      if (err instanceof AuthError) {
        try {
          const refreshResponse = await refreshMethod()

          storage.setAccessToken(refreshResponse.data.accessToken)
          storage.setRefreshToken(refreshResponse.data.refreshToken)

          return await this.newRequest<any>({
            method,
            params,
            data,
            ...rest,
          })
        } catch (err) {
          navigate(RouteConstant.LOGIN.path)
        }
      }
      throw new ApiError(err)
    }
  }

  async newFileRequest<T extends CommonResponse>({
    data = {},
    header = {},
    params,
    ...rest
  }: ApiRequest): Promise<T> {
    const newData = new FormData()
    Object.keys(data).forEach((key) => {
      newData.append(key, data[key])
    })

    return this.newAuthedRequest<T>({
      data: newData,
      header: {
        ...header,
        'Content-Type': 'multipart/form-data',
      },
      params,
      ...rest,
      ignoreData: true,
    })
  }
}

const newFetcher = new NewAxiosWrapper()

export default newFetcher
