import styled from 'styled-components'

export const TableOverflowWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
  }
`

export const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 43px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    padding: 10px;
    min-width: 80px;
  }
`

export const PackageTableHeader = styled.thead`
  height: 42px;
  background-color: #e9ecef;
  & p {
    margin: 0;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }
`

export const LineBreakBox = styled.div`
  display: flex;
  flex-direction: column;
`
