import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import React from 'react'
import RouteIf from 'routes/RouteIf'
import {PaymentWindow} from 'components/Dialog/PackagePaymentDialog'
import {Redirect, Route, Switch} from 'react-router-dom'

export default function TossRoute() {
  return (
    <Switch>
      <RouteIf
        requiredRoles={AllUserRoles}
        path={RouteConstant.PACKAGE_PAYMENT.path}
        exact
        component={PaymentWindow}
      />
      <Route path='*'>
        <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
      </Route>
    </Switch>
  )
}
