import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'
import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import {isProd} from 'helpers/envHelper'
import CommonLayout from 'layouts/Common'
import MyPurchasePage from 'pages/MyPages/MyPurchasePage'
import DepartmentPage from 'pages/OrgPage/DepartmentPage'
import PatientPage from 'pages/OrgPage/PatientsPage'
import TreatmentPage from 'pages/OrgPage/TreatmentPage'
import UserPage from 'pages/OrgPage/UserPage'
import PresetPage from 'pages/OrgPage/PresetPage'
import PaymentManagementPages from 'pages/OrgPage/PaymentManagementPages'
import InstitutionalUsageHistory from 'pages/OrgPage/InstitutionalUsageHistory'
import AccountUsageHistory from 'pages/OrgPage/AccountUsageHistory'
// import PaymentManagementPage from './PaymentManagementPages/PaymentManagementPage'
// import ProductsCheckPage from './PaymentManagementPages/ProductsCheckPage'
// import ProductsSelectPage from './PaymentManagementPages/ProductsSelectPage'

export default function OrgRoute() {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_PATIENTS.path}
          exact
          component={PatientPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_INSTITUTIONAL.path}
          exact
          component={InstitutionalUsageHistory}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_ACCOUNT.path}
          exact
          component={AccountUsageHistory}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_DEPARTMENTS.path}
          exact
          component={DepartmentPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_USERS.path}
          exact
          component={UserPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ORG_PRESET.path}
          exact
          component={PresetPage}
        />
        {!isProd() ? (
          <RouteIf
            requiredRoles={AllUserRoles}
            path={RouteConstant.ORG_PURCHASE.path}
            exact
            component={PaymentManagementPages}
          />
        ) : (
          <RouteIf
            requiredRoles={AllUserRoles}
            path={RouteConstant.ORG_PURCHASE.path}
            exact
            component={MyPurchasePage}
          />
        )}
        {/* {!isProd() && (
          <RouteIf
            requiredRoles={AllUserRoles}
            path={RouteConstant.ORG_PURCHASE_SELECT.path}
            exact
            component={ProductsSelectPage}
          />
        )}
        {!isProd() && (
          <RouteIf
            requiredRoles={AllUserRoles}
            path={RouteConstant.ORG_PURCHASE_CHECK.path}
            exact
            component={ProductsCheckPage}
          />
        )} */}
        <Route path={RouteConstant.ORG_TREATMENTS.path}>
          <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
        </Route>
        <TreatmentPage />
        <Route path='*'>
          <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
        </Route>
      </Switch>
    </CommonLayout>
  )
}
