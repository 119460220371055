import {Dialog, TableBody} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {fetchPackageHistoryListApi} from 'api/paymentApi'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import {closeHistoryDialog, selectHistoryOpen} from 'features/modal/modalSlice'
import useOrganization from 'features/org/useOrganization'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const RootWrap = styled.div`
  background-color: #fff;
`
const TitleText = styled.p`
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`

const ContentWrap = styled.div`
  background-color: #f9f9fb;
  background-color: #fff;
  padding: 40px;
`

const BottomButtonWrap = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 25px;
  gap: 28px;
`

const Padding = styled.br`
  line-height: 10px;
`

const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 43px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 10px;
  }
`

interface HeadCell {
  id: string
  label: string
}

const headCells: HeadCell[] = [
  {id: 'division', label: 'IDivision'},
  {id: 'serviceTypes', label: 'IAnalysisServiceType'},
  {id: 'serviceContent', label: 'IAnalysisServiceContent'},
  {id: 'point', label: 'IPointUsed'},
]

function PackageTableRows() {
  const {organization} = useOrganization()
  const getPackageHistory = async () => {
    const result = await fetchPackageHistoryListApi()
    console.log({result})
  }

  useEffect(() => {
    if (organization?.authorityGroup === 'COMPANY_GROUP') {
      getPackageHistory()
    }
  }, [])
  return (
    <>
      <RoundTableWrap>
        <td>Basic</td>
        <td>{`EEG analysis\nEEG Norm\nEEG summary`}</td>
        <td>26$</td>
        <td>150</td>
      </RoundTableWrap>
    </>
  )
}

function PackageSelectedTable() {
  return (
    <PackageTable>
      {headCells.map((item, index) => (
        <RoundTableHeader
          label={item.label}
          length={headCells.length}
          index={index}
        />
      ))}
      <TableBody>
        <PackageTableRows />
      </TableBody>
    </PackageTable>
  )
}

const HistoryDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectHistoryOpen)
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(closeHistoryDialog())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth='md'
    >
      <Card>
        <RootWrap>
          <div className={classes.containerTitle}>
            <div className={classes.closeButtonWrap}>
              <IconButton
                color='secondary'
                aria-label='favorite'
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <TitleText>{t('IHistoryTitle')}</TitleText>
          </div>
          <Padding />
          <ContentWrap>
            <PackageSelectedTable />
          </ContentWrap>
          <BottomButtonWrap>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleClose}
              style={{width: 222, backgroundColor: '#e9ecef', color: '#4b4b4b'}}
            >
              {t('IExcelDownload')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleClose}
              style={{width: 222}}
            >
              {t('IOk')}
            </Button>
          </BottomButtonWrap>
        </RootWrap>
      </Card>
    </Dialog>
  )
}

export default HistoryDialog
