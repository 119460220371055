import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import QRCode from 'pages/HealthCenter/Report/Elements/QRCode.png'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import {isoStringToDateString} from 'helpers/dateHelper'

const useStyles = makeStyles(HealthCenterReportStyle)

type PropsType = {
  title: string
  content: string
  qr?: string
  name: string
  sex: string
  birthday: string
  measurementDate: string
}

const HeaderReport = ({
  title,
  content,
  qr = QRCode,
  name,
  sex,
  birthday,
  measurementDate,
}: PropsType) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const calculateAge = (birthDateString: string) => {
    const birthDate = new Date(birthDateString)
    const today = new Date()

    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age -= 1
    }

    return age
  }

  const TABLE = [
    [t('IFirstName'), name],
    [t('IGender'), sex],
    [
      t('IBirthday'),
      birthday
        ? `${birthday} (${calculateAge(birthday)}${t(
            'IEEGSummaryReportChildBrainAgeYear',
          )})`
        : '',
    ],
    [t('IMeasurementDate'), isoStringToDateString(measurementDate)],
  ]

  return (
    <div className={classes.header}>
      <div className={classes.leftHeader}>
        <div className={classes.leftHeaderTitle}>{title}</div>
        <div className={classes.leftHeaderContent}>{content}</div>
      </div>
      <div className={classes.rightHeader}>
        <div className={classes.table}>
          {TABLE.map((item) => (
            <div className={classes.row} key={item[0]}>
              <div className={classes.headCell}>{item[0]}</div>
              <div className={classes.bodyCell}>{item[1]}</div>
            </div>
          ))}
        </div>
        {/* <div className={classes.qrCode}>
          <img src={qr} alt='IconDownload' />
        </div> */}
      </div>
    </div>
  )
}
export default HeaderReport
