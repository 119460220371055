import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import SearchBar from 'components/Forms/SearchBar'
import useCustomerRegistration from 'features/invoice/useInvoiceList'
import {useLocation} from 'react-router-dom'
import {getQueryFromUrl} from 'helpers/commonHelper'

interface Terms {
  name: string
  oid: string
}

interface Status {
  name: string
  oid: string
}

export default function InstitutionSearch() {
  const classes = useToolbarStyles()
  const location = useLocation()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {query, onSearch} = useCustomerRegistration()

  const {t} = useTranslation()

  const allStatus: Status[] = [
    {
      name: t('IAllInvoice'),
      oid: '1',
    },
    {
      name: t('IComplete'),
      oid: '2',
    },
    {
      name: t('IWriting'),
      oid: '3',
    },
  ]
  const allTerm: Terms[] = [
    {
      name: t('IChargingTerms'),
      oid: 'All',
    },
    {
      name: t('DeviceRental'),
      oid: 'Etc',
    },
    {
      name: t('IStandardPlan'),
      oid: 'Regular',
    },
    {
      name: t('IMonthlyFlatRate'),
      oid: 'FlatRate',
    },
    {
      name: t('IRatePlanBySection'),
      oid: 'TieredRate',
    },
  ]
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchConditionStatus, setConditionStatus] = useState<any>()
  const [tempSearchConditionStatus, setTempConditionStatus] = useState<any>()
  const [searchConditionTerm, setConditionTerm] = useState('All')
  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  const getSearch = () => {
    return {
      ...query,
      search: {orgName: searchKeyword},
      isConfirmed: tempSearchConditionStatus,
      type: searchConditionTerm,
    }
  }

  const handleChangeStatus = (value: string) => {
    setConditionStatus(value)
    switch (value) {
      case '2':
        setTempConditionStatus(true)
        break
      case '3':
        setTempConditionStatus(false)
        break
      default:
        setTempConditionStatus(null)
        break
    }
  }

  const handleChangeTerm = (value: string) => {
    setConditionTerm(value)
  }

  const handleSearch = () => {
    const newSearch = getSearch()
    onSearch(newSearch)
  }

  /** Set default value for search condition if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (searchParams?.type) setConditionTerm(searchParams.type || 'All')
    if (searchParams.isConfirmed) {
      setTempConditionStatus(searchParams.isConfirmed)
      switch (searchParams.isConfirmed) {
        case true:
          setConditionStatus('2')
          break
        case false:
          setConditionStatus('3')
          break
        default:
          setConditionStatus('1')
          break
      }
    }
  }, [])

  return (
    <div style={{display: 'flex', gap: 17, justifyContent: 'space-between'}}>
      <FormControl variant='outlined'>
        <div
          style={{display: 'flex', gap: 17, justifyContent: 'space-between'}}
        >
          <Select
            value={searchConditionStatus || allStatus[0]?.oid}
            displayEmpty
            className={classes.searchSelectInvoice}
            onChange={(event) => {
              handleChangeStatus(event.target.value as string)
            }}
            input={<OutlinedInput classes={outlinedInputClasses} />}
          >
            {allStatus?.map((item) => (
              <MenuItem value={item.oid} key={item.oid}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
      <FormControl variant='outlined'>
        <div
          style={{display: 'flex', gap: 17, justifyContent: 'space-between'}}
        >
          <Select
            value={searchConditionTerm || allTerm[0]?.oid}
            displayEmpty
            className={classes.searchSelectInvoice}
            onChange={(event) => {
              handleChangeTerm(event.target.value as string)
            }}
            input={<OutlinedInput classes={outlinedInputClasses} />}
          >
            {allTerm?.map((item) => (
              <MenuItem value={item.oid} key={item.oid}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
      <FormControl variant='outlined'>
        <div
          style={{display: 'flex', gap: 17, justifyContent: 'space-between'}}
        >
          <SearchBar
            onChangeValue={handleValue}
            onSearchEvent={handleSearch}
            disabledValue={false}
          />
        </div>
      </FormControl>
    </div>
  )
}
