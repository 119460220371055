import {
  fetchOrgUserSimpleList,
  selectData,
  selectError,
  selectLoading,
} from 'features/org/orgUserListSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useOrgUserList() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const userList = useAppSelector(selectData)
  const error = useAppSelector(selectError)

  const onFetch = () => dispatch(fetchOrgUserSimpleList())

  return {
    loading,
    userList,
    error,
    onFetch,
  }
}
