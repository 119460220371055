import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import therapyBrain from 'assets/img/therapy_brain.png'
import textLeft from 'assets/img/text_left.png'
import textRight from 'assets/img/text_right.png'
import Select, {ValueSelectItems} from 'components/V2Select/Select/Select'
import {NormalSelectTopShortStyles} from 'components/V2Select/Select/Styles'
import useTherapy from 'features/therapy/useTherapy'
import {
  StyledBrainContainer,
  StyledBrainImgContainer,
  StyledBrainImgInwrap,
  StyledButtonGroupAlignRight,
  StyledExplanation,
  StyledTextEditInfoContainer,
  StyledTextImgWrapLeft,
  StyledTextImgWrapRight,
  StyledTextInfoGroup,
  StyledTherapyPointWrap,
  StyledTherapyPointCheckbox,
  FlexItem,
} from 'components/V2Dialog/TherapyDialog/Style'
import OutlineButton from 'components/V2Buttons/OutlineButton/OutlineButton'
import {
  SmallLedOffButtonStyles,
  SmallLedOnButtonStyles,
} from 'components/V2Buttons/OutlineButton/Styles'
import {channels, subgroupChannels} from './constants'
import {BoxContainer, FlexContainer, Text} from './Style'
import useTherapyDialogViewModels from './TherapyDialogViewModels'
import {BrainImageContainerProps} from './Interface'
import usePrescriptionViewModelV2 from './Prescription/PrescriptionViewModel'

const BrainImageContainer = ({customerId}: BrainImageContainerProps) => {
  const {t} = useTranslation()
  const {
    frequencyValues,
    settingTimeValues,
    frequencyThumb,
    setFrequencyThumb,
    settingTimeThumb,
    setSettingTimeThumb,
    handleSubmit,
    setValue,
    makeTimeText,
  } = useTherapyDialogViewModels()

  const {
    getElectrodeStatus,
    updateElectrodeStatus,
    selectedGroup: globalSelectedGroup,
    selectedSet: globalSelectedSet,
    updateFrequency,
    updateDuration,
    selectAndDeselectAllElectrode,
  } = usePrescriptionViewModelV2()

  const [electrodes, setElectrodes] = useState(getElectrodeStatus())

  SmallLedOnButtonStyles.buttonWidth = 100
  SmallLedOnButtonStyles.buttonHeight = 42
  SmallLedOffButtonStyles.buttonWidth = 100
  SmallLedOffButtonStyles.buttonHeight = 42
  // const groupId = selectedGroup?.[0]?.groupId

  console.log('brainimagecontainer')

  const initBody = (
    <>
      {electrodes &&
        channels.map((element) => {
          return (
            <StyledTherapyPointCheckbox
              key={element}
              type='checkbox'
              name={element}
              value='N'
              className={element}
              onChange={(e) => {
                console.log(`checked : ${e.target.checked}`)

                updateElectrodeStatus(element, e.target.checked ? 'Y' : 'N')
              }}
              checked={electrodes[element] === 'Y' ?? false}
            />
          )
        })}
    </>
  )

  useEffect(() => {
    // get electrodes of the active set
    setElectrodes(getElectrodeStatus())

    // set Frequency and Duration of the currently active set
    setFrequencyThumb({
      value: globalSelectedSet.frequency,
      label: `${globalSelectedSet.frequency}Hz`,
    })
    setSettingTimeThumb({
      value: globalSelectedSet.settingTime,
      label: makeTimeText(globalSelectedSet.settingTime, t),
    })
  }, [globalSelectedSet])

  return (
    <>
      <FlexContainer padding='20px' justifyContent='space-around'>
        <StyledBrainImgContainer>
          <StyledBrainImgInwrap>
            <img src={therapyBrain} alt='therapy_brain' />
            <StyledTherapyPointWrap>{initBody}</StyledTherapyPointWrap>
            <StyledTextImgWrapLeft>
              <img src={textLeft} alt='text_left' />
            </StyledTextImgWrapLeft>
            <StyledTextImgWrapRight>
              <img src={textRight} alt='text_right' />
            </StyledTextImgWrapRight>
          </StyledBrainImgInwrap>
        </StyledBrainImgContainer>
        <FlexContainer flexDirection='column' width='min-content'>
          <StyledTextEditInfoContainer>
            <div style={{paddingBottom: '10px'}}>
              <FlexContainer padding='0px 0px 5px 0px'>
                <FlexItem fontSize='13px'>{t('IFrequency')}</FlexItem>
                <FlexItem fontSize='13px'>{t('IRunningTime')}</FlexItem>
              </FlexContainer>
              <StyledTextInfoGroup>
                <Select
                  thumb={frequencyThumb.label}
                  values={frequencyValues}
                  handler={(items, index) => {
                    const newData: ValueSelectItems = items[
                      index
                    ] as ValueSelectItems
                    setFrequencyThumb(newData)
                    updateFrequency(newData)
                  }}
                  theme={NormalSelectTopShortStyles}
                />
                <Select
                  thumb={settingTimeThumb.label}
                  values={settingTimeValues}
                  handler={(items, index) => {
                    const newData: ValueSelectItems = items[
                      index
                    ] as ValueSelectItems
                    setSettingTimeThumb(newData)
                    updateDuration(newData)
                  }}
                  theme={NormalSelectTopShortStyles}
                />
              </StyledTextInfoGroup>
            </div>
          </StyledTextEditInfoContainer>

          <Text
            padding='10px 0px 5px 0px'
            color='#333'
            size='13px'
            width='70%'
            // margin='auto'
          >
            {t('IPartSelection')}
          </Text>

          <StyledButtonGroupAlignRight justifyContent='center'>
            <OutlineButton
              theme={SmallLedOnButtonStyles}
              title={t('ISelectAllLed')}
              event={() => selectAndDeselectAllElectrode(true)}
              disabled={false}
            />
            <OutlineButton
              theme={SmallLedOffButtonStyles}
              title={t('IDeselectAllLed')}
              event={() => selectAndDeselectAllElectrode(false)}
              disabled={false}
            />
          </StyledButtonGroupAlignRight>
          <Text
            padding='10px 0px 0px 0px'
            color='#A4A4A4'
            size='12px'
            fontWeight='400'
            // width='70%'
            // margin='auto'
          >
            {t('IIndivisualSelection')}
          </Text>
        </FlexContainer>
      </FlexContainer>
    </>
  )
}

export default BrainImageContainer
