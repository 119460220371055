import {yupResolver} from '@hookform/resolvers/yup'
import {makeStyles} from '@material-ui/core/styles'
import LoginPageFooter from 'pages/AuthPages/LoginPageOldStyle/loginFooter'
import {getOrInitializeDeviceId} from 'api/storageApi'
import {AppHistoryState, navigate} from 'AppHistory'
import OldLogo from 'assets/img/isyncbrain_logo.png'
import FirstLoginDialog from 'components/Dialog/FirstLoginDialog'
import RouteConstant, {getRegisterPath} from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import {
  openFindIdDialog,
  openFindPasswordDialog,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import {isDefined} from 'helpers/commonHelper'
import {getFBToken} from 'helpers/firebaseHelper'
import styles from 'pages/AuthPages/LoginPageOldStyle/Style'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {Redirect, useLocation} from 'react-router-dom'
import * as yup from 'yup'
import * as storage from 'api/storageApi'

const useStyles = makeStyles(styles)

interface LoginFormInput {
  email: string
  password: string
}

export default function LoginPage() {
  const {t, i18n} = useTranslation()
  const classes = useStyles()

  const dispatch = useDispatch()
  const {user, loading, onLogin} = useAuth()
  const {onOpen} = useFailureModal()

  const handleFindPassword = () => {
    dispatch(openFindPasswordDialog())
  }

  const handleFindId = () => {
    dispatch(openFindIdDialog())
  }

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('IEmailInvalid'))
      .required(t('IEmailRequired'))
      .max(50, t('IEmailLength')),
    password: yup
      .string()
      .required(t('IPasswordRequired'))
      .min(4, t('IPasswordLength'))
      .max(20, t('IPasswordLength'))
      .test('password-character-test', t('IPasswordInvalid'), () => {
        // TODO: 테스트 통과를 위해 임시로 비밀번호 유효성 검사를 해제
        // your logic
        // if (str && str.replace(/[^0-9]/g, '').length === 0) {
        //   return false
        // }
        // if (str && str.replace(/[^A-za-z]/g, '').length === 0) {
        //   return false
        // }
        // if (str && str.replace(/[^A-Z]/g, '').length === 0) {
        //   return false
        // }

        return true
      }),
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<LoginFormInput>({
    // @ts-ignore
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = async (data: LoginFormInput) => {
    try {
      const token = await getFBToken()
      await onLogin({
        ...data,
        deviceId: getOrInitializeDeviceId(),
        deviceType: 'WEB',
        token,
        lang: i18n.language,
      })
    } catch (err) {
      await onOpen(err.message)
    }
  }

  // const handleRegister = () => navigate(getRegisterPath())

  const location = useLocation<AppHistoryState>()
  const from = location?.state?.from ?? {pathname: '/'}
  const twoFactorType = JSON.parse(storage.getTwoFactorType() ?? '{}')

  if (twoFactorType?.type === 'Email') {
    return <Redirect to={{pathname: RouteConstant.VERIFICATION.path}} />
  }
  if (isDefined(user)) {
    return <Redirect to={from} />
  }

  return (
    <div className={classes.root}>
      {/* {loading && <div>loading</div>} */}
      <div className='Container'>
        <div className='Logo'>
          <img src={OldLogo} alt='' />
        </div>
        <div className='Header'>
          <p className='Title'>iSyncBrain®-C/M</p>
          <div className='Desc'>
            Advanced EEG Analysis Platform: cloud-based, AI-driven,
            norm-matched, gender/age-specific
          </div>
        </div>
        <form className='Form' onSubmit={handleSubmit(onSubmit)}>
          <div className='FormControl'>
            <input
              type='text'
              placeholder={t('IEmail')}
              {...register('email')}
            />
            {errors.email && (
              <div className='FormError'>{errors.email?.message}</div>
            )}
          </div>
          <div className='FormControl'>
            <input
              type='password'
              placeholder={t('IPassword')}
              {...register('password')}
            />
            {errors.password && (
              <div className='FormError'>{errors.password?.message}</div>
            )}
          </div>
          <button type='submit'>{t('ILogin')}</button>

          <div className='Buttons'>
            {/* <button
              className='Primary Large Button'
              type='button'
              onClick={handleRegister}
              disabled={loading}
            >
              {t('IRegister')}
            </button> */}
            <div className='FindButtons'>
              <button
                className='White Large Button'
                type='button'
                onClick={handleFindId}
              >
                {t('IFindId')}
              </button>
              <button
                className='White Large Button'
                type='button'
                onClick={handleFindPassword}
              >
                {t('IPasswordFind')}
              </button>
            </div>
          </div>
        </form>
        <p className='Copyright'>Copyright iSyncBrain Co.,Ltd ©2021</p>
      </div>
      <LoginPageFooter />
      {/* 페이지가 Route안에 있어야 history가 제대로 동작하는 것 같다... */}
      <FirstLoginDialog />
    </div>
  )
}
