import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core/styles'
import {Radio} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

import useInvoice from 'features/invoice/useInvoice'
import {BaseFeesType, PlanType} from 'features/invoice/invoiceSlice'

import {FormInputType} from 'pages/CustomerManagement/InvoiceSetting/Form/Cell'
import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'
import {INVOICE_OTHER} from 'pages/CustomerManagement/InvoiceSetting/constant'

const useStyles = makeStyles(InvoiceSettingStyle)

interface ElementOtherProps {
  keyPackage: string
  description: string
  isRadio: boolean
  label: string
  input: FormInputType
  isLast?: boolean
  packageType: PlanType
  baseFees: BaseFeesType | null
  discount: number
  eegSummarySurcharge: number
  changeBaseFeesAction: (payload: BaseFeesType) => void
  changeInvoiceAction: (payload: PlanType) => void
  changeDiscountAction: (payload: number) => void
  changeSummarySurchargeAction: (payload: number) => void
}

const ElementOther = ({
  keyPackage,
  description,
  isRadio,
  label,
  input,
  isLast = false,
  packageType,
  baseFees,
  discount,
  eegSummarySurcharge,
  changeBaseFeesAction,
  changeInvoiceAction,
  changeDiscountAction,
  changeSummarySurchargeAction,
}: ElementOtherProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [isActive, setIsActive] = useState(false)
  const [value, setValue] = useState<string | number>('')

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (key === 'discount') {
      changeDiscountAction(parseInt(e.target.value || '0', 10))
    }
    if (key === 'eeg_summary_surcharge') {
      changeSummarySurchargeAction(parseInt(e.target.value || '0', 10))
    }
    if (key === 'only_device_price') {
      changeBaseFeesAction([
        {
          name: t('IEquipmentRentalFee'),
          price: parseInt(e.target.value || '0', 10),
        },
      ])
    }
    if (key === 'year_subscription_price') {
      changeBaseFeesAction([
        {name: t('IFlatRate'), price: parseInt(e.target.value || '0', 10)},
      ])
    }
  }

  const valueDefault = useMemo(() => {
    if (keyPackage === INVOICE_OTHER[2].keyPackage) return eegSummarySurcharge
    if (keyPackage === INVOICE_OTHER[3].keyPackage) return discount
    if (!baseFees?.length || keyPackage !== packageType) return ''
    return baseFees[0]?.price || ''
  }, [keyPackage, packageType, eegSummarySurcharge, discount, baseFees])

  useEffect(() => {
    if (keyPackage === INVOICE_OTHER[2].keyPackage && !!eegSummarySurcharge) {
      setIsActive(true)
    }
    if (keyPackage === INVOICE_OTHER[3].keyPackage && !!discount) {
      setIsActive(true)
    }
  }, [discount, eegSummarySurcharge])

  useEffect(() => {
    setValue(valueDefault)
  }, [valueDefault])

  if (isLast)
    return (
      <div className={classes.invoiceOtherItem}>
        <div className={classes.invoiceOtherDescription}>
          <div className={classes.invoiceOtherBlank} />
          <div className={classes.descriptionLabel}>{description}</div>
        </div>
      </div>
    )

  return (
    <div className={classes.invoiceOtherItem}>
      <div className={classes.invoiceOtherDescription}>
        <div className={classes.invoiceOtherBlank} />
        <div className={classes.descriptionLabel}>{description}</div>
      </div>
      <div className={classes.invoiceOtherForm}>
        <div className={classes.formElement}>
          {isRadio ? (
            <label htmlFor={keyPackage} className={classes.invoiceOtherTitle}>
              <Radio
                style={{padding: 10}}
                id={keyPackage}
                name={keyPackage}
                checked={packageType === keyPackage}
                onChange={() => changeInvoiceAction(keyPackage as PlanType)}
              />
              <span className={classes.invoiceOtherSpan}>{label}</span>
            </label>
          ) : (
            <label htmlFor={keyPackage} className={classes.invoiceOtherTitle}>
              <Checkbox
                id={keyPackage}
                name={keyPackage}
                checked={isActive}
                onChange={() => {
                  setIsActive(!isActive)
                }}
              />
              <span className={classes.invoiceOtherSpan}>{label}</span>
            </label>
          )}
        </div>
        <div className={classes.invoiceOtherInput}>
          <input
            style={{
              width: input.width ? input.width : 60,
              maxWidth: input.width ? input.width : 60,
            }}
            placeholder={input.placeholder}
            className={classes.input}
            disabled={isRadio ? packageType !== keyPackage : !isActive}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => changeInput(e, input.name)}
          />
        </div>
      </div>
    </div>
  )
}

function InvoiceOtherSetting({press, orgId}: {press: number; orgId: number}) {
  const classes = useStyles()
  const {
    packageType,
    baseFees,
    discount,
    eegSummarySurcharge,
    templates,
    onHandleInvoice,
    changeBaseFeesAction,
    changeInvoiceAction,
    changeDiscountAction,
    changeSummarySurchargeAction,
  } = useInvoice()

  useEffect(() => {
    if (
      press &&
      orgId &&
      (packageType === INVOICE_OTHER[0].keyPackage ||
        packageType === INVOICE_OTHER[1].keyPackage)
    ) {
      onHandleInvoice(orgId, baseFees, templates)
    }
  }, [press])

  return (
    <div className={classes.invoiceOther}>
      {INVOICE_OTHER.map((invoice, index) => (
        <ElementOther
          key={`${index}_other-setting`}
          {...invoice}
          isLast={INVOICE_OTHER.length - 1 === index}
          discount={discount}
          eegSummarySurcharge={eegSummarySurcharge}
          packageType={packageType}
          baseFees={baseFees}
          changeBaseFeesAction={changeBaseFeesAction}
          changeInvoiceAction={changeInvoiceAction}
          changeDiscountAction={changeDiscountAction}
          changeSummarySurchargeAction={changeSummarySurchargeAction}
        />
      ))}
    </div>
  )
}

export default InvoiceOtherSetting
