import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import PageTable from 'pages/Analysis/EEGSummary/PageTable'
import React from 'react'

function EEGSummary() {
  return (
    <>
      <CardWithTitle>
        <PageTable />
      </CardWithTitle>
      <NoticeConfirmDialog />
    </>
  )
}

export default EEGSummary
