import CircularProgress from '@material-ui/core/CircularProgress'
import {AppHistoryState} from 'AppHistory'
import useAuth from 'features/auth/useAuth'
import useSuccessModal from 'features/modal/useSuccessModal'
import {isDefined} from 'helpers/commonHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function RedirectPage() {
  const {t} = useTranslation()
  const loading = true
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const appLocation = useLocation<AppHistoryState>()
  const from = appLocation?.state?.from ?? {pathname: '/'}
  const history = useHistory()
  const {user} = useAuth()

  const handlePaymentResult = async () => {
    history.goBack()
    onSuccessModalOpen(t('IProcessSuccess'))

    // TODO: 빌링키 서버 작업이 끝나면 진행될 것
    // try {
    //   const result = await paymentRequestApi({
    //     paymentKey: params.get('paymentKey') ?? '',
    //     orderId: params.get('orderId') ?? '',
    //     amount: params.get('amount') ?? '',
    //   })
    //   if (!result) {
    //     history.goBack()
    //     onSuccessModalOpen(t('IProcessSuccess'))
    //   }
    //   console.log({result})
    // } catch (error) {
    //   console.log(error)
    // }
  }

  useEffect(() => {
    handlePaymentResult()
  }, [])

  if (isDefined(user) && !window.location.href.includes('org')) {
    return <Redirect to={from} />
  }

  if (loading)
    return (
      <ContentWrap>
        <CircularProgress size='3rem' color='primary' />
      </ContentWrap>
    )
  return (
    <>
      <ContentWrap>
        <CircularProgress size='3rem' color='primary' />
      </ContentWrap>
    </>
  )
}

export default RedirectPage
