import ActionButton from 'components/atoms/Buttons/ActionButton'
import RegisteredCardDialog from 'components/Dialog/RegisteredCardDialog'
import {openRegisteredCardDialog} from 'features/modal/modalSlice'
import {useAppDispatch} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const WrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 36px;
`
const ContentContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const ContentWrap = styled.div`
  display: flex;
  width: 350px;
  gap: 10px;
`
const TitleContainer = styled.div`
  display: flex;
  width: 120px;
`
const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
`
const ContentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin: 0;
`
const BackgroundWrap = styled.div`
  width: 100%;
  background-color: #f7f7f8;
  border-radius: 4px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Select = styled.select`
  margin: 0;
  min-width: 0;
  display: block;
  padding: 8px 8px;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid;
  border-radius: 4px;
  color: inherit;
  border-radius: 3px;
  border: solid 1px #e0e0e0;
  background-color: #fff;
`

interface SelectProps {
  selectContent: string[]
  setSelectName: (value?: string) => void
  defaultValue: string
}

const SelectMonth = ({
  selectContent,
  setSelectName,
  defaultValue,
}: SelectProps) => {
  const {t} = useTranslation()
  return (
    <Select
      onChange={(e) => {
        setSelectName(e.target.value)
      }}
    >
      {selectContent.map((option) => (
        <option
          value={option}
          defaultValue={defaultValue === option ? defaultValue : 'ISelect'}
        >
          {t(option)}
        </option>
      ))}
    </Select>
  )
}

const RegisteredCard = () => {
  const months: string[] = ['5월', '4월', '3월', '2월', '1월']
  const dispatch = useAppDispatch()

  const {t} = useTranslation()
  const onRegisteredCardOpen = () => dispatch(openRegisteredCardDialog())

  return (
    <WrapDiv>
      <BackgroundWrap>
        <ContentContainer>
          <ContentWrap>
            <TitleContainer>
              <TitleText>신한카드 법인</TitleText>
            </TitleContainer>
            <ContentText>nnnn **** **** nnnn</ContentText>
          </ContentWrap>
          <ContentWrap>
            <TitleContainer>
              <TitleText>다음 후불 결제일</TitleText>
            </TitleContainer>
            <ContentText>2022.04.22</ContentText>
          </ContentWrap>
        </ContentContainer>
        <ContentContainer>
          <ContentWrap>
            <TitleContainer>
              <TitleText>등록 날짜</TitleText>
            </TitleContainer>
            <ContentText>2022.04.22</ContentText>
          </ContentWrap>
          <TitleContainer>
            <TitleText>결제일 변경</TitleText>
          </TitleContainer>

          <SelectMonth
            selectContent={months}
            setSelectName={() => {}}
            defaultValue={months[0]}
          />

          <ContentContainer>
            <ActionButton
              onClick={() => {
                onRegisteredCardOpen()
              }}
            >
              {t('IChange')}
            </ActionButton>
          </ContentContainer>
        </ContentContainer>
      </BackgroundWrap>
      <RegisteredCardDialog />
    </WrapDiv>
  )
}
export default RegisteredCard
