import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from '../../../Text/ReportSubtitle'
import {Colors} from '../../../common/useBaseStyle'
import {Lobe} from '../../../../api/analysisApi'
import {Section5Desc} from './Style'

export default function EEGSummaryBrainAgeDevelopment(props: {
  brainAgeGraphImage: string | undefined
  avg: number
  age: number
  lobe: Lobe
}) {
  const {brainAgeGraphImage, avg, lobe, age} = props
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.eeg}>
      <ReportSubtitle number='5'>
        {t('IEEGSummaryReportChildBrainAgeTitle')}
      </ReportSubtitle>
      <div className={classes.childValueGraphFull}>
        <div className='Child-Graph-Desc'>
          <Section5Desc className='Top-Desc'>
            {t('IEEGSummaryReportChildBrainAgeDesc')}
          </Section5Desc>
        </div>
        <div className='Child-Graph-Box'>
          <div className='Child-Graph'>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <div
              style={{
                backgroundImage: `url(${brainAgeGraphImage})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: 500,
                backgroundSize: 'contain',
              }}
            >
              <div className='Score-Layout'>
                <div className='Bottom-Avg'>
                  <div className={`Avg-Label ${avg > age ? '' : 'Red-Label'}`}>
                    {t('IEEGSummaryReportChildBrainAgeTitleImg')}
                  </div>
                  <div className='Avg-Score'>
                    <span
                      className={`Avg-Score-Value ${
                        avg > age ? '' : 'Red-Label'
                      }`}
                      style={{lineHeight: '77px'}}
                    >
                      {avg}
                    </span>
                    <span
                      className={`Avg-Score-Unit ${
                        avg > age ? '' : 'Red-Label'
                      }`}
                    >
                      {t('IEEGSummaryReportChildBrainAgeYear')}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#e0e0e0',
                  }}
                />
                <div className='Bottom-Score' style={{marginTop: '13px'}}>
                  <div className='Current-Label'>
                    {t('IEEGSummaryReportChildBrainAgeTitleAge', {
                      age: age?.toFixed(1),
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='Child-info-alert' style={{marginBottom: 45}}>
              {t('IEEGSummaryReportChildEOAlert')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
