import {ThreeDEEGType, ThreeDReport} from 'api/analysisApi'
import ConnectivityFooter from 'pages/Popup/ThreeDReport/Footer/ConnectivityFooter'
import PowerFooter from 'pages/Popup/ThreeDReport/Footer/PowerFooter'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 11;
`

interface FooterProps {
  theme: ThreeDTheme
  report: ThreeDReport
  gb: ThreeDEEGType
}

const Index = ({report, theme, gb}: FooterProps) => {
  return (
    <StyledFooter>
      {report.analysisType === 'C' && <ConnectivityFooter theme={theme} />}
      {report.analysisType !== 'C' && (
        <PowerFooter
          theme={theme}
          gb={gb}
          analysisType={report.analysisType}
          leftValue={report.zscoreLeftValue}
          centerValue={report.zscoreMiddleValue}
          rightValue={report.zscoreRightValue}
        />
      )}
    </StyledFooter>
  )
}

export default Index
