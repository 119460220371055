// eslint-disable-next-line import/prefer-default-export
export enum PaymentGatewayType {
  EximBay = 'EB',
  Toss = 'TP',
}

export enum OrderSteps {
  Management = 0,
  ProductsSelect = 1,
  ProductsCheck = 2,
}

export enum VisibleTypes {
  All = 'IProductName',
  Packages = 'IPackage',
  Bundles = 'IBundle',
}

export enum ProductsTypes {
  Package = 'package',
  Bundle = 'bundle',
}
