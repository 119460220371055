import firebase from 'firebase/app'
import {dispatchPushReceived} from 'helpers/pushHelper'

import 'firebase/messaging'

const config = {
  appId: '1:163079497730:web:071e1452b8a5103aef5f1b',
  apiKey: 'AIzaSyCXplOzn_lGNzMoC9FdLZSSqWs_Hyikqo0',
  authDomain: 'isyncbrain-89136.firebaseapp.com',
  projectId: 'isyncbrain-89136',
  storageBucket: 'isyncbrain-89136.appspot.com',
  messagingSenderId: '163079497730',
  measurementId: 'G-E8J0YNMXBE',
}

const vapidKey =
  'BB6JigP87YzntuxAXiFN21pMWTwc25UN5mupDp6JQAGhz1BMcYw9PVzlizCBRgJh8aDnJZQvKzjrrztIZEAfjBM'

interface AnalysisPush {
  type: 'ANALYSIS'
  analysisId: string
  status: AnalysisStatus
  measureId: string
  uid: string
}

export interface FcmMessage {
  data: AnalysisPush
  fcmMessageId: string
  from: string
  notification: {
    title: string
    body: string
  }
  priority: 'normal'
}

export const init = () => {
  if (!firebase.apps.length) {
    try {
      firebase.initializeApp(config)
      // export const messaging = firebase.messaging()
      const messaging = firebase.messaging()

      messaging.onMessage((payload) => {
        dispatchPushReceived(payload)
      })
    } catch (err) {
      console.log('firebase initialize error:', err)
    }
  } else {
    firebase.app()
  }
}

export const requestNotificationPermission = async () => {
  if (window.Notification === undefined) return false

  const permitted = await window.Notification.requestPermission()
  if (permitted === 'default') return false
  if (permitted === 'denied') return false
  return permitted === 'granted'
}

export const getFBToken = async () => {
  try {
    const permitted = await requestNotificationPermission()
    if (!permitted) return ''

    return await firebase.messaging().getToken({
      vapidKey,
    })
  } catch (e) {
    console.log('firbase Token error: ', e)
    return ''
  }
}

export const generateDeviceId = (length = 8) =>
  `${Date.now()}-${
    Math.floor(Math.random() * 9 ** length - 1) + (1 ** length - 1)
  }`
