import {
  fetch3DPowerApi,
  fetch3DReportApi,
  fetch3DZScoreApi,
  FetchThreeDReportResponse,
  ThreeDAnalysisType,
  ThreeDEEGType,
  ThreeDPowerResponse,
  ThreeDReport,
  ThreeDSideType,
  ThreeDZScoreRange,
  ThreeDZScoreResponse,
} from 'api/analysisApi'
import {isDefined} from 'helpers/commonHelper'
import produce from 'immer'
import Index from 'pages/Popup/ThreeDReport/Footer'
import PowerTab from 'pages/Popup/ThreeDReport/PowerTab'
import Renderer from 'pages/Popup/ThreeDReport/Renderer'
import Settings from 'pages/Popup/ThreeDReport/Settings'
import SideTab from 'pages/Popup/ThreeDReport/SideTab'
import React, {useEffect, useMemo, useReducer, useState} from 'react'
import styled from 'styled-components'

export type ThreeDTheme = 'Light' | 'Dark'

type Action =
  | {type: '3D_Power/REQUEST'}
  | {type: '3D_Power/SUCCESS'; payload: ThreeDPowerResponse[]}
  | {type: '3D_Power/FAILURE'; payload: string}
  | {type: '3D_Data/REQUEST'}
  | {
      type: '3D_Data/SUCCESS'
      payload: FetchThreeDReportResponse & {
        analysisType: ThreeDAnalysisType
        sideType: ThreeDSideType
        bandType: number
      }
    }
  | {type: '3D_Data/FAILURE'; payload: string}
  | {type: '3D_ZScore/REQUEST'}
  | {
      type: '3D_ZScore/SUCCESS'
      payload: {
        list: ThreeDZScoreResponse[]
        zscore: ThreeDZScoreRange
      }
    }
  | {type: '3D_ZScore/FAILURE'; payload: string}
  | {type: '3D_TOGGLE_THEME'}

interface ThreeDimensionReportState {
  loading: boolean
  error?: string
  analysisType: ThreeDAnalysisType
  sideType: ThreeDSideType
  bandType: number
  zscore: ThreeDZScoreRange
  theme: ThreeDTheme
  requestNo: number
  gb: ThreeDEEGType
  neural3dDataList: ThreeDReport[]
  neural3dZscoreDataList: ThreeDZScoreResponse[]
  zScoreStrList: ThreeDZScoreRange[]
  supportedPower: ThreeDPowerResponse[]
}

function reducer(state: ThreeDimensionReportState, action: Action) {
  switch (action.type) {
    case '3D_Power/SUCCESS':
      return produce(state, (draft) => {
        draft.supportedPower = action.payload
        draft.error = undefined
      })
    case '3D_Power/FAILURE':
      return produce(state, (draft) => {
        draft.error = action.payload
      })
    case '3D_Data/REQUEST':
      return produce(state, (draft) => {
        draft.loading = true
        draft.error = undefined
      })
    case '3D_Data/SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false
        draft.neural3dDataList = action.payload.neural3dDataList
        draft.neural3dZscoreDataList =
          action.payload.neural3dZscoreDataList ?? []
        draft.zScoreStrList = action.payload.zScoreStrList.filter(isDefined)
        draft.analysisType = action.payload.analysisType
        draft.sideType = action.payload.sideType
        draft.bandType = action.payload.bandType
      })
    case '3D_Data/FAILURE':
      return produce(state, (draft) => {
        draft.loading = false
        draft.error = action.payload
      })
    case '3D_ZScore/REQUEST':
      return produce(state, (draft) => {
        draft.loading = true
        draft.error = undefined
      })
    case '3D_ZScore/SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false
        draft.neural3dZscoreDataList = action.payload.list
        draft.zscore = action.payload.zscore
      })
    case '3D_ZScore/FAILURE':
      return produce(state, (draft) => {
        draft.loading = false
        draft.error = action.payload
      })
    case '3D_TOGGLE_THEME':
      return produce(state, (draft) => {
        draft.theme = draft.theme === 'Dark' ? 'Light' : 'Dark'
      })
    default:
      return state
  }
}

interface StyledContainerProps {
  theme: ThreeDTheme
}

const StyledReport = styled.div<StyledContainerProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.theme === 'Dark' ? '#000' : '#fff')};
  position: relative;

  & .flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    z-index: 1;
  }

  & .footer {
    position: fixed;
    left: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
`

interface ReportProps {
  id: number
  type: ThreeDEEGType
}

export default function Report({id, type}: ReportProps) {
  const [open, setOpen] = useState(false)
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: undefined,
    analysisType: 'P',
    sideType: 'B',
    bandType: 1,
    zscore: '15',
    theme: 'Light',
    requestNo: id,
    gb: type,
    neural3dDataList: [],
    neural3dZscoreDataList: [],
    zScoreStrList: [],
    supportedPower: [],
  })

  const report = useMemo(
    () =>
      state.neural3dDataList.find(
        (item) =>
          item.analysisType === state.analysisType &&
          item.bandType === state.bandType &&
          item.sideType === state.sideType,
      ),
    [
      state.analysisType,
      state.sideType,
      state.bandType,
      state.neural3dDataList,
    ],
  )

  const path = useMemo(() => report?.filePath, [report])

  const fetch3dPowerAction = async () => {
    dispatch({type: '3D_Power/REQUEST'})
    try {
      const response = await fetch3DPowerApi({
        requestId: state.requestNo,
        gb: state.gb,
      })
      dispatch({type: '3D_Power/SUCCESS', payload: response.list})
    } catch (err) {
      dispatch({type: '3D_Power/FAILURE', payload: err.message})
    }
  }

  const fetch3dDataAction = async ({
    analysisType,
    sideType,
    bandType,
  }: {
    analysisType?: ThreeDAnalysisType
    sideType?: ThreeDSideType
    bandType?: number
  }) => {
    const defaultAnalysisType = analysisType ?? state.analysisType

    const defaultSideType = sideType ?? state.sideType
    const processedSideType: ThreeDSideType =
      defaultAnalysisType === 'C' ? 'B' : defaultSideType

    const defaultBandType = bandType ?? state.bandType

    dispatch({type: '3D_Data/REQUEST'})

    try {
      const response = await fetch3DReportApi({
        requestIds: [id],
        gb: type,
        analysisType: defaultAnalysisType,
        sideType: processedSideType,
        bandType: defaultBandType,
      })
      dispatch({
        type: '3D_Data/SUCCESS',
        payload: {
          ...response.list[0],
          analysisType: defaultAnalysisType,
          sideType: processedSideType,
          bandType: defaultBandType,
        },
      })
    } catch (err) {
      dispatch({type: '3D_Data/FAILURE', payload: err.message})
    }
  }

  const changePowerAction = (analysisType: ThreeDAnalysisType) =>
    fetch3dDataAction({analysisType})

  const changeSideAction = (sideType: ThreeDSideType) =>
    fetch3dDataAction({sideType})

  const changeBandAction = (bandType: number) => fetch3dDataAction({bandType})

  const changeZScoreAction = async (zscore: ThreeDZScoreRange) => {
    dispatch({type: '3D_ZScore/REQUEST'})
    try {
      const response = await fetch3DZScoreApi({
        requestId: id,
        gb: type,
        analysisType: state.analysisType,
        sideType: state.sideType,
        zscore,
      })
      dispatch({
        type: '3D_ZScore/SUCCESS',
        payload: {
          zscore,
          list: response.list,
        },
      })
    } catch (err) {
      dispatch({type: '3D_ZScore/FAILURE', payload: err.message})
    }
  }

  const toggleThemeAction = () => dispatch({type: '3D_TOGGLE_THEME'})

  useEffect(() => {
    fetch3dPowerAction().then(() => fetch3dDataAction({}))
  }, [])

  return (
    <StyledReport theme={state.theme}>
      <div className='flex-center'>
        <PowerTab
          loading={state.loading}
          theme={state.theme}
          analysis_type={state.analysisType}
          supported_power={state.supportedPower}
          onPowerChange={changePowerAction}
        />
      </div>
      <div className='flex-center'>
        <SideTab
          loading={state.loading}
          theme={state.theme}
          analysis_type={state.analysisType}
          side_type={state.sideType}
          supported_power={state.supportedPower}
          onSideChange={changeSideAction}
        />
      </div>
      {path !== undefined && <Renderer path={path} theme={state.theme} />}
      {report !== undefined && (
        <Index report={report} theme={state.theme} gb={state.gb} />
      )}
      <Settings
        loading={state.loading}
        theme={state.theme}
        open={open}
        onOpen={() => setOpen((open) => !open)}
        onThemeChanged={toggleThemeAction}
        onBandChanged={(bandType: number) => changeBandAction(bandType)}
        bandType={state.bandType}
        neural3dDataList={state.neural3dDataList}
        neural3dZscoreDataList={state.neural3dZscoreDataList}
        zscore={state.zscore}
        zscoreStrList={state.zScoreStrList}
        onZScoreChange={changeZScoreAction}
      />
    </StyledReport>
  )
}
