import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    // Tabs Section
    tabWrapper: {
      display: 'flex',
      borderRadius: '5px 5px 0px 0px',
      justifyContent: 'space-between',
      minWidth: 50,
      '@media (min-width: 1280px)': {
        minWidth: 130,
      },
      '@media (min-width: 1440px)': {
        minWidth: 180,
      },
      '@media (min-width: 1700px)': {
        minWidth: 230,
      },
    },
    tabContainer: {
      display: 'flex',
      gap: 5,
    },
    tab: {
      padding: '5px 10px',
      borderRadius: '5px 5px 0px 0px',
      backgroundColor: '#3ebcbd',
      cursor: 'pointer',
    },
    tabActive: {
      fontWeight: 600,
      cursor: 'default',
      backgroundColor: '#3ebcbd',
      color: 'white',
    },
    // Table Content
    rowContainer: {
      '& label': {
        display: 'inline-flex',
        gap: 8,
        marginRight: 10,
      },
    },
    reportPage: {
      padding: '40px 45px',
      backgroundColor: 'white ',
      justifyContent: 'space-between',
      '@media (max-width: 1600px)': {
        padding: '20px 20px',
      },
    },
    reportContainer: {
      width: '50%',
      padding: '10px 15px',
    },
    reportTitle: {
      fontSize: '18px',
      color: '#333333',
    },
    // Report Type - Position 1
    reportTypeTitle: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 16,
      color: '#333333',
      marginTop: 30,
      gap: '10px !important',
    },
    // Report Type Field - Position 2 & 3
    reportFieldContainer: {
      fontWeight: 500,
      marginTop: 20,
      marginLeft: 30,
    },
    reportFieldTitle: {
      fontSize: 14,
      color: '#5A6268',
      marginBottom: 10,
    },
    reportFieldValue: {
      minWidth: 160,
      fontSize: 13,
      color: '#5A6268',
      '& > .MuiRadio-colorSecondary.Mui-checked': {
        color: '#10CFC9 !important',
      },
    },
    fieldRadio: {
      minWidth: 160,
      fontSize: 13,
      color: '#5A6268',
      '& > .MuiRadio-colorSecondary.Mui-checked': {
        color: '#10CFC9 !important',
      },
      '@media (max-width: 1920px)': {
        minWidth: 140,
      },
      '@media (max-width: 1800px)': {
        minWidth: 100,
      },
      '@media (max-width: 1700px)': {
        minWidth: 80,
      },
      '@media (max-width: 1400px)': {
        minWidth: 40,
      },
    },
    verticalDivider: {
      borderLeft: '1px solid #E9E9E9',
      margin: '0px 25px',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
