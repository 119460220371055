import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FormControlLabel} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import WaveGraphPropertyFactory, {
  WaveGraphPropertySourceType,
} from 'lib/GraphSource'
import useReloadImg from 'features/analysis/useReloadImg'
import {CONFIRM_OK} from 'constants/DialogConstant'
import {AnalysisResultItem, TypeScreenGraph, TypeGraph} from 'api/analysisApi'
import {WaveGraph} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawData'
import {HorizontalImageGraph} from 'components/molcules/Report/RawData/HorizontalImageGraph'
import CustomConfirmDialog from 'components/Dialog/CustomConfirmDialog'

interface RawDataProps {
  readonly data: AnalysisResultItem
  readonly type: TypeGraph
  readonly screen: TypeScreenGraph
}

enum SearchKeys {
  advancedMode = 'advancedMode',
  normalMode = 'normalMode',
}

const SEARCH_SELECT_ITEMS: SelectItem<any>[] = [
  {label: 'INormalMode', value: SearchKeys.normalMode},
  {label: 'IAdvancedMode', value: SearchKeys.advancedMode},
]

const NORM_MODE: SelectItem<any>[] = [
  {label: 'INormalMode', value: SearchKeys.normalMode},
]
const useStyles = makeStyles(() =>
  createStyles({
    combinationRadio: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '15px',
    },
    ml15: {
      marginLeft: '15px',
    },
  }),
)
function RenderRawDataGraph({data, type, screen}: RawDataProps) {
  const classes = useStyles()
  const {time} = useReloadImg()

  const [changeKey, setChangeKey] = useState<string>(SearchKeys.normalMode)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<SelectItem<any>[]>(NORM_MODE)
  const {t} = useTranslation()

  const handleSearchChanged = (value: string) => {
    if (value === SearchKeys.normalMode) {
      setChangeKey(value)
    } else {
      setIsOpen(true)
    }
  }

  const handleResult = (value: ConfirmResult) => {
    if (value === CONFIRM_OK) {
      setChangeKey(SearchKeys.advancedMode)
    }
  }

  const handleIsOpen = (value: boolean) => {
    setIsOpen(value)
  }
  const renderWaveGraph = () => {
    const propertyGuide = (() => {
      switch (type) {
        case 'raw':
          return WaveGraphPropertyFactory.sourceOfType(
            WaveGraphPropertySourceType.Raw,
            data.rejectedTime ? JSON.parse(data.rejectedTime) : [],
          )
        case 'component':
          return WaveGraphPropertyFactory.sourceOfType(
            WaveGraphPropertySourceType.Component,
          )
        case 'cleaned':
          return WaveGraphPropertyFactory.sourceOfType(
            WaveGraphPropertySourceType.Cleaned,
          )
        default:
          return null
      }
    })()

    return propertyGuide ? (
      <WaveGraph
        raw={{
          url: data.rawDataNamePath,
        }}
        image={data.rawDataPath}
        propertyGuide={propertyGuide}
        screen={screen}
        typeGraph={type}
      />
    ) : null
  }

  useEffect(() => {
    if (data.rawDataNamePath) {
      setSelectItem(SEARCH_SELECT_ITEMS)
    } else {
      setSelectItem(NORM_MODE)
    }
  }, [data])

  if (data) {
    return (
      <Fragment>
        <CustomConfirmDialog
          title={t('IConfirm')}
          message={t('INotificationSwitchModeGraph')}
          isOpen={isOpen}
          handleResult={handleResult}
          handleIsOpen={handleIsOpen}
        />
        <div
          className={classes.combinationRadio}
          style={{display: selectItem.length > 1 ? '' : 'none'}}
        >
          {selectItem.map((item) => (
            <FormControlLabel
              control={
                <Radio checked={changeKey === item.value} color='primary' />
              }
              value={item.value}
              key={item.value}
              label={t(item.label)}
              onChange={() => handleSearchChanged(item.value)}
              className={classes.ml15}
            />
          ))}
        </div>
        {changeKey === SearchKeys.normalMode ? (
          <HorizontalImageGraph
            xAxisImg={
              time && type === 'cleaned'
                ? `${data.imgPath}?${time}`
                : data.imgPath
            }
            key={time && type === 'cleaned' ? time : undefined}
            screen={screen}
            typeGraph={type}
          />
        ) : (
          renderWaveGraph()
        )}
      </Fragment>
    )
  }
  return null
}
export default RenderRawDataGraph
