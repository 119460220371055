import {Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import CardWithTitle from 'components/atoms/CardWithTitle'
import useAuth from 'features/auth/useAuth'
import {openSalesOrgEditDialog} from 'features/modal/modalSlice'
import {SalesState, selectSales} from 'features/sales/salesSlice'
import {useAppDispatch} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: theme.spacing(1),
  },
  direction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tableMargin: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  hospitalName: {
    fontWeight: 'bolder',
    color: '#555555',
  },
  contentText: {
    color: '#555555',
  },
}))

interface ButtonProps {
  salesState: SalesState
  uid: string
}

const buttonUseStyles = makeStyles(() => ({
  button: {
    minWidth: 110,
    maxWidth: 110,
  },
}))

function HospitalEditButton(buttonProps: ButtonProps) {
  const {t} = useTranslation()
  const classes = buttonUseStyles()
  const dispatch = useAppDispatch()
  const {salesState} = buttonProps

  return (
    <div>
      {salesState.searchedDetailData != null && (
        <Button
          className={classes.button}
          color='primary'
          variant='contained'
          disableElevation
          onClick={() => {
            dispatch(openSalesOrgEditDialog())
          }}
        >
          {t('IEdit')}
        </Button>
      )}
    </div>
  )
}

export default function SalesSearchedInfo() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {user: currentUser} = useAuth()
  const searchedDetail = useSelector(selectSales)

  return (
    <CardWithTitle
      title={String(t('ISearchedOrg'))}
      buttons={[
        HospitalEditButton({
          salesState: searchedDetail,
          uid: currentUser?.uid ?? '',
        }),
      ]}
    >
      <Grid xs={12}>
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IOrgName')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.orgName ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IOrgNursingInstitutionNumber')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.institutionNo ??
                  '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('ICeoName')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.firstName ?? '-'}
                {searchedDetail.searchedDetailData?.orgDetail.lastName ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IOrgUserCount')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.doctorCount ??
                  '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IEmail')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.email ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IJoinRoute')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.joinRoute ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IPassword')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.password ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IInterestArea')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.interestArea ??
                  '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('ICountry')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.country.name ??
                  '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('ISalesManagerLabelNameEmail')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.salesName ?? '-'}{' '}
                /{' '}
                {searchedDetail.searchedDetailData?.orgDetail.salesEmail ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('ITel')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.tel ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IAuthority')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.authority ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IIndustryDetail')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.industry.title ??
                  '-'}{' '}
                /{' '}
                {searchedDetail.searchedDetailData?.orgDetail.detailIndustry
                  .title ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IProperty')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.property ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IArea')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.area ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IJoinDate')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.createdAt ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IAddress')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.address ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('ISubscriptionInfo')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.startDate ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        <div className={classes.tableMargin}>
          <Grid xs={12} md={6} className={classes.direction}>
            <Grid xs={12} md={4}>
              <Typography variant='subtitle1' className={classes.hospitalName}>
                {t('IBusinessNo')}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='subtitle1' className={classes.contentText}>
                {searchedDetail.searchedDetailData?.orgDetail.businessNo ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Typography className={classes.padding} />
        <Typography className={classes.padding} />
      </Grid>
    </CardWithTitle>
  )
}
