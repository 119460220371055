import React from 'react'
import down from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/down.svg'
import useGaugeStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/style'

interface Props {
  index: number
  percentage: number
}
function ProcessArrow({index, percentage}: Props) {
  const classes = useGaugeStyles()

  return (
    <>
      {index === 3 ? (
        <div className={classes.processArrowV}>
          <img
            className={classes.percentBarV2Arrow}
            style={{marginLeft: `${percentage - 1}%`, position: 'absolute'}}
            src={down}
            alt='icon'
          />
        </div>
      ) : (
        <div>
          <div className={classes.processArrowV1}>
            <img
              style={{
                top: `${percentage - 1}%`,
                height: '8px',
                transform: index === 1 ? 'rotate(90deg)' : 'rotate(270deg)',
                position: 'absolute',
              }}
              src={down}
              alt='icon'
            />
          </div>
        </div>
      )}
    </>
  )
}
export default ProcessArrow
