import {makeStyles} from '@material-ui/core'
import Button, {ButtonProps} from '@material-ui/core/Button'
import {createStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

interface ActionButtonProps extends ButtonProps {
  // size?: 'sm' | 'lg'
  color?: 'secondary' | 'primary' | 'default'
  error?: boolean
}

const styles = () =>
  createStyles({
    root: {
      minWidth: 87,
      height: 40,
      borderRadius: 6,
    },
    error: {
      // marginBottom: 23,
    },
  })
const useStyles = makeStyles(styles)

const ActionButton = ({
  className,
  error = false,
  children,
  color = 'secondary',
  ...props
}: ActionButtonProps) => {
  const classes = useStyles()

  const actionButtonClasses = classNames({
    [classes.root]: true,
    [classes.error]: error,
    // @ts-ignore
    [className]: className !== undefined,
  })

  return (
    <Button
      variant='contained'
      color={color}
      disableElevation
      className={actionButtonClasses}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ActionButton
