import React from 'react'
import {Dialog, DialogContent, Divider} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
  CONFIRM_CANCEL,
  CONFIRM_CLOSE,
  CONFIRM_OK,
} from 'constants/DialogConstant'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'

interface CustomConfirmProps {
  title: string
  message: string
  handleResult: (result: ConfirmResult) => void
  isOpen: boolean
  handleIsOpen: (result: boolean) => void
}

const styles = () =>
  createStyles({
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 15,
    },
    inputLabel: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 2px',
      minWidth: 120,
      maxWidth: 100,
    },
    buttonsContainer: {
      padding: 20,
      backgroundColor: '#f4f4f4',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 10,
      '& .MuiFormLabel-root': {
        marginBottom: 10,
      },
      '& .MuiFormControlLabel-root': {
        minWidth: 250,
      },
      '& button': {
        minWidth: 136,
      },
    },
    radioWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& label > span': {
        marginRight: 70,
        color: '#555555',
        fontSize: 13,
      },
      '& label > span > span > div > .MuiSvgIcon-fontSizeSmall': {
        fontSize: 17,
      },
    },
    radioText: {
      color: '#555555',
      fontSize: 13,
    },
    line: {
      color: '#fafafa',
      backgroundColor: '#fafafa',
      margin: '5px 0',
    },
  })
const useStyles = makeStyles(styles)

const CustomConfirmDialog = ({
  title,
  message,
  handleResult,
  isOpen,
  handleIsOpen,
}: CustomConfirmProps) => {
  const classes = useStyles()
  return (
    <>
      <Dialog open={isOpen} disableEscapeKeyDown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              color: '#333333',
              fontSize: 18,
              fontWeight: 'bold',
              marginLeft: 20,
            }}
          >
            {title}
          </div>
          <div>
            <IconButton
              aria-label='search'
              onClick={() => {
                handleResult(CONFIRM_CLOSE)
                handleIsOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
        <DialogContent style={{margin: 10, whiteSpace: 'pre-wrap'}}>
          <div style={{color: 'rgb(134, 142, 150)', textAlign: 'center'}}>
            {message}
          </div>
        </DialogContent>
        <div className={classes.buttonsContainer}>
          <ConfirmButton
            onClick={() => {
              handleResult(CONFIRM_OK)
              handleIsOpen(false)
            }}
          />
          <CancelButton
            onClick={() => {
              handleResult(CONFIRM_CANCEL)
              handleIsOpen(false)
            }}
          />
        </div>
      </Dialog>
    </>
  )
}

export default CustomConfirmDialog
