import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {isEqualsDate} from 'helpers/dateHelper'
import {useLocation} from 'react-router-dom'
import {getQueryFromUrl} from 'helpers/commonHelper'
import React, {useEffect} from 'react'

interface SelectPeriodSearchProps {
  period: PeriodDate
  onSearch: (params: PeriodDate) => void
  hasPlaceholder?: boolean
}

function PeriodSearch({
  period,
  onSearch,
  hasPlaceholder,
}: SelectPeriodSearchProps) {
  const classes = useToolbarStyles()
  const location = useLocation()

  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate: period.startDate,
    endDate: period.endDate,
  })

  const {startDate, endDate} = selection

  const handleValue = (value: any) => {
    setSelection(value)
  }

  const handleDateSearch = () => {
    if (
      isEqualsDate(startDate, period.startDate) &&
      isEqualsDate(endDate, period.endDate)
    ) {
      return
    }
    onSearch({startDate, endDate})
  }

  /** Set default value for search period if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (
      searchParams?.period &&
      searchParams.period.startDate &&
      searchParams.period.endDate
    ) {
      // Refactor start date plus 1 for match with  server date
      const startDate = new Date(searchParams.period.startDate)
      startDate.setDate(startDate.getDate() + 1)
      setSelection({
        startDate,
        endDate: new Date(searchParams.period.endDate),
      })
    } else if (searchParams.startDate && searchParams.endDate) {
      // Refactor start date plus 1 for match with  server date
      const startDate = new Date(searchParams.startDate)
      startDate.setDate(startDate.getDate() + 1)
      setSelection({
        startDate,
        endDate: new Date(searchParams.endDate),
      })
    }
  }, [])

  useEffect(() => {
    handleDateSearch()
  }, [selection])

  return (
    <RangeDatePicker
      onChangeValue={handleValue}
      dateValue={selection}
      hasPlaceholder={hasPlaceholder}
    />
  )
}

export default PeriodSearch
