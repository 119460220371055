import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getProductsMyOwn, unPaidOrder} from 'api/paymentApi'
import {makeRsaKeys} from 'api/securityApi'
import MyProductModel from '../Models/MyProductModel'
import UnPaidOrderModel from '../Models/UnPaidOrderModel'

// MyProductsViewModel
// 마이패키지 테이블은 따로 뷰모델 생성 이건 나중에 다르걸로 바꿔야 함
export default function PaymentManageViewModel() {
  const {t} = useTranslation()

  const [values, setValues] = useState<{
    hasUnpaid: boolean
    unPaidOrder: UnPaidOrderModel | null
  }>({hasUnpaid: false, unPaidOrder: null})
  const [myProducts, setMyProducts] = useState<MyProductModel>({} as never)

  async function getMyProducts() {
    const result = await getProductsMyOwn()
    if (result) {
      const products = new MyProductModel(result, t)
      setMyProducts(products)
      //
    }
  }

  async function getUnpaidOrder() {
    try {
      const keySet = makeRsaKeys()
      const eKey = btoa(keySet.PublicKey)

      const res = await unPaidOrder(eKey)
      const order = new UnPaidOrderModel(res, keySet.PrivateKey)
      setValues({...values, hasUnpaid: true, unPaidOrder: order})
    } catch (e) {
      // no exists unpaid order
      console.log('no exists order with error : ', e)
    }
  }

  async function setInitialUnpaidOrder() {
    setValues({hasUnpaid: false, unPaidOrder: null})
  }

  return {
    setInitialUnpaidOrder,
    getMyProducts,
    getUnpaidOrder,
    myProducts,
    ...values,
  }
}
