import {Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
    horizontalImage: {
      width: '100%',
      height: 'auto',
    },
    carouselRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    carouselButtonContainer: {
      display: 'flex',
    },
    carouselIndicator: {
      alignSelf: 'center',
      fontSize: '2rem',
      background: '#2db1ae',
      color: '#fff',
      borderRadius: '50px',
      padding: '5px 20px',
    },
    carouselNeuralIndex: {
      width: '50vw',
      verticalAlign: 'middle',
      alignSelf: 'center',
    },
    carouselContainer: {
      // 1312x1125 비율을 유지하는 이미지 래퍼
      alignSelf: 'center',
      position: 'relative',
      overflow: 'hidden',
      // md보다 크면 가로 최대 55%로 설정
      [theme.breakpoints.up('md')]: {
        paddingTop: '47vw',
        width: '55vw',
        height: 0,
      },
      // sm보다 작으면 100%에 1312x1125 비율 유지
      [theme.breakpoints.down('sm')]: {
        paddingTop: '87%',
        width: '100%',
        height: 0,
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
      },
    },
    carouselButton: {
      color: theme.palette.grey[400],
    },
    carouselButtonActive: {
      color: theme.palette.grey[900],
      fontSize: '1.1rem',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
