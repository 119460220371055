import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import ProgressBar from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/ProgressBar'
import TitlePosition from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/TitlePosition'
import DescriptionPosition from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/DescriptionPosition'
import ProcessArrow from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/ProcessArrow'
import useHealthCenterStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV3/style'

interface Props {
  headerName: string
  percentage: number
  index: number
  top: string
  middle: string
  bottom: string
}

function PercentBarCardV3({
  headerName,
  percentage,
  index,
  top,
  middle,
  bottom,
}: Props) {
  const classes = useHealthCenterStyles()
  const {t} = useTranslation()
  const listPosition = {
    TOP_LEFT: 1,
    TOP_RIGHT: 2,
    BOT_CENTER: 3,
  }
  const contentTitlePosition = [
    {
      id: 0,
      text: top,
    },
    {
      id: 1,
      text: middle,
    },
    {
      id: 0,
      text: bottom,
    },
  ]

  const getTextInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return top
    }
    if (Math.floor(percentage) > 70) {
      return bottom
    }
    return middle
  }, [percentage])

  const getColorInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return '#B4B4B4'
  }, [percentage])
  const checkPosition = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.positionTopLet
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.positionTopRight
    }

    return classes.positionBotLeft
  }, [index])

  const checkTitlePosition = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.titlePositionLeft
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.titlePositionRight
    }

    return ''
  }, [index])

  const checkPercentBarCard = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.percentBarCardLeft
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.percentBarCardRight
    }

    return ''
  }, [index])

  const checkProcessBar = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.progressBarLeft
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.progressBarRight
    }

    return ''
  }, [index])

  const checkProcessArrow = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.progressArrowLeft
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.progressArrowRight
    }

    return ''
  }, [index])

  const getBorderlineDot = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '1px dotted #FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '1px dotted #008BC9'
    }
    return '#B4B4B4 dotted 1px '
  }, [percentage])

  const getBackGroundColor = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#EE3D3B solid 2px'
    }
    if (Math.floor(percentage) > 70) {
      return '#20A2DC solid 2px'
    }
    return ''
  }, [percentage])

  const getColorText = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return ''
  }, [percentage])

  return (
    <div className={`${checkPosition} ${classes.connectBrainRegionPart2} `}>
      <DescriptionPosition
        text={getTextInRound}
        index={index}
        getColorInRound={getColorInRound}
        headerName={headerName}
        getColorText={getColorText}
        getBorderlineDot={getBorderlineDot}
      />

      <div
        className={`${classes.percentBarCard} ${checkPercentBarCard}`}
        style={{
          border: getBackGroundColor,
        }}
      >
        <div className={classes.topScorePercent} style={{color: getColorText}}>
          <span
            className={classes.topScorePercentScore}
            style={{color: getColorText}}
          >
            {percentage}
          </span>
          {t('IPoints')}
        </div>
        <div
          className={
            index === 3 ? classes.percentBarV2Bot : classes.percentBarV2Top
          }
        >
          <div className={checkProcessArrow}>
            <ProcessArrow index={index} percentage={percentage} />
          </div>

          <div className={checkProcessBar}>
            <ProgressBar index={index} />
          </div>
          <div className={checkTitlePosition}>
            <TitlePosition
              contentTitlePosition={contentTitlePosition}
              index={index}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PercentBarCardV3
