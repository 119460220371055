import {IndividualOrNormativeEEGCodeType} from 'api/analysisApi'
import IndividualEEGFetcher from 'components/Fetcher/IndividualEEGFetcher'
import NormativeEEGFetcher from 'components/Fetcher/NormativeEEGFetcher'
import SubTitle from 'components/Text/SubTitle'
import {
  Channel,
  Channels,
  PowerType,
  PowerTypes,
} from 'constants/AnalysisConstant'
import useQuery from 'hooks/useQuery'
import CsvFetcher from 'pages/Popup/PowerSpectrum/CsvFetcher'
import React, {useEffect, useState} from 'react'
import {mixed, number, object, SchemaOf} from 'yup'
import useStyles from './useStyle'

interface QueryItem {
  id: number
  type: IndividualOrNormativeEEGCodeType
  powerType: PowerType
  channel: Channel
}

const QueryItemScheme: SchemaOf<QueryItem> = object({
  id: number()
    .typeError('IInvalidRequest')
    .required('IInvalidRequest')
    .min(1, 'IInvalidRequest'),
  type: mixed().oneOf(['I', 'N'], 'IInvalidRequest'),
  powerType: mixed().oneOf(PowerTypes as any, 'IInvalidRequest'),
  channel: mixed().oneOf(Channels as any, 'IInvalidRequest'),
})

function PowerSpectrumPopup() {
  const classes = useStyles()

  const id = useQuery().get('id')
  const type = useQuery().get('type')
  const powerType = useQuery().get('power_type')
  const channel = useQuery().get('channel')

  const [error, setError] = useState<string>()
  const [queryItem, setQueryItem] = useState<QueryItem>()

  useEffect(() => {
    const validate = () =>
      QueryItemScheme.validate({
        id,
        type,
        powerType,
        channel,
      })
    validate()
      .then((value) => setQueryItem(value))
      .catch((err) => setError(err.message))
  }, [id, type, channel])

  return (
    <div className={classes.root}>
      <SubTitle>Power Spectrum</SubTitle>
      {error && <div>{error}</div>}
      {!error && queryItem && queryItem.type === 'I' && (
        <IndividualEEGFetcher id={queryItem.id}>
          {(data) => (
            <CsvFetcher
              {...data}
              powerType={queryItem.powerType}
              channel={queryItem.channel}
            />
          )}
        </IndividualEEGFetcher>
      )}
      {!error && queryItem && queryItem.type === 'N' && (
        <NormativeEEGFetcher id={queryItem.id}>
          {(data) => (
            <CsvFetcher
              {...data}
              powerType={queryItem.powerType}
              channel={queryItem.channel}
            />
          )}
        </NormativeEEGFetcher>
      )}
    </div>
  )
}

export default PowerSpectrumPopup
