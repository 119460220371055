/* eslint-disable import/prefer-default-export */
import {ProductType} from 'features/invoice/invoiceSlice'
import i18n from 'i18n'

const getCell = (id: string, key: ProductType) => [
  {
    placeholder: i18n.t('IAmountValue', {value: '0'}),
    name: `${id}_highPrice` as string,
    key,
    required: true,
    value: '',
    width: 50,
  },
  {
    placeholder: i18n.t('ILimitedQuantity'),
    name: `${id}_maxCount` as string,
    key,
    required: true,
    value: '',
    width: 75,
  },
  {
    placeholder: i18n.t('ILimitAmount'),
    name: `${id}_regularPrice` as string,
    key,
    required: true,
    value: '',
    width: 75,
  },
]

const STANDARD_PLAN = {
  label: i18n.t('IStandardPlan'),
  checked: false,
  key: '',
  contentLevelOne: [
    {
      data: {
        label: i18n.t('IGeneralOnly'),
        checked: false,
        key: 'RegularPlan',
        contentLevelTwo: [
          {
            title: i18n.t('IEEGText'),
            rowsContent: [
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmountValue', {value: '0'}),
                        key: 'eegIndv' as ProductType,
                        name: 'eeg_min_individual',
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_individual3D',
                        key: 'eegIndv3d' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_norm',
                        key: 'eegNorm' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetNorm'),
                  },
                ],
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_norm3D',
                        key: 'eegNorm3d' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_summary',
                        key: 'eegSummary' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_summaryAdvanced',
                        key: 'eegSummaryAdv' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'eeg_min_mci',
                        key: 'eegMci' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetMci'),
                  },
                ],
              ],
            ],
          },
          {
            title: i18n.t('IHRVText'),
            rowsContent: [
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'hrvIndv',
                        key: 'hrvIndv' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'hrvSummary',
                        key: 'hrvSummary' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: [
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'hrv_min_summaryAdvanced',
                        key: 'hrvSummaryAdv' as ProductType,
                        required: true,
                        value: '',
                      },
                    ],
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
            ],
          },
        ],
      },
      title: i18n.t('IChargedAccording'),
    },
    {
      data: {
        label: i18n.t('IGeneralByQuantityUsed'),
        checked: false,
        key: 'RegularLimited',
        contentLevelTwo: [
          {
            title: i18n.t('IEEGText'),
            rowsContent: [
              [
                [
                  {
                    input: getCell('eeg_individual', 'eegIndv'),
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_individual3D', 'eegIndv3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCell('eeg_norm', 'eegNorm'),
                    title: i18n.t('IPresetNorm'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_norm3D', 'eegNorm3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_summary', 'eegSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCell('eeg_summaryAdvanced', 'eegSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCell('eeg_mci', 'eegMci'),
                    title: i18n.t('IPresetMci'),
                  },
                ],
              ],
            ],
          },
          {
            title: i18n.t('IHRVText'),
            rowsContent: [
              [
                [
                  {
                    input: getCell('hrv_individual', 'hrvIndv'),
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCell('hrv_summary', 'hrvSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCell('hrv_summaryAdvanced', 'hrvSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
            ],
          },
        ],
      },
      title: i18n.t('IDescriptionChargeAccording'),
    },
  ],
}

const MONTHLY_FLAT_FEE = {
  label: i18n.t('IMonthlyFlatRate'),
  checked: false,
  key: '',
  contentLevelOne: [
    {
      data: {
        label: i18n.t('IMonthlyFeeOnly'),
        checked: false,
        key: 'FlatRateSubscription',
        contentLevelTwo: [
          {
            title: i18n.t('IMonthlyFee'),
            rowsContent: [
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('INameNumber', {value: '1'}),
                        name: 'first_subscription_name',
                        required: true,
                        value: '',
                        width: 70,
                      },
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'first_subscription_price',
                        required: true,
                        value: '',
                      },
                      {
                        placeholder: i18n.t('INameNumber', {value: '2'}),
                        name: 'second_subscription_name',
                        required: true,
                        value: '',
                        width: 70,
                      },
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'second_subscription_price',
                        required: true,
                        value: '',
                      },
                    ],
                    title: '',
                  },
                ],
              ],
            ],
          },
        ],
      },
      title: i18n.t('IDescriptionFixed'),
    },
    {
      data: {
        label: i18n.t('IMonthlyFeeData'),
        checked: false,
        key: 'FlatRateSubscriptionLimited',
        contentLevelTwo: [
          {
            title: i18n.t('IMonthlyFee'),
            rowsContent: [
              [
                [
                  {
                    input: [
                      {
                        placeholder: i18n.t('INameNumber', {value: '1'}),
                        name: 'first_subscription_name',
                        required: true,
                        value: '',
                        width: 70,
                      },
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'first_subscription_price',
                        required: true,
                        value: '',
                      },
                      {
                        placeholder: i18n.t('INameNumber', {value: '2'}),
                        name: 'second_subscription_name',
                        required: true,
                        value: '',
                        width: 70,
                      },
                      {
                        placeholder: i18n.t('IAmount'),
                        name: 'second_subscription_price',
                        required: true,
                        value: '',
                      },
                    ],
                    title: '',
                  },
                ],
              ],
            ],
          },
          {
            title: i18n.t('IEEGText'),
            rowsContent: [
              [
                [
                  {
                    input: getCell('eeg_individual', 'eegIndv'),
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_individual3D', 'eegIndv3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCell('eeg_norm', 'eegNorm'),
                    title: i18n.t('IPresetNorm'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_norm3D', 'eegNorm3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCell('eeg_summary', 'eegSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCell('eeg_summaryAdvanced', 'eegSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCell('eeg_mci', 'eegMci'),
                    title: i18n.t('IPresetMci'),
                  },
                ],
              ],
            ],
          },
          {
            title: i18n.t('IHRVText'),
            rowsContent: [
              [
                [
                  {
                    input: getCell('hrv_individual', 'hrvIndv'),
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCell('hrv_summary', 'hrvSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCell('hrv_summaryAdvanced', 'hrvSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
            ],
          },
        ],
      },
      title: i18n.t('IDescriptionMonthlyEx'),
    },
  ],
}

const getCellMax = (id: string, key: ProductType) => [
  {
    placeholder: i18n.t('IQuantityLess'),
    name: `${id}_1_maxCount`,
    required: true,
    key,
    value: '',
    width: 90,
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_1_price`,
    required: true,
    key,
    value: '',
    width: 90,
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_2_min`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_2_maxCount`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_2_price`,
    required: true,
    key,
    width: 90,
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}4`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_3_maxCount`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_3_price`,
    required: true,
    key,
    width: 90,
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_4_min`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_4_maxCount`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_4_price`,
    required: true,
    key,
    width: 90,
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_5_min`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_5_maxCount`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_5_price`,
    required: true,
    key,
    width: 90,
  },
  {
    placeholder: i18n.t('IQuantity'),
    name: `${id}_6_min`,
    required: true,
    key,
    value: '',
  },
  {
    placeholder: i18n.t('IAmount'),
    name: `${id}_6_price`,
    required: true,
    key,
    width: 90,
  },
]

const RATE_PLAN = {
  label: i18n.t('IRatePlanBySection'),
  checked: false,
  key: 'TieredRatePlan',
  contentLevelOne: [
    {
      data: {
        label: i18n.t('IMonthlyFeeOnly'),
        checked: false,
        key: '',
        contentLevelTwo: [
          {
            title: i18n.t('IEEGText'),
            rowsContent: [
              [
                [
                  {
                    input: getCellMax('eeg_individual', 'eegIndv'),
                    title: i18n.t('IPresetIndividual'),
                  },
                ],
                [
                  {
                    input: getCellMax('eeg_individual3D', 'eegIndv3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCellMax('eeg_norm', 'eegNorm'),
                    title: i18n.t('IPresetNorm'),
                  },
                ],
                [
                  {
                    input: getCellMax('eeg_norm3D', 'eegNorm3d'),
                    title: i18n.t('IPreset3D'),
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCellMax('eeg_summary', 'eegSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCellMax('eeg_summaryAdvanced', 'eegSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
              [
                [
                  {
                    input: getCellMax('eeg_mci', 'eegMci'),
                    title: i18n.t('IPresetMci'),
                  },
                ],
              ],
            ],
          },
          {
            title: i18n.t('IHRVText'),
            rowsContent: [
              [
                [
                  {
                    input: getCellMax('hrv_individual', 'hrvIndv'),
                    title: 'Individual analysis ',
                  },
                ],
              ],
              [
                [
                  {
                    input: [],
                    title: i18n.t('IPresetSummary'),
                  },
                ],
                [
                  {
                    input: getCellMax('hrv_summary', 'hrvSummary'),
                    title: i18n.t('IPresetBas'),
                  },
                  {
                    input: getCellMax('hrv_summaryAdvanced', 'hrvSummaryAdv'),
                    title: i18n.t('IPresetAdv'),
                  },
                ],
              ],
            ],
          },
        ],
      },
      title: '',
    },
  ],
}

const INVOICE_OTHER = [
  {
    keyPackage: 'OnlyDevice',
    description: i18n.t('IDescriptionDifferential'),
    isRadio: true,
    label: i18n.t('IMonthlyDevice'),
    input: {
      placeholder: i18n.t('IAmountValue', {value: '0'}),
      name: 'only_device_price',
      required: true,
      value: '',
      width: 70,
    },
  },
  {
    keyPackage: 'YearSubscription',
    description: i18n.t('IDescriptionSetting'),
    isRadio: true,
    label: i18n.t('IMonthFlatRate'),
    input: {
      placeholder: i18n.t('IAmountValue', {value: '0'}),
      name: 'year_subscription_price',
      required: true,
      value: '',
      width: 70,
    },
  },
  {
    keyPackage: 'eegSummarySurcharge',
    description: i18n.t('IDescriptionSettingOrg'),
    isRadio: false,
    label: i18n.t('IReportApplicable'),
    input: {
      placeholder: '%',
      name: 'eeg_summary_surcharge',
      required: true,
      value: '',
      width: 70,
    },
  },
  {
    keyPackage: 'discount',
    description: i18n.t('IDescriptionSeparate'),
    isRadio: false,
    label: i18n.t('IRevenueSharing'),
    input: {
      placeholder: '%',
      name: 'discount',
      required: true,
      value: '',
      width: 70,
    },
  },
  {
    keyPackage: '55555',
    description: i18n.t('IDescriptionSeparateDiscount'),
    isRadio: false,
    label: i18n.t('IRevenueSharing'),
    input: {
      placeholder: '%',
      name: 'standardGeneralIndividualAnalysis',
      required: true,
      value: '',
      width: 70,
    },
  },
]

const STATUS_INVOICE = [
  {
    name: i18n.t('IWriting'),
    id: 1,
  },
  {
    name: i18n.t('ICrewInvitedUser'),
    id: 2,
  },
]

const NEW_TEMPLATE_SINGLE = [
  {
    minCount: 1,
    maxCount: null,
    price: 0,
  },
  {
    minCount: 1,
    maxCount: null,
    price: 0,
  },
]

const NEW_TEMPLATE_GROUP = [
  {
    minCount: 1,
    maxCount: null,
    price: null,
  },
  {
    minCount: null,
    maxCount: null,
    price: null,
  },
  {
    minCount: null,
    maxCount: null,
    price: null,
  },
  {
    minCount: null,
    maxCount: null,
    price: null,
  },
  {
    minCount: null,
    maxCount: null,
    price: null,
  },
  {
    minCount: null,
    maxCount: null,
    price: null,
  },
]

const ID_NEW_INVOICE = 9999999

export {
  STANDARD_PLAN,
  MONTHLY_FLAT_FEE,
  RATE_PLAN,
  INVOICE_OTHER,
  STATUS_INVOICE,
  NEW_TEMPLATE_SINGLE,
  NEW_TEMPLATE_GROUP,
  ID_NEW_INVOICE,
}
