import React, {useEffect, useState} from 'react'
import {
  StyledBrainContainer,
  StyledBrainImgContainer,
  StyledBrainImgInwrap,
  StyledTextImgWrapLeft,
  StyledTextImgWrapRight,
  StyledTherapyPointWrap,
  StyledTextReadInfoContainer,
  StyledTextReadInfoName,
  StyledTextReadInfoValue,
  StyledTextSpan,
  StyledBrainImgContainerDim,
  StyledBrainImgInwrapDim,
  StyledNoticeTextSpan,
  StyledTherapyPointCheckbox,
} from 'components/V2Dialog/TherapyDialog/Style'
import useAuth from 'features/auth/useAuth'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import {useTranslation} from 'react-i18next'
import therapyBrain from 'assets/img/therapy_brain.png'
import therapyBrainDim from 'assets/img/therapy_brain_fake.png'
import textLeft from 'assets/img/text_left.png'
import textRight from 'assets/img/text_right.png'
import {useReadDialog, useUpdateDialog} from 'features/therapy/useTherapyDialog'
import CommonDialog from 'components/V2Dialog/Templates/CommonDialog/CommonDialog'
import {StyledButtonGroup} from 'components/V2Modal/Modal/Modal'
import NormalButton from 'components/V2Buttons/NormalButton/NormalButton'
import useTherapy from 'features/therapy/useTherapy'
import {
  SecondaryButtonStyles,
  PrimaryButtonStyles,
} from 'components/V2Buttons/NormalButton/Styles'
import {SmallWidthModalStyles} from 'components/V2Modal/Modal/Styles'
import useTherapyDialogViewModels from '../TherapyDialogViewModels'

interface TherapyId {
  therapyId: string
  onFetchPbmId: (pbmId: string) => void
}

const TherapyReadDialog = ({therapyId, onFetchPbmId}: TherapyId) => {
  const {t} = useTranslation()

  const {user: currentUser} = useAuth()
  const {onOpen: onTherapyEditDialogOpen} = useUpdateDialog()
  const {setTherapyPointLoading, makeTimeText} = useTherapyDialogViewModels()

  const [pbmId, setPbmId] = useState('')

  const {
    open,
    reservation: reservationList,
    onClose: handleClose,
    loading: readLoading,
    uuid,
  } = useReadDialog()
  const {
    customerId,
    setCustomerId: setTherapyCustomerId,
    therapyPresetLists,
    electrodeData,
  } = useTherapy()

  const handleEditClick = () => {
    if (isDefined(therapyId)) {
      handleClose()
      onTherapyEditDialogOpen(therapyId)
      onFetchPbmId(pbmId)
    }
  }

  const isTherapyPreset = (): boolean => {
    if (
      therapyPresetLists &&
      therapyPresetLists?.list &&
      therapyPresetLists?.list.length > 0 &&
      electrodeData?.electrode
    )
      return true

    return false
  }

  useEffect(() => {
    if (open && isDefined(therapyId)) {
      setTherapyPointLoading(true)
      setTherapyCustomerId(therapyId)
    }
    if (isTherapyPreset()) {
      setPbmId(therapyPresetLists?.list[0].id ?? '')
    }
  }, [open, therapyId])

  useEffect(() => {
    onFetchPbmId(pbmId)
  }, [pbmId])

  const emptyTherapy = (
    <StyledBrainContainer>
      <StyledBrainImgContainerDim>
        <StyledBrainImgInwrapDim>
          <img src={therapyBrainDim} alt='therapy_brain_dim' />
          <StyledNoticeTextSpan>{t('INoTherapy')}</StyledNoticeTextSpan>
        </StyledBrainImgInwrapDim>
      </StyledBrainImgContainerDim>
    </StyledBrainContainer>
  )
  const body = (
    <>
      {therapyPresetLists &&
        therapyPresetLists?.list &&
        therapyPresetLists?.list.length > 0 &&
        electrodeData?.electrode && (
          <StyledBrainContainer>
            <StyledBrainImgContainer>
              <StyledBrainImgInwrap>
                <img src={therapyBrain} alt='therapy_brain' />
                <StyledTherapyPointWrap>
                  {Object.entries(electrodeData?.electrode).map(
                    ([key, value]) => {
                      return (
                        <StyledTherapyPointCheckbox
                          key={key}
                          type='checkbox'
                          name={key}
                          data-idx={key}
                          className={`${key} therapyPointRecent`}
                          checked={false}
                          disabled
                        />
                      )
                    },
                  )}
                </StyledTherapyPointWrap>
                <StyledTextImgWrapLeft>
                  <img src={textLeft} alt='text_left' />
                </StyledTextImgWrapLeft>
                <StyledTextImgWrapRight>
                  <img src={textRight} alt='text_right' />
                </StyledTextImgWrapRight>
              </StyledBrainImgInwrap>
            </StyledBrainImgContainer>
            <StyledTextReadInfoContainer>
              <StyledTextReadInfoName>
                <span>{t('IFrequency')}</span>
                <span>{t('IRunningTime')}</span>
              </StyledTextReadInfoName>
              <StyledTextReadInfoValue>
                <StyledTextSpan>{electrodeData?.frequency}Hz</StyledTextSpan>
                <StyledTextSpan>
                  {makeTimeText(electrodeData?.duration, t)}
                </StyledTextSpan>
              </StyledTextReadInfoValue>
            </StyledTextReadInfoContainer>
          </StyledBrainContainer>
        )}
    </>
  )

  const footer = (
    <StyledButtonGroup>
      <NormalButton
        theme={SecondaryButtonStyles}
        title={t('ICancel')}
        event={() => handleClose()}
        disabled={false}
      />
      {isTherapyPreset() ? (
        <NormalButton
          theme={PrimaryButtonStyles}
          title={t('IEdit')}
          event={handleEditClick}
          disabled={false}
        />
      ) : (
        <NormalButton
          theme={PrimaryButtonStyles}
          title={t('IAdd')}
          event={handleEditClick}
          disabled={false}
        />
      )}
    </StyledButtonGroup>
  )

  return (
    <CommonDialog
      open={open}
      modalTitle={t('ITherapyReservationTitle')}
      handleClose={handleClose}
      theme={SmallWidthModalStyles}
      body={isTherapyPreset() ? body : emptyTherapy}
      footer={footer}
      style={{
        opacity: !open ? 0 : 1,
        userSelect: !open ? 'none' : 'auto',
      }}
    />
  )
}

export default TherapyReadDialog
