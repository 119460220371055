import {TableRow, TableCell} from '@material-ui/core'
import useTableStyles from 'components/Table/useTableStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  loading: boolean
  error: any
  isEmpty: boolean
  emptyRows: number
}

export default function TableEmpty({
  loading,
  error,
  isEmpty,
  emptyRows,
}: Props) {
  const {t} = useTranslation()
  const classes = useTableStyles()

  if (loading) return null
  if (!loading && error) return null
  if (!isEmpty) return null

  return (
    <TableRow style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}>
      <TableCell colSpan={12}>
        <div className={classes.empty}>{t('ISearchResultEmpty')}</div>
      </TableCell>
    </TableRow>
  )
}
