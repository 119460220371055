import {getFeatures} from 'api/storageApi'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'

export const Roles = {
  ROLE_ORG_ADMIN: 'ROLE_ORG_ADMIN',
  ROLE_ORG_RESEARCHER: 'ROLE_ORG_RESEARCHER',
  ROLE_ORG_USER: 'ROLE_ORG_USER',

  ROLE_SHAREWARE_ADMIN: 'ROLE_SHAREWARE_ADMIN',
  ROLE_SHAREWARE_RESEARCHER: 'ROLE_SHAREWARE_RESEARCHER',
  ROLE_SHAREWARE_USER: 'ROLE_SHAREWARE_USER',

  ROLE_COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
  ROLE_COMPANY_CS: 'ROLE_COMPANY_CS',
  ROLE_COMPANY_SALES: 'ROLE_COMPANY_SALES',
  ROLE_COMPANY_RESEARCHER: 'ROLE_COMPANY_RESEARCHER',
  ROLE_COMPANY_USER: 'ROLE_COMPANY_USER',
  ROLE_COMPANY_MASTER: 'ROLE_COMPANY_MASTER',

  ROLE_UNKNOWN: 'UNKNOWN',
} as const

export type Role = (typeof Roles)[keyof typeof Roles]

export const AllUserRoles: Role[] = Object.values(Roles)

export const CompanyRoles: Role[] = [
  Roles.ROLE_COMPANY_ADMIN,
  Roles.ROLE_COMPANY_CS,
  Roles.ROLE_COMPANY_SALES,
  Roles.ROLE_COMPANY_RESEARCHER,
  Roles.ROLE_COMPANY_USER,
]

export const OrgRoles: Role[] = [
  Roles.ROLE_ORG_ADMIN,
  Roles.ROLE_ORG_RESEARCHER,
  Roles.ROLE_ORG_USER,
]

export const ShareWareRoles: Role[] = [
  Roles.ROLE_SHAREWARE_ADMIN,
  Roles.ROLE_SHAREWARE_RESEARCHER,
  Roles.ROLE_SHAREWARE_USER,
]

export const isCompanyAuthority = (authority: Role | undefined) =>
  CompanyRoles.some((role) => role === authority)

export const isCs = (authority: Role | undefined) =>
  authority === Roles.ROLE_COMPANY_CS

const isSales = (authority: Role | undefined) =>
  authority === Roles.ROLE_COMPANY_SALES

export const isCSOrSalesAuthority = (authority: Role | undefined) =>
  isCs(authority) || isSales(authority)

export const isOrgAuthority = (authority: Role | undefined) =>
  OrgRoles.some((role) => role === authority) ||
  ShareWareRoles.some((role) => role === authority)

export const ismasterOrgAdmin = (authority: Role | undefined) =>
  Roles.ROLE_ORG_ADMIN === authority

export const isOrgAdmin = (authority: Role | undefined) =>
  Roles.ROLE_SHAREWARE_ADMIN === authority || Roles.ROLE_ORG_ADMIN === authority

export const isOrgUser = (authority: Role | undefined) =>
  Roles.ROLE_SHAREWARE_USER === authority || Roles.ROLE_ORG_USER === authority

export const isAdmin = (authority: Role | undefined) =>
  Roles.ROLE_COMPANY_ADMIN === authority ||
  Roles.ROLE_SHAREWARE_ADMIN === authority ||
  Roles.ROLE_ORG_ADMIN === authority

export const isUser = (authority: Role | undefined) =>
  Roles.ROLE_COMPANY_USER === authority ||
  Roles.ROLE_COMPANY_RESEARCHER === authority ||
  Roles.ROLE_SHAREWARE_USER === authority ||
  Roles.ROLE_ORG_USER === authority

export const isOrgAndCompanyUser = (authority: Role | undefined) =>
  Roles.ROLE_ORG_USER === authority || Roles.ROLE_COMPANY_USER === authority

const IMEDISYNC_DOMAIN = '@imedisync.com'
export const isImedisyncCS = (authority: Role | undefined, email: string) =>
  isCs(authority) && email?.includes(IMEDISYNC_DOMAIN)

export const isOrganizationHealth = (
  organizationType: string | undefined | null,
) => organizationType && organizationType === 'HEALTH'

export const isOrganizationWhiteList = (
  organizationType: string | undefined | null,
) =>
  organizationType === 'HEALTH' &&
  TYPE_WHITE_LIST.every((item) =>
    JSON.parse(getFeatures() ?? '[]').includes(item),
  )
