import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  countHitsNoticeApi,
  createNoticeApi,
  deleteNoticeApi,
  fetchNoticeDetailInfoApi,
  fetchNoticeListApi,
  NoticeCreateInfoRequest,
  NoticeDetailInfoRequest,
  NoticeEditInfoRequest,
  NoticeListRequest,
  updateNoticeApi,
} from 'api/noticeApi'
import {navigate} from 'AppHistory'
import {getNoticeListPath} from 'constants/RouteConstant'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const fetchNoticePaging = createAsyncThunk(
  'api/notice/paging',
  async (payload: NoticeListRequest, {rejectWithValue}) => {
    try {
      const response = await fetchNoticeListApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchNoticeRead = createAsyncThunk(
  'api/notice/read',
  async (payload: NoticeDetailInfoRequest, {rejectWithValue}) => {
    try {
      const response = await fetchNoticeDetailInfoApi(payload)
      await countHitsNoticeApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchNoticeDelete = createAsyncThunk(
  'api/notice/delete',
  async (payload: NoticeDetailInfoRequest, {dispatch}) => {
    try {
      await deleteNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      navigate(getNoticeListPath())
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
    }
  },
)

export const fetchNoticeUpdate = createAsyncThunk(
  'api/notice/update',
  async (payload: NoticeEditInfoRequest, {dispatch}) => {
    try {
      await updateNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      navigate(getNoticeListPath())
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
    }
  },
)

export const fetchNoticeCreate = createAsyncThunk(
  'api/notice/create',
  async (payload: NoticeCreateInfoRequest, {dispatch}) => {
    try {
      await createNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      navigate(getNoticeListPath())
    } catch (err) {
      // dispatch(openFailureModalAction(err.message))
    }
  },
)

export interface NoticeState {
  loading: boolean
  error: any
  noticePage?: NoticePageItem[]
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  unpaged: boolean
  noticeData?: NoticeDetailInfo | null
}

export const initialState: NoticeState = {
  loading: false,
  error: null,
  noticePage: [],
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 0,
  noticeData: null,
  unpaged: true,
}

export const noticeSlice = createSlice({
  name: 'noticeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNoticePaging.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchNoticePaging.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.noticePage = action.payload.content
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.pageIndex = action.payload.pageable.pageNumber
      state.itemPerPage = action.payload.pageable.pageSize
      state.unpaged = action.payload.pageable.unpaged
    })
    builder.addCase(fetchNoticePaging.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchNoticeRead.pending, (state) => {
      state.loading = true
      state.error = null
      state.noticeData = null
    })
    builder.addCase(fetchNoticeRead.fulfilled, (state, action) => {
      state.loading = false
      state.noticeData = action.payload
    })
    builder.addCase(fetchNoticeRead.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectLoading = (state: RootState) => state.notice.loading
export const selectError = (state: RootState) => state.notice.error
export const selectNoticePage = (state: RootState) => state.notice.noticePage
export const selectNoticeData = (state: RootState) => state.notice.noticeData
export const selectPaging = (state: RootState) => ({
  page: state.notice.pageIndex,
  size: state.notice.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.notice.totalPages,
  totalElements: state.notice.totalElements,
})

export default noticeSlice.reducer
