import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {fetchProductInfoListApi} from 'api/pointApi'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import useAuth from 'features/auth/useAuth'
import {
  closePointDetailDialog,
  selectPointDetailDialogOpen,
} from 'features/modal/modalSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      minWidth: 750,
      borderRadius: 5,
      border: '1px solid #D6D6D8',
      overflow: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      height: 50,
      backgroundColor: '#e9ecef',
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
    hidden: {
      visibility: 'hidden',
    },
    hospital: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 10,
    },
    hospitalName: {
      fontWeight: 'bolder',
      color: '#555555',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}

const headCells: HeadCell[] = [
  {id: 'division', label: 'IDivision'},
  {id: 'serviceTypes', label: 'IAnalysisServiceType'},
  {id: 'serviceContent', label: 'IAnalysisServiceContent'},
  {id: 'point', label: 'IPointUsed'},
]

function PointDetailItem({
  item,
  isFirstChild,
  rowSpan,
}: {
  item: ProductInfo
  isFirstChild: boolean
  rowSpan: number
}) {
  return (
    <TableRow>
      {isFirstChild && (
        <TableCell align='center' rowSpan={rowSpan}>
          {item.serviceType}
        </TableCell>
      )}
      <TableCell align='center'>{item.serviceName}</TableCell>
      <TableCell align='center'>{item.description}</TableCell>
      <TableCell align='center'>{item.point}</TableCell>
    </TableRow>
  )
}

function PointDetailTableRows({items}: {items: ProductInfo[]}) {
  return (
    <>
      {items.map((item, idx) => (
        <PointDetailItem
          item={item}
          isFirstChild={idx === 0}
          rowSpan={items.length}
          key={idx}
        />
      ))}
    </>
  )
}

function PointDetailTable() {
  const classes = useTableStyles()
  const {t} = useTranslation()
  const {user: currentUser} = useAuth()
  const [productItems, setProductItems] = React.useState<
    Record<string, ProductInfo[]>
  >({})

  function getServiceTypeArray(originArray: ProductInfo[]): string[] {
    // 중복제거를 위해 set을 사용
    // 일반 배열에 push 하면 중복 체크하는 로직을 또 넣어야 함
    const serviceTypes = originArray.reduce((accr, curr) => {
      accr.add(curr.serviceType)
      return accr
    }, new Set<string>())
    return Array.from(serviceTypes)
  }

  const fetchProductList = async function fetchProductList() {
    const response = await fetchProductInfoListApi({
      uid: currentUser?.uid ?? '',
    })
    const serviceTypeArray = getServiceTypeArray(response.list)

    const tempMap: Record<string, ProductInfo[]> = serviceTypeArray.reduce(
      (accr, curr) => {
        // @ts-ignore
        accr[curr] = response.list.filter((item) => item.serviceType === curr)
        return accr
      },
      {},
    )
    setProductItems(tempMap)
  }

  useEffect(() => {
    fetchProductList()
  }, [])

  return (
    <Table
      className={classes.table}
      aria-labelledby='tableTitle'
      aria-label='enhanced table'
      size='medium'
    >
      <TableHead>
        <TableRow className={classes.tableHeader}>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align='center' padding='none'>
              <Typography>{t(headCell.label)}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.entries(productItems).map(([key, value]) => (
          <PointDetailTableRows key={key} items={value} />
        ))}
      </TableBody>
    </Table>
  )
}

const PointDetailDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectPointDetailDialogOpen)
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(closePointDetailDialog())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth='md'
    >
      <Card className={classes.cardWrap}>
        {/* 상단 타이틀 컨테이너  */}
        <div className={classes.longTitleContainer}>
          <div className={classes.containerTitle}>
            <div className={classes.closeButtonWrap}>
              <IconButton
                color='secondary'
                aria-label='favorite'
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <Typography variant='h6' className={classes.title}>
              {t('IPointProductInfo')}
            </Typography>
          </div>
        </div>
        <Divider className={classes.titleDivider} />
        <Typography className={classes.padding} />
        <div className={classes.scroll}>
          <PointDetailTable />
        </div>
        <Typography className={classes.padding} />
        <div className={classes.buttonContainerBgColor}>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleClose}
              className={classes.okButtonColor}
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default PointDetailDialog
