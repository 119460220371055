import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Spacing} from 'components/common/useBaseStyle'
import useHealthCenterStyles from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Style'
import {Typography} from '@material-ui/core'
import mark from 'stories/assets/mark.svg'
import warning from 'stories/assets/warning.svg'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import {FiveFeaturesScore, Frequency} from 'api/healthCareApi'
import PercentChangeBarCard from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Elements/percentChangeBar'
import NotificationCard from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Elements/notificationCard'

export const PageWrap = styled.div`
  background: white;
`
export const PageContent = styled.div`
  width: 1160px;
`

export const PageCard = styled.div`
  width: 100%;
  margin-bottom: 60px;
`
export const HeaderCard = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px solid #00a29c;
`
export const HeaderCardNumber = styled.div`
  background-color: #2e4d6f;
  color: white;
  background-color: #2e4d6f;
  width: 42px;
  height: 42px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px;
`

export const HeaderCardTitle = styled.div`
  font-size: 20px;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`

export const HeaderCardTitleGreen = styled.div`
  font-size: 18px;
  font: 'Noto Sans KR';
  font-weight: 500;
  line-height: 18px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: #00a29c;
  color: white;
  padding: 10px 24px 12px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const Line = styled.div`
  height: 1px;
  background-color: #2e4d6f;
  width: 1150px;
`

export const EmptyBox = styled.div`
  width: 10px;
`

export const ReportCustomPadding = styled.div`
  padding: ${Spacing.report.reportCustomPadding}px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`

export interface HealthCenterMajorProps {
  fiveFeaturesRadarImage: string
  fiveFeaturesScore: FiveFeaturesScore
}

function HealthCenterMajorPage({
  fiveFeaturesRadarImage,
  fiveFeaturesScore,
}: HealthCenterMajorProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterStyles()
  const getTextAnalysis = (
    percent: number,
    contentGood: string,
    contentNormal: string,
    contentBad: string,
  ) => {
    const value = !Number.isNaN(Number(percent))
      ? Math.floor(Number(percent))
      : 0
    if (value < 30) return contentGood
    if (value > 70) return contentBad
    return contentNormal
  }

  const getIconAnalysis = (percent: number, color?: boolean) => {
    const value = !Number.isNaN(Number(percent))
      ? Math.floor(Number(percent))
      : 0
    if (color && value < 30) return warning
    if (value > 70) return warning
    return mark
  }

  const getBackgroundColorAnalysis = (percent: number, colorBlue?: boolean) => {
    const value = !Number.isNaN(Number(percent))
      ? Math.floor(Number(percent))
      : 0
    if (colorBlue && value < 30) return '#fef0f0'
    if (value > 70) return '#fef0f0'
    return 'white'
  }

  const getTextColor = (percent: number, colorBlue?: boolean) => {
    const value = !Number.isNaN(Number(percent))
      ? Math.floor(Number(percent))
      : 0
    if (colorBlue && value < 30) return 'red'
    if (value > 70) return 'red'
    return '#000000'
  }
  const dataNotification = [
    {
      color: getTextColor(fiveFeaturesScore?.sadness),
      iconName: getIconAnalysis(fiveFeaturesScore?.sadness),
      headerName: t('IDepression'),
      content: getTextAnalysis(
        fiveFeaturesScore?.sadness,
        t('IBrainPatterns'),
        t('IBrainPatternsNormal'),
        t('IBrainPatternsBad'),
      ),
      childContent: getTextAnalysis(
        fiveFeaturesScore?.sadness,
        t('ISatisfaction'),
        t('ISatisfactionNormal'),
        t('ISatisfactionBad'),
      ),
      backgroundColor: getBackgroundColorAnalysis(fiveFeaturesScore?.sadness),
    },
    {
      color: getTextColor(fiveFeaturesScore?.tension),
      iconName: getIconAnalysis(fiveFeaturesScore?.tension),
      headerName: t('IAnxiety'),
      content: getTextAnalysis(
        fiveFeaturesScore?.tension,
        t('IStressRelated'),
        t('IStressRelatedNormal'),
        t('IStressRelatedBad'),
      ),
      childContent: getTextAnalysis(
        fiveFeaturesScore?.tension,
        t('IRecommendedToManage'),
        t('IRecommendedToManageNormal'),
        t('IRecommendedToManageBad'),
      ),
      backgroundColor: getBackgroundColorAnalysis(fiveFeaturesScore?.tension),
    },
    {
      color: getTextColor(fiveFeaturesScore?.stress),
      iconName: getIconAnalysis(fiveFeaturesScore?.stress),
      headerName: t('IStress'),
      content: getTextAnalysis(
        fiveFeaturesScore?.stress,
        t('IAnxietyRelated'),
        t('IAnxietyRelatedNormal'),
        t('IAnxietyRelatedBad'),
      ),
      childContent: getTextAnalysis(
        fiveFeaturesScore?.stress,
        t('IRecommendedToMaintain'),
        t('IRecommendedToMaintainNormal'),
        t('IRecommendedToMaintainBad'),
      ),
      backgroundColor: getBackgroundColorAnalysis(fiveFeaturesScore?.stress),
    },
    {
      color: getTextColor(fiveFeaturesScore?.dominant_frequency?.score, true),
      iconName: getIconAnalysis(
        fiveFeaturesScore?.dominant_frequency?.score,
        true,
      ),
      headerName: t('IBrainWaveSpeed'),
      content: getTextAnalysis(
        fiveFeaturesScore?.dominant_frequency?.score,
        t('IVibrantBalanced'),
        t('IVibrantBalancedNormal'),
        t('IVibrantBalancedBad'),
      ),
      childContent: getTextAnalysis(
        fiveFeaturesScore?.dominant_frequency?.score,
        t('IExcellentAbility'),
        t('IExcellentAbilityNormal'),
        t('IExcellentAbilityBad'),
      ),
      backgroundColor: getBackgroundColorAnalysis(
        fiveFeaturesScore?.dominant_frequency?.score,
        true,
      ),
    },
    {
      color: getTextColor(fiveFeaturesScore?.inattention?.score),
      iconName: getIconAnalysis(fiveFeaturesScore?.inattention?.score),
      headerName: t('IAttentionDeficit'),
      content: getTextAnalysis(
        fiveFeaturesScore?.inattention?.score,
        t('IRelatedToCognitive'),
        t('IRelatedToCognitiveNormal'),
        t('IRelatedToCognitiveBad'),
      ),
      childContent: getTextAnalysis(
        fiveFeaturesScore?.inattention?.score,
        t('IUnderstandAndProcess'),
        t('IUnderstandAndProcessNormal'),
        t('IUnderstandAndProcessBad'),
      ),
      backgroundColor: getBackgroundColorAnalysis(
        fiveFeaturesScore?.inattention?.score,
      ),
    },
  ]

  // const dataPercentNotification = [
  //   {
  //     headerName: t('IDepressionNotification'),
  //     headerNote: '(Sadness)',
  //     backgroundColorCard: 'white',
  //     contentRound: {
  //       value: '80',
  //       status: t('IAverage'),
  //     },
  //     headerContent: t('INotShowSpecific'),
  //     content: t('IJoyAndSatisfaction'),
  //     percentage: 30,
  //     textColor: '#000000',
  //     colorPercentage: '#b4b4b4',
  //   },
  //   {
  //     headerName: t('IDepressionNotification'),
  //     headerNote: '(Sadness)',
  //     backgroundColorCard: '#fef0f0',
  //     contentRound: {
  //       value: '97',
  //       status: t('IAverage'),
  //     },
  //     headerContent: t('IStressRelated'),
  //     content: t('IRecommendedToManage'),
  //     percentage: 30,
  //     textColor: 'red',
  //     colorPercentage: '#ee3d3b',
  //   },
  //   {
  //     headerName: t('IStressSpace'),
  //     headerNote: '(Stress)',
  //     backgroundColorCard: '#fef0f0',
  //     contentRound: {
  //       value: '97',
  //       status: t('IPointsBad'),
  //     },
  //     headerContent: t('IAnxietyRelated'),
  //     content: t('IRecommendedToMaintain'),
  //     percentage: 30,
  //     textColor: 'red',
  //     colorPercentage: '#ee3d3b',
  //   },
  //   {
  //     headerName: t('IBrainWaveSpeedSpace'),
  //     headerNote: '(dominant frequency)',
  //     backgroundColorCard: 'white',
  //     contentRound: {
  //       value: '45',
  //       frequency: '(9.5Hz)',
  //       status: t('IAverage'),
  //     },
  //     headerContent: t('IActivityAssociated'),
  //     content: t('IExcellentAbility'),
  //     percentage: 30,
  //     textColor: '#000000',
  //     colorPercentage: '#b4b4b4',
  //   },
  //   {
  //     headerName: t('IAttentionDeficit'),
  //     headerNote: '(inattention)',
  //     backgroundColorCard: 'white',
  //     contentRound: {
  //       value: '45',
  //       frequency: '(2.4)',
  //       status: t('IAverage'),
  //     },
  //     headerContent: t('ICognitiveFunction'),
  //     content: t('IProcessInformation'),
  //     percentage: 30,
  //     textColor: '#000000',
  //     colorPercentage: '#b4b4b4',
  //   },
  // ]

  const formatColorLeft = (value: number | string) => {
    if (Math.floor(Number(value)) > 70) return '#ee3d3b'
    return '#B4B4B4'
  }

  const formatColorRight = (value: number | string) => {
    if (Math.floor(Number(value)) < 30) return '#ee3d3b'
    if (Math.floor(Number(value)) > 70) return '#ee3d3b'
    return '#B4B4B4'
  }

  // Special text for typeStatus = 3 (Speed), typeStatus = 1(good and bad), typeStatus = 2 (normal and good)
  const getTextInRound = (value: number, typeStatus: number) => {
    if (typeStatus === 3) {
      if (Math.floor(Number(value)) > 70) return t('ISpeed')
      if (Math.floor(Number(value)) < 30) return t('ISlow')
      return t('ICommonly')
    }
    return Math.floor(Number(value)) > 70 ? t('IBad') : t('INormal')
  }

  const getNumber = (value: number) => {
    if (!Number.isNaN(Number(value))) return value
    return 0
  }

  // typeStatus = 1 show text 좋음(X) 나쁨(O) and typeStatus != 1 show text 좋음(X) 정상(O)
  const formatTextRound = (
    frequency: Frequency,
    type: number,
    typeStatus: number,
  ) => {
    if (type === 1)
      return {
        value: Math.floor(getNumber(frequency?.score)),
        frequency: `(${getNumber(frequency?.value)}Hz)`,
        status: getTextInRound(getNumber(frequency?.score), typeStatus),
      }
    return {
      value: Math.floor(getNumber(frequency?.score)),
      frequency: `(${getNumber(frequency?.value)})`,
      status: getTextInRound(getNumber(frequency?.score), typeStatus),
    }
  }

  const formatTextRoundLeft = (
    frequency: number,
    type: number,
    typeStatus: number,
  ) => {
    return {
      value: Math.floor(getNumber(frequency)),
      status: getTextInRound(getNumber(frequency), typeStatus),
    }
  }

  return (
    <div className={classes.fiveMajorIndicators}>
      <PageWrap>
        <PageContent>
          <WrapElement number={1} text={t('IAnalysisIndicators')}>
            <CardContent>
              <FlexBox>
                <div className={classes.imageChart}>
                  <img src={fiveFeaturesRadarImage} alt='RadarImage' />
                </div>
                <div className={classes.textChart}>
                  <div>
                    <div style={{width: 606}}>
                      <div className={classes.textWeight}>
                        <p className={classes.textThroughAI}>
                          {t('IThroughAI')}
                        </p>
                        <p className={classes.textResultsOfPersonal}>
                          {t('IResultsOfPersonal')}
                        </p>
                      </div>
                      <Typography className={classes.padding} />
                      <div>
                        <p className={classes.notificationChildContent}>
                          {t('IComprehensively')}
                        </p>
                      </div>
                    </div>
                    <Typography className={classes.padding} />
                    <Typography className={classes.padding} />
                    <div className={classes.imageDescription}>
                      <Typography className={classes.padding} />
                      <ul className={classes.list}>
                        <li>
                          <p className={classes.textList}>
                            {t('IDeficitBetter')}
                          </p>
                        </li>
                        <li>
                          <p className={classes.textList}>
                            {t('IScoresIndicate')}
                          </p>
                        </li>
                        <li>
                          <p className={classes.textList}>
                            {t('IFindingsAppear')}
                          </p>
                        </li>
                        <li>
                          <p className={classes.textList}>
                            {t('IReMeasurement')}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <WrapElement number={2} text={t('IEvaluationKey')}>
            <CardContent>
              <FlexBox>
                <div className={classes.pullUpContent}>
                  <PercentChangeBarCard
                    headerName={t('IDepressionNotification')}
                    backgroundColor={formatColorLeft(
                      fiveFeaturesScore?.sadness,
                    )}
                    textRound={formatTextRoundLeft(
                      fiveFeaturesScore?.sadness,
                      2,
                      1,
                    )}
                    percentage={fiveFeaturesScore?.sadness ?? 0}
                    index={1}
                    top={t('IGood')}
                    middle={t('ICommonly')}
                    bottom={t('IBad')}
                  />

                  <PercentChangeBarCard
                    headerName={t('IAnxiety')}
                    backgroundColor={formatColorLeft(
                      fiveFeaturesScore?.tension,
                    )}
                    textRound={formatTextRoundLeft(
                      fiveFeaturesScore?.tension,
                      2,
                      2,
                    )}
                    percentage={fiveFeaturesScore?.tension ?? 0}
                    index={2}
                    top={t('IGood')}
                    middle={t('ICommonly')}
                    bottom={t('IBad')}
                  />

                  <PercentChangeBarCard
                    headerName={t('IStress')}
                    backgroundColor={formatColorLeft(fiveFeaturesScore?.stress)}
                    textRound={formatTextRoundLeft(
                      fiveFeaturesScore?.stress,
                      2,
                      2,
                    )}
                    percentage={fiveFeaturesScore?.stress ?? 0}
                    index={3}
                    top={t('IGood')}
                    middle={t('ICommonly')}
                    bottom={t('IBad')}
                  />
                </div>
                <div className={classes.paddingLeft40}>
                  <PercentChangeBarCard
                    headerName={t('IBrainWaveSpeed')}
                    backgroundColor={formatColorRight(
                      getNumber(fiveFeaturesScore?.dominant_frequency?.score)
                        ? fiveFeaturesScore?.dominant_frequency?.score
                        : 0,
                    )}
                    textRound={formatTextRound(
                      fiveFeaturesScore?.dominant_frequency,
                      1,
                      3,
                    )}
                    percentage={
                      getNumber(fiveFeaturesScore?.dominant_frequency?.score) ??
                      0
                    }
                    index={4}
                    top={t('ISlow')}
                    middle={t('ICommonly')}
                    bottom={t('ISpeed')}
                  />

                  <PercentChangeBarCard
                    headerName={t('IAttentionDeficit')}
                    backgroundColor={formatColorLeft(
                      getNumber(fiveFeaturesScore?.inattention?.score)
                        ? fiveFeaturesScore?.inattention?.score
                        : 0,
                    )}
                    textRound={formatTextRound(
                      fiveFeaturesScore?.inattention,
                      2,
                      2,
                    )}
                    percentage={
                      getNumber(fiveFeaturesScore?.inattention?.score) ??
                      getNumber(
                        fiveFeaturesScore?.inattention as unknown as number,
                      )
                    }
                    index={5}
                    top={t('IGood')}
                    middle={t('ICommonly')}
                    bottom={t('IBad')}
                  />
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <PageCard>
            <HeaderCard>
              <HeaderCardTitleGreen>{t('IAnalysis')}</HeaderCardTitleGreen>
            </HeaderCard>

            {dataNotification.map((item, index) => (
              <NotificationCard
                key={index}
                color={item.color}
                iconName={item.iconName}
                headerName={item.headerName}
                content={item.content}
                childContent={item.childContent}
                backgroundColor={item.backgroundColor}
                index={index + 1}
              />
            ))}
          </PageCard>

          {/* <WrapElement number={2} text={t('IEvaluationKey')}>
            <CardContent>
              {dataPercentNotification.map((item, index) => (
                <PercentNotificationCard
                  headerName={item.headerName}
                  headerNote={item.headerNote}
                  backgroundColorCard={item.backgroundColorCard}
                  contentRound={item.contentRound}
                  headerContent={item.headerContent}
                  content={item.content}
                  percentage={item.percentage}
                  textColor={item.textColor}
                  colorPercentage={item.colorPercentage}
                  index={index + 1}
                />
              ))}
            </CardContent>
          </WrapElement> */}
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default HealthCenterMajorPage
