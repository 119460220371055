import {makeStyles, Theme} from '@material-ui/core/styles'
import CardWithTitle from 'components/atoms/CardWithTitle'
import React from 'react'
import {useTranslation} from 'react-i18next'
import UserLogTablePage from './UserLogTablePage'

/**
 * 사용자 계정 관리 관련 테이블
 */

/**
 * 사용자 관리 페이지 관련
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: 10,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 20,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },

  searchButton: {
    height: 56,
    marginTop: 15,
  },

  indexInputContainer: {
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  selectBox: {
    flexGrow: 1,
    backgroundColor: '#fff',
    width: 130,
  },
  disableText: {
    color: '#a6a6a6',
  },
  button: {
    height: 55,
    minWidth: 110,
    maxWidth: 110,
  },
  tableWrap: {
    backgroundColor: '#fff',

    marginTop: 10,
    overflow: 'Hidden',
    // margin: 15,
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  textField: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  align: {
    justify: 'center',
  },
  dateSearch: {
    marginLeft: 10,
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
    '& h6': {
      margin: '0 10px',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#F8F8F8 !important',
    },
  },
}))

export default function SalesUserLogPage() {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <CardWithTitle title={t('IUserLogs')} className={classes.root}>
        <UserLogTablePage />
      </CardWithTitle>
    </div>
  )
}
