import {Colors} from 'components/common/useBaseStyle'
import React, {ReactNode} from 'react'
import styled, {keyframes} from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'

const zoomIn = keyframes`
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
`

const zoomOut = keyframes`
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
`

interface StyledCheckboxProps {
  isArtifact: boolean
  disabled: boolean
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  font-size: 12px;

  & label {
    display: flex;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + .Checkbox {
    border: ${(props) =>
      props.isArtifact ? `1px solid ${Colors.primary}` : '1px solid #555'};
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    padding: 0 2px;

    & .MuiSvgIcon-root {
      font-size: inherit;
      visibility: hidden;
      animation: ${zoomOut} ease 0.1s;
      color: ${(props) => (props.isArtifact ? Colors.primary : '#555')};
    }

    & + .Label {
      color: ${(props) => (props.isArtifact ? Colors.primary : '#555')};
      font-size: inherit;
      user-select: none;
    }
  }

  input[type='checkbox']:checked + .Checkbox {
    & .MuiSvgIcon-root {
      visibility: visible;
      animation: ${zoomIn} ease 0.1s;
    }
  }

  input[type='checkbox']:disabled + .Checkbox {
    //background-color: pink;
    border-color: ${Colors.pagination.disabled};
    & + .Label {
      color: ${Colors.pagination.disabled};
    }
    & .MuiSvgIcon-root {
      color: ${Colors.pagination.disabled};
    }
  }
`

interface CheckboxProps {
  className?: string
  name?: string
  value?: string | number | string[]
  onBlur?: () => void
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isArtifact?: boolean
  children?: string | ReactNode
  disabled?: boolean
}

const CheckBox = React.forwardRef(
  (props: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      className = '',
      isArtifact = false,
      name,
      value,
      onChange,
      onBlur,
      children,
      checked = false,
      disabled = false,
    } = props
    return (
      <StyledCheckbox
        isArtifact={isArtifact}
        className={className}
        disabled={disabled}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <input
            type='checkbox'
            name={name}
            value={value}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            checked={checked}
            disabled={disabled}
          />
          <span className='Checkbox'>
            <CheckIcon />
          </span>
          <span className='Label'>{children}</span>
        </label>
      </StyledCheckbox>
    )
  },
)

export default CheckBox
