import {makeStyles} from '@material-ui/core/styles'

import styles from 'assets/tss/material-dashboard-pro-react/layouts/authStyle'
import TranslationSelect from 'components/atoms/TranslationSelect'
import React, {ReactNode} from 'react'

const useStyles = makeStyles(styles)

interface AuthLayoutProps {
  children: ReactNode | ReactNode[]
}

export default function AuthLayout({children}: AuthLayoutProps) {
  const classes = useStyles()

  React.useEffect(() => {
    document.body.style.overflow = 'unset'
    return () => {}
  }, [])

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          position: 'absolute',
          top: '40px',
          right: '40px',
          zIndex: 999,
        }}
      >
        <TranslationSelect />
      </div>
      <div className={classes.fullPage}>{children}</div>
    </div>
  )
}
