import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import {Route, Switch} from 'react-router-dom'
import React from 'react'
import RouteIf from 'routes/RouteIf'
import InternalErrorPage from 'pages/ErrorPages/InternalErrorPage'
import DisplayDescription from 'pages/DisplayDescription/DescBrainCPage'
import DescBrainMCIPage from 'pages/DisplayDescription/DescBrainMCIPage'
import DescHeartPage from 'pages/DisplayDescription/DescHeartPage'

export default function DisplayRoute() {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ISYNC_BRAIN_C.path}
          exact
          component={DisplayDescription}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ISYNCBRAIN_MCI.path}
          exact
          component={DescBrainMCIPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.ISYNCBRAIN_HEART.path}
          exact
          component={DescHeartPage}
        />
        <Route
          path={RouteConstant.ERROR_NOT_FOUND.path}
          component={InternalErrorPage}
        />
      </Switch>
    </CommonLayout>
  )
}
