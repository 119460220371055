import {CurrencyCode} from 'api/paymentApi'
import {numberWithCommas} from 'helpers/commonHelper'

export default class PaymentHistoryTableItemsModel {
  constructor(raw: PaymentHistoryTableItemsModel) {
    this.amount = raw.amount
    this.cardName = raw.cardName
    this.createdAt = raw.createdAt
    this.currencyCode =
      CurrencyCode[raw.currencyCode as string as keyof typeof CurrencyCode]
    this.isRefund = raw.isRefund
    this.orderName = raw.orderName
    this.orderNo = raw.orderNo
    this.pgType = raw.pgType
  }

  amount: number

  cardName: string

  createdAt: string

  currencyCode: string

  isRefund: boolean

  orderName: string

  orderNo: string

  pgType: string

  getOrderNo: () => string = () => {
    return this.isRefund ? `${this.orderNo}R` : this.orderNo
  }

  getOrderType: () => string = () => {
    return this.isRefund ? 'IRefund' : 'IPay'
  }

  getPurchaseAmountWithCurrencyCode: () => string = () => {
    return `${this.currencyCode} ${numberWithCommas(this.amount)}`
  }
}
