import {Colors} from 'components/common/useBaseStyle'
import {availableStatusToI18nString} from 'helpers/analysisHelper'
import React from 'react'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const StyledConfirmTable = styled.table`
  width: 100%;
  border-bottom: 1px solid #e6e7eb;
  border-collapse: collapse;
  background-color: #fff;
  padding: 10px;

  & thead {
    background-color: #eff0f5;
    font-size: 15px;
    color: #333333;
  }

  & td {
    text-align: center;
    padding: 14px;
    font-size: 15px;
    color: #333;
  }

  & th:last-child {
    min-width: 200px;
  }
`

interface MultiIcaResultTableItem {
  id: number
  status: 'SUCCESS' | 'FAIL' | 'PAIR'
  description: string
}

interface ColumnStatusProps {
  status: 'SUCCESS' | 'FAIL' | 'PAIR'
}

const ColumnStatus = styled.td<ColumnStatusProps>`
  color: ${(props) => (props.status === 'FAIL' ? Colors.error : 'auto')};
`

interface MultiIcaResultTableProps {
  items: MultiIcaResultTableItem[]
}

function MultiIcaCheckTable({items}: MultiIcaResultTableProps) {
  const {t} = useTranslation()
  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IAnalysisNumber'},
    {id: '2', label: 'IProgress'},
    {id: '3', label: 'IDescription'},
  ]

  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: 30,
        borderRadius: 6,
        border: 'solid 1.5px #e4e4e4',
      }}
    >
      <StyledConfirmTable>
        <thead>
          {HeaderCells.map((item, index) => (
            <RoundTableHeader
              label={item.label}
              length={HeaderCells.length}
              index={index}
            />
          ))}
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <ColumnStatus status={item.status}>
                {t(availableStatusToI18nString(item.status))}
              </ColumnStatus>
              <td style={{width: 300}}>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </StyledConfirmTable>
    </div>
  )
}

export default MultiIcaCheckTable
