import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SvgIcon} from 'components/common/useSvgIcons'
import {
  GroupType,
  selectGroups,
  Set2,
  setCanPrescribe,
} from 'features/analysis/nirCareLedSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import {
  FlexContainer,
  Text,
  PrimaryBtn,
  BoxContainer,
  DivWrapper,
  InputEditName,
  TitleGroup,
  WrapperSetTabs,
} from '../Style'
import {MainContentProps, Groups, SingleGroup} from '../Interface'
import BrainImageContainer from '../BrainImageContainer'
import usePrescriptionViewModelV2 from './PrescriptionViewModel'

const MainContent = ({customerId}: MainContentProps) => {
  const {t} = useTranslation()
  const {
    selectedGroup,
    setSelectGroup,
    addNewSetToAGroup,
    setSelectSet,
    selectedSet,
    changeNameOfGroup,
    deleteGroup,
    deleteSet,
  } = usePrescriptionViewModelV2()
  const Groups: GroupType[] = useAppSelector(selectGroups)

  const [setName, setSetName] = useState('')
  const [prescriptionName, setPrescriptionName] = useState('')
  const [editName, setEditName] = useState('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  console.log(`selected group : ${JSON.stringify(selectedGroup)}`)

  const findGroupIndex = (): number => {
    const index = Groups.findIndex(
      (group) => group.label === selectedGroup.label,
    )
    return index
  }

  useEffect(() => {
    setIsEditing(false)
    setEditName('')
  }, [selectedGroup])

  useEffect(() => {
    console.log('isEditing', isEditing)
    dispatch(setCanPrescribe(!isEditing))
  }, [isEditing])

  const handleCreateSet = () => {
    addNewSetToAGroup(`set_${Date.now().toString(36)}`, selectedGroup)
  }

  const handleSetClick = (set: Set2) => {
    setSelectSet(set)
  }

  useEffect(() => {
    setPrescriptionName(
      selectedGroup.label?.startsWith('grp_')
        ? `${t('IPrescription')} ${findGroupIndex() + 1}`
        : `${selectedGroup.label}`,
    )
  }, [selectedGroup, selectedSet])

  const handleEditBtnClick = () => {
    setIsEditing(true)
    // setEditName(selectedGroup.name as string)
    setEditName(prescriptionName)
  }
  const handleDeleteGroup = () => {
    deleteGroup()
  }
  const handleDeleteSet = () => {
    deleteSet(customerId)
  }

  const handleEditGroupName = () => {
    changeNameOfGroup(editName, customerId)
    setEditName('')
    setIsEditing(false)
  }

  if (
    !prescriptionName ||
    prescriptionName.trim() === '' ||
    prescriptionName === 'undefined' ||
    prescriptionName === 'null'
  ) {
    setPrescriptionName(`${t('IPrescription')} ${findGroupIndex() + 1}`)
  }

  return (
    <>
      {Groups.length !== 0 && (
        <FlexContainer
          justifyContent='space-between'
          padding='0px 10px 20px 0px'
          bgcolor='#F9F9F9'
          alignItem='center'
        >
          {isEditing ? (
            <FlexContainer
              bgcolor='#F9F9F9'
              cursor='pointer'
              padding='0px 15px 0px 0px'
              // onClick={() => handleEditBtnClick()}
            >
              <InputEditName
                // placeholder={`${t('IAdult')}ADHD${t('IGroup')}`}
                placeholder={`${t('IPrescription')}1`}
                id='edit-group-name'
                onChange={(event) => {
                  setEditName(event.target.value)
                }}
                value={editName}
              />
              <Text
                size='12px'
                padding='10px'
                margin='0px 5px 0px 0px'
                color='#fff'
                cursor='pointer'
                bgcolor='#606264'
                fontWeight='700'
                onClick={() => handleEditGroupName()}
              >
                {t('ICheck')}
              </Text>
            </FlexContainer>
          ) : (
            // <h3>{selectedGroup.name}</h3>
            <TitleGroup>{prescriptionName ?? '프로포콜'}</TitleGroup>
          )}
          <FlexContainer gap='5px'>
            {!isEditing && (
              <Text
                cursor='pointer'
                margin='0x 5px 0px 0px'
                padding='10px 15px'
                color='#5A6268'
                bgcolor='#fff'
                radius='2px'
                border='1px solid #E9E9E9'
                onClick={() => handleEditBtnClick()}
              >
                <SvgIcon name='edit' iconColor='#454E64' />
                <span style={{marginLeft: '8px'}}>{t('IEdit')}</span>
              </Text>
            )}
            {!isEditing && Groups?.length && Groups?.length > 1 && (
              <Text
                cursor='pointer'
                padding='10px 15px'
                color='#5A6268'
                bgcolor='#fff'
                radius='2px'
                border='1px solid #E9E9E9'
                onClick={() => handleDeleteGroup()}
              >
                <SvgIcon name='delete' iconColor='#454E64' />
                <span style={{marginLeft: '8px'}}>{t('IDelete')}</span>
              </Text>
            )}
          </FlexContainer>
        </FlexContainer>
      )}
      {selectedGroup?.protocolList?.length && (
        <FlexContainer alignItem='center' bgcolor='#F9F9F9' gap='5px'>
          {/* existing set continer */}
          {selectedGroup?.protocolList?.map((set, index) => {
            return (
              <WrapperSetTabs>
                <Text
                  size='12px'
                  fontWeight={selectedSet?.label === set?.label ? '700' : '500'}
                  radius='4px 0px 0px 0px'
                  padding='10px'
                  margin='0px 5px 0px 0px'
                  color={selectedSet?.label === set?.label ? '#000' : '#818181'}
                  cursor='pointer'
                  bgcolor={
                    selectedSet?.label === set?.label ? '#fff' : '#D9D9D9'
                  }
                  onClick={() => handleSetClick(set)}
                >
                  {/* {set?.name} */}
                  {`${t('IProtocol')} ${index + 1}`}
                </Text>
                {/* Cannot remove first set */}
                <Text
                  style={{minWidth: '25px', borderRadius: '0px 4px 0px 0px'}}
                  size='12px'
                  fontWeight={selectedSet?.label === set?.label ? '700' : '600'}
                  padding='10px 10px 10px 5px'
                  margin='0px 0px 0px -8px'
                  color={selectedSet?.label === set?.label ? '#000' : '#818181'}
                  cursor='pointer'
                  bgcolor={
                    selectedSet?.label === set?.label ? '#fff' : '#D9D9D9'
                  }
                  onClick={() => {
                    if (index === 0) return
                    handleDeleteSet()
                  }}
                >
                  {index === 0 ? '' : '✕'}
                </Text>
              </WrapperSetTabs>
            )
          })}
          <FlexContainer cursor='pointer' padding='0px' alignItem='center'>
            {/* create set continer */}
            <PrimaryBtn
              onClick={handleCreateSet}
              bgcolor='#3EBCBD'
              radius='4px 4px 0px 0px'
            >
              <SvgIcon name='addSubgroups' iconColor='' />
            </PrimaryBtn>
          </FlexContainer>
        </FlexContainer>
      )}
      <BoxContainer minHeight='300px'>
        {Groups.length !== 0 && (
          <Text
            textAlign='right'
            padding='20px 20px 0px 10px'
            color='#5A6268'
            // onClick={() => handleDeleteSet()}
          >
            {/* delete set button */}
            <FlexContainer padding='10px' />
          </Text>
        )}
        {selectedGroup?.protocolList &&
          selectedGroup?.protocolList?.length > 0 &&
          selectedGroup?.protocolList?.map((set) => {
            return (
              <div
                style={{
                  display: set?.label === selectedSet.label ? 'block' : 'none',
                }}
              >
                <BrainImageContainer customerId={customerId} />
              </div>
            )
          })}
      </BoxContainer>
    </>
  )
}

export default MainContent
