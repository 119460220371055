import Dialog from '@material-ui/core/Dialog'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import {useRefDialog} from 'features/modal/refDialogSlice'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '.5rem',
      fontSize: '1.4rem',
      padding: '30px 15px',
      '& svg': {marginRight: '.5rem'},
    },
    body: {
      backgroundColor: '#eef2f6',
      padding: '30px 15px',
      color: '#666',
    },
  }),
)

function ReferenceDialog() {
  const classes = useStyles()

  const {open, body, onClose} = useRefDialog()

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.title}>
          <MenuBookIcon />
          <Typography variant='h6'>References</Typography>
        </div>
        <div className={classes.body}>{body}</div>
      </div>
    </Dialog>
  )
}

export default ReferenceDialog
