import {makeStyles, Theme} from '@material-ui/core/styles'

const useHealthCenterHeartRateAnalysisStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  padding: {
    padding: theme.spacing(1),
  },
  heartRate: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 68,
  },
  imageChart: {
    width: 500,
    '& img': {
      width: 500,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  paddingContent: {
    padding: '0px 15px',
  },
  contentCard: {
    padding: '20px 15px 0px 15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  pieChart: {
    '& img': {
      width: 545,
      minHeight: 364,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  tablePercent: {
    '& img': {
      width: 545,
      minHeight: 364,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  tablePercentMargin: {
    minWidth: 545,
    '& img': {
      width: 545,
      minHeight: 364,
      marginTop: -20,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  contentAnalysis: {
    backgroundColor: '#f8f9f9',
    padding: '20px 24px 20px 24px',
  },
  fontSize18: {
    fontSize: 18,
  },
  fontWeightContent: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '148%',
    letterSpacing: '-0.72px !important',
    marginBottom: 12,
  },
  fontWeightContentHeaderAppears: {
    fontWeight: 700,
    fontSize: '19px',
    lineHeight: '148%',
    letterSpacing: '0.28px !important',
    marginBottom: 12,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  header: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    backgroundColor: '#7f8892',
  },
  cell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    position: 'relative',
    alignContent: 'space-evenly',
  },
  bar: {
    position: 'absolute',
    top: '50%',
    left: 0,
    height: 12,
    background:
      'linear-gradient(to right, rgb(97, 124, 156), rgb(72, 101, 134))',
    transform: 'translateY(-50%)',
  },
  barColOne: {
    position: 'absolute',
    top: '50%',
    left: 0,
    height: 12,
    background:
      'linear-gradient(to right, rgb(122, 148, 178), rgb(97, 125, 156))',
    transform: 'translateY(-50%)',
    borderRadius: '10px 0px 0px 10px',
  },
  barColThree: {
    position: 'absolute',
    top: '50%',
    left: 0,
    height: 12,
    background:
      'linear-gradient(to right, rgb(71, 100, 133), rgb(46, 77, 111))',
    transform: 'translateY(-50%)',
    borderRadius: '0px 10px 10px 0px',
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  headerTable: {
    color: '#ffffff',
    fontWeight: 500,
  },
  rowTable: {
    fontWeight: 500,
  },
  headerTwo: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    background:
      'linear-gradient(to right, rgb(122, 148, 178), rgb(97, 125, 156))',
  },
  headerThree: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    background:
      'linear-gradient(to right, rgb(97, 124, 156), rgb(72, 101, 134))',
  },
  headerFour: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    background:
      'linear-gradient(to right, rgb(71, 100, 133), rgb(46, 77, 111))',
  },
  variabilityLabel: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26.64px',
    letterSpacing: '-0.72px !important',
  },
  textCircularTop: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21.6px',
    letterSpacing: '-0.03em',
    textAlign: 'center',
  },
  imgCircular: {
    maxWidth: 280,
    maxHeight: 283,
    minHeight: 283,
  },
  textLeftCircular: {
    display: 'flex',
    alignItems: 'end',
    textAlign: 'end',
    maxWidth: '179px',
  },
  textRightCircular: {
    display: 'flex',
    alignItems: 'end',
    maxWidth: '150px',
  },
  textLeftGreen: {
    color: '#2CA5A7',
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '-0.54px',
    textAlign: 'left',
  },
  textRightGreen: {
    color: '#007689',
    textAlign: 'right',
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '-0.54px',
  },
  textLeftDec: {
    color: '#444',
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '-0.51px',
    textAlign: 'left',
  },
  textRightDec: {
    color: '#444',
    textAlign: 'right',
    fontFamily: '"Noto Sans KR", sans-serif',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '-0.51px',
    wordBreak: 'keep-all',
  },
  circularChart: {
    width: '50%',
    '& img': {
      width: 545,
      marginTop: 21,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  textEndCircular: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  flexCircular: {
    display: 'flex',
    justifyContent: 'center',
  },
  changePosition: {
    marginBottom: '-64px',
  },
  headerHRV: {
    padding: '0px 15px',
  },
}))

export default useHealthCenterHeartRateAnalysisStyles
