import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import {fileDownload} from 'api'
import {
  fetchPromotionListApi,
  FetchPromotionListRequest,
} from 'api/promotionApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import GridPaginationPadding from 'components/Grid/GridPaginationPadding'
import useTableStyles from 'components/Table/useTableStyles'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useFailureModal from 'features/modal/useFailureModal'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import {
  formatDate,
  getDateForFile,
  getDefaultPeriodString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    navContainer: {
      // margin: 15,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        // flex: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },

    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
    },
    disableText: {
      color: '#a6a6a6',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    analBox: {
      width: 200,
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      justifyContent: 'space-between',
      border: '1px solid rgb(53 73 99 / 20%)',
      alignItems: 'center',
      borderRadius: '4px',
      marginBottom: 10,
    },
    pointTitle: {
      fontSize: '12px',
    },
    pointCont: {
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
      //   alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
      // alignItems: 'center',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    toolbarWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: 5,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    pointSearchContainer: {
      marginLeft: 5,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}

const headCells: HeadCell[] = [
  {id: 'id', label: 'IOrderNo'},
  {id: 'paidDate', label: 'IOrderDate'},
  {id: 'productName', label: 'IProductName'},
  {id: 'analysisNo', label: 'IAnalysisNo'},
  {id: 'orderOrgName', label: 'IOrderOrgName'},
  {id: 'purchaseType', label: 'IPurchaseType'},
  {id: 'pointRemainingNumber', label: 'IPointRemainingNumber'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface PromotionHistoryTableProps {
  itemList: PageResponse<PromotionHistory> | null
  onItemList: (data?: PageResponse<PromotionHistory>) => void
}

function PromotionHistoryTable(props: PromotionHistoryTableProps) {
  const classes = useTableStyles()
  const {itemList, onItemList} = props

  const selectedOid = useSelector(selectAllOrgOid)
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [length] = React.useState(0)

  const emptyRows = itemList?.data.content === null ? 5 : 5 - length ?? 0

  const handlePromotion = async (data: FetchPromotionListRequest) => {
    try {
      const response = await fetchPromotionListApi({
        ...data,
      })
      if (response.success) {
        onItemList(response)
      } else {
        console.log({response}, '프로모션 사용내역')
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }
  const [page, setPage] = React.useState(0)
  const setPageIndex = (page: number) => {
    setPage(page)
    handlePromotion({
      page,
      size: 5,
      oid: selectedOid ?? null,
    })
  }

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='medium'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {itemList != null &&
            itemList.data.content.map((id: any) => {
              return (
                <TableRow hover classes={{hover: classes.hover}} key={id.id}>
                  <TableCell align='center'>{id.id ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {isoStringToDateAndTimeString(id.paidDate ?? '-')}
                  </TableCell>
                  <TableCell align='center'>{id.product ?? '-'}</TableCell>
                  <TableCell align='center'>{id.analysisId ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {id.name ?? '-'} / {id.userName ?? '-'}
                  </TableCell>
                  <TableCell align='center'>{id.pointType ?? '-'}</TableCell>
                  <TableCell align='center'>{id.point ?? '-'}</TableCell>
                  <TableCell align='center'>{id.totalCount ?? '-'}</TableCell>
                </TableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <GridPaginationPadding />
      <Pagination
        totalPageCount={itemList?.data.totalElements ?? 0}
        currentPageIndex={page ?? 0}
        itemCountPerPage={5}
        setCurrentPageIndex={setPageIndex}
        loading={false}
        onItemCountPerPageChanged={(event: any) =>
          handlePromotion({
            page: 0,
            size: parseInt(event.target.value as string, 10),
            oid: selectedOid ?? null,
          })
        }
        isItemCountPerPageEnabled={false}
      />
    </TableContainer>
  )
}

export default function MyPromotionHistory() {
  const searchRadioItems = [
    {value: 0, label: 'IOrderNo', apiKey: 'ORDER_NO'},
    {value: 1, label: 'IOrderDate', apiKey: 'DATE'},
    {value: 2, label: 'IProductName', apiKey: 'PRODUCT'},
    {value: 3, label: 'IAnalysisNumber', apiKey: 'ANALYSIS_ID'},
    {value: 4, label: 'IName', apiKey: 'NAME'},
    {value: 5, label: 'IPurchaseType', apiKey: 'TYPE'},
  ]

  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {t} = useTranslation()

  const selectedOid = useSelector(selectAllOrgOid)
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [searchNameValue, setSearchNameValue] = React.useState(0)
  const [searchedValue, setSearchedValue] = React.useState('')
  const [promotionList, setPromotionList] =
    React.useState<PageResponse<PromotionHistory>>()

  const [selection, setSelection] = React.useState<PeriodString>(
    getDefaultPeriodString(),
  )

  const handleOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    if ((event.target.value as number) in searchRadioItems) {
      const number = event.target.value as number
      setSearchNameValue(number)
    }
  }

  function makeStartDate(date: Date | null | undefined): string | null {
    if (date !== null || date !== undefined || date !== '') {
      return formatDate(date ?? new Date())
    }
    return null
  }
  const handleValue = (value: any) => {
    setSelection(value)
  }
  const handleSearchedValue = (value: any) => {
    setSearchedValue(value)
  }
  const handlePromotion = async (data: FetchPromotionListRequest) => {
    try {
      const response = await fetchPromotionListApi({
        ...data,
      })
      if (response.success) {
        setPromotionList(response)
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }

  const handleDownload = async () => {
    if (promotionList?.data.content.length !== 0) {
      await fileDownload({
        path: '/api/point/v1/promotion/download/excel',
        params: {
          oid: selectedOid ?? null,
        },
        fileName: getDateForFile(),
      })
    }
    if (promotionList?.data.content.length === 0) {
      onFailureModalOpen(t('IPromotionEmpty'))
    }
  }

  const handleKeywordSearch = () => {
    handlePromotion({
      page: 0,
      size: 5,
      searchName: searchRadioItems[searchNameValue].apiKey ?? 'PRODUCT',
      searchValue: searchedValue ?? undefined,
    })
  }

  useEffect(() => {
    handlePromotion({
      page: 0,
      size: 5,
    })
    setSearchNameValue(2)
  }, [])

  const handlePeriod = (period: PeriodDate) => {
    handlePromotion({
      page: 0,
      size: 5,
      oid: selectedOid ?? null,
      startDate: makeStartDate(period.startDate),
      endDate: makeStartDate(period.endDate),
    })
  }

  useEffect(() => {
    handlePeriod({
      startDate: new Date(selection.startDate),
      endDate: new Date(selection.endDate),
    })
  }, [selection])

  return (
    <CardWithTitle title={t('IPromotionHistory')}>
      <Grid className={classes.pointContainer}>
        <Grid item xs={12}>
          <Grid container className={classes.toolbarContainer}>
            <Grid className={classes.toolbarWrap}>
              <div style={{display: 'flex'}}>
                <div className={classes.dataContainer}>
                  <RangeDatePicker
                    onChangeValue={handleValue}
                    dateValue={{
                      startDate: new Date(selection.startDate),
                      endDate: new Date(selection.endDate),
                    }}
                  />
                </div>
                <div className={classes.pointSearchContainer}>
                  <FormControl variant='outlined' className={classes.selectBox}>
                    <Select
                      value={searchNameValue}
                      onChange={handleOnchange}
                      displayEmpty
                      color='primary'
                      input={
                        <OutlinedInput
                          name='age'
                          id='outlined-age-simple'
                          classes={outlinedInputClasses}
                        />
                      }
                    >
                      <MenuItem disabled value=''>
                        <Typography className={classes.disableText}>
                          {t('ISelect')}
                        </Typography>
                      </MenuItem>
                      {searchRadioItems.map((item) => (
                        <MenuItem value={item.value}>{t(item.label)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <SearchBar
                    onChangeValue={handleSearchedValue}
                    onSearchEvent={handleKeywordSearch}
                    disabledValue={false}
                  />
                </div>
              </div>
              <ActionButton
                variant='contained'
                color='secondary'
                startIcon={<GetAppIcon />}
                disableElevation
                onClick={async (event) => {
                  event.stopPropagation()
                  handleDownload()
                }}
              >
                {t('IExcelDownload')}
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <PromotionHistoryTable
          itemList={promotionList ?? null}
          onItemList={setPromotionList}
        />
      </Grid>
    </CardWithTitle>
  )
}
