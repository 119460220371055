import ActionButton from 'components/atoms/Buttons/ActionButton'
import PeriodSearch from 'components/molcules/PeriodSearch'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import GetAppIcon from '@material-ui/icons/GetApp'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Pagination from 'components/molcules/Pagination'
import {
  PackageTable,
  PackageTableHeader,
  TableOverflowWrap,
} from 'components/Table/CommonTableStyle'
import {getPackageFullName} from 'helpers/paymentHelper'
import UsageHistoryTableModel from 'pages/OrgPage/PaymentManagementPages/Models/UsageHistoryTableModel'
import useUsageHistoryTableViewModel from './UsageHistoryTableViewModel'

interface UsageHistoryTableHeaderProps {
  HeaderCells: HeadCell[]
}

interface UsageHistoryTableRowsProps {
  usageHistoryList: UsageHistoryTableModel
}

/**
 * UsageHistoryTableHeader Component
 */
const UsageHistoryTableHeader = (props: UsageHistoryTableHeaderProps) => {
  const {t} = useTranslation()
  const {HeaderCells} = props

  return (
    <PackageTableHeader>
      <tr>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={t(item.label)}
            length={HeaderCells.length}
            index={index}
            key={index}
          />
        ))}
      </tr>
    </PackageTableHeader>
  )
}

/**
 * UsageHistoryTableRows Component
 */
const UsageHistoryTableRows = (props: UsageHistoryTableRowsProps) => {
  const {usageHistoryList} = props
  const {t} = useTranslation()

  return (
    <>
      {usageHistoryList.items.map((item, idx) => (
        <tr key={idx}>
          <td>{isoStringToDateAndTimeString(item.createdAt)}</td>
          <td>{item.getOrderNo()}</td>
          <td>{item.userName}</td>
          {item.package ? (
            <>
              <td>{item.package.name}</td>
              <td>
                {item.package.analyses
                  .map(({code}) => {
                    return t(getPackageFullName(code))
                  })
                  .join(', ')}
              </td>
            </>
          ) : (
            <>
              <td>{item.bundle.name}</td>
              <td>
                {item.bundle.analyses
                  .map(({code}) => {
                    return t(getPackageFullName(code))
                  })
                  .join(', ')}
              </td>
            </>
          )}
          <td>
            {item.getStatus() !== 'ISucceed' && item.getStatus() !== 'IFailed'
              ? `${item.getStatus()} ${t('IError')}`
              : t(item.getStatus())}
          </td>
          <td>
            {item.getRemainAmount() === 'IUnlimited'
              ? t(item.getRemainAmount() as string)
              : item.getRemainAmount()}
          </td>
          <td>{item.getChargeAmount()}</td>
        </tr>
      ))}
    </>
  )
}

/**
 * UsageHistoryTable Component
 */
const UsageHistoryTable = () => {
  const {t} = useTranslation()

  const {
    isUsageHistoryListLoading: isListLoading,
    usageHistoryList,
    getUsageHistory,
    selection,
    pagingState,
    handlePeriod,
    setPageIndex,
    handlePageSizeChanged,
    handleExcelExport,
  } = useUsageHistoryTableViewModel()

  useEffect(() => {
    getUsageHistory()
  }, [])

  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IDate'},
    {id: '2', label: 'IOrderNo'},
    {id: '3', label: 'ICustomerNameShort'},
    {id: '5', label: 'IProductName'},
    {id: '5', label: 'IIncludedProducts'},
    {id: '6', label: 'IStatus'},
    {id: '7', label: 'IRemainingAmount'},
    {id: '8', label: 'IAmount'},
  ]

  const HeaderForExcel: string[][] = [HeaderCells.map((item) => t(item.label))]
  const hasData =
    !isListLoading &&
    usageHistoryList !== undefined &&
    usageHistoryList.items !== undefined &&
    usageHistoryList.items.length !== 0

  return (
    <>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          rowGap: 10,
        }}
      >
        <PeriodSearch
          period={selection}
          onSearch={handlePeriod}
          hasPlaceholder
        />
        <ActionButton
          variant='contained'
          color='secondary'
          startIcon={<GetAppIcon />}
          disableElevation
          onClick={() => handleExcelExport(HeaderForExcel)}
        >
          {t('IExcelDownload')}
        </ActionButton>
      </div>
      <TableOverflowWrap>
        <PackageTable>
          <colgroup>
            <col style={{minWidth: 200}} />
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 140}} />
            <col style={{minWidth: 180}} />
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 80}} />
          </colgroup>
          <UsageHistoryTableHeader HeaderCells={HeaderCells} />
          <tbody>
            {hasData && (
              <UsageHistoryTableRows usageHistoryList={usageHistoryList} />
            )}
          </tbody>
        </PackageTable>
        {!hasData && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            {t('INoData')}
          </div>
        )}
      </TableOverflowWrap>
      {!isListLoading && (
        <Pagination
          totalPageCount={Math.ceil(usageHistoryList.total / pagingState.size)}
          currentPageIndex={pagingState.page - 1}
          itemCountPerPage={pagingState.size}
          setCurrentPageIndex={setPageIndex}
          loading={isListLoading}
          onItemCountPerPageChanged={handlePageSizeChanged}
        />
      )}
    </>
  )
}
export default UsageHistoryTable
