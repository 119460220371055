export const channels = [
  'c3',
  'c4',
  'cz',
  'f3',
  'f4',
  'f7',
  'f8',
  'fp1',
  'fp2',
  'fz',
  'o1',
  'o2',
  'p3',
  'p4',
  'pz',
  't3',
  't4',
  't5',
  't6',
]

export const subgroupChannels = {
  c3: false,
  c4: false,
  cz: false,
  f3: false,
  f4: false,
  f7: false,
  f8: false,
  fp1: false,
  fp2: false,
  fz: false,
  o1: false,
  o2: false,
  p3: false,
  p4: false,
  pz: false,
  t3: false,
  t4: false,
  t5: false,
  t6: false,
}

export const dummy = {
  name: 'test',
}
