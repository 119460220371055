import {
  ExecuteNormAnalysisRequest,
  SearchedIndividualEEG,
} from 'api/analysisApi'
import {
  closeNormDialog,
  confirmNormDialog,
  openNormDialog,
  selectNormDialogOpen,
  selectSelectedItems,
} from 'features/analysis/eegIndividualSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useEegIndividualNormDialog() {
  const dispatch = useAppDispatch()

  const onOpen = (payload: SearchedIndividualEEG[]) =>
    dispatch(openNormDialog(payload))

  const onConfirm = (payload: ExecuteNormAnalysisRequest) =>
    dispatch(confirmNormDialog(payload))

  const onClose = () => dispatch(closeNormDialog())

  const isOpen = useAppSelector(selectNormDialogOpen)

  const selected = useAppSelector(selectSelectedItems)

  return {
    open: isOpen,
    selected,
    onOpen,
    onConfirm,
    onClose,
  }
}
