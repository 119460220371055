import {RootState} from 'store'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  OrgDetailEditRequest,
  OrgDetailResponse,
  getDetailOrgRegister,
  updateOrgDetailApi,
} from 'api/invoiceApis'

export const fetchDetailOrg = createAsyncThunk(
  '/api/manager/v2/org/id/detail',
  async (payload: {id: number}, {rejectWithValue}) => {
    try {
      const response = await getDetailOrgRegister(payload.id)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchOrgUpdateAction = createAsyncThunk(
  'api/org/update',
  async (payload: OrgDetailEditRequest, {dispatch, rejectWithValue}) => {
    try {
      await updateOrgDetailApi(payload)
      return await dispatch(fetchDetailOrg({id: payload.orgId})).unwrap()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface OrgRegisterState {
  loading: boolean
  error: any
  selected: OrgDetailResponse
  oid?: string | null
  normDialogOpen: boolean
  reSelectYn: YN | undefined
  reAnalysisYn: YN | undefined
  isIcaSelectOpen: boolean
  isIcaConfirmOpen: boolean
  isIcaResultOpen: boolean
  sraYn?: YN | undefined
}

const initialState: OrgRegisterState = {
  loading: false,
  error: null,
  selected: {
    address: '',
    businessNumber: '',
    countryId: 0,
    createdAt: '',
    email: '',
    firstName: '',
    industryId: 0,
    detailIndustryId: null,
    institutionNumber: '',
    lastName: '',
    name: '',
    orgId: 0,
    orgName: '',
    orgType: '',
    phoneNumber: '',
    healthProductInfoList: [],
  },
  normDialogOpen: false,
  reSelectYn: undefined,
  reAnalysisYn: undefined,
  isIcaSelectOpen: false,
  isIcaConfirmOpen: false,
  isIcaResultOpen: false,
  oid: '',
  sraYn: undefined,
}

export const customerSlice = createSlice({
  name: 'orgRegisterSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDetailOrg.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchDetailOrg.fulfilled, (state, action) => {
      state.loading = false
      const selected = action.payload
      state.selected = selected
      state.error = null
    })
    builder.addCase(fetchDetailOrg.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default customerSlice.reducer

export const selectLoading = (state: RootState) => state.orgRegister.loading
export const selectError = (state: RootState) => state.orgRegister.error
export const selectSelected = (state: RootState) => state.orgRegister.selected
