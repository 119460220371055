import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    image: {
      width: '100%',
      height: 'auto',
      '&.all': {
        margin: 'auto',
        marginLeft: -40,
      },
      '&.bin': {
        margin: 'auto',
        marginLeft: -40,
      },
    },
    content: {
      '& table thead th': {
        textAlign: 'center',
        backgroundColor: '#eff0f5',
        padding: '10px 3px',
        fontSize: '12px',
        color: '#333',
        wordBreak: 'keep-all',
        border: '1px solid transparent',
        minWidth: '50px',
      },
      '& table tbody td': {
        '& a': {
          color: '#f8ac59',
        },
        cursor: 'pointer',
        padding: '10px',
        fontSize: '12px',
        color: '#000',
        border: '1px solid #e7eaec',
        borderWidth: '1px 0 0 1px',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        background: '#fff',
        minWidth: '50px',
        '&:last-child': {
          borderWidth: '1px 1px 0 1px',
        },
      },
      '& table tbody tr:last-child td': {
        border: '1px solid #e7eaec',
        borderWidth: '1px 0 1px 1px',
      },
      '& table tbody tr:last-child td:last-child': {
        border: '1px solid #e7eaec',
        borderWidth: '1px 1px 1px 1px',
      },
    },
    contentError: {
      padding: '.5rem',
      fontSize: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eff0f5',
      color: '#333',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
