import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    carouselButtonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '.2rem',
      justifyContent: 'center',
    },
    carouselButton: {
      cursor: 'pointer',
      backgroundColor: 'rgba(248,172,89, .6)',
      color: '#fff',
      border: 'none',
      borderRadius: '50px',
      fontSize: '.9rem',
      height: '1.4rem',
      // lineHeight: '1.4rem',
      width: '1.4rem',
      padding: '.6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    carouselButtonActive: {
      backgroundColor: 'rgb(248,172,89)',
    },
    carouselWrapper: {
      /// 5:2 image wrapper
      position: 'relative',
      paddingTop: '40%',
      width: '100%',
      height: 0,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
    artifactRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    artifactTitle: {
      background: '#2f404f',
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    },
    artifactComponentsRoot: {
      flex: 1,
      background: '#dee6eb',
      padding: 10,
      display: 'flex',
      minHeight: 40,
    },
    artifactComponent: {
      padding: '0 7px',
      background: '#f8ac59',
      color: '#fff',
      fontWeight: 600,
      fontSize: 12,
      marginLeft: 5,
      borderRadius: 30,
      height: 20,
      lineHeight: '20px',
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
