import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeTable from 'pages/CommunityPages/NoticePage/NoticeTable/index'
import React from 'react'

export default function NoticeListPage() {
  return (
    <CardWithTitle>
      <NoticeTable />
    </CardWithTitle>
  )
}
