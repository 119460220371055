import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import {ClientName} from 'components/atoms/ClientName'
import useStyles from 'components/Dialog/User/Style'
import {
  MY_INFO_DIALOG_TYPE,
  USER_INFO_DIALOG_TYPE,
} from 'constants/DialogConstant'
import {
  closeUserReadDialog,
  openUserEditDialog,
  selectUserDialogType,
  selectUserDialogUid,
  selectUserReadDialogOpen,
} from 'features/modal/modalSlice'
import useOrgUser from 'features/org/useOrgUser'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export function nullCheck(arg?: any): boolean {
  if (arg === undefined) return false
  if (arg === null) return false
  if (Array.isArray(arg)) {
    return arg.length !== 0
  }
  if (typeof arg === 'object') {
    return Object.keys(arg).length !== 0
  }
  return false
}

const UserReadDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectUserReadDialogOpen)
  const uid = useAppSelector(selectUserDialogUid)
  const dialogType = useAppSelector(selectUserDialogType)
  const {user: userDetailInfo, onFetch} = useOrgUser()

  useEffect(() => {
    if (open && uid) {
      onFetch(uid)
    }
  }, [open, uid])

  const handleClose = () => dispatch(closeUserReadDialog())

  const handleEditDialogOpen = () => {
    handleClose()
    dispatch(openUserEditDialog({uid, dialogType}))
  }

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={handleClose}>
      <Card className={classes.cardWrap}>
        <div className={classes.root}>
          <div
            className={classes.longTitleContainer}
            style={{backgroundColor: '#f9f9fb'}}
          >
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              {dialogType === MY_INFO_DIALOG_TYPE && (
                <Typography className={classes.title}>
                  {t('IViewMyInformation')}
                </Typography>
              )}
              {dialogType === USER_INFO_DIALOG_TYPE && (
                <Typography variant='h6' className={classes.title}>
                  {t('IUserRead')}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.scroll} style={{backgroundColor: '#f9f9fb'}}>
            <div className={classes.cardContent}>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <Typography
                  style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                >
                  {t('IBaseInfo')}
                </Typography>
                <Typography className={classes.smallPadding} />
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IFirstName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    <ClientName
                      firstName={userDetailInfo?.firstName ?? '-'}
                      lastName={userDetailInfo?.lastName ?? '-'}
                    />
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div
                  className={classes.contentContainer}
                  style={{flexWrap: 'nowrap'}}
                >
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IPassword')}
                  </Typography>
                  {dialogType === MY_INFO_DIALOG_TYPE && (
                    <Typography className={classes.subTitle}>****</Typography>
                  )}
                  {dialogType === USER_INFO_DIALOG_TYPE && (
                    <Typography
                      style={{flexWrap: 'nowrap', textAlign: 'left'}}
                      className={classes.subTitle}
                    >
                      <div style={{margin: '3px 2px 0 '}}>* </div>
                      {t('IPasswordDesc')}
                    </Typography>
                  )}
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IEmail')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.email ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ITel')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.tel ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.padding} />
              </div>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <Typography
                  style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                >
                  {t('IOrgInfo_short')}
                </Typography>
                <Typography className={classes.smallPadding} />
                <Typography className={classes.smallPadding} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IUserDepartment')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.deptName ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IUserDivision')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.division.title ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IUserPosition')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.position.title ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.padding} />
              </div>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <Typography
                  style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                >
                  {t('IMeasurementInformation')}
                </Typography>
                <Typography className={classes.smallPadding} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    NormDB EEG version
                  </Typography>
                  {nullCheck(userDetailInfo?.eegVersion) && (
                    <Typography className={classes.subTitle}>
                      {userDetailInfo?.eegVersion.version}
                    </Typography>
                  )}
                  {!nullCheck(userDetailInfo?.eegVersion) && (
                    <Typography className={classes.subContent}> - </Typography>
                  )}
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    NormDB HRV version
                  </Typography>
                  {nullCheck(userDetailInfo?.hrvVersion) && (
                    <Typography className={classes.subTitle}>
                      {userDetailInfo?.hrvVersion.version}
                    </Typography>
                  )}
                  {!nullCheck(userDetailInfo?.hrvVersion) && (
                    <Typography className={classes.subContent}> - </Typography>
                  )}
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IJoinDate')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {isoStringToDateAndTimeString(
                      userDetailInfo?.createdAt ?? '-',
                    )}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IAuthority')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {userDetailInfo?.authorityName ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.padding} />
              </div>

              <Typography className={classes.padding} />
              <Typography className={classes.padding} />
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='default'
                disableElevation
                onClick={handleClose}
                className={classes.defaultButton}
              >
                {t('IOk')}
              </Button>
              <Typography className={classes.smallPadding} />
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleEditDialogOpen}
                className={classes.okButtonColor}
              >
                {t('IEdit')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default UserReadDialog
