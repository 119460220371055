import {CircularProgress, makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import React from 'react'

export const loadingStyle = () =>
  createStyles({
    root: {
      // height: 'auto',
      // minHeight: '100vh',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: `rgba(${hexToRgb(blackColor)}, 0.2)`,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      marginBottom: '10px',
    },
    progress: {
      '&:before': {},
    },
  })
const useLoadingStyle = makeStyles(loadingStyle)
export const AppLoading = ({loading}: {loading: boolean}) => {
  const styles = useLoadingStyle()

  if (!loading) return null

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <span className={styles.label}>Loading</span>
        <CircularProgress className={styles.progress} />
      </div>
    </div>
  )
}
