import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  cancelPaymentApi,
  createCreditCardsApi,
  fetchDomesticListApi,
  fetchPaymentListApi,
  PaymentCancelRequest,
  paymentCompletedApi,
  PaymentCompletedRequest,
  RegisterCreditCardRequest,
  CurrencyCode,
} from 'api/paymentApi'
import {UserLogListRequest} from 'api/salesApi'
import {getDefaultPeriodString} from 'helpers/dateHelper'
import {UnPaidOrder} from 'pages/OrgPage/Interfaces/UnPaidOrder'
import {PaymentGatewayType} from 'pages/OrgPage/Interfaces/Enums'
import {RootState} from 'store'

interface PaymentState {
  loading: boolean
  error: any
  paymentList: PaymentContent[] | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  unpaged: boolean
  searchValue?: string | null
  searchName?: string | null
  period?: PeriodString | null
  packageId?: number[] | null
  order: UnPaidOrder
}

export const initialState: PaymentState = {
  loading: false,
  error: null,
  paymentList: [],
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 0,
  unpaged: true,
  searchValue: null,
  searchName: null,
  period: getDefaultPeriodString(),
  packageId: null,
  order: {
    id: '',
    orderNo: '',
    expiresAt: new Date().toISOString(),
    pgType: PaymentGatewayType.Toss,
    currencyCode: CurrencyCode.KRW,
    refundPrice: 0,
    totalPrice: 0,
    overduePrice: 0,
    bundles: [
      {
        name: '',
        bundleId: '',
        priceSet: {
          price: 0,
          currency: CurrencyCode.KRW,
          unitPurchase: 0,
        },
        analyses: [{code: ''}],
        analysisGroup: [''],
      },
    ],
    packages: [
      {
        name: '',
        bundleId: '',
        price: 0,
        analyses: [{code: ''}],
        analysisGroup: [''],
      },
    ],
  },
}

export const fetchPaymentAction = createAsyncThunk(
  'paymentSlice/fetchPayment',
  async (payload: UserLogListRequest) => {
    const response = await fetchPaymentListApi(payload)
    return response
  },
)

export const completePaymentAction = createAsyncThunk(
  'paymentSlice/completePayment',
  async (payload: PaymentCompletedRequest) => {
    const response = await paymentCompletedApi(payload)
    return response
  },
)

export const cancelPaymentAction = createAsyncThunk(
  'paymentSlice/cancelPayment',
  async (payload: PaymentCancelRequest) => {
    const response = await cancelPaymentApi(payload)
    return response
  },
)
export const fetchDomesticListAction = createAsyncThunk(
  'paymentSlice/fetchDomesticList',
  async () => {
    const response = await fetchDomesticListApi()
    return response
  },
)

export const createCreditCardAction = createAsyncThunk(
  'paymentSlice/createCreditCard',
  async (payload: RegisterCreditCardRequest) => {
    const response = await createCreditCardsApi(payload)
    return response
  },
)
interface PackageIdProps {
  packageId: number[]
}
export const addPackageIdsAction = createAsyncThunk(
  'paymentSlice/addPackageId',
  async (payload: PackageIdProps) => {
    console.log('slice', payload)
    return payload
  },
)

export const addPackageOrderAction = createAction(
  'paymentSlice/addPackageOrder',
  function packageOrder(order: UnPaidOrder) {
    return {
      payload: {
        order,
      },
    }
  },
)

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    completePaymentAction(
      state: PaymentState,
      // eslint-disable-next-line
      action: PayloadAction<PaymentCompletedRequest>,
    ) {
      state.loading = true
      state.error = null
    },
    fetchPaymentListRequest(
      state: PaymentState,
      action: PayloadAction<UserLogListRequest>,
    ) {
      state.loading = true
      state.error = null
      if (action.payload.search?.searchName) {
        state.searchName = action.payload.search?.searchName
      } else {
        state.searchName = null
      }
      if (action.payload.search?.searchValue) {
        state.searchValue = action.payload.search?.searchValue
      } else {
        state.searchValue = null
      }
      state.period = action.payload.period
    },
    fetchPaymentListSuccess(
      state: PaymentState,
      action: PayloadAction<Payment>,
    ) {
      state.loading = false
      state.error = null
      state.paymentList = action.payload.content
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.pageIndex = action.payload.pageable.pageNumber
      state.itemPerPage = action.payload.pageable.pageSize
      state.unpaged = action.payload.pageable.unpaged
    },
    fetchPaymentListError(state: PaymentState, action: PayloadAction<any>) {
      state.loading = false
      state.error = action.payload
    },
    cancelPaymentRequest(
      state: PaymentState,
      // eslint-disable-next-line
      action: PayloadAction<PackageIdProps>,
    ) {
      state.loading = true
      state.error = null
    },
    resetPaymentListRequest(state: PaymentState) {
      state.loading = false
      state.paymentList = initialState.paymentList
      state.searchName = null
      state.searchValue = null
      state.period = null
    },
    addPackgeIdsAction(
      state: PaymentState,
      action: PayloadAction<PackageIdProps>,
    ) {
      state.loading = true
      state.packageId = action.payload.packageId
    },
    addPackageOrder(state: PaymentState, action) {
      state.order = action.payload.order
      state.loading = true
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchPaymentAction.fulfilled, (state, action) => {
      state.loading = false
      state.paymentList = action.payload.data.content
    })
    builder.addCase(fetchPaymentAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(addPackageIdsAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(addPackageIdsAction.fulfilled, (state, action) => {
      state.loading = false
      state.packageId = action.payload.packageId
    })
    builder.addCase(addPackageIdsAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  fetchPaymentListRequest,
  fetchPaymentListSuccess,
  fetchPaymentListError,
  cancelPaymentRequest,
  addPackageOrder,
  resetPaymentListRequest,
} = paymentSlice.actions

export const selectPayment = (state: RootState) => state.payment
export const selectPaymentQuery = (state: RootState): UserLogListRequest => ({
  search: {
    searchName: state.payment.searchName ?? '',
    searchValue: state.payment.searchValue ?? '',
  },
  period: state.payment.period ?? null,
  paging: {
    page: state.payment.pageIndex ?? 0,
    size: state.payment.itemPerPage,
  },
})
export const selectPackageIds = (state: RootState) => state.payment.packageId
export const selectPackageOrders = (state: RootState) => state.payment.order

export default paymentSlice.reducer
