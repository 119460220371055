import Dialog from '@material-ui/core/Dialog'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import {useIcaSelectDialog} from 'features/analysis/useEegIndividualIcaDialog'
import useFailureModal from 'features/modal/useFailureModal'
import {StyledFieldSet} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledFieldSet'
import {StyledIcaDialog} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledIcaDialog'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

/**
 * Radio, Checkbox 구현
 */

type RadioValue<T> = {
  label: string
  value: T
}

type ReAnalysis = 'manual' | 'auto'

type ReAnalysisRadio = RadioValue<ReAnalysis>

const ReAnalysisRadioValue: ReAnalysisRadio[] = [
  {label: 'IReAnalysis_auto', value: 'auto'},
  {label: 'IReAnalysis_manual', value: 'manual'},
]

type ManualReAnalysis = 'maintain' | 're-select'

type ManualReAnalysisRadio = RadioValue<ManualReAnalysis>

const ManualReAnalysisRadioValue: ManualReAnalysisRadio[] = [
  {label: 'IReAnalysis_manual_re-select', value: 're-select'},
  {label: 'IReAnalysis_manual_maintain', value: 'maintain'},
]

function IcaSelectDialog() {
  const {t} = useTranslation()

  const {open, onClose, onConfirm} = useIcaSelectDialog()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [reAnalysis, setReAnalysis] = useState<ReAnalysis>('auto')
  const handleReAnalysisTypeChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setReAnalysis((event.target as HTMLInputElement).value as ReAnalysis)
  }

  const [manualReAnalysis, setManualReAnalysis] =
    useState<ManualReAnalysis>('re-select')
  const [isAgreeAutoDenoise, setIsAgreeAutoDenoise] = useState(false)
  const [isSraYn, setIsSraYn] = useState(false)

  const handleManualReAnalysisChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsSraYn(false)
    setManualReAnalysis(
      (event.target as HTMLInputElement).value as ManualReAnalysis,
    )
  }

  const handleClose = () => onClose()

  const handleConfirm = () => {
    if (reAnalysis === 'auto') {
      if (!isAgreeAutoDenoise) {
        onFailureModalOpen(t('IRegisterTermsNotAgree'))
        return
      }
      onConfirm({reAnalysisYn: 'N', reSelectYn: 'Y'})
    } else if (reAnalysis === 'manual' && manualReAnalysis === 'maintain') {
      onConfirm({
        reAnalysisYn: 'Y',
        reSelectYn: 'N',
        sraYn: isSraYn ? 'Y' : 'N',
      })
    } else if (reAnalysis === 'manual' && manualReAnalysis === 're-select') {
      onConfirm({
        reAnalysisYn: 'Y',
        reSelectYn: 'Y',
        sraYn: isSraYn ? 'Y' : 'N',
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledIcaDialog>
        <h3 className='header'>{t('IReAnalysis')}</h3>
        <div className='content'>
          <StyledFieldSet aria-label='ICA 종류' direction='row'>
            {ReAnalysisRadioValue.map((item) => (
              <label htmlFor={item.value} key={item.value}>
                <input
                  type='radio'
                  id={item.value}
                  name='ica'
                  value={item.value}
                  style={{marginTop: 3}}
                  checked={item.value === reAnalysis}
                  onChange={handleReAnalysisTypeChanged}
                />
                <span className='label'>{t(item.label)}</span>
              </label>
            ))}
          </StyledFieldSet>
          <div className='input-area' style={{padding: '20px 10px'}}>
            {reAnalysis === 'auto' && (
              <StyledFieldSet direction='column' style={{padding: '0 0 10px'}}>
                <label
                  htmlFor='agreeAutoDenoise'
                  style={{alignItems: 'flex-start'}}
                >
                  <input
                    type='checkbox'
                    id='agreeAutoDenoise'
                    name='agree_auto_denoise'
                    style={{marginTop: 4}}
                    checked={isAgreeAutoDenoise}
                    onChange={() => setIsAgreeAutoDenoise((b) => !b)}
                  />
                  <span className='label'>{t('IReAnalysis_agree')}</span>
                </label>
              </StyledFieldSet>
            )}
            {reAnalysis === 'manual' && (
              <StyledFieldSet direction='column' style={{padding: '0 0 10px'}}>
                {ManualReAnalysisRadioValue.map((item) => (
                  <label htmlFor={item.value} key={item.value}>
                    <input
                      type='radio'
                      id={item.value}
                      name='manual_ica'
                      value={item.value}
                      checked={item.value === manualReAnalysis}
                      onChange={handleManualReAnalysisChanged}
                    />
                    <span className='label'>{t(item.label)}</span>
                  </label>
                ))}
                <label htmlFor='signalRestoration'>
                  <input
                    type='checkbox'
                    id='signalRestoration'
                    name='agree_auto_denoise'
                    checked={isSraYn}
                    onChange={() => setIsSraYn((b) => !b)}
                    disabled={manualReAnalysis === 'maintain'}
                  />
                  <span
                    className='label'
                    style={{
                      opacity: manualReAnalysis === 'maintain' ? 0.7 : 1,
                    }}
                  >
                    {t('ISignalRestoration')}
                  </span>
                </label>
              </StyledFieldSet>
            )}
            <p>{t('IReAnalysis_notice')}</p>
          </div>
        </div>
        <div className='footer'>
          <CancelButton onClick={handleClose} />
          <ConfirmButton onClick={handleConfirm} />
        </div>
      </StyledIcaDialog>
    </Dialog>
  )
}

export default IcaSelectDialog
