import Fetcher from 'components/Fetcher/Fetcher'
import {fetchCsv} from 'helpers/csvHelper'
import Graph, {PowerSpectrumData} from 'pages/Popup/PowerSpectrum/graph'
import React, {useMemo} from 'react'
import {EEGResult} from 'api/analysisApi'
import {
  Channel,
  getPowerSpectrumSubType,
  PowerType,
} from 'constants/AnalysisConstant'
import {ParseResult} from 'papaparse'

interface PowerSpectrumGraphWrapperProps extends EEGResult {
  powerType: PowerType
  channel: Channel
}

export default function CsvFetcher(props: PowerSpectrumGraphWrapperProps) {
  const {resultDetailList, powerType, channel} = props

  const subType = useMemo(() => getPowerSpectrumSubType(powerType), [powerType])
  const item = useMemo(
    () =>
      resultDetailList
        .filter((item) => item.analysisType === '3')
        .find((item) => item.subType === subType),
    [subType],
  )
  const csvPath = useMemo(() => item?.psdPath, [item])
  const options = {header: true, delimiter: ',', download: true}

  return (
    <React.Fragment>
      {csvPath && (
        <Fetcher<ParseResult<PowerSpectrumData>>
          params={[csvPath, options]}
          fetchFn={fetchCsv}
        >
          {(data) => <Graph data={data.data} channel={channel} />}
        </Fetcher>
      )}
    </React.Fragment>
  )
}
