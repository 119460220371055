import request from 'api/index'
import {
  MultiTherapyHistory,
  MultiTherapyPrescription,
  MultiTherapySettings,
  TherapyMode,
  TreatmentStatus,
} from '../../@types'
import {PageDataResponseT} from './serviceFetcher'

// 임시 Fetch
export interface TherapyFetchRequest
  extends PagingRequest,
    OptionalPeriodRequest {
  uid: string
  uuid: string
}
export const fetchTherapyApi = ({paging, ...rest}: TherapyFetchRequest) =>
  request<PageResponse<Therapy>>({
    method: 'get',
    path: '/api/therapy/v1/list',
    params: {
      ...paging,
      ...rest,
    },
  })

// CYM : LED 테라피 - 고객 검색 테이블
interface TherapySearch {
  searchName?: TherapySearchKind | null
  searchValue?: string | null
}
export interface TherapySearchRequest extends PagingRequest {
  search: TherapySearch
  uid: string | null | undefined
}
export const searchTherapyApi = ({search, paging, uid}: TherapySearchRequest) =>
  request<PageResponse<Therapy>>({
    method: 'get',
    path: '/api/treatment/v2/therapy/latest-list',
    params: {...search, ...paging, uid},
  })

// CYM : LED 테라피 - 고객 검색 테이블에서 선택된 고객의 상세 테라피 예약 리스트
export interface TherapySelectRequest
  extends PagingRequest,
    OptionalPeriodRequest {
  uid: string
  uuid: string
}
export const selectTherapyApi = ({paging, ...rest}: TherapySelectRequest) =>
  request<PageResponse<TherapySelect>>({
    method: 'get',
    path: '/api/therapy/v1/list',
    params: {
      ...paging,
      ...rest,
    },
  })

// CYM : 처방 예약 버튼 클릭시 읽어오기
export interface TherapyReadRequest {
  uid: string
  uuid: string
}
export const readTherapyReservationApi = ({uid, uuid}: TherapyReadRequest) =>
  request<ModelResponse<TherapyReservationRead>>({
    method: 'get',
    path: '/api/therapy/v1/reservation/prescription',
    params: {uid, uuid},
  })

export interface TherapyEditRequest extends TherapySettings {
  uid?: string
  uuid?: string
}
export const editTherapyApi = (data: TherapyEditRequest) =>
  request<ModelResponse<TherapySettings>>({
    method: 'post',
    path: 'api/therapy/v1/reservation/prescription',
    data: {
      ...data,
    },
  })

export interface TherapyAddRequest extends TherapySettings {
  uid?: string
  uuid?: string
}
export const addTherapyApi = (data: TherapyAddRequest) =>
  request<ModelResponse<TherapySettings>>({
    method: 'post',
    path: 'api/therapy/v1/reservation/prescription',
    data: {
      ...data,
    },
  })

export interface TherapyHistoryRequest {
  careId?: string
  uid?: string
}

/**
 * 멀티 테라피 치료 내역 정보
 * @param data
 */
export const getTherapyHistoryApi = (data: TherapyHistoryRequest) =>
  request<ModelResponse<MultiTherapyHistory>>({
    method: 'post',
    path: 'api/therapy/v2/history',
    data: {
      ...data,
    },
  })

/**
 * 멀티 테라피 치료 내역 리스트
 * @param paging
 * @param rest
 */
export const selectTherapyApiV2 = ({paging, ...rest}: TherapySelectRequest) =>
  request<PageResponse<MultiTherapyHistory>>({
    method: 'get',
    path: '/api/therapy/v2/list',
    params: {
      ...paging,
      ...rest,
    },
  })

export interface TherapyCommonRequest {
  uid: string
  uuid: string
}
/**
 * 멀티 테라피 최근 치료 내역 리스트
 * @param data
 */
export const selectTherapyLatestApiV2 = (data: TherapyCommonRequest) =>
  request<PageResponse<MultiTherapyHistory>>({
    method: 'get',
    path: '/api/therapy/v2/latest',
    params: {
      ...data,
    },
  })

export interface MultiTherapyGroupAdd {
  uid: string
  uuid: string
  dataset: TherapySettings[]
  recommender: string
  reservationGroupId: number
  therapyMode: TherapyMode
}

export interface MultiTherapyGroupAddResp {
  id: number
  dataIdList: number[]
}

export interface MultiTherapyAdd {
  uid: string
  uuid: string
  dataset: TherapySettings[]
  therapyId: number
}

type PbmType = 'THERAPY'

interface PresetList {
  name?: string
  pbmType: PbmType
  sequence: number
  presetDataId: string
  groupId: string
}
export interface TherapyCustomerList {
  name?: string
  description?: string
  registerCrewId: string
  latestModifierCrewId: string
  createAt: string
  updateAt: string
  customerId: string
  presetList: PresetList[]
  id: string
}

export interface PresetElectrodesResponseType extends PresetList {
  frequency: number
  duration: number
  electrode: MultiTherapySettings
}

export interface PresetElectrodesResponse
  extends PageDataResponseT<PresetElectrodesResponseType> {}

export const AddMultiTherapyGroupApi = (data: MultiTherapyGroupAdd) =>
  request<ModelResponse<MultiTherapyGroupAddResp>>({
    method: 'post',
    path: '/api/therapy/v2/request',
    data: {
      ...data,
    },
  })

export interface MultiTherapyStatus {
  status: TreatmentStatus
  therapyDataId: number
  therapyTime: number
}

export interface MultiTherapyStatusUpdate {
  uid: string
  therapyGroupId: number
  statusList: MultiTherapyStatus[]
}

export const AddMultiTherapyApi = (data: MultiTherapyAdd) =>
  request<ModelResponse<MultiTherapyGroupAddResp>>({
    method: 'post',
    path: '/api/therapy/v2/request/add',
    data: {
      ...data,
    },
  })

export interface MultiTherapyPrescriptionListReq {
  uid: string
  uuid: string
  dataList: MultiTherapyPrescription[]
  label: string
}

export interface MultiTherapyPrescriptionListResp {
  uid: string
  uuid: string
  dataList: MultiTherapyPrescription[]
  label: string
  id: number
}

export interface MultiTherapyPrescriptionOneReq {
  uid: string
  uuid: string
  groupId: number
}

export const MultiTherapyPrescriptionRegApi = (
  data: MultiTherapyPrescriptionListReq,
) =>
  request<ModelsResponse<MultiTherapyPrescriptionListResp>>({
    method: 'post',
    path: '/api/therapy/v2/reservation/prescription',
    data: {
      ...data,
    },
  })

export const MultiTherapyPrescriptionOneApi = (
  data: MultiTherapyPrescriptionOneReq,
) =>
  request<ModelResponse<MultiTherapyPrescriptionListResp>>({
    method: 'get',
    path: '/api/therapy/v2/reservation/prescription/one',
    params: {
      ...data,
    },
  })

export const MultiTherapyPrescriptionListApi = (data: TherapyCommonRequest) =>
  request<ModelsResponse<MultiTherapyPrescriptionListResp>>({
    method: 'get',
    path: '/api/therapy/v2/reservation/prescription/list',
    params: {
      ...data,
    },
  })

export const MultiTherapyPrescriptionUpdateApi = (
  groupId: number,
  data: MultiTherapyPrescriptionListReq,
) =>
  request<ModelsResponse<MultiTherapyPrescriptionListResp>>({
    method: 'post',
    path: `/api/therapy/v2/reservation/prescription/modify?groupId=${groupId}`,
    data: {
      ...data,
    },
  })

export const MultiTherapyPrescriptionDeleteApi = (
  data: MultiTherapyPrescriptionOneReq,
) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/therapy/v2/reservation/prescription/delete',
    params: {
      ...data,
    },
  })

export const MultiTherapyStatusUpdateApi = (data: MultiTherapyStatusUpdate) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/therapy/v2/status',
    data: {
      ...data,
    },
  })
