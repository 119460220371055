import {numberWithCommas} from 'helpers/commonHelper'

export default class UsageHistoryTableItemsModel {
  constructor(raw: UsageHistoryTableItemsModel) {
    this.createdAt = raw.createdAt
    this.orderNo = raw.orderNo
    this.userName = raw.userName
    this.package = raw.package
    this.bundle = raw.bundle
    this.failures = raw.failures
    this.isRefund = raw.isRefund
    this.remainAmount = raw.remainAmount
    this.chargeAmount = raw.chargeAmount
  }

  createdAt: string

  orderNo: string

  userName: string

  package: {name: string; analyses: {code: string}[]; analysisGroup: string[]}

  bundle: {name: string; analyses: {code: string}[]; analysisGroup: string[]}

  failures: string[]

  isRefund: boolean

  remainAmount: number | null

  chargeAmount: number | null

  getOrderNo: () => string = () => {
    return this.isRefund ? `${this.orderNo}R` : this.orderNo
  }

  getStatus: () => string = () => {
    let text = 'ISucceed'
    if (this.isRefund) text = 'IRefund'
    if (
      (this.package && this.failures.length === this.package.analyses.length) ||
      (this.bundle && this.failures.length === this.bundle.analyses.length)
    )
      text = 'IFailed'
    else if (this.failures.length > 0) text = this.failures.join(', ')

    return text
  }

  getRemainAmount: () => number | string = () => {
    return this.remainAmount ?? 'IUnlimited'
  }

  // TODO: CYM - currencyCode 추가하기
  getChargeAmount: () => number | string = () => {
    let text =
      (this.chargeAmount && `${numberWithCommas(this.chargeAmount)}`) ?? 0

    if (this.isRefund) text = `- ${text}`

    return text
  }
}
