import {PurchaseBundle, PurchasePackage} from 'api/paymentApi'

export default function IncludedProductsDuplicateViewModel() {
  // 구매하려는 상품과 구매하려는 상품을 비교
  function comparePurchasingToPurchasing(
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
    rowIdx: number, // rowIdx는 package는 그냥 보내고 bundle은 번들 idx + packages.length하자
    compareItem: string,
  ) {
    let isDuplicated = false

    // 패키지+번들 변수
    const purchasingProducts = [...packages, ...bundles]

    purchasingProducts.find((product, idx) => {
      // rowIdx(자기 자신)을 제외하고 비교
      if (idx === rowIdx) return null

      product.analyses.find((item) => {
        if (compareItem === item.code) isDuplicated = true
        return null
      })

      return null
    })

    return isDuplicated
  }

  return {
    comparePurchasingToPurchasing,
  }
}
