import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  addCommentApi,
  CommentAddRequest,
  CommentDeleteRequest,
  CommentPageRequest,
  deleteCommentApi,
  fetchCommentsApi,
} from 'api/commentApi'
import {
  openSuccessModalAction,
  openFailureModalAction,
} from 'features/modal/alertSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const openCommentWriteDialog = createAction<string>(
  'comment/write_dialog/open',
)
export const closeCommentWriteDialog = createAction(
  'comment/write_dialog/close',
)

export const fetchCommentPage = createAsyncThunk(
  'api/comment/page',
  async (payload: CommentPageRequest, {rejectWithValue}) => {
    try {
      const response = await fetchCommentsApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const createComment = createAsyncThunk(
  'comment/create',
  async (payload: CommentAddRequest, {dispatch, rejectWithValue, getState}) => {
    try {
      const response = await addCommentApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ICommentCreateSuccess')))

      const {uuid} = payload
      const page = (getState() as RootState).comment.pageIndex
      const size = (getState() as RootState).comment.itemPerPage
      await fetchCommentPage({uuid, paging: {page, size}})

      return response.data
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export const deleteComment = createAsyncThunk(
  'comment/delete',
  async (payload: CommentDeleteRequest, {rejectWithValue, dispatch}) => {
    try {
      const result = await deleteCommentApi(payload)
      return result
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue({status: 'failure', message: err.message})
    }
  },
)

interface CommentState {
  uuid: string | null
  loading: boolean
  error: any
  comments: CommentModel[]
  pageIndex: number
  totalPages: number
  itemPerPage: number
  comment: CommentModel | null
  writeDialogOpen: boolean
}

export const initialState: CommentState = {
  uuid: null,
  loading: false,
  error: null,
  comments: [],
  pageIndex: 0,
  totalPages: 1,
  itemPerPage: 10,
  comment: null,
  writeDialogOpen: false,
}

export const commentSlice = createSlice({
  name: 'commentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(openCommentWriteDialog, (state, action) => {
      state.uuid = action.payload
      state.writeDialogOpen = true
    })
    builder.addCase(closeCommentWriteDialog, (state) => {
      state.writeDialogOpen = false
    })
    builder.addCase(fetchCommentPage.pending, (state, action) => {
      state.loading = true
      const {uuid, paging} = action.meta.arg
      state.uuid = uuid
      state.pageIndex = paging.page
      state.itemPerPage = paging.size
    })
    builder.addCase(fetchCommentPage.fulfilled, (state, action) => {
      state.comments = action.payload.content
      state.totalPages = action.payload.totalPages
      state.loading = false
    })
    builder.addCase(fetchCommentPage.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectLoading = (state: RootState) => state.comment.loading
export const selectError = (state: RootState) => state.comment.error
export const selectUuid = (state: RootState) => state.comment.uuid
export const selectData = (state: RootState) => state.comment.comments
export const selectQuery = (state: RootState): CommentPageRequest => ({
  uuid: state.comment.uuid ?? '',
  paging: {
    page: state.comment.pageIndex,
    size: state.comment.itemPerPage,
  },
})
export const selectPaging = (state: RootState) => ({
  pageIndex: state.comment.pageIndex,
  itemPerPage: state.comment.itemPerPage,
  totalPages: state.comment.totalPages,
})
export const selectDialogOpen = (state: RootState) =>
  state.comment.writeDialogOpen

export default commentSlice.reducer
