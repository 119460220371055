import React from 'react'
import {fetchUsageHistoryApi, UsageHistoryBeginEndDate} from 'api/paymentApi'
import useOrganization from 'features/org/useOrganization'
import UsageHistoryTableModel from 'pages/OrgPage/PaymentManagementPages/Models/UsageHistoryTableModel'
import {
  dateToDashString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {utils, writeFile} from 'xlsx'
import UsageHistoryTableItemsModel from 'pages/OrgPage/PaymentManagementPages/Models/UsageHistoryTableItemsModel'
import {getPackageFullName} from 'helpers/paymentHelper'
import {useTranslation} from 'react-i18next'
import useFailureModal from 'features/modal/useFailureModal'

export default function UsageHistoryTableViewModel() {
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {organization} = useOrganization()
  const oid = organization?.oid ?? ''

  const [isUsageHistoryListLoading, setIsUsageHistoryListLoading] =
    React.useState<boolean>(false)
  const [usageHistoryList, setUsageHistoryList] =
    React.useState<UsageHistoryTableModel>({} as UsageHistoryTableModel)

  const startDate = new Date()
  const endDate = new Date()

  // 파라미터 초기값: beginDate, endDate
  const initialDate: UsageHistoryBeginEndDate = {
    begin: '2000-01-01',
    end: '2300-12-31',
  }
  const [beginEndDate, setBeginEndDate] =
    React.useState<UsageHistoryBeginEndDate>(initialDate)

  // Period Search props selection
  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate,
    endDate,
  })

  // 파라미터 초기값: page, size
  const initialPaging: Paging = {
    page: 1,
    size: 10,
  }

  // Paging State
  const [pagingState, setPagingState] = React.useState<Paging>(initialPaging)

  // Payment History Api 호출
  async function getUsageHistory(
    date?: UsageHistoryBeginEndDate,
    paging?: Paging,
  ) {
    setIsUsageHistoryListLoading(true)
    try {
      let params = {...initialPaging}
      if (date) {
        params = {...params, ...date}
      }
      if (paging) {
        params = {...params, ...paging}
      }

      const result = await fetchUsageHistoryApi(oid, params)
      const UsageHistory = new UsageHistoryTableModel(result)

      setUsageHistoryList(UsageHistory)
      setIsUsageHistoryListLoading(false)
    } catch (e) {
      console.log('Error:::: getUsageHistory:::: ', e)
      setIsUsageHistoryListLoading(false)
    }
  }

  // Handle Period Search button
  const handlePeriod = (period: PeriodDate) => {
    const begin = dateToDashString(period.startDate)
    const end = dateToDashString(period.endDate)
    const paging = {...pagingState, page: 1}

    getUsageHistory({begin, end}, paging)

    setSelection((prev) => {
      const newData = {
        ...prev,
        startDate: period.startDate,
        endDate: period.endDate,
      }
      return newData
    })

    setBeginEndDate((prev) => {
      const newData = {...prev, begin, end}
      return newData
    })

    setPagingState((prev) => {
      const newData = {...prev, page: 1}
      return newData
    })
  }

  // 페이지 prev, next 버튼 클릭시 page 설정
  const setPageIndex = (pageIdx: number) => {
    const paging = {...pagingState, page: pageIdx + 1}
    getUsageHistory(beginEndDate, paging)
    setPagingState(paging)
  }

  // handle page size change(페이지의 사이즈 변경 셀렉트박스 핸들러
  const handlePageSizeChanged = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    const paging = {page: 1, size}

    getUsageHistory(beginEndDate, paging)

    setPagingState((prev) => {
      const newData = {...prev, ...paging}
      return newData
    })
  }

  // Excel용 PaymentHistoryList 가져오기
  async function getPaymentHistoryForExcel() {
    const maximumSize = 100

    try {
      const params = {
        ...beginEndDate,
        ...pagingState,
        page: 1,
        size: maximumSize,
      }
      const result = await fetchUsageHistoryApi(oid, params)
      let usageHistoryListForExcel: (string | number)[][] = [[]]

      // 데이터 가져오면 무조건 1번은 넣기
      if (result) {
        const usageHistory = new UsageHistoryTableModel(result)

        usageHistoryListForExcel = usageHistory.items.map(
          (item: UsageHistoryTableItemsModel) => {
            let productName = ''
            let productAnalyses = ''

            if (item.package) {
              productName = item.package.name
              productAnalyses = item.package.analyses
                .map(({code}) => {
                  return t(getPackageFullName(code))
                })
                .join(', ')
            } else if (item.bundle) {
              productName = item.bundle.name
              productAnalyses = item.bundle.analyses
                .map(({code}) => {
                  return t(getPackageFullName(code))
                })
                .join(', ')
            }

            return [
              isoStringToDateAndTimeString(item.createdAt),
              item.getOrderNo(),
              item.userName,
              productName,
              productAnalyses,
              item.getStatus() !== 'ISucceed' && item.getStatus() !== 'IFailed'
                ? `${item.getStatus()} ${t('IError')}`
                : t(item.getStatus()),
              item.getRemainAmount() === 'IUnlimited'
                ? t(item.getRemainAmount() as string)
                : String(item.getRemainAmount()),
              String(item.getChargeAmount()),
            ]
          },
        )
      }

      // size가 최대 100이기 때문에 total이 100 이상일 때 확인해서 더 넣어주기
      if (result.total > maximumSize) {
        const counts = Math.ceil(result.total / maximumSize)
        const countsArr = Array.from({length: counts}, (v, i) => i + 1)

        countsArr.map(async (count) => {
          // page 1은 이미 받아왔기 때문에 2부터
          if (count > 1) {
            const newParams = {...params, page: count}
            const results = await fetchUsageHistoryApi(oid, newParams)
            const usageHistory = new UsageHistoryTableModel(results)

            const usageHistoryListForExcelRow = usageHistory.items.map(
              (item: UsageHistoryTableItemsModel) => {
                let productName = ''
                let productAnalyses = ''

                if (item.package) {
                  productName = item.package.name
                  productAnalyses = item.package.analyses
                    .map(({code}) => {
                      return t(getPackageFullName(code))
                    })
                    .join(', ')
                } else if (item.bundle) {
                  productName = item.bundle.name
                  productAnalyses = item.bundle.analyses
                    .map(({code}) => {
                      return t(getPackageFullName(code))
                    })
                    .join(', ')
                }

                return [
                  isoStringToDateAndTimeString(item.createdAt),
                  item.getOrderNo(),
                  item.userName,
                  productName,
                  productAnalyses,
                  item.getStatus() !== 'ISucceed' &&
                  item.getStatus() !== 'IFailed'
                    ? `${item.getStatus()} ${t('IError')}`
                    : t(item.getStatus()),
                  item.getRemainAmount() === 'IUnlimited'
                    ? t(item.getRemainAmount() as string)
                    : String(item.getRemainAmount()),
                  String(item.getChargeAmount()),
                ]
              },
            )

            usageHistoryListForExcel.push(...usageHistoryListForExcelRow)
          }
          return null
        })
      }

      return usageHistoryListForExcel
    } catch (e) {
      console.log('Error:::: getPaymentHistoryForExcel:::: ', e)
    }

    return null
  }

  // Handle Excel Export button
  const handleExcelExport = async (header: string[][]) => {
    const result = await getPaymentHistoryForExcel()
    console.log('handleExcelExport: ', result)

    if (result && result.length > 0) {
      const wb = utils.book_new()
      const ws = utils.json_to_sheet(result)
      ws['!cols'] = [
        {wpx: 120},
        {wpx: 100},
        {wpx: 80},
        {wpx: 150},
        {wpx: 500},
        {wpx: 100},
        {wpx: 100},
        {wpx: 100},
      ]
      utils.sheet_add_aoa(ws, header)
      utils.book_append_sheet(wb, ws, t('IUsageHistory'))
      writeFile(wb, `${t('IUsageHistory')}.xlsx`)
    } else {
      onFailureModalOpen(t('IUsageHistoryEmpty'))
    }
  }

  return {
    isUsageHistoryListLoading,
    usageHistoryList,
    getUsageHistory,
    selection,
    pagingState,
    handlePeriod,
    setPageIndex,
    handlePageSizeChanged,
    handleExcelExport,
  }
}
