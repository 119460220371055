import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import NoticePage from 'pages/CommunityPages/NoticePage'
import NoticeEditPage from 'pages/CommunityPages/NoticePage/NoticeEditPage'
import NoticeReadPage from 'pages/CommunityPages/NoticePage/NoticeReadPage'
import NoticeWritePage from 'pages/CommunityPages/NoticePage/NoticeWritePage'
import QuestionPage from 'pages/CommunityPages/QuestionPage'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

/**
 * 커뮤니티 라우트
 */
export default function CommunityRoute() {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.COMMUNITY_NOTICE_WRITE.path}
          exact
          component={NoticeWritePage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.COMMUNITY_NOTICE_READ.path}
          exact
          component={NoticeReadPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.COMMUNITY_NOTICE_EDIT.path}
          exact
          component={NoticeEditPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.COMMUNITY_QUESTION.path}
          exact
          component={QuestionPage}
        />
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.COMMUNITY_NOTICE.path}
          exact
          component={NoticePage}
        />
        {/* <TreatmentPage /> */}
        <Route path='*'>
          <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
        </Route>
      </Switch>
    </CommonLayout>
  )
}
