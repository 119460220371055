import React from 'react'
import TextTitle from 'components/V2Text/TextTitle'
import styled from 'styled-components'
import {
  CustomTitleModalPageTitleStyles,
  ModalPageTitleMobileStyles,
  ModalPageTitleStyles,
} from 'components/V2Text/Styles'
import {SvgIcon} from 'components/common/useSvgIcons'
// import useBreakpoints, {Breakpoints} from 'features/ui/useBreakpoints'
import Media from 'theme/Media'
import {
  CloseButtonWrapStyleProps,
  ModalAllStyleProps,
  ModalHeaderWrapStyleProps,
  ModalCommonStyleProps,
} from '../Styles'
import useBreakpoints, {
  Breakpoints,
} from '../../../../features/ui/useBreakpoints'

const StyledModalHeaderWrap = styled.div<
  ModalHeaderWrapStyleProps & ModalCommonStyleProps
>`
  display: flex;
  justify-content: ${(props) => props.textAlign || 'center'};
  padding-top: ${(props) => props.paddingHeaderTopMobile}px;
  padding-bottom: ${(props) => props.paddingHeaderBottomMobile}px;
  padding-left: ${(props) => props.paddingLeftMobile}px;
  padding-right: ${(props) => props.paddingRightMobile}px;
  border-width: ${(props) => props.headerBorderWidth || 'none'};
  border: ${(props) => props.headerBorderStyle || 'none'};
  position: relative;

  ${(props) => Media.min_601`
    padding-top: ${props.paddingHeaderTopTablet}px;
    padding-right: ${props.paddingRightTablet}px;
    padding-bottom: ${props.paddingHeaderBottomTablet}px;
    padding-left: ${props.paddingLeftTablet}px;
  `}

  h2, h3, h4, h5 {
    padding: 0 30px;
    text-align: center;
    margin-block: 0;
  }
`

const StyledCloseButtonWrap = styled.button<CloseButtonWrapStyleProps>`
  display: inline-flex;
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;

  svg {
    width: ${(props) => props.closeIconWidth}px;
    height: ${(props) => props.closeIconHeight}px;
  }
`

export interface ModalHeaderProps {
  title: string
  handleClose: () => any
  theme: ModalAllStyleProps
}

function ModalHeader({title, handleClose, theme}: ModalHeaderProps) {
  // const {breakpoints} = useBreakpoints()

  const styleThemeFilter = () => {
    if (theme.titleFontSize) {
      return CustomTitleModalPageTitleStyles(
        theme.titleFontSize,
        theme?.titleTextColor,
      )
    }
    // if (breakpoints === Breakpoints.smMobile) {
    //   return ModalPageTitleMobileStyles
    // }
    return ModalPageTitleStyles
  }

  return (
    <StyledModalHeaderWrap {...theme}>
      <TextTitle as='h4' title={title} theme={styleThemeFilter()} />
      <StyledCloseButtonWrap
        onClick={handleClose}
        closeIconWidth={theme.closeIconWidth}
        closeIconHeight={theme.closeIconHeight}
        closeIconColor={theme.closeIconColor}
      >
        <SvgIcon name='close' iconColor={theme.closeIconColor} />
      </StyledCloseButtonWrap>
    </StyledModalHeaderWrap>
  )
}

export default ModalHeader
