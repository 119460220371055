import React, {useEffect} from 'react'

import {useLocation} from 'react-router-dom'
import {sha256} from 'js-sha256'
import UnPaidOrderModel from '../OrgPage/PaymentManagementPages/Models/UnPaidOrderModel'

const EximbayResponses = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const orderInfo = (window.opener as any).getOrderInfo() as UnPaidOrderModel
  // 가맹점 secretkey
  const {secretKey} = orderInfo.getEximBayInfo()
  const makeFgkey = (secrentKey: any, params: URLSearchParams) => {
    // eximbay 의 형식에 맞게 hash 키 생성
    // acending sort by key
    params.sort()
    const sortedKeys = Array.from(params.keys())
    const query = sortedKeys.reduce((acc, key, i) => {
      if (key === 'fgkey') return acc
      const value = params.get(key)
      const prefix = i === 0 ? '' : '&'
      const res = `${prefix}${key}=${value}`
      return acc + res
    }, '')

    return sha256(`${secrentKey}?${query}`) // sha256을 활용해서 해시처리
  }

  const shaKey = makeFgkey(secretKey, params)

  const checkHashed = () => {
    const fgKey = (params.get('fgkey') as string).toLocaleLowerCase()
    return fgKey === shaKey.toLowerCase()
  }

  useEffect(() => {
    const resCode = params.get('rescode') ?? null
    const resMsg = params.get('resmsg') ?? null
    const isValidate = checkHashed()
    const p = window.opener as any
    p.successCallback({
      isValidate,
      resCode,
      resMsg,
    })
    window.close()
  }, [])

  return <></>
}

export default EximbayResponses
