import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  getProductsMyOwn,
  patchAutoBillingStatus,
} from '../../../../../api/paymentApi'
import MyProductModel from '../../Models/MyProductModel'

export default function ProducDetailViewModel() {
  const {t} = useTranslation()

  const [myProducts, setMyProducts] = useState<MyProductModel>({} as never)
  const [isLoaded, setLoaded] = useState(false)
  const [packagePauses, setPackagePauses] = useState<boolean[]>([])
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  async function getMyProducts() {
    const result = await getProductsMyOwn()
    if (result) {
      const products = new MyProductModel(result, t)
      setMyProducts(products)
      const pauses = products.packages.map((item) => {
        return item.isPause
      })
      setPackagePauses(pauses)
      setLoaded(true)
      //
    } else {
      //
    }
  }

  // toggle status for auto billing
  async function toggleAutoBilling(order: number) {
    const {packageId, isPause} = myProducts.packages[order]

    if (!isPause) {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeStopAutoPayment'),
      }).then((result) => {
        if (result.payload) {
          // result.payload가 true면 confirm
          // 버튼 텍스트 정지 -> 재시작으로 변경 필요
          // after api processing
          myProducts.packages[order].isPause = !isPause

          const pauses = packagePauses.slice()

          pauses[order] = myProducts.packages[order].isPause
          setPackagePauses(pauses)

          patchAutoBillingStatus(packageId, !isPause)
        }
      })
    }

    // 자동결제가 재시작인가?
    else {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeRestartAutoPayment'),
      }).then((result) => {
        if (result.payload) {
          // result.payload가 true면 confirm
          // 버튼 텍스트 재시작 -> 정지로 변경 필요
          myProducts.packages[order].isPause = !isPause

          const pauses = packagePauses.slice()
          pauses[order] = myProducts.packages[order].isPause
          setPackagePauses(pauses)

          patchAutoBillingStatus(packageId, !isPause)
        }
      })
    }
  }

  function isPauseForPackage(order: number) {
    return myProducts.packages[order].isPause
  }

  return {
    getMyProducts,
    toggleAutoBilling,
    isPauseForPackage,
    isLoaded,
    myProducts,
  }
}
// function onNoticeDialogOpen(arg0: {title: string; message: string}) {
//   throw new Error('Function not implemented.')
// }
