import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import {makeStyles} from '@material-ui/core'
import Button, {ButtonProps} from '@material-ui/core/Button'
import {createStyles} from '@material-ui/core/styles'

interface AsyncButtonProps extends ButtonProps {
  color?: 'secondary' | 'primary' | 'default'
  error?: boolean
  onClick: () => Promise<void>
  disabled?: boolean
}

const styles = () =>
  createStyles({
    root: {
      minWidth: 87,
      height: 40,
      borderRadius: 6,
    },
    error: {},
  })
const useStyles = makeStyles(styles)

const AsyncButton = ({
  className,
  error = false,
  children,
  color = 'secondary',
  onClick,
  disabled,
  ...props
}: AsyncButtonProps) => {
  const classes = useStyles()

  const AsyncButtonClasses = classNames({
    [classes.root]: true,
    [classes.error]: error,
    // @ts-ignore
    [className]: className !== undefined,
  })

  const [loading, setLoading] = useState(false)

  const asyncOnClick = async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  useEffect(() => {
    if (typeof disabled === 'boolean' && disabled !== loading) {
      setLoading(disabled)
    }
  }, [disabled])

  return (
    <Button
      variant='contained'
      color={color}
      disableElevation
      disabled={loading}
      className={AsyncButtonClasses}
      onClick={asyncOnClick}
      {...props}
    >
      {children}
    </Button>
  )
}

export default AsyncButton
