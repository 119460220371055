import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getDefaultPeriodString} from 'helpers/dateHelper'
import {RootState} from 'store'

interface SalesAllOrgState {
  loading: boolean
  error: any
  allOrganizationList?: AllOrganizationContent[] | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  unpaged: boolean
  searchValue?: string | null
  searchName?: string | null
  selectedOid?: string | null
  period?: PeriodString | null
}

export const initialState: SalesAllOrgState = {
  loading: false,
  error: null,
  allOrganizationList: [],
  pageIndex: 0,
  totalPages: 0,
  totalElements: 0,
  itemPerPage: 5,
  unpaged: true,
  searchValue: null,
  searchName: null,
  selectedOid: null,
  period: getDefaultPeriodString(),
}

export const salesAllOrgSlice = createSlice({
  name: 'salesAllOrgSlice',
  initialState,
  reducers: {
    fetchAllOrganizationRequest(state: SalesAllOrgState) {
      state.loading = true
      state.error = null
    },
    fetchAllOrganizationSuccess(
      state: SalesAllOrgState,
      action: PayloadAction<AllOrganization>,
    ) {
      state.loading = false
      state.error = null
      state.allOrganizationList = action.payload.content
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.pageIndex = action.payload.pageable.pageNumber
      state.itemPerPage = action.payload.pageable.pageSize
      state.unpaged = action.payload.pageable.unpaged
    },
    fetchAllOrganizationError(
      state: SalesAllOrgState,
      action: PayloadAction<any>,
    ) {
      state.loading = false
      state.error = action.payload
    },
    selectAllOrganizationOid(
      state: SalesAllOrgState,
      action: PayloadAction<string>,
    ) {
      state.selectedOid = action.payload
    },
    resetSelectedOidRequest(state: SalesAllOrgState) {
      state.selectedOid = initialState.selectedOid
    },
  },
})

export const {
  fetchAllOrganizationRequest,
  fetchAllOrganizationSuccess,
  fetchAllOrganizationError,
  selectAllOrganizationOid,
  resetSelectedOidRequest,
} = salesAllOrgSlice.actions

export const selectSalesAllOrganization = (state: RootState) =>
  state.salesAllOrg
export const selectAllOrgOid = (state: RootState) =>
  state.salesAllOrg.selectedOid

export default salesAllOrgSlice.reducer
