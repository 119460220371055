import {TFunction} from 'react-i18next'
import {PurchasedProducts} from 'api/paymentApi'
import PurchasedPackageModel from './PurchasedPackageModel'
import PurchasedBundleModel from './PurchasedBundleModel'

export default class PurchasedProductsModel implements PurchasedProducts {
  bundles: [PurchasedBundleModel]

  packages: [PurchasedPackageModel]

  constructor(raw: PurchasedProducts, t: TFunction) {
    this.bundles = raw.bundles.map((item) => {
      return new PurchasedBundleModel(item)
    }) as [PurchasedBundleModel]
    this.packages = raw.packages.map((item) => {
      return new PurchasedPackageModel(item, t)
    }) as [PurchasedPackageModel]
  }
}
