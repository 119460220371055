import {getLangSession} from 'api/storageApi'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export default function useLanguageReport() {
  const {i18n} = useTranslation()

  useEffect(() => {
    const lang = getLangSession()
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [])
}
