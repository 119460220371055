import React from 'react'
import styled from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const CheckBox = styled.label<{checked: boolean}>`
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid ${({checked}) => (checked ? `#0cc7c7` : `#6d6e6f`)};
  background: ${({checked}) => (checked ? `#0cc7c7` : `#fff`)};
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    position: absolute;
  }
`

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Label = styled.label`
  display: inline-block;
  line-height: 16px;
  padding-left: 5px;
  cursor: pointer;
`

interface CheckboxProps {
  id: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string | null
}

export default function CustomCheckbox({
  id,
  checked,
  onChange,
  label,
}: CheckboxProps) {
  return (
    <CheckboxContainer>
      <CheckBox checked={checked} htmlFor={id}>
        <HiddenCheckbox
          id={id}
          type='checkbox'
          onChange={onChange}
          checked={checked}
        />
        <CheckIcon
          className='arrowIcon'
          style={{
            width: 13,
            height: 13,
            marginBottom: 3,
            color: 'white',
            border: 1,
            visibility: checked ? 'visible' : 'hidden',
          }}
        />
        {/* <Icon width='14px' height='14px' /> */}
      </CheckBox>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
    </CheckboxContainer>
  )
}
