import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  OrgDetailInfoEditRequest,
  updateOrgDetailInfoApi,
} from 'api/organizationApi'
import {
  fetchAccountHistoryApi,
  fetchListAccountByIdApi,
  fetchOrgApi,
  fetchOrgHistoryApi,
} from 'api/orgApi'
import {AllPackages, organizationRegisterPackageListApi} from 'api/paymentApi'
import {RootState} from 'store'

export const readHistoryOrg = createAsyncThunk(
  'api/org/history',
  async (payload: UserUsageHistoryRequest, {rejectWithValue}) => {
    try {
      const response = await fetchOrgHistoryApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const readHistoryAccountUsage = createAsyncThunk(
  'api/org/history/user',
  async (payload: UserUsageHistoryRequest, {rejectWithValue}) => {
    try {
      const response = await fetchAccountHistoryApi(payload)
      return response.list
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)
export const readAccountById = createAsyncThunk(
  'api/org/history/user',
  async (payload: AccountByIdRequest, {rejectWithValue}) => {
    try {
      const response = await fetchListAccountByIdApi(payload)
      return response
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)
export const fetchOrgReadAction = createAsyncThunk(
  'api/org/get',
  async (_, {rejectWithValue}) => {
    try {
      const response = await fetchOrgApi()
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchOrgUpdateAction = createAsyncThunk(
  'api/org/update',
  async (payload: OrgDetailInfoEditRequest, {dispatch, rejectWithValue}) => {
    try {
      await updateOrgDetailInfoApi(payload)
      return await dispatch(fetchOrgReadAction()).unwrap()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchOrgPackagesAction = createAsyncThunk(
  'api/org/package',
  async (dispatch, {rejectWithValue}) => {
    try {
      const result = await organizationRegisterPackageListApi()
      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface OrgState {
  organization: Organization | null
  organizationPackages: AllPackages[] | null
  loading: boolean
  error: unknown
  history: UserUsageHistory | null
  AccountUsageHistory: AccountUsageHistory[] | null
}

const initialState: OrgState = {
  loading: false,
  organization: null,
  organizationPackages: [],
  error: undefined,
  history: null,
  AccountUsageHistory: null,
}

export const orgSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readHistoryOrg.pending, (state) => {
      state.loading = true
      state.error = null
      state.history = null
    })
    builder.addCase(readHistoryOrg.fulfilled, (state, action) => {
      state.loading = false
      state.history = action.payload
    })
    builder.addCase(readHistoryOrg.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(readHistoryAccountUsage.pending, (state) => {
      state.loading = true
      state.error = null
      state.AccountUsageHistory = null
    })
    builder.addCase(readHistoryAccountUsage.fulfilled, (state, action) => {
      state.loading = false
      state.AccountUsageHistory = action.payload
    })
    builder.addCase(readHistoryAccountUsage.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchOrgReadAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchOrgReadAction.fulfilled, (state, action) => {
      state.loading = false
      state.organization = action.payload
    })
    builder.addCase(fetchOrgReadAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchOrgUpdateAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchOrgUpdateAction.fulfilled, (state, action) => {
      state.loading = false
      state.organization = action.payload
    })
    builder.addCase(fetchOrgUpdateAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchOrgPackagesAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchOrgPackagesAction.fulfilled, (state, action) => {
      state.loading = false
      state.organizationPackages = action.payload
    })
    builder.addCase(fetchOrgPackagesAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default orgSlice.reducer

export const selectLoading = (state: RootState) => state.org.loading
export const selectData = (state: RootState) => state.org.organization
export const selectOrganizationPackages = (state: RootState) =>
  state.org.organizationPackages
export const selectError = (state: RootState) => state.org.error
export const selectOrgHistory = (state: RootState) => state.org.history
export const selectAccountUsageHistory = (state: RootState) =>
  state.org.AccountUsageHistory
