import {createStyles, makeStyles} from '@material-ui/core/styles'

export const styles = () =>
  createStyles({
    root: {},
    areaCursor: {
      cursor: 'pointer',
    },
    tabContainer: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      overflow: 'hidden',
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
      },
    },
    selectWrapper: {
      height: 60,
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
