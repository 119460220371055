import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState, ThunkExtraArguments} from 'store'

export interface OpenDialogPayload {
  title?: string
  message: string
}

interface ConfirmState {
  isOpened: boolean
  title?: string
  message?: string
  isConfirmed: boolean
  isDeclined: boolean
}

const initialState: ConfirmState = {
  isOpened: false,
  isConfirmed: false,
  isDeclined: false,
}

const slice = createSlice({
  name: 'noticeConfirm',
  initialState,
  reducers: {
    open(state, action: PayloadAction<OpenDialogPayload>) {
      state.isOpened = true
      state.isConfirmed = false
      state.isDeclined = false

      state.title = action.payload.title
      state.message = action.payload.message
    },
    confirm(state) {
      state.isOpened = false
      state.isConfirmed = true
      state.isDeclined = false

      state.title = undefined
      state.message = undefined
    },
    reject(state) {
      state.isOpened = false
      state.isConfirmed = false
      state.isDeclined = true

      state.title = undefined
      state.message = undefined
    },
  },
})

export const {open, confirm, reject} = slice.actions

export default slice.reducer

export const selectNoticeConfirm = (state: RootState) => ({
  isOpened: state.noticeConfirm.isOpened,
  isConfirmed: state.noticeConfirm.isConfirmed,
  isDeclined: state.noticeConfirm.isDeclined,
  title: state.noticeConfirm.title,
  message: state.noticeConfirm.message,
})

export const confirmNoticeAction = createAsyncThunk<
  boolean,
  OpenDialogPayload,
  {extra: ThunkExtraArguments}
>('modal/noticeConfirm', async (payload, {dispatch, extra}) => {
  const {store} = extra
  dispatch(open(payload))

  return new Promise<boolean>((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state: RootState = store.getState()
      if (state.noticeConfirm.isConfirmed) {
        unsubscribe()
        resolve(true)
      }
      if (state.noticeConfirm.isDeclined) {
        unsubscribe()
        resolve(false)
      }
    })
  })
})
