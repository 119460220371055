import {OutlinedInput, Select} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {SelectProps} from '@material-ui/core/Select'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import useOrgUserList from 'features/org/useOrgUserList'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

interface SelectDoctorsProps extends SelectProps {
  className?: string
  selectValue?: string
}

const SelectBoldDoctor = (props: SelectDoctorsProps) => {
  const {className, error, selectValue, ...selectProps} = props
  const {t} = useTranslation()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {userList, onFetch} = useOrgUserList()

  useEffect(() => {
    onFetch()
  }, [])

  const items: SelectItem<string>[] =
    userList?.map((user) => ({
      label: t('IGetFullName', {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
      }),
      value: String(user.id),
    })) ?? []

  return (
    <FormControl className={className} error={error}>
      <Select
        variant='outlined'
        value={selectValue}
        input={
          <OutlinedInput
            name='docNo'
            id='outlined-country-simple'
            classes={outlinedInputClasses}
          />
        }
        {...selectProps}
      >
        <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {t(item.label)}
          </MenuItem>
        ))}
      </Select>
      {!!error && <span>{error}</span>}
    </FormControl>
  )
}

export default SelectBoldDoctor
