import React from 'react'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import CardInfoUsageHistory from 'components/atoms/CardInfoUsageHistory'
import pbmHistory from 'assets/img/usage_history/pbm_history.png'
import hrvHistory from 'assets/img/usage_history/hrv_history.png'
import eegScale from 'assets/img/usage_history/eeg_scale.png'

const headerStyles = () =>
  createStyles({
    cardEeg: {
      display: 'flex',
      gap: '50px',
      alignItems: 'center',
    },
    cardHrv: {
      display: 'flex',
      gap: '50px',
      margin: '25px 0 50px 0',
      alignItems: 'center',
      '@media (max-width: 1696px)': {margin: '25px 0 105px 0'},
    },
    cardPbm: {
      display: 'flex',
      gap: '15px',
    },
    cardAll: {
      display: 'flex',
      height: '150px',
    },
    cardDetail: {
      display: 'flex',
      gap: '5px 10px',
      flexWrap: 'wrap',
    },

    divider: {
      width: '10px',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    dividerEeg: {
      backgroundColor: '#0CC7C7',
    },
    dividerHrv: {backgroundColor: '#FFC000'},
    dividerPbm: {backgroundColor: '#4472C4'},
    listCardHrv: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      width: '100%',
    },
  })
const useStyles = makeStyles(headerStyles)

interface RenderPage1Props {
  dataEEG: AnalysisHistoryArea
  dataHRV: AnalysisHistoryArea
  dataPBM: AnalysisHistoryArea
}

const AnalysisArea = ({dataEEG, dataHRV, dataPBM}: RenderPage1Props) => {
  const classes = useStyles()
  const isTotal = true
  const renderEEG = () => {
    if (Object.keys(dataEEG).length === 0) {
      return null
    }
    return (
      <div className={classes.cardEeg}>
        <div className={classes.cardAll}>
          <div className={`${classes.divider} ${classes.dividerEeg}`} />
          <CardInfoUsageHistory
            data={dataEEG.itemTotal}
            type='EEG'
            isTotal={isTotal}
            logo={eegScale}
            title='EEG (MONTHLY)'
          />
        </div>

        <div className={classes.cardDetail}>
          {dataEEG?.listData?.map((item: UsageHistory) => (
            <CardInfoUsageHistory data={item} type='EEG' key={item.type} />
          ))}
        </div>
      </div>
    )
  }
  const renderHRV = () => {
    if (Object.keys(dataHRV).length === 0) {
      return null
    }
    return (
      <div className={classes.cardHrv}>
        <div className={classes.cardAll}>
          <div className={`${classes.divider} ${classes.dividerHrv}`} />
          <CardInfoUsageHistory
            data={dataHRV.itemTotal}
            type='HRV'
            isTotal={isTotal}
            logo={hrvHistory}
            title='HRV (MONTHLY)'
          />
        </div>

        <div className={classes.listCardHrv}>
          {dataHRV?.listData?.map((item: UsageHistory) => (
            <CardInfoUsageHistory data={item} type='HRV' key={item.type} />
          ))}
        </div>
      </div>
    )
  }
  const renderPBM = () => {
    if (Object.keys(dataPBM).length === 0) {
      return null
    }
    return (
      <div className={classes.cardPbm}>
        <div className={classes.cardAll}>
          <div className={`${classes.divider} ${classes.dividerPbm}`} />
          <CardInfoUsageHistory
            data={dataPBM.itemTotal}
            type='PBM'
            isTotal={isTotal}
            logo={pbmHistory}
            title='PBM (MONTHLY)'
          />
        </div>

        <div className={classes.cardDetail}>
          {dataPBM?.listData?.map((item: UsageHistory) => (
            <CardInfoUsageHistory data={item} type='PBM' key={item.type} />
          ))}
        </div>
      </div>
    )
  }
  return (
    <div>
      {renderEEG()}
      {renderHRV()}
      {renderPBM()}
    </div>
  )
}
export default AnalysisArea
