import styled from 'styled-components'

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin: 40px;
  padding-top: 50px;
`
export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 0 50px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: unset;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const RightContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const UserFormContainer = styled.div``
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;

  & > button {
    height: 55px;
    font-size: 18px;
    width: 100%;
  }
`
export const PolicyContent = styled.iframe`
  height: 500px;
  width: 100%;
  border: none;
  & > body {
    margin: 0 !important;
  }
`
export const PolicyCheckboxWrap = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 14px 50px 0 50px;
`
export const CheckboxContent = styled.p`
  color: #4b4b4b;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`

export const ErrorText = styled.span`
  color: #d03c35;
  font-size: 11px;
  margin: 2px 50px;
`

export default Container
