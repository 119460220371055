import {useAppDispatch, useAppSelector} from 'hooks'
import {
  closeEmailSendResultDialog,
  openEmailSendResultDialog,
  selectEmailSendResult,
} from './treatmentSearchSlice'

export interface CareList {
  careId: number
  country: string
  eegSummary: string
  errorMsg: string
  hrvSummary: string
  mciSummary: string
  patientEmail: string
}

/* eslint-disable import/prefer-default-export */
export function useEmailSendResultDialog() {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectEmailSendResult)

  const onOpen = () => dispatch(openEmailSendResultDialog())
  const onClose = () => dispatch(closeEmailSendResultDialog())

  return {
    open: isOpen,
    onOpen,
    onClose,
  }
}
