import {TreatmentTypeSearch} from 'api/treatmentApi'

interface SearchParams {
  careDoctorName?: string
  chartNumber?: number
  patientName?: string
}
export const convertParamsTreatmentListV3 = (
  searchValue: string | number,
  searchName: TreatmentTypeSearch,
): SearchParams => {
  if (searchName === 'CARE_DOC') return {careDoctorName: searchValue as string}
  if (searchName === 'CHART_NO') return {chartNumber: searchValue as number}
  if (searchName === 'NAME') return {patientName: searchValue as string}
  return {}
}

export const convertTypeSearch = (
  searchCondition: any,
): {searchName: TreatmentTypeSearch; searchValue: string | number} => {
  if (Object.prototype.hasOwnProperty.call(searchCondition, 'careDoctorName'))
    return {
      searchName: 'CARE_DOC',
      searchValue: searchCondition?.careDoctorName,
    }
  if (Object.prototype.hasOwnProperty.call(searchCondition, 'chartNumber'))
    return {
      searchName: 'CHART_NO',
      searchValue: searchCondition?.chartNumber,
    }
  if (Object.prototype.hasOwnProperty.call(searchCondition, 'patientName'))
    return {
      searchName: 'NAME',
      searchValue: searchCondition?.patientName,
    }
  return {searchName: 'NAME', searchValue: searchCondition?.patientName || ''}
}
