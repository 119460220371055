import {
  cancelConfirmDialog,
  confirmAnalysisAction,
  confirmConfirmDialog,
  OpenConfirmPayload,
  selectConfirm,
} from 'features/analysis/analysisSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

// eslint-disable-next-line import/prefer-default-export
export function useAnalysisConfirm() {
  const dispatch = useAppDispatch()
  const {type, isOpen, selected, isConfirm, confirmed} =
    useAppSelector(selectConfirm)
  const onOpen = async (payload: OpenConfirmPayload) =>
    dispatch(confirmAnalysisAction(payload)).unwrap()
  const onConfirm = (payload: number[]) =>
    dispatch(confirmConfirmDialog(payload))
  const onCancel = () => dispatch(cancelConfirmDialog())

  return {
    type,
    isOpen,
    selected,
    isConfirm,
    confirmed,
    onOpen,
    onConfirm,
    onCancel,
  }
}
