import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {Button} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DatePicker from 'react-datepicker'
import {PostRegisterMember, postRegisterMemberApi} from 'api/orgApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RegisterCardWithTitle from 'components/atoms/RegisterCardWithTitle'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import RegisterStyle from 'pages/CustomerManagement/MemberRegistration/Style'
import TableHospitalInformation, {
  SelectedListType,
} from 'pages/CustomerManagement/MemberRegistration/Form/TableHospitalInfo'
import RouteConstant from 'constants/RouteConstant'
import AddressInput from './Form/AddressInput'
import BusinessNoInput from './Form/BusinessNoInput'
import EmailInput from './Form/EmailInput'
import NursingInstitutionInput from './Form/InstitutionInput'
import OrganizationNameInput from './Form/OrganizationNameInput'
import SelectedIndustry from './Form/SelectedIndustry'
import TelephoneNumberInput from './Form/TelephoneNumberInput'
import UserNameInput from './Form/UserNameInput'
import CountrySelect from './Form/CountrySelect'

const useStyles = makeStyles(RegisterStyle)

export default function MemberRegistrationPage() {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const {loading} = useAuth()
  const [policyChecked, setPolicyChecked] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [privacyUrl, setPrivacyUrl] = React.useState('')
  const [serviceUrl, setServiceUrl] = React.useState('')
  const [selectedDetails, setSelectedDetails] = useState<SelectedListType[]>([])
  const [minDate, setMinDate] = useState<Date>()
  const [packageStartDate, setPackageStartDate] = useState<Date | null>(null)
  const [packageExpireDate, setPackageExpireDate] = useState<Date | null>(null)
  const [orgType, setOrgType] = useState(false)
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const history = useHistory()
  const createMemberInfo = useForm()
  const {
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm<PostRegisterMember>()
  const [key, setKey] = useState(1)

  const checkServiceHandler = () => {
    setPolicyChecked(!policyChecked)
    setValue('policyYn', policyChecked ? 'Y' : 'N')
  }
  const checkPrivacyHandler = () => {
    setPrivacyChecked(!privacyChecked)
    setValue('privacyYn', privacyChecked ? 'Y' : 'N')
  }
  const convertStringFromDateTime = (data: Date | null) => {
    if (data) {
      const date = new Date(data)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
    return ''
  }

  const handleValueStartDate = (data: Date | null) => {
    if (data) {
      const newDate = new Date(data)
      newDate.setDate(newDate.getDate() + 1)
      setMinDate(newDate)
      if (packageExpireDate && data >= packageExpireDate) {
        setPackageExpireDate(null)
      }
    } else {
      const newDate = new Date()
      if (packageExpireDate && new Date() >= packageExpireDate) {
        setPackageExpireDate(null)
      }
      newDate.setDate(newDate.getDate() + 1)
      setMinDate(newDate)
    }
    setPackageStartDate(data)
  }
  const handleValueExpireDate = (data: Date | null) => {
    setPackageExpireDate(data)
  }
  const handleRegister = async (orgInfo: PostRegisterMember) => {
    try {
      const response = await postRegisterMemberApi(orgInfo)
      if (response)
        onSuccessModalOpen(response.message).then(() => {
          history.push(`${RouteConstant.HOME.path}`)
        })
    } catch (e) {
      onFailureModalOpen(e?.message ?? t('IRegisterFail'))
    }
  }
  const handleCreate = (orgData: PostRegisterMember) => {
    handleRegister(orgData)
  }
  const submit = (data: PostRegisterMember) => {
    clearErrors('policyYn')
    clearErrors('privacyYn')
    if (!privacyChecked) {
      setError('privacyYn', {
        type: 'custom',
        message: `${t('IRequired')}`,
      })
      onFailureModalOpen(t('IRegisterTermsNotAgree'))
      return
    }
    if (!policyChecked) {
      setError('policyYn', {
        type: 'custom',
        message: `${t('IRequired')}`,
      })
      onFailureModalOpen(t('IRegisterTermsNotAgree'))
      return
    }

    if (orgType && !selectedDetails.length) {
      onFailureModalOpen(t('IStyleNotSelected'))
      return
    }
    const arrProductIds = selectedDetails.map((item) => item.productId)
    if (!packageStartDate && packageExpireDate) {
      data.packageStartDate = convertStringFromDateTime(new Date())
    } else {
      data.packageStartDate = convertStringFromDateTime(packageStartDate)
    }
    data.packageExpireDate = convertStringFromDateTime(packageExpireDate)
    handleCreate({
      address: data.customerPo,
      area: data.customerPo,
      businessNo: data.businessNo,
      countryId: data.countryId,
      detailIndustry: data.industryDetail,
      edfYn: data.edfYn ? 'Y' : 'N',
      email: data.email,
      firstUserName: data.customerFistN,
      lastUserName: data.lastUserName,
      industry: data.industry,
      institutionNo: data.institutionNo,
      privacyYn: 'Y',
      policyYn: 'Y',
      orgName: data.orgName,
      orgType: data.orgType ? data.orgType : 'NORMAL',
      packageExpireDate: data.packageExpireDate ? data.packageExpireDate : null,
      packageStartDate: data.packageStartDate ? data.packageStartDate : null,
      password: '12345678',
      tel: data.tel,
      therapyYn: data.therapyYn ? 'Y' : 'N',
      healthProductIds: arrProductIds.length > 0 ? arrProductIds : null,
    } as PostRegisterMember)
  }

  const handleCheckboxProductType = (details: SelectedListType[]) => {
    setSelectedDetails([...details])
  }

  const handleCheckboxChangeOrgType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setOrgType(isChecked)
  }

  useEffect(() => {
    if (i18n.language === 'ko') {
      setPrivacyUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_ko.html',
      )
      setServiceUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko.html',
      )
    } else {
      setPrivacyUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_en.html',
      )
      setServiceUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_en.html',
      )
    }
  }, [i18n.language])

  useEffect(() => {
    createMemberInfo.setValue('orgType', orgType ? 'HEALTH' : 'NORMAL')
    if (!orgType) {
      setSelectedDetails([])
    }
  }, [orgType])

  useEffect(() => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 1)
    setMinDate(newDate)

    setKey(key + 1)
  }, [])

  return (
    <div key={key}>
      {loading && <div>loading</div>}
      <div className={classes.topRoot}>
        <FormProvider {...createMemberInfo}>
          <form
            onSubmit={createMemberInfo.handleSubmit((data) =>
              submit(data as PostRegisterMember),
            )}
          >
            <div className={classes.container}>
              <div className={classes.agreeSectionContainer}>
                <RegisterCardWithTitle
                  title={t('IRegisterPrivacyAgree')}
                  className={classes.termsWrap}
                >
                  <iframe
                    className={classes.termsContainer}
                    title='Privacy information policy'
                    src={privacyUrl}
                  />
                </RegisterCardWithTitle>
                <div className={classes.serviceCheckboxWrap}>
                  <Checkbox
                    id='check'
                    color='primary'
                    disableRipple
                    checked={privacyChecked}
                    inputProps={{'aria-label': 'decorative checkbox'}}
                    onChange={() => checkPrivacyHandler()}
                  />
                  <span className={classes.termsTitle}>
                    {t('IRegisterPrivacyAgreeNotice')}
                  </span>
                </div>
                {errors.privacyYn && (
                  <span className={inputClasses.errorText}>
                    {t('IRequired')}
                  </span>
                )}
                <Typography className={classes.padding} />
                <RegisterCardWithTitle
                  title={t('IRegisterServiceAgree')}
                  className={classes.termsWrap}
                >
                  <iframe
                    className={classes.termsContainer}
                    title='My Daily Marathon Tracker'
                    src={serviceUrl}
                  />
                </RegisterCardWithTitle>

                <div className={classes.serviceCheckboxWrap}>
                  <Checkbox
                    color='primary'
                    checked={policyChecked}
                    disableRipple
                    inputProps={{'aria-label': 'decorative checkbox'}}
                    onChange={() => checkServiceHandler()}
                  />
                  <span className={classes.termsTitle}>
                    {t('IRegisterServiceAgreeNotice')}
                  </span>
                </div>
                {errors.policyYn && (
                  <span className={inputClasses.errorText}>
                    {t('IRequired')}
                  </span>
                )}
              </div>

              <CardWithTitle
                title={t('IUserInfo')}
                className={classes.formRegister}
              >
                <div className={classes.inputContainer}>
                  <div style={{height: 24}}>
                    <Checkbox
                      color='primary'
                      checked={orgType}
                      onChange={handleCheckboxChangeOrgType}
                      style={{height: 24, paddingLeft: 0}}
                    />
                    {t('INoteCenter')}
                  </div>
                  <OrganizationNameInput />
                  <UserNameInput />
                  <EmailInput />
                  {/* <PasswordInput /> */}
                  <CountrySelect />
                  <AddressInput />
                  <TelephoneNumberInput />
                  <SelectedIndustry />
                  <BusinessNoInput />
                  <NursingInstitutionInput />

                  <div style={{height: 24, marginTop: '5px'}}>
                    <Checkbox
                      color='primary'
                      style={{height: 24, paddingLeft: 0}}
                      {...createMemberInfo.register('therapyYn')}
                    />
                    {t('IEnablePBMFeature')}
                  </div>

                  <div>
                    <p
                      className={inputClasses.inputLabel}
                      style={{padding: '5px 0'}}
                    >
                      {t('IAccountUsagePeriod')}
                    </p>
                    <div className={inputClasses.inputContainer}>
                      <div className={inputClasses.inputWrap}>
                        <DatePicker
                          selected={packageStartDate}
                          onChange={(date) => handleValueStartDate(date)}
                          placeholderText='yyyy-MM-dd'
                          className={inputClasses.input}
                        />
                      </div>
                      <div className={inputClasses.inputWrap}>
                        <DatePicker
                          selected={packageExpireDate}
                          onChange={(date) => handleValueExpireDate(date)}
                          placeholderText='yyyy-MM-dd'
                          minDate={minDate}
                          className={inputClasses.input}
                        />
                      </div>
                    </div>
                  </div>
                  <Typography className={classes.padding} />
                  {orgType && (
                    <div className={classes.tableFlexBox}>
                      <TableHospitalInformation
                        products={selectedDetails}
                        onCheckboxChange={handleCheckboxProductType}
                      />
                    </div>
                  )}
                  <div className={classes.submitButtonContainer}>
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      type='submit'
                    >
                      {t('IRegister')}
                    </Button>
                  </div>
                </div>
              </CardWithTitle>
            </div>
          </form>
        </FormProvider>
      </div>
      <NoticeConfirmDialog />
    </div>
  )
}
