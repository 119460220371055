export const getEnv = () => process.env.REACT_APP_ENV ?? 'unknown'
export const getApiHost = () => process.env.REACT_APP_API_URL
export const getNewApiHost = () => process.env.REACT_APP_NEW_API_URL
export const isDev = () => process.env.REACT_APP_ENV === 'development'
export const isTest = () => process.env.REACT_APP_API_USE_MSW_MOCK_API === 'yes'
export const isProd = () => process.env.REACT_APP_ENV === 'production'
export const isStage = () => process.env.REACT_APP_ENV === 'stage'
export const isLoggable = () => !isProd()
export const getPublicUrl = (): string => process.env.PUBLIC_URL
export const getReactPublicUrl = (): string => `${process.env.PUBLIC_URL}`

export const eximBayReqHost: string =
  process.env.REACT_APP_EXIMBAY_REQ_URL ?? ''
/**
 * @param dirName string -> 루트부터 시작하는 파일 디렉토리 경로. '/'로 시작하면 안되고 '/'로 끝나도 안됨.
 */
export const getPublicFile = (dirName: string) => (fileName: string) =>
  ` ${getPublicUrl() ?? ''}/${dirName}/${fileName}`
