import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function UserNameInput() {
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    register,
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IRegisterOrgBossName')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.inputWrap}>
          <input
            placeholder={t('IFirstName')}
            className={classes.input}
            {...register('chiefFirstName')}
          />
          {errors.chiefFirstName && (
            <span className={classes.errorText}>
              {errors.chiefFirstName.message}
            </span>
          )}
        </div>
        <div className={classes.inputWrap}>
          <input
            placeholder={t('ILastName')}
            className={classes.input}
            {...register('chiefLastName')}
          />
          {errors.chiefLastName && (
            <span className={classes.errorText}>
              {errors.chiefLastName.message}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
