import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import {fileDownload} from 'api'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import GridPaginationPadding from 'components/Grid/GridPaginationPadding'
import useTableStyles from 'components/Table/useTableStyles'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useOrganization from 'features/org/useOrganization'
import {
  fetchPointListRequest,
  selectPoint,
  selectPointQuery,
} from 'features/point/pointSlice'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import {
  dateToPeriodString,
  getDateForFile,
  getDefaultPeriodString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    navContainer: {
      // margin: 15,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        // flex: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },

    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
    },
    disableText: {
      color: '#a6a6a6',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    analBox: {
      width: 200,
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      justifyContent: 'space-between',
      border: '1px solid rgb(53 73 99 / 20%)',
      alignItems: 'center',
      borderRadius: '4px',
      marginBottom: 10,
    },
    pointTitle: {
      fontSize: '12px',
    },
    pointCont: {
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
      //   alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
      // alignItems: 'center',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    toolbarWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      gap: 5,
      flexWrap: 'wrap',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    pointSearchContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginLeft: 5,
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}

const headCells: HeadCell[] = [
  {id: 'id', label: 'IOrderNo'},
  {id: 'paidDate', label: 'IOrderDate'},
  {id: 'productName', label: 'IProductName'},
  {id: 'analysisNo', label: 'IAnalysisNo'},
  {id: 'orderOrgName', label: 'IOrderOrgName'},
  {id: 'purchaseType', label: 'IPurchaseType'},
  {id: 'purchaseAmount', label: 'IPurchaseAmount'},
  {id: 'remark', label: 'IRemark'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function PointHistoryTable() {
  const classes = useTableStyles()

  const dispatch = useAppDispatch()

  const pointList = useSelector(selectPoint)
  const pointQuery = useSelector(selectPointQuery)

  const {user: currentUser} = useAuth()
  const {organization: orgInfo} = useOrganization()

  const items = pointList.pointList ?? []

  const emptyRows = items === null ? 5 : 5 - items.length

  const setPageIndex = (page: number) => {
    dispatch(
      fetchPointListRequest({
        ...pointQuery,
        page,
        uid: currentUser?.uid ?? null,
        oid: orgInfo?.oid ?? '',
      }),
    )
  }

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='medium'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {pointList.pointList != null &&
            pointList.pointList.map((id: any) => {
              return (
                <TableRow hover classes={{hover: classes.hover}} key={id.id}>
                  <TableCell align='center'>{id.id ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {isoStringToDateAndTimeString(id.paidDate ?? '-')}
                  </TableCell>
                  <TableCell align='center'>{id.product ?? '-'}</TableCell>
                  <TableCell align='center'>{id.analysisId ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {id.name ?? '-'} / {id.userName ?? '-'}
                  </TableCell>
                  <TableCell align='center'>{id.pointType ?? '-'}</TableCell>
                  <TableCell align='center'>{id.point ?? '-'}</TableCell>
                  <TableCell align='center'>{id.remark ?? '-'}</TableCell>
                </TableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <GridPaginationPadding />
      <Pagination
        totalPageCount={pointList.totalPages}
        currentPageIndex={pointList.pageIndex ?? 0}
        itemCountPerPage={pointList.itemPerPage ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={pointList.loading}
        onItemCountPerPageChanged={(event: any) => {
          dispatch(
            fetchPointListRequest({
              ...pointQuery,
              page: 0,
              size: parseInt(event.target.value as string, 10),
              uid: currentUser?.uid ?? null,
              oid: orgInfo?.oid ?? '',
            }),
          )
        }}
        isItemCountPerPageEnabled={false}
      />
    </TableContainer>
  )
}

type PointSearchedType =
  | 'ORDER_NO'
  | 'DATE'
  | 'PRODUCT'
  | 'ANALYSIS_ID'
  | 'NAME'
  | 'ORG'
  | 'TYPE'

export default function MyPointHistory() {
  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const selectedOid = useSelector(selectAllOrgOid)
  const pointList = useSelector(selectPoint)
  const {user: currentUser} = useAuth()
  const {organization: orgInfo} = useOrganization()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [searchNameValue, setSearchNameValue] = React.useState('')
  const [searchedValue, setSearchedValue] = React.useState('')
  const searchedList = [
    'IOrderNo',
    'IOrderDate',
    'IProductName',
    'IAnalysisNumber',
    'IName',
    'IPurchaseType',
  ]

  const searchedListMap = searchedList.map((value, index) => (
    <MenuItem value={index}>{t(value)}</MenuItem>
  ))

  const [searchedSelectedType, setSearchedSelectedType] =
    React.useState<PointSearchedType>()

  const handleOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchNameValue(event.target.value as string)
    switch (event.target.value) {
      case 0:
        setSearchedSelectedType('ORDER_NO')
        break
      case 1:
        setSearchedSelectedType('DATE')
        break
      case 2:
        setSearchedSelectedType('PRODUCT')
        break
      case 3:
        setSearchedSelectedType('ANALYSIS_ID')
        break
      case 4:
        setSearchedSelectedType('NAME')
        break
      case 5:
        setSearchedSelectedType('ORG')
        break
      case 6:
        setSearchedSelectedType('TYPE')
        break
      default:
        setSearchedSelectedType('PRODUCT')
    }
  }

  const [selection, setSelection] = React.useState<PeriodString>(
    getDefaultPeriodString(),
  )

  const handleValue = (value: any) => {
    setSearchedValue(value)
  }
  const handleDateValue = (value: any) => {
    setSelection(dateToPeriodString(value as PeriodDate))
  }
  const handlePeriod = (period: PeriodString) => {
    dispatch(
      fetchPointListRequest({
        page: 0,
        size: 5,
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? '',
        period,
      }),
    )
  }

  const handleKeywordSearch = () =>
    dispatch(
      fetchPointListRequest({
        page: 0,
        size: 5,
        uid: currentUser?.uid ?? '',
        oid: orgInfo?.oid ?? '',
        searchName: searchedSelectedType,
        searchValue: searchedValue,
      }),
    )

  const handleExcelDownload = async () => {
    if (pointList.pointList?.length === 0) {
      onFailureModalOpen(t('IPromotionEmpty'))
      return
    }
    await fileDownload({
      path: '/api/point/v1/download/excel',
      params: {
        oid: selectedOid ?? null,
      },
      fileName: getDateForFile(),
    })
  }

  useEffect(() => {
    dispatch(
      fetchPointListRequest({
        page: 0,
        size: 5,
        uid: currentUser?.uid ?? null,
        oid: orgInfo?.oid ?? null,
      }),
    )
  }, [])

  useEffect(() => {
    handlePeriod(selection)
  }, [selection])

  return (
    <CardWithTitle title={t('IPointHistory')}>
      <Grid className={classes.pointContainer}>
        <Grid container className={classes.toolbarContainer}>
          <Grid className={classes.toolbarWrap}>
            <div style={{display: 'flex'}}>
              <div className={classes.dataContainer}>
                <RangeDatePicker
                  onChangeValue={handleDateValue}
                  dateValue={{
                    startDate: new Date(selection.startDate),
                    endDate: new Date(selection.endDate),
                  }}
                />
              </div>
              <div className={classes.pointSearchContainer}>
                <FormControl variant='outlined' className={classes.selectBox}>
                  <Select
                    value={searchNameValue}
                    onChange={handleOnchange}
                    displayEmpty
                    color='primary'
                    input={
                      <OutlinedInput
                        name='age'
                        id='outlined-age-simple'
                        classes={outlinedInputClasses}
                      />
                    }
                  >
                    <MenuItem disabled value=''>
                      <Typography className={classes.disableText}>
                        {t('ISelect')}
                      </Typography>
                    </MenuItem>
                    {searchedListMap}
                  </Select>
                </FormControl>
                <SearchBar
                  onChangeValue={handleValue}
                  onSearchEvent={handleKeywordSearch}
                  disabledValue={false}
                />

                <Typography className={classes.padding} />
              </div>
            </div>

            <ActionButton
              variant='contained'
              color='secondary'
              startIcon={<GetAppIcon />}
              disableElevation
              onClick={() => handleExcelDownload()}
            >
              {t('IExcelDownload')}
            </ActionButton>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <PointHistoryTable />
      </Grid>
    </CardWithTitle>
  )
}
