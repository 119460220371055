import React from 'react'
import {
  AnalysisResultItem,
  IcaAnalysisResultIndicator,
  TypeScreenGraph,
  TypeGraph,
} from 'api/analysisApi'
import ReportHeader from 'components/Report/ReportHeader'
import LeftChannel from 'assets/img/LeftChannel.jpeg'
import RenderRawDataGraph from 'components/molcules/Report/RawData/RenderRawDataGraph'

interface RawDataProps {
  readonly indicator: IcaAnalysisResultIndicator
  readonly items: AnalysisResultItem[]
  readonly yAxisImage?: string
  readonly type: TypeGraph
  readonly screen: TypeScreenGraph
}

function IcaRawDataV1({
  indicator,
  items,
  yAxisImage = LeftChannel,
  type,
  screen,
}: RawDataProps) {
  const [item] = items

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <RenderRawDataGraph data={item} type={type} screen={screen} />
    </div>
  )
}

export default IcaRawDataV1
