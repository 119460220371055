import FormControl from '@material-ui/core/FormControl'
import SearchBar from 'components/Forms/SearchBar'
import SelectOrder from 'components/Select/SelectOrder'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {searchSalesInstituteAction} from 'features/sales/salesSlice'
import {useAppDispatch} from 'hooks'
import React, {useState} from 'react'

type InstituteSearchType = 'INSTITUTE_NAME' | 'EMAIL' | 'PHONE'

const SEARCH_SELECT_ITEMS: SelectItem<InstituteSearchType>[] = [
  {label: 'IOrgNameShort', value: 'INSTITUTE_NAME'},
  {label: 'IEmail', value: 'EMAIL'},
  {label: 'IPhone', value: 'PHONE'},
]

export default function InstituteSearchBar() {
  const classes = useToolbarStyles()
  const dispatch = useAppDispatch()
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearch = async () => {
    try {
      await dispatch(
        searchSalesInstituteAction({
          page: 1,
          size: 5,
          searchValue: `{"DEFAULT": "${searchKeyword}"}`,
        }),
      )
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
  const handleValue = (value: any) => setSearchKeyword(value)

  return (
    <div className={classes.container}>
      <FormControl variant='outlined'>
        <SelectOrder
          selectContent={SEARCH_SELECT_ITEMS}
          setSelectName={() => {}}
        />
      </FormControl>
      <SearchBar
        onChangeValue={handleValue}
        onSearchEvent={handleSearch}
        disabledValue={false}
      />
    </div>
  )
}
