/* eslint-disable prettier/prettier */
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(359deg);
  }
`
interface StyledSettingsProps {
    open: boolean
    width: number
}
const Container = styled.div<StyledSettingsProps>`
  z-index: 9999;
  position: fixed;
  right: ${(props) => (props.open ? 20 : -props.width)}px;;
  top: 200px;
  display: flex;
  transition: all 0.3s ease-in-out;
`
export const SettingContainer = styled.div`
  cursor: pointer;
  color: #fff;
  margin-top: 16px;
  background-color: #4d5d6b;
  font-size: 20px;
  border-radius: 20px 0 0 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .MuiSvgIcon-root {
    animation: ${spin} 3s infinite linear;
  }

`

export const ContentContainer = styled.div<{ width: number }>`
  padding: 16px;
  width: ${props => props.width}px;
  background: white;
  border: 2px solid #4d5d6b;
  box-shadow: -3px 2px 12px -1px rgb(0 0 0 / 15%);
`
export const Title = styled.p`
    color: #fff;
    padding: 8px 10px;
    background: #2f404f;
    margin: 0;
`
export const SelectContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
`

export const ListContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
`

export const ActionContainer = styled.div`
    margin-top: 16px;
    border-top: 1px solid #eee;
    padding-top:16px;
`

export default Container
