import React, {useState} from 'react'
import {
  calRefundPackagePrice,
  createOrder,
  PurchaseBundle,
  PurchasedProducts,
  PurchasePackage,
  UnPaidVoucherContainer,
} from 'api/paymentApi'
import {makeRsaKeys} from 'api/securityApi'
import OrderResponseModel from '../Models/OrderResponseModel'
import UnPaidOrderModel from '../Models/UnPaidOrderModel'
import {
  OrganizationBundle,
  OrganizationPackage,
} from '../../Interfaces/UnPaidOrder'
import useIncludedProductsDuplicateViewModel from './IncludedProductsDuplicateViewModel'

export default function ProductsCheckViewModel() {
  const [values, setValues] = React.useState<{price: number}>({price: 0})
  const [refunds, setRefunds] = React.useState<number[]>([])
  const [refundPrice, setRefundPrice] = React.useState<number>(0)
  let packages: PurchasePackage[] = []
  let bundles: PurchaseBundle[] = []
  let purchased: PurchasedProducts
  let overdue: UnPaidVoucherContainer

  function setInitialProducts(
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
  ) {
    const p =
      packages.reduce((prev, cur) => prev + cur.price, 0) +
      bundles.reduce((prev, cur) => prev + cur.price, 0)
    setValues({price: p})
  }

  function setBundles(b: PurchaseBundle[]) {
    bundles = b
  }

  function setPackages(p: PurchasePackage[]) {
    packages = p
  }

  function setPurchased(b: PurchasedProducts) {
    purchased = b
  }

  function setOverdue(o: UnPaidVoucherContainer) {
    overdue = o
  }

  function setRefundPriceValue(p: number) {
    setRefundPrice(p)
  }

  function setInitialPrice() {
    // setRefunds([])
    setValues({price: 0})
    setRefundPriceValue(0)
  }

  async function handleRefund(order: number) {
    const r = refunds.slice()
    const itemIndex = r.indexOf(order)
    if (itemIndex > -1) {
      r.splice(itemIndex, 1)
    } else {
      r.push(order)
    }
    setRefunds(r)
    if (r.length !== 0) {
      const packages = purchased.packages.filter((item, idx) => {
        return r.includes(idx)
      })
      try {
        const totalRefunds = await calRefundPackagePrice(
          packages.map((item) => {
            return item.packageId
          }),
        )
        setRefundPrice(totalRefunds.refundPrice)
      } catch (e) {
        console.error('get price error : ', e)
      }
    } else {
      setRefundPrice(0)
    }
  }

  async function requestOrder() {
    // request new order and response order id, payment gateway type, etc...
    // check over refund
    const bundleIds = bundles.map((item) => {
      return item.id
    })
    const packageIds = packages.map((item) => {
      return item.id
    })
    let refundPackages: string[] = []
    if (refunds.length !== 0) {
      const packages = purchased.packages.filter((item, idx) => {
        return refunds.includes(idx)
      })
      refundPackages = packages.map((item) => {
        return item.packageId
      })
    }

    const overdueIds = overdue.vouchers.map((item) => {
      return item.id
    })

    try {
      const keySet = makeRsaKeys()

      const res = await createOrder(
        bundleIds,
        packageIds,
        refundPackages,
        overdueIds,
        keySet.PublicKey,
      )

      const orderInfo = new OrderResponseModel(
        res,
        keySet.PrivateKey,
      ) as UnPaidOrderModel

      orderInfo.bundles = bundles.map((item) => {
        return {
          name: item.name,
          bundleId: item.id,
          priceSet: {
            price: item.price,
            currency: item.currency,
            unitPurchase: item.unitPurchase,
          },
          analyses: item.analyses,
          analysisGroup: item.analysisGroup,
        } as OrganizationBundle
      })
      orderInfo.packages = packages.map((item) => {
        return {
          name: item.name,
          bundleId: item.id,
          price: item.price,
          analyses: item.analyses,
          analysisGroup: item.analysisGroup,
        } as OrganizationPackage
      })

      return orderInfo
    } catch (e) {
      console.error('error when request order : ', e)
    }
    return null
  }

  return {
    setInitialPrice,
    refunds,
    refundPrice,
    setBundles,
    setPackages,
    setInitialProducts,
    setPurchased,
    setOverdue,
    handleRefund,
    requestOrder,
    ...values,
  }
}

export function DuplicatedItemsViewModel() {
  const [duplicated, setDuplicated] = useState<boolean>(false)

  const {comparePurchasingToPurchasing} =
    useIncludedProductsDuplicateViewModel()

  // 구매하려는 상품의 포함 상품들
  function purchasingAnalysesCode(
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
  ) {
    // 포함 상품을 담을 변수
    const analysesCode: string[] = []

    // 패키지+번들 변수
    const purchasingProducts = [...packages, ...bundles]

    // 구매 하려고 하는 포함 상품을 모음
    purchasingProducts.forEach((product) => {
      product.analyses.forEach((item) => {
        analysesCode.push(item.code)
      })
    })

    const analysesCodeSet = new Set(analysesCode)
    return Array.from(analysesCodeSet)
  }

  // 이미 구매한 상품의 포함 상품들
  function purchasedAnalysesCode(
    purchased: PurchasedProducts,
    refunds: number[],
  ) {
    // 포함 상품을 담을 변수
    const analysesCode: string[] = []

    if (purchased) {
      // 패키지+번들 변수
      const purchasedProducts = [...purchased.packages, ...purchased.bundles]

      purchasedProducts.forEach((product, idx) => {
        // 환불 객체가 빈 배열이어도 아래 조건식 만족
        if (!refunds.includes(idx)) {
          product.analyses.forEach((item) => {
            analysesCode.push(item.code)
          })
        }
      })

      const analysesCodeSet = new Set(analysesCode)
      return Array.from(analysesCodeSet)
    }

    return analysesCode
  }

  function getDuplicated(
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
    refunds: number[],
    purchased?: PurchasedProducts,
  ) {
    let duplicatedCheck = false

    packages.find((item, idx) => {
      item.analyses.find((product) => {
        if (
          comparePurchasingToPurchasing(packages, bundles, idx, product.code)
        ) {
          duplicatedCheck = true
          return true
        }

        if (purchased) {
          const purchasedCode = purchasedAnalysesCode(purchased, refunds)
          purchasedCode.find((compare) => {
            if (compare === product.code) {
              duplicatedCheck = true
              return true
            }
            return null
          })
        }
        return null
      })

      return null
    })

    bundles.find((item, idx) => {
      item.analyses.find((product) => {
        if (
          comparePurchasingToPurchasing(
            packages,
            bundles,
            packages.length + idx,
            product.code,
          )
        ) {
          duplicatedCheck = true
          return true
        }

        if (purchased) {
          const purchasedCode = purchasedAnalysesCode(purchased, refunds)
          purchasedCode.find((compare) => {
            if (compare === product.code) {
              duplicatedCheck = true
              return true
            }
            return null
          })
        }
        return null
      })
      return null
    })

    setDuplicated(duplicatedCheck)
  }

  return {
    purchasingAnalysesCode,
    purchasedAnalysesCode,
    getDuplicated,
    duplicated,
  }
}
