import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import Analysis from 'pages/HealthCenter/Report/Elements/Analysis'
import {getPublicFile} from 'helpers/envHelper'
import PercentBarCardV3 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV3'

const useStyles = makeStyles(HealthCenterReportStyle)

export interface BrainFunctionalConnectivityProps {
  partTwoImg: string
  partThreeImg: string
  brainConnectivityBetweenLobesScore: {
    vertical: {
      left: number
      right: number
    }
    horizontal: number
  } | null
  brainConnectivityByLobeScore: {
    frontal: {
      left: number
      right: number
    }
    pariocci: {
      left: number
      right: number
    }
  } | null
}

const PagePrintWrap2 = ({
  partTwoImg,
  partThreeImg,
  brainConnectivityByLobeScore,
  brainConnectivityBetweenLobesScore,
}: BrainFunctionalConnectivityProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getHealthReportReportImage = getPublicFile(
    'images/health_center_report',
  )

  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }

  const getText = (value = 0, key: string) => {
    if (value < 30) {
      return t(`IBot${key}`, {
        value: getNumber(value),
      })
    }
    if (value > 70) {
      return t(`ITop${key}`, {
        value: getNumber(value),
      })
    }
    return t(`IMid${key}`, {
      value: getNumber(value),
    })
  }

  const getTextV2 = (left = 0, right = 0, key = 'Title') => {
    if (left < 30 && right < 30) {
      return `IAnalysisResultsDes${key}Case3`
    }
    if (left > 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case2`
    }
    if (left > 70 && right < 70) {
      return `IAnalysisResultsDes${key}Case4`
    }
    if (left < 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case5`
    }
    return `IAnalysisResultsDes${key}Case1`
  }

  const textPart2Title1 = useMemo(() => {
    const key = getTextV2(
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.right ?? 0),
    )
    return t(key, {
      left: getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      right: getNumber(
        brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
      ),
    })
  }, [brainConnectivityBetweenLobesScore?.vertical])

  const textPart2Text1 = useMemo(() => {
    const key = getTextV2(
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.right ?? 0),
      'Text',
    )
    return t(key, {
      left: getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      right: getNumber(
        brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
      ),
    })
  }, [brainConnectivityBetweenLobesScore?.vertical])

  const textPart2Title2 = useMemo(() => {
    return getText(
      brainConnectivityBetweenLobesScore?.horizontal ?? 0,
      'LeftRightBrainAnalysisResultsTitle',
    )
  }, [brainConnectivityBetweenLobesScore?.horizontal])

  const textPart2Text2 = useMemo(() => {
    return getText(
      brainConnectivityBetweenLobesScore?.horizontal ?? 0,
      'LeftRightBrainAnalysisResultsText',
    )
  }, [brainConnectivityBetweenLobesScore?.horizontal])

  return (
    <div className={classes.brainBasic}>
      <WrapElement number={2} text={t('IBrainConnectivityBetweenBrain')}>
        <div>
          <div className={classes.brainConnectivityBrainRegionTabs2}>
            <div
              className={`${classes.cardLeftContent} ${classes.cardLeftContentAdditional}`}
            >
              <img
                className={classes.cardLeftImg}
                src={getHealthReportReportImage('brainRegions.webp')}
                alt='Contour'
                style={{width: '285px'}}
              />
            </div>
            <div className={classes.percentBarCardV3}>
              <PercentBarCardV3
                index={1}
                headerName={t('ILeftBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.vertical?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV3
                index={2}
                headerName={t('IRightBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV3
                index={3}
                headerName={t('ILeftRightBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.horizontal ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
            </div>
          </div>
          <div
            className={`${classes.cardRightContent} ${classes.cardRightContentAdditional}`}
          >
            <div>
              <p className={classes.textBold}>
                {t('IBrainConnectivityAnalysisResultsTitle1')}
              </p>
              <br />
              <p className={classes.textBlack}>
                {t('IBrainConnectivityAnalysisResultsTitle2')}
              </p>
            </div>
            <Analysis>
              <div className={classes.connectivityBrain}>
                <p className={classes.textBold}>{textPart2Title1}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart2Text1}
                </p>

                <p className={classes.textBold}>{textPart2Title2}</p>
                <p className={classes.textP}>{textPart2Text2}</p>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default PagePrintWrap2
