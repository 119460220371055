import {AnalysisProduct, PriceSet, PurchasedBundle} from 'api/paymentApi'

export default class PurchasedBundleModel implements PurchasedBundle {
  analyses: [AnalysisProduct]

  analysisGroup: [string]

  name: string

  bundleId: string

  givenAmount: number

  remainAmount: number

  priceSet: PriceSet

  constructor(raw: PurchasedBundle) {
    this.analyses = raw.analyses
    this.analysisGroup = raw.analysisGroup
    this.name = raw.name
    this.bundleId = raw.bundleId
    this.givenAmount = raw.givenAmount
    this.remainAmount = raw.remainAmount
    this.priceSet = raw.priceSet
  }

  getGivenAmount = (): number => {
    return this.givenAmount
  }

  getRemainAmount = (): number => {
    return this.remainAmount
  }
}
