import {XAxis, YAxis} from '@react-financial-charts/axes'
import {
  CrossHairCursor,
  CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY,
} from '@react-financial-charts/coordinates'
import {Chart, ChartCanvas} from '@react-financial-charts/core'
import {LineSeries} from '@react-financial-charts/series'
import {MovingAverageTooltip} from '@react-financial-charts/tooltip'
import {Colors} from 'components/common/useBaseStyle'
import {Channel} from 'constants/AnalysisConstant'
import {format} from 'd3-format'
import {scaleLinear} from 'd3-scale'
import {
  channelAccessor,
  identity,
  PowerSpectrumData,
} from 'pages/Popup/PowerSpectrum/graph'
import React, {useCallback, useMemo} from 'react'

const margin = {left: 0, right: 46, top: 0, bottom: 24}

const yFormat = format('.3f')

type MovingAverageTooltipOption = {
  yAccessor: (data: any) => number
  type: string
  stroke: string
  windowSize: number
}

interface LineGraphProps {
  readonly data: PowerSpectrumData[]
  readonly channel: Channel
  readonly pairableChannel: Channel
  readonly pairable: boolean
  readonly width: number
  readonly height: number
}

export default function LineGraph({
  data,
  channel,
  width,
  height,
  pairable,
  pairableChannel,
}: LineGraphProps) {
  const hzAccessor = (d?: PowerSpectrumData) => d?.hz ?? 0

  const targetAccessor = useCallback(channelAccessor(channel), [channel])

  const pairAccessor = useCallback(channelAccessor(pairableChannel), [
    pairableChannel,
  ])

  const diffAccessor = useCallback(
    (d: PowerSpectrumData) => d[channel] - d[pairableChannel],
    [channel, pairableChannel],
  )

  const xExtent = useMemo(() => {
    const hz = data.map(hzAccessor).filter(identity)

    const min = Math.min(...hz, 0)
    const max = Math.max(...hz, 0)

    return [min, max]
  }, [data])

  const yExtent = useMemo(() => {
    const ch = data.map(targetAccessor).filter(identity)
    const pair = data.map(pairAccessor).filter(identity)

    const min = Math.min(...ch, ...pair, 0)
    const max = Math.max(...ch, ...pair, 0)

    return [min, max]
  }, [data])

  const diffExtent = useMemo(() => {
    const diff = data.map(diffAccessor).filter(identity)
    const min = Math.min(...diff)
    const max = Math.max(...diff)
    return [min, max]
  }, [data])

  const diffHeight = useMemo(() => (pairable ? 100 : 0), [pairable])
  const diffOrigin = (_: number, h: number) => [0, h - diffHeight]
  const gridHeight = height - margin.top - margin.bottom
  const chartHeight = gridHeight - diffHeight

  const pairableTooltipOptions: MovingAverageTooltipOption[] = [
    {
      yAccessor: targetAccessor,
      type: channel,
      stroke: Colors.primary,
      windowSize: 1,
    },
    {
      yAccessor: pairAccessor,
      type: pairableChannel,
      stroke: Colors.secondary,
      windowSize: 1,
    },
    {
      yAccessor: diffAccessor,
      type: 'diff',
      stroke: Colors.error,
      windowSize: 1,
    },
  ]

  const nonPairableTooltipOptions: MovingAverageTooltipOption[] = [
    {
      yAccessor: targetAccessor,
      type: channel,
      stroke: Colors.primary,
      windowSize: 1,
    },
  ]

  const tooltipOptions = useMemo(
    () => (pairable ? pairableTooltipOptions : nonPairableTooltipOptions),
    [pairable],
  )

  return (
    <ChartCanvas
      className='react-financial-charts'
      width={width}
      height={height}
      ratio={2}
      margin={margin}
      data={data}
      // @ts-ignore
      xScale={scaleLinear()}
      xAccessor={hzAccessor}
      xExtents={xExtent}
      seriesName='PowerSpectrum data'
    >
      <Chart id={1} height={chartHeight} yExtents={yExtent}>
        <XAxis showGridLines gridLinesStrokeStyle='#e0e3eb' />
        <YAxis showGridLines />

        {/* channel */}
        <LineSeries yAccessor={targetAccessor} strokeStyle={Colors.primary} />
        <CurrentCoordinate
          yAccessor={targetAccessor}
          fillStyle={Colors.primary}
        />

        {/* pair */}
        {pairable && (
          <React.Fragment>
            <LineSeries
              yAccessor={pairAccessor}
              strokeStyle={Colors.secondary}
            />
            <CurrentCoordinate
              yAccessor={pairAccessor}
              fillStyle={Colors.secondary}
            />
          </React.Fragment>
        )}

        <MouseCoordinateY rectWidth={margin.right} displayFormat={yFormat} />

        <MovingAverageTooltip
          origin={[0, 0]}
          displayFormat={yFormat}
          options={tooltipOptions}
        />
      </Chart>
      {pairable && (
        <Chart
          id={2}
          height={diffHeight}
          yExtents={diffExtent}
          padding={{top: 16, bottom: 8}}
          origin={diffOrigin}
        >
          <XAxis showGridLines gridLinesStrokeStyle='#e0e3eb' />
          <YAxis showGridLines />

          <MouseCoordinateX displayFormat={yFormat} />
          <MouseCoordinateY rectWidth={margin.right} displayFormat={yFormat} />

          <LineSeries yAccessor={diffAccessor} strokeStyle={Colors.error} />
          <CurrentCoordinate
            yAccessor={diffAccessor}
            fillStyle={Colors.error}
          />
        </Chart>
      )}
      <CrossHairCursor />
    </ChartCanvas>
  )
}
