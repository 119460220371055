import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {SearchedCustomerRegistration, sendMailInvoiceApi} from 'api/invoiceApis'
import {Button, TableCell} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TableCustomerSearch from 'pages/CustomerManagement/InvoiceList/TableCustomerSearch'
import useStyles from 'components/Table/useTableStyles'
import Pagination from 'components/molcules/Pagination'
import useCustomerRegistration from 'features/invoice/useInvoiceList'
import {dateToPeriodString, getDefaultPeriodDate} from 'helpers/dateHelper'
import {getQueryFromUrl} from 'helpers/commonHelper'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import RouteConstant from 'constants/RouteConstant'

interface RenderTableRowProps {
  item: SearchedCustomerRegistration
  onClick: (item: SearchedCustomerRegistration) => void
  isSelected: (item: SearchedCustomerRegistration) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()
  const history = useHistory()
  const labelId = `enhanced-table-checkbox-${item.id}`
  const classes = useStyles()
  const checked = isSelected(item)
  const handleClick = () => onClick(item)
  const formatDate = (date: string) => {
    const year = parseInt(date.substring(0, 4), 10)
    const month = parseInt(date.substring(4, 6), 10) - 1
    const formattedDate = new Date(year, month)
    const valueDate = `01/${(formattedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${formattedDate.getFullYear()}`

    return valueDate
  }
  const handleToInvoiceView = (item: SearchedCustomerRegistration) => {
    history.push(
      `${RouteConstant.INVOICE_VIEW.path}?invoiceId=${
        item.id
      }&usageMonth=${formatDate(item.usageMonth)}`,
    )
  }
  const handleGoToDetailOrg = (id: number | undefined) => {
    if (typeof id === 'number') {
      history.push(`${RouteConstant.MEMBER_EDIT.path}?orgId=${id}`)
    }
  }
  return (
    <TableRow hover onClick={handleClick}>
      <TableCell padding='checkbox'>
        <Checkbox
          checked={checked}
          disabled={!item.billingFileId}
          inputProps={{'aria-labelledby': labelId}}
        />
      </TableCell>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>{item.countryName}</TableCell>
      <TableCell
        align='center'
        onClick={() => handleGoToDetailOrg(item.orgId)}
        className={classes.buttonActiveLink}
      >
        {item.orgName}
      </TableCell>
      <TableCell align='center'>
        {t('IGetFullName', {
          firstName: item?.orgUserFirstname ?? '',
          lastName: item?.orgUserLastname ?? '',
        }).trim()}
      </TableCell>
      <TableCell align='center'>{item.email}</TableCell>
      <TableCell align='center'>{item.phoneNumber}</TableCell>
      <TableCell align='center'>{item.manager}</TableCell>
      <TableCell align='center'>{item.billingPrice}</TableCell>
      <TableCell align='center'>{item.usageMonth}</TableCell>
      <TableCell align='center'>
        {item.confirmed ? t('ISuccess') : t('IWriting')}
      </TableCell>
      <TableCell align='center'>
        {item.billingFileId
          ? t('IActiveSendMailPDF')
          : t('IActiveSendMailNoPDF')}
      </TableCell>
      <TableCell
        align='center'
        onClick={() => handleToInvoiceView(item)}
        className={classes.buttonActiveLink}
      >
        {item.deployed ? t('ITransmissionComplete') : t('INotCent')}
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const location = useLocation()
  const classes = useStyles()
  const [selected, setSelected] = useState<SearchedCustomerRegistration[]>([])
  const selectedId = useMemo(() => selected.map((item) => item.id), [selected])
  const selection = getDefaultPeriodDate()
  const {loading, query, paging, pagingInfo, items, onSearch} =
    useCustomerRegistration()
  const setPageIndex = (pageNumber: number) => {
    onSearch({
      ...query,
      paging: {
        pageNumber,
        pageSize: paging.pageSize,
      },
    })
  }
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const pageSize = parseInt(event.target.value as string, 10)
    onSearch({...query, paging: {pageNumber: 0, pageSize}})
  }

  const activeSendMail = async () => {
    if (!selected.length) return
    const listId = selected.map((item) => item.id)
    try {
      await sendMailInvoiceApi(listId.sort().join(','))
      onSuccessModalOpen(t('IEmailHasBeenSent'))
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error))
      onFailureModalOpen(
        err.err.debugMessage
          ? err.err.debugMessage
          : t('ISelectDataInProgress'),
      )
    } finally {
      onSearch({...query})
    }
  }

  const handleRowClick = (item: SearchedCustomerRegistration) => {
    if (!item.billingFileId) return
    const selectedIndex = selectedId.indexOf(item.id)

    let newSelected: SearchedCustomerRegistration[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedCustomerRegistration) => {
    if (!item.billingFileId) return false
    return selectedId.indexOf(item.id) !== -1
  }

  const successItems = items.filter((item) => item.id)
  const emptyRows = items === null ? 10 : 10 - items.length

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter((item) => item.id))
      return
    }
    setSelected([])
  }

  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      search: {orgName: ''},
      period: dateToPeriodString(selection),
      paging: {pageNumber: 0, pageSize: 10},
      ...initQuery,
      type: 'All',
    })
  }, [])

  return (
    <>
      <TableContainer>
        <TableCustomerSearch isRoot={false} query={query} onSearch={onSearch} />
        <div style={{display: 'flex', justifyContent: 'end'}}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            type='submit'
            onClick={() => activeSendMail()}
            className={classes.buttonSendInvoice}
          >
            {t('ICustomerSendInvoice')}
          </Button>
        </div>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell align='center' padding='none'>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < successItems.length
                  }
                  checked={
                    successItems.length > 0 &&
                    selected.length === successItems.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{'aria-label': 'select all desserts'}}
                />
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerNumber')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerNation')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerOrganizationName')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerRepresentative')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerMail')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerContact')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerManager')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerAmount')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerInstitution')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IStatus')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IActiveSendMail')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICustomerInvoice')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <RenderTableRow
                key={item.id}
                item={item}
                onClick={handleRowClick}
                isSelected={isRowSelected}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={14} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.pageNumber}
          itemCountPerPage={query.paging.pageSize}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={onSizeChange}
        />
      </TableContainer>
    </>
  )
}

export default PageTable
