import {HRVNormSearchRequest} from 'api/analysisApi'
import {
  searchHrvNorm,
  selectError,
  selectItems,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectPeriod,
  selectQuery,
  selectSearch,
} from 'features/analysis/hrvNormSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useHrvNorm() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectItems)

  const period = useAppSelector(selectPeriod)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)

  const onSearch = (payload: HRVNormSearchRequest) =>
    dispatch(searchHrvNorm(payload))

  return {
    loading,
    error,
    items,
    period,
    paging,
    pagingInfo,
    search,
    query,
    onSearch,
  }
}
