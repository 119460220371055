import Dialog from '@material-ui/core/Dialog'
import {IcaRequestRes, requestIcaApi} from 'api/analysisApi'
import CancelButton from 'components/atoms/Buttons/CancelButton'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import {useIcaResultDialog} from 'features/analysis/useEegIndividualIcaDialog'
import MultiIcaResultTable from 'pages/Analysis/EEGIndividual/ReAnalysis/MultiIcaResultTable'
import {StyledIcaDialog} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledIcaDialog'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

function IcaResultDialog() {
  const {t} = useTranslation()

  const {open, selected: requestIds, ReAnalysis, onClose} = useIcaResultDialog()

  const {reSelectYn, reAnalysisYn, sraYn} = ReAnalysis

  const [loading, setLoading] = useState(false)
  const [icaRequestResults, setIcaRequestResults] = useState<
    IcaRequestRes[] | undefined
  >()
  const [error, setError] = useState<string | undefined>()

  const requestIca = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const resp = await requestIcaApi({
        requestIds,
        reSelectYn: reSelectYn ?? 'N',
        reAnalysisYn: reAnalysisYn ?? 'N',
        sraYn: sraYn ?? 'N',
      })
      setIcaRequestResults(resp.list)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      requestIca()
    }
  }, [open])

  const handleClose = () => onClose()

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledIcaDialog>
        <h3 className='header'>{t('IReAnalysis_post_alert')}</h3>
        <div className='content'>
          {loading && <div>{t('ILoading')}</div>}
          {!loading && icaRequestResults !== undefined && (
            <MultiIcaResultTable items={icaRequestResults} />
          )}
          {!loading && error !== undefined && <div>{error}</div>}
        </div>
        <div className='footer'>
          <CancelButton onClick={handleClose} />
          <ConfirmButton onClick={handleClose} />
        </div>
      </StyledIcaDialog>
    </Dialog>
  )
}

export default IcaResultDialog
