import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import {ClientName} from 'components/atoms/ClientName'
import {
  GenderValue,
  HandValue,
  toGenderString,
  toHandString,
  TYPE_WHITE_LIST,
} from 'constants/CommonConstant'
import usePatient from 'features/patient/usePatient'
import {useReadDialog} from 'features/patient/usePatientDialog'
import useWhiteListReadDialog from 'features/patient/usePatientWhiteListDialog'
import {isDefined} from 'helpers/commonHelper'
import {getAgeToday} from 'helpers/dateHelper'
import UserComments from 'pages/OrgPage/TreatmentPage/SearchOne/UserComments'
import {getFeatures} from 'api/storageApi'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingBottom: 20,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        height: 30,
      },
    },
    headerName: {
      fontSize: 19,
      marginRight: 10,
      fontWeight: 700,
      color: '#333333',
      flexWrap: 'wrap',
      wordBreak: 'break-all',
    },
    headerInfoButton: {
      marginRight: 10,
      border: '1px solid #ced4da',
      borderRadius: 0,
      backgroundColor: 'white',
      color: '#868e96',
      fontSize: 11,
    },
    headerOnlineButton: {
      borderRadius: 0,
      fontSize: 11,
    },
    headerAllButton: {
      marginLeft: 'auto',
    },
    body: {
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },

      '& > div': {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
          marginBottom: 10,
        },
        '& > .MuiCard-root': {
          borderRadius: 6,
          backgroundColor: '#f4f4f7',
        },
      },
    },
    userRoot: {
      [theme.breakpoints.up('sm')]: {
        marginRight: 10,
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },

      '& .root': {
        [theme.breakpoints.up('sm')]: {
          padding: '20px 0 0 0',
        },
        [theme.breakpoints.down('xs')]: {
          padding: 10,
        },
      },
      '& .container': {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0',
        '& .label': {
          flex: 2,
          fontWeight: 'bold',
          color: '#454545',
          textAlign: 'start',
        },
        '& .text': {
          flex: 3,
          textAlign: 'start',
          color: '#333333',
        },
      },
    },
    commentRoot: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 10,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    showButton: {
      backgroundColor: '#5a6268',
      border: '1px solid #5a6268',
      color: 'white',
      '&:hover': {
        backgroundColor: 'white',
        color: '#5a6268',
      },
    },
  })

const useStyles = makeStyles(styles)

interface SimplePatientInfoProps {
  error: any
  patient: Patient | null
  onReload: VoidFunction
  isWhiteList: boolean
  nameProduct: string
}

const SimplePatientInfo = ({
  error,
  patient,
  onReload,
  isWhiteList,
  nameProduct,
}: SimplePatientInfoProps) => {
  const {t} = useTranslation()

  return (
    <div className='root'>
      {error && (
        <div>
          {error}
          <IconButton onClick={onReload}>
            <RefreshIcon />
          </IconButton>
        </div>
      )}
      {!error && patient && (
        <>
          <div className='container'>
            <div className='label'>{t('ICareDoctor')}</div>
            <div className='text'>
              <ClientName
                firstName={patient?.primaryFirstName ?? 'Unknown'}
                lastName={patient?.primaryLastName ?? ''}
              />
            </div>
          </div>
          {!isWhiteList && (
            <div className='container'>
              <div className='label'>{t('ITel')}</div>
              <div className='text'>{patient.phone}</div>
            </div>
          )}
          <div className='container'>
            <div className='label'>{t('IBirthday')}</div>
            <div className='text'>
              {patient.birth}({getAgeToday(patient.birth)})
            </div>
          </div>
          <div className='container'>
            <div className='label'>{t('IGender')}</div>
            <div className='text'>
              {t(toGenderString(patient.gender as GenderValue))}
            </div>
          </div>
          {!isWhiteList && (
            <div className='container'>
              <div className='label'>{t('IHandedness')}</div>
              <div className='text'>
                {t(toHandString(patient.hand as HandValue))}
              </div>
            </div>
          )}
          <div className='container'>
            <div className='label'>{t('IHcAnalysisTypes')}</div>
            <div className='text'>{nameProduct}</div>
          </div>
        </>
      )}
    </div>
  )
}

interface UserInfoProps {
  uuid: string
  isWhiteList: boolean
}

const PatientInfo = ({uuid, isWhiteList}: UserInfoProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {entity: patient, error: patientError, onFetch} = usePatient()
  const {onOpen} = useReadDialog()
  const {onOpen: onOpenWhiteList, listProducts: list} = useWhiteListReadDialog()

  const handleReload = () => onFetch(uuid)
  const handlePatientInfo = (uuid: string) => {
    if (
      TYPE_WHITE_LIST.every((item) =>
        JSON.parse(getFeatures() ?? '[]').includes(item),
      )
    )
      onOpenWhiteList(uuid)
    else onOpen(uuid)
  }
  const nameProduct = useMemo(() => {
    return (
      list.find((item) => item.id === patient?.healthProductId)?.displayName ||
      ''
    )
  }, [list, patient?.healthProductId])
  useEffect(() => {
    handleReload()
  }, [uuid])

  return (
    <Grid container className={classes.body}>
      <Grid item xs={12} sm={4} className={classes.userRoot}>
        {/* <SubTitle>{t('ICustomerRead')}</SubTitle> */}
        <div
          style={{
            backgroundColor: '#f4f4f7',
            padding: '40px 40px',
          }}
        >
          <div>
            <div className={classes.headerName} style={{marginBottom: 10}}>
              <ClientName
                firstName={patient?.firstName ?? 'Unknown'}
                lastName={patient?.lastName ?? ''}
              />
            </div>
            <div>
              <Button
                variant='outlined'
                className={classes.headerInfoButton}
                onClick={() => {
                  handlePatientInfo(patient?.uuid ?? '')
                }}
              >
                {t('IDetailInfo')}
              </Button>
              <Button
                disabled
                variant='outlined'
                className={classes.headerOnlineButton}
                style={{backgroundColor: '#b4b6b7'}}
              >
                {t('IOnlineContact')}
              </Button>
            </div>
          </div>

          {isDefined(patient) && (
            <SimplePatientInfo
              patient={patient}
              error={patientError}
              onReload={handleReload}
              isWhiteList={isWhiteList}
              nameProduct={nameProduct}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={8} className={classes.commentRoot}>
        {/* <SubTitle>{t('ICommentList')}</SubTitle> */}
        <div style={{border: '1px solid #e4e7eb', padding: '0 20px'}}>
          <UserComments uuid={uuid} />
        </div>
      </Grid>
    </Grid>
  )
}

export default PatientInfo
