import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  IndividualNormSearchRequest,
  SearchedIndividualNorm,
  searchIndividualNormApi,
} from 'api/analysisApi'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchEegNorm = createAsyncThunk(
  'api/eeg/norm/search',
  async (payload: IndividualNormSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchIndividualNormApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface EegNormState {
  loading: boolean
  error: any
  items: SearchedIndividualNorm[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: EegNormState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'eegNormSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchEegNorm.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search, paging} = action.meta.arg
      state.period = period
      state.search = search
      state.paging = paging
    })
    builder.addCase(searchEegNorm.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.loading = false
      state.error = null
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchEegNorm.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) => state.individualNorm.loading
export const selectError = (state: RootState) => state.individualNorm.error
export const selectItems = (state: RootState) => state.individualNorm.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.individualNorm.period)
export const selectSearch = (state: RootState) => state.individualNorm.search
export const selectPaging = (state: RootState) => state.individualNorm.paging
export const selectPagingInfo = (state: RootState) =>
  state.individualNorm.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.individualNorm.period,
  search: state.individualNorm.search,
  paging: state.individualNorm.paging,
})
