import React from 'react'
import {Checkbox, Radio, RadioGroup} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {LabelPreset, PRESET_KEY} from '../constant'
import useStyle from '../Style'

interface RenderTablePresetProps {
  updateSelectedSet: (
    newSelected: string[],
    removeSelect: string[],
    selectedKey: string,
  ) => void
  data: LabelPreset[]
  keySelected: string[]
  allSelected: string[]
}

function RenderTablePreset(props: RenderTablePresetProps) {
  const {t} = useTranslation()
  const {data, keySelected, allSelected, updateSelectedSet} = props
  const classes = useStyle()
  const getEachTableLabel = (position: number) => {
    return data.filter((item: any) => item.position === position)
  }
  const updateValue = (selected: LabelPreset) => {
    let newSelected = keySelected
    let removeSelect = keySelected
    if (selected.position === 1) {
      // Case remove value
      if (keySelected.includes(selected.key)) {
        newSelected = []
        removeSelect = removeSelect.filter((item) =>
          data.map((value) => value.key).includes(item),
        )
      } else {
        newSelected.push(selected.key)
        if (selected.key === 'eegIndv') newSelected.push('eegIndv3dOff')
        if (selected.key === 'eegNorm') newSelected.push('1.96', 'eegNorm3dOff')
        if (selected.key === 'eegSummary') newSelected.push('eegSummaryBas')
        if (selected.key === 'hrvSummary') newSelected.push('hrvSummaryBas')

        removeSelect = []
      }
    }
    // Update data in second column
    else if (selected.position === 2) {
      newSelected = [selected.key]
      const dataSamePosition = data.filter((item) => item.position === 2)
      const keySamePosition = dataSamePosition.map((item) => item.key)
      removeSelect = keySamePosition.filter((item) => item !== selected.key)
    }
    // Update data in third column
    else if (selected.position === 3) {
      // Case remove value
      newSelected = [selected.key]
      const dataSamePosition = data.filter((item) => item.position === 3)
      const keySamePosition = dataSamePosition.map((item) => item.key)
      removeSelect = keySamePosition.filter((item) => item !== selected.key)
    }

    updateSelectedSet(newSelected, removeSelect, selected.key)
  }

  const isDisableCheckbox = (key: string): boolean | undefined => {
    const EEG = [PRESET_KEY.eegNorm, PRESET_KEY.eegMci, PRESET_KEY.eegSummary]
    const HRV = [PRESET_KEY.hrvSummary]
    if (
      (key === PRESET_KEY.eegIndv &&
        EEG.find((item) => allSelected.includes(item))) ||
      (key === PRESET_KEY.hrvIndv &&
        HRV.find((item) => allSelected.includes(item)))
    ) {
      return true
    }
    return false
  }

  return (
    <>
      {/* Render Report Title */}
      {getEachTableLabel(1)?.map((item: LabelPreset) => {
        return (
          <div>
            <label htmlFor={item.key} className={classes.reportTypeTitle}>
              <Checkbox
                style={{
                  padding: 0,
                }}
                id={item.key}
                checked={keySelected.includes(item.key)}
                onChange={() => updateValue(item)}
                disabled={isDisableCheckbox(item.key)}
              />
              {t(item.label)}
            </label>
          </div>
        )
      })}
      {/* Render Report Fist row */}
      {keySelected.includes(getEachTableLabel(1)[0].key) &&
        getEachTableLabel(2).length > 0 && (
          <div className={classes.reportFieldContainer}>
            {getEachTableLabel(2)[0].field && (
              <p className={classes.reportFieldTitle}>
                {t(getEachTableLabel(2)[0].field || '')}
              </p>
            )}
            <RadioGroup
              style={{display: 'flex', flexDirection: 'row', gap: 20}}
            >
              {getEachTableLabel(2)?.map((item: LabelPreset) => {
                return (
                  <label
                    htmlFor={item.key}
                    style={{display: 'flex', alignItems: 'center'}}
                    className={classes.fieldRadio}
                  >
                    <Radio
                      style={{padding: 0}}
                      id={item.key}
                      name={item.name}
                      value={item.key}
                      // checked={defaultRadio === item.key}
                      checked={keySelected.includes(item.key)}
                      onChange={() => updateValue(item)}
                      disabled={
                        !getEachTableLabel(1).find((val) =>
                          keySelected.includes(val.key),
                        ) || item.key === PRESET_KEY.eegIndvIca
                      }
                    />
                    {t(item.label)}
                  </label>
                )
              })}
            </RadioGroup>
          </div>
        )}

      {/* Render Report Second row */}
      {keySelected.includes(getEachTableLabel(1)[0].key) &&
        getEachTableLabel(3)?.length > 0 && (
          <div className={classes.reportFieldContainer}>
            <p className={classes.reportFieldTitle}>
              {t(getEachTableLabel(3)[0]?.field || '')}
            </p>
            <RadioGroup
              style={{display: 'flex', flexDirection: 'row', gap: 20}}
            >
              {getEachTableLabel(3).map((item: LabelPreset) => {
                return (
                  <label
                    htmlFor={item.key}
                    style={{display: 'flex', alignItems: 'center'}}
                    className={`${classes.fieldRadio}`}
                  >
                    <Radio
                      style={{padding: 0}}
                      id={item.key}
                      name={item.name}
                      value={item.key}
                      checked={keySelected.includes(item.key)}
                      onChange={() => updateValue(item)}
                      disabled={
                        !getEachTableLabel(1).find((val) =>
                          keySelected.includes(val.key),
                        ) || item.key === PRESET_KEY.eegIndvIca
                      }
                    />
                    {t(item.label)}
                  </label>
                )
              })}
            </RadioGroup>
          </div>
        )}
    </>
  )
}
export default RenderTablePreset
