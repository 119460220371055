import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Table, TableBody, TableHead, TableRow} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'

import useInvoiceView from 'features/invoice/useInvoiceView'
import {ProductType} from 'features/invoice/invoiceSlice'
import {DataTableUsageSummary} from 'features/invoice/invoiceViewSlice'

import InvoiceViewStyle from 'pages/CustomerManagement/InvoiceView/Style'
import {
  USAGE_SUMMARY_HEAD,
  USAGE_SUMMARY_MOCK,
  USAGE_SUMMARY_POSITION_HEAD,
  USAGE_SUMMARY_POSITION_BODY,
} from 'pages/CustomerManagement/InvoiceView/constant'

const useStyles = makeStyles(InvoiceViewStyle)

type CellSpan = {
  pos: number
  num: number
}
interface MergeCell {
  row: number
  rowSpan: CellSpan[]
  colSpan: CellSpan[]
}

function UsageSummary() {
  const {t} = useTranslation()
  const classes = useStyles()
  const [usageSummaryBody, setUsageSummaryBody] = useState(USAGE_SUMMARY_MOCK)
  const {usageSummaryData} = useInvoiceView()
  const getNum = (cellSpan: CellSpan[], col: number) => {
    const posData = cellSpan?.map((item) => item.pos)
    if (posData?.includes(col)) {
      return cellSpan?.find((item) => item.pos === col)?.num || 1
    }
    return 1
  }

  const getMergeCell = (data: MergeCell[], row: number, col: number) => {
    let rowSpan = 1
    let colSpan = 1
    if (!data.length) return {rowSpan, colSpan}
    const rowIds = data.map((item) => item.row)
    if (!rowIds.includes(row)) return {rowSpan, colSpan}
    // get rowSpan property
    const cellRowSpanData = data.find((item) => item.row === row)?.rowSpan
    if (cellRowSpanData?.length) {
      rowSpan = getNum(cellRowSpanData, col)
    }
    // get ColSpan property
    const cellColSpanData = data.find((item) => item.row === row)?.colSpan
    if (cellColSpanData?.length) {
      colSpan = getNum(cellColSpanData, col)
    }
    return {rowSpan, colSpan}
  }
  const getDataFromKey = (key: ProductType) => {
    const nullData = {
      count: 0,
      price: 0,
      total: 0,
    }
    if (!usageSummaryData) return nullData
    const usageSummaryItem = usageSummaryData.filter(
      (item) => item.productType === key,
    )
    if (!usageSummaryItem) return nullData
    return {
      count: usageSummaryItem.reduce((total, item: DataTableUsageSummary) => {
        return total + item.count
      }, 0),
      price: 0,
      total: usageSummaryItem.reduce((total, item: DataTableUsageSummary) => {
        return total + item.count * item.price
      }, 0),
    }
  }
  useEffect(() => {
    if (usageSummaryData?.length > 0) {
      const newData = JSON.parse(JSON.stringify(usageSummaryBody))
      // Fill data first row
      newData[0][1] = getDataFromKey('eegIndv').count.toString()
      newData[0][2] = getDataFromKey('eegSummary').count.toString()
      newData[0][3] = getDataFromKey('eegSummaryAdv').count.toString()
      newData[0][4] = getDataFromKey('eegIndv3d').count.toString()
      newData[0][5] = getDataFromKey('hrvIndv').count.toString()
      newData[0][6] = getDataFromKey('hrvSummary').count.toString()
      newData[0][7] = getDataFromKey('hrvSummaryAdv').count.toString()
      newData[0][8] = getDataFromKey('eegMci').count.toString()
      newData[0][9] = getDataFromKey('eegNorm').count.toString()
      newData[0][10] = getDataFromKey('eegNorm3d').count.toString()
      newData[0][11] = ''
      // Fill data second row
      newData[1][1] = (
        parseInt(newData[0][1], 10) +
        parseInt(newData[0][2], 10) +
        parseInt(newData[0][3], 10) +
        parseInt(newData[0][4], 10)
      ).toString()
      newData[1][2] = (
        parseInt(newData[0][5], 10) +
        parseInt(newData[0][6], 10) +
        parseInt(newData[0][7], 10)
      ).toString()
      newData[1][3] = (parseInt(newData[0][8], 10) + 0).toString()
      newData[1][4] = (
        parseInt(newData[0][9], 10) + parseInt(newData[0][10], 10)
      ).toString()
      newData[1][5] = ''

      // Fill data third row
      newData[2][1] = (
        getDataFromKey('eegIndv').total +
        getDataFromKey('eegSummary').total +
        getDataFromKey('eegSummaryAdv').total +
        getDataFromKey('eegIndv3d').total
      ).toString()
      newData[2][2] = (
        getDataFromKey('hrvIndv').total +
        getDataFromKey('hrvSummary').total +
        getDataFromKey('hrvSummaryAdv').total
      ).toString()
      newData[2][3] = getDataFromKey('eegMci').total.toString()
      newData[2][4] = (
        getDataFromKey('eegNorm').total + getDataFromKey('eegNorm3d').total
      ).toString()
      newData[2][5] = ''
      setUsageSummaryBody(newData)
    } else if (!usageSummaryData.length) {
      setUsageSummaryBody(JSON.parse(JSON.stringify(USAGE_SUMMARY_MOCK)))
    }
  }, [usageSummaryData])

  return (
    <div className={classes.defaultBilling} style={{marginTop: 10}}>
      <div className={classes.defaultBillingTitle}>{t('IUsageSummary')}</div>
      <div className={classes.defaultBillingTable}>
        <Table>
          <TableHead>
            {USAGE_SUMMARY_HEAD.map((data, rowI) => (
              <TableRow key={`usage_${rowI}`}>
                {data.map((cell, collId) => (
                  <TableCell
                    key={`cell_usage_${collId}`}
                    {...getMergeCell(USAGE_SUMMARY_POSITION_HEAD, rowI, collId)}
                    className={`${classes.header} ${classes.backGroundHead}`}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {usageSummaryBody.map((data, rowI) => (
              <TableRow key={`usage_body_${rowI}`}>
                {data.map((cell, collId) => (
                  <TableCell
                    key={`cell_usage_body_${collId}`}
                    {...getMergeCell(USAGE_SUMMARY_POSITION_BODY, rowI, collId)}
                    className={classes.cell}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default UsageSummary
