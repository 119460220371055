/**
 * 기관 관리 - 결제 관리 페이지 - 결제 내역 테이블
 * 1. 결제 관리 API(Retrieve billing) 호출해 데이터 가공
 */

import React from 'react'
import {fetchPaymentHistoryApi, PaymentHistoryRequest} from 'api/paymentApi'
import PaymentHistoryTableModel from 'pages/OrgPage/PaymentManagementPages/Models/PaymentHistoryTableModel'
import {
  dateToDashString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import PaymentHistoryTableItemsModel from 'pages/OrgPage/PaymentManagementPages/Models/PaymentHistoryTableItemsModel'
import {useTranslation} from 'react-i18next'
import useFailureModal from 'features/modal/useFailureModal'
import {utils, writeFile} from 'xlsx'

export default function PaymentHistoryTableViewModel() {
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [isPaymentHistoryListLoading, setIsPaymentHistoryListLoading] =
    React.useState<boolean>(false)
  const [paymentHistoryList, setPaymentHistoryList] =
    React.useState<PaymentHistoryTableModel>({} as PaymentHistoryTableModel)

  const startDate = new Date()
  const endDate = new Date()

  // 파라미터 초기값: beginDate, endDate
  const initialDate: PaymentHistoryRequest = {
    beginDate: '2000-01-01',
    endDate: '2300-12-31',
  }

  const [beginEndDate, setBeginEndDate] =
    React.useState<PaymentHistoryRequest>(initialDate)

  // Period Search props selection
  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate,
    endDate,
  })

  // 파라미터 초기값: page, size
  const initialPaging: Paging = {
    page: 1,
    size: 10,
  }

  // Paging State
  const [pagingState, setPagingState] = React.useState<Paging>(initialPaging)

  // Payment History Api 호출
  async function getPaymentHistory(
    date?: PaymentHistoryRequest,
    paging?: Paging,
  ) {
    setIsPaymentHistoryListLoading(true)
    try {
      let params = {...initialDate, ...initialPaging}
      if (date) {
        params = {...params, ...date}
      }
      if (paging) {
        params = {...params, ...paging}
      }

      const result = await fetchPaymentHistoryApi(params)
      const paymentHistory = new PaymentHistoryTableModel(result)

      setPaymentHistoryList(paymentHistory)
      setIsPaymentHistoryListLoading(false)
    } catch (e) {
      console.log('Error:::: getPaymentHistory:::: ', e)
      setIsPaymentHistoryListLoading(false)
    }
  }

  // Handle Period Search button
  const handlePeriod = (period: PeriodDate) => {
    const beginDate = dateToDashString(period.startDate)
    const endDate = dateToDashString(period.endDate)
    const paging = {...pagingState, page: 1}

    // 이전 기간과 같지 않을 때만 호출
    getPaymentHistory({beginDate, endDate}, paging)

    setSelection((prev) => {
      const newData = {
        ...prev,
        startDate: period.startDate,
        endDate: period.endDate,
      }
      return newData
    })

    setBeginEndDate((prev) => {
      const newData = {...prev, beginDate, endDate}
      return newData
    })

    setPagingState((prev) => {
      const newData = {...prev, page: 1}
      return newData
    })
  }

  // 페이지 prev, next 버튼 클릭시 page 설정
  const setPageIndex = (pageIdx: number) => {
    const paging = {...pagingState, page: pageIdx + 1}
    getPaymentHistory(beginEndDate, paging)
    setPagingState(paging)
  }

  // handle page size change(페이지의 사이즈 변경 셀렉트박스 핸들러
  const handlePageSizeChanged = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    const paging = {page: 1, size}

    getPaymentHistory(beginEndDate, paging)

    setPagingState((prev) => {
      const newData = {...prev, ...paging}
      return newData
    })
  }

  // Excel용 PaymentHistoryList 가져오기
  async function getPaymentHistoryForExcel() {
    const maximumSize = 100

    try {
      const params = {
        ...beginEndDate,
        ...pagingState,
        page: 1,
        size: maximumSize,
      }
      const result = await fetchPaymentHistoryApi(params)
      let PaymentHistoryListForExcel: string[][] = [[]]

      // 데이터 가져오면 무조건 1번은 넣기
      if (result) {
        const paymentHistory = new PaymentHistoryTableModel(result)

        PaymentHistoryListForExcel = paymentHistory.items.map(
          (item: PaymentHistoryTableItemsModel) => {
            return [
              item.getOrderNo(),
              isoStringToDateAndTimeString(item.createdAt),
              t(item.getOrderType()),
              item.orderName,
              item.cardName,
              item.getPurchaseAmountWithCurrencyCode(),
            ]
          },
        )
      }

      // size가 최대 100이기 때문에 total이 100 이상일 때 확인해서 더 넣어주기
      if (result.total > maximumSize) {
        const counts = Math.ceil(result.total / maximumSize)
        const countsArr = Array.from({length: counts}, (v, i) => i + 1)

        countsArr.map(async (count) => {
          // page 1은 이미 받아왔기 때문에 2부터
          if (count > 1) {
            const newParams = {...params, page: count}
            const results = await fetchPaymentHistoryApi(newParams)
            const paymentHistory = new PaymentHistoryTableModel(results)

            const PaymentHistoryListForExcelRow = paymentHistory.items.map(
              (item: PaymentHistoryTableItemsModel) => {
                return [
                  item.getOrderNo(),
                  isoStringToDateAndTimeString(item.createdAt),
                  t(item.getOrderType()),
                  item.orderName,
                  item.cardName,
                  item.getPurchaseAmountWithCurrencyCode(),
                ]
              },
            )

            PaymentHistoryListForExcel.push(...PaymentHistoryListForExcelRow)
          }
          return null
        })
      }

      return PaymentHistoryListForExcel
    } catch (e) {
      console.log('Error:::: getPaymentHistoryForExcel:::: ', e)
    }

    return null
  }

  // Handle Excel Export button
  const handleExcelExport = async (header: string[][]) => {
    const result = await getPaymentHistoryForExcel()

    if (result && result.length > 0) {
      const wb = utils.book_new()
      const ws = utils.json_to_sheet(result)
      ws['!cols'] = [
        {wpx: 100},
        {wpx: 120},
        {wpx: 80},
        {wpx: 200},
        {wpx: 100},
        {wpx: 100},
      ]
      utils.sheet_add_aoa(ws, header)
      utils.book_append_sheet(wb, ws, t('IPaymentHistory'))
      writeFile(wb, `${t('IPaymentHistory')}.xlsx`)
    } else {
      onFailureModalOpen(t('IPaymentEmpty'))
    }
  }

  return {
    isPaymentHistoryListLoading,
    paymentHistoryList,
    selection,
    getPaymentHistory,
    handlePeriod,
    pagingState,
    setPagingState,
    setPageIndex,
    beginEndDate,
    handleExcelExport,
    handlePageSizeChanged,
  }
}
