import CardWithTitle from 'components/atoms/CardWithTitle'
import React from 'react'
import {useTranslation} from 'react-i18next'

function EEGGroupCompare() {
  const {t} = useTranslation()
  return (
    <CardWithTitle>
      <div style={{height: 'calc(100vh - 25rem)', marginBottom: 20}}>
        {t('INotYetImplemented')}
      </div>
    </CardWithTitle>
  )
}

export default EEGGroupCompare
