import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function PasswordInput() {
  const createOrgInfo = useFormContext()
  const passwordConfirmInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IPassword')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.inputWrap}>
          <input
            id='password'
            placeholder={t('IRegisterPassword')}
            className={classes.input}
            type='password'
            {...createOrgInfo.register('password')}
          />
          {errors.password && (
            <span className={classes.errorText}>{errors.password.message}</span>
          )}
        </div>
        <div className={classes.inputWrap}>
          <input
            id='passwordConfirm'
            placeholder={t('IRegisterPassword')}
            className={classes.input}
            type='password'
            {...passwordConfirmInfo.register('confirmPassword')}
          />
          {errors.confirmPassword && (
            <span className={classes.errorText}>
              {errors.confirmPassword.message}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
