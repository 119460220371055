// eslint-disable-next-line no-restricted-imports
import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select, {SelectProps} from '@material-ui/core/Select'
import {fetchOrgEegVersionApi} from 'api/organizationApi'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import SelectError from 'components/Select/SelectError'
import useStyles from 'components/Select/selectFormStyle'
import SelectLoading from 'components/Select/SelectLoading'
import useAsyncList from 'hooks/useAsyncList'
import React, {forwardRef} from 'react'
import {useTranslation} from 'react-i18next'

interface SelectAnalysisVersionProps extends SelectProps {
  type: 'EEG' | 'HRV'
  selectedValue: string | null
  onChangeData: (data: DataVersion) => void
}

const SelectBoldAnalysisVersion = forwardRef(
  (props: SelectAnalysisVersionProps, ref) => {
    const {type, selectedValue, onChangeData, onChange, ...selectProps} = props
    const {t} = useTranslation()
    const classes = useStyles()
    const outlinedInputClasses = useOutlinedInputStyles()
    const [state, fetch] = useAsyncList<DataVersion>(
      () =>
        fetchOrgEegVersionApi({
          type,
        }),
      [type],
    )
    const {data, loading, error} = state

    if (loading) return <SelectLoading />
    if (error) return <SelectError error={error} fetch={fetch} />

    return (
      <FormControl classes={{root: classes.root}}>
        <Select
          autoWidth
          //   variant='outlined'
          displayEmpty
          classes={{select: classes.root}}
          className={classes.selectBox}
          {...selectProps}
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
          ref={ref}
          onChange={(e) => {
            const departmentId = e.target.value as number
            const department = data.find((item) => item.id === departmentId)
            if (onChangeData)
              onChangeData(department ?? {id: 0, versionType: '', version: ''})
            if (onChange) onChange(e, null)
          }}
        >
          {/* <MenuItem disabled value=''>
            {t('ISelectRequired')}
          </MenuItem> */}
          {selectedValue && <MenuItem disabled>{selectedValue}</MenuItem>}
          {!selectedValue && (
            <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
          )}
          {data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.version}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  },
)

export default SelectBoldAnalysisVersion
