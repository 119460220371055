import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import SubTitle from 'components/Text/SubTitle'
import {useTranslation} from 'react-i18next'
import React from 'react'

interface HRVSummaryAutoNervousSystemProps {
  customerFirstName: string
  customerLastName: string
  balanceOpinion: string
  balanceCircularImagePath: string
}

export default function HRVSummaryAutoNervousSystem(
  props: HRVSummaryAutoNervousSystemProps,
) {
  const {
    balanceCircularImagePath,
    balanceOpinion,
    customerFirstName,
    customerLastName,
  } = props
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const koreanCustomerName = `${customerLastName} ${customerFirstName}`
  const englishCustomerName = `${customerFirstName} ${customerLastName}`

  return (
    <div className={classes.hrv}>
      <SubTitle className='ReportTitle'>
        1. {t('IHRVSummaryReportAutonomicNervousSystemTitle')}
      </SubTitle>
      <div className={classes.hrvAutoNervous}>
        <img
          className='CircularChartImage'
          src={balanceCircularImagePath}
          alt=''
        />
        <div>
          <div className='Opinion'>
            {i18n.language !== 'ko' &&
              i18n.language !== 'ja' &&
              t('IOpinionOfUser', {name: englishCustomerName})}
            {(i18n.language === 'ko' || i18n.language === 'ja') &&
              t('IOpinionOfUser', {
                name: koreanCustomerName,
              })}
          </div>
          <div className='About'>
            {t('IHRVSummaryReportAutonomicNervousSystemAbout')}
          </div>
          <div className='Balance'>{balanceOpinion}</div>
          <div className='Analysis'>
            <div className='Sympathetic'>
              <div>
                <span className='Label'>{t('ISympathetic')}</span>{' '}
                <span className='Label-append'>{t('IWhenDominant')}</span>
              </div>
              <div>{t('ISymptomOfSympatheticDominant')}</div>
            </div>

            <div className='Parasympathetic'>
              <div>
                <span className='Label'>{t('IParasympathetic')}</span>{' '}
                <span className='Label-append'>{t('IWhenDominant')}</span>
              </div>
              <div>{t('ISymptomOfParasympatheticDominant')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
