import * as React from 'react'
import i18n from 'i18n'
import {checkEmailDuplicateApi, emailAuthenticationApi} from 'api/authApi'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {EmailAuthenticationType} from 'constants/CommonConstant'
import {toLocaleUppercase} from 'helpers/userHelper'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'

interface EmailProps {
  onDuplicateChecked: () => void
  requestType: EmailAuthenticationType
  onSetUuid: (uuid?: string) => void
}

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export default function EmailInput({
  onDuplicateChecked: onSubmit,
  requestType,
  onSetUuid: setUuid,
}: EmailProps) {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const {
    formState: {errors},
    setError,
  } = useFormContext()

  async function checkEmailDuplication(email: string) {
    try {
      const response = await checkEmailDuplicateApi(email)
      if (response.success) {
        onSubmit()
        return response.success
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
    return false
  }

  async function checkEmail(email: string) {
    if (validateEmail(email)) {
      try {
        const response = await emailAuthenticationApi({
          email,
          requestType,
          languageCode: toLocaleUppercase(i18n.language),
        })
        if (response) {
          await onSuccessModalOpen(t('IAuthCodeSendSuccess'))
          setUuid(response.uuid)
          onSubmit()
        } else {
          setError('email', {message: t('IEmailIsExists')})
        }
      } catch (e) {
        onFailureModalOpen(e.message)
      }
    } else {
      setError('email', {message: t('IEmailInvalid')})
    }
  }

  const handleEmailDuplicate = () => {
    const result = checkEmailDuplication(createOrgInfo.getValues('email'))
    result.then((result) => {
      if (result) checkEmail(createOrgInfo.getValues('email'))
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IEmail')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', gap: '16px'}}>
        <div style={{flex: 1}}>
          <input
            style={{width: '100%'}}
            id='email'
            placeholder={t('IEmailRequired')}
            className={classes.input}
            {...createOrgInfo.register('email')}
          />
        </div>
        <div style={{flex: 1}}>
          <ActionButton
            color='primary'
            onClick={handleEmailDuplicate}
            style={{height: 50}}
          >
            {t('IAuthNumber')}
          </ActionButton>
        </div>
      </div>
      {errors.email && (
        <span className={classes.errorText}>{errors.email.message}</span>
      )}
    </div>
  )
}
