export default class PackageConstant {
  public static readonly EEG_INDIVIDUAL = 'EEG_INDIVIDUAL'

  public static readonly EEG_SUMMARY = 'EEG_SUMMARY'

  public static readonly EEG_ADDITIONAL = 'EEG_ADDITIONAL'

  public static readonly EEG_NORM_ADDITIONAL = 'EEG_NORM_ADDITIONAL'

  public static readonly EEG_NORM = 'EEG_NORM'

  public static readonly EEG_MCI = 'EEG_MCI'

  public static readonly HRV_INDIVIDUAL = 'HRV_INDIVIDUAL'

  public static readonly HRV_SUMMARY = 'HRV_SUMMARY'
}
