import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import ReactNewWindow, {
  ReactNewWindowProps,
} from 'components/NewWindow/ReactNewWindowClone'

export type {WindowFeatures} from 'components/NewWindow/ReactNewWindowClone'
export type {ReactNewWindowProps} from 'components/NewWindow/ReactNewWindowClone'

export type NewWindowRef = {
  focus: () => void
}

type ReactNewWindowRef = {
  window: Window
}

const NewWindow = forwardRef<NewWindowRef, ReactNewWindowProps>(
  (props, ref) => {
    const windowRef = useRef<ReactNewWindowRef>()

    useImperativeHandle(
      ref,
      () => ({
        focus() {
          windowRef.current?.window?.focus()
        },
      }),
      [windowRef],
    )

    // @ts-ignore
    return <ReactNewWindow {...props} ref={windowRef} />
  },
)

export default NewWindow
