import React, {ReactNode} from 'react'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'

const useStyles = makeStyles(HealthCenterReportStyle)

type PropsType = {
  children: ReactNode
  number: number
  text: string
}

const WrapElement = ({children, number, text}: PropsType) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapElement}>
      <div className={classes.headElement}>
        <div className={classes.numberElement}>
          <p>{number}</p>
        </div>
        <div className={classes.textElement}>{text}</div>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default WrapElement
