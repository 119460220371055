import {Card} from '@material-ui/core'
import {AllPackages} from 'api/paymentApi'
import {fetchInstitutePackagePriceListApi} from 'api/salesApi'
import {Colors} from 'components/common/useBaseStyle'
import {fetchDomesticListAction} from 'features/paymentProducts/paymentProductsSlice'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import SearchInstitute from './SearchInstitute'
import DomesticPackageTable from './Tables/DomesticPackageTable'
import ForeignPackageTable from './Tables/ForeignPackageTable'
import SingleItemsTable from './Tables/SingleItemsTable'
import {isProd} from '../../../helpers/envHelper'
import OldSalesPaymentPage from './OldSalesPaymentPage'

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const TableDiv = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`

const ResponsiveDiv = styled.div`
  display: flex;
  width: 50%;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`
const SalesPaymentManagementPage = () => {
  const dispatch = useAppDispatch()
  const [allPackages, setAllpackages] = React.useState<AllPackages[]>([])
  const [domesticPackages, setDomesticpackages] = React.useState<AllPackages[]>(
    [],
  )
  const [ForeignPackages, setForeignPackages] = React.useState<AllPackages[]>(
    [],
  )

  const allPackagesAction = async () => {
    try {
      const result = await fetchInstitutePackagePriceListApi()
      if (result) {
        setAllpackages(result)
        setDomesticpackages(
          allPackages.filter((item) => item.packageType === 'DEF'),
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(fetchDomesticListAction())
    allPackagesAction()
  }, [])

  useEffect(() => {
    setDomesticpackages(
      allPackages.filter((item) => item.packageType === 'DEF'),
    )
    setForeignPackages(allPackages.filter((item) => item.packageType === 'PRE'))
  }, [allPackages])

  return (
    <>
      {isProd() && <OldSalesPaymentPage />}
      {!isProd() && (
        <div>
          <Card
            style={{
              padding: '50px 30px 50px 50px',
              border: `1px solid ${Colors.border}`,
              borderRadius: 5,
              marginBottom: 0,
              paddingBottom: 57,
            }}
            elevation={0}
          >
            <CardWrap>
              <TableDiv>
                <DomesticPackageTable items={domesticPackages} />
                <ForeignPackageTable items={ForeignPackages} />
              </TableDiv>
              <ResponsiveDiv>
                <SingleItemsTable />
              </ResponsiveDiv>
            </CardWrap>
          </Card>
          <br />
          <Card
            style={{
              padding: '50px 30px 50px 50px',
              border: `1px solid ${Colors.border}`,
              borderRadius: 5,
              marginBottom: 0,
              paddingBottom: 57,
            }}
            elevation={0}
          >
            <SearchInstitute />
          </Card>
        </div>
      )}

      {/* <br /> */}
      {/* <Card */}
      {/*  style={{ */}
      {/*    padding: '50px 30px 50px 50px', */}
      {/*    border: `1px solid ${Colors.border}`, */}
      {/*    borderRadius: 5, */}
      {/*    marginBottom: 0, */}
      {/*    paddingBottom: 57, */}
      {/*  }} */}
      {/*  elevation={0} */}
      {/* > */}
      {/*  <SalesPaymentManageTable /> */}
      {/* </Card> */}
    </>
  )
}
export default SalesPaymentManagementPage
