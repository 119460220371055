import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'

const useStyles = makeStyles(HealthCenterReportStyle)

type PropsType = {
  children: ReactNode
}

const Analysis = ({children}: PropsType) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.styleAnalysis}>
      <div className={classes.greenLabel}>
        <span>{t('IAnalysisHealth')}</span>
      </div>
      <div className={classes.contentAnalysis}>{children}</div>
    </div>
  )
}

export default Analysis
