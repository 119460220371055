import {makeStyles, Theme} from '@material-ui/core/styles'

const useGaugeStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  botBar: {
    borderRadius: '14px',
    border: '1px solid #CCC',
    background: '#DDD',
    height: '12px',
    alignSelf: 'stretch',
  },
  processBar: {
    borderRadius: '14px',
    border: '1px solid #CCC',
    background: '#DDD',
    height: '280px',
    width: '12px',
    alignSelf: 'stretch',
    position: 'relative',
  },
  marker: {},
  marker1: {
    width: '12px',
    height: '1px',
    position: 'absolute',
    display: 'block',
    opacity: '50%',
    backgroundColor: '#EE3D3B',
    top: 0,
  },
  marker2: {
    position: 'absolute',
    height: '100%',
    marginTop: '5px',
    '& p': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
      color: '#333',
      marginLeft: -15,
    },
  },
  marker3: {
    position: 'absolute',
    top: 0,
    height: '100%',
    '& span': {
      display: 'block',
      width: 1,
      opacity: '50%',
      backgroundColor: '#EE3D3B',
      height: '100%',
      marginBottom: 8,
    },

    '& div': {
      height: 12,
      marginBottom: 8,
    },
  },
  leftArrow: {transform: 'rotate(90deg)'},
  rightArrow: {transform: 'rotate(270deg)'},
  topStatus: {
    display: 'flex',
    width: '60px',
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    background: '#B4B4B4',
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important', // 21.6px
    zIndex: 1,
  },
  percentBarV2Arrow: {
    position: 'absolute',
    top: -15,
  },
  topScorePosition: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important', // 21.6px
    letterSpacing: '-0.54px !important',
  },
  scoreGroup: {
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    position: 'absolute',
    right: '70px',
    top: '-9px',
  },
  lineDot: {
    height: '180px',
    width: '143px',
    position: 'relative',
    marginLeft: '-31px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderRight: 'none',
  },
  topDot: {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    borderRadius: '50%',
    top: '-5px',
    right: 0,
  },
  botDot: {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    borderRadius: '50%',
    bottom: '-5px',
    right: 0,
  },

  scoreGroupRight: {
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    position: 'absolute',
    top: '-9px',
    left: '98px',
  },
  lineDotRight: {
    height: '180px',
    width: '143px',
    position: 'relative',
    marginLeft: '-27px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  topDotRight: {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    borderRadius: '50%',
    top: '-5px',
    left: 0,
  },
  botDotRight: {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    borderRadius: '50%',
    bottom: '-5px',
    left: 0,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  descriptionPosition1: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '15px',
  },
  leftArrowLine: {
    width: '80px',
    height: '1px',
    borderBottom: '1px solid #000',
    borderStyle: 'dashed !important',
  },
  rightArrowLine: {
    width: '80px',
    height: '1px',
    borderBottom: '1px solid #000',
    borderStyle: 'dashed !important',
  },
  processArrowV: {
    width: '272px',
    position: 'relative',
  },
  processArrowV1: {
    height: '272px',
    width: '5px',
    position: 'relative',
  },
}))

export default useGaugeStyles
