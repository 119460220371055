import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {TherapySearchRequest, searchTherapyApi} from 'api/therapyApi'
import {RootState} from 'store'

export const fetchTherapySearch = createAsyncThunk(
  'api/therapy/search',
  async (payload: TherapySearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchTherapyApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export interface TherapySearchState {
  loading: boolean
  searchName: TherapySearchKind | null
  searchValue: string | null
  uid?: string | null
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  period?: PeriodString
  therapy: Therapy[]
  error: any
}
export const initialState: TherapySearchState = {
  loading: false,
  therapy: [],
  error: null,
  searchName: 'CHART_NO',
  searchValue: null,
  pageIndex: 0,
  totalPages: 1,
  totalElements: 0,
  itemPerPage: 5,
  uid: '',
}
export const therapySearchSlice = createSlice({
  name: 'therapySearchSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTherapySearch.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchTherapySearch.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber},
        content: therapy,
        size,
      } = action.payload

      // searchName, searchValue 값이 state에 저장이 안돼서 추가
      const {searchName, searchValue} = action.meta.arg.search

      state.therapy = therapy
      state.loading = false
      state.error = null
      state.totalPages = totalPages
      state.totalElements = totalElements
      state.pageIndex = pageNumber
      state.itemPerPage = size
      state.searchName = searchName ?? null
      state.searchValue = searchValue ?? null
    })
    builder.addCase(fetchTherapySearch.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectError = (state: RootState) => state.therapySearch.error
export const selectList = (state: RootState) => state.therapySearch.therapy
export const selectLoading = (state: RootState) => state.therapySearch.loading
export const selectQuery = (state: RootState) => ({
  search: {
    searchName: state.therapySearch.searchName ?? null,
    searchValue: state.therapySearch.searchValue ?? null,
  },
  paging: {
    page: state.therapySearch.pageIndex,
    size: state.therapySearch.itemPerPage,
  },
  uid: state.therapySearch.uid,
})
export const selectPaging = (state: RootState) => ({
  page: state.therapySearch.pageIndex,
  size: state.therapySearch.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.therapySearch.totalPages,
  totalElements: state.therapySearch.totalElements,
})

export default therapySearchSlice.reducer
