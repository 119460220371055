import React, {ReactNode, useEffect} from 'react'
import styled from 'styled-components'
import Media from 'theme/Media'
import {
  ModalAllStyleProps,
  ModalFooterWrapStyleProps,
  ModalCommonStyleProps,
} from '../Styles'

const StyledModalFooterWrap = styled.div<
  ModalFooterWrapStyleProps & ModalCommonStyleProps & {isChildren: boolean}
>`
  padding-top: ${(props) =>
    props.isChildren ? `${props.paddingFooterTopMobile}px` : null};
  padding-bottom: ${(props) => props.paddingFooterBottomMobile}px;
  padding-left: ${(props) => props.paddingLeftMobile}px;
  padding-right: ${(props) => props.paddingRightMobile}px;
  background: ${(props) => props.bgFooter || 'transparent'};

  ${(props) => Media.min_601`
    padding-top: ${
      props.isChildren ? `${props.paddingFooterTopTablet}px` : null
    };
    padding-right: ${props.paddingRightTablet}px;
    padding-bottom: ${props.paddingFooterBottomTablet}px;
    padding-left: ${props.paddingLeftTablet}px;
  `}
`

interface ModalFooterProps {
  children: ReactNode
  theme: ModalAllStyleProps
}

function ModalFooter({children, theme}: ModalFooterProps) {
  const [isChildren, setIsChildren] = React.useState<boolean>(false)

  useEffect(() => {
    if (children) setIsChildren(true)
  }, [children])

  return (
    <StyledModalFooterWrap
      paddingFooterTopTablet={theme.paddingFooterTopTablet}
      paddingFooterTopMobile={theme.paddingFooterTopMobile}
      paddingFooterBottomTablet={theme.paddingFooterBottomTablet}
      paddingFooterBottomMobile={theme.paddingFooterBottomMobile}
      paddingRightTablet={theme.paddingRightTablet}
      paddingRightMobile={theme.paddingRightMobile}
      paddingLeftTablet={theme.paddingLeftTablet}
      paddingLeftMobile={theme.paddingLeftMobile}
      bgFooter={theme.bgFooter}
      isChildren={isChildren}
    >
      {children}
    </StyledModalFooterWrap>
  )
}

export default ModalFooter
