import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select, {SelectProps} from '@material-ui/core/Select'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
  grayColor,
  primaryBoxShadow,
  primaryColor,
  whiteColor,
} from 'assets/tss/material-dashboard-pro-react'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      padding: '7px 0 7px 7px',
      fontSize: '.75rem',
      lineHeight: '.9rem',
      fontWeight: 400,
      // lineHeight: '1.42857',
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: grayColor[2],
      letterSpacing: '0',
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&[aria-owns] + input + svg': {
        transform: 'rotate(180deg)',
      },
      '& + input + svg': {
        transition: 'all 300ms linear',
      },
    },
    selectFormControl: () => ({
      minWidth: '80px',
      marginTop: 7,
      // @ts-ignore
      // margin: `11px 5px 8px ${props.marginLeft ?? 0}px !important`,
      '& > div': {
        '&:before': {
          borderBottomWidth: '1px !important',
          borderBottomColor: `${grayColor[4]}!important`,
        },
        '&:after': {
          borderBottomColor: `${primaryColor[0]}!important`,
        },
      },
    }),
    selectFormControl2: {
      marginRight: '5px',
    },
    selectLabel: {
      fontSize: '12px',
      textTransform: 'uppercase',
      color: `${grayColor[2]} !important`,
      top: '8px',
    },
    selectMenu: {
      '& > div > ul': {
        border: '0',
        padding: '5px 0',
        margin: '0',
        boxShadow: 'none',
        minWidth: '100%',
        borderRadius: '4px',
        boxSizing: 'border-box',
        display: 'block',
        fontSize: '14px',
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: whiteColor,
        backgroundClip: 'padding-box',
      },
      '& $selectPaper $selectMenuItemSelectedMultiple': {
        backgroundColor: 'inherit',
      },
      '& > div + div': {
        maxHeight: '266px !important',
      },
    },
    selectMenuItem: {
      fontSize: '13px',
      padding: '10px 20px',
      margin: '0 5px',
      borderRadius: '2px',
      transition: 'all 150ms linear',
      display: 'block',
      clear: 'both',
      fontWeight: 400,
      lineHeight: '2',
      whiteSpace: 'nowrap',
      color: grayColor[7],
      paddingRight: '30px',
      '&:hover': {
        backgroundColor: '#0cc7c7',
        color: whiteColor,
        ...primaryBoxShadow,
      },
    },
    selectMenuItemSelected: {
      backgroundColor: `#0cc7c7 !important`,
      color: whiteColor,
    },
  }),
)

interface CustomSelectProps extends SelectProps {
  marginLeft?: number
  label: string
  items: SelectItem<string>[]
}

const CustomSelect = (props: CustomSelectProps) => {
  const {marginLeft, label, items, value, onChange, children, ...selectProps} =
    props

  const classes = useStyles(props)
  const {t} = useTranslation()

  return (
    <FormControl
      // variant='outlined'
      className={`${classes.selectFormControl} ${classes.selectFormControl2}`}
    >
      <Select
        // name='search2-condition'
        // id='search2-select'
        MenuProps={{className: classes.selectMenu}}
        classes={{select: classes.select}}
        value={value}
        onChange={onChange}
        autoWidth
        variant='outlined'
        {...selectProps}
      >
        <MenuItem disabled classes={{root: classes.selectMenuItem}}>
          {t('ISelectRequired')}
        </MenuItem>
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
          >
            {t(item.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomSelect
