import {RootState} from 'store'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  CustomerSearchRequest,
  SearchedCustomerRegistration,
  getBillingSummary,
  getInfoListConsumerApi,
  searchCustomerApi,
  getDetailListOrg,
} from 'api/invoiceApis'
import {fetchCountryList} from 'api/commonApi'
import {
  fromIsoDateOrStringToLocalDate,
  getDefaultPeriodString,
  toParsedDate,
} from 'helpers/dateHelper'
import {zeroPad} from 'helpers/commonHelper'

const formatDate = (date: string) => {
  if (!date) return ''
  const localDate = fromIsoDateOrStringToLocalDate(date)
  const {years, months} = toParsedDate(localDate)
  return `${years}${zeroPad(months)}`
}

export const searchCustomerRegistration = createAsyncThunk(
  'api/eeg/CustomerRegistration/search',
  async (payload: CustomerSearchRequest, {rejectWithValue}) => {
    try {
      const tempPayload = {
        ...payload,
        period: {
          startDate: formatDate(payload.period.startDate),
          endDate: formatDate(payload.period.endDate),
        },
      }

      const responseSearch = await searchCustomerApi(tempPayload)
      let invoices: SearchedCustomerRegistration[] = []
      if (
        responseSearch.data.content &&
        responseSearch.data.content.length > 0
      ) {
        const idList = responseSearch.data.content.map((item) => item.id)
        const responseListInvoice = await getInfoListConsumerApi({
          ids: idList.join(','),
        })
        const listOrgId: (number | undefined)[] =
          responseSearch.data.content.map((invoice) => {
            return responseListInvoice.list.find(
              (info) => info.id === invoice.id,
            )?.orgId
          })
        const dataBilling = await getBillingSummary(idList.join(','))
        const responseCountry = await fetchCountryList()
        const countries = responseCountry.list
        const dataCountry = await getDetailListOrg(
          [...new Set(listOrgId)].join(','),
        )

        invoices = responseSearch.data.content.map((invoice) => {
          const infoCustomer = responseListInvoice.list.find(
            (info) => info.id === invoice.consumerId,
          )
          if (infoCustomer) {
            invoice.orgId = infoCustomer.orgId
            invoice.orgName = infoCustomer.orgName
            invoice.orgUserFirstname = infoCustomer.orgUserFirstname
            invoice.orgUserLastname = infoCustomer.orgUserLastname
            invoice.email = infoCustomer.email

            const infoOrg = dataCountry.list.find(
              (item) => item.orgId === infoCustomer.orgId,
            )
            invoice.countryName =
              countries.find((item) => item.id === infoOrg?.countryId)?.name ||
              ''
            invoice.phoneNumber = infoOrg?.phoneNumber
            invoice.manager = infoOrg?.name
            const findDataBilling = dataBilling?.list.find(
              (item) => item.id === invoice.billingSummaryId,
            )
            invoice.billingPrice = findDataBilling?.billingPrice
            invoice.billingFileId = findDataBilling?.billingFileId
          }
          return invoice
        })
      }
      responseSearch.data.content = invoices
      return responseSearch
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

interface CustomerRegistrationState {
  loading: boolean
  error: any
  items: SearchedCustomerRegistration[]
  period: PeriodString
  search: CustomerSearchKind
  paging: PagingV3
  oid?: string | null
  type: string
  isConfirmed?: boolean | null
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
  normDialogOpen: boolean
  selected: SearchedCustomerRegistration[]
  reSelectYn: YN | undefined
  reAnalysisYn: YN | undefined
  isIcaSelectOpen: boolean
  isIcaConfirmOpen: boolean
  isIcaResultOpen: boolean
  sraYn?: YN | undefined
}

const initialState: CustomerRegistrationState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {orgName: ''},
  paging: {pageNumber: 0, pageSize: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
  type: '',
  isConfirmed: null,
  normDialogOpen: false,
  selected: [],
  reSelectYn: undefined,
  reAnalysisYn: undefined,
  isIcaSelectOpen: false,
  isIcaConfirmOpen: false,
  isIcaResultOpen: false,
  oid: '',
  sraYn: undefined,
}

export const customerSlice = createSlice({
  name: 'customerRegistrationSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchCustomerRegistration.pending, (state, action) => {
      state.loading = true
      state.error = null
      const {period, search, paging} = action.meta.arg
      state.period = period
      state.search = search
      state.paging = paging
    })
    builder.addCase(searchCustomerRegistration.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload.data

      state.items = items
      state.loading = false
      state.error = null
      state.paging.pageNumber = page
      state.paging.pageSize = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchCustomerRegistration.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default customerSlice.reducer

export const selectList = (state: RootState) => state.invoiceList.items
export const selectLoading = (state: RootState) => state.invoiceList.loading
export const selectError = (state: RootState) => state.invoiceList.error
export const selectSearch = (state: RootState) => state.invoiceList.search
export const selectPeriod = (state: RootState) => state.invoiceList.period
export const selectPaging = (state: RootState) => state.invoiceList.paging
export const selectPagingInfo = (state: RootState) =>
  state.invoiceList.pagingInfo
export const selectSelected = (state: RootState) => state.invoiceList.selected
export const selectType = (state: RootState) => state.invoiceList.type
export const selectIsConfirmed = (state: RootState) =>
  state.invoiceList.isConfirmed
export const selectQuery = (state: RootState) => ({
  period: state.invoiceList.period,
  search: state.invoiceList.search,
  paging: state.invoiceList.paging,
  oid: state.invoiceList.oid,
  type: state.invoiceList.type,
  isConfirmed: state.invoiceList.isConfirmed,
})
