import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core/styles'
import SelectBoldCountry from 'components/Dialog/BoldSelect/SelectBoldCountry'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import RegisterStyle from 'pages/BusinessManagement/MemberRegistration/Style'

const useStyles = makeStyles(RegisterStyle)

export default function CountrySelect({countryId}: {countryId: number}) {
  const createMemberInfo = useFormContext()
  const inputClasses = useBoldInputStyle()
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    formState: {errors},
  } = useFormContext()
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <p className={inputClasses.inputLabel}>{t('ICountry')}</p>
        <p className={inputClasses.require}>*</p>
      </div>
      <SelectBoldCountry
        className={classes.selectContainer}
        {...createMemberInfo.register('countryId', {
          required: `${t('IRequired')}`,
        })}
        countryId={countryId}
      />
      {errors.countryId && (
        <span className={inputClasses.errorText}>{t('IRequired')}</span>
      )}
    </div>
  )
}
