import {SearchActivityPagingRequest} from 'api/patientApi'
import {
  searchPatientActivityListAction,
  selectError,
  selectItems,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectQuery,
  selectSearch,
} from 'features/patient/patientActivitySlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function usePatientActivity() {
  const dispatch = useAppDispatch()

  const onSearch = (payload: SearchActivityPagingRequest) =>
    dispatch(searchPatientActivityListAction(payload))

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const activities = useAppSelector(selectItems)
  const search = useAppSelector(selectSearch)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const query = useAppSelector(selectQuery)

  return {
    loading,
    error,
    activities,
    search,
    paging,
    pagingInfo,
    query,
    onSearch,
  }
}
