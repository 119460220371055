import {
  confirm,
  confirmAction,
  OpenDialogPayload,
  reject,
  selectConfirm,
} from 'features/modal/confirmSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useConfirm() {
  const dispatch = useAppDispatch()

  const onOpen = async (payload: OpenDialogPayload) => {
    return dispatch(confirmAction(payload))
  }

  const onConfirm = () => dispatch(confirm())

  const onReject = () => dispatch(reject())

  const {title, message, isOpened} = useAppSelector(selectConfirm)

  return {
    open: isOpened,
    title,
    message,
    onOpen,
    onConfirm,
    onReject,
  }
}
