import {yupResolver} from '@hookform/resolvers/yup'
import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {RegisterCreditCardRequest} from 'api/paymentApi'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import {
  closeRegisteredCardDialog,
  selectRegisteredCardOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useCreditCard from 'features/payment/useCreditCard'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import * as yup from 'yup'
import {TypeOf} from 'yup'

const RootWrap = styled.div`
  background-color: #fff;
`
const TitleText = styled.p`
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`

const ContentWrap = styled.div`
  background-color: #f9f9fb;
  background-color: #fff;
  padding: 30px 40px 0 40px;
  margin: 10px 0;
  border-top: 1px solid #e9ecef;
`

const BottomButtonWrap = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 30px 60px;
  gap: 28px;
`

const CellTitleText = styled.p`
  width: 100px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left;
  color: #555;
  & p {
    margin: 0;
    padding: 0;
  }
`

const InputText = styled.input<{width: number}>`
  width: ${(props) => props.width}px;
  height: 34px;
  padding: 6px 15px;
  border: solid 1px #e9ecef;
  ::placeholder {
    color: #555;
    margin: 5px 46px 0 0;
  }
`

const PaymentEditCell = styled.div`
  display: flex;
  margin: 10px 0;
  height: 40px;
  align-items: center;
`

const ErrorText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11;
  color: #d03c35;
`

const ExpirePeriodWrap = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
  height: 40px;
  align-items: center;
  color: #b7b9bc;
`

const RegisteredCardDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectRegisteredCardOpen)
  const dispatch = useAppDispatch()

  const {onCreate: onRegisterCard} = useCreditCard()

  interface RegisterFormData extends TypeOf<typeof schema> {}
  const schema = yup.object({
    cardNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(15, 'Must be exactly 2 digits')
      .max(16, 'Must be exactly 2 digits')
      .required(t('IRequired')),
    expirePeriod: yup.number().typeError(t('INotNumberFormat')),
    month: yup
      .string()
      .required(t('IRequired'))
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be exactly 2 digits')
      .max(2, 'Must be exactly 2 digits'),
    year: yup
      .string()
      .required(t('IRequired'))
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be exactly 2 digits')
      .max(2, 'Must be exactly 2 digits'),
    cardPassword: yup.number().typeError(t('INotNumberFormat')),
    cardType: yup.string(),
    cvcNumber: yup
      .string()
      .required(t('IRequired'))
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(3, 'Must be exactly 2 digits')
      .max(3, 'Must be exactly 2 digits'),
    businessLicenseNumber: yup.string(),
  })

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<RegisterFormData>({
    // @ts-ignore
    resolver: yupResolver(schema),
  })

  const isNoneExpireDate = false
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const handleClose = () => dispatch(closeRegisteredCardDialog())

  const isValidCardNumber = (cardNumber: string): boolean => {
    if (!cardNumber.length) return false
    const cardNumberStringArray: string[] = Array.from(cardNumber)
    let cardNumberArray: number[] = cardNumberStringArray.map((i) => Number(i))
    const lastNumber = Number(cardNumberArray.pop())
    cardNumberArray.reverse()
    cardNumberArray = cardNumberArray.map((num, idx) =>
      idx % 2 === 0 ? Number(num) * 2 : Number(num),
    )
    cardNumberArray = cardNumberArray.map((num: number) =>
      num > 9 ? num - 9 : num,
    )
    let sum = cardNumberArray.reduce((acc, curr) => acc + curr, 0)
    sum += lastNumber
    const modulo = sum % 10
    return !modulo
  }

  const onSubmit = (data: RegisterFormData) => {
    const requestData: RegisterCreditCardRequest = {
      creditNumber: data.cardNumber ?? '',
      expirationPeriod: `${data.month}${data.year}`,
      cvc: data.cvcNumber ?? '',
      isPrimary: true,
    }
    if (isNoneExpireDate) onFailureModalOpen('동의해주세요')
    if (!isValidCardNumber(data.cardNumber ?? ''))
      onFailureModalOpen('신용카드 번호를 확인해주세요.')

    if (isValidCardNumber(data.cardNumber ?? '')) {
      onRegisterCard(requestData).then((result) => {
        if (!result) {
          handleClose()
          return onSuccessModalOpen(t('IProcessSuccess'))
        }
        return onFailureModalOpen(t('IProcessFail'))
      })
    }
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth='md'
    >
      <Card>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <RootWrap>
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <TitleText>{t('결제 정보 등록하기')}</TitleText>
            </div>
            <ContentWrap>
              <PaymentEditCell>
                <CellTitleText>{t('ICardNumber')} *</CellTitleText>
                <Controller
                  name='cardNumber'
                  control={control}
                  render={({field}) => (
                    <InputText type='text' {...field} width={274} />
                  )}
                />
              </PaymentEditCell>
              <PaymentEditCell>
                <CellTitleText>유효기간 *</CellTitleText>
                <ExpirePeriodWrap>
                  <Controller
                    name='month'
                    control={control}
                    render={({field}) => (
                      <InputText
                        placeholder='MM'
                        type='text'
                        {...field}
                        width={47}
                      />
                    )}
                  />
                  /
                  <Controller
                    name='year'
                    control={control}
                    render={({field}) => (
                      <InputText
                        placeholder='YY'
                        type='text'
                        {...field}
                        width={47}
                      />
                    )}
                  />
                  {t('IGivenExample')}) 01/21
                </ExpirePeriodWrap>
              </PaymentEditCell>
              {errors?.month && <ErrorText>{errors.month.message}</ErrorText>}
              {errors?.month && errors?.year && (
                <ErrorText>{errors.year.message}</ErrorText>
              )}
              <PaymentEditCell>
                <CellTitleText>cvc *</CellTitleText>
                <ExpirePeriodWrap>
                  <Controller
                    name='cvcNumber'
                    control={control}
                    render={({field}) => (
                      <InputText type='text' {...field} width={60} />
                    )}
                  />
                </ExpirePeriodWrap>
              </PaymentEditCell>
              {/* <PaymentEditCell>
                <CellTitleText>카드유형</CellTitleText>
                <input
                  id={PERSON}
                  value={PERSON}
                  name={PERSON}
                  type='radio'
                  checked={cardType === PERSON}
                  onChange={(e) => {
                    setCardType(e.target.value)
                  }}
                />
                개인
                <input
                  id={LEGAL}
                  value={LEGAL}
                  name={LEGAL}
                  type='radio'
                  checked={cardType === LEGAL}
                  onChange={(e) => {
                    setCardType(e.target.value)
                  }}
                />
                법인
              </PaymentEditCell>
              {cardType === LEGAL && (
                <PaymentEditCell>
                  <CellTitleText>사업자등록번호</CellTitleText>
                  <Controller
                    name='changedCount'
                    control={control}
                    render={({field}) => (
                      <InputText
                        placeholder='숫자입력'
                        type='text'
                        {...field}
                        width={274}
                      />
                    )}
                  />
                </PaymentEditCell>
              )}

              {cardType === PERSON && (
                <PaymentEditCell>
                  <CellTitleText>주민등록번호</CellTitleText>
                  <Controller
                    name='changedCount'
                    control={control}
                    render={({field}) => (
                      <InputText
                        placeholder='주민등록번호 앞 6자리'
                        type='text'
                        {...field}
                        width={274}
                      />
                    )}
                  />
                </PaymentEditCell>
              )}

              {errors.changedCount && (
                <ErrorText>{errors.changedCount.message}</ErrorText>
              )} */}
              {/* <div style={{display: 'flex', width: 505, gap: 10}}>
                <input
                  id='noneExpire'
                  type='checkbox'
                  onClick={() => {
                    setIsNoneExpireDate(!isNoneExpireDate)
                  }}
                />
                <p>
                  사용자 동의 하에, 본인이 등록한 카드를 통해 금융 기관에
                  사용금액을 청구하도록 할 것을 승인합니다.
                </p>
              </div> */}
            </ContentWrap>

            <br />
            <BottomButtonWrap>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleClose}
                style={{
                  width: 222,
                  height: 55,
                  backgroundColor: '#e9ecef',
                  color: '#4b4b4b',
                }}
              >
                {t('ICancel')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                style={{width: 222, height: 55}}
                type='submit'
              >
                {t('IOk')}
              </Button>
            </BottomButtonWrap>
          </RootWrap>
        </form>
      </Card>
    </Dialog>
  )
}

export default RegisteredCardDialog
