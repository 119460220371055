import {AnalysisResultIndicator} from 'api/analysisApi'
import ReportHeader from 'components/Report/ReportHeader'
import {CodeType} from 'constants/AnalysisConstant'
import {ThreeDPopupContext} from 'context/ThreeDPopupContext'
import {getPublicFile} from 'helpers/envHelper'
import React, {Fragment, useContext} from 'react'

const getBannerImage = getPublicFile('images/eeg_report')('3D_view_banner.jpeg')

interface ThreeDBannerProps {
  indicator: AnalysisResultIndicator
  requestId: number
  codeType: CodeType
}

export default function ThreeDBanner({
  indicator,
  codeType,
  requestId,
}: ThreeDBannerProps) {
  const {handleOpen} = useContext(ThreeDPopupContext)

  const handleClick = () => {
    handleOpen({id: requestId, type: codeType})
  }

  return (
    <Fragment>
      <div>
        <ReportHeader
          index={indicator.codeSort}
          title={indicator.codeName}
          description={indicator.titleDescription}
        />
        <div
          role='button'
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          <img
            src={getBannerImage}
            alt='open to 3d report'
            style={{width: '100%', height: 'auto', cursor: 'pointer'}}
          />
        </div>
      </div>
    </Fragment>
  )
}
