import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {deleteOrgUserApi} from 'api/userApi'
import Card from 'components/atoms/Card'
import useAuth from 'features/auth/useAuth'
import {
  closeUserDeletePasswordConfirm,
  selectUserDeletePasswordConfirmOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {useAppDispatch, useAppSelector} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // @ts-ignore
      zIndex: '1350 !important',
      Width: 600,
    },
    contentContainer: {
      Width: 600,
      justifyContent: 'center',
      background: '#eef2f6',
    },
    confirmContainer: {
      background: '#eef2f6',
      display: 'flex',
      alignItems: 'center',
      margin: '40px 20px',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'column',
      flex: 1,
      width: '300px',
      '& h5': {
        fontWeight: 'bolder',
      },
      '& h6': {
        fontSize: '1.0rem',
        fontWeight: 'normal',
        textAlign: 'left',
        margin: '5px 0',
        flexWrap: 'wrap',
        flex: 1,
        color: '#717376',
      },
      '& TextField': {
        flex: 1,
      },
    },
    alertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
      background: '#f4f4f4',
      gap: 10,
      '& button': {
        flex: 1,
        fontSize: 12,
      },
    },
    padding: {
      padding: theme.spacing(1),
    },
    okButtonColor: {
      color: 'white',
    },
    longTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      //   flex: '1 1 0',
      justifyContent: 'space-between',
      color: 'primary',
      background: 'white',
      borderBottom: '1px solid #e9ecef',
      '& .Title': {
        marginLeft: 19,
      },
    },
    hidden: {
      visibility: 'hidden',
    },
    iconTitleWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 15,
    },
    iconColor: {
      color: '#636364',
      fontSize: 20,
      marginRight: 8,
    },
    scroll: {
      flexDirection: 'column',
      flexGrow: 1,
      flexWrap: 'wrap',
      overflow: 'auto',
      backgroundColor: '#fff',
      padding: '25px 20px ',
    },
    subTitle: {
      fontSize: 13,
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '10px 0',

      minWidth: 50,
      flexWrap: 'wrap',
      marginTop: 5,
      color: '#757575',
    },
    inputContainer: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    input: {
      border: '1px solid #DCDCDC',
      height: 35,
      padding: 10,
    },
    closeIcon: {
      width: 16,
      height: 16,
      color: '#c5c5c5',
    },
    closeButton: {
      marginLeft: 'auto',
      padding: 6,
      alignSelf: 'flex-start',
      marginTop: 2,
      marginRight: 2,
    },
  }),
)

interface UserDelete {
  selected: string[]
}

/**
 * TODO: 삭제 버튼 클릭시 비밀번호 확인 다이얼로그를 띄우는데, 해당 다이얼로그에 사용자 삭제 로직이 들어있다.
 *       만약, 비밀번호 확인 다이얼로그가 여러 페이지에서 씌인다면 문제가 발생할 수 있으므로,
 *       비밀번호 확인 다이얼로그는 비밀번호 확인 결과만 리턴하도록 변경되어야 한다.
 */
const UserDeletePasswordConfirm = ({selected}: UserDelete) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectUserDeletePasswordConfirmOpen)
  const {user: currentUser} = useAuth()
  const dispatch = useAppDispatch()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const handleClose = () => {
    dispatch(closeUserDeletePasswordConfirm())
  }

  const [password, setPassword] = React.useState('')
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (deleteArray: string[]) =>
      deleteOrgUserApi({
        uid: currentUser?.uid ?? '',
        uidList: deleteArray,
        password,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchUsersList')
        handleClose()
        onSuccessModalOpen(t('IProcessSuccess'))
      },
      onError: (error: any) => {
        onFailureModalOpen(error.message)
      },
    },
  )

  const handleOk = () => {
    if (selected.length > 0) {
      mutation.mutate(selected)
    } else {
      onFailureModalOpen(t('IPatientSelect'))
    }
  }

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      className={classes.root}
      onClose={handleClose}
    >
      <Card className={classes.contentContainer}>
        <div className={classes.longTitleContainer}>
          <p className='Title'>{t('IPasswordConfirm')}</p>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div>
          <div className={classes.scroll}>
            <div className={classes.inputContainer}>
              <Typography className={classes.subTitle}>
                {t('IPassword')}
              </Typography>
              <input
                className={classes.input}
                placeholder={t('IPasswordConfirm')}
                type='password'
                onChange={(e) => {
                  setPassword(e.currentTarget.value)
                }}
              />
            </div>
          </div>

          <div className={classes.alertButtonContainer}>
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
            >
              {t('ICancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleOk}
              className={classes.okButtonColor}
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default UserDeletePasswordConfirm
