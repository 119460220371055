import {HRVSummaryResponse} from 'api/analysisApi'
import HRVSummaryAutoNervousSystem from 'components/molcules/N1SummaryReport/HRV/HRVSummaryAutoNervousSystem'
import HRVSummaryStress from 'components/molcules/N1SummaryReport/HRV/HRVSummaryStress'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React, {ReactNode} from 'react'
import HRVSummarySentimental from './HRVSummarySentimental'

interface HRVSummaryProps extends HRVSummaryResponse {
  CustomerCard?: ReactNode
  customerFirstName: string
  customerLastName: string
}

export default function HRVSummary(props: HRVSummaryProps) {
  const {
    circularImage,
    balanceOpinion,
    depression,
    phase,
    anxiety,
    CustomerCard,
    customerFirstName,
    customerLastName,
    reportType,
  } = props

  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      {/* Basic analysis */}
      <HRVSummaryAutoNervousSystem
        customerFirstName={customerFirstName}
        customerLastName={customerLastName}
        balanceCircularImagePath={circularImage}
        balanceOpinion={balanceOpinion}
      />
      {/* Premium analysis */}
      {reportType === 'P2' && <HRVSummaryStress phase={phase} />}
      {reportType === 'P2' && (
        <HRVSummarySentimental
          depression={depression}
          anxiety={anxiety}
          customerFirstName={customerFirstName ?? ''}
          customerLastName={customerLastName ?? ''}
        />
      )}

      <p className='Warning'>{t('IHRVSummaryReportWarning')}</p>
    </div>
  )
}
