import {SearchedIndividualEEG} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import NormRequestDialog from 'pages/Analysis/EEGIndividual/NormRequestDialog'
import PageTable from 'pages/Analysis/EEGIndividual/PageTable'
import IcaConfirmDialog from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaConfirmDialog'
import IcaResultDialog from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaResultDialog'
import IcaSelectDialog from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaSelectDialog'
import React, {useState} from 'react'

/**
 * TODO: 이렇게 문서화 하지말고, query parameter에 관련된 내용을 타이핑하기
 *
 * @param query search name | index | file_name | measure_date | eceo
 * @param query keyword string
 */
function EEGIndividual() {
  const [selectedOld, setSelectedOld] = useState<SearchedIndividualEEG[]>([])

  const updateSelectedOld = (
    selected: SearchedIndividualEEG[],
    listAll: SearchedIndividualEEG[],
  ) => {
    const removeItems = listAll.filter(
      (itemA) => !selected.some((itemB) => itemA.id === itemB.id),
    )

    let selectedOldNew = selectedOld
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selected.length; i++) {
      if (!selectedOld?.find((item) => item.id === selected[i].id)) {
        selectedOldNew.push(selected[i])
      }
    }

    selectedOldNew = selectedOldNew.filter(
      (itemA) => !removeItems.some((itemB) => itemA.id === itemB.id),
    )

    setSelectedOld(selectedOldNew)
  }

  return (
    <>
      <CardWithTitle>
        <PageTable
          selectedOld={JSON.parse(JSON.stringify(selectedOld))}
          setSelectedOld={updateSelectedOld}
        />
      </CardWithTitle>
      <NormRequestDialog />
      <IcaSelectDialog />
      <IcaConfirmDialog />
      <IcaResultDialog />
    </>
  )
}

export default EEGIndividual
