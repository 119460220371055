import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface CancelButtonProps extends ConfirmationButtonProps {}

export const RootClass = 'CancelButton'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #e9ecef',
    backgroundColor: 'white',
  },
}))

const CancelButton = ({className, ...props}: CancelButtonProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Button
      variant='contained'
      className={`${classes.root} ${RootClass} ${className}`}
      disableElevation
      {...props}
    >
      {t('ICancel')}
    </Button>
  )
}

export default CancelButton
