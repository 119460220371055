import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'
import useStyles from 'components/molcules/Report/PowerSpectrum.style'
import ReportHeader from 'components/Report/ReportHeader'
import SelectEmpty from 'components/Select/SelectEmpty'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import {Channel, PowerTypes} from 'constants/AnalysisConstant'
import {getPowerSpectrumChartUrl} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import {sleep} from 'helpers/commonHelper'
import React, {useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import NewWindow, {NewWindowRef, WindowFeatures} from 'components/NewWindow'
import {useResizeDetector} from 'react-resize-detector'

const POWER_BAND_ABS = 1
const POWER_BAND_ABS_BIN = 2
const POWER_BAND_REL = 3

type PowerSpectrumTabProps = {
  index: number
  currentIndex: number
  subType: number
  classes: ReturnType<typeof useStyles>
  items: AnalysisResultItem[]
}

/** 2개의 좌표가 함께 있음. 박스에 사용됨 [x1, y1, x2, y2] */
type Coords = [number, number, number, number]

type PSMapCoords = Record<Channel, Coords>

const PSD_MAP: PSMapCoords = {
  Fp1: [0.2992, 0.1912, 0.4064, 0.296],
  Fp2: [0.556, 0.1912, 0.6616, 0.2952],
  F3: [0.2984, 0.3176, 0.4056, 0.4208],
  F4: [0.5544, 0.3176, 0.6624, 0.4208],
  F7: [0.1704, 0.3176, 0.2776, 0.4208],
  F8: [0.684, 0.3176, 0.7904, 0.4208],
  Fz: [0.4264, 0.3176, 0.5344, 0.4208],
  T3: [0.1696, 0.4432, 0.2768, 0.548],
  T4: [0.684, 0.4432, 0.7896, 0.548],
  T5: [0.1704, 0.5696, 0.2776, 0.6744],
  T6: [0.684, 0.5696, 0.7896, 0.6744],
  C3: [0.2992, 0.4432, 0.4056, 0.548],
  C4: [0.5552, 0.4432, 0.6624, 0.548],
  Cz: [0.4424, 0.4432, 0.5352, 0.548],
  P3: [0.2992, 0.5696, 0.4056, 0.6744],
  P4: [0.5552, 0.5696, 0.6624, 0.6744],
  Pz: [0.4424, 0.5696, 0.5352, 0.6744],
  O1: [0.2776, 0.6952, 0.4056, 0.8008],
  O2: [0.5552, 0.6952, 0.6624, 0.8008],
}

function PowerSpectrumTab({
  items = [],
  classes,
  index,
  currentIndex,
  subType,
}: PowerSpectrumTabProps) {
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  // 해당 탭에서 렌더링 할 image 목록을 subType으로 거른다.
  const filteredItems = useMemo(
    () => items.filter((item) => item.subType === subType),
    [items],
  )

  // select에서 사용될 psdScale 배열을 추출.
  // abs_bin의 경우 추출되는 아이템이 없다.
  const selectBandItems = useMemo(
    () =>
      filteredItems
        .sort((item) => item.psdScaleOrder)
        .map((item) => item.psdScale)
        .filter((item) => item !== null),
    [filteredItems],
  )

  const [selectedBand, setSelectedBand] = useState(selectBandItems[0])
  const handleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const {value} = event.target
    setSelectedBand(value as string)
  }

  // select에서 선택된 item으로 그릴 image를 찾음.
  // 선택된 item이 없을 경우 items의 첫 항목을 지정.
  const selectedItem =
    selectBandItems.length === 0
      ? filteredItems[0]
      : filteredItems.find((item) => item.psdScale === selectedBand)

  // 이미지의 width, height를 얻어내어 area 좌표를 설정
  const ref = useRef(null)
  const {width, height} = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 300,
    targetRef: ref,
  })

  const coords = useMemo<PSMapCoords>(() => {
    const coord = Object.entries(PSD_MAP).reduce<PSMapCoords>(
      (accr, curr) => {
        const [key, coords] = curr
        if (width === undefined || height === undefined) {
          accr[key as Channel] = coords as Coords
        } else {
          const [x1, y1, x2, y2] = coords as Coords
          accr[key as Channel] = [
            Math.round(width * x1),
            Math.round(height * y1),
            Math.round(width * x2),
            Math.round(height * y2),
          ] as Coords
        }
        return accr
      },
      // @ts-ignore
      {},
    )
    return coord as unknown as PSMapCoords
  }, [width, height])

  const powerSpectrumRef = useRef<NewWindowRef>()
  const [isPowerSpectrumOpen, setIsPowerSpectrumOpen] = useState(false)
  const [powerSpectrumUrl, setPowerSpectrumUrl] = useState('')
  const onPowerSpectrumUnload = () => setIsPowerSpectrumOpen(false)
  const onPopupBlocked = () => onFailureModalOpen(t('IAllowPopupPlease'))
  const Features: WindowFeatures = {
    width: 1500,
    height: 900,
  }

  return (
    <TabPanel value={currentIndex} index={index}>
      {selectBandItems.length > 0 && (
        <div>
          <Select
            value={selectedBand}
            onChange={handleChange}
            variant='outlined'
          >
            {selectBandItems.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {selectBandItems.length === 0 && (
        <div>
          <SelectEmpty />
        </div>
      )}
      {selectedItem && (
        <div className={classes.tabContainer}>
          <img
            id='img_psd_spectrum'
            useMap={`#psd2dMap${index}`}
            src={selectedItem.imgPath}
            alt='power spectrum'
            ref={ref}
            width='1250'
            height='1250'
          />
          <map name={`psd2dMap${index}`}>
            {Object.entries(coords).map(([key, value]) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <area
                className={classes.areaCursor}
                key={key}
                shape='rect'
                coords={value.join(',')}
                alt='psd map'
                onClick={async () => {
                  setIsPowerSpectrumOpen(false)
                  await sleep(100)

                  const eegType = selectedItem.requestId ? 'I' : 'N'
                  const eegId = selectedItem.requestId
                    ? selectedItem.requestId
                    : selectedItem.normativeId

                  setPowerSpectrumUrl(
                    getPowerSpectrumChartUrl(
                      eegId ?? -1,
                      eegType,
                      PowerTypes[index],
                      key as Channel,
                    ),
                  )
                  setIsPowerSpectrumOpen(true)
                  // @ts-ignore
                  powerSpectrumRef.current?.window?.focus()
                }}
              />
            ))}
          </map>
        </div>
      )}
      {isPowerSpectrumOpen && (
        <NewWindow
          title='PowerSpectrum'
          name='PowerSpectrum'
          url={powerSpectrumUrl}
          onUnload={onPowerSpectrumUnload}
          onBlock={onPopupBlocked}
          features={Features}
          // @ts-ignore
          ref={powerSpectrumRef}
        />
      )}
    </TabPanel>
  )
}

interface PowerSpectrumProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
}

function PowerSpectrum({indicator, items}: PowerSpectrumProps) {
  const classes = useStyles()

  const [tabIndex, setTabIndex] = React.useState(0)
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        indicatorColor='secondary'
        textColor='secondary'
      >
        <Tab label='Absolute (㎶²/㎐)' />
        <Tab label='Absolute (㏈/㎐)' />
        <Tab label='Relative' />
      </Tabs>
      <PowerSpectrumTab
        items={items}
        currentIndex={tabIndex}
        index={0}
        subType={POWER_BAND_ABS}
        classes={classes}
      />
      <PowerSpectrumTab
        items={items}
        currentIndex={tabIndex}
        index={1}
        subType={POWER_BAND_ABS_BIN}
        classes={classes}
      />
      <PowerSpectrumTab
        items={items}
        currentIndex={tabIndex}
        index={2}
        subType={POWER_BAND_REL}
        classes={classes}
      />
    </div>
  )
}

export default PowerSpectrum
