import HistoryDialog from 'components/Dialog/HistoryDialog'
import SubTitle from 'components/Text/SubTitle'
import {openHistoryDialog} from 'features/modal/modalSlice'
import {useAppDispatch} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import HistoryIcon from '../../../assets/img/ico-history.png'
import InstituteSearchBar from './InstituteSearchBar'
import SearchInstituteTable from './Tables/SearchInstituteTable'

const HistoryButton = styled.button`
  border-collapse: collapse;
  border-radius: 5px;
  background-color: #5a6268;
  color: #fff;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 15px 0;

  &:hover,
  &:active {
    background-color: #464f56;
  }
`

const SearchInstitute = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const historyOpen = () => dispatch(openHistoryDialog())

  return (
    <div style={{width: '100%'}}>
      <HistoryDialog />
      <SubTitle>{t('IInstitutionalSearch')}</SubTitle>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'space-between',
        }}
      >
        <InstituteSearchBar />
        <HistoryButton type='button' onClick={historyOpen}>
          <img src={HistoryIcon} alt='historyImage' />
          {t('IHistoryTitle')}
        </HistoryButton>
      </div>
      <SearchInstituteTable />
    </div>
  )
}
export default SearchInstitute
