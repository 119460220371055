import type {ButtonProps} from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import styles from 'assets/tss/material-dashboard-pro-react/components/buttonStyle'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles(styles)
export interface CustomButtonProps extends Omit<ButtonProps, 'color' | 'size'> {
  color?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'rose'
    | 'white'
    | 'twitter'
    | 'facebook'
    | 'google'
    | 'linkedin'
    | 'pinterest'
    | 'youtube'
    | 'tumblr'
    | 'github'
    | 'behance'
    | 'dribbble'
    | 'reddit'
    | 'transparent'
  size?: 'sm' | 'lg'
  simple?: boolean
  round?: boolean
  fullWidth?: boolean
  disabled?: boolean
  block?: boolean
  link?: boolean
  justIcon?: boolean
  className?: string
  muiClasses?: Record<string, any>
}

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  (props: CustomButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles()
    const {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className = 'default-custom-button',
      muiClasses,
      ...rest
    } = props
    const btnClasses = classNames({
      [classes.button]: true,
      // @ts-ignore
      [classes[size]]: size,
      // @ts-ignore
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    })
    return (
      <Button {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
        {children}
      </Button>
    )
  },
)
export default CustomButton
