import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchReferenceApi} from 'api/analysisApi'
import {useAppSelector} from 'hooks'
import {RootState, useAppDispatch} from 'store'
import {openFailureModal} from 'features/modal/alertSlice'

export const open = createAction<string>('reference_dialog/open')
export const close = createAction('reference_dialog/close')

export const openReferenceAction = createAsyncThunk(
  'fetchReference',
  async (payload: string, {dispatch}) => {
    try {
      const result = await fetchReferenceApi(payload)
      const [reference] = result.list
      dispatch(open(reference.citation))
      return reference
    } catch (err) {
      dispatch(openFailureModal(err.message))
      return err
    }
  },
)

interface State {
  open: boolean
  body: string | null
}

const initialState: State = {
  open: false,
  body: null,
}

const slice = createSlice({
  name: 'refDialogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(open, (state, action) => {
      state.open = true
      state.body = action.payload
    })
    builder.addCase(close, (state) => {
      state.open = false
      state.body = null
    })
  },
})

export const selectOpen = (state: RootState) => state.refDialog.open
export const selectBody = (state: RootState) => state.refDialog.body

export default slice.reducer

export const useRefDialog = () => {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(selectOpen)
  const body = useAppSelector(selectBody)

  const onOpen = (referenceId: string) =>
    dispatch(openReferenceAction(referenceId))

  const onClose = () => dispatch(close())

  return {
    open: isOpen,
    body,
    onOpen,
    onClose,
  }
}
