import CardWithTitle from 'components/atoms/CardWithTitle'
import PageTable from 'pages/Analysis/EDFDownload/PageTable'
import React from 'react'

const EDFDownload = () => {
  return (
    <CardWithTitle>
      <PageTable />
    </CardWithTitle>
  )
}

export default EDFDownload
