import * as storage from 'api/storageApi'
import {navigate, navigator} from 'AppHistory'
import axios, {AxiosInstance, Method} from 'axios'
import RouteConstant from 'constants/RouteConstant'
import {RouteErrorConstant} from 'error/AppError'
import {
  ApiError,
  AuthError,
  AuthRefreshError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  NotTokenError,
} from 'exceptions'
import {getApiHost, isProd} from 'helpers/envHelper'
import qs from 'qs'

const isPatchMethod = (method: Method | undefined) =>
  method === 'patch' || method === 'PATCH'

const isPutMethod = (method: Method | undefined) =>
  method === 'put' || method === 'PUT'

const isPostMethod = (method: Method | undefined) =>
  method === 'post' || method === 'POST'

const isUpdateMethod = (method: Method | undefined) =>
  isPostMethod(method) || isPutMethod(method) || isPatchMethod(method)

// const isGetMethod = (method: Method | undefined) =>
//   method === 'get' || method === 'GET'

type Header = Record<string, string> | null

const getApiHeader = (header: Header = {}) => {
  const newHeader = {...header}
  const accessToken = storage.getAccessToken()
  if (accessToken !== null) {
    newHeader.Authorization = `Bearer ${accessToken}`
  }
  return newHeader
}

const getBaseData = (data: Record<string, any>) => {
  const uid = storage.getUserUid()
  const lang = storage.getLanguage()
  const adminUid = storage.getAdminUid()

  return {...data, uid, adminUid, lang}
}

export type AuthorizedRequest = ApiRequest & {ignoreData?: boolean}

export type FileDownloadRequest = ApiRequest & {fileName?: string}

const isCommonResponse = (data: Blob): boolean => {
  if (data.type === 'application/json') {
    return true
  }
  return false
}

class AxiosWrapper {
  axios: AxiosInstance

  constructor() {
    this.axios = axios.create({
      baseURL: getApiHost(),
      timeout: isProd() ? 10000 : 0,
      maxRedirects: 0,
    })
  }

  async request<T extends CommonResponse>({
    method = 'get',
    path,
    data,
    header = null,
    params,
    responseType = 'json',
  }: ApiRequest): Promise<T> {
    try {
      const requestHeader = await getApiHeader(header)
      const response = await this.axios.request<T>({
        method,
        url: path,
        headers: requestHeader,
        data,
        params,
        paramsSerializer: (params) => qs.stringify(params),
        responseType,
      })
      const {data: result} = response
      if (Number(result.code) === 1001) {
        new NotTokenError(`${RouteConstant.LOGIN.path}`).moveToLogin()
        return result
      }

      if (!result.success) {
        throw new ApiError(result)
      }
      return result
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          // force refresh to login page when token expired
          storage.removeAccessToken()
          storage.removeRefreshToken()
          storage.removeAdminUid()
          storage.removeUserUid()
          storage.removeOrganizationType()
          throw new AuthError(RouteErrorConstant.CREDENTIAL_EXPIRED)
        }
        if (e.response.status === 404) {
          throw new NotFoundError(RouteErrorConstant.NOT_FOUND_CONTENT)
          new NotTokenError(`${RouteConstant.LOGIN.path}`).moveToLogin()
        }

        const result = e.response.data as CommonResponse
        throw new ApiError(result)
      } else if (e.request) {
        throw new NetworkError(RouteErrorConstant.NETWORK_ERROR_MESSAGE)
      } else {
        throw e
      }
    }
  }

  async authedRequest<T extends CommonResponse>({
    params: requestParams,
    data: requestData,
    method,
    ignoreData,
    ...rest
  }: AuthorizedRequest): Promise<T> {
    const params = {
      ...getBaseData(requestParams),
      ...requestParams,
    }

    // eslint-disable-next-line no-nested-ternary
    const data = ignoreData
      ? requestData
      : isUpdateMethod(method)
      ? {
          ...getBaseData(requestData),
          ...requestData,
        }
      : requestData

    try {
      return await this.request<T>({
        method,
        params,
        data,
        ...rest,
      })
    } catch (err) {
      if (err instanceof AuthError) {
        try {
          const refreshResponse = await this.refreshApi()

          storage.setAccessToken(refreshResponse.data.accessToken)
          storage.setRefreshToken(refreshResponse.data.refreshToken)

          return await this.request<T>({
            method,
            params,
            data,
            ...rest,
          })
        } catch (err) {
          navigate(RouteConstant.LOGIN.path)
        }
      }
      if (err instanceof ForbiddenError) {
        // 403 에러일때 사용
        navigator()
      }
      if (err instanceof NotFoundError) {
        navigate(RouteConstant.ERROR_NOT_FOUND.path)
      }
      throw err
    }
  }

  async fileRequest<T extends CommonResponse>({
    data = {},
    header = {},
    params,
    ...rest
  }: ApiRequest): Promise<T> {
    const newData = new FormData()
    Object.keys(data).forEach((key) => {
      newData.append(key, data[key])
    })

    return this.authedRequest<T>({
      data: newData,
      header: {
        ...header,
        'Content-Type': 'multipart/form-data',
      },
      params,
      ...rest,
      ignoreData: true,
    })
  }

  async fileDownload({
    method = 'get',
    path,
    data,
    header = null,
    params: requestParams,
    responseType = 'blob',
    fileName,
  }: FileDownloadRequest) {
    const requestHeader = getApiHeader(header)
    const params = {
      ...getBaseData(requestParams),
      ...requestParams,
    }

    try {
      const response = await this.axios.request<Blob>({
        method,
        url: path,
        headers: requestHeader,
        data,
        params,
        paramsSerializer: (params) => qs.stringify(params),
        responseType,
      })

      if (isCommonResponse(response.data)) {
        return
      }

      if (!fileName) throw new Error(RouteErrorConstant.INVALID_FILE_NAME)
      const [fileNameOnly, extension] = fileName.split('.')
      if (!fileNameOnly || !extension)
        throw new Error(RouteErrorConstant.INVALID_FILE_NAME)

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        }),
      )

      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          throw new AuthError(e.response.message)
        }
        if (e.response.status === 404) {
          throw new NotFoundError(e.response.message)
        }

        throw new ApiError(e.response)
      } else if (e.request) {
        throw new NetworkError(e.request)
      } else {
        throw e
      }
    }
  }

  async hasResponseFileDownload({
    method = 'get',
    path,
    data,
    header = null,
    params: requestParams,
    responseType = 'blob',
    fileName,
  }: FileDownloadRequest): Promise<boolean> {
    const requestHeader = getApiHeader(header)
    const params = {
      ...getBaseData(requestParams),
      ...requestParams,
    }

    try {
      const response = await this.axios.request<Blob>({
        method,
        url: path,
        headers: requestHeader,
        data,
        params,
        paramsSerializer: (params) => qs.stringify(params),
        responseType,
      })

      if (isCommonResponse(response.data) || response.data === undefined) {
        return false
      }
      if (!fileName) throw new Error(RouteErrorConstant.INVALID_FILE_NAME)
      const [fileNameOnly, extension] = fileName.split('.')
      if (!fileNameOnly || !extension)
        throw new Error(RouteErrorConstant.INVALID_FILE_NAME)

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      return true
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          throw new AuthError(RouteErrorConstant.CREDENTIAL_EXPIRED)
        }
        if (e.response.status === 404) {
          throw new NotFoundError(RouteErrorConstant.NOT_FOUND_CONTENT)
        }

        const result = e.response.data as CommonResponse
        throw new ApiError(result.message)
      } else if (e.request) {
        throw new NetworkError(RouteErrorConstant.NETWORK_ERROR_MESSAGE)
      } else {
        throw e
      }
    }
  }

  async fileDownloadDataResponse({
    method = 'get',
    path,
    data,
    header = null,
    params: requestParams,
    responseType = 'json',
    fileName,
  }: FileDownloadRequest) {
    const requestHeader = getApiHeader(header)
    const params = {
      ...getBaseData(requestParams),
      ...requestParams,
    }

    try {
      const response = await this.axios.request<{data: string}>({
        method,
        url: path,
        headers: requestHeader,
        data,
        params,
        paramsSerializer: (params) => qs.stringify(params),
        responseType,
      })

      if (!response.data) {
        return
      }

      if (!fileName) throw new Error(RouteErrorConstant.INVALID_FILE_NAME)
      const [fileNameOnly, extension] = fileName.split('.')
      if (!fileNameOnly || !extension)
        throw new Error(RouteErrorConstant.INVALID_FILE_NAME)

      const link = document.createElement('a')
      link.href = response.data.data
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          throw new AuthError(e.response.message)
        }
        if (e.response.status === 404) {
          throw new NotFoundError(e.response.message)
        }

        throw new ApiError(e.response)
      } else if (e.request) {
        throw new NetworkError(e.request)
      } else {
        throw e
      }
    }
  }

  public async refreshApi(): Promise<ModelResponse<AuthResponse>> {
    try {
      const refreshToken = storage.getRefreshToken()
      if (refreshToken) {
        throw new AuthRefreshError(RouteErrorConstant.CREDENTIAL_EXPIRED)
      }

      return await this.request<ModelResponse<AuthResponse>>({
        method: 'post',
        path: '/api/org/v1/auth/refresh',
        header: {
          authorization: `Bearer ${refreshToken}`,
        },
      })
    } catch (e) {
      if (e.request) {
        throw new NetworkError(e.request)
      }
      if (e instanceof AuthError) {
        throw new AuthRefreshError(e.message)
      }
      throw e
    }
  }
}

const fetcher = new AxiosWrapper()
export const refreshMethod = AxiosWrapper.prototype.refreshApi

export default fetcher
