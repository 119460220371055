import React, {useEffect, useMemo, useRef, useState} from 'react'
import axios from 'axios'
import Colors from 'theme/Colors'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import {TypeScreenGraph, TypeGraph} from 'api/analysisApi'
import {getEdfByPath, setEdfByPath} from 'helpers/DBConfig'
import {MontageFilterType} from 'lib/EdfGraph/MontageFilter'
import {WaveGraphPropertySourceImpl} from 'lib/GraphSource'
import GraphViewModel from 'lib/EdfGraph/GraphViewModelV1'
import {Header} from 'lib/EdfParser/Edf'
import BioGraphCanvas from 'components/molcules/Report/RawData/Canvas/BioGraphCanvas'
import {heightOptions} from 'components/molcules/Report/RawData/RawDataStyles'
import {HorizontalImageGraphV1} from 'components/molcules/Report/RawData/HorizontalImageGraphV1'
import styled from 'styled-components'

export const IOSSwitch2 = styled(Switch).attrs(() => ({
  classes: {
    root: 'root',
    switchBase: 'switchBase',
    thumb: 'thumb',
    track: 'track',
    checked: 'checked',
    focusVisible: 'focusVisible',
  },
  disableRipple: true,
  focusVisibleClassName: 'focusVisible',
}))`
  &.root {
    width: 42px;
    height: 26px;
    padding: 0;
    margin: 8px;
  }

  .switchBase {
    padding: 1px;

    &.checked {
      transform: translateX(16px);
      color: white;
      & + .track {
        background-color: ${Colors.common.primary};
        opacity: 1;
        border: none;
      }
    }

    &.focusVisible &.thumb {
      color: ${Colors.common.primary};
      border: 6px solid #ffffff;
    }
  }

  .thumb {
    width: 24px;
    height: 24px;
  }

  & .track {
    border-radius: 13px;
    border: 1px solid #bdbdbd;
    background-color: #fafafa;
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
      border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .checked {
  }
  .focusVisible {
  }
`

interface StyledCanvasProps {
  visible: boolean
}

const StyledGraphWrap = styled.div<StyledCanvasProps>`
  overflow-x: hidden;
  height: ${(props) => {
    return props.visible ? '100%' : 0
  }};
  width: 100%;
  border-radius: 13px 13px 0px 0px;
  margin-top: 15px;
  overflow-y: hidden;
`

const StyledToolbarWrap = styled.div`
  background-color: #f2f2f2;
  border-radius: 0px 0px 13px 13px;
  width: 100%;
  padding-right: 32px;
`

const StyledControlsWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;

  & input[type='range'] {
    -webkit-appearance: none;
    height: 7px;
    background: grey;
    border-radius: 5px;
    background-image: linear-gradient(#d9dce0, #d9dce0);
    background-repeat: no-repeat;
  }

  & input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: ${Colors.common.primary};
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
  }
`

interface StyledScaleWrapProps {
  $display?: boolean
}

const StyledScaleWrap = styled.div<StyledScaleWrapProps>`
  display: ${(props) => (props.$display === false ? 'none' : 'flex')};
  align-items: center;
  gap: 7px;
`
const ControlsContainer = styled.div`
  display: flex;
  flex-direction: horizontal;
  gap: 7px;
  margin-left: auto;
`

const StyledDiv = styled.div<StyledCanvasProps>`
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 13px;
  color: #333333;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const StyledLoadingDiv = styled.div`
  display: flex;
  width: 100%;
  min-width: 300px;
  min-height: 608px; // ch h 32 * 19
  justify-content: center;
  align-items: center;
`

const StyledContentTitle = styled.p`
  font-size: 13px;
  color: #babfc5;
  font-weight: 700;
`

const StyledContentBody = styled.p`
  font-size: 16px;
  color: #9a9fa5;
  font-weight: 900;
`

interface RawDataEdfProps {
  readonly edfUrl?: string
  readonly width?: number
  readonly xAxisImg: string
  readonly usingCache?: boolean
  readonly propertyGuide: WaveGraphPropertySourceImpl
  readonly screen: TypeScreenGraph
  readonly typeGraph: TypeGraph
}

interface DropDownStructure {
  optionTitle: string
  optionValue: number
}

interface EDFResultData {
  header: Header | null
  physicalData: any[]
}

export default function RawDataEdfGraphV1({
  edfUrl,
  width,
  xAxisImg,
  usingCache,
  propertyGuide,
  screen,
  typeGraph,
}: RawDataEdfProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  const [edfData, setEdfData] = useState<EDFResultData>()

  const [arrOfVertices, setArrOfVertices] = useState<any[]>([[]])

  const [file, setFile] = useState<File>()

  const [edfFileError, setEdfFileError] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [selectedOptionHeight, setSelectedOptionHeight] =
    useState<DropDownStructure>(heightOptions[4])

  const [selectedOptionMontage, setSelectedOptionMontage] =
    useState<DropDownStructure | null>(null)

  const [showBadEpoch, setShowBadEpoch] = useState(false)

  const selectedOptionWidthRef = useRef<any>()
  const selectedOptionHeightRef = useRef<any>()
  const selectedOptionMontageRef = useRef<any>()

  const graphViewModel = useMemo<GraphViewModel>(() => {
    return new GraphViewModel({
      SECONDS_PER_PAGE: 30,
    })
  }, [])

  const b64ToArray = (str: string) => {
    const binaryString = atob(str)
    const bytes = new Uint8Array(binaryString.length)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }

  // choose file button handler
  const getEdfFile = async () => {
    if (!edfUrl) return
    const key = `stored_${edfUrl}`

    const prevEdf = await getEdfByPath(key)
    if (usingCache !== false && prevEdf) {
      setFile(
        new Blob([b64ToArray(prevEdf)], {type: 'binary/octet-stream'}) as File,
      )
      return
    }
    let edfData
    await axios({
      method: 'get',
      url: edfUrl,
      responseType: 'blob',
    })
      .then(({data}) => {
        if (usingCache !== false) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const result = reader.result as string
            const contents = result.split(',')[1]
            setEdfByPath(key, contents)
          }
          reader.readAsDataURL(data)
        }
        edfData = data
      })
      .catch((e) => {
        setEdfFileError(true)
      })
    if (!edfData) {
      return
    }
    await setFile(edfData)
  }

  useEffect(() => {
    if (edfData) {
      const arrOfTPV = graphViewModel.getGraph().generateGraphData(edfData)
      if (arrOfTPV) setArrOfVertices(arrOfTPV)
    }
  }, [edfData, selectedOptionHeight, selectedOptionMontage])

  const extractEdfDataFromFile = async (file: File) => {
    if (graphViewModel) {
      const edfDataLocal = await graphViewModel.getEdfDataFromEdfFile(file)
      if (edfDataLocal) {
        setEdfData(edfDataLocal)
      }
    }
  }

  // file change handler to draw graph when the file is changed
  useEffect(() => {
    if (file) {
      extractEdfDataFromFile(file)
    }
  }, [file])

  // get the EDF file
  useEffect(() => {
    setEdfData(undefined)
    getEdfFile()
  }, [edfUrl])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(
        (!graphViewModel.isFinished && !edfFileError) || edfData === undefined,
      )
    }, 200)

    return () => clearTimeout(timeout)
  }, [graphViewModel.isFinished, edfFileError, edfData])

  const isFinishLoaded =
    graphViewModel.isFinished && !edfFileError && edfData !== undefined

  return (
    <div>
      {isLoading && (
        <StyledLoadingDiv>
          <CircularProgress size='3rem' color='primary' />
        </StyledLoadingDiv>
      )}
      {!graphViewModel.isFinished && edfFileError && (
        <HorizontalImageGraphV1 xAxisImg={xAxisImg} width={width} />
      )}
      {isFinishLoaded && (
        <StyledDiv visible={graphViewModel.isFinished} style={{width}}>
          <StyledGraphWrap id='canvasParent' visible>
            {graphViewModel.getGraph().SET_OF_INDICES.length > 0 && (
              <BioGraphCanvas
                propertyGuide={propertyGuide}
                edfData={edfData}
                extraparam={arrOfVertices}
                graphViewModel={graphViewModel}
                containerRef={containerRef}
                showBadEpoch={showBadEpoch}
                optionChange={(type, value) => {
                  if (type === 'Width') {
                    selectedOptionWidthRef.current.innerText = value.optionTitle
                  } else if (type === 'Height') {
                    selectedOptionHeightRef.current.innerText =
                      value.optionTitle
                    setSelectedOptionHeight(value)
                  } else if (type === 'Montage') {
                    propertyGuide.changeLabelIndex(
                      value.optionValue as MontageFilterType,
                    )

                    selectedOptionMontageRef.current.innerText =
                      propertyGuide.optionForType(value.optionValue).optionTitle
                    setSelectedOptionMontage(value)
                    graphViewModel.updateMontageFilter(value.optionValue)
                  }
                }}
                screen={screen}
                typeGraph={typeGraph}
              />
            )}
          </StyledGraphWrap>
          <StyledToolbarWrap>
            <StyledControlsWrap>
              {propertyGuide.hasTimeRejections() && (
                <StyledScaleWrap>
                  <IOSSwitch2
                    title='Bad Epoch'
                    checked={showBadEpoch}
                    onClick={() => {
                      setShowBadEpoch(!showBadEpoch)
                    }}
                  />
                  <StyledContentTitle>Bad Epoch</StyledContentTitle>
                </StyledScaleWrap>
              )}
              <ControlsContainer>
                <StyledScaleWrap>
                  <StyledContentTitle>Width</StyledContentTitle>
                  <StyledContentBody ref={selectedOptionWidthRef} />
                </StyledScaleWrap>
                <StyledScaleWrap>
                  <StyledContentTitle>Height</StyledContentTitle>
                  <StyledContentBody ref={selectedOptionHeightRef} />
                </StyledScaleWrap>
                <StyledScaleWrap $display={propertyGuide.isDisplayOptions()}>
                  <StyledContentTitle>Montage</StyledContentTitle>
                  <StyledContentBody ref={selectedOptionMontageRef} />
                </StyledScaleWrap>
              </ControlsContainer>
            </StyledControlsWrap>
          </StyledToolbarWrap>
        </StyledDiv>
      )}
    </div>
  )
}
