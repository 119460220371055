import {Theme} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import classNames from 'classnames'
import CustomSelect from 'components/atoms/CustomSelect'
import {Colors} from 'components/common/useBaseStyle'
import {range} from 'helpers/commonHelper'
import React from 'react'

const pageCountSelectItems: SelectItem<PagingCountKind>[] = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '30', value: '30'},
  {label: '50', value: '50'},
]

const makePageNumbers = (
  pageIndex: number,
  displayPageCount: number,
  pageCount: number,
): number[] => {
  if (displayPageCount >= pageCount) {
    return range(0, pageCount - 1)
  }

  const pivotCount = Math.floor(displayPageCount / 2)

  if (pageIndex - pivotCount < 0) {
    // 앞이 비어서 뒤에 페이지네이션 채워야 함
    const distance = (pageIndex - pivotCount) * -1
    return range(0, pageIndex + pivotCount + distance)
  }

  if (pageIndex + pivotCount > pageCount - 1) {
    // 뒤가 비어서 앞에 채워야 함
    const distance = pageIndex + pivotCount - (pageCount - 1)
    return range(pageIndex - pivotCount - distance, pageCount - 1)
  }

  return range(pageIndex - pivotCount, pageIndex + pivotCount)
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    flex1: {flex: 1},
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      paddingBottom: 15,
      margin: '5px 0',
      // width: '100% !important',
      '& .MuiGrid-root': {
        maxWidth: '100%',
        padding: '0 !important',
      },
    },
    rootNoRowCount: {
      justifyContent: 'center',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginTop: 7,
      [theme.breakpoints.down('xs')]: {
        flex: 1,
      },
      '& > button': {
        padding: 5,
        height: 30,
        minWidth: 31,
        alignSelf: 'flex-end',
        color: Colors.pagination.color,
        border: `solid 1px ${Colors.pagination.border}`,
        borderRadius: 0,
        borderRightWidth: '0 !important',
        '&:first-child': {borderTopLeftRadius: 5, borderBottomLeftRadius: 5},
        '&:last-child': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          borderRightWidth: '1px !important',
        },
        '&.active': {backgroundColor: Colors.pagination.activeBg},
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        '&:disabled': {
          color: Colors.pagination.disabled,
        },
      },
    },
    paginationNoRowCount: {
      marginLeft: 0,
    },
  }),
)

interface PageNumbersProps {
  totalPageCount: number
  currentPageIndex: number
  displayIndexCount: number
  setCurrentPageIndex: (index: number) => void
  loading: boolean
}

const PageNumbers = ({
  totalPageCount,
  currentPageIndex,
  displayIndexCount,
  setCurrentPageIndex,
  loading = false,
}: PageNumbersProps) => {
  const numbers = makePageNumbers(
    currentPageIndex,
    displayIndexCount,
    totalPageCount,
  )

  if (!numbers || numbers.length === 0) {
    return (
      <Button
        variant='outlined'
        key={1}
        onClick={() => setCurrentPageIndex(1)}
        size='small'
        color='primary'
        disabled
      >
        <Typography variant='body2'>1</Typography>
      </Button>
    )
  }

  return (
    <>
      {numbers.map((index) => (
        <Button
          variant='outlined'
          key={index}
          onClick={() => {
            if (index === currentPageIndex) {
              return
            }
            setCurrentPageIndex(index)
          }}
          size='small'
          color='default'
          disabled={loading}
          className={currentPageIndex === index ? 'active' : ''}
        >
          <Typography variant='body2'>{index + 1}</Typography>
        </Button>
      ))}
    </>
  )
}

interface PaginationProps {
  totalPageCount: number
  currentPageIndex: number
  setCurrentPageIndex: (index: number) => void
  loading: boolean
  itemCountPerPage: number
  onItemCountPerPageChanged: (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
    child: React.ReactNode,
  ) => void
  displayIndexCount?: number
  isItemCountPerPageEnabled?: boolean
  isFirstAndLastButtonEnabled?: boolean
}

const Pagination = (props: PaginationProps) => {
  const classes = useStyle()
  const {
    totalPageCount,
    currentPageIndex,
    setCurrentPageIndex,
    loading = false,
    itemCountPerPage = 10,
    onItemCountPerPageChanged,
    displayIndexCount = 5,
    isItemCountPerPageEnabled = true,
    isFirstAndLastButtonEnabled = true,
  } = props

  const isFirstPage = () => currentPageIndex === 0 || totalPageCount === 0
  const isLastPage = () =>
    currentPageIndex === totalPageCount - 1 || totalPageCount === 0

  const hasPreviousPage = () => currentPageIndex - 1 >= 0
  const hasNextPage = () => currentPageIndex + 1 <= totalPageCount - 1

  const handleFirstPageClick = () => setCurrentPageIndex(0)
  const handleLastPageClick = () => setCurrentPageIndex(totalPageCount - 1)

  const handleNextPageClick = () => setCurrentPageIndex(currentPageIndex + 1)
  const handlePreviousPageClick = () =>
    setCurrentPageIndex(currentPageIndex - 1)

  // const noneItemPerPageViewSize = (itemPerPageEnable ? 10 : 12) as GridSize

  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.rootNoRowCount]: !isItemCountPerPageEnabled,
  })

  const pagingClassNames = classNames({
    [classes.pagination]: true,
    [classes.paginationNoRowCount]: !isItemCountPerPageEnabled,
  })

  return (
    <div className={rootClassNames}>
      {isItemCountPerPageEnabled && (
        <CustomSelect
          label='줄씩 보기'
          items={pageCountSelectItems}
          value={itemCountPerPage}
          marginLeft={15}
          onChange={onItemCountPerPageChanged}
        />
      )}
      <div className={pagingClassNames}>
        {isFirstAndLastButtonEnabled && (
          <Button
            variant='outlined'
            disabled={isFirstPage() || loading}
            onClick={handleFirstPageClick}
          >
            <FirstPageIcon />
          </Button>
        )}
        <Button
          variant='outlined'
          disabled={loading || !hasPreviousPage()}
          onClick={handlePreviousPageClick}
        >
          <NavigateBeforeIcon />
        </Button>
        <PageNumbers
          {...props}
          loading={loading}
          displayIndexCount={displayIndexCount}
        />
        <Button
          variant='outlined'
          disabled={loading || !hasNextPage()}
          onClick={handleNextPageClick}
        >
          <NavigateNextIcon />
        </Button>
        {isFirstAndLastButtonEnabled && (
          <Button
            variant='outlined'
            disabled={isLastPage() || loading}
            onClick={handleLastPageClick}
          >
            <LastPageIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Pagination
