import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {fetchReceiptApi} from 'api/paymentApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import useAuth from 'features/auth/useAuth'
import {
  closeReceiptDialog,
  selectReceiptDialogOpen,
} from 'features/modal/modalSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  paymentId: number
}

const ReceiptDialog = ({paymentId}: Props) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectReceiptDialogOpen)

  const {user: currentUser} = useAuth()

  const [receipt, setReceipt] = React.useState<Receipt>()

  const fetchReceipt = async function fetchReceipt() {
    const response = await fetchReceiptApi({
      paymentId,
      uid: currentUser?.uid ?? '',
    })
    setReceipt(response.data)
  }
  useEffect(() => {
    if (open) {
      fetchReceipt()
    }
  }, [open && currentUser])

  const handleClose = () => dispatch(closeReceiptDialog())

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={handleClose}>
      <Card className={classes.cardWrap}>
        <div className={classes.root}>
          <div
            className={classes.longTitleContainer}
            style={{backgroundColor: '#f9f9fb'}}
          >
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography variant='h6' className={classes.title}>
                {t('IReceipt')}
              </Typography>
            </div>
          </div>
          <div className={classes.scroll} style={{backgroundColor: '#f9f9fb'}}>
            <div className={classes.cardContent}>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap} style={{position: 'relative'}}>
                <div style={{position: 'absolute', right: 40}}>
                  <img
                    src={receipt?.stamp ?? ''}
                    alt='stamp'
                    className={`${classes.image} all`}
                  />
                </div>
                <Typography
                  style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                >
                  {t('IBaseInfo')}
                </Typography>
                <Typography className={classes.padding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IOrderNo')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.id ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IBusinessLicense')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.businessNo ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IReceiptCompanyName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.companyName ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IReceiptCompanyBossName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.bossName ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IReceiptCompanyAddress')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.address ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ITel')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.tel ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IReceiptCustomerSupport')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.email ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IFax')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.fax ?? '-'}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.cardContent}>
              <Typography className={classes.padding} />
              <div className={classes.infoWrap}>
                <Typography
                  style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                >
                  {t('IPurchaseInformation')}
                </Typography>
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IOrderDate')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.dateTime ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IProduct')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.product ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>NO.</Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.id ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IProductName')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.product ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IUnitPrice')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.amount ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IQuantity')}
                  </Typography>
                  <Typography className={classes.subTitle}>1</Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ISupplyValue')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.amount ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('IAdditionalTax')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.additionalTax ?? '-'}
                  </Typography>
                </div>
                <Divider className={classes.titleDivider} />
                <Typography className={classes.smallPadding} />
                <div className={classes.contentContainer}>
                  <span className={classes.bullet}> </span>
                  <Typography className={classes.subContent}>
                    {t('ITotalAmount')}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {receipt?.totalAmount ?? '-'}
                  </Typography>
                </div>
              </div>
            </div>
            <Typography className={classes.padding} />
            <Typography className={classes.padding} />
            <table className={classes.table}>
              <thead>
                <tr className={classes.bottomTableHeader}>
                  <th scope='col'>No</th>
                  <th scope='col'>{t('IProductName')}</th>
                  <th scope='col'>{t('IUnitPrice')}</th>
                  <th scope='col'>{t('IQuantity')}</th>
                  <th scope='col'>{t('ISupplyValue')}</th>
                  <th scope='col'>{t('IAdditionalTax')}</th>
                  <th scope='col'>{t('ITotalAmount')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.tableBackground}>
                  <td className={classes.bottomTableContent}>1</td>
                  <td className={classes.bottomTableContent}>
                    {receipt?.product ?? '-'}
                  </td>
                  <td className={classes.bottomTableContent}>
                    ₩ {receipt?.amount ?? '-'}
                  </td>
                  <td className={classes.bottomTableContent}>1</td>
                  <td className={classes.bottomTableContent}>
                    ₩ {receipt?.amount ?? '-'}
                  </td>
                  <td className={classes.bottomTableContent}>
                    ₩ {receipt?.additionalTax ?? '-'}
                  </td>
                  <td className={classes.bottomTableContent}>
                    ₩ {receipt?.totalAmount ?? '-'}
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography className={classes.padding} />
            <Typography className={classes.padding} />
          </div>
        </div>

        <div
          style={{
            padding: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ActionButton
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleClose}
            style={{width: 220, height: 40}}
          >
            {t('IOk')}
          </ActionButton>
        </div>
      </Card>
    </Dialog>
  )
}

export default ReceiptDialog
