import React, {useMemo} from 'react'
import {makeStyles} from '@material-ui/core/styles'

import {
  BaseFeesType,
  PlanType,
  ProductTemplateType,
  ProductType,
  ProductUsesKey,
} from 'features/invoice/invoiceSlice'

import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'
import {
  NEW_TEMPLATE_SINGLE,
  NEW_TEMPLATE_GROUP,
} from 'pages/CustomerManagement/InvoiceSetting/constant'

const useStyles = makeStyles(InvoiceSettingStyle)
export interface FormInputType {
  placeholder: string
  name: string
  key?: ProductType
  required: boolean
  value?: string
  width?: number
}

export interface CellItemType {
  title: string
  input: FormInputType[]
}

export interface CellPropsType {
  data: CellItemType[]
  parentId: PlanType
  packageType: PlanType
  baseFees: BaseFeesType | null
  templates: ProductTemplateType[] | null
  changeProductTemplateAction: (payload: ProductTemplateType[]) => void
  changeBaseFeesAction: (payload: BaseFeesType) => void
  ratePlan?: boolean
}

const Cell = ({
  data,
  parentId,
  packageType,
  baseFees,
  templates,
  changeProductTemplateAction,
  changeBaseFeesAction,
  ratePlan,
}: CellPropsType) => {
  const classes = useStyles()
  const getNumber = (value: number | string) => {
    if (value && !Number.isNaN(Number(value))) return Number(value)
    return null
  }
  const isDisabled = useMemo(
    () => !packageType || (packageType && packageType !== parentId),
    [packageType, parentId],
  )

  const mapBaseFeesData = (key: string) => {
    if (!baseFees?.length || baseFees.length !== 2) return ''
    if (key === 'first_subscription_name') {
      return baseFees[0].name ?? ''
    }
    if (key === 'first_subscription_price') {
      if (baseFees[0].price === 0) return 0
      return baseFees[0].price ?? ''
    }
    if (key === 'second_subscription_name') {
      return baseFees[1].name ?? ''
    }
    if (key === 'second_subscription_price') {
      if (baseFees[1].price === 0) return 0
      return baseFees[1].price ?? ''
    }
    return ''
  }

  const mapTemplateSingle = (productType?: ProductType) => {
    const product = templates?.find((item) => item.productType === productType)
    if (product?.usages[0]?.price === 0) return 0
    return product?.usages[0]?.price || ''
  }

  const mapTemplateThree = (key: string, productType?: ProductType) => {
    const product: ProductTemplateType | undefined = templates?.find(
      (item) => item.productType === productType,
    )

    if (!product || !product?.usages || product?.usages?.length < 2) return ''
    if (key.includes('highPrice')) {
      if (product?.usages[1].price === 0) return 0
      return product?.usages[1].price
    }
    if (key.includes('regularPrice')) {
      if (product?.usages[0].price === 0) return 0
      return product?.usages[0].price
    }
    if (key.includes('maxCount')) {
      return product.usages[0].maxCount
    }
    return ''
  }

  const getDefaultValue = (
    key: string,
    length: number,
    productType?: ProductType,
  ) => {
    if (isDisabled) return ''
    if (packageType !== parentId) return ''

    if (length === 4) {
      return mapBaseFeesData(key)
    }
    if (length === 1) {
      return mapTemplateSingle(productType)
    }
    if (length === 3) {
      return mapTemplateThree(key, productType)
    }
    return ''
  }

  const getBaseFeesData = (value: string, key: string) => {
    let baseData: BaseFeesType = JSON.parse(JSON.stringify(baseFees))
    if (!baseData?.length) {
      baseData = [
        {name: '', price: 0},
        {name: '', price: 0},
      ]
    }
    if (key === 'first_subscription_name') {
      baseData[0].name = value
    }
    if (key === 'first_subscription_price') {
      baseData[0].price = Number(value) ? parseInt(value || '0', 10) : 0
    }
    if (key === 'second_subscription_name') {
      baseData[1].name = value
    }
    if (key === 'second_subscription_price') {
      baseData[1].price = Number(value) ? parseInt(value || '0', 10) : 0
    }
    return baseData
  }

  const getTemplateSingle = (value: string, productType?: ProductType) => {
    if (!productType) return []
    let baseData: ProductTemplateType[] = JSON.parse(
      JSON.stringify(templates || []),
    )
    const productTypes = baseData.map((item) => item.productType)
    const isExistProductType = !!productTypes.includes(productType)
    if (isExistProductType) {
      baseData = baseData.map((item) => {
        if (item.productType === productType) {
          item.usages[0].price = getNumber(value)
        }
        return item
      })
    } else {
      baseData.push({
        productType,
        usages: [
          {
            minCount: 1,
            maxCount: null,
            price: getNumber(value),
          },
        ],
      })
    }
    return baseData
  }

  const updateTemplate = (
    value: string,
    inputKey: string,
    item: ProductTemplateType,
  ) => {
    if (inputKey.includes('highPrice')) {
      item.usages[1].price = getNumber(value)
    } else if (inputKey.includes('regularPrice')) {
      item.usages[0].price = getNumber(value)
    } else if (inputKey.includes('maxCount')) {
      item.usages[0].maxCount = Number(value)
        ? parseInt(value || '0', 10)
        : null
      item.usages[1].minCount = Number(value)
        ? parseInt(value || '0', 10) + 1
        : 0 + 1
    }
    return item
  }

  const getTemplateGroup = (
    value: string,
    inputKey: string,
    productType?: ProductType,
  ) => {
    if (!productType) return []
    let baseData: ProductTemplateType[] = JSON.parse(
      JSON.stringify(templates || []),
    )
    const productTypes = baseData.map((item) => item.productType)

    const isExistProductType = !!productTypes.includes(productType)
    if (isExistProductType) {
      baseData = baseData.map((item) => {
        if (item.productType === productType) {
          item = updateTemplate(value, inputKey, item)
        }
        return item
      })
    } else {
      const temp = {
        productType,
        usages: [...NEW_TEMPLATE_SINGLE],
      }
      baseData.push(updateTemplate(value, inputKey, temp))
    }
    return baseData
  }

  const onChangeSingle = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    length: number,
    productType?: ProductType,
  ) => {
    if (length === 4) {
      const data = getBaseFeesData(e.target.value, key)
      changeBaseFeesAction(data)
    }
    if (length === 1) {
      const data = getTemplateSingle(e.target.value, productType)
      changeProductTemplateAction(data)
    }
    if (length === 3) {
      const data = getTemplateGroup(e.target.value, key, productType)
      changeProductTemplateAction(data)
    }
  }

  const getProperty = (row: number, col: number) => {
    let property: ProductUsesKey
    if (row === 0) {
      property = col === 0 ? 'maxCount' : 'price'
    } else if (row === 5) {
      property = col === 0 ? 'minCount' : 'price'
    } else if (col === 0) {
      property = 'minCount'
    } else if (col === 1) {
      property = 'maxCount'
    } else {
      property = 'price'
    }
    return property
  }

  const mapTemplateGroup = (
    row: number,
    col: number,
    productType?: ProductType,
  ) => {
    if (isDisabled) return ''
    if (packageType !== parentId) return ''
    const product = templates?.find((item) => item.productType === productType)
    if (!product || !product.usages?.length) return ''
    const property: ProductUsesKey = getProperty(row, col)
    if (product.usages[row]) return product.usages[row][property]
    return ''
  }

  const updateTemplateGroup = (
    value: string,
    item: ProductTemplateType,
    row: number,
    col: number,
  ) => {
    const newData = {...item, usages: item.usages.map((use) => ({...use}))}
    let parsedValue
    if (ratePlan && getNumber(value) === 0 && col === 0) {
      parsedValue = null
    } else {
      parsedValue = getNumber(value)
    }

    const property: ProductUsesKey = getProperty(row, col)
    newData.usages[row][property] = parsedValue
    if (
      row <= 4 &&
      typeof parsedValue === 'number' &&
      property === 'maxCount'
    ) {
      newData.usages[row + 1].minCount = parsedValue + 1
    }
    return newData
  }

  const onChangeGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: number,
    col: number,
    productType?: ProductType,
  ) => {
    if (!productType) return
    let baseData: ProductTemplateType[] = JSON.parse(
      JSON.stringify(templates || []),
    )
    const productTypes = baseData.map((item) => item.productType)
    const isExistProductType = !!productTypes.includes(productType)
    if (isExistProductType) {
      baseData = baseData.map((item) => {
        if (item.productType === productType) {
          item = updateTemplateGroup(e.target.value, item, row, col)
        }
        return item
      })
    } else {
      const temp = {
        productType,
        usages: [...NEW_TEMPLATE_GROUP],
      }
      baseData.push(updateTemplateGroup(e.target.value, temp, row, col))
    }

    changeProductTemplateAction(baseData)
  }

  if (!data.length) return <div>''</div>
  return (
    <div className={classes.elementCell}>
      {data.map((item, index) => (
        <div className={classes.cellItem} key={`${index}_row`}>
          <span className={classes.cellItemTitle}>{item.title}</span>
          {item.input.length < 15 ? (
            item.input?.map((val) => (
              <input
                key={`${val.name}`}
                style={{
                  width: val.width ? val.width : 60,
                  maxWidth: val.width ? val.width : 60,
                }}
                placeholder={val.placeholder}
                disabled={isDisabled}
                className={classes.input}
                value={
                  getDefaultValue(val.name, item.input.length, val.key) ?? ''
                }
                onChange={(e) =>
                  onChangeSingle(e, val.name, item.input.length, val.key)
                }
              />
            ))
          ) : (
            <div>
              <div>
                {item.input?.slice(0, 2).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(0, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 0, i, val.key)}
                  />
                ))}
              </div>

              <div>
                {item.input?.slice(2, 5).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(1, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 1, i, val.key)}
                  />
                ))}
              </div>
              <div>
                {item.input?.slice(5, 8).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(2, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 2, i, val.key)}
                  />
                ))}
              </div>
              <div>
                {item.input?.slice(8, 11).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(3, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 3, i, val.key)}
                  />
                ))}
              </div>
              <div>
                {item.input?.slice(11, 14).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(4, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 4, i, val.key)}
                  />
                ))}
              </div>
              <div>
                {item.input?.slice(14, 16).map((val, i) => (
                  <input
                    key={`${val.name}`}
                    style={{
                      width: val.width ? val.width : 60,
                      maxWidth: val.width ? val.width : 60,
                    }}
                    placeholder={val.placeholder}
                    disabled={isDisabled}
                    className={classes.input}
                    value={mapTemplateGroup(5, i, val.key) ?? ''}
                    onChange={(e) => onChangeGroup(e, 5, i, val.key)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Cell
