export interface LabelPreset {
  position: number
  label: string
  type: 'checkbox' | 'radio'
  key: string
  name?: string
  field?: string
}

export interface PresetSetting {
  id: number
  createdAt?: string
  label?: string
  orgId?: number
  requestTypeList: string[]
  updateAt?: string
  zscore?: number | null
  action?: null | PresetAction
}

export enum PresetAction {
  create = 'create',
  update = 'update',
  delete = 'delete',
  default = 'default',
}

export const PRESET_SETTING: PresetSetting[] = []

export const NEW_PRESET_SETTING: PresetSetting = {
  id: -99,
  requestTypeList: [],
  zscore: null,
  action: PresetAction.create,
}

export const ZSCORE_VALID = ['1.96', '2.58', '3']

export const PRESET_KEY = {
  eegIndv: 'eegIndv',
  eegIndvAuto: 'eegIndvAuto',
  eegIndvIca: 'eegIndvIca',
  eegIndv3dOn: 'eegIndv3dOn',
  eegIndv3dOff: 'eegIndv3dOff',
  eegNorm: 'eegNorm',
  1.96: '1.96',
  2.58: '2.58',
  3: '3',
  eegNorm3dOn: 'eegNorm3dOn',
  eegNorm3dOff: 'eegNorm3dOff',
  eegSummary: 'eegSummary',
  eegSummaryBas: 'eegSummaryBas',
  eegSummaryAdv: 'eegSummaryAdv',
  eegMci: 'eegMci',
  hrvIndv: 'hrvIndv',
  hrvSummary: 'hrvSummary',
  hrvSummaryBas: 'hrvSummaryBas',
  hrvSummaryAdv: 'hrvSummaryAdv',
}

export const DATA_EEG_LABEL_PRESET: LabelPreset[][] = [
  [
    {
      position: 1,
      label: 'IPresetIndividual',
      type: 'checkbox',
      key: PRESET_KEY.eegIndv,
    },
    // {
    //   position: 2,
    //   field: 'Denoising method',
    //   label: 'Auto denoising',
    //   type: 'radio',
    //   key: PRESET_KEY.eegIndvAuto,
    //   name: 'eegIndvDenoising',
    // },
    // {
    //   position: 2,
    //   label: 'Manual denoising',
    //   type: 'radio',
    //   key: PRESET_KEY.eegIndvIca,
    //   name: 'eegIndvDenoising',
    // },
    {
      position: 2,
      label: 'IOn',
      type: 'radio',
      key: PRESET_KEY.eegIndv3dOn,
      name: 'eegIndv3d',
      field: 'IPreset3D',
    },
    {
      position: 2,
      label: 'IOff',
      type: 'radio',
      key: PRESET_KEY.eegIndv3dOff,
      name: 'eegIndv3d',
    },
  ],
  [
    {
      position: 1,
      label: 'IPresetNorm',
      type: 'checkbox',
      key: PRESET_KEY.eegNorm,
    },
    {
      position: 2,
      label: '1.96',
      type: 'radio',
      key: PRESET_KEY['1.96'],
      name: 'zscore',
      field: 'Izscore',
    },
    {
      position: 2,
      label: '2.58',
      type: 'radio',
      key: PRESET_KEY['2.58'],
      name: 'zscore',
    },
    {
      position: 2,
      label: '3.00',
      type: 'radio',
      key: PRESET_KEY[3],
      name: 'zscore',
    },
    {
      position: 3,
      label: 'IOn',
      type: 'radio',
      key: PRESET_KEY.eegNorm3dOn,
      name: 'eegNorm3d',
      field: 'IPreset3D',
    },
    {
      position: 3,
      label: 'IOff',
      type: 'radio',
      key: PRESET_KEY.eegNorm3dOff,
      name: 'eegNorm3d',
    },
  ],
  [
    {
      position: 1,
      label: 'IPresetSummary',
      type: 'checkbox',
      key: PRESET_KEY.eegSummary,
    },
    {
      position: 2,
      label: 'IPresetBas',
      type: 'radio',
      key: PRESET_KEY.eegSummaryBas,
      name: 'eegSummaryType',
      field: 'IPresetType',
    },
    {
      position: 2,
      label: 'IPresetAdv',
      type: 'radio',
      key: PRESET_KEY.eegSummaryAdv,
      name: 'eegSummaryType',
    },
  ],
  [
    {
      position: 1,
      label: 'IPresetMci',
      type: 'checkbox',
      key: PRESET_KEY.eegMci,
    },
  ],
]

export const DATA_HRV_LABEL_PRESET: LabelPreset[][] = [
  [
    {
      position: 1,
      label: 'IPresetIndividual',
      type: 'checkbox',
      key: PRESET_KEY.hrvIndv,
    },
  ],
  [
    {
      position: 1,
      label: 'IPresetSummary',
      type: 'checkbox',
      key: PRESET_KEY.hrvSummary,
    },
    {
      position: 2,
      label: 'IPresetBas',
      type: 'radio',
      key: PRESET_KEY.hrvSummaryBas,
      name: 'hrvSummaryType',
      field: 'IPresetType',
    },
    {
      position: 2,
      label: 'IPresetAdv',
      type: 'radio',
      key: PRESET_KEY.hrvSummaryAdv,
      name: 'hrvSummaryType',
    },
  ],
]
