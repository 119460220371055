import {Box, Button} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {CreateOrganizationV2, createOrganizationV2Api} from 'api/authApi'
// import {fetchTerms} from 'api/commonApi'
import {rsaEncryption} from 'api/securityApi'
import {getOrInitializeDeviceId} from 'api/storageApi'
import {AppHistoryState, navigate} from 'AppHistory'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RegisterCardWithTitle from 'components/atoms/RegisterCardWithTitle'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import SelectBoldCountry from 'components/Dialog/BoldSelect/SelectBoldCountry'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import RouteConstants from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
// import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {getFBToken} from 'helpers/firebaseHelper'
import RegisterStyle from 'pages/AuthPages/RegisterPage/Style'
import React, {useEffect, useState} from 'react'
import {isDefined} from 'helpers/commonHelper'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
// import {loadTossPayments} from '@tosspayments/payment-sdk'
import {Redirect, useLocation} from 'react-router-dom'
import AddressInput from './Form/AddressInput'
import BusinessNumberInput from './Form/BusinessNumberInput'
import EmailConfirmInput from './Form/EmailConfirmInput'
import EmailInput from './Form/EmailInput'
import NursingInstitutionInput from './Form/NursingInstitutionInput'
import OrganizationNameInput from './Form/OrganizationNameInput'
import PasswordInput from './Form/PasswordInput'
import SelectedIndustry from './Form/SelectedIndustry'
import TelephoneNumberInput from './Form/TelephoneNumberInput'
import UserNameInput from './Form/UserNameInput'

const useStyles = makeStyles(RegisterStyle)

export default function RegisterPage() {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const EMAIL_REQUEST_TYPE = 'J'

  const {loading} = useAuth()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const createOrgInfo = useForm()
  const {
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm<CreateOrganizationV2>()
  // const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const [serviceChecked, setServiceChecked] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [personalChecked, setPersonalChecked] = useState(false)
  const [uuid, setUuid] = useState<string>()

  const checkServiceHandler = () => setServiceChecked(!serviceChecked)
  const checkPrivacyHandler = () => {
    setPrivacyChecked(!privacyChecked)
    setValue('isAgreePrivacy', !privacyChecked)
  }
  const handleMoveLogin = () => navigate(RouteConstants.LOGIN.path)

  const [duplicateEmail, setDuplicateEmail] = useState(false)
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(false)
  const [isVerifiedCode, setIsVerifiedCode] = useState(false)

  const duplicateCheck = () => setDuplicateEmail(!duplicateEmail)
  const isVerified = () => setIsVerifiedEmail(!isVerifiedEmail)

  // const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
  const {user, onLogin} = useAuth()
  const {onOpen} = useFailureModal()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const location = useLocation<AppHistoryState>()
  const from = location?.state?.from ?? {pathname: '/'}

  const loginAction = async (email: string, password: string) => {
    try {
      const token = await getFBToken()
      await onLogin({
        email,
        password,
        deviceId: getOrInitializeDeviceId(),
        deviceType: 'WEB',
        token,
        lang: i18n.language,
      })
    } catch (err) {
      await onOpen(err.message)
    }
  }

  const handleRegister = async (
    orgInfo: CreateOrganizationV2,
    email: string,
    password: string,
  ) => {
    // const tosspayments = await loadTossPayments(clientKey)
    try {
      const response = await createOrganizationV2Api(orgInfo)
      if (!response) {
        loginAction(email, password)

        // onNoticeDialogOpen({
        //   title: t('INoticeTitle'),
        //   message: t('IRegisterCardConfirmDesc'),
        // }).then((result) => {
        //   if (result.payload) {
        //     tosspayments.requestBillingAuth('카드', {
        //       customerKey: `customerKey_${Math.random()
        //         .toString(36)
        //         .substring(2, 11)}_${Date.now()}`,
        //       successUrl: `${window.location.href}/redirect/test`,
        //       failUrl: `${window.location.href}/redirect/test`,
        //     })
        //     loginAction(email, password)
        //   }
        //   if (!result.payload) loginAction(email, password)
        // })
      }
      if (response) onFailureModalOpen(response.message)
    } catch (e) {
      onFailureModalOpen(t('IRegisterFail'))
    }
  }

  const handleCreate = (
    orgData: CreateOrganizationV2,
    email: string,
    password: string,
  ) => {
    handleRegister(orgData, email, password)
  }
  const [privacyUrl, setPrivacyUrl] = React.useState('')
  const [serviceUrl, setServiceUrl] = React.useState('')

  // const handleTerms = async (termsRequest: 'PRIVACY' | 'SERVICE') => {
  //   try {
  //     const response = await fetchTerms({
  //       termsLang: i18n.language,
  //       type: termsRequest,
  //     })
  //     if (response.success && termsRequest === 'PRIVACY') {
  //       setPrivacyUrl(response.data)
  //     }
  //     if (response.success && termsRequest === 'SERVICE') {
  //       setServiceUrl(response.data)
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // CYM: add 2022-12-12
  // *apply previous terms html file until finish update ui*
  // because the way was changed that iframe to innerHTML
  // in html file, only there is body tag, there is no head
  // so korean language is broken because utf-8 is not applied
  // ko terms,en terms url exception handling (add 2023-03-20)
  useEffect(() => {
    if (i18n.language === 'ko') {
      setPrivacyUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_ko.html',
      )
      setServiceUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko.html',
      )
    } else {
      setPrivacyUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_en.html',
      )
      setServiceUrl(
        'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_en.html',
      )
    }
    // handleTerms('PRIVACY')
    // handleTerms('SERVICE')
  }, [i18n.language])

  // useEffect(() => {
  //   handleTerms('PRIVACY')
  //   handleTerms('SERVICE')
  // }, [i18n.language])

  if (isDefined(user)) {
    return <Redirect to={from} />
  }

  return (
    <>
      {loading && <div>loading</div>}
      <div className={classes.topRoot}>
        <FormProvider {...createOrgInfo}>
          <form
            onSubmit={createOrgInfo.handleSubmit((data) => {
              clearErrors('isAgreeTerms')
              clearErrors('isAgreePrivacy')
              if (!privacyChecked) {
                setError('isAgreePrivacy', {
                  type: 'custom',
                  message: `${t('IRequired')}`,
                })
                onFailureModalOpen(t('IRegisterTermsNotAgree'))
                return
              }
              if (!serviceChecked) {
                setError('isAgreeTerms', {
                  type: 'custom',
                  message: `${t('IRequired')}`,
                })
                onFailureModalOpen(t('IRegisterTermsNotAgree'))
                return
              }
              if (!duplicateEmail) {
                onFailureModalOpen(t('IRegisterEmailDuplicateNotChecked'))
                return
              }
              if (!isVerifiedEmail) {
                onFailureModalOpen(t('INotVerifiedCode'))
                return
              }
              delete data.passwordConfirm
              handleCreate(
                {
                  name: data.name,
                  isPersonal: personalChecked,
                  chiefFirstName: data.chiefFirstName,
                  chiefLastName: data.chiefLastName,
                  countryId: data.countryId,
                  address: data.address,
                  businessNo: data.businessNo,
                  nursingNo: data.nursingNo,
                  tel: data.tel,
                  industries: [
                    {id: data.industry, title: ''},
                    {
                      id: data.industryDetail,
                      title: '',
                    },
                  ],
                  email: rsaEncryption(data.email),
                  password: rsaEncryption(data.password),
                  isAgreePrivacy: true,
                  isAgreeTerms: true,
                },
                data.email,
                data.password,
              )
              setEmail(data.email)
              setPassword(data.password)
            })}
          >
            <div className={classes.container}>
              <div className={classes.agreeSectionContainer}>
                <RegisterCardWithTitle
                  title={t('IRegisterPrivacyAgree')}
                  className={classes.termsWrap}
                >
                  <iframe
                    className={classes.termsContainer}
                    title='My Daily Marathon Tracker'
                    scrolling='yes'
                    src={privacyUrl}
                  />
                </RegisterCardWithTitle>
                <div className={classes.serviceCheckboxWrap}>
                  <Checkbox
                    id='check'
                    color='primary'
                    disableRipple
                    checked={privacyChecked}
                    inputProps={{'aria-label': 'decorative checkbox'}}
                    onChange={() => checkPrivacyHandler()}
                  />
                  <span className={classes.termsTitle}>
                    {t('IRegisterPrivacyAgreeNotice')}
                  </span>
                </div>
                {errors.isAgreePrivacy && (
                  <span className={inputClasses.errorText}>
                    {t('IRequired')}
                  </span>
                )}
                <Typography className={classes.padding} />
                <RegisterCardWithTitle
                  title={t('IRegisterServiceAgree')}
                  className={classes.termsWrap}
                >
                  <iframe
                    className={classes.termsContainer}
                    title='My Daily Marathon Tracker'
                    src={serviceUrl}
                  />
                </RegisterCardWithTitle>

                <div className={classes.serviceCheckboxWrap}>
                  <Checkbox
                    color='primary'
                    checked={serviceChecked}
                    disableRipple
                    inputProps={{'aria-label': 'decorative checkbox'}}
                    onChange={() => checkServiceHandler()}
                  />
                  <span className={classes.termsTitle}>
                    {t('IRegisterServiceAgreeNotice')}
                  </span>
                </div>
                {errors.isAgreeTerms && (
                  <span className={inputClasses.errorText}>
                    {t('IRequired')}
                  </span>
                )}
              </div>

              <CardWithTitle title={t('IUserInfo')} className={classes.root}>
                <div className={classes.root}>
                  <div className={classes.inputContainer}>
                    <OrganizationNameInput
                      onPersonal={(e) => {
                        setPersonalChecked(e ?? false)
                      }}
                    />
                    <UserNameInput />
                    <EmailInput
                      onDuplicateChecked={duplicateCheck}
                      requestType={EMAIL_REQUEST_TYPE}
                      onSetUuid={setUuid}
                      isVerifiedCode={isVerifiedCode}
                    />
                    <EmailConfirmInput
                      onDuplicateChecked={() => {
                        isVerified()
                        setIsVerifiedCode(true)
                      }}
                      uuid={uuid ?? ''}
                    />
                    <PasswordInput />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}
                      >
                        <p className={inputClasses.inputLabel}>
                          {t('ICountry')}
                        </p>
                        <p className={inputClasses.require}>*</p>
                      </div>

                      <SelectBoldCountry
                        className={classes.selectContainer}
                        {...createOrgInfo.register('countryId', {
                          required: `${t('IRequired')}`,
                        })}
                      />
                      {errors.countryId && (
                        <span className={inputClasses.errorText}>
                          {t('IRequired')}
                        </span>
                      )}
                    </div>
                    <AddressInput />
                    <TelephoneNumberInput />
                    <SelectedIndustry />
                    <BusinessNumberInput />
                    <NursingInstitutionInput />
                    <Typography className={classes.padding} />
                    <div className={classes.submitButtonContainer}>
                      <Button
                        variant='contained'
                        color='default'
                        disableElevation
                        type='submit'
                        className={classes.cancelButton}
                        onClick={handleMoveLogin}
                      >
                        {t('ICancel')}
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        type='submit'
                      >
                        {t('IRegister')}
                      </Button>
                    </div>
                    <div className={classes.bottomToolbarContainer}>
                      <p>{t('IRegisterHaveAnAccount')}</p>
                      <Typography color='primary' onClick={handleMoveLogin}>
                        <Box className={classes.textButton}>
                          {t('IRegisterLogin')}
                        </Box>
                      </Typography>
                    </div>
                  </div>
                </div>
              </CardWithTitle>
            </div>
          </form>
        </FormProvider>
      </div>
      <NoticeConfirmDialog />
    </>
  )
}
