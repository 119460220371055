import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import {
  addTherapyApi,
  TherapyAddRequest,
  editTherapyApi,
  TherapyEditRequest,
  TherapyReadRequest,
  readTherapyReservationApi,
} from 'api/therapyApi'

import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'

import i18n from 'i18n'
import {RootState} from 'store'
import {MultiTherapyHistory} from '../../../@types'

export interface TherapyReservationState {
  reservation: TherapyReservationRead
  recentList: MultiTherapyHistory
  selectedIdx: number
  uid?: string | null
  uuid?: string | null
  loading: boolean
  error: unknown
  readDialogOpen: boolean
  createDialogOpen: boolean
  updateDialogOpen: boolean
  recentReadDialogOpen: boolean
}
export const initialState: TherapyReservationState = {
  reservation: <TherapyReservationRead>{},
  recentList: <MultiTherapyHistory>{},
  selectedIdx: 0,
  uid: '',
  uuid: '',
  error: null,
  loading: false,
  readDialogOpen: false,
  createDialogOpen: false,
  updateDialogOpen: false,
  recentReadDialogOpen: false,
}

// CYM : 테라피 Modal - Read Edit Create (처방 예약 수정 / 추가 / 최근 예약 확인)
export const fetchTherapyRead = createAsyncThunk(
  'api/therapy/read',
  async (payload: TherapyReadRequest, {rejectWithValue}) => {
    try {
      const response = await readTherapyReservationApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const updateTherapyReservation = createAsyncThunk(
  'therapy/update',
  async (payload: TherapyReadRequest, {dispatch}) => {
    try {
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
    } catch (err) {
      openFailureModalAction(err.message)
    }
  },
)

export const openReadDialog = createAction<string>('therapy_dialog/read/open')
export const closeReadDialog = createAction('therapy_dialog/read/close')

export const openUpdateDialog = createAction<string>(
  'therapy_dialog/update/open',
)
export const closeUpdateDialog = createAction('therapy_dialog/update/close')

export const openCreateDialog = createAction<string>(
  'therapy_dialog/create/open',
)
export const closeCreateDialog = createAction('therapy_dialog/create/close')

export const openRecentReadDialog = createAction<MultiTherapyHistory>(
  'therapy_dialog/recent_read/open',
)
export const closeRecentReadDialog = createAction(
  'therapy_dialog/recent_read/close',
)

export const moveRecentReadDialogIndexPrev = createAction(
  'therapy_dialog/recent_read/prev',
)

export const moveRecentReadDialogIndexNext = createAction(
  'therapy_dialog/recent_read/next',
)

export const moveRecentReadDialogIndex = createAction<number>(
  'therapy_dialog/recent_read/idx',
)

export const therapy = createSlice({
  name: 'therapy',
  initialState,
  reducers: {
    openReadDialog(state, action: PayloadAction<string>) {
      state.readDialogOpen = true
      state.uuid = action.payload
    },
    openUpdateDialog(state) {
      state.updateDialogOpen = true
    },
    openCreateDialog(state) {
      state.createDialogOpen = true
    },
    openRecentReadDialog(state, action: PayloadAction<MultiTherapyHistory>) {
      state.recentReadDialogOpen = true
      state.recentList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openReadDialog, (state, action) => {
        state.readDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeReadDialog, (state) => {
        state.readDialogOpen = false
      })
      .addCase(openUpdateDialog, (state, action) => {
        state.updateDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeUpdateDialog, (state) => {
        state.updateDialogOpen = false
      })
      .addCase(openCreateDialog, (state, action) => {
        state.createDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeCreateDialog, (state) => {
        state.createDialogOpen = false
      })
      .addCase(openRecentReadDialog, (state, action) => {
        state.recentReadDialogOpen = true
        state.recentList = action.payload
      })
      .addCase(closeRecentReadDialog, (state) => {
        state.recentReadDialogOpen = false
      })
      .addCase(moveRecentReadDialogIndexPrev, (state) => {
        if (state.selectedIdx > 0) {
          state.selectedIdx -= 1
        } else {
          state.selectedIdx = 0
        }
      })
      .addCase(moveRecentReadDialogIndexNext, (state) => {
        state.selectedIdx += 1
      })
      .addCase(moveRecentReadDialogIndex, (state, action) => {
        state.selectedIdx = action.payload
      })

    builder.addCase(fetchTherapyRead.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchTherapyRead.fulfilled, (state, action) => {
      const therapyReservationData = action.payload
      state.reservation = therapyReservationData
      state.loading = false
      state.error = null
    })
    builder.addCase(fetchTherapyRead.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const createTherapy = createAsyncThunk(
  'therapy/create',
  async (payload: TherapyAddRequest, {dispatch}) => {
    try {
      await addTherapyApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
    } catch (err) {
      openFailureModalAction(err.message)
    }
  },
)

export const updateTherapy = createAsyncThunk(
  'therapy/update',
  async (payload: TherapyEditRequest, {dispatch}) => {
    try {
      await editTherapyApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
    } catch (err) {
      openFailureModalAction(err.message)
    }
  },
)

export const selectEntity = (state: RootState) => ({
  reservation: state.therapy.reservation,
  loading: state.therapy.loading,
  error: state.therapy.error,
})

export const selectReadDialog = (state: RootState) => ({
  open: state.therapy.readDialogOpen,
  reservation: state.therapy.reservation,
  uuid: state.therapy.uuid,
})

export const selectQuery = (state: RootState) => ({
  uid: state.therapy.uid,
  uuid: state.therapy.uuid,
})

export const selectError = (state: RootState) => state.therapy.error
export const selectList = (state: RootState) => state.therapy.reservation
export const selectLoading = (state: RootState) => state.therapy.loading

// Update Dialog
export const selectUpdateDialog = (state: RootState) => ({
  open: state.therapy.updateDialogOpen,
  reservation: state.therapy.reservation,
  uuid: state.therapy.uuid,
})

// Create Dialog
export const selectCreateDialog = (state: RootState) => ({
  open: state.therapy.createDialogOpen,
  reservation: state.therapy.reservation,
  uuid: state.therapy.uuid,
})

// Recent Read Dialog
export const selectRecentReadDialog = (state: RootState) => ({
  open: state.therapy.recentReadDialogOpen,
  recentList: state.therapy.recentList,
  selectedIdx: state.therapy.selectedIdx,
})

export default therapy.reducer
