import {TableBody} from '@material-ui/core'
import CardWithTitle from 'components/atoms/CardWithTitle'
import {
  CanBePurchaseProducts,
  PurchaseBundle,
  PurchasedProducts,
  PurchasePackage,
  AnalysisProduct,
  UnPaidVoucherContainer,
} from 'api/paymentApi'
import CheckBoxRoundTableHeader from 'components/Table/CheckBoxRoundTableHeader'
import {
  PackageTable,
  TableOverflowWrap,
} from 'components/Table/CommonTableStyle'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import {numberWithCommas} from 'helpers/commonHelper'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {PaymentManagementStyles} from 'components/V2Select/IconSelect/Styles'
import ProductThumbnail from 'components/V2ProductThumbnail/ProductThumbnail'
import {
  BundleThumbnailStyles,
  PackageThumbnailStyles,
} from 'components/V2ProductThumbnail/Styles'
import useOrgPurchase from 'features/org/useOrgPurchase'
import IconSelect, {
  SpreadableWithIcon,
  SpreadItemWithIcon,
} from 'components/V2Select/IconSelect/IconSelect'
import Select from 'components/V2Select/Select/Select'
import useViewModel from '../ViewModels/ProductsSelectViewModel'
import {OrderSteps, ProductsTypes, VisibleTypes} from '../../Interfaces/Enums'
import {StyledGrayButtonBig, StyledPrimaryButtonBig} from '../Style'
import ProductsInfos from '../ProductsInfos'
import IncludedProducts from '../IncludedProducts'
import CircleCheckbox from '../CircleCheckbox'
import CanBePurchasePackageModel from '../Models/CanBePurchasePackageModel'

const StyledTableTitle = styled.span`
  color: '#4B4B4B';
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`

export const NoticeText = styled.span`
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
  }

  & + div {
    margin-top: 50px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentBody = styled.div`
  margin-bottom: 180px;
  width: 100%;

  @media only screen and (max-width: 959.95px) {
    margin-bottom: 130px;
  }
`

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 28px;
  padding: 20px;
  width: calc(100% - 235px);
  height: 150px;
  background: white;
  box-shadow: -13px -4px 16px rgb(0 0 0 / 13%);
  position: fixed;
  bottom: 0;
  left: 235px;
  z-index: 1000;

  @media only screen and (max-width: 959.95px) {
    width: 100%;
    height: 100px;
    left: 0;
  }
`

interface HeadCell {
  id: string
  label: string
}

interface PackageTableProps {
  products?: CanBePurchaseProducts
  getPrice: (index: number) => string
  setCycle: (order: number, index: number) => void
  setPeriod: (order: number, index: number) => void
  setAmount: (order: number, index: number) => void
  getSelectedBillingCycle: (
    order: number,
  ) => SpreadableWithIcon & SpreadItemWithIcon
  getSelectedPeriod: (order: number) => SpreadableWithIcon & SpreadItemWithIcon
  getSelectedAmount: (order: number) => SpreadItemWithIcon
  setSelectBundle: (order: number) => void
  isBundleSelected: (order: number) => boolean
  setSelectPackage: (order: number) => void
  isPackageSelected: (order: number) => boolean
  disablePurchasedPackage: (index: number) => boolean
  disablePurchasedBundle: (index: number) => boolean
  isDuplicatedProduct: (
    index: number,
    compareItems: [AnalysisProduct],
    type: string,
  ) => void
  visibleType: string
}
interface PackageTableRowsProps extends PackageTableProps {}

interface PackageTableContainerProps extends PackageTableProps {
  setAllSelectedToggle: () => void
  isAllChecked: boolean
  isProductsLoading: boolean
  setVisibleType: React.Dispatch<React.SetStateAction<string>>
}

interface ProductsSelectProps {
  successCalback: (
    packages: PurchasePackage[],
    bundles: PurchaseBundle[],
    purchased: PurchasedProducts,
    overdue: UnPaidVoucherContainer,
  ) => void
  setSelectedPackages: React.Dispatch<React.SetStateAction<PurchasePackage[]>>
  setSelectedBundles: React.Dispatch<React.SetStateAction<PurchaseBundle[]>>
}

const headCells: HeadCell[] = [
  {id: 'serviceTypes', label: 'IIncludedProducts'},
  {id: 'purchaseTerms', label: 'IPurchaseCondition'},
  {id: 'cycle', label: 'IPaymentBillingCycle'},
  {id: 'period', label: 'IPaymentPeriod'},
  {id: 'point', label: 'ICount'},
  {id: 'serviceContent', label: 'IPrice'},
]

function PackageTableRows({
  products,
  getPrice,
  setCycle,
  setPeriod,
  setAmount,
  getSelectedBillingCycle,
  getSelectedPeriod,
  getSelectedAmount,
  setSelectBundle,
  isBundleSelected,
  setSelectPackage,
  isPackageSelected,
  disablePurchasedPackage,
  disablePurchasedBundle,
  isDuplicatedProduct,
  visibleType,
}: PackageTableRowsProps) {
  return (
    <>
      {products &&
        visibleType !== VisibleTypes.Bundles &&
        products.available.packages.map((item, idx) => (
          <RoundTableWrap key={idx}>
            <td style={{minWidth: 'unset'}}>
              <CircleCheckbox
                checked={isPackageSelected(idx)}
                onClick={() => {
                  setSelectPackage(idx)
                  if (!isPackageSelected(idx))
                    isDuplicatedProduct(
                      idx,
                      item.analyses,
                      ProductsTypes.Package,
                    )
                }}
                disabled={disablePurchasedPackage(idx)}
              />
            </td>
            <td>
              <ProductThumbnail
                type='Package'
                productName={item.name}
                theme={PackageThumbnailStyles}
              />
            </td>
            <td style={{verticalAlign: 'top'}}>
              {/* TODO: hasPdf는 가짜 데이터로 추후 api 변경 후 변경 필요 item.guide ? 같은게 있을 때만 호버 및 이벤트 추가 */}
              <ProductsInfos
                description={item.description ? item.description : '-'}
                hasPdf={false}
              >
                <IncludedProducts analyses={item.analyses} />
              </ProductsInfos>
            </td>
            <td>-</td>
            <td>
              <IconSelect
                thumb={getSelectedBillingCycle(idx).appearanceValueWithIcon()}
                spreadableWithIcon={item as CanBePurchasePackageModel}
                handler={(it, index) => {
                  setCycle(idx, index)
                }}
                theme={PaymentManagementStyles}
              />
            </td>
            <td>
              <IconSelect
                thumb={getSelectedPeriod(idx).appearanceValueWithIcon()}
                spreadableWithIcon={getSelectedBillingCycle(idx)}
                handler={(it, index) => {
                  setPeriod(idx, index)
                }}
                theme={PaymentManagementStyles}
              />
            </td>
            <td>
              <IconSelect
                thumb={getSelectedAmount(idx).appearanceValueWithIcon()}
                spreadableWithIcon={getSelectedPeriod(idx)}
                handler={(it, index) => {
                  setAmount(idx, index)
                }}
                theme={PaymentManagementStyles}
              />
            </td>
            <td>{`${item.currencyCode} ${getPrice(idx)}`}</td>
          </RoundTableWrap>
        ))}
      {products &&
        visibleType !== VisibleTypes.Packages &&
        products.available.bundles.map((item, idx) => (
          <RoundTableWrap key={idx}>
            <td style={{minWidth: 'unset'}}>
              <CircleCheckbox
                checked={isBundleSelected(idx)}
                onClick={() => {
                  setSelectBundle(idx)
                  if (!isBundleSelected(idx))
                    isDuplicatedProduct(
                      idx,
                      item.analyses,
                      ProductsTypes.Bundle,
                    )
                }}
                disabled={disablePurchasedBundle(idx)}
              />
            </td>
            <td>
              <ProductThumbnail
                type='Bundle'
                productName={item.name}
                theme={BundleThumbnailStyles}
              />
            </td>
            <td style={{verticalAlign: 'top'}}>
              {/* TODO: hasPdf는 가짜 데이터로 추후 api 변경 후 변경 필요 item.guide ? 같은게 있을 때만 호버 및 이벤트 추가 */}
              <ProductsInfos
                description={item.description ? item.description : '-'}
                hasPdf={false}
              >
                <IncludedProducts analyses={item.analyses} />
              </ProductsInfos>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>{item.priceSet.unitPurchase}</td>
            <td>{`${item.priceSet.currency} ${numberWithCommas(
              item.priceSet.price,
            )}`}</td>
          </RoundTableWrap>
        ))}
    </>
  )
}

function PackageSelectedTable({
  products,
  getPrice,
  setCycle,
  setPeriod,
  setAmount,
  getSelectedBillingCycle,
  getSelectedPeriod,
  getSelectedAmount,
  setSelectBundle,
  isBundleSelected,
  setSelectPackage,
  isPackageSelected,
  setAllSelectedToggle,
  isAllChecked,
  isProductsLoading,
  disablePurchasedPackage,
  disablePurchasedBundle,
  isDuplicatedProduct,
  visibleType,
  setVisibleType,
}: PackageTableContainerProps) {
  const {t} = useTranslation()

  // 상품 명 - 타입 선택해서 원하는 것 노출
  const visibleTypeLists = [t('IProductName'), t('IPackage'), t('IBundle')]

  return (
    <>
      <TableOverflowWrap>
        <PackageTable>
          <colgroup>
            <col style={{width: 40}} />
            <col style={{minWidth: 340, width: '20%'}} />
            <col style={{minWidth: 420, width: '20%'}} />
            <col style={{minWidth: 60, width: '5%'}} />
            <col style={{minWidth: 100, width: '10%'}} />
            <col style={{minWidth: 100, width: '10%'}} />
            <col style={{minWidth: 80, width: '8%'}} />
            <col style={{minWidth: 120, width: '12%'}} />
          </colgroup>
          <thead>
            <tr>
              <th
                style={{borderTopLeftRadius: '5px', backgroundColor: '#e9ecef'}}
              >
                <CircleCheckbox
                  checked={isAllChecked}
                  onClick={setAllSelectedToggle}
                />
              </th>
              <th style={{backgroundColor: '#e9ecef'}}>
                <Select
                  thumb={t(visibleType)}
                  options={visibleTypeLists}
                  handler={(it, index) => {
                    if (index === 0) setVisibleType(VisibleTypes.All)
                    else if (index === 1) setVisibleType(VisibleTypes.Packages)
                    else if (index === 2) setVisibleType(VisibleTypes.Bundles)
                  }}
                  theme={PaymentManagementStyles}
                />
              </th>
              {headCells.map((item, index) => (
                <CheckBoxRoundTableHeader
                  key={index}
                  label={t(item.label)}
                  length={headCells.length}
                  index={index}
                />
              ))}
            </tr>
          </thead>
          {!isProductsLoading && (
            <TableBody>
              <PackageTableRows
                products={products}
                getPrice={getPrice}
                setCycle={setCycle}
                setPeriod={setPeriod}
                setAmount={setAmount}
                getSelectedBillingCycle={getSelectedBillingCycle}
                getSelectedPeriod={getSelectedPeriod}
                getSelectedAmount={getSelectedAmount}
                setSelectBundle={setSelectBundle}
                setSelectPackage={setSelectPackage}
                isBundleSelected={isBundleSelected}
                isPackageSelected={isPackageSelected}
                disablePurchasedPackage={disablePurchasedPackage}
                disablePurchasedBundle={disablePurchasedBundle}
                isDuplicatedProduct={isDuplicatedProduct}
                visibleType={visibleType}
              />
            </TableBody>
          )}
        </PackageTable>
        {isProductsLoading ||
          (!products && !isProductsLoading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              {t('INoData')}
            </div>
          ))}
      </TableOverflowWrap>
    </>
  )
}

const ProductsSelectPage = ({
  successCalback,
  setSelectedPackages,
  setSelectedBundles,
}: ProductsSelectProps) => {
  const {t} = useTranslation()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {onChange: setOrderStep} = useOrgPurchase()

  const handleCancel = () => {
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: `${t('INoticeProductsSelectCancel')}`,
    }).then((result) => {
      if (result.payload) {
        setOrderStep(OrderSteps.Management)
      }
    })
  }

  const {
    products,
    getPrice,
    getProducts,
    setCycle,
    setPeriod,
    setAmount,
    setDefaultProducts,
    getSelectedBillingCycle,
    getSelectedPeriod,
    getSelectedAmount,
    setSelectBundle,
    isBundleSelected,
    setSelectPackage,
    isPackageSelected,
    setAllSelectedToggle,
    checkAllDefaultStatus,
    allSelectedProducts,
    isAllChecked,
    isProductsLoading,
    setAllPurchasedId,
    disablePurchasedPackage,
    disablePurchasedBundle,
    isDuplicatedProduct,
    visibleType,
    setVisibleType,
    isDisabledConfirmButton,
  } = useViewModel()

  const submitPackages = () => {
    if (products != null) {
      const {packages, bundles} = allSelectedProducts()
      // CYM: 구매 조건 미충족 알람 팝업
      // 구매조건 미충족은 아직 기획 단계에서 완성되지 않아 보류 처리
      // const items = packages
      //   .map((item) => {
      //     return item.name
      //   })
      //   .concat(
      //     bundles.map((item) => {
      //       return item.name
      //     }),
      //   )
      // const itemText = items.join(', ')
      const itemText = ''
      successCalback(packages, bundles, products.purchased, products.overdue)

      // CYM: 구매 조건 미충족 알람 팝업 노출을 위한 temp result data
      const result = true
      if (result) {
        // handleClose()
        // dispatch(openPackagePurchaseDialog())
        setOrderStep(OrderSteps.ProductsCheck)
      } else {
        onNoticeDialogOpen({
          title: t('IUnfulfilledPurchaseCondition'),
          message: t('INoticeUnfulfilledPurchaseCondition', {
            productsList: itemText,
          }),
        })
      }
    }
  }

  useEffect(() => {
    getProducts()
    setDefaultProducts()
    checkAllDefaultStatus()

    // 처음 접속시 선택한 상품 비워주기
    setSelectedPackages([])
    setSelectedBundles([])
  }, [])

  // for disabled allPurchasedId set
  useEffect(() => {
    if (products) {
      setAllPurchasedId((prev) => {
        let newData = [...prev]
        newData = [
          ...products.purchased.packages.map((item) => item.packageId),
          ...products.purchased.bundles.map((item) => item.bundleId),
        ]
        return newData
      })
    }
  }, [products])

  return (
    <CardWithTitle style={{minHeight: 300}}>
      <ContentContainer style={{overflow: 'hidden'}}>
        <ContentWrap>
          <ContentHeader>
            <StyledTableTitle>{t('IProductsSelectTitle')}</StyledTableTitle>
            {products &&
              products.available.packages[0].currencyCode === '$' && (
                <NoticeText>
                  {t('INoticeOneTimePaymentLimit', {amount: '$ 36,000'})}
                </NoticeText>
              )}
          </ContentHeader>
          <ContentBody>
            <PackageSelectedTable
              products={products}
              getPrice={getPrice}
              setCycle={setCycle}
              setPeriod={setPeriod}
              setAmount={setAmount}
              getSelectedBillingCycle={getSelectedBillingCycle}
              getSelectedPeriod={getSelectedPeriod}
              getSelectedAmount={getSelectedAmount}
              setSelectBundle={setSelectBundle}
              setSelectPackage={setSelectPackage}
              isBundleSelected={isBundleSelected}
              isPackageSelected={isPackageSelected}
              isAllChecked={isAllChecked}
              setAllSelectedToggle={setAllSelectedToggle}
              isProductsLoading={isProductsLoading}
              disablePurchasedPackage={disablePurchasedPackage}
              disablePurchasedBundle={disablePurchasedBundle}
              isDuplicatedProduct={isDuplicatedProduct}
              visibleType={visibleType}
              setVisibleType={setVisibleType}
            />
          </ContentBody>
          <ContentFooter>
            <StyledGrayButtonBig onClick={handleCancel}>
              {t('ISelectCancel')}
            </StyledGrayButtonBig>
            <StyledPrimaryButtonBig
              onClick={submitPackages}
              disabled={isDisabledConfirmButton}
            >
              {t('ISelectConfirm')}
            </StyledPrimaryButtonBig>
          </ContentFooter>
        </ContentWrap>
      </ContentContainer>
    </CardWithTitle>
  )
}

export default ProductsSelectPage
