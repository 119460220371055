import React from 'react'

const CheckIcon = ({color = '#EDEDED'}: {color: string}) => {
  return (
    <svg
      width='31'
      height='23'
      viewBox='0 0 31 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3147 23.007L0 11.6897L3.77067 7.919L11.3147 15.4603L26.3973 0.375L30.1707 4.14833L11.3147 23.007Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckIcon
