import request from 'api/index'

export interface PointListRequest {
  oid?: string | null
  page: number
  size: number
  uid?: string | null
  searchName?: string | null
  searchValue?: string | null
  period?: PeriodString | null
  startDate?: string
  endDate?: string
}

export const fetchPointListApi = (query: PointListRequest) =>
  request<ModelResponse<Point>>({
    method: 'get',
    path: '/api/point/v1/list',
    params: query,
  })

export interface PointSaveRequest {
  adminUid?: string | null
  oid: string
  point: number
  pointType: string | null
  product: string | null
  remark: string | null
}

export type PointType =
  | 'NONE'
  | 'USE_POINT'
  | 'GIVE_POINT'
  | 'DEDUCT_POINT'
  | 'FREE_POINT'

export const savePointApi = (data: PointSaveRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/point/v1/save',
    data,
  })

export const consumePointApi = (data: PointSaveRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/point/v1/consume',
    data,
  })

export interface PointInfoRequest {
  uid: string
  oid: string
  month: string | null
}

export const fetchPointInfoApi = (query: PointInfoRequest) =>
  request<ModelResponse<PointInfo>>({
    method: 'get',
    path: '/api/point/v1/info',
    params: query,
  })

export interface ProductPackageList {
  adminUid?: string
  oid: string
}

export interface PackageInfo {
  myPackage: Package[]
  packageList: Package[]
}

export const fetchProductPackageListApi = (params: ProductPackageList) =>
  request<ModelResponse<PackageInfo>>({
    method: 'get',
    path: '/api/manager/v1/product/package/list',
    params,
  })

export interface PointPackageInfoRequest {
  uid: string
  oid: string
}

export const fetchPointPackageInfoApi = (params: PointPackageInfoRequest) =>
  request<ModelResponse<PointPackageInfo>>({
    method: 'get',
    path: '/api/manager/v1/org/package/detail-info',
    params,
  })

export interface PointListDownloadRequest {
  oid?: string | null
  uid?: string | null
  searchName?: string | null
  searchValue?: string | null
  startDate?: string | null
  endDate?: string | null
}

export const downloadPointListApi = (params: PointListDownloadRequest) =>
  request<ModelsResponse<Point>>({
    method: 'get',
    path: '/api/point/v1/download/excel',
    params,
  })

export interface UpdatePointPackageRequest {
  oid: string
  eegPackage: string
  hrvPackage: string
}

export const updatePointPackageApi = (data: UpdatePointPackageRequest) =>
  request<ModelsResponse<Point>>({
    method: 'post',
    path: '/api/manager/v1/product/package/update',
    data,
  })

export interface FetchProductInfoRequest {
  uid: string
}

export const fetchProductInfoListApi = (params: FetchProductInfoRequest) =>
  request<ModelsResponse<ProductInfo>>({
    method: 'get',
    path: '/api/point/v1/product/detail-info',
    params,
  })
