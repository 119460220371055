import ForbiddenPage from 'pages/ErrorPages/ForbiddenPage'
import InternalErrorPage from 'pages/ErrorPages/InternalErrorPage'
import NotFoundPage from 'pages/ErrorPages/NotFoundPage'
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteConstant from 'constants/RouteConstant'

/**
 * 에러 라우트
 */
const ErrorPages = () => {
  return (
    <Switch>
      <Route
        path={RouteConstant.ERROR_NOT_FOUND.path}
        component={NotFoundPage}
      />
      <Route
        path={RouteConstant.ERROR_FORBIDDEN.path}
        component={ForbiddenPage}
      />
      <Route
        path={RouteConstant.ERROR_NOT_FOUND.path}
        component={InternalErrorPage}
      />
    </Switch>
  )
}

export default ErrorPages
