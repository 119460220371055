import {
  CommentAddRequest,
  CommentDeleteRequest,
  CommentPageRequest,
} from 'api/commentApi'
import {
  closeCommentWriteDialog,
  openCommentWriteDialog,
  selectDialogOpen,
  selectData,
  selectUuid,
  selectQuery,
  fetchCommentPage,
  createComment,
  deleteComment,
  selectPaging,
  selectLoading,
  selectError,
} from 'features/comment/commentSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export function usePagination() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const uuid = useAppSelector(selectUuid)
  const comments = useAppSelector(selectData)
  const query = useAppSelector(selectQuery)
  const paging = useAppSelector(selectPaging)

  const onFetchPage = (payload: CommentPageRequest) =>
    dispatch(fetchCommentPage(payload))

  const onCreate = (payload: CommentAddRequest) =>
    dispatch(createComment(payload))

  const onDelete = (payload: CommentDeleteRequest) =>
    dispatch(deleteComment(payload))

  return {
    loading,
    error,
    uuid,
    comments,
    query,
    paging,
    onFetchPage,
    onCreate,
    onDelete,
  }
}

export function useWriteDialog() {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(selectDialogOpen)
  const uuid = useAppSelector(selectUuid)

  const onOpen = (uuid: string) => dispatch(openCommentWriteDialog(uuid))

  const onClose = () => dispatch(closeCommentWriteDialog())

  const onCreate = (payload: CommentAddRequest) =>
    dispatch(createComment(payload))

  return {
    uuid,
    open: isOpen,
    onOpen,
    onClose,
    onCreate,
  }
}

export default usePagination
