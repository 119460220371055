import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'
import useStyles from 'components/molcules/Report/OccipitalAlphaPeak.style'
import ReportHeader from 'components/Report/ReportHeader'
import React from 'react'

interface OccipitalAlphaPeakProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
}

function OccipitalAlphaPeak({indicator, items}: OccipitalAlphaPeakProps) {
  const classes = useStyles()

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />

      {items.map((item) => (
        <img
          key={item.imgPath}
          src={item.imgPath}
          className={classes.horizontalImage}
          alt='OccipitalAlphaPeak'
        />
      ))}
    </div>
  )
}

export default OccipitalAlphaPeak
