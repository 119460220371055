import request, {fileRequest} from 'api/index'
import {OrgDetailInfoEditRequest} from 'api/organizationApi'
import {OrgUserCreateRequest} from 'api/userApi'
import {newNormalRequest} from './index'

export interface SalesIndexSearchRequest {
  index?: string | null
  uid?: string | null
}
export interface SalesDetailSearchRequest {
  oid?: string
  uid?: string
}
export interface SalesRegisteredUserSearchRequest {
  oid: string
  adminUid?: string | null
  searchName?: string | null
  searchValue?: string | null
}

export const fetchSalesIndexSearchApi = (query: SalesIndexSearchRequest) =>
  request<ModelsResponse<SalesIndexSearchedData>>({
    method: 'get',
    path: 'api/manager/v1/org/list',
    params: query,
  })

export const fetchSalesDetailSearchApi = (query: SalesDetailSearchRequest) =>
  request<ModelResponse<SalesDetailSearch>>({
    method: 'get',
    path: '/api/manager/v1/org/detail',
    params: query,
  })

export const searchSalesRegisteredUserApi = (
  query: SalesRegisteredUserSearchRequest,
) =>
  request<ModelResponse<SalesDetailSearch>>({
    method: 'get',
    path: '/api/manager/v1/org/user-search/list',
    params: query,
  })

export const updateSalesOrganizationApi = (data: OrgDetailInfoEditRequest) =>
  request<ModelResponse<CommonResponse>>({
    method: 'post',
    path: '/api/manager/v2/org/update',
    data,
  })

export interface SalesUserUpdateRequest
  extends Partial<Omit<OrgUserCreateRequest, 'division' | 'position'>> {
  uid: string
  divisionId?: number | null
  positionId?: number | null
  authority?: string | null
}
export const updateOrgUserDetailInfoApi = (data: SalesUserUpdateRequest) => {
  const {profile, ...params} = data

  return fileRequest<CommonResponse>({
    method: 'post',
    path: 'api/manager/v1/org/user/update',
    data: {
      profile,
    },
    params,
  })
}

export interface UserLogListRequestDto {
  page: number
  size: number
  uid?: string | null
  searchName?: string | null
  searchValue?: string | null
  startDate?: string
  endDate?: string
  oid?: string | null
}

export interface UserLogListRequest {
  paging?: Paging | null
  uid?: string | null
  search?: NormalizedSearch | null
  period?: PeriodString | null
  oid?: string | null
}

export const fetchSalesUserLogListApi = (query: UserLogListRequestDto) =>
  request<ModelResponse<SalesUserLog>>({
    method: 'get',
    path: '/api/log/v1/list',
    params: query,
  })

export const fetchAllOrganizationListApi = (query: UserLogListRequest) =>
  request<ModelResponse<AllOrganization>>({
    method: 'get',
    path: '/api/org/v1/all',
    params: {...query.search, ...query.paging, ...query.period},
  })

export interface PageManagementRequest {
  page: number
  searchName?: string | null
  searchValue?: string | null
  size: number
}

export interface MenuList {
  content: Menu[]
}

export interface Menu {
  depth: number
  name: string
  useYn: string
  id?: number | null
  parentId?: number | null
}

export interface FetchMenuList {
  oid: string
  orgName: string
  menuList: Menu[]
}

export interface UpdateMenuRequest {
  oid: string
  menuList: Menu[]
}

export const fetchPageManagementListApi = (query: PageManagementRequest) =>
  request<PageResponse<FetchMenuList>>({
    method: 'get',
    path: '/api/manager/v2/menu/list',
    params: query,
  })

export const updatePageManagementApi = (data: UpdateMenuRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/manager/v2/menu/update',
    data,
  })

export interface InstituteListRequest {
  begin?: string
  end?: string
  searchValue?: string
  page: number
  size: number
}

export const fetchInstituteListApi = (query: InstituteListRequest) =>
  newNormalRequest<any>({
    method: 'get',
    path: 'organization/v1.0/',
    params: query,
  })

export interface InstituteListPackagePriceList {
  oid: string
}

// 전체 패키지 조회
export const fetchInstitutePackagePriceListApi = () =>
  newNormalRequest<any>({
    method: 'get',
    path: 'payments/v1.0/packages',
  })

export interface PersonalPackagePriceListRequest {
  packageId: number
  orgUid: string
  page: number
  size: number
}

export const fetchPackagePriceListApi = (
  query: PersonalPackagePriceListRequest,
) =>
  newNormalRequest<any>({
    method: 'delete',
    path: '/payments/v1.0/customize_price_package',
    params: query,
  })

export interface FetchUsageListRequest {
  beginDate: string
  endDate: string
  ignore_free: boolean
  for_download: boolean
}
export const fetchUsageListApi = (query: FetchUsageListRequest) =>
  newNormalRequest<any>({
    method: 'get',
    path: '/payments/v1.0/use/history/',
    params: query,
  })

export interface FetchPeriodUsageListRequest {
  beginDate: string
  endDate: string
  ignoreFree: boolean
  forDownload: boolean
  page?: number
  size?: number
}

export interface PeriodUsage {
  period: string
  organizationName: string
  email: string
  organizationProperty: string
  joinStatus?: string
  country?: string
  waveCount?: number
  eegIndividualCount?: number
  individual3dRoiCount?: number
  eegNormCount?: number
  eegNormSummaryCount?: number
  Norm3dRoiCount?: number
  mciCount?: number
  eegSummaryCount?: number
  eegAdvancedSummaryCount?: number
  hrvIndividualCount?: number
  hrvSummaryCount?: number
  hrvAdvancedSummaryCount?: number
  edfCount?: number
  ledCount?: number
}
export interface FetchListResponse {
  items: PeriodUsage[]
  total: number
  page: number
  size: number
}

export const fetchPeriodUsageListApi = (query: FetchPeriodUsageListRequest) =>
  newNormalRequest<FetchListResponse>({
    method: 'get',
    path: '/payments/v1.0/use/history/each/',
    params: query,
  })

export interface FetchPersonalListRequest {
  beginDate: string
  endDate: string
  ignoreFree: boolean
  forDownload: boolean
  page: number
  size: number
}

export interface PersonalUsage {
  beginDate: string
  endDate: string
  orgName: string
  email: string
  attribute: string
  countryCode: string
  countOfProducts: any
}
export interface FetchPersonalListResponse {
  items: PersonalUsage[]
  total: number
  page: number
  size: number
}
export const fetchPersonalUsageListApi = (query: FetchPersonalListRequest) =>
  newNormalRequest<FetchPersonalListResponse>({
    method: 'get',
    path: '/payments/v1.0/use/history/range/',
    params: query,
  })
