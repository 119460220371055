import React, {ReactNode} from 'react'
import styled from 'styled-components'
import Colors from 'theme/Colors'
import Media from 'theme/Media'
import {ModalTypes, ModalAllStyleProps, ModalCommonStyleProps} from '../Styles'

interface ModalBodyProps {
  children: ReactNode
  theme: ModalAllStyleProps
  modalType?: ModalTypes
}

const StyledModalBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: visible;
`
const StyledOverflowWrap = styled.div<
  ModalCommonStyleProps & {modalType?: ModalTypes}
>`
  padding-top: ${(props) =>
    props.modalType === ModalTypes.state
      ? `${props.paddingTopMobile}px`
      : null};
  padding-right: ${(props) => props.paddingRightMobile}px;
  padding-left: ${(props) => props.paddingLeftMobile}px;

  ${(props) => Media.min_601`
    padding-top: ${
      props.modalType === ModalTypes.state
        ? `${props.paddingTopTablet}px`
        : null
    };
    padding-right: ${props.paddingRightTablet}px;
    padding-left: ${props.paddingLeftTablet}px;
  `}

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.common.grays.g_02};
  }
`

function ModalBody({children, theme, modalType}: ModalBodyProps) {
  return (
    <StyledModalBodyWrap>
      <StyledOverflowWrap
        paddingRightTablet={theme.paddingRightTablet}
        paddingRightMobile={theme.paddingRightMobile}
        paddingLeftTablet={theme.paddingLeftTablet}
        paddingLeftMobile={theme.paddingLeftMobile}
        paddingTopTablet={theme.paddingTopTablet}
        paddingTopMobile={theme.paddingTopMobile}
        modalType={modalType}
      >
        {children}
      </StyledOverflowWrap>
    </StyledModalBodyWrap>
  )
}

export default ModalBody
