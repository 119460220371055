import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {Redirect, useHistory, useLocation} from 'react-router-dom'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {makeStyles} from '@material-ui/core/styles'
import FirstLoginDialog from 'components/Dialog/FirstLoginDialog'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import LoginPageFooter from 'pages/AuthPages/VerificationLogin/loginFooter'
import {AppHistoryState} from 'AppHistory'
import RouteConstant from 'constants/RouteConstant'
import {isDefined} from 'helpers/commonHelper'
import * as storage from 'api/storageApi'
import OldLogo from 'assets/img/isyncbrain_logo.png'
import styles from 'pages/AuthPages/VerificationLogin/Style'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'

const useStyles = makeStyles(styles)

interface TwoFactorFormInput {
  authCode: number
  email: string
  twoFactorId: number
}

const VerificationPage = () => {
  const twoFactorType = JSON.parse(storage.getTwoFactorType() ?? '{}')
  const history = useHistory()
  const {onOpen} = useFailureModal()
  const {onVerifyCode, user} = useAuth()
  const [seconds, setSeconds] = useState(180)
  const {t} = useTranslation()
  const classes = useStyles()

  const LoginSchema = yup.object().shape({
    authCode: yup
      .string()
      .required(t('IAuthedCodeRequired'))
      .max(6, t('IAuthCodeLength')),
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<TwoFactorFormInput>({
    // @ts-ignore
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = async (data: TwoFactorFormInput) => {
    try {
      await onVerifyCode({
        ...data,
        email: twoFactorType.mail,
        twoFactorId: twoFactorType.id,
      })
    } catch (err) {
      await onOpen(err.message)
    }
  }
  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1)
      storage.setInputTimeout(seconds.toString())
    }, 1000)

    if (seconds <= 0) {
      storage.removeInputTimeout()
      storage.removeTwoFactorType()
      clearInterval(interval)
      history.push(RouteConstant.LOGIN.path)
    }

    return () => clearInterval(interval)
  }, [seconds])

  useEffect(() => {
    const time = storage.getInputTimeout()
    if (Number(time) > 0) {
      storage.removeInputTimeout()
      storage.removeTwoFactorType()
      history.push(RouteConstant.LOGIN.path)
    }
  }, [])
  const location = useLocation<AppHistoryState>()
  const from = location?.state?.from ?? {
    pathname: TYPE_WHITE_LIST.every((item) =>
      JSON.parse(storage.getFeatures() ?? '[]').includes(item),
    )
      ? RouteConstant.ANALYSIS_HEALTH_CENTER.path
      : RouteConstant.HOME.path,
  }

  if (isDefined(user)) {
    storage.removeTwoFactorType()
    storage.removeInputTimeout()
    return <Redirect to={from} />
  }
  return (
    <div className={classes.root}>
      <div className='Container'>
        <div className='Logo'>
          <img src={OldLogo} alt='' />
        </div>
        <div className='Header'>
          <p className='Title'>iSyncBrain®-C/M</p>
          <div className='Desc'>
            Advanced EEG Analysis Platform: cloud-based, AI-driven,
            norm-matched, gender/age-specific
          </div>
          <div className='InputTimeout'>
            {t('IInputTimeOut')}: {formatTime(seconds)}
          </div>
        </div>
        <form className='Form' onSubmit={handleSubmit(onSubmit)}>
          <div className='FormControl'>
            <input
              type='text'
              placeholder={t('IAuthenticationCode')}
              {...register('authCode')}
            />
            {errors.authCode && (
              <div className='FormError'>{errors.authCode?.message}</div>
            )}
          </div>
          <button type='submit'>{t('ICertificationConfirmation')}</button>
        </form>
        <p className='Copyright'>Copyright iSyncBrain Co.,Ltd ©2021</p>
      </div>
      <LoginPageFooter />

      <FirstLoginDialog />
    </div>
  )
}
export default VerificationPage
