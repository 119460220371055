import {EEGResult, fetchNormativeEEGResult} from 'api/analysisApi'
import Fetcher, {FetcherEmpty} from 'components/Fetcher/Fetcher'
import {CodeTypeMap, AnalysisDivision} from 'constants/AnalysisConstant'
import React, {ReactElement} from 'react'

interface NormativeEEGFetcherProps {
  id: number
  children: (data: EEGResult) => ReactElement
}

export default function NormativeEEGFetcher({
  id,
  children,
}: NormativeEEGFetcherProps) {
  return (
    <Fetcher<ModelsResponse<EEGResult>>
      fetchFn={fetchNormativeEEGResult}
      params={[
        {
          normRequestIds: [id],
          codeType: CodeTypeMap.Individual,
          codeTypeDivision: 'EEG' as AnalysisDivision,
          codeVersion: '3.0',
        },
      ]}
    >
      {(data) => {
        const [eegResult] = data.list
        if (eegResult === undefined) return <FetcherEmpty />

        return children(eegResult)
      }}
    </Fetcher>
  )
}
