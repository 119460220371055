import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {SearchedHealthCare} from 'api/healthCareApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import PeriodSearch from 'components/molcules/PeriodSearch'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useHealthCareWhiteList from 'features/healthCare/useHealthCareWhiteList'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDateOneDay,
} from 'helpers/dateHelper'
import DownloadSearch from 'pages/HealthCenter/PageTableWhiteList/ItemDownloadSearch'
import HealthCareSearchComponent from 'pages/HealthCenter/PageTableWhiteList/HealthCareSearch'
import useFailureModal from 'features/modal/useFailureModal'

interface TableToolBarProps {
  selectedItems: SearchedHealthCare[]
  statusDownload: boolean
  loadingExcel: boolean
  onDownload: (hcIds: number[]) => void
  onDownloadExcel: (hcIds: number[]) => void
}

function TableToolbar({
  selectedItems = [],
  statusDownload,
  loadingExcel,
  onDownload,
  onDownloadExcel,
}: TableToolBarProps) {
  const location = useLocation()
  const {t, i18n} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const classes = useToolbarStyles()

  const {query, onSearch} = useHealthCareWhiteList()
  const [selection, setSelection] = useState<PeriodDate>(
    getDefaultPeriodDateOneDay(),
  )

  const handleSearch = (search: any) => {
    return onSearch({...query, ...search, page: 0})
  }
  const handleCheckDownloadedSearch = (value: any) => {
    return onSearch({...query, ...value, page: 0})
  }
  const handlePeriod = (period: PeriodDate) => {
    const formatPeriod = dateToPeriodString(period)
    if (
      formatPeriod.startDate === query.startDate &&
      formatPeriod.endDate === query.endDate
    )
      return
    onSearch({
      ...query,
      page: 0,
      ...dateToPeriodString(period),
    } as any)
  }

  const downloadFile = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(blobUrl)
      })
      .catch((error) => console.error('Error downloading file:', error))
  }

  const handleDownload = (
    pathPDF: string,
    name: string,
    birth: string,
    analysisNo: string | number,
  ) => {
    if (!pathPDF) return
    const filename = `${name}_${birth.split('-').join('')}_${analysisNo}.pdf`
    const path = `${pathPDF}?t=${Date.now()}`
    downloadFile(path, filename)
  }

  const handlePdfDownloadClick = () => {
    if (!selectedItems) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    selectedItems.forEach((item) => {
      const fullNamePatient = t('IGetFullName', {
        firstName: item.patientFirstName ?? '',
        lastName: item.patientLastName ?? '',
      })
        .split(' ')
        .join('_')
      handleDownload(
        item.reportUrl,
        fullNamePatient,
        item.patientBirth,
        item.hcId,
      )
    })
    onDownload(selectedItems?.map((item) => item.hcId) || [])
  }
  const handleExcelDownloadClick = () => {
    if (!selectedItems) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    selectedItems.forEach((item) => {
      const fullNamePatient = t('IGetFullName', {
        firstName: item.patientFirstName ?? '',
        lastName: item.patientLastName ?? '',
      })
        .split(' ')
        .join('_')
      handleDownload(
        item.reportUrl,
        fullNamePatient,
        item.patientBirth,
        item.hcId,
      )
    })
    onDownloadExcel(selectedItems?.map((item) => item.hcId) || [])
  }

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    setSelection({
      startDate: new Date(initQuery.startDate),
      endDate: new Date(initQuery.endDate),
    })
    onSearch({
      ...query,
      ...dateToPeriodString(selection),
      patientName: '',
      patientVisitNo: '',
      patientChartNo: '',
      birth: '',
      downloaded: '',
      ...initQuery,
    })
  }, [])

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.container}>
          <PeriodSearch period={selection} onSearch={handlePeriod} />
          <DownloadSearch onSearch={handleCheckDownloadedSearch} />
          <HealthCareSearchComponent onSearch={handleSearch} />
        </div>
        <div className={classes.containerOptional}>
          <ActionButton
            onClick={handlePdfDownloadClick}
            className={classes.flexRight}
            disabled={
              selectedItems.length === 0 ||
              selectedItems.every((item) => {
                return item.hcStatus === 'ERROR'
              }) ||
              statusDownload
            }
          >
            {t('IPdfDownload')}
          </ActionButton>
          {i18n.language === 'ko' && (
            <ActionButton
              onClick={handleExcelDownloadClick}
              className={classes.flexRight}
              disabled={
                selectedItems.length === 0 ||
                selectedItems.every((item) => {
                  return item.hcStatus === 'ERROR'
                }) ||
                loadingExcel
              }
            >
              {t('IExcelDownload')}
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default TableToolbar
