import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {makeFullMonthName} from 'helpers/dateHelper'
import {SvgIcon} from 'components/common/useSvgIcons'
import {
  Root,
  IconBgWrap,
  StyledContentContainer,
  StyledContentWrap,
  StyledFullLine,
  StyledTextGroup,
  StyledTextBox,
  StyledKeyText,
  StyledValueText,
} from '../Style'
import UnpaidVouchersModel from '../Models/UnpaidVouchersModel'
import UsageHistoryAndUnpaidVouchersModel from '../Models/UsageHistoryAndUnpaidVouchersModel'

interface PaymentProps {
  isUnpaidVouchersListLoading: boolean
  unpaidVouchersList?: UnpaidVouchersModel[]
  usageHistoryAndUnpaidVouchersList?: UsageHistoryAndUnpaidVouchersModel
}

const StyledBackgroundWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 50px;
  width: 100%;
  background-color: #f7f7f8;
  border-radius: 4px;
  position: relative;
`

const Payment = (props: PaymentProps) => {
  const {
    isUnpaidVouchersListLoading: isListLoading,
    unpaidVouchersList,
    usageHistoryAndUnpaidVouchersList,
  } = props
  const {t, i18n} = useTranslation()
  const [currentLocale, setCurrentLocale] = React.useState<string>('ko')

  useEffect(() => {
    setCurrentLocale(i18n.language)
  }, [i18n.language])

  const hasData =
    !isListLoading && unpaidVouchersList && unpaidVouchersList.length > 0

  return (
    <Root>
      <StyledBackgroundWrap className='styled-payment'>
        <StyledContentContainer>
          {!hasData && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
              }}
            >
              {t('INoData')}
            </div>
          )}
          {hasData && (
            <>
              <StyledContentWrap>
                <StyledTextGroup>
                  {unpaidVouchersList.map((item, idx) => {
                    if (idx !== 0) {
                      return (
                        <div key={idx}>
                          <IconBgWrap>
                            <SvgIcon iconColor='#444B52' name='plusFill' />
                          </IconBgWrap>
                          <StyledTextBox>
                            <StyledKeyText>
                              {`${makeFullMonthName(
                                item.dueDate,
                                currentLocale,
                              )} ${t('IUnpaidAmount')}`}
                            </StyledKeyText>
                            <StyledValueText>
                              {item.getPriceWithCurrencyCode()}
                            </StyledValueText>
                          </StyledTextBox>
                        </div>
                      )
                    }
                    return (
                      <StyledTextBox key={idx}>
                        <StyledKeyText>
                          {`${makeFullMonthName(
                            item.dueDate,
                            currentLocale,
                          )} ${t('IUnpaidAmount')}`}
                        </StyledKeyText>
                        <StyledValueText>
                          {item.getPriceWithCurrencyCode()}
                        </StyledValueText>
                      </StyledTextBox>
                    )
                  })}
                </StyledTextGroup>
                <StyledFullLine />
                <StyledTextGroup>
                  <IconBgWrap>
                    <SvgIcon iconColor='#444B52' name='equalFill' />
                  </IconBgWrap>
                  <StyledTextBox>
                    <StyledKeyText>{t('ITotalAmountShort')}</StyledKeyText>
                    <StyledValueText>
                      {usageHistoryAndUnpaidVouchersList &&
                        usageHistoryAndUnpaidVouchersList.getUnpaidVouchersTotalPrice()}
                    </StyledValueText>
                  </StyledTextBox>
                </StyledTextGroup>
              </StyledContentWrap>
            </>
          )}
        </StyledContentContainer>
      </StyledBackgroundWrap>
    </Root>
  )
}
export default Payment
