import {faChartArea, faCogs} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {updateIcaApi} from 'api/analysisApi'
import {Colors} from 'components/common/useBaseStyle'
import useFailureModal from 'features/modal/useFailureModal'
import {range} from 'helpers/commonHelper'
import CheckBox from 'components/Checkbox'
import {PrimaryButton} from 'components/CustomButtons/PrimaryButton'
import {PrimaryOutlineButton} from 'components/CustomButtons/PrimaryOutlineButton'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const StyledIcaSettings = styled.form`
  & .CheckAll {
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    border-bottom: 1px solid ${Colors.border};
  }

  & .Components {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  & .Index {
    display: flex;
    gap: 5px;
  }
`

interface IcaSettingsProps {
  requestId: number
  components: number[]
  onProcess: () => void
}

function IcaSettings({
  components = [],
  requestId,
  onProcess,
}: IcaSettingsProps) {
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [checkedComponents, setCheckedComponents] = useState<number[]>([])

  useEffect(() => {
    setCheckedComponents([...components])
  }, [components])

  const handleSelect = (component: number) => {
    const newComponents = checkedComponents?.includes(component)
      ? checkedComponents.filter(
          (checkedComponent) => checkedComponent !== component,
        )
      : [...(checkedComponents ?? []), component]
    setCheckedComponents(newComponents)
    return newComponents
  }

  const handleSelectAll = () => {
    const isAllChecked = checkedComponents.length === 19
    if (isAllChecked) {
      setCheckedComponents([])
    } else {
      setCheckedComponents([...range(1, 19)])
    }
  }

  const handlePreviewClick = () => {
    if (checkedComponents.length === 19) {
      onFailureModalOpen(t('IComponentLimitOver'))
      return
    }

    updateIcaApi({
      icaType: 'P',
      requestId,
      componentType: checkedComponents,
    })
      .then(() => {
        // dispatch(openSuccessAlert(t('ISuccess')))
        onProcess()
      })
      .catch((err) => onFailureModalOpen(err.message))
  }

  const handlePostProcessClick = () => {
    if (checkedComponents.length === 19) {
      onFailureModalOpen(t('IComponentLimitOver'))
      return
    }

    updateIcaApi({
      icaType: 'C',
      requestId,
      componentType: checkedComponents,
    })
      .then(() => {
        // dispatch(openSuccessAlert(t('ISuccess')))
        onProcess()
      })
      .catch((err) => onFailureModalOpen(err.message))
  }

  return (
    <StyledIcaSettings>
      <div className='Header'>
        <h3 className='Title'>{t('IIcaComponentRejection')}</h3>
      </div>
      <div className='Contents'>
        <CheckBox
          className='CheckAll'
          checked={checkedComponents.length === 19}
          onChange={() => handleSelectAll()}
        >
          {t('ISelectAll')}
        </CheckBox>
        <div className='Components'>
          {range(1, 19).map((num) => (
            <CheckBox
              key={num}
              isArtifact={components.includes(num)}
              name='components'
              value={num}
              checked={checkedComponents.includes(num)}
              onChange={() => handleSelect(num)}
            >
              {t('IComponentNumber', {number: num})}
            </CheckBox>
          ))}
        </div>
      </div>
      <div className='Footer'>
        <PrimaryOutlineButton onClick={handlePreviewClick} type='button'>
          <FontAwesomeIcon className='Icon' icon={faChartArea} />
          {t('IPreview')}
        </PrimaryOutlineButton>{' '}
        <PrimaryButton onClick={handlePostProcessClick}>
          <FontAwesomeIcon className='Icon' icon={faCogs} />
          {t('IPostProcessing')}
        </PrimaryButton>
      </div>
    </StyledIcaSettings>
  )
}

export default IcaSettings
