import {IDBPDatabase, openDB} from 'idb'

class IndexedDb {
  private readonly database: string

  private db: any

  constructor(database: string) {
    this.database = database
  }

  public async createObjectStore(tableNames: string[]) {
    try {
      this.db = await openDB(this.database, 1, {
        upgrade(db: IDBPDatabase) {
          tableNames.forEach((tableName) => {
            if (!db.objectStoreNames.contains(tableName)) {
              db.createObjectStore(tableName)
            }
          })
        },
      })
    } catch (error) {
      console.error('createObjectStore.error : ', error)
    }
  }

  public async getValue(tableName: string, id: number | string) {
    const tx = this.db.transaction(tableName, 'readonly')
    const store = tx.objectStore(tableName)
    const result = await store.get(id)
    return result
  }

  public async getAllValue(tableName: string) {
    const tx = this.db.transaction(tableName, 'readonly')
    const store = tx.objectStore(tableName)
    const result = await store.getAll()
    return result
  }

  public async putValue(tableName: string, value: any, key: string | number) {
    const tx = this.db.transaction(tableName, 'readwrite')
    const store = tx.objectStore(tableName)
    const result = await store.put(value, key)
    return result
  }
}

export default IndexedDb

const INDEXED_DB_FILE_KEY = 'iSyncBrainFiles'
const INDEXED_DB_EDF_STORE_KEY = 'eegSource'

export const setEdfByPath = async (path: string, edf: string) => {
  const indexedDb = new IndexedDb(INDEXED_DB_FILE_KEY)
  await indexedDb.createObjectStore([INDEXED_DB_EDF_STORE_KEY])
  await indexedDb.putValue(INDEXED_DB_EDF_STORE_KEY, edf, path)
}

export const getEdfByPath = async (path: string) => {
  const indexedDb = new IndexedDb(INDEXED_DB_FILE_KEY)
  await indexedDb.createObjectStore([INDEXED_DB_EDF_STORE_KEY])
  const cachedPlayListAudioFiles = await indexedDb.getValue(
    INDEXED_DB_EDF_STORE_KEY,
    path,
  )
  return cachedPlayListAudioFiles
}
