import {makeStyles, Theme} from '@material-ui/core/styles'

const useHealthCenterStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  positionTopLet: {
    top: 0,
    left: 0,
    width: '50%',
  },
  positionTopRight: {
    top: 0,
    right: 0,
    width: '50%',
  },

  positionBotLeft: {
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titlePositionLeft: {
    position: 'absolute',
    right: '5px',
    width: '27px',
  },
  titlePositionRight: {
    position: 'absolute',
    left: '18px',
    width: '27px',
  },
  percentBarV2Bot: {
    position: 'relative',
    width: '280px',
  },
  percentBarV2Arrow: {
    position: 'absolute',
    top: -15,
  },
  botBar: {
    borderRadius: '14px',
    border: '1px solid #CCC',
    background: '#DDD',
    height: '12px',
    alignSelf: 'stretch',
  },
  botStatus: {
    position: 'absolute',
    display: 'flex',
  },
  marker3: {
    position: 'absolute',
    top: 0,
    height: '100%',
    '& span': {
      display: 'block',
      width: 1,
      opacity: '50%',
      backgroundColor: '#EE3D3B',
      height: '100%',
      marginBottom: 8,
    },
    '& p': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
      color: '#333',
      marginLeft: -15,
    },
    '& div': {
      height: 12,
      marginBottom: 8,
    },
  },
  percentBarCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 30px 45px 30px',
    borderRadius: '10px',
    marginTop: '10px',
  },
  percentBarCardLeft: {
    marginLeft: '125px',
    width: '130px',
    height: '365px',
  },
  percentBarCardRight: {
    width: '130px',
    height: '365px',
    marginRight: '125px',
    float: 'right',
  },
  progressBarLeft: {
    position: 'absolute',
  },
  progressBarRight: {
    position: 'absolute',
  },
  progressArrowLeft: {
    position: 'absolute',
    left: '15px',
  },
  progressArrowRight: {
    position: 'absolute',
    right: '10px',
  },
  connectBrainRegionPart2: {zIndex: 1, position: 'absolute'},
  topScorePercent: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important',
    marginTop: '10px',
    marginBottom: '30px',
  },
  topScorePercentScore: {
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important',
  },
  percentBarV2Top: {
    position: 'relative',
    marginRight: '15px',
  },
}))

export default useHealthCenterStyles
