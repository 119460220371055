import {useEffect, useReducer} from 'react'

type Action<T> =
  | {type: 'LOADING'}
  | {type: 'SUCCESS'; payload: T[]}
  | {type: 'ERROR'; payload: string}

interface AsyncState<T> {
  loading: boolean
  data: T[]
  error: string | null
}

function createReducer<T>() {
  return function reducer(
    state: AsyncState<T>,
    action: Action<T>,
  ): AsyncState<T> {
    switch (action.type) {
      case 'LOADING':
        return {
          loading: true,
          data: [],
          error: null,
        }
      case 'SUCCESS':
        return {
          loading: false,
          data: action.payload,
          error: null,
        }
      case 'ERROR':
        return {
          loading: false,
          data: [],
          error: action.payload,
        }
      default:
        throw new Error('지원하지 않는 액션 타입입니다.')
    }
  }
}

export default function useAsyncList<T>(
  callback: () => Promise<ModelsResponse<T>>,
  deps: any[] = [],
  skip = false,
): [AsyncState<T>, VoidPromiseFunc] {
  const reducer = createReducer<T>()
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    data: [],
    error: null,
  })

  const fetchData = async () => {
    dispatch({type: 'LOADING'})
    try {
      const response = await callback()
      dispatch({type: 'SUCCESS', payload: response.list})
    } catch (e) {
      dispatch({type: 'ERROR', payload: e.message})
    }
  }

  useEffect(() => {
    if (skip) return

    fetchData()
  }, deps)

  return [state, fetchData]
}
