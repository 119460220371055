import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

export default function AddressInput() {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const schema = yup.object().shape({
    code: yup.string().required(t('IRequired')),
  })
  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IAddress')} </p>
        <p className={classes.require}>*</p>
      </div>
      <input
        placeholder={t('IRegisterIAddress')}
        className={classes.input}
        {...createOrgInfo.register('address', {
          validate: (code) => schema.validate({code}).catch((e) => e.errors[0]),
        })}
      />
      {errors.address && (
        <span className={classes.errorText}>{errors.address.message}</span>
      )}
    </div>
  )
}
