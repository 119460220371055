import {createStyles} from '@material-ui/core'
import {Colors} from 'components/common/useBaseStyle'

// eslint-disable-next-line import/prefer-default-export
export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 16,
    },
    inputWithLabel: {
      '& .Label': {
        fontSize: 13,
        color: '#868e96',
      },

      '& .ErrorText': {
        fontSize: 11,
        marginTop: 2,
        color: '#d03c35',
        paddingLeft: 2,
      },

      '& .InputText': {
        minWidth: 200,
        border: '1px solid #DCDCDC',
        padding: 10,
        color: '#333',
        '&:active, &:focus': {
          outline: 'none',
          borderColor: Colors.primary,
        },
        '&:disabled': {
          border: 'solid 1px #e9ecef',
          backgroundColor: '#eff1f4',
          color: '#b7b9bc',
        },
        '&.Error': {
          borderColor: Colors.error,
        },
      },

      '& .nameInput': {
        // minWidth: 200,
        height: 33,
        border: '1px solid #DCDCDC',
        padding: 10,
        color: '#333',
        '&:active, &:focus': {
          outline: 'none',
          borderColor: Colors.primary,
        },
        '&:disabled': {
          border: 'solid 1px #e9ecef',
          backgroundColor: '#eff1f4',
          color: '#b7b9bc',
        },
        '&.Error': {
          borderColor: Colors.error,
        },
      },

      '& .Container': {
        display: 'flex',
        gap: 18,
        alignItems: 'center',
        '& .Left': {
          width: 90,
          '&.Label': {
            textAlign: 'right',
          },
        },
        '& .Right': {
          flex: 1,
          '&.InputText': {
            height: 33,
          },
          '&.ErrorText': {
            textAlign: 'left',
          },
          '&.Timer': {
            textAlign: 'left',
          },
        },
      },
    },
  })
