import {PriceSet, CurrencyCode} from 'api/paymentApi'

export default class PriceSetModel implements PriceSet {
  price: number

  currency: CurrencyCode

  unitPurchase: number

  constructor(raw: PriceSet) {
    this.price = raw.price
    this.currency =
      CurrencyCode[raw.currency as string as keyof typeof CurrencyCode]
    this.unitPurchase = raw.unitPurchase
  }
}
