import {createStyles, makeStyles} from '@material-ui/core/styles'
import {EEGResult} from 'api/analysisApi'
import Left from 'assets/img/LeftNumeric.jpeg'
import IndividualEEGFetcher from 'components/Fetcher/IndividualEEGFetcher'
import NormativeEEGFetcher from 'components/Fetcher/NormativeEEGFetcher'
import SubTitle from 'components/Text/SubTitle'
import useQuery from 'hooks/useQuery'
import ChartLeft from 'assets/img/LeftChannel.jpeg'
import React, {useEffect} from 'react'

const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      padding: 16,
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {},
    wrapper: {
      alignSelf: 'flex-start',
      position: 'relative',
      width: '100%',
      overflowX: 'scroll',

      '& .Left': {
        position: 'sticky',
        left: 0,
        top: 0,
        width: 'auto',
        height: '70vh',
        zIndex: 1,

        '& img': {maxWidth: 167, height: '100%'},
      },
      '& .Right': {
        height: '70vh',
        position: 'absolute',
        left: 0,
        top: 0,
        '& img': {height: '100%'},
      },
    },
  })

const useStyles = makeStyles(styles)

function AllComponentTimeSeries({data}: {data: EEGResult}) {
  const classes = useStyles()

  const resultDetailList = data.resultDetailList ?? []
  const [item] = resultDetailList
    .filter((item) => item.analysisType === '12')
    .filter((item) => item.componentType === 0)
    .map((item) => item.imgPath)

  return (
    <div className={classes.wrapper}>
      <div className='Left'>
        <img src={Left} alt='All Component time series Y axis' />
      </div>
      <div className='Right'>
        <img src={item} alt='All component time series' />
      </div>
    </div>
  )
}

function RawdataImage({data}: {data: EEGResult}) {
  const classes = useStyles()
  const resultDetailList = data.resultDetailList ?? []
  const realData = resultDetailList
  const [item] = realData
    .filter((item) => item.analysisType === '1')
    .filter((item) => item.componentType === 0)
    .filter((item) => item.imgType === 'WEB')
    .map((item) => item.rawDataPath)

  useEffect(() => {
    console.log(item)
  }, [item])

  return (
    <div className={classes.wrapper}>
      <div className='Left'>
        <img src={ChartLeft} alt='Graph Left' />
      </div>
      <div className='Right'>
        <img src={item} alt='Graph Content' />
      </div>
    </div>
  )
}

export default function AllComponentTimeSeriesPopup() {
  const classes = useStyles(styles)

  const type = useQuery().get('type') ?? 'I'
  const id = useQuery().get('id') ?? '-1'

  const parsedId = Number.parseInt(id, 10)

  if (Number.isNaN(parsedId) || parsedId === -1) {
    return (
      <div className={classes.container}>유효하지 않은 페이지 접근입니다</div>
    )
  }
  // R == raw data imag
  if (type !== 'I' && type !== 'N' && type !== 'R') {
    return (
      <div className={classes.container}>유효하지 않은 페이지 접근입니다</div>
    )
  }

  const getTitleText = (): string => {
    if (type === 'R') {
      return 'Raw Data'
    }
    return 'All Components Time Series'
  }

  return (
    <div className={classes.root}>
      <SubTitle className={classes.title}>{getTitleText()}</SubTitle>
      <div className={classes.container}>
        {type === 'I' && (
          <IndividualEEGFetcher id={parsedId}>
            {(data) => <AllComponentTimeSeries data={data} />}
          </IndividualEEGFetcher>
        )}
        {type === 'N' && (
          <NormativeEEGFetcher id={parsedId}>
            {(data) => <AllComponentTimeSeries data={data} />}
          </NormativeEEGFetcher>
        )}
        {type === 'R' && (
          <IndividualEEGFetcher id={parsedId}>
            {(data) => <RawdataImage data={data} />}
          </IndividualEEGFetcher>
        )}
      </div>
    </div>
  )
}
