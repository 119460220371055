import React from 'react'
import {useTranslation} from 'react-i18next'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import ReportSubtitle from '../../../Text/ReportSubtitle'
import {Colors} from '../../../common/useBaseStyle'

export default function EEGChildSummaryInformationProcessingSpeed(props: {
  informationProcessingSpeedGraphImage: string | undefined
  // frontalLobeDevelopmentColorbarImage: string | undefined
  percentile: number
  // maxRange: number
  score: number
}) {
  const {informationProcessingSpeedGraphImage, percentile, score} = props
  const isDisable = !percentile || !score
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.eeg} style={{marginTop: 45}}>
      <ReportSubtitle number='2'>
        {t('IEEGSummaryReportChildInfoProcessTitle')}
      </ReportSubtitle>
      <div className={classes.childValueGraph}>
        <div className='Child-Graph'>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={informationProcessingSpeedGraphImage}
            alt='brain wave analysis 3d image'
          />
        </div>
        <div className='Child-info'>
          <div
            style={{
              backgroundColor: !isDisable ? '#00a29c' : '#e0e0e0',
              color: '#FFF',
              padding: '15px 24px',
              marginTop: 84,
              marginBottom: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span className='Range'>
              <span style={{display: 'inline-block', maxWidth: '65%'}}>
                {t('IEEGSummaryReportChildInfoProcessPercentTitle')}
              </span>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {(percentile == null || percentile <= 0) && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label
                  className='ScoreValue'
                  style={{color: '#f55856f !important'}}
                >
                  {`    - `}
                </label>
              )}
              {percentile != null && percentile > 0 && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className='ScoreValue'>
                  {`    ${percentile} %ile    ` ?? '- %ile'}
                </label>
              )}
            </span>
            <span
              className='Score scoreWrapText'
              style={{marginLeft: 0, display: 'flex', alignItems: 'center'}}
            >
              <span>{t('IEEGSummaryReportChildScore')}</span>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {score == null && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className='ScoreNullValue'>- Hz</label>
              )}
              {score != null && (
                <div>
                  <span
                    className='ScoreValue'
                    style={{color: !isDisable ? '#00a29c' : '#e0e0e0'}}
                  >
                    {`${score || '-'} Hz`}
                  </span>
                </div>
              )}
            </span>
          </div>
          <div className='Child-info-alert'>
            {t('IEEGSummaryReportChildECAlert')}
          </div>
          <div
            className='Title'
            style={{color: '#000000', marginBottom: '20px'}}
          >
            {t('IEEGSummaryReportChildInfoProcessAbout')}
          </div>
          <div className='Desc' style={{color: '#333333'}}>
            {t('IEEGSummaryReportChildInfoProcessDescription')}
          </div>
        </div>
      </div>
    </div>
  )
}
