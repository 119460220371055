import {yupResolver} from '@hookform/resolvers/yup'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ReorderIcon from '@material-ui/icons/Reorder'
import {navigate} from 'AppHistory'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SubTitle from 'components/Text/SubTitle'
import {getNoticeListPath} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import useNotice from 'features/notice/useNotice'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
  },
  gridContainer: {
    margin: 25,
    display: 'flex',
    flexDirection: 'column',
    // margin: 10,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 30,
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  contentTitleWrap: {
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
    '& > .MuiFormLabel-root.Mui-disabled': {
      color: 'red', // (default alpha is 0.38)
    },
  },
  inputRoot: {
    '&$disabled': {
      color: 'red',
    },
  },

  titleSize: {
    fontSize: '1.0rem',
  },
  titleCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
    '& > h6': {
      fontSize: '1.0rem',
      // margin: '0 30px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 43,
    '& button': {
      minWidth: 148,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  rootText: {
    '&$disabled': {
      color: 'white',
    },
  },
  disabled: {},
  background: {
    color: 'primary',
    '& span': {
      background: '#E0E0E0',
    },
  },

  textField: {
    marginTop: 10,
    '& .MuiInputBase-input': {
      borderColor: 'red',
      fontSize: 14,
      fontWeight: 400,
      color: '#333333',
    },
    '& textarea:disabled': {
      color: '#000',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '0',
    },
  },

  specialOutline: {
    borderColor: '#e2e4e7',
    borderWidth: 1,
  },

  hidden: {
    visibility: 'hidden',
  },

  titleTextField: {
    width: '100%',
    minWidth: 120,
    height: 47,
    marginTop: 10,
    display: 'flex',
    padding: 10,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    border: '1px solid #e2e4e7',
    borderRadius: theme.shape.borderRadius,
    color: '#333333',
  },

  buttonWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))

// 서버로 보낼때 사용하는 type
type NoticeCreateInputType = {
  uid: string
  title: string
  content: string
}

export default function NoticeWritePage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {onCreate} = useNotice()
  const {onCustomOpen: onFailureModalOpen} = useFailureModal()

  // 핀번호 유효성 검사
  const AddSchema = yup.object().shape({
    title: yup.string().required().min(1),
    content: yup.string().required().min(1),
  })

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm<NoticeCreateInputType>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })

  const onSubmit = handleSubmit((data: NoticeCreateInputType) => {
    if (data.content.length > 60000) {
      onFailureModalOpen({
        title: t('INoticeTitle'),
        message: t('IContentsMaxLength'),
      })
    }
    onCreate({
      title: data.title,
      content: data.content,
    }).then(() => {
      reset()
    })
  })
  return (
    <CardWithTitle title={t('ICreate')}>
      {/* <Grid container> */}
      <Grid container className={classes.gridContainer}>
        <SubTitle>{t('INoticeCreate')} </SubTitle>
        <Grid item xs={12} sm={1} className={classes.titleCenter}>
          <Typography variant='h6'>{t('ITitle')}</Typography>
        </Grid>
        <Grid item xs={12} sm={11} className={classes.contentContainer}>
          <Controller
            name='title'
            control={control}
            rules={{required: false}}
            render={({field}) => (
              <TextField
                variant='outlined'
                color='primary'
                fullWidth
                placeholder={t('ITitleRequired')}
                className={classes.textField}
                error={!!errors.title}
                InputProps={{
                  classes: {notchedOutline: classes.specialOutline},
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{maxLength: 100}}
                {...field}
              />
            )}
          />
        </Grid>
        <Typography className={classes.padding} />
        <Grid item xs={12} sm={1}>
          <Typography variant='h6' className={classes.titleSize}>
            {t('IContents')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} className={classes.contentContainer}>
          <Controller
            name='content'
            control={control}
            rules={{required: false}}
            render={({field}) => (
              <TextField
                className={classes.textField}
                variant='outlined'
                fullWidth
                multiline
                placeholder={t('IContentsRequired')}
                error={!!errors.content}
                rows={13}
                InputProps={{
                  classes: {notchedOutline: classes.specialOutline},
                }}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={11} className={classes.buttonContainer}>
          <ActionButton
            startIcon={<ReorderIcon />}
            color='secondary'
            onClick={(event) => {
              event.stopPropagation()
              navigate(getNoticeListPath())
            }}
          >
            {t('IReadList')}
          </ActionButton>
          <div className={classes.buttonWrap}>
            <ActionButton
              variant='contained'
              color='default'
              disableElevation
              onClick={() => {
                navigate(getNoticeListPath())
              }}
            >
              {t('ICancel')}
            </ActionButton>
            <Typography className={classes.padding} />
            <ActionButton
              variant='contained'
              color='primary'
              disableElevation
              onClick={onSubmit}
            >
              {t('IOk')}
            </ActionButton>
          </div>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </CardWithTitle>
  )
}
