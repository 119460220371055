import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  HealthProductInfoListType,
  SearchOrgRequest,
  SearchedCustomerOrg,
  searchOrganizationApi,
} from 'api/invoiceApis'
import {RootState} from 'store'

export const searchCustomerOrg = createAsyncThunk(
  'api/eeg/CustomerOrg/search',
  async (payload: SearchOrgRequest, {rejectWithValue}) => {
    try {
      const response = await searchOrganizationApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface OrgType {
  orgId: number | null
  lastName: string
  firstName: string
  name: string
  email: string
  countryId: number | null
  phoneNumber: string
  industryId: number | null
  detailIndustryId: number | null
  address: string
  businessNumber: string
  institutionNumber: string
  createdAt: string
  orgType: string
  orgName: string
  healthProductInfoList: HealthProductInfoListType[]
}

interface CustomerRegistrationOrgState {
  loading: boolean
  error: any
  items: SearchedCustomerOrg[]
  listOrg: OrgType[]
  orgSelected: OrgType
  search: CustomerSearchOrgKind
  paging: Paging
  oid?: string | null
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
  reSelectYn: YN | undefined
  reAnalysisYn: YN | undefined
  isIcaSelectOpen: boolean
  isIcaConfirmOpen: boolean
  isIcaResultOpen: boolean
  sraYn?: YN | undefined
}

const initialState: CustomerRegistrationOrgState = {
  loading: false,
  error: null,
  items: [],
  listOrg: [],
  orgSelected: {
    orgId: null,
    lastName: '',
    firstName: '',
    name: '',
    email: '',
    countryId: null,
    phoneNumber: '',
    industryId: null,
    detailIndustryId: null,
    address: '',
    businessNumber: '',
    institutionNumber: '',
    createdAt: '',
    orgType: '',
    orgName: '',
    healthProductInfoList: [],
  },
  search: {orgName: ''},
  reSelectYn: undefined,
  reAnalysisYn: undefined,
  isIcaSelectOpen: false,
  isIcaConfirmOpen: false,
  isIcaResultOpen: false,
  oid: '',
  sraYn: undefined,
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

export const customerOrgSlice = createSlice({
  name: 'customerRegistrationOrgSlice',
  initialState,
  reducers: {
    setListOrg: (state, action) => {
      state.listOrg = action.payload
    },
    setOrgSelected: (state, action) => {
      state.orgSelected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCustomerOrg.pending, (state, action) => {
      state.loading = true
      state.error = null
      const {search, paging} = action.meta.arg
      state.search = search
      state.paging = paging
    })
    builder.addCase(searchCustomerOrg.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.listOrg = items
      state.loading = false
      state.error = null
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchCustomerOrg.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {setListOrg, setOrgSelected} = customerOrgSlice.actions

export default customerOrgSlice.reducer

export const selectList = (state: RootState) => state.invoiceInquiry.items
export const selectLoading = (state: RootState) => state.invoiceInquiry.loading
export const selectError = (state: RootState) => state.invoiceInquiry.error
export const selectSearch = (state: RootState) => state.invoiceInquiry.search
export const selectListOrg = (state: RootState) => state.invoiceInquiry.listOrg
export const selectPaging = (state: RootState) => state.invoiceInquiry.paging
export const selectPagingInfo = (state: RootState) =>
  state.invoiceInquiry.pagingInfo
export const selectOrgSelected = (state: RootState) =>
  state.invoiceInquiry.orgSelected
export const selectQuery = (state: RootState) => ({
  search: state.invoiceInquiry.search,
  oid: state.invoiceInquiry.oid,
  paging: state.invoiceInquiry.paging,
})
