import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState, ThunkExtraArguments} from 'store'

export type ConfirmType =
  | 'eeg_summary'
  | 'eeg_summary_premium'
  | 'eeg_norm'
  | 'eeg_mci'

export interface OpenConfirmPayload {
  type: ConfirmType
  selected: number[]
}

interface AnalysisState {
  isConfirmOpen: boolean
  isConfirmed: boolean
  selected: number[]
  confirmType?: ConfirmType
  confirmed?: number[]
}

const initialState: AnalysisState = {
  isConfirmOpen: false,
  isConfirmed: false,
  selected: [],
  confirmed: [],
}

const slice = createSlice({
  name: 'analysisSlice',
  initialState,
  reducers: {
    openConfirmDialog(state, action: PayloadAction<OpenConfirmPayload>) {
      state.isConfirmOpen = true
      state.isConfirmed = false
      state.selected = [...action.payload.selected]
      state.confirmType = action.payload.type
    },
    confirmConfirmDialog(state, action: PayloadAction<number[]>) {
      state.isConfirmOpen = false
      state.isConfirmed = true
      state.confirmed = action.payload
    },
    cancelConfirmDialog(state) {
      state.isConfirmOpen = false
      state.isConfirmed = false
    },
  },
})

export default slice.reducer

export const {openConfirmDialog, confirmConfirmDialog, cancelConfirmDialog} =
  slice.actions

export const confirmAnalysisAction = createAsyncThunk<
  false | number[],
  OpenConfirmPayload,
  {extra: ThunkExtraArguments}
>('analysis/confirm', async (payload, {dispatch, extra}) => {
  const {store} = extra
  dispatch(openConfirmDialog(payload))

  return new Promise<false | number[]>((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state: RootState = store.getState()
      if (state.analysis.isConfirmed === true) {
        unsubscribe()
        resolve(state.analysis.confirmed ?? [])
      }
      if (state.analysis.isConfirmed === false) {
        unsubscribe()
        resolve(false)
      }
    })
  })
})

export const selectConfirm = (state: RootState) => ({
  isOpen: state.analysis.isConfirmOpen,
  isConfirm: state.analysis.isConfirmed,
  type: state.analysis.confirmType,
  selected: state.analysis.selected,
  confirmed: state.analysis.confirmed ?? [],
})
