import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import {AnalysisAvailable, fetchIsIcaAvailableApi} from 'api/analysisApi'
import {useIcaConfirmDialog} from 'features/analysis/useEegIndividualIcaDialog'
import MultiIcaCheckTable from 'pages/Analysis/EEGIndividual/ReAnalysis/MultiIcaCheckTable'
import {StyledIcaDialog} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledIcaDialog'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const BottomButtonWrap = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 25px;
  gap: 28px;
`

function IcaConfirmResult({detailDto}: AnalysisAvailable) {
  return (
    <div>
      {/* <StyledConfirmTitle */}
      {/*  className='title' */}
      {/*  dangerouslySetInnerHTML={{ */}
      {/*    __html: t('IReAnalysis_pointReduceAlert', {point: totalPoint}), */}
      {/*  }} */}
      {/* /> */}
      <MultiIcaCheckTable
        items={detailDto.map((d) => ({
          id: d.requestId,
          status: d.status,
          description: d.description,
        }))}
      />
    </div>
  )
}

function IcaConfirmDialog() {
  const {t} = useTranslation()

  const {open, selected: requestIds, onConfirm, onClose} = useIcaConfirmDialog()

  const [loading, setLoading] = useState(false)
  const [isIcaAvailableResponse, setIsIcaAvailableResponse] = useState<
    AnalysisAvailable | undefined
  >()
  const [error, setError] = useState<string | undefined>()

  const fetchIsIcaAvailable = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const resp = await fetchIsIcaAvailableApi({requestIds})
      setIsIcaAvailableResponse(resp.data)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchIsIcaAvailable()
    }
  }, [open])

  const hasNoIcaAvailable = useMemo(
    () =>
      isIcaAvailableResponse?.detailDto.every((i) => i.status !== 'SUCCESS'),
    [isIcaAvailableResponse],
  )

  const handleCancel = () => onClose()
  const handleConfirm = () => onConfirm()

  return (
    <Dialog open={open} onClose={handleCancel}>
      <StyledIcaDialog>
        <h3 className='header'>{t('IReAnalysis_pre_alert')}</h3>
        <div className='content'>
          {loading && <div>{t('ILoading')}</div>}
          {!loading && isIcaAvailableResponse !== undefined && (
            <IcaConfirmResult {...isIcaAvailableResponse} />
          )}
          {!loading && error !== undefined && <div>{error}</div>}
        </div>
        <BottomButtonWrap>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleCancel}
            style={{width: 222, backgroundColor: '#e9ecef', color: '#4b4b4b'}}
          >
            {t('ICancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleConfirm}
            style={{width: 222}}
            disabled={loading || hasNoIcaAvailable}
          >
            {t('IOk')}
          </Button>
        </BottomButtonWrap>
        {/* <div className='footer'>
          <CancelButton onClick={handleCancel} />
          <ConfirmButton
            disabled={loading || hasNoIcaAvailable}
            onClick={handleConfirm}
          />
        </div> */}
      </StyledIcaDialog>
    </Dialog>
  )
}

export default IcaConfirmDialog
