import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import {Divider, Typography} from '@material-ui/core'
import ReportSubtitle from 'components/Text/ReportSubtitle'

interface HRVSummaryAutoNervousSystemProps {
  customerFirstName: string
  customerLastName: string
  balanceOpinion: string
  balanceCircularImagePath: string
  tableImagePath: string
  contourImagePath: string
  histogramImagePath: string
  contour: number
  histogram: number
}

export default function HRVSummaryAutoNervousSystem(
  props: HRVSummaryAutoNervousSystemProps,
) {
  const {
    customerFirstName,
    customerLastName,
    balanceCircularImagePath,
    balanceOpinion,
    tableImagePath,
    contourImagePath,
    histogramImagePath,
    contour,
    histogram,
  } = props

  const hrvVariabilityItems = [
    {value: 'IHrv2DVariabilityLevel1'},
    {value: 'IHrv2DVariabilityLevel2'},
    {value: 'IHrv2DVariabilityLevel3'},
    {value: 'IHrv2DVariabilityLevel4'},
    {value: 'IHrv2DVariabilityLevel5'},
    {value: 'IHrv2DVariabilityLevel6'},
    {value: 'IHrv2DVariabilityLevel7'},
    {value: 'IHrv2DVariabilityLevel8'},
  ]
  const hrvHistogramItems = [
    {value: 'IHrvVariabilityHistogramLevel1'},
    {value: 'IHrvVariabilityHistogramLevel2'},
    {value: 'IHrvVariabilityHistogramLevel3'},
  ]

  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const KoreanCustomerName = `${customerLastName} ${customerFirstName}`
  const englishCustomerName = `${customerFirstName} ${customerLastName} `

  return (
    <div className={classes.hrv}>
      <br />
      <ReportSubtitle number='1'>{t('IHRVControlAssayTitle')}</ReportSubtitle>
      <div className={classes.hrvAutoNervous}>
        <img className='TableImage' src={tableImagePath} alt='' />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className='AutoNervousTitle'>{t('IAutonomicNervousSystem')}</div>
          <img
            className='CircularChartImage'
            src={balanceCircularImagePath}
            alt=''
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              marginTop: 10,
            }}
          >
            <div className='Sympathetic'>
              <div>
                <span className='Label'>{t('IParasympathetic')}</span>
                <span className='Sympathetic' style={{fontWeight: 600}}>
                  {t('ISuperior')}
                </span>
              </div>
              <div style={{marginTop: 8}}>
                {t('ISymptomOfParasympatheticDominant')}
              </div>
            </div>
            <div className='Parasympathetic'>
              <div>
                <span className='Label'>{t('ISympathetic')}</span>
                <span className='Parasympathetic' style={{fontWeight: 600}}>
                  {t('ISuperior')}
                </span>
              </div>
              <div style={{marginTop: 8}}>
                {t('ISymptomOfSympatheticDominant')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.hrvOpinion}>
        <div className='Opinion'>
          <span className='Label'>
            {i18n.language !== 'ko' &&
              i18n.language !== 'ja' &&
              t('IOpinionOfUser', {name: englishCustomerName})}
            {(i18n.language === 'ko' || i18n.language === 'ja') &&
              t('IOpinionOfUser', {
                name: KoreanCustomerName,
              })}
          </span>
          <Divider className='Divider' />
        </div>
        <div className='Balance'>{balanceOpinion}</div>
      </div>
      <Divider className={classes.Divider} />
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <ReportSubtitle number='2'>
        {t('I2DAnalysisOfHrvVariability')}
      </ReportSubtitle>
      <div className={classes.description}>
        {t('I2DAnalysisOfHrvVariabilityLabel')}
      </div>
      <div className={classes.hrvAriability}>
        <img className='ContourImage' src={contourImagePath} alt='' />
        <div id='hrv-item'>
          <div className={classes.hrvOpinion}>
            <div className='Opinion'>
              <span className='Label'>{t('IOpinionTitle')}</span>
              <Divider className='Divider' />
            </div>
            <div className='Balance'>
              {(i18n.language === 'ko' || i18n.language === 'ja') &&
                t(hrvVariabilityItems[contour - 1]?.value, {
                  name: KoreanCustomerName,
                })}
              {i18n.language !== 'ko' &&
                i18n.language !== 'ja' &&
                t(hrvVariabilityItems[contour - 1]?.value, {
                  name: englishCustomerName,
                })}
            </div>
            <Divider className={classes.Divider} />
          </div>
        </div>
      </div>
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <Typography className={classes.padding} />
      <ReportSubtitle number='3'>
        {t('IHrvVariabilityHistogramTitle')}
      </ReportSubtitle>

      <div className={classes.description}>
        {(i18n.language === 'ko' || i18n.language === 'ja') &&
          t('IHrvVariabilityHistogramDescription', {
            name: KoreanCustomerName,
          })}
        {i18n.language !== 'ko' &&
          i18n.language !== 'ja' &&
          t('IHrvVariabilityHistogramDescription', {
            name: englishCustomerName,
          })}
      </div>
      <div className={classes.hrvAriability}>
        <img className='ContourImage' src={histogramImagePath} alt='' />
        <div id='hrv-item'>
          <div className={classes.hrvOpinion}>
            <div className='Opinion'>
              <span className='Label'>{t('IOpinionTitle')}</span>
              <Divider className='Divider' />
            </div>
            <div className='Balance'>
              {t(hrvHistogramItems[histogram - 1]?.value)}
            </div>
            <Divider className={classes.Divider} />
          </div>
        </div>
      </div>
    </div>
  )
}
