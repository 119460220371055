import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'

const ErrorPageStyle = () =>
  createStyles({
    root: {
      backgroundColor: '#F4F4F7',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    statusCodeText: {
      fontSize: 100,
      color: '#676a6c',
      margin: 0,
      fontWeight: 500,
    },
    titleText: {
      fontWeight: 800,
      fontSize: 18,
      color: '#676a6c',
      textAlign: 'center',
      margin: 0,
    },
    subText: {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
      fontSize: 14,
      color: '#333',
      textAlign: 'center',
    },
  })

const NotFoundPage = () => {
  const useStyles = makeStyles(ErrorPageStyle)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h3 className={classes.statusCodeText}>404</h3>
      <p className={classes.titleText}>Pages Not Found</p>
      <p className={classes.subText}>
        {`Sorry, but the page you are looking for has note been found.\nTry checking the URL for error,\nthen hit the refresh button on your browser or \ntry found something else in our app.`}
      </p>
    </div>
  )
}

export default NotFoundPage
