import {yupResolver} from '@hookform/resolvers/yup'
import {DialogActions, DialogContent, OutlinedInput} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {isAdmin as getIsAdmin} from 'Authority'
import Card from 'components/atoms/Card'
import {Colors} from 'components/common/useBaseStyle'
import usePinDialogStyles from 'components/Dialog/PinDialogStyle'
import {commentOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import usePagination, {useWriteDialog} from 'features/comment/useComment'
import useOrgUserList from 'features/org/useOrgUserList'
import React, {useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

interface CommentWriteFormInput {
  docNo: number | undefined
  comment: string
}

const WriteCommentDialog = () => {
  const {t} = useTranslation()
  const classes = usePinDialogStyles()
  const outlinedInputClasses = commentOutlinedInputStyles()

  const WriteCommentSchema = yup.object().shape({
    docNo: yup.number(),
    comment: yup
      .string()
      .min(1, 'ICommentMinLength')
      .max(50, 'ICommentValidation'),
  })

  const {
    control,
    handleSubmit,
    formState: {errors: formErrors},
    setValue,
    reset,
  } = useForm<CommentWriteFormInput>({
    // @ts-ignore
    resolver: yupResolver(WriteCommentSchema),
  })
  const {onFetchPage: onCommentFetch} = usePagination()
  const {userList: orgUsers, onFetch: onFetchOrgUserList} = useOrgUserList()
  const {user: currentUser} = useAuth()
  const {open, onCreate, onClose, uuid} = useWriteDialog()

  const [doctors, setDoctors] = useState<OrgSimpleUser[]>([])

  const isAdmin = useMemo(
    () => getIsAdmin(currentUser?.authority),
    [currentUser?.authority],
  )
  const isUser = useMemo(() => !isAdmin, [isAdmin])

  const handleDialogClose = () => {
    onClose()
    if (isAdmin) {
      setValue('docNo', undefined)
    }
    reset()
  }

  const onSubmit = async (data: CommentWriteFormInput) => {
    const {docNo, comment} = data
    try {
      await onCreate({
        docNo: docNo ?? doctors[0].id,
        comment,
        uuid: uuid ?? '',
      })
      setValue('comment', '')
      onCommentFetch({uuid: uuid ?? '', paging: {page: 0, size: 3}})
      onClose()
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

  useEffect(() => {
    if (!open) {
      reset()
    }

    if (open && currentUser && isUser) {
      setValue('docNo', currentUser?.id)
      return
    }
    onFetchOrgUserList()
    setDoctors(orgUsers)
  }, [open, currentUser])

  useEffect(() => {
    if (isAdmin && orgUsers.length === 1) {
      setValue('docNo', orgUsers[0].id)
    }
  }, [orgUsers, currentUser])

  useEffect(() => {
    if (currentUser && isUser) {
      setValue('docNo', currentUser.id)
    }
  }, [doctors, currentUser])

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className={classes.root}>
          <div className={classes.longTitleContainer}>
            <div className={classes.iconTitleWrap}>
              <Typography variant='h6'>{t('ICommentCreate')}</Typography>
            </div>
            <IconButton aria-label='search' onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <DialogContent>
            <div className={classes.pinNumberContainer}>
              {isAdmin && (
                <div className={classes.pinNumberWrap}>
                  <Typography
                    variant='h6'
                    className={classes.labelText}
                    style={{width: 100}}
                  >
                    {t('ICommentSelectMedicalStaff')}
                  </Typography>
                  <Grid item xs={12} sm={9}>
                    <Controller
                      name='docNo'
                      control={control}
                      render={({field}) => (
                        <FormControl variant='outlined' fullWidth>
                          <Select
                            disabled={isUser}
                            defaultValue={doctors[0].id}
                            error={!!formErrors.docNo}
                            input={
                              <OutlinedInput
                                name='age'
                                id='outlined-age-simple'
                                classes={outlinedInputClasses}
                              />
                            }
                            {...field}
                          >
                            <MenuItem disabled value=''>
                              <em>{t('ICommentCommentedMedicalStaff')}</em>
                            </MenuItem>
                            {doctors.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </div>
              )}
              <div
                className={classes.pinNumberWrap}
                style={{alignItems: 'flex-start'}}
              >
                <Typography
                  variant='h6'
                  className={classes.labelText}
                  style={{width: 100}}
                >
                  {t('ICommentContents')}
                </Typography>
                <Grid item xs={12} sm={9}>
                  <Controller
                    render={({field}) => (
                      <TextField
                        multiline
                        minRows={5}
                        variant='outlined'
                        fullWidth
                        error={!!formErrors.comment}
                        className={classes.pinNumberTextField}
                        placeholder={t('ICommentContents')}
                        style={{width: '100%', marginTop: 0}}
                        {...field}
                      />
                    )}
                    name='comment'
                    control={control}
                  />
                  {formErrors.comment?.message && (
                    <p style={{color: Colors.error}}>
                      {t(String(formErrors.comment?.message))}
                    </p>
                  )}
                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.actionButtonWrap}>
            <div
              style={{
                margin: '5px 30px',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 10,
              }}
            >
              <Button
                variant='contained'
                disableElevation
                onClick={handleDialogClose}
                fullWidth
              >
                {t('IClose')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                type='submit'
                fullWidth
              >
                {t('IConfirm')}
              </Button>
            </div>
          </DialogActions>
        </Card>
      </form>
    </Dialog>
  )
}

export default WriteCommentDialog
