import CardWithTitle from 'components/atoms/CardWithTitle'
import SubTitle from 'components/Text/SubTitle'
import UserTable from 'pages/OrgPage/UserPage/UserTable'
// import UserLogTablePage from 'pages/SalesPages/SalesUserLogPage/UserLogTablePage'
import React from 'react'
import {useTranslation} from 'react-i18next'

function UserPage() {
  const {t} = useTranslation()

  return (
    <>
      <CardWithTitle>
        <SubTitle
          style={{
            padding: '15px 0',
          }}
        >
          {t('IUserHome')}
        </SubTitle>
        <UserTable />
      </CardWithTitle>
      {/* Hide in QCQA-1119 */}
      {/* <CardWithTitle title={t('IUserLogs')} style={{marginTop: 25}}>
        <UserLogTablePage />
      </CardWithTitle> */}
    </>
  )
}

export default UserPage
