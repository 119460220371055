import Colors from 'theme/Colors'
import Size from 'theme/Size'
import Weight from 'theme/Weight'

export const IconBigSmallPrimaryButtonStyles = {
  buttonColor: Colors.common.primary,
  buttonBackgroundColor: Colors.common.primary_bg,
  buttonHeight: Size.iconBigSmallButton.height,
  buttonWidth: Size.iconBigSmallButton.width,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.iconButton.padding,
}

export const IconBigSmallDisabledButtonStyles = {
  buttonColor: Colors.common.white,
  buttonBackgroundColor: Colors.common.disabled,
  buttonHeight: Size.BigSmallButton.height,
  buttonWidth: Size.iconBigSmallButton.width,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.iconButton.padding,
}

export const IconBigSmallErrorButtonStyles = {
  buttonColor: Colors.common.error.error_01,
  buttonBackgroundColor: Colors.common.error.bg,
  buttonHeight: Size.BigSmallButton.height,
  buttonWidth: Size.iconBigSmallButton.width,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.iconButton.padding,
}

export const IconBigSmallDisableButtonStyles = {
  buttonColor: '#dadada',
  // buttonBackgroundColor: '#dadada',
  buttonHeight: Size.BigSmallButton.height,
  buttonWidth: Size.iconBigSmallButton.width,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
  buttonPadding: Size.padding.iconButton.padding,
}
