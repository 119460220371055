import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import {EmergencyNotifyItems} from 'api/salesNoticeApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import SalesNoticeEditDialog from 'components/Dialog/Sales/Notice/Edit'
import SalesNoticeInspectionEditDialog from 'components/Dialog/Sales/Notice/Inspection/Edit'
import SalesNoticeInspectionAddDialog from 'components/Dialog/Sales/Notice/Inspection/Write'
import SalesNoticeWriteDialog from 'components/Dialog/Sales/Notice/Write'
import TableNotice from 'components/Table/TableNotice'
import {
  openSalesInspectionAddDialog,
  openSalesInspectionEditDialog,
  openSalesNoticeAddDialog,
  openSalesNoticeEditDialog,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import {
  fetchEmergencyNotice,
  selectEmergencyNoticeData,
} from 'features/sales/salesNoticeSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
      marginTop: 3,
    },

    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      width: '100%',
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 14,
      marginTop: 10,
      gap: 7,
    },
  }),
)

export default function SalesAppNoticePage() {
  const [onSelected, setOnSelected] = React.useState<boolean>(false)
  const [noticeData, setNoticeData] = React.useState<EmergencyNotifyItems[]>()
  const [inspectionData, setInspectionData] =
    React.useState<EmergencyNotifyItems[]>()
  const dispatch = useAppDispatch()
  const appNoticeFilter = false
  const appInspectionFilter = true
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {t} = useTranslation()
  const classes = useStyles()

  const [clikedNumber, setClickedNumber] = React.useState<number>(0)

  const notice = useAppSelector(selectEmergencyNoticeData)

  const handleDataFilter = (
    noticeData: EmergencyNotifyItems[],
    isProceed: boolean,
  ) => noticeData.filter((value) => value.isNotProceed === isProceed)

  const handleNoticeEditClick = () => {
    if (onSelected && noticeData) {
      noticeData.map((value) => {
        return value.id === clikedNumber
          ? dispatch(openSalesNoticeEditDialog())
          : null
      })
    } else {
      onFailureModalOpen(t('INoticeSelectRequired'))
    }
  }

  const handleNoticeAddClick = () => {
    dispatch(openSalesNoticeAddDialog())
  }

  const handleInspectionAddClick = () => {
    dispatch(openSalesInspectionAddDialog())
  }

  const handleInspectionEditClick = () => {
    if (onSelected && inspectionData) {
      inspectionData.map((value) => {
        return value.id === clikedNumber
          ? dispatch(openSalesInspectionEditDialog())
          : null
      })
    } else {
      onFailureModalOpen(t('INoticeSelectRequired'))
    }
  }
  const getEmergencyNotice = async (pageIndex: number, sizeIndex: number) => {
    const result = await dispatch(
      fetchEmergencyNotice({
        page: pageIndex,
        size: sizeIndex,
      }),
    ).unwrap()
    if (result) {
      const list = result.items
      setNoticeData(handleDataFilter(list, false))
      setInspectionData(handleDataFilter(list, true))
    }
  }

  useEffect(() => {
    getEmergencyNotice(1, 100)
  }, [])

  useEffect(() => {
    const list = notice
    setNoticeData(handleDataFilter(list, false))
    setInspectionData(handleDataFilter(list, true))
  }, [notice])
  return (
    <div>
      <CardWithTitle title={t('IAppNotice')}>
        <Grid className={classes.navContainer}>
          <Grid item xs={12}>
            <div className={classes.topContainer}>
              <ActionButton
                startIcon={<AddIcon />}
                variant='contained'
                size='medium'
                color='primary'
                disableElevation
                onClick={handleNoticeAddClick}
              >
                {t('IWrite')}
              </ActionButton>
              <ActionButton
                startIcon={<CreateIcon />}
                onClick={handleNoticeEditClick}
                variant='contained'
                size='medium'
                color='primary'
                disableElevation
              >
                {t('IUpdate')}
              </ActionButton>
            </div>
            <Grid item xs={12}>
              {/* 테이블 */}
              <TableNotice
                isSelected={(e) => setOnSelected(e)}
                isProceed={appNoticeFilter}
                clickIndex={(e) => {
                  setClickedNumber(e)
                }}
                items={noticeData ?? []}
              />
            </Grid>
            {/* <PagingLeftAlign>
              <span style={{width: '100%'}} />
              * 긴급공지, 점검 기능은 게시글이 많지 않으므로 페이징 구현하지않음 *
              <Pagination
                totalPageCount={1}
                currentPageIndex={0}
                itemCountPerPage={10}
                setCurrentPageIndex={(e) => {
                  setPageIndex(e + 1)
                }}
                loading={false}
                onItemCountPerPageChanged={(event: any) => {}}
                isItemCountPerPageEnabled={false}
              />
            </PagingLeftAlign> */}
          </Grid>
        </Grid>
      </CardWithTitle>
      <Typography className={classes.padding} />
      <CardWithTitle title={t('IAppInspection')}>
        <Grid className={classes.navContainer}>
          <Grid item xs={12}>
            <div className={classes.topContainer}>
              <ActionButton
                startIcon={<AddIcon />}
                variant='contained'
                size='medium'
                color='primary'
                disableElevation
                onClick={handleInspectionAddClick}
              >
                {t('IWrite')}
              </ActionButton>
              <ActionButton
                startIcon={<CreateIcon />}
                variant='contained'
                size='medium'
                color='primary'
                disableElevation
                onClick={handleInspectionEditClick}
              >
                {t('IUpdate')}
              </ActionButton>
            </div>
            <Grid item xs={12}>
              <TableNotice
                isSelected={(e) => setOnSelected(e)}
                clickIndex={(e) => {
                  setClickedNumber(e)
                }}
                isProceed={appInspectionFilter}
                items={inspectionData ?? []}
              />
            </Grid>
            {/* <PagingLeftAlign>
              <span style={{width: '100%'}} />
              * 긴급공지, 점검 기능은 게시글이 많지 않으므로 페이징 구현하지않음 *
              <Pagination
                totalPageCount={1}
                currentPageIndex={0}
                itemCountPerPage={10}
                setCurrentPageIndex={(e) => {
                  setPageIndex(e + 1)
                }}
                loading={false}
                onItemCountPerPageChanged={(event: any) => {}}
                isItemCountPerPageEnabled={false}
              />
            </PagingLeftAlign> */}
            <SalesNoticeWriteDialog />

            <SalesNoticeEditDialog
              isSelected={onSelected}
              clickIndexDataNumber={clikedNumber}
            />

            <SalesNoticeInspectionAddDialog />

            <SalesNoticeInspectionEditDialog
              isSelected={onSelected}
              clickIndexDataNumber={clikedNumber}
            />
          </Grid>
        </Grid>
      </CardWithTitle>
    </div>
  )
}
