import {
  TreatmentSearchNewRequest,
  TreatmentSearchRequest,
} from 'api/treatmentApi'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useHistory, useLocation} from 'react-router-dom'
import {
  searchTreatment,
  searchTreatmentNewAction,
  selectError,
  selectItems,
  selectLoading,
  selectOneItems,
  selectPaging,
  selectPagingInfo,
  selectPeriod,
  selectPeriodInfo,
  selectQuery,
  selectSearch,
  selectSearchInfo,
} from 'features/treatment/treatmentSearchSlice'

export default function useTreatmentSearch() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const onSearch = (payload: TreatmentSearchRequest) =>
    dispatch(searchTreatment(payload))

  const onSearchNew = (payload: TreatmentSearchNewRequest) => {
    try {
      const keyPairs = Object.entries(payload).map(([key, value]) => [
        key,
        typeof value === 'object' ? JSON.stringify(value) : value ?? '',
      ])
      const params = new URLSearchParams(keyPairs)
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return dispatch(searchTreatmentNewAction(payload))
  }

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const allItems = useAppSelector(selectItems)
  const oneItems = useAppSelector(selectOneItems)
  const period = useAppSelector(selectPeriod)
  const periodInfo = useAppSelector(selectPeriodInfo)
  const search = useAppSelector(selectSearch)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const searchInfo = useAppSelector(selectSearchInfo)
  const query = useAppSelector(selectQuery)

  return {
    onSearch,
    onSearchNew,
    loading,
    error,
    allItems,
    oneItems,
    period,
    periodInfo,
    search,
    searchInfo,
    paging,
    pagingInfo,
    query,
  }
}
