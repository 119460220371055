/**
 * 기관 관리 - 결제 관리 페이지 - 사용 내역 / 납부금
 * 1. 사용내역의 년-월 API(List of invoiced year-month) 호출해 사용 내역의 셀렉트 박스 변경
 * 2. 1의 데이터를 이용해 사용 내역, 납부금 API(Retrieve invoice with year-month) 호출해 사용 내역, 납부금 데이터 가공
 */

import {
  isoStringToDateAndTimeString,
  localDateToUtcString,
} from 'helpers/dateHelper'
import React from 'react'
import {
  fetchInvoicedYearMonthApi,
  // fetchUsageHistoryAndUnpaidVouchersApi,
} from '../../../../api/paymentApi'
import InvoicedYearMonthModel from '../Models/InvoicedYearMonthModel'
import UnpaidVouchersModel from '../Models/UnpaidVouchersModel'
import UsageHistoryAndUnpaidVouchersModel from '../Models/UsageHistoryAndUnpaidVouchersModel'

interface Failed {
  errorName: string[]
  errorValue: string
  errorCount: number
}
interface ProcessedData {
  name: string
  failed: Failed[] | null
  success(): number
  total: number
  failedTotal: number
}
export interface UsageHistoriesProcessedList {
  packages: ProcessedData[]
  bundles: ProcessedData[]
}

interface BeginEndMonthDate {
  begin?: string
  end?: string
}

export default function UsageHistoryAndUnpaidVouchersViewModel() {
  //
  const [isUsageHistoryLoaded, setIsUsageHistoryLoaded] =
    React.useState<boolean>(false)
  const [isUsageHistoryListLoading, setIsUsageHistoryListLoading] =
    React.useState<boolean>(false)
  const [isUnpaidVouchersListLoading, setIsUnpaidVouchersListLoading] =
    React.useState<boolean>(false)

  const [yearMonthList, setYearMonthList] =
    React.useState<InvoicedYearMonthModel>({} as never)
  const [selectedYearMonth, setSelectedYearMonth] = React.useState<string>('')

  // 사용내역 / 미납금
  const [
    usageHistoryAndUnpaidVouchersList,
    setUsageHistoryAndUnpaidVouchersList,
  ] = React.useState<UsageHistoryAndUnpaidVouchersModel>()

  // 미납금
  const [unpaidVouchersList, setUnpaidVouchersList] =
    React.useState<UnpaidVouchersModel[]>()

  // 사용 내역 - begin end month date
  const [beginEndMonthDate, setBeginEndMonthDate] =
    React.useState<BeginEndMonthDate>()

  // yearMonth로 셀렉트 박스 값 변경, 사용내역/미납금 api 가져오는 함수
  async function getUsageHistoryAndUnpaidVouchers(
    index: number,
    ref?: InvoicedYearMonthModel,
  ) {
    const reference = ref !== undefined ? ref : yearMonthList

    setIsUsageHistoryLoaded(false)
    setIsUnpaidVouchersListLoading(true)
    try {
      setSelectedYearMonth(reference.items[index].appearanceValue())
      // API: /payments/v2.0/{targetYearMonth}/invoice/

      const targetYearMonth = reference.items[index].yearMonth
      const res = await fetchUsageHistoryAndUnpaidVouchersApi(targetYearMonth)
      const usageHistoriesList = new UsageHistoryAndUnpaidVouchersModel(res)
      setUsageHistoryAndUnpaidVouchersList(usageHistoriesList)
      setUnpaidVouchersList(usageHistoriesList.unpaidVouchers)
      setIsUsageHistoryLoaded(true)
      setIsUnpaidVouchersListLoading(false)
    } catch (e) {
      console.log('Error:::: getUsageHistoryAndUnpaidVouchers:::: ', e)

      setUsageHistoryAndUnpaidVouchersList(undefined)
      setIsUnpaidVouchersListLoading(false)
    }
  }

  // yearMonth Api 호출
  async function getInvoicedYearMonth() {
    try {
      const res = await fetchInvoicedYearMonthApi()
      const yearMonths = new InvoicedYearMonthModel(res)
      setYearMonthList(() => yearMonths)

      // thumb값 최신 년-월로 초기 설정
      if (!yearMonths.items || yearMonths.items.length === 0) {
        setSelectedYearMonth('사용 내역이 없습니다.')
      } else {
        getUsageHistoryAndUnpaidVouchers(0, yearMonths)
      }
    } catch (e) {
      console.log('Error:::: getInvoicedYearMonth:::: ', e)
    }
  }

  const [usageHistoriesProcessedList, setUsageHistoriesProcessedList] =
    React.useState<UsageHistoriesProcessedList>()

  /**
   * @description 실패한 횟수를 구하는 함수
   * @param useHistories
   * @returns total
   */
  function getFailedTotal(
    useHistories: {
      createdAt: string
      failures: string[]
    }[],
  ) {
    let total = 0
    useHistories.map((history) => {
      if (history.failures.length > 0) total += 1
      return total
    })
    return total
  }

  /**
   * @description 하나의 패키지/번들에서 같은 오류명을 합쳐 오류횟수를 구하는 함수
   * @param data
   * @returns
   */
  function makeFailed(data: {
    name: string
    useHistories: {
      createdAt: string
      failures: string[]
    }[]
  }) {
    let total = 0
    data.useHistories.map((history) => {
      if (history.failures.length > 0) total += 1
      return null
    })
    if (total === 0) {
      return null
    }

    // 1. 빈 어레이 제거한 새로운 배열
    let fIdx = 0
    const failuresArray = [['']]
    data.useHistories.filter((history) => {
      if (history.failures.length > 0) {
        failuresArray[fIdx] = history.failures
        fIdx += 1
      }
      return false
    })

    // 2. 이중배열을 문자열로 바꾼 배열 생성(errorKey)
    const stringArray = ['']
    failuresArray.map((item, idx) => {
      stringArray[idx] = item.sort().join().toUpperCase()
      return null
    })

    // 3. 2를 복제해 중복을 제거한 새로운 배열 생성
    // const deduplicationArray = [...stringArray]
    const deduplicationArray = [stringArray[0]]
    stringArray.reduce((prev: string, curr: string) => {
      if (prev !== curr) {
        const isNew = !deduplicationArray.find((item) => item === curr)

        if (isNew) {
          deduplicationArray.push(curr)
        }
      }
      return curr
    })

    // 4. count를 포함한 리턴할 오브젝트 생성
    // i: 3의 중복 제거한 errorKey 배열과
    // j: 빈 어레이를 제거한 failuresArray 배열로 비교
    // i === j면 errorCount + 1 / 마지막 바퀴일 때 newArray[i] 생성
    const newArray: Failed[] = [
      {
        errorName: deduplicationArray[0].split(','),
        errorValue: deduplicationArray[0],
        errorCount: 0,
      },
    ]
    for (let i = 0; i < deduplicationArray.length; i += 1) {
      let errorCount = 0
      const errorKey = deduplicationArray[i]

      for (let j = 0; j < failuresArray.length; j += 1) {
        const currKey = failuresArray[j].sort().join().toUpperCase()
        if (errorKey === currKey) {
          errorCount += 1
        }

        if (j === failuresArray.length - 1) {
          newArray[i] = {
            errorName: errorKey.split(','),
            errorValue: errorKey.split(',').join(', '),
            errorCount,
          }
        }
      }
    }
    return newArray
  }

  /**
   * @description: UsageHistory packages name, used counts, error name
   */
  function makeUsageHistoryProcessedList() {
    setIsUsageHistoryListLoading(true)

    if (
      usageHistoryAndUnpaidVouchersList &&
      (usageHistoryAndUnpaidVouchersList?.usageHistory.packages.length > 0 ||
        usageHistoryAndUnpaidVouchersList?.usageHistory.bundles.length > 0)
    ) {
      const packs = usageHistoryAndUnpaidVouchersList.usageHistory.packages
      const bunds = usageHistoryAndUnpaidVouchersList.usageHistory.bundles

      const newPacks: ProcessedData[] = new Array(packs.length)
      packs.map((pack, idx) => {
        newPacks[idx] = {
          name: pack.name,
          total: pack.useHistories.length,
          failedTotal: getFailedTotal(pack.useHistories),
          failed: makeFailed(pack),
          success() {
            return this.total - this.failedTotal
          },
        }
        return newPacks
      })

      const newBunds: ProcessedData[] = new Array(bunds.length)
      bunds.map((pack, idx) => {
        newBunds[idx] = {
          name: pack.name,
          total: pack.useHistories.length,
          failedTotal: getFailedTotal(pack.useHistories),
          failed: makeFailed(pack),
          success() {
            return this.total - this.failedTotal
          },
        }
        return newBunds
      })

      setUsageHistoriesProcessedList((prev) => {
        const newData = {...prev, packages: newPacks, bundles: newBunds}
        return newData
      })

      setIsUsageHistoryListLoading(false)
    } else {
      setUsageHistoriesProcessedList(undefined)
      setIsUsageHistoryListLoading(false)
    }
  }

  /**
   * @description: make usage history begin end date for a month
   */
  function makeUsageHistoryBeginEndMonthDate() {
    if (usageHistoryAndUnpaidVouchersList) {
      const todayMonth = Number(
        isoStringToDateAndTimeString(localDateToUtcString(new Date())).split(
          '-',
        )[1],
      )
      const selectedYear = Number(selectedYearMonth.split('-')[0])
      const selectedMonth = Number(selectedYearMonth.split('-')[1])

      let endVariable = new Date()
      if (todayMonth > selectedMonth) {
        endVariable = new Date(selectedYear, selectedMonth, 0)
      }
      setBeginEndMonthDate({
        begin: isoStringToDateAndTimeString(
          usageHistoryAndUnpaidVouchersList.begin,
        ).split(' ')[0],
        end: isoStringToDateAndTimeString(
          localDateToUtcString(endVariable),
        ).split(' ')[0],
      })
    } else {
      setBeginEndMonthDate(undefined)
    }
  }

  return {
    yearMonthList,
    selectedYearMonth,
    getInvoicedYearMonth,
    getUsageHistoryAndUnpaidVouchers,
    usageHistoryAndUnpaidVouchersList,
    isUsageHistoryLoaded,
    makeUsageHistoryProcessedList,
    usageHistoriesProcessedList,
    unpaidVouchersList,
    makeUsageHistoryBeginEndMonthDate,
    beginEndMonthDate,
    isUnpaidVouchersListLoading,
    isUsageHistoryListLoading,
  }
}
