import {createStyles} from '@material-ui/core'

export const Colors = {
  primary: '#0cc7c7',
  secondary: '#5a6268',
  white: '#fff',
  red: '#e84139',
  black: '#000',
  selectBorder: '#E0E0E0',
  darkblue: '#4d5d6b',
  text: {
    content: '#333333',
    title: '#676a6c',
    input: '#666a6c',
    input_lighten: '#868e96',
  },
  error: '#d03c35',
  tableHeaderBackground: '#e9ecef',
  tableHeaderBackgroundColor: '#f4f4f7',
  tableHoverBackground: '#f4f4f7',
  border: '#e4e7eb',
  pagination: {
    color: '#a4a4a4',
    border: '#dee2e6',
    activeBg: '#f4f4f4',
    disabled: '#e1e1e1',
  },
  confirmButton: '#3cbbbd',
  cancelButton: '#fff',
  cancelButtonBorder: '#e9ecef',
  cancelButtonLight: '#f4f4f4',
  comment: '#728A9B',
  report: '#495e6c',
  nineX3: '#999',
  roi: {
    underActive: '#e95160',
    balanced: '#5c829c',
    overActive: '#1eaae3',
  },
  warning: '#f8ac59',
  chip: {
    chipBackgroundColor: '#f4f4f7',
  },
  newIcon: {
    newIconBackgroundColor: '#32c7c9',
  },
  notice: {
    noticeDeleteButtonColor: '#586269',
    noticeCancleIconColor: '#c5c5c5',
    noticeFileViewBackgroundColor: '#eff1f4',
    noticeRadioButtonBackgroundColor: '#32c7c9',
    noticeTimeInputTextBorderColor: '#e9ecef',
    noticeRadioButtonBorderColor: '#b7b7b7',
    noticeRadioButtonTextColor: '#555',
    noticeInputPlaceHolder: '#b7b9bc',
    noticeInputTextDisable: '#f6f8fa',
  },
  deleteButtonBackgroundColor: 'rgba(88, 98, 105, 0)',
  calenderSubmitBackground: '#eff2f7',
  calenderIcon: 'rgba(0, 0, 0, 0.54)',
  calenderBoxShadow: '15px 10px 30px rgba(0, 0, 0, 0.1)',
} as const

export const Spacing = {
  select: {
    selectBorderNone: 0,
    selectWidth: 143,
    selectListItemWidth: 142,
    selectListTopBorder: 1,
    selectListPositionRight: 0,
    selectListPositionTop: 0,
    selectHeight: 42,
    selectBorder: 1,
    selectBorderRadius: 4,
    selectBackgroundPositionTopDown: 90,
    selectBackgroundPositionRightLeft: 50,
    selectBackgroundSizeTopDown: 6,
    selectBackgroundSizeRightLeft: 10,
    selectMargin: 7,
    selectTextIndent: 15,
    selectTextLineHeight: 37,
    selectCustomZindex: 999,
    selectCustomHeaderIndex: 0,
    selectCustomListPadding: 20,
    selectTextSize: 13,
  },
  chip: {
    chipWidth: 63,
    chipHeight: 24,
    chipBorderRadius: 20,
    chipFontSize: 0.8,
    chipLineHeight: 24,
  },
  marginCustom: 3,
  paddingCustom: 5,
  paddingPaginationTop: 20,
  paddingPaginationRight: 0,

  table: {
    tablewidth: 100,
    tableBorderRadiusTop: 25,
    tableBorderRadiusRight: 10,
    tableHeaderHeight: 50,
    tableBorderBottom: 1,
    tableHeaderFontSize: 14,
    tableHeaderFontWeight: 700,
    tableBodyHeight: 50,
  },
  newIcon: {
    newIconWidth: 42,
    newIconHeight: 18,
    newIconBorderRadius: 11,
    newIconFontSize: 10,
    newIconMarginTop: 2,
    newIconMarginRight: 6,
  },
  notice: {
    noticeTitleMarginRight: 15,
    noticeTitleMarginTop: 17,
    noticeTitleFontSize: 15,
    noticeTitleFontWeight: 500,
    noticeDivWidth: 100,
    noticeDivWidthMarginBottom: 60,
    noticeTextWidth: 52,
    noticeTextHeight: 30,
    noticeTextBorder: 1,
    noticeTextMargin: 0,
    noticeTextMarginRight: 5,
    noticeInputTitleFontSize: 11,
    noticeInputTitleMarginTop: 15,
    noticeContentPaddingBorder: 50,
    noticeRadioButtonTextWidth: 70,
    noticeRadioButtonContainerHeight: 32,
    noticeRadioButtonLabelWidth: 14,
    noticeRadioButtonZindex: 1,
    noticeRadioButtonWidth: 6,
    noticeRadioButtonMargin: 3,
    noticeRadioButtonFontSize: 12,
    noticeRadioButtonFontLeft: 6,
    noticeRadioButtonFontbottom: 2,
    noticeInputMargin: 6,
    noticeInputTitleWidth: 280,
    noticeInputTitleHeight: 33,
    noticeInputTitlePadding: 10,
    noticeInputContentHeight: 92,
    noticeInputContentPaddingTop: 10,
    noticeInputContentPaddingRight: 10,
    noticeInputContentPaddingBottom: 65,
    noticeInputPlaceHolderPosition: 25,
    noticeFileMargin: 19,
    noticeFileMarginTop: 15,
    noticeFileWidth: 55,
    noticeFileHeight: 33,
    noticeFileBorderRadius: 5,
    noticeFileFontSize: 10,
    noticeFileViewMarginLeft: 54,
    noticeFileViewMarginTop: 15,
    noticeFileViewMarginBottom: 19,
    noticeFileViewWidth: 128,
    noticeFileViewHeight: 33,
    noticeFileViewPadding: 3,
    noticeFileViewFontSize: 12,
    noticeFileViewLineHeight: 30,
    noticeFileDeleteWidth: 74,
    noticeFileDeleteHeight: 33,
    noticeFileDeleteBorderRadius: 3,
    noticeFileLabelWidth: 60,
    noticeFilelabelMargin: 18,
    noticeCloseIconTop: 8,
    noticeCloseIconRight: 6,
    noticeCloseIconWidth: 14,
    noticeDialogWidth: 750,
    noticeContentWidth: 300,
    noticeContentPadding: 5,
    noticeInputTitleCustomWidth: 55,
    noticeInputTitleCustomMarginTop: 25,
    noticeDivMarginTop: 32,
    noticeInspectionDivMarginTop: 45,
    noticeInputTitleTextCustomMarginTop: 47,
    noticeInputEnTitleCustomMarginTop: 37.5,
    noticeInputTitleTextCustomMarginBottom: 30,
    noticeButtonBackgroundPaddingTop: 15,
    noticeButtonBackgroundPaddingLeft: 0,
    noticeButtonMarginRight: 9,
    noticeButtonWidth: 126,
    noticeButtonFontSize: 12,
    noticeDeleteButtonWidth: 52,
    noticeDeleteButtonHeight: 31,
    noticeDeleteButtonFontSize: 13,
    noticeDeleteButtonBorderRadius: 4,
  },
  calender: {
    calenderWidth: 129,
    calenderWrapperWidth: 332,
    calenderHeight: 30,
    calenderBorderRadius: 4,
    calenderParentDateMargin: 6,
    calenderFontSize: 12,
    calenderIconMarginLeft: 9,
    calenderIconFontSize: 13,
    calenderDividerMargin: 10,
    calenderTypoMarginLeft: 5,
    calenderOpenPosition: 0,
    calenderOpenZindex: 999,
    calenderDateWrapBorder: 1,
    calenderSubmitButtonPadding: 10,
  },
  report: {
    reportTranslationMargin: 50,
    reportCustomPadding: 20,
  },
} as const

const themedStyles = () =>
  createStyles({
    absoluteAndCenter: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
export const Styles = themedStyles
