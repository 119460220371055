import {ThreeDAnalysisType, ThreeDPowerResponse} from 'api/analysisApi'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React, {useMemo} from 'react'
import styled, {css} from 'styled-components'

export const PowerMap: Record<ThreeDAnalysisType, string> = {
  P: 'Voxel power(Abs.)',
  R: 'Voxel power(Rel.)',
  C: 'Connectivity',
}

export const PowerPriorityMap: Record<ThreeDAnalysisType, number> = {
  P: 3,
  R: 2,
  C: 1,
}

interface StyledTabItemProps {
  theme: ThreeDTheme
  isActive: boolean
  loading: boolean
}

const StyledTabItem = styled.li<StyledTabItemProps>`
  margin-top: 15px;
  opacity: ${(props) => (props.loading ? 0.4 : 1)};
  border: 1px solid #e7eaec;
  ${(props) =>
    props.theme === 'Dark' &&
    props.isActive &&
    css`
      border-color: #3ebbbd;
    `}
  ${(props) =>
    props.theme === 'Dark' &&
    !props.isActive &&
    css`
      border-color: #333;
    `}
  padding: 0;
  position: relative;
  text-align: center;
  flex: 1;
  ${(props) =>
    props.theme === 'Dark' &&
    css`
      background-color: transparent;
    `}
  ${(props) =>
    props.theme === 'Light' &&
    props.isActive &&
    css`
      background-color: #fff;
    `}
  ${(props) =>
    props.theme === 'Light' &&
    !props.isActive &&
    css`
      background-color: #f8fafb;
    `}
  //background-color: ${(props) => (props.isActive ? '#fff' : '#f8fafb')};

  & button {
    cursor: pointer;
    width: 100%;
    border: none;
    background-color: transparent;
    display: block;
    padding: 10px;
    color: ${(props) => (props.isActive ? '#3ebcbd' : '#9ba1a5')};

    ${(props) =>
      props.isActive &&
      css`
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: -1px;
          background-color: ${props.theme === 'Light' ? '#fff' : '#3ebcbd'};
        }
      `}
  }
`

interface ThreeDPowerTabItemProps {
  theme: ThreeDTheme
  loading: boolean
  value: ThreeDAnalysisType
  current: ThreeDAnalysisType
  onPowerChange: (power: ThreeDAnalysisType) => Promise<void>
}

function ThreeDPowerTabItem({
  theme,
  loading,
  value,
  current,
  onPowerChange,
}: ThreeDPowerTabItemProps) {
  const handlePowerChange = () => onPowerChange(value)
  return (
    <StyledTabItem isActive={value === current} loading={loading} theme={theme}>
      <button type='button' onClick={handlePowerChange} disabled={loading}>
        {PowerMap[value]}
      </button>
    </StyledTabItem>
  )
}

const StyledUl = styled.ul`
  overflow: hidden;
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
  margin: 0 0 15px;
`

interface ThreeDPowerTabProps {
  loading: boolean
  theme: ThreeDTheme
  analysis_type: ThreeDAnalysisType
  supported_power: ThreeDPowerResponse[]
  onPowerChange: (power: ThreeDAnalysisType) => Promise<void>
}

function PowerTab({
  theme,
  loading,
  analysis_type,
  supported_power,
  onPowerChange,
}: ThreeDPowerTabProps) {
  const powers = useMemo(
    () =>
      [
        ...supported_power.reduce((accr, curr) => {
          accr.add(curr.analysisType)
          return accr
        }, new Set<ThreeDAnalysisType>()),
      ].sort((a, b) => {
        const aPriority = PowerPriorityMap[a]
        const bPriority = PowerPriorityMap[b]
        return bPriority - aPriority
      }),
    [supported_power],
  )

  return (
    <StyledUl>
      {powers.map((item) => (
        <ThreeDPowerTabItem
          theme={theme}
          loading={loading}
          current={analysis_type}
          value={item}
          key={item}
          onPowerChange={onPowerChange}
        />
      ))}
    </StyledUl>
  )
}

export default PowerTab
