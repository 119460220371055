import {HRVSummaryResponse} from 'api/analysisApi'
import HRVSummaryAutoNervousSystem from 'components/molcules/ProfitSummaryReport/HRV/HRVSummaryAutoNervousSystem'
import HRVSummarySentimental from 'components/molcules/ProfitSummaryReport/HRV/HRVSummarySentimental'
import HRVSummaryStress from 'components/molcules/ProfitSummaryReport/HRV/HRVSummaryStress'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React, {ReactNode} from 'react'

interface HRVSummaryProps extends HRVSummaryResponse {
  CustomerCard?: ReactNode
  tableImage: string
  contourImage: string
  histogramImage: string
  contour: number
  clientFirstName: string
  clientLastName: string
}

export default function HRVSummary(props: HRVSummaryProps) {
  const {
    circularImage,
    balanceOpinion,
    depression,
    phase,
    anxiety,
    CustomerCard,
    tableImage,
    contourImage,
    histogramImage,
    contour,
    histogram,
    clientFirstName,
    clientLastName,
    reportType,
  } = props

  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      {/* Basic analysis */}
      <HRVSummaryAutoNervousSystem
        customerFirstName={clientFirstName}
        customerLastName={clientLastName}
        balanceCircularImagePath={circularImage}
        balanceOpinion={balanceOpinion}
        tableImagePath={tableImage}
        contourImagePath={contourImage}
        histogramImagePath={histogramImage}
        contour={contour}
        histogram={histogram}
      />
      {/* Premium analysis */}
      {reportType === 'P2' && <HRVSummaryStress phase={phase} />}
      {reportType === 'P2' && (
        <HRVSummarySentimental
          depression={depression}
          anxiety={anxiety}
          customerFirstName={clientFirstName}
          customerLastName={clientLastName}
        />
      )}

      <div className='Warning'>{t('IHRVSummaryReportWarning')}</div>
    </div>
  )
}
