import {FetchPeriodUsageListRequest} from 'api/salesApi'
import {useAppDispatch, useAppSelector} from 'hooks'
import {fetchUsageAction, selectPeriodUsageList} from './salesSlice'

export default function useSalesUsage() {
  const dispatch = useAppDispatch()
  const onSearch = (payload: FetchPeriodUsageListRequest) =>
    dispatch(fetchUsageAction({...payload}))

  const periodUsageList = useAppSelector(selectPeriodUsageList)

  return {
    onSearch,
    periodUsageList,
  }
}
