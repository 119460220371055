import {useState} from 'react'
import {useAppDispatch, useAppSelector} from 'hooks'
import {
  UpdateInvoiceRequest,
  fetchTemplateInvoice,
  getBillingSummaryInvoiceApi,
  getInfoConsumerInvoiceApi,
  getInvoiceApi,
  getProviderInvoiceApi,
  getUsageDetailInvoiceApi,
  fetchListVersionInvoice,
  PostInvoiceRequestType,
  postInvoiceAPI,
  getConfirmInvoiceApi,
  getProviderLatest,
} from 'api/invoiceApis'
import {
  selectConsumer,
  selectBillingSummary,
  selectProvider,
  selectUsageDetail,
  selectProduct,
  selectTemplates,
  selectUsageSummaryData,
  setUsageSummaryData,
  selectPatchInvoiceData,
  selectListVersion,
  selectError,
  initData,
  DataTableUsageSummary,
  updateInvoice,
  setPatchInvoiceData,
  setTemplates,
  setError,
  selectConfirmInvoice,
  selectBaseFees,
  setProduct,
  initDataEmptyInvoice,
  resetEmptyData,
} from 'features/invoice/invoiceViewSlice'

export function useInvoiceView() {
  const dispatch = useAppDispatch()

  const error = useAppSelector(selectError)
  const consumer = useAppSelector(selectConsumer)
  const billingSummary = useAppSelector(selectBillingSummary)
  const provider = useAppSelector(selectProvider)
  const usageDetail = useAppSelector(selectUsageDetail)
  const product = useAppSelector(selectProduct)
  const templates = useAppSelector(selectTemplates)
  const usageSummaryData = useAppSelector(selectUsageSummaryData)
  const patchInvoiceData = useAppSelector(selectPatchInvoiceData)
  const listVersion = useAppSelector(selectListVersion)
  const confirmInvoice = useAppSelector(selectConfirmInvoice)
  const baseFees = useAppSelector(selectBaseFees)
  const [isEmptyTemp, setIsEmptyTem] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onInitInvoiceViewData = async (invoiceId: number) => {
    setLoading(true)
    const billingSummaryData = await getBillingSummaryInvoiceApi({invoiceId})
    const consumerData = await getInfoConsumerInvoiceApi({invoiceId})
    const providerData = getProviderInvoiceApi({invoiceId})
    const usageDetailData = getUsageDetailInvoiceApi({invoiceId})
    const templatesData = fetchTemplateInvoice(
      billingSummaryData.data.productId,
    )
    const productData = getInvoiceApi({pid: billingSummaryData.data.productId})
    const listVersionData = fetchListVersionInvoice(consumerData.data.orgId)
    const getConfirmInvoice = await getConfirmInvoiceApi({invoiceId})
    const [
      providerDataRes,
      usageDetailDataRes,
      templatesDataRes,
      productDataRes,
      listVersionDataRes,
      confirmInvoiceRes,
    ] = await Promise.all([
      providerData,
      usageDetailData,
      templatesData,
      productData,
      listVersionData,
      getConfirmInvoice,
    ])

    setIsEmptyTem(!listVersionDataRes.list.length)
    dispatch(
      initData({
        consumer: consumerData.data,
        billingSummary: billingSummaryData.data,
        provider: providerDataRes.data,
        usageDetail: usageDetailDataRes.list,
        product: productDataRes.data,
        templates: templatesDataRes.list,
        listVersion: listVersionDataRes.list,
        confirmInvoice: confirmInvoiceRes.data,
      }),
    )
    setLoading(false)
  }

  const onInitEmptyInvoice = async (orgId: number) => {
    setLoading(true)
    const listVersionData = fetchListVersionInvoice(orgId)
    const providerData = getProviderLatest()
    const [listVersionDataRes, providerDataRes] = await Promise.all([
      listVersionData,
      providerData,
    ])
    dispatch(
      initDataEmptyInvoice({
        provider: providerDataRes.data,
        listVersion: listVersionDataRes.list,
      }),
    )
    setIsEmptyTem(!listVersionDataRes.list.length)
    setLoading(false)
  }

  const updateUsageSummaryAction = (data: DataTableUsageSummary[]) => {
    dispatch(setUsageSummaryData(data))
  }

  const convertDate = (dateStr: string | undefined): string => {
    if (!dateStr) return ''
    const [year, month, day] = dateStr.split('-')
    if (year && month && day) return `${year}${month}${day}`
    return dateStr
  }

  const callAPIUpdateInvoice = async (invoiceId: number) => {
    if (patchInvoiceData)
      return dispatch(
        updateInvoice({
          invoiceId,
          data: {
            ...patchInvoiceData,
            billingDate: convertDate(patchInvoiceData.billingDate),
          },
        }),
      )

    return undefined
  }

  const callAPICreateInvoice = async ({
    orgId,
    productId,
    usageMonth,
  }: PostInvoiceRequestType) => {
    const res = await postInvoiceAPI({orgId, productId, usageMonth})
    if (!patchInvoiceData && res.data) {
      return {payload: true}
    }
    if (patchInvoiceData && res.data)
      return dispatch(
        updateInvoice({
          invoiceId: res.data,
          data: {
            ...patchInvoiceData,
            billingDate: convertDate(patchInvoiceData.billingDate),
          },
        }),
      )

    return undefined
  }

  const settingInvoiceDataAction = (data: UpdateInvoiceRequest) => {
    dispatch(setPatchInvoiceData(data))
  }

  const changeVersionAction = async (id: number) => {
    const dataTempRes = await fetchTemplateInvoice(id)
    dispatch(setProduct(id))
    if (dataTempRes.list) dispatch(setTemplates(dataTempRes.list))
  }

  const resetInvoiceDataAction = () => {
    dispatch(resetEmptyData())
  }

  return {
    error,
    baseFees,
    consumer,
    billingSummary,
    provider,
    usageDetail,
    product,
    templates,
    usageSummaryData,
    patchInvoiceData,
    listVersion,
    isEmptyTemp,
    confirmInvoice,
    onInitInvoiceViewData,
    onInitEmptyInvoice,
    updateUsageSummaryAction,
    callAPIUpdateInvoice,
    callAPICreateInvoice,
    settingInvoiceDataAction,
    changeVersionAction,
    resetInvoiceDataAction,
    setError,
    loading,
  }
}

export default useInvoiceView
