import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from 'hooks'
import {Button, Typography} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Card from 'components/atoms/Card'
import {
  closeConfirmOpenInvoiceSettingDialog,
  selectConfirmInvoiceSettingDialogOpen,
} from 'features/modal/modalSlice'
import useDialogInvoiceViewStyles from 'pages/CustomerManagement/InvoiceView/DialogInvoiceView/Style'

export default function UserConfirmOpenInvoiceSettingDialog() {
  const {t} = useTranslation()
  const classes = useDialogInvoiceViewStyles()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectConfirmInvoiceSettingDialogOpen)
  const handleClose = () => {
    dispatch(closeConfirmOpenInvoiceSettingDialog())
  }

  return (
    <div>
      <Dialog open={open} maxWidth='md'>
        <Card className={classes.cardWrap}>
          <div className={classes.root}>
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <Typography className={classes.title}>
                  {t('INotification')}
                </Typography>
              </div>
            </div>
            <div className={classes.scroll}>
              <div className={classes.container}>
                {t('IConfirmGoToViewSetting')}
              </div>
            </div>
            <div className={classes.popupBottom}>
              <div className={classes.buttonContainerClose}>
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={handleClose}
                >
                  {t('IOk')}
                </Button>
                <Typography className={classes.smallPadding} />
              </div>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
