import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import SubTitle from 'components/Text/SubTitle'
import {useTranslation} from 'react-i18next'
import React from 'react'

import GasPhaseChart1 from 'assets/img/img_gas_phase_01.png'
import GasPhaseChart2 from 'assets/img/img_gas_phase_02.png'
import GasPhaseChart3 from 'assets/img/img_gas_phase_03.png'
import GasPhaseChart4 from 'assets/img/img_gas_phase_04.png'
import GasPhaseChart5 from 'assets/img/img_gas_phase_05.png'

const GasPhaseChartList = [
  GasPhaseChart1,
  GasPhaseChart2,
  GasPhaseChart3,
  GasPhaseChart4,
  GasPhaseChart5,
]

const getGasPhasePosition = (phase: number) => {
  if (phase < 1 || phase > 5) return '-100%'
  if (phase === 1) return '11.862%'
  if (phase === 2) return '31.367%'
  if (phase === 3) return '50.903%'
  if (phase === 4) return '70.435%'
  if (phase === 5) return '90.103%'
  return '-100%'
}

export default function HRVSummaryStress(props: {phase: number}) {
  const {phase} = props
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div className={classes.hrvStress}>
      <SubTitle className='ReportTitle'>
        2. {t('IHRVSummaryReportAIStressTitle')}
      </SubTitle>
      <div className='Wrapper'>
        <img
          className='PhaseChart'
          src={GasPhaseChartList[phase - 1]}
          alt='Gas Phase chart'
        />
        <span
          className='PhaseChart-Label'
          style={{left: getGasPhasePosition(phase)}}
        >
          {t('ICurrentStatus')}
        </span>
      </div>
    </div>
  )
}
