import {makeStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ConfirmButtonProps extends ConfirmationButtonProps {}

export const RootClass = 'ConfirmButton'

const styles = () =>
  createStyles({
    root: {
      color: 'white',
    },
  })
const useStyles = makeStyles(styles)

const ConfirmButton = ({className, ...props}: ConfirmButtonProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Button
      variant='contained'
      color='primary'
      disableElevation
      {...props}
      className={`${classes.root} ${RootClass} ${className} `}
    >
      {t('IOk')}
    </Button>
  )
}

export default ConfirmButton
