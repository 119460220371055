import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReorderIcon from '@material-ui/icons/Reorder'
import {UserLogListRequest} from 'api/salesApi'
import CardWithPoint from 'components/atoms/CardWithPoint'
import CardWithTitle from 'components/atoms/CardWithTitle'
import PointPaymentDialog from 'components/Dialog/PointPaymentDialog'
import SalesOrganizationEditDialog from 'components/Dialog/Sales/Organization/Edit'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import {
  openSalesOrgEditDialog,
  selectSalesOrgEditDialogOpen,
} from 'features/modal/modalSlice'
import useSalesOrganization from 'features/org/useSalesOrganization'
import {fetchPaymentAction} from 'features/payment/paymentSlice'
import {
  fetchPointInfoAction,
  fetchUsedPointListAction,
  selectedOidRequest,
} from 'features/point/pointSlice'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import useSalesAllOrgFetch from 'features/sales/useSalesAllOrg'
import {nowYearDate} from 'helpers/dateHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import SalesPaymentHistory from 'pages/SalesPages/SalesPaymentPage/SalesPaymentHistory'
import SalesPointHistory from 'pages/SalesPages/SalesPaymentPage/SalesPointHistory'
import SalesPointRating from 'pages/SalesPages/SalesPaymentPage/SalesPointRating'
import PointInfo from 'pages/StorePages/PointInfo'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    indexInputContainer: {
      marginTop: 6,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
      marginTop: 3,
    },
    analBoxWrap: {
      marginTop: 10,
    },
    analBox: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 80px 15px 80px',
      border: '1px solid rgb(53 73 99 / 20%)',
      position: 'relative',
      borderRadius: '4px',
    },
    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      width: '100%',
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
)

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
      height: 450,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      height: 50,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
  }),
)

interface HeadCell {
  id: keyof AllOrganizationContent
  label: string
}
const headCells: HeadCell[] = [
  {id: 'id', label: 'IHospitalNo'},
  {id: 'name', label: 'IHospitalName'},
  {id: 'email', label: 'IEmail'},
  {id: 'salesName', label: 'ITel'},
  {id: 'oid', label: 'ISalesManager'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
        <TableCell align='center'>{t('IDetailInfo')}</TableCell>
      </TableRow>
    </TableHead>
  )
}

interface UserAccountTableProps {
  refetchQuery: UserLogListRequest
  onChangeTotalCount: (count: number) => void
  onChangeClicked: (clicked: boolean) => void
}

function UserAccountTable({
  refetchQuery,
  onChangeTotalCount,
  onChangeClicked,
}: UserAccountTableProps) {
  const classes = useTableStyles()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {user: currentUser} = useAuth()
  const open = useAppSelector(selectSalesOrgEditDialogOpen)
  const queryClient = useQueryClient()
  const [oid, setOid] = React.useState<string>()

  const [query, setQuery] = React.useState<UserLogListRequest>({
    uid: currentUser?.uid,
    paging: {
      page: 0,
      size: 5,
    },
    search: null,
    period: {
      startDate: '',
      endDate: '',
    },
  })

  const {data} = useSalesAllOrgFetch(query)

  const setPageIndex = (page: number) =>
    setQuery({...query, paging: {page, size: 5}})

  const {onFetch: readDataFetch, organizationUser} = useSalesOrganization()

  const fetchOrganizationInfo = async (oid: string) => {
    await setOid(oid)
  }
  const handleReadClick = async (oid: string) => {
    fetchOrganizationInfo(oid).then(() => dispatch(openSalesOrgEditDialog()))
  }
  const getUid = (organizationUser: SalesUserList[] | undefined): string => {
    if (organizationUser) return organizationUser[0].uid
    return ''
  }
  const handlePaymentAction = async () => {
    await dispatch(
      fetchPaymentAction({
        paging: {
          page: 0,
          size: 5,
        },
        uid: getUid(organizationUser),
        oid,
      }),
    )
  }
  const handlePointInfoAction = async (oid: string) => {
    await dispatch(
      fetchPointInfoAction({
        uid: getUid(organizationUser),
        oid,
        month: nowYearDate(),
      }),
    )
  }

  const handleTableRowClick = async (oid: string) => {
    try {
      dispatch(
        fetchUsedPointListAction({
          page: 0,
          size: 5,
          uid: currentUser?.uid ?? '',
          oid,
        }),
      )
      readDataFetch(oid).then(() => {
        onChangeClicked(true)
      })
      setOid(oid)
      dispatch(selectedOidRequest(oid))
    } catch (e) {
      console.log(e)
    }
  }

  const emptyRows =
    data?.data.content === null || data?.data.content === undefined
      ? 5
      : 5 - data?.data.content.length

  useEffect(() => {
    queryClient.invalidateQueries('fetchOrganizationList')
    setQuery({...refetchQuery})
  }, [refetchQuery])

  useEffect(() => {
    onChangeTotalCount(data?.data.totalElements ?? 0)
  }, [data])

  useEffect(() => {
    handlePaymentAction()
    handlePointInfoAction(oid ?? '')
  }, [organizationUser])

  return (
    <>
      <TableContainer className={classes.container}>
        <PointPaymentDialog />
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
          size='medium'
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {data?.data.content != null &&
              data?.data.content.map((id: any) => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    classes={{hover: classes.hover}}
                    onClick={() => handleTableRowClick(id.oid)}
                  >
                    <TableCell align='center'>{id.id ?? '-'}</TableCell>
                    <TableCell align='center'>{id.name ?? '-'}</TableCell>
                    <TableCell align='center'>{id.email ?? '-'}</TableCell>
                    <TableCell align='center'>{id.tel ?? '-'}</TableCell>
                    <TableCell align='center'>{id.salesName ?? '-'}</TableCell>
                    <TableCell align='center'>
                      <Button
                        color='secondary'
                        variant='outlined'
                        size='small'
                        disableElevation
                        onClick={() => {
                          fetchOrganizationInfo(id.oid)
                          handleReadClick(id.oid)
                        }}
                      >
                        {t('IRead')}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={data?.data.totalPages ?? 0}
          currentPageIndex={data?.data.pageable.pageNumber ?? 0}
          itemCountPerPage={data?.data.pageable.pageSize ?? 0}
          setCurrentPageIndex={setPageIndex}
          loading={false}
          onItemCountPerPageChanged={() => {}}
          isItemCountPerPageEnabled={false}
        />
        <SalesOrganizationEditDialog oid={oid} open={open} />
      </TableContainer>
    </>
  )
}

export default function OldSalesPaymentPage() {
  const classes = useStyles()
  const {t} = useTranslation()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {user: currentUser} = useAuth()
  const selectedOid = useSelector(selectAllOrgOid)

  const [totalCount, setTotalCount] = React.useState(0)
  const [inputSearched, setInputSearched] = React.useState<string>()
  const [searchedSelectedType, setSearchedSelectedType] =
    React.useState<string>()
  const [searchedListValue, setSearchedListValue] = React.useState('')
  const [isClicked, setIsClicked] = React.useState(false)

  const makeChipLable = (data: number) => {
    const chipLabel = `Total ${data}`
    return chipLabel
  }

  const searchedList = ['IIndex', 'IOrgName', 'IEmail', 'ITel', 'ISalesManager']
  const searchedListMap = searchedList.map((value, index) => (
    <MenuItem value={index}>{t(value)}</MenuItem>
  ))
  const searchedType = ['INDEX', 'NAME', 'EMAIL', 'TEL', 'ADMIN']
  const searchedChange = (event: React.ChangeEvent<{value: any}>) => {
    setSearchedListValue(event.target.value as string)
    setSearchedSelectedType(
      searchedType[Number((event.target.value as string) ?? '0')],
    )
  }

  const handleValue = (value: any) => {
    setInputSearched(value)
  }

  const [query, setQuery] = React.useState<UserLogListRequest>({
    uid: currentUser?.uid,
    paging: {
      page: 0,
      size: 5,
    },
    search: null,
    period: {
      startDate: '',
      endDate: '',
    },
  })

  const handleSearch = () => {
    setQuery({
      ...query,
      search: {
        searchName: searchedSelectedType ?? '',
        searchValue: inputSearched ?? '',
      },
    })
  }

  return (
    <div>
      <CardWithTitle title={t('ISearchUsers')}>
        <Grid className={classes.navContainer}>
          <Grid item xs={12}>
            <Grid container className={classes.dateContainer}>
              <Chip size='small' label={makeChipLable(totalCount)} />
              <Typography className={classes.padding} />
              <div className={classes.indexInputContainer}>
                <FormControl variant='outlined'>
                  <Select
                    value={searchedListValue}
                    onChange={searchedChange}
                    displayEmpty
                    color='primary'
                    input={
                      <OutlinedInput
                        name='age'
                        id='outlined-age-simple'
                        classes={outlinedInputClasses}
                      />
                    }
                  >
                    <MenuItem disabled value=''>
                      <Typography className={classes.disableText}>
                        {t('ISelect')}
                      </Typography>
                    </MenuItem>
                    {searchedListMap}
                  </Select>
                </FormControl>
                <SearchBar
                  onChangeValue={handleValue}
                  onSearchEvent={handleSearch}
                  disabledValue={false}
                />
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<ReorderIcon />}
                  disableElevation
                  onClick={() => {
                    setQuery({
                      uid: currentUser?.uid,
                      paging: {
                        page: 0,
                        size: 5,
                      },
                      search: null,
                      period: {
                        startDate: '',
                        endDate: '',
                      },
                    })
                  }}
                >
                  {t('IAllAccount')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.tableWrap}>
          <UserAccountTable
            refetchQuery={query}
            onChangeTotalCount={(e) => setTotalCount(e)}
            onChangeClicked={(e) => setIsClicked(e)}
          />
        </Grid>
      </CardWithTitle>
      <Typography className={classes.padding} />
      {isClicked && (
        <div>
          {/* <CardWithPoint title='포인트' buttons={[PointPaymentButton()]}></CardWithPoint> */}
          <CardWithPoint title='포인트'>
            <div style={{margin: '10px 60px'}}>
              <PointInfo selectOid={selectedOid ?? ''} />
            </div>

            <div
              style={{
                backgroundColor: '#fbfbfb',
                height: '100%',
                padding: '20px 0 40px 0 ',
              }}
            >
              <div style={{margin: '0 60px'}}>
                <SalesPointRating />
              </div>
            </div>
          </CardWithPoint>
          <Typography className={classes.padding} />
          <Typography className={classes.padding} />
          <SalesPointHistory />
          <Typography className={classes.padding} />
          <SalesPaymentHistory />
          <Typography className={classes.padding} />
        </div>
      )}
    </div>
  )
}
