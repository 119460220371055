import {AllPackages} from 'api/paymentApi'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import SubTitle from 'components/Text/SubTitle'
import {makePackagesName, removeDot} from 'helpers/paymentHelper'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const PackageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 25% 10%;
  & td {
    border-bottom: 1px solid #e4e7eb;
    height: 43px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 10px;
  }
`

interface DomesticPackagesProps {
  items: AllPackages[]
}
const ForeignPackageTable = ({items}: DomesticPackagesProps) => {
  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IDefault'},
    {id: '2', label: 'IProductName'},
    {id: '3', label: 'IIncludedProducts'},
    {id: '4', label: 'IForeignPrice'},
    {id: '3', label: 'ICount'},
    {id: '3', label: 'IExpirationAt'},
  ]
  const {t} = useTranslation()

  return (
    <div style={{width: '100%'}}>
      <SubTitle>{t('IForeignTableTitle')}</SubTitle>
      <PackageTable>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={item.label}
            length={HeaderCells.length}
            index={index}
          />
        ))}
        {items?.map((item, index) => (
          <RoundTableWrap key={index}>
            {item.isDefault && <td>default </td>}
            {!item.isDefault && <td> </td>}
            <td style={{width: '10%'}}>{item.appearanceName}</td>
            <td style={{width: '40%'}}>
              {makePackagesName(item.products ?? [])}
            </td>
            <td>{removeDot(item.price.undefinedPrice)} $</td>
            <td>{item.availableNumberOfTimes}</td>
            {item.expiresOn && item.expiresOn === 365 && (
              <td>{t('IAfterOneYear')}</td>
            )}
            {!item.expiresOn && <td>{t('INone')}</td>}
          </RoundTableWrap>
        ))}
      </PackageTable>
    </div>
  )
}
export default ForeignPackageTable
