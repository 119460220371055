import {createStyles, Divider, FormControlLabel} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import {
  dangerColor,
  defaultBoxShadow,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
} from 'assets/tss/material-dashboard-pro-react'
import {Colors} from 'components/common/useBaseStyle'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {setLangSession} from 'api/storageApi'
import {
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioButtonText,
} from '../../pages/SalesPages/SalesAppNoticePage/StyledPostDialog'

const styles = createStyles({
  translationWrap: {
    color: Colors.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 100,
    height: 30,
    textTransform: 'none',
    fontSize: 16,

    '&:active': {
      boxShadow: 'none',
      backgroundColor: Colors.primary,
      color: Colors.primary,
      background: 'transparent',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.primary,
      background: 'transparent',
    },
    '&:focus': {
      boxShadow: 'none',
      color: Colors.primary,
      background: 'transparent',
    },
  },

  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  root: {
    // position: 'relative',
  },
  dropdown: {
    width: 150,
    height: 90,
    position: 'absolute',
    top: 50,
    right: 20,
    zIndex: 1,
    border: '1px solid #ebebeb',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: 10,
  },
  translationClick: {
    position: 'relative',
  },
  translationText: {
    color: '#666666',
    fontSize: 13,
    padding: 3,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: '#f8f9fa',
      background: 'transparent',
    },
  },
  dividerPadding: {
    marginTop: 5,
    marginBottom: 5,
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})
const useStyles = makeStyles(styles)
export type Locale = 'ko' | 'en' | 'ja'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 38,
      height: 22,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#cccccc',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: Colors.primary,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#cccccc',
      opacity: 1,
      transition: theme.transitions.create([Colors.primary, 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({classes, ...props}: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

export default function TranslationSelect() {
  const {i18n} = useTranslation()
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()

  const [currentLocale, setCurrentLocale] = useState<Locale>('ko')

  const isEEGAnalysisPage = location?.pathname.includes(
    '/analysis/eeg_19/summary',
  )

  const handleKoreanClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentLocale('ko')
    await i18n.changeLanguage('ko' as Locale)
    if (isEEGAnalysisPage) setLangSession('ko')
  }

  const handleEnglishClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentLocale('en')
    await i18n.changeLanguage('en')
    if (isEEGAnalysisPage) setLangSession('en')
  }

  const handleJapaneseClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentLocale('ja')
    await i18n.changeLanguage('ja' as Locale)
    if (isEEGAnalysisPage) setLangSession('ja')
  }

  useEffect(() => {
    setCurrentLocale(i18n.language as Locale)
  }, [i18n.language])

  const l1 = 'English'
  const l2 = '한국어'
  const l3 = '日本語'
  return (
    <div>
      <p />
      <div style={{display: 'flex'}}>
        <RadioButtonContainer>
          <RadioButton
            type='radio'
            name='start'
            value='en'
            checked={currentLocale === 'en'}
            onChange={(event) => {
              handleEnglishClick(event)
            }}
          />
          <RadioButtonLabel />
          <RadioButtonText>{l1}</RadioButtonText>
        </RadioButtonContainer>
        <RadioButtonContainer>
          <RadioButton
            type='radio'
            name='start'
            value='ko'
            checked={currentLocale === 'ko'}
            onChange={(event) => {
              handleKoreanClick(event)
            }}
          />
          <RadioButtonLabel />
          <RadioButtonText>{l2}</RadioButtonText>
        </RadioButtonContainer>
        {isEEGAnalysisPage && (
          <RadioButtonContainer>
            <RadioButton
              type='radio'
              name='start'
              value='ja'
              checked={currentLocale === 'ja'}
              onChange={(event) => {
                handleJapaneseClick(event)
              }}
            />
            <RadioButtonLabel />
            <RadioButtonText>{l3}</RadioButtonText>
          </RadioButtonContainer>
        )}
      </div>
    </div>
  )
}
