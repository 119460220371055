import React, {useState} from 'react'
import {SearchedHealthCare} from 'api/healthCareApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import PageTable from 'pages/HealthCenter/PageTable'
import PageTableWhiteList from 'pages/HealthCenter/PageTableWhiteList'
import {getFeatures} from 'api/storageApi'
import {TYPE_WHITE_LIST} from 'constants/CommonConstant'

const HealthCenter = () => {
  const typeWhiteList = JSON.parse(getFeatures() ?? '[]')
  const [selectedOld, setSelectedOld] = useState<SearchedHealthCare[]>([])

  const updateSelectedOld = (selected: any, listAll: any) => {
    const removeItems = listAll.filter(
      (itemA: any) => !selected.some((itemB: any) => itemA.hcId === itemB.hcId),
    )
    let selectedOldNew = selectedOld
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selected.length; i++) {
      if (!selectedOld?.find((item: any) => item.hcId === selected[i].hcId)) {
        selectedOldNew.push(selected[i])
      }
    }
    selectedOldNew = selectedOldNew.filter(
      (itemA: any) =>
        !removeItems.some((itemB: any) => itemA.hcId === itemB.hcId),
    )
    setSelectedOld(selectedOldNew)
  }
  return (
    <CardWithTitle>
      {TYPE_WHITE_LIST.every((item) => typeWhiteList.includes(item)) ? (
        <PageTableWhiteList
          selectedOld={JSON.parse(JSON.stringify(selectedOld))}
          setSelectedOld={updateSelectedOld}
        />
      ) : (
        <PageTable
          selectedOld={JSON.parse(JSON.stringify(selectedOld))}
          setSelectedOld={updateSelectedOld}
        />
      )}
    </CardWithTitle>
  )
}

export default HealthCenter
