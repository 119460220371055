import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from '../../../Text/ReportSubtitle'
import {Colors} from '../../../common/useBaseStyle'
import {
  CommentContainer,
  CommentDiscussion,
  CommentDivider,
  CommentTag,
  SectionTitle,
} from './Style'

export default function EEGSummaryBrainBalanceDevelopment(props: {
  brainBalance3dImage: string | undefined
  brainBalanceImage: string | undefined
  LH: number
  RH: number
}) {
  const {brainBalance3dImage, brainBalanceImage, LH, RH} = props
  const {t} = useTranslation()
  const classes = useStyles()

  let discussionType = ''

  // 좌뇌 >= 50 && 우뇌 >= 50
  // 균형형
  // 좌뇌 >=50 && 우뇌 < 50
  // 좌뇌형
  // 좌뇌 < 50 && 우뇌 >= 50
  // 우뇌형
  // 좌뇌 < 50 && 우뇌 < 50
  // 연결없음

  if (LH >= 50 && RH >= 50) {
    discussionType = 'IEEGSummaryReportChildBrainBalanceResultBalance'
  } else if (LH >= 50 && RH < 50) {
    discussionType = 'IEEGSummaryReportChildBrainBalanceResultLeft'
  } else if (LH < 50 && RH >= 50) {
    discussionType = 'IEEGSummaryReportChildBrainBalanceResultRight'
  } else if (LH < 50 && RH < 50) {
    discussionType = 'IEEGSummaryReportChildBrainBalanceResultNone'
  }

  return (
    <div className={classes.eeg}>
      <ReportSubtitle number='4'>
        {t('IEEGSummaryReportChildBrainBalanceTitle')}
      </ReportSubtitle>
      <div className={classes.childValueGraphHalf}>
        <div className='Child-info'>
          <SectionTitle className='Title'>
            {t('IEEGSummaryReportChildBrainBalanceAbout')}
          </SectionTitle>
          <div className='Desc' style={{color: '#333333'}}>
            {t('IEEGSummaryReportChildBrainBalanceDescription')}
          </div>
        </div>

        <div className='Child-Graph-Box'>
          <div className='Child-Graph' style={{flex: 6}}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              src={brainBalance3dImage}
              alt='brain wave analysis 3d image'
              style={{marginTop: 30}}
            />
          </div>
          <div className='Child-Graph' style={{flex: 4}}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img src={brainBalanceImage} alt='brain wave analysis 3d image' />
          </div>
        </div>
        <div>
          <CommentTag>{t('IEEGSummaryReportChildResult')}</CommentTag>
          <CommentDivider />

          <CommentContainer>
            <CommentDiscussion>
              {t('IEEGSummaryReportChildBrainBalanceResultDesc', {
                LH,
                RH,
                TYPE: t(discussionType),
              })}
            </CommentDiscussion>
          </CommentContainer>
        </div>
        <div className='Child-info-alert' style={{marginBottom: '25px'}}>
          {t('IEEGSummaryReportChildECAlert')}
        </div>
      </div>
    </div>
  )
}
