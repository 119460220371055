import {SearchedIndividualEEG} from 'api/analysisApi'
import {
  closeIcaConfirmDialog,
  closeIcaResultDialog,
  closeIcaSelectDialog,
  confirmIcaConfirmDialog,
  confirmIcaResultDialog,
  confirmIcaSelectDialog,
  openIcaSelectDialog,
  selectIcaConfirmOpen,
  selectIcaResultOpen,
  selectIcaSelectOpen,
  selectReAnalysisYn,
  selectSelectedItems,
} from 'features/analysis/eegIndividualSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export function useIcaSelectDialog() {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(selectIcaSelectOpen)

  const onOpen = (payload: SearchedIndividualEEG[]) =>
    dispatch(openIcaSelectDialog(payload))

  const onConfirm = (payload: {reSelectYn: YN; reAnalysisYn: YN; sraYn?: YN}) =>
    dispatch(confirmIcaSelectDialog(payload))

  const onClose = () => dispatch(closeIcaSelectDialog())

  return {
    open: isOpen,
    onOpen,
    onConfirm,
    onClose,
  }
}

export function useIcaConfirmDialog() {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(selectIcaConfirmOpen)
  const selected = useAppSelector(selectSelectedItems)

  const onConfirm = () => dispatch(confirmIcaConfirmDialog())

  const onClose = () => dispatch(closeIcaConfirmDialog())

  return {
    open: isOpen,
    selected,
    onConfirm,
    onClose,
  }
}

export function useIcaResultDialog() {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(selectIcaResultOpen)
  const selected = useAppSelector(selectSelectedItems)
  const reAnalysisYn = useAppSelector(selectReAnalysisYn)

  const onConfirm = () => dispatch(confirmIcaResultDialog())

  const onClose = () => dispatch(closeIcaResultDialog())

  return {
    open: isOpen,
    selected,
    ReAnalysis: reAnalysisYn,
    onConfirm,
    onClose,
  }
}
