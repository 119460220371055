import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SvgIcon} from 'components/common/useSvgIcons'
import useFailureModal from 'features/modal/useFailureModal'
import {GroupType, selectGroups} from 'features/analysis/nirCareLedSlice'
import {useAppSelector} from 'hooks'
import usePrescriptionViewModelV2 from './PrescriptionViewModel'
import {
  FlexContainer,
  Text,
  BoxContainer,
  PrimaryBtn,
  GroupContainer,
} from '../Style'
import {SidebarProps, Group} from '../Interface'

const Sidebar = ({customerId}: SidebarProps) => {
  const Groups: GroupType[] = useAppSelector(selectGroups)
  const {addNewGroup, selectedGroup, setSelectGroup} =
    usePrescriptionViewModelV2()

  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [groupName, setGroupName] = useState('')

  const {t} = useTranslation()

  const handleClick = (item: GroupType, indexToBeUpdated: number) => {
    console.log(`side bar click : ${JSON.stringify(item.protocolList)}`)
    setSelectGroup(item, customerId, indexToBeUpdated)
  }

  const findGroupIndex = (): number => {
    const index = Groups.findIndex(
      (group) => group.label === selectedGroup.label,
    )
    return Groups.length
    // return index
  }

  const generateDisplayLabel = (index: number, itemLabel?: string): string => {
    const MAX_LENGTH = 15
    if (
      !itemLabel ||
      itemLabel?.startsWith('grp_') ||
      itemLabel === 'undefined' ||
      itemLabel === 'null' ||
      itemLabel.trim() === ''
    ) {
      return `${t('IPrescription')} ${index + 1}`
    }

    return itemLabel.length < MAX_LENGTH
      ? itemLabel
      : `${itemLabel.slice(0, MAX_LENGTH)}...`
  }

  const handleCreateGroup = (event: any) => {
    event.preventDefault()
    // addNewGroup(`Prescription ${Groups.length + 1}`)
    addNewGroup(`grp_${Date.now().toString(36)}`)
    // setGroupName('')
  }

  return (
    <>
      <GroupContainer>
        <Text padding='16px 10px 4px 22px' color='#AEB6BE'>
          {t('IGroup')}
        </Text>
        {Groups &&
          Groups.map((item, index) => {
            return (
              <FlexContainer
                padding='10px 10px 10px 20px'
                onClick={() => handleClick(item, index)}
                bgcolor={
                  selectedGroup?.label === item?.label ? '#3EBCBD' : '#fff'
                }
                borderLeft={
                  selectedGroup?.label === item?.label
                    ? '4px solid #3EBCBD'
                    : 'none'
                }
                radius={selectedGroup?.label === item?.label ? '3px' : 'none'}
                margin='0px 0px 0px 10px'
              >
                <SvgIcon
                  name='editUser'
                  iconColor={
                    selectedGroup?.label === item?.label ? '#fff' : '#333'
                  }
                />
                <Text
                  padding='0px 0px 0px 5px'
                  cursor='pointer'
                  bgcolor={
                    selectedGroup?.label === item?.label ? '#3EBCBD' : '#fff'
                  }
                  color={selectedGroup?.label === item?.label ? '#fff' : '#333'}
                >
                  {/* {item.name} */}
                  {generateDisplayLabel(index, item.label)}
                </Text>
              </FlexContainer>
            )
          })}
        <FlexContainer
          cursor='pointer'
          padding='10px 0px 10px 20px'
          alignItem='center'
          onClick={handleCreateGroup}
        >
          {/* <Text onClick={handleCreateInput}> */}
          <SvgIcon name='addGroup' iconColor='' />
          {/* </Text> */}
          {/* <BoxContainer padding='10px' margin='10px'>
          <input
            type='text'
            id='addGroup'
            value={groupName}
            placeholder={t('IAddGroup')}
            onChange={(event) => {
              setGroupName(event.target.value)
            }}
          />
        </BoxContainer> */}
          <Text
            padding='0px 0px 0px 5px'
            cursor='pointer'
            bgcolor='#fff'
            color='#333'
          >
            {t('PbmModalAddPrescriptionTitle')}
          </Text>
        </FlexContainer>
      </GroupContainer>
    </>
  )
}

export default Sidebar
