import CardWithTitle from 'components/atoms/CardWithTitle'
import PageTable from 'pages/Analysis/HRVSummary/PageTable'
import React from 'react'

function HRVSummary() {
  return (
    <CardWithTitle>
      <PageTable />
    </CardWithTitle>
  )
}

export default HRVSummary
