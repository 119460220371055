import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultIndicator,
  AnalysisResultItem,
  FetchNormativeContentRequest,
  fetchNormativeEEGContent,
} from 'api/analysisApi'
import useStyles from 'components/molcules/Report/BandPowerTopoMap.style'
import ReportHeader from 'components/Report/ReportHeader'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import {
  AnalysisDivision,
  CodeType,
  ReportAnalysisType,
} from 'constants/AnalysisConstant'
import {isEEGNorm} from 'helpers/analysisHelper'
import React, {useEffect, useMemo} from 'react'
import {useAsync} from 'react-async-hook'

const SUB_TYPE_ALL = 6
const SUB_TYPE_ABS = 1
const SUB_TYPE_REL = 3

interface BandPowerTopoMapProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
  requestId: number
  codeType: CodeType
  codeTypeDivision: AnalysisDivision
  codeId: ReportAnalysisType
}

function BandPowerTopoMap({
  indicator,
  items,
  requestId,
  codeType,
  codeTypeDivision,
  codeId,
}: BandPowerTopoMapProps) {
  const classes = useStyles()

  const params: FetchNormativeContentRequest = {
    normRequestId: requestId,
    analysisType: codeId,
  }

  const asyncResult = useAsync(fetchNormativeEEGContent, [params], {
    executeOnMount: false,
    executeOnUpdate: false,
  })

  const all = useMemo(
    () => items.find((item) => item.subType === SUB_TYPE_ALL),
    [items],
  )
  const abs = useMemo(
    () => items.find((item) => item.subType === SUB_TYPE_ABS),
    [items],
  )
  const rel = useMemo(
    () => items.find((item) => item.subType === SUB_TYPE_REL),
    [items],
  )

  const [tabIndex, setTabIndex] = React.useState(0)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    if (isEEGNorm(codeTypeDivision, codeType)) {
      asyncResult.execute(params)
    }
  }, [codeTypeDivision, codeType])

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        indicatorColor='secondary'
        textColor='secondary'
      >
        <Tab label='Band' />
        <Tab label='1Hz bin(Absolute)' />
        <Tab label='1Hz bin(Relative)' />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <img
          src={all?.imgPath ?? ''}
          alt='Bandpower'
          className={`${classes.image} all`}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <img
          src={abs?.imgPath ?? ''}
          alt='Abs'
          className={`${classes.image} bin`}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <img
          src={rel?.imgPath ?? ''}
          alt='Rel'
          className={`${classes.image} bin`}
        />
      </TabPanel>
      {asyncResult.error !== undefined && (
        <div className={classes.contentError}>
          {asyncResult.error.message}
          <IconButton onClick={() => asyncResult.execute(params)} size='small'>
            <ReplayIcon />
          </IconButton>
        </div>
      )}
      {asyncResult.result !== undefined && (
        <div
          className={classes.content}
          // @ts-ignore
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{__html: asyncResult.result?.data.dTable}}
        />
      )}
    </div>
  )
}

export default BandPowerTopoMap
