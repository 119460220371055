import {makeStyles, Theme} from '@material-ui/core/styles'

const useProductStyle = makeStyles((theme: Theme) => ({
  productContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
  },
  productItem: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: 'calc(50% - 8px)',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default useProductStyle
