import {
  HealthCareSearchRequest,
  HealthCareDownloadRequest,
  HealthProduct,
} from 'api/healthCareApi'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useHistory, useLocation} from 'react-router-dom'
import {
  searchHealthCare,
  selectError,
  selectItems,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectPeriod,
  selectQuery,
  selectSearch,
  selectLoadingChoice,
  selectErrorChoice,
  downloadHealthCare,
  selectLoadingDownload,
  selectErrorDownload,
  selectHdAnalysisTypes,
} from 'features/healthCare/healthCareSlice'
import {useState} from 'react'

export default function useHealthCare() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [listProduct, setListProduct] = useState<HealthProduct[]>([])

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)

  const loadingChoice = useAppSelector(selectLoadingChoice)
  const errorChoice = useAppSelector(selectErrorChoice)

  const loadingDownload = useAppSelector(selectLoadingDownload)
  const errorDownload = useAppSelector(selectErrorDownload)

  const items = useAppSelector(selectItems)
  const hdAnalysisTypes = useAppSelector(selectHdAnalysisTypes)

  const period = useAppSelector(selectPeriod)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)

  const onSearch = (payload: HealthCareSearchRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    dispatch(searchHealthCare(payload))
  }

  const onDownload = (payload: HealthCareDownloadRequest) =>
    dispatch(downloadHealthCare(payload))
  const getHdAnalysisTypes = (id: number[]) => {
    return (
      listProduct
        .filter((item) => id.includes(item.id))
        .map((ite) => ite.displayName)
        .join(',') || ''
    )
  }

  const getRequiredListProduct = (ids: number[]) => {
    const requiredSet: Set<string> = new Set()
    listProduct.forEach((item) => {
      if (ids.includes(item.id)) {
        item.required.forEach((element) => requiredSet.add(element))
      }
    })
    const requiredList: string[] = Array.from(requiredSet)
    return requiredList
  }

  return {
    loadingChoice,
    loadingDownload,
    errorChoice,
    errorDownload,
    loading,
    error,
    items,
    period,
    paging,
    pagingInfo,
    search,
    query,
    hdAnalysisTypes,
    onSearch,
    onDownload,
    getHdAnalysisTypes,
    setListProduct,
    getRequiredListProduct,
  }
}
