import {PostPresetData, PutPresetData, DeletePresetData} from 'api/orgApi'
import {
  fetchPresetListAction,
  fetchPresetCreateAction,
  fetchPresetUpdateAction,
  fetchPresetDeleteAction,
  selectOrgPresetsList,
  selectOrgPresetsLoading,
  selectOrgPresetsError,
} from 'features/org/orgPresetSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'
import useAuth from 'features/auth/useAuth'

export default function usePreset() {
  const dispatch = useAppDispatch()
  const {user: currentUser} = useAuth()
  const uid = currentUser?.uid ?? ''

  const loading = useAppSelector(selectOrgPresetsLoading)
  const presets = useAppSelector(selectOrgPresetsList)
  const error = useAppSelector(selectOrgPresetsError)

  const onFetch = () => dispatch(fetchPresetListAction({uid}))

  const onCreate = (payload: PostPresetData) =>
    dispatch(fetchPresetCreateAction(payload))

  const onUpdate = (payload: PutPresetData) =>
    dispatch(fetchPresetUpdateAction(payload))

  const onRemove = (payload: DeletePresetData) =>
    dispatch(fetchPresetDeleteAction(payload))

  return {
    uid,
    loading,
    presets,
    error,
    onFetch,
    onCreate,
    onUpdate,
    onRemove,
  }
}
