import styled from 'styled-components'

export const SectionTitle = styled.div`
  color: #000000;
  margin-bottom: 20px;
  margin-top: 25px;
`

// Section Analysis Comments
export const CommentTag = styled.div`
  display: inline-block;
  padding: 6px 32px;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #00a29c;
  color: #fff;
`

export const CommentDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #00a29c;
`

export const CommentContainer = styled.div`
  background-color: #f2f5f5;
  padding: 30px 40px;
  display: grid;
  gap: 15px;
`

export const CommentDiscussion = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #000000;
`

// Section 5
export const Section5Desc = styled.div`
  margin-top: 25px;
  color: #333333;
  font-size: 16px;
`
