import React from 'react'
import styled from 'styled-components'
import {getPackageFullName} from 'helpers/paymentHelper'
import {AnalysisProduct, PurchasePackage, PurchaseBundle} from 'api/paymentApi'
import {useTranslation} from 'react-i18next'
import useViewModel from './ViewModels/IncludedProductsDuplicateViewModel'
import {
  StyledIncludedProductBadge,
  StyledIncludedProductWrap,
} from './IncludedProducts'

interface IncludedProductsProps {
  analyses: [AnalysisProduct]
  compareIncludedProducts: string[]
  packages?: PurchasePackage[]
  bundles?: PurchaseBundle[]
  rowIdx?: number
  purchasingToPurchasing?: boolean
}

const StyledDuplicateIncludedProductBadge = styled(StyledIncludedProductBadge)`
  color: #ee5454;
`

function IncludedProductsDuplicate({
  analyses,
  compareIncludedProducts,
  packages,
  bundles,
  rowIdx,
  purchasingToPurchasing,
}: IncludedProductsProps) {
  const {t} = useTranslation()

  const {comparePurchasingToPurchasing} = useViewModel()

  return (
    <StyledIncludedProductWrap>
      {analyses.map((product, idx) => {
        const productText = getPackageFullName(product.code)

        let el = (
          <StyledIncludedProductBadge key={idx}>
            {t(productText)}
          </StyledIncludedProductBadge>
        )

        // 1. 구매한 상품은 구매하려는 상품과 비교
        // 2. 구매하려는 상품은 구매한 상품과 비교
        compareIncludedProducts.find((compare) => {
          if (compare === product.code) {
            el = (
              <StyledDuplicateIncludedProductBadge key={idx}>
                {t(productText)}
              </StyledDuplicateIncludedProductBadge>
            )
          }
          return null
        })

        // 3. 구매할 상품끼리 비교가  추가로 필요하다면
        if (purchasingToPurchasing) {
          if (
            comparePurchasingToPurchasing(
              packages as PurchasePackage[],
              bundles as PurchaseBundle[],
              rowIdx as number,
              product.code as string,
            )
          ) {
            el = (
              <StyledDuplicateIncludedProductBadge key={idx}>
                {t(productText)}
              </StyledDuplicateIncludedProductBadge>
            )
          }
        }

        return el
      })}
    </StyledIncludedProductWrap>
  )
}

export default IncludedProductsDuplicate
