import {OutlinedInput, Select} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import {SelectProps} from '@material-ui/core/Select'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import {getDaysInMonth} from 'date-fns'
import React, {useEffect, useState} from 'react'

interface SelectDoctorsProps extends SelectProps {
  oldDate: Date
  onDateChange: (value: Date) => void
}

const SelectBoldYear = (props: SelectDoctorsProps) => {
  const {error, onDateChange, oldDate} = props
  const outlinedInputClasses = useOutlinedInputStyles()

  const [selectedYear, setSelectedYear] = useState(0)
  const [selectedMonth, setSelectedMonth] = useState(0)
  const [selectedDay, setSelectedDay] = useState(0)
  const [selectedLastDay, setSelectedLastDay] = useState(0)
  const [days, setDays] = React.useState<Array<number>>([])

  function changeMonthValue() {
    const day = getDaysInMonth(new Date(selectedYear, selectedMonth - 1))
    setSelectedLastDay(day)
  }

  function changeDaysValue() {
    setDays(Array.from({length: selectedLastDay ?? 0}, (_, i) => i + 1))
  }

  function makeBirth(): Date {
    return new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`)
  }

  const handleYear = (e: React.ChangeEvent<{value: unknown}>) => {
    const value = e.target.value as number
    setSelectedYear(value)
  }
  const handleMonth = (e: React.ChangeEvent<{value: unknown}>) => {
    const value = e.target.value as number
    setSelectedMonth(value)
  }
  const handleDay = (e: React.ChangeEvent<{value: unknown}>) => {
    const value = e.target.value as number
    setSelectedDay(value)
  }
  const handleBirth = () => {
    onDateChange(makeBirth())
  }

  const year = new Date().getFullYear()
  const years = Array.from(new Array(100), (val, index) => year - index)
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  useEffect(() => {
    onDateChange(oldDate)
  }, [])

  useEffect(() => {
    changeMonthValue()
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    changeDaysValue()
  }, [selectedLastDay])

  useEffect(() => {
    if (selectedYear === 0) {
      setSelectedYear(oldDate?.getFullYear())
    }
    if (selectedMonth === 0) {
      setSelectedMonth(oldDate?.getMonth() + 1)
    }
    if (selectedDay === 0) {
      setSelectedDay(oldDate?.getDate())
    }
    handleBirth()
  }, [selectedDay, selectedYear, selectedMonth])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flex: 1,
        gap: 10,
      }}
    >
      <FormControl error={error} style={{flexGrow: 1}}>
        <Select
          variant='outlined'
          onChange={handleYear}
          displayEmpty
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          <MenuItem disabled>{oldDate?.getFullYear()}</MenuItem>
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>

        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      <FormControl style={{flexGrow: 1}} error={error}>
        <Select
          variant='outlined'
          displayEmpty
          onChange={handleMonth}
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          <MenuItem disabled>{oldDate?.getMonth() + 1}</MenuItem>
          {month.map((month, index) => (
            <MenuItem key={index} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{flexGrow: 1}} error={error}>
        <Select
          variant='outlined'
          onChange={handleDay}
          displayEmpty
          input={
            <OutlinedInput
              name='country'
              id='outlined-country-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          <MenuItem disabled>{oldDate?.getDate()}</MenuItem>
          {days.map((days, index) => (
            <MenuItem key={index} value={days}>
              {days}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectBoldYear
