import {createStyles, makeStyles} from '@material-ui/core/styles'
import {EEGResult} from 'api/analysisApi'
import IndividualEEGFetcher from 'components/Fetcher/IndividualEEGFetcher'
import NormativeEEGFetcher from 'components/Fetcher/NormativeEEGFetcher'
import SubTitle from 'components/Text/SubTitle'
import useQuery from 'hooks/useQuery'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = () =>
  createStyles({
    root: {
      width: 1500,
      height: 800,
      padding: 16,
      overflowY: 'scroll',
    },
    container: {
      width: '100%',
      height: '100%',
    },
    title: {},
    components: {
      display: 'flex',
      alignItems: 'center',

      '& .Title': {
        background: '#2f404f',
        color: '#fff',
        textAlign: 'center',
        padding: 10,
      },
      '& .Container': {
        flex: 1,
        background: '#dee6eb',
        padding: 10,
        display: 'flex',
      },
      '& .Item': {
        padding: '0 7px',
        background: '#f8ac59',
        color: '#fff',
        fontWeight: 600,
        fontSize: 12,
        marginLeft: 5,
        borderRadius: 30,
        height: 20,
        lineHeight: '20px',
      },
    },
    images: {
      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
  })

const useStyles = makeStyles(styles)

function AllComponentImage({data}: {data: EEGResult}) {
  const classes = useStyles()

  const componentList = data.componentList ?? []
  const resultDetailList = data.resultDetailList ?? []
  const {t} = useTranslation()
  // const [item] = resultDetailList
  //   .filter((item) => item.analysisType === '12')
  //   .filter((item) => item.componentType === 0)
  //   .map((item) => item.imgPath)
  // console.log(item)

  const images = resultDetailList
    .filter((item) => item.componentType !== 0)
    .map((item) => item.imgPath)

  return (
    <div>
      <div className={classes.components}>
        <div className='Title'>Artifact Component No.</div>
        <div className='Container'>
          {componentList.map((item) => (
            <div className='Item' key={item}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.images}>
        {images.map((image) => (
          <img
            src={image}
            alt='Component'
            style={{width: '100%', height: 'auto'}}
          />
        ))}
      </div>
      {componentList.length === 0 && images.length === 0 && (
        <div style={{height: '100vh'}}>{t('IReportEmpty')}</div>
      )}
    </div>
  )
}

export default function AllComponentImagesPopup() {
  const classes = useStyles(styles)

  const type = useQuery().get('type') ?? 'I'
  const id = useQuery().get('id') ?? '-1'

  const parsedId = Number.parseInt(id, 10)

  if (Number.isNaN(parsedId) || parsedId === -1) {
    return (
      <div className={classes.container}>유효하지 않은 페이지 접근입니다</div>
    )
  }
  if (type !== 'I' && type !== 'N') {
    return (
      <div className={classes.container}>유효하지 않은 페이지 접근입니다</div>
    )
  }

  return (
    <div className={classes.root}>
      <SubTitle className={classes.title}>All Components Images</SubTitle>
      <div className={classes.container}>
        {type === 'I' && (
          <IndividualEEGFetcher id={parsedId}>
            {(data) => <AllComponentImage data={data} />}
          </IndividualEEGFetcher>
        )}
        {type === 'N' && (
          <NormativeEEGFetcher id={parsedId}>
            {(data) => <AllComponentImage data={data} />}
          </NormativeEEGFetcher>
        )}
      </div>
    </div>
  )
}
