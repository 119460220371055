import React from 'react'
import {TypeScreenGraph} from 'api/analysisApi'
import {ReportIcaAnalysisTypeMap} from 'constants/AnalysisConstant'
import IcaRawDataV1 from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawDataV1'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'

export interface IcaReportItemProps {
  readonly renderingItem: RenderingItem
  readonly screen: TypeScreenGraph
}

export function TsrReportItem({renderingItem, screen}: IcaReportItemProps) {
  const [indicator, items] = renderingItem

  return (
    <div>
      {indicator.codeId === ReportIcaAnalysisTypeMap.CleanedData && (
        <IcaRawDataV1
          indicator={indicator}
          items={items}
          type='cleaned'
          screen={screen}
        />
      )}
    </div>
  )
}
