import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from 'components/Select/selectFormStyle'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function SelectLoading() {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.noSelect}>
      <span>{t('ILoading')}</span>
      <CircularProgress
        color='secondary'
        size='1rem'
        className={classes.iconButton}
      />
    </div>
  )
}
