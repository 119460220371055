import {HRVSearchRequest} from 'api/analysisApi'
import {useHistory, useLocation} from 'react-router-dom'
import {
  searchEegNorm,
  selectError,
  selectItems,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectPeriod,
  selectQuery,
  selectSearch,
} from 'features/analysis/eegNormSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useEegNormSearch() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectItems)

  const period = useAppSelector(selectPeriod)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)

  const onSearch = (payload: HRVSearchRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    dispatch(searchEegNorm(payload))
  }

  return {
    loading,
    error,
    items,
    period,
    paging,
    pagingInfo,
    search,
    query,
    onSearch,
  }
}
