import Colors from 'theme/Colors'
import Size from 'theme/Size'
import Weight from 'theme/Weight'

export const ModalPageTitleStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_07,
  textWeight: Weight.semi_bolder,
  lineHeight: Size.lineHeight.heading_02,
}

export const HeaderTitleStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_07,
  textWeight: Weight.semi_bolder,
  lineHeight: Size.lineHeight.heading_02,
  textMarginLeft: -30,
}

export const ModalPageTitleMobileStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_04,
  textWeight: Weight.semi_bolder,
  lineHeight: Size.lineHeight.heading_04,
}

export const StateModalTitleStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_03,
  textWeight: Weight.extra_bolder,
  lineHeight: Size.lineHeight.heading_03,
}

export const CardTitleStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_05,
  textWeight: Weight.extra_bolder,
  lineHeight: Size.lineHeight.heading_05,
}

export const ModalPageTitleExtraBoldStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_02,
  textWeight: Weight.extra_bolder,
  lineHeight: Size.lineHeight.heading_02,
}

export const AuthCardInnerTitleStyles = {
  textColor: Colors.common.blacks.b_01,
  textSize: Size.font.heading_04,
  textWeight: Weight.extra_bolder,
  lineHeight: Size.lineHeight.heading_04,
}

export const CustomTitleModalPageTitleStyles = (
  fontSize: number,
  titleTextColor?: string,
) => {
  return {
    textColor: titleTextColor || Colors.common.blacks.b_01,
    textSize: fontSize,
    textWeight: Weight.semi_bolder,
    lineHeight: Size.lineHeight.heading_02,
  }
}
