import {createStyles, makeStyles} from '@material-ui/core'

const styles = () =>
  createStyles({
    root: {
      // width: '100%',
      // height: 'calc(100vh - 20px)',
      width: 1400,
      height: 700,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
