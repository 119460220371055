import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import Card from 'components/atoms/Card'
import {styles} from 'components/Dialog/SuccessDialog'
import useFailureModal from 'features/modal/useFailureModal'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(styles)

const FailDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  const {open, message, onClose, customTitle, customMessage} = useFailureModal()

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <Card className={classes.alertRoot}>
        <div>
          <div className={classes.alertContainer}>
            <CancelIcon className={classes.failIcon} />
            <Typography variant='h5'>
              {customTitle || t('IProcessFail')}
            </Typography>
            <Typography variant='h6' style={{whiteSpace: 'pre-line'}}>
              {customMessage || message}
            </Typography>
          </div>

          <div className={classes.alertButtonContainer}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={onClose}
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default FailDialog
