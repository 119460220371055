import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import 'assets/scss/app.scss'
import {Colors} from 'components/common/useBaseStyle'
import 'i18n'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {store} from 'store'
import ReactGa from 'react-ga'
import {isDev} from 'helpers/envHelper'
import App from './App'
import reportWebVitals from './reportWebVitals'

// msw를 브라우저환경에서 사용할때만 호출함(local환경에서만 사용하도록 권장)
// if (isDev()) {
//   worker.start({
//     onUnhandledRequest: 'bypass',
//   })
// }

const appTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.secondary,
    },
    background: {
      default: '#f4f4f7',
    },
  },
})

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
if (TRACKING_ID && !isDev()) ReactGa.initialize(TRACKING_ID)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback='loading'>
      <ThemeProvider theme={appTheme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
