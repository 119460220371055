import {Box} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Facebook, Instagram, LinkedIn, YouTube} from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IMediSyncLogo from 'assets/img/imedisync_logo.png'
import useStyles from 'components/Footer/useFooterStyles'
import {fetchTerms} from 'api/commonApi'

import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import useOrgPurchase from 'features/org/useOrgPurchase'
import {OrderSteps} from 'pages/OrgPage/Interfaces/Enums'

interface RelatedSitesProps {
  classes: ReturnType<typeof useStyles>
}

function RelatedSites({classes}: RelatedSitesProps) {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const newTab = (link: string) => {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    handleClose()
  }

  return (
    <div className={classes.relatedContainer}>
      <Button
        className={classes.relatedButton}
        variant='outlined'
        color='default'
        aria-controls='related-sties-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        {t('IRelatedSite')}
        <ExpandMoreIcon
          className={anchorEl ? classes.downIcon : classes.upIcon}
        />
      </Button>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id='related-sties-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => newTab('https://imedisync.com')}>
          {t('IHomePage')}
        </MenuItem>
        <MenuItem onClick={() => newTab('https://isyncbrain.com')}>
          {t('IAppName')}
        </MenuItem>
        <MenuItem onClick={() => newTab('http://www.isyncdevice.com/')}>
          {t('IStoreSite')}
        </MenuItem>
      </Menu>
    </div>
  )
}

interface SocialButtonsProps {
  classes: ReturnType<typeof useStyles>
}
function SocialButtons({classes}: SocialButtonsProps) {
  return (
    <div className={classes.socialContainer}>
      <a
        href='https://www.instagram.com/imedisync/?igshid=a4qh11g4oqqr'
        target='_blank'
        rel='noreferrer'
        className={classes.socialButton}
      >
        <Instagram />
      </a>
      <a
        href='https://www.facebook.com/groups/655562654998099'
        target='_blank'
        rel='noreferrer'
        className={classes.socialButton}
      >
        <Facebook />
      </a>
      <a
        href='https://www.linkedin.com/company/imedisyn/about/'
        target='_blank'
        rel='noreferrer'
        className={classes.socialButton}
      >
        <LinkedIn />
      </a>
      <a
        href='https://www.youtube.com/channel/UCHOF2TjnInkmvsqOOwpJMuw/featured'
        target='_blank'
        rel='noreferrer'
        className={classes.socialButton}
        style={{marginRight: 0}}
      >
        <YouTube />
      </a>
    </div>
  )
}

function CompanyInfo({classes}: {classes: ReturnType<typeof useStyles>}) {
  return (
    <div className={classes.companyInfo}>
      <div className='desc'>
        iMediSync Founder & CEO : Seung Wan Kang | Office : 15FL, 411,
        Teheran-ro, Gangnam-gu, Seoul, Republic of Korea <br />
        Business Number : 104-86-46675 | Contact : +82-2-747-7422
        isyncbrain@imedisyn.com | Customer Service: +82-1533-4080 /
        cs@imedisync.com <br />
        Copyright © iMediSync Inc. All rights reserved.
      </div>
    </div>
  )
}

function ServiceLinksWithLogo({
  classes,
}: {
  classes: ReturnType<typeof useStyles>
}) {
  const {t, i18n} = useTranslation()

  const [termsUrl, setTermsUrl] = React.useState('')

  const handleTerms = async (termsRequest: 'PRIVACY' | 'SERVICE') => {
    try {
      const response = await fetchTerms({
        termsLang: i18n.language,
        type: termsRequest,
      })
      if (response.success && termsRequest === 'PRIVACY') {
        // CYM: add 2022-12-12
        // *apply previous terms html file until finish update ui*
        // because the way was changed that iframe to innerHTML
        // in html file, only there is body tag, there is no head
        // so korean language is broken because utf-8 is not applied
        setTermsUrl(
          'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_ko.html',
        )
        // setTermsUrl(response.data)
      }
      if (response.success && termsRequest === 'SERVICE') {
        setTermsUrl(
          'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko-2.html',
        )
        // setTermsUrl(response.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const newTab = (link: string) => {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    if (termsUrl.length !== 0) {
      newTab(termsUrl)
      setTermsUrl('')
    }
  }, [termsUrl])

  return (
    <div className={classes.serviceLinks}>
      <img src={IMediSyncLogo} alt='imedisync company logo' />
      <ul className='links'>
        <li>
          <Box onClick={() => handleTerms('PRIVACY')}>{t('IPrivacy')}</Box>
        </li>
        <div className='divider' />
        <li>
          <Box onClick={() => handleTerms('SERVICE')}>{t('ITerms')}</Box>
        </li>
      </ul>
    </div>
  )
}

export default function Footer() {
  const classes = useStyles()

  // for footer not show on org/purchase orderStep === OrderSteps.ProductsSelect
  const {orderStep} = useOrgPurchase()

  useEffect(() => {
    console.log('orderStep', orderStep)
  }, [orderStep])

  return (
    <>
      {orderStep !== OrderSteps.ProductsSelect && (
        <footer className={classes.root}>
          {/* left */}
          <div className={classes.infoContainer}>
            <ServiceLinksWithLogo classes={classes} />
            <CompanyInfo classes={classes} />
          </div>
          {/* right */}
          <div className={classes.sitesContainer}>
            <RelatedSites classes={classes} />
            <SocialButtons classes={classes} />
          </div>
        </footer>
      )}
    </>
  )
}
