import styled from 'styled-components'
import React from 'react'
import {Spacing} from 'components/common/useBaseStyle'

const Padding = styled.div`
  padding: ${Spacing.paddingPaginationTop}px;
  ${Spacing.paddingPaginationRight}px;
`
export default function GridPaginationPadding() {
  return (
    <>
      <Padding />
    </>
  )
}
