import React from 'react'
import styled from 'styled-components'

export interface NormalStyleButtonProps {
  buttonWidth?: number
  buttonHeight: number
  buttonBackgroundColor: string
  buttonBackgroundHoverColor: string
  buttonFontSize: number
  buttonFontWeight: number
  buttonColor: string
  buttonPadding: number
  buttonBorder?: string
}

export interface NormalButtonProps {
  title?: string
  theme: NormalStyleButtonProps
  disabled?: boolean
  event?: () => void
  type?: 'button' | 'submit' | 'reset'
  submitForm?: string // 버튼 타입이 submit일 경우 form 안에 넣지 않고 button에 form값을 form의 id와 연결해서 submit 할 수 있음
}

const StyledNormalButton = styled.button<NormalStyleButtonProps>`
  padding-left: ${(props) => props.buttonPadding}px;
  padding-right: ${(props) => props.buttonPadding}px;
  width: ${(props) => props.buttonWidth}px;
  height: ${(props) => props.buttonHeight}px;
  background: ${(props) => props.buttonBackgroundColor};
  color: ${(props) => props.buttonColor};
  border-radius: 3px;
  border: ${(props) => props.buttonBorder ?? 0};
  outline: 0;
  font-size: ${(props) => props.buttonFontSize}px;
  font-weight: ${(props) => props.buttonFontWeight};
  transition: background-color 0.1s;
  &:hover {
    background: ${(props) => props.buttonBackgroundHoverColor};
  }
  &:disabled {
    cursor: auto;
  }
`

export default function NormalButton({
  title,
  theme,
  disabled,
  event,
  type,
  submitForm,
}: NormalButtonProps) {
  return (
    <StyledNormalButton
      buttonWidth={theme.buttonWidth}
      buttonPadding={theme.buttonPadding}
      buttonHeight={theme.buttonHeight}
      buttonColor={theme.buttonColor}
      buttonBackgroundColor={theme.buttonBackgroundColor}
      buttonBackgroundHoverColor={theme.buttonBackgroundHoverColor}
      buttonFontSize={theme.buttonFontSize}
      buttonFontWeight={theme.buttonFontWeight}
      buttonBorder={theme.buttonBorder}
      type={type ?? 'button'}
      form={submitForm}
      onClick={(e) => {
        if (event) {
          event()
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      disabled={disabled}
    >
      {title}
    </StyledNormalButton>
  )
}
