import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'store'
import {
  InvoiceGetResponse,
  InfoConsumerInvoiceResponse,
  BillingItemResponse,
  ProviderInvoiceResponse,
  UsageDetailResponse,
  updateInvoiceAPI,
  UpdateInvoiceRequest,
  LastInvoiceResponse,
} from 'api/invoiceApis'
import {ProductTemplateType, ProductType} from 'features/invoice/invoiceSlice'

export const updateInvoice = createAsyncThunk(
  '/cs/api/v1/invoice/invoiceId',
  async (
    payload: {invoiceId: number; data: UpdateInvoiceRequest},
    {rejectWithValue},
  ) => {
    try {
      const response = await updateInvoiceAPI(payload)
      return response.success
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface DataTableUsageSummary {
  productType: ProductType
  minCount: number
  maxCount: string | number
  count: number
  price: number
}

interface InvoiceViewInit {
  consumer: InfoConsumerInvoiceResponse | null
  billingSummary: BillingItemResponse | null
  provider: ProviderInvoiceResponse | null
  usageDetail: UsageDetailResponse[] | null
  product: InvoiceGetResponse | null
  templates: ProductTemplateType[] | null
  listVersion: InvoiceGetResponse[] | null
  confirmInvoice: LastInvoiceResponse | null
}

interface InvoiceViewState extends InvoiceViewInit {
  loading: boolean
  error: any
  usageSummaryData: DataTableUsageSummary[]
  patchInvoiceData: UpdateInvoiceRequest | null
}

export const initialState: InvoiceViewState = {
  loading: false,
  error: null,
  consumer: null,
  billingSummary: null,
  provider: null,
  usageDetail: null,
  product: null,
  templates: null,
  listVersion: null,
  confirmInvoice: null,
  usageSummaryData: [],
  patchInvoiceData: null,
}

export const InvoiceViewSlice = createSlice({
  name: 'InvoiceViewSlice',
  initialState,
  reducers: {
    initData(state, action: PayloadAction<InvoiceViewInit>) {
      state.consumer = action.payload.consumer
      state.billingSummary = action.payload.billingSummary
      state.provider = action.payload.provider
      state.usageDetail = action.payload.usageDetail
      state.product = action.payload.product
      state.templates = action.payload.templates
      state.listVersion = action.payload.listVersion
      state.confirmInvoice = action.payload.confirmInvoice
      const {billingSummary} = action.payload
      state.patchInvoiceData = {
        billingDate: billingSummary?.billingDate || '',
        billingPrice: billingSummary?.billingPrice || 0,
        productId: billingSummary?.productId || null,
        isConfirm: action.payload.confirmInvoice?.confirmed ?? false,
        remarks: billingSummary?.remarks || '',
      }
    },
    initDataEmptyInvoice(
      state,
      action: PayloadAction<{
        provider: ProviderInvoiceResponse
        listVersion: InvoiceGetResponse[]
      }>,
    ) {
      state.provider = action.payload.provider
      state.listVersion = action.payload.listVersion
      state.usageDetail = []
      state.usageSummaryData = []
    },
    setListVersion(state, action: PayloadAction<InvoiceGetResponse[]>) {
      state.listVersion = JSON.parse(JSON.stringify(action.payload))
    },
    setUsageSummaryData(state, action: PayloadAction<DataTableUsageSummary[]>) {
      state.usageSummaryData = JSON.parse(JSON.stringify(action.payload))
    },
    setPatchInvoiceData(state, action: PayloadAction<UpdateInvoiceRequest>) {
      state.patchInvoiceData = action.payload
    },
    setTemplates(state, action: PayloadAction<ProductTemplateType[]>) {
      state.templates = action.payload
    },
    setProduct(state, action: PayloadAction<number>) {
      state.product =
        state.listVersion?.find((item) => item.id === action.payload) || null
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload
    },
    resetEmptyData(state) {
      state.billingSummary = null
      state.provider = null
      state.usageSummaryData = []
      state.patchInvoiceData = null
    },
  },
  extraReducers: (builder) => {
    // POST /cs/api/v1/product
    builder.addCase(updateInvoice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  initData,
  initDataEmptyInvoice,
  setUsageSummaryData,
  setPatchInvoiceData,
  setTemplates,
  setListVersion,
  setProduct,
  setError,
  resetEmptyData,
} = InvoiceViewSlice.actions

export const selectLoading = (state: RootState) => state.invoiceView.loading
export const selectError = (state: RootState) => state.invoiceView.error
export const selectConsumer = (state: RootState) => state.invoiceView.consumer
export const selectBillingSummary = (state: RootState) =>
  state.invoiceView.billingSummary
export const selectProvider = (state: RootState) => state.invoiceView.provider
export const selectUsageDetail = (state: RootState) =>
  state.invoiceView.usageDetail
export const selectProduct = (state: RootState) => state.invoiceView.product
export const selectTemplates = (state: RootState) => state.invoiceView.templates
export const selectUsageSummaryData = (state: RootState) =>
  state.invoiceView.usageSummaryData
export const selectPatchInvoiceData = (state: RootState) =>
  state.invoiceView.patchInvoiceData
export const selectListVersion = (state: RootState) =>
  state.invoiceView.listVersion
export const selectConfirmInvoice = (state: RootState) =>
  state.invoiceView.confirmInvoice
export const selectBaseFees = (state: RootState) =>
  state.invoiceView.product?.pkgInfo?.baseFees || []

export default InvoiceViewSlice.reducer
