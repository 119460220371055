import React, {useEffect, useState} from 'react'
import useStyles from 'components/Dialog/Therapy/Style'
import useAuth from 'features/auth/useAuth'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import {useTranslation, TFunction} from 'react-i18next'
import {IconButton} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import therapyBrain from 'assets/img/therapy_brain.png'
import therapyBrainFake from 'assets/img/therapy_brain_fake.png'
import textLeft from 'assets/img/text_left.png'
import textRight from 'assets/img/text_right.png'
import {
  useCreateDialog,
  useReadDialog,
  useUpdateDialog,
} from 'features/therapy/useTherapyDialog'

export interface TherapyPoint {
  name: string
  value: 'Y' | 'N'
}

export const initialEEGElectrodeData: TherapyPoint[] = [
  {
    name: 'c3',
    value: 'N',
  },
  {
    name: 'c4',
    value: 'N',
  },
  {
    name: 'cz',
    value: 'N',
  },
  {
    name: 'f3',
    value: 'N',
  },
  {
    name: 'f4',
    value: 'N',
  },
  {
    name: 'f7',
    value: 'N',
  },
  {
    name: 'f8',
    value: 'N',
  },
  {
    name: 'fp1',
    value: 'N',
  },
  {
    name: 'fp2',
    value: 'N',
  },
  {
    name: 'fz',
    value: 'N',
  },
  {
    name: 'o1',
    value: 'N',
  },
  {
    name: 'o2',
    value: 'N',
  },
  {
    name: 'p3',
    value: 'N',
  },
  {
    name: 'p4',
    value: 'N',
  },
  {
    name: 'pz',
    value: 'N',
  },
  {
    name: 't3',
    value: 'N',
  },
  {
    name: 't4',
    value: 'N',
  },
  {
    name: 't5',
    value: 'N',
  },
  {
    name: 't6',
    value: 'N',
  },
]

// CYM : 테라피 전극 데이터 담기
export const makeTherapyData = (
  data: TherapySettings,
  setTherapyPoint: React.Dispatch<React.SetStateAction<TherapyPoint[]>>,
  setTherapyPointLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isTherapyAllCheck?: (therapyPoint: TherapyPoint[]) => void,
) => {
  setTherapyPointLoading(true)

  // setTherapyPoint에 넣을 변수
  const newTherapyPoint: TherapyPoint[] = []

  Object.entries(data).forEach((item) => {
    initialEEGElectrodeData.forEach((electrode) => {
      if (electrode.name === item[0]) {
        newTherapyPoint.push({
          name: item[0],
          value: item[1],
        })
      }
    })
  })

  if (isTherapyAllCheck) {
    // CYM : 전체 선택, 선택 해제 토글 확인
    isTherapyAllCheck(newTherapyPoint)
  }

  setTherapyPoint(newTherapyPoint)
  setTherapyPointLoading(false)
}

// CYM : 테라피 초단위를 시/분/초 단위 문자로 변경
export const makeTimeText = (time: number, t: TFunction) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time - hours * 3600) / 60)
  const seconds = time - hours * 3600 - minutes * 60

  let timeText = ''
  let h = ''
  let m = ''
  let s = ''

  if (hours > 0) {
    h = hours === 1 ? t('IRangeHour') : t('IRangeHours')
  }
  if (minutes > 0) {
    m = minutes === 1 ? t('IMinute') : t('IMinutes')
  }
  if (seconds >= 0) {
    s = seconds === 1 ? t('ISecond') : t('ISeconds')
  }

  if (hours > 0) {
    if (minutes > 0) {
      if (seconds > 0) {
        timeText = `${hours}${h} ${minutes}${m} ${seconds}${s}`
      } else {
        timeText = `${hours}${h} ${minutes}${m}`
      }
    } else {
      timeText = `${hours}${h} ${minutes}${m}`
    }
    timeText = `${hours}${h}`
  } else if (minutes > 0) {
    if (seconds > 0) {
      timeText = `${minutes}${m} ${seconds}${s}`
    } else {
      timeText = `${minutes}${m}`
    }
  } else if (seconds >= 0) {
    timeText = `${seconds}${s}`
  }

  return timeText
}

const TherapyReadDialog = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {user: currentUser} = useAuth()

  const {
    open,
    reservation: reservationList,
    onClose: handleClose,
    onFetch,
    uuid: therapyId,
  } = useReadDialog()
  const {onOpen: onTherapyEditDialogOpen} = useUpdateDialog()
  const {onOpen: onTherapyCreateDialogOpen} = useCreateDialog()

  const [therapyPointLoading, setTherapyPointLoading] = useState<boolean>(true)
  const [therapyPoint, setTherapyPoint] = useState<TherapyPoint[]>(
    initialEEGElectrodeData,
  )

  const handleEditClick = () => {
    if (isDefined(therapyId)) {
      handleClose()
      onTherapyEditDialogOpen(therapyId)
    }
  }

  const handleAddClick = () => {
    if (isDefined(therapyId)) {
      handleClose()
      onTherapyCreateDialogOpen(therapyId)
    }
  }

  useEffect(() => {
    if (open && isDefined(therapyId)) {
      setTherapyPointLoading(true)

      // CYM : 데이터 불러오기 전 initialEEGElectrodeData로 초기화
      setTherapyPoint(initialEEGElectrodeData)

      onFetch({
        uid: currentUser?.uid ?? '',
        uuid: therapyId,
      })
    }
  }, [open, therapyId])

  // CYM: 20221226 전극 골라주기 위해 필요 없는 값을 제거 했는데 서버에 추가 데이터가 생기면서 에러 발생
  // 필요 없는 값을 제거하지 않고 필요한 전극을 배열에 담는 걸로 함
  useEffect(() => {
    if (isNotDefined(reservationList.reservationDto)) {
      setTherapyPointLoading(false)
      return
    }

    const therapyData = reservationList.reservationDto
    makeTherapyData(therapyData, setTherapyPoint, setTherapyPointLoading)
  }, [reservationList])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.container}
      style={{
        opacity: therapyPointLoading ? 0 : 1,
        visibility: therapyPointLoading ? 'hidden' : 'visible',
        userSelect: therapyPointLoading ? 'none' : 'auto',
        transition:
          'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, visibility 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
    >
      <form>
        <div className={classes.root}>
          {/* 상단 타이틀 컨테이너  */}
          <div className={classes.longTitleContainer}>
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography variant='h6' className={classes.title}>
                {t('ITherapyReservationTitle')}
              </Typography>
            </div>
          </div>
          <Divider className={classes.titleDivider} />
          <DialogContent className={classes.contentWrap}>
            {/* Null이 아니면 데이터 가져오고 */}
            {reservationList?.reservationDto !== null ? (
              <div className={classes.therapyInfoContainer}>
                <div className={classes.brainContainer}>
                  <div className={classes.brainImgContainer}>
                    <div className={classes.brainImgInwrap}>
                      <img src={therapyBrain} alt='therapy_brain' />
                      <div className={classes.therapyPointContainer}>
                        {!therapyPointLoading &&
                          therapyPoint.map((item, idx) => {
                            return (
                              <input
                                key={idx}
                                type='checkbox'
                                name={item.name}
                                value={item.value}
                                data-idx={idx}
                                className={`${classes.therapyPoint} ${item.name} therapyPointRecent`}
                                checked={item.value === 'Y'}
                                disabled
                              />
                            )
                          })}
                      </div>
                      <figure
                        className={`${classes.textImgContainer} ${classes.textImgContainerLeft}`}
                      >
                        <img src={textLeft} alt='text_left' />
                      </figure>
                      <figure
                        className={`${classes.textImgContainer} ${classes.textImgContainerRight}`}
                      >
                        <img src={textRight} alt='text_right' />
                      </figure>
                    </div>
                  </div>
                  <div className={classes.textReadInfoContainer}>
                    <div className={classes.textReadInfoName}>
                      <span> {t('IFrequency')}</span>
                      <span>{t('IRunningTime')}</span>
                    </div>
                    <div className={classes.textReadInfoValue}>
                      <span className={classes.frequency}>
                        {reservationList?.reservationDto?.frequency}
                        Hz
                      </span>
                      <span className={classes.therapyTime}>
                        {makeTimeText(
                          reservationList?.reservationDto?.settingTime,
                          t,
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Null이면 딤처리
              <div className={classes.therapyInfoContainer}>
                <Typography className={classes.smallPadding} />
                <div className={classes.brainContainer}>
                  <div className={classes.brainImgContainerDim}>
                    <div className={classes.brainImgInwrapDim}>
                      <img src={therapyBrainFake} alt='therapy_brain_fake' />
                      <span>
                        <span>{t('INoTherapy')}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          {/* 하단 버튼 컨테이너  */}
          <Typography className={classes.smallPadding} />
          <div className={classes.buttonContainerBgColor}>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='default'
                disableElevation
                onClick={() => {
                  handleClose()
                }}
                className={classes.okButton}
              >
                {t('ICancel')}
              </Button>
              <Typography className={classes.smallPadding} />
              {reservationList?.reservationDto !== null ? (
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  className={classes.okButtonColor}
                  onClick={handleEditClick}
                >
                  {t('IEdit')}
                </Button>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  className={classes.okButtonColor}
                  onClick={handleAddClick}
                >
                  {t('IAdd')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default TherapyReadDialog
