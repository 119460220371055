import {
  closeFailureModal,
  openFailureModalAction,
  selectFailureModal,
  FailureModalProps,
  openFailureCustomModalAction,
  selectFailureCustomModal,
} from 'features/modal/alertSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useFailureModal() {
  const dispatch = useAppDispatch()

  const onOpen = async (payload: string) =>
    dispatch(openFailureModalAction(payload))

  const onCustomOpen = async (payload: FailureModalProps) =>
    dispatch(openFailureCustomModalAction(payload))

  const onClose = () => dispatch(closeFailureModal())

  const {open, message} = useAppSelector(selectFailureModal)

  const {customOpen, customTitle, customMessage} = useAppSelector(
    selectFailureCustomModal,
  )

  return {
    open,
    message,
    onOpen,
    customOpen,
    customTitle,
    customMessage,
    onCustomOpen,
    onClose,
  }
}
