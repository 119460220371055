import {IconButton} from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  fetchHRVSummaryReportApi,
  FetchIndividualSummaryReportRequest,
  HRVSummaryResponseWithClient,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import CustomerInfo from 'components/molcules/N1SummaryReport/CustomerInfo'
import HRVSummaryN1 from 'components/molcules/N1SummaryReport/HRV/HRVSummary'
import HRVSummaryProfit from 'components/molcules/ProfitSummaryReport/HRV/HRVSummary'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import {
  ReportCustomPadding,
  ReportFetcherWrap,
  ReportTranslationSelectWrap,
  ReportWrap,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import PdfDownload from 'components/PdfDownload'
import useLanguageReport from 'hooks/useLanguageReport'

function Report() {
  const {t, i18n} = useTranslation()
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()
  const [report, setReport] = useState<HRVSummaryResponseWithClient>()

  const asyncResult = useAsync(
    fetchHRVSummaryReportApi,
    [
      {
        summaryIds: [Number.parseInt(id, 10)],
        summaryLang: i18n.language as LanguageCode,
      },
    ],
    {
      executeOnMount: true,
      executeOnUpdate: false,
    },
  )

  useEffect(() => {
    if (asyncResult.currentParams !== null) {
      const {summaryIds} = asyncResult.currentParams[0]
      const newParams: [FetchIndividualSummaryReportRequest] = [
        {summaryIds, summaryLang: i18n.language as LanguageCode},
      ]
      asyncResult.execute(...newParams)
    }
  }, [i18n.language])

  useEffect(() => {
    if (asyncResult.result !== undefined) {
      const [response] = asyncResult.result.list
      if (response !== undefined) setReport(response)
    }
  }, [asyncResult.result])

  useLanguageReport()

  return (
    <ReportWrap
      style={{width: '1300px', margin: '0 auto'}}
      id='print-modified-hrv'
    >
      <PdfDownload pathPDF={report?.finalReportPath} />
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <ReportCustomPadding />
        <CardWithTitle
          title={String(t('IHRVSummaryReport'))}
          style={{width: '100%'}}
        >
          {asyncResult.loading && <div>{t('ILoading')}</div>}
          {asyncResult.error && (
            <div
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div>
                {asyncResult.error.message}
                <IconButton onClick={() => {}} size='small'>
                  <ReplayIcon />
                </IconButton>
              </div>
            </div>
          )}
          {/* {!asyncResult.loading && asyncResult.error !== undefined && (
        <div>{asyncResult.error}</div>
      )} */}
          {!asyncResult.loading &&
            report !== undefined &&
            report.reportType === 'P1' && (
              <HRVSummaryProfit
                {...report}
                CustomerCard={
                  <CustomerInfo
                    analysisNo={report.id}
                    analysisTime={report.treatmentAt ?? ''}
                    {...report.client}
                  />
                }
                clientFirstName={report.client.clientFirstName ?? ''}
                clientLastName={report.client.clientLastName ?? ''}
              />
            )}
          {!asyncResult.loading &&
            report !== undefined &&
            report.reportType === 'P2' && (
              <HRVSummaryProfit
                {...report}
                CustomerCard={
                  <CustomerInfo
                    analysisNo={report.id}
                    analysisTime={report.treatmentAt ?? ''}
                    {...report.client}
                  />
                }
                clientFirstName={report.client.clientFirstName ?? ''}
                clientLastName={report.client.clientLastName ?? ''}
              />
            )}
          {!asyncResult.loading &&
            report !== undefined &&
            report.reportType === 'N1' && (
              <HRVSummaryN1
                {...report}
                CustomerCard={
                  <CustomerInfo
                    analysisNo={report.id}
                    analysisTime={report.treatmentAt ?? ''}
                    {...report.client}
                  />
                }
                customerFirstName={report.client.clientFirstName ?? ''}
                customerLastName={report.client.clientLastName ?? ''}
              />
            )}
          {!asyncResult.loading &&
            asyncResult.error === undefined &&
            report === undefined && (
              <div style={{minHeight: 400}}>출력할 결과지가 없습니다.</div>
            )}
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default Report
