import {
  closeSuccessModal,
  openSuccessModalAction,
  selectSuccessModal,
} from 'features/modal/alertSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useSuccessModal() {
  const dispatch = useAppDispatch()

  const onOpen = async (payload: string) =>
    dispatch(openSuccessModalAction(payload))

  const onClose = () => dispatch(closeSuccessModal())

  const {open, message} = useAppSelector(selectSuccessModal)

  return {
    open,
    message,
    onOpen,
    onClose,
  }
}
