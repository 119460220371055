import Dialog from '@material-ui/core/Dialog'
import ConfirmButton from 'components/atoms/Buttons/ConfirmButton'
import {
  CareList,
  useEmailSendResultDialog,
} from 'features/treatment/useTreatmentDialog'
import {StyledIcaDialog} from 'pages/Analysis/EEGIndividual/ReAnalysis/StyledIcaDialog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import MultiEmailSendResultTable from './MultiEmailSendResultTable'

interface EmailSendResultProps {
  items: CareList[]
}

function EmailSendResultDialog({items}: EmailSendResultProps) {
  const {t} = useTranslation()
  const {open, onClose} = useEmailSendResultDialog()
  const handleClose = () => onClose()

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledIcaDialog>
        <h3 className='header'>{t('ISendEmail')}</h3>
        <div className='content'>
          <MultiEmailSendResultTable items={items} />
        </div>
        <div className='footer'>
          <ConfirmButton onClick={handleClose} />
        </div>
      </StyledIcaDialog>
    </Dialog>
  )
}

export default EmailSendResultDialog
