import {CustomerSearchRequest} from 'api/invoiceApis'
import {useHistory, useLocation} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'hooks'
import {
  searchCustomerRegistration,
  selectList,
  selectError,
  selectLoading,
  selectQuery,
  selectPeriod,
  selectPaging,
  selectPagingInfo,
  selectSearch,
  selectIsConfirmed,
  selectType,
} from './InvoiceListSlice'

export default function useCustomerRegistration() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectList)

  const period = useAppSelector(selectPeriod)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)
  const type = useAppSelector(selectType)
  const isConfirmed = useAppSelector(selectIsConfirmed)

  const onSearch = (payload: CustomerSearchRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return dispatch(searchCustomerRegistration(payload))
  }

  return {
    loading,
    error,
    items,
    period,
    paging,
    pagingInfo,
    search,
    query,
    type,
    isConfirmed,
    onSearch,
  }
}
