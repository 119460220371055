import {makeStyles, Theme} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  title: {
    fontSize: 22,
    color: '#4b4b4b',
    fontWeight: 500,
  },
  cardWrap: {
    overflow: 'auto',
  },
  container: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('xs')]: {
        minWidth: 354,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 589,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 985,
      },
    },
    '& .MuiDialog-paperScrollPaper': {},
  },

  cardContent: {},
  infoWrap: {
    backgroundColor: 'white',
    padding: '20px 40px',
    border: '1px solid #e4e4e4',
    borderRadius: 6,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 10px 0',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  longTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0',
    justifyContent: 'space-between',
    color: 'primary',
    background: 'white',
    paddingBottom: 10,
  },

  subTitle: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
    margin: '5px 0',
    minWidth: 50,
    flexWrap: 'wrap',
    marginTop: 5,
    color: '#4b4b4b',
    display: 'flex',
    flexDirection: 'row',
  },
  subContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    flexWrap: 'wrap',
    marginTop: 5,
    marginLeft: 10,
    width: 250,
  },
  questionContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    flexWrap: 'wrap',
    marginTop: 5,
    marginLeft: 10,
    width: 600,
  },
  subContentText: {
    fontSize: 15,
    color: '#b4b4b4',
  },
  line: {
    background: '#eeeeee',
  },

  button: {
    minWidth: 100,
    height: 50,
    marginLeft: 10,
    padding: '0px 70px',
    marginTop: 20,
  },
  hidden: {
    visibility: 'hidden',
  },
  scroll: {
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    paddingRight: 50,
    paddingLeft: 50,
  },
  buttonContainerBgColor: {
    backgroundColor: '#f4f4f4',
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    padding: 20,
    width: 520,
    justifyContent: 'center',
    '& button': {
      minWidth: 100,
      padding: '8px 0',
      flex: 1,
      margin: '0 10px',
      fontWeight: 600,
    },
    '& button:nth-child(1)': {
      marginLeft: 20,
    },
    '& button:nth-child(3)': {
      marginRight: 20,
    },
    '& .MuiButton-containedSecondary': {
      color: 'white',
    },
  },
  okButton: {
    backgroundColor: 'white',
    border: 0,
    color: '#4b4b4b',
  },
  defaultButton: {
    backgroundColor: '#f4f4f4',
    color: '#4b4b4b',
  },
  alertButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: 'white',
    '& button': {
      flex: 1,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  smallPadding: {
    padding: theme.spacing(0.5),
  },

  okButtonColor: {
    color: 'white',
  },
  editButton: {
    width: 152,
    height: 53,
  },
  iconTitleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 15,
  },
  iconColor: {
    color: '#636364',
    fontSize: 20,
    marginRight: 8,
  },
  titleDivider: {
    backgroundColor: '#f1f1f1',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    marginLeft: 50,
    marginTop: 10,
    marginRight: 10,
  },
  closeButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'start',
    '& .MuiTypography-h6': {
      margin: 0,
      lineHeight: 0,
      fontWeight: 700,
    },
  },
  closeButton: {
    backgroundColor: '#f4f4f4',
    width: 20,
    height: 20,
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: '#c5c5c5',
  },
  requiredMark: {
    color: 'red',
    marginRight: 5,
  },
  requiredMarkHidden: {
    visibility: 'hidden',
    marginLeft: 5,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentWrap: {
    padding: '20px 100px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 0',
    flexWrap: 'wrap',
    // alignItems: 'center',
    '& input:focus': {outline: `none`, borderColor: `${Colors.primary}`},

    '& .subTitle': {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 50,
      maxWidth: 200,
      flexWrap: 'wrap',
      marginTop: 5,
      color: '#757575',
    },

    '& .subContent': {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      flexWrap: 'wrap',
      marginTop: 5,
      flexGrow: 1,
      // marginLeft: 10,
    },
  },
  iconContainer: {
    backgroundColor: '#e9e9e9',
    borderRadius: 3,
    width: 45,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: 'white',
    },
  },
  fileContainer: {
    border: '3px solid #e9e9e9',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
  },
  fileContainerError: {
    border: '3px solid red',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
  },
  fileWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    flex: 1,
  },
  fileLabel: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    width: '100%',
  },
  fileInput: {
    visibility: 'hidden',
    width: 1,
    height: '100%',
  },
  errorText: {
    fontSize: 11,
    marginTop: 2,
    color: '#d03c35',
  },
  typeMemberText: {
    fontSize: 11,
    marginTop: 2,
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  radioContainer: {
    flex: 1,
  },
  privacyButton: {
    width: 304,
    height: 41,
    backgroundColor: '#5a6268',
    color: 'white',
  },
  privacyText: {
    fontSize: 14,
    color: '#868e96',
  },
  labelText: {
    color: '#868e96',
    fontSize: 16,
  },
  bullet: {
    width: 4,
    height: 4,
    backgroundColor: '#4b4b4b',
    borderRadius: 50,
    margin: '0 20px',
  },
  table: {
    width: '100%',
    padding: 0,
    borderCollapse: 'collapse',
  },
  bottomTableHeader: {
    border: '1px solid #e5e5e5',
    backgroundColor: '#38464e',
    height: 58,
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },
  bottomTableContent: {
    height: 58,
    border: '1px solid #e5e5e5',
    paddingLeft: 15,
    textAlign: 'center',
  },
  tableBackground: {
    backgroundColor: 'white',
  },
  image: {
    border: '1px solid #f1f1f1',
  },
  departmentDesc: {
    fontSize: 13,
    color: '#b4b4b4',
    fontWeight: 400,
  },
  displayController: {
    display: 'flex',
    gap: '20px',
  },
  displayRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  formatLabel: {
    color: '#5a6268',
    fontSize: '17px',
    paddingBottom: 3,
  },
  contentCheckupNote: {
    fontSize: 14,
    paddingLeft: 10,
  },
  contentCheckupNoteError: {
    fontSize: 14,
    paddingLeft: 10,
    color: '#0cc7c7',
  },
  buttonContainerClose: {
    display: 'flex',
    padding: 20,
    width: 200,
    justifyContent: 'center',
    '& button': {
      minWidth: 100,
      padding: '8px 0',
      flex: 1,
      margin: '0 10px',
      fontWeight: 600,
    },
    '& button:nth-child(1)': {
      marginLeft: 20,
    },
    '& button:nth-child(3)': {
      marginRight: 20,
    },
    '& .MuiButton-containedSecondary': {
      color: 'white',
    },
  },
}))

export default useStyles
