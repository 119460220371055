import {createStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {Colors} from 'components/common/useBaseStyle'
import Text, {TextProps} from 'components/Text'
import React, {ReactNodeArray} from 'react'

const useSubTitleStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      marginBottom: 15,
      marginTop: 15,
      alignItems: 'center',
    },
    numberBackground: {
      position: 'relative',
      backgroundColor: '#1A3E66',
      width: 25,
      height: 25,
      borderRadius: 5,
      margin: 5,
    },
    numberTitle: {
      position: 'absolute',
      color: 'white',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 600,
    },
    notation: {
      display: 'inline-block',
      width: 6,
      height: '100%',
      minHeight: '1.125rem',
      marginRight: 9,
      backgroundColor: Colors.primary,
      paddingTop: 5,
      marginTop: 5,
    },
    buttonsWrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      position: 'absolute',
      top: 0,
      right: 0,
      gap: 6,
    },
    h3Reset: {
      color: '#1A3E66',
      display: 'flex',
      alignContent: 'center',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
  }),
)

export type SubTitleBaseProps = {
  className?: string
  buttons?: ReactNodeArray
  number: string
}

type SubtitleProps = Omit<TextProps<'h3'>, 'typography' | 'as'> &
  SubTitleBaseProps

export default function ReportSubtitle({
  className,
  children,
  buttons = [],
  number,
  ...props
}: SubtitleProps) {
  const classes = useSubTitleStyles()

  const classNames = classnames({
    [classes.root]: true,
    // @ts-ignore
    [className]: className !== undefined,
  })

  return (
    <div className={classNames}>
      <div className={classes.numberBackground}>
        <div className={classes.numberTitle}>{number}</div>
      </div>
      <Text
        typography='subTitle'
        as='h3'
        {...props}
        className={classes.h3Reset}
      >
        {children}
      </Text>
      {buttons.length > 0 && (
        <div className={classes.buttonsWrapper}>{buttons}</div>
      )}
    </div>
  )
}
