import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Table, TableBody, TableHead, TableRow} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'

import useInvoiceView from 'features/invoice/useInvoiceView'

import InvoiceViewStyle from 'pages/CustomerManagement/InvoiceView/Style'
import {DEFAULT_BILLING_HEAD} from '../constant'

const useStyles = makeStyles(InvoiceViewStyle)

function DefaultBilling() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {product} = useInvoiceView()
  const dataBilling = useMemo(() => {
    let result = [[t('ITotal'), 0, '']]
    if (product) {
      result = [
        ...product.pkgInfo.baseFees.map((item) => {
          return [item.name, item.price, '']
        }),
        ...result,
      ]
      const total = product.pkgInfo.baseFees.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.price
        },
        0,
      )
      result[result.length - 1][1] = total
    }

    return result
  }, [product])

  return (
    <div className={classes.defaultBilling} style={{marginTop: 5}}>
      <div className={classes.defaultBillingTitle}>{t('IDefaultBilling')}</div>
      <div className={classes.defaultBillingTable}>
        <Table>
          <TableHead>
            <TableRow>
              {DEFAULT_BILLING_HEAD.map((item, colId) => (
                <TableCell
                  key={`billing_${colId}`}
                  className={`${classes.header} ${classes.backGroundHead}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataBilling.map((data, index) => (
              <TableRow key={`billing_body${index}`}>
                {data.map((cell, cellId) => (
                  <TableCell
                    key={`cell_billing_body${cellId}`}
                    className={
                      index === dataBilling.length - 1
                        ? classes.header
                        : classes.cell
                    }
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default DefaultBilling
