import {BitKey, fetchEdfEncryptApi} from 'api/analysisApi'
import {getAESDecryptValue, stringToArrayBuffer} from 'helpers/encryptionHelper'
import FileResource, {Options} from './FileResource'

const STATIC_HEADER_SIZE = 256
const SAMPLE_SIZE = 2
// 1초에 들어가는 데이터 갯수
const SAMPLE_RECORD_SIZE = 250

interface Channels {
  index: number
  digitalMaximum: number
  digitalMinimum: number
  numberOfSamples: number
  physicalMaximum: number
  physicalMinimum: number
  standardIndex: number
  label: string
  physicalDimension: string
  preFiltering: string
  standardLabel: string
  transducerType: string
}

export interface Header {
  version: number | null
  startTime: string | null
  startDate: string | null
  start: Date | null
  end: Date | null
  reserved: string | null
  recordSize: number | null
  recordSampleSize: number | null
  recordIndicies: number[] | null
  recordIdentification: string | null
  recordHeaderByteSize: number | null
  recordDurationTime: number | null
  numberOfDataRecords: number | null
  numberOfSignals: number | null
  patientIdentification: string | null
  channels: Channels[] | null
}

interface FilesProps {
  file: File
}
export default class Edf {
  file?: FileResource | null

  header: Header | null

  physicalData: any[]

  unitData: Uint8Array | null

  decData: number[] | null

  keyArray: number[]

  constructor() {
    this.header = null
    this.physicalData = []
    this.unitData = null
    this.decData = null
    this.keyArray = []
  }

  init({file}: FilesProps) {
    if (!file) this.file = null
    this.file = new FileResource(file)
    this.header = {
      start: null,
      end: null,
      version: null,
      recordSize: null,
      recordSampleSize: null,
      recordIndicies: null,
      patientIdentification: null,
      recordIdentification: null,
      startDate: null,
      startTime: null,
      recordHeaderByteSize: null,
      reserved: null,
      numberOfDataRecords: null,
      recordDurationTime: null,
      numberOfSignals: null,
      channels: [],
    }
    this.physicalData = []
    this.unitData = new Uint8Array()
    this.keyArray = []
  }

  async readHeader() {
    await this.readStaticHeader()
    await this.readDynamicHeader()
    await this.readFile()

    const result = {
      header: this.header,
      physicalData: this.physicalData,
    }

    return result
  }

  async readFile() {
    const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

    if (!this.file) return
    if (!this.header) return
    if (!this.header.recordHeaderByteSize) return
    if (!this.file.file) return
    await this.getDecData()
    await this.getData()
    while (this.physicalData.length === 19) {
      timer(500)
    }
  }

  // header 생성
  // 기본정보
  async readStaticHeader() {
    if (!this.file) return
    if (!this.header) return
    const {header} = this
    const options: Options = {
      from: 0,
      till: STATIC_HEADER_SIZE,
    }
    const result = await this.file.fileRead(options)

    header.version = +result.slice(0, 8)
    header.patientIdentification = String(result.slice(8, 88)).trim()
    header.recordIdentification = String(result.slice(88, 168)).trim()
    header.startDate = String(result.slice(168, 176)).trim()
    header.startTime = String(result.slice(176, 184)).trim()
    header.recordHeaderByteSize = +result.slice(184, 192)
    header.reserved = String(result.slice(192, 236)).trim()
    header.numberOfDataRecords = +result.slice(236, 244)
    header.recordDurationTime = +result.slice(244, 252)
    header.numberOfSignals = +result.slice(252, 256)
  }
  // header 생성
  // digit min max, sample 추가

  async readDynamicHeader() {
    if (!this.file) return
    if (!this.header) return
    const {header} = this
    const {numberOfSignals} = header
    if (!numberOfSignals) return

    const channels = Array(numberOfSignals)
      .fill(1)
      .map((v, index) => ({index}))
    const options = {
      from: STATIC_HEADER_SIZE,
      till: STATIC_HEADER_SIZE + numberOfSignals * 256,
    }
    const fields = [
      // edf 스펙 확인
      /* eslint-disable no-multi-spaces, key-spacing */
      {name: 'label', size: 16}, // label for each signal
      {name: 'transducerType', size: 80},
      {name: 'physicalDimension', size: 8}, // uV or degreeC
      {name: 'physicalMinimum', size: 8, filter: parseFloat},
      {name: 'physicalMaximum', size: 8, filter: parseFloat},
      {name: 'digitalMinimum', size: 8, filter: parseInt},
      {name: 'digitalMaximum', size: 8, filter: parseInt},
      {name: 'preFiltering', size: 80},
      {name: 'numberOfSamples', size: 8, filter: parseInt},
    ] /* eslint-enable no-multi-spaces, key-spacing */

    const result = await this.file.fileRead(options)
    let offset = 0

    fields.forEach(({name, size, filter = (x) => x}) => {
      for (let i = 0; i < numberOfSignals; i += 1) {
        const value = result.slice(offset, offset + size).trim()
        // @ts-ignore
        channels[i][name] = filter(value)
        offset += size
      }
    })

    // @ts-ignore
    header.channels = channels
    header.recordIndicies = []
    header.recordSize = channels.reduce((sum, channel) => {
      // @ts-ignore
      header.recordIndicies.push(sum)
      // @ts-ignore
      return sum + channel.numberOfSamples
    }, 0)
    header.recordSampleSize = header.recordSize / SAMPLE_SIZE
  }

  // eslint-disable-next-line class-methods-use-this
  async getKey(key: string): Promise<BitKey> {
    const result = await fetchEdfEncryptApi(key)
    return result.data
  }

  async getDecData(): Promise<number[]> {
    if (!this.header) await this.readHeader()
    if (!this.header) return []
    if (!this.header.patientIdentification) return []
    if (!this.header.recordHeaderByteSize) return []

    const indexKey = this.header.patientIdentification
    const value = this.header.patientIdentification.slice(
      0,
      indexKey.length - 6,
    )

    const getBitKeyData = await this.getKey(value)
    const xorKey = getAESDecryptValue(
      value,
      getBitKeyData.bitKey,
      getBitKeyData.key,
    )

    // xor 사용할 키값
    const keyArr: number[] = []

    const res = await stringToArrayBuffer(xorKey)

    const dataResult2 = new Int16Array(res)
    for (let i = 0; i < 256; i += 16) {
      keyArr.push(...dataResult2)
    }
    this.keyArray = keyArr
    return keyArr
  }

  // edf 데이터 파싱
  async getData() {
    // 헤더 없을경우 헤더 생성
    if (!this.header) await this.readHeader()
    if (!this.header) return
    if (!this.header.channels) return
    if (!this.header.numberOfSignals) return
    if (!this.header.numberOfDataRecords) return
    if (!this.header.recordDurationTime) return
    if (!this.header.recordSize) return
    if (!this.file) return
    if (!this.file.file) return
    if (!this.header.recordHeaderByteSize) return
    if (!this.header.recordIndicies) return

    // const DURATION = 1

    const rawSignals = new Array(this.header.numberOfSignals)
    const physicalSignals = new Array(this.header.numberOfDataRecords)

    // 채널 숫자
    for (let i = 0; i < 19; i += 1) {
      rawSignals[i] = new Array(this.header.numberOfDataRecords)
      physicalSignals[i] = new Array(this.header.numberOfDataRecords)
    }

    // edf에서 데이터를 뽑아올때 *2를 해줘야지 제대로된 데이터가 나온다
    // 왜냐면 데이터가 담기는 최소 단위가 2byte이기 때문에
    // -> Since the minimum unit of data is 2 bytes, multiplication is required.
    const sampleSize = SAMPLE_RECORD_SIZE * 2
    let start = this.header.recordHeaderByteSize
    let end = this.header.recordHeaderByteSize + 500

    // xor 암호화 key index
    let keyIndex = 0

    for (let j = 0; j < this.header.numberOfDataRecords; j += 1) {
      // signal index
      for (let k = 0; k < 19; k += 1) {
        // sample이란? -> 그래프에 찍히는 점, 즉 하나하나의 숫자f
        //            -> points on the graph,
        // number of samples -> 1초당 샘플의 갯수
        //                   -> number of samples per second
        // duration = => sample을 저장 초수 ex) 1초(duration)당 250(sample)개.. 0.5초당 125개..
        // 250 x 120(총 숫자 저장된 초수 numberOfDataRecords) x 1 초 (duration)
        // rawData signal (계산되지 않은 데이터)
        const options: Options = {
          type: 'arrayBuffer',
          from: start,
          till: end,
        }

        /* eslint-disable no-await-in-loop */
        const result = await this.file.fileRead(options)
        // 여기에서 xor 비트 연산 작업이 되어가지고 온전한 file을 가지고 데이터 값 만들기
        const dataResult = new Int16Array(result)
        // 2바이트씩 기록된 어레이
        // uint -> 1바이트씩 기록된 어레이

        const xorDecData: number[] = []

        for (let decIndex = 0; decIndex < dataResult.length; decIndex += 1) {
          // eslint-disable-next-line no-bitwise
          const xor = dataResult[decIndex] ^ this.keyArray[keyIndex]
          xorDecData.push(xor)

          if (keyIndex < 256) {
            keyIndex += 1
          }
          if (keyIndex >= 256) {
            keyIndex = 0
          }
        }

        rawSignals[k].splice(j, 1, xorDecData)

        // physical signal (그래프를 그릴수 있도록 계산된 데이터 (real edf raw data))
        const physicalSignal = new Float32Array(xorDecData.length).fill(0)

        const digitalSignalRange =
          this.header.channels[k].digitalMaximum -
          this.header.channels[k].digitalMinimum
        const physicalSignalRange =
          this.header.channels[k].physicalMaximum -
          this.header.channels[k].physicalMinimum

        for (let index = 0; index < 250; index += 1) {
          physicalSignal[index] =
            ((xorDecData[index] - this.header.channels[k].digitalMinimum) /
              digitalSignalRange) *
              physicalSignalRange +
            this.header.channels[k].physicalMinimum
        }
        await physicalSignals[k].splice(j, 1, physicalSignal)

        // 한사이클이 돌면 start와 end값을 변경시켜서 다음 데이터를 불러오도록 start point, end point 수정
        // Modify start, end to load the following data
        start += sampleSize
        end += sampleSize
      }
    }

    this.physicalData = physicalSignals

    if (this.physicalData.length > 0) {
      /* eslint-disable */
      return Promise.resolve(physicalSignals)
    }

    return setTimeout(() => console.log('after'), 1000)
  }
}
