import styled from 'styled-components'

export interface FlexItemProps {
  flex?: number
  padding?: string
  bgcolor?: string
  fontSize?: string
  color?: string
  fontWeight?: string
}

export interface FlexContainerProps {
  padding?: string
  justifyContent?: string
  bgcolor?: string
  flexDirection?: string
  cursor?: string
  alignItem?: string
  borderLeft?: string
  height?: string
  width?: string
  gap?: string
  radius?: string
  margin?: string
}

export interface TextProps {
  padding?: string
  fontWeight?: string
  size?: string
  radius?: string
  margin?: string
  bgcolor?: string
  color?: string
  cursor?: string
  width?: string
  textAlign?: string
  border?: string
  height?: string
}

export interface DivWrapperProps {
  minHeight?: string
}

export interface BoxContainerProps {
  bgColor?: string
  padding?: string
  radius?: string
  margin?: string
  width?: string
  shadow?: string
  display?: string
  minHeight?: string
}

export interface StyledButtonGroupAlignRightProps {
  justifyContent?: string
}

export const StyledButtonGroupAlignRight = styled.div<StyledButtonGroupAlignRightProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: ${(props) => props.justifyContent || 'flex-end'};
`

export const StyledBrainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const GroupContainer = styled.div`
  max-height: 100%;
  overflow: scroll;
`

export const StyledBrainImgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 220px;
  margin-top: 8px;
  margin-bottom: 8px;

  &:nth-child(2) {
    margin-top: 25px;
    margin-bottom: 20px;
  }
`

export const StyledBrainImgInwrap = styled.div`
  position: relative;
  height: 100%;
  display: inline-flex;

  & img {
    max-height: 100%;
  }
`

export const StyledBrainImgContainerDim = styled.div`
  display: flex;
  justify-content: center;
  height: 220px;
  position: relative;
`

export const StyledBrainImgInwrapDim = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: auto;
  right: auto;

  & img {
    max-height: 100%;
    position: relative;
    left: 4px;
    opacity: 0.3;
  }
`

export const StyledNoticeTextSpan = styled.span`
  font-weight: 500;
  color: #5a6268;
  position: absolute;
  display: inline-block;
  width: 100%;
  line-height: 24px;
  text-align: center;
  max-width: 140px;
  word-break: keep-all;
`

export const StyledTherapyPointWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

export const StyledTherapyPointCheckbox = styled.input`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 0px;
  appearance: none;

  &.therapyPointRecent {
    &:before,
    &:after {
      transition: all 0s;
    }
  }

  &:not(.therapyPointRecent) {
    cursor: pointer;
  }

  &:not(.therapyPointRecent):hover:after {
    background: #8ea9c1;
  }

  &:not(.therapyPointRecent):checked:hover:after {
    background: #e37878;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #a6bcd0;
    border-radius: 50%;
    transition: background 0.25s linear;
  }

  &:checked:after {
    background: #ff8686;
  }

  &:checked:before {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    background: #ff86864d;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.25s linear;
  }

  &.fp1 {
    top: 2px;
    left: 64px;
  }

  &.fp2 {
    top: 3px;
    right: 65px;
  }

  &.f7 {
    top: 48px;
    left: 18px;
  }

  &.f3 {
    top: 55px;
    left: 50px;
  }

  &.fz {
    top: 56px;
    left: 80px;
  }

  &.f4 {
    top: 55px;
    right: 50px;
  }

  &.f8 {
    top: 48px;
    right: 18px;
  }

  &.t3 {
    top: 98px;
    left: 3px;
  }

  &.c3 {
    top: 98px;
    left: 42px;
  }

  &.cz {
    top: 98px;
    left: 81px;
  }

  &.c4 {
    top: 98px;
    right: 43px;
  }

  &.t4 {
    top: 98px;
    right: 3px;
  }

  &.t5 {
    top: 148px;
    left: 10px;
  }

  &.p3 {
    top: 141px;
    left: 46px;
  }

  &.pz {
    top: 140px;
    left: 80px;
  }

  &.p4 {
    top: 141px;
    right: 48px;
  }

  &.t6 {
    top: 148px;
    right: 12px;
  }

  &.o1 {
    bottom: 4px;
    left: 64px;
  }

  &.o2 {
    right: 65px;
    bottom: 4px;
  }
`

export const StyledTextImgWrap = styled.figure`
  margin: 0px;
  width: 40px;
  position: absolute;
  top: 0;

  & > img {
    width: 100%;
  }
`

export const StyledTextImgWrapLeft = styled(StyledTextImgWrap)`
  left: -12.5%;
`

export const StyledTextImgWrapRight = styled(StyledTextImgWrap)`
  right: -17.5%;
`

export const StyledTextEditInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5a6268;
  width: :min-content;
`
export const StyledTextInfoGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 8px;

  & > div {
    flex: 1;
    max-width: 100px;
    height: 42;
  }
`

export const StyledExplanation = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  font-size: 11px;
  color: #333333;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    width: 3px;
    height: 3px;
    background-color: #333333;
    border-radius: 50%;
  }
`

export const StyledTextReadInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  color: #5a6268;
  margin-bottom: 20px;
`

export const StyledTextReadInfoName = styled.div`
  font-size: 13px;
  display: flex;
  margin-right: 10px;
  font-weight: 500;
  color: #333333;
  align-items: center;
`

export const StyledTextReadInfoValue = styled.div`
  display: flex;
  font-size: 15px;
`

export const StyledTextSpan = styled.span`
  display: inline-block;
`

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  padding: ${(props) => props.padding || '0px'};
  justify-content: ${(props) => props.justifyContent || 'initial'};
  background: ${(props) => props.bgcolor || '#fff'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  cursor: ${(props) => props.cursor || 'auto'};
  align-items: ${(props) => props.alignItem || 'none'};
  border-left: ${(props) => props.borderLeft || 'none'};
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
  gap: ${(props) => props.gap || 'auto'};
  border-radius: ${(props) => props.radius || 'auto'};
  margin: ${(props) => props.margin || 'initial'};
`

export const FlexItem = styled.div<FlexItemProps>`
  flex: ${(props) => props.flex || 1};
  padding: ${(props) => props.padding || '0px'};
  background: ${(props) => props.bgcolor || '#fff'};
  font-size: ${(props) => props.fontSize || '15px'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontWeight || '500'};
`

export const Text = styled.div<TextProps>`
  padding: ${(props) => props.padding || '0px'};
  font-weight: ${(props) => props.fontWeight || '500'};
  font-size: ${(props) => props.size || '15px'};
  border-radius: ${(props) => props.radius || '0px'};
  border: ${(props) => props.border || 'none'};
  margin: ${(props) => props.margin || '0px'};
  background: ${(props) => props.bgcolor || '#fff'};
  color: ${(props) => props.color || '#000'};
  cursor: ${(props) => props.cursor || 'auto'};
  width: ${(props) => props.width || 'auto'};
  text-align: ${(props) => props.textAlign || 'left'};
`
export const BoxContainer = styled.div<BoxContainerProps>`
  background: ${(props) => props.bgColor || '#fff'};
  box-shadow: ${(props) => props.shadow || '0px 4px 8px rgba(0, 0, 0, 0.12)'};
  padding: ${(props) => props.padding || 0};
  border-radius: ${(props) => props.radius || 0};
  margin: ${(props) => props.margin || '0px'};
  width: ${(props) => props.width || 'initial'};
  display: ${(props) => props.display || 'block'};
  min-height: ${(props) => props.minHeight || 'auto'};
`
export const PrimaryBtn = styled.button<TextProps>`
  padding: ${(props) => props.padding || '0px'};
  font-weight: ${(props) => props.fontWeight || '500'};
  font-size: ${(props) => props.size || '15px'};
  border-radius: ${(props) => props.radius || '0px'};
  margin: ${(props) => props.margin || '0px'};
  background: ${(props) => props.bgcolor || '#fff'};
  color: ${(props) => props.color || '#000'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

export const DivWrapper = styled.div<DivWrapperProps>`
  min-height: ${(props) => props.minHeight || 'auto'};
`

export const MainContainer = styled.div`
  padding: 10px 50px 20px;
`

export const TitleProtocol = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #333333;
`

// Section Group Name
export const GroupNameContainer = styled.div`
  margin-top: 5px;
  padding: 5px 20x;
`

export const GroupName = styled.div`
  padding: 7px 15px;
  border-radius: 3px;
  background-color: #f4f4f7;
  color: #5c5c5c;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 3px;
`

// Section Slider
export const ProtocolSliderContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`

export const ProtocolSlider = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
`

// Main Content Component
export const InputEditName = styled.input`
  padding: 10px;
  width: 300px;
  margin: 0;
  border: 1px solid #e9e9e9;
  border-right: none;
`

export const TitleGroup = styled.h3`
  max-width: 300px;
  overflow: hidden;
  margin: 0;
  color: #5a6268;
`

export const WrapperSetTabs = styled.div`
  display: flex;
  transform: translateY(1px);
`
