import {IconButton} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  fetchMciReportApi,
  FetchMCIReportRequest,
  MCIReport,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import MCIReportPage from 'components/molcules/MciReport/MCIReportPage'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import {
  ReportWrap,
  ReportTranslationSelectWrap,
  ReportFetcherWrap,
  ReportCustomPadding,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import PdfDownload from 'components/PdfDownload'
import useLanguageReport from 'hooks/useLanguageReport'

function ReportPage() {
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()
  const {t, i18n} = useTranslation()
  const locale = i18n.language

  const params: FetchMCIReportRequest = {
    requestIds: [Number.parseInt(id, 10)],
    locale,
  }

  const asyncResult = useAsync(fetchMciReportApi, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })

  const [resultInfo, setResultInfo] = useState<MCIReport>()

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      setResultInfo(result)
    }
  }, [asyncResult.result])

  useEffect(() => {
    asyncResult.execute(params)
  }, [locale])

  useLanguageReport()

  return (
    <div
      style={{
        width: '1300px',
      }}
      id='print-modified'
    >
      <PdfDownload pathPDF={resultInfo?.finalReportPath} />
      <ReportWrap style={{width: '100%'}}>
        <ReportTranslationSelectWrap>
          <TranslationSelect />
        </ReportTranslationSelectWrap>
        <ReportFetcherWrap style={{width: '100%'}}>
          <ReportCustomPadding />
          <CardWithTitle title={t('IEEGMCIReport')} style={{width: '100%'}}>
            <div
              style={{
                minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {asyncResult.loading && (
                <CircularProgress size='3rem' color='secondary' />
              )}
              {asyncResult.error && (
                <div>
                  {asyncResult.error.message}
                  <IconButton
                    onClick={() => asyncResult.execute(params)}
                    size='small'
                  >
                    <ReplayIcon />
                  </IconButton>
                </div>
              )}
              {asyncResult.result && (
                <div style={{width: '100%'}}>
                  {resultInfo !== undefined && (
                    <ReportInfo resultType='MCI' {...resultInfo} eeg />
                  )}
                  {resultInfo !== undefined && (
                    <div style={{paddingBottom: 100}}>
                      <MCIReportPage
                        MciImagePath={resultInfo.imgPath}
                        probability={resultInfo.probability}
                        clientName={resultInfo.clientName}
                        clientFirstName={resultInfo.clientFirstName ?? ''}
                        clientLastName={resultInfo.clientLastName ?? ''}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </CardWithTitle>
          <ReportCustomPadding />
        </ReportFetcherWrap>
        <ReportCustomPadding />
      </ReportWrap>
    </div>
  )
}

export default ReportPage
