import request from 'api/index'
import {MediCalPageRequestT} from './serviceFetcher'

export interface TreatmentSearchRequest extends PagingRequest, PeriodRequest {
  uid?: string | null
  uuid?: string | null
  search: TreatmentSearchKind
  type?: 'ALL' | 'TREATMENT' | 'THERAPY'
}

export type TreatmentTypeSearch = 'CHART_NO' | 'NAME' | 'CARE_DOC'

export interface TreatmentSearchNewRequest {
  uid?: string
  patientUuid?: string | null
  careDoctorName?: string
  chartNumber?: number
  medicalNum?: number
  searchName?: TreatmentTypeSearch
  searchValue?: string
  patientName?: string
  primaryDoctorName?: string
  type?: 'ALL' | 'TREATMENT' | 'THERAPY'
  startDate: string
  endDate: string
  pageNumber: number
  pageSize: number
}

export interface TreatmentSearchMonthlyAllRequest {
  endDate?: Date
  searchMonth: number
  searchYear: number
  startDate?: Date
  uid: string
}

export interface TreatmentSearchMonthlyAllResponse {
  uuid?: string | null
  code: number
  data: string[]
  message: string
  success: boolean
}

// export const searchTreatmentAllApi = ({
//   search,
//   paging,
//   period,
//   type = 'ALL',
// }: TreatmentSearchRequest) =>
//   request<PageResponse<Treatment>>({
//     method: 'get',
//     path: '/api/treatment/v2/list/all',
//     params: {
//       ...search,
//       ...paging,
//       ...period,
//       type,
//     },
//   })

// export const searchTreatmentOneApi = ({
//   uuid,
//   paging,
//   period,
//   type = 'ALL',
// }: TreatmentSearchRequest) =>
//   request<PageResponse<Treatment>>({
//     method: 'get',
//     path: '/api/treatment/v1/list/one',
//     params: {
//       uuid,
//       ...paging,
//       ...period,
//       type,
//     },
//   })

export const searchTreatmentMonthlyAllApi = (
  params: TreatmentSearchMonthlyAllRequest,
) =>
  request<TreatmentSearchMonthlyAllResponse>({
    method: 'get',
    path: '/api/treatment/v1/list/all/monthlyCount',
    params,
  })

// export const searchTreatmentWebApi = (params: TreatmentSearchNewRequest) =>
//   request<PageResponse<Treatment>>({
//     method: 'get',
//     path: '/api/treatment/v1/list/web',
//     params,
//   })

interface SendEmailRequest {
  uid: string
  careList: number[]
}

interface CareList {
  careId: number
  country: string
  eegSummary: string
  errorMsg: string
  hrvSummary: string
  mciSummary: string
  patientEmail: string
}

export const sendEmailApi = (data: SendEmailRequest) =>
  request<ModelsResponse<CareList>>({
    method: 'post',
    path: '/api/treatment/v1/email/send',
    data,
  })

export const searchTreatmentAllApiV3 = (data: TreatmentSearchNewRequest) =>
  request<PageResponse<Treatment>>({
    method: 'get',
    path: '/api/treatment/v3/list',
    params: {...data},
  })
