import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import ProgressBar from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/ProgressBar'
import TitlePosition from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/TitlePosition'

import ProcessArrow from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/ProcessArrow'
import useHealthCenterStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV4/style'

interface Props {
  headerName: string
  percentage: number
  index: number
  top: string
  middle: string
  bottom: string
}

function PercentBarCardV4({
  headerName,
  percentage,
  index,
  top,
  middle,
  bottom,
}: Props) {
  const classes = useHealthCenterStyles()
  const {t} = useTranslation()
  const getTextInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return top
    }
    if (Math.floor(percentage) > 70) {
      return bottom
    }
    return middle
  }, [percentage])
  const listPosition = {
    TOP_LEFT: 1,
    TOP_RIGHT: 3,
    BOT_LEFT: 2,
    BOT_RIGHT: 4,
  }
  const contentTitlePosition = [
    {
      id: 0,
      text: top,
    },
    {
      id: 1,
      text: middle,
    },
    {
      id: 0,
      text: bottom,
    },
  ]
  const getColorInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return '#B4B4B4'
  }, [percentage])

  const isShowBorder = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return true
    }
    if (Math.floor(percentage) > 70) {
      return true
    }
    return false
  }, [percentage])

  const getBackGroundColor = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '2px solid #FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '2px solid #008BC9'
    }
    return ''
  }, [percentage])

  const getBorderlineDot = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '1px dotted #FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '1px dotted #008BC9'
    }
    return '#B4B4B4 dotted 1px '
  }, [percentage])

  const checkPosition = useMemo(() => {
    if (index === listPosition.TOP_LEFT) {
      return classes.positionTopLet
    }
    if (index === listPosition.TOP_RIGHT) {
      return classes.positionTopRight
    }
    if (index === listPosition.BOT_LEFT) {
      return classes.positionBotLeft
    }
    return classes.positionBotRight
  }, [index])

  const getColorText = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return ''
  }, [percentage])

  return (
    <div className={`${checkPosition} ${classes.connectBrainRegionPart3}`}>
      <div
        className={classes.cardBar}
        style={{
          border: getBackGroundColor,
          float:
            index === listPosition.TOP_RIGHT || index === listPosition.BOT_RIGHT
              ? 'right'
              : 'left',
        }}
      >
        {(index === listPosition.TOP_RIGHT ||
          index === listPosition.BOT_RIGHT) && (
          <div
            className={classes.topStatus}
            style={{background: getColorInRound}}
          >
            {getTextInRound}
          </div>
        )}

        <div className={classes.contentCardBar}>
          <div className={classes.percentBarV2Top}>
            <div className={classes.topScore}>
              <div className={classes.topScoreDetail}>
                <span
                  className={classes.topScorePosition}
                  style={{color: getColorText}}
                >
                  {headerName}
                </span>
              </div>
            </div>
            <div
              className={classes.topScorePercent}
              style={{color: getColorText}}
            >
              <span
                className={classes.topScorePercentScore}
                style={{color: getColorText}}
              >
                {percentage}
              </span>
              {t('IPoints')}
            </div>
          </div>

          <div className={classes.percentBarV2Bot}>
            <ProcessArrow index={3} percentage={percentage} />
            <ProgressBar index={3} />
            <TitlePosition
              contentTitlePosition={contentTitlePosition}
              index={3}
            />
          </div>
        </div>
        {(index === listPosition.TOP_LEFT ||
          index === listPosition.BOT_LEFT) && (
          <div
            className={classes.topStatus}
            style={{background: getColorInRound}}
          >
            {getTextInRound}
          </div>
        )}
      </div>
      {index === listPosition.TOP_LEFT && (
        <div
          className={classes.lineTopLeft}
          style={{
            width: isShowBorder ? '109px' : '134px',
            border: getBorderlineDot,
          }}
        >
          <span
            className={classes.dotPoint}
            style={{
              bottom: '-3px',
              right: '-6px',
            }}
          />
        </div>
      )}
      {index === listPosition.BOT_LEFT && (
        <div
          className={classes.lineBotLeft}
          style={{
            width: isShowBorder ? '108px' : '134px',
            border: getBorderlineDot,
          }}
        >
          <span
            className={classes.dotPoint}
            style={{
              top: '-3px',
              right: '-5px',
            }}
          />
        </div>
      )}
      {index === listPosition.TOP_RIGHT && (
        <div
          className={classes.lineTopRight}
          style={{
            width: isShowBorder ? '92px' : '134px',

            border: getBorderlineDot,
          }}
        >
          <span
            className={classes.dotPoint}
            style={{
              bottom: '-3px',
              left: '-5px',
            }}
          />
        </div>
      )}
      {index === listPosition.BOT_RIGHT && (
        <div
          className={classes.lineBotRight}
          style={{
            width: isShowBorder ? '89px' : '134px',

            border: getBorderlineDot,
          }}
        >
          <span
            className={classes.dotPoint}
            style={{
              top: '-3px',
              left: '-4px',
            }}
          />
        </div>
      )}
    </div>
  )
}

export default PercentBarCardV4
