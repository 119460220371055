import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PointRankDialog from 'components/Dialog/PointRankDialog'
import SubTitle from 'components/Text/SubTitle'
import {openPointRankDialog} from 'features/modal/modalSlice'
import useOrganization from 'features/org/useOrganization'
import {selectMyPackage, selectPointInfo} from 'features/point/pointSlice'
import {useAppDispatch} from 'hooks'
import PointUsableRange from 'pages/SalesPages/SalesPaymentPage/SalesPointRating/PointUsableRange'
import React, {useEffect} from 'react'
import brain from 'assets/img/brain.svg'
import heartbeat from 'assets/img/heartbeat.svg'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Box, Divider} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    containerTitle: {
      padding: 10,
      fontWeight: 600,
      color: '#35495D',
      marginLeft: 20,
    },
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
      '& div ': {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      flex: 1,
    },

    searchButton: {
      height: 56,
      marginTop: 15,
    },

    indexInputContainer: {
      marginTop: 6,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    button: {
      height: 55,
      minWidth: 110,
      maxWidth: 110,
    },
    tableWrap: {
      backgroundColor: '#fff',
      border: '1px solid #D6D6D8',
      borderRadius: 5,
      marginTop: 10,
      marginBottom: 30,
      overflow: 'Hidden',
      // margin: 15,
    },
    chipsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      marginLeft: 15,
      marginRight: 15,
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    align: {
      justify: 'center',
    },
    dateSearch: {
      marginLeft: 10,
    },
    dateWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
    },
    rankingRoot: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    rankingBoxContainer: {
      border: '1px solid #f3f3f2',
      backgroundColor: 'white',
      borderRadius: 12,
      padding: '20px 60px',
      width: '100%',
    },
    rankingBoxWrap: {
      marginTop: 10,
    },
    rankingBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '4px',
      width: 430,
      flex: 1,
      gap: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    descriptionBox: {
      width: 100,
      height: 74,
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      border: '1px solid rgb(53 73 99 / 20%)',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
    },
    iconWrap: {
      color: '#9B9BA4',
    },
    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      fontSize: 12,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
      '& > .pointNameText': {
        color: '#3ebbbd',
      },
      '& > .pointRankText': {
        color: '#b7b7b7',
      },
      '& > .wavePointTitle': {
        color: '#5a6268',
        // whiteSpace: 'pre-wrap',
      },
    },
    wavePoint: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      gap: 10,
      '& > .title': {
        fontSize: 25,
        fontWeight: 600,
        color: '#5a6268',
      },
      '& > .remainNumber': {
        fontSize: 13,
        fontWeight: 400,
        color: '#5a6268',
      },
    },
    pointCont: {
      display: 'flex',
      fontWeight: 400,
      color: '#5a6268',
      textAlign: 'center',
      fontSize: 18,
      marginTop: 3,
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 30,
      flex: 1,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'column',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
    },
    hospital: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 19,
    },
    outCircle: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      width: 66,
      height: 66,
      borderRadius: 50,
      boxShadow: '0 4px 7px rgba(0, 0, 0, 0.16)',
      position: 'relative',
    },
    inCircle: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      backgroundImage: `linear-gradient(160deg, #3ebbbd 4%, #71d6d9 87%)`,
      width: 46,
      height: 46,
      borderRadius: 30,
    },
    circleText: {
      fontSize: 37,
      fontWeight: 800,
      color: 'white',
      position: 'absolute',
      marginTop: -5,
    },
    line: {
      padding: '0 20px',
      backgroundColor: '#f3f3f2',
    },
  }),
)

const buttonUseStyles = makeStyles(() => ({
  button: {
    minWidth: 110,
  },
}))

function PointPaymentButton() {
  const {t} = useTranslation()
  const classes = buttonUseStyles()
  const dispatch = useAppDispatch()
  const pointInfo = useSelector(selectPointInfo)
  const openPointRank = () => {
    dispatch(openPointRankDialog())
  }

  return (
    <div>
      {pointInfo != null && (
        <Button
          className={classes.button}
          color='secondary'
          variant='contained'
          disableElevation
          onClick={() => openPointRank()}
          endIcon={<InfoOutlinedIcon />}
        >
          {t('IReadAndUpdate')}
        </Button>
      )}
    </div>
  )
}

export default function SalesPointRating() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {organization: orgInfo} = useOrganization()
  const pointInfo = useSelector(selectPointInfo)
  const myPackageInfo = useSelector(selectMyPackage)
  const [pointRangeArray, setPointRangeArray] = React.useState<string[]>([
    'string',
    'string',
  ])

  function getArray(array: string): string[] {
    const stringArray = array.split(',')
    return stringArray
  }

  useEffect(() => {
    setPointRangeArray([])
    setPointRangeArray(getArray(pointInfo?.eegPackageDetail ?? ''))
    setPointRangeArray((pointRangeArray) =>
      pointRangeArray.concat(getArray(pointInfo?.hrvPackageDetail ?? '')),
    )
  }, [pointInfo])

  return (
    <>
      <Grid className={classes.pointContainer}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Grid className={classes.rankingRoot}>
            <SubTitle>{t('IPointRate')} </SubTitle>
            {orgInfo?.promotionYn === 'N' && (
              <div className={classes.rankingBoxContainer}>
                <Grid container className={classes.rankingBoxWrap}>
                  <div className={classes.rankingBox}>
                    <div>
                      <span className={classes.outCircle}>
                        <span className={classes.circleText}>
                          <img src={brain} alt='eeg point' />
                        </span>
                        <span className={classes.inCircle}> </span>
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 200,
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.pointTitle}
                        style={{display: 'flex', flexDirection: 'row'}}
                      >
                        <Box minWidth={65} className='pointNameText'>
                          {t('IPointEegRate')}
                        </Box>
                        <Box minWidth={35} className='pointRankText'>
                          {t('IPointRateName')}
                        </Box>
                      </Typography>
                      {myPackageInfo && (
                        <div>
                          {myPackageInfo?.length > 0 && (
                            <Typography className={classes.pointCont}>
                              {myPackageInfo[0].packageName ?? '-'}
                            </Typography>
                          )}
                          {myPackageInfo?.length === 0 && (
                            <Typography className={classes.pointCont}>
                              None
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography className={classes.pointTitle}>
                        <Box minWidth={130} className='wavePointTitle'>
                          {t('IPointWaveAmount')}
                        </Box>
                      </Typography>
                      <Typography className={classes.wavePoint}>
                        {pointInfo?.eegPackagePoint ?? '-'}
                      </Typography>
                    </div>
                  </div>
                  <Typography className={classes.smallPadding} />
                </Grid>
                <Typography className={classes.padding} />
                <Divider className={classes.line} />
                <Typography className={classes.padding} />
                <Grid container className={classes.rankingBoxWrap}>
                  <div className={classes.rankingBox}>
                    <div>
                      <span className={classes.outCircle}>
                        <span className={classes.circleText}>
                          <img
                            style={{marginLeft: 5}}
                            src={heartbeat}
                            alt='hrv point'
                          />
                        </span>
                        <span className={classes.inCircle}> </span>
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 200,
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.pointTitle}
                        style={{display: 'flex', flexDirection: 'row'}}
                      >
                        <Box minWidth={65} className='pointNameText'>
                          {t('IPointHrvRate')}
                        </Box>
                        <Box minWidth={35} className='pointRankText'>
                          {t('IPointRateName')}
                        </Box>
                      </Typography>
                      {myPackageInfo && (
                        <div>
                          {myPackageInfo.length > 1 && (
                            <Typography className={classes.pointCont}>
                              {myPackageInfo[1].packageName ?? '-'}
                            </Typography>
                          )}
                          {myPackageInfo?.length <= 1 && (
                            <Typography className={classes.pointCont}>
                              None
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.pointTitle}
                        style={{display: 'flex', flexDirection: 'row'}}
                      >
                        <Box minWidth={130} className='wavePointTitle'>
                          {t('IPointWaveAmount')}
                        </Box>
                      </Typography>
                      <Typography className={classes.wavePoint}>
                        {pointInfo?.hrvPackagePoint ?? '-'}
                      </Typography>
                    </div>
                  </div>
                  <Typography className={classes.smallPadding} />
                </Grid>
              </div>
            )}
            {orgInfo?.promotionYn === 'Y' && (
              <div className={classes.rankingBoxContainer}>
                <Grid container className={classes.rankingBoxWrap}>
                  <div className={classes.rankingBox}>
                    <div>
                      <span className={classes.outCircle}>
                        <span className={classes.circleText}>
                          <img src={brain} alt='promotion' />
                        </span>
                        <span className={classes.inCircle}> </span>
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 200,
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.pointTitle}
                        style={{display: 'flex', flexDirection: 'row'}}
                      >
                        <Box minWidth={65} className='pointNameText'>
                          {t('IPromotionRate')}
                        </Box>
                        <Box minWidth={35} className='pointRankText'>
                          {t('IPointRateName')}
                        </Box>
                      </Typography>
                      {myPackageInfo && (
                        <div>
                          {myPackageInfo?.length > 0 && (
                            <Typography className={classes.pointCont}>
                              {myPackageInfo[0].packageName ?? '-'}
                            </Typography>
                          )}
                          {myPackageInfo?.length === 0 && (
                            <Typography className={classes.pointCont}>
                              None
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.pointTitle}
                        style={{display: 'flex', flexDirection: 'row'}}
                      >
                        <Box minWidth={65} className='wavePointTitle'>
                          {t('IPointUseNumber')}
                        </Box>
                      </Typography>
                      {myPackageInfo && (
                        <Typography className={classes.wavePoint}>
                          <Box className='title'>
                            {myPackageInfo[0].totalCount ?? '-'}
                          </Box>
                          <Box className='remainNumber'>
                            ({t('IPointRemainingNumber')}
                            {myPackageInfo[0].usedCount ?? '-'})
                          </Box>
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Typography className={classes.smallPadding} />
                </Grid>
                <Typography className={classes.padding} />
              </div>
            )}
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              justifyItems: 'space-between',
            }}
          >
            <SubTitle>{t('IPointUseRange')} </SubTitle>
            {orgInfo?.promotionYn === 'N' && <PointPaymentButton />}
          </div>

          <Grid>
            <Grid container className={classes.rankingBoxWrap}>
              {pointRangeArray != null && (
                <div className={classes.hospital}>
                  {pointRangeArray.map((data: any) => (
                    <PointUsableRange packageName={String(data)} />
                  ))}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <PointRankDialog />
    </>
  )
}
