import {makeStyles} from '@material-ui/core/styles'
import logo from 'assets/img/isyncme-logo.png'
import styles from 'assets/tss/material-dashboard-pro-react/layouts/adminStyle'
import cx from 'classnames'
import Footer from 'components/Footer/Footer'
import Navbar from 'components/Navbars/NavBar'
import Sidebar from 'components/Sidebar/Sidebar'
import {buildMenu} from 'constants/SideMenuConstant'
import useAuth from 'features/auth/useAuth'
import useOrganization from 'features/org/useOrganization'
import {isCollapsibleMenu, isLeafMenu} from 'helpers/sideMenuHelper'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import * as storage from 'api/storageApi'

const useStyles = makeStyles(styles)

interface Props {
  children: ReactNode
}

const CommonLayout = (props: Props) => {
  const {children} = props
  const classes = useStyles()
  const {t} = useTranslation()
  const location = useLocation()
  const {user: currentUser} = useAuth()
  const {organization: currentOrg} = useOrganization()

  const color = 'blue'
  const bgColor = 'black'

  const [menus, setMenus] = useState<SideMenu[]>([])
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const miniActive = false

  const mainPanelClasses = `${classes.mainPanel} ${cx({
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]:
      navigator.platform.indexOf('Win') > -1,
  })}`

  const mainPanel = useRef<HTMLDivElement>(null)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const currentPath = window.location.pathname
  const collapsibles = menus.filter(isCollapsibleMenu)
  const leaves = menus.filter(isLeafMenu)

  const [activePath, setActivePath] = useState('')
  const [parentActivePath, setParentActivePath] = useState('')
  const hasPathWord = (path: string) => currentPath.indexOf(path)

  const findPathInCollapse = (menu: CollapsibleMenu[]) => {
    menu?.forEach((element) => {
      if (isCollapsibleMenu(element)) {
        element.children?.forEach((value) => {
          if (hasPathWord(value.path) === 0) {
            setActivePath(value.title)
            setParentActivePath(element.title)
          }
        })
      }
    })
  }
  const findPathInLeaf = (menu: LeafMenu[]) => {
    menu?.forEach((element) => {
      if (isLeafMenu(element)) {
        if (
          hasPathWord(element.path) === 0 &&
          currentPath.length !== 1 &&
          element.path.length !== 1
        ) {
          setActivePath(element.title)
          setParentActivePath(element.title)
        }
      }
    })
  }

  const [isEmpty, setIsEmpty] = React.useState(false)

  const isInclude = (value: string) => value.includes(currentPath)
  const [trueCount, setTrueCount] = React.useState(0)
  const [falseCount, setFalseCount] = React.useState(0)

  const checkEmptyPage = (menu: CollapsibleMenu[]) => {
    menu?.forEach((element) => {
      if (isCollapsibleMenu(element)) {
        const result = element.children?.some((item) => isInclude(item.path))
        if (result) setTrueCount(trueCount + 1)
        if (!result) setFalseCount(falseCount + 1)
      }
    })
  }

  useEffect(() => {
    const organizationType = storage.getOrganizationType()
    setMenus(
      buildMenu(
        currentUser?.authority,
        currentOrg?.edfYn,
        currentOrg?.menuList ?? [],
        currentUser?.email,
        organizationType,
      ),
    )
    setTrueCount(0)
    setFalseCount(0)
  }, [currentUser, currentOrg])

  useEffect(() => {
    if (currentPath.length === 1) {
      setActivePath('IDashboard')
      setParentActivePath('IDashboard')
    }
    if (currentPath.length !== 1) {
      findPathInCollapse(collapsibles)
      findPathInLeaf(leaves)
    }
    checkEmptyPage(collapsibles)
  }, [menus, location])

  useEffect(() => {
    // console.log('text', activePath)
  }, [activePath])

  // if (activePath.length === 0) {
  //   return <Redirect to='/' />
  // }
  useEffect(() => {
    if (trueCount !== falseCount && currentPath !== '') {
      setIsEmpty(true)
    }
  }, [falseCount])

  useEffect(() => {
    // if (isEmpty) navigate(getHomePath())
  }, [isEmpty])

  return (
    <div className={classes.wrapper}>
      <Sidebar
        // @ts-ignore
        // sidebar에 출력되는 메뉴 리스트
        routes={menus}
        logoText='iSyncBrain'
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
      />

      <div className={mainPanelClasses} ref={mainPanel}>
        <Navbar
          brandText={t(activePath)}
          handleDrawerToggle={handleDrawerToggle}
          menuText={t(parentActivePath)}
        />
        <div className={classes.content}>
          <div className={classes.container}>{children}</div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default CommonLayout
