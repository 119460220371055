import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  fetchNormativeEEGResult,
  FetchNormativeEEGResultRequest,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import ReportItem, {
  RenderingItem,
} from 'pages/Analysis/EEGIndividual/Report/ReportItem'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
} from 'constants/AnalysisConstant'
import useQuery from 'hooks/useQuery'
import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {
  ReportWrap,
  ReportTranslationSelectWrap,
  ReportFetcherWrap,
  ReportCustomPadding,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import useLanguageReport from 'hooks/useLanguageReport'

function ReportPage() {
  const {t, i18n} = useTranslation()

  // ID: path_parameter. ex) /report/:id -> :id 가 id에 매칭
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()

  // version: query_parameter. ex) /report/292?version=3.0.1
  function decodeHtmlEntity(str: string) {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = str
    const result = textarea.value
    textarea.remove()
    return result
  }

  const location = useLocation()
  const param = decodeHtmlEntity(`${location.search}${location.hash}`)
  const clientNameIndex = param.indexOf('clientName=') + 'clientName='.length
  const endIndex = param.indexOf('&version', clientNameIndex)
  const clientNameEncode =
    endIndex === -1
      ? param.substring(clientNameIndex)
      : param.substring(clientNameIndex, endIndex)

  const userNameFromURL = JSON.parse(decodeURIComponent(clientNameEncode))

  const clientName = t('IGetFullName', {
    firstName: userNameFromURL.firstName ?? '',
    lastName: userNameFromURL.lastName ?? '',
    interpolation: {escapeValue: false},
  })

  const version = useQuery().get('version') ?? '3.0'
  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Normative
  const locale = i18n.language
  const params: FetchNormativeEEGResultRequest = {
    normRequestIds: [Number.parseInt(id, 10)],
    codeTypeDivision,
    codeType,
    codeVersion: version ?? '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchNormativeEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [componentList, setComponentList] = useState<number[]>([])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {codeList, resultDetailList, resultInfo, componentList} = result
      const sortedCodeList = codeList.sort((code) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map((code) => {
        const items = resultDetailList.filter(
          (item) => item.analysisType === code.codeId,
        )
        return [code, items]
      })
      setRenderingItems(renderingItems)
      setResultInfo(resultInfo)
      setComponentList(componentList)
    }
  }, [asyncResult.result])
  useEffect(() => {
    asyncResult.execute(params)
  }, [locale])

  useLanguageReport()

  return (
    <ReportWrap style={{width: '100%'}}>
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <ReportCustomPadding />
        <CardWithTitle title={t('IEEGNormReportVersion', {version})}>
          {/* height - header - footer - CardHeader & border - margin */}
          <div
            style={{
              minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {asyncResult.loading && (
              <CircularProgress size='3rem' color='secondary' />
            )}
            {asyncResult.error && (
              <div>
                {asyncResult.error.message}
                <IconButton
                  onClick={() => asyncResult.execute(params)}
                  size='small'
                >
                  <ReplayIcon />
                </IconButton>
              </div>
            )}
            <div style={{width: '100%'}}>
              {resultInfo !== undefined && (
                <ReportInfo
                  resultType='NORM'
                  {...resultInfo}
                  clientName={clientName}
                  eeg
                />
              )}
              {renderingItems !== undefined &&
                renderingItems.map((item, index) => (
                  <ReportItem
                    key={index}
                    renderingItem={item}
                    requestId={Number.parseInt(id, 10)}
                    codeType={codeType}
                    codeTypeDivision={codeTypeDivision}
                    componentList={componentList}
                    screen='norm'
                  />
                ))}
            </div>
          </div>
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default ReportPage
