import {IcaRequestRes} from 'api/analysisApi'
import {statusToI18nString} from 'helpers/analysisHelper'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const StyledConfirmTable = styled.table`
  width: 100%;
  border: 1px solid #e6e7eb;
  border-collapse: collapse;

  & thead {
    background-color: #eff0f5;
    border: 1px solid #e6e7eb;
  }

  & th,
  & td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #e6e7eb;
  }

  & th:last-child {
    min-width: 200px;
  }
`

interface MultiIcaResultTableProps {
  items: IcaRequestRes[]
}

function MultiIcaResultTable({items}: MultiIcaResultTableProps) {
  const {t} = useTranslation()
  return (
    <StyledConfirmTable>
      <thead>
        <tr>
          <th>{t('IAnalysisNumber')}</th>
          <th>{t('IReAnalysis_code')}</th>
          <th>{t('IProgress')}</th>
          <th>{t('IDescription')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.reAnalysisId}>
            <td>{item.requestId}</td>
            <td>{item.reAnalysisId}</td>
            <td>{t(statusToI18nString(item.resultCode as AnalysisStatus))}</td>
            <td>{item.resultMsg}</td>
          </tr>
        ))}
      </tbody>
    </StyledConfirmTable>
  )
}

export default MultiIcaResultTable
