import React, {useEffect} from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import ReceiptDialog from 'components/Dialog/ReceiptDialog'
import PeriodSearch from 'components/molcules/PeriodSearch'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import {useTranslation} from 'react-i18next'
import Pagination from 'components/molcules/Pagination'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import {
  PackageTable,
  PackageTableHeader,
  TableOverflowWrap,
} from 'components/Table/CommonTableStyle'
import PaymentHistoryTableModel from 'pages/OrgPage/PaymentManagementPages/Models/PaymentHistoryTableModel'
import usePaymentHistoryTableViewModel from './PaymentHistoryTableViewModel'

interface PaymentHistoryTableHeadProps {
  HeaderCells: HeadCell[]
}
const PaymentHistoryTableHead = (props: PaymentHistoryTableHeadProps) => {
  const {t} = useTranslation()
  const {HeaderCells} = props

  return (
    <PackageTableHeader>
      <tr>
        {HeaderCells.map((item, index) => (
          <RoundTableHeader
            label={t(item.label)}
            length={HeaderCells.length}
            index={index}
            key={index}
          />
        ))}
      </tr>
    </PackageTableHeader>
  )
}

interface PaymentHistoryTableRowsProps {
  paymentHistoryList: PaymentHistoryTableModel
}
const PaymentHistoryTableRows = (props: PaymentHistoryTableRowsProps) => {
  const {paymentHistoryList} = props
  const {t} = useTranslation()

  return (
    <>
      {paymentHistoryList.items.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>{item.getOrderNo()}</td>
            <td>{isoStringToDateAndTimeString(item.createdAt)}</td>
            <td>{t(item.getOrderType())}</td>
            <td>{item.orderName}</td>
            <td>{item.cardName}</td>
            <td>{item.getPurchaseAmountWithCurrencyCode()}</td>
          </tr>
        )
      })}
    </>
  )
}

const PaymentHistoryTable = () => {
  const {t} = useTranslation()

  const HeaderCells: HeadCell[] = [
    {id: '1', label: 'IOrderNo'},
    {id: '2', label: 'IPaidDateAndTime'},
    {id: '3', label: 'IPaidType'},
    {id: '5', label: 'IProductName'},
    {id: '5', label: 'ICardNameShort'},
    {id: '6', label: 'IPurchaseAmount'},
  ]

  const HeaderForExcel: string[][] = [HeaderCells.map((item) => t(item.label))]

  const {
    isPaymentHistoryListLoading: isListLoading,
    paymentHistoryList,
    selection,
    getPaymentHistory,
    handlePeriod,
    pagingState,
    setPageIndex,
    handleExcelExport,
    handlePageSizeChanged,
  } = usePaymentHistoryTableViewModel()

  useEffect(() => {
    getPaymentHistory()
  }, [])

  const hasData =
    !isListLoading &&
    paymentHistoryList !== undefined &&
    paymentHistoryList.items !== undefined &&
    paymentHistoryList.items.length !== 0

  return (
    <>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          rowGap: 10,
        }}
      >
        <PeriodSearch
          period={selection}
          onSearch={handlePeriod}
          hasPlaceholder
        />
        <ActionButton
          variant='contained'
          color='secondary'
          startIcon={<GetAppIcon />}
          disableElevation
          onClick={() => handleExcelExport(HeaderForExcel)}
        >
          {t('IExcelDownload')}
        </ActionButton>
      </div>
      <TableOverflowWrap>
        <PackageTable>
          <colgroup>
            <col style={{minWidth: 100}} />
            <col style={{minWidth: 160}} />
            <col style={{minWidth: 90}} />
            <col style={{minWidth: 180}} />
            <col style={{minWidth: 90}} />
            <col style={{minWidth: 130}} />
          </colgroup>
          <PaymentHistoryTableHead HeaderCells={HeaderCells} />
          <tbody>
            {hasData && (
              <PaymentHistoryTableRows
                paymentHistoryList={paymentHistoryList}
              />
            )}
          </tbody>
        </PackageTable>
        {!hasData && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            {t('INoData')}
          </div>
        )}
      </TableOverflowWrap>
      {!isListLoading && (
        <Pagination
          totalPageCount={Math.ceil(
            paymentHistoryList.total / pagingState.size,
          )}
          currentPageIndex={pagingState.page - 1}
          itemCountPerPage={pagingState.size}
          setCurrentPageIndex={setPageIndex}
          loading={isListLoading}
          onItemCountPerPageChanged={handlePageSizeChanged}
        />
      )}
      <ReceiptDialog paymentId={1} />
    </>
  )
}
export default PaymentHistoryTable
