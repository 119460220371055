import React, {useEffect, useState} from 'react'
import {Radio} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

import useInvoice from 'features/invoice/useInvoice'
import {
  BaseFeesType,
  PlanType,
  ProductTemplateType,
} from 'features/invoice/invoiceSlice'
import Element from 'pages/CustomerManagement/InvoiceSetting/Form/Element'

import InvoiceSettingStyle from 'pages/CustomerManagement/InvoiceSetting/Style'
import {MONTHLY_FLAT_FEE} from 'pages/CustomerManagement/InvoiceSetting/constant'

const useStyles = makeStyles(InvoiceSettingStyle)

function MonthlyFee({press, orgId}: {press: number; orgId: number}) {
  const classes = useStyles()
  const {
    packageType,
    baseFees,
    templates,
    changeProductTemplateAction,
    changeBaseFeesAction,
    changeInvoiceAction,
    onHandleInvoice,
  } = useInvoice()

  const [dataTem, setDataTem] = useState<ProductTemplateType[]>([])
  const [dataFees, setDataFees] = useState<BaseFeesType>([])

  useEffect(() => {
    setDataTem(templates || [])
  }, [templates])

  useEffect(() => {
    setDataFees(baseFees || [])
  }, [baseFees])

  useEffect(() => {
    if (
      press &&
      (packageType === MONTHLY_FLAT_FEE.contentLevelOne[0].data.key ||
        packageType === MONTHLY_FLAT_FEE.contentLevelOne[1].data.key)
    ) {
      changeProductTemplateAction(dataTem)
      changeBaseFeesAction(dataFees)
      onHandleInvoice(orgId, dataFees, dataTem)
    }
  }, [press])

  return (
    <div className={classes.standardPlan} style={{marginTop: 5}}>
      <label htmlFor='standardPlan' className={classes.standardPlanTitle}>
        {MONTHLY_FLAT_FEE.label}
      </label>
      <div>
        {MONTHLY_FLAT_FEE.contentLevelOne.map((elm, index) => (
          <div className={classes.generalOnly} key={`${index}_monthly-fee`}>
            <div className={classes.generalOnlyContent}>
              <label
                htmlFor='standardPlan'
                className={classes.generalOnlyContentTitle}
              >
                <Radio
                  style={{padding: 0}}
                  id={elm.data.key}
                  name={elm.data.key}
                  checked={packageType === elm.data.key}
                  onChange={() => {
                    changeInvoiceAction(elm.data.key as PlanType)
                    setDataTem([])
                    setDataFees([])
                  }}
                />
                {elm.data.label}
              </label>
              <Element
                data={elm.data.contentLevelTwo}
                parentId={elm.data.key as PlanType}
                packageType={packageType}
                baseFees={dataFees}
                templates={elm.data.key === packageType ? dataTem : []}
                changeProductTemplateAction={setDataTem}
                changeBaseFeesAction={setDataFees}
              />
            </div>
            <p className={classes.generalOnlyTitle}>{elm.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MonthlyFee
