import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

interface DisplayCardContentProps {
  title: string
  content: string
}

interface DisplayProps {
  contents: DisplayCardContentProps[]
}

const DisplayWrapper = styled.div`
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  margin: 0 auto;
  margin: 20px;

  & > div:first-child {
    margin-top: 30px;
  }
`

const DivideLine = styled.hr`
  border: 0;
  border-top: 1px solid #f1f1f1;
  margin: 0 3rem;
`

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`

const ContentsTitle = styled.p`
  font-size: 14px;
  width: 150px;
  font-weight: 500;
  position: relative;
  left: 4%;
`

const ContentsValue = styled.p`
  font-size: 14px;
  position: relative;
  width: 790px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  left: 5%;
`

const TextCenter = styled.div`
  max-width: 800px;
  margin: 30px 0;
  position: relative;
  left: 4%;
`

function DisplayCard(props: DisplayProps) {
  const {t} = useTranslation()
  const {contents} = props
  const itemLength = contents.length - 1
  return (
    <DisplayWrapper>
      {contents.map((item, index) => (
        <div>
          <ContentsWrapper>
            <ContentsTitle> • {item.title}</ContentsTitle>
            <ContentsValue>{item.content}</ContentsValue>
          </ContentsWrapper>
          {index !== itemLength && <DivideLine />}
        </div>
      ))}
      <TextCenter> - {t('IMedicalDevice')} </TextCenter>
    </DisplayWrapper>
  )
}

export default DisplayCard
