export type Path = RegExp | string

export interface Match<T> {
  matches: boolean
  params: T | null
}

export interface BaseMatch extends Match<Record<string, string>> {}

/**
 * Converts a string path to a Regular Expression.
 * Transforms path parameters into named RegExp groups.
 */
export const pathToRegExp = (path: string): RegExp => {
  const pattern = path
    // Escape literal dots
    .replace(/\./g, '\\.')
    // Escape literal slashes
    .replace(/\//g, '/')
    // Escape literal question marks
    .replace(/\?/g, '\\?')
    // Ignore trailing slashes
    .replace(/\/+$/, '')
    // Replace wildcard with any zero-to-any character sequence
    .replace(/\*+/g, '.*')
    // Replace parameters with named capturing groups
    .replace(
      /* eslint-disable no-useless-escape */
      /:([^\d|^\/][a-zA-Z0-9_]*(?=(?:\/|\\.)|$))/g,
      (_, paramName) => `(?<${paramName}>[^\/]+?)`,
    )
    // Allow optional trailing slash
    .concat('(\\/|$)')

  return new RegExp(pattern, 'gi')
}

/**
 * Matches a given url against a path.
 */
export const match = (path: Path, url: string): BaseMatch => {
  const expression = path instanceof RegExp ? path : pathToRegExp(path)
  const match = expression.exec(url) || false

  // Matches in strict mode: match string should equal to input (url)
  // Otherwise loose matches will be considered truthy:
  // match('/messages/:id', '/messages/123/users') // true
  const matches =
    path instanceof RegExp ? !!match : !!match && match[0] === match.input

  return {
    matches,
    params: match && matches ? match.groups || null : null,
  }
}

/**
 * Node match path code snippet
 *
 * see also: https://github.com/mswjs/node-match-path
 */
export default {
  pathToRegExp,
  match,
}
