import React from 'react'
import styled from 'styled-components'
import LoadingImg from 'assets/img/ajax-loader.gif'

const StyledLoading = styled.div`
  user-select: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;

  & img {
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    vertical-align: middle;
    border: 0;
  }
`

const Loading = () => (
  <StyledLoading>
    <img src={LoadingImg} alt='loading img' />
  </StyledLoading>
)

export default Loading
