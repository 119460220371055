import {
  checkEmailDuplicateAction,
  loginAction,
  LoginPayload,
  logoutAction,
  selectData,
  selectError,
  selectLoading,
  selectSilentLoading,
  silentLoginAction,
  VerifyPayload,
  verifyTwoFactorAction,
} from 'features/auth/authSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useAuth() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const user = useAppSelector(selectData)
  const error = useAppSelector(selectError)

  const onLogin = (payload: LoginPayload) =>
    dispatch(loginAction(payload)).unwrap()

  const onLogout = () => dispatch(logoutAction()).unwrap()

  const onCheckEmailDuplicate = (email: string) =>
    dispatch(checkEmailDuplicateAction(email))

  const onVerifyCode = (payload: VerifyPayload) =>
    dispatch(verifyTwoFactorAction(payload)).unwrap()

  return {
    loading,
    user,
    error,
    onLogin,
    onLogout,
    onCheckEmailDuplicate,
    onVerifyCode,
  }
}

export function useSilentLogin() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectSilentLoading)
  const user = useAppSelector(selectData)
  const error = useAppSelector(selectError)

  const onSilentLogin = () => dispatch(silentLoginAction())

  return {
    loading,
    user,
    error,
    onSilentLogin,
  }
}
