import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  fetchDomesticListApi,
  fetchSingleProductsApi,
  SingleProduct,
} from 'api/paymentApi'
import {RootState} from 'store'

interface DomesticPaymentState {
  loading: boolean
  error: any
  domesticProducts: SingleProduct[] | null
  // domesticProducts: PaymentContent[] | null
  singleProducts: SingleProduct[] | null
}

export const initialState: DomesticPaymentState = {
  loading: false,
  error: null,
  domesticProducts: [],
  singleProducts: [],
}

export const fetchDomesticListAction = createAsyncThunk(
  'paymentProductsSlice/fetchDomesticList',
  async () => {
    const response = await fetchDomesticListApi()
    return response
  },
)

export const fetchSingleProductsAction = createAsyncThunk(
  'paymentProductsSlice/SingleProducts',
  async () => {
    const response = await fetchSingleProductsApi()
    return response
  },
)

export const paymentProductsSlice = createSlice({
  name: 'paymentProductsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDomesticListAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchDomesticListAction.fulfilled, (state, action) => {
      state.loading = false
      state.domesticProducts = action.payload
    })
    builder.addCase(fetchDomesticListAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchSingleProductsAction.pending, (state) => {
      state.loading = true
      state.error = undefined
    })
    builder.addCase(fetchSingleProductsAction.fulfilled, (state, action) => {
      state.loading = false
      state.singleProducts = action.payload
    })
    builder.addCase(fetchSingleProductsAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectDomesticProductList = (state: RootState) =>
  state.paymentProducts.domesticProducts
export const selectSingleProductsList = (state: RootState) =>
  state.paymentProducts.singleProducts

export default paymentProductsSlice.reducer
