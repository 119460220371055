import React, {useEffect} from 'react'
import Modal from 'components/V2Modal/Modal/Modal'
import {ModalAllStyleProps} from 'components/V2Modal/Modal/Styles'

type HeaderType = 'ONE_ROW' | 'MULTI_ROW'
interface CommonDialogProps {
  open: boolean
  modalTitle?: string
  handleClose: () => any
  theme: ModalAllStyleProps
  body: JSX.Element
  footer?: JSX.Element
  style?: React.CSSProperties
  modalZindex?: number
  headerType?: HeaderType
}

/**
 * - 일반 다이얼로그는 z-index가 1300
 * - alert 다이얼로그는 z-index가 1400
 * - confirm 다이얼로그는 z-index를 1350
 * - 다른 다이얼로그보다 위에 표현되도록 하고 alert 다이얼로그보다 아래에 표현되도록 한다.
 */
function CommonDialog({
  open,
  modalTitle,
  handleClose,
  theme,
  body,
  footer,
  style,
  modalZindex,
  headerType,
}: CommonDialogProps) {
  return (
    <>
      {open && (
        <Modal
          style={style}
          open={open}
          modalTitle={modalTitle}
          handleClose={handleClose}
          body={body}
          footer={footer}
          theme={theme}
          modalZIndex={modalZindex}
          headerType={headerType}
        />
      )}
    </>
  )
}

export default CommonDialog
