import styled, {css} from 'styled-components'
import React, {useEffect} from 'react'
import {handleClickOutside} from 'helpers/uiHelper'
import {CommonSvgIcon, SvgIcon} from 'components/common/useSvgIcons'
import Colors from '../../../theme/Colors'

interface SelectContainerStyleProps extends SelectStyleProps {
  isOpened: boolean
  isInvalid?: boolean
}

export interface SelectStyleProps {
  openDirection: 'top' | 'bottom'
  paddingLeft: number
  paddingRight: number
  boxHeight: number
  textColor: string
  textSize: number
  textWeight: number
  bgColor: string
  bgHoverColor: string
  borderColor: string
  borderRadius: number
  textHoverColor?: string
  fixWidth?: number // StyledSelectContainer width
  fixWidthPercent?: string // StyledSelectContainer width percent (ex: 100% 줘야할 때)
  fixMaxWidth?: number // StyledSelectContainer max-width - when select box have to be max width fix
  fixMinWidth?: number // StledSelectContainer max-width - when select box have to be min width fix
  fixMaxHeight?: number
  arrowIconColor?: string
  arrowIconWidth?: number
  arrowIconHeight?: number
  iconColor?: string
  iconWidth?: number
  iconHeight?: number
}

export interface SpreadableWithIcon {
  spread(): SelectItemsWithIcon[]
}

export interface SpreadItem {
  appearanceValue(): string
}

interface SelectProps {
  thumb: SelectItemsWithIcon
  spreadableWithIcon?: SpreadableWithIcon // 데이터를 spread() 형태로 넣어주면 spreadableWithIcon로 전달
  optionsWithIcon?: SelectItemsWithIcon[] // 데이터가 spread() 형태가 아니고 단순히 string[]이라면 optionsWithIcon로 전달
  handler: (lists: SelectItemsWithIcon[], index: number) => void
  theme: SelectStyleProps
  isInvalid?: boolean
  isOpenControlParent?: boolean
  setIsOpenControlParent?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IconSelectComponentProps {
  thumb: SelectItemsWithIcon
  theme: SelectStyleProps
  selectItems: SelectItemsWithIcon[]
  isOpen: boolean
  isInvalid?: boolean
  selectRef: React.RefObject<HTMLDivElement>
  toggling: () => void
  handleSelectItem: (lists: SelectItemsWithIcon[], index: number) => void
}

export interface SelectItemsWithIcon {
  iconName: string
  iconType?: string
  optionText: string
}

const StyledArrowIcon = styled.div`
  height: 100%;
`

const StyledDirectionTopMixin = css<SelectContainerStyleProps>`
  display: flex;
  flex-direction: column-reverse;
  border-bottom: 0;
  bottom: ${(props) => `calc(100% + ${props.boxHeight}px)`};
  border-radius: 4px 4px 0 0;

  opacity: ${({isOpened}) => (isOpened ? 1 : 0)};
  visibility: ${({isOpened}) => (isOpened ? 'visible' : 'hidden')};
  transform: ${({isOpened}) =>
    isOpened ? 'translate3d(0, 0, 0)' : 'translate3d(0, 15px, 0)'};
  transition: opacity 200ms ease-out, visibility 200ms ease-out,
    transform 200ms ease-out;
`
const StyledDirectionBottomMixin = css<SelectContainerStyleProps>`
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  border-top: 0px;
  top: 100%;

  opacity: ${({isOpened}) => (isOpened ? 1 : 0)};
  visibility: ${({isOpened}) => (isOpened ? 'visible' : 'hidden')};
  transform: ${({isOpened}) =>
    isOpened ? 'translate3d(0, 0, 0)' : 'translate3d(0, -15px, 0)'};
  transition: opacity 200ms ease-out, visibility 200ms ease-out,
    transform 200ms ease-out;
`
const StyledSelectHeaderTextDirectionTopMixin = css<SelectContainerStyleProps>`
  border-radius: ${(props) => {
    let borderRadius = ''
    if (props.isOpened && props.borderRadius) {
      borderRadius = `0 0 ${props.borderRadius}px ${props.borderRadius}px`
    } else if (props.borderRadius) {
      borderRadius = `${props.borderRadius}px`
    } else if (props.isOpened) {
      borderRadius = '0 0 2px 2px'
    } else {
      borderRadius = '2px'
    }
    return borderRadius
  }};
`
const StyledSelectHeaderTextDirectionBottomMixin = css<SelectContainerStyleProps>`
  border-radius: ${(props) => {
    let borderRadius = ''
    if (props.isOpened && props.borderRadius) {
      borderRadius = `${props.borderRadius}px ${props.borderRadius}px 0 0`
    } else if (props.borderRadius) {
      borderRadius = `${props.borderRadius}px`
    } else if (props.isOpened) {
      borderRadius = '2px 2px 0 0'
    } else {
      borderRadius = '2px'
    }
    return borderRadius
  }};
`

const StyledSelectHeader = styled.div``

const StyledSelectHeaderText = styled.div``

const StyledTextAutoFullWidth = styled.div``

const StyledSelectListContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledSelectList = styled.ul`
  margin: 0;
  padding: 0;
`
const StyledSelectListItem = styled.li``

const StyledSelectContainer = styled.div<SelectContainerStyleProps>`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) =>
    props.fixMaxWidth ? `${props.fixMaxWidth}px` : null};
  min-width: ${(props) =>
    props.fixMinWidth ? `${props.fixMinWidth}px` : null};
  width: ${(props) => {
    let width = ''
    if (props.fixWidth) width = `${props.fixWidth}px`
    else if (props.fixWidthPercent) width = `${props.fixWidthPercent}`
    return width
  }};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.textSize}px;
  font-weight: ${(props) => props.textWeight};
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  ${StyledSelectHeader} {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: ${(props) => props.bgColor};
    border: ${(props) =>
      props.isInvalid
        ? `1px solid ${Colors.state.error}`
        : `1px solid ${props.borderColor}`};
    border-radius: ${(props) => props.borderRadius}px;
    padding-right: ${(props) =>
      props.arrowIconWidth
        ? `calc(${props.arrowIconWidth}px + (${props.paddingRight}px  * 2))`
        : `calc(10px + (${props.paddingRight}px * 2))`};
    padding-left: ${(props) => props.paddingLeft}px;
    width: 100%;
    height: ${(props) => props.boxHeight}px;

    ${(props) =>
      props.openDirection === 'top'
        ? StyledSelectHeaderTextDirectionTopMixin
        : StyledSelectHeaderTextDirectionBottomMixin};
  }

  ${StyledSelectHeaderText} {
    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      line-height: ${(props) => props.boxHeight - 2}px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    ${StyledTextAutoFullWidth} {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }

    svg {
      width: ${(props) => props.iconWidth}px;
      height: ${(props) => props.iconHeight}px;
    }
  }

  ${StyledArrowIcon} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: ${(props) => props.paddingRight}px;

    svg {
      width: ${(props) => props.arrowIconWidth ?? 10}px;
      height: ${(props) => props.arrowIconHeight ?? 10}px;

      * {
        fill: ${(props) => props.isInvalid && Colors.state.error};
        stroke: ${(props) => props.isInvalid && Colors.state.error};
      }
    }
  }

  ${StyledSelectList} {
    display: flex;
    width: 100%;
    max-height: ${(props) => props.fixMaxHeight ?? 400}px;
    background-color: ${(props) => props.bgColor};
    border: ${(props) =>
      props.isInvalid ? `1px solid ${Colors.state.error}` : null};
    position: absolute;
    user-select: none;
    list-style: none;
    overflow: hidden;
    overflow-y: auto;
    z-index: 999;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 8px;
    }

    ${(props) =>
      props.openDirection === 'top'
        ? StyledDirectionTopMixin
        : StyledDirectionBottomMixin}
    & li:first-child {
      border-bottom: none;
    }
    & li:nth-child(2) {
      border-top: none;
    }
  }

  ${StyledSelectListItem} {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: ${(props) =>
      props.arrowIconWidth
        ? `calc(${props.arrowIconWidth}px + (${props.paddingRight}px  * 2))`
        : `calc(10px + (${props.paddingRight}px * 2))`};
    padding-left: ${(props) => props.paddingLeft}px;
    height: ${(props) => props.boxHeight}px;
    line-height: ${(props) => props.boxHeight - 1}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: background-color 300ms ease-in-out;
    /* border: 1px solid #e7eaec; */

    &:hover {
      color: ${(props) => props.textHoverColor ?? null};
      background-color: ${(props) => props.bgHoverColor};
    }
  }
`

export function IconSelectComponent(props: IconSelectComponentProps) {
  const {
    thumb,
    theme,
    selectItems,
    isOpen,
    isInvalid,
    selectRef,
    toggling,
    handleSelectItem,
  } = props

  return (
    <StyledSelectContainer
      isOpened={isOpen}
      isInvalid={isInvalid}
      openDirection={theme.openDirection}
      paddingLeft={theme.paddingLeft}
      paddingRight={theme.paddingRight}
      boxHeight={theme.boxHeight}
      textSize={theme.textSize}
      textColor={theme.textColor}
      textWeight={theme.textWeight}
      bgColor={theme.bgColor}
      bgHoverColor={theme.bgHoverColor}
      borderColor={theme.borderColor}
      borderRadius={theme.borderRadius}
      textHoverColor={theme.textHoverColor}
      fixWidth={theme.fixWidth}
      fixWidthPercent={theme.fixWidthPercent}
      fixMaxWidth={theme.fixMaxWidth}
      fixMinWidth={theme.fixMinWidth}
      fixMaxHeight={theme.fixMaxHeight}
      arrowIconColor={theme.arrowIconColor}
      arrowIconWidth={theme.arrowIconWidth}
      arrowIconHeight={theme.arrowIconHeight}
      iconWidth={theme.iconWidth}
      iconHeight={theme.iconHeight}
      ref={selectRef}
    >
      <StyledSelectHeader onClick={toggling}>
        {/* 선택된 옵션 보여줌 */}
        <StyledSelectHeaderText>
          <div>
            {thumb.iconType === 'img' ? (
              <img src={thumb.iconName} alt={`icon_${thumb.optionText}`} />
            ) : (
              <SvgIcon
                name={thumb.iconName}
                iconColor={theme.iconColor ?? ''}
              />
            )}
            <span>{thumb.optionText}</span>
          </div>
          <StyledTextAutoFullWidth>
            {selectItems.map((item, idx) => (
              <div key={idx}>
                {item.iconType === 'img' ? (
                  <img src={item.iconName} alt={`icon_${item.optionText}`} />
                ) : (
                  <SvgIcon
                    name={item.iconName}
                    iconColor={theme.iconColor ?? ''}
                  />
                )}
                <span>{item.optionText}</span>
              </div>
            ))}
          </StyledTextAutoFullWidth>
        </StyledSelectHeaderText>
        <StyledArrowIcon>
          <CommonSvgIcon
            name='allowBottom'
            iconColor={theme.arrowIconColor ?? Colors.icon.fill.select_arrow}
          />
        </StyledArrowIcon>
      </StyledSelectHeader>
      <StyledSelectListContainer>
        <StyledSelectList>
          {selectItems.map((item, idx) => (
            <StyledSelectListItem
              key={idx}
              value={item.optionText}
              onClick={() => {
                handleSelectItem(selectItems, idx)
              }}
            >
              {item.iconType === 'img' ? (
                <img src={item.iconName} alt={`icon_${item.optionText}`} />
              ) : (
                <SvgIcon
                  name={item.iconName}
                  iconColor={theme.iconColor ?? ''}
                />
              )}
              <span>{item.optionText}</span>
            </StyledSelectListItem>
          ))}
        </StyledSelectList>
      </StyledSelectListContainer>
    </StyledSelectContainer>
  )
}

/**
 *
 * @param
 * - thumb: select box가 닫혀있을 때 노출되는 문자열입니다. (items[0] or 선택해주세요.)
 * - spreadableWithIcon: spread() => [string] or string[] 형태의 셀렉트 아이템입니다. (기관관리 - 결제관리 등에서 사용 됨)
 * - optionsWithIcon: string[] 형태의 셀렉트 아이템입니다.
 * - handler: 부모가 전달하는 이벤트를 실행합니다. (thumb change 등)
 * - theme: 적용시킬 셀렉트 스타일입니다. (Styles.ts 파일에서 필요한 테마 만들기)
 * - isInvalid?: required 등의 체크 필요할 때 테두리 색상을 error color로 변경
 *
 * 부모에서 select open 제어하고 싶을 때
 * - isOpenControlParent?: boolean state that select open with label click
 * - setIsOpenControlParent?: boolean setState that select open with label click
 */
function IconSelect(props: SelectProps) {
  const {
    thumb,
    spreadableWithIcon,
    optionsWithIcon,
    handler,
    theme,
    isInvalid,
    isOpenControlParent,
    setIsOpenControlParent,
  } = props

  const [selectItems, setSelectItems] = React.useState<SelectItemsWithIcon[]>(
    [],
  )

  // open control
  const isControlParent =
    isOpenControlParent !== undefined && setIsOpenControlParent !== undefined
  const [selectIsOpen, setSelectIsOpen] = React.useState<boolean>(false)

  // isOpen은 isControlParent가 true면 프롭으로, 아니면 selectIsOpen으로 제어
  const [isOpen, setIsOpen] = isControlParent
    ? [isOpenControlParent, setIsOpenControlParent]
    : [selectIsOpen, setSelectIsOpen]

  const selectRef = React.useRef<HTMLDivElement>(null)

  const toggling = () => {
    setIsOpen((prev) => !prev)
  }

  const handleSelectItem = (lists: SelectItemsWithIcon[], index: number) => {
    handler(lists, index)
    setIsOpen(false)
  }

  // 중첩 방지 useCallback
  const handleClickOutsideCallback = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      handleClickOutside(e, selectRef, setIsOpen)
    },
    [selectRef, isOpen],
  )

  useEffect(() => {
    if (spreadableWithIcon && !optionsWithIcon) {
      setSelectItems(spreadableWithIcon.spread())
    } else if (optionsWithIcon) {
      setSelectItems(optionsWithIcon)
    }
  }, [spreadableWithIcon, optionsWithIcon])

  // 셀렉트 오픈 후 외부 영역 선택 확인
  useEffect(() => {
    // 셀렉트 박스가 열려 있을 때만 실행, 부모 컴포넌트에서 제어하면 실행 X 해당 컴포넌트에서 실행해야 함
    if (isOpen && !isOpenControlParent) {
      window.addEventListener('mousedown', handleClickOutsideCallback)
    }

    // 중첩 실행 방지
    return () =>
      window.removeEventListener('mousedown', handleClickOutsideCallback)
  }, [isOpen])

  return (
    <IconSelectComponent
      thumb={thumb}
      theme={theme}
      selectItems={selectItems}
      isOpen={isOpen}
      isInvalid={isInvalid}
      selectRef={selectRef}
      toggling={toggling}
      handleSelectItem={handleSelectItem}
    />
  )
}

export default IconSelect
