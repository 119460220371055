import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {PackageInfo, updatePointPackageApi} from 'api/pointApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Card from 'components/atoms/Card'
import {fetchMyPackageAction} from 'features/auth/authSlice'
import useAuth from 'features/auth/useAuth'
import {
  closePointRankDialog,
  selectPointRankDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrganization from 'features/org/useOrganization'
import {
  fetchMyPackageRequest,
  fetchPointInfoAction,
  fetchPointPackageInfo,
} from 'features/point/pointSlice'
import {nowYearDate} from 'helpers/dateHelper'
import {useAppDispatch, useAppSelector} from 'hooks'
import PointUsableRangeSmall from 'pages/SalesPages/SalesPaymentPage/SalesPointRating/PointUsableRangeSmall'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // @ts-ignore
      zIndex: '1350 !important',
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 1050,
      },
    },
    contentContainer: {
      justifyContent: 'center',
      overflow: 'auto',
      // background: '#eef2f6',
    },
    confirmContainer: {
      background: '#eef2f6',
      display: 'flex',
      alignItems: 'center',
      margin: '40px 20px',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'column',
      flex: 1,
      width: '300px',
      '& h5': {
        fontWeight: 'bolder',
      },
      '& h6': {
        fontSize: '1.0rem',
        fontWeight: 'normal',
        textAlign: 'left',
        margin: '5px 0',
        flexWrap: 'wrap',
        flex: 1,
        color: '#717376',
      },
      '& TextField': {
        flex: 1,
      },
    },
    alertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#f4f4f4',
      '& button': {
        width: 221,
        height: 55,
      },
    },
    padding: {
      padding: theme.spacing(1),
    },

    okButtonColor: {
      backgroundColor: 'white',
    },
    longTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'space-between',
      color: 'primary',
      background: 'white',
      '& button': {
        margin: 10,
      },
    },
    hidden: {
      visibility: 'hidden',
    },
    iconTitleWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 15,
    },
    iconColor: {
      color: '#636364',
      fontSize: 20,
      marginRight: 8,
    },
    scroll: {
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      backgroundColor: '#fff',
      // margin: 30,
      // padding: 20,
    },
    subTitle: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 50,
      maxWidth: 200,
      flexBasis: 200,
      flexWrap: 'wrap',
      marginTop: 5,
      color: '#757575',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    textField: {
      flexGrow: 1,
      maxWidth: 200,
    },
    subWrap: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      flexWrap: 'wrap',
      // marginTop: 15,
      overflow: 'auto',
    },
    titleTextWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid white',
      gap: 5,
      '& h6': {
        fontSize: '1rem',
        textAlign: 'left',
        padding: 10,
        paddingLeft: 15,
      },
    },
    subBackgroundcolor: {
      color: '#555555',
      background: '#fafafa',
      paddingRight: 5,
      '& h6': {
        fontSize: '0.95rem',
      },
    },
    containText: {
      color: '#8b8b8b',
      borderBottom: '1px solid #fafafa',
      backgroundColor: 'white',
      fontSize: '0.9rem',
      paddingLeft: 20,
      padding: 12,
    },
    promotionInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: 30,
      '& .promotionInfoWrap': {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 20px',
        backgroundColor: '#f9f9fb',
      },
      '& .promotionInfoTitle': {
        width: 250,
      },
      '& .promotionInfoContent': {
        fontWeight: 600,
      },
    },
  }),
)

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      minWidth: 750,
      borderRadius: 5,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },

    tableHeader: {
      height: 65,
      backgroundColor: '#e8f9f9',
    },
    tableHeaderCell: {
      color: '#5b7171',
      fontWeight: 600,
      minWidth: 160,
      padding: 0,
    },
    tableContent: {
      fontWeight: 600,
      color: '#4b4b4b',
      fontSize: 15,
      borderBottom: '1px solid #f1f1f1',
    },
    hover: {
      '&:hover': {
        backgroundColor: '#e8f9f9 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
    hidden: {
      visibility: 'hidden',
    },
    hospital: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 6,
    },
    hospitalName: {
      fontWeight: 'bolder',
      color: '#555555',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
)

interface EegProp {
  onSelectedEeg: (value?: string) => void
  allPackages: Package[]
}

function getArray(array: string): string[] {
  const stringArray = array.split(',')
  return stringArray
}

function RankEegTable({onSelectedEeg, allPackages}: EegProp) {
  const {t} = useTranslation()
  const classes = useTableStyles()
  const {organization} = useOrganization()
  const currentPaymentType = organization?.promotionYn === 'N' ? 'N' : 'Y'
  const [eegValue, setEegValue] = React.useState<string>()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setEegValue(event.target.value)
    onSelectedEeg(value)
  }

  return (
    <Table
      className={classes.table}
      aria-labelledby='tableTitle'
      aria-label='enhanced table'
    >
      <TableHead>
        <TableRow className={classes.tableHeader}>
          <TableCell align='center'>
            <Typography align='center' className={classes.hidden}>
              {' '}
            </Typography>
          </TableCell>
          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
          >
            {t('IPointRateName')}
          </TableCell>
          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
          >
            {t('IPointWaveAmount')}
          </TableCell>
          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
            style={{width: 610}}
          >
            {t('IIncludedProduct')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allPackages != null &&
          allPackages.map((item) => {
            return (
              <>
                {item.promotionYn === currentPaymentType && (
                  <TableRow hover role='checkbox' className={classes.hover}>
                    <TableCell
                      align='center'
                      style={{borderBottom: '1px solid #f1f1f1'}}
                    >
                      <Radio
                        checked={eegValue === item.packageName}
                        onChange={(e) =>
                          handleChange(e, item.packageType ?? '')
                        }
                        value={item.packageName}
                        name='radio-button-demo'
                        inputProps={{'aria-label': 'A'}}
                      />
                    </TableCell>
                    <TableCell align='center' className={classes.tableContent}>
                      {item.packageName ?? '-'}
                    </TableCell>
                    <TableCell align='center' className={classes.tableContent}>
                      {item.point ?? '-'}
                    </TableCell>
                    <TableCell align='center' className={classes.tableContent}>
                      {item.detailTypeList != null && (
                        <div className={classes.hospital}>
                          {getArray(item.detailTypeList).map((data: any) => (
                            <PointUsableRangeSmall packageName={String(data)} />
                          ))}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}
      </TableBody>
    </Table>
  )
}

interface HrvProp {
  onSelectedHrv: (value: string) => void
  allPackages: Package[]
}
function RankHrvTable({onSelectedHrv, allPackages}: HrvProp) {
  const {t} = useTranslation()
  const classes = useTableStyles()

  const {organization} = useOrganization()

  const currentPaymentType = organization?.promotionYn === 'N' ? 'N' : 'Y'
  const [hrvValue, setHrvValue] = React.useState<string>()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setHrvValue(event.target.value)
    onSelectedHrv(value)
  }

  return (
    <Table
      className={classes.table}
      aria-labelledby='tableTitle'
      aria-label='enhanced table'
    >
      <TableHead>
        <TableRow className={classes.tableHeader}>
          <TableCell align='center'>
            <Typography align='center' className={classes.hidden}>
              {' '}
            </Typography>
          </TableCell>

          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
          >
            {t('IPointRateName')}
          </TableCell>
          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
          >
            {t('IPointWaveAmount')}
          </TableCell>
          <TableCell
            align='center'
            padding='none'
            className={classes.tableHeaderCell}
            style={{width: 610}}
          >
            {t('IIncludedProduct')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allPackages != null &&
          allPackages.map((item) => {
            return (
              <>
                {item.promotionYn === currentPaymentType && (
                  <TableRow
                    hover
                    role='checkbox'
                    classes={{hover: classes.hover}}
                  >
                    <TableCell
                      align='center'
                      style={{borderBottom: '1px solid #f1f1f1'}}
                    >
                      <Radio
                        checked={hrvValue === item.packageName}
                        onChange={(e) =>
                          handleChange(e, item.packageType ?? '')
                        }
                        value={item.packageName}
                        name='radio-button-demo'
                        inputProps={{'aria-label': 'A'}}
                      />
                    </TableCell>

                    <TableCell align='center' className={classes.tableContent}>
                      {item.packageName ?? '-'}
                    </TableCell>
                    <TableCell align='center' className={classes.tableContent}>
                      {item.point ?? '-'}
                    </TableCell>
                    <TableCell align='center' className={classes.tableContent}>
                      {item.detailTypeList != null && (
                        <div className={classes.hospital}>
                          {getArray(item.detailTypeList).map((data: any) => (
                            <PointUsableRangeSmall packageName={String(data)} />
                          ))}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}
      </TableBody>
    </Table>
  )
}

const PointRankDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const open = useAppSelector(selectPointRankDialogOpen)
  const [myPackage, setMyPackage] = React.useState<Package[]>()
  const [eegPackages, setEegPackages] = React.useState<Package[]>()
  const [hrvPackages, setHrvPackages] = React.useState<Package[]>()
  const {user: currentUser} = useAuth()
  const {organization} = useOrganization()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const [eegValue, setEegValue] = React.useState('')
  const [hrvValue, setHrvValue] = React.useState('')

  const HRV_PRO = 'C_3'
  const NONE = 'NONE'

  const getOptionPackage = (list: Package): boolean => {
    if (list.packageType === HRV_PRO) return true
    if (list.packageType === NONE) return true
    return false
  }

  const packageList = async () => {
    const result = await dispatch(fetchMyPackageAction(organization?.oid ?? ''))
    const data = result.payload as PackageInfo
    if (data) {
      setMyPackage(data.myPackage)
      const eegPackageList = data.packageList.filter(
        (value) => value.packageType !== HRV_PRO,
      )
      const hrvPackageList = data.packageList.filter((value) =>
        getOptionPackage(value),
      )
      setEegPackages(eegPackageList)
      setHrvPackages(hrvPackageList)
    }
  }

  const handleClose = () => {
    dispatch(closePointRankDialog())
  }
  const handleOk = async () => {
    if (eegValue && hrvValue) {
      try {
        const response = await updatePointPackageApi({
          oid: organization?.oid ?? '',
          eegPackage: eegValue ?? '',
          hrvPackage: hrvValue ?? '',
        })
        if (response.success) {
          onSuccessModalOpen(t('IProcessSuccess'))
          dispatch(
            fetchPointInfoAction({
              uid: currentUser?.uid ?? '',
              oid: organization?.oid ?? '',
              month: nowYearDate(),
            }),
          )
          dispatch(fetchMyPackageRequest())
          handleClose()
        }
        if (!response.success) onFailureModalOpen(response.message)
      } catch (e) {
        onFailureModalOpen(e.message)
      }
    } else {
      onFailureModalOpen(t('ISelectRequired'))
    }
  }

  useEffect(() => {
    dispatch(
      fetchPointPackageInfo({
        oid: organization?.oid ?? '',
        uid: currentUser?.uid ?? '',
      }),
    )
    packageList()
  }, [])

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      className={classes.root}
      fullWidth
      onClose={handleClose}
    >
      <Card className={classes.contentContainer}>
        <div>
          <div className={classes.scroll}>
            <RadioGroup
              row
              aria-label='Classification'
              name='Classification'
              defaultValue='top'
            >
              <div className={classes.subWrap}>
                <RankEegTable
                  onSelectedEeg={(value) => setEegValue(value ?? '')}
                  allPackages={eegPackages ?? []}
                />
                <RankHrvTable
                  onSelectedHrv={(value) => setHrvValue(value ?? '')}
                  allPackages={hrvPackages ?? []}
                />
              </div>
            </RadioGroup>

            <div className={classes.promotionInfoContainer}>
              {myPackage && (
                <div className='promotionInfoWrap'>
                  <p className='promotionInfoTitle'>MCI Classifier</p>
                  {myPackage.length > 2 && (
                    <p className='promotionInfoContent'>
                      {myPackage[2].totalCount}
                    </p>
                  )}
                  {myPackage.length < 3 && (
                    <p className='promotionInfoContent'>{t('INoFreeTime')}</p>
                  )}
                </div>
              )}
              {myPackage && (
                <div className='promotionInfoWrap'>
                  <p className='promotionInfoTitle'>Advanced summary report</p>
                  {myPackage.length > 3 && (
                    <p className='promotionInfoContent'>
                      {myPackage[3].totalCount}
                    </p>
                  )}
                  {myPackage.length < 4 && (
                    <p className='promotionInfoContent'>{t('INoFreeTime')}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={classes.alertButtonContainer}>
            <ActionButton
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
              className={classes.okButtonColor}
            >
              {t('ICancel')}
            </ActionButton>
            <Typography className={classes.padding} />
            <ActionButton
              variant='contained'
              disableElevation
              color='primary'
              onClick={handleOk}
            >
              {t('IOk')}
            </ActionButton>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default PointRankDialog
