import Card from 'components/atoms/Card'
import Dialog from '@material-ui/core/Dialog'
import {
  closeSalesInspectionEditDialog,
  selectSalesInspectionEditDialogOpen,
} from 'features/modal/modalSlice'
import {
  EmergencyNotifyItems,
  EmergencyNotifyRequest,
  EmergencyNotifyResponse,
} from 'api/salesNoticeApi'
import {
  editSalesEmergencyNoticeAction,
  fetchEmergencyNotice,
} from 'features/sales/salesNoticeSlice'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useFailureModal from 'features/modal/useFailureModal'
import Button from '@material-ui/core/Button'
import {
  dateToDashString,
  fromIsoDateOrStringToLocalDate,
  isoStringToDateAndTimeString,
  localStringToUtcString,
  makeDateAndTimeFormat,
  startDateCompareEndDate,
  toUtcDate,
} from 'helpers/dateHelper'
import CloseIcon from '@material-ui/icons/Close'
import CalenderSinglePicker from 'components/Calender/CalenderSinglePicker'
import {
  InputTitle,
  NoticeContentWrapper,
  NoticeInputContentText,
  NoticeInputTitleText,
  NoticeTimeInputText,
  NoticeTitle,
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioButtonText,
  TitleLine,
} from 'pages/SalesPages/SalesAppNoticePage/StyledPostDialog'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import {useTranslation} from 'react-i18next'
import i18n from 'i18n'

interface SalesInspectionEditDialogProps {
  isSelected?: boolean
  clickIndexDataNumber: number
}

export default function SalesNoticeInspectionEditDialog(
  props: SalesInspectionEditDialogProps,
) {
  const {isSelected, clickIndexDataNumber} = props
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [startTimeSelect, setStartTimeSelect] = React.useState<string>(
    t('IAppNoticeTimeNow'),
  )
  const [endTimeSelect, setEndTimeSelect] = React.useState<string>(
    t('IAppNoticeTimeAutoNone'),
  )
  const [beginDate, setBeginDate] = React.useState<Date>()
  const [beginDateString, setBeginDateString] = React.useState<string>()

  const [onBeginDate, setOnBeginDate] = React.useState<Date>()
  const [beginDatePick, setBeginDatePick] = React.useState<boolean>(true)

  const [endDate, setEndDate] = React.useState<Date>()
  const [endDateString, setEndDateString] = React.useState<string>()

  const [titleKr, setTitleKr] = React.useState<string>()
  const [contentKr, setContentKr] = React.useState<string>()

  const [titleEn, setTitelEn] = React.useState<string>()
  const [contentEn, setContentEn] = React.useState<string>()
  const [beginHour, setBeginHour] = React.useState<string>()
  const [beginMin, setBeginMin] = React.useState<string>()
  const [endHour, setEndHour] = React.useState<string>()
  const [endMin, setEndMin] = React.useState<string>()
  const [submitCheck, setSubmitCheck] = React.useState<boolean>(true)

  const handleBeginDateChanged = (date?: Date | null) => {
    if (date) {
      setBeginDatePick(true)
      setOnBeginDate(date)
      setBeginDateString(dateToDashString(date))
    }
  }

  const handleEndDateChanged = (date?: Date | null) => {
    if (date) {
      setEndDateString(dateToDashString(date))
    }
  }

  const handleClose = () => {
    dispatch(closeSalesInspectionEditDialog())
  }
  const checkSelectManualTime = (value: string) => {
    return value === t('IAppNoticeTimeManualEntry')
  }
  const handleDuplicate = (hour: string, min: string): string => {
    return `${hour}:${min}`
  }

  const hadleStartSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStartTimeSelect(event.target.value)
  }

  const handleEndSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEndTimeSelect(event.target.value)
  }

  const salesInspectionDialogOpen = useAppSelector(
    selectSalesInspectionEditDialogOpen,
  )
  const selectTimeNow = (value: string): boolean => {
    return value === t('IAppNoticeTimeNow')
  }

  const selectTimeEnd = (value: string): boolean => {
    return value === t('IAppNoticeTimeAutoNone')
  }

  const handleTimeNumberSet = (time: string): string => {
    const timeString = time.substring(0, 1)

    if (Number(time) < 10 && timeString !== '0') {
      return `0${time}`
    }
    return time
  }

  const onSubmit = () => {
    if (Number(beginHour) > 23 || Number(endHour) > 23) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }
    if (Number(beginMin) > 59 || Number(endMin) > 59) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }

    const contentData: EmergencyNotifyRequest = {
      isNotProceed: true,
      begin: null,
      end: null,
      contents: JSON.stringify([
        {
          languageCode: 'EN',
          title: titleEn,
          content: contentEn,
        },
        {
          languageCode: 'KO',
          title: titleKr,
          content: contentKr,
        },
      ]),
    }

    if (!checkSelectManualTime(startTimeSelect)) {
      const timeNow = toUtcDate(new Date())

      contentData.begin = makeDateAndTimeFormat(timeNow)
    }

    if (beginHour && beginMin && beginDate) {
      const beginString = `${beginDateString} ${handleDuplicate(
        handleTimeNumberSet(beginHour),
        handleTimeNumberSet(beginMin),
      )}`

      contentData.begin = localStringToUtcString(beginString)
    }
    if (endHour && endMin && endDate) {
      const endString = `${endDateString} ${handleDuplicate(
        handleTimeNumberSet(endHour),
        handleTimeNumberSet(endMin),
      )}`

      contentData.end = localStringToUtcString(endString)
    }

    if (
      (checkSelectManualTime(startTimeSelect) && !beginHour) ||
      (checkSelectManualTime(startTimeSelect) && !beginMin)
    ) {
      const beginString = `${beginDateString} ${handleDuplicate('00', '00')}`

      contentData.begin = localStringToUtcString(beginString)
    }
    if (
      (checkSelectManualTime(endTimeSelect) && !endHour) ||
      (checkSelectManualTime(endTimeSelect) && !endMin)
    ) {
      const endString = `${beginDateString} ${handleDuplicate('23', '59')}`

      contentData.end = localStringToUtcString(endString)
    }

    if (contentData.begin && contentData.end) {
      if (
        !startDateCompareEndDate(
          fromIsoDateOrStringToLocalDate(contentData.begin),
          fromIsoDateOrStringToLocalDate(contentData.end),
        )
      ) {
        return onFailureModalOpen(t('IStartTimeEndTimeComparisonMessage'))
      }
    }

    if (contentData.isNotProceed) {
      dispatch(
        editSalesEmergencyNoticeAction({
          data: contentData,
          query: {notifyId: clickIndexDataNumber},
        }),
      )
    }
    return handleClose()
  }

  const [emergencyNotifyItems, setEmergencyNoticesItems] = React.useState<
    EmergencyNotifyItems[]
  >([
    {
      id: 0,
      order: 0,
      isNotProceed: false,
      authorName: '',
      begin: '',
      end: '',
      contents: [
        {
          languageCode: '',
          title: '',
          content: '',
          attachments: null,
        },
        {
          languageCode: '',
          title: '',
          content: '',
          attachments: null,
        },
      ],
    },
  ])

  const getEmergencyResponse = async () => {
    const result = await dispatch(fetchEmergencyNotice({page: 1, size: 100}))
    const data = result.payload as EmergencyNotifyResponse

    return data
  }

  const checkBeginDateNull = (begin: EmergencyNotifyItems[]) => {
    if (begin[0].begin === null) {
      setStartTimeSelect(t('IAppNoticeTimeNow'))
    } else {
      setStartTimeSelect(t('IAppNoticeTimeManualEntry'))
    }
  }

  const checkEndDateNull = (end: EmergencyNotifyItems[]) => {
    if (end[0].end === null) {
      setEndTimeSelect(t('IAppNoticeTimeAutoNone'))
    } else {
      setEndTimeSelect(t('IAppNoticeTimeManualEntry'))
    }
  }

  const parseDate = (date: EmergencyNotifyItems[]) => {
    if (date && date[0].begin) {
      setStartTimeSelect(t('IAppNoticeTimeManualEntry'))

      const beginUTCtoLocal = isoStringToDateAndTimeString(date[0].begin)

      const beginParse = beginUTCtoLocal.slice(-8, -3)
      const parsingResult = beginParse.split(':')

      // const beginParseCalendar = date[0].begin.slice(0, 10)
      const calendarToDate = fromIsoDateOrStringToLocalDate(beginUTCtoLocal)

      setBeginHour(parsingResult[0])
      setBeginMin(parsingResult[1])
      setOnBeginDate(calendarToDate)
      setBeginDate(calendarToDate)
    }
    if (date && date[0].end) {
      setEndTimeSelect(t('IAppNoticeTimeManualEntry'))

      const endUTCtoLocal = isoStringToDateAndTimeString(date[0].end)
      const beginParse = endUTCtoLocal.slice(-8, -3)
      const parsingResult = beginParse.split(':')

      // const endParseCalendar = date[0].end.slice(0, 10)
      const calendarToDate = fromIsoDateOrStringToLocalDate(endUTCtoLocal)

      setEndHour(parsingResult[0])
      setEndMin(parsingResult[1])
      setEndDate(calendarToDate)
    }
  }

  useEffect(() => {
    if (emergencyNotifyItems && salesInspectionDialogOpen) {
      if (!emergencyNotifyItems[0].begin) {
        setStartTimeSelect(t('IAppNoticeTimeNow'))
        setBeginDate(new Date())
        setOnBeginDate(new Date())
      }
      if (!emergencyNotifyItems[0].end) {
        setEndTimeSelect(t('IAppNoticeTimeAutoNone'))
        setEndDate(new Date())
      }
      checkBeginDateNull(emergencyNotifyItems)
      checkEndDateNull(emergencyNotifyItems)
      parseDate(emergencyNotifyItems)
      setTitleKr(emergencyNotifyItems[0].contents[1].title)
      setContentKr(emergencyNotifyItems[0].contents[1].content)
      setTitelEn(emergencyNotifyItems[0].contents[0].title)
      setContentEn(emergencyNotifyItems[0].contents[0].content)
    }
  }, [emergencyNotifyItems])

  useEffect(() => {
    setBeginDate(undefined)
    setEndDate(undefined)
    setBeginHour(undefined)
    setBeginMin(undefined)
    setEndHour(undefined)
    setEndMin(undefined)
    if (clickIndexDataNumber) {
      getEmergencyResponse().then((result) =>
        setEmergencyNoticesItems(
          result.items.filter((value) => {
            return value.id === clickIndexDataNumber
          }),
        ),
      )
    }

    if (salesInspectionDialogOpen && !isSelected) {
      handleClose()
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeSelectRequired'),
      })
    }
  }, [salesInspectionDialogOpen])

  useEffect(() => {
    if (titleKr && contentKr && titleEn && contentEn) {
      setSubmitCheck(false)
    }
    if (
      titleKr === undefined ||
      contentKr === undefined ||
      titleEn === undefined ||
      contentEn === undefined
    ) {
      setSubmitCheck(true)
    }
  }, [titleKr, contentKr, titleEn, contentEn])

  useEffect(() => {
    if (beginHour && beginMin && endHour && endMin) {
      const beginHourToNum = Number(beginHour)
      const endHourToNum = Number(endHour)
      const beginMinToNum = Number(beginMin)
      const endMinToNum = Number(endMin)

      if (beginHourToNum > 23) {
        setBeginHour('')
      }
      if (endHourToNum > 23) {
        setEndHour('')
      }
      if (beginMinToNum > 59) {
        setBeginMin('')
      }
      if (endMinToNum > 59) {
        setEndMin('')
      }
    }
  }, [beginHour, beginMin, endHour, endMin])

  useEffect(() => {
    if (startTimeSelect === `${t('IAppNoticeTimeNow')}`) {
      setBeginHour(undefined)
      setBeginMin(undefined)
    }
    if (endTimeSelect === `${t('IAppNoticeTimeAutoNone')}`) {
      setEndHour(undefined)
      setEndMin(undefined)
    }
  }, [startTimeSelect, endTimeSelect])

  useEffect(() => {
    if (beginDate) setBeginDateString(dateToDashString(beginDate))
    if (endDate) setEndDateString(dateToDashString(endDate))
  }, [beginDate, endDate])

  return (
    <div>
      <Dialog
        open={salesInspectionDialogOpen}
        fullWidth
        maxWidth='md'
        style={{
          width: `${Spacing.notice.noticeDialogWidth}px`,
          margin: `${Spacing.notice.noticeTextMargin} auto`,
        }}
      >
        <Card>
          <div
            style={{
              marginBottom: `${Spacing.notice.noticeDivWidthMarginBottom}px`,
            }}
          >
            <NoticeTitle>{t('IAppInspectionTitle')}</NoticeTitle>
            <CloseIcon
              style={{
                position: 'absolute',
                right: `${Spacing.notice.noticeCloseIconRight}px`,
                top: `${Spacing.notice.noticeCloseIconTop}px`,
                width: `${Spacing.notice.noticeCloseIconWidth}`,
                height: `${Spacing.notice.noticeCloseIconWidth}`,
                color: `${Colors.notice.noticeCancleIconColor}`,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <TitleLine />
            <NoticeContentWrapper>
              <div
                style={{
                  margin: `${Spacing.notice.noticeTextMargin} auto`,
                  width: `${Spacing.notice.noticeContentWidth}px`,
                }}
              >
                <InputTitle>{t('IAppInspectionTime')}</InputTitle>
                <div
                  style={{padding: `${Spacing.notice.noticeContentPadding}px`}}
                />
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeStart')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeNow')}
                      checked={startTimeSelect === `${t('IAppNoticeTimeNow')}`}
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>{t('IAppNoticeTimeNow')}</RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        startTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeEnd')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeAutoNone')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeAutoNone')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeAutoNone')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                {/* 공지텍스트 */}
                <InputTitle>{t('IAppInspectionTitleKrEn')}</InputTitle>

                <NoticeInputTitleText
                  onChange={(e) => {
                    setTitleKr(e.target.value)
                  }}
                  value={titleKr}
                  placeholder={t('IAppInspectionTitleRequired')}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/gi,
                  //     '',
                  //   )
                  // }}
                />

                <InputTitle>{t('IAppNoticeContentKrEn')}</InputTitle>
                <NoticeInputContentText
                  value={contentKr}
                  onChange={(e) => {
                    setContentKr(e.target.value)
                  }}
                  placeholder={t('IContentsRequired')}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/gi,
                  //     '',
                  //   )
                  // }}
                />
              </div>
              <div
                style={{marginTop: `${Spacing.notice.noticeDivMarginTop}px`}}
              >
                <div>
                  <div style={{display: 'flex'}}>
                    {beginDate && (
                      <CalenderSinglePicker
                        selectedBeginDate={false}
                        isOpen={!selectTimeNow(startTimeSelect)}
                        onChangeValue={handleBeginDateChanged}
                        dateValue={beginDate}
                      />
                    )}
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      value={beginHour}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => setBeginHour(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      value={beginMin}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => setBeginMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                  <div style={{display: 'flex'}}>
                    {endDate && (
                      <CalenderSinglePicker
                        onBeginDate={onBeginDate}
                        selectedBeginDate={beginDatePick}
                        isOpen={!selectTimeEnd(endTimeSelect)}
                        dateValue={endDate}
                        onChangeValue={handleEndDateChanged}
                      />
                    )}
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      value={endHour}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndHour(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      value={endMin}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                </div>
                {/* 영문입력텍스트 */}
                {i18n.language !== 'ko' && (
                  <div
                    style={{
                      marginTop: `${Spacing.notice.noticeInputTitleTextCustomMarginTop}px`,
                    }}
                  >
                    <NoticeInputTitleText
                      value={titleEn}
                      style={{
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      value={contentEn}
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
                {i18n.language === 'ko' && (
                  <div>
                    <NoticeInputTitleText
                      value={titleEn}
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      style={{
                        marginTop: `${Spacing.notice.noticeInputEnTitleCustomMarginTop}px`,
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      value={contentEn}
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
              </div>
            </NoticeContentWrapper>
          </div>
          <div
            style={{
              textAlign: 'center',
              backgroundColor: `${Colors.cancelButtonLight}`,
              padding: `${Spacing.notice.noticeButtonBackgroundPaddingTop}px ${Spacing.notice.noticeButtonBackgroundPaddingLeft}`,
            }}
          >
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
              style={{
                backgroundColor: 'white',
                color: `${Colors.notice.noticeRadioButtonTextColor}`,
                marginRight: `${Spacing.notice.noticeButtonMarginRight}px`,
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('ICancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={submitCheck}
              disableElevation
              onClick={onSubmit}
              style={{
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('IOk')}
            </Button>
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
