import {numberWithCommas} from 'helpers/commonHelper'
import {
  UsageHistoryItems,
  UsageHistoryAndUnpaidVouchers,
} from '../../Interfaces/UsageHistoryAndUnpaidVouchers'
import UnpaidVouchersModel from './UnpaidVouchersModel'

export default class UsageHistoryAndUnpaidVouchersModel
  implements UsageHistoryAndUnpaidVouchers
{
  constructor(raw: UsageHistoryAndUnpaidVouchersModel) {
    this.begin = raw.begin
    this.usageHistory = raw.usageHistory
    this.unpaidVouchers = raw.unpaidVouchers.map(
      (item) => new UnpaidVouchersModel(item),
    )
  }

  begin: string

  usageHistory: UsageHistoryItems

  unpaidVouchers: UnpaidVouchersModel[]

  getUnpaidVouchersTotalPrice: () => string = () => {
    const {currencyCode} = this.unpaidVouchers[0]
    let total = 0
    this.unpaidVouchers.map((item) => {
      total += item.price
      return null
    })
    return `${currencyCode} ${numberWithCommas(total)}`
  }
}
