import React, {useState} from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import {useTranslation} from 'react-i18next'
import CheckBox from 'components/Checkbox'
import {PrimaryLoadableButton} from 'components/CustomButtons/PrimaryLoadableButton'
import Container, {
  SettingContainer,
  ContentContainer,
  Title,
  SelectContainer,
  ListContainer,
  ActionContainer,
} from './styles'

const CONTENT_WIDTH = 300
interface IPdfDownloadProps {
  isMultipleDownload?: boolean
  pathPDF?: string
}

const PdfDownload = (props: IPdfDownloadProps) => {
  const {isMultipleDownload, pathPDF} = props
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checkList, setCheckList] = useState([
    {
      type: 'eeg',
      label: 'EEG',
      checked: false,
    },
    {
      type: 'hrv',
      label: 'HRV',
      checked: false,
    },
    {
      type: 'mci',
      label: 'aMCI',
      checked: false,
    },
  ])
  const {t} = useTranslation()
  const handleOpenChange = () => {
    setIsOpen((prev) => !prev)
  }

  const handleDownload = () => {
    if (pathPDF) {
      // Create a link element
      const link = document.createElement('a')
      const filename = pathPDF.split('/').pop()
      // Set the href attribute to the file URL
      link.href = `${pathPDF}?t=${Date.now()}`
      // Specify the download attribute with the desired file name
      link.download = filename || 'report.pdf'
      // Append the link to the document
      document.body.appendChild(link)
      // Trigger a click event on the link
      link.click()
      // Remove the link from the document
      document.body.removeChild(link)
    }
  }

  const handleCheckAll = () => {
    if (checkList.every((item) => item.checked === true)) {
      setCheckList((prev) => prev.map((item) => ({...item, checked: false})))
    } else {
      setCheckList((prev) => prev.map((item) => ({...item, checked: true})))
    }
  }

  const handleCheckItem = (type: string) => {
    setCheckList((prev) =>
      prev.map((item) =>
        item.type === type ? {...item, checked: !item.checked} : {...item},
      ),
    )
  }

  return (
    <Container open={isOpen} width={CONTENT_WIDTH}>
      <SettingContainer onClick={handleOpenChange}>
        <SettingsIcon />
      </SettingContainer>
      <ContentContainer width={CONTENT_WIDTH}>
        <Title>{t('IPdfDownload')}</Title>
        {isMultipleDownload && (
          <SelectContainer>
            <CheckBox
              onChange={handleCheckAll}
              checked={checkList.every((item) => item.checked === true)}
            >
              {t('ISelectAll')}
            </CheckBox>

            <ListContainer>
              {checkList.map((item) => (
                <CheckBox
                  checked={item.checked}
                  onChange={() => handleCheckItem(item.type)}
                >
                  {item.label}
                </CheckBox>
              ))}
            </ListContainer>
          </SelectContainer>
        )}
        <ActionContainer>
          <PrimaryLoadableButton
            disabled={!pathPDF}
            onClick={handleDownload}
            fullWidth
            loading={isLoading}
          >
            {t('IDownload')}
          </PrimaryLoadableButton>
        </ActionContainer>
      </ContentContainer>
    </Container>
  )
}

export default PdfDownload
