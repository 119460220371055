import BrainActiveChart from 'assets/img/summary_standard_img.png'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import SubTitle from 'components/Text/SubTitle'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from '../../../Text/ReportSubtitle'

const getNormativeI18n = (score: number) => {
  if (score > 84) return 'INormativeHigher'
  if (score >= 16) return 'INormativeIn'
  return 'INormativeLower'
}

export default function EEGSummaryOpinion(props: {
  score: number
  contourImage?: string
  index: number
}) {
  const {score, contourImage, index} = props
  const {t} = useTranslation()
  const classes = useStyles()
  const normative = getNormativeI18n(score)
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number={`${index}`}>
        {t('IEEGSummaryReportBrainAgingOpinionTitle')}
      </ReportSubtitle>
      <div className={classes.brainOpinion}>
        <div className='Detail'>
          <div className='Opinion'>
            {t('IEEGSummaryReportBrainAgingResult', {
              score,
              normative: t(normative),
            })}
          </div>
          <img src={BrainActiveChart} alt='' className='Image' />

          <div className='Reference'>
            <div className='Title'>{t('IReference')}</div>
            <div className='Description'>
              {t('IEEGSummaryReportBrainAgingOpinionAbout')}
            </div>
          </div>
        </div>
        <div className='Contour'>
          <img src={contourImage} alt='' className='Image' />
        </div>
      </div>
    </div>
  )
}
