import {OutlinedInput} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import SearchBar from 'components/Forms/SearchBar'

import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import {getQueryFromUrl} from 'helpers/commonHelper'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

enum SearchKeys {
  patientName = 'patientName',
  patientChartNo = 'patientChartNo',
  birth = 'birth',
}

const SEARCH_SELECT_ITEMS: SelectItem<any>[] = [
  {label: 'ICustomerName', value: SearchKeys.patientName},
  {label: 'IChartNo', value: SearchKeys.patientChartNo},
  {label: 'IBirthday', value: SearchKeys.birth},
]

interface SelectAnalysisSearchProps {
  onSearch: (params: any) => void
}

export default function HealthCareSearchComponent({
  onSearch,
}: SelectAnalysisSearchProps) {
  const location = useLocation()
  const classes = useToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()

  const {t} = useTranslation()

  const [searchCondition, setSearchCondition] = useState<SearchKeys>(
    SearchKeys.patientName,
  )
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as SearchKeys)

  const handleSearch = () => {
    const condition = {
      patientName: '',

      patientChartNo: '',
      birth: '',
    }
    onSearch({
      ...condition,
      [searchCondition]: searchKeyword,
    })
  }

  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  /** Set default value for search condition if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (searchParams) {
      if (searchParams.patientChartNo)
        setSearchCondition(SearchKeys.patientChartNo)
      else if (searchParams.birth) setSearchCondition(SearchKeys.birth)
      else if (searchParams.patientName)
        setSearchCondition(SearchKeys.patientName)
    }
  }, [])

  return (
    <div className={classes.container}>
      <FormControl variant='outlined'>
        <Select
          value={searchCondition}
          onChange={handleSearchChanged}
          className={classes.searchSelect}
          input={
            <OutlinedInput
              name='age'
              id='outlined-age-simple'
              classes={outlinedInputClasses}
            />
          }
        >
          {SEARCH_SELECT_ITEMS.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar
        onChangeValue={handleValue}
        onSearchEvent={handleSearch}
        disabledValue={false}
      />
    </div>
  )
}
