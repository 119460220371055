import {makeStyles, OutlinedInput, Select} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
// eslint-disable-next-line no-restricted-imports
import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import {SelectProps} from '@material-ui/core/Select'
import {createStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import {fetchSalesNameListApi} from 'api/organizationApi'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import useAsyncList from 'hooks/useAsyncList'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface SelectSalesNameProps extends SelectProps {
  className?: string
}

const styles = () =>
  createStyles({
    root: {
      flex: 1,
    },
    noSelect: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '18.5px 14px',
      minHeight: '1.1876rem',
      boxSizing: 'border-box',
      border: '1px solid',
      borderRadius: 4,
      borderColor: grey[400],
      fontSize: '1rem',
    },
    iconButton: {
      marginLeft: 'auto',
    },
  })

const useStyles = makeStyles(styles)

const SelectLoading = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.noSelect}>
      <span>{t('common.loading')}</span>
      <CircularProgress
        color='secondary'
        size='1rem'
        className={classes.iconButton}
      />
    </div>
  )
}

interface ErrorSelectProps {
  error: any
  fetch?: VoidPromiseFunc
}

const SelectError = ({error, fetch}: ErrorSelectProps) => {
  const classes = useStyles()
  return (
    <div className={classes.noSelect}>
      <span>{error}</span>
      {fetch && (
        <IconButton onClick={fetch} className={classes.iconButton} size='small'>
          <RefreshIcon />
        </IconButton>
      )}
      {!fetch && <CloseIcon className={classes.iconButton} />}
    </div>
  )
}

interface SalesName {
  firstName: string
  lastName: string
  name: string
}

const SelectBoldSalesName = (props: SelectSalesNameProps) => {
  const {t} = useTranslation()
  const {className, ...selectProps} = props
  const outlinedInputClasses = useOutlinedInputStyles()
  const [state, fetch] = useAsyncList<SalesName>(
    () => fetchSalesNameListApi(),
    [],
  )
  const {data, loading, error} = state

  if (loading) return <SelectLoading />
  if (error) return <SelectError error={error} fetch={fetch} />

  return (
    <Select
      variant='outlined'
      className={className}
      displayEmpty
      input={
        <OutlinedInput
          name='country'
          id='outlined-country-simple'
          classes={outlinedInputClasses}
        />
      }
      {...selectProps}
    >
      <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
      {data?.map((item, index) => (
        <MenuItem key={index} value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectBoldSalesName
