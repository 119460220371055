import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Colors} from 'components/common/useBaseStyle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1300,
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'pre-wrap',
      gap: 40,
      '& .Warning': {
        borderTop: '1px solid',
        borderTopColor: '#bdbdbd',
        color: '#bdbdbd',
      },
    },
    hrv: {
      maxWidth: 1100,
      '& .ReportTitle': {margin: '20px 0'},
    },
    hrvOpinion: {
      '& .Opinion': {
        fontWeight: 'bold',
        fontSize: 15,
        margin: '50px 0 10px',
        position: 'relative',
        '& .Label': {
          padding: '2px 10px',
          backgroundColor: '#0bb7b6',
          color: 'white',
          fontWeight: 'bold',
          borderRadius: 30,
          zIndex: 10,
          position: 'relative',
        },
        '& .Divider': {
          position: 'absolute',
          top: 12,
          border: '1px solid #0bb7b6',
          width: '100%',
          margin: 0,
        },
      },
      '& .Balance': {
        fontSize: 17,
        margin: '20px 30px',
        color: '#3eb9bc',
        lineHeight: 1.5,
        whiteSpace: 'pre-wrap',
      },
    },
    hrvAriability: {
      display: 'flex',
      flexDirection: 'row',
      gap: 30,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& .ContourImage': {
        flex: 1,
        height: 'auto',
        width: '45%',
        objectFit: 'scale-down',
      },
    },
    Divider: {
      height: 2,
      backgroundColor: '#0bb7b6',
      width: '100%',
    },
    description: {
      fontSize: 17,
      fontWeight: 500,
      color: Colors.darkblue,
      lineHeight: 1.5,
    },
    hrvAutoNervous: {
      display: 'flex',
      gap: 30,
      width: '100%',
      // maxHeight: 450,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& .CircularChartImage': {
        // flex: 1,
        height: 'auto',
        width: '40%',
        marginTop: 20,
        // objectFit: 'scale-down',
      },
      '& .TableImage': {
        // flex: 2,
        width: '45%',
        height: 'auto',
        objectFit: 'scale-down',
        padding: 5,
      },
      '& .AutoNervousTitle': {
        fontWeight: 'bold',
        fontSize: 20,
        color: Colors.darkblue,
      },
      '& .About': {
        fontSize: 17,
        color: '#707070',
        marginBottom: 15,
      },
      '& .Sympathetic': {
        color: '#0bb7b6',
        fontSize: 15,
        padding: 3,
        '& .Label': {
          padding: '1px 3px',
          backgroundColor: '#0bb7b6',
          color: 'white',
          fontWeight: 'bold',
        },
        '& .Label-append': {
          fontWeight: 'bold',
        },
      },
      '& .Parasympathetic': {
        color: '#008ba3',
        fontSize: 15,
        padding: 3,
        '& .Label': {
          padding: '1px 3px',
          backgroundColor: '#008ba3',
          color: 'white',
          fontWeight: 'bold',
        },
        '& .Label-append': {
          fontWeight: 'bold',
        },
      },
      '& .Analysis': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 30,
      },
    },
    hrvStress: {
      maxWidth: 1100,
      '& .Wrapper': {
        position: 'relative',
        '& .PhaseChart': {
          width: '100%',
          height: 'auto',
        },
        '& .PhaseChart-Label': {
          color: 'white',
          fontWeight: 'bold',
          fontSize: 14,
          position: 'absolute',
          top: '44%',
          transform: 'translate(-50%, -50%)',
        },
      },
      '& .TableHeader': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#0bb7b6',
        color: 'white',
        padding: '10px 20px',
        fontSize: 15,
        fontWeight: 600,
      },
      '& .TableCell': {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        alignItems: 'center',
        color: Colors.darkblue,
        '& .title': {
          fontSize: 18,
          width: '30%',
          fontWeight: 600,
        },
        '& .content': {
          fontSize: 16,
          width: '70%',
        },
      },
    },
    Table: {
      '& .TableHeader': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#0bb7b6',
        color: 'white',
        padding: '10px 20px',
        fontSize: 18,
        fontWeight: 600,
        '& .title': {
          width: '30%',
        },
        '& .content': {
          width: '70%',
        },
      },
      '& .TableCell': {
        display: 'flex',
        flexDirection: 'row',
        padding: '25px 20px',
        alignItems: 'center',
        color: Colors.darkblue,
        '& .title': {
          fontSize: 18,
          width: '30%',
          fontWeight: 600,
        },
        '& .content': {
          fontSize: 16,
          width: '70%',
        },
      },
    },

    hrvSentimental: {
      maxWidth: 1100,
      marginBottom: 50,
      '& .ReportTitle': {
        marginBottom: 40,
      },
      '& .TableTitle': {
        color: Colors.darkblue,
        fontSize: 25,
        fontWeight: 600,
        marginTop: 20,
      },
      '& .Status': {
        display: 'grid',
        gridTemplateColumns: 'minmax(20%, 250px) 1fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: 30,
        gridRowGap: 50,

        '& .StatusIcon': {
          position: 'relative',
          justifySelf: 'center',
          alignSelf: 'center',
          textAlign: 'center',
          '& .StatusIcon-image': {
            width: '100%',
            height: 'auto',
            maxWidth: 160,
          },

          '& .StatusIcon-label': {
            position: 'absolute',
            width: 160,
            bottom: 4,
            color: '#008ba3',
            fontWeight: 'bold',
            fontSize: 16,
            // left: '50%',
            transform: 'translate(0%, -50%)',
            '&.Depress': {color: '#008ba3'},
            '&.Anxiety': {color: '#ffa600'},
          },
        },
        '& .Status-text': {
          fontSize: 18,

          '& .Title': {
            color: '#008ba3',
            fontWeight: 'bold',
            marginBottom: 30,
          },
          '& .Level': {
            fontSize: 17,
            fontWeight: 'bold',
            marginBottom: 15,
          },
          '& .Reference': {
            fontSize: 16,
            color: '#707070',
          },
        },
      },
    },
    eeg: {
      '& .ReportTitle': {marginBottom: 25},
    },
    brain3D: {
      '& .Image-wrapper': {
        textAlign: 'center',

        '& .Image': {
          width: '100%',
          height: 'auto',
          maxWidth: 800,
          objectFit: 'contain',
          marginBottom: 25,
        },
      },
      '& .Reference': {
        textAlign: 'left',

        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
    },
    p_brain3D: {
      display: 'flex',
      gap: 20,

      '& .Brain-3d-image': {
        flex: 6,
        '& img': {
          width: '100%',
          height: 'auto',
        },
      },
      '& .Brain-3d-info': {
        flex: 6,
        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
    },
    p_brainScore: {
      display: 'flex',
      gap: 30,

      '& .Brain-score-table': {
        flex: 6,
        '& table': {width: '100%', borderSpacing: 0, marginBottom: 7},
        '& thead': {
          backgroundColor: Colors.primary,
          color: Colors.white,
          borderColor: Colors.primary,
          padding: 5,
        },
        '& tbody tr td': {
          borderBottom: `1px solid ${Colors.border}`,
          paddingTop: 5,
          paddingBottom: 5,
        },
        '& .AreaLabel': {
          fontSize: 17,
          fontWeight: 'bold',
          color: Colors.darkblue,
        },

        '& .Score': {
          fontSize: 16,
          fontWeight: 'bold',
          color: Colors.darkblue,
        },
        '& .Label': {
          width: '100%',
          textAlign: 'center',
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 15,
          fontWeight: 'bold',

          '&.OverActive': {
            backgroundColor: Colors.roi.overActive,
            color: Colors.white,
          },
          '&.Balanced': {
            backgroundColor: Colors.roi.balanced,
            color: Colors.white,
          },
          '&.Underactive': {
            backgroundColor: Colors.roi.underActive,
            color: Colors.white,
            padding: 3,
          },
        },
        '& .Comment': {
          letterSpacing: -1,
          color: Colors.comment,
        },
      },
      '& .Brain-score-info': {
        flex: 6,
        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
    },
    p_brainOpinion: {
      // marginTop: 50,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '30px',

      '& .Detail': {
        '& .Opinion': {
          textAlign: 'left',
          fontSize: 15,
          marginBottom: 20,
        },
        '& .Reference': {
          '& .Title': {
            fontWeight: 'bold',
            fontSize: 15,
            marginBottom: 10,
            color: Colors.comment,
          },
          '& .Description': {
            fontSize: 13,
            color: Colors.comment,
          },
        },
      },
      '& .Contour': {
        alignSelf: 'center',
        justifySelf: 'center',

        '& .Image': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxWidth: 470,
        },
      },
    },
    brainOpinion: {
      marginTop: 50,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '30px',

      '& .Detail': {
        '& .Opinion': {
          textAlign: 'left',
          fontSize: 18,
          marginBottom: 20,
        },
        '& .Image': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxWidth: 470,
          marginBottom: 30,
        },
        '& .Reference': {
          '& .Title': {
            fontWeight: 'bold',
            fontSize: 20,
            marginBottom: 10,
            color: '#adadad',
          },
          '& .Description': {
            fontSize: 16,
            color: '#a8a6a6',
          },
        },
      },
      '& .Contour': {
        alignSelf: 'center',
        justifySelf: 'center',

        '& .Image': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxWidth: 470,
        },
      },
    },
    padding: {
      padding: theme.spacing(1),
    },
  }),
)

export default useStyles
