import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import SubTitle from 'components/Text/SubTitle'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from '../../../Text/ReportSubtitle'

export default function EEGSummaryBrain3D(props: {
  threeDimensionImage: string
  index: number
}) {
  const {threeDimensionImage, index} = props
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number={`${index}`}>
        {t('IEEGSummaryReportBrainAgingTitle')}
      </ReportSubtitle>
      <div className={classes.brain3D}>
        <div className='Image-wrapper'>
          <img src={threeDimensionImage} alt='' className='Image' />
        </div>

        <div className='Reference'>
          <div className='Title'>
            {t('IEEGSummaryReportBrainAgingAbout3DTitle')}
          </div>
          <div className='Desc'>
            {t('IEEGSummaryReportBrainAgingAbout3DDescription')}
          </div>
        </div>
      </div>
    </div>
  )
}
