import {fetchTherapyRead, selectEntity} from 'features/therapy/therapySlice'
import {useAppDispatch, useAppSelector} from 'hooks'
// import {
//   addTherapyCustomerPreset,
//   ElectrodeSettings,
//   fetchTherapyPresetList,
//   MultiTherapyPrescriptionListApi,
//   TherapyCustomerList,
//   TherapyCustomerListResponse,
//   TherapyReadRequest,
//   updateTherapyCustomerPreset,
//   MeasureTherapyListResponse,
//   fetchMeasuresList,
//   fetchPresetElectrodes,
//   PresetElectrodesResponseType,
// } from 'api/therapyApi'
import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {useTranslation} from 'react-i18next'
import {
  MultiTherapyPrescriptionListApi,
  MultiTherapyPrescriptionListResp,
  MultiTherapyPrescriptionOneReq,
  TherapyCommonRequest,
} from '../../api/therapyApi'
import {MultiTherapySettings} from '../../../@types'
// import {
//   MedicalChartsRequest,
//   MedicalChartsResponse,
//   fetchCustomerMedicalChartApi,
// } from 'api/treatmentApi'

interface UpdateSetting {
  electrode: MultiTherapySettings
  groupId: string
  dataId: string
}

export default function useTherapy() {
  const {t} = useTranslation()

  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const [customerId, setCustomerId] = useState<string>('')
  const [frequency, setFrequency] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [totalElement, setTotalElement] = useState<number>(0)
  const [measureIds, setMeasureIds] = useState<string[]>([])
  const [electrodeData, setElectrodeData] = useState<MultiTherapySettings>()

  const [medicalListQuery, setMedicalListQuery] =
    useState<MedicalChartsRequest>({
      page: 0,
      size: 20,
      chartSearchType: 'PBM',
    })

  const dispatch = useAppDispatch()

  // CYM : 테라피 처방 예약 조회 수정 추가
  const onFetch = ({uid, uuid}: MultiTherapyPrescriptionOneReq) =>
    dispatch(fetchTherapyRead({uid, uuid}))

  const onFetchTherapyCustomerList = async ({
    uid,
    uuid,
  }: TherapyCommonRequest): Promise<MultiTherapyPrescriptionListResp[]> => {
    try {
      console.log('customerId in useThereaphy', customerId)
      const result = await MultiTherapyPrescriptionListApi({
        uid,
        uuid,
      })
      return result.list
    } catch (e) {
      console.log(e)
      return e
    }
  }

  const {data: therapyPresetLists, refetch: refetchTherapyCustomer} = useQuery(
    ['fetchTherapyCustomerList', customerId],
    () => onFetchTherapyCustomerList(customerId),
    {
      enabled: !!customerId,
      retry: false,
    },
  )

  useEffect(() => {
    if (therapyPresetLists?.list && therapyPresetLists?.list[0]?.presetList) {
      const data = {
        customerId,
        groupId: therapyPresetLists?.list[0]?.presetList[0]?.groupId,
        dataId: therapyPresetLists?.list[0]?.presetList[0]?.presetDataId,
      }
      fetchPresetElectrodes(data).then((response) => {
        setElectrodeData(response.data)
      })
    }
  }, [therapyPresetLists])

  const onFetchMeasureTherapyList =
    // async (): Promise<MeasureTherapyListResponse> => {
    async (): Promise<any> => {
      try {
        const result = await fetchMeasuresList(measureIds)
        return result
      } catch (e) {
        console.log(e)
        return e
      }
    }

  const {data: measureTherapyList, refetch: refetchMeasureTherapyList} =
    useQuery(
      ['fetchMeasureTherapyList', measureIds],
      () => onFetchMeasureTherapyList(),
      {enabled: measureIds.length > 0},
    )

  const onFetchMedicalChartList = async (
    data: MedicalChartsRequest,
  ): Promise<MedicalChartsResponse> => {
    try {
      const result = await fetchCustomerMedicalChartApi(customerId, data)
      return result.pageData
    } catch (e) {
      console.log(e)
      return Promise.reject(e)
    }
  }

  const {
    data: customerMedicalChartList,
    refetch: refetchCustomerMedicalChartList,
  } = useQuery(
    ['fetchMedicalChartList', customerId],
    () => {
      return onFetchMedicalChartList(medicalListQuery)
    },
    {enabled: !!customerId},
  )

  const addTherapyPreset = async (
    electrode: MultiTherapySettings,
  ): Promise<TherapyCustomerListResponse> => {
    try {
      const result = await addTherapyCustomerPreset({
        customerId,
        presetData: [
          {
            frequency,
            duration,
            electrode,
            pbmType: 'THERAPY',
          },
        ],
      })
      return result
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const onAddTherapyPreset = useMutation(
    ['addTherapyPreset'],
    (electrode: MultiTherapySettings) => addTherapyPreset(electrode),
    {
      onSuccess: () => {
        onSuccessModalOpen(t('IProcessSuccess'))
      },
      onError: (error: any) => {
        onFailureModalOpen(error.message ?? t('IInvalidRequest'))
      },
    },
  )

  const updateTherapyPreset = async (
    electrode: ElectrodeSettings,
    groupId: string,
    dataId: string,
  ): Promise<TherapyCustomerListResponse> => {
    try {
      const result = await updateTherapyCustomerPreset({
        customerId,
        groupId,
        dataId,
        frequency,
        duration,
        electrode,
        pbmType: 'THERAPY',
      })
      return result
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const onUpdateTherapyPreset = useMutation(
    ['updateTherapyPreset'],
    ({electrode, groupId, dataId}: UpdateSetting) =>
      updateTherapyPreset(electrode, groupId, dataId),
    {
      onSuccess: () => {
        onSuccessModalOpen(t('IProcessSuccess'))
      },
      onError: (error: any) => {
        onFailureModalOpen(error.message ?? t('IInvalidRequest'))
      },
    },
  )

  const {loading, error, reservation} = useAppSelector(selectEntity)

  return {
    loading,
    error,
    reservation,
    therapyPresetLists,
    totalElement,
    customerId,
    onFetch,
    refetchTherapyCustomer,
    setCustomerId,
    onAddTherapyPreset,
    onUpdateTherapyPreset,
    setFrequency,
    setDuration,
    measureIds,
    setMeasureIds,
    measureTherapyList,
    customerMedicalChartList,
    electrodeData,
  }
}
