import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import {MINUTE} from 'constants/HistoryConstant'

const headerStyles = () =>
  createStyles({
    card: {
      '@media (max-width: 1696px)': {
        width: '49%',
      },
      '@media (max-width: 1369px)': {
        width: '99%',
      },
      border: '2px solid #d8d8d8',
      width: 'calc(33% - 4px)',
      minWidth: '250px',
      padding: '10px 15px 10px 15px',
      fontWeight: 700,
      borderRadius: '5px',
      height: '100px',
    },
    cardTotal: {
      '@media (max-width: 1750px)': {
        width: '330px',
      },
      '@media (max-width: 1697px)': {
        width: '370px',
      },
      '@media (max-width: 1500px)': {
        width: '330px',
      },
      '@media (max-width: 1369px)': {
        width: '370px',
      },
      '@media (max-width: 1140px)': {
        width: '300px',
      },
      height: '150px',
      width: '370px',
      position: 'relative',
      borderLeft: 'none',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    },
    title: {fontSize: '16px'},
    fontSize20: {
      fontSize: '20px',
    },
    fontSize32: {
      fontSize: '32px !important',
    },
    EEG: {color: '#0CC7C7'},
    PBM: {color: '#4472C4'},
    HRV: {color: '#FFC000'},
    logoPbm: {
      transform: 'rotate(180deg)',
    },
    totalCount: {
      fontSize: '20px',
      marginBottom: '3px',
      lineHeight: 'normal',
      color: '#676a6c',
      marginLeft: '11px',
    },
    detail: {fontSize: '17px', marginTop: '10px', marginLeft: '10px'},
    detailMonth: {marginLeft: '10px'},
    divider: {
      backgroundColor: '#c0c0c0',
      height: '1px',
      margin: '10px 0 10px 0',
      width: '70%',
    },
    success: {
      color: '#4472C4',
    },
    fail: {
      color: '#FF0000',
    },
    logo: {
      height: '60px',
      width: '60px',
      position: 'absolute',
      right: '35px',
      top: '45px',
    },
    mx5: {
      margin: '0 5px',
    },
    ml5: {
      marginLeft: '5px',
    },
  })
const useStyles = makeStyles(headerStyles)

interface CardInfoUsageHistoryProps {
  data?: UsageHistory
  isTotal?: boolean
  type: string
  logo?: string
  title?: string
}
const CardInfoUsageHistory = ({
  data,
  isTotal = false,
  type,
  logo,
  title,
}: CardInfoUsageHistoryProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const totalSuccess = useMemo(() => {
    if (type === 'PBM') return Math.floor((data?.successCount ?? 0) / MINUTE)
    return data?.successCount ?? 0
  }, [type, data?.successCount])

  const totalFail = useMemo(() => {
    if (type === 'PBM') return Math.floor((data?.failureCount ?? 0) / MINUTE)
    return data?.failureCount ?? 0
  }, [type, data?.failureCount])

  const renderColorTitle = () => {
    if (type === 'EEG') {
      return classes.EEG
    }
    if (type === 'HRV') {
      return classes.HRV
    }
    if (type === 'PBM') {
      return classes.PBM
    }
    return null
  }

  const displayTotalCount = () => {
    if (type === 'PBM') {
      const second = data ? data.totalCount : 0
      return Math.floor(second / MINUTE)
    }
    return data?.totalCount
  }

  return (
    <div className={`${classes.card} ${isTotal ? classes.cardTotal : ''} `}>
      {isTotal && (
        <img
          className={`${classes.logo} ${type === 'PBM' ? classes.logoPbm : ''}`}
          src={logo}
          alt='logo'
        />
      )}

      <div
        className={`${classes.title} ${renderColorTitle()} ${
          isTotal ? classes.fontSize20 : ''
        }`}
      >
        {data?.title ?? title}
      </div>

      <div
        className={`${classes.totalCount} ${isTotal ? classes.fontSize32 : ''}`}
      >
        {displayTotalCount()}
        <span className={`${classes.fontSize20} ${classes.ml5}`}>
          {type === 'PBM' ? t('IMins') : ''}
        </span>
      </div>
      {isTotal && <div className={classes.divider} />}
      <div className={`${isTotal ? classes.detail : classes.detailMonth}`}>
        <Tooltip title={t('ISuccess')} arrow>
          <span className={classes.success}>S</span>
        </Tooltip>
        <span className={classes.mx5}>{totalSuccess}</span>
        <span>|</span>
        <Tooltip title={t('IFailed')} arrow>
          <span className={`${classes.fail} ${classes.mx5}`}>F</span>
        </Tooltip>
        <span>{totalFail}</span>
      </div>
    </div>
  )
}
export default CardInfoUsageHistory
