import {makeStyles, Theme} from '@material-ui/core/styles'

const useHealthCenterStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  positionTopLet: {
    top: 0,
    left: 0,
  },
  positionTopRight: {
    top: 0,
    right: 0,
  },
  positionBotLeft: {
    bottom: 0,
    left: 0,
  },
  positionBotRight: {
    bottom: 0,
    right: 0,
  },
  connectBrainRegionPart3: {
    zIndex: 1,
    position: 'absolute',
    width: '50%',
  },
  topStatus: {
    display: 'flex',
    width: '60px',
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    background: '#B4B4B4',
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important', // 21.6px
    marginTop: '5px',
  },
  percentBarV2Top: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  topScoreIndex: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '5px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '32px',
    background: '#666',
    color: '#FFF',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%', // 12px
    letterSpacing: '-0.36px',
  },
  topScore: {
    display: 'flex',
    gap: 8,
  },
  topScoreDetail: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  topScorePosition: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important', // 21.6px
    letterSpacing: '-0.54px !important',
  },
  topScorePercent: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120% !important',
  },
  topScorePercentScore: {
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important',
  },
  percentBarV2Bot: {
    position: 'relative',
    width: '100%',
  },
  percentBarV2Arrow: {
    position: 'absolute',
    top: -15,
  },
  botBar: {
    borderRadius: '14px',
    border: '1px solid #CCC',
    background: '#DDD',
    height: '12px',
    alignSelf: 'stretch',
  },
  botStatus: {
    position: 'absolute',
    display: 'flex',
  },
  marker3: {
    position: 'absolute',
    top: 0,
    height: '100%',
    '& span': {
      display: 'block',
      width: 1,
      opacity: '50%',
      backgroundColor: '#EE3D3B',
      height: '100%',
      marginBottom: 8,
    },
    '& p': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120% !important',
      color: '#333',
      marginLeft: -15,
    },
    '& div': {
      height: 12,
      marginBottom: 8,
    },
  },
  dotPoint: {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    borderRadius: '50%',
  },
  cardBar: {
    width: '400px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px 30px 20px',
    borderRadius: '15px',
  },
  contentCardBar: {
    width: '280px',
    gap: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  lineTopLeft: {
    height: '127px',
    position: 'absolute',
    right: '0',
    marginRight: '57px',
    marginTop: '46px',
    borderBottom: 'none !important',
    borderLeft: 'none !important',
    borderTopRightRadius: '10px',
  },
  lineBotLeft: {
    height: '116px',
    position: 'absolute',
    right: '0',
    marginRight: '59px',
    marginTop: '-67px',
    borderLeft: 'none !important',
    borderTop: 'none !important',
    borderBottomRightRadius: '10px',
  },
  lineTopRight: {
    height: '127px',
    marginLeft: '78px',
    marginTop: '46px',
    borderBottom: 'none !important',
    borderRight: 'none !important',
    borderTopLeftRadius: '10px',
    position: 'absolute',
  },
  lineBotRight: {
    height: '116px',
    marginLeft: '80px',
    marginTop: '-68px',
    borderTop: 'none !important',
    borderRight: 'none !important',
    borderBottomLeftRadius: '10px',
    position: 'absolute',
  },
}))

export default useHealthCenterStyles
