import {createStyles} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {defaultFont, grayColor} from 'assets/tss/material-dashboard-pro-react'

const footerStyle = makeStyles((theme: Theme) =>
  createStyles({
    // @ts-ignore
    root: {
      bottom: 0,
      borderTop: `1px solid ${grayColor[15]}`,
      padding: '43px 80px',
      ...defaultFont,
      zIndex: 4,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 30,
      marginBottom: 45,
    },

    // info-container(left)
    infoContainer: {
      flex: 1,
      marginRight: 10,
      marginBottom: 10,
    },
    serviceLinks: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 100,

      '& .links': {
        display: 'flex',
        listStyle: 'none',
        color: '#5a6268',
        '& a': {
          '&:visited': {color: '#5a6268'},
        },
        '& li': {
          minWidth: 60,
        },
        '& .divider': {
          height: 16,
          borderRight: '1px solid #5a6268',
          margin: '3px 20px',
        },
      },
    },
    companyInfo: {
      marginTop: 10,
      fontSize: 10,
      maxWidth: 600,
      minWidth: 200,
      wordWrap: 'break-word',
      '& .desc': {
        display: 'block',
        color: '#868e96',
        lineHeight: 1.5,

        '& .divider': {
          margin: '0 8px',
        },
      },
    },
    // sites-container(right)
    sitesContainer: {
      display: 'flex',
      marginTop: 10,
      alignSelf: 'flex-start',
    },
    socialContainer: {
      display: 'flex',
      width: 173,
      alignItems: 'center',
    },
    socialButton: {
      padding: 5,
      width: '2rem',
      height: '2rem',
      lineHeight: '2rem',
      color: 'rgb(134, 142, 150)',
      marginRight: 15,
    },
    downIcon: {
      userSelect: 'none',
      pointerEvents: 'none',
    },
    relatedContainer: {
      alignSelf: 'center',
      marginRight: 34,
    },
    relatedTermsContainer: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: 'auto',
      },
    },
    relatedButton: {width: 135, fontWeight: 'normal', backgroundColor: 'white'},
    upIcon: {
      userSelect: 'none',
      pointerEvents: 'none',
      transform: 'rotate(180deg)',
    },
  }),
)
export default footerStyle
