import {useAppDispatch, useAppSelector} from 'hooks'
import {OrderSteps} from 'pages/OrgPage/Interfaces/Enums'
import {changeOrderStep, selectOrderStep} from './orgPurchaseSlice'

export default function useOrgPurchase() {
  const dispatch = useAppDispatch()

  const orderStep: OrderSteps = useAppSelector(selectOrderStep)
  const onChange = (str: OrderSteps) => dispatch(changeOrderStep(str))

  return {orderStep, onChange}
}
