import {Box, Grid, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useAuth from 'features/auth/useAuth'
import {fetchPointInfoAction, selectPointInfo} from 'features/point/pointSlice'
import {makeNextDate, makePrevDate, nowYearDate} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    indexInputContainer: {
      marginTop: 6,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: 'white',
      marginTop: 3,
    },
    pointBoxWrap: {
      marginTop: 10,
    },
    pointBox: {
      display: 'flex',
      flex: 1,
      minHeight: 111,
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 17,
      border: '1px solid #e9eaed',
      position: 'relative',
      borderRadius: '4px',
      backgroundColor: 'white',
    },
    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      fontSize: 30,
      fontWeight: 600,
      color: '#3EBBBD',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#3ebbbd',
      minHeight: 226,
      width: 527,
      minWidth: 200,
      borderRadius: 5,
      padding: '25px 38px',
    },
    realPoint: {
      display: 'flex',
      flexDirection: 'row',
      fontWeight: 600,
      color: 'white',
      alignItems: 'flex-end',
      gap: 10,
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'wrap',
      },
      '& .boxPoint': {
        fontSize: 40,
        wordBreak: 'break-word',
      },
      '& .pointText': {
        margin: '5px 0',
      },
    },
    pointChip: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      justifyContent: 'flex-end',
      '& .chipWrap': {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
      },
      '& .chipText': {
        padding: '0 5px',
        fontSize: 12,
        fontWeight: 800,
        color: '#3ebbbd',
      },
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
      border: '1px solid #dee2e6',
      background: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    itemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
    },
    itemProductWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
      backgroundColor: '#fbfbfb',
    },
    itemName: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 50,
      gap: 10,
      '& h6': {
        fontSize: 21,
        color: '#3cbbbd',
        textAlign: 'center',
      },
    },
    itemWonText: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 28,
      fontWeight: 500,
      alignContent: 'center',
      alignItems: 'center',
      color: '#59595a',
      marginTop: '8%',
      marginBottom: 40,
      '& h6': {
        fontSize: 15,
      },
    },
    itemProductText: {
      fontSize: 18,
      color: '#707070',
      textAlign: 'center',
      width: 125,
    },
    itemChartIcon: {
      fontSize: 55,
      color: '#707070',
    },
    carouse: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: 'solid 1px #eeeeed',
      padding: 15,
      height: 421,
      '& h5': {
        fontSize: 22,
        color: '#212121',
        textAlign: 'center',
        fontWeight: 500,
        marginTop: 20,
        marginBottom: 10,
      },
    },
    carouseContainer: {
      height: '100%',
      paddingLeft: 80,
      paddingRight: 80,
      paddingTop: 36,
      paddingBottom: 36,
      display: 'flex',
      flexDirection: 'row',
      gap: 15,
      backgroundColor: '#f8f8f8',
      '& div': {
        flex: 1,
      },
    },
    buyButton: {
      width: '100%',
      marginBottom: 30,
      marginTop: 10,
      height: 59,
      borderRadius: 7,
      fontSize: 18,
    },
    carouselWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    carouselItemWrapper: {
      width: '33%',
      height: '100%',
      paddingLeft: 100,
      paddingRight: 100,
      display: 'flex',
      flexDirection: 'row',
    },
    carouselItemWrapper2: {
      paddingLeft: 100,
      paddingRight: 100,
      display: 'flex',
      flexDirection: 'row',
    },
    hidden: {
      visibility: 'hidden',
    },
    buttonWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 5,
      height: 40,
    },
    infoButton: {
      border: '1px solid',
      borderColor: 'inherit',
    },
    infoTitle: {
      color: 'white',
      fontSize: 20,
    },
    outcircle: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
      width: 70,
      height: 70,
      borderRadius: 50,
    },
    incircle: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      backgroundColor: 'white',
      border: '3px solid #3ebbbd',
      width: 60,
      height: 60,
      borderRadius: 30,
    },
    circleText: {
      fontSize: 37,
      fontWeight: 800,
      color: '#3ebbbd',
    },
    circleWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    pointTextWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    calenderContainer: {
      backgroundColor: '#fbfbfb',
      border: '2px solid #f3f3f2',
      minHeight: 226,
      borderRadius: 5,
      padding: '25px 38px',
      '& h6': {
        color: '#494949',
      },
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    calenderText: {
      fontSize: 22,
      color: '#676a6c',
      fontWeight: 800,
    },
    pointDescriptionWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: '1px solid #f0f0f0',
      gap: 10,
      alignItems: 'center',
      padding: '19px 24px',
      '& h6': {
        color: '#212121',
        fontSize: 18,
      },
    },
    pointDescription: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& h5': {
        color: '#212121',
        fontWeight: 700,
        fontSize: 20,
      },
    },
  }),
)

interface Props {
  selectOid: string
}

function PointInfo({selectOid}: Props) {
  const pointInfo = useSelector(selectPointInfo)
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const {user: currentUser} = useAuth()
  const [currentPoint, setCurrentPoint] = useState('')

  const handlePrevClick = () => {
    dispatch(
      fetchPointInfoAction({
        uid: currentUser?.uid ?? '',
        oid: selectOid,
        month: makePrevDate(pointInfo?.month ?? ''),
      }),
    )
  }
  const handleNextClick = () => {
    dispatch(
      fetchPointInfoAction({
        uid: currentUser?.uid ?? '',
        oid: selectOid,
        month: makeNextDate(pointInfo?.month ?? ''),
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchPointInfoAction({
        uid: currentUser?.uid ?? '',
        oid: selectOid,
        month: nowYearDate(),
      }),
    )
  }, [])
  useEffect(() => {
    setCurrentPoint(pointInfo?.currentPoint.toLocaleString('ko-KR') ?? '-')
  }, [pointInfo])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 5,
            fontSize: 13,
            color: '#3ebbbd',
            padding: '3px 10px',
            border: '1px solid #3ebbbd',
          }}
        >
          <div>{t('IDiscountPoint', {point: pointInfo?.discount ?? '-'})}</div>
        </div>
      </div>
      <Typography className={classes.padding} />
      <Grid className={classes.pointContainer}>
        <Grid xs={12} md={4} className={classes.pointWrap}>
          <Grid xs={12} md={8}>
            <Typography className={classes.clientTitle}>
              <Box className={classes.infoTitle}>
                {t('IPointUserHas2', {name: pointInfo?.name ?? '-'})}
              </Box>
              <Box>{t('IPointCurrent')}</Box>
            </Typography>
          </Grid>
          <Grid xs={12} md={4} className={classes.circleWrap}>
            <span className={classes.outcircle}>
              <span className={classes.incircle}>
                <span className={classes.circleText}>P</span>
              </span>
            </span>
          </Grid>
          <Grid
            xs={12}
            alignContent='center'
            justifyContent='center'
            className={classes.pointTextWrap}
          >
            <Typography className={classes.realPoint} variant='h5'>
              <Box className='boxPoint'>{currentPoint} </Box>
              <Box className='pointText'> POINT</Box>
            </Typography>
          </Grid>
          <Grid
            xs={12}
            alignContent='center'
            justifyContent='center'
            className={classes.pointChip}
          >
            <Typography className='chipWrap' variant='h5'>
              <Box className='chipText'>
                {t('IFreePoint')}{' '}
                {pointInfo?.chargedPoint?.toLocaleString('ko-KR') ?? '-'} P
              </Box>
            </Typography>
            <Typography className='chipWrap' variant='h5'>
              <Box className='chipText'>
                {t('IPaidPoint')}{' '}
                {pointInfo?.noChargedPoint?.toLocaleString('ko-KR') ?? '-'} P
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <Grid item xs={12} md={8} className={classes.calenderContainer}>
          <div className={classes.titleWrap}>
            <Typography variant='h6'>{t('IPointInfoEachMonth')}</Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography className={classes.calenderText}>
                {pointInfo?.month ?? '-'}
              </Typography>
              <Typography className={classes.smallPadding} />
              <Grid className={classes.arrowWrap}>
                <ChevronLeftIcon
                  className={classes.arrow}
                  onClick={() => {
                    handlePrevClick()
                  }}
                />
                <ChevronRightIcon
                  className={classes.arrow}
                  onClick={() => {
                    handleNextClick()
                  }}
                />
              </Grid>
            </div>
          </div>
          <Typography className={classes.padding} />
          <Grid container className={classes.pointBoxWrap}>
            <Grid className={classes.pointBox}>
              <Typography className={classes.pointTitle}>
                {t('IPointUseAmountThisMonth')}
              </Typography>
              <Typography className={classes.padding} />
              <Typography className={classes.pointCont}>
                {pointInfo?.usePoint.toLocaleString('ko-KR') ?? '-'} POINT
              </Typography>
            </Grid>
            <Typography className={classes.smallPadding} />
            <Grid className={classes.pointBox}>
              <Typography className={classes.pointTitle}>
                {t('IPointBuyAmountThisMonth')}
              </Typography>
              <Typography className={classes.padding} />
              <Typography className={classes.pointCont}>
                {pointInfo?.buyPoint.toLocaleString('ko-KR') ?? '-'} POINT
              </Typography>
            </Grid>
            <Typography className={classes.smallPadding} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PointInfo
