import request from 'api/index'

export type GenderType = 'UNKNOWN' | 'MAN' | 'WOMAN'

export type HandType = 'LEFT' | 'RIGHT' | 'BOTH' | 'UNKNOWN'

export interface PatientListResponse {
  createAt: any
  id: string
  orgId: string
  customerNum: string
  email: string
  firstName: string
  lastName: string
  countryId: string
  tel: string
  birth: string
  gender: GenderType
  handType: HandType
  code?: string
}

interface PatientSearch {
  searchName?: PatientSearchKind | null
  searchValue?: string | null
}

export interface PatientSearchRequest
  extends PagingRequest,
    OptionalPeriodRequest {
  search: PatientSearch
}

export const searchPatientApi = ({
  search,
  paging,
  period,
}: PatientSearchRequest) =>
  request<PageResponse<Patient>>({
    method: 'get',
    path: '/api/patient/v1/list',
    params: {
      ...search,
      ...paging,
      ...period,
      searchValue: search.searchValue ? search.searchValue : undefined,
    },
  })

export interface PatientAddRequest {
  birth?: string
  chartNo?: string | null
  countryCode?: string
  docNo?: string
  gender?: string
  hand?: string
  firstName?: string
  lastName?: string
  phone?: string
  terms?: string
  email?: string
  registrationNumber?: string // TODO: May change when call api
  healthProductId: number | null
  visitNumber: string
}

export type PatientAddForm = Omit<PatientAddRequest, 'birth'> & {
  birth: Date
  email: string
}

export const addPatientApi = (data: PatientAddRequest) =>
  request<ModelResponse<Patient>>({
    method: 'post',
    path: '/api/patient/v3/create',
    data: {
      ...data,
      docNo: parseInt(data.docNo ?? '0', 10),
      terms: data.terms ?? '',
    },
  })

export const v2AddPatientApi = (data: PatientAddRequest) =>
  request<ModelResponse<Patient>>({
    method: 'post',
    path: '/api/patient/v2/create',
    data: {
      ...data,
      docNo: parseInt(data.docNo ?? '0', 10),
      terms: data.terms ?? '',
    },
  })

export interface PatientFetchRequest {
  uuid: string
}

export const fetchPatientApi = (params: PatientFetchRequest) =>
  request<ModelResponse<Patient>>({
    method: 'get',
    path: '/api/patient/v2/detail',
    params,
  })

export interface FetchPatientActivityRequest extends PatientFetchRequest {}

export type ActivityType =
  | 'REGISTRATION'
  | 'EEG'
  | 'EEG_NORM'
  | 'EEG_STANDARD_SUMMARY'
  | 'HRV'
  | 'HRV_NORM'
  | 'HRV_STANDARD_SUMMARY'
  | 'MCI'
  | 'THERAPY'

interface ActivityInfoItem {
  analysisId: number
  date: string
  fileName: string
  type: ActivityType
}

export interface ActivityInfo {
  eeg: ActivityInfoItem | null
  eegNorm: ActivityInfoItem | null
  eegSummary: ActivityInfoItem | null
  hrv: ActivityInfoItem | null
  hrvNorm: ActivityInfoItem | null
  hrvSummary: ActivityInfoItem | null
  mci: ActivityInfoItem | null
  therapy: ActivityInfoItem | null
  registration: string
}

export const fetchPatientActivityInfoApi = (uuid: string) =>
  request<ModelResponse<ActivityInfo>>({
    method: 'get',
    path: '/api/patient/v1/activity',
    params: {uuid},
  })

export type ActivitySearchCondition =
  | ActivitySearchNumber
  | ActivitySearchFileName
  | ActivitySearchDate
  | ActivitySearchPROCESS
  | ActivitySearchName

export type KeyOfActivitySearchCondition = ActivitySearchCondition['searchName']

type ActivitySearchNumber = {searchName: 'NUMBER'; searchValue?: number}
type ActivitySearchFileName = {searchName: 'FILE_NAME'; searchValue?: string}
type ActivitySearchDate = {searchName: 'DATE'; searchValue?: string}
type ActivitySearchPROCESS = {searchName: 'PROCESS'; searchValue?: string}
type ActivitySearchName = {searchName: 'NAME'; searchValue?: string}

export interface SearchActivityPagingRequest extends PagingRequest {
  search: ActivitySearchCondition
  uuid: string
  type: string
}
export type ActivityTypeCondition = SelectItem<string> & {checked: boolean}

/**
 * WARNING:절대 배열 자체를 수정하는 함수를 사용하지 말아주세요
 */
export const ActivityTypeConditions: ActivityTypeCondition[] = [
  {label: 'EEG', value: 'EEG', checked: true},
  {label: 'EEG_NORM', value: 'EEG_NORM', checked: true},
  {label: 'EEG_STANDARD_SUMMARY', value: 'EEG_STANDARD_SUMMARY', checked: true},
  {label: 'HRV', value: 'HRV', checked: true},
  {label: 'HRV_STANDARD_SUMMARY', value: 'HRV_STANDARD_SUMMARY', checked: true},
  {label: 'MCI', value: 'MCI', checked: true},
  {label: 'Therapy', value: 'THERAPY', checked: true},
]

export interface ActivityItem {
  id: number
  analysisId: number
  analysisType:
    | 'HRV'
    | 'HRV_STANDARD_SUMMARY'
    | 'EEG'
    | 'EEG_STANDARD_SUMMARY'
    | 'EEG_NORM'
    | 'MCI'
  fileName: string
  process: 'PROCESSING' | 'ERROR' | 'READY' | 'SUCCESS'
  treatmentDate: string
  treatmentNumber: number
}

export const searchPatientActivityListApi = ({
  search,
  paging,
  ...rest
}: SearchActivityPagingRequest) =>
  request<PageResponse<ActivityItem>>({
    method: 'get',
    path: '/api/patient/v1/activity/list',
    params: {
      ...search,
      ...paging,
      ...rest,
    },
  })

export interface PatientEditRequest {
  birth: string
  chartNo: string
  countryCode: string
  docNo: string
  gender: string
  hand: string
  firstName: string
  lastName: string
  phone: string
  terms: string
  uuid: string
  email?: string | null
  healthProductId: number | null
}

export const editPatientApi = (data: PatientEditRequest) =>
  request<ModelResponse<Patient>>({
    method: 'post',
    path: '/api/patient/v2/modify',
    data: {
      ...data,
      docNo: parseInt(data.docNo, 10),
    },
  })

export const createPatientChartNoApi = (uid: string) =>
  request<ModelResponse<string>>({
    method: 'get',
    path: '/api/patient/v1/chart-no/create',
    params: {uid},
  })

export interface PatientDeleteRequest {
  // uid: string
  uuid: string
}

export const deletePatientApi = (data: PatientDeleteRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/patient/v1/delete/uuid',
    data: {
      ...data,
    },
  })

export interface PatientVisitNumber {
  birth: string
  chartNo: string
  gender: string
  latestDate: string
  name: string
  phone: string
  primaryId: number
  primaryName: string
  uuid: string
  hand: string
  comment?: CommentModel
  firstName: string
  lastName: string
  countryCode: Country
  email?: string | null
  primaryFirstName?: string
  primaryLastName?: string
  primary_name?: string
  healthProductIds: number[]
  visitNumber?: string
}

export const doubleCheckPatientChartNoApi = (
  chartNo: string | null | undefined,
) =>
  request<ModelResponse<PatientVisitNumber>>({
    method: 'get',
    path: '/api/patient/v1/chart-no/duplication-check',
    params: {
      chartNo,
    },
  })

export interface VisitNumberPatient {
  healthProductId: number
  id: number
  visitNumber: string
}

export interface CreateVisitNumberPatientRequest {
  birth: string
  gender: string
  healthProductId: number
  uuid: string
  visitNumber: string
  docNo: number
}

export const createVisitNumberPatientApi = (
  data: CreateVisitNumberPatientRequest,
) =>
  request<ModelResponse<VisitNumberPatient>>({
    method: 'post',
    path: '/api/patient/v3/patient/visit-number',
    data,
  })
