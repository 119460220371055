import {Header} from 'lib/EdfParser/Edf'
import MontageFilter, {
  MontageFilterImpl,
  MontageFilterType,
} from 'lib/EdfGraph/MontageFilter'

interface EDFResultData {
  header: Header | null
  physicalData: any[]
}

export default class Graphs {
  SECONDS_PER_CANVAS: number

  NUMBER_OF_SAMPLES: number

  AMPLITUDE_FACTOR: number

  REMAINDER: number

  SET_OF_INDICES: number[]

  MONTAGE_FILTER: MontageFilterImpl

  constructor() {
    this.SECONDS_PER_CANVAS = 30 // 30 seconds per canvas
    this.NUMBER_OF_SAMPLES = 250
    this.REMAINDER = 0
    this.AMPLITUDE_FACTOR = 1
    this.SET_OF_INDICES = []
    this.MONTAGE_FILTER = MontageFilter.filterOfType(MontageFilterType.NONE)
  }

  findRemainder(totalseconds: number) {
    const rem = totalseconds % this.SECONDS_PER_CANVAS
    return rem
  }

  getIndices(totalseconds: number) {
    const remainder = this.findRemainder(totalseconds)
    this.REMAINDER = remainder
    const quotient = Math.floor(totalseconds / this.SECONDS_PER_CANVAS)
    const arrSize = remainder > 0 ? quotient + 1 : quotient
    const indexOfSets = []
    let indexInterval = 0
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrSize; i++) {
      indexOfSets.push(indexInterval)
      indexInterval += this.SECONDS_PER_CANVAS
    }
    return indexOfSets
  }

  /* eslint-disable no-plusplus */
  generateGraphData(edfData: EDFResultData) {
    if (!edfData?.header?.numberOfDataRecords) {
      return
    }

    const sets = this.getIndices(edfData?.header?.numberOfDataRecords)
    this.SET_OF_INDICES = sets

    let tempVetices: number[] = []

    const tt: number[][] = []

    let tempParentVetices: number[][] = []
    const arrOfTPV: any[] = sets.map(() => tt)

    let lastYValuePerSecond = 0
    let increment250 = 0

    let numberOfIteration = this.SECONDS_PER_CANVAS
    for (let set = 0; set < sets.length; set++) {
      if (set === sets.length - 1 && this.REMAINDER > 0) {
        numberOfIteration = this.REMAINDER
      }

      for (let i = 1; i <= 19; i++) {
        for (let y = 0; y < numberOfIteration; y++) {
          for (let x = 0; x < this.NUMBER_OF_SAMPLES; x += 1) {
            // if the count of samples increases more than 250 then exit the loop
            if (increment250 >= this.NUMBER_OF_SAMPLES) {
              break
            }
            lastYValuePerSecond =
              edfData.physicalData[i - 1][y + sets[set]][increment250]

            tempVetices.push(lastYValuePerSecond)
            increment250 += 1
          }
          increment250 = 0
          tempParentVetices.push(tempVetices)
          tempVetices = []
        }
        arrOfTPV[set] = tempParentVetices
      }
      tempParentVetices = []
    }

    const filtered: number[][][] = []
    for (let i = 0; i < arrOfTPV.length; i++) {
      const arr: number[][] = []
      // 초단위 (19 * 초)
      for (let j = 0; j < arrOfTPV[i].length; j++) {
        // 채널당 초단위 : x,y,z
        const idx = Math.floor(
          j /
            (i === arrOfTPV.length - 1 && this.REMAINDER > 0
              ? this.REMAINDER
              : this.SECONDS_PER_CANVAS),
        )
        if (arr[idx] === undefined) {
          arr.push([])
        }
        for (let k = 0; k < arrOfTPV[i][j].length; k += 1) {
          arr[idx].push(arrOfTPV[i][j][k])
        }
      }
      filtered.push(this.MONTAGE_FILTER.filtering(arr))
    }

    const finalArr: number[][][] = []
    for (let i = 0; i < filtered.length; i++) {
      // 19 => 570 이 되어야 함
      const canvasArr: number[][] = []
      for (let j = 0; j < filtered[i].length; j++) {
        // 7500 => 750이 되어야 함 (250 * 3)
        const chCount = filtered[i].length + 1
        const cf = 2 / chCount
        const channelArr: number[] = []

        for (let k = 0; k < filtered[i][j].length; k++) {
          const x = k / (filtered[i][j].length / 2) - 1
          const dy =
            1 +
            (filtered[i][j][k] / 10000) *
              this.AMPLITUDE_FACTOR *
              (chCount / 2) -
            j * cf -
            cf
          channelArr.push(x, dy, 0)
        }
        canvasArr.push(channelArr)
      }
      finalArr.push(canvasArr)
    }
    // eslint-disable-next-line consistent-return
    return finalArr
  }

  setAmplitudeFactor(amplitudeFactor: number) {
    this.AMPLITUDE_FACTOR = amplitudeFactor
  }

  setMontageFilter(type: MontageFilterType) {
    this.MONTAGE_FILTER = MontageFilter.filterOfType(type)
  }
}
