import React, {MouseEventHandler, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {executeHrvSummaryAnalysisApi, SearchedHRV} from 'api/analysisApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import TableReportSearch from 'components/Table/TableReportSearch'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import {getHrvIndividualReportPath} from 'constants/RouteConstant'
import useHrv from 'features/analysis/useHrv'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import {statusToI18nString} from 'helpers/analysisHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {getQueryFromUrl} from 'helpers/commonHelper'
import openInNewTab from 'helpers/openInNewTab'

interface TableToolBarProps {
  selectedItems: SearchedHRV[]
}

function TableToolbar({selectedItems}: TableToolBarProps) {
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const {query, search, onSearch} = useHrv()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())

  const summaryRequest = async () => {
    // TODO: 등급에 따라 STANDARD | PREMIUM 설정하기
    const response = await executeHrvSummaryAnalysisApi({
      individualRequestIds: selectedItems.map((item) => item.id),
      type: 'HRV_STANDARD_SUMMARY',
    })

    if (response.isError) {
      onFailureModalOpen(response.error)
    } else {
      onSuccessModalOpen(t('IProcessSuccess'))
    }
  }

  const handleSummaryClick = async () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) summaryRequest()
    })
  }

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      search: {searchName: 'NAME', searchValue: ''},
      period: dateToPeriodString(selection),
      paging: {
        page: 0,
        size: 10,
      },
      ...initQuery,
    })
  }, [])

  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.HRVIndividual}
        isRoot={false}
        query={query}
        search={search}
        onSearch={onSearch}
      />

      <div className={classes.containerOptional}>
        <ActionButton onClick={handleSummaryClick}>
          {t('ISummaryRun')}
        </ActionButton>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedHRV
  onClick: (item: SearchedHRV) => void
  isSelected: (item: SearchedHRV) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()

  const handleResultClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    const clientName = t('IGetFullName', {
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
    }).trim()
    openInNewTab(getHrvIndividualReportPath(item.id, clientName))
  }

  const labelId = `enhanced-table-checkbox-${item.id}`

  const checked = isSelected(item)

  return (
    <TableRow hover onClick={() => onClick(item)}>
      <TableCell padding='checkbox'>
        <Checkbox checked={checked} inputProps={{'aria-labelledby': labelId}} />
      </TableCell>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(String(item.createdAt))}
      </TableCell>
      <TableCell align='center'>{item.normdbVersion || '-'}</TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.progress))}
      </TableCell>
      <TableCell align='center'>
        <TableButton
          onClick={handleResultClick}
          disabled={item.progress !== 'SUCCESS'}
        >
          {t('IRead')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

interface PageTableProps {
  selectedOld: SearchedHRV[]
  setSelectedOld: (data: SearchedHRV[], items: SearchedHRV[]) => void
}

function PageTable(props: PageTableProps) {
  const {selectedOld, setSelectedOld} = props
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} = useHrv()
  const [selected, setSelected] = useState<SearchedHRV[]>([])
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const selectedId = useMemo(() => selected.map((item) => item.id), [selected])

  const setPageIndex = (page: number) => {
    setSelectedOld(selected, items)
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })
  }

  const onSizeChanged = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter((item) => item.progress === 'SUCCESS'))
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedHRV) => {
    if (item.progress !== 'SUCCESS') {
      onFailureModalOpen(t('IAnalysisUnselectable'))
      return
    }

    const selectedIndex = selectedId.indexOf(item.id)
    let newSelected: SearchedHRV[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedHRV) =>
    selectedId.indexOf(item.id) !== -1

  const successItems = items.filter((item) => item.progress === 'SUCCESS')

  const emptyRows = items === null ? 10 : 10 - items.length

  useEffect(() => {
    const itemIds = items.map((item) => item.id)
    const selectedOldNew = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedOld.length; i++) {
      if (itemIds.indexOf(selectedOld[i].id) !== -1) {
        selectedOldNew.push(selectedOld[i])
      }
    }
    setSelected(selectedOldNew)
  }, [selectedOld, items])

  return (
    <TableContainer>
      <TableToolbar selectedItems={selected} />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < successItems.length
                }
                checked={
                  successItems.length > 0 &&
                  selected.length === successItems.length
                }
                onChange={handleSelectAllClick}
                inputProps={{'aria-label': 'select all desserts'}}
              />
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IEEGIndividualIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IVersion')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisReport')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow
              key={item.id}
              item={item}
              onClick={handleRowClick}
              isSelected={isRowSelected}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChanged}
      />
    </TableContainer>
  )
}

export default PageTable
