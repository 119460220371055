import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import {fileDownload} from 'api'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import {
  fetchUsedPointListAction,
  selectedOid,
  selectPoint,
  selectPointQuery,
} from 'features/point/pointSlice'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import {getDateForFile, getDefaultPeriodString} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    navContainer: {
      // margin: 15,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',

      '& div ': {
        // flex: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      },
    },
    searchButton: {
      height: 56,
      marginTop: 15,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    analBox: {
      width: 200,
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      justifyContent: 'space-between',
      border: '1px solid rgb(53 73 99 / 20%)',
      alignItems: 'center',
      borderRadius: '4px',
      marginBottom: 10,
    },
    pointTitle: {
      fontSize: '12px',
    },
    pointCont: {
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
      minHeight: 400,
      //   alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
      // alignItems: 'center',
    },
    realPoint: {
      flexDirection: 'column',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    arrowWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    arrow: {
      width: 30,
      height: 30,
      color: 'rgb(53 73 99 / 20%)',
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      // justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    toolbarWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: 5,
      flexWrap: 'wrap',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    pointSearchContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
    buttonWrap: {
      height: 56,
    },
  }),
)

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      height: 50,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}
const headCells: HeadCell[] = [
  {id: 'id', label: 'IOrderNo'},
  {id: 'paidDate', label: 'IOrderDate'},
  {id: '', label: 'IProductName'},
  {id: 'content', label: 'IAnalysisNo'},
  {id: 'content', label: 'IOrderOrgName'},
  {id: 'content', label: 'IPurchaseType'},
  {id: 'content', label: 'IPurchaseAmount'},
  {id: 'content', label: 'IRemark'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
function PointHistoryTable() {
  const classes = useTableStyles()
  const dispatch = useAppDispatch()

  const {user: currentUser} = useAuth()
  const pointList = useSelector(selectPoint)
  const pointQuery = useSelector(selectPointQuery)
  const oid = useSelector(selectedOid)

  const setPageIndex = (page: number) => {
    dispatch(
      fetchUsedPointListAction({
        ...pointQuery,
        page,
        size: 5,
        uid: currentUser?.uid ?? null,
        oid,
      }),
    )
  }

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='medium'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {pointList.pointList != null &&
            pointList.pointList.map((id: any) => {
              return (
                <TableRow hover classes={{hover: classes.hover}}>
                  <TableCell align='center'>{id.id ?? '-'}</TableCell>
                  <TableCell align='center'>{id.paidDate ?? '-'}</TableCell>
                  <TableCell align='center'>{id.product ?? '-'}</TableCell>
                  <TableCell align='center'>{id.analysisId ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {id.name ?? '-'} / {id.userName ?? '-'}
                  </TableCell>
                  <TableCell align='center'>{id.pointType ?? '-'}</TableCell>
                  <TableCell align='center'>{id.point ?? '-'}</TableCell>
                  <TableCell align='center'>{id.remark ?? '-'}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pointList.totalPages}
        currentPageIndex={pointList.pageIndex ?? 0}
        itemCountPerPage={pointList.itemPerPage ?? 5}
        setCurrentPageIndex={setPageIndex}
        loading={pointList.loading}
        onItemCountPerPageChanged={() => {}}
        isItemCountPerPageEnabled={false}
      />
    </TableContainer>
  )
}

type PointSearchedType =
  | 'ORDER_NO'
  | 'DATE'
  | 'PRODUCT'
  | 'ANALYSIS_ID'
  | 'NAME'
  | 'ORG'
  | 'TYPE'

export default function SalesPointHistory() {
  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {t} = useTranslation()
  const {user: currentUser} = useAuth()
  const dispatch = useAppDispatch()
  const selectedOid = useSelector(selectAllOrgOid)
  const pointList = useSelector(selectPoint)
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [searchNameValue, setSearchNameValue] = React.useState('')
  const [searchedValue, setSearchedValue] = React.useState('')
  const searchedList = [
    'IOrderNo',
    'IOrderDate',
    'IProductName',
    'IAnalysisNumber',
    'IName',
    'IPurchaseType',
  ]

  const searchedListMap = searchedList.map((value, index) => (
    <MenuItem value={index}>{t(value)}</MenuItem>
  ))

  const [searchedSelectedType, setSearchedSelectedType] =
    React.useState<PointSearchedType>()

  const handleOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchNameValue(event.target.value as string)
    switch (event.target.value) {
      case 0:
        setSearchedSelectedType('ORDER_NO')
        break
      case 1:
        setSearchedSelectedType('DATE')
        break
      case 2:
        setSearchedSelectedType('PRODUCT')
        break
      case 3:
        setSearchedSelectedType('ANALYSIS_ID')
        break
      case 4:
        setSearchedSelectedType('NAME')
        break
      case 5:
        setSearchedSelectedType('ORG')
        break
      case 6:
        setSearchedSelectedType('TYPE')
        break
      default:
        setSearchedSelectedType('PRODUCT')
    }
  }

  const [selection, setSelection] = React.useState<PeriodString>(
    getDefaultPeriodString(),
  )

  const handleDateValue = (value: any) => setSelection(value)
  const handleValue = (value: any) => setSearchedValue(value)
  const handleDateSearch = () => {
    dispatch(
      fetchUsedPointListAction({
        page: 0,
        size: 5,
        uid: currentUser?.uid ?? '',
        oid: selectedOid ?? null,
        period: selection,
      }),
    )
  }

  const excelDownload = async () => {
    if (pointList.pointList?.length !== 0) {
      await fileDownload({
        path: '/api/point/v1/download/excel',
        params: {
          oid: selectedOid ?? null,
        },
        fileName: getDateForFile(),
      })
    }
    if (pointList.pointList?.length === 0)
      onFailureModalOpen(t('IPaymentEmpty'))
  }

  const handleSearch = () => {
    dispatch(
      fetchUsedPointListAction({
        page: 0,
        size: 5,
        uid: currentUser?.uid ?? '',
        oid: selectedOid ?? null,
        searchName: searchedSelectedType,
        searchValue: searchedValue,
      }),
    )
  }
  useEffect(() => {
    handleDateSearch()
  }, [selection])

  return (
    <CardWithTitle title={t('IPointHistory')}>
      <Grid className={classes.pointContainer}>
        <Grid item xs={12}>
          <Grid className={classes.toolbarContainer}>
            <div className={classes.toolbarWrap}>
              <div className={classes.dataContainer}>
                <RangeDatePicker
                  onChangeValue={handleDateValue}
                  dateValue={{
                    startDate: new Date(selection.startDate),
                    endDate: new Date(selection.endDate),
                  }}
                />
              </div>
              <div className={classes.pointSearchContainer}>
                <FormControl variant='outlined'>
                  <Select
                    value={searchNameValue}
                    onChange={handleOnchange}
                    displayEmpty
                    color='primary'
                    input={
                      <OutlinedInput
                        name='age'
                        id='outlined-age-simple'
                        classes={outlinedInputClasses}
                      />
                    }
                  >
                    <MenuItem disabled value=''>
                      <Typography className={classes.disableText}>
                        {t('ISelect')}
                      </Typography>
                    </MenuItem>
                    {searchedListMap}
                  </Select>
                </FormControl>

                <SearchBar
                  onChangeValue={handleValue}
                  onSearchEvent={handleSearch}
                  disabledValue={false}
                />
              </div>
            </div>
            <Typography className={classes.padding} />
            <ActionButton
              variant='contained'
              color='primary'
              startIcon={<GetAppIcon />}
              disableElevation
              onClick={() => {
                excelDownload()
              }}
            >
              {t('IExcelDownload')}
            </ActionButton>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <PointHistoryTable />
      </Grid>
    </CardWithTitle>
  )
}
