import {CurrencyCode} from 'api/paymentApi'
import {numberWithCommas} from 'helpers/commonHelper'

export interface UnpaidVouchers {
  id: number
  packageName: string
  countOfPeriod: number
  totalPeriod: number
  price: number
  dueDate: Date
  currencyCode: string
  // getDueDateYearMonth: () => string
  getPriceWithCurrencyCode: () => string
}

export default class UnpaidVouchersModel implements UnpaidVouchers {
  constructor(raw: UnpaidVouchersModel) {
    this.id = raw.id
    this.packageName = raw.packageName
    this.countOfPeriod = raw.countOfPeriod
    this.totalPeriod = raw.totalPeriod
    this.price = raw.price
    this.dueDate = new Date(raw.dueDate)
    this.currencyCode =
      CurrencyCode[raw.currencyCode as string as keyof typeof CurrencyCode]
  }

  id: number

  packageName: string

  countOfPeriod: number

  totalPeriod: number

  price: number

  dueDate: Date

  currencyCode: string

  // getDueDateYearMonth: () => string = () => {
  //   return this.dueDate.split('').slice(0, 7).join('')
  // }

  getPriceWithCurrencyCode: () => string = () => {
    return `${this.currencyCode} ${numberWithCommas(this.price)}`
  }
}
