import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {SearchedHealthCare} from 'api/healthCareApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import PeriodSearch from 'components/molcules/PeriodSearch'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useHealthCare from 'features/healthCare/useHealthCare'
import useFailureModal from 'features/modal/useFailureModal'
import * as storage from 'api/storageApi'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDateOneDay,
} from 'helpers/dateHelper'
import HealthCareSearchComponent from 'pages/HealthCenter/PageTable/HealthCareSearch'
import DownloadSearch from 'pages/HealthCenter/PageTable/DownloadSearch'
import {isOrganizationHealth} from 'Authority'

interface TableToolBarProps {
  selectedItems: SearchedHealthCare[]
  statusDownload: boolean
  onDownload: (hcIds: number[]) => void
  onSendEmail: (selectedItems: SearchedHealthCare[]) => void
}

function TableToolbar({
  selectedItems = [],
  statusDownload,
  onDownload,
  onSendEmail,
}: TableToolBarProps) {
  const location = useLocation()
  const {t} = useTranslation()
  const organizationType = storage.getOrganizationType()
  const isHealthOrg = isOrganizationHealth(organizationType)

  const classes = useToolbarStyles()

  const {query, onSearch} = useHealthCare()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const [selection, setSelection] = useState<PeriodDate>(
    getDefaultPeriodDateOneDay(),
  )

  const handleSearch = (search: any) => {
    return onSearch({...query, ...search, page: 0})
  }

  const handlePeriod = (period: PeriodDate) => {
    const formatPeriod = dateToPeriodString(period)
    if (
      formatPeriod.startDate === query.startDate &&
      formatPeriod.endDate === query.endDate
    )
      return
    onSearch({
      ...query,
      page: 0,
      ...dateToPeriodString(period),
    } as any)
  }

  const downloadFile = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(blobUrl)
      })
      .catch((error) => console.error('Error downloading file:', error))
  }

  const handleDownload = (
    pathPDF: string,
    name: string,
    birth: string,
    analysisNo: string | number,
  ) => {
    if (!pathPDF) return
    const filename = `${name}_${birth.split('-').join('')}_${analysisNo}.pdf`
    const path = `${pathPDF}?t=${Date.now()}`
    downloadFile(path, filename)
  }
  const handleClick = (type: string) => {
    if (!selectedItems) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    selectedItems.forEach((item) => {
      const fullNamePatient = t('IGetFullName', {
        firstName: item.patientFirstName ?? '',
        lastName: item.patientLastName ?? '',
      })
        .split(' ')
        .join('_')
      handleDownload(
        item.reportUrl,
        fullNamePatient,
        item.patientBirth,
        item.hcId,
      )
    })
    if (type === 'sendEmail') {
      onSendEmail(selectedItems)
    }
  }
  const handleSendEmailClick = () => {
    handleClick('sendEmail')
  }
  const handleDownloadClick = () => {
    if (!selectedItems) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    selectedItems.forEach((item) => {
      const fullNamePatient = t('IGetFullName', {
        firstName: item.patientFirstName ?? '',
        lastName: item.patientLastName ?? '',
      })
        .split(' ')
        .join('_')
      handleDownload(
        item.reportUrl,
        fullNamePatient,
        item.patientBirth,
        item.hcId,
      )
    })
    // onFailureModalOpen(t('IErrorForbidden'))
    onDownload(selectedItems?.map((item) => item.hcId) || [])
  }

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    setSelection({
      startDate: new Date(initQuery.startDate),
      endDate: new Date(initQuery.endDate),
    })
    onSearch({
      ...query,
      ...dateToPeriodString(selection),
      patientName: '',
      patientChartNo: '',
      birth: '',
      downloaded: '',
      ...initQuery,
    })
  }, [])

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.container}>
          <PeriodSearch period={selection} onSearch={handlePeriod} />
          <DownloadSearch onSearch={handleSearch} />
          <HealthCareSearchComponent onSearch={handleSearch} />
        </div>
        <div className={classes.containerOptional}>
          {isHealthOrg && (
            <ActionButton
              onClick={handleSendEmailClick}
              className={classes.flexRight}
              disabled={
                selectedItems.length === 0 ||
                selectedItems.every((item) => {
                  return item.hcStatus === 'ERROR'
                }) ||
                statusDownload
              }
            >
              {t('ISendMail')}
            </ActionButton>
          )}

          <ActionButton
            onClick={handleDownloadClick}
            className={classes.flexRight}
            disabled={
              selectedItems.length === 0 ||
              selectedItems.every((item) => {
                return item.hcStatus === 'ERROR'
              }) ||
              statusDownload
            }
          >
            {t('IDownload')}
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

export default TableToolbar
