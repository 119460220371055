import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  ActivityItem,
  ActivitySearchCondition,
  SearchActivityPagingRequest,
  searchPatientActivityListApi,
} from 'api/patientApi'
import {RootState} from 'store'

interface PatientActivityState {
  uuid: string | null
  error: string | null
  items: ActivityItem[] | null
  loading: boolean
  searchType: string
  search: ActivitySearchCondition
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

export const initialState: PatientActivityState = {
  uuid: null,
  error: null,
  items: null,
  loading: false,
  searchType: 'ALL',
  search: {
    searchName: 'FILE_NAME',
    searchValue: '',
  },
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

export const searchPatientActivityListAction = createAsyncThunk(
  'patient/ActivityList/search',
  async (payload: SearchActivityPagingRequest, {rejectWithValue}) => {
    try {
      const response = await searchPatientActivityListApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message ?? '')
    }
  },
)
export const patientActivitySlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchPatientActivityListAction.pending, (state, action) => {
        state.loading = true
        state.error = null
        const {paging, search, type, uuid} = action.meta.arg
        state.uuid = uuid
        state.paging = paging
        state.search = search
        state.searchType = type
      })
      .addCase(searchPatientActivityListAction.fulfilled, (state, action) => {
        state.loading = false
        state.items = action.payload.content
        state.paging.page = action.payload.pageable.pageNumber
        state.paging.size = action.payload.pageable.pageSize
        state.pagingInfo.totalElements = action.payload.totalElements
        state.pagingInfo.totalPages = action.payload.totalPages
      })
      .addCase(searchPatientActivityListAction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message ?? ''
      })
  },
})

export const selectLoading = (state: RootState) => state.patientActivity.loading
export const selectError = (state: RootState) => state.patientActivity.error
export const selectItems = (state: RootState) => state.patientActivity.items
export const selectSearch = (state: RootState) => state.patientActivity.search
export const selectPagingInfo = (state: RootState) =>
  state.patientActivity.pagingInfo
export const selectPaging = (state: RootState) => state.patientActivity.paging
export const selectQuery = (state: RootState): SearchActivityPagingRequest => ({
  uuid: state.patientActivity.uuid ?? '',
  search: state.patientActivity.search,
  paging: state.patientActivity.paging,
  type: state.patientActivity.searchType,
})

export default patientActivitySlice.reducer
