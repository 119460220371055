import {
  MontageChannelLabels,
  MontageFilterType,
} from 'lib/EdfGraph/MontageFilter'
import {DropDownStructure} from 'components/molcules/Report/RawData/RawDataStyles'
import {GraphLineColor} from 'lib/WEBGL/WEBGLContextManipulation/WEBGLContextManipulation'
import LeftNumeric from 'assets/img/LeftNumeric.jpeg'

export interface GraphLabel {
  label: string
}
interface EegIndividualTimesRejectedResponse {
  label: string //  "ASR",
  time_section: number[] // [976, 1151]
}

export enum WaveGraphPropertySourceType {
  Raw,
  Cleaned,
  Component,
}

export interface WaveGraphPropertySourceImpl {
  getChannelLabels: () => GraphLabel[]
  changeLabelIndex: (index: MontageFilterType) => void
  filterOptions: () => DropDownStructure[]
  optionForType: (type: MontageFilterType) => DropDownStructure
  isDisplayOptions: () => boolean
  hasTimeRejections: () => boolean
  timeRejections: () => EegIndividualTimesRejectedResponse[]
  graphColor: () => GraphLineColor

  // for legacy
  xAxisImage: string
  yAxisImage: string
}

abstract class WaveGraphPropertySource implements WaveGraphPropertySourceImpl {
  abstract readonly xAxisImage: string

  abstract readonly yAxisImage: string

  protected channelLabels: GraphLabel[]

  protected filterTypes: DropDownStructure[]

  protected constructor(
    channelLabels: GraphLabel[],
    filterTypes: DropDownStructure[],
  ) {
    this.channelLabels = channelLabels
    this.filterTypes = filterTypes
  }

  filterOptions = () => {
    return this.filterTypes.slice()
  }

  changeLabelIndex = (index: MontageFilterType) => {
    this.channelLabels = MontageChannelLabels[index]
  }

  getChannelLabels = (): GraphLabel[] => {
    return this.channelLabels.slice()
  }

  optionForType = (type: MontageFilterType) => {
    const options = this.filterOptions()
    return options.find((it) => it.optionValue === type) ?? options[0]
  }

  isDisplayOptions = () => {
    return this.filterOptions().length > 1
  }

  hasTimeRejections = (): boolean => false

  timeRejections = (): EegIndividualTimesRejectedResponse[] => {
    return []
  }

  graphColor = (): GraphLineColor => {
    return GraphLineColor.RawData
  }
}

export class WaveGraphPropertySourceComponent extends WaveGraphPropertySource {
  xAxisImage = ''

  yAxisImage = ''

  constructor() {
    super(MontageChannelLabels[MontageFilterType.Component], [
      {optionTitle: 'Components', optionValue: MontageFilterType.Component},
    ])
  }
}

export class WaveGraphPropertySourceRawData extends WaveGraphPropertySource {
  xAxisImage = ''

  yAxisImage = LeftNumeric as string

  private timesRejectedData: EegIndividualTimesRejectedResponse[]

  constructor(timesRejectedData: EegIndividualTimesRejectedResponse[]) {
    super(MontageChannelLabels[MontageFilterType.NONE], [
      {optionTitle: 'Ear Reference', optionValue: MontageFilterType.NONE},
      {optionTitle: 'Common Average', optionValue: MontageFilterType.CAR},
      {optionTitle: 'Longitudinal', optionValue: MontageFilterType.BANANA},
      {optionTitle: 'Transverse', optionValue: MontageFilterType.TRANSVERSE},
    ])

    this.timesRejectedData = timesRejectedData
  }

  hasTimeRejections = () => {
    return this.timesRejectedData.length !== 0
  }

  timeRejections = () => {
    return this.timesRejectedData.slice()
  }
}

export class WaveGraphPropertySourceCleanedData extends WaveGraphPropertySource {
  xAxisImage = ''

  yAxisImage = ''

  constructor() {
    super(MontageChannelLabels[MontageFilterType.CAR], [
      {optionTitle: 'Common Average', optionValue: MontageFilterType.CAR},
      {optionTitle: 'Longitudinal', optionValue: MontageFilterType.BANANA},
      {optionTitle: 'Transverse', optionValue: MontageFilterType.TRANSVERSE},
    ])
  }

  graphColor = (): GraphLineColor => {
    return GraphLineColor.CleanedData
  }
}

class WaveGraphPropertyFactory {
  static sourceOfType(
    type: WaveGraphPropertySourceType,
    timesRejectedData?: EegIndividualTimesRejectedResponse[],
  ) {
    if (type === WaveGraphPropertySourceType.Component) {
      return new WaveGraphPropertySourceComponent()
    }
    if (type === WaveGraphPropertySourceType.Raw) {
      return new WaveGraphPropertySourceRawData(timesRejectedData ?? [])
    }
    if (type === WaveGraphPropertySourceType.Cleaned) {
      return new WaveGraphPropertySourceCleanedData()
    }

    return new WaveGraphPropertySourceComponent()
  }
}

export default WaveGraphPropertyFactory
