import React, {useState, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Checkbox} from '@material-ui/core'
import RegisterCardWithTitle from 'components/atoms/RegisterCardWithTitle'
import {
  CheckboxContent,
  ErrorText,
  PolicyCheckboxWrap,
  PolicyContent,
} from '../../style'

const PolicyLanguage: Record<string, Record<string, string>> = {
  ko: {
    privacyUrl:
      'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_ko.html',
    serviceUrl:
      'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_ko.html',
  },
  en: {
    privacyUrl:
      'https://isyncme.s3.ap-northeast-2.amazonaws.com/privacy/v1/privacy_en.html',
    serviceUrl:
      'https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_en.html',
  },
}

const Policy = () => {
  const [policy, setPolicy] = useState({
    privacyUrl: '',
    serviceUrl: '',
  })

  const {
    formState: {errors},
    register,
  } = useFormContext()

  const {t, i18n} = useTranslation()

  useEffect(() => {
    const {language} = i18n
    const matchedLanguagePolicy = PolicyLanguage[language] || PolicyLanguage.en

    setPolicy((prev) => ({...prev, ...matchedLanguagePolicy}))
  }, [i18n.language])
  return (
    <>
      <RegisterCardWithTitle title={t('IRegisterPrivacyAgree')}>
        <PolicyContent
          src={policy.privacyUrl}
          title={t('IRegisterPrivacyAgree')}
        />
        <PolicyCheckboxWrap>
          <Checkbox
            id='check'
            color='primary'
            disableRipple
            inputProps={{'aria-label': 'decorative checkbox'}}
            {...register('isAgreePrivacy')}
          />
          <CheckboxContent>{t('IRegisterPrivacyAgreeNotice')}</CheckboxContent>
        </PolicyCheckboxWrap>
        {errors.isAgreePrivacy && (
          <ErrorText>{errors.isAgreePrivacy.message}</ErrorText>
        )}
      </RegisterCardWithTitle>
      <RegisterCardWithTitle title={t('IRegisterServiceAgree')}>
        <PolicyContent
          src={policy.serviceUrl}
          title={t('IRegisterServiceAgree')}
        />
        <PolicyCheckboxWrap>
          <Checkbox
            id='check'
            color='primary'
            disableRipple
            inputProps={{'aria-label': 'decorative checkbox'}}
            {...register('isAgreeTerms')}
          />
          <CheckboxContent>{t('IRegisterServiceAgreeNotice')}</CheckboxContent>
        </PolicyCheckboxWrap>
        {errors.isAgreeTerms && (
          <ErrorText>{errors.isAgreeTerms.message}</ErrorText>
        )}
      </RegisterCardWithTitle>
    </>
  )
}

export default Policy
