import React, {useEffect} from 'react'
import styled from 'styled-components'
import checkboxOn from 'assets/img/checkbox_on.svg'
import checkboxOff from 'assets/img/checkbox_off.svg'
import checkboxDisabled from 'assets/img/checkbox_disabled.svg'

interface CircleCheckboxProps {
  checked?: boolean
  onClick?: () => void
  disabled?: boolean
}

const StyledCheckboxWrap = styled.div`
  margin: 0 auto;
  width: 32px;
  height: 32px;
  position: relative;

  transition: background 300ms ease-out;
  background: url(${checkboxOff}) no-repeat center / cover;

  &.active {
    background: url(${checkboxOn}) no-repeat center / cover;
  }

  &.disabled {
    background: url(${checkboxDisabled}) no-repeat center / cover;
  }
`
const StyledCheckbox = styled.input.attrs({type: 'checkbox'})`
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;

  &:disabled {
    cursor: auto;
  }
`

function CircleCheckbox({checked, onClick, disabled}: CircleCheckboxProps) {
  // const [isChecked, setIsChecked] = React.useState<boolean>(checked)
  const handleCheckbox = () => {
    if (onClick !== undefined) onClick()
  }

  const [className, setClassName] = React.useState<string>('')

  // checked 바뀔 때 className 설정
  useEffect(() => {
    if (checked)
      setClassName((prev) => {
        let newData = prev

        if (newData.includes('disabled')) newData += ' active'
        else newData = 'active'
        return newData
      })
    else
      setClassName((prev) => {
        let newData = prev
        if (newData.includes('disabled')) newData = 'disabled'
        else newData = ''
        return newData
      })
  }, [checked])

  // disable 바뀔 때 className 설정
  useEffect(() => {
    if (disabled)
      setClassName((prev) => {
        let newData = prev

        if (newData.includes('active')) newData += ' disabled'
        else newData = 'disabled'
        return newData
      })
    else
      setClassName((prev) => {
        let newData = prev
        if (newData.includes('active')) newData = 'active'
        else newData = ''
        return newData
      })
  }, [disabled])

  return (
    <StyledCheckboxWrap className={className}>
      <StyledCheckbox
        onChange={handleCheckbox}
        checked={checked}
        disabled={disabled}
      />
    </StyledCheckboxWrap>
  )
}

export default CircleCheckbox
