import {TableBody} from '@material-ui/core'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RoundTableHeader from 'components/Table/RoundTableHeader'
import {RoundTableWrap} from 'components/Table/RoundTableBodyStyle'
import {numberWithCommas} from 'helpers/commonHelper'
import SubTitle from 'components/Text/SubTitle'
import {openPackagePaymentDialog} from 'features/modal/modalSlice'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PackageTable,
  TableOverflowWrap,
} from 'components/Table/CommonTableStyle'
import styled from 'styled-components'
import {
  PurchaseBundle,
  PurchasedProducts,
  PurchasePackage,
  UnPaidVoucherContainer,
} from 'api/paymentApi'
import useOrgPurchase from 'features/org/useOrgPurchase'
import {SvgIcon} from 'components/common/useSvgIcons'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useViewModel, {
  DuplicatedItemsViewModel,
} from '../ViewModels/ProductsCheckViewModel'
import {OrderSteps} from '../../Interfaces/Enums'
import {
  StyledGrayButtonBig,
  StyledPrimaryButtonBig,
  IconBgWrap,
  StyledContentContainer,
  StyledContentWrap,
  StyledFullLine,
  StyledTextGroup,
  StyledTextBox,
  StyledKeyText,
  StyledValueText,
} from '../Style'
import ProductsInfos from '../ProductsInfos'
import IncludedProductsDuplicate from '../IncludedProductsDuplicate'
import CircleCheckbox from '../CircleCheckbox'
import OrderResponseModel from '../Models/OrderResponseModel'

interface TableRowsProps extends TableRowCommonProps {
  purchased: PurchasedProducts
  refunds: number[]
  handleRefund: (order: number) => void
  purchasingIncludedProducts: string[]
}

interface TableRowHasProductProps extends TableRowCommonProps {
  purchasedIncludedProducts: string[]
}

interface TableRowCommonProps {
  bundles: PurchaseBundle[]
  packages: PurchasePackage[]
}

interface ProductsCheckProps {
  handler: (order: OrderResponseModel | null) => void
  packages: PurchasePackage[]
  bundles: PurchaseBundle[]
  purchased?: PurchasedProducts
  overdue?: UnPaidVoucherContainer
}

export const NoticeText = styled.span`
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
`

const StyledTableTitle = styled.span`
  display: inline-block;
  color: #4b4b4b;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 13px;
`

const ContentContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

interface HeadCell {
  id: string
  label: string
}

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
  }

  & + div {
    margin-top: 50px;
  }
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  width: 100%;
`

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 28px;
  padding: 70px 20px;
`

const ContentGroup = styled.div`
  width: 100%;
  overflow: hidden;

  &:last-child {
    border-top: 2px solid #444b52;
  }
`

const InvalidText = styled.span`
  color: #ee5454;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

const StyledTextBoxGrid = styled(StyledTextBox)`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;
  column-gap: 10px;

  & > span:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    text-align: center;
  }

  & > span:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    text-align: center;
  }

  & > span:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: inline-flex;
    align-items: center;
  }
`
// //////////////////////////////////////////////////////////

const PossessivePackageHeadCells: HeadCell[] = [
  {id: 'refund', label: 'IRefund'},
  {id: 'type', label: 'ITypeInPymtMngmt'},
  {id: 'division', label: 'IProductName'},
  {id: 'serviceTypes', label: 'IIncludedProducts'},
  {id: 'remainingPeriod', label: 'IRemainingBillingCycle'},
  {id: 'remainingNumber', label: 'IRemainingAmount'},
  {id: 'endDate', label: 'IExpirationAt'},
]

const PurchasingPackageHeadCells: HeadCell[] = [
  {id: 'type', label: 'ITypeInPymtMngmt'},
  {id: 'division', label: 'IProductName'},
  {id: 'serviceTypes', label: 'IIncludedProducts'},
  {id: 'purchaseTerms', label: 'IPurchaseCondition'},
  {id: 'cycle', label: 'IPaymentBillingCycle'},
  {id: 'period', label: 'IPaymentPeriod'},
  {id: 'point', label: 'ICount'},
  {id: 'serviceContent', label: 'IPrice'},
]

function PossessivePackageTableRows({
  purchased,
  refunds,
  handleRefund,
  purchasingIncludedProducts,
}: TableRowsProps) {
  const {t} = useTranslation()

  return (
    <>
      {purchased.packages.map((item, idx) => {
        return (
          <RoundTableWrap key={idx}>
            <td>
              <CircleCheckbox
                checked={refunds.includes(idx)}
                onClick={() => handleRefund(idx)}
              />
            </td>
            <td>{t('IPackage')}</td>
            <td>{item.name}</td>
            <td>
              {/* TODO: hasPdf는 가짜 데이터로 추후 api 변경 후 변경 필요 item.guide ? 같은게 있을 때만 호버 및 이벤트 추가 */}
              <ProductsInfos
                description='QEEG with Norm DB coparision Source level analysis (sLORETA)
                  Basic Summary Report_ 8 Lobe Health Score'
                hasPdf
                pdfUrl='https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko-2.html'
              >
                <IncludedProductsDuplicate
                  analyses={item.analyses}
                  compareIncludedProducts={purchasingIncludedProducts}
                />
              </ProductsInfos>
              {/* <LineBreakBox>
                {item.analyses.map((product, pIdx) => {
                  const productText = getPackageFullName(product.code)
                  let el = <span key={pIdx}>{t(productText)}</span>

                  purchasingIncludedProducts.find((compare) => {
                    if (compare === product.code) {
                      el = (
                        <DuplicateProduct key={pIdx}>
                          {t(productText)}
                        </DuplicateProduct>
                      )
                    }
                    return null
                  })
                  return el
                })}
              </LineBreakBox> */}
            </td>
            <td>
              {item.getRemainDate()} / {item.getGivenDate()}
            </td>
            <td>
              {item.getRemainAmount()} / {item.getGivenAmount()}
            </td>
            <td>{item.expiresAt}</td>
          </RoundTableWrap>
        )
      })}
      {purchased.bundles.map((item, idx) => {
        return (
          <RoundTableWrap key={idx}>
            <td>
              <CircleCheckbox disabled />
            </td>
            <td>{t('IBundle')}</td>
            <td>{item.name}</td>
            <td>
              <ProductsInfos
                description='QEEG with Norm DB coparision Source level analysis (sLORETA)
                  Basic Summary Report_ 8 Lobe Health Score'
                hasPdf
                pdfUrl='https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko-2.html'
              >
                <IncludedProductsDuplicate
                  analyses={item.analyses}
                  compareIncludedProducts={purchasingIncludedProducts}
                />
              </ProductsInfos>
              {/* <LineBreakBox>
                {item.analyses.map((product, pIdx) => {
                  const productText = getPackageFullName(product.code)
                  let el = <span key={pIdx}>{t(productText)}</span>

                  purchasingIncludedProducts.find((compare) => {
                    if (compare === product.code) {
                      el = (
                        <DuplicateProduct key={pIdx}>
                          {t(productText)}
                        </DuplicateProduct>
                      )
                    }
                    return null
                  })
                  return el
                })}
              </LineBreakBox> */}
            </td>
            <td>-</td>
            <td>
              {item.getRemainAmount()} / {item.getGivenAmount()}
            </td>
            <td>-</td>
          </RoundTableWrap>
        )
      })}
    </>
  )
}

function PossessivePackageTable({
  bundles,
  packages,
  purchased,
  refunds,
  handleRefund,
  purchasingIncludedProducts,
}: TableRowsProps) {
  const {t} = useTranslation()

  return (
    <TableOverflowWrap>
      <PackageTable>
        <colgroup>
          <col style={{minWidth: 60}} />
          <col style={{minWidth: 80}} />
          <col style={{minWidth: 150}} />
          <col style={{minWidth: 180}} />
          <col style={{minWidth: 150}} />
          <col style={{minWidth: 100}} />
          <col style={{minWidth: 100}} />
        </colgroup>
        <thead>
          <tr>
            {PossessivePackageHeadCells.map((item, index) => (
              <RoundTableHeader
                label={t(item.label)}
                length={PossessivePackageHeadCells.length}
                index={index}
                key={index}
              />
            ))}
          </tr>
        </thead>
        {purchased && (
          <TableBody>
            <PossessivePackageTableRows
              bundles={bundles}
              packages={packages}
              purchased={purchased}
              handleRefund={handleRefund}
              refunds={refunds}
              purchasingIncludedProducts={purchasingIncludedProducts}
            />
          </TableBody>
        )}
      </PackageTable>

      {(!purchased ||
        (!purchased.bundles && !purchased.packages) ||
        (purchased.bundles.length <= 0 && purchased.packages.length <= 0)) && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 200,
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          {t('INoData')}
        </div>
      )}
    </TableOverflowWrap>
  )
}

function PurchasingPackageTableRows({
  bundles,
  packages,
  purchasedIncludedProducts,
}: TableRowHasProductProps) {
  const {t} = useTranslation()

  return (
    <>
      {packages.map((item, idx) => (
        <RoundTableWrap key={idx}>
          <td>{t('IPackage')}</td>
          <td>{item.name}</td>
          <td>
            <ProductsInfos
              description='QEEG with Norm DB coparision Source level analysis (sLORETA)
                  Basic Summary Report_ 8 Lobe Health Score'
              hasPdf
              pdfUrl='https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko-2.html'
            >
              <IncludedProductsDuplicate
                analyses={item.analyses}
                compareIncludedProducts={purchasedIncludedProducts}
                packages={packages}
                bundles={bundles}
                rowIdx={idx}
                purchasingToPurchasing
              />
            </ProductsInfos>
          </td>
          <td>-</td>
          <td>
            {t(item.billingCycle === 1 ? 'INMonth' : 'INMonths', {
              number: item.billingCycle,
            })}
          </td>
          <td>
            {t('IRepetition', {
              number: item.period,
            })}
          </td>
          <td>{item.amount ?? t('IUnlimited')}</td>
          <td>{`${item.currencyCode} ${numberWithCommas(item.price)}`}</td>
        </RoundTableWrap>
      ))}
      {bundles.map((item, idx) => (
        <RoundTableWrap key={idx}>
          <td>{t('IBundle')}</td>
          <td>{item.name}</td>
          <td>
            <ProductsInfos
              description='QEEG with Norm DB coparision Source level analysis (sLORETA)
                  Basic Summary Report_ 8 Lobe Health Score'
              hasPdf
              pdfUrl='https://isyncme.s3.ap-northeast-2.amazonaws.com/policy/v1/policy_ko-2.html'
            >
              <IncludedProductsDuplicate
                analyses={item.analyses}
                compareIncludedProducts={purchasedIncludedProducts}
                packages={packages}
                bundles={bundles}
                rowIdx={packages.length + idx}
                purchasingToPurchasing
              />
            </ProductsInfos>
          </td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>{item.unitPurchase}</td>
          <td>{`${item.currency} ${numberWithCommas(item.price)}`}</td>
        </RoundTableWrap>
      ))}
    </>
  )
}

function PurchasingPackageTable({
  bundles,
  packages,
  purchasedIncludedProducts,
}: TableRowHasProductProps) {
  const {t} = useTranslation()

  return (
    <TableOverflowWrap>
      <PackageTable>
        <colgroup>
          <col style={{minWidth: 80}} />
          <col style={{minWidth: 150}} />
          <col style={{minWidth: 180}} />
          <col style={{minWidth: 150}} />
          <col style={{minWidth: 100}} />
          <col style={{minWidth: 100}} />
          <col style={{minWidth: 100}} />
          <col style={{minWidth: 130}} />
        </colgroup>
        <thead>
          <tr>
            {PurchasingPackageHeadCells.map((item, index) => (
              <RoundTableHeader
                label={t(item.label)}
                length={PurchasingPackageHeadCells.length}
                index={index}
                key={index}
              />
            ))}
          </tr>
        </thead>
        {(bundles || packages) && (
          <TableBody>
            <PurchasingPackageTableRows
              bundles={bundles}
              packages={packages}
              purchasedIncludedProducts={purchasedIncludedProducts}
            />
          </TableBody>
        )}
      </PackageTable>
      {(!packages && !bundles) ||
        (packages.length === 0 && bundles.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            {t('INoData')}
          </div>
        ))}
    </TableOverflowWrap>
  )
}

const ProductsCheckPage = ({
  handler,
  packages,
  bundles,
  purchased,
  overdue,
}: ProductsCheckProps) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {onChange: setOrderStep} = useOrgPurchase()

  const handleOpen = () => dispatch(openPackagePaymentDialog())

  // CYM: isExpensive: 최종 결제 금액이 마이너스 일 때 노출되는 Invalid Text를 위한 temp data
  // submit button의 disabled도 이걸로 체크
  const [isExpensive, setIsExpensive] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const {
    setInitialPrice,
    refundPrice,
    refunds,
    setPackages,
    setBundles,
    setInitialProducts,
    setPurchased,
    setOverdue,
    handleRefund,
    requestOrder,
    price,
  } = useViewModel()

  // 중복상품 아이템 뷰모델
  const {
    purchasingAnalysesCode,
    purchasedAnalysesCode,
    getDuplicated,
    duplicated,
  } = DuplicatedItemsViewModel()

  // 보유 상품의 포함 상품(SET으로 중복 제거)
  const [purchasedIncludedProducts, setPurchasedIncludedProducts] =
    React.useState<string[]>([])

  // 구매할 상품의 포함 상품(SET으로 중복 제거)
  const [purchasingIncludedProducts, setPurchasingIncludedProducts] =
    React.useState<string[]>([])

  const handleGoBack = () => {
    setInitialPrice()
    setOrderStep(OrderSteps.ProductsSelect)
  }

  // will purchase
  setPackages(packages)
  setBundles(bundles)
  if (purchased) setPurchased(purchased)
  if (overdue) setOverdue(overdue)

  useEffect(() => {
    if (purchased && overdue && isLoading) setIsLoading(false)
  }, [purchased, overdue])

  // isExpensive set
  useEffect(() => {
    if (price - refundPrice < 0) {
      setIsExpensive(true)
    } else {
      setIsExpensive(false)
    }
  }, [price, refundPrice])

  const submitPurchase = async () => {
    if (duplicated) {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: `${t('INoticeDuplicateProducts')}`,
      }).then(async (result) => {
        if (result.payload) {
          console.log('??????')
          handleOpen()

          const o = await requestOrder()
          handler(o)
        }
      })
    } else {
      // alert('on pay')
      handleOpen()

      const o = await requestOrder()
      handler(o)
      // // child component open
      // const child = (await window.open(
      //   getPaymentsPath(),
      //   'payment',
      //   'width=700, height=800',
      // )) as any
      //
      // const purchaseWindow = (await window) as any
      //
      // // 결제에 필요한 모델 형태의 데이터 모두 전달해야함, 임시로 객체형태로만 전달
      // // 자식 컴포넌트의 setOrder에 전달
      //
      // purchaseWindow.values = () => {
      //   return {packages, bundles, purchased, overdue}
      // }
    }
  }

  let currency = ''
  if (packages.length > 0) {
    currency = packages[0].currencyCode
  } else if (bundles.length > 0) {
    currency = bundles[0].currency
  }

  useEffect(() => {
    if (refundPrice > price) {
      setIsExpensive(true)
    } else {
      setIsExpensive(false)
    }
  }, [refundPrice])

  useEffect(() => {
    setInitialProducts(packages, bundles)

    // 보유 상품의 포함 상품
    const includedProducts = purchasingAnalysesCode(packages, bundles)
    setPurchasingIncludedProducts(includedProducts)
  }, [packages, bundles])

  // 구매할 상품의 포함 상품
  useEffect(() => {
    if (purchased) {
      const includedProducts = purchasedAnalysesCode(purchased, refunds)
      setPurchasedIncludedProducts(includedProducts)
    }
  }, [refunds])

  // 중복 상품 하나라도 있으면 결제 버튼 클릭 시 알람 팝업
  useEffect(() => {
    if (purchased) {
      // 결제된 것까지 보내기
      getDuplicated(packages, bundles, refunds, purchased)
    } else {
      // 결제하려는 것만
      getDuplicated(packages, bundles, refunds)
    }
  }, [packages, bundles, refunds])

  return (
    <CardWithTitle>
      <ContentContainer>
        <ContentWrap>
          <ContentHeader>
            {/* <TableTitle>{t('IPackageSelectedDialogTitle')}</TableTitle> */}
            <SubTitle>{t('IProductsCheckTitle')}</SubTitle>
          </ContentHeader>
          <ContentBody>
            {!isLoading && purchased && (
              <>
                <ContentGroup>
                  <StyledTableTitle>
                    {t('IProductsInPossession')}
                  </StyledTableTitle>
                  <PossessivePackageTable
                    bundles={bundles}
                    packages={packages}
                    purchased={purchased}
                    handleRefund={handleRefund}
                    refunds={refunds}
                    purchasingIncludedProducts={purchasingIncludedProducts}
                  />
                </ContentGroup>
                <ContentGroup>
                  <StyledTableTitle>{t('IProductsToBuy')}</StyledTableTitle>
                  <PurchasingPackageTable
                    bundles={bundles}
                    packages={packages}
                    purchasedIncludedProducts={purchasedIncludedProducts}
                  />
                </ContentGroup>
                <ContentGroup>
                  <StyledContentContainer>
                    <StyledContentWrap>
                      <StyledTextGroup>
                        <StyledTextBox>
                          <StyledKeyText>
                            {t('IProductsToBuyPrice')}
                          </StyledKeyText>
                          <StyledValueText>
                            {`${currency} ${numberWithCommas(price)}`}
                          </StyledValueText>
                        </StyledTextBox>
                        <IconBgWrap>
                          <SvgIcon iconColor='#444B52' name='minusFill' />
                        </IconBgWrap>
                        <StyledTextBoxGrid>
                          <StyledKeyText>
                            {t('IProductsRefundPrice')}
                          </StyledKeyText>
                          <StyledValueText>
                            {`${currency} ${numberWithCommas(refundPrice)}`}
                          </StyledValueText>
                          {/* isExpensive : 최종 결제 금액이 마이너스 일 때 노출되는 Invalid Text를 위한 temp data */}
                          {isExpensive ? (
                            <>
                              <StyledValueText>
                                <InvalidText>
                                  {t('INoticeCanNotBuy')}
                                </InvalidText>
                              </StyledValueText>
                            </>
                          ) : null}
                        </StyledTextBoxGrid>
                      </StyledTextGroup>
                      <StyledFullLine />
                      <StyledTextGroup>
                        <IconBgWrap>
                          <SvgIcon iconColor='#444B52' name='equalFill' />
                        </IconBgWrap>
                        <StyledTextBox>
                          <StyledKeyText>{t('IFinalPrice')}</StyledKeyText>
                          <StyledValueText>
                            {`${currency} ${numberWithCommas(
                              price - refundPrice,
                            )}`}
                          </StyledValueText>
                        </StyledTextBox>
                      </StyledTextGroup>
                    </StyledContentWrap>
                  </StyledContentContainer>
                </ContentGroup>
              </>
            )}
          </ContentBody>
          <ContentFooter>
            <StyledGrayButtonBig onClick={handleGoBack}>
              {t('IGoBack')}
            </StyledGrayButtonBig>
            <StyledPrimaryButtonBig
              onClick={submitPurchase}
              disabled={isExpensive}
            >
              {t('IDoPurchase')}
            </StyledPrimaryButtonBig>
          </ContentFooter>
        </ContentWrap>
      </ContentContainer>
    </CardWithTitle>
  )
}

export default ProductsCheckPage
