import Card from 'components/atoms/Card'
import Dialog from '@material-ui/core/Dialog'
import {
  closeSalesNoticeEditDialog,
  selectSalesNoticeEditDialogOpen,
} from 'features/modal/modalSlice'
import {
  EmergencyNotifyItemsFile,
  EmergencyNotifyRequest,
  EmergencyNotifyResponseFileString,
} from 'api/salesNoticeApi'
import {
  editSalesEmergencyNoticeAction,
  fetchEmergencyNotice,
} from 'features/sales/salesNoticeSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  dateToDashString,
  fromIsoDateOrStringToLocalDate,
  isoStringToDateAndTimeString,
  localStringToUtcString,
  makeDateAndTimeFormat,
  startDateCompareEndDate,
  toUtcDate,
} from 'helpers/dateHelper'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import {
  InputTitle,
  NoticeAttachedFileDelete,
  NoticeAttachedFileLabel,
  NoticeAttachedFileView,
  NoticeContentWrapper,
  NoticeInputContentText,
  NoticeInputTitleText,
  NoticeTimeInputText,
  NoticeTitle,
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioButtonText,
  TitleLine,
} from 'pages/SalesPages/SalesAppNoticePage/StyledPostDialog'
import CalenderSinglePicker from 'components/Calender/CalenderSinglePicker'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import {useTranslation} from 'react-i18next'
import i18n from 'i18n'

interface SalesNoticeEditDialogProps {
  isSelected?: boolean
  clickIndexDataNumber: number
}

export default function SalesNoticeEditDialog(
  props: SalesNoticeEditDialogProps,
) {
  const {clickIndexDataNumber, isSelected} = props
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const [startTimeSelect, setStartTimeSelect] = React.useState<string>('')
  const [endTimeSelect, setEndTimeSelect] = React.useState<string>('')
  const [beginDate, setBeginDate] = React.useState<Date>()
  const [beginDateString, setBeginDateString] = React.useState<string>()

  const [onBeginDate, setOnBeginDate] = React.useState<Date>()
  const [beginDatePick, setBeginDatePick] = React.useState<boolean>(true)

  const [beginHour, setBeginHour] = React.useState<string>()
  const [beginMin, setBeginMin] = React.useState<string>()
  const [endDate, setEndDate] = React.useState<Date>()
  const [endDateString, setEndDateString] = React.useState<string>()
  const [endHour, setEndHour] = React.useState<string>()
  const [endMin, setEndMin] = React.useState<string>()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  // 이미지 파일 등록시 저장하는 변수
  const [krFileSelected, setKrFileSelected] = React.useState<File>()
  const [enFileSelected, setEnFileSelected] = React.useState<File>()

  const [titleKr, setTitleKr] = React.useState<string>()
  const [contentKr, setContentKr] = React.useState<string>()
  const [titleEn, setTitelEn] = React.useState<string>()
  const [contentEn, setContentEn] = React.useState<string>()

  // 파일 삭제 유무 보내기 위한 boolean
  const [deleteFileKr, setDeleteFileKr] = React.useState<boolean>(false)
  const [deleteFileEn, setDeleteFileEn] = React.useState<boolean>(false)

  const [submitCheck, setSubmitCheck] = React.useState<boolean>(true)

  // 다이얼로그 랜딩 시 데이터에 존재하던 파일명 받아오는 변수
  const [krFileName, setKrFileName] = React.useState<string>('')
  const [enFileName, setEnFileName] = React.useState<string>('')

  const salesNoticeDialogOpen = useAppSelector(selectSalesNoticeEditDialogOpen)

  const [emergencyNotifyItems, setEmergencyNoticesItems] = React.useState<
    EmergencyNotifyItemsFile[]
  >([
    {
      id: 0,
      order: 0,
      isNotProceed: false,
      authorName: '',
      begin: '',
      end: '',
      contents: [
        {
          languageCode: '',
          title: '',
          content: '',
          attachments: null,
        },
        {
          languageCode: '',
          title: '',
          content: '',
          attachments: null,
        },
      ],
    },
  ])

  const handleBeginDateChanged = (date?: Date | null) => {
    if (date) {
      setBeginDatePick(true)
      setOnBeginDate(date)
      setBeginDateString(dateToDashString(date))
    }
  }

  const handleEndDateChanged = (date?: Date | null) => {
    if (date) {
      setEndDateString(dateToDashString(date))
    }
  }

  const checkSelectManualTime = (value: string) => {
    return value === t('IAppNoticeTimeManualEntry')
  }

  const handleDuplicate = (hour: string, min: string): string => {
    return `${hour}:${min}`
  }

  const handleClose = () => {
    dispatch(closeSalesNoticeEditDialog())
  }
  const handleTimeNumberSet = (time: string): string => {
    const timeString = time.substring(0, 1)

    if (Number(time) < 10 && timeString !== '0') {
      return `0${time}`
    }
    return time
  }

  const onSubmit = () => {
    if (Number(beginHour) > 23 || Number(endHour) > 23) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }
    if (Number(beginMin) > 59 || Number(endMin) > 59) {
      return onFailureModalOpen(t('ICorrectTimeRequired'))
    }

    const contentData: EmergencyNotifyRequest = {
      isNotProceed: false,
      begin: null,
      end: null,
      contents: JSON.stringify([
        {
          languageCode: 'EN',
          title: titleEn,
          content: contentEn,
          deleteImage: deleteFileEn,
        },
        {
          languageCode: 'KO',
          title: titleKr,
          content: contentKr,
          deleteImage: deleteFileKr,
        },
      ]),
    }

    if (!checkSelectManualTime(startTimeSelect)) {
      const timeNow = toUtcDate(new Date())

      contentData.begin = makeDateAndTimeFormat(timeNow)
    }

    if (krFileSelected) {
      contentData.KO_attachments = krFileSelected
    }
    if (enFileSelected) {
      contentData.EN_attachments = enFileSelected
    }
    if (beginHour && beginMin && beginDate) {
      const beginString = `${beginDateString} ${handleDuplicate(
        handleTimeNumberSet(beginHour),
        handleTimeNumberSet(beginMin),
      )}`

      contentData.begin = localStringToUtcString(beginString)
    }

    if (endHour && endMin && endDate) {
      const endString = `${endDateString} ${handleDuplicate(
        handleTimeNumberSet(endHour),
        handleTimeNumberSet(endMin),
      )}`

      contentData.end = localStringToUtcString(endString)
    }

    if (
      (checkSelectManualTime(startTimeSelect) && !beginHour) ||
      (checkSelectManualTime(startTimeSelect) && !beginMin)
    ) {
      const beginString = `${beginDateString} ${handleDuplicate('00', '00')}`

      contentData.begin = localStringToUtcString(beginString)
    }
    if (
      (checkSelectManualTime(endTimeSelect) && !endHour) ||
      (checkSelectManualTime(endTimeSelect) && !endMin)
    ) {
      const endString = `${endDateString} ${handleDuplicate('23', '59')}`

      contentData.end = localStringToUtcString(endString)
    }

    if (contentData.begin && contentData.end) {
      if (
        !startDateCompareEndDate(
          fromIsoDateOrStringToLocalDate(contentData.begin),
          fromIsoDateOrStringToLocalDate(contentData.end),
        )
      ) {
        return onFailureModalOpen(t('IStartTimeEndTimeComparisonMessage'))
      }
    }

    dispatch(
      editSalesEmergencyNoticeAction({
        data: contentData,
        query: {notifyId: clickIndexDataNumber},
      }),
    )
    dispatch(fetchEmergencyNotice({page: 1, size: 100}))
    return handleClose()
  }

  function handleInputKrFile(e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files
    if (!fileList) return
    const reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/

    if (!fileList[0].name.match(reg)) {
      onFailureModalOpen(t('IInvalidImageFileRequest'))
      return
    }

    setKrFileSelected(fileList[0])
    setKrFileName('')
  }

  function handleInputEnFile(e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files
    if (!fileList) return
    const reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/

    if (!fileList[0].name.match(reg)) {
      onFailureModalOpen(t('IInvalidImageFileRequest'))
      return
    }

    setEnFileSelected(fileList[0])
  }

  const hadleStartSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStartTimeSelect(event.target.value)
  }

  const handleEndSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEndTimeSelect(event.target.value)
  }

  const HadleAttachedKrFileDelete = () => {
    setKrFileSelected(undefined)
  }

  const HadleAttachedEnFileDelete = () => {
    setEnFileSelected(undefined)
  }
  const selectTimeNow = (value: string): boolean => {
    return value === t('IAppNoticeTimeNow')
  }

  const selectTimeEnd = (value: string): boolean => {
    return value === t('IAppNoticeTimeAutoNone')
  }

  const getEmergencyResponse = async () => {
    const result = await dispatch(fetchEmergencyNotice({page: 1, size: 100}))
    const data = result.payload as EmergencyNotifyResponseFileString
    return data
  }

  const parseDate = (date: EmergencyNotifyItemsFile[]) => {
    if (date && date[0].begin) {
      setStartTimeSelect(t('IAppNoticeTimeManualEntry'))

      const beginUTCtoLocal = isoStringToDateAndTimeString(date[0].begin)

      const beginParse = beginUTCtoLocal.slice(-8, -3)
      const parsingResult = beginParse.split(':')

      // const beginParseCalendar = date[0].begin.slice(0, 10)
      const calendarToDate = fromIsoDateOrStringToLocalDate(beginUTCtoLocal)

      setBeginHour(parsingResult[0])
      setBeginMin(parsingResult[1])
      setOnBeginDate(calendarToDate)
      setBeginDate(calendarToDate)
    }
    if (date && date[0].end) {
      setEndTimeSelect(t('IAppNoticeTimeManualEntry'))

      const endUTCtoLocal = isoStringToDateAndTimeString(date[0].end)

      const endParse = endUTCtoLocal.slice(-8, -3)
      const parsingResult = endParse.split(':')

      // const endParseCalendar = date[0].end.slice(0, 10)
      const calendarToDate = fromIsoDateOrStringToLocalDate(endUTCtoLocal)

      setEndHour(parsingResult[0])
      setEndMin(parsingResult[1])
      setEndDate(calendarToDate)
    }
  }
  useEffect(() => {
    if (titleKr && contentKr && titleEn && contentEn) {
      setSubmitCheck(false)
    }
    if (
      titleKr === undefined ||
      contentKr === undefined ||
      titleEn === undefined ||
      contentEn === undefined
    ) {
      setSubmitCheck(true)
    }
  }, [titleKr, contentKr, titleEn, contentEn])

  useEffect(() => {
    if (emergencyNotifyItems && salesNoticeDialogOpen) {
      if (!emergencyNotifyItems[0].begin) {
        setStartTimeSelect(t('IAppNoticeTimeNow'))
        setBeginDate(new Date())
        setOnBeginDate(new Date())
      }
      if (!emergencyNotifyItems[0].end) {
        setEndTimeSelect(t('IAppNoticeTimeAutoNone'))
        setEndDate(new Date())
      }
      parseDate(emergencyNotifyItems)
      setTitleKr(emergencyNotifyItems[0].contents[1].title)
      setContentKr(emergencyNotifyItems[0].contents[1].content)
      setTitelEn(emergencyNotifyItems[0].contents[0].title)
      setContentEn(emergencyNotifyItems[0].contents[0].content)
      if (emergencyNotifyItems[0].contents[1].attachments) {
        setKrFileName(emergencyNotifyItems[0].contents[1].attachments)
      }
      if (emergencyNotifyItems[0].contents[0].attachments) {
        setEnFileName(emergencyNotifyItems[0].contents[0].attachments)
      }
    }
  }, [emergencyNotifyItems])

  useEffect(() => {
    setBeginDate(undefined)
    setEndDate(undefined)
    setBeginHour(undefined)
    setBeginMin(undefined)
    setEndHour(undefined)
    setEndMin(undefined)
    if (clickIndexDataNumber) {
      getEmergencyResponse().then((result) =>
        setEmergencyNoticesItems(
          result.items.filter((value) => {
            return value.id === clickIndexDataNumber
          }),
        ),
      )
    }

    if (salesNoticeDialogOpen && !isSelected) {
      handleClose()
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: t('INoticeSelectRequired'),
      })
    }
  }, [salesNoticeDialogOpen])

  useEffect(() => {
    if (krFileSelected) {
      setDeleteFileKr(false)
    }
  }, [krFileSelected])

  useEffect(() => {
    if (enFileSelected) {
      setDeleteFileEn(false)
    }
  }, [enFileSelected])

  useEffect(() => {
    if (startTimeSelect === `${t('IAppNoticeTimeNow')}`) {
      setBeginHour(undefined)
      setBeginMin(undefined)
    }
    if (endTimeSelect === `${t('IAppNoticeTimeAutoNone')}`) {
      setEndHour(undefined)
      setEndMin(undefined)
    }
  }, [startTimeSelect, endTimeSelect])

  useEffect(() => {
    if (beginDate) setBeginDateString(dateToDashString(beginDate))
    if (endDate) setEndDateString(dateToDashString(endDate))
  }, [beginDate, endDate])

  return (
    <div>
      <Dialog
        open={salesNoticeDialogOpen}
        fullWidth
        maxWidth='md'
        style={{
          width: `${Spacing.notice.noticeDialogWidth}px`,
          margin: `${Spacing.notice.noticeTextMargin} auto`,
        }}
      >
        <Card style={{width: `${Spacing.notice.noticeDivWidth}`}}>
          <div style={{width: `${Spacing.notice.noticeDivWidth}`}}>
            <NoticeTitle>{t('IAppNoticeTitle')}</NoticeTitle>
            <CloseIcon
              style={{
                position: 'absolute',
                right: `${Spacing.notice.noticeCloseIconRight}`,
                top: `${Spacing.notice.noticeCloseIconTop}px`,
                width: `${Spacing.notice.noticeCloseIconWidth}`,
                height: `${Spacing.notice.noticeCloseIconWidth}`,
                color: `${Colors.notice.noticeCancleIconColor}`,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <TitleLine />
            <NoticeContentWrapper>
              <div
                style={{
                  margin: `${Spacing.notice.noticeTextMargin} auto`,
                  width: `${Spacing.notice.noticeContentWidth}px`,
                }}
              >
                <InputTitle>{t('IAppNoticeTime')}</InputTitle>
                <div
                  style={{padding: `${Spacing.notice.noticeContentPadding}px`}}
                />
                {/* 라디오 버튼 */}
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeStart')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeNow')}
                      checked={startTimeSelect === `${t('IAppNoticeTimeNow')}`}
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>{t('IAppNoticeTimeNow')}</RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='start'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        startTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => hadleStartSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                <div style={{display: 'flex'}}>
                  <RadioButtonText>{t('IAppNoticeTimeEnd')}</RadioButtonText>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeAutoNone')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeAutoNone')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeAutoNone')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                  <RadioButtonContainer>
                    <RadioButton
                      type='radio'
                      name='end'
                      value={t('IAppNoticeTimeManualEntry')}
                      checked={
                        endTimeSelect === `${t('IAppNoticeTimeManualEntry')}`
                      }
                      onChange={(event) => handleEndSelectChange(event)}
                    />
                    <RadioButtonLabel />
                    <RadioButtonText>
                      {t('IAppNoticeTimeManualEntry')}
                    </RadioButtonText>
                  </RadioButtonContainer>
                </div>
                {/* 공지 텍스트 */}
                <InputTitle>{t('IAppNoticeTitleKrEn')}</InputTitle>
                <NoticeInputTitleText
                  placeholder={t('IAppNoticeTitleRequired')}
                  value={titleKr}
                  onChange={(e) => {
                    setTitleKr(e.target.value)
                  }}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/gi,
                  //     '',
                  //   )
                  // }}
                />
                <InputTitle>{t('IAppNoticeContentKrEn')}</InputTitle>
                <NoticeInputContentText
                  placeholder={t('IContentsRequired')}
                  value={contentKr}
                  onChange={(e) => {
                    setContentKr(e.target.value)
                  }}
                  // onInput={(e) => {
                  //   e.currentTarget.value = e.currentTarget.value.replace(
                  //     /[a-z|A-Z]/gi,
                  //     '',
                  //   )
                  // }}
                />
                {/* 국문 파일 첨부 */}
                <div style={{display: 'flex'}}>
                  <InputTitle
                    style={{
                      width: `${Spacing.notice.noticeInputTitleCustomWidth}px`,
                      marginTop: `${Spacing.notice.noticeInputTitleCustomMarginTop}px`,
                    }}
                  >
                    {t('IAppNoticeAttachedFile')}
                  </InputTitle>
                  {/* 국문파일업로드 */}
                  <input
                    type='file'
                    name='imageFileKr'
                    id='imageFileKr'
                    accept='image/*'
                    onChange={handleInputKrFile}
                    style={{
                      display: 'none',
                    }}
                  />
                  <NoticeAttachedFileLabel
                    htmlFor='imageFileKr'
                    style={{
                      width: `${Spacing.notice.noticeFileLabelWidth}px`,
                      marginTop: `${Spacing.notice.noticeFilelabelMargin}px`,
                    }}
                  >
                    <div>{t('IAppNoticeAttachedFileButton')}</div>
                  </NoticeAttachedFileLabel>
                </div>
                <div style={{display: 'flex'}}>
                  {krFileName && (
                    <NoticeAttachedFileView>
                      {krFileName}
                    </NoticeAttachedFileView>
                  )}
                  {krFileSelected && (
                    <NoticeAttachedFileView>
                      {krFileSelected.name}
                    </NoticeAttachedFileView>
                  )}
                  {!krFileName && !krFileSelected && (
                    <NoticeAttachedFileView>
                      {t('IAppNoticeAttachedFileNameDefault')}
                    </NoticeAttachedFileView>
                  )}
                  <NoticeAttachedFileDelete
                    onClick={() => {
                      setKrFileName('')
                      HadleAttachedKrFileDelete()
                      setDeleteFileKr(true)
                    }}
                  >
                    {t('IDelete')}
                  </NoticeAttachedFileDelete>
                </div>
              </div>
              <div
                style={{marginTop: `${Spacing.notice.noticeDivMarginTop}px`}}
              >
                <div>
                  {/* 캘린더 */}
                  <div style={{display: 'flex'}}>
                    {beginDate && (
                      <CalenderSinglePicker
                        selectedBeginDate={false}
                        dateValue={beginDate}
                        onChangeValue={handleBeginDateChanged}
                        isOpen={!selectTimeNow(startTimeSelect)}
                      />
                    )}
                    {/* 시간 입력하는 텍스트 */}
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      value={beginHour}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => {
                        setBeginHour(e.target.value)
                      }}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      value={beginMin}
                      disabled={selectTimeNow(startTimeSelect)}
                      onChange={(e) => setBeginMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                  <div style={{display: 'flex'}}>
                    {endDate && (
                      <CalenderSinglePicker
                        dateValue={endDate}
                        onBeginDate={onBeginDate}
                        selectedBeginDate={beginDatePick}
                        onChangeValue={handleEndDateChanged}
                        isOpen={!selectTimeEnd(endTimeSelect)}
                      />
                    )}
                    <NoticeTimeInputText
                      min={0}
                      max={23}
                      value={endHour}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndHour(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                    :
                    <NoticeTimeInputText
                      min={0}
                      max={59}
                      value={endMin}
                      disabled={selectTimeEnd(endTimeSelect)}
                      onChange={(e) => setEndMin(e.target.value)}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  </div>
                </div>
                {/* 영문입력 텍스트 */}

                {i18n.language !== 'ko' && (
                  <div
                    style={{
                      marginTop: `${Spacing.notice.noticeInputTitleTextCustomMarginTop}px`,
                    }}
                  >
                    <NoticeInputTitleText
                      style={{
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      value={titleEn}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      value={contentEn}
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/gi,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
                {i18n.language === 'ko' && (
                  <div>
                    <NoticeInputTitleText
                      value={titleEn}
                      placeholder={t('IAppNoticeTitleRequiredEn')}
                      style={{
                        marginTop: `${Spacing.notice.noticeInputEnTitleCustomMarginTop}px`,
                        marginBottom: `${Spacing.notice.noticeDeleteButtonHeight}px`,
                      }}
                      onChange={(e) => {
                        setTitelEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                    <NoticeInputContentText
                      value={contentEn}
                      placeholder={t('IAppNoticeContentRequiredEn')}
                      onChange={(e) => {
                        setContentEn(e.target.value)
                      }}
                      // onInput={(e) => {
                      //   e.currentTarget.value = e.currentTarget.value.replace(
                      //     /[ㄱ-ㅣ가-힣]/g,
                      //     '',
                      //   )
                      // }}
                    />
                  </div>
                )}
                <div style={{display: 'flex'}}>
                  <InputTitle
                    style={{
                      width: `${Spacing.notice.noticeInputTitleCustomWidth}px`,
                      marginTop: `${Spacing.notice.noticeInputTitleCustomMarginTop}px`,
                    }}
                  >
                    {t('IAppNoticeAttachedFile')}
                  </InputTitle>
                  {/* 영문파일 업로드 */}
                  <input
                    type='file'
                    name='imageFileEn'
                    id='imageFileEn'
                    accept='image/*'
                    onChange={handleInputEnFile}
                    style={{
                      display: 'none',
                    }}
                  />
                  <NoticeAttachedFileLabel
                    htmlFor='imageFileEn'
                    style={{
                      width: `${Spacing.notice.noticeFileLabelWidth}px`,
                      marginTop: `${Spacing.notice.noticeFilelabelMargin}px`,
                    }}
                  >
                    <div>{t('IAppNoticeAttachedFileButton')}</div>
                  </NoticeAttachedFileLabel>
                </div>
                <div style={{display: 'flex'}}>
                  {enFileName && (
                    <NoticeAttachedFileView>
                      {enFileName}
                    </NoticeAttachedFileView>
                  )}
                  {enFileSelected && (
                    <NoticeAttachedFileView>
                      {enFileSelected.name}
                    </NoticeAttachedFileView>
                  )}
                  {!enFileName && !enFileSelected && (
                    <NoticeAttachedFileView>
                      {t('IAppNoticeAttachedFileNameDefault')}
                    </NoticeAttachedFileView>
                  )}
                  <NoticeAttachedFileDelete
                    onClick={() => {
                      setEnFileName('')
                      HadleAttachedEnFileDelete()
                      setDeleteFileEn(true)
                    }}
                  >
                    {t('IDelete')}
                  </NoticeAttachedFileDelete>
                </div>
              </div>
            </NoticeContentWrapper>
          </div>
          <div
            style={{
              textAlign: 'center',
              backgroundColor: `${Colors.cancelButtonLight}`,
              padding: `${Spacing.notice.noticeButtonBackgroundPaddingTop}px ${Spacing.notice.noticeButtonBackgroundPaddingLeft}`,
            }}
          >
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleClose}
              style={{
                backgroundColor: 'white',
                color: `${Colors.notice.noticeRadioButtonTextColor}`,
                marginRight: `${Spacing.notice.noticeButtonMarginRight}px`,
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('ICancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={onSubmit}
              disabled={submitCheck}
              disableElevation
              type='submit'
              style={{
                width: `${Spacing.notice.noticeButtonWidth}px`,
                fontSize: `${Spacing.notice.noticeButtonFontSize}px`,
              }}
            >
              {t('IOk')}
            </Button>
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
