import {fetchTemplateInvoice} from 'api/invoiceApis'
import {
  selectPackageType,
  selectBaseFees,
  selectProductTemplate,
  selectDiscount,
  selectEegSummarySurcharge,
  selectIdInvoice,
  selectListInvoice,
  selectDataInvoices,
  selectLabelInvoice,
  changePackageType,
  changeDiscount,
  changeProductTemplate,
  changeBaseFees,
  changeSummarySurcharge,
  changeInvoiceVersion,
  updatePackageType,
  createNewInvoice,
  PlanType,
  ProductTemplateType,
  BaseFeesType,
  createInvoice,
  fetchListInvoice,
  updateInvoice,
  selectLoading,
} from 'features/invoice/invoiceSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {ID_NEW_INVOICE} from 'pages/CustomerManagement/InvoiceSetting/constant'
import {checkContinuous} from 'helpers/invoiceHelper'

export function useInvoice() {
  const dispatch = useAppDispatch()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const packageType = useAppSelector(selectPackageType)
  const baseFees = useAppSelector(selectBaseFees)
  const templates = useAppSelector(selectProductTemplate)
  const discount = useAppSelector(selectDiscount)
  const eegSummarySurcharge = useAppSelector(selectEegSummarySurcharge)
  const idInvoice = useAppSelector(selectIdInvoice)
  const listInvoice = useAppSelector(selectListInvoice)
  const dataInvoices = useAppSelector(selectDataInvoices)
  const labelInvoice = useAppSelector(selectLabelInvoice)
  const loading = useAppSelector(selectLoading)

  const changeInvoiceAction = (payload: PlanType) =>
    dispatch(changePackageType(payload))

  const changeDiscountAction = (payload: number) =>
    dispatch(changeDiscount(payload))

  const changeProductTemplateAction = (payload: ProductTemplateType[]) =>
    dispatch(changeProductTemplate(payload))

  const changeBaseFeesAction = (payload: BaseFeesType) =>
    dispatch(changeBaseFees(payload))

  const changeSummarySurchargeAction = (payload: number) =>
    dispatch(changeSummarySurcharge(payload))

  const changeInvoiceVersionAction = (payload: number) =>
    dispatch(changeInvoiceVersion(payload))

  const updateDataVersion = async () => {
    const versionActive = dataInvoices.find((item) => item.id === idInvoice)
    if (versionActive) {
      const dataTemplate = await fetchTemplateInvoice(versionActive.id)
      if (dataTemplate) dispatch(changeProductTemplate(dataTemplate.list))
      dispatch(changeBaseFees(versionActive.pkgInfo.baseFees))
      dispatch(updatePackageType(versionActive.pkgInfo.packageType))
      dispatch(changeDiscount(versionActive.pkgInfo.discount))
      dispatch(
        changeSummarySurcharge(versionActive.pkgInfo.eegSummarySurcharge),
      )
      dispatch(changeInvoiceVersion(versionActive.id))
    }
  }

  const onHandleInvoice = async (
    orgId: number | undefined,
    baseFees: BaseFeesType | null,
    templates: ProductTemplateType[] | null,
  ) => {
    try {
      const versionActive = dataInvoices.find((item) => item.id === idInvoice)
      if (versionActive?.canEditable === false) {
        onFailureModalOpen('This version cannot be updated')
        return
      }
      const payload = {
        baseFees,
        discount,
        eegSummarySurcharge,
        label: labelInvoice,
        orgId: orgId || '',
        packageType,
        templates,
      }
      const templatesFlat = templates?.map((item) => item.usages).flat()
      const templatesCheck = templatesFlat?.find(
        (item) => !item.price && item.price !== 0,
      )
      const baseFeesCheck = baseFees?.find((item) => !item.price)
      if (
        (!baseFees && !templates) ||
        (!baseFees?.length && !templates?.length) ||
        (templates?.length && templates?.length !== 10) ||
        templatesCheck ||
        baseFeesCheck
      ) {
        onFailureModalOpen('Please fill all field')
        return
      }

      if (
        packageType === 'FlatRateSubscriptionLimited' &&
        (!baseFees?.length || !templates?.length)
      ) {
        onFailureModalOpen('Please fill all field')
        return
      }

      if (templates?.length && !checkContinuous(templates)) {
        onFailureModalOpen('Range is not continuous')
        return
      }

      if (idInvoice === ID_NEW_INVOICE) {
        await dispatch(createInvoice(payload))
        onSuccessModalOpen('SUCCESS')
      } else if (typeof idInvoice === 'number') {
        await dispatch(updateInvoice({data: payload, pid: idInvoice}))
        onSuccessModalOpen('SUCCESS')
      }
    } catch (error) {
      onFailureModalOpen('Error')
    }
    if (typeof orgId === 'number') await dispatch(fetchListInvoice({orgId}))
  }

  const handleNewInvoice = (label: string) => dispatch(createNewInvoice(label))

  const fetchInvoices = async (orgId: number) => {
    await dispatch(fetchListInvoice({orgId}))
  }

  return {
    loading,
    packageType,
    baseFees,
    templates,
    discount,
    eegSummarySurcharge,
    idInvoice,
    listInvoice,
    dataInvoices,
    changeInvoiceAction,
    changeDiscountAction,
    changeProductTemplateAction,
    changeBaseFeesAction,
    changeSummarySurchargeAction,
    changeInvoiceVersionAction,
    onHandleInvoice,
    fetchInvoices,
    updateDataVersion,
    handleNewInvoice,
  }
}

export default useInvoice
