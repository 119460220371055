import React from 'react'
import {useTranslation} from 'react-i18next'
import DatePicker from 'react-datepicker'
import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import {MIN_DATE} from 'constants/HistoryConstant'
import AnalysisDate from 'components/molcules/UsageHistory/AnalysisDate'

const headerStyles = () =>
  createStyles({
    inputSearch: {
      margin: '2px 8px',
      minWidth: 170,
      flex: 1,
      border: '0px solid',
      height: 30,
      outline: 'none',
    },
    frameSearch: {
      border: '1px solid #e0e0e0',
      borderRadius: 4,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      width: 180,
    },
    cardToolBar: {display: 'flex', justifyContent: 'space-between'},
    toolBarLeft: {display: 'flex', gap: '10px'},
    datePicker: {width: 150, marginBottom: '20px'},
  })
const Styles = makeStyles(headerStyles)

interface TableToolbarProps {
  handleChangeDate: (dateInput: Date | null) => void
  date: Date
  handleChangeKeyword: (e: string) => void
  keyword: string
}

const TableToolbar = ({
  handleChangeDate,
  date,
  handleChangeKeyword,
  keyword,
}: TableToolbarProps) => {
  const styles = Styles()
  const {t} = useTranslation()

  return (
    <div className={styles.cardToolBar}>
      <div className={styles.toolBarLeft}>
        <div className={styles.datePicker}>
          <DatePicker
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            maxDate={new Date()}
            minDate={new Date(MIN_DATE)}
          />
        </div>
        <div className={styles.frameSearch}>
          <input
            className={styles.inputSearch}
            value={keyword}
            type='text'
            placeholder={t('ISearch')}
            onChange={(e) => handleChangeKeyword(e.target.value)}
          />
        </div>
      </div>

      <div>
        <AnalysisDate />
      </div>
    </div>
  )
}
export default TableToolbar
