import React from 'react'
import {useTranslation} from 'react-i18next'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import ReportSubtitle from '../../../Text/ReportSubtitle'

export default function EEGChildSummaryFrontLobeDevelopment(props: {
  frontalLobeDevelopmentGraphImage: string | undefined
  // eslint-disable-next-line react/no-unused-prop-types
  frontalLobeDevelopmentColorbarImage: string | undefined
  percentile: number
  // maxRange: number
  score: number
}) {
  const {frontalLobeDevelopmentGraphImage, percentile, score} = props
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number='1'>
        {t('IEEGSummaryReportChildFrontBrainTitle')}
      </ReportSubtitle>
      <div className={classes.childValueGraph}>
        <div className='Child-Graph'>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={frontalLobeDevelopmentGraphImage}
            alt='brain wave analysis 3d image'
          />
        </div>
        <div className='Child-info'>
          <div
            style={{
              backgroundColor: '#00a29c',
              color: '#FFF',
              padding: '15px 24px',
              marginTop: 84,
              marginBottom: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='Range'>
              <span style={{display: 'inline-block', maxWidth: '65%'}}>
                {t('IEEGSummaryReportChildFrontPercentTitle')}
              </span>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {percentile == null && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className='ScoreValue'>{`    - %ile`}</label>
              )}
              {percentile != null && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className='ScoreValue'>
                  {`    ${percentile} %ile` ?? '- %ile'}
                </label>
              )}
            </div>
            <div
              className='Score scoreWrapText'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span>{t('IEEGSummaryReportChildScore')}</span>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {score == null && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className='ScoreNullValue'>-</label>
              )}
              {score != null && (
                <div>
                  <span className='ScoreValue'>{`${score}` ?? '-'}</span>
                </div>
              )}
            </div>
          </div>
          <div className='Child-info-alert'>
            {t('IEEGSummaryReportChildEOAlert')}
          </div>
          <div
            className='Title'
            style={{color: '#000000', marginBottom: '20px'}}
          >
            {t('IEEGSummaryReportChildFrontBrainAbout')}
          </div>
          <div className='Desc' style={{color: '#333333'}}>
            {t('IEEGSummaryReportChildFrontBrainDescription')}
          </div>
        </div>
      </div>
    </div>
  )
}
