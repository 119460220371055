import {
  NoticeCreateInfoRequest,
  NoticeDetailInfoRequest,
  NoticeEditInfoRequest,
  NoticeListRequest,
} from 'api/noticeApi'
import {
  fetchNoticeCreate,
  fetchNoticeDelete,
  fetchNoticePaging,
  fetchNoticeRead,
  fetchNoticeUpdate,
  selectError,
  selectLoading,
  selectNoticeData,
  selectNoticePage,
  selectPaging,
  selectPagingInfo,
} from 'features/notice/noticeSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useLocation, useHistory} from 'react-router-dom'

export default function useNotice() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const noticePage = useAppSelector(selectNoticePage)
  const notice = useAppSelector(selectNoticeData)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)

  const onPaging = (payload: NoticeListRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    dispatch(fetchNoticePaging(payload))
  }

  const onCreate = (payload: NoticeCreateInfoRequest) =>
    dispatch(fetchNoticeCreate(payload))

  const onRead = (payload: NoticeDetailInfoRequest) =>
    dispatch(fetchNoticeRead(payload))

  const onUpdate = (payload: NoticeEditInfoRequest) =>
    dispatch(fetchNoticeUpdate(payload))

  const onDelete = (payload: NoticeDetailInfoRequest) =>
    dispatch(fetchNoticeDelete(payload))

  return {
    loading,
    error,
    noticePage,
    notice,
    paging,
    pagingInfo,
    onPaging,
    onRead,
    onCreate,
    onUpdate,
    onDelete,
  }
}
