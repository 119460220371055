import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import ReportSubtitle from 'components/Text/ReportSubtitle'

export default function EEGSummaryBrain3D(props: {
  threeDimensionImage: string
  index: number
}) {
  const {threeDimensionImage, index} = props
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.eeg}>
      <ReportSubtitle number={`${index}`}>
        {t('IEEGSummaryReportBrainAgingTitle')}
      </ReportSubtitle>
      <div className={classes.p_brain3D}>
        <div className='Brain-3d-image'>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img src={threeDimensionImage} alt='brain wave analysis 3d image' />
        </div>
        <div className='Brain-3d-info'>
          <div className='Title'>
            {t('IEEGSummaryReportBrainAgingAbout3DTitle')}
          </div>
          <div className='Desc'>
            {t('IEEGSummaryReportBrainAgingAbout3DDescription')}
          </div>
        </div>
      </div>
    </div>
  )
}
