import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import PercentChangeBarCard from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Elements/percentChangeBar'
import Analysis from 'pages/HealthCenter/Report/Elements/Analysis'

const useStyles = makeStyles(HealthCenterReportStyle)

export interface BrainFunctionalConnectivityProps {
  partOneImg: string
  brainConnectivityTotalScore: number
  brainConnectivityBetweenLobesScore: {
    vertical: {
      left: number
      right: number
    }
    horizontal: number
  } | null
  brainConnectivityByLobeScore: {
    frontal: {
      left: number
      right: number
    }
    pariocci: {
      left: number
      right: number
    }
  } | null
}

const PagePrintWrap1 = ({
  partOneImg,
  brainConnectivityTotalScore,
  brainConnectivityByLobeScore,
  brainConnectivityBetweenLobesScore,
}: BrainFunctionalConnectivityProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const formatColorLeft = (value: number | string) => {
    if (Math.floor(Number(value)) < 30) return '#ee3d3b'
    if (Math.floor(Number(value)) > 70) return '#1eaae3'
    return '#B4B4B4'
  }

  const getTextInRound = (value: number) => {
    if (Math.floor(Number(value)) > 70) return t('IHeight')
    if (Math.floor(Number(value)) < 30) return t('ILowness')
    return t('ICommonlyHeart')
  }

  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }

  const formatTextRoundLeft = (frequency: number) => {
    return {
      value: getNumber(frequency),
      frequency: `(${getNumber(Number(frequency))})`,
      status: getTextInRound(getNumber(frequency)),
    }
  }

  const getText = (value = 0, key: string) => {
    if (value < 30) {
      return t(`IBot${key}`, {
        value: getNumber(value),
      })
    }
    if (value > 70) {
      return t(`ITop${key}`, {
        value: getNumber(value),
      })
    }
    return t(`IMid${key}`, {
      value: getNumber(value),
    })
  }

  const getTextV2 = (left = 0, right = 0, key = 'Title') => {
    if (left < 30 && right < 30) {
      return `IAnalysisResultsDes${key}Case3`
    }
    if (left > 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case2`
    }
    if (left > 70 && right < 70) {
      return `IAnalysisResultsDes${key}Case4`
    }
    if (left < 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case5`
    }
    return `IAnalysisResultsDes${key}Case1`
  }

  const textPart1Title = useMemo(() => {
    return getText(
      brainConnectivityTotalScore,
      'OverallRecoveryAnalysisResultsTitle',
    )
  }, [brainConnectivityTotalScore])

  const textPart1Text = useMemo(() => {
    return getText(
      brainConnectivityTotalScore,
      'OverallRecoveryAnalysisResultsText',
    )
  }, [brainConnectivityTotalScore])

  const textPart2Title1 = useMemo(() => {
    const key = getTextV2(
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.right ?? 0),
    )
    return t(key, {
      left: getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      right: getNumber(
        brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
      ),
    })
  }, [brainConnectivityBetweenLobesScore?.vertical])

  return (
    <div className={classes.brainBasic}>
      <div className={classes.brainConnectivityTitle}>
        {t('IBrainConnectivityTitlePart1')}
        <span>{t('IBrainConnectivityTitlePart2')}</span>
        {t('IBrainConnectivityTitlePart3')}
      </div>
      <div className={classes.brainConnectivityDes}>
        <p>{t('IBrainConnectivityDesPart1')}</p>
        <p>
          <p>{t('IBrainConnectivityDesPart2')}</p>
        </p>
        <p>
          <p>{t('IBrainConnectivityDesPart3')}</p>
        </p>
        <p>
          <p>{t('IBrainConnectivityDesPart4')}</p>
        </p>
      </div>
      <div className={classes.brainConnectivityDes} style={{marginBottom: 60}}>
        <p>{t('IBrainConnectivityDes1')}</p>
        <div>
          <ul>
            <li>{t('IBrainConnectivityDesLi1')}</li>
            <li>{t('IBrainConnectivityDesLi2')}</li>
            <li>{t('IBrainConnectivityDesLi3')}</li>
          </ul>
        </div>
        <p>{t('IBrainConnectivityDes1')}</p>
      </div>
      <WrapElement number={1} text={t('IBrainConnectivityAnalysisResult')}>
        <div className={classes.contentCard}>
          <div className={classes.cardLeftContent}>
            <img
              className={classes.cardLeftImg}
              src={partOneImg}
              alt='Contour'
            />
            <div className={classes.percentChangeBar}>
              <div style={{height: 24}} />
              <PercentChangeBarCard
                headerName={t('IBrainConnectivityOverallRecovery')}
                backgroundColor={formatColorLeft(brainConnectivityTotalScore)}
                textRound={formatTextRoundLeft(brainConnectivityTotalScore)}
                percentage={brainConnectivityTotalScore ?? 0}
                index={5}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
                showHz={false}
              />
            </div>
          </div>
          <div className={classes.cardRightContent}>
            <p className={classes.textBold}>
              {t('IBrainConnectivityOverallRecoveryTitle')}
            </p>
            <Analysis>
              <div className={classes.connectivityAnalysis}>
                <p className={classes.textBold}>{textPart1Title}</p>
                <p className={classes.textP}>{textPart1Text}</p>
                <ul>
                  <li>{textPart2Title1}</li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi2', {
                      horizontal: getNumber(
                        brainConnectivityBetweenLobesScore?.horizontal ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi3', {
                      left: getNumber(
                        brainConnectivityByLobeScore?.frontal?.left ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi4', {
                      right: getNumber(
                        brainConnectivityByLobeScore?.frontal?.right ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi5', {
                      left: getNumber(
                        brainConnectivityByLobeScore?.pariocci?.left ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi6', {
                      right: getNumber(
                        brainConnectivityByLobeScore?.pariocci?.right ?? 0,
                      ),
                    })}
                  </li>
                </ul>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default PagePrintWrap1
