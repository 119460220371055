import {Typography} from '@material-ui/core'
import {emailAuthenticationCheckApi} from 'api/authApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

interface EmailProps {
  onDuplicateChecked: () => void
  uuid: string
}

export default function EmailConfirmInput({
  onDuplicateChecked: onSubmit,
  uuid,
}: EmailProps) {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const [isInputClosed, setIsInputClosed] = React.useState(false)

  const {
    formState: {errors},
  } = useFormContext()

  async function checkEmail(uuid: string, code: string) {
    try {
      await emailAuthenticationCheckApi({uuid, code})
      await onSuccessModalOpen(t('IEmailIsAvailable'))
      onSubmit()
      setIsInputClosed(true)
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }

  const handleEmailDuplicate = () => {
    checkEmail(uuid, createOrgInfo.getValues('emailAuthenticationCode'))
  }

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IAuthNumber')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <input
          id='emailAuthenticationCode'
          placeholder={t('IRequired')}
          className={classes.input}
          disabled={isInputClosed}
          {...createOrgInfo.register('emailAuthenticationCode', {
            required: `${t('IRequired')}`,
          })}
        />
        <Typography className={classes.padding} />
        <ActionButton
          color='primary'
          onClick={handleEmailDuplicate}
          style={{height: 50}}
          disabled={isInputClosed}
        >
          {t('IConfirm')}
        </ActionButton>
      </div>
      <span className={classes.errorText}>
        {errors.email && t(`${errors.email.message}`)}
      </span>
    </div>
  )
}
