import request, {fileRequest} from 'api/index'
import {
  BaseFeesType,
  PlanType,
  ProductTemplateType,
} from 'features/invoice/invoiceSlice'

export interface CommentPageRequest extends PagingRequest {
  uuid: string
}

export interface InvoiceCreateRequest {
  baseFees: BaseFeesType | null
  discount: number
  eegSummarySurcharge: number
  label: string
  orgId: number | string
  packageType: PlanType
  templates: ProductTemplateType[] | null
}

interface InvoiceCreateResponse extends CommonResponse {
  data: number
}

export const postInvoiceCreatesApi = (data: InvoiceCreateRequest) =>
  request<InvoiceCreateResponse>({
    method: 'post',
    path: '/cs/api/v1/product',
    data,
  })

export interface InvoiceGetResponse extends CommonResponse {
  canEditable: boolean
  id: number
  label: string
  orgId: number
  pkgInfo: {
    baseFees: BaseFeesType
    discount: number
    eegSummarySurcharge: number
    packageId: number
    packageType: PlanType
    templateIds: ProductTemplateType[]
  }
}

export const fetchListVersionInvoice = (orgId: number) =>
  request<ModelsResponse<InvoiceGetResponse>>({
    method: 'get',
    path: '/cs/api/v1/product',
    params: {orgId},
  })

export const fetchTemplateInvoice = (pid: number) =>
  request<ModelsResponse<ProductTemplateType>>({
    method: 'get',
    path: `/cs/api/v1/product/${pid}/template`,
  })

export const putInvoiceUpdateAPI = ({
  pid,
  data,
}: {
  pid: number
  data: InvoiceCreateRequest
}) =>
  request<CommonResponse>({
    method: 'put',
    path: `/cs/api/v1/product/${pid}`,
    data,
  })

export interface PostInvoiceRequestType {
  orgId: number
  productId: number
  usageMonth: string
}

// post API invoice
export const postInvoiceAPI = ({
  orgId,
  productId,
  usageMonth,
}: PostInvoiceRequestType) => {
  return request<ModelResponse<number>>({
    method: 'post',
    path: '/cs/api/v1/invoice',
    data: {
      orgId,
      productId,
      usageMonth,
    },
  })
}

export interface SearchedCustomerRegistration {
  id: number
  billingSummaryId: number
  confirmedTimestamp: number
  consumerId: number
  deployed: boolean
  deployedTimestamp: string
  confirmed: boolean
  providerId: number
  usageMonth: string
  orgId?: number
  orgName?: string | null
  orgUserFirstname?: string | null
  orgUserLastname?: string | null
  email?: string | null
  countryName?: string
  countryId: number
  billingPrice?: number
  phoneNumber?: string
  manager?: string
  billingFileId?: number
}

interface AnalysisSearchCustomerRequest extends PagingRequestV3, PeriodRequest {
  search: CustomerSearchKind
}

export interface CustomerSearchRequest extends AnalysisSearchCustomerRequest {
  isConfirmed?: boolean | null
  type?: string
}

// search api CUSTOMER
export const searchCustomerApi = ({
  paging,
  period,
  search,
  isConfirmed,
  type,
}: CustomerSearchRequest) => {
  return request<PageResponse<SearchedCustomerRegistration>>({
    method: 'get',
    path: '/cs/api/v1/invoice',
    params: {
      ...paging,
      orgName: search.orgName,
      ...period,
      isConfirmed,
      type: type === 'All' ? undefined : type,
    },
  })
}

export interface GetInfoCustomerRequest {
  ids: string
}

export interface InfoCustomer {
  id: number
  orgId: number
  orgName: string
  orgUserFirstname: string
  orgUserLastname: string
  email: string
}

export const getInfoListConsumerApi = ({ids}: GetInfoCustomerRequest) => {
  return request<ModelsResponse<InfoCustomer>>({
    method: 'get',
    path: 'cs/api/v1/invoice/any/consumer',
    params: {
      id: ids,
    },
  })
}

export interface HealthProductInfoListType {
  displayName: string
  id: number
  productId: number
}

export interface SearchedCustomerOrg {
  orgId: number
  lastName: string
  firstName: string
  name: string
  email: string
  countryId: number
  phoneNumber: string
  industryId: number
  detailIndustryId: number
  address: string
  businessNumber: string
  institutionNumber: string
  createdAt: string
  orgType: string
  orgName: string
  healthProductInfoList: HealthProductInfoListType[]
}
export interface SearchOrgRequest extends PagingRequestV3 {
  search: CustomerSearchOrgKind
}

export const searchOrganizationApi = ({search, paging}: SearchOrgRequest) => {
  return request<PageResponse<SearchedCustomerOrg>>({
    method: 'get',
    path: '/api/manager/v2/org/list',
    params: {
      orgName: search.orgName,
      ...paging,
    },
  })
}

export interface OrgDetailResponse {
  address: string
  businessNumber: string
  countryId: number
  createdAt: string
  email: string
  firstName: string
  industryId: number
  detailIndustryId: number | null
  institutionNumber: string
  lastName: string
  name: string
  orgId: number
  orgName: string
  orgType: string
  phoneNumber: string
  healthProductInfoList: HealthProductInfoListType[]
  packageExpireDate: string | null
  packageStartDate: string | null
  therapyYn: string
}

export const getDetailOrgRegister = (id?: number) => {
  return request<ModelResponse<OrgDetailResponse>>({
    method: 'get',
    path: `/api/manager/v2/org/${id}/detail`,
  })
}

export const getDetailListOrg = (oid?: string) => {
  return request<ModelsResponse<OrgDetailResponse>>({
    method: 'get',
    path: `/api/manager/v1/org/any/detail`,
    params: {
      oid,
    },
  })
}

export interface BillingItemResponse {
  billingDate: string
  billingFileId: number
  billingPrice: number
  id: number
  productId: number
  remarks: any
}

export const getBillingSummary = (id?: string) => {
  return request<ModelsResponse<BillingItemResponse>>({
    method: 'get',
    path: '/cs/api/v1/invoice/any/billing-summary',
    params: {
      id,
    },
  })
}

export const sendMailInvoiceApi = (id?: string) => {
  return request<CommonResponse>({
    method: 'post',
    path: '/cs/api/v1/invoice/any/deploy',
    params: {
      id,
    },
  })
}

// API for invoice view
export interface UpdateInvoiceRequest {
  billingDate?: string
  billingPrice?: number
  isConfirm?: boolean
  productId?: number | null
  remarks?: string
}

export const updateInvoiceAPI = ({
  invoiceId,
  data,
}: {
  invoiceId: number
  data: UpdateInvoiceRequest
}) => {
  return request<CommonResponse>({
    method: 'patch',
    path: `/cs/api/v1/invoice/${invoiceId}`,
    params: {invoiceId},
    data,
  })
}

export const fileAttachmentInvoiceAPI = ({
  invoiceId,
  data,
}: {
  invoiceId: number
  data: File
}) => {
  return fileRequest<CommonResponse>({
    method: 'post',
    path: `/cs/api/v1/invoice/${invoiceId}/file-attachment`,
    params: {invoiceId},
    data: {file: data},
  })
}

export interface InfoConsumerInvoiceResponse {
  email: string
  id: number
  orgId: number
  orgName: string
  orgUserFirstname: string
  orgUserLastname: string
}

export const getInfoConsumerInvoiceApi = ({invoiceId}: {invoiceId: number}) => {
  return request<ModelResponse<InfoConsumerInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}/consumer`,
    params: {
      invoiceId,
    },
  })
}

export const getBillingSummaryInvoiceApi = ({
  invoiceId,
}: {
  invoiceId: number
}) => {
  return request<ModelResponse<BillingItemResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}/billing-summary`,
    params: {
      invoiceId,
    },
  })
}

export interface ProviderInvoiceResponse {
  address: string
  bankAccount: string
  bankUsername: string
  ceoFirstname: string
  ceoLastname: string
  companyName: string
  companyNumber: string
  id: number
  manager: string
  phone: string
}

export const getProviderInvoiceApi = ({invoiceId}: {invoiceId: number}) => {
  return request<ModelResponse<ProviderInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}/provider`,
    params: {
      invoiceId,
    },
  })
}

export interface UsageDetailResponse {
  analysisCompleteTime: number
  analysisRequestTime: number
  patientAge: number
  patientChartNo: string
  patientFirstname: string
  patientLastname: string
  remarks: string
  sequence: number
  type: string
}

export const getUsageDetailInvoiceApi = ({invoiceId}: {invoiceId: number}) => {
  return request<ModelsResponse<UsageDetailResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}/usage-detail`,
    params: {
      invoiceId,
    },
  })
}

export const getInvoiceApi = ({pid}: {pid: number}) => {
  return request<ModelResponse<InvoiceGetResponse>>({
    method: 'get',
    path: `/cs/api/v1/product/${pid}`,
    params: {
      pid,
    },
  })
}

export interface OrgDetailEditRequest {
  orgId: number
  address?: string
  customerPo?: string
  area?: string
  businessNo: string
  firstUserName: string
  customerFistN?: string
  lastUserName: string
  countryId: number
  industry?: Industry
  detailIndustry?: Industry
  email: string
  industryId: number
  detailIndustryId: number
  orgName: string
  institutionNo?: string
  orgType?: 'HEALTH' | 'NORMAL'
  tel: string
  countryCode: string
  healthProductIds?: number[] | null
  packageExpireDate: string | null
  packageStartDate: string | null
  therapyYn: string
}

export const updateOrgDetailApi = (data: OrgDetailEditRequest) =>
  request<CommonResponse>({
    method: 'put',
    path: `/api/manager/v1/org/${data.orgId}`,
    data: {
      address: data.address,
      businessNumber: data.businessNo,
      countryCode: data.countryCode,
      detailIndustryId: data.detailIndustryId,
      firstName: data.firstUserName,
      industryId: data.industry?.id,
      institutionNumber: data.institutionNo,
      lastName: data.lastUserName,
      orgType: data.orgType,
      phoneNumber: data.tel,
      healthProductIds: data.healthProductIds,
      orgName: data.orgName,
      packageExpireDate: data.packageExpireDate,
      packageStartDate: data.packageStartDate,
      therapyYn: data.therapyYn,
    },
    params: {
      id: data.orgId,
    },
  })

export interface LastInvoiceResponse {
  billingSummaryId: number
  confirmed: boolean
  confirmedTimestamp: number
  consumerId: number
  deployed: boolean
  deployedTimestamp: number
  id: number
  productId: number
  remarks: string
}

export const fetchLastInvoice = (orgId: number) =>
  request<ModelResponse<LastInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/org/${orgId}/invoice/latest`,
    params: {
      orgId,
    },
  })

export interface BillingSummaryResponse {
  billingDate: string
  billingFileId: number
  billingPrice: number
  id: number
  productId: number
  remarks: string
}

export const fetchBillingSummary = (invoiceId: number) =>
  request<ModelResponse<BillingSummaryResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}/billing-summary`,
    params: {
      invoiceId,
    },
  })

export const getConfirmInvoiceApi = ({invoiceId}: {invoiceId: number}) => {
  return request<ModelResponse<LastInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${invoiceId}`,
    params: {
      invoiceId,
    },
  })
}

export const getInvoiceFollowMonthAPI = (orgId: number, usageMonth: string) =>
  request<ModelResponse<ProviderInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/org/${orgId}/invoice`,
    params: {
      orgId,
      usageMonth,
    },
  })

export const getOrgIdInvoiceApi = (id: number) => {
  return request<ModelResponse<InfoCustomer>>({
    method: 'get',
    path: `/cs/api/v1/invoice/${id}/consumer`,
    params: {
      id,
    },
  })
}

export const getProviderLatest = () => {
  return request<ModelResponse<ProviderInvoiceResponse>>({
    method: 'get',
    path: `/cs/api/v1/provider/latest`,
  })
}
