import {numberWithCommas} from 'helpers/commonHelper'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {getPackageFullName} from 'helpers/paymentHelper'
import {ProductsTypes, VisibleTypes} from 'pages/OrgPage/Interfaces/Enums'
import CanBePurchaseProductsModel from '../Models/CanBePurchaseProductsModel'
import PackageBillingCycleModel from '../Models/PackageBillingCycleModel'
import PackageSubscriptionModel from '../Models/PackageSubscriptionModel'
import PackageUnitModel from '../Models/PackageUnitModel'
import {
  AnalysisProduct,
  canBePurchase,
  PurchaseBundle,
  PurchasePackage,
} from '../../../../api/paymentApi'

export default function ProductsSelectViewModel() {
  const {t} = useTranslation()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  // 패키지 구입 단위 설정
  const [rules, setRule] = useState<
    [
      {
        cycle: number
        period: number
        amount: number
      },
    ]
  >([
    {
      cycle: 0,
      period: 0,
      amount: 0,
    },
  ])

  const [selectedPackages, setSelectedPackages] = useState<string[]>([])
  const [selectedBundles, setSelectedBundles] = useState<string[]>([])
  const [isAllChecked, setAllChecked] = useState(false)

  const [products, setProducts] = useState<CanBePurchaseProductsModel>()
  const [isProductsLoading, setIsProductsLoading] = useState(false)

  const [allPurchasedId, setAllPurchasedId] = useState<string[]>([])
  const [disabledPackageLength, setDisabledPackageLength] = useState<number>(0)
  const [disabledBundleLength, setDisabledBundleLength] = useState<number>(0)

  // 상품 명 - 타입 선택해서 원하는 것 노출
  const [visibleType, setVisibleType] = useState<string>(VisibleTypes.All)

  const [isDisabledConfirmButton, setIsDisabledConfirmButton] =
    useState<boolean>(selectedPackages.length + selectedBundles.length < 1)

  async function setDefaultProducts() {
    setSelectedPackages([])
    setSelectedBundles([])
  }

  async function getProducts() {
    setIsProductsLoading(false)
    try {
      setIsProductsLoading(true)

      // 구입 가능 한 상품을 읽어오고, 기타 로직에 필요한 상태 값들을 추가 적으로 설정한다.
      const result = await canBePurchase()

      if (result) {
        const p = new CanBePurchaseProductsModel(result, t)

        // todo 구매내역 만들어서 비교 하는거 구현 하자...
        setProducts(p)

        // 서버로 부터 받아온 패키지의 수에 맞게 state 설정 해준다.
        const r = p.available.packages.map(() => {
          return {cycle: 0, period: 0, amount: 0}
        }) as [{cycle: number; period: number; amount: number}]
        setRule(r)

        setIsProductsLoading(false)
      }
    } catch (e) {
      console.log('Error:::: getProducts:::: ', e)
      setIsProductsLoading(false)
    }
  }

  // --- 패키지 / 번들 선택과 관련한 처리들
  // 패키지 이미 구매한 상품일 경우 disabled
  // 아래 함수 사용할 때 id를 바로 알 수 있는 경우 넣어주기(setCycle 함수 등)
  function disablePurchasedPackage(index: number, id?: string) {
    let disabled = false

    if (products != null && allPurchasedId.length > 0) {
      let packageId = id
      if (!id) {
        const {cycle, period, amount} = rules[index]
        const {id} =
          products.available.packages[index].billingCycles[cycle].subscriptions[
            period
          ].units[amount]
        packageId = id
      }

      allPurchasedId.find((item) => {
        if (item === packageId) disabled = true
        return null
      })
    }

    return disabled
  }

  // 번들 이미 구매한 상품일 경우 disabled
  function disablePurchasedBundle(index: number) {
    let disabled = false

    if (products != null && allPurchasedId.length > 0) {
      const {id} = products.available.bundles[index]

      allPurchasedId.find((item) => {
        if (item === id) disabled = true
        return null
      })
    }
    return disabled
  }

  function checkAllStatus(
    packageLength: number,
    bundleLength: number,
    dPackageLength: number,
    dBundleLength: number,
  ) {
    if (products != null) {
      const a = products.available

      const isAllPackages = packageLength === a.packages.length - dPackageLength
      const isAllBundles = bundleLength === a.bundles.length - dBundleLength
      let isAllRelease = isAllPackages && isAllBundles

      // add visibleType - 전체 length가 타입에 따라 달라져야함
      if (visibleType === VisibleTypes.All)
        isAllRelease = isAllPackages && isAllBundles
      else if (visibleType === VisibleTypes.Packages)
        isAllRelease = isAllPackages
      else if (visibleType === VisibleTypes.Bundles) isAllRelease = isAllBundles

      setAllChecked(isAllRelease)
    }
  }

  function checkAllDefaultStatus() {
    setAllChecked(false)
  }

  function setSelectPackage(index: number) {
    if (products != null) {
      const packages = selectedPackages.slice()

      const {cycle, period, amount} = rules[index]
      const {id} =
        products.available.packages[index].billingCycles[cycle].subscriptions[
          period
        ].units[amount]
      const itemIndex = packages.indexOf(id)

      if (itemIndex > -1) {
        packages.splice(itemIndex, 1)
      } else {
        packages.push(id)
      }
      setSelectedPackages(packages)
      checkAllStatus(
        packages.length,
        selectedBundles.length,
        disabledPackageLength,
        disabledBundleLength,
      )
    }
  }

  function isPackageSelected(index: number) {
    if (products != null) {
      const packages = selectedPackages.slice()
      const {cycle, period, amount} = rules[index]
      const {id} =
        products.available.packages[index].billingCycles[cycle].subscriptions[
          period
        ].units[amount]

      return packages.indexOf(id) > -1
    }
    return false
  }

  // 여기서 부턴 번들

  function setSelectBundle(index: number) {
    if (products != null) {
      const bundles = selectedBundles.slice()
      // const set = new Set()
      const {id} = products.available.bundles[index]
      const itemIndex = bundles.indexOf(id)
      if (itemIndex > -1) {
        bundles.splice(itemIndex, 1)
      } else {
        bundles.push(id)
      }
      setSelectedBundles(bundles)
      checkAllStatus(
        selectedPackages.length,
        bundles.length,
        disabledPackageLength,
        disabledBundleLength,
      )
    }
  }

  function isBundleSelected(index: number) {
    if (products != null) {
      const bundles = selectedBundles.slice()
      const {id} = products.available.bundles[index]
      return bundles.indexOf(id) > -1
    }
    return false
  }

  function setAllSelectedToggle() {
    if (products != null) {
      if (isAllChecked) {
        setSelectedBundles([])
        setSelectedPackages([])
        checkAllDefaultStatus()
      } else {
        // for checkAllStatus length
        let dPackageLength = 0
        let dBundleLength = 0

        const a = products.available

        const pkgs: string[] = []
        a.packages.map((pkg, index) => {
          const {cycle, period, amount} = rules[index]
          const {id} =
            pkg.billingCycles[cycle].subscriptions[period].units[amount]

          allPurchasedId.find((purchasedId) => {
            if (purchasedId !== id) {
              pkgs.push(id)
            } else {
              dPackageLength += 1
            }
            return null
          })
          return null
        })

        const bnds: string[] = []
        a.bundles.map((bundle) => {
          allPurchasedId.find((purchasedId) => {
            if (purchasedId !== bundle.id) {
              bnds.push(bundle.id)
            } else {
              dBundleLength += 1
            }
            return null
          })
          return null
        })

        // add visibleType - 선택된 셀렉트 아이템만 담겨야 해서
        if (visibleType === VisibleTypes.All) {
          setSelectedPackages(pkgs)
          setSelectedBundles(bnds)
        } else if (visibleType === VisibleTypes.Packages) {
          setSelectedPackages(pkgs)
        } else if (visibleType === VisibleTypes.Bundles) {
          setSelectedBundles(bnds)
          checkAllStatus(0, bnds.length, 0, dBundleLength)
        }

        setDisabledPackageLength(dPackageLength)
        setDisabledBundleLength(dBundleLength)
        checkAllStatus(pkgs.length, bnds.length, dPackageLength, dBundleLength)

        onNoticeDialogOpen({
          title: t('INoticeTitle'),
          message: `${t('INoticeDuplicateProductsAll')}`,
        }).then((result) => {
          if (!result.payload) {
            // 취소

            setSelectedBundles([])
            setSelectedPackages([])
            checkAllDefaultStatus()
          }
        })
      }
    }
  }

  // 패키지 / 번들 선택과 관련한 처리들 ---

  function releaseSelectedPackage(order: number) {
    if (isPackageSelected(order)) {
      setSelectPackage(order)
    }
  }

  /**
   * 기간(cycle)의 드롭다운 index 선택해 값 변경
   */
  function setCycle(order: number, index: number) {
    if (products == null) {
      return
    }

    // 체크박스 전체 선택을 위한 disabled package length
    let disabledLength = 0

    const r = rules.slice()
    const {cycle, period, amount} = r[order]

    // cycle(기간)에서 같은 index 선택하면 아무 처리 하지 않음 (period, amount의 값을 0번째로 변경 X)
    if (index === cycle) {
      return
    }

    // 기간을 변경하기 전의 같은 패키지가 체크되어 있는지 확인
    let {id} =
      products.available.packages[order].billingCycles[cycle].subscriptions[
        period
      ].units[amount]
    const wasChecked = selectedPackages.includes(id)

    // 바꾸기 전의 id가 disabled인지
    disabledLength = disablePurchasedPackage(-1, id) ? 1 : 0

    // 선택된 상품의 값들이 변경되어서 패키지 정보 수정(정보가 바뀌어서 체크도 해제 됨)
    releaseSelectedPackage(order)

    // cycle은 index(선택한 값)으로 변경, period는 0번째, amount는 0번째
    // cycle에 따라 period와 amount는 다른 값을 가질 수 있기 때문
    r[order] = {cycle: index, period: 0, amount: 0}
    setRule(r as [{cycle: number; period: number; amount: number}])

    // 정보가 변경된 패키지를 다시 체크하기 위한 id 값 (cycle: index, period: 0, amount: 0)
    id =
      products.available.packages[order].billingCycles[index].subscriptions[0]
        .units[0].id

    // 상품 정보 변경 전 체크가 되어 있었고, 이미 구매한 상품이 아닌 경우 다시 체크
    if (wasChecked && !disablePurchasedPackage(-1, id)) {
      setSelectedPackages((prev) => [...prev, id])
    }

    // 이미 구매한 상품이면 disabled
    disablePurchasedPackage(-1, id)

    // 바뀐 id가 disabled인지
    disabledLength -= disablePurchasedPackage(-1, id) ? 0 : 1
    setDisabledPackageLength((prev) => prev + disabledLength)

    // 전체 선택 다시 확인
    checkAllStatus(
      products.available.packages.length,
      products.available.bundles.length,
      disabledPackageLength + disabledLength,
      disabledBundleLength,
    )
  }

  function setPeriod(order: number, index: number) {
    if (products == null) {
      return
    }

    const r = rules.slice()
    const {cycle, period, amount} = r[order]

    // period(결제 반복)에서 같은 index 선택하면 아무 처리 하지 않음 (amount의 값을 0번째로 변경 X)
    if (index === period) {
      return
    }

    // 체크박스 전체 선택을 위한 disabled package length
    let disabledLength = 0

    // 결제 반복을 변경하기 전의 같은 패키지가 체크되어 있는지 확인
    let {id} =
      products.available.packages[order].billingCycles[cycle].subscriptions[
        period
      ].units[amount]
    const wasChecked = selectedPackages.includes(id)

    // 바꾸기 전의 id가 disabled인지
    disabledLength = disablePurchasedPackage(-1, id) ? 1 : 0

    releaseSelectedPackage(order)

    // cycle은 그대로 유지, period는 index(선택한 값)으로 변경, amount는 0번째
    // period에 따라 amount는 다른 값을 가질 수 있기 때문
    r[order] = {...r[order], period: index, amount: 0}
    setRule(r as [{cycle: number; period: number; amount: number}])

    // 정보가 변경된 패키지를 다시 체크하기 위한 id 값 (cycle: cycle, period: index, amount: 0)
    id =
      products.available.packages[order].billingCycles[cycle].subscriptions[
        index
      ].units[0].id

    // 상품 정보 변경 전 체크가 되어 있었고, 이미 구매한 상품이 아닌 경우 다시 체크
    if (wasChecked && !disablePurchasedPackage(-1, id)) {
      setSelectedPackages((prev) => [...prev, id])
    }

    // 이미 구매한 상품이면 disabled
    disablePurchasedPackage(-1, id)

    // 바뀐 id가 disabled인지
    disabledLength -= disablePurchasedPackage(-1, id) ? 0 : 1
    setDisabledPackageLength((prev) => prev + disabledLength)

    // 전체 선택 다시 확인
    checkAllStatus(
      products.available.packages.length,
      products.available.bundles.length,
      disabledPackageLength + disabledLength,
      disabledBundleLength,
    )
  }

  function setAmount(order: number, index: number) {
    if (products == null) {
      return
    }

    // 체크박스 전체 선택을 위한 disabled package length
    let disabledLength = 0

    const r = rules.slice()
    const {cycle, period, amount} = r[order]

    // period(결제 반복)에서 같은 index 선택하면 아무 처리 하지 않음 (amount의 값을 0번째로 변경 X)
    if (index === amount) {
      return
    }

    // 횟수를 변경하기 전의 같은 패키지가 체크되어 있는지 확인
    let {id} =
      products.available.packages[order].billingCycles[cycle].subscriptions[
        period
      ].units[amount]
    const wasChecked = selectedPackages.includes(id)

    // 바꾸기 전의 id가 disabled인지
    disabledLength = disablePurchasedPackage(-1, id) ? 1 : 0

    releaseSelectedPackage(order)

    // cycle, period는 그대로 유지, amount는 index(선택한 값)으로 변경
    r[order] = {...r[order], amount: index}
    setRule(r as [{cycle: number; period: number; amount: number}])

    // 정보가 변경된 패키지를 다시 체크하기 위한 id 값 (cycle: cycle, period: period, amount: index)
    id =
      products.available.packages[order].billingCycles[cycle].subscriptions[
        period
      ].units[index].id

    // 상품 정보 변경 전 체크가 되어 있었고, 이미 구매한 상품이 아닌 경우 다시 체크
    if (wasChecked && !disablePurchasedPackage(-1, id)) {
      setSelectedPackages((prev) => [...prev, id])
    }

    // 이미 구매한 상품이면 disabled
    disablePurchasedPackage(-1, id)

    // 바뀐 id가 disabled인지
    disabledLength -= disablePurchasedPackage(-1, id) ? 0 : 1
    setDisabledPackageLength((prev) => prev + disabledLength)

    // 전체 선택 다시 확인
    checkAllStatus(
      products.available.packages.length,
      products.available.bundles.length,
      disabledPackageLength + disabledLength,
      disabledBundleLength,
    )
  }

  function getSelectedBillingCycle(order: number): PackageBillingCycleModel {
    if (products == null) {
      return new PackageBillingCycleModel({} as any, t)
    }
    const {cycle} = rules[order]
    return products.available.packages[order].billingCycles[cycle]
  }

  function getSelectedPeriod(order: number): PackageSubscriptionModel {
    if (products == null) {
      return new PackageSubscriptionModel({} as any, t)
    }
    const {cycle, period} = rules[order]
    return products.available.packages[order].billingCycles[cycle]
      .subscriptions[period]
  }

  function getSelectedAmount(order: number): PackageUnitModel {
    if (products == null) {
      return new PackageUnitModel({} as any, t)
    }
    const {cycle, period, amount} = rules[order]
    return products.available.packages[order].billingCycles[cycle]
      .subscriptions[period].units[amount]
  }

  // CYM: 컴마 추가
  function getPrice(index: number): string {
    if (products === undefined) {
      return String(0)
    }
    const {cycle, period, amount} = rules[index]
    return numberWithCommas(
      products.available.packages[index].billingCycles[cycle].subscriptions[
        period
      ].units[amount].price,
    )
  }

  function allSelectedProducts() {
    const pkgs: PurchasePackage[] = []
    const bnds: PurchaseBundle[] = []
    if (products != null) {
      const a = products.available
      a.packages.forEach((pkg, index) => {
        const {cycle, period, amount} = rules[index]
        const billing = pkg.billingCycles[cycle]
        const subs = billing.subscriptions[period]
        const unit = subs.units[amount]
        const {id} = unit
        const itemIndex = selectedPackages.indexOf(id)

        if (itemIndex > -1) {
          pkgs.push({
            name: pkg.name,
            period: subs.period,
            billingCycle: billing.billingCycle,
            analyses: pkg.analyses,
            analysisGroup: pkg.analysisGroup,
            billingType: pkg.billingType,
            currencyCode: pkg.currencyCode,
            id: `${id}`,
            amount: unit.amount,
            price: unit.price,
          })
        }
      })
      a.bundles.forEach((bnd) => {
        const {id} = bnd
        const itemIndex = selectedBundles.indexOf(id)

        if (itemIndex > -1) {
          bnds.push({
            name: bnd.name,
            id: `${id}`,
            analyses: bnd.analyses,
            analysisGroup: bnd.analysisGroup,
            price: bnd.priceSet.price,
            currency: bnd.priceSet.currency,
            unitPurchase: bnd.priceSet.unitPurchase,
          })
        }
      })
      return {
        packages: pkgs,
        bundles: bnds,
      }
    }
    return {
      packages: pkgs,
      bundles: bnds,
    }
  }

  // 구매하려고 체크한 상품들과 지금 선택한 상품의 포함상품의 중복 확인
  function isDuplicatedProduct(
    index: number,
    compareItems: [AnalysisProduct],
    type: string,
  ) {
    // 중복 체크
    let isDuplicated = false
    const whatItems: string[] = []

    if (
      products != null &&
      (selectedBundles.length > 0 || selectedPackages.length > 0)
    ) {
      const {packages, bundles} = allSelectedProducts()

      // 포함 상품을 담을 변수
      const analysesCode: string[] = []

      // 패키지+번들 변수
      const purchasingProducts = [...packages, ...bundles]

      // 구매 하려고 하는 포함 상품을 모음
      purchasingProducts.forEach((product) => {
        product.analyses.forEach((item) => {
          analysesCode.push(item.code)
        })
      })

      // 중복 제거, 중복 상품 담기
      const analysesCodeArr = Array.from(new Set(analysesCode))

      analysesCodeArr.find((item) => {
        compareItems.forEach((compareItem) => {
          if (compareItem.code === item) {
            isDuplicated = true
            const productText = getPackageFullName(compareItem.code)
            whatItems.push(t(productText))
          }
        })
        return null
      })
    }

    // 중복 확인 후 알람 팝업
    if (isDuplicated) {
      onNoticeDialogOpen({
        title: t('INoticeTitle'),
        message: `
          [${whatItems.join(', ')}] ${t('INoticeDuplicateProductsLong')}`,
      }).then((result) => {
        if (!result.payload) {
          if (type === ProductsTypes.Package) {
            /* 
              setSelectPackage 함수에서 체크박스의 체크를 확인해 selectedPackages 스테이트를 변경해주는데
              시점이 아직 변경이 일어나기 전이라 지금 클릭한 상품은 가지고 있지 않음!
            */
            const beforePackages = selectedPackages.slice()
            setSelectedPackages(beforePackages)

            // 무조건 하나가 체크 해제 되기 때문에 all check에 해당하지 않는다.
            checkAllDefaultStatus()
          } else if (type === ProductsTypes.Bundle) {
            const beforeBundles = selectedBundles.slice()
            setSelectedBundles(beforeBundles)

            // 무조건 하나가 체크 해제 되기 때문에 all check에 해당하지 않는다.
            checkAllDefaultStatus()
          }
        }
      })
    }
  }

  // visibleType 변경하면 선택한 아이템 전부 제거
  useEffect(() => {
    setSelectedPackages([])
    setSelectedBundles([])
    checkAllDefaultStatus()
  }, [visibleType])

  // 선택된 상품 수 변경될 때 마다 확인 버튼 disabled 체크
  useEffect(() => {
    if (selectedPackages.length + selectedBundles.length < 1) {
      setIsDisabledConfirmButton(true)
    } else {
      setIsDisabledConfirmButton(false)
    }
  }, [selectedPackages, selectedBundles])

  return {
    setDefaultProducts,
    getProducts,
    setCycle,
    setPeriod,
    setAmount,
    getPrice,
    getSelectedBillingCycle,
    getSelectedPeriod,
    getSelectedAmount,
    setSelectPackage,
    isPackageSelected,
    setSelectBundle,
    isBundleSelected,
    setAllSelectedToggle,
    checkAllDefaultStatus,
    allSelectedProducts,
    isAllChecked,
    products,
    isProductsLoading,
    setAllPurchasedId,
    disablePurchasedPackage,
    disablePurchasedBundle,
    isDuplicatedProduct,
    visibleType,
    setVisibleType,
    isDisabledConfirmButton,
  }
}
