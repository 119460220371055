import {PatientAddRequest, PatientEditRequest} from 'api/patientApi'
import {
  closeCreateDialog,
  closeReadDialog,
  closeUpdateDialog,
  createPatient,
  openCreateDialog,
  openReadDialog,
  openUpdateDialog,
  selectCreateDialog,
  selectReadDialog,
  selectUpdateDialog,
  updatePatient,
  fetchHdAnalysisTypes,
} from 'features/patient/patientSlice'
import usePatient from 'features/patient/usePatient'
import {useAppDispatch, useAppSelector} from 'hooks'

export function useReadDialog() {
  const dispatch = useAppDispatch()

  const {entity, open, uuid, listProducts} = useAppSelector(selectReadDialog)
  const {onFetch} = usePatient()
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  const onOpen = (uuid: string) => dispatch(openReadDialog(uuid))
  const onClose = () => dispatch(closeReadDialog())

  return {
    entity,
    open,
    uuid,
    listProducts,
    onOpen,
    onClose,
    onFetch,
    onGetProducts,
  }
}

export function useUpdateDialog() {
  const dispatch = useAppDispatch()

  const {entity, open, uuid, listProducts} = useAppSelector(selectUpdateDialog)
  const {onFetch} = usePatient()
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  const onOpen = (uuid: string) => dispatch(openUpdateDialog(uuid))
  const onClose = () => dispatch(closeUpdateDialog())
  const onUpdate = (payload: PatientEditRequest) =>
    dispatch(updatePatient(payload))

  return {
    entity,
    open,
    uuid,
    listProducts,
    onOpen,
    onClose,
    onUpdate,
    onFetch,
    onGetProducts,
  }
}

export function useCreateDialog() {
  const dispatch = useAppDispatch()

  const {open, listProducts} = useAppSelector(selectCreateDialog)

  const onOpen = () => dispatch(openCreateDialog())
  const onClose = () => dispatch(closeCreateDialog())
  const onCreate = (payload: PatientAddRequest) =>
    dispatch(createPatient(payload))
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  return {
    open,
    listProducts,
    onOpen,
    onClose,
    onCreate,
    onGetProducts,
  }
}
