import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {Button, Checkbox, Typography, makeStyles} from '@material-ui/core'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import MemberRegisterStyle from 'pages/CustomerManagement/MemberEdit/Style'
import OrganizationNameInput from 'pages/CustomerManagement/MemberEdit/Form/OrganizationNameInput'
import UserNameInput from 'pages/CustomerManagement/MemberEdit/Form/UserNameInput'
import EmailInput from 'pages/CustomerManagement/MemberEdit/Form/EmailInput'
import CountrySelect from 'pages/CustomerManagement/MemberEdit/Form/CountrySelect'
import AddressInput from 'pages/CustomerManagement/MemberEdit/Form/AddressInput'
import TelephoneNumberInput from 'pages/CustomerManagement/MemberEdit/Form/TelephoneNumberInput'
import SelectedIndustry from 'pages/CustomerManagement/MemberEdit/Form/SelectedIndustry'
import NursingInstitutionInput from 'pages/CustomerManagement/MemberEdit/Form/InstitutionInput'
import BusinessNoInput from 'pages/CustomerManagement/MemberEdit/Form/BusinessNoInput'
import TableHospitalInformation, {
  SelectedListType,
} from 'pages/CustomerManagement/MemberRegistration/Form/TableHospitalInfo'
import useOrgRegister from 'features/invoice/useOrgRegister'
import {
  OrgDetailEditRequest,
  OrgDetailResponse,
  updateOrgDetailApi,
} from 'api/invoiceApis'
import {fetchCountryList} from 'api/commonApi'
import RouteConstant from 'constants/RouteConstant'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

const useStyles = makeStyles(MemberRegisterStyle)

function MemberEditPage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {loading} = useAuth()
  const history = useHistory()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {selected, onFetch} = useOrgRegister()
  const inputClasses = useBoldInputStyle()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const orgId = queryParams.get('orgId')
  const [orgType, setOrgType] = useState(false)
  const [therapyYn, setTherapyYn] = useState(false)
  const [minDate, setMinDate] = useState<Date>()
  const [packageStartDate, setPackageStartDate] = useState<Date | null>(null)
  const [packageExpireDate, setPackageExpireDate] = useState<Date | null>(null)
  const [selectedDetails, setSelectedDetails] = useState<SelectedListType[]>([])

  const getValueView = (dataInput: OrgDetailResponse) => {
    return {
      orgId: dataInput.orgId,
      address: dataInput.address,
      businessNo: dataInput.businessNumber,
      countryId: dataInput.countryId,
      detailIndustry: {
        id: dataInput.detailIndustryId,
        parent: 1,
        depth: 1,
        title: '',
      },
      industryId: dataInput.industryId,
      detailIndustryId: dataInput.detailIndustryId,
      email: dataInput.email,
      firstUserName: dataInput.firstName,
      industry: {
        id: dataInput.industryId,
        parent: 1,
        depth: 1,
        title: '',
      },
      institutionNo: dataInput.institutionNumber,
      lastUserName: dataInput.lastName,
      orgName: dataInput.orgName,
      orgType: dataInput.orgType ? dataInput.orgType : 'NORMAL',
      tel: dataInput.phoneNumber,
      packageExpireDate: dataInput.packageExpireDate,
      packageStartDate: dataInput.packageStartDate,
      therapyYn: dataInput.therapyYn,
    } as OrgDetailEditRequest
  }

  const createMemberInfo = useForm({defaultValues: getValueView(selected)})

  const onUpdateOrgInfo = async (orgInfo: OrgDetailEditRequest) => {
    try {
      const response = await updateOrgDetailApi(orgInfo)
      if (response) {
        onSuccessModalOpen(t('IEditORGSuccessMessage')).then(() => {
          history.push(
            `${RouteConstant.CUSTOMER_INQUIRY.path}?orgId=${orgInfo.orgId}`,
          )
        })
      }
    } catch (e) {
      onFailureModalOpen(e?.message ?? t('IRegisterFail'))
    }
  }

  const handleCreate = (orgData: OrgDetailEditRequest) => {
    if (!orgType) orgData.healthProductIds = null
    onUpdateOrgInfo(orgData)
  }
  const convertStringFromDateTime = (data: Date | null) => {
    if (data) {
      const date = new Date(data)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
    return ''
  }
  const submit = async (data: OrgDetailEditRequest) => {
    const today = new Date()

    if (!packageStartDate && packageExpireDate) {
      data.packageStartDate = convertStringFromDateTime(today)
    } else {
      data.packageStartDate = packageStartDate
        ? convertStringFromDateTime(packageStartDate)
        : null
    }

    data.packageExpireDate = packageExpireDate
      ? convertStringFromDateTime(packageExpireDate)
      : null
    data.therapyYn = therapyYn ? 'Y' : 'N'

    if (orgType && !selectedDetails.length) {
      onFailureModalOpen(t('IStyleNotSelected'))
      return
    }
    const responseCountry = await fetchCountryList()
    const countries = responseCountry.list
    const countryCode =
      countries.find((item) => item.id === data.countryId)?.code || ''
    const arrProductIds = selectedDetails.map((item) => item.productId)
    handleCreate({
      ...data,
      countryCode,
      healthProductIds: arrProductIds.length > 0 ? arrProductIds : null,
    })
  }

  const handleCheckboxProductType = (details: SelectedListType[]) => {
    setSelectedDetails([...details])
  }
  const handleValueStartDate = (data: Date | null) => {
    if (data) {
      const newDate = new Date(data)
      newDate.setDate(newDate.getDate() + 1)
      setMinDate(newDate)
      if (packageExpireDate && data >= packageExpireDate) {
        setPackageExpireDate(null)
      }
    } else {
      const newDate = new Date()
      if (packageExpireDate && new Date() >= packageExpireDate) {
        setPackageExpireDate(null)
      }
      newDate.setDate(newDate.getDate() + 1)
      setMinDate(newDate)
    }
    setPackageStartDate(data)
  }
  const handleValueExpireDate = (data: Date | null) => {
    setPackageExpireDate(data)
  }
  const handleCheckboxChangeOrgType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setOrgType(isChecked)
  }
  const handleCheckboxChangeTherapyYn = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setTherapyYn(isChecked)
  }
  useEffect(() => {
    if (orgId && Number(orgId)) {
      onFetch({id: Number(orgId)})
    }
  }, [])

  useEffect(() => {
    if (selected) {
      createMemberInfo.reset(getValueView(selected))
      if (selected.orgType === 'HEALTH') setOrgType(true)
      else setOrgType(false)

      if (selected.therapyYn === 'Y') setTherapyYn(true)
      else setTherapyYn(false)

      if (selected.packageStartDate) {
        setPackageStartDate(new Date(selected.packageStartDate))
        const newDate = new Date(selected.packageStartDate)
        newDate.setDate(newDate.getDate() + 1)
        setMinDate(newDate)
      } else {
        const newDate = new Date()
        newDate.setDate(newDate.getDate() + 1)
        setMinDate(newDate)
      }

      if (selected.packageExpireDate) {
        setPackageExpireDate(new Date(selected.packageExpireDate))
      }

      setSelectedDetails(selected.healthProductInfoList)
    }
  }, [selected])

  useEffect(() => {
    createMemberInfo.setValue('orgType', orgType ? 'HEALTH' : 'NORMAL')
    if (!orgType) {
      setSelectedDetails([])
    }
  }, [orgType])

  return (
    <>
      {loading && <div>loading</div>}
      <div className={classes.topRoot}>
        <FormProvider {...createMemberInfo}>
          <form
            onSubmit={createMemberInfo.handleSubmit((data) =>
              submit(data as OrgDetailEditRequest),
            )}
          >
            <CardWithTitle
              title={t('IUserInfo')}
              className={classes.formRegister}
            >
              <div className={classes.inputContainer}>
                <div style={{height: 24}}>
                  <Checkbox
                    color='primary'
                    checked={orgType}
                    onChange={handleCheckboxChangeOrgType}
                    style={{height: 24, paddingLeft: 0}}
                  />
                  {t('INoteCenter')}
                </div>
                <OrganizationNameInput />
                <UserNameInput />
                <EmailInput />
                <CountrySelect countryId={getValueView(selected).countryId} />
                <AddressInput />
                <TelephoneNumberInput />
                <SelectedIndustry />
                <BusinessNoInput />
                <NursingInstitutionInput />
                <div style={{height: 24, marginTop: '5px'}}>
                  <Checkbox
                    color='primary'
                    checked={therapyYn}
                    onChange={handleCheckboxChangeTherapyYn}
                    style={{height: 24, paddingLeft: 0}}
                  />
                  {t('IEnablePBMFeature')}
                </div>

                <div>
                  <p
                    className={inputClasses.inputLabel}
                    style={{padding: '5px 0'}}
                  >
                    {t('IAccountUsagePeriod')}
                  </p>
                  <div className={inputClasses.inputContainer}>
                    <div className={inputClasses.inputWrap}>
                      <DatePicker
                        selected={packageStartDate}
                        onChange={(date) => handleValueStartDate(date)}
                        placeholderText='yyyy-MM-dd'
                        className={inputClasses.input}
                      />
                    </div>
                    <div className={inputClasses.inputWrap}>
                      <DatePicker
                        selected={packageExpireDate}
                        onChange={(date) => handleValueExpireDate(date)}
                        placeholderText='yyyy-MM-dd'
                        minDate={minDate}
                        className={inputClasses.input}
                      />
                    </div>
                  </div>
                </div>
                {orgType && (
                  <div className={classes.tableFlexBox}>
                    <div className={classes.tableEdit}>
                      <TableHospitalInformation
                        products={selected.healthProductInfoList}
                        onCheckboxChange={handleCheckboxProductType}
                      />
                    </div>
                  </div>
                )}
                <Typography className={classes.padding} />
                <div className={classes.submitButtonContainer}>
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    type='submit'
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </CardWithTitle>
          </form>
        </FormProvider>
      </div>
      <NoticeConfirmDialog />
    </>
  )
}

export default MemberEditPage
