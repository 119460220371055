/* eslint-disable @typescript-eslint/ban-types */
import Graph from 'lib/EdfGraph/GraphV1'
import Edf, {Header} from 'lib/EdfParser/Edf'
import {MontageFilterType} from 'lib/EdfGraph/MontageFilter'

interface GraphViewModelProps {
  SECONDS_PER_PAGE: number
}
interface EDFResultData {
  header: Header | null
  physicalData: any[]
}

export default class GraphViewModel {
  edfData: EDFResultData

  graph: Graph

  edfFile: Edf

  time: string

  startTime: number

  timeRun: number

  currentScrollPosition: number | null

  oldPosition: number

  speed: number

  pauseAtCurrentTime: number

  pauseAtStartTime: number

  timeHandler?: Function

  playStateHandler?: Function

  resetValueHandler?: Function

  scrollX: number

  SECONDS_PER_PAGE: number

  isFinished: boolean

  // initialize values in the constructor
  constructor({SECONDS_PER_PAGE}: GraphViewModelProps) {
    this.SECONDS_PER_PAGE = SECONDS_PER_PAGE
    this.time = '00:00'
    this.scrollX = 0

    this.startTime = 0
    this.timeRun = 0
    this.currentScrollPosition = null
    this.oldPosition = 0
    this.speed = 1
    this.pauseAtCurrentTime = 0
    this.pauseAtStartTime = 0
    this.isFinished = false
    this.edfData = {
      header: null,
      physicalData: [],
    }
    this.graph = new Graph()
    this.edfFile = new Edf()
  }

  setPlayStateHandler = (handler: Function) => {
    this.playStateHandler = handler
  }

  setResetValueHandler = (handler: Function) => {
    this.resetValueHandler = handler
  }

  setTimeHandler = (handler: Function) => {
    this.timeHandler = handler
  }

  // plots graph on initial loading
  async getEdfDataFromEdfFile(file: File) {
    this.edfFile.init({file})
    const edfData = await this.edfFile.readHeader()
    this.edfData = edfData
    this.isFinished = true
    return edfData
  }

  // eslint-disable-next-line class-methods-use-this
  convertSecToMinuteAndSec(seconds: number): string {
    const secondsToInteger = Math.floor(seconds)
    const minutes: number = Math.floor(secondsToInteger / 60)
    const remainingSeconds: number = secondsToInteger % 60

    // add leading zero to minutes and seconds if they are single digits
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
  }

  secondsWithPadding = (sec: number) => {
    return String(sec).padStart(2, '0')
  }

  updateSecondsPerPage(SECONDS_PER_PAGE: number) {
    this.SECONDS_PER_PAGE = SECONDS_PER_PAGE
  }

  setTime(seconds: number): void {
    const timeInString = this.convertSecToMinuteAndSec(seconds)
    const {timeHandler} = this
    if (timeHandler) {
      timeHandler(timeInString)
    }

    this.time = timeInString
  }

  // function to start the setInterval
  onPlay(containerRef: any, scrollInterval: any, action: string) {
    if (containerRef.current) {
      const myDiv = containerRef.current

      const TOTAL_NUMBER_OF_SECONDS =
        this.edfData?.header?.numberOfDataRecords ?? 0

      const animate = (currentTime: number) => {
        // Animation run first time
        if (!this.startTime && !this.pauseAtCurrentTime) {
          this.startTime = currentTime
        }
        // Animation pause and replay
        else if (this.startTime && this.pauseAtCurrentTime) {
          this.startTime =
            this.pauseAtStartTime + (currentTime - this.pauseAtCurrentTime)
          this.pauseAtCurrentTime = 0
        }
        // Animation playing
        else {
          this.startTime = this.pauseAtStartTime
        }

        // The duration between the current time and the start play time.
        const elapsedTime = currentTime - this.startTime
        const scrollDistance = myDiv.scrollWidth - myDiv.clientWidth
        const totalScrollTime =
          (TOTAL_NUMBER_OF_SECONDS - this.SECONDS_PER_PAGE) * 1000

        // calc scroll position
        const scrollStep = (scrollDistance / totalScrollTime) * elapsedTime

        if (scrollStep === 0) {
          this.oldPosition = 0
        }
        // save old position when handle move track bar
        if (this.currentScrollPosition !== null && !this.oldPosition) {
          this.oldPosition = scrollStep
        }

        // set scroll position for canvas
        myDiv.scrollLeft =
          (this.currentScrollPosition ?? 0) + (scrollStep - this.oldPosition)

        // case play video
        if (scrollDistance > myDiv.scrollLeft) {
          this.pauseAtStartTime = this.startTime
          this.timeRun = currentTime
          scrollInterval.current = requestAnimationFrame(animate)
        }
        // this else block is to reset all values when the time duration finishes
        else {
          this.setTime(0)
          this.pauseAtCurrentTime = 0
          this.pauseAtStartTime = 0
          this.oldPosition = 0
          this.currentScrollPosition = null
          this.startTime = 0
          this.timeRun = 0
          cancelAnimationFrame(scrollInterval.current)
          myDiv.scrollLeft = 0
          const {playStateHandler, resetValueHandler} = this
          if (playStateHandler) {
            playStateHandler(false)
          }
          if (resetValueHandler) {
            resetValueHandler('')
          }
        }
      }

      if (action === 'start') {
        scrollInterval.current = requestAnimationFrame(animate)
      }

      if (action === 'pause') {
        this.pauseAtCurrentTime = this.timeRun
        cancelAnimationFrame(scrollInterval.current)
      }

      if (action === 'reset') {
        this.setTime(0)
        this.pauseAtCurrentTime = 0
        this.pauseAtStartTime = 0
        cancelAnimationFrame(scrollInterval.current)
        myDiv.scrollLeft = 0
      }
    }
  }

  handleSlide = (
    event: React.ChangeEvent<HTMLInputElement>,
    containerRef: any,
  ): number => {
    const value = parseFloat(event.currentTarget.value)
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: value,
      })
    }
    this.oldPosition = 0
    this.currentScrollPosition = value
    return value
  }

  handleScroll = (event: React.UIEvent<HTMLDivElement>): number => {
    const {scrollLeft} = event.currentTarget
    return scrollLeft
  }

  getGraph() {
    return this.graph
  }

  updateAmplitudeFactor(uV: number) {
    const value = Number(uV) * 0.01
    const scaleValue = Number(value.toFixed(2))
    if (value > 1) {
      const scaleCalcul = 1 - scaleValue * 0.13
      const plusScale = scaleCalcul < 0 ? 0.1 : scaleCalcul
      this.graph.setAmplitudeFactor(plusScale)
    }
    if (value === 1) {
      this.graph.setAmplitudeFactor(1)
    }
    if (value < 1) {
      this.graph.setAmplitudeFactor(1 + (1 - scaleValue))
    }
  }

  updateMontageFilter(type: MontageFilterType) {
    this.graph.setMontageFilter(type)
  }

  setSpeed(value: number) {
    this.speed = value
  }
}
