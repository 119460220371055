import {
  CreateVisitNumberPatientRequest,
  PatientAddRequest,
  PatientEditRequest,
} from 'api/patientApi'

import {
  closeReadWhiteListDialog,
  openReadWhiteListDialog,
  openRegistrationWhiteListDialog,
  closeRegistrationWhiteListDialog,
  selectReadDialog,
  selectRegistrationDialog,
  fetchHdAnalysisTypes,
  registrationPatientWhiteList,
  selectUpdateDialog,
  openUpdateWhiteListDialog,
  closeUpdateWhiteListDialog,
  updatePatientWhiteList,
  registrationVisitNumberPatientWhiteList,
} from 'features/patient/patientWhiteListSlice'
import usePatient from 'features/patient/usePatient'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useWhiteListReadDialog() {
  const dispatch = useAppDispatch()

  const {entity, open, uuid, listProducts} = useAppSelector(selectReadDialog)
  const {openRegistration} = useAppSelector(selectRegistrationDialog)
  const {onFetch} = usePatient()
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  const onOpen = (uuid: string) => dispatch(openReadWhiteListDialog(uuid))
  const onClose = () => dispatch(closeReadWhiteListDialog())

  return {
    entity,
    open,
    openRegistration,
    uuid,
    listProducts,
    onOpen,
    onClose,
    onFetch,
    onGetProducts,
  }
}

export function useWhiteListUpdateDialog() {
  const dispatch = useAppDispatch()

  const {entity, open, uuid, listProducts} = useAppSelector(selectUpdateDialog)
  const {onFetch} = usePatient()
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  const onOpen = (uuid: string) => dispatch(openUpdateWhiteListDialog(uuid))
  const onClose = () => dispatch(closeUpdateWhiteListDialog())
  const onUpdate = (payload: PatientEditRequest) =>
    dispatch(updatePatientWhiteList(payload))

  return {
    entity,
    open,
    uuid,
    listProducts,
    onOpen,
    onClose,
    onUpdate,
    onFetch,
    onGetProducts,
  }
}

export function useWhiteListCreateDialog() {
  const dispatch = useAppDispatch()

  const {openRegistration, listProducts} = useAppSelector(
    selectRegistrationDialog,
  )

  const onOpen = () => dispatch(openRegistrationWhiteListDialog())
  const onClose = () => dispatch(closeRegistrationWhiteListDialog())
  const onCreate = (payload: PatientAddRequest) =>
    dispatch(registrationPatientWhiteList(payload))
  const onCreateVisitNumber = (payload: CreateVisitNumberPatientRequest) =>
    dispatch(registrationVisitNumberPatientWhiteList(payload))
  const onGetProducts = (orgId: string) => dispatch(fetchHdAnalysisTypes(orgId))

  return {
    open: openRegistration,
    listProducts,
    onOpen,
    onClose,
    onCreate,
    onCreateVisitNumber,
    onGetProducts,
  }
}
