import {createStyles} from '@material-ui/core'
import {
  dangerColor,
  defaultBoxShadow,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
} from 'assets/tss/material-dashboard-pro-react'
import {Colors} from 'components/common/useBaseStyle'

const headerStyle = createStyles({
  appBar: {
    backgroundColor: Colors.primary,
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    // position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: 1039,
    color: 'white',
    border: '0',
    borderRadius: '0px !important',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    // ...containerFluid,
    minHeight: '50px',
    margin: 50,
  },
  flex: {
    flex: 1,
  },

  appResponsive: {
    color: 'white',
  },
  translationWrap: {
    color: 'white',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 110,
    height: 50,
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Colors.primary,
      background: 'transparent',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.primary,
      background: 'transparent',
    },
    '&:focus': {
      boxShadow: 'none',
      background: 'transparent',
    },
  },

  // @ts-ignore
  navBarTitle: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: 30,
    borderRadius: '3px',
    textTransform: 'none',
    color: 'white',
    paddingTop: '0.625rem',
    letterSpacing: 'unset',
    textAlign: 'left',
    fontWeight: 600,
    marginLeft: 60,
    marginTop: 20,
  },
  navBarSubtitle: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: 15,
    borderRadius: '3px',
    textTransform: 'none',
    color: 'white',
    letterSpacing: 'unset',
    textAlign: 'left',
    fontWeight: 600,
    marginLeft: 60,
  },
  navItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 50,
    alignContent: 'center',
    alignItems: 'center',
  },
  navItemWrap: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: 50,
    alignContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginRight: 55,
  },

  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  root: {
    // position: 'relative',
  },
  dropdown: {
    width: 150,
    // height: 85,
    position: 'absolute',
    top: 50,
    right: 20,
    zIndex: 1,
    border: '1px solid #ebebeb',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: '4px 10px',
  },
  translationClick: {
    position: 'relative',
  },
  translationText: {
    color: '#919191',
    padding: 3,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: '#f8f9fa',
      background: 'transparent',
    },
  },
  dividerPadding: {
    marginTop: 5,
    marginBottom: 5,
  },
})

export default headerStyle
