import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useAppSelector} from 'hooks'
import {format, parse} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import DatePicker from 'react-datepicker'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

import useFailureModal from 'features/modal/useFailureModal'
import useInvoiceView from 'features/invoice/useInvoiceView'
import {
  fileAttachmentInvoiceAPI,
  getInvoiceFollowMonthAPI,
} from 'api/invoiceApis'
import {fileDownloadData} from 'api'

import CardWithTitle from 'components/atoms/CardWithTitle'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import AsyncButton from 'components/atoms/Buttons/AsyncButton'
import DefaultBilling from 'pages/CustomerManagement/InvoiceView/Elements/DefaultBilling'
import UsageSummary from 'pages/CustomerManagement/InvoiceView/Elements/UsageSummary'
import TransactionStatement from 'pages/CustomerManagement/InvoiceView/Elements/TransactionStatement'
import AppLoading from 'components/atoms/Loading'
import {Locale} from 'components/atoms/TranslationSelect'

import {STATUS_INVOICE} from 'pages/CustomerManagement/InvoiceSetting/constant'
import UsageStatus from 'pages/CustomerManagement/InvoiceView/Elements/UsageStatus'
import InvoiceViewStyle from 'pages/CustomerManagement/InvoiceView/Style'
import RouteConstant from 'constants/RouteConstant'

import {
  openConfirmOpenInvoiceSettingDialog,
  selectConfirmInvoiceSettingDialogOpen,
} from 'features/modal/modalSlice'

import {
  formatDateGetApi,
  formatDateInvoice,
  getMonthYear,
} from 'helpers/dateHelper'
import useSuccessModal from 'features/modal/useSuccessModal'
import 'react-datepicker/dist/react-datepicker.css'

const useStyles = makeStyles(InvoiceViewStyle)

function InvoiceView() {
  const {t, i18n} = useTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [status, setStatus] = useState(1)
  const [usageMonth, setUsageMonth] = useState<string>(
    queryParams.get('usageMonth') || '',
  )
  const [orgIdPath, setOrgIdPath] = useState<string>('')
  const [invoiceId, setInvoiceId] = useState<string | number>('')
  const parsedDate = usageMonth
    ? parse(usageMonth, 'dd/MM/yyyy', new Date())
    : new Date()
  const [date, setDate] = useState(parsedDate)
  const [versionId, setVersionId] = useState<number | null>(null)
  const {
    error,
    consumer,
    patchInvoiceData,
    listVersion,
    billingSummary,
    isEmptyTemp,
    confirmInvoice,
    onInitInvoiceViewData,
    onInitEmptyInvoice,
    callAPIUpdateInvoice,
    callAPICreateInvoice,
    settingInvoiceDataAction,
    changeVersionAction,
    resetInvoiceDataAction,
    setError,
    loading,
  } = useInvoiceView()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const closeDialogConfirmGoToSetting = useAppSelector(
    selectConfirmInvoiceSettingDialogOpen,
  )
  const handleInputInvoiceFile = async (file: File) => {
    if (!file) return
    try {
      await fileAttachmentInvoiceAPI({
        invoiceId: Number(invoiceId),
        data: file,
      })
      await fileDownloadData({
        path: `/cs/api/v1/invoice/${invoiceId}/file-attachment`,
        params: {
          invoiceId: invoiceId ?? null,
        },
        fileName: 'invoice.pdf',
      })
    } catch (error) {
      onFailureModalOpen(t('IInvalidImageFileRequest'))
    }
  }
  const listVersionTemplate = useMemo(() => {
    if (listVersion?.length) {
      if (orgIdPath) {
        changeVersionAction(listVersion[listVersion.length - 1]?.id)
        setVersionId(listVersion[listVersion.length - 1]?.id)
      } else if (billingSummary?.productId) {
        setVersionId(billingSummary?.productId)
      }
      return listVersion.map((item) => ({
        label: item.label,
        id: item.id,
      }))
    }
    return []
  }, [listVersion, billingSummary])

  const onChangeDate = async (dateInput: Date | null) => {
    if (!dateInput) return
    setDate(dateInput)
    const idOrgCurrentInvoice = orgIdPath || consumer?.orgId
    try {
      const resInvoiceDate = await getInvoiceFollowMonthAPI(
        Number(idOrgCurrentInvoice),
        formatDateGetApi(dateInput),
      )
      if (resInvoiceDate.data.id) {
        setOrgIdPath('')
        history.replace(
          `${RouteConstant.INVOICE_VIEW.path}?invoiceId=${
            resInvoiceDate.data.id
          }&usageMonth=${formatDateInvoice(dateInput)}`,
        )
        setInvoiceId(resInvoiceDate.data.id)
      } else if (orgIdPath) {
        setInvoiceId('')
        resetInvoiceDataAction()
        history.replace(
          `${
            RouteConstant.INVOICE_VIEW.path
          }?orgId=${orgIdPath}&usageMonth=${formatDateInvoice(dateInput)}`,
        )
      }
    } catch (error) {
      setInvoiceId('')
      resetInvoiceDataAction()
      history.replace(
        `${
          RouteConstant.INVOICE_VIEW.path
        }?orgId=${idOrgCurrentInvoice}&usageMonth=${formatDateInvoice(
          dateInput,
        )}`,
      )
    }
  }

  const onChangeStatusInvoice = (
    event: React.ChangeEvent<{value: unknown}>,
  ) => {
    const newData = {...patchInvoiceData}
    newData.isConfirm = event.target.value === 2
    settingInvoiceDataAction(newData)
    setStatus(event.target.value as number)
  }

  const onChangeVersionTemplate = (
    event: React.ChangeEvent<{value: unknown}>,
  ) => {
    setVersionId(event.target.value as number)
    const newData = {...patchInvoiceData}
    newData.productId = event.target.value as number
    settingInvoiceDataAction(newData)
    changeVersionAction(event.target.value as number)
  }

  const handleSaveBillingDetail = async () => {
    try {
      if (Number(invoiceId)) {
        const res = await callAPIUpdateInvoice(Number(invoiceId))
        if (res?.payload === true)
          onSuccessModalOpen(t('IMessageUpdateInvoiceSuccess')).then(() => {
            history.push(RouteConstant.INVOICES_LIST.path)
          })
      } else if (orgIdPath && versionId && date) {
        const res = await callAPICreateInvoice({
          orgId: parseInt(orgIdPath, 10),
          productId: versionId,
          usageMonth: format(date, 'yyyyMM'),
        })
        if (res?.payload === true)
          onSuccessModalOpen(
            t('IMessageCreateInvoiceSuccess', {
              month: getMonthYear(date, i18n.language as Locale),
            }),
          ).then(() => {
            history.push(RouteConstant.INVOICES_LIST.path)
          })
      }
    } catch (error) {
      onFailureModalOpen(t('IErrorMessageInvoiceExisting'))
    }
  }

  const componentRef = useRef<HTMLDivElement>(null)

  const cropImage = (
    imgData: string,
    startX: number,
    startY: number,
    width: number,
    height: number,
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = imgData
      image.onload = () => {
        const tempCanvas = document.createElement('canvas')
        const tempCtx = tempCanvas.getContext('2d')
        if (!tempCtx) {
          reject(new Error('Could not get canvas context'))
          return
        }
        tempCanvas.width = width
        tempCanvas.height = height
        tempCtx.drawImage(
          image,
          startX,
          startY,
          width,
          height,
          0,
          0,
          width,
          height,
        )
        const croppedImageData = tempCanvas.toDataURL('image/jpeg')
        resolve(croppedImageData)
      }
      image.onerror = () => {
        reject(new Error('Failed to load image'))
      }
    })
  }

  const HEIGHT_A4_PX = 1836
  const WIDTH_CONTENT_PX = 1280
  const HEIGHT_A4_MM = 287
  const WIDTH_A4_MM = 200
  const MARGIN_CONTENT_MM = 5
  const SCALE = 3

  const generatePDFImage = async (
    index: number,
    scale: number,
    imgData: string,
    pdf: JsPDF,
    heightPx: number,
  ) => {
    if (index > 0) {
      pdf.addPage()
    }
    await cropImage(
      imgData,
      0,
      index * HEIGHT_A4_PX * scale,
      WIDTH_CONTENT_PX * scale,
      (heightPx > HEIGHT_A4_PX ? HEIGHT_A4_PX : heightPx) * scale,
    )
      .then(async (croppedImage1) => {
        pdf.addImage(
          croppedImage1,
          'JPEG',
          MARGIN_CONTENT_MM,
          MARGIN_CONTENT_MM,
          WIDTH_A4_MM,
          heightPx > HEIGHT_A4_PX
            ? HEIGHT_A4_MM
            : (HEIGHT_A4_MM * heightPx) / HEIGHT_A4_PX,
        )
        heightPx -= heightPx > HEIGHT_A4_PX ? HEIGHT_A4_PX : heightPx
        if (heightPx > 0) {
          pdf = await generatePDFImage(index + 1, scale, imgData, pdf, heightPx)
        }
      })
      .catch((error) => {
        console.error(`Error cropping image ${index}:`, error)
      })
    return pdf
  }

  const handleDownloadPdf = async () => {
    const input = componentRef.current
    if (!input) return

    const canvas = await html2canvas(input, {
      scale: SCALE,
      width: WIDTH_CONTENT_PX,
      height: input.scrollHeight,
    })
    const imgData = canvas.toDataURL('image/jpeg')

    const pdf = new JsPDF('portrait', 'mm', 'a4')
    const result = await generatePDFImage(
      0,
      SCALE,
      imgData,
      pdf,
      input.scrollHeight,
    )
    const pdfBlob = result.output('blob')
    const file = new File([pdfBlob], 'invoice.pdf', {type: 'application/pdf'})
    handleInputInvoiceFile(file)
  }

  useEffect(() => {
    const initUsageMonth = queryParams.get('usageMonth')
    const initOrgIdPath = queryParams.get('orgId')
    const initInvoiceId = queryParams.get('invoiceId')
    if (initUsageMonth) setUsageMonth(initUsageMonth)
    if (initOrgIdPath) setOrgIdPath(initOrgIdPath)
    if (initInvoiceId) setInvoiceId(initInvoiceId)
  }, [])

  useEffect(() => {
    const initOrgIdPath = queryParams.get('orgId')
    if (initOrgIdPath) setOrgIdPath(initOrgIdPath)
  }, [queryParams.get('orgId')])

  useEffect(() => {
    if (invoiceId && Number(invoiceId)) onInitInvoiceViewData(Number(invoiceId))
    else if (orgIdPath) onInitEmptyInvoice(Number(orgIdPath))
  }, [usageMonth, invoiceId, orgIdPath])

  useEffect(() => {
    if (isEmptyTemp) {
      dispatch(openConfirmOpenInvoiceSettingDialog())
    }
  }, [isEmptyTemp])

  useEffect(() => {
    if (!closeDialogConfirmGoToSetting && isEmptyTemp)
      history.push(`${RouteConstant.INVOICE_SETTING.path}?orgId=${orgIdPath}`)
  }, [closeDialogConfirmGoToSetting])

  useEffect(() => {
    if (invoiceId) setStatus(confirmInvoice?.confirmed ? 2 : 1)
  }, [confirmInvoice])

  useEffect(() => {
    if (error) {
      onFailureModalOpen(error?.message || error || t('IProcessFail')).then(
        () => {
          dispatch(setError(null))
        },
      )
    }
  }, [error])

  return (
    <>
      <AppLoading loading={loading} />
      {loading && <div>loading</div>}
      <div className={classes.topRoot}>
        <div className={classes.container}>
          <CardWithTitle
            title={t('IBillingDetails')}
            className={classes.invoiceView}
          >
            <div className={classes.InvoiceSettingContainer}>
              <div className={classes.invoiceAction}>
                <div style={{width: 150}}>
                  <DatePicker
                    selected={date}
                    onChange={(date) => onChangeDate(date)}
                    dateFormat='MM/yyyy'
                    showMonthYearPicker
                    maxDate={new Date()}
                  />
                </div>
                <FormControl variant='outlined'>
                  <Select
                    value={status}
                    displayEmpty
                    onChange={(event) => onChangeStatusInvoice(event)}
                    className={classes.searchSelect}
                    input={<OutlinedInput />}
                  >
                    {STATUS_INVOICE?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant='outlined'>
                  <Select
                    value={versionId}
                    displayEmpty
                    onChange={(event) => onChangeVersionTemplate(event)}
                    className={classes.searchSelect}
                    input={<OutlinedInput />}
                  >
                    {listVersionTemplate.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <AsyncButton
                  variant='contained'
                  disableElevation
                  color='primary'
                  onClick={handleSaveBillingDetail}
                >
                  {invoiceId ? t('IEdit') : t('IProduce')}
                </AsyncButton>
                <ActionButton
                  variant='contained'
                  disableElevation
                  color='primary'
                  onClick={() => {
                    handleDownloadPdf()
                  }}
                >
                  {t('ICreateInvoice')}
                </ActionButton>
              </div>
              <div className={classes.invoiceViewInfo} ref={componentRef}>
                <p className={classes.titleInvoice}>
                  <span className={classes.titleMonthInvoice}>{`${getMonthYear(
                    date,
                    i18n.language as Locale,
                  )}`}</span>
                  {`  ${t('ITitleInvoice')}`}
                </p>
                <TransactionStatement />
                <DefaultBilling />
                <UsageSummary />
                <UsageStatus />
              </div>
            </div>
          </CardWithTitle>
        </div>
      </div>
      <NoticeConfirmDialog />
    </>
  )
}

export default InvoiceView
