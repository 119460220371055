import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {parse} from 'date-fns'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from 'components/Table/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import * as storage from 'api/storageApi'
import {formatDateInvoice} from 'helpers/dateHelper'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RouteConstant from 'constants/RouteConstant'
import {DEFAULT_DATE_SELECT} from 'constants/HistoryConstant'
import TableToolbar from 'pages/OrgPage/AccountUsageHistory/TableToolbar'
import useFailureModal from 'features/modal/useFailureModal'
import useOrganization from 'features/org/useOrganization'
import AppLoading from 'components/atoms/Loading'
import useStyles from 'components/Table/useTableStyles'
import {isOrganizationHealth} from 'Authority'

interface ListHeaderTableType {
  id: number
  title: string
  key: keyof ListAccount
}
interface ListAccount {
  email: string
  eeg?: number
  hrv?: number
  pbm?: number
  eegNorm?: number
  eegNorm3d?: number
  eeg3D?: number
  eegSummary?: number
  eegSummaryAdv?: number
  hrvSummary?: number
  hrvSummaryAdv?: number
  healthBasic?: number
  healthStandard?: number
  healthPremium?: number
  healthPremiumPlus?: number
  healthHrvOnly?: number
  healthPremiumHrv?: number
  healthPremiumPlusHrv?: number
  healthStandardHrv?: number
  healthBasicHrv?: number
}
const AccountUsageHistory = () => {
  const classes = useStyles()
  const {onOpen: openFailModal} = useFailureModal()
  const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const {onFetchAccountById, onFetchUsageHistoryByAccount} = useOrganization()
  const organizationType = storage.getOrganizationType()
  const isHealthOrg = isOrganizationHealth(organizationType)
  const queryParams = new URLSearchParams(location.search)
  const usageMonth = queryParams.get('usageMonth') ?? DEFAULT_DATE_SELECT
  const getKeyword = queryParams.get('keyword') ?? ''
  const parsedDate = usageMonth
    ? parse(usageMonth, 'dd/MM/yyyy', new Date())
    : new Date()
  const [date, setDate] = useState(parsedDate)
  const [listAccount, setListAccount] = useState<ListAccount[]>([])
  const [originalArray, setOriginalArray] = useState<ListAccount[]>([])
  const [keyword, setKeyword] = useState(getKeyword)
  const [loading, setLoading] = useState<boolean>(false)

  const [listHeaderTable, setListHeaderTable] = useState<ListHeaderTableType[]>(
    [{id: 0, title: t('IEmail'), key: 'email'}],
  )
  const listAllHeaderTable: ListHeaderTableType[] = [
    {id: 1, title: 'EEG', key: 'eeg'},
    {id: 2, title: 'HRV', key: 'hrv'},
    {id: 3, title: 'PBM', key: 'pbm'},
    {id: 4, title: 'NORM', key: 'eegNorm'},
    {id: 5, title: 'NORM 3D', key: 'eegNorm3d'},
    {id: 6, title: '3D', key: 'eeg3D'},
    {id: 7, title: 'EEG S', key: 'eegSummary'},
    {id: 8, title: 'EEG SA', key: 'eegSummaryAdv'},
    {id: 9, title: 'HRV S', key: 'hrvSummary'},
    {id: 10, title: 'HRV SA', key: 'hrvSummaryAdv'},
    {id: 11, title: 'AI Basic', key: 'healthBasic'},
    {id: 12, title: 'AI Standard', key: 'healthStandard'},
    {id: 13, title: 'AI Premium', key: 'healthPremium'},
    {id: 14, title: 'AI Premium Plus', key: 'healthPremiumPlus'},
    {id: 15, title: 'AI HRV', key: 'healthHrvOnly'},
    {id: 16, title: 'AI Premium HRV', key: 'healthPremiumHrv'},
    {id: 17, title: 'AI Premium Plus HRV', key: 'healthPremiumPlusHrv'},
    {id: 18, title: 'AI Standard HRV', key: 'healthStandardHrv'},
    {id: 19, title: 'AI Basic HRV', key: 'healthBasicHrv'},
  ]

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setDate(date)
    }
  }

  const handleSearch = (temp?: ListAccount[]) => {
    const data = temp ?? originalArray
    const filteredRecords = data?.filter((record: ListAccount) =>
      record.email.toLowerCase().includes(keyword.toLowerCase()),
    )
    setListAccount(filteredRecords)
  }

  const handleChangeKeyword = (keyword: string) => {
    setKeyword(keyword)
    handleSearch()
  }

  const generateOutput = (
    userUsages: AccountUsageHistory[],
    users: AccountById[],
    listHeader: ListHeaderTableType[],
  ) => {
    return users.map((user: AccountById) => {
      const usageData = userUsages.find(
        (uu: AccountUsageHistory) => uu.userId === user.userId.toString(),
      )

      if (!usageData) {
        return listHeader.reduce((acc, item) => {
          acc[item.key] = item.key === 'email' ? user.email : 0
          return acc
        }, {} as any)
      }
      const findData = (key: string) => {
        return (
          usageData.usages.find((u: UsageHistory) => u.type === key)
            ?.totalCount || 0
        )
      }
      return listHeader.reduce((acc, item) => {
        acc[item.key] = item.key === 'email' ? user.email : findData(item.key)
        return acc
      }, {} as any)
    })
  }
  const generateHeader = (data: UsageHistory[]) => {
    const listHeaderDefault = listHeaderTable
    const listHeaderFilter = listAllHeaderTable.filter((item) =>
      data.some((element) => item.key === element.type),
    )
    return [...listHeaderDefault, ...listHeaderFilter]
  }
  const getAccountHistory = async () => {
    setLoading(true)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const output = `${year}${month}`

    const payload = {
      periodBegin: output,
      periodEnd: output,
    }
    try {
      const responseHistory = await onFetchUsageHistoryByAccount(payload)
      const listUserId = responseHistory.map((item) => {
        return item.userId
      })

      const payloadAccount = {userId: listUserId.join(',')}
      const responseAccount = await onFetchAccountById(payloadAccount)
      const generateLHeader = generateHeader(responseHistory[0].usages)
      const output = generateOutput(
        responseHistory,
        responseAccount.list,
        generateLHeader,
      )

      setListHeaderTable([...generateLHeader])
      setOriginalArray(output)
      setListAccount(output)
      handleSearch(output)
    } catch (e) {
      openFailModal(e as string)
    }
    setLoading(false)
  }

  const replaceUrl = () => {
    history.replace(
      `${RouteConstant.ORG_ACCOUNT.path}?usageMonth=${formatDateInvoice(
        date,
      )}&keyword=${keyword}`,
    )
  }

  useEffect(() => {
    getAccountHistory()
    replaceUrl()
  }, [date])

  useEffect(() => {
    handleSearch()
    replaceUrl()
  }, [keyword])

  return (
    <div>
      <AppLoading loading={loading} />
      <CardWithTitle title={t('IAcountUsageHistory')}>
        <TableToolbar
          handleChangeDate={handleChangeDate}
          date={date}
          handleChangeKeyword={handleChangeKeyword}
          keyword={keyword}
        />
        {!loading && (
          <TableContainer>
            <Table className={classes.table} size='small'>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  {listHeaderTable.map((item: ListHeaderTableType) => (
                    <TableCell align='center' key={item.id}>
                      {item.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listAccount.map((itemData: ListAccount) => (
                  <TableRow key={itemData.email}>
                    {listHeaderTable.map((itemHeader: ListHeaderTableType) => (
                      <TableCell align='center' key={itemHeader.id}>
                        {itemData[itemHeader.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {!listAccount.length && (
                  <TableRow
                    style={{
                      height: '250px',
                      backgroundColor: '#F9F9FB',
                    }}
                  >
                    <TableCell colSpan={listHeaderTable.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardWithTitle>
    </div>
  )
}
export default AccountUsageHistory
