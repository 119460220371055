import {fetchCareSummaryReportApi} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import TranslationSelect from 'components/atoms/TranslationSelect'
import {getQueryFromUrl, isDefined, isNotDefined} from 'helpers/commonHelper'
import usePrevious from 'hooks/usePrevious'
import {
  ReportCustomPadding,
  ReportFetcherWrap,
  ReportTranslationSelectWrap,
  ReportWrap,
} from 'pages/Analysis/EEGIndividual/Report'
import Report from 'pages/OrgPage/TreatmentPage/TreatmentSummary/Report'
import React, {useEffect} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useLocation, useRouteMatch} from 'react-router-dom'

import useAuth from 'features/auth/useAuth'

function TreatmentSummary() {
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()

  const location = useLocation()
  const queryParms = getQueryFromUrl(location.search)

  const {user: currentUser} = useAuth()

  const {t, i18n} = useTranslation()

  const prevLang = usePrevious(i18n.language)
  // const prevLang = usePrevious(selectedLang.lang)

  console.log(`current_user : ${currentUser.uid}`)
  console.log(`params : ${JSON.stringify(queryParms)}`)

  const asyncResult = useAsync(
    fetchCareSummaryReportApi,
    [
      {
        careId: id,
        requestId: queryParms?.requestId,
        hrvRequestId: queryParms?.hrvRequestId,
        // @ts-ignore
        uid: currentUser.uid ?? '',
        summaryLang: i18n.language as LanguageCode,
      },
    ],
    {
      executeOnMount: false,
      executeOnUpdate: false,
    },
  )

  useEffect(() => {
    if (isNotDefined(prevLang)) {
      asyncResult.execute({
        careId: id,
        requestId: queryParms?.requestId,
        hrvRequestId: queryParms?.hrvRequestId,
        uid: currentUser?.uid ?? '',
        summaryLang: i18n.language as LanguageCode,
      })
      return
    }

    // if (isDefined(prevLang) && prevLang !== i18n.language) {
    console.log('will send : ', prevLang)
    if (isDefined(prevLang)) {
      console.log('sent : ', prevLang)
      let lang: string
      if (prevLang === 'ja') {
        lang = 'jp'
      } else {
        lang = prevLang
      }
      asyncResult.execute({
        careId: id,
        requestId: queryParms?.requestId,
        hrvRequestId: queryParms?.hrvRequestId,
        uid: currentUser?.uid ?? '',
        summaryLang: lang as LanguageCode,
      })
    }
    // [id, i18n.language, prevLang]
  }, [id, i18n.language, prevLang])

  return (
    <ReportWrap style={{width: '1550px', margin: '0 auto'}}>
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <ReportCustomPadding />
        <CardWithTitle
          title={String(t('ISummaryReport'))}
          style={{width: '100%'}}
        >
          {asyncResult.loading && <div>{t('ILoading')}</div>}
          {!asyncResult.loading && isDefined(asyncResult.error) && (
            <div>{asyncResult.error}</div>
          )}
          {!asyncResult.loading && isDefined(asyncResult.result) && (
            <Report {...asyncResult.result.data} />
          )}
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default TreatmentSummary
