import {TableCellProps} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import {Colors} from 'components/common/useBaseStyle'
import TableCell from 'components/Table/TableCell'
import React from 'react'
import styled from 'styled-components'

const StyledTableCell = styled(TableCell)`
  & .MuiSvgIcon-root {
    color: ${Colors.primary};
  }
`

const TableCellShortStatus = ({
  status,
  ...props
}: TableCellProps & {status: '-' | 'o'}) => (
  <StyledTableCell {...props}>
    {status === 'o' && <CheckIcon fontSize='small' />}
  </StyledTableCell>
)

export default TableCellShortStatus
