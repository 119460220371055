import {
  ThreeDAnalysisType,
  ThreeDPowerResponse,
  ThreeDSideType,
} from 'api/analysisApi'
import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import React, {useMemo} from 'react'
import styled from 'styled-components'

const SideMap: Record<ThreeDSideType, string> = {
  L: 'Left Side',
  B: 'Both Side',
  R: 'Right Side',
}

const SidePriorityMap: Record<ThreeDSideType, number> = {
  L: 3,
  B: 2,
  R: 1,
}

interface StyledTabItemProps {
  isActive: boolean
  loading: boolean
  theme: ThreeDTheme
}

const getButtonBorderColor = (theme: ThreeDTheme, isActive: boolean) => {
  if (isActive) {
    return '#3ebcbd'
  }
  return theme === 'Light' ? '#e7eaec' : '#333'
}

const getButtonBGColor = (theme: ThreeDTheme, isActive: boolean) => {
  if (isActive) {
    return '#3ebcbd'
  }
  return theme === 'Light' ? '#fff' : 'transparent'
}

const StyledTabItem = styled.li<StyledTabItemProps>`
  flex: 1;

  opacity: ${(props) => (props.loading ? 0.4 : 1)};

  & button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      getButtonBorderColor(props.theme, props.isActive)};
    font-size: 13px;
    padding: 6px 12px;
    color: ${(props) => (props.isActive ? '#fff' : '#666')};
    background-color: ${(props) =>
      getButtonBGColor(props.theme, props.isActive)};
  }
`

interface ThreeDSideTabItemProps {
  theme: ThreeDTheme
  loading: boolean
  value: ThreeDSideType
  current: ThreeDSideType
  onPowerChange: (power: ThreeDSideType) => Promise<void>
}

function ThreeDSideTabItem({
  theme,
  loading,
  value,
  current,
  onPowerChange,
}: ThreeDSideTabItemProps) {
  const handlePowerChange = () => onPowerChange(value)
  return (
    <StyledTabItem isActive={value === current} loading={loading} theme={theme}>
      <button type='button' onClick={handlePowerChange} disabled={loading}>
        {SideMap[value]}
      </button>
    </StyledTabItem>
  )
}

const StyledUl = styled.ul`
  overflow: hidden;
  list-style: none;
  padding: 0;
  display: flex;
  //width: 100%;
  margin: 0 0 15px;
  width: 300px;
`

interface ThreeDSideTabProps {
  theme: ThreeDTheme
  loading: boolean
  analysis_type: ThreeDAnalysisType
  side_type: ThreeDSideType
  supported_power: ThreeDPowerResponse[]
  onSideChange: (side: ThreeDSideType) => Promise<void>
}

function SideTab({
  theme,
  loading,
  analysis_type,
  side_type,
  supported_power,
  onSideChange,
}: ThreeDSideTabProps) {
  const sideList = useMemo(
    () =>
      supported_power
        .filter((power) => power.analysisType === analysis_type)
        .map((power) => power.sideType)
        .sort((a, b) => {
          const aPriority = SidePriorityMap[a]
          const bPriority = SidePriorityMap[b]
          return bPriority - aPriority
        }),
    [supported_power, analysis_type],
  )

  return (
    <StyledUl>
      {sideList.map((side) => (
        <ThreeDSideTabItem
          theme={theme}
          loading={loading}
          current={side_type}
          value={side}
          key={side}
          onPowerChange={onSideChange}
        />
      ))}
    </StyledUl>
  )
}

export default SideTab
