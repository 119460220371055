import React, {useEffect} from 'react'
import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import useStyles from 'components/Dialog/User/Style'
import {loadTossPayments} from '@tosspayments/payment-sdk'
import {numberWithCommas} from 'helpers/commonHelper'
import {
  closePackagePaymentDialog,
  closePackagePurchaseDialog,
  openPackagePaymentDialog,
  selectPackagePaymentOpen,
} from 'features/modal/modalSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {sha256} from 'js-sha256'
import UnPaidOrderModel from 'pages/OrgPage/PaymentManagementPages/Models/UnPaidOrderModel'
import OrderResponseModel from 'pages/OrgPage/PaymentManagementPages/Models/OrderResponseModel'
import {
  ModalBody,
  ModalContentBody,
  ModalContentWrap,
  ModalFooter,
  ModalHeaderBorderBottom,
  ModalTitleBox,
  ModalWrap,
  TitleText,
} from './CommonDialogStyle'
import useViewModel from './ViewModels/PaymentViewModel'
import {
  eximBayReqHost,
  getNewApiHost,
  isProd,
  isStage,
} from '../../helpers/envHelper'
import useAuth from '../../features/auth/useAuth'

const ModalBodyPayment = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
`

const ModalContentWrapPayment = styled(ModalContentWrap)`
  overflow: hidden;
`

const ModalContentBodyPayment = styled(ModalContentBody)`
  height: 100%;
  overflow: hidden;
`

const PaymentTextGroup = styled.div`
  padding: 0 44px;

  & > p {
    padding: 0 18px;
  }
`

const PaymentTextGroupOrderList = styled(PaymentTextGroup)`
  display: flex;
  flex-direction: column;
  padding: 20px 26px;
  overflow: hidden;
`

const KeySpan = styled.span`
  font-size: 14px;
  color: #868e96;
`

const ValueSpan = styled.span`
  font-size: 18px;
  color: #555555;
`

const FinalPrice = styled.div`
  ${KeySpan} {
    color: #555555;
    font-size: 18px;
  }

  ${ValueSpan} {
    color: #e84139;
    font-size: 24px;
  }
`

const PaymentTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 427px;
  max-width: 100%;
  color: #868e96;
  overflow: hidden;

  ${PaymentTextGroup} + ${PaymentTextGroup} {
    & > div {
      padding: 20px 0;
      border-top: 1px solid #e4e7eb;
    }
  }

  ${PaymentTextGroup} {
    span:first-child {
      font-size: 18px;
    }

    p {
      margin: 0;
      margin-bottom: 14px;
      color: #555555;
      font-size: 18px;
    }

    & > div > div {
      display: flex;
      justify-content: space-between;
      line-height: 25px;

      & + div {
        margin-top: 5px;
      }
    }
  }
`

const OverflowWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 18px;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 9px;
    background-color: #e9ecef;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #868e96;
    border-radius: 8px;
    height: 30%;
  }
`

type OrderParam = {
  order: UnPaidOrderModel
}

const PackagePaymentDialog = ({order}: OrderParam) => {
  const open = useAppSelector(selectPackagePaymentOpen)
  // 결제 정보 받을 state
  const {finished, getVAT, doPayment, cancelPayment} = useViewModel(order)

  const classes = useStyles()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const handleOpen = () => {
    dispatch(openPackagePaymentDialog())
  }
  const handleClose = async () => {
    try {
      cancelPayment().then((res) => {
        if (res) {
          dispatch(closePackagePaymentDialog())
          dispatch(closePackagePurchaseDialog())
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleOpen()
  }, [])

  if (open === false) {
    return <></>
  }

  if (finished) {
    window.location.reload()
  }

  const currency = order.currencyCode

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(closePackagePaymentDialog())
      }}
      disableEscapeKeyDown
      maxWidth='sm'
    >
      <Card
        style={{
          display: 'flex',
          backgroundColor: '#f9f9fb',
          overflowY: 'hidden',
        }}
      >
        <ModalWrap>
          <ModalHeaderBorderBottom>
            <ModalTitleBox>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <TitleText>{t('IPay')}</TitleText>
            </ModalTitleBox>
          </ModalHeaderBorderBottom>
          <ModalBodyPayment>
            <ModalContentWrapPayment>
              <ModalContentBodyPayment>
                <PaymentTextWrap>
                  <PaymentTextGroupOrderList>
                    <p>{t('IOrderHistory')}</p>
                    <OverflowWrap>
                      {/* list of package */}
                      {order.packages.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <KeySpan>{item.name}</KeySpan>
                            <ValueSpan>
                              {`${order.currencyCode} ${numberWithCommas(
                                item.price,
                              )}`}
                            </ValueSpan>
                          </div>
                        )
                      })}
                      {/* list of bundle */}
                      {order.bundles.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <KeySpan>{item.name}</KeySpan>
                            <ValueSpan>
                              {`${order.currencyCode} ${numberWithCommas(
                                item.priceSet.price,
                              )}`}
                            </ValueSpan>
                          </div>
                        )
                      })}
                      {/* if exists showing the overdue price */}
                      {order.overduePrice !== 0 && (
                        <div>
                          <KeySpan>{t('IUnpaidAmount')}</KeySpan>
                          <ValueSpan>
                            {`${currency} ${numberWithCommas(
                              order.overduePrice,
                            )}`}
                          </ValueSpan>
                        </div>
                      )}
                      {/* if exists showing the refund price */}
                      {order.refundPrice !== 0 && (
                        <div>
                          <KeySpan>{t('IProductsRefund')}</KeySpan>
                          <ValueSpan>
                            {`- ${currency} ${numberWithCommas(
                              order.refundPrice,
                            )}`}
                          </ValueSpan>
                        </div>
                      )}
                    </OverflowWrap>
                  </PaymentTextGroupOrderList>
                  <PaymentTextGroup>
                    <div>
                      <div>
                        <KeySpan>{t('IOrderAmount')}</KeySpan>
                        <ValueSpan>
                          {`${currency} ${numberWithCommas(
                            order.amount - getVAT(),
                          )}`}
                        </ValueSpan>
                      </div>
                      <div>
                        <KeySpan>VAT </KeySpan>
                        <ValueSpan>
                          {`${currency} ${numberWithCommas(getVAT())}`}
                        </ValueSpan>
                      </div>
                    </div>
                  </PaymentTextGroup>
                  <PaymentTextGroup>
                    <div>
                      <FinalPrice>
                        <KeySpan>{t('IFinalPrice')}</KeySpan>
                        <ValueSpan>
                          {`${currency} ${numberWithCommas(order.amount)}`}
                        </ValueSpan>
                      </FinalPrice>
                    </div>
                  </PaymentTextGroup>
                </PaymentTextWrap>
              </ModalContentBodyPayment>
            </ModalContentWrapPayment>
          </ModalBodyPayment>
          <ModalFooter>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={doPayment}
              style={{width: 186}}
              className='mid'
            >
              {t('IPay')}
            </Button>
          </ModalFooter>
        </ModalWrap>
      </Card>
    </Dialog>
  )
}

export default PackagePaymentDialog

interface EximbayWindowProps {
  oderInfo: OrderResponseModel
}

export const EximbayWindow = ({oderInfo}: EximbayWindowProps) => {
  const {mid, secretKey} = oderInfo.getEximBayInfo()
  const {user: currentUser} = useAuth()
  const params = new URLSearchParams()

  let env: any = ''

  if (!isProd()) {
    if (isStage()) {
      env = 'stage'
    } else {
      env = 'dev'
    }
  } else {
    env = 'prod'
  }

  if (currentUser) {
    params.append('ver', '230')
    params.append('txntype', 'PAYMENT')
    params.append('charset', 'UTF-8')
    params.append('statusurl', `${getNewApiHost()}/payments/v2.0/ext/exim/`)
    params.append(
      'returnurl',
      `https://wave.isyncbrain.com:441/${env}/return.php`,
    )
    params.append('rescode', '')
    params.append('resmsg', '')
    params.append('mid', mid)
    params.append('ref', oderInfo.orderNo)
    params.append('ostype', 'P')
    params.append('displaytype', 'P')
    params.append('cur', 'USD')
    params.append('amt', `${oderInfo.amount}`) // 3010.25
    params.append('shop', 'iMediSync')
    params.append('buyer', `${currentUser.name}`) // 구매자 명
    // params.append('buyer', `jungbae kim`) // 구매자 명
    params.append('email', `${currentUser.email}`)
    // params.append('email', 'jungbae.kim@imedisync.com')
    params.append('tel', `${currentUser.tel}`) // 결제자 연락처
    // params.append('tel', `+82 1038876626`) // 결제자 연락처
    params.append('lang', 'EN')
    // params.append('paymethod', 'P000') // 모두 열어줌
    // params.append('issuercountry', 'KR')
    params.append('autoclose', 'N')
    params.append('item_0_product', oderInfo.orderName) // item_#_product
    params.append('item_0_quantity', '1')
    params.append('item_0_unitPrice', `${oderInfo.amount}`)
  }

  const makeFgkey = (secrentKey: any, params: URLSearchParams) => {
    // eximbay 의 형식에 맞게 hash 키 생성
    // acending sort by key
    params.sort()
    const sortedKeys = Array.from(params.keys())
    const query = sortedKeys.reduce((acc, key, i) => {
      const value = params.get(key)
      const prefix = i === 0 ? '' : '&'
      const res = `${prefix}${key}=${value}`
      return acc + res
    }, '')

    return sha256(`${secrentKey}?${query}`) // sha256을 활용해서 해시처리
  }
  const shaKey = makeFgkey(secretKey, params)

  useEffect(() => {
    // request payment when loaded page
    if (mid && secretKey) {
      const frm = document.getElementById('regForm') as any
      frm.submit()
    }
  }, [])

  return (
    <>
      <form id='regForm' name='regForm' method='post' action={eximBayReqHost}>
        <input type='hidden' name='fgkey' value={shaKey} />
        {Array.from(params.keys()).map((key) => {
          return (
            <input type='hidden' name={key} value={params.get(key) as string} />
          )
        })}
      </form>
    </>
  )
}

export const PaymentWindow = () => {
  const orderInfo = (window as any).getOrderInfo() as UnPaidOrderModel
  const {orderNo, pgType, amount, orderName, getTossInfo} = orderInfo

  if (pgType === 'EB') {
    return (
      <>
        <EximbayWindow oderInfo={orderInfo} />
      </>
    )
  }
  if (pgType === 'TP') {
    const c = getTossInfo()
    loadTossPayments(c.clientKey)
      .then((tp) => {
        tp.requestPayment('카드', {
          // 결제 정보 파라미터
          amount,
          orderId: orderNo,
          orderName,
          customerName: 'currentUser?.name',
          successUrl: `${window.location.href}/redirect/success`,
          failUrl: `${window.location.href}/redirect/fail`,
          validHours: 1,
          cashReceipt: {
            type: '소득공제',
          },
        })
      })
      .catch((reason) => {
        console.log('reason :', reason)
      })
  }

  return <></>
}
