import {CircularProgress, makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import React from 'react'

export const loadingStyle = () =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
    },
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'grey',
      opacity: 0.4,
      height: '100vh',
      width: '100vw',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      marginBottom: '10px',
    },
    progress: {
      '&:before': {},
    },
  })
const useLoadingStyle = makeStyles(loadingStyle)
const AppLoading = ({loading}: {loading: boolean}) => {
  const styles = useLoadingStyle()

  if (!loading) return null

  return (
    <div className={styles.root}>
      <div className={styles.background} />
      <div className={styles.wrapper}>
        <CircularProgress className={styles.progress} />
      </div>
    </div>
  )
}

export default AppLoading
