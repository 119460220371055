import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
  ActivityInfo,
  ActivityType,
  fetchPatientActivityInfoApi,
} from 'api/patientApi'
import classnames from 'classnames'
import {
  getEegIndividualReportPath,
  getEegMciReportPath,
  getEegNormReportPath,
  getEegSummaryReportPath,
  getHrvIndividualReportPath,
  getHrvSummaryReportPath,
} from 'constants/RouteConstant'
import {isoStringToDateAndTimeString} from 'helpers/dateHelper'
import React, {useEffect, useMemo, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import openInNewTab from 'helpers/openInNewTab'

const useStyles = makeStyles(
  createStyles({
    root: {
      flex: 1,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 5px',
      borderBottom: '1px solid #d2d3d7',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.05)',
      },

      '& .title': {
        fontSize: '0.875rem',
        marginRight: 14,
      },
      '& .file': {
        backgroundColor: '#f8b455',
        fontSize: 10,
        lineHeight: 1,
        color: 'white',
        padding: 7,
        borderRadius: 15,
        fontWeight: 600,
      },
      '& .date': {
        marginLeft: 'auto',
      },
    },
  }),
)

interface ActivityProps {
  onClick?: VoidFunction
  type: ActivityType
  date?: string | undefined
  fileName?: string | undefined
}

// TODO: i18n
const getActivityTitle = (type: ActivityType) => {
  switch (type) {
    case 'REGISTRATION':
      return 'Registration'
    case 'EEG':
      return 'Upload EEG'
    case 'EEG_NORM':
      return 'Upload Norm DB'
    case 'EEG_STANDARD_SUMMARY':
      return 'Run EEG Summary'
    case 'HRV':
      return 'Upload HRV'
    case 'HRV_NORM':
      return 'Upload HRV Norm DB'
    case 'HRV_STANDARD_SUMMARY':
      return 'Run HRV Summary'
    case 'MCI':
      return 'Upload MCI'
    case 'THERAPY':
      return 'Run Therapy'
    default:
      return 'IUnknown'
  }
}

const Activity = ({type, date, fileName, onClick}: ActivityProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const title = getActivityTitle(type)
  const parsedDate = date ? isoStringToDateAndTimeString(date) : ''

  return (
    <div
      className={classes.container}
      onClick={onClick}
      onKeyDown={onClick}
      role='button'
      tabIndex={0}
    >
      <div className='title'>{t(title)}</div>
      {fileName && <div className='file'>{fileName}</div>}
      <div className='date'>{parsedDate}</div>
    </div>
  )
}

export interface PatientActivityInfoProps {
  uuid: string
  className: string
  firstName: string
  lastName: string
}

export const PatientActivityInfo = ({
  uuid,
  className,
  firstName,
  lastName,
}: PatientActivityInfoProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const asyncResult = useAsync(fetchPatientActivityInfoApi, [uuid], {
    executeOnMount: true,
    executeOnUpdate: true,
  })

  const [info, setInfo] = useState<ActivityInfo>()

  const clientNameData = useMemo(() => {
    return encodeURIComponent(
      JSON.stringify({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      }),
    )
  }, [firstName, lastName])

  const clientName = useMemo(() => {
    return t('IGetFullName', {firstName, lastName})
  }, [firstName, lastName])

  const handleEegIndividualClick = () => {
    if (info?.eeg)
      openInNewTab(
        getEegIndividualReportPath(
          info?.eeg.analysisId,
          undefined,
          clientNameData,
        ),
      )
  }

  const handleEegNormClick = () => {
    if (info?.eegNorm)
      openInNewTab(
        getEegNormReportPath(
          info?.eegNorm.analysisId,
          undefined,
          clientNameData,
        ),
      )
  }

  const handleEegMciClick = () => {
    if (info?.mci)
      openInNewTab(getEegMciReportPath(info?.mci.analysisId, clientName))
  }

  const handleEegSummaryClick = () => {
    if (info?.eegSummary)
      openInNewTab(
        getEegSummaryReportPath(info?.eegSummary.analysisId, clientName),
      )
  }

  const handleHrvClick = () => {
    if (info?.hrv)
      openInNewTab(getHrvIndividualReportPath(info?.hrv.analysisId, clientName))
  }

  const handleHrvSummaryClick = () => {
    if (info?.hrvSummary)
      openInNewTab(
        getHrvSummaryReportPath(info?.hrvSummary.analysisId, clientName),
      )
  }

  useEffect(() => {
    setInfo(asyncResult.result?.data)
  }, [asyncResult.result])

  const classNames = classnames({
    [classes.root]: true,
    [className]: className !== undefined,
  })

  return (
    <div className={classNames}>
      {asyncResult.loading && <div>{t('ILoading')}</div>}
      {asyncResult.error && <div>{asyncResult.error}</div>}
      {asyncResult.result && (
        <div className={classes.wrapper}>
          <Activity type='REGISTRATION' date={info?.registration} />
          {info?.eeg && (
            <Activity {...info.eeg} onClick={handleEegIndividualClick} />
          )}
          {info?.eegNorm && (
            <Activity {...info.eegNorm} onClick={handleEegNormClick} />
          )}
          {info?.mci && <Activity {...info.mci} onClick={handleEegMciClick} />}
          {info?.hrv && <Activity {...info.hrv} onClick={handleHrvClick} />}
          {info?.hrvNorm && <Activity {...info.hrvNorm} />}
          {info?.eegSummary && (
            <Activity {...info.eegSummary} onClick={handleEegSummaryClick} />
          )}
          {info?.hrvSummary && (
            <Activity {...info.hrvSummary} onClick={handleHrvSummaryClick} />
          )}
          {info?.therapy && <Activity {...info.therapy} />}
        </div>
      )}
    </div>
  )
}
