import {UnpaidOrderResponse} from 'api/paymentApi'
import {
  OrganizationBundle,
  OrganizationPackage,
} from '../../Interfaces/UnPaidOrder'
import OrderResponseModel from './OrderResponseModel'

export default class UnPaidOrderModel
  extends OrderResponseModel
  implements UnpaidOrderResponse
{
  bundles: OrganizationBundle[]

  packages: OrganizationPackage[]

  constructor(raw: UnpaidOrderResponse, privateKey: string) {
    super(raw, privateKey)
    this.bundles = raw.bundles
    this.packages = raw.packages
  }
}
