import styled from 'styled-components'

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
`
export const TitleText = styled.p`
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`

export const NoticeText = styled.span`
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
`

export const ModalHeader = styled.div`
  padding: 15px 15px 23px 44px;
`
export const ModalHeaderBorderBottom = styled.div`
  position: relative;
  border-bottom: 1px solid #e4e7eb;
  padding: 18px 20px;

  button {
    padding: 0;
    width: 18px;
    height: 18px;
    background: transparent;

    position: absolute;
    top: 8px;
    right: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`
export const ModalTitleBox = styled.div``
export const ModalBody = styled.div`
  padding: 0 44px;
  overflow-y: auto;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
  }
`
export const ModalFooter = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 40px 25px;
  gap: 28px;

  button {
    height: 55px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 8px;
  }

  button.mid {
    height: 48px;
    span {
      font-size: 17px;
      font-weight: 400;
    }
  }
`

export const TitleTextSmall = styled.p`
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin: 0;
`

export const ModalContentWrap = styled.div`
  & + * {
    margin-top: 25px;
  }
`

export const ModalContentHeader = styled.div`
  margin-bottom: 12px;
`
export const ModalContentBody = styled.div``
