import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

export const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    height: 40,
    '& $notchedOutline': {
      borderColor: '#e0e0e0',
    },
    '&:hover $notchedOutline': {
      borderColor: '#e0e0e0',
    },
  },
  focused: {},
  notchedOutline: {},
}))
export const commentOutlinedInputStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    height: 40,
    '& $notchedOutline': {
      borderColor: '#e9ecef',
    },
    '&:hover $notchedOutline': {
      borderColor: '#e9ecef',
    },
  },
  focused: {},
  notchedOutline: {},
}))

const useTableToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 8,
      flexWrap: 'wrap',
      marginBottom: 10,
      justifyContent: 'space-between',
      '& .MuiOutlinedInput-input': {
        padding: 0,
        margin: 12,
      },
      [theme.breakpoints.down('md')]: {
        gap: 4,
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      boxSizing: 'border-box',
      gap: 16,
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 4,
        alignItems: 'flex-start',
      },
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      boxSizing: 'border-box',
      gap: 4,
    },
    actionContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      gap: 4,
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      gap: 4,
    },
    containerOptional: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      gap: 4,
    },
    searchWrap: {
      border: '1px solid #e0e0e0',
      borderRadius: 4,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      height: 42,
    },
    searchDivider: {
      width: 2,
      color: '#e0e0e0',
      height: 40,
      borderRight: '1px solid #e0e0e0',
    },
    searchButton: {
      color: '#666a6c',
      border: 0,
      backgroundColor: 'white',
      margin: '2px 2px',
      width: 52,
      fontSize: 14,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    downloadButton: {minWidth: 133},
    showButton: {
      backgroundColor: 'white',
      border: '1px solid #5a6268',
      color: '#5a6268',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    smsButton: {minWidth: 113},
    searchInput: {
      margin: '2px 8px',
      minWidth: 170,
      flex: 1,
      border: '0px solid',
      height: 30,
      outline: 'none',
    },
    searchDateInput: {margin: '6px 0', minWidth: 165, flex: 1},
    searchSelect: {
      width: 130,
      height: 40,
    },
    dateInput: {margin: '6px 0'},
    actionButton: {},
    padding: {
      padding: theme.spacing(1),
    },
    emptySpace: {
      width: '100%',
      backgroundColor: 'red',
      color: 'red',
    },
    flexRight: {
      marginLeft: 'auto',
    },
    dateSearchButton: {
      border: '1px solid #0cc7c7',
      borderRadius: 4,
      padding: '0 15px',
      background: 'white',
      color: '#0cc7c7',
      height: 40,
    },
    buttonActive: {
      border: 0,
      margin: '2px 2px',
      fontSize: '14px',
      color: 'white',
      height: '40px',
      minWidth: '100px',
      borderRadius: '6px',
      marginBottom: '15px',
      padding: '0px 15px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    searchSelectInvoice: {
      width: 180,
      height: 42,
    },
    searchDownloadRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
  }),
)
export default useTableToolbarStyles
