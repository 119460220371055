import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import * as React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

interface NameInput {
  onPersonal: (isPersonal?: boolean) => void
}

export default function OrganizationNameInput({onPersonal}: NameInput) {
  const createOrgInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()
  const schema = yup.object().shape({
    code: yup.string(),
  })
  const [isClose, setIsClose] = React.useState(false)

  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IInstituteName')}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
        <div style={{width: '100%'}}>
          <input
            placeholder={t('IRegisterHospitalName')}
            className={classes.input}
            style={{width: '100%'}}
            {...createOrgInfo.register('name', {
              validate: (code) =>
                schema.validate({code}).catch((e) => e.errors[0]),
            })}
            disabled={isClose}
          />
        </div>
        {/* Unnecessary Checkbox
        <div>
          <div className={classes.checkbox} style={{display: 'flex', gap: 5}}>
            <input
              type='checkbox'
              id='noInstitute'
              onClick={() => {
                setIsClose(!isClose)
                onPersonal(!isClose)
              }}
            />
            <label className='label' htmlFor='noInstitute'>
              <CheckIcon className='arrowIcon' />
            </label>
            <div>{t('IIndividualUser')}</div>
          </div>
        </div> */}
      </div>
      {errors.orgName && (
        <span className={classes.errorText}>
          {errors.orgName && errors.orgName.message}
        </span>
      )}
    </div>
  )
}
