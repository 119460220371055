import {Customer, EEGSummaryResponse, Lobe} from 'api/analysisApi'
import EEGSummaryBrain3D from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryBrain3d'
import EEGSummaryLobe from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryLobe'
import EEGSummaryOpinion from 'components/molcules/ProfitSummaryReport/EEG/EEGSummaryOpnion'
import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import RoiComponent from 'components/molcules/Report/RoiComponent'
import React, {ReactNode, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getLanguage} from 'api/storageApi'
import EEGChildSummaryFrontLobeDevelopment from '../../N1SummaryReport/EEGChild/EEGSummaryFrontLobeDevelopment'
import EEGChildSummaryInformationProcessingSpeed from '../../N1SummaryReport/EEGChild/EEGSummaryInformationProcessSpeed'
import EEGSummaryAlphaBandDevelopment from '../../N1SummaryReport/EEGChild/EEGSummaryAlphaBandDevelopment'
import EEGSummaryBrainAgeDevelopment from '../../N1SummaryReport/EEGChild/EEGSummaryBrainAgeDevelopment'
import EEGSummaryBrainBalanceDevelopment from '../../N1SummaryReport/EEGChild/EEGSummaryBrainBalanceDevelopment'

interface EEGSummaryProps extends EEGSummaryResponse {
  contourImage?: string
  threeDimensionImage: string
  total: string
  CustomerCard?: ReactNode
  reportType: ReportType
  client: Customer
}

export default function EEGSummary(props: EEGSummaryProps) {
  const {
    frontalLobeDevelopment,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    frontalLobeDevelopmentGraph_image,
    frontalLobeDevelopmentColorbarImage,
    informationProcessingSpeedGraphImage,
    informationProcessingSpeed,
    alphaBandDevelopment,
    alphaBandDevelopmentBarGraphImage,
    alphaBandDevelopmentLineGraphImage,
    brainBalance,
    brainBalance3dImage,
    brainBalanceImage,
    brainAgeGraphImage,
    brainAge,
    contourImage,
    threeDimensionImage,
    total,
    lobe,
    roi,
    CustomerCard,
    reportType,
    client,
  } = props
  const classes = useStyles()
  const {t} = useTranslation()
  const score = Number(total) ?? 0
  const lang = getLanguage()
  const {treatmentAge} = client
  const [isShowSummaryChild, setIsShowSummaryChild] = useState<boolean>(false)
  const [isEmptyReport, setIsEmptyReport] = useState<boolean>(false)

  const handleTop = () => {
    const scroll = document.getElementById('root')

    scroll?.scrollTo(0, 0)
  }

  useEffect(() => {
    handleTop()
  }, [])

  useEffect(() => {
    if (lang && lang === 'ko') setIsShowSummaryChild(true)
    else setIsShowSummaryChild(true)
  }, [lang])

  useEffect(() => {
    const isEmpty =
      !isShowSummaryChild &&
      !lobe &&
      !threeDimensionImage &&
      !contourImage &&
      !roi
    setIsEmptyReport(isEmpty)
  }, [isShowSummaryChild])

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      {isShowSummaryChild && (
        <>
          {frontalLobeDevelopment && (
            <EEGChildSummaryFrontLobeDevelopment
              frontalLobeDevelopmentGraphImage={
                frontalLobeDevelopmentGraph_image
              }
              frontalLobeDevelopmentColorbarImage={
                frontalLobeDevelopmentColorbarImage
              }
              score={frontalLobeDevelopment.sensor_tbr_cz ?? 0}
              percentile={frontalLobeDevelopment.percentile ?? 0}
            />
          )}
          {informationProcessingSpeed && (
            <EEGChildSummaryInformationProcessingSpeed
              informationProcessingSpeedGraphImage={
                informationProcessingSpeedGraphImage
              }
              score={informationProcessingSpeed.occipital_alpha_peak_freq ?? 0}
              percentile={informationProcessingSpeed.percentile ?? 0}
            />
          )}
          {alphaBandDevelopment && (
            <EEGSummaryAlphaBandDevelopment
              alphaBandDevelopmentBarGraphImage={
                alphaBandDevelopmentBarGraphImage
              }
              alphaBandDevelopmentLineGraphImage={
                alphaBandDevelopmentLineGraphImage
              }
              ec_alpha_proportion={
                alphaBandDevelopment.ec_alpha_proportion ?? 0
              }
              ec_alpha_proportion_normal={
                alphaBandDevelopment.ec_alpha_proportion_normal ?? 0
              }
              eo_alpha_proportion={
                alphaBandDevelopment.eo_alpha_proportion ?? 0
              }
              eo_alpha_proportion_normal={
                alphaBandDevelopment.eo_alpha_proportion_normal ?? 0
              }
              eo_alpha_proportion_percentile={
                alphaBandDevelopment.eo_alpha_proportion_percentile
              }
              ec_alpha_proportion_percentile={
                alphaBandDevelopment.ec_alpha_proportion_percentile
              }
            />
          )}
          {brainBalance && (
            <EEGSummaryBrainBalanceDevelopment
              brainBalance3dImage={brainBalance3dImage}
              brainBalanceImage={brainBalanceImage}
              LH={brainBalance.LH ?? 0}
              RH={brainBalance.RH ?? 0}
            />
          )}
          {brainAge && (
            <EEGSummaryBrainAgeDevelopment
              brainAgeGraphImage={brainAgeGraphImage}
              age={brainAge.subject_age ?? 0}
              avg={brainAge.avg ?? 0}
              lobe={brainAge.by_lobe}
            />
          )}
        </>
      )}

      {threeDimensionImage && (
        <EEGSummaryBrain3D
          index={brainBalance == null || !isShowSummaryChild ? 1 : 6}
          threeDimensionImage={threeDimensionImage}
        />
      )}
      {lobe !== null && lobe !== undefined && (
        <EEGSummaryLobe
          index={brainBalance == null || !isShowSummaryChild ? 2 : 7}
          lobe={lobe}
        />
      )}
      {contourImage && (
        <EEGSummaryOpinion
          index={brainBalance == null || !isShowSummaryChild ? 3 : 8}
          score={score}
          contourImage={contourImage}
        />
      )}
      {lobe != null &&
        lobe !== undefined &&
        roi !== undefined &&
        reportType === 'P2' && <RoiComponent roi={roi} lobe={lobe} />}
      {isEmptyReport && (
        <p style={{textAlign: 'center', color: 'red'}}>
          {t('IResultNotProvided')}
        </p>
      )}
      <p className='Warning'>{t('IEEGSummaryReportWarning')}</p>
    </div>
  )
}
