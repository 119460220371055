import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import CalenderDatePicker from 'components/Calender/CalenderPicker'
import {yupResolver} from '@hookform/resolvers/yup'
import useStyles from 'components/Dialog/User/Style'
import {
  closePaymentInfoEditDialog,
  selectPaymentInfoEditOpen,
} from 'features/modal/modalSlice'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'
import styled from 'styled-components'
import {
  updateChangePackagePriceApi,
  UpdatePackagePriceRequest,
} from 'api/paymentApi'
import useSuccessModal from 'features/modal/useSuccessModal'
import useFailureModal from 'features/modal/useFailureModal'
import {
  dateToDateAndTimeString,
  fromLocalDateOrStringToUtcDate,
} from 'helpers/dateHelper'

const RootWrap = styled.div`
  background-color: #fff;
`
const TitleText = styled.p`
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`

const ContentWrap = styled.div`
  background-color: #f9f9fb;
  background-color: #fff;
  padding: 30px 40px 0 40px;
  margin: 10px 0;
  border-top: 1px solid #e9ecef;
`

const BottomButtonWrap = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 30px 60px;
  gap: 28px;
`

const CellTitleText = styled.p`
  width: 100px;
  height: 20px;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left;
  color: #333;
  & p {
    margin: 0;
    padding: 0;
  }
`

const InputText = styled.input`
  height: 44px;
  padding: 10px 15px;
  border: solid 1px #e0e0e0;
  width: 275px;
  ::placeholder {
    color: #555;
    margin: 5px 46px 0 0;
  }
`
const CellContentText = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  padding: 0;
  margin: 0;
`

const PaymentEditCell = styled.div`
  display: flex;
  margin: 10px 0;
  height: 40px;
  align-items: center;
`

const ErrorText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11;
  color: #d03c35;
`

interface PaymentInfoEditProps {
  packageId: number
  organizationOid: string
  packageType?: string
}

const PaymentInfoEditDialog = ({
  packageId,
  organizationOid,
  packageType,
}: PaymentInfoEditProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const open = useAppSelector(selectPaymentInfoEditOpen)
  const dispatch = useAppDispatch()

  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const schema = yup.object().shape({
    price: yup.number().typeError(t('INotNumberFormat')),
    changedCount: yup.number().typeError(t('INotNumberFormat')),
    memo: yup.string().required(t('IRequired')),
  })
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
  })

  const [date, setDate] = React.useState<Date>(new Date())
  const [isNoneExpireDate, setIsNoneExpireDate] = React.useState(false)

  const handleClose = () => dispatch(closePaymentInfoEditDialog())
  const handleDateChanged = (date?: Date | null) => {
    if (date) setDate(date)
  }

  const updatePackagePrice = async (data: UpdatePackagePriceRequest) => {
    try {
      const result = await updateChangePackagePriceApi(data)
      if (result) {
        onSuccessModalOpen(t('IProcessSuccess'))
        handleClose()
      }
    } catch (error) {
      onFailureModalOpen(t('IProcessFail'))
    }
  }
  const onSubmit = (data: any) => {
    if (isNoneExpireDate) {
      const request: UpdatePackagePriceRequest = {
        organizationOid,
        packageId,
        price: data.price,
        memo: data.memo,
        amount: data.changedCount ?? undefined,
      }
      updatePackagePrice(request)
    }
    if (!isNoneExpireDate) {
      const request: UpdatePackagePriceRequest = {
        organizationOid,
        packageId,
        price: data.price,
        memo: data.memo,
        amount: data.changedCount ?? undefined,
        expiresOn: `${dateToDateAndTimeString(
          fromLocalDateOrStringToUtcDate(date),
        )}`,
      }
      updatePackagePrice(request)
    }
  }

  useEffect(() => {
    if (!open) reset()
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth='md'
    >
      <Card>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data)
          })}
        >
          <RootWrap>
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <TitleText>{t('IPaymentInfoEdit')}</TitleText>
            </div>
            <ContentWrap>
              <PaymentEditCell>
                <CellTitleText>{t('ICost')}</CellTitleText>
                <CellContentText>0원</CellContentText>
              </PaymentEditCell>
              <PaymentEditCell>
                <CellTitleText>{t('IPriceChange')}</CellTitleText>
                <Controller
                  name='price'
                  control={control}
                  render={({field}) => <InputText type='text' {...field} />}
                />
              </PaymentEditCell>
              {errors.changePrice && (
                <ErrorText>{errors.changePrice.message}</ErrorText>
              )}
              <PaymentEditCell>
                <CellTitleText>{t('IExpirationAt')}</CellTitleText>
                <CellContentText>2022.11.13</CellContentText>
              </PaymentEditCell>
              <PaymentEditCell>
                <CellTitleText>{t('INoExpirationAt')}</CellTitleText>
                <input
                  id='noneExpire'
                  type='checkbox'
                  checked={isNoneExpireDate}
                  onClick={() => {
                    setIsNoneExpireDate(!isNoneExpireDate)
                  }}
                />
              </PaymentEditCell>
              <PaymentEditCell>
                <CellTitleText>{t('IExpirationAtChange')}</CellTitleText>
                <div
                  style={{
                    width: 275,
                    pointerEvents: isNoneExpireDate ? 'none' : 'auto',
                  }}
                >
                  <CalenderDatePicker
                    onChangeValue={handleDateChanged}
                    dateValue={date}
                  />
                </div>
              </PaymentEditCell>
              {packageType && packageType === 'PRE' && (
                <PaymentEditCell>
                  <CellTitleText>{t('IMaxCount')}</CellTitleText>
                  <CellContentText>1</CellContentText>
                </PaymentEditCell>
              )}
              {packageType && packageType === 'PRE' && (
                <>
                  <PaymentEditCell>
                    <CellTitleText>{t('ICountChange')}</CellTitleText>
                    <Controller
                      name='changedCount'
                      control={control}
                      render={({field}) => <InputText type='text' {...field} />}
                    />
                  </PaymentEditCell>
                  {errors.changedCount && (
                    <ErrorText>{errors.changedCount.message}</ErrorText>
                  )}
                </>
              )}
              <PaymentEditCell>
                <CellTitleText style={{display: 'flex'}}>
                  <p>{t('IChangeMemo')}</p>
                  <p style={{color: '#e84139'}}>*</p>
                </CellTitleText>
                <Controller
                  name='memo'
                  control={control}
                  render={({field}) => <InputText type='text' {...field} />}
                />
              </PaymentEditCell>
              {errors.changedReason && (
                <ErrorText>{errors.changedReason.message}</ErrorText>
              )}
            </ContentWrap>
            <br />
            <BottomButtonWrap>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleClose}
                style={{
                  width: 222,
                  height: 55,
                  backgroundColor: '#e9ecef',
                  color: '#4b4b4b',
                }}
              >
                {t('ICancel')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                style={{width: 222, height: 55}}
                type='submit'
              >
                {t('IOk')}
              </Button>
            </BottomButtonWrap>
          </RootWrap>
        </form>
      </Card>
    </Dialog>
  )
}

export default PaymentInfoEditDialog
