/* eslint-disable */
import request, {newNormalRequest} from 'api/index'
import {isProd} from 'helpers/envHelper'
import CryptoES from 'crypto-es'
import JSEncrypt from 'jsencrypt'

// AES 암호화 함수
export const getEncryptionValue = (
  secretKey: string,
  input: string,
): string => {
  const key = CryptoES.enc.Utf8.parse(secretKey)
  const iv = CryptoES.lib.WordArray.random(16)
  const encrypted = CryptoES.AES.encrypt(input, key, {
    iv: iv,
    padding: CryptoES.pad.Pkcs7,
    mode: CryptoES.mode.CBC, // [cbc 모드 선택]
  })
  return iv.concat(encrypted.ciphertext).toString(CryptoES.enc.Base64)
}

// AES 복호화 함수
export const getDecryptionValue = (
  secretKey: string,
  input: string,
): string => {
  const key = CryptoES.enc.Utf8.parse(secretKey)
  let ciphertext = CryptoES.enc.Base64.parse(input)
  const iv = ciphertext.clone()
  iv.sigBytes = 16
  iv.clamp()
  ciphertext.words.splice(0, 4) // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16

  const decrypted = CryptoES.AES.decrypt({ciphertext: ciphertext}, key, {
    iv: iv,
  })
  return decrypted.toString(CryptoES.enc.Utf8)
}

export const getFieldSecurityKey = async () => {
  const result = await fieldSecurityApi()
  try {
    if (result) return result.aesKey
  } catch (e) {
    return e
  }
}

export const rsaPublicKey = `-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRSQY7vJFDDejWCGn5WtSX+6dR\nGP0mN0hySnnFUgfXsyDboFZNlmKfUvSFxpuO2M3KeGrSFFfjP1kue6EjrXinkuls\nC8EYSE9Htb9R+Is+KmAYda2jRwaYIty1QpBClziRR5/eqVkSi/zVQkRegA6/y66z\nt4JkPVRALfVQFXVXSQIDAQAB\n-----END PUBLIC KEY-----`
export const newRsaPublicKey = `-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/z1wGwqDjweYeVDeKEoMkg5Np\nUF+Oe7kSpnuaaX5isynIpaNV37Aoz1JtF759xLQOf/Vl+ut0Jb+XnQ+ZKwvr3VUv\noNpQkb2l7qvCkiBG9B53HO6HchPTeDxGyofitb7lLFYLbODcll6f81Cn3RbIaD/f\na6q77CQGNjnq8r1AGQIDAQAB\n-----END PUBLIC KEY-----`

// api 명세를 확인하고 encKey 파라미터가 있을시 키셋을 생성한뒤 사용
export const makeRsaKeys = () => {
  const crypt = new JSEncrypt({default_key_size: '1024'})
  const PublicPrivateKey = {
    PublicKey: crypt.getPublicKey(),
    PrivateKey: crypt.getPrivateKey(),
  }
  return PublicPrivateKey
}

export const rsaEncryption = (input: string): any => {
  let encrypt = new JSEncrypt()
  if (isProd()) {
    encrypt.setPublicKey(newRsaPublicKey)
  }
  if (!isProd()) {
    encrypt.setPublicKey(rsaPublicKey)
  }
  const encrypted = encrypt.encrypt(input)
  return encrypted
}

export const rsaDecryption = (key: string, value: string): string => {
  let decrypt = new JSEncrypt()
  decrypt.setPublicKey(key)
  const encrypted = decrypt.decrypt(value) as string
  return encrypted
}

interface FieldSecurityResponse {
  aesKey: string
  expiresAt: Date
}

export const fieldSecurityApi = () =>
  newNormalRequest<FieldSecurityResponse>({
    method: 'post',
    path: '/authenticate/v1.0/security-key/',
  })
