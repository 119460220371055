import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  searchTreatmentAllApiV3,
  searchTreatmentMonthlyAllApi,
  TreatmentSearchMonthlyAllRequest,
  TreatmentSearchNewRequest,
  TreatmentSearchRequest,
} from 'api/treatmentApi'
import {isDefined} from 'helpers/commonHelper'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {
  convertParamsTreatmentListV3,
  convertTypeSearch,
} from 'helpers/treatmentHelper'
import {RootState} from 'store'

const fetchTreatmentSearchAll = createAsyncThunk(
  'api/treatment/search/all',
  async (payload: TreatmentSearchRequest, {rejectWithValue}) => {
    try {
      const {uuid} = payload
      // const response = isDefined(uuid)
      //   ? await searchTreatmentOneApi(payload)
      //   : await searchTreatmentAllApi(payload)

      const response = await searchTreatmentAllApiV3({
        careDoctorName: payload.search.searchValue,
        // chartNumber: 0,
        endDate: payload.period.endDate,
        // medicalNum: 0,
        pageNumber: payload.paging.page,
        pageSize: payload.paging.size,
        patientUuid: payload.uuid,
        startDate: payload.period.startDate,
        type: 'ALL',
        uid: payload?.uid ?? '',
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

const fetchTreatmentSearchOne = createAsyncThunk(
  'api/treatment/search/one',
  async (payload: TreatmentSearchRequest, {rejectWithValue}) => {
    try {
      const {uuid} = payload
      // const response = isDefined(uuid)
      //   ? await searchTreatmentOneApi(payload)
      //   : await searchTreatmentAllApi(payload)

      const response = await searchTreatmentAllApiV3({
        careDoctorName: payload.search.searchValue,
        chartNumber: 0,
        endDate: payload.period.endDate,
        medicalNum: 0,
        pageNumber: payload.paging.page,
        pageSize: payload.paging.size,
        patientUuid: payload.uuid,
        startDate: payload.period.startDate,
        type: 'ALL',
        uid: payload?.uid ?? '',
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const searchTreatment = createAsyncThunk(
  'treatment/search',
  async (payload: TreatmentSearchRequest, {rejectWithValue, dispatch}) => {
    try {
      const {uuid} = payload
      if (isDefined(uuid)) {
        return await dispatch(fetchTreatmentSearchOne(payload)).unwrap()
      }
      return await dispatch(fetchTreatmentSearchAll(payload)).unwrap()
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const searchTreatmentMonthlyAllAction = createAsyncThunk(
  'treatment/search/monthly',
  async (payload: TreatmentSearchMonthlyAllRequest, {rejectWithValue}) => {
    try {
      const response = await searchTreatmentMonthlyAllApi(payload)

      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const searchTreatmentNewAction = createAsyncThunk(
  'treatment/search/web',
  async (payload: TreatmentSearchNewRequest, {rejectWithValue}) => {
    try {
      console.log(`search treatment : ${JSON.stringify(payload)}`)

      const response = await searchTreatmentAllApiV3(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

interface TreatmentSearchState {
  uuid?: string | null
  loading: boolean
  error: any
  allItems: Treatment[]
  oneItems: Treatment[]
  search: TreatmentSearchKind
  period: PeriodString
  paging: Paging
  pagingInfo: PagingInfo
  isEmailSendResultOpen: boolean
}

export const initialState: TreatmentSearchState = {
  uuid: null,
  loading: false,
  error: null,
  allItems: [],
  oneItems: [],
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
  period: getDefaultPeriodString(),
  isEmailSendResultOpen: false,
}

export const treatmentSearchSlice = createSlice({
  name: 'treatmentSearchSlice',
  initialState,
  reducers: {
    openEmailSendResultDialog(state) {
      state.isEmailSendResultOpen = true
    },
    closeEmailSendResultDialog(state) {
      state.isEmailSendResultOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreatmentSearchAll.pending, (state, action) => {
      state.loading = true
      state.error = null

      const payload = action.meta.arg
      const {uuid, search, period} = payload
      console.log(`fetch treatment pending : ${JSON.stringify(payload)}`)

      state.uuid = uuid
      state.search = search
      state.period = period
    })
    builder.addCase(fetchTreatmentSearchAll.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber: page},
        content: items,
        size,
      } = action.payload

      state.loading = false
      state.error = null
      state.allItems = items

      state.paging.page = page
      state.paging.size = size

      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(fetchTreatmentSearchAll.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchTreatmentSearchOne.pending, (state, action) => {
      state.loading = true
      state.error = null

      const payload = action.meta.arg
      const {uuid, search, period} = payload
      state.uuid = uuid
      state.search = search
      state.period = period
    })
    builder.addCase(fetchTreatmentSearchOne.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber: page},
        content: items,
        size,
      } = action.payload

      state.loading = false
      state.error = null
      state.oneItems = items

      state.paging.page = page
      state.paging.size = size

      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(fetchTreatmentSearchOne.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(searchTreatmentNewAction.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(searchTreatmentNewAction.pending, (state, action) => {
      state.loading = true
      state.error = null

      const payload = action.meta.arg
      const {patientUuid, startDate, endDate} = payload
      const searchCondition = convertTypeSearch(payload)
      state.uuid = patientUuid
      state.period.startDate = startDate
      state.period.endDate = endDate
      state.search.searchName = searchCondition?.searchName
      state.search.searchValue = (searchCondition.searchValue as string) ?? ''
    })
    builder.addCase(searchTreatmentNewAction.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber: page},
        content: items,
        size,
      } = action.payload

      const payload = action.meta.arg

      const {startDate, endDate} = payload
      const searchCondition = convertTypeSearch(payload)

      state.loading = false
      state.error = null
      state.allItems = items

      state.paging.page = page
      state.paging.size = size

      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements

      state.search.searchName = searchCondition?.searchName ?? ''
      state.search.searchValue = (searchCondition.searchValue as string) ?? ''

      state.period.startDate = startDate
      state.period.endDate = endDate
    })
  },
})

export const {openEmailSendResultDialog, closeEmailSendResultDialog} =
  treatmentSearchSlice.actions

export const selectLoading = (state: RootState) => state.treatmentSearch.loading
export const selectError = (state: RootState) => state.treatmentSearch.error
export const selectItems = (state: RootState) => state.treatmentSearch.allItems
export const selectOneItems = (state: RootState) =>
  state.treatmentSearch.oneItems
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.treatmentSearch.period)
export const selectPeriodInfo = (state: RootState) => ({
  startDate: state.treatmentSearch.period.startDate,
  endDate: state.treatmentSearch.period.endDate,
})
export const selectSearch = (state: RootState) => state.treatmentSearch.search
export const selectSearchInfo = (state: RootState) => {
  return convertParamsTreatmentListV3(
    state.treatmentSearch.search.searchValue,
    state.treatmentSearch.search.searchName,
  )
}
export const selectPaging = (state: RootState) => state.treatmentSearch.paging
export const selectPagingInfo = (state: RootState) =>
  state.treatmentSearch.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.treatmentSearch.period,
  search: state.treatmentSearch.search,
  paging: state.treatmentSearch.paging,
})

export const selectAuth = (state: RootState) => state.auth.user

export const selectEmailSendResult = (state: RootState) =>
  state.treatmentSearch.isEmailSendResultOpen

export default treatmentSearchSlice.reducer
