import {TherapySearchRequest} from 'api/therapyApi'
import {
  fetchTherapySearch,
  selectError,
  selectList,
  selectLoading,
  selectQuery,
  selectPaging,
  selectPagingInfo,
} from 'features/therapy/therapySearchSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useTherapySearch() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const therapy = useAppSelector(selectList)
  const query = useAppSelector(selectQuery)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)

  const onSearch = (payload: TherapySearchRequest) => {
    dispatch(fetchTherapySearch(payload))
  }

  const onRefresh = () =>
    dispatch(
      fetchTherapySearch({
        ...query,
      }),
    )

  return {
    loading,
    error,
    therapy,
    query,
    paging,
    pagingInfo,
    onSearch,
    onRefresh,
  }
}
