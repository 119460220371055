import {PackageUnit} from 'api/paymentApi'
import {SpreadItemWithIcon} from 'components/V2Select/IconSelect/IconSelect'
import {TFunction} from 'react-i18next'

export default class PackageUnitModel
  implements PackageUnit, SpreadItemWithIcon
{
  t: TFunction

  id: string

  amount: number

  price: number

  constructor(raw: PackageUnit, t: TFunction) {
    this.t = t

    this.id = raw.id
    this.amount = raw.amount
    this.price = raw.price
  }

  appearanceValueWithIcon = () => {
    return {
      iconName: 'counts',
      optionText:
        this.amount === undefined || this.amount == null
          ? this.t('IUnlimited')
          : `${this.amount}`,
    }
  }
}
