import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import {createOrgUserApi, OrgUserCreateRequest} from 'api/userApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import SelectBoldDepartment from 'components/Dialog/BoldSelect/SelectBoldDepartment'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import useStyles from 'components/Dialog/User/Style'
import useAuth from 'features/auth/useAuth'
import {
  closeUserAddDialog,
  selectUserAddDialogOpen,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrganization from 'features/org/useOrganization'
import useOrgDepartment from 'features/org/useOrgDepartment'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {Fragment, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useMutation, useQueryClient} from 'react-query'

type FormData = {
  firstName: string
  lastName: string
  email: string
  checkedEmail: string
  licenseNumber: string
  deptId: string
  userType: string
  profile?: File | null
  tel?: string
  divisionId?: number | null
  positionId?: number | null
  eegVersionId?: number | null
  hrvVersionId?: number | null
}

export default function UserAddDialog() {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectUserAddDialogOpen)

  const {organization, onFetch: onOrganizationFetch} = useOrganization()
  const {onFetch: onDepartmentsFetch} = useOrgDepartment()
  const {onCheckEmailDuplicate} = useAuth()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const inputClasses = useBoldInputStyle()

  const handleClose = () => dispatch(closeUserAddDialog())

  const AddSchema = yup.object().shape({
    firstName: yup.string().required(t('INameRequired')),
    lastName: yup.string().required(t('INameRequired')),
    email: yup.string().email(t('IEmailInvalid')).required(t('IEmailRequired')),
    checkedEmail: yup
      .string()
      .required(t('IEmailDuplicatePlease'))
      .oneOf([yup.ref('email'), null], t('IEmailDuplicatePlease')),
    deptId: yup.string().required(t('IDepartmentRequired')),
    tel: yup
      .string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        t('ITelInvalid'),
      ),
  })

  // 이미지 등록
  const [profile, setProfile] = React.useState<File>()
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files
    if (!fileList) return
    const reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/

    if (!fileList[0].name.match(reg)) {
      onFailureModalOpen(t('IInvalidImageFileRequest'))
      return
    }

    setProfile(fileList[0])
  }

  const {
    handleSubmit,
    formState: {errors},
    clearErrors,
    control,
    reset,
    setValue,
    getValues,
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(AddSchema),
    shouldFocusError: true,
  })

  /**
   * TODO: 부서/포지션 리스트 수정
   */

  const [division, setDivision] = useState<Industry>()
  const [position, setPosition] = useState<Industry>()

  const handleDivisionChanged = (industry: Industry) => {
    setDivision(industry ?? 0)
    // setDivisionId(industry.id ?? 0)
  }

  const handlePositionChanged = (industry: Industry) => {
    setPosition(industry)
  }

  const [eegVersionId] = React.useState('')
  const [hrvVersionId] = React.useState('')

  const [departmentValue, setDepartmentValue] = React.useState<string>(
    t('IDepartmentneurology'),
  )
  const handleDepartmentValueChanged = (department: string) =>
    setDepartmentValue(department)

  const handleEmailDuplicate = async () => {
    const email = getValues('email')
    if (!email) return
    try {
      await onCheckEmailDuplicate(email)
      setValue('checkedEmail', email)
      clearErrors(['email', 'checkedEmail'])
    } catch (err) {
      setError('checkedEmail', {type: 'custom', message: t('IEmailIsExists')})
    }
  }

  const queryClient = useQueryClient()
  const mutation = useMutation(
    (data: OrgUserCreateRequest) => createOrgUserApi(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchUsersList')
        handleClose()
        onSuccessModalOpen(t('IProcessSuccess'))
      },
      onError: (error: any) => {
        onFailureModalOpen(error.message)
      },
    },
  )

  const onSubmit = handleSubmit(
    ({firstName, lastName, email, userType, deptId, ...rest}: FormData) => {
      const data: OrgUserCreateRequest = {
        firstName,
        lastName,
        email,
        userType: 'DOCTOR',
        deptId: Number.parseInt(deptId, 10),
        tel: rest.tel || null,
        profile: profile || null,
        eegVersionId: eegVersionId ? Number.parseInt(eegVersionId, 10) : null,
        hrvVersionId: hrvVersionId ? Number.parseInt(hrvVersionId, 10) : null,
        division: division || null,
        position: position || null,
      }

      // const submitData = Object.fromEntries(
      //   Object.entries(data).filter(([k, v]) => v != null),
      // )
      // 옵셔널한 값들을 서버로 전송하지 않기 위해 널을 필터링 한다.
      // 필요한 타입이 달라지는 것이 아니고, 명확하게 알고 쓰기 때문에 타입 단언을 사용해도 괜찮다.
      mutation.mutate(data)
    },
  )

  useEffect(() => {
    if (open) {
      onDepartmentsFetch()
      onOrganizationFetch()
    }
    return () => {
      reset()
    }
  }, [open])

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth='md'>
        <Card className={classes.cardWrap}>
          <div className={classes.root}>
            {/* 상단 타이틀 컨테이너  */}
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <div className={classes.closeButtonWrap}>
                  <IconButton
                    color='secondary'
                    aria-label='favorite'
                    className={classes.closeButton}
                    onClick={handleClose}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <Typography variant='h6' className={classes.title}>
                  {t('IUserCreate')}
                </Typography>
              </div>
            </div>
            <Divider className={classes.titleDivider} />
            <div className={classes.scroll}>
              {/* 컨텐츠 컨테이너 */}
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {' '}
                    {t('IInstituteTitleName')}{' '}
                  </p>
                </div>

                <input
                  className={inputClasses.input}
                  defaultValue={organization?.orgName ?? '-'}
                  disabled
                />
              </div>
              {(i18n.language === 'ko' || i18n.language === 'ja') && (
                <Fragment>
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IFirstName')}
                      </p>
                      <p className={inputClasses.require}>*</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <input
                            placeholder={t('ILastName')}
                            className={
                              errors.lastName
                                ? `${inputClasses.errorInput}`
                                : `${inputClasses.input}`
                            }
                            type='text'
                            {...field}
                          />
                        )}
                      />
                      <Typography className={classes.padding} />
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <input
                            placeholder={t('IFirstName')}
                            className={
                              errors.firstName
                                ? `${inputClasses.errorInput}`
                                : `${inputClasses.input}`
                            }
                            {...field}
                          />
                        )}
                      />
                    </div>
                    {errors.firstName !== null && errors.lastName !== null && (
                      <span className={classes.errorText}>
                        {t(
                          errors.firstName
                            ? errors.firstName?.message ?? ''
                            : errors.lastName?.message ?? '',
                        )}
                      </span>
                    )}
                  </div>
                </Fragment>
              )}
              {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                <Fragment>
                  <div className={classes.inputContainer}>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IFirstName')}
                      </p>
                      <p className={inputClasses.require}>*</p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <input
                            placeholder={t('IFirstName')}
                            className={`${inputClasses.input}`}
                            type='text'
                            required
                            {...field}
                          />
                        )}
                      />
                      <Typography className={classes.padding} />
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <input
                            placeholder={t('ILastName')}
                            className={inputClasses.input}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    {errors.firstName !== null && errors.lastName !== null && (
                      <span className={classes.errorText}>
                        {t(
                          errors.firstName
                            ? errors.firstName?.message ?? ''
                            : errors.lastName?.message ?? '',
                        )}
                      </span>
                    )}
                  </div>
                </Fragment>
              )}
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IEmail')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{required: false}}
                    render={({field}) => (
                      <input
                        placeholder={t('IEmailRequired')}
                        className={inputClasses.input}
                        {...field}
                      />
                    )}
                  />
                  <Typography className={classes.padding} />
                  <ActionButton
                    style={{height: 'auto'}}
                    onClick={handleEmailDuplicate}
                    error={!!errors.email || !!errors.checkedEmail}
                    disabled={!!errors.email}
                  >
                    {t('ICheckDuplicate')}
                  </ActionButton>
                </div>
                {errors.email !== null && (
                  <span className={classes.errorText}>
                    {t(errors.email?.message ?? '')}
                  </span>
                )}
                {errors.email == null && errors.checkedEmail !== null && (
                  <span className={classes.errorText}>
                    {t(errors.checkedEmail?.message ?? '')}
                  </span>
                )}
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IPassword')}</p>
                </div>
                <div>* {t('ITemporaryPasswordDesc')}</div>
              </div>
              <div className={classes.inputContainer}>
                {/* <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IUserLicense')}</p>
                  <p className={inputClasses.require}>*</p>
                </div> */}
                {/* <Controller
                  name='licenseNumber'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      type='text'
                      className={inputClasses.input}
                      placeholder={t('IUserLicenseRequired')}
                      {...field}
                    />
                  )}
                />
                {errors.licenseNumber !== null && (
                  <span className={classes.errorText}>
                    {t(errors.licenseNumber?.message ?? '')}
                  </span>
                )} */}
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IUserDepartment')}
                  </p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <FormControl variant='outlined'>
                  <Controller
                    name='deptId'
                    control={control}
                    render={({field}) => (
                      <SelectBoldDepartment
                        selectedValue={departmentValue}
                        onChangeData={handleDepartmentValueChanged}
                        {...field}
                      />
                    )}
                  />
                  {errors.deptId !== null && (
                    <span className={classes.errorText}>
                      {t(errors.deptId?.message ?? '')}
                    </span>
                  )}
                </FormControl>
                <Typography className={classes.departmentDesc}>
                  {t('IUserAddDepartmentDesc')}
                </Typography>
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IMyProfile')}</p>
                </div>

                <div
                  className={
                    errors.profile
                      ? classes.fileContainerError
                      : classes.fileContainer
                  }
                >
                  <div className={classes.fileWrap}>
                    <input
                      id='profile'
                      name='profile'
                      type='file'
                      accept='image/*'
                      onChange={handleImageChange}
                      className={classes.fileInput}
                    />
                    <label className={classes.fileLabel} htmlFor='profile'>
                      <div style={{padding: 10}}>
                        {profile ? profile.name : t('IFileName')}
                      </div>
                      <div className={classes.iconContainer}>
                        <SearchIcon />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('ITel')}</p>
                </div>

                <Controller
                  rules={{required: false}}
                  name='tel'
                  control={control}
                  render={({field}) => (
                    <input
                      placeholder={t('ITelRequired')}
                      className={inputClasses.input}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IUserDivision')}
                  </p>
                </div>

                <SelectBoldIndustryWrapper
                  parent={organization?.industry.id ?? 0}
                  depth={2}
                  selectedValue={null}
                  onChangeData={handleDivisionChanged}
                />
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IUserPosition')}
                  </p>
                </div>

                <SelectBoldIndustryWrapper
                  parent={division?.id}
                  depth={3}
                  selectedValue={null}
                  onChangeData={handlePositionChanged}
                />
              </div>
              <Typography className={classes.padding} />
              <Typography className={classes.padding} />
            </div>

            {/* 하단 버튼 컨테이너  */}
            <div className={classes.buttonContainerBgColor}>
              <div className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  color='default'
                  disableElevation
                  onClick={handleClose}
                  className={classes.okButton}
                >
                  {t('ICancel')}
                </Button>
                <Typography className={classes.smallPadding} />
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  className={classes.okButtonColor}
                  type='submit'
                  onClick={onSubmit}
                >
                  {t('IOk')}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
