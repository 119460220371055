import React from 'react'
import useHealthCenterStyles from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Style'
import {Typography} from '@material-ui/core'

interface Props {
  color: string
  iconName: string
  headerName: string
  content: string
  childContent?: string
  backgroundColor: string
  index: number
}

function NotificationCard({
  color,
  iconName,
  headerName,
  content,
  childContent,
  backgroundColor,
  index,
}: Props) {
  const classes = useHealthCenterStyles()
  const customStyle = {
    backgroundColor,
    borderBottom: `1px solid ${index === 5 ? '#00A29C' : '#d9d9d9'}`,
  }

  return (
    <div className={classes.notification} style={customStyle}>
      <div className={classes.headerNotification} style={{color: `${color}`}}>
        <img className={classes.iconNotification} src={iconName} alt='icon' />
        &nbsp;&nbsp;
        <div className={classes.NotificationHeaderName}>{headerName}</div>
      </div>
      <div className={classes.contentNotification}>
        <div style={{color: `${color}`}} className={classes.notificationHeader}>
          {content}
        </div>
        <div className={classes.notificationChildContent}>{childContent}</div>
      </div>
    </div>
  )
}

export default NotificationCard
