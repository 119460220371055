import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core/styles'
import useHealthCenterStyles from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/Style'
import down from 'pages/HealthCenter/Report/HealthCenterMajorIndicators/down.svg'

const useStyles = makeStyles(() => ({
  topStatus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#B4B4B4',
    color: '#FFF',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120% !important',
    width: '85px',
    height: '85px',
    borderRadius: '50%',
    marginTop: '28px',
  },
  topScore: {
    display: 'flex',
    gap: 8,
    height: '40px',
  },
  width80pc: {
    width: '80%',
  },
  percentBarV2: {
    borderTop: '5px solid #7F8892',
    borderBottom: '1px solid#E0E0E0',
    background: 'rgba(127, 136, 146, 0.05)',
    width: '100%',
    height: 130,
    padding: '0 16px 24px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
interface Props {
  headerName: string
  percentage: number
  index: number
  top: string
  middle: string
  bottom: string
  showIndex?: boolean
}

function PercentBarCardV2({
  headerName,
  percentage,
  index,
  top,
  middle,
  bottom,
  showIndex,
}: Props) {
  const classes = useHealthCenterStyles()
  const styles = useStyles()
  const {t} = useTranslation()
  const getTextInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return top
    }
    if (Math.floor(percentage) > 70) {
      return bottom
    }
    return middle
  }, [percentage])

  const getColorInRound = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF0404'
    }
    if (Math.floor(percentage) > 70) {
      return '#008BC9'
    }
    return '#B4B4B4'
  }, [percentage])

  const getBackGroundIndex = useMemo(() => {
    if (Math.floor(percentage) < 30) {
      return '#FF6666'
    }
    if (Math.floor(percentage) > 70) {
      return '#20A2DC'
    }
    return ''
  }, [percentage])

  return (
    <div className={styles.percentBarV2}>
      <div className={styles.width80pc}>
        <div className={styles.topScore}>
          {!showIndex && (
            <div
              className={classes.topScoreIndex}
              style={{background: getBackGroundIndex}}
            >
              {index}
            </div>
          )}

          <div className={classes.topScoreDetail}>
            <span className={classes.topScorePosition}>{headerName}</span>
          </div>
        </div>
        <div className={classes.percentBarV2Bot}>
          <img
            className={classes.percentBarV2Arrow}
            style={{marginLeft: `${percentage - 1}%`}}
            src={down}
            alt='icon'
          />
          <div className={classes.botBar}>
            <div>
              <div className={classes.marker3} style={{left: '30%'}}>
                <span />
                <p>{top}</p>
              </div>
              <div className={classes.marker3} style={{left: '50%'}}>
                <div />
                <p>{middle}</p>
              </div>
              <div className={classes.marker3} style={{left: '70%'}}>
                <span />
                <p>{bottom}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={styles.topStatus}
        style={{
          background: getColorInRound,
        }}
      >
        <div className={classes.topScorePercent}>
          <span className={classes.topScorePercentScore}>{percentage}</span>
          {t('IPoints')}
        </div>
        <div>{getTextInRound}</div>
      </div>
    </div>
  )
}

export default PercentBarCardV2
