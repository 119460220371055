import styled from 'styled-components'

export interface StyledFieldSetProps {
  direction: 'row' | 'column'
}

export const StyledFieldSet = styled.fieldset<StyledFieldSetProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 5px;
  padding: 10px;
  border: none;

  & label {
    display: flex;
    align-items: center;

    & input {
      margin-right: 6px;
    }

    & .label {
      user-select: none;
    }
  }
`
