// import {FetchListRequest} from 'api/salesApi'
import {FetchPersonalListRequest} from 'api/salesApi'
import {useAppDispatch, useAppSelector} from 'hooks'
import {fetchPersonalUsageAction, selectPersonalUsageList} from './salesSlice'

export default function useSalesPersonalUsage() {
  const dispatch = useAppDispatch()
  const onSearch = (payload: FetchPersonalListRequest) =>
    dispatch(fetchPersonalUsageAction({...payload}))

  const personalUsageList = useAppSelector(selectPersonalUsageList)

  return {
    onSearch,
    personalUsageList,
  }
}
