import classNames from 'classnames'
import React, {ElementType, forwardRef, Ref} from 'react'
import useTextStyles from './useTextStyle'

type Typography = 'title' | 'subTitle' | 'th' | 'content'

type TextBaseProps = {
  typography?: Typography
}

export type TextProps<T extends ElementType> = OverridableProps<
  T,
  TextBaseProps
>

const TextClassName = 'Text'

/**
 * 공통 Text 컴퍼넌트
 *
 * https://evan-moon.github.io/2020/11/28/making-your-components-extensible-with-typescript/
 */
function Text<T extends ElementType = 'span'>(
  {typography = 'content', as, className, ...props}: TextProps<T>,
  ref: Ref<any>,
) {
  const classProp = className ?? ''
  const variant = typography ?? 'content'
  const Component = as ?? 'span'

  const classes = useTextStyles()
  const textClasses = classNames({
    [TextClassName]: true,
    [classes.root]: true,
    [classes.title]: variant === 'title',
    [classes.subTitle]: variant === 'subTitle',
    [classes.th]: variant === 'th',
    [classes.content]: variant === 'content',
    [classProp]: className !== '',
  })

  return <Component className={textClasses} ref={ref} {...props} />
}

export default forwardRef(Text) as typeof Text
