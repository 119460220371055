import {ValueSelectItems} from 'components/V2Select/Select/Select'
import React from 'react'
import {useForm} from 'react-hook-form'
import {TFunction, useTranslation} from 'react-i18next'
import {MultiTherapySettings} from '../../../../@types'

export interface TherapyPoint {
  name: string
  value: 'Y' | 'N'
}

const EEGElectrodeKeys: string[] = [
  'c3',
  'c4',
  'cz',
  'f3',
  'f4',
  'f7',
  'f8',
  'fp1',
  'fp2',
  'fz',
  'o1',
  'o2',
  'p3',
  'p4',
  'pz',
  't3',
  't4',
  't5',
  't6',
]

function TherapyDialogViewModels() {
  const {t} = useTranslation()
  const {handleSubmit, setValue} = useForm()

  // CYM : 테라피 초단위를 시/분/초 단위 문자로 변경
  const makeTimeText = (time: number, t: TFunction) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - hours * 3600) / 60)
    const seconds = time - hours * 3600 - minutes * 60

    let timeText = ''
    let h = ''
    let m = ''
    let s = ''

    if (hours > 0) {
      h = hours === 1 ? t('IRangeHour') : t('IRangeHours')
    }
    if (minutes > 0) {
      m = minutes === 1 ? t('IMinute') : t('IMinutes')
    }
    if (seconds >= 0) {
      s = seconds === 1 ? t('ISecond') : t('ISeconds')
    }

    if (hours > 0) {
      if (minutes > 0) {
        if (seconds > 0) {
          timeText = `${hours}${h} ${minutes}${m} ${seconds}${s}`
        } else {
          timeText = `${hours}${h} ${minutes}${m}`
        }
      } else {
        timeText = `${hours}${h} ${minutes}${m}`
      }
      timeText = `${hours}${h}`
    } else if (minutes > 0) {
      if (seconds > 0) {
        timeText = `${minutes}${m} ${seconds}${s}`
      } else {
        timeText = `${minutes}${m}`
      }
    } else if (seconds >= 0) {
      timeText = `${seconds}${s}`
    }

    return timeText
  }

  const frequencyValues: ValueSelectItems[] = Array.from(
    {length: 45},
    (_, i: number) => {
      return {
        value: i + 1,
        label: `${i + 1}Hz`,
      }
    },
  )
  const settingTimeValues: ValueSelectItems[] = Array.from(
    {length: 30},
    (_, i: number) => {
      return {
        value: (i + 1) * 60,
        label: makeTimeText((i + 1) * 60, t),
      }
    },
  )

  // const settingTimeValues: ValueSelectItems[] = [
  //   {
  //     value: 5 * 60,
  //     label: makeTimeText(5 * 60, t),
  //   },
  //   {
  //     value: 10 * 60,
  //     label: makeTimeText(10 * 60, t),
  //   },
  //   {
  //     value: 15 * 60,
  //     label: makeTimeText(15 * 60, t),
  //   },
  //   {
  //     value: 20 * 60,
  //     label: makeTimeText(20 * 60, t),
  //   },
  //   {
  //     value: 25 * 60,
  //     label: makeTimeText(25 * 60, t),
  //   },
  //   {
  //     value: 30 * 60,
  //     label: makeTimeText(30 * 60, t),
  //   },
  // ]

  const [therapyPointLoading, setTherapyPointLoading] =
    React.useState<boolean>(true)
  const [therapyPoint, setTherapyPoint] = React.useState<TherapyPoint[]>([
    {
      name: 'c3',
      value: 'N',
    },
    {
      name: 'c4',
      value: 'N',
    },
    {
      name: 'cz',
      value: 'N',
    },
    {
      name: 'f3',
      value: 'N',
    },
    {
      name: 'f4',
      value: 'N',
    },
    {
      name: 'f7',
      value: 'N',
    },
    {
      name: 'f8',
      value: 'N',
    },
    {
      name: 'fp1',
      value: 'N',
    },
    {
      name: 'fp2',
      value: 'N',
    },
    {
      name: 'fz',
      value: 'N',
    },
    {
      name: 'o1',
      value: 'N',
    },
    {
      name: 'o2',
      value: 'N',
    },
    {
      name: 'p3',
      value: 'N',
    },
    {
      name: 'p4',
      value: 'N',
    },
    {
      name: 'pz',
      value: 'N',
    },
    {
      name: 't3',
      value: 'N',
    },
    {
      name: 't4',
      value: 'N',
    },
    {
      name: 't5',
      value: 'N',
    },
    {
      name: 't6',
      value: 'N',
    },
  ])
  const [therapyAllCheckToggle, setTherapyAllCheckToggle] =
    React.useState<boolean>(true)

  // 주파수
  const [frequencyThumb, setFrequencyThumb] = React.useState<ValueSelectItems>({
    value: 10,
    label: '10Hz',
  })

  // 진행시간
  const [settingTimeThumb, setSettingTimeThumb] =
    React.useState<ValueSelectItems>({
      value: 5 * 60,
      label: makeTimeText(5 * 60, t),
    })

  // 초기화
  const setInitialStates = () => {
    setTherapyPoint([
      {
        name: 'c3',
        value: 'N',
      },
      {
        name: 'c4',
        value: 'N',
      },
      {
        name: 'cz',
        value: 'N',
      },
      {
        name: 'f3',
        value: 'N',
      },
      {
        name: 'f4',
        value: 'N',
      },
      {
        name: 'f7',
        value: 'N',
      },
      {
        name: 'f8',
        value: 'N',
      },
      {
        name: 'fp1',
        value: 'N',
      },
      {
        name: 'fp2',
        value: 'N',
      },
      {
        name: 'fz',
        value: 'N',
      },
      {
        name: 'o1',
        value: 'N',
      },
      {
        name: 'o2',
        value: 'N',
      },
      {
        name: 'p3',
        value: 'N',
      },
      {
        name: 'p4',
        value: 'N',
      },
      {
        name: 'pz',
        value: 'N',
      },
      {
        name: 't3',
        value: 'N',
      },
      {
        name: 't4',
        value: 'N',
      },
      {
        name: 't5',
        value: 'N',
      },
      {
        name: 't6',
        value: 'N',
      },
    ])

    // setFrequencyThumb({
    //   value: '10',
    //   label: '10Hz',
    // })
    // setSettingTimeThumb({
    //   value: String(5 * 60),
    //   label: makeTimeText(5 * 60, t),
    // })
  }

  const setInitialValues = () => {
    setValue('fp1', 'N')
    setValue('fp2', 'N')
    setValue('c3', 'N')
    setValue('c4', 'N')
    setValue('cz', 'N')
    setValue('f3', 'N')
    setValue('f4', 'N')
    setValue('f7', 'N')
    setValue('f8', 'N')
    setValue('fz', 'N')
    setValue('o1', 'N')
    setValue('o2', 'N')
    setValue('p3', 'N')
    setValue('p4', 'N')
    setValue('pz', 'N')
    setValue('t3', 'N')
    setValue('t4', 'N')
    setValue('t5', 'N')
    setValue('t6', 'N')
  }

  // CYM : 테라피 모든 전극 활성화 / 비활성화 토글 확인 함수
  const isTherapyAllCheck = (therapyPoint: TherapyPoint[]) => {
    let itemLengthY = 0
    let itemLengthN = 0
    const newTherapyPoint = therapyPoint
    newTherapyPoint.map((item: TherapyPoint) => {
      if (item.value === 'Y') itemLengthY += 1
      else if (item.value === 'N') itemLengthN += 1
      return null
    })
    if (itemLengthY === 19) setTherapyAllCheckToggle(false)
    else if (itemLengthN === 19) setTherapyAllCheckToggle(true)
    else setTherapyAllCheckToggle(true)
  }

  // CYM : 테라피 전극 데이터 담기
  const makeTherapyData = (data: MultiTherapySettings) => {
    setTherapyPointLoading(true)

    // setTherapyPoint에 넣을 변수
    const newTherapyPoint: TherapyPoint[] = []

    Object.entries(data).forEach((item) => {
      EEGElectrodeKeys.forEach((key) => {
        if (key === item[0]) {
          newTherapyPoint.push({
            name: item[0],
            value: item[1],
          })
        }
      })
    })

    // CYM : 전체 선택, 선택 해제 토글 확인
    isTherapyAllCheck(newTherapyPoint)

    setTherapyPoint(newTherapyPoint)
    setTherapyPointLoading(false)
  }

  // CYM : 전극 checked toggle 버튼
  const changeLedYN = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name} = event.target
    const idx = Number(event.currentTarget.dataset.idx)

    const beforeArr = therapyPoint.slice()
    const obj = beforeArr[idx]

    const checked = obj.value === 'Y'
    obj.value = checked ? 'N' : 'Y'
    setTherapyPoint(beforeArr)
    setValue(name, obj.value)

    // CYM : 전체 선택, 선택 해제 토글 확인
    isTherapyAllCheck(beforeArr)
  }

  // CYM : 테라피 - 최근 예약, 이전 데이터 불러오기 함수 (최근 예약, 이전 데이터 공통으로 사용)
  const getBeforeTherapyData = (therapyData: MultiTherapySettings) => {
    console.log(`getBeforeTherapyData : ${JSON.stringify(therapyData)}`)
    setValue('fp1', therapyData.fp1)
    setValue('fp2', therapyData.fp2)
    setValue('c3', therapyData.c3)
    setValue('c4', therapyData.c4)
    setValue('cz', therapyData.cz)
    setValue('f3', therapyData.f3)
    setValue('f4', therapyData.f4)
    setValue('f7', therapyData.f7)
    setValue('f8', therapyData.f8)
    setValue('fz', therapyData.fz)
    setValue('o1', therapyData.o1)
    setValue('o2', therapyData.o2)
    setValue('p3', therapyData.p3)
    setValue('p4', therapyData.p4)
    setValue('pz', therapyData.pz)
    setValue('t3', therapyData.t3)
    setValue('t4', therapyData.t4)
    setValue('t5', therapyData.t5)
    setValue('t6', therapyData.t6)
    setValue('frequency', therapyData.frequency)
    setValue('settingTime', therapyData.settingTime)

    setFrequencyThumb({
      value: therapyData.frequency,
      label: `${therapyData.frequency}Hz`,
    })

    setSettingTimeThumb({
      value: therapyData.settingTime,
      label: makeTimeText(therapyData.settingTime, t),
    })

    makeTherapyData(therapyData)
  }

  return {
    frequencyValues,
    settingTimeValues,
    therapyPointLoading,
    setTherapyPointLoading,
    therapyPoint,
    setTherapyPoint,
    therapyAllCheckToggle,
    setTherapyAllCheckToggle,
    frequencyThumb,
    setFrequencyThumb,
    settingTimeThumb,
    setSettingTimeThumb,
    makeTherapyData,
    makeTimeText,
    setInitialStates,
    setInitialValues,
    handleSubmit,
    setValue,
    isTherapyAllCheck,
    changeLedYN,
    getBeforeTherapyData,
  }
}

export default TherapyDialogViewModels
