import {
  CanBePurchasePackage,
  AnalysisProduct,
  CurrencyCode,
} from 'api/paymentApi'
import {
  SelectItemsWithIcon,
  SpreadableWithIcon,
} from 'components/V2Select/IconSelect/IconSelect'
import {TFunction} from 'react-i18next'
import PackageBillingCycleModel from './PackageBillingCycleModel'

export default class CanBePurchasePackageModel
  implements CanBePurchasePackage, SpreadableWithIcon
{
  name: string

  billingType: string

  currencyCode: CurrencyCode

  analysisGroup: [string]

  analyses: [AnalysisProduct]

  billingCycles: [PackageBillingCycleModel]

  description: string

  constructor(raw: CanBePurchasePackage, t: TFunction) {
    this.analyses = raw.analyses
    this.analysisGroup = raw.analysisGroup
    this.description = raw.description
    this.name = raw.name
    this.billingType = raw.billingType
    this.currencyCode =
      CurrencyCode[raw.currencyCode as string as keyof typeof CurrencyCode]
    this.billingCycles = raw.billingCycles.map((item) => {
      return new PackageBillingCycleModel(item, t)
    }) as [PackageBillingCycleModel]
  }

  spread = (): SelectItemsWithIcon[] => {
    return this.billingCycles.map((item) => {
      return item.appearanceValueWithIcon()
    }) as SelectItemsWithIcon[]
  }
}
