import styled from 'styled-components'

export const StyledPrimaryButtonBig = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 222px;
  height: 55px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  background-color: #3cbbbd;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: 300ms ease-out;

  &:hover:not(:disabled) {
    background-color: #369fa1;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export const StyledGrayButtonBig = styled(StyledPrimaryButtonBig)`
  color: #4b4b4b;
  background-color: #e9ecef;

  &:hover:not(:disabled) {
    background-color: #dcdee1;
  }
`

export const StyledButtonPrimary = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  min-width: 64px;
  height: 42px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: #3ebbbd;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: 300ms ease-out;

  &:hover:not(:disabled) {
    background-color: #369fa1;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export const StyledButtonGray = styled(StyledButtonPrimary)`
  background-color: #5a6268;

  &:hover:not(:disabled) {
    background-color: rgb(62, 68, 72);
  }
`

export const StyledRemainsText = styled.span`
  color: #333333;
`

export const StyledTotalityText = styled.span`
  color: #a4a4a4;
`

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
  }
`

// 미납금 / 선택 상품 확인 + - 총 금액 스타일
export const IconBgWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin: 0 30px;
  width: 36px;
  height: 36px;
  background-color: #e9ecef;
  border-radius: 50%;

  & svg {
    width: 12px;
    height: 12px;
  }

  @media only screen and (min-width: 600px) {
    margin: 0 40px;
  }

  @media only screen and (min-width: 1000px) {
    margin: 0 60px;
  }
`

export const StyledContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-y: auto;
`

export const StyledContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 28px 40px;
  width: 100%;
  min-width: max-content;
  position: relative;

  @media only screen and (min-width: 600px) {
    padding: 28px 60px;
  }

  @media only screen and (min-width: 1000px) {
    padding: 28px 107px;
  }
`

export const StyledFullLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e7eb;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const StyledTextGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export const StyledTextBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
`

export const StyledKeyText = styled.span`
  color: #444b52;
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  white-space: nowrap;
`

export const StyledValueText = styled.span`
  color: #444b52;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  white-space: nowrap;
`
