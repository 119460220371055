import {Typography} from '@material-ui/core'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import * as React from 'react'
import {useRef} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

export default function PasswordInput() {
  const createOrgInfo = useFormContext()
  const passwordConfirmInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const schema = yup.object({
    password: yup
      .string()
      .required(t('IRequired'))
      .min(8, t('IPasswordLength'))
      .max(20, t('IPasswordLength'))
      .matches(
        /^.*(?=.{1,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-zA-z]){1}).*$/,
        t('IPasswordInvalid'),
      ),
  })

  const {
    watch,
    formState: {errors},
  } = useFormContext()
  const password = useRef({})
  password.current = watch('password', '')

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IPassword')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.inputWrap}>
          <input
            id='password'
            placeholder={t('IRegisterPassword')}
            className={classes.input}
            type='password'
            {...createOrgInfo.register('password', {
              validate: (password) =>
                schema.validate({password}).catch((e) => e.errors[0]),
            })}
            style={{flex: 'none', height: '45px'}}
          />
          {errors.password && (
            <span className={classes.errorText}>
              {errors.password && errors.password.message}
            </span>
          )}
        </div>
        <Typography className={classes.padding} />
        <div className={classes.inputWrap}>
          <input
            id='passwordConfirm'
            placeholder={t('IRegisterPassword')}
            className={classes.input}
            type='password'
            {...passwordConfirmInfo.register('passwordConfirm', {
              required: `${t('IRequired')}`,
              validate: (value) =>
                value === password.current || `${t('IPasswordSamePlease')}`,
            })}
            style={{flex: 'none', height: '45px'}}
          />
          {errors.passwordConfirm && (
            <span className={classes.errorText}>
              {errors.passwordConfirm.message}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
