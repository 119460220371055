import Button from '@material-ui/core/Button'
import {paymentSubscribeApi} from 'api/paymentApi'
import Card from 'components/atoms/Card'
import {Colors} from 'components/common/useBaseStyle'
import CustomConfirmDialog from 'components/Dialog/CustomConfirmDialog'
import {CONFIRM_CANCEL, CONFIRM_OK} from 'constants/DialogConstant'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrganization from 'features/org/useOrganization'
import {completePaymentAction} from 'features/payment/paymentSlice'
import {isDev, isProd, isStage} from 'helpers/envHelper'
import {useAppDispatch} from 'hooks'
import useScript from 'hooks/useScript'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import useProductStyle from './styles'

type DummyProduct = ProductList & {
  features: string[]
}

const dummyProducts: DummyProduct[] = [
  {
    addPoint: 0,
    additionalTax: 0,
    name: '베이식',
    won: 300_000,
    type: '',
    dollar: 300,
    point: 0,
    features: ['EEG 개별분석', 'EEG Norm 분석', 'EEG 서머리'],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: '스탠다드',
    won: 500_000,
    type: '',
    dollar: 500,
    point: 0,
    features: ['EEG 개별분석', 'EEG Norm 분석', 'EEG 서머리', 'HRV 서머리'],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: '골드',
    won: 800_000,
    type: '',
    dollar: 800,
    point: 0,
    features: [
      'EEG 개별분석',
      'EEG Norm 분석',
      'MCI',
      'EEG 서머리',
      'HRV 서머리',
    ],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: '프로',
    won: 1_000_000,
    type: '',
    dollar: 1_000,
    point: 0,
    features: [
      'EEG 개별분석',
      'EEG Norm 분석',
      'MCI',
      'RoI',
      '3D 리포트',
      'EEG 서머리',
      '고급 HRV 서머리',
    ],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: '프리미엄',
    won: 1_500_000,
    type: '',
    dollar: 1_500,
    point: 0,
    features: [
      'EEG 개별분석',
      'EEG Norm 분석',
      'MCI',
      'RoI',
      '3D 리포트',
      'EEG 서머리',
      'HRV 서머리',
      '고급 EEG 서머리',
      '고급 HRV 서머리',
    ],
  },
]

const dummyProductsEn: DummyProduct[] = [
  {
    addPoint: 0,
    additionalTax: 0,
    name: 'Basic',
    won: 300_000,
    type: '',
    dollar: 300,
    point: 0,
    features: ['EEG Individual Analysis', 'EEG Norm Analysis', 'EEG Summary'],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: 'Standard',
    won: 500_000,
    type: '',
    dollar: 500,
    point: 0,
    features: [
      'EEG Individual Analysis',
      'EEG Norm Analysis',
      'EEG Summary',
      'HRV Summary',
    ],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: 'Gold',
    won: 800_000,
    type: '',
    dollar: 800,
    point: 0,
    features: [
      'EEG Individual Analysis',
      'EEG Norm Analysis',
      'MCI',
      'EEG Summary',
      'HRV Summary',
    ],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: 'Pro',
    won: 1_000_000,
    type: '',
    dollar: 1_000,
    point: 0,
    features: [
      'EEG Individual Analysis',
      'EEG Norm Analysis',
      'MCI',
      'RoI',
      '3D Analysis',
      'EEG Summary',
      'Advanced HRV Summary',
    ],
  },
  {
    addPoint: 0,
    additionalTax: 0,
    name: 'Premium',
    won: 1_500_000,
    type: '',
    dollar: 1_500,
    point: 0,
    features: [
      'EEG Individual Analysis',
      'EEG Norm Analysis',
      'MCI',
      'RoI',
      '3D Analysis',
      'EEG Summary',
      'HRV Summary',
      'Advanced EEG Summary',
      'Advanced HRV Summary',
    ],
  },
]

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

interface PaymentResult {
  impUid: string
  merchantUid: string
  customerUid: string
}

const DummyProductList = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {user: currentUser} = useAuth()
  const {organization: orgInfo} = useOrganization()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const classes = useProductStyle()
  const {i18n} = useTranslation()
  const currentLocale =
    i18n.language === 'ko' ? 'html5_inicis.MOIisybill' : 'paypal'
  const text = `기존카드 재결제 하겠습니까? \n\n취소 선택시 이전 결제 정보는 사라지고 \n새로운 결제 정보를 등록합니다.`

  const [confirmResult, setConfirmResult] = React.useState<ConfirmResult>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const customerUid = ''

  const paymentProduct: DummyProduct = {
    addPoint: 0,
    additionalTax: 0,
    name: 'default',
    won: 0,
    type: '',
    dollar: 0,
    point: 0,
    features: [],
  }

  const items = i18n.language === 'ko' ? dummyProducts : dummyProductsEn
  // 정기결제 요청
  const repaymentRequest = async (item: DummyProduct) => {
    try {
      const response = await paymentSubscribeApi({
        uid: currentUser?.uid ?? '',
        amount: item.won,
        productName: item.name,
        customerUid: customerUid ?? undefined,
      })

      if (response.success) onSuccessModalOpen('결제가 완료되었습니다.')
      if (!response.success) onFailureModalOpen('결제가 실패하였습니다.')
    } catch (e) {
      onFailureModalOpen(e.message)
      // if (success) {
      //   dispatch(
      //     completePaymentAction({
      //       impUid: response.imp_uid,
      //       uid: currentUser?.uid ?? '',
      //       merchantUid: response.merchant_uid,
      //     }),
      //   )
      // } else {
      //   dispatch(openFailAlert(t('IPurchaseFailure')))
      // }
      setConfirmResult(undefined)
    }
  }

  // 결제 완료후 call 하는 함수
  const paymentCallback = (response: PaymentResult) => {
    const {impUid, merchantUid, customerUid} = response
    dispatch(
      completePaymentAction({
        uid: currentUser?.uid ?? '',
        impUid,
        customerUid,
        merchantUid,
      }),
    )
  }

  const importPayment = (item: DummyProduct) => {
    setConfirmResult(undefined)
    // @ts-ignore
    const {IMP} = window
    IMP.init('imp14809051') // 'imp00000000' 대신 부여받은 "가맹점 식별코드"를 사용
    // TODO: 결제일로부터 30일인지? 매달 1일에 결제 되는지?
    IMP.request_pay(
      {
        pg: currentLocale,
        pay_method: 'card', // "card"만 지원됩니다
        merchant_uid: `mid_${new Date().getTime()}`, // 빌링키 발급용 주문번호
        customer_uid: 'imedisyncTest_0001_1234', // 카드(빌링키)와 1:1로 대응하는 값
        name: item.name,
        amount: item.won, // 0 으로 설정하여 빌링키 발급만 진행합니다.
        buyer_name: currentUser?.name ?? '', // 구매자 이름
        buyer_tel: currentUser?.tel ?? '', // 구매자 전화번호
        buyer_email: currentUser?.email ?? '', // 구매자 이메일
        buyer_addr: orgInfo?.address, // 구매자 주소
        period: {
          from: '20210101',
          to: '20211216',
        },
      },
      // eslint-disable-next-line
      function (rsp: any) {
        if (rsp.success) {
          // 빌링키 발급 성공
          paymentCallback({
            impUid: rsp.imp_uid,
            customerUid: '',
            merchantUid: rsp.merchant_uid,
          })
        } else {
          // 빌링키 발급 실패
          onFailureModalOpen(t('IPurchaseFailure'))
        }
      },
    )
  }
  // 컨펌 다이얼로그 결과
  const onConfirmResult = (item: DummyProduct) => {
    if (confirmResult === CONFIRM_OK) repaymentRequest(item)
    if (confirmResult === CONFIRM_CANCEL) importPayment(item)
  }

  const eximbayCallback = () => {
    setTimeout(() => {
      // eslint-disable-next-line
      const form = document.getElementById('resForm') as any
      const resCode = form.rescode.value
      const resMsg = form.resmsg.value
      if (resCode === '0000') {
        // eslint-disable-next-line
        window.alert('Success Payment')
      } else {
        // eslint-disable-next-line
        window.alert(`Failure with ${resMsg}`)
      }
    }, 250)
  }

  const handleBuy = (item: DummyProduct) => {
    // if (isDev()) handlePayment(item)
    // if (isProd()) handlePreParing()
    // if (isStage()) importPayment(item)
    let env
    if (isProd()) {
      env = 'prod'
    } else if (isStage()) {
      env = 'stage'
    } else {
      env = 'dev'
    }
    const params = new URLSearchParams()
    params.append('ver', '230')
    params.append('txntype', 'PAYMENT')
    params.append('charset', 'UTF-8')
    params.append(
      'statusurl',
      `https://wave.isyncbrain.com:441/${env}/status.php`,
    )
    params.append(
      'returnurl',
      `https://wave.isyncbrain.com:441/${env}/return.php`,
    )
    params.append('rescode', '')
    params.append('resmsg', '')
    params.append('mid', 'eximBayMid')
    params.append('ref', 'demo20170418202020')
    params.append('ostype', 'P')
    params.append('displaytype', 'P')
    params.append('cur', 'USD')
    params.append('amt', `${item.dollar * 2}`) // 3010.25
    params.append('shop', 'iMediSync')
    params.append('buyer', 'Buyer')
    params.append('email', 'jungbae.kim@imedisync.com')
    params.append('tel', '+82-2-747-7422')
    params.append('lang', 'EN')
    params.append('paymethod', 'P000')
    params.append('param1', '')
    params.append('param2', '')
    params.append('param3', '')
    params.append('autoclose', 'N')
    params.append('item_0_product', '')
    params.append('item_0_quantity', '1')
    params.append('item_0_unitPrice', `${item.dollar * 2}`) //
    params.append('surcharge_0_name', 'Shipping+fee')
    params.append('surcharge_0_quantity', '1')
    params.append('surcharge_0_unitPrice', '0') // 10.25

    // call from child window when finish payment sinario
    // eslint-disable-next-line
    const w = window as any
    w.eximbayCallback = eximbayCallback

    let child: Window
    if (isDev()) {
      child = window.open(
        `/eximbay/request?${params.toString()}`,
        'payment2',
        'resizable=yes,scrollbars=yes,width=820,height=600',
      ) as Window
    } else if (isStage()) {
      child = window.open(
        `/eximbay/request?${params.toString()}`,
        'payment2',
        'resizable=yes,scrollbars=yes,width=820,height=600',
      ) as Window
    } else {
      // eslint-disable-next-line
      child = window.open(
        `/eximbay/request?${params.toString()}`,
        'payment2',
        'resizable=yes,scrollbars=yes,width=820,height=600',
      ) as Window
    }
  }

  useEffect(() => {
    onConfirmResult(paymentProduct)
  }, [confirmResult])

  useScript('https://code.jquery.com/jquery-1.12.4.min.js')
  useScript('https://cdn.iamport.kr/js/iamport.payment-1.1.8.js')

  return (
    <>
      <form name='resForm' id='resForm'>
        <input type='hidden' name='rescode' value='' />
        <input type='hidden' name='resmsg' value='' />
      </form>
      <div className={classes.productContainer}>
        <CustomConfirmDialog
          title='결제 요청'
          message={text}
          handleResult={setConfirmResult}
          isOpen={isOpen}
          handleIsOpen={setIsOpen}
        />
        {items.map((item) => (
          <Card className={classes.productItem}>
            <span
              style={{
                color: Colors.primary,
                fontSize: 14,
              }}
            >
              {t('ISubscriptionPlan')}
            </span>
            <p
              style={{
                fontSize: 35,
                fontWeight: 'bold',
                color: Colors.text.title,
                margin: '0px 0px 50px 0px',
              }}
            >
              {item.name}
            </p>
            <ul style={{listStyle: 'none', padding: 0}}>
              {item.features.map((feature) => (
                <li
                  key={feature}
                  style={{color: Colors.text.input_lighten, fontSize: 14}}
                >
                  {feature}
                </li>
              ))}
            </ul>
            <p
              style={{marginTop: 'auto', fontWeight: 'bold', fontSize: 18}}
            >{`$ ${numberWithCommas(item.dollar * 2)} / Month`}</p>
            <Button
              variant='contained'
              color='primary'
              // className={classes.buyButton}
              disableElevation
              onClick={() => handleBuy(item)}
              size='small'
            >
              {t('IBuy')}
            </Button>
          </Card>
        ))}
      </div>
    </>
  )
}

const StorePage = () => {
  // 결제 완료후 call 하는 함수
  // function paymentCallback(response: any) {
  //   const {success, merchantUid, errorMsg} = response
  //   if (success) {
  //     dispatch(
  //       completePaymentRequest({
  //         impUid: response.imp_uid,
  //         uid: currentUser?.uid ?? '',
  //         merchantUid: response.merchant_uid,

  //       }),
  //     )
  //   } else {
  //     dispatch(openFailAlert(t('IPurchaseFailure')))
  //   }
  // }

  // useEffect(() => {
  //   fetchProductList()
  // }, [])
  //
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetPointListRequest())
  //     dispatch(resetPaymentListRequest())
  //     dispatch(resetSelectedOidRequest())
  //   }
  // }, [])
  //

  return (
    <DummyProductList />
    // <div>
    //   <CardWithTitle title={String(t('IStore'))}>
    //     {/* <SubTitle>{t('IPointMyInfo')}</SubTitle>
    //     <PointInfo selectOid={orgInfo?.oid ?? ''} /> */}
    //     {/* <Typography className={classes.padding} />
    //     <SubTitle>{t('IPointPurchase')}</SubTitle> */}
    //     {/* <PointDescription /> */}
    //     {/* <Typography className={classes.padding} /> */}
    //     <div>
    //       <Grid>
    //
    //             setCarouselIndex(value)
    //           }}
    //         >
    //           {list.map((item) => (
    //             <Grid className={classes.carouseContainer}>
    //               {item.map((list) => (
    //                 <div>
    //                   {list.name === 'empty' && (
    //                     <CardWithTitle
    //                       title={String(t('IPointProduct'))}
    //                       className={classes.hidden}
    //                     >
    //                       <Grid className={classes.itemWrapper}>
    //                         <Typography className={classes.pointCont}>
    //                           {list.won} ₩
    //                         </Typography>
    //                         <Typography className={classes.pointCont}>
    //                           {list.point} POINT
    //                         </Typography>
    //                       </Grid>
    //                       <Button
    //                         variant='contained'
    //                         color='primary'
    //                         className={classes.buyButton}
    //                         disableElevation
    //                         onClick={(event) => {}}
    //                       >
    //                         {t('IBuy')}
    //                       </Button>
    //                     </CardWithTitle>
    //                   )}
    //                   {listCheck(list.name, t('ICustomProducts')) && (
    //                     <Grid className={classes.carouse}>
    //                       <Typography variant='h5'>
    //                         {t('IPointProduct')}
    //                       </Typography>
    //
    //                       <Grid className={classes.itemWrapper}>
    //                         <div className={classes.itemName}>
    //                           <Grid className={classes.circleWrap}>
    //                             <span className={styles.smallOutcircle}>
    //                               <span className={styles.smallIncircle}>
    //                                 <span className={styles.smallCircleText}>
    //                                   P
    //                                 </span>
    //                               </span>
    //                             </span>
    //                           </Grid>
    //
    //                           <Typography className={classes.pointCont}>
    //                             {Number(list.point).toLocaleString('ko-KR')}
    //                           </Typography>
    //                           <Typography variant='h6'>POINT</Typography>
    //                         </div>
    //                         {list.addPoint !== 0 && (
    //                           <div
    //                             style={{
    //                               display: 'flex',
    //                               flexDirection: 'row',
    //                               alignItems: 'center',
    //                               gap: 10,
    //                               position: 'absolute',
    //                             }}
    //                           >
    //                             <Typography className={classes.pointCont}>
    //                               +{' '}
    //                               {Number(list.addPoint).toLocaleString(
    //                                 'ko-KR',
    //                               )}
    //                             </Typography>
    //                             <Typography
    //                               variant='h6'
    //                               style={{color: '#3EBBBD', fontSize: 15}}
    //                             >
    //                               POINT
    //                             </Typography>
    //                           </div>
    //                         )}
    //                         {i18n.language === 'ko' && (
    //                           <Typography className={classes.itemWonText}>
    //                             {Number(list.won).toLocaleString('ko-KR')}
    //                             <Typography variant='h6'>
    //                               {t('IMonetaryUnit')}
    //                             </Typography>
    //                           </Typography>
    //                         )}
    //                         {i18n.language === 'en' && (
    //                           <Typography className={classes.itemWonText}>
    //                             <Typography variant='h6'>USD</Typography>
    //                             {Number(list.dollar).toLocaleString('ko-KR')}
    //                           </Typography>
    //                         )}
    //                       </Grid>
    //                       <Button
    //                         variant='contained'
    //                         color='primary'
    //                         className={classes.buyButton}
    //                         disableElevation
    //                         onClick={(event) => {
    //                           setPaymentProduct({
    //                             amount:
    //                               i18n.language === 'ko'
    //                                 ? list.won
    //                                 : list.dollar,
    //                             productName: list.name,
    //                             point: list.point,
    //                             additionalTax: list.additionalTax,
    //                             addPoint: list.addPoint,
    //                           })
    //                           handlePaymentDialog()
    //                         }}
    //                       >
    //                         {t('IBuy')}
    //                       </Button>
    //                     </Grid>
    //                   )}
    //                   {list.name === String(t('ICustomProducts')) && (
    //                     <Grid className={classes.carouse}>
    //                       <Typography variant='h5'>
    //                         {t('ICustomProducts')}
    //                       </Typography>
    //                       <Typography className={classes.padding} />
    //                       <Grid className={classes.itemProductWrapper}>
    //                         <Typography className={classes.itemProductText}>
    //                           <Typography className={classes.padding} />
    //                           <Box>
    //                             <ShoppingCartIcon
    //                               className={classes.itemChartIcon}
    //                             />
    //                           </Box>
    //                           <Typography className={classes.padding} />
    //                           {t('ICustomProductsDescription')}
    //                         </Typography>
    //                         <Typography className={classes.padding} />
    //                       </Grid>
    //
    //                       <Button
    //                         variant='contained'
    //                         color='secondary'
    //                         className={classes.buyButton}
    //                         disableElevation
    //                         onClick={(event) => handleQna()}
    //                       >
    //                         {t('IInquiry')}
    //                       </Button>
    //                     </Grid>
    //                   )}
    //                 </div>
    //               ))}
    //             </Grid>
    //           ))}
    //         </Carousel>
    //         <Typography className={classes.padding} />
    //         <Typography className={classes.padding} />
    //       </Grid>
    //     </div>
    //   </CardWithTitle>
    //   <PaymentDialog
    //     amount={paymentProduct?.amount ?? 0}
    //     productName={paymentProduct?.productName ?? ''}
    //     point={paymentProduct?.point ?? 0}
    //     additionalTax={paymentProduct?.additionalTax ?? 0}
    //     addPoint={paymentProduct?.addPoint ?? 0}
    //   />
    // </div>
  )
}

export default StorePage
