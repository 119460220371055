import {makeStyles} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import Card, {CardProps} from 'components/atoms/Card'
import {Colors} from 'components/common/useBaseStyle'
import SubTitle from 'components/Text/SubTitle'

import React, {ReactNodeArray} from 'react'

const styles = () =>
  createStyles({
    root: {
      borderColor: Colors.border,
      border: '1px solid',
      borderRadius: 0,
      padding: '22px 20px',
    },
  })
const useStyles = makeStyles(styles)

interface CardWithSubTitleProps extends CardProps {
  subTitle: string
  buttons?: ReactNodeArray
}

const CardWithSubTitle = ({
  subTitle,
  buttons = [],
  children,
  className,
  ...props
}: CardWithSubTitleProps) => {
  const classes = useStyles()

  const classNames = classnames({
    [classes.root]: true,
    // @ts-ignore
    [className]: className !== undefined,
  })

  return (
    <Card className={classNames} {...props} elevation={0}>
      <SubTitle buttons={buttons}>{subTitle}</SubTitle>
      {children}
    </Card>
  )
}

export default CardWithSubTitle
