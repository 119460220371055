import * as React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'

export default function EmailInput() {
  const createMemberInfo = useFormContext()
  const classes = useBoldInputStyle()
  const {t} = useTranslation()

  const {
    formState: {errors},
  } = useFormContext()

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapContainer}>
        <p className={classes.inputLabel}>{t('IEmail')}</p>
        <p className={classes.require}>*</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <input
          id='email'
          placeholder={t('IEmailRequired')}
          className={classes.input}
          {...createMemberInfo.register('email', {
            required: `${t('IRequired')}`,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('IEmailInvalidDuplicatePlease'),
            },
          })}
        />
      </div>
      <span className={classes.errorText}>
        {errors.email && t(`${errors.email.message}`)}
      </span>
    </div>
  )
}
