import {AllUserRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import InternalErrorPage from 'pages/ErrorPages/InternalErrorPage'
import StorePage from 'pages/StorePages/StorePage'
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'

const StoreRoute = () => {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={AllUserRoles}
          path={RouteConstant.STORE.path}
          exact
          component={StorePage}
        />
        <Route
          path={RouteConstant.ERROR_NOT_FOUND.path}
          component={InternalErrorPage}
        />
      </Switch>
    </CommonLayout>
  )
}

export default StoreRoute
