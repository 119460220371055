import {Spacing, Colors} from 'components/common/useBaseStyle'
import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const NoticeTitle = styled.div`
  margin: ${Spacing.notice.noticeTitleMarginTop}px
    ${Spacing.notice.noticeTitleMarginRight}px;
  font-size: ${Spacing.notice.noticeTitleFontSize}px;
  font-weight: ${Spacing.notice.noticeTitleFontWeight};
  text-align: left;
  color: ${Colors.text.content};
`
export const TitleLine = styled.hr`
  border: ${Spacing.notice.noticeTextMargin};
  width: ${Spacing.notice.noticeDivWidth}%;
  border-bottom: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeTimeInputTextBorderColor};
`

export const InputTitle = styled.div`
  width: ${Spacing.notice.noticeDivWidth}px;
  font-size: ${Spacing.notice.noticeInputTitleFontSize}px;
  font-weight: normal;
  margin-top: ${Spacing.notice.noticeInputTitleMarginTop}px;
  text-align: left;
  color: ${Colors.text.input_lighten};
`
export const NoticeContentWrapper = styled.div`
  display: flex;
  width: ${Spacing.notice.noticeDivWidth}%;
  padding: ${Spacing.notice.noticeTextMargin}
    ${Spacing.notice.noticeContentPaddingBorder}px;
  margin-bottom: ${Spacing.notice.noticeTextHeight}px;
`
export const RadioButtonContainer = styled.div`
  display: flex;
  flex-diretion: column;
  height: ${Spacing.notice.noticeRadioButtonContainerHeight}px;
  width: ${Spacing.notice.noticeDivWidth}%;
`
export const RadioButtonLabel = styled.label`
  width: ${Spacing.notice.noticeRadioButtonLabelWidth}px;
  height: ${Spacing.notice.noticeRadioButtonLabelWidth}px;
  border: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeRadioButtonBorderColor};
  border-radius: ${Spacing.notice.noticeContentPaddingBorder}%;
`

export const RadioButton = styled.input.attrs({type: 'radio'})`
  appearance: none;
  z-index: ${Spacing.notice.noticeRadioButtonZindex};
  cursor: pointer;
  width: ${Spacing.notice.noticeRadioButtonLabelWidth}px;
  height: ${Spacing.notice.noticeRadioButtonLabelWidth}px;
  position: absolute;

  &:checked + ${RadioButtonLabel} {
    border: ${Spacing.notice.noticeTextBorder}px solid #32c7c9;

    &::after {
      content: '';
      display: block;
      background-color: ${Colors.notice.noticeRadioButtonBackgroundColor};
      width: ${Spacing.notice.noticeRadioButtonWidth}px;
      height: ${Spacing.notice.noticeRadioButtonWidth}px;
      margin-left: ${Spacing.notice.noticeRadioButtonMargin}px;
      margin-top: ${Spacing.notice.noticeRadioButtonMargin}px;
      border-radius: ${Spacing.notice.noticeContentPaddingBorder}%;
    }
  }
`

export const RadioButtonText = styled.div`
  width: ${Spacing.notice.noticeDivWidth}px;
  color: ${Colors.notice.noticeRadioButtonTextColor};
  font-size: ${Spacing.notice.noticeRadioButtonFontSize}px;
  position: relative;
  left: ${Spacing.notice.noticeRadioButtonFontLeft}px;
  bottom: ${Spacing.notice.noticeRadioButtonFontbottom}px;
`

export const NoticeInputTitleText = styled.input.attrs({type: 'text'})`
  margin-top: ${Spacing.notice.noticeInputMargin}px;
  width: ${Spacing.notice.noticeInputTitleWidth}px;
  height: ${Spacing.notice.noticeInputTitleHeight}px;
  padding: ${Spacing.notice.noticeInputTitlePadding}px
    ${Spacing.notice.noticeInputTitlePadding}px;
  border: none;
  border: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeTimeInputTextBorderColor};
  background-color: white;
  &::placeholder {
    font-size: ${Spacing.notice.noticeRadioButtonFontSize}px;
    color: ${Colors.notice.noticeInputPlaceHolder};
  }
`

export const NoticeInputContentText = styled.textarea`
  resize: none;
  margin-top: ${Spacing.notice.noticeInputMargin}px;
  width: ${Spacing.notice.noticeInputTitleWidth}px;
  height: ${Spacing.notice.noticeInputContentHeight}px;
  padding: ${Spacing.notice.noticeInputContentPaddingTop}px
    ${Spacing.notice.noticeInputContentPaddingRight}px
    ${Spacing.notice.noticeInputContentPaddingBottom}px
    ${Spacing.notice.noticeInputTitlePadding}px;
  border: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeTimeInputTextBorderColor};
  background-color: white;
  &::placeholder {
    position: relative;
    bottom: ${Spacing.notice.noticeInputPlaceHolderPosition}%;
    font-size: ${Spacing.notice.noticeRadioButtonFontSize}px;
    color: ${Colors.notice.noticeInputPlaceHolder};
  }
`

export const NoticeTimeInputText = styled.input.attrs({type: 'number'})`
  width: ${Spacing.notice.noticeTextWidth}px;
  height: ${Spacing.notice.noticeTextHeight}px;
  border: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeTimeInputTextBorderColor};
  background-color: white;
  margin: ${Spacing.notice.noticeTextMargin}
    ${Spacing.notice.noticeTextMarginRight}px;
  &:disabled {
    background-color: ${Colors.notice.noticeInputTextDisable};
  }
`

export const NoticeAttachedFileLabel = styled.label`
  margin-top: ${Spacing.notice.noticeFileMargin}px;
  width: ${Spacing.notice.noticeFileWidth}px;
  height: ${Spacing.notice.noticeFileHeight}px;
  border: ${Spacing.notice.noticeTextBorder}px solid
    ${Colors.notice.noticeRadioButtonBackgroundColor};
  color: ${Colors.notice.noticeRadioButtonBackgroundColor};
  text-align: center;
  line-height: ${Spacing.notice.noticeTextHeight}px;
  border-radius: ${Spacing.notice.noticeFileBorderRadius}px;
  font-size: ${Spacing.notice.noticeFileFontSize}px;
`

export const NoticeAttachedFileView = styled.div`
  margin-left: ${Spacing.notice.noticeFileViewMarginLeft}px;
  margin-top: ${Spacing.notice.noticeFileViewMarginTop}px;
  margin-bottom: ${Spacing.notice.noticeFileViewMarginBottom}px;
  display: inline-block;
  width: ${Spacing.notice.noticeFileViewWidth}px;
  height: ${Spacing.notice.noticeFileViewHeight}px;
  background-color: ${Colors.notice.noticeFileViewBackgroundColor};
  padding: ${Spacing.notice.noticeFileViewPadding}px;
  color: ${Colors.notice.noticeInputPlaceHolder};
  font-size: ${Spacing.notice.noticeFileViewPadding}px;
  text-align: center;
  user-select: none;
  line-height: ${Spacing.notice.noticeFileViewLineHeight}px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
`
export const NoticeAttachedFileDelete = styled.button`
  width: ${Spacing.notice.noticeFileDeleteWidth}px;
  height: ${Spacing.notice.noticeFileDeleteHeight}px;
  border-radius: ${Spacing.notice.noticeFileDeleteBorderRadius}px;
  background-color: ${Colors.secondary};
  color: white;
  text-align: center;
  text-decoration: none;
  border: ${Spacing.notice.noticeTextMargin};
  outline: ${Spacing.notice.noticeTextMargin};
  margin: ${Spacing.notice.noticeTextMargin};
  margin-left: ${Spacing.notice.noticeInputMargin}px;
  font-size: ${Spacing.notice.noticeFileFontSize}px;
  margin-top: ${Spacing.notice.noticeFileMarginTop}px;
`
