import {CircularProgress} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ReplayIcon from '@material-ui/icons/Replay'
import CardWithTitle from 'components/atoms/CardWithTitle'
import useOrgDepartment from 'features/org/useOrgDepartment'
import AddDialog from 'pages/OrgPage/DepartmentPage/AddDialog'
import DepartmentTable from 'pages/OrgPage/DepartmentPage/DepartmentTable'
import EditDialog from 'pages/OrgPage/DepartmentPage/EditDialog'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const DepartmentPage = () => {
  const {t} = useTranslation()

  const {error, loading, onFetch: onDepartmentsFetch} = useOrgDepartment()

  useEffect(() => {
    onDepartmentsFetch()
  }, [])

  return (
    <>
      <AddDialog />
      <EditDialog />
      <CardWithTitle>
        {error && (
          <div>
            <Button
              variant='outlined'
              onClick={onDepartmentsFetch}
              startIcon={<ReplayIcon />}
            >
              {t('IRetry')}
            </Button>
          </div>
        )}
        {loading && <CircularProgress size='3rem' color='secondary' />}
        {!loading && <DepartmentTable />}
      </CardWithTitle>
    </>
  )
}

export default DepartmentPage
