import {selectError, selectLoading} from 'features/org/orgSlice'
import {
  fetchSalesDetailAction,
  selectSalesDetailInfo,
  selectSalesOidUserListInfo,
} from 'features/sales/salesSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useSalesOrganization() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const organization = useAppSelector(selectSalesDetailInfo)
  const organizationUser = useAppSelector(selectSalesOidUserListInfo)
  const error = useAppSelector(selectError)
  const onFetch = (oid: string) => dispatch(fetchSalesDetailAction({oid}))

  return {
    loading,
    organization,
    organizationUser,
    error,
    onFetch,
  }
}
