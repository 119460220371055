import {createStyles, makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React, {useRef} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {styles} from '../../findFormStyle'

const useStyles = makeStyles(() =>
  createStyles({
    ...styles(),
  }),
)

interface ChangePasswordInputProps {
  isAuthConfirmed: boolean
}

export default function ChangePasswordInput({
  isAuthConfirmed,
}: ChangePasswordInputProps) {
  const {
    watch,
    formState: {errors},
  } = useFormContext()

  const changePasswordData = useFormContext()
  const classes = useStyles()
  const {t} = useTranslation()
  const password = useRef({})
  password.current = watch('password', '')

  const passwordClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.password !== undefined,
  })

  const passwordConfirmClasses = classNames({
    Right: true,
    InputText: true,
    Error: errors.passwordConfirm !== undefined,
  })

  return (
    <div className={classes.root}>
      <label htmlFor='input_password' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IPassword')}</span>
          <input
            id='input_password'
            type='password'
            className={passwordClasses}
            placeholder={t('IPassword')}
            {...changePasswordData.register('password', {
              minLength: {
                value: 8,
                message: 'IPasswordInvalid',
              },
              maxLength: {
                value: 20,
                message: 'IPasswordInvalid',
              },
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
                message: 'IPasswordInvalid',
              },
            })}
            disabled={!isAuthConfirmed}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
          {!errors.passwordConfirm && errors.password && (
            <span className='Right ErrorText'>
              {t(`${errors.password.message}`)}
            </span>
          )}
        </div>
      </label>

      <label htmlFor='input_passwordConfirm' className={classes.inputWithLabel}>
        <div className='Container'>
          <span className='Left Label'>{t('IPasswordConfirm')}</span>
          <input
            id='input_passwordConfirm'
            type='password'
            disabled={!isAuthConfirmed}
            className={passwordConfirmClasses}
            placeholder={t('IPasswordConfirm')}
            {...changePasswordData.register('passwordConfirm', {
              validate: (value) => {
                if (!isAuthConfirmed) return true
                return value === password.current || 'IPasswordSamePlease'
              },
            })}
          />
        </div>
        <div className='Container'>
          <div className='Left' />
          {errors.passwordConfirm && (
            <span className='Right ErrorText'>
              {t(`${errors.passwordConfirm.message}`)}
            </span>
          )}
        </div>
        <div className='Container'>
          <div className='Left' />
          {!isAuthConfirmed && errors.passwordConfirm && (
            <span className='Right ErrorText'>
              {t('IAuthedPasswordRequired')}
            </span>
          )}
        </div>
      </label>
    </div>
  )
}
