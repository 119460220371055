import thumbnailPackage from 'assets/img/thumbnail_package.png'
import thumbnailBundle from 'assets/img/thumbnail_bundle.png'

export const PackageThumbnailStyles = {
  thumbnailBgImage: `${thumbnailPackage}`,
  badgeTextColor: '#4a587d',
  badgeBgColor: '#d1d8e8',
}

export const BundleThumbnailStyles = {
  thumbnailBgImage: `${thumbnailBundle}`,
  badgeTextColor: '#0abba3',
  badgeBgColor: '#f0fffd',
}
