import useStyles from 'components/molcules/ProfitSummaryReport/useStyles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import {Divider, Typography} from '@material-ui/core'

interface HeadCell {
  id: string
  label: string
}

interface SummaryTableProps {
  items: HeadCell[]
  headerTitle: string
}

export default function SummaryTable({items, headerTitle}: SummaryTableProps) {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div>
      <Typography className={classes.padding} />
      <div className={classes.Table}>
        <div className='TableHeader'>
          <div className='title'>{headerTitle}</div>
          <div className='content'>{t('IDescription')}</div>
        </div>
        {items.map((item) => (
          <div>
            <div className='TableCell'>
              <div className='title'>{t(item.id)}</div>
              <div className='content'>{t(item.label)}</div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  )
}
