import {newFileRequest, newNormalRequest} from 'api/index'
import {
  EmergencyDeleteAction,
  EmergencyEditAction,
} from 'features/sales/salesNoticeSlice'

export interface EmergencyNotifyRequest {
  isNotProceed: boolean
  begin?: string | null
  end?: string | null
  contents: string
  KO_attachments?: File | null
  EN_attachments?: File | null
}

export interface EmergencyNotifyContents {
  languageCode: string
  title: string
  content: string
  attachments?: File | null
  deleteImage?: boolean
}

export interface EmergencyNotifyItems {
  id: number
  order: number
  isNotProceed: boolean
  authorName: string
  begin?: string
  end?: string
  contents: EmergencyNotifyContents[]
}

export interface EmergencyNotifyResponse {
  items: EmergencyNotifyItems[]
  page: number
  size: number
  total: number
}

export interface EmergencyNotifyContentsFile {
  languageCode: string
  title: string
  content: string
  attachments?: string | null
  deleteImage?: boolean
}
export interface EmergencyNotifyItemsFile {
  id: number
  order: number
  isNotProceed: boolean
  authorName: string
  begin?: string
  end?: string
  contents: EmergencyNotifyContentsFile[]
}

export interface EmergencyNotifyResponseFileString {
  items: EmergencyNotifyItemsFile[]
  page: number
  size: number
  total: number
}

export interface EmergencyNotifyListRequest {
  page: number
  size: number
}

export interface EmergencyNotifyEditRequest {
  data: EmergencyNotifyRequest
  query: EmergencyEditAction
}

export const getSalesEmergencyApi = (query: EmergencyNotifyListRequest) =>
  newNormalRequest<EmergencyNotifyResponse>({
    method: 'get',
    path: '/emergency-notify',
    params: query,
  })

export const updateSalesEmergencyApi = (data: EmergencyNotifyRequest) => {
  return newFileRequest<any>({
    method: 'post',
    path: '/emergency-notify',
    data,
  })
}

export const deleteSalesEmergencyApi = (data: EmergencyDeleteAction) => {
  newNormalRequest<any>({
    method: 'delete',
    path: 'emergency-notify',
    data,
  })
}

export const editSalesEmergencyApi = (request: EmergencyNotifyEditRequest) => {
  newFileRequest<any>({
    method: 'post',
    path: `emergency-notify/${request.query.notifyId}/`,
    data: request.data,
  })
}
