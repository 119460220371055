/* eslint-disable import/prefer-default-export */
import i18n from 'i18n'

const USAGE_SUMMARY_HEAD = [
  [i18n.t('IDivision'), 'EEG', 'HRV', 'MCI', 'Norm DB', i18n.t('IRemark')],
  [
    'Individual only',
    i18n.t('ISummarySheetBasic'),
    i18n.t('ISummaryAdvanced'),
    '3D',
    'Individual only',
    i18n.t('ISummarySheetBasic'),
    i18n.t('ISummaryAdvanced'),
    i18n.t('IAnalysis'),
    '3D',
  ],
]
const USAGE_SUMMARY_MOCK = [
  [i18n.t('IEnabled'), '', '', '', '', '', '', '', '', '', '', ''],
  [i18n.t('ITotalEA'), '', '', '', '', ''],
  [i18n.t('IAmountOne'), '', '', '', '', ''],
]

const USAGE_SUMMARY_POSITION_HEAD = [
  {
    row: 0,
    rowSpan: [
      {pos: 0, num: 2},
      {pos: 3, num: 2},
      {pos: 5, num: 2},
    ],
    colSpan: [
      {pos: 1, num: 4},
      {pos: 2, num: 3},
      {pos: 4, num: 2},
    ],
  },
  {
    row: 1,
    rowSpan: [],
    colSpan: [],
  },
]

const USAGE_SUMMARY_POSITION_BODY = [
  {
    row: 1,
    rowSpan: [],
    colSpan: [
      {pos: 1, num: 4},
      {pos: 2, num: 3},
      {pos: 4, num: 2},
    ],
  },
  {
    row: 2,
    rowSpan: [],
    colSpan: [
      {pos: 1, num: 4},
      {pos: 2, num: 3},
      {pos: 4, num: 2},
    ],
  },
]

const CUSTOMER_HEAD = [
  [i18n.t('IPersonReceiving'), i18n.t('ICustomerNameInvoice'), ''],
  [i18n.t('IBillingDate'), ''],
]
const CUSTOMER_MOCK = [[i18n.t('ITheTotalAmount'), 0]]

const CUSTOMER_POSITION_HEAD = [
  {
    row: 0,
    rowSpan: [{pos: 0, num: 2}],
    colSpan: [],
  },
  {
    row: 1,
    rowSpan: [],
    colSpan: [],
  },
]

const CUSTOMER_POSITION_BODY = [
  {
    row: 0,
    rowSpan: [],
    colSpan: [{pos: 0, num: 2}],
  },
]

const PRODUCER_HEAD = [
  [i18n.t('IProducer'), i18n.t('IReceiptCompanyName'), '', '성멍', ''],
  ['등록번호', ''],
  ['주소', ''],
  ['전화', ''],
  ['담당자', ''],
]

const PRODUCER_POSITION_HEAD = [
  {
    row: 0,
    rowSpan: [{pos: 0, num: 5}],
    colSpan: [],
  },
  {
    row: 1,
    rowSpan: [],
    colSpan: [{pos: 1, num: 3}],
  },
  {
    row: 2,
    rowSpan: [],
    colSpan: [{pos: 1, num: 3}],
  },
  {
    row: 3,
    rowSpan: [],
    colSpan: [{pos: 1, num: 3}],
  },
  {
    row: 4,
    rowSpan: [],
    colSpan: [{pos: 1, num: 3}],
  },
]

const INVOICE_DETAIL_HEAD = [
  [
    'NO',
    i18n.t('IPumok'),
    i18n.t('IQuantity'),
    i18n.t('IUnitPrice'),
    i18n.t('IAmount'),
    i18n.t('IComparison'),
  ],
]
const INVOICE_DETAIL_MOCK = [
  ['1', '', '', '', '', ''],
  ['2', '', '', '', '', ''],
  ['3', '', '', '', '', ''],
  ['4', '', '', '', '', ''],
  ['5', '', '', '', '', ''],
  [i18n.t('ITotalAmountVAT'), '', '', ''],
  [i18n.t('ISignificant'), ''],
]

const INVOICE_DETAIL_POSITION_BODY = [
  {
    row: 5,
    rowSpan: [],
    colSpan: [
      {pos: 0, num: 2},
      {pos: 2, num: 2},
    ],
  },
  {
    row: 6,
    rowSpan: [],
    colSpan: [
      {pos: 0, num: 1},
      {pos: 1, num: 5},
    ],
  },
]

const DEFAULT_BILLING_HEAD = [
  i18n.t('IDivision'),
  i18n.t('IUnitPriceKRW'),
  i18n.t('IRemark'),
]
const DEFAULT_BILLING_MOCK = [
  [i18n.t('ISaaSSubscriptionE'), '', ''],
  [i18n.t('ISaaSSubscriptionP'), '', ''],
  ['합 계', '', ''],
]

const USAGE_STATUS_HEADER = [
  i18n.t('ICustomerNumber'),
  i18n.t('IDivision'),
  i18n.t('IDateAnalysisRequest'),
  i18n.t('IDateAnalysisCompleted'),
  i18n.t('IPatientCode'),
  i18n.t('IPatientNameV1'),
  i18n.t('IPatientAge'),
  i18n.t('IRemark'),
]

const CUSTOMER_BACKGROUND_POS = {
  head: ['00', '01', '10'],
  body: ['00'],
}

const PROVIDER_BACKGROUND_POS = {
  head: ['00', '01', '03', '10', '20', '30', '40'],
}

const DEFAULT_TEMPLATE_INVOICE = [
  {
    productType: 'eegIndv',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegIndv3d',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegNorm',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegNorm3d',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegSummary',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegSummaryAdv',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'eegMci',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'hrvIndv',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'hrvSummary',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
  {
    productType: 'hrvSummaryAdv',
    usages: [
      {
        minCount: 1,
        maxCount: null,
        price: 0,
      },
    ],
  },
]

export {
  USAGE_SUMMARY_HEAD,
  USAGE_SUMMARY_MOCK,
  USAGE_SUMMARY_POSITION_HEAD,
  USAGE_SUMMARY_POSITION_BODY,
  CUSTOMER_HEAD,
  CUSTOMER_MOCK,
  CUSTOMER_POSITION_HEAD,
  CUSTOMER_POSITION_BODY,
  PRODUCER_HEAD,
  PRODUCER_POSITION_HEAD,
  INVOICE_DETAIL_HEAD,
  INVOICE_DETAIL_MOCK,
  INVOICE_DETAIL_POSITION_BODY,
  DEFAULT_BILLING_HEAD,
  DEFAULT_BILLING_MOCK,
  USAGE_STATUS_HEADER,
  CUSTOMER_BACKGROUND_POS,
  PROVIDER_BACKGROUND_POS,
  DEFAULT_TEMPLATE_INVOICE,
}
