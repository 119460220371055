import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export const Tabs = withStyles((theme: Theme) => {
  return {
    root: {
      borderBottom: '1px solid',
      borderBottomColor: '#f1f1f3',
      backgroundColor: '#ffffff',
      fontSize: '1rem',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor:
    },
  }
})(MuiTabs)

export const Tab = withStyles(() => {
  return {
    root: {
      fontSize: '1rem',
    },
  }
})(MuiTab)

const styles = (theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(1),
    },
    root: {
      position: 'relative',
    },
  })
const useStyles = makeStyles(styles)

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export function TabPanel(props: TabPanelProps) {
  const classes = useStyles()
  const {children, value, index, ...other} = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.root}
    >
      <>
        <Typography className={classes.padding} />
        {children}
      </>
    </div>
  )
}
