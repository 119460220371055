import {PatientSearchRequest} from 'api/patientApi'
import {
  fetchPatientSearch,
  patientDelete,
  selectError,
  selectList,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectQuery,
} from 'features/patient/patientSearchSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function usePatientSearch() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const patients = useAppSelector(selectList)
  const query = useAppSelector(selectQuery)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)

  const onSearch = (payload: PatientSearchRequest) =>
    dispatch(fetchPatientSearch(payload))

  const onRefresh = (param?: PatientSearchRequest) =>
    dispatch(
      fetchPatientSearch({
        ...(param ?? query),
      }),
    )

  const onDelete = (payload: string[]) => dispatch(patientDelete(payload))

  return {
    loading,
    error,
    patients,
    query,
    paging,
    pagingInfo,
    onSearch,
    onRefresh,
    onDelete,
  }
}
