import {RegisterCreditCardRequest} from 'api/paymentApi'
import {getEncryptionValue, getFieldSecurityKey} from 'api/securityApi'
import React from 'react'
import {useAppDispatch} from 'store'
import {createCreditCardAction} from './paymentSlice'

export default function useCreditCard() {
  const dispatch = useAppDispatch()
  const [key, setKey] = React.useState('')

  const getSecurityKeys = async () => {
    const result = await getFieldSecurityKey()
    if (result) {
      setKey(result)
    }
    console.log(key)
    return result
  }

  const registerAction = async (data: any) => {
    const response = await dispatch(createCreditCardAction(data))
    return response
  }

  const onCreate = async (data: RegisterCreditCardRequest): Promise<any> => {
    getSecurityKeys().then((result) => {
      const encryptionData: RegisterCreditCardRequest = {
        creditNumber: getEncryptionValue(result, data.creditNumber),
        expirationPeriod: getEncryptionValue(result, data.expirationPeriod),
        cvc: getEncryptionValue(result, data.cvc),
        isPrimary: true,
      }
      registerAction(encryptionData)
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    })
  }

  return {
    onCreate,
  }
}
