import {makeStyles} from '@material-ui/core'
import Button, {ButtonProps} from '@material-ui/core/Button'
import {createStyles} from '@material-ui/core/styles'
import React from 'react'

const styles = () =>
  createStyles({
    root: {
      minWidth: 65,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
  })
const useStyles = makeStyles(styles)

const TableButton = ({children, ...props}: ButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      color='primary'
      variant='outlined'
      size='small'
      disableElevation
      className={classes.root}
      {...props}
    >
      {children}
    </Button>
  )
}

export default TableButton
