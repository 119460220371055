import React from 'react'

const InternalErrorPage = () => {
  return (
    <div>
      <h3>500</h3>
      <div>Unknown error</div>
    </div>
  )
}

export default InternalErrorPage
