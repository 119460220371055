import React from 'react'
import {TypeScreenGraph} from 'api/analysisApi'
import ICAComponent from 'components/molcules/Report/ICAComponent'
import {CodeType, ReportIcaAnalysisTypeMap} from 'constants/AnalysisConstant'
import IcaRawDataV1 from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawDataV1'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import LeftNumeric from 'assets/img/LeftNumeric.jpeg'

export interface IcaReportItemProps {
  readonly renderingItem: RenderingItem
  readonly codeType: CodeType
  readonly screen: TypeScreenGraph
}

export function IcaReportItem({
  renderingItem,
  codeType,
  screen,
}: IcaReportItemProps) {
  const [indicator, items] = renderingItem

  return (
    <div>
      {indicator.codeId === ReportIcaAnalysisTypeMap.RawData && (
        <IcaRawDataV1
          indicator={indicator}
          items={items}
          type='raw'
          screen={screen}
        />
      )}
      {indicator.codeId ===
        ReportIcaAnalysisTypeMap.ComponentDataTimeSeries && (
        <IcaRawDataV1
          indicator={indicator}
          items={items}
          yAxisImage={LeftNumeric}
          type='component'
          screen={screen}
        />
      )}
      {indicator.codeId === ReportIcaAnalysisTypeMap.ComponentData && (
        <ICAComponent
          indicator={indicator}
          items={items}
          codeType={codeType}
          componentList={[]}
          hideDescription
          hideAllComponentTimeSeries
        />
      )}
      {indicator.codeId === ReportIcaAnalysisTypeMap.CleanedData && (
        <IcaRawDataV1
          indicator={indicator}
          items={items}
          type='cleaned'
          screen={screen}
        />
      )}
    </div>
  )
}
