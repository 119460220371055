import React from 'react'
import MciAnalysisSearch from 'components/molcules/MciAnalysisSearch'
import AnalysisSearch from 'components/molcules/AnalysisSearch'
import HrvAnalysisSearch from 'components/molcules/HrvAnalysisSearch'
import PeriodSearch from 'components/molcules/PeriodSearch'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import {dateToPeriodString, getDefaultPeriodDate} from 'helpers/dateHelper'
import useTableToolbarStyles from './useTableToolbarStyles'

interface TableReportSearchProps {
  reportType: AnalysisReportType
  isRoot: boolean
  query: any
  onSearch: any
  search?: AnalysisSearchKind
  children?: any
}

/**
 * Component with Date Search + Search by keywords
 */
function TableReportSearch(props: TableReportSearchProps) {
  const {reportType, isRoot, query, onSearch, search, children} = props
  const classes = useTableToolbarStyles()
  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())
  // let useSearch: any

  // 2. Search By Keyword
  const handleSearch = (search: AnalysisSearchKind) => {
    onSearch({...query, search, paging: {page: 0, size: 10}})
  }

  // 2. Search By Date period
  const handlePeriod = (period: PeriodDate) => {
    // ignore dumplicated call
    const formatedPeriod = dateToPeriodString(period)
    if (
      formatedPeriod.startDate === query.period.startDate &&
      formatedPeriod.endDate === query.period.endDate
    )
      return
    onSearch({
      ...query,
      paging: {page: 0, size: 10},
      period: dateToPeriodString(period),
    })
  }

  // 5. Map search component by report type
  function renderComponentSearch(reportType: AnalysisReportType) {
    let component: any
    switch (reportType) {
      case AnalysisReportType.EEGIndividual:
      case AnalysisReportType.EEGSummary:
      case AnalysisReportType.EEGNorm:
        component = <AnalysisSearch onSearch={handleSearch} />
        break
      case AnalysisReportType.EEGMCI:
        component = <MciAnalysisSearch onSearch={handleSearch} />
        break
      // Last case = HRV report
      default:
        if (search)
          component = (
            <HrvAnalysisSearch search={search} onSearch={handleSearch} />
          )
        break
    }
    return component
  }
  return (
    <div className={classes[isRoot ? 'root' : 'container']}>
      <PeriodSearch period={selection} onSearch={handlePeriod} />
      {renderComponentSearch(reportType)}
      {children}
    </div>
  )
}

export default TableReportSearch
