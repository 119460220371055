import request from 'api/index'

export interface CommentPageRequest extends PagingRequest {
  uuid: string
}

export const fetchCommentsApi = ({paging, uuid}: CommentPageRequest) =>
  request<PageResponse<CommentModel>>({
    method: 'get',
    path: '/api/patient/comment/v1/list',
    params: {...paging, uuid},
  })

export interface CommentAddRequest {
  uuid: string
  comment: string
  docNo: number
}

export const addCommentApi = (data: CommentAddRequest) =>
  request<ModelResponse<CommentModel>>({
    method: 'post',
    path: '/api/patient/comment/v1/create',
    data,
  })

export interface CommentDeleteRequest {
  uuid: string
  commentIds: number[]
}

export const deleteCommentApi = (data: CommentDeleteRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/patient/comment/v1/delete',
    data,
  })
