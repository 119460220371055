import {EEGResult, fetchIndividualEEGResult} from 'api/analysisApi'
import Fetcher, {FetcherEmpty} from 'components/Fetcher/Fetcher'
import {CodeTypeMap, AnalysisDivision} from 'constants/AnalysisConstant'
import React, {ReactElement} from 'react'

interface IndividualEEGFetcherProps {
  id: number
  children: (data: EEGResult) => ReactElement
}

export default function IndividualEEGFetcher({
  id,
  children,
}: IndividualEEGFetcherProps) {
  return (
    <Fetcher<ModelsResponse<EEGResult>>
      fetchFn={fetchIndividualEEGResult}
      params={[
        {
          individualRequestIds: [id],
          codeType: CodeTypeMap.Individual,
          codeTypeDivision: 'EEG' as AnalysisDivision,
          codeVersion: '3.0',
        },
      ]}
    >
      {(data) => {
        const [eegResult] = data.list
        if (eegResult === undefined) return <FetcherEmpty />

        return children(eegResult)
      }}
    </Fetcher>
  )
}
