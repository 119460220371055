import {OutlinedInput, Select} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import {SelectProps} from '@material-ui/core/Select'
import {fetchCountryList} from 'api/commonApi'
import {useOutlinedInputStyles} from 'components/common/useBoldInputStyle'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

interface SelectCountryProps extends SelectProps {
  className?: string
  defaultCountry?: Country
}

const SelectBoldCountryCode = (props: SelectCountryProps) => {
  const {t} = useTranslation()
  const {className, defaultCountry, ...selectProps} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [countryList, setCountryList] = useState<Country[]>([])
  const outlinedInputClasses = useOutlinedInputStyles()

  const handleFetchCountryList = async () => {
    const response = await fetchCountryList()
    if (response.success) {
      setCountryList(
        response.list.map((item) => ({
          id: item.id,
          code: item.code,
          name: item.name,
          number: item.number,
        })),
      )
      setLoading(false)
    } else {
      setLoading(true)
      setError('error')
    }
  }

  useEffect(() => {
    setLoading(true)
    handleFetchCountryList()
  }, [])

  useEffect(() => {}, [])

  if (loading) {
    return <div>{t('common.loading')}</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Select
      variant='outlined'
      displayEmpty
      input={
        <OutlinedInput
          name='country'
          id='outlined-country-simple'
          classes={outlinedInputClasses}
        />
      }
      className={className}
      defaultValue={defaultCountry?.code ?? 'KR'}
      {...selectProps}
    >
      <MenuItem disabled>{t('ISelectRequired')}</MenuItem>
      {countryList.map((item) => (
        <MenuItem key={item.id} value={item.code}>
          {t(item.name)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectBoldCountryCode
