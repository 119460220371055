import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Table, TableBody, TableHead, TableRow} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'

import {ProductType, ProductUsesType} from 'features/invoice/invoiceSlice'
import useInvoiceView from 'features/invoice/useInvoiceView'

import InvoiceViewStyle from 'pages/CustomerManagement/InvoiceView/Style'
import {
  CUSTOMER_HEAD,
  CUSTOMER_MOCK,
  CUSTOMER_POSITION_HEAD,
  CUSTOMER_POSITION_BODY,
  PRODUCER_HEAD,
  PRODUCER_POSITION_HEAD,
  INVOICE_DETAIL_HEAD,
  INVOICE_DETAIL_MOCK,
  INVOICE_DETAIL_POSITION_BODY,
  PROVIDER_BACKGROUND_POS,
  CUSTOMER_BACKGROUND_POS,
  DEFAULT_TEMPLATE_INVOICE,
} from 'pages/CustomerManagement/InvoiceView/constant'
import {formatDateFromString} from 'helpers/dateHelper'

const useStyles = makeStyles(InvoiceViewStyle)

type CellSpan = {
  pos: number
  num: number
  style?: string
}
interface MergeCell {
  row: number
  rowSpan: CellSpan[]
  colSpan: CellSpan[]
}

function TransactionStatement() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {
    baseFees,
    consumer,
    billingSummary,
    provider,
    usageDetail,
    product,
    templates,
    patchInvoiceData,
    updateUsageSummaryAction,
    settingInvoiceDataAction,
  } = useInvoiceView()

  const [customerHead, setCustomerHead] = useState(CUSTOMER_HEAD)
  const [customerBody, setCustomerBody] = useState(CUSTOMER_MOCK)
  const [providerData, setProviderData] = useState(PRODUCER_HEAD)
  const [productData, setProductData] =
    useState<(string | number)[][]>(INVOICE_DETAIL_MOCK)
  const [posProduct, setPosProduct] = useState(INVOICE_DETAIL_POSITION_BODY)

  const [date, setDate] = useState<string>('')
  const [total, setTotal] = useState<string>('0')
  const [desc, setDesc] = useState<string>('')

  const getNum = (cellSpan: CellSpan[], col: number) => {
    const posData = cellSpan?.map((item) => item.pos)
    if (posData?.includes(col)) {
      return cellSpan?.find((item) => item.pos === col)?.num || 1
    }

    return 1
  }

  const getMergeCell = (data: MergeCell[], row: number, col: number) => {
    let rowSpan = 1
    let colSpan = 1
    if (!data.length) return {rowSpan, colSpan}
    const rowIds = data.map((item) => item.row)
    if (!rowIds.includes(row)) return {rowSpan, colSpan}

    // get rowSpan property
    const cellRowSpanData = data.find((item) => item.row === row)?.rowSpan
    if (cellRowSpanData?.length) {
      rowSpan = getNum(cellRowSpanData, col)
    }

    // get ColSpan property
    const cellColSpanData = data.find((item) => item.row === row)?.colSpan
    if (cellColSpanData?.length) {
      colSpan = getNum(cellColSpanData, col)
    }
    return {rowSpan, colSpan}
  }

  useEffect(() => {
    const newCustomerHead = [...customerHead]
    const newCustomerBody = [...customerBody]
    if (consumer) {
      newCustomerHead[0][2] = consumer.orgName
    }
    if (billingSummary) {
      newCustomerHead[1][1] =
        formatDateFromString(billingSummary.billingDate) ?? ''
      newCustomerBody[0][1] = billingSummary.billingPrice
      setTotal(billingSummary.billingPrice?.toString() ?? '0')
      setDesc(billingSummary.remarks ?? '')
      setDate(formatDateFromString(billingSummary.billingDate) ?? '')
    } else {
      setTotal('0')
      setDesc('')
      setDate('')
    }
    setCustomerHead(newCustomerHead)
    setCustomerBody(newCustomerBody)
  }, [consumer, billingSummary])

  useEffect(() => {
    const newProducer = [...providerData]
    if (provider) {
      newProducer[0][2] = provider.companyName
      newProducer[0][4] = t('IGetFullName', {
        firstName: provider.ceoFirstname,
        lastName: provider.ceoLastname,
      })
      newProducer[1][1] = provider.companyNumber
      newProducer[2][1] = provider.address
      newProducer[3][1] = provider.phone
      newProducer[4][1] = provider.manager
    }
    setProviderData(newProducer)
  }, [provider])

  const productTypes = useMemo(
    () => Array.from(new Set(usageDetail?.map((item) => item.type))),
    [usageDetail],
  )

  const getSegment = (usages: ProductUsesType[], index: number) => {
    const segment: ProductUsesType | undefined = usages.find(
      ({minCount, maxCount}) =>
        index >= (minCount ?? 0) && (maxCount === null || index <= maxCount),
    )
    return segment
  }

  const getProductInfo = (productType: ProductType) => {
    const usageDetailItem = usageDetail
      ?.filter((item) => item.type === productType)
      ?.sort((a, b) => a.sequence - b.sequence)

    const usageCount = usageDetailItem?.length || 0
    const templateFinal = templates?.length
      ? templates
      : DEFAULT_TEMPLATE_INVOICE
    const usages =
      templateFinal.find((item) => item.productType === productType)?.usages ||
      []
    const discount = product?.pkgInfo.discount || 0
    let eegSummarySurcharge = 0
    let count = 1
    const dataRow = []
    while (count <= usageCount) {
      const row = getSegment(usages, count)
      // TODO: calc eegSummarySurcharge
      if (
        usageDetailItem &&
        usageDetailItem[count]?.patientAge &&
        (productType === 'eegSummary' ||
          productType === 'eegSummaryAdv' ||
          productType === 'eegSummarySurcharge') &&
        usageDetailItem[count].patientAge < 20
      ) {
        eegSummarySurcharge = product?.pkgInfo.eegSummarySurcharge || 0
      } else eegSummarySurcharge = 0

      if (!row) {
        count += usageCount - count + 1
        break
      }

      dataRow.push({
        productType,
        minCount: count,
        maxCount: row?.maxCount || '',
        count:
          row?.maxCount && row?.maxCount <= usageCount
            ? row?.maxCount - count + 1
            : usageCount - count + 1,
        price: Math.ceil(
          ((row?.price || 0) * (100 - discount + eegSummarySurcharge)) / 100,
        ),
      })
      if (!row?.maxCount) {
        count += usageCount - count + 1
        break
      }
      count += row?.maxCount
    }
    return dataRow
  }

  const renderTable = () => {
    const dataTable = productTypes
      .map((item) => {
        return getProductInfo(item as ProductType)
      })
      .flat()

    updateUsageSummaryAction(dataTable)
    let index = 0
    let priceBaseFees = 0
    let resultBaseFees: (string | number)[][] = []
    if (baseFees.length === 1) {
      resultBaseFees = [
        [
          index + 1,
          baseFees[0].name,
          1,
          baseFees[0].price,
          baseFees[0].price,
          '',
        ],
      ]
      index += 1
      priceBaseFees = baseFees[0].price
    } else if (baseFees.length === 2) {
      resultBaseFees = [
        [
          index + 1,
          baseFees[0].name,
          1,
          baseFees[0].price,
          baseFees[0].price,
          '',
        ],
        [
          index + 2,
          baseFees[1].name,
          1,
          baseFees[1].price,
          baseFees[1].price,
          '',
        ],
      ]
      index += 2
      priceBaseFees = baseFees[0].price + baseFees[1].price
    }
    const resultTemplate = dataTable.map((item) => {
      const rowData: (string | number | ProductType)[] = [
        index + 1,
        item.productType,
        item.count,
        item.price,
        item.price * item.count,
        '',
      ]
      index += 1
      if (index === 0) rowData.push(billingSummary?.billingDate ?? '')
      return rowData
    })

    const result = [...resultBaseFees, ...resultTemplate]
    const totalCount =
      dataTable.reduce((prev, next) => {
        return (prev ?? 0) + next.count
      }, 0) + baseFees.length

    const totalPrice =
      dataTable.reduce((prev, next) => {
        return (prev ?? 0) + next.price * next.count
      }, 0) + priceBaseFees

    result.push([t('ITotalAmountVAT'), totalCount, `₩${totalPrice}`, ''])
    result.push([t('ISignificant'), billingSummary?.remarks])
    // if (result.length > 2) {
    //   result[0].push('')
    // }

    const newPosProduct = posProduct

    // if (result.length === 2) {
    //   newPosProduct[0].row = -1
    // } else {
    //   newPosProduct[0].rowSpan[0].num = result.length - 2
    // }

    newPosProduct[0].row = result.length - 2
    newPosProduct[1].row = result.length - 1

    setPosProduct(newPosProduct)
    setProductData(result)
  }

  const handleChangeRemark = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDesc(e.target.value)
  }

  const handleBlur = () => {
    const newData = {...patchInvoiceData}
    newData.remarks = desc
    settingInvoiceDataAction(newData)
  }

  useEffect(() => {
    if (productTypes && templates) renderTable()
  }, [templates, productTypes])

  return (
    <div className={classes.defaultBilling} style={{marginTop: 5}}>
      <div className={classes.defaultBillingTitle}>
        {t('ITransactionStatement')}
      </div>
      <div className={classes.defaultBillingTable}>
        <div className={classes.invoiceCommonInfo}>
          <Table>
            <TableHead>
              {customerHead.map((data, rowI) => (
                <TableRow key={`customer_${rowI}`}>
                  {data.map((cell, colI) => (
                    <TableCell
                      key={`cell_customer_${colI}`}
                      {...getMergeCell(CUSTOMER_POSITION_HEAD, rowI, colI)}
                      className={`${classes.headerMax} ${
                        CUSTOMER_BACKGROUND_POS.head.includes(`${rowI}${colI}`)
                          ? classes.backGroundHead
                          : ''
                      } ${
                        rowI === 0 && colI === 0 ? classes.verticalText : ''
                      }`}
                    >
                      {rowI === 1 && colI === 1 ? (
                        <input
                          value={date}
                          className={classes.inputTotal}
                          onChange={(e) => {
                            let inputValue = e.target.value
                            inputValue = inputValue.replace(/[^0-9]/g, '')
                            if (inputValue.length > 8) {
                              inputValue = inputValue.slice(0, 8)
                            }
                            setDate(inputValue)
                          }}
                          onBlur={() => {
                            const newData = {...patchInvoiceData}
                            newData.billingDate = date
                            settingInvoiceDataAction(newData)
                          }}
                        />
                      ) : (
                        cell
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {customerBody.map((data, rowI) => (
                <TableRow key={`customer_body_${rowI}`}>
                  {data.map((cell, colI) => (
                    <TableCell
                      key={`cell_customer_body_${colI}`}
                      {...getMergeCell(CUSTOMER_POSITION_BODY, rowI, colI)}
                      className={`${classes.header}  ${
                        CUSTOMER_BACKGROUND_POS.body.includes(`${rowI}${colI}`)
                          ? classes.backGroundHead
                          : ''
                      }`}
                    >
                      {rowI === customerBody.length - 1 && colI === 1 ? (
                        <input
                          value={total}
                          className={classes.inputTotal}
                          onChange={(e) => {
                            const inputValue = e.target.value
                            const sanitizedValue = inputValue.replace(
                              /[^0-9]/g,
                              '',
                            )
                            setTotal(sanitizedValue)
                          }}
                          onBlur={() => {
                            const newData = {...patchInvoiceData}
                            newData.billingPrice = parseInt(total ?? 0, 10)
                            settingInvoiceDataAction(newData)
                          }}
                        />
                      ) : (
                        cell
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Table>
            <TableHead>
              {providerData.map((data, rowI) => (
                <TableRow key={`producer_${rowI}`}>
                  {data.map((cell, colI) => (
                    <TableCell
                      key={`cell_producer_body_${colI}`}
                      {...getMergeCell(PRODUCER_POSITION_HEAD, rowI, colI)}
                      className={`${rowI === 0 ? classes.header : classes.cell} 
                      ${
                        PROVIDER_BACKGROUND_POS.head.includes(`${rowI}${colI}`)
                          ? classes.backGroundHead
                          : ''
                      }
                      ${rowI === 0 && colI === 0 ? classes.verticalText : ''}`}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </div>

        <div className={classes.invoiceDetailInfo}>
          <Table>
            <TableHead>
              {INVOICE_DETAIL_HEAD.map((data, rowI) => (
                <TableRow key={`invoice_${rowI}`}>
                  {data.map((cell, colI) => (
                    <TableCell
                      key={`cell_invoice_${colI}`}
                      {...getMergeCell([], rowI, colI)}
                      className={`${classes.header} ${classes.backGroundHead}`}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {productData.map((data, rowI) => (
                <TableRow key={`body_invoice_${rowI}`}>
                  {data.map((cell, colI) => (
                    <TableCell
                      id={`body_invoice_${rowI}_${colI}`}
                      key={`cell_body_invoice${colI}`}
                      {...getMergeCell(posProduct, rowI, colI)}
                      className={`${classes.cell} ${
                        rowI === productData.length - 2 && colI !== 3
                          ? classes.backGroundHead
                          : ''
                      } ${
                        rowI !== productData.length - 2 && colI === 0
                          ? classes.noColumn
                          : ''
                      }`}
                    >
                      {rowI === productData.length - 1 && colI === 1 ? (
                        <textarea
                          value={desc}
                          rows={5}
                          className={classes.inputDescription}
                          onChange={(e) => {
                            handleChangeRemark(e)
                          }}
                          onBlur={handleBlur}
                        />
                      ) : (
                        cell
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default TransactionStatement
