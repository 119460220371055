import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import AIDementiaPredictionGuide from './AIDementiaPredictionGuide'

const useStyles = makeStyles(HealthCenterReportStyle)

export interface AIDementiaPredictionProps {
  mciResultImage: string
  mciProbability: number
  name: string
}

const AIDementiaPrediction = ({
  mciResultImage,
  mciProbability,
  name,
}: AIDementiaPredictionProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const data = useMemo(() => {
    return [
      {
        index: 1,
        title: t('IAIDementiaPredictionLv1Title1'),
        content: [
          t('IAIDementiaPredictionLv1Tex1'),
          t('IAIDementiaPredictionLv1Tex2'),
        ],
        color: '#15A87A',
        isActive: mciProbability < 50,
      },
      {
        index: 2,
        title: t('IAIDementiaPredictionLv2Title1'),
        content: [
          t('IAIDementiaPredictionLv2Tex1'),
          t('IAIDementiaPredictionLv2Tex2'),
        ],
        color: '#63C54B',
        isActive: mciProbability >= 50 && mciProbability < 60,
      },
      {
        index: 3,
        title: t('IAIDementiaPredictionLv3Title1'),
        content: [
          t('IAIDementiaPredictionLv3Tex1'),
          t('IAIDementiaPredictionLv3Tex2'),
        ],
        color: '#EBC600',
        isActive: mciProbability >= 60 && mciProbability < 70,
      },
      {
        index: 4,
        title: t('IAIDementiaPredictionLv4Title1'),
        content: [
          t('IAIDementiaPredictionLv4Tex1'),
          t('IAIDementiaPredictionLv4Tex2'),
        ],
        color: '#E3A037',
        isActive: mciProbability >= 70 && mciProbability < 85,
      },
      {
        index: 5,
        title: t('IAIDementiaPredictionLv5Title1'),
        content: [
          t('IAIDementiaPredictionLv5Tex1'),
          t('IAIDementiaPredictionLv5Tex2'),
        ],
        color: '#D14126',
        isActive: mciProbability >= 85,
      },
    ]
  }, [mciProbability])
  return (
    <div className={classes.brainBasic}>
      <WrapElement number={1} text='AI 치매 예측'>
        <div className={classes.contentCard}>
          <img
            className={classes.mciResultImage}
            src={mciResultImage}
            alt='mci'
          />
        </div>
        <div className={classes.mciResultDes}>
          <div className={classes.mciResultDesTitle}>
            {t('IMciResultDesTitle', {
              name,
              step: data.find((item) => item.isActive)?.index || 1,
            })}
          </div>
          <div className={classes.mciResultDesContent}>
            <div className={classes.mciResultDesContentList}>
              <div className={classes.mciResultDesContentText}>
                <span>1</span>
                {t('IMciResultDesContentText1')}
              </div>
              <div className={classes.mciResultDesContentText}>
                <span>2</span>
                {t('IMciResultDesContentText2')}
              </div>
            </div>
            <div className={classes.mciResultDesContentText}>
              {t('IMciResultDesContentText3')}
            </div>
          </div>
          <div className={classes.AIDementiaPredictionDetail}>
            {data.map((content) => (
              <AIDementiaPredictionGuide
                index={content.index}
                color={content.color}
                title={content.title}
                content={content.content}
                isActive={content.isActive}
              />
            ))}
          </div>
          <div className={classes.mciResultNote}>
            <div className={classes.mciResultNoteElm}>
              <span>*</span>
              {t('IMciResultNote1')}
            </div>
            <div className={classes.mciResultNoteElm}>
              <span>*</span>
              {t('IMciResultNote2')}
            </div>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default AIDementiaPrediction
