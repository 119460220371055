import React, {ReactNode} from 'react'
import styled, {keyframes} from 'styled-components'
import SettingsIcon from '@material-ui/icons/Settings'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(359deg);
  }
`

interface StyledSettingsProps {
  open: boolean
  width: number
}

export const StyledSettings = styled.div<StyledSettingsProps>`
  position: fixed;
  right: ${(props) => (props.open ? 0 : -props.width)}px;
  top: 140px;
  z-index: 999;
  background-color: #fff;
  box-shadow: -3px 2px 12px -1px rgb(0 0 0 / 15%);
  width: ${(props) => props.width}px;
  //height: 350px;
  transition-duration: 0.4s;
  border: 1.5px solid #4d5d6b;
  padding: 15px;

  & button.toggle {
    cursor: pointer;
    border: none;
    position: absolute;
    left: -41px;
    top: 10px;
    color: #fff;
    background-color: #4d5d6b;
    font-size: 20px;
    border-radius: 20px 0 0 20px;
    width: 40px;
    height: 40px;
    text-align: center;

    & .MuiSvgIcon-root {
      margin-top: 5px;
      animation: ${spin} 3s infinite linear;
    }
  }

  & .Child {
    & .Header {
      margin-bottom: 15px;

      & .Title {
        margin: 0;
        background: #2f404f;
        font-size: 13px;
        color: #fff;
        display: block;
        padding: 7px 10px;
      }

      & .SubTitle {
        margin: 0;
        font-size: 11px;
        font-weight: normal;
        padding: 10px;
        background: #eef1f6;
        display: block;
      }
    }

    & .Contents {
      margin-bottom: 5px;
    }

    & .Footer {
      margin-top: 10px;
      padding-top: 15px;
      border-top: 1px solid #eee;
    }
  }
`

export interface SettingsProps {
  open: boolean
  onOpen: () => void
  children: ReactNode
  width?: number
}

export function Settings({open, onOpen, children, width = 300}: SettingsProps) {
  return (
    <StyledSettings open={open} width={width}>
      <button className='toggle' type='button' onClick={onOpen}>
        <SettingsIcon />
      </button>
      <div className='Child'>{children}</div>
    </StyledSettings>
  )
}
