import {Dialog} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from 'components/atoms/Card'
import {styles} from 'components/Dialog/SuccessDialog'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(styles)

const NoticeConfirmDialog = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {open, title, message, onConfirm, onReject} = useNoticeConfirm()

  return (
    <Dialog open={open} disableEscapeKeyDown className={classes.root}>
      <Card className={classes.alertRoot}>
        <div>
          <div className={classes.alertContainer}>
            <h2>{title}</h2>
            <Typography variant='h6' style={{whiteSpace: 'pre-line'}}>
              {message}
            </Typography>
          </div>

          <div
            className={classes.alertButtonContainer}
            style={{display: 'flex', flexWrap: 'nowrap', gap: 10}}
          >
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={onReject}
              style={{color: '#555555'}}
            >
              {t('ICancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={onConfirm}
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default NoticeConfirmDialog
