import {
  IndividualOrNormativeEEGCodeType,
  toThreeDEEGType,
} from 'api/analysisApi'
import ThreeDReport from 'pages/Popup/ThreeDReport/Report'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {mixed, number, object, SchemaOf} from 'yup'

interface PathParams {
  id: number
  type: IndividualOrNormativeEEGCodeType
}

const QueryItemScheme: SchemaOf<PathParams> = object({
  id: number()
    .typeError('IInvalidRequest')
    .required('IInvalidRequest')
    .min(1, 'IInvalidRequest'),
  type: mixed()
    .oneOf(['I', 'N'], 'IInvalidRequest')
    .required('IInvalidRequest'),
})

function ThreeDReportPage() {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [pathParams, setPathParams] = useState<PathParams>()

  const {id, type} = useParams<{
    id?: string | undefined
    type?: string | undefined
  }>()

  useEffect(() => {
    setLoading(true)
    QueryItemScheme.validate({
      id,
      type,
    })
      .then((value) => setPathParams(value))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false))
  }, [id, type])

  if (loading) return <div>Loading...</div>
  if (!loading && error !== undefined) return <div>{t(error)}</div>
  if (!loading && pathParams !== undefined)
    return (
      <ThreeDReport
        id={pathParams.id}
        type={toThreeDEEGType(pathParams.type)}
      />
    )

  return null
}

export default ThreeDReportPage
