import AxisBottom from '@visx/axis/lib/axis/AxisBottom'
import AxisRight from '@visx/axis/lib/axis/AxisRight'
import {Group} from '@visx/group'
import {scaleBand, scaleLinear} from '@visx/scale'
import {Bar} from '@visx/shape'
import {
  Tooltip,
  withTooltip,
  defaultStyles as defaultTooltipStyles,
} from '@visx/tooltip'
import {WithTooltipProvidedProps} from '@visx/tooltip/lib/enhancers/withTooltip'
import {Colors} from 'components/common/useBaseStyle'
import {Channel} from 'constants/AnalysisConstant'
import {
  channelAccessor,
  PowerSpectrumData,
} from 'pages/Popup/PowerSpectrum/graph/index'
import React, {useCallback, useMemo} from 'react'

const margin = {top: 20, bottom: 40, left: 0, right: 44}

interface BarGraphProps {
  readonly width: number
  readonly height: number
  readonly channel: Channel
  readonly pairableChannel: Channel
  readonly data: PowerSpectrumData[]
}

type BarGraphTooltip = {
  band: string
  value: number
}

const isDeltaRange = (d: PowerSpectrumData) => d.hz >= 1 && d.hz < 4
const isThetaRange = (d: PowerSpectrumData) => d.hz >= 4 && d.hz < 8
const isAlpha1Range = (d: PowerSpectrumData) => d.hz >= 8 && d.hz < 10
const isAlpha2Range = (d: PowerSpectrumData) => d.hz >= 10 && d.hz < 12
const isBeta1Range = (d: PowerSpectrumData) => d.hz >= 12 && d.hz < 15
const isBeta2Range = (d: PowerSpectrumData) => d.hz >= 15 && d.hz < 20
const isBeta3Range = (d: PowerSpectrumData) => d.hz >= 20 && d.hz < 30
const isGammaRange = (d: PowerSpectrumData) => d.hz >= 30 && d.hz < 45

type BandAccessor = (d: PowerSpectrumData) => number
type BandSum = [number, number]
type Band = {band: string; data: number}

const getBand = (d: Band) => d.band
const getBandData = (d: Band) => d.data

const sumReduce =
  (targetAccessor: BandAccessor, pairAccessor: BandAccessor) =>
  (accr: BandSum, curr: PowerSpectrumData): BandSum => {
    return [accr[0] + targetAccessor(curr), accr[1] + pairAccessor(curr)]
  }

const calcBand = (targetSum: number, pairSum: number) => {
  return Number(-(((targetSum - pairSum) * 100) / targetSum).toFixed(0))
}

const BarGraph = withTooltip<BarGraphProps, BarGraphTooltip>(
  ({
    width,
    height,
    data: originData,
    channel,
    pairableChannel,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip,
    hideTooltip,
  }: BarGraphProps & WithTooltipProvidedProps<BarGraphTooltip>) => {
    const targetAccessor = useCallback(channelAccessor(channel), [channel])

    const pairAccessor = useCallback(channelAccessor(pairableChannel), [
      pairableChannel,
    ])

    const data: Band[] = useMemo(() => {
      const reduce = sumReduce(targetAccessor, pairAccessor)

      const delta = calcBand(
        ...originData.filter(isDeltaRange).reduce(reduce, [0, 0]),
      )
      const theta = calcBand(
        ...originData.filter(isThetaRange).reduce(reduce, [0, 0]),
      )
      const alpha1 = calcBand(
        ...originData.filter(isAlpha1Range).reduce(reduce, [0, 0]),
      )
      const alpha2 = calcBand(
        ...originData.filter(isAlpha2Range).reduce(reduce, [0, 0]),
      )
      const beta1 = calcBand(
        ...originData.filter(isBeta1Range).reduce(reduce, [0, 0]),
      )
      const beta2 = calcBand(
        ...originData.filter(isBeta2Range).reduce(reduce, [0, 0]),
      )
      const beta3 = calcBand(
        ...originData.filter(isBeta3Range).reduce(reduce, [0, 0]),
      )
      const gamma = calcBand(
        ...originData.filter(isGammaRange).reduce(reduce, [0, 0]),
      )

      const bandData: Band[] = [
        {band: 'δ', data: delta},
        {band: 'θ', data: theta},
        {band: 'α1', data: alpha1},
        {band: 'α2', data: alpha2},
        {band: 'β1', data: beta1},
        {band: 'β2', data: beta2},
        {band: 'β3', data: beta3},
        {band: 'γ', data: gamma},
      ]
      return bandData
    }, [originData, targetAccessor, pairAccessor])

    const xMax = useMemo(() => width - margin.left - margin.right, [width])
    const yMax = useMemo(() => height - margin.top - margin.bottom, [height])

    const xScale = useMemo(
      () =>
        scaleBand<string>({
          range: [0, xMax],
          round: true,
          domain: data.map(getBand),
          padding: 0.4,
        }),
      [xMax],
    )

    const yScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [yMax, 0],
          round: true,
          domain: [-100, 100],
        }),
      [yMax, data],
    )

    return (
      <div style={{position: 'relative', height}}>
        <svg width={width} height={height}>
          <Group top={margin.top}>
            {data.map((d) => {
              const band = getBand(d)
              const data = getBandData(d)
              const barWidth = xScale.bandwidth()
              const barHeight = Math.abs(yScale(data) - yScale(0))
              const barX = xScale(band)
              const barY = yScale(Math.max(0, data))
              return (
                <Bar
                  key={`bar-${band}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={data <= 0 ? Colors.error : Colors.primary}
                  onMouseOver={() =>
                    showTooltip({
                      tooltipTop: yMax / 2,
                      tooltipLeft: barX ?? 0 + barWidth / 2 - 27,
                      tooltipData: {
                        band,
                        value: data,
                      },
                    })
                  }
                  onMouseLeave={() => hideTooltip()}
                />
              )
            })}
          </Group>
          <AxisBottom
            top={yMax + margin.top}
            scale={xScale}
            stroke={Colors.black}
            tickStroke={Colors.black}
            tickFormat={(d) => d}
            tickLabelProps={() => ({
              fill: Colors.text.content,
              fontSize: 11,
              textAnchor: 'middle',
            })}
          />
          <AxisRight
            top={margin.top}
            left={xMax}
            scale={yScale}
            stroke={Colors.black}
            tickStroke={Colors.black}
            tickFormat={(d) => `${d} %`}
            tickLabelProps={() => ({
              fill: Colors.text.content,
              fontSize: 11,
              textAnchor: 'start',
            })}
            numTicks={5}
          />
        </svg>
        {tooltipOpen && tooltipData && (
          <Tooltip
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultTooltipStyles,
              backgroundColor: '#283238',
              color: 'white',
            }}
          >
            <div>
              <strong>{tooltipData.band}</strong>
            </div>
            <div style={{marginTop: 5, fontSize: 12}}>
              <span>{tooltipData.value} %</span>
            </div>
          </Tooltip>
        )}
      </div>
    )
  },
)

export default BarGraph
