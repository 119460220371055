import Colors from '../../../theme/Colors'
import Size from '../../../theme/Size'
import Weight from '../../../theme/Weight'

export const PrimaryButtonStyles = {
  buttonColor: Colors.common.primary,
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.NormalButton.width,
  hoverEvent: true,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}
export const PrimaryDisabledButtonStyles = {
  buttonColor: Colors.common.disabled,
  buttonBackgroundColor: Colors.common.disabled,
  buttonBackgroundHoverColor: Colors.common.disabled,
  buttonWidth: Size.NormalButton.width,
  hoverEvent: true,
  buttonHeight: Size.NormalButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const SmallButtonStyles = {
  buttonColor: Colors.common.primary,
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.BigSmallButton.width,
  hoverEvent: true,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const VerySmallButtonStyles = {
  buttonColor: Colors.common.primary,
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: Colors.common.primary_hover,
  buttonWidth: Size.OutLineVerySmallButton.width,
  hoverEvent: true,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const SmallSecondaryButtonStyles = {
  buttonColor: Colors.common.secondary,
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  buttonWidth: Size.BigSmallButton.width,
  buttonHeight: Size.BigSmallButton.height,
  hoverEvent: true,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const VerySmallSecondaryButtonStyles = {
  buttonColor: '#dadada',
  buttonTextColor: '#666',
  buttonBackgroundColor: '#dadada',
  buttonBackgroundHoverColor: Colors.common.secondary_hover,
  hoverEvent: false,
  buttonWidth: Size.OutLineVerySmallButton.width,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const SmallLedOnButtonStyles = {
  buttonColor: '#4C657A',
  borderColor: '#92aabf',
  buttonBackgroundColor: Colors.common.white,
  // buttonBackgroundHoverColor: '#e37573',
  buttonBackgroundHoverColor: '#4C657A',
  buttonWidth: Size.BigSmallButton.width,
  hoverEvent: true,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}

export const SmallLedOffButtonStyles = {
  buttonColor: '#92aabf',
  buttonBackgroundColor: Colors.common.white,
  buttonBackgroundHoverColor: '#92aabf',
  buttonWidth: Size.BigSmallButton.width,
  hoverEvent: true,
  buttonHeight: Size.BigSmallButton.height,
  buttonFontSize: Size.font.regular,
  buttonFontWeight: Weight.bolder,
}
