export function makeHipenRemove(string: string): string {
  const telValue = string.replace(/[^0-9]/gi, '')
  return telValue
}

export const getAgeWordGroup = (age: number) => {
  switch (true) {
    case age && age < 20:
      return 'IAgeGroupYoung'
    case age && age >= 20 && age < 50:
      return 'IAgeGroupAdult'
    case age && age >= 50:
      return 'IAgeGroupOld'
    default:
      return 'IUnknown'
  }
}

export const getGenderWordString = (type: any) => {
  switch (type) {
    case 'MAN':
      return 'IGenderWordMale'
    case 'WOMAN':
      return 'IGenderWordFemale'
    case 'UNKNOWN':
      return 'IDoNotKnow'
    default:
      return 'IDoNotKnow'
  }
}

export const getGenderString = (type: any) => {
  switch (type) {
    case 'MAN':
      return 'IGenderMale'
    case 'WOMAN':
      return 'IGenderFemale'
    case 'UNKNOWN':
      return 'IDoNotKnow'
    default:
      return 'IDoNotKnow'
  }
}

export const getHandString = (type: any) => {
  switch (type) {
    case 'BOTH':
      return 'IHandednessBoth'
    case 'LEFT':
      return 'IHandednessLeft'
    case 'RIGHT':
      return 'IHandednessRight'
    case 'UNKNOWN':
      return 'IDoNotKnow'
    default:
      return 'IDoNotKnow'
  }
}

// eslint-disable-next-line import/prefer-default-export
export function toUserTypeString(userType: UserType | string = '') {
  if (userType === 'STAFF') {
    return 'IUserTypeStaff'
  }
  if (userType === 'DOCTOR') {
    return 'IUserTypeDoctor'
  }

  return '-'
}

export const toLocaleUppercase = (locale: string) => {
  if (locale === 'ko') return 'KO'
  return 'EN'
}

export const getFullNamePerson = (
  lastName: string,
  firstName: string,
  lang: string,
): string => {
  if (lang === 'ko') return `${lastName} ${firstName}`
  return `${firstName} ${lastName}`
}
