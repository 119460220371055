import {AllUserRoles, Role} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import {isNotDefined} from 'helpers/commonHelper'
import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'

interface RouteIfProps extends RouteProps {
  requiredRoles: Role[]
}

/**
 * 인가 라우트.
 * 인증되어 있지 않으면 인증페이지로,
 * 하위계정 선택되어 있지 않으면 계정 선택 페이지로,
 * 제시된 권한과 일치하지 않으면 Forbidden 페이지로.
 */
const RouteIf = ({
  requiredRoles = AllUserRoles,
  children,
  component: Component,
  ...rest
}: RouteIfProps) => {
  const {user} = useAuth()

  return (
    <Route
      {...rest}
      // eslint-disable-next-line consistent-return
      render={(props) => {
        if (isNotDefined(user)) {
          return (
            <Redirect
              to={{
                pathname: RouteConstant.LOGIN.path,
                state: {from: props.location},
              }}
            />
          )
        }

        if (!requiredRoles.includes(user.authority)) {
          return (
            <Redirect
              to={{
                pathname: RouteConstant.ERROR_FORBIDDEN.path,
                state: {from: props.location},
              }}
            />
          )
        }

        if (children) return children
        if (Component) return <Component {...props} />

        return null
      }}
    />
  )
}

export default RouteIf
