import {isProd} from 'helpers/envHelper'
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  addPatientApi,
  createVisitNumberPatientApi,
  CreateVisitNumberPatientRequest,
  editPatientApi,
  fetchPatientApi,
  PatientAddRequest,
  PatientEditRequest,
} from 'api/patientApi'
import {getHdAnalysisTypesApi, HdAnalysisTypes} from 'api/healthCareApi'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'
import {
  fetchPatientSearch,
  selectQuery,
} from 'features/patient/patientSearchSlice'
import i18n from 'i18n'
import {RootState} from 'store'

export const fetchPatientWhiteListRead = createAsyncThunk(
  'api/patient/read',
  async (uuid: string, {rejectWithValue}) => {
    try {
      const response = await fetchPatientApi({uuid})
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchHdAnalysisTypes = createAsyncThunk(
  'api/org/v1/healthcare-product',
  async (orgId: string, {rejectWithValue}) => {
    try {
      const response = await getHdAnalysisTypesApi(orgId)
      return response.list
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const registrationPatientWhiteList = createAsyncThunk(
  'patient/create',
  async (payload: PatientAddRequest, {dispatch, rejectWithValue}) => {
    try {
      const result = await addPatientApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      return result
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err)
    }
  },
)

export const registrationVisitNumberPatientWhiteList = createAsyncThunk(
  'patient/createVisitNUmber',
  async (
    payload: CreateVisitNumberPatientRequest,
    {dispatch, rejectWithValue},
  ) => {
    try {
      const result = await createVisitNumberPatientApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      return result
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err)
    }
  },
)

export const updatePatientWhiteList = createAsyncThunk(
  'patient/update',
  async (payload: PatientEditRequest, {dispatch, getState}) => {
    try {
      const result = await editPatientApi(payload)
      console.log(result, 'resutl?')
      if (result.success) {
        await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
        const state = getState() as RootState
        const query = selectQuery(state)
        await dispatch(fetchPatientWhiteListRead(payload.uuid))
        await dispatch(fetchPatientSearch(query))
      }
      console.log(result, 'resutl')
      return result
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return err
    }
  },
)

export const openReadWhiteListDialog = createAction<string>(
  'patient_white_list_dialog/read/open',
)
export const closeReadWhiteListDialog = createAction(
  'patient_white_list_dialog/read/close',
)

export const openUpdateWhiteListDialog = createAction<string>(
  'patient_white_list_dialog/update/open',
)
export const closeUpdateWhiteListDialog = createAction(
  'patient_white_list_dialog/update/close',
)

export const openRegistrationWhiteListDialog = createAction(
  'patient_white_list_dialog/create/open',
)
export const closeRegistrationWhiteListDialog = createAction(
  'patient_white_list_dialog/create/close',
)

interface PatientState {
  uuid?: string
  entity?: Patient
  loading: boolean
  error: unknown
  readDialogOpen: boolean
  registrationDialogOpen: boolean
  updateDialogOpen: boolean
  listProducts: HdAnalysisTypes[]
}

export const initialState: PatientState = {
  readDialogOpen: false,
  registrationDialogOpen: false,
  updateDialogOpen: false,
  loading: false,
  error: null,
  listProducts: [],
}

export const patientWhiteListSlice = createSlice({
  name: 'patientWhiteListSlice',
  initialState,
  reducers: {
    openReadDialog(state, action: PayloadAction<string>) {
      state.readDialogOpen = true
      state.uuid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openReadWhiteListDialog, (state, action) => {
        state.readDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeReadWhiteListDialog, (state) => {
        state.entity = {} as Patient
        state.readDialogOpen = false
      })
      .addCase(openUpdateWhiteListDialog, (state, action) => {
        state.updateDialogOpen = true
        state.uuid = action.payload
      })
      .addCase(closeUpdateWhiteListDialog, (state) => {
        state.entity = {} as Patient
        state.updateDialogOpen = false
      })
      .addCase(openRegistrationWhiteListDialog, (state) => {
        state.registrationDialogOpen = true
      })
      .addCase(closeRegistrationWhiteListDialog, (state) => {
        state.registrationDialogOpen = false
      })
      .addCase(fetchPatientWhiteListRead.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchPatientWhiteListRead.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload
      })
      .addCase(fetchPatientWhiteListRead.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(fetchHdAnalysisTypes.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchHdAnalysisTypes.fulfilled, (state, action) => {
        state.loading = false
        state.listProducts = action.payload
      })
      .addCase(fetchHdAnalysisTypes.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const selectEntity = (state: RootState) => ({
  entity: state.patientWhiteList.entity,
  loading: state.patientWhiteList.loading,
  error: state.patientWhiteList.error,
})

export const selectReadDialog = (state: RootState) => ({
  entity: state.patientWhiteList.entity,
  open: state.patientWhiteList.readDialogOpen,
  uuid: state.patientWhiteList.uuid,
  listProducts: state.patientWhiteList.listProducts,
})

export const selectUpdateDialog = (state: RootState) => ({
  entity: state.patientWhiteList.entity,
  open: state.patientWhiteList.updateDialogOpen,
  uuid: state.patientWhiteList.uuid,
  listProducts: state.patientWhiteList.listProducts,
})

export const selectRegistrationDialog = (state: RootState) => ({
  openRegistration: state.patientWhiteList.registrationDialogOpen,
  listProducts: state.patientWhiteList.listProducts,
})

export const selectProducts = (state: RootState) => ({
  listProducts: state.patientWhiteList.listProducts,
  loading: state.patientWhiteList.loading,
  error: state.patientWhiteList.error,
})

export default patientWhiteListSlice.reducer
