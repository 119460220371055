import selectArrow from 'assets/img/angle-down-solid.png'
import styled, {css, keyframes} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import React, {useEffect} from 'react'

interface SelectProps {
  isOpened: boolean
}

const selectAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
`
const StyledSelectContainer = styled.div`
  white-space: nowrap;
`
const StyledSelectHeader = styled.div<SelectProps>`
  user-select: none;
  box-sizing: border-box;
  background: transparent;
  border: ${Spacing.select.selectBorderNone};
  border: ${Spacing.select.selectBorder}px solid ${Colors.selectBorder};
  border-radius: ${(props) =>
    props.isOpened
      ? `${Spacing.select.selectBorderRadius}px ${Spacing.select.selectBorderRadius}px 0 0`
      : `${Spacing.select.selectBorderRadius}px`};
  background: url(${selectArrow}) no-repeat;
  background-position: calc(100% - 7px)
    ${Spacing.select.selectBackgroundPositionRightLeft}%;
  background-size: ${Spacing.select.selectBackgroundSizeRightLeft}px
    ${Spacing.select.selectBackgroundSizeTopDown}px;
  color: ${Colors.text.input};
  font-size: ${Spacing.select.selectTextSize}px;

  padding-right: 32px;
  padding-left: 12px;
  height: 40px;
  line-height: 38px;
`
const StyledSelectListContainer = styled.div`
  width: 100%;
  position: relative;
`
const StyledSelectList = styled.ul<SelectProps>`
  position: absolute;
  user-select: none;
  list-style: none;
  padding: ${Spacing.select.selectBorderNone};
  margin: ${Spacing.select.selectBorderNone};
  border: ${Spacing.select.selectBorder}px solid ${Colors.selectBorder};
  border-top: ${Spacing.select.selectListTopBorder}px;
  border-radius: 0 0 ${Spacing.select.selectBorderRadius}px
    ${Spacing.select.selectBorderRadius}px;
  box-sizing: border-box;
  background-color: white;
  color: ${Colors.text.input};
  z-index: ${Spacing.select.selectCustomZindex};
  &:first-child {
    padding-top: ${Spacing.select.selectCustomListPadding};
  }
  ${(props) =>
    props.isOpened &&
    css`
      animation: ${selectAnimation} 0.2s;
    `};

  width: 100%;
`
const StyledSelectListItem = styled.li`
  &:hover {
    background-color: ${Colors.selectBorder};
  }
  &:first-of-type {
    border-top-right-radius: ${Spacing.select.selectBorderRadius}px;
  }

  font-size: 13px;
  line-height: 38px;
  padding-right: 32px;
  padding-left: 12px;
  height: 40px;
`

export interface SelectContent {
  label: string
  value: string
}

interface SelectOrderProps {
  selectContent: SelectContent[]
  setSelectName: (value?: string) => void
}

export default function SelectOrder(props: SelectOrderProps) {
  const {t} = useTranslation()
  const {selectContent, setSelectName} = props
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState<SelectContent>()

  const toggling = () => setIsOpen(!isOpen)
  const onOptionClicked = (value: SelectContent) => {
    setSelectedOption(value)
    setSelectName(value.value)
    setIsOpen(false)
  }

  // 선택된 옵션에 목업 0번째 데이터 넣기
  useEffect(() => {
    if (selectContent.length > 0) {
      setSelectedOption(selectContent[0])
    }
  }, [])

  return (
    <>
      <StyledSelectContainer>
        <StyledSelectHeader isOpened={isOpen} onClick={() => toggling()}>
          {t(selectedOption?.label ?? 'ISelect')}
        </StyledSelectHeader>
        {isOpen && (
          <StyledSelectListContainer>
            <StyledSelectList isOpened={isOpen}>
              {selectContent.map((item) => (
                <StyledSelectListItem
                  key={Math.random() * 10000}
                  value={item.value}
                  onClick={() => onOptionClicked(item)}
                >
                  {t(item.label)}
                </StyledSelectListItem>
              ))}
            </StyledSelectList>
          </StyledSelectListContainer>
        )}
      </StyledSelectContainer>
    </>
  )
}
