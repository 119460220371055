import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchOrgUsersApi} from 'api/userApi'
import {RootState} from 'store'

export const fetchOrgUserSimpleList = createAsyncThunk(
  'api/org/user/simple_list',
  async (_, {rejectWithValue}) => {
    try {
      const response = await fetchOrgUsersApi()
      return response.list
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

interface OrgUsersState {
  users: OrgSimpleUser[]
  loading: boolean
  error: unknown
}

const initialState: OrgUsersState = {
  users: [],
  loading: false,
  error: null,
}

const orgUserListSlice = createSlice({
  name: 'orgUserListSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrgUserSimpleList.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchOrgUserSimpleList.fulfilled, (state, action) => {
      state.loading = false
      state.users = action.payload
    })
    builder.addCase(fetchOrgUserSimpleList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default orgUserListSlice.reducer

export const selectData = (state: RootState) =>
  state.orgUserList.users as OrgSimpleUser[]
export const selectLoading = (state: RootState) => state.orgUserList.loading
export const selectError = (state: RootState) => state.orgUserList.error
