import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'store'
import {
  InvoiceCreateRequest,
  postInvoiceCreatesApi,
  fetchListVersionInvoice,
  InvoiceGetResponse,
  putInvoiceUpdateAPI,
} from 'api/invoiceApis'
import {ID_NEW_INVOICE} from 'pages/CustomerManagement/InvoiceSetting/constant'

export const createInvoice = createAsyncThunk(
  'cs/api/v1/product',
  async (payload: InvoiceCreateRequest, {rejectWithValue}) => {
    try {
      const response = await postInvoiceCreatesApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const updateInvoice = createAsyncThunk(
  'cs/api/v1/product/id',
  async (
    payload: {pid: number; data: InvoiceCreateRequest},
    {rejectWithValue},
  ) => {
    try {
      const response = await putInvoiceUpdateAPI(payload)
      return response.success
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const fetchListInvoice = createAsyncThunk(
  'cs/api/v1/product/fetch',
  async (payload: {orgId: number}, {rejectWithValue}) => {
    try {
      const response = await fetchListVersionInvoice(payload.orgId)
      return response.list
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export type PlanType =
  | 'RegularPlan'
  | 'RegularLimited'
  | 'FlatRateSubscription'
  | 'FlatRateSubscriptionLimited'
  | 'TieredRatePlan'
  | 'OnlyDevice'
  | 'YearSubscription'
  | ''

export type ProductType =
  | 'eegIndv'
  | 'eegIndv3d'
  | 'eegMci'
  | 'eegNorm'
  | 'eegNorm3d'
  | 'eegSummary'
  | 'eegSummaryAdv'
  | 'hrvIndv'
  | 'hrvSummary'
  | 'hrvSummaryAdv'
  | 'eegSummarySurcharge'

export interface ProductUsesType {
  minCount: number | null
  maxCount: number | null
  price: number | null
}

export type ProductUsesKey = keyof ProductUsesType

export interface ProductTemplateType {
  productType: ProductType
  usages: ProductUsesType[]
}

export type BaseFeesType = {name: string; price: number}[]

interface InvoiceState {
  uuid: string | null
  loading: boolean
  error: any
  data: {
    baseFees: BaseFeesType | null
    discount: number
    eegSummarySurcharge: number
    label: string
    packageType: PlanType
    productTemplate: ProductTemplateType[] | null
  }
  idInvoice: number | null
  listInvoice: {label: string; id: number}[]
  dataInvoices: InvoiceGetResponse[]
}

export const initialState: InvoiceState = {
  uuid: null,
  loading: true,
  error: null,
  data: {
    baseFees: null,
    discount: 0,
    eegSummarySurcharge: 0,
    label: '',
    packageType: '',
    productTemplate: null,
  },
  idInvoice: null,
  listInvoice: [],
  dataInvoices: [],
}

export const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState,
  reducers: {
    updatePackageType(state, action: PayloadAction<PlanType>) {
      state.data.packageType = action.payload
    },
    changePackageType(state, action: PayloadAction<PlanType>) {
      state.data.packageType = action.payload
      state.data.baseFees = null
      state.data.productTemplate = null
    },
    changeDiscount(state, action: PayloadAction<number>) {
      state.data.discount = action.payload
    },
    changeProductTemplate(state, action: PayloadAction<ProductTemplateType[]>) {
      state.data.productTemplate = JSON.parse(JSON.stringify(action.payload))
    },
    changeBaseFees(state, action: PayloadAction<BaseFeesType>) {
      state.data.baseFees = JSON.parse(JSON.stringify(action.payload))
    },
    changeSummarySurcharge(state, action: PayloadAction<number>) {
      state.data.eegSummarySurcharge = action.payload
    },
    changeInvoiceVersion(state, action: PayloadAction<number>) {
      state.idInvoice = action.payload
      state.data.label =
        state.listInvoice.find((invoice) => invoice.id === action.payload)
          ?.label ?? ''
    },
    createNewInvoice(state, action: PayloadAction<string>) {
      state.data.packageType = ''
      state.data.baseFees = null
      state.data.productTemplate = null
      state.idInvoice = ID_NEW_INVOICE
      state.data.discount = 0
      state.data.eegSummarySurcharge = 0
      state.data.label = action.payload
      state.listInvoice = [
        ...state.listInvoice,
        {id: ID_NEW_INVOICE, label: action.payload},
      ]
    },
  },
  extraReducers: (builder) => {
    // GET /cs/api/v1/product
    builder.addCase(fetchListInvoice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchListInvoice.fulfilled, (state, action) => {
      state.loading = false
      state.dataInvoices = action.payload
      state.listInvoice = action.payload.map((item, index) => ({
        id: item.id,
        label: item.label ? item.label : `Version_${index + 1}`,
      }))

      if (!state.idInvoice && action.payload.length) {
        state.idInvoice = action.payload[action.payload.length - 1].id
      }
    })
    builder.addCase(fetchListInvoice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    // POST /cs/api/v1/product
    builder.addCase(createInvoice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.loading = false
      state.idInvoice = action.payload
    })
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    // PUT /cs/api/v1/product/id
    builder.addCase(updateInvoice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  changePackageType,
  updatePackageType,
  changeDiscount,
  changeProductTemplate,
  changeBaseFees,
  changeSummarySurcharge,
  changeInvoiceVersion,
  createNewInvoice,
} = invoiceSlice.actions

export const selectPackageType = (state: RootState) =>
  state.invoice.data.packageType

export const selectBaseFees = (state: RootState) => state.invoice.data.baseFees
export const selectProductTemplate = (state: RootState) =>
  state.invoice.data.productTemplate
export const selectDiscount = (state: RootState) => state.invoice.data.discount
export const selectEegSummarySurcharge = (state: RootState) =>
  state.invoice.data.eegSummarySurcharge

export const selectIdInvoice = (state: RootState) => state.invoice.idInvoice
export const selectListInvoice = (state: RootState) => state.invoice.listInvoice
export const selectDataInvoices = (state: RootState) =>
  state.invoice.dataInvoices
export const selectLabelInvoice = (state: RootState) => state.invoice.data.label
export const selectLoading = (state: RootState) => state.invoice.loading

export default invoiceSlice.reducer
