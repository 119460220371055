import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Colors, Styles} from 'components/common/useBaseStyle'

const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      position: 'relative',
      minWidth: 880,
      color: 'red',
      '& td': {
        color: Colors.text.content,
        fontSize: '0.8125rem',
        lineHeight: 2,
        paddingTop: 6,
        paddingBottom: 6,
        height: 43,
      },
    },
    tableMini: {
      minWidth: 720,
    },
    tableHeader: {
      height: 42,
      backgroundColor: Colors.tableHeaderBackground,
      borderRadius: 10,
      '& th': {
        color: Colors.text.content,
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        fontWeight: 'bold',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
    tableDateTime: {
      width: 190,
    },
    tableString: {},
    empty: {
      ...Styles().absoluteAndCenter,
      color: Colors.text.content,
      paddingTop: 50,
    },
    loading: {
      ...Styles().absoluteAndCenter,
      backgroundColor: 'rgba(0,0,0,.1)',
      zIndex: 2,
      color: Colors.white,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    buttonSendInvoice: {
      border: 0,
      margin: '2px 2px',
      fontSize: '14px',
      color: 'white',
      height: '40px',
      minWidth: '100px',
      borderRadius: '6px',
      marginBottom: '15px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cellKey: {
      width: 200,
      border: '1px black solid',
      backgroundColor: '#e9e9e9',
    },
    cellValue: {
      border: '1px black solid',
      width: 200,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    noPadding: {
      padding: '0 0 0 0',
    },
    borderTable: {
      borderRight: '1px black solid',
      borderBottom: '1px black solid',
    },
    borderRow: {
      border: '1px black solid',
    },
    colorBrowTable: {
      borderRight: '1px black solid',
      borderBottom: '1px black solid',
      backgroundColor: '#e9e9e9',
    },
    colorBrowRow: {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    widthCol: {
      border: '1px solid rgba(224, 224, 224, 1)',
      width: 200,
    },
    buttonActiveLink: {
      cursor: 'pointer',
      '&:hover': {
        color: '#1391ff',
      },
    },
  }),
)

export default useTableStyles
