import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import ReplayIcon from '@material-ui/icons/Replay'
import React, {ReactElement} from 'react'
import {useAsync} from 'react-async-hook'

type Unknown = unknown
type UnknownArgs = any[]

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

const useStyles = makeStyles(styles)

const FetcherLoading = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <CircularProgress size='3rem' color='primary' />
    </div>
  )
}

function FetcherError<R extends Unknown, Args extends any[] = UnknownArgs>({
  error,
  execute,
  params,
}: {
  error: Error
  execute: (...args: Args) => Promise<R>
  params: Args | null
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>{error.message}</div>
      <IconButton
        onClick={() => {
          if (params !== null) {
            // @ts-ignore
            execute(...params)
          } else {
            // @ts-ignore
            execute()
          }
        }}
        size='small'
      >
        <ReplayIcon />
      </IconButton>
    </div>
  )
}

const FetcherUnknownError = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>이 상태는 들어올 수 없습니다...</div>
  )
}

export const FetcherEmpty = () => {
  const classes = useStyles()
  return <div className={classes.container}>컨텐츠가 없습니다.</div>
}

export default function Fetcher<
  R extends Unknown,
  Args extends any[] = UnknownArgs,
>({
  params,
  fetchFn,
  children,
  executeOnMount = true,
  executeOnUpdate = false,
}: {
  params: Args
  fetchFn: (...args: Args) => Promise<R>
  children: (data: R) => ReactElement
  executeOnMount?: boolean
  executeOnUpdate?: boolean
}) {
  const {loading, error, result, execute, currentParams} = useAsync(
    fetchFn,
    params,
    {executeOnMount, executeOnUpdate},
  )

  if (loading) return <FetcherLoading />
  if (!loading && error !== undefined)
    return (
      <FetcherError error={error} execute={execute} params={currentParams} />
    )
  if (!loading && result !== undefined) return children(result)

  return <FetcherUnknownError />
}
