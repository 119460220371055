import {TFunction} from 'react-i18next'
import {PackageSubscription} from 'api/paymentApi'
import {
  SelectItemsWithIcon,
  SpreadableWithIcon,
  SpreadItemWithIcon,
} from 'components/V2Select/IconSelect/IconSelect'
import PackageUnitModel from './PackageUnitModel'

export default class PackageSubscriptionModel
  implements PackageSubscription, SpreadableWithIcon, SpreadItemWithIcon
{
  t: TFunction

  period: number

  units: [PackageUnitModel]

  constructor(raw: PackageSubscription, t: TFunction) {
    this.period = raw.period
    this.units = raw.units.map((item) => {
      return new PackageUnitModel(item, t)
    }) as [PackageUnitModel]
    this.t = t
  }

  spread = (): SelectItemsWithIcon[] => {
    return this.units.map((item) => {
      return item.appearanceValueWithIcon()
    }) as SelectItemsWithIcon[]
  }

  // period - 결제 반복
  appearanceValueWithIcon = () => {
    return {
      iconName: 'paymentRepeat',
      optionText: this.t('IRepetition', {
        number: this.period,
      }),
    }
  }
}
