import request, {newNormalRequest} from 'api/index'
import {UserLogListRequest} from 'api/salesApi'
import {InvoicedYearMonth} from 'pages/OrgPage/Interfaces/UsageHistoryAndUnpaidVouchers'
import PaymentHistoryTableItemsModel from 'pages/OrgPage/PaymentManagementPages/Models/PaymentHistoryTableItemsModel'
import UsageHistoryAndUnpaidVouchersModel from 'pages/OrgPage/PaymentManagementPages/Models/UsageHistoryAndUnpaidVouchersModel'
import UsageHistoryTableModel from 'pages/OrgPage/PaymentManagementPages/Models/UsageHistoryTableModel'
import {
  OrganizationBundle,
  OrganizationPackage,
} from '../pages/OrgPage/Interfaces/UnPaidOrder'

export interface PaymentCancelRequest {
  id: number
  uid: string
  oid?: string | null
}

type PaymentStatus = 'ready' | 'paid' | 'failed' | 'cancelled'

interface PaymentStatusRequest {
  id: number
  status: PaymentStatus
}

export const cancelPaymentApi = (data: PaymentCancelRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/payment/v1/cancel',
    data,
  })

export const fetchPaymentListApi = (query: UserLogListRequest) =>
  request<ModelResponse<Payment>>({
    method: 'get',
    path: '/api/payment/v1/list',
    params: {...query.paging, ...query.period, ...query.search, ...query},
  })

export interface PaymentCompletedRequest {
  impUid: string
  uid: string
  merchantUid: string
  customerUid: string
}

export const paymentCompletedApi = (data: PaymentCompletedRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/payment/v1/result',
    data,
  })

export const fetchProductListApi = () =>
  request<ModelsResponse<ProductList>>({
    method: 'get',
    path: '/api/payment/v1/product/list',
  })

export const fetchPaymentStatusApi = (query: PaymentStatusRequest) =>
  request<ModelResponse<string>>({
    method: 'post',
    path: '/api/payment/v1/payment-status',
    data: query,
  })

export const fetchPaymentIsCanceled = (query: PaymentStatusRequest) =>
  request<ModelResponse<string>>({
    method: 'post',
    path: '/api/payment/v1/payment-status',
    data: {...query, status: 'cancelled'},
  })

export const fetchPaymentFailedReasonApi = (query: PaymentCancelRequest) =>
  request<ModelResponse<string>>({
    method: 'post',
    path: '/api/payment/v1/cancel/failed-reason',
    data: query,
  })

export interface ReceiptRequest {
  paymentId: number
  uid: string
}

export const fetchReceiptApi = (query: ReceiptRequest) =>
  request<ModelResponse<Receipt>>({
    method: 'get',
    path: '/api/payment/v1/receipt',
    params: query,
  })

export interface SubscribePlanRequest {
  uid: string
}

export const fetchSubscribeCardInfoApi = (query: SubscribePlanRequest) =>
  request<ModelsResponse<CardInfo>>({
    method: 'get',
    path: '/api/payment/v1/card-info',
    params: query,
  })

export const deleteSubscribeCardInfoApi = (data: SubscribePlanRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/payment/v1/card-info',
    data,
  })

export interface RepaymentRequest {
  uid: string
  amount: number
  productName: string
  customerUid?: string
}

export const paymentSubscribeApi = (data: RepaymentRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/payment/v1/subscribe',
    data,
  })

export const fetchDomesticListApi = () =>
  newNormalRequest<any>({
    method: 'get',
    path: '/payments/v1.0/products',
    // data,
  })

export interface Price {
  name: string
  defaultPrice: string
  undefinedPrice: string
}

export interface SingleProduct {
  id: number
  code: string
  price: Price
}

export interface PackageProduct {
  code: string
  id: number
  price: Price
}

export interface AllPackages {
  id: number
  availableNumberOfTimes: number
  appearanceName: string
  isDefault: boolean
  isJoined: boolean
  expiresOn: number
  price: Price
  packageType?: string
  products?: PackageProduct[]
}

export const fetchSingleProductsApi = () =>
  newNormalRequest<SingleProduct[]>({
    method: 'get',
    path: '/payments/v1.0/products',
    // data,
  })

export const fetchPackageListApi = () =>
  newNormalRequest<any>({
    method: 'get',
    path: '/payments/v1.0/products',
    // data,
  })

export interface CreditCardInfo {
  encCreditNumber: string
  creditNumber: string
  expirationPeriod: string
  cvc: string
  isPrimary: string
  registerName: string
  createdAt: string
}

export interface FetchCreditCardsResponse {
  items: CreditCardInfo[]
  total: number
  page: number
  size: number
}

// 신용카드 정보를 요청하기 전에 암호화키를 요청해야 값이 내려옴
export const fetchCreditCardsApi = () =>
  newNormalRequest<FetchCreditCardsResponse>({
    method: 'get',
    path: '/payments/v1.0/credit/',
    // data,
  })

export interface RegisterCreditCardRequest {
  creditNumber: string
  expirationPeriod: string
  cvc: string
  isPrimary: boolean
}

export const createCreditCardsApi = (data: RegisterCreditCardRequest) =>
  newNormalRequest<FetchCreditCardsResponse>({
    method: 'post',
    path: '/payments/v1.0/credit/',
    data,
  })
export interface FetchCreditCardV1Response {
  company: string
  number: string
  cardType: string
  ownerType: string
  authenticatedAt: string
}

export const fetchCreditCardsV1Api = () =>
  newNormalRequest<FetchCreditCardV1Response[]>({
    method: 'get',
    path: '/payments/v1.1/credit/',
  })

export const fetchIsExistsCardsV1Api = () =>
  newNormalRequest<FetchCreditCardV1Response[]>({
    method: 'get',
    path: '/payments/v1.1/credit/is_exists',
  })

export interface FetchPaymentDateResponse {
  day: string
}

export const fetchPaymentDateApi = (oid: string) =>
  newNormalRequest<FetchPaymentDateResponse>({
    method: 'get',
    path: `/payments/v1.0/credit/payment-date/${oid}/`,
  })

export interface FetchMonthPaymentListRequest {
  oid: string
  ym: string
}

export const fetchMonthPaymentListApi = (data: FetchMonthPaymentListRequest) =>
  newNormalRequest<any>({
    method: 'get',
    path: `/payments/v1.0/use/payment-list/${data.oid}/${data.ym}/`,
  })

export const fetchEnabledPackageApi = () =>
  newNormalRequest<any>({
    method: 'get',
    // path: `/payments/v1.0/enabled_packages`,
    path: `/packages/v2.0/organizations/can-be-purchase/`,
  })
export const fetchSalesEnabledPackageApi = (oid: string) =>
  newNormalRequest<any>({
    method: 'get',
    path: `/payments/v1.0/enabled_packages`,
    params: {orgOid: oid},
  })

export interface Histories {
  organizationOid: string
  packageId: string
  price: string
  expiresOn: string
  memo: string
}

export interface PackageHistoryListResponse {
  items: Histories[]
  total: number
  page: number
  size: number
}

export const fetchPackageHistoryListApi = () =>
  newNormalRequest<PackageHistoryListResponse>({
    method: 'get',
    path: '/payments/v1.0/customize_price_package/history',
  })

export interface FetchPackagePrice {
  packageId: number
  orgUid: string
  page?: number
  size?: number
}

export const fetchPackagePriceListApi = (data: FetchPackagePrice) =>
  newNormalRequest<PackageHistoryListResponse>({
    method: 'get',
    path: '/payments/v1.0/customize_price_package',
    params: {...data},
  })

export interface DeleteAllPackagePriceRequest {
  packageIds: number[] | null
}

export const deleteAllPackagePriceApi = (data: DeleteAllPackagePriceRequest) =>
  newNormalRequest<any>({
    method: 'delete',
    path: '/payments/v1.0/customize_price_package',
    data,
  })

export interface UpdatePackagePriceRequest {
  organizationOid: string
  packageId: number
  price: number
  expiresOn?: string
  amount?: number
  memo: string
}

export const updateChangePackagePriceApi = (data: UpdatePackagePriceRequest) =>
  newNormalRequest<UpdatePackagePriceRequest>({
    method: 'post',
    path: 'payments/v1.0/customize_price_package',
    data,
  })

export interface DiscountAllPackagesRequest {
  orgOid: string
  rate: number
}

export const discountAllPackageApi = (data: DiscountAllPackagesRequest) =>
  newNormalRequest<any>({
    method: 'post',
    path: '/payments/v1.0/bulk-discount',
    data,
  })

export const paidMonthListApi = (oid: string) =>
  newNormalRequest<string[]>({
    method: 'get',
    path: `/payments/v1.0/credit/paid-months/${oid}/`,
  })

export const organizationRegisterPackageListApi = () =>
  newNormalRequest<any>({
    method: 'get',
    path: `/payments/v1.0/org-packages/`,
  })

export interface OrganizationRegisterPackagesRequest {
  orgOid?: string
  packageIds: number[]
}
// 아이메디신 관리자
export const updateRegisterPackageListApi = (
  data: OrganizationRegisterPackagesRequest,
) =>
  newNormalRequest<any>({
    method: 'post',
    path: `/payments/v1.0/org-packages/?orgOid=${data.orgOid}`,
    data: {packageIds: data.packageIds},
  })
// 기관용
export const updatePersonalRegisterPackageListApi = (
  data: OrganizationRegisterPackagesRequest,
) =>
  newNormalRequest<any>({
    method: 'post',
    path: `/payments/v1.0/org-packages/`,
    data: {packageIds: data.packageIds},
  })

interface TossVerification {
  paymentKey: string
  orderId: string
  amount: string
}

type EximVerification = {
  refId: string
}

export const paymentRequestApi = (data: TossVerification | EximVerification) =>
  newNormalRequest<any>({
    method: 'post',
    path: '/payments/v2.0/verification/',
    data,
  })

export interface UpdateProductPriceRequest {
  organizationOid: string
  packageId: number
  price: number
  expiresOn?: string
  amount?: number
  memo: string
}

export type H = {
  foo: string
  bar: string
}

export interface UsedPaymentPackageResponse {
  packageId: number
}

export const fetchUsedPaymentPackageApi = () =>
  newNormalRequest<UsedPaymentPackageResponse[]>({
    method: 'get',
    path: '/payments/v1.1/credit/prepayment/can-be-paid/',
  })

export interface BillingRequest {
  customerKey: string
  authKey: string
}

export const billingRequestApi = (data: BillingRequest) =>
  newNormalRequest<any>({
    method: 'post',
    path: '/payments/v1.0/credit/verification/billing-key/',
    data,
  })

// 신규 api 작업
export enum CurrencyCode {
  USD = '$',
  KRW = '₩',
}
export interface AnalysisProduct {
  code: string
}

export interface PackageUnit {
  id: string
  amount: number
  price: number
}
export interface PackageSubscription {
  period: number
  units: [PackageUnit]
}

export interface PackageBillingCycle {
  billingCycle: number
  subscriptions: [PackageSubscription]
}

export interface CanBePurchasePackage {
  description: string
  analyses: [AnalysisProduct]
  analysisGroup: [string]
  name: string
  billingType: string
  currencyCode: CurrencyCode
  billingCycles: [PackageBillingCycle]
}

export interface PriceSet {
  price: number
  currency: CurrencyCode
  unitPurchase: number
}

export interface CanBePurchaseBundle {
  description: string
  descLink: string | null
  name: string
  id: string
  priceSet: PriceSet
  analyses: [AnalysisProduct]
  analysisGroup: [string]
}
export interface AvailableProducts {
  packages: [CanBePurchasePackage]
  bundles: [CanBePurchaseBundle]
}

export interface PurchasePackage extends PackageUnit {
  name: string
  period: number
  billingCycle: number
  analyses: [AnalysisProduct]
  analysisGroup: [string]
  billingType: string
  currencyCode: CurrencyCode
}

export interface PurchaseBundle extends PriceSet {
  name: string
  id: string
  analyses: [AnalysisProduct]
  analysisGroup: [string]
}

export interface PurchasedPackage {
  analyses: [AnalysisProduct]
  analysisGroup: [string]
  name: string
  packageId: string
  bundleId: string
  givenAmount: number | null
  remainAmount: number | null
  billingCycle: number
  subscriptionPeriod: number
  expiresAt: Date

  getGivenAmount: () => number | string
  getRemainAmount: () => number | string

  getGivenDate: () => string
  getRemainDate: () => string
}

export interface PurchasedBundle {
  analyses: [AnalysisProduct]
  analysisGroup: [string]
  name: string
  bundleId: string
  givenAmount: number
  remainAmount: number
  priceSet: PriceSet

  getGivenAmount: () => number
  getRemainAmount: () => number
}

export interface PurchasedProducts {
  packages: [PurchasedPackage]
  bundles: [PurchasedBundle]
}

export interface UnPaidVoucher {
  id: number
  packageName: string
  countOfPeriod: number
  totalPeriod: number
  price: number
  dueDate: Date
}

export interface UnPaidVoucherContainer {
  vouchers: UnPaidVoucher[]
}

export interface CanBePurchaseProducts {
  available: AvailableProducts
  purchased: PurchasedProducts
  overdue: UnPaidVoucherContainer
  description: string
  descLink: string | null
}

export interface TossInterlocking {
  clientKey: string
}

export interface EximInterlocking {
  mid: string
  secretKey: string
}

export interface OrderResponse {
  amount: number
  currencyCode: CurrencyCode
  expiresAt: Date
  id: string
  interlockingCodes: EximInterlocking | TossInterlocking
  orderNo: string
  orderName: string
  overduePrice: number
  pgType: string // "EB"
  price: number
  refundPrice: number
}

export interface UnpaidOrderResponse extends OrderResponse {
  bundles: OrganizationBundle[]
  packages: OrganizationPackage[]
}

export const canBePurchase = async () => {
  // TODO: Edge에서는 언어코드만 내려줌
  let acceptLanguage = await navigator.language
  if (acceptLanguage === 'ko') acceptLanguage = 'ko_KR'
  else if (acceptLanguage === 'en') acceptLanguage = 'en_US'

  const request = newNormalRequest<any>({
    method: 'get',
    path: '/packages/v2.0/organizations/can-be-purchase/',
    header: {'Accept-Language': acceptLanguage},
  })
  return request
}

export const calRefundPackagePrice = async (packageIds: string[]) =>
  newNormalRequest<any>({
    method: 'get',
    path: `/payments/v2.0/cal-refund/?${packageIds
      .map((id) => {
        return `packages=${id}`
      })
      .join('&')}`,
  })

export const getProductsMyOwn = async () => {
  // TODO: Edge에서는 언어코드만 내려줌
  let acceptLanguage = await navigator.language
  if (acceptLanguage === 'ko') acceptLanguage = 'ko_KR'
  else if (acceptLanguage === 'en') acceptLanguage = 'en_US'

  return newNormalRequest<any>({
    method: 'get',
    path: '/packages/v2.0/organizations/has-products/',
    header: {'Accept-Language': acceptLanguage},
  })
}

export const patchAutoBillingStatus = (packageId: string, pause: boolean) =>
  newNormalRequest<any>({
    method: 'patch',
    path: `packages/v2.0/organizations/bundle/${packageId}/auto-payment/`,
    data: {isPause: pause},
  })

export const createOrder = (
  bundles: string[],
  packages: string[],
  refundPackages: string[],
  unpaidVoucherIds: number[],
  encKey: string,
) => {
  console.log('1 bundles', bundles)
  console.log('2 packages', packages)
  console.log('3 refundPackages', refundPackages)
  console.log('4 unpaidVoucherIds', unpaidVoucherIds)
  console.log('5 encKey', encKey)

  return newNormalRequest<any>({
    method: 'post',
    path: '/payments/v2.0/order/',
    data: {
      bundles,
      packages,
      refundPackages,
      unpaidVoucherIds,
      encKey,
    },
    header: {'Accept-Language': 'en_US'},
  })
}

export const unPaidOrder = (encKey: string) =>
  newNormalRequest<any>({
    method: 'get',
    path: `/payments/v2.0/order/?encKey=${encKey}`,
  })

export const deletePayment = (orderId: string) =>
  newNormalRequest<any>({
    method: 'delete',
    path: `/payments/v2.0/order/${orderId}/`,
    data: {
      reason: '-',
    },
  })
// CYM
export const fetchInvoicedYearMonthApi = () =>
  newNormalRequest<InvoicedYearMonth>({
    method: 'get',
    path: '/payments/v2.0/invoiced/date/',
  })

export const fetchUsageHistoryAndUnpaidVouchersApi = (
  targetYearMonth: string,
) => {
  return newNormalRequest<UsageHistoryAndUnpaidVouchersModel>({
    method: 'get',
    path: `/payments/v2.0/${targetYearMonth}/invoice/`,
  })
}

export interface PaymentHistoryRequest {
  beginDate: string
  endDate: string
}
export interface PaymentHistoryResponse {
  items: PaymentHistoryTableItemsModel[]
  total: number
  page: number
  size: number
}
export const fetchPaymentHistoryApi = (params: PaymentHistoryRequest) => {
  return newNormalRequest<PaymentHistoryResponse>({
    method: 'get',
    path: `/payments/v2.0/billing/`,
    params,
  })
}

export interface UsageHistoryBeginEndDate {
  begin?: string
  end?: string
}
export interface UsageHistoryRequest extends UsageHistoryBeginEndDate, Paging {}
export interface UsageHistoryResponse {
  createdAt: string
  orderNo: string
  package: {
    name: string
    analyses: string[]
    analysisGroup: string[]
  }[]
  bundle: {
    name: string
    analyses: string[]
    analysisGroup: string[]
  }[]
  failures: string[]
  remainAmount: number | null
  chargeAmount: number | null
  isRefund: boolean
}

/**
 * @Description Fast Api: Detail analyses list of Organization
 * @param params
 * @returns https://dev.isyncbrain.com/packages/v2.0/analysis/{orgOid}/use-history/
 */
export const fetchUsageHistoryApi = (
  orgOid: string,
  params: UsageHistoryRequest,
) => {
  return newNormalRequest<UsageHistoryTableModel>({
    method: 'get',
    path: `/packages/v2.0/analysis/${orgOid}/use-history/`,
    params,
  })
}
