import {
  CurrencyCode,
  EximInterlocking,
  OrderResponse,
  TossInterlocking,
} from 'api/paymentApi'
import {rsaDecryption} from 'api/securityApi'

export default class OrderResponseModel implements OrderResponse {
  amount: number

  currencyCode: CurrencyCode

  expiresAt: Date

  id: string

  interlockingCodes: EximInterlocking | TossInterlocking

  orderNo: string

  orderName: string

  overduePrice: number

  pgType: string // EB, TP

  price: number

  refundPrice: number

  constructor(raw: OrderResponse, privateKey: string) {
    this.amount = raw.amount
    this.currencyCode =
      CurrencyCode[raw.currencyCode as string as keyof typeof CurrencyCode]
    this.expiresAt = raw.expiresAt
    this.id = raw.id
    const {interlockingCodes} = raw
    // payment gateway system type check
    if ('clientKey' in interlockingCodes) {
      const {clientKey} = interlockingCodes as TossInterlocking
      this.interlockingCodes = {clientKey: rsaDecryption(privateKey, clientKey)}
    } else {
      const {mid, secretKey} = interlockingCodes as EximInterlocking
      this.interlockingCodes = {
        mid: rsaDecryption(privateKey, mid),
        secretKey: rsaDecryption(privateKey, secretKey),
      }
    }

    this.orderNo = raw.orderNo
    this.overduePrice = raw.overduePrice
    this.pgType = raw.pgType
    this.price = raw.price
    this.refundPrice = raw.refundPrice
    this.orderName = raw.orderName
  }

  getTossInfo = () => {
    return this.interlockingCodes as TossInterlocking
  }

  getEximBayInfo = () => {
    return this.interlockingCodes as EximInterlocking
  }
}
