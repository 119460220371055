import CardWithTitle from 'components/atoms/CardWithTitle'
import PageTable from 'pages/Analysis/HRVNorm/PageTable'
import React from 'react'

function HRVNorm() {
  return (
    <CardWithTitle>
      <PageTable />
    </CardWithTitle>
  )
}

export default HRVNorm
