import {ThreeDTheme} from 'pages/Popup/ThreeDReport/Report'
import styled from 'styled-components'
import React from 'react'

interface StyledConnectivityFooterProps {
  theme: ThreeDTheme
}

const StyledConnectivityFooter = styled.div<StyledConnectivityFooterProps>`
  width: 60%;
  margin: 0 auto;

  & .title {
    color: ${(props) => (props.theme === 'Dark' ? '#fff' : '#000')};
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
    border-bottom: 1px solid #e7eaec;
    font-weight: bold;
  }

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      color: ${(props) => (props.theme === 'Dark' ? '#fff' : '#000')};
      position: relative;
      font-size: 18px;
      font-weight: 400;
      padding: 0 30px 0 0;
      list-style: none;
      line-height: 20px;
      display: inline-block;
      width: 50%;
      text-align: right;

      &::before {
        display: block;
        content: '';
        width: 120px;
        height: 5px;
        position: absolute;
        left: 20px;
        top: 10px;
      }

      &.pink::before {
        background: #eba6a5;
      }

      &.blue::before {
        background: #94bcdb;
      }
    }
  }
`

interface ConnectivityFooterProps {
  theme: ThreeDTheme
}

export default function ConnectivityFooter({theme}: ConnectivityFooterProps) {
  return (
    <StyledConnectivityFooter theme={theme}>
      <h3 className='title'>Connection</h3>
      <ul>
        <li className='pink'>
          <span>Hyper</span>
        </li>
        <li className='blue'>
          <span>Hypo</span>
        </li>
      </ul>
    </StyledConnectivityFooter>
  )
}
