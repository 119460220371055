import React from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import styled from 'styled-components'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import useHealthCenterBrainFunctionMappingStyles from 'pages/HealthCenter/Report/BrainFunctionMapping/Style'
import TableFiguresByPart from 'pages/HealthCenter/Report/BrainFunctionMapping/Elements/tableFiguresByPart'

export const PageWrap = styled.div`
  background: White;
`

export const PageContent = styled.div`
  width: 1160px;
`

export const CardContent = styled.div`
  font-size: 14px;
`

export const FlexBox = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
`
const getHealthReportReportImage = getPublicFile('images/health_center_report')

interface BrainFunctionMappingProps {
  Frontal_LH: number
  Temporal_LH: number
  agingRoiScore: Record<string, any>
}

const getNumber = (value: number | string) => {
  if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
  return 0
}

const scoreToString = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'IScoreBrainMapping_Lowering'
  }
  if (Math.floor(score) > 70) {
    return 'IScoreBrainMapping_Exaltation'
  }

  return 'IScoreBrainMapping_Balance'
}

const scoreToClass = (score: number) => {
  if (Math.floor(score) < 30) {
    return 'LoweringActive'
  }
  if (Math.floor(score) > 70) {
    return 'ExaltationActive'
  }

  return 'Balanced'
}

const scoreSign = (score: number | string) => {
  const parsedScore =
    typeof score === 'string' ? Number.parseFloat(score) : score

  if (parsedScore < 30) {
    return getHealthReportReportImage('down.svg')
  }
  if (parsedScore > 70) {
    return getHealthReportReportImage('up.svg')
  }

  return getHealthReportReportImage('arrow.svg')
}

const Label = ({score}: {score: number | string}) => {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const string = scoreToString(getNumber(score))
  const className = scoreToClass(getNumber(score))
  return (
    <div className={`${classes.labelScore} ${className}`}>
      {t(string)}
      <img src={scoreSign(score)} alt='icon' />
    </div>
  )
}

const Score = ({score}: {score: number}) => {
  const classes = useHealthCenterBrainFunctionMappingStyles()
  return (
    <div className={classes.bottom16}>
      <div className={classes.scoreNumber}>{score}점</div>
      <Label score={score} />
    </div>
  )
}

function TablePrintT1({
  Frontal_LH,
  Temporal_LH,
  agingRoiScore,
}: BrainFunctionMappingProps) {
  const {t} = useTranslation()
  const classes = useHealthCenterBrainFunctionMappingStyles()
  const headTable = [
    t('ITemporalLobe'),
    t('IMainFunction'),
    t('IFunctionalDecline'),
    t('ILeftHemisphere'),
    t('IRightHemisphere'),
  ]

  const bodyTable3 = [
    {
      data: [
        {
          header: [
            {
              key: 'T1.',
              content: 'Temporal Pole',
              note: t('ITemporalPoleT1'),
            },
          ],
          image: getHealthReportReportImage('brainT1.png'),
        },
        [
          t('IRegionActivatedT1'),
          t('ILeftHemisphereT1'),
          t('IRightHemisphereT1'),
        ],
        [
          t('IDifficultiesT1'),
          t('IDifficultProcessT1'),
          t('IDifficultRespondT1'),
        ],
        [getNumber(agingRoiScore?.Temporal_Pole_LH)],
        [getNumber(agingRoiScore?.Temporal_Pole_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T2.',
              content: 'Superior Temporal',
              note: t('ISuperiorT2'),
            },
          ],
          image: getHealthReportReportImage('brainT2.png'),
        },
        [t('IResponsibleT2')],
        [t('IPossibilityT2'), t('IImpossibleT2'), t('IDifficultT2')],
        [getNumber(agingRoiScore?.Superior_Temporal_LH)],
        [getNumber(agingRoiScore?.Superior_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T3.',
              content: 'Middle Temporal',
              note: t('IMiddleT3'),
            },
          ],
          image: getHealthReportReportImage('brainT3.png'),
        },
        [t('IAreaT3'), t('ILeftHemisphereT3'), t('IRightHemisphereT3')],
        [t('IVisualT3'), t('IDifficultT3'), t('IRightSidedT3')],
        [getNumber(agingRoiScore?.Middle_Temporal_LH)],
        [getNumber(agingRoiScore?.Middle_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T4.',
              content: 'Inferior Temporal',
              note: t('IInferiorT4'),
            },
          ],
          image: getHealthReportReportImage('brainT4.png'),
        },
        [t('IInvolvedT4'), t('ILeftHemisphereT4'), t('IRightHemisphereT4')],
        [t('ILeftSidedT4'), t('IRightSidedT4')],
        [getNumber(agingRoiScore?.Inferior_Temporal_LH)],
        [getNumber(agingRoiScore?.Inferior_Temporal_RH)],
      ],
      background: 'White',
    },
    {
      data: [
        {
          header: [
            {
              key: 'T5.',
              content: 'Transverse Temporal',
              note: t('ILateralT5'),
            },
          ],
          image: getHealthReportReportImage('brainT5.png'),
        },
        [t('IAuditoryT5')],
        [t('IDifficultyT5'), t('IPossibleT5')],
        [getNumber(agingRoiScore?.Transverse_Temporal_LH)],
        [getNumber(agingRoiScore?.Transverse_Temporal_RH)],
      ],
      background: 'White',
    },
  ]

  return (
    <div className={classes.brainFunctionMapping}>
      <PageWrap>
        <PageContent>
          <WrapElement number={2} text={t('ITemporalLobeEEG')}>
            <CardContent>
              <FlexBox>
                <div className={classes.contentDetailedAnalysis}>
                  <div className={classes.textHeader}>
                    <p>{t('IHeaderTemporalLobe1')}</p>&nbsp;
                    <p className={classes.headerColorGreen}>
                      {t('IHeaderTemporalLobe2')}
                    </p>
                    &nbsp;
                    <p>{t('IHeaderTemporalLobe3')}</p>
                  </div>
                  <div className={classes.textContent}>
                    <p>{t('IGovernsHigherMental')}</p>
                    <p>{t('ITemporalLobeDisorders')}</p>
                    <p>{t('IDetailedAnalysis2')}</p>
                  </div>
                </div>
                <div className={classes.imgDetailedAnalysis}>
                  <div className={classes.scoreSpace}>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>좌</div>
                        <Score score={getNumber(Frontal_LH)} />
                      </div>
                    </div>
                    <div className={classes.scoreContainer}>
                      <div>
                        <div className={classes.headerScore}>우</div>
                        <Score score={getNumber(Temporal_LH)} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageOverviewBrain}>
                    <img
                      src={getHealthReportReportImage('brainPage2.png')}
                      alt=''
                    />
                  </div>
                </div>
              </FlexBox>
            </CardContent>
          </WrapElement>

          <div className={classes.marginBottom60}>
            <TableFiguresByPart head={headTable} body={bodyTable3} />
            <div className={classes.textBottomTable}>
              {t('IBasedOnTheMedian')}
            </div>
          </div>
        </PageContent>
      </PageWrap>
    </div>
  )
}

export default TablePrintT1
