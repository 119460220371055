import React, {ReactNode} from 'react'
import styled, {css} from 'styled-components'
import searchIcon from 'assets/img/search.svg'

interface ProductsInfosProps {
  description: string
  hasPdf: boolean
  pdfUrl?: string
  children?: ReactNode
}

const StyledHoverMixin = css`
  &:hover {
    text-decoration: underline;
    cursor: url(${searchIcon}) 12 12, auto;
  }
`
const StyledProductsIntroWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 0;
`
const StyledProductsDescription = styled.p<{hasPdf: boolean}>`
  margin: 0;
  margin-bottom: 12px;
  color: #444b52;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;

  ${(props) => props.hasPdf && StyledHoverMixin}
`

function ProductsInfos({
  description,
  hasPdf,
  pdfUrl,
  children,
}: ProductsInfosProps) {
  const handleGuide = (pdfUrl: string) => {
    const newWindow = window.open(pdfUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <StyledProductsIntroWrap>
      <StyledProductsDescription
        hasPdf={hasPdf}
        onClick={pdfUrl ? () => handleGuide(pdfUrl) : undefined}
      >
        {description}
      </StyledProductsDescription>
      {children}
    </StyledProductsIntroWrap>
  )
}

export default ProductsInfos
