import React, {useEffect, useState} from 'react'
import {Controller, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Radio, RadioGroup} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import useStyles from 'components/Dialog/User/Style'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {HealthProductInfoListType} from 'api/invoiceApis'
import {HdAnalysisTypes} from 'api/healthCareApi'
import {PatientAddForm} from 'api/patientApi'

interface CheckUpItemsProps {
  control: any
  products: HdAnalysisTypes[]
  errors: FieldErrors<PatientAddForm>
}

export default function CheckUpItems({
  control,
  products,
  errors,
}: CheckUpItemsProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const [listHealth, setListHealth] = useState<HealthProductInfoListType[]>([])

  const initDataHealth = async () => {
    const list: HealthProductInfoListType[] = JSON.parse(
      JSON.stringify(products),
    )
    if (list.length) {
      setListHealth(list.sort((a, b) => a.productId - b.productId))
    }
  }

  useEffect(() => {
    initDataHealth()
  }, [products])
  return (
    <div className={classes.infoContainer}>
      <Typography className={inputClasses.inputLabel}>
        {t('ICheckupItems')} {t('ICheckupWarn')}
      </Typography>
      <Typography className={classes.smallPadding} />

      <div className={classes.displayController}>
        <Controller
          name='healthProductId'
          control={control}
          defaultValue={null}
          render={({field}) => (
            <RadioGroup
              row
              className={classes.displayRadioGroup}
              value={field.value !== null ? field.value.toString() : ''}
              onChange={(event) => field.onChange(Number(event.target.value))}
            >
              {listHealth.map((item) => (
                <FormControlLabel
                  control={<Radio color='primary' />}
                  label={
                    <p style={{marginRight: '30px'}}>{item.displayName}</p>
                  }
                  value={item.productId.toString()}
                />
              ))}
            </RadioGroup>
          )}
        />
      </div>

      <p className={classes.contentCheckupNote}>“{t('ICheckupNote')}”</p>
      {errors.healthProductId && (
        <p className={classes.errorText}>{errors.healthProductId.message}</p>
      )}
    </div>
  )
}
