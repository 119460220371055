import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  HRVNormSearchRequest,
  SearchedHRVNorm,
  searchHrvNormApi,
} from 'api/analysisApi'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchHrvNorm = createAsyncThunk(
  'api/hrv/norm/search',
  async (payload: HRVNormSearchRequest, {rejectWithValue}) => {
    try {
      const response = await searchHrvNormApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export interface HRVNormState {
  loading: boolean
  error: any
  items: SearchedHRVNorm[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: HRVNormState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(), // TODO: 나래와 Redux date 협의 필요
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'hrvNormSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchHrvNorm.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search} = action.meta.arg
      state.search = search
      state.period = period
    })
    builder.addCase(searchHrvNorm.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalElements = totalElements
      state.pagingInfo.totalPages = totalPages
    })
    builder.addCase(searchHrvNorm.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) => state.hrvNorm.loading
export const selectError = (state: RootState) => state.hrvNorm.error
export const selectItems = (state: RootState) => state.hrvNorm.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.hrvNorm.period)
export const selectSearch = (state: RootState) => state.hrvNorm.search
export const selectPaging = (state: RootState) => state.hrvNorm.paging
export const selectPagingInfo = (state: RootState) => state.hrvNorm.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.hrvNorm.period,
  search: state.hrvNorm.search,
  paging: state.hrvNorm.paging,
})
