import React from 'react'
import styled from 'styled-components'
import Colors from 'theme/Colors'

export interface NormalStyleButtonProps {
  buttonWidth: number
  buttonHeight: number
  buttonBackgroundColor: string
  buttonBackgroundHoverColor: string
  buttonFontSize: number
  buttonFontWeight: number
  buttonColor: string
  hoverEvent: boolean
  buttonTextColor?: string
  borderColor?: string
}

export interface NormalButtonProps {
  title?: string
  theme: NormalStyleButtonProps
  disabled: boolean
  event?: () => void
  type?: 'button' | 'submit' | 'reset'
  submitForm?: string
}

const StyledOutlineButton = styled.button<NormalStyleButtonProps>`
  width: ${(props) => props.buttonWidth}px;
  height: ${(props) => props.buttonHeight}px;
  background: ${(props) => props.buttonBackgroundColor};
  color: ${(props) =>
    props.buttonTextColor ? props.buttonTextColor : props.buttonColor};
  border-radius: 3px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : props.buttonColor)};
  outline: 0;
  font-size: ${(props) => props.buttonFontSize}px;
  font-weight: ${(props) => props.buttonFontWeight};
  transition: background-color 0.1s;
  &:hover {
    background: ${(props) =>
      props.hoverEvent ? props.buttonBackgroundHoverColor : null};
    color: ${(props) => (props.hoverEvent ? Colors.common.white : null)};
  }
`
export default function OutlineButton({
  title,
  theme,
  disabled,
  event,
  type,
  submitForm,
}: NormalButtonProps) {
  return (
    <StyledOutlineButton
      buttonWidth={theme.buttonWidth}
      buttonHeight={theme.buttonHeight}
      borderColor={theme.borderColor}
      buttonColor={theme.buttonColor}
      buttonBackgroundColor={theme.buttonBackgroundColor}
      buttonBackgroundHoverColor={theme.buttonBackgroundHoverColor}
      buttonFontSize={theme.buttonFontSize}
      buttonFontWeight={theme.buttonFontWeight}
      onClick={event}
      disabled={disabled}
      type={type ?? 'button'}
      form={submitForm}
      hoverEvent={theme.hoverEvent}
      buttonTextColor={theme.buttonTextColor}
    >
      {title}
    </StyledOutlineButton>
  )
}
