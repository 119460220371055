import {Button, Typography} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import {addDays} from 'date-fns'
import {enUS, ko} from 'date-fns/locale'
import {formatDate, isEqualsDate} from 'helpers/dateHelper'
import React, {useEffect} from 'react'
import {DateRange} from 'react-date-range'
import {useTranslation} from 'react-i18next'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import useTableToolbarStyles from 'components/Table/useTableToolbarStyles'
import {useLocation} from 'react-router-dom'

interface ParentProps {
  onChangeValue: (range: PeriodDate) => void
  dateValue: PeriodDate
  hasPlaceholder?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  parentDate: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    border: '1px solid #e0e0e0',
    backgroundColor: 'white',
    borderRadius: 4,

    '& h6': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 10,
  },
  divider: {
    margin: 'auto',
    marginRight: 10,
    marginLeft: 10,
    height: 19,
  },
  searchButton: {
    background: 'white',
    width: 60,
    color: '#666a6c',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
  },
  dateSearchButton: {
    border: '1px solid #0cc7c7',
    borderRadius: 4,
    padding: '0 15px',
    background: 'white',
    color: '#0cc7c7',
  },
  dropdown: {
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 500,
    width: 664,
    background: 'white',
    boxShadow: '15px 10px 30px rgba(0, 0, 0, 0.1)',
    '& .submitButton': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10,
      padding: 10,
      background: '#EFF2F7',
      '& .cancelButton': {
        background: 'white',
      },
    },
  },
  dateTextWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginRight: 10,
  },
  padding: {
    padding: theme.spacing(0.5),
  },
}))

/**
 * 날짜범위선택이 가능한 캘린더
 *
 * @param onChangeValue setValue 함수
 * @param dateValue date value 값
 */

export default function RangeDatePicker({
  onChangeValue,
  dateValue,
  hasPlaceholder,
}: ParentProps) {
  const classes = useStyles()
  const buttonClasses = useTableToolbarStyles()
  const {t, i18n} = useTranslation()
  const location = useLocation()
  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate: dateValue.startDate,
    endDate: dateValue.endDate,
  })
  const [open, setOpen] = React.useState(false)
  const [selectRanges, setSelectRanges] = React.useState<PeriodDate>({
    startDate: dateValue.startDate,
    endDate: dateValue.endDate,
  })
  const [placeholder, setPlaceholder] = React.useState(hasPlaceholder ?? false)

  const onRangeChange = (ranges: any) => {
    if (ranges != null) {
      setSelectRanges(ranges.range1)
    }
  }
  const handleClick = () => setOpen((prev) => !prev)
  const handleClose = () => {
    setOpen(false)
    setSelectRanges(selection)
  }
  const handleDataChange = () => {
    setOpen(false)
    setPlaceholder(false)
    setSelection(selectRanges)
  }
  const handleDataSubmit = () => {
    onChangeValue(selectRanges)
  }

  useEffect(() => {
    setSelection({
      startDate: dateValue.startDate,
      endDate: dateValue.endDate,
    })
    setSelectRanges({
      startDate: dateValue.startDate,
      endDate: dateValue.endDate,
    })
  }, [dateValue])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.parentDate}>
        <Grid className={classes.dateWrap}>
          <Grid
            style={{display: 'flex', alignItems: 'center'}}
            onClick={handleClick}
          >
            <DateRangeIcon className={classes.icon} />
            <Divider
              orientation='vertical'
              flexItem
              className={classes.divider}
            />
            <div className={classes.dateTextWrap}>
              <Typography variant='h6'>
                {placeholder
                  ? `${t('INoticeDateRange')}`
                  : `${formatDate(selectRanges.startDate)} ~ 
                ${formatDate(selectRanges.endDate)}`}
              </Typography>
            </div>
            <span className={buttonClasses.searchDivider} />
          </Grid>
          <button
            type='button'
            className={buttonClasses.searchButton}
            onClick={() => handleDataSubmit()}
            style={{cursor: 'pointer'}}
          >
            {t('ISearch')}
          </button>
        </Grid>

        {open ? (
          <div className={classes.dropdown}>
            <DateRange
              onChange={onRangeChange}
              months={2}
              maxDate={addDays(new Date(), 0)}
              direction='horizontal'
              scroll={{enabled: false}}
              ranges={[
                {
                  startDate: selectRanges?.startDate,
                  endDate: selectRanges?.endDate,
                },
              ]}
              rangeColors={['#0cc7c7']}
              color='#0cc7c7'
              locale={i18n.language === 'ko' ? ko : enUS}
              editableDateInputs
            />
            <Divider />
            <div className='submitButton'>
              <Button
                variant='contained'
                color='default'
                size='small'
                disableElevation
                onClick={() => handleClose()}
                className='cancelButton'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                disableElevation
                size='small'
                color='primary'
                onClick={() => handleDataChange()}
              >
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}
