import React from 'react'
import useGaugeStyles from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/components/style'

interface Props {
  index: number
}
function ProgressBar({index}: Props) {
  const classes = useGaugeStyles()

  return (
    <>
      {index === 3 ? (
        <div className={classes.botBar}>
          <div>
            <div className={classes.marker3} style={{left: '30%'}}>
              <span />
            </div>
            <div className={classes.marker3} style={{left: '50%'}}>
              <div />
            </div>
            <div className={classes.marker3} style={{left: '70%'}}>
              <span />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.processBar}>
          <span className={classes.marker1} style={{top: '30%'}} />
          <span className={classes.marker1} style={{top: '70%'}} />
        </div>
      )}
    </>
  )
}
export default ProgressBar
