import EEGSummaryBrain3D from 'components/molcules/N1SummaryReport/EEG/EEGSummaryBrain3d'
import EEGSummaryOpinion from 'components/molcules/N1SummaryReport/EEG/EEGSummaryOpnion'
import useStyles from 'components/molcules/N1SummaryReport/useStyles'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

interface EEGSummaryProps {
  contourImage?: string
  threeDimensionImage: string
  total: string
  CustomerCard?: ReactNode
  frontalLobeDevelopmentColorbarImage?: string
}

// frontalLobeDevelopment: {
//   sensor_tbr_cz: number
//   normal_range_min: number
//   normal_range_max: number
// }
// frontalLobeDevelopmentColorbarImage: string
// frontalLobeDevelopmentGraph_image: string

export default function EEGSummary(props: EEGSummaryProps) {
  const {
    contourImage,
    threeDimensionImage,
    frontalLobeDevelopmentColorbarImage,
    total,
    CustomerCard,
  } = props
  const classes = useStyles()
  const {t} = useTranslation()

  const score = Number.parseInt(total, 10) ?? 0

  return (
    <div className={classes.root}>
      {CustomerCard && CustomerCard}
      <EEGSummaryBrain3D index={1} threeDimensionImage={threeDimensionImage} />
      <EEGSummaryOpinion index={2} score={score} contourImage={contourImage} />
      <div className='Warning'>{t('IEEGSummaryReportWarning')}</div>
    </div>
  )
}
