import React from 'react'
import styled from 'styled-components'

export interface ProductThumbnailStyleProps {
  badgeTextColor: string
  badgeBgColor: string
  thumbnailBgImage: string
}

const StyledProductTypeBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 13px;
  font-size: 12px;
  font-weight: 600px;
  line-height: 14px;
  border-radius: 3px;
  position: absolute;
  top: 12px;
  left: 12px;
`

const StyledProductThumbnail = styled.div<ProductThumbnailStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px auto;
  padding: 48px 20px 36px;
  width: 100%;
  max-width: 280px;
  height: auto;
  min-height: 180px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background: ${(props) =>
    `url(${props.thumbnailBgImage}) no-repeat center / cover`};
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  ${StyledProductTypeBadge} {
    color: ${(props) => props.badgeTextColor};
    background-color: ${(props) => props.badgeBgColor};
  }
`

interface ProductThumbnailProps {
  type: string
  productName: string
  theme: ProductThumbnailStyleProps
}

function ProductThumbnail(props: ProductThumbnailProps) {
  const {type, productName, theme} = props

  return (
    <StyledProductThumbnail
      thumbnailBgImage={theme.thumbnailBgImage}
      badgeTextColor={theme.badgeTextColor}
      badgeBgColor={theme.badgeBgColor}
    >
      <StyledProductTypeBadge>{type}</StyledProductTypeBadge>
      <span>{productName}</span>
    </StyledProductThumbnail>
  )
}

export default ProductThumbnail
