import {Theme} from '@material-ui/core'
import {
  blackColor,
  boxShadow,
  dangerColor,
  darkGreyColor,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  grayColor,
  hexToRgb,
  infoColor,
  primaryBoxShadow,
  primaryColor,
  roseColor,
  successColor,
  transition,
  warningColor,
  whiteColor,
} from 'assets/tss/material-dashboard-pro-react'
import {Colors} from 'components/common/useBaseStyle'

const sidebarStyle = (theme: Theme) => ({
  drawerPaper: {
    border: 'none',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top,',
    transitionDuration: '.1s,',
    transitionTimingFunction: 'linear, ',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      border: 'none',
      width: drawerWidth,
      height: '100%',
      overflow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      ...boxShadow,
      border: 'none',
      display: 'block',
      top: '0',
      right: '250',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflow: 'none',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(0, ${drawerWidth}px,0 )`,
      ...transition,
    },
  },
  blackBackground: {
    color: darkGreyColor,
    '&:after': {
      background: whiteColor,
      opacity: '.8',
    },
  },
  blueBackground: {
    color: whiteColor,
    '&:after': {
      background: infoColor[0],
      opacity: '.93',
    },
  },
  whiteBackground: {
    color: grayColor[2],
    '&:after': {
      background: whiteColor,
      opacity: '.93',
    },
  },
  whiteAfter: {
    '&:after': {
      backgroundColor: 'hsla(0,0%,71%,.3) !important',
    },
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`,
  },
  logo: {
    padding: '15px 0px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: '4',
    backgroundColor: Colors.primary,
    color: 'white',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left',
    textAlign: 'center',
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '22px',
    marginRight: '18px',
    marginTop: '7px',
    color: 'inherit',
  },
  logoNormal: {
    ...defaultFont,
    transition: 'all 300ms linear',
    display: 'flex',

    opacity: '1',
    transform: 'translate3d(0, 0, 0)',
    // textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '18px',
    whiteSpace: 'whiteSpace',
    fontWeight: '400',
    lineHeight: '30px',
    overflow: 'hidden',
    justifyContent: 'center',
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
    // marginLeft: 20,
  },
  logoNormalSidebarMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)',
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    // width: '35px',
    // verticalAlign: 'middle',
    border: '0',
    textAlign: 'center',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  list: {
    marginTop: '15px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  userInfoWrapper: {
    display: 'flex',
    justifyItems: 'center',
    // display: 'inline-block',
    // maxWidth: '100%',
    margin: 20,
  },
  userInfoContainer: {
    display: 'relative',
    marginTop: 30,
  },
  userInfoWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
  },
  userInfo: {
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  userInfoTextWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
  },
  userAccountChange: {
    display: 'flex',
    marginRight: 10,
    justifyItems: 'right',
    justifyContent: 'flex-end',
    position: 'absolute',
    left: '57%',
    top: 40,
  },
  accountChengIcon: {
    width: 30,
    height: 30,
    color: '#d0d0d0',
  },
  accountInformation: {
    color: '#a4a4a4',
    fontSize: 10,
    fontWeight: 500,
  },
  userItem: {
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  itemLink: {
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: 'all 300ms linear',
    margin: '5px 10px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: 5,
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
      marginLeft: 10,
    },
  },
  itemIcon: {
    color: Colors.primary,
    width: '20px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: '0.8',
  },
  itemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: 14,
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    padding: '0 16px !important',
    fontWeight: '400',
  },
  userItemText: {
    lineHeight: '22px',
  },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  collapseList: {
    marginTop: '0',
    '& $caret': {
      marginTop: '8px',
    },
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '5px 0 0 0',
    padding: '0',
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 10px',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: 7,
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  collapseItemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemText: {
    color: 'inherit',
    ...defaultFont,
    margin: 0,
    marginLeft: 38,
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'noWrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: 14,
    fontWeight: '400',
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '0px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transform: 'rotate(270deg)',
    color: grayColor[3],
  },
  userCaret: {
    marginTop: '10px',
    right: '10px',
  },
  caretActive: {
    transform: 'rotate(360deg)',
  },
  purple: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: Colors.primary,
    },
  },
  green: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        successColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(successColor[0])},.2)`,
    },
  },
  orange: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        warningColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(warningColor[0])},.2)`,
    },
  },
  red: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        dangerColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(dangerColor[0])},.2)`,
    },
  },
  white: {
    '&,&:hover,&:focus': {
      color: grayColor[2],
      backgroundColor: whiteColor,
      boxShadow: `0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.14), 0 7px 10px -5px rgba(${hexToRgb(grayColor[2])},.4)`,
    },
  },
  rose: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow: `0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.14), 0 7px 10px -5px rgba(${hexToRgb(roseColor[0])},.4)`,
    },
  },
  sidebarWrapper: {
    // position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '100%',
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  user: {
    margin: '5px auto 5px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      right: '15px',
      height: '1px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
  userOrganization: {
    color: darkGreyColor,
    fontSize: 16,
    fontWeight: '500',
  },
  userName: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: '400',
  },
  photo: {
    transition: 'all 300ms linear',
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    // float: 'center',
    zIndex: -1,
    marginRight: '11px',
    borderRadius: '50%',
    marginLeft: '38%',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyItems: 'center',
    textAlign: 'center',

    ...boxShadow,
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
    display: 'block',
    objectFit: 'cover',
    textAlign: 'center',
  },
  userCollapseButton: {
    margin: '0',
    padding: '6px 15px',
    '&:hover': {
      background: 'none',
    },
  },
  userCollapseLinks: {
    marginTop: '-4px',
  },
  subTitle: {
    fontSize: 11,
    margin: 3,
    marginLeft: 20,
    textAlign: 'left',
    color: grayColor[19],
  },
  topMainWrap: {
    backgroundColor: 'red',
    '& .MuiDrawer-paper': {
      overflowY: 'hidden',
    },
  },
})

export default sidebarStyle
