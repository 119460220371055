import {isProd} from 'helpers/envHelper'
import {FcmMessage} from 'helpers/firebaseHelper'

const element: HTMLElement | null = document.getElementById('root')

export type PushHandler = (e: CustomEvent<FcmMessage>) => void

export const addPushReceiveHandler = (handler: PushHandler) => {
  if (element) {
    element.addEventListener('PUSH', handler as EventListener)
  }
}

export const dispatchPushReceived = (detail: FcmMessage) => {
  if (element) {
    element.dispatchEvent(new CustomEvent('PUSH', {detail}))
  }
}

export const removePushReceiveHandler = (
  handler: (e: CustomEvent<FcmMessage>) => void,
) => {
  if (element) {
    element.removeEventListener('PUSH', handler as EventListener)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
!isProd() &&
  addPushReceiveHandler((event) => {
    console.log('---PUSH message received:')
    console.log(event.detail)
    console.log()
  })
