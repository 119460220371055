import {useState} from 'react'
import UnPaidOrderModel from '../../../pages/OrgPage/PaymentManagementPages/Models/UnPaidOrderModel'
import {deletePayment, paymentRequestApi} from '../../../api/paymentApi'
import {getPaymentsPath} from '../../../constants/RouteConstant'

export default function PaymentViewModel(order: UnPaidOrderModel) {
  function getVAT() {
    return order.amount - Math.round(order.amount / 1.1)
  }
  // eximbay/response < 이거 리스폰스 창

  const [finished, setFinished] = useState(false)

  async function doPayment() {
    const child = window.open(
      getPaymentsPath(),
      'payment',
      'width=700, height=800',
    ) as any

    child.window.getOrderInfo = () => {
      return order
    }
    // for eximbay callback
    // needs secret key in order info there's
    // callback page do not access first page's window function
    const w = window as any
    w.getOrderInfo = () => {
      return order
    }
    w.successCallback = async (p: any) => {
      if (order.pgType === 'EB') {
        const {isValidate, resCode, resMsg} = p
        if (isValidate) {
          // Integrity data
          if (resCode === '0000') {
            try {
              paymentRequestApi({
                refId: order.orderNo,
              }).then(() => {
                setFinished(true)
              })
            } catch (error) {
              // window.close()
            }
          } else {
            // eslint-disable-next-line
            alert(
              `isValidate: ${isValidate}, rescode : ${resCode}, resMsg : ${resMsg}`,
            )
          }
        } else {
          // Invalid data
        }
      } else {
        const {paymentKey, orderId, amount} = p
        try {
          paymentRequestApi({
            paymentKey,
            orderId,
            amount,
          }).then(() => {
            setFinished(true)
          })
        } catch (error) {
          // window.close()
        }
      }
    }
  }

  async function deletePaymentOrder() {
    order.bundles = []
    order.packages = []
  }

  async function cancelPayment() {
    //
    try {
      const res = await deletePayment(order.id)
      await deletePaymentOrder()

      if (!res) return true
    } catch (e) {
      console.error('error when cancel order : ', e)
    }
    return false
  }

  return {
    finished,
    getVAT,
    doPayment,
    cancelPayment,
    deletePaymentOrder,
  }
}
