import {makeStyles, Theme} from '@material-ui/core/styles'

const useDialogInvoiceViewStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    minWidth: 350,
  },
  title: {
    fontSize: 22,
    color: '#4b4b4b',
    fontWeight: 500,
  },
  cardWrap: {
    overflow: 'auto',
  },
  longTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0',
    justifyContent: 'space-between',
    color: 'primary',
    background: 'white',
    paddingBottom: 10,
  },
  button: {
    minWidth: 100,
    height: 50,
    marginLeft: 10,
    padding: '0px 70px',
    marginTop: 20,
  },
  scroll: {
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    paddingRight: 50,
    paddingLeft: 50,
  },
  buttonContainer: {
    display: 'flex',
    padding: 20,
    width: 520,
    justifyContent: 'center',
    '& button': {
      minWidth: 100,
      padding: '8px 0',
      flex: 1,
      margin: '0 10px',
      fontWeight: 600,
    },
    '& button:nth-child(1)': {
      marginLeft: 20,
    },
    '& button:nth-child(3)': {
      marginRight: 20,
    },
    '& .MuiButton-containedSecondary': {
      color: 'white',
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  smallPadding: {
    padding: theme.spacing(0.5),
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    marginLeft: 20,
    marginTop: 20,
    marginRight: 10,
  },
  buttonContainerClose: {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    '& button': {
      minWidth: 100,
      padding: '8px 0',
      flex: 1,
      margin: '0 10px',
      fontWeight: 600,
    },
    '& button:nth-child(1)': {
      marginLeft: 20,
    },
    '& button:nth-child(3)': {
      marginRight: 20,
    },
    '& .MuiButton-containedSecondary': {
      color: 'white',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0 40px 0',
    fontSize: 16,
  },
  popupBottom: {
    display: 'flex',
    justifyContent: 'end',
    backgroundColor: '#f9f9fb',
  },
}))

export default useDialogInvoiceViewStyles
