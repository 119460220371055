import {
  OrgDepartmentCreateRequest,
  OrgDepartmentUpdateRequest,
} from 'api/orgApi'
import {
  fetchDepartmentCreateAction,
  fetchDepartmentDeleteAction,
  fetchDepartmentListAction,
  fetchDepartmentUpdateAction,
  selectOrgDepartmentError,
  selectOrgDepartmentList,
  selectOrgDepartmentLoading,
} from 'features/org/orgDepartmentSlice'
import {useAppSelector} from 'hooks'
import {useAppDispatch} from 'store'

export default function useOrgDepartment() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectOrgDepartmentLoading)
  const departments = useAppSelector(selectOrgDepartmentList)
  const error = useAppSelector(selectOrgDepartmentError)

  const onFetch = () => dispatch(fetchDepartmentListAction())

  const onCreate = (payload: OrgDepartmentCreateRequest) =>
    dispatch(fetchDepartmentCreateAction(payload))

  const onUpdate = (payload: OrgDepartmentUpdateRequest) =>
    dispatch(fetchDepartmentUpdateAction(payload))

  const onRemove = (payload: number[]) =>
    dispatch(fetchDepartmentDeleteAction(payload))

  return {
    loading,
    departments,
    error,
    onFetch,
    onCreate,
    onUpdate,
    onRemove,
  }
}
