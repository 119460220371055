import {getAccessToken} from 'api/storageApi'
import CheckBox from 'components/Checkbox'
import {PrimaryLoadableButton} from 'components/CustomButtons/PrimaryLoadableButton'
import {Settings} from 'components/Settings'
import RouteConstant from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import {saveAs} from 'file-saver'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import {dateToFileFormat} from 'helpers/dateHelper'
import JSZip from 'jszip'
// @ts-ignore
import JSZipUtils from 'jszip-utils'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'

const StyledSummarySettings = styled.div`
  .CheckAll {
    margin-bottom: 15px;
  }
  .CheckboxWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

interface TreatmentSummarySettingsProps {
  ecFilePath?: string
  eoFilePath?: string
  hrvFilePath?: string
  mciFilePath?: string
}

type CheckboxType = 'eeg' | 'hrv' | 'mci'
type CheckboxState = {
  type: CheckboxType
  checked: boolean
  label: string
}

function TreatmentSummarySettings({
  ecFilePath,
  eoFilePath,
  hrvFilePath,
  mciFilePath,
}: TreatmentSummarySettingsProps) {
  const {t} = useTranslation()
  const {
    params: {id: reportId},
  } = useRouteMatch<{id: string}>()

  const {onOpen: onOpenFailureModal} = useFailureModal()

  const [open, setOpen] = useState(false)

  const handleSetOpen = () => setOpen((isOpen) => !isOpen)

  const disabled: Record<CheckboxType, boolean> = {
    eeg: isNotDefined(ecFilePath) && isNotDefined(eoFilePath),
    hrv: isNotDefined(hrvFilePath),
    mci: isNotDefined(mciFilePath),
  }

  const getPath = (type: CheckboxType) => {
    if (type === 'hrv') return hrvFilePath
    if (type === 'mci') return mciFilePath
    if (type === 'eeg') return isDefined(ecFilePath) ? ecFilePath : eoFilePath
    return undefined
  }

  const [checked, setChecked] = useState<CheckboxState[]>([
    {
      type: 'eeg',
      checked: !disabled.eeg,
      label: 'EEG',
    },
    {
      type: 'hrv',
      checked: !disabled.hrv,
      label: 'HRV',
    },
    {
      type: 'mci',
      checked: !disabled.mci,
      label: 'aMCI',
    },
  ])

  const [loading, setLoading] = useState(false)

  const isAllChecked = () =>
    checked.filter((item) => !disabled[item.type]).every((item) => item.checked)

  const handleChecked = (type: CheckboxType) => () =>
    setChecked((items) =>
      items.map((item) => {
        if (item.type !== type) return item

        return {...item, checked: !item.checked}
      }),
    )

  const handleSelectAll = () => {
    if (isAllChecked()) {
      setChecked((items) =>
        items.map((item) => {
          if (disabled[item.type]) return item

          return {...item, checked: false}
        }),
      )
    } else {
      setChecked((items) =>
        items.map((item) => {
          if (disabled[item.type]) return item

          return {...item, checked: true}
        }),
      )
    }
  }

  const handleClick = async () => {
    const token = await getAccessToken()

    if (!token) {
      window.location.replace(`${RouteConstant.LOGIN.path}`)
      return
    }
    setLoading(true)
    const sources = checked
      .filter((c) => !disabled[c.type])
      .filter((c) => c.checked)
      .map((c) => getPath(c.type))
      .filter(isDefined)

    const promises = sources.map((source) =>
      // @ts-ignore
      JSZipUtils.getBinaryContent(source).then((data) => {
        const fileName = source.split('/').pop()
        return {
          fileName,
          data,
        }
      }),
    )

    const result = await Promise.allSettled(promises)
    const filteredResult = result
      .map((result) => {
        if (result.status === 'rejected') {
          return undefined
        }
        return result.value
      })
      .filter(isDefined)

    if (filteredResult.length === 0) {
      await onOpenFailureModal('다운로드 실패')
      setLoading(false)
      return
    }

    const zip = new JSZip()
    filteredResult.forEach((result) => {
      zip.file(result.fileName, result.data, {binary: true})
    })
    const zipFile = await zip.generateAsync({type: 'blob'})
    saveAs(zipFile, `${reportId}_${dateToFileFormat(new Date())}`)
    setLoading(false)
  }

  return (
    <StyledSummarySettings>
      <Settings open={open} onOpen={handleSetOpen}>
        <div className='Header'>
          <div className='Title'>PDF Download</div>
        </div>
        <div className='Contents'>
          <CheckBox
            className='CheckAll'
            checked={isAllChecked()}
            onChange={handleSelectAll}
          >
            {t('ISelectAll')}
          </CheckBox>
          <div className='CheckboxWrapper'>
            {checked.map((item) => (
              <CheckBox
                key={item.type}
                name='components'
                value={item.type}
                checked={item.checked}
                onChange={handleChecked(item.type)}
                disabled={disabled[item.type]}
              >
                {item.label}
              </CheckBox>
            ))}
          </div>
          <div className='Footer'>
            <PrimaryLoadableButton
              fullWidth
              loading={loading}
              onClick={handleClick}
            >
              Download
            </PrimaryLoadableButton>
          </div>
        </div>
      </Settings>
    </StyledSummarySettings>
  )
}

export default TreatmentSummarySettings
