import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import {fileDownload} from 'api'
import TableButton from 'components/atoms/Buttons/TableButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import ReceiptDialog from 'components/Dialog/ReceiptDialog'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import {openReceiptDialog} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import {
  fetchPaymentAction,
  fetchPaymentListRequest,
  selectPayment,
  selectPaymentQuery,
} from 'features/payment/paymentSlice'
import {selectAllOrgOid} from 'features/sales/salesAllOrgSlice'
import {
  dateToPeriodString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchButton: {
      height: 56,
      marginTop: 15,
    },
    selectBox: {
      flexGrow: 1,
      backgroundColor: '#fff',
      width: 130,
      height: 56,
    },
    disableText: {
      color: '#a6a6a6',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },

    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    analBox: {
      width: 200,
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      justifyContent: 'space-between',
      border: '1px solid rgb(53 73 99 / 20%)',
      alignItems: 'center',
      borderRadius: '4px',
      marginBottom: 10,
    },
    pointTitle: {
      fontSize: '12px',
    },
    pointCont: {
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
      minHeight: 400,
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    toolbarWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      gap: 5,
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    pointSearchContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
    buttonWrap: {
      height: 56,
    },
  }),
)

const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
    },
    table: {
      minWidth: 750,
      borderRadius: 5,
    },
    tableHeader: {
      height: 50,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
  }),
)

interface HeadCell {
  id: string
  label: string
}
const headCells: HeadCell[] = [
  {id: 'logTime', label: 'IOrderNo'},
  {id: 'orgName', label: 'IOrderDate'},
  {id: 'userId', label: 'IProductName'},
  {id: 'content', label: 'IOrderOrgName'},
  {id: 'content', label: 'IPurchaseType'},
  {id: 'content', label: 'IPurchaseAmount'},
  {id: 'content', label: 'IReceipt'},
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes} = props

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function UserLogTable() {
  const classes = useTableStyles()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const {user: currentUser} = useAuth()
  const paymentList = useSelector(selectPayment)
  const paymentQuery = useSelector(selectPaymentQuery)
  const selectedOid = useSelector(selectAllOrgOid)
  const [paymentId, setPaymentId] = React.useState(0)

  const setPageIndex = (page: number) => {
    dispatch(
      fetchPaymentAction({
        ...paymentQuery,
        paging: {
          page,
          size: paymentQuery.paging?.size ?? 10,
        },
        uid: currentUser?.uid ?? '',
        oid: selectedOid ?? null,
      }),
    )
  }
  const handleReadClick = (paymnetId: number) => {
    dispatch(openReceiptDialog())
    setPaymentId(paymnetId)
  }

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='small'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {paymentList.paymentList != null &&
            paymentList.paymentList.map((id: any) => {
              return (
                <TableRow
                  hover
                  role='checkbox'
                  classes={{hover: classes.hover}}
                >
                  <TableCell align='center'>{id.id ?? '-'}</TableCell>
                  <TableCell align='center'>
                    {isoStringToDateAndTimeString(id.dateTime ?? '-')}
                  </TableCell>
                  <TableCell align='center'>{id.product ?? '-'}</TableCell>
                  <TableCell align='center'>{id.name ?? '-'}</TableCell>
                  <TableCell align='center'>{id.paymentType ?? '-'}</TableCell>
                  <TableCell align='center'>{id.amount ?? '-'}</TableCell>
                  <TableCell align='center'>
                    <TableButton onClick={() => handleReadClick(id.id)}>
                      {t('IReceipt')}
                    </TableButton>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={paymentList.totalPages}
        currentPageIndex={paymentList.pageIndex}
        itemCountPerPage={paymentList.itemPerPage}
        setCurrentPageIndex={setPageIndex}
        loading={paymentList.loading}
        onItemCountPerPageChanged={(event: any) => {
          dispatch(
            fetchPaymentListRequest({
              ...paymentQuery,
              paging: {
                page: 0,
                size: parseInt(event.target.value as string, 10),
              },
              uid: currentUser?.uid ?? '',
              oid: selectedOid ?? null,
            }),
          )
        }}
        isItemCountPerPageEnabled={false}
      />
      <ReceiptDialog paymentId={paymentId} />
    </TableContainer>
  )
}

type PaymentSearchedType =
  | 'ORDER_NO'
  | 'USED_DATE'
  | 'PRODUCT'
  | 'ANALYSIS_ID'
  | 'NAME'
  | 'ORG'
  | 'TYPE'

export default function SalesPaymentHistory() {
  const classes = useStyles()
  const {t} = useTranslation()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {user: currentUser} = useAuth()
  const dispatch = useAppDispatch()
  const selectedOid = useSelector(selectAllOrgOid)
  const paymentList = useSelector(selectPayment)
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const [searchNameValue, setSearchNameValue] = React.useState(0)
  const [searchedValue, setSearchedValue] = React.useState('')

  const searchRadioItems = [
    {value: 0, label: 'IOrderNo', apiKey: 'ORDER_NO'},
    {value: 1, label: 'IOrderDate', apiKey: 'USED_DATE'},
    {value: 2, label: 'IProductName', apiKey: 'PRODUCT'},
    {value: 3, label: 'IAnalysisNumber', apiKey: 'ANALYSIS_ID'},
    {value: 4, label: 'IName', apiKey: 'NAME'},
    {value: 5, label: 'IPurchaseType', apiKey: 'TYPE'},
  ]

  const searchedListMap = searchRadioItems.map((item) => (
    <MenuItem value={item.value}>{t(item.label)}</MenuItem>
  ))
  const [searchedSelectedType, setSearchedSelectedType] =
    React.useState<PaymentSearchedType>()

  const handleOnchange = (event: React.ChangeEvent<{value: unknown}>) => {
    if ((event.target.value as number) in searchRadioItems) {
      const number = event.target.value as number
      const value = String(event.target.value) as PaymentSearchedType
      setSearchNameValue(number)
      setSearchedSelectedType(value)
    }
  }

  const handleValue = (value: any) => {
    setSearchedValue(value)
  }
  const handleKeywordSearch = () =>
    dispatch(
      fetchPaymentListRequest({
        paging: {
          page: 0,
          size: 5,
        },
        search: {
          searchName: searchedSelectedType ?? '',
          searchValue: searchedValue,
        },
        uid: currentUser?.uid ?? '',
        oid: selectedOid ?? null,
      }),
    )
  const defaultStateDate = new Date().setDate(-340)
  const [selection, setSelection] = React.useState<PeriodDate>({
    startDate: new Date(defaultStateDate),
    endDate: new Date(),
  })
  const handleDateValue = (value: any) => {
    setSelection(value)
  }
  const handleDateSearch = () => {
    dispatch(
      fetchPaymentAction({
        paging: {
          page: 0,
          size: 5,
        },
        uid: currentUser?.uid ?? '',
        period: dateToPeriodString(selection),
        oid: selectedOid ?? null,
      }),
    )
  }
  const excelDownload = async () => {
    if (paymentList.paymentList?.length !== 0) {
      await fileDownload({
        path: '/api/payment/v1/download/excel',
        params: {
          oid: selectedOid ?? null,
        },
        fileName: 'payment.xlsx',
      })
    }
    if (paymentList.paymentList?.length === 0) {
      onFailureModalOpen(t('IPaymentEmpty'))
    }
  }

  useEffect(() => {
    handleDateSearch()
  }, [selection])

  return (
    <CardWithTitle title={t('IMyPurchase')}>
      <Grid className={classes.pointContainer}>
        <Grid item xs={12}>
          <Grid container className={classes.toolbarContainer}>
            <Grid className={classes.toolbarWrap}>
              <RangeDatePicker
                onChangeValue={handleDateValue}
                dateValue={selection}
              />
              <div className={classes.pointSearchContainer}>
                <FormControl variant='outlined'>
                  <Select
                    value={searchNameValue}
                    onChange={handleOnchange}
                    displayEmpty
                    color='primary'
                    input={
                      <OutlinedInput
                        name='age'
                        id='outlined-age-simple'
                        classes={outlinedInputClasses}
                      />
                    }
                  >
                    <MenuItem disabled value=''>
                      <Typography className={classes.disableText}>
                        {t('ISelect')}
                      </Typography>
                    </MenuItem>
                    {searchedListMap}
                  </Select>
                </FormControl>
                <SearchBar
                  onChangeValue={handleValue}
                  onSearchEvent={handleKeywordSearch}
                  disabledValue={false}
                />
              </div>
            </Grid>
            <Button
              variant='contained'
              color='primary'
              startIcon={<GetAppIcon />}
              disableElevation
              onClick={() => {
                excelDownload()
              }}
            >
              {t('IExcelDownload')}
            </Button>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <UserLogTable />
      </Grid>
    </CardWithTitle>
  )
}
