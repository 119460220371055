import {Theme, makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
  },
  title: {
    padding: '15px 0 9px 18px',
    fontSize: 15,
    fontWeight: 400,
    color: '#333333',
  },
  cardWrap: {
    overflow: 'auto',
  },
  container: {
    '& > .MuiDialog-container > .MuiPaper-root': {
      [theme.breakpoints.up('xs')]: {
        minWidth: 354,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 'unset',
        maxWidth: 410,
      },
    },
    '& .MuiDialog-paperScrollPaper': {},
  },

  cardContent: {},
  infoWrap: {
    backgroundColor: 'white',
    padding: '20px 40px',
    border: '1px solid #e4e4e4',
    borderRadius: 6,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 10px 0',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  longTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'primary',
    background: 'white',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
    margin: '5px 0',
    minWidth: 50,
    flexWrap: 'wrap',
    marginTop: 5,
    color: '#4b4b4b',
    display: 'flex',
    flexDirection: 'row',
  },
  subContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    flexWrap: 'wrap',
    marginTop: 5,
    marginLeft: 10,
    width: 250,
  },
  questionContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '5px 0',
    flexWrap: 'wrap',
    marginTop: 5,
    marginLeft: 10,
    width: 600,
  },
  subContentText: {
    fontSize: 15,
    color: '#b4b4b4',
  },
  line: {
    background: '#eeeeee',
  },
  button: {
    minWidth: 100,
    height: 50,
    marginLeft: 10,
    padding: '0px 70px',
    marginTop: 20,
  },
  hidden: {
    visibility: 'hidden',
  },
  scroll: {
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    paddingRight: 50,
    paddingLeft: 50,
  },
  buttonContainerBgColor: {
    backgroundColor: '#f4f4f4',
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    width: 520,
    '& button': {
      minWidth: 100,
      padding: '6px 0',
      flex: 1,
      margin: '0 10px',
      maxWidth: 126,
      fontSize: 12,
      fontWeight: 400,
    },
    '& button:nth-child(1)': {
      marginLeft: 20,
    },
    '& button:nth-child(3)': {
      marginRight: 20,
    },
    '& .MuiButton-containedSecondary': {
      color: 'white',
    },
  },
  okButton: {
    backgroundColor: 'white',
    border: 0,
    color: '#4b4b4b',
  },
  defaultButton: {
    backgroundColor: '#f4f4f4',
    color: '#4b4b4b',
  },
  alertButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: 'white',
    '& button': {
      flex: 1,
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  smallPadding: {
    padding: theme.spacing(0.5),
  },
  okButtonColor: {
    color: 'white',
  },
  editButton: {
    width: 152,
    height: 53,
  },
  flexRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  buttonSize: {
    padding: '7px 14px',
    fontSize: 10,
    backgroundColor: 'white',
    boxSizing: 'border-box',
  },
  lineButton: {
    color: '#3cbbbd',
    border: '1px solid #3cbbbd',
  },
  ledOnButton: {
    color: '#e37573',
    border: '1px solid #e37573',
  },
  ledOffButton: {
    color: '#92aabf',
    border: '1px solid #92aabf',
  },
  iconTitleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 15,
  },
  iconColor: {
    color: '#636364',
    fontSize: 20,
    marginRight: 8,
  },
  titleDivider: {
    backgroundColor: '#f1f1f1',
  },
  containerTitle: {
    width: '100%',
    position: 'relative',
  },
  closeButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 8,
    right: 8,
  },
  closeButton: {
    padding: 0,
    width: 14,
    height: 14,
  },
  closeIcon: {
    width: 14,
    height: 14,
    color: '#c5c5c5',
  },
  requiredMark: {
    color: 'red',
    marginRight: 5,
  },
  requiredMarkHidden: {
    visibility: 'hidden',
    marginLeft: 5,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentWrap: {
    padding: '24px 20px 16px',
  },
  therapyInfoContainer: {
    background: 'unset',
  },
  iconContainer: {
    backgroundColor: '#e9e9e9',
    borderRadius: 3,
    width: 45,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: 'white',
    },
  },
  fileContainer: {
    border: '3px solid #e9e9e9',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
  },
  fileWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    flex: 1,
  },
  fileLabel: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    width: '100%',
  },
  fileInput: {
    visibility: 'hidden',
    width: 1,
    height: '100%',
  },
  errorText: {
    fontSize: 11,
    marginTop: 2,
    color: '#d03c35',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  radioContainer: {
    flex: 1,
  },
  privacyButton: {
    width: 304,
    height: 41,
    backgroundColor: '#5a6268',
    color: 'white',
  },
  privacyText: {
    fontSize: 14,
    color: '#868e96',
  },
  labelText: {
    color: '#868e96',
    fontSize: 16,
  },
  bullet: {
    width: 4,
    height: 4,
    backgroundColor: '#4b4b4b',
    borderRadius: 50,
    margin: '0 20px',
  },
  table: {
    width: '100%',
    padding: 0,
    borderCollapse: 'collapse',
  },
  bottomTableHeader: {
    border: '1px solid #e5e5e5',
    backgroundColor: '#38464e',
    height: 58,
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },
  bottomTableContent: {
    height: 58,
    border: '1px solid #e5e5e5',
    paddingLeft: 15,
    textAlign: 'center',
  },
  tableBackground: {
    backgroundColor: 'white',
  },
  image: {
    border: '1px solid #f1f1f1',
  },
  departmentDesc: {
    fontSize: 13,
    color: '#b4b4b4',
    fontWeight: 400,
  },
  brainContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  brainImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 220,
    marginBottom: 8,

    '&:nth-child(2)': {
      marginTop: 18,
    },
  },
  brainImgInwrap: {
    position: 'relative',
    height: '100%',
    display: 'inline-flex',

    '& img': {
      maxHeight: '100%',
    },
  },
  brainImgContainerDim: {
    display: 'flex',
    justifyContent: 'center',
    height: 220,
    position: 'relative',
  },
  brainImgInwrapDim: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    left: 'auto',
    right: 'auto',

    '& img': {
      maxHeight: '100%',
      position: 'relative',
      left: 4,
      opacity: 0.3,
    },

    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '300px',
      fontWeight: 500,
      color: '#5a6268',
      position: 'absolute',

      '& > span': {
        display: 'inline-block',
        width: '100%',
        lineHeight: '17px',
        textAlign: 'center',
        maxWidth: 140,
        wordBreak: 'keep-all',
      },
    },
  },

  therapyPointContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  therapyPoint: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    borderRadius: '50%',
    width: 16,
    height: 16,
    border: 0,
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    '&:not(.therapyPointRecent)': {
      cursor: 'pointer',
    },
    '&:not(.therapyPointRecent):hover:after': {
      background: '#8ea9c1',
    },
    '&:not(.therapyPointRecent):checked:hover:after': {
      background: '#e37878',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '#a6bcd0',
      borderRadius: '50%',
      transition: 'background 0.25s linear',
    },
    '&:checked:after': {
      background: '#ff8686',
    },
    '&:checked:before': {
      opacity: 1,
    },
    '&:before': {
      content: '" "',
      position: 'absolute',
      width: 36,
      height: 36,
      background: '#ff86864D',
      borderRadius: '50%',
      opacity: 0,
      transition: 'opacity 0.25s linear',
    },
    '&.fp1': {
      top: 2,
      left: 64,
    },
    '&.fp2': {
      top: 3,
      right: 65,
    },
    '&.f7': {
      top: 48,
      left: 18,
    },
    '&.f3': {
      top: 55,
      left: 50,
    },
    '&.fz': {
      top: 56,
      left: 80,
    },
    '&.f4': {
      top: 55,
      right: 50,
    },
    '&.f8': {
      top: 48,
      right: 18,
    },
    '&.t3': {
      top: 98,
      left: 3,
    },
    '&.c3': {
      top: 98,
      left: 42,
    },
    '&.cz': {
      top: 98,
      left: 81,
    },
    '&.c4': {
      top: 98,
      right: 43,
    },
    '&.t4': {
      top: 98,
      right: 3,
    },
    '&.t5': {
      top: 148,
      left: 10,
    },
    '&.p3': {
      top: 141,
      left: 46,
    },
    '&.pz': {
      top: 140,
      left: 80,
    },
    '&.p4': {
      top: 141,
      right: 48,
    },
    '&.t6': {
      top: 148,
      right: 12,
    },
    '&.o1': {
      bottom: 4,
      left: 64,
    },
    '&.o2': {
      right: 65,
      bottom: 4,
    },
  },
  textImgContainer: {
    margin: 0,
    width: 40,
    position: 'absolute',
    top: 0,

    '& > img': {
      width: '100%',
    },
  },
  textImgContainerLeft: {
    left: '-12.5%',
  },
  textImgContainerRight: {
    right: '-17.5%',
  },

  textEditInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#5a6268',
  },
  textInfoGroup: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '& > div': {
      flex: 1,
      maxWidth: 100,
      height: 42,
    },
  },
  explanation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 13,
    fontSize: 11,
    color: '#333333',

    '&:before': {
      content: '""',
      display: 'inline-block',
      marginRight: 5,
      width: 3,
      height: 3,
      backgroundColor: '#333333',
      borderRadius: '50%',
    },
  },
  textReadInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#5a6268',
  },
  textReadInfoName: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 15,

    '& span': {
      display: 'inline-block',
      fontWeight: 500,
    },
  },
  textReadInfoValue: {
    display: 'flex',
    flexDirection: 'column',
  },
  frequency: {
    display: 'inline-block',
  },
  therapyTime: {
    display: 'inline-block',
  },
}))

export default useStyles
